import { connect } from 'react-redux';
import DataRoom from '../../../components/data_room';

const mapStateToProps = state => ({
  editor: false,
  company: state.company,
  dataRoom: state.dataRoom,
  comments: null,
  blockFileDownload: state.currentUser ? state.currentUser.blockFileDownload : false,
});

export default connect(mapStateToProps)(DataRoom);
