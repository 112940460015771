import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import FactSheetSettings from './FactSheetSettings';
import HtmlComponent from '../tab_view/HtmlComponent';


class FactSheetSettingsModal extends Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    className: PropTypes.string,
    width: PropTypes.string,
    html: PropTypes.string,
  };

  state = {
    open: false,
  };

  render() {
    const { report, className, width, html } = this.props;
    const { open } = this.state;

    return (
      <div className="">
        <div
          className={`${className} cursor-pointer`}
          style={{ width }}
          onClick={(e) => { e.preventDefault(); this.setState({ open: true }); }}
        >
          <HtmlComponent data={{ html }} />
        </div>
        <Modal
          className="bg-white flex flex-column mb2 p2 border-top border-blue bw-4"
          style={{ height: '313px', width: '540px' }}
          show={open}
        >
          <form action={report.settings_url} acceptCharset="UTF-8" method="post">
            <FactSheetSettings
              kpiQuantityValue={report.kpi_quantity_value}
              kpiQuantityOptions={report.kpi_quantity_options}
              kpisMetricsValue={report.kpis_metrics_value}
              kpisMetricsOptions={report.kpis_metrics_options}
              headerBackground={report.header_background}
              headingColor={report.heading_color}
              subHeadingColor={report.sub_heading_color}
              stringColor={report.string_color}
              borderColor={report.border_color}
              headerTextColor={report.header_text_color}
              closeModal={() => this.setState({ open: false })}
            />
          </form>
        </Modal>
      </div>
    );
  }
}

export default FactSheetSettingsModal;
