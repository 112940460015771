import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FaButtonFunction from './FaButtonFunction';
import CompanyPermissions from './CompanyPermissions';


class CompanyInvestmentInvited extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  state = {
    reinvite: true,
  }

  delete = () => {
    const { data } = this.props;

    const confirmed = confirm('Are your sure to revoke invitation ?');
    if (confirmed) {
      App.Api.delete(data.delete_url).then(() => {
        store.dispatch({ type: 'REMOVE_ITEM', list: 'main', id: data.id });
        App.State.setFlash({ name: 'notice', msg: 'Removed!' });
      });
    }
  }

  deleteButton = () => {
    const { data } = this.props;

    if (data.delete_url) {
      return (
        <FaButtonFunction
          faIcon="trash"
          margin="m0"
          tooltip="Revoke invite"
          color="light-gray"
          handleClick={this.delete}
        />);
    }
    return (
      null
    );
  }

  reinvite = () => {
    const { data } = this.props;

    App.Api.patch(data.reinvite_url).then(() => {
      App.State.setFlash({ name: 'notice', msg: 'Invite email sent again!' });
      this.setState({ reinvite: false });
    });
  }

  reinviteButton = () => {
    const { reinvite } = this.state;

    if (reinvite) {
      return (
        <FaButtonFunction
          faIcon="envelope"
          margin="m0"
          tooltip="Reinvite user"
          color="light-gray"
          handleClick={this.reinvite}
        />);
    }
    return (
      <div className="mx1" data-balloon="Invite resent." data-balloon-pos="down">
        <i className="fa fa-fw fa-check text-green" />
      </div>
    );
  }

  render() {
    const { data } = this.props;

    return (
      <div className="col-12 flex flex-wrap px2 h5 sm-px0">
        <div className="col-3 text-light-gray py1 pl2 sm-col-6 sm-pr2">
          {data.name}
        </div>

        <div className="col-2 sm-col-6 text-light-gray py1 pl2">
          {data.note}
        </div>

        { !data.is_investor &&
          <div className="col-7 sm-col-6 flex">
            {/* Permissions Listing */}
            <CompanyPermissions data={data} />

            {/* Actions */}
            <div className="flex items-center sm-hide">
              {this.deleteButton()}
              {this.reinviteButton()}
            </div>
          </div>
        }
      </div>
    );
  }
}

export default CompanyInvestmentInvited;
