import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import { defaultSelectStyles, defaultSelectTheme } from '../selects/SelectStyles';


class KpiSelector extends Component {
  static propTypes = {
    kpi_id: PropTypes.number,
    kpis: PropTypes.array,
    kpi_charts: PropTypes.array,
    kpiName: PropTypes.func,
    chart_title: PropTypes.string,
    setStateInKpiSection: PropTypes.func,
    setKpis: PropTypes.func,
    kpisForSelection: PropTypes.array,
    kpiOptionsOpened: PropTypes.bool,
    useDefaultTitle: PropTypes.bool,
    kpiIds: PropTypes.string,
    isNumberKpi: PropTypes.func,
    isFunnelKpi: PropTypes.func,
    isTableKpi: PropTypes.func,
  }

  static defaultProps = {
    kpi_id: null,
    kpis: [],
    kpi_charts: false,
    kpiName: {},
    chart_title: null,
    setStateInKpiSection: {},
    setKpis: {},
    kpisForSelection: [],
    kpiOptionsOpened: false,
    useDefaultTitle: true,
    kpiIds: null,
    isNumberKpi: {},
    isFunnelKpi: {},
    isTableKpi: {},
  }

  correctType = {
    bar: 'column',
    line: 'line',
    spline: 'spline',
    'area-spline': 'areaspline',
    bar_horizontal: 'bar',
    number: 'number',
  }

  componentDidUpdate(prevProps) {
    const { kpiIds } = this.props;

    if (kpiIds !== prevProps.kpiIds) {
      if (kpiIds) this.buildKpiCharts(kpiIds);
    }
  }

  // When it is a multi kpi selection, all kpis are added as 'line' if the 'funnel' or 'table' type were not selected
  addKpiChart = (kpiId) => {
    const { kpi_charts, kpiName, useDefaultTitle, chart_title, setStateInKpiSection } = this.props;

    let localKpiCharts = [];
    Object.assign(localKpiCharts, kpi_charts);
    const type = this.multiKpiChartTypeSet(kpiId);
    const kpiChart = {
      kpi_id: kpiId,
      name: kpiName(parseInt(kpiId, 10)),
      show_actual_data: true,
      show_plan_data: true,
      type,
    };
    localKpiCharts = localKpiCharts.concat(kpiChart);
    const chosenChartTitle = useDefaultTitle ? localKpiCharts.map(i => i.name).join(' | ') : chart_title;
    setStateInKpiSection({ kpi_charts: localKpiCharts, chart_title: chosenChartTitle });
  }

  // Decides whether a kpi will be added or removed from the state
  buildKpiCharts = (kpiIds) => {
    const { kpi_charts } = this.props;

    const arrayKpiIds = kpiIds.split(',');
    if (arrayKpiIds.length > kpi_charts.length) {
      const lastId = arrayKpiIds[arrayKpiIds.length - 1];
      this.addKpiChart(lastId);
    } else {
      kpi_charts.forEach((kpiChart) => {
        if (arrayKpiIds.find(kpiId => parseInt(kpiId, 10) === parseInt(kpiChart.kpi_id, 10)) === undefined) {
          this.removeKpiChart(kpiChart.kpi_id);
        }
      });
    }
  }

  kpiChartType = (kpiId) => {
    const { kpis } = this.props;

    const kpiSelected = kpis.find(kpi => kpi.id === parseInt(kpiId, 10));
    return this.correctType[kpiSelected.chart_type];
  }

  // Funnel charts must go with funnel charts only.
  multiKpiChartTypeSet = (kpiId) => {
    const { kpi_charts, isFunnelKpi, isTableKpi } = this.props;

    if (kpi_charts.length > 0) {
      if (isFunnelKpi()) {
        return 'funnel';
      }
      if (isTableKpi()) {
        return 'table';
      }
      return 'line';
    }
    return this.kpiChartType(kpiId);
  }

  periodStepLabel = (periodStep) => {
    if (periodStep === 'months') {
      return 'monthly';
    }
    if (periodStep === 'weeks') {
      return 'weekly';
    }
    if (periodStep === 'quarters') {
      return 'quarterly';
    }
    if (periodStep === 'years') {
      return 'yearly';
    }
    return 'daily';
  }

  removeKpiChart = (kpiId) => {
    const localKpiCharts = [];
    Object.assign(localKpiCharts, this.props.kpi_charts);
    const index = localKpiCharts.indexOf(localKpiCharts.find(kpiChart => kpiChart.kpi_id === kpiId));
    localKpiCharts.splice(index, 1);
    const firstKpi = (this.props.kpi_id !== localKpiCharts[0].kpi_id) ? parseInt(localKpiCharts[0].kpi_id, 10) : this.props.kpi_id;
    const chart_title = this.props.useDefaultTitle ? localKpiCharts.map(i => i.name).join(' | ') : this.props.chart_title;
    this.props.setStateInKpiSection({ kpi_charts: localKpiCharts, kpi_id: firstKpi, chart_title });
  }

  render() {
    const {
      kpisForSelection, kpiIds, kpi_id, kpiOptionsOpened,
      isNumberKpi, setKpis, setStateInKpiSection,
      tabScroll
    } = this.props;

    const kpis = kpisForSelection.map(kpi => ({ value: kpi.id, label: `${kpi.name} (${this.periodStepLabel(kpi.period_step)})` }));
    return (
      <div>
        <div className="px2 pt2">
          <p className="h4 mb3">Select KPI(s) to chart</p>
          <div className="h5 left-align mb2 relative">
            <ReactSelect
              className={`Select--new ${isNumberKpi() ? 'Select--disabled' : ''}`}
              name="kpi_chart[kpi_id]"
              value={kpiIds ? kpis.filter(option => (kpiIds.includes(option.value))) : ''}
              options={kpis}
              onChange={(selectedOptions) => (setKpis(selectedOptions.map(option => (option.value)).join(', ')))}
              isClearable={false}
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
              placeholder="Select a KPI"
              isMulti
              isDisabled={isNumberKpi()}
            />
          </div>
          {
            kpi_id &&
            <div
              className="cursor-pointer"
              href="#"
              onClick={(e) => { e.preventDefault(); setStateInKpiSection({ kpiOptionsOpened: !kpiOptionsOpened }); }}
            >
              <p className="flex flex-justify-between flex-center ml2 mt3 pb3 text-black" style={{ flexShrink: 0 }}>
                <span style={{ whiteSpace: 'nowrap' }}>Display options</span>
                <span style={{ width: '100%', borderBottom: '1px solid lightgray', margin: '5px' }} />
                {
                  kpiOptionsOpened ?
                    <span className="relative" style={{ marginRight: '0.7rem', top: '-2px' }}><i className="fa fa-caret-down" aria-hidden="true" /></span>
                    :
                    <span style={{ marginRight: '0.7rem' }}><i className="fa fa-caret-right" aria-hidden="true" /></span>
                }
              </p>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default KpiSelector;
