import React, { Component } from 'react';
import PropTypes from 'prop-types';


class Textarea extends Component {
  static defaultProps = {
    value: '',
    onChange: null,
    skipPropsUpdate: false,
  }

  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    skipPropsUpdate: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
    };
  }

  componentDidMount() {
    this.resize();
  }

  onChange = e => {
    if (this.props.onChange) this.props.onChange(e);
    this.setState({
      value: e.target.value,
    }, this.resize);
  }

  componentWillReceiveProps(props) {
    if (this.props.skipPropsUpdate) return;
    this.setState(props, this.resize);
  }

  resize = () => {
    while (this.textarea.clientHeight === this.textarea.scrollHeight && this.textarea.rows > 1) {
      if (this.textarea.rows === this.props.rows) break;
      this.textarea.rows -= 1;
    }

    while (this.textarea.clientHeight < this.textarea.scrollHeight) {
      this.textarea.rows += 1;
    }
  }

  render() {
    const textareaStyle = {
      resize: 'none',
    };

    const { onChange, value, skipPropsUpdate, ...other } = this.props;

    return (
      <textarea
        ref={(c) => { this.textarea = c; }}
        onChange={this.onChange}
        value={this.state.value}
        style={textareaStyle}
        {...other}
      >
        {this.props.textarea}
      </textarea>
    );
  }
}

export default Textarea;
