import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import NumberInput from '../../../NumberInput';
import DatePicker from '../../../selects/DatePicker';
import CustomAutocomplete from '../../../CustomAutocomplete';
import CleaveNumberFormatting from '../../../CleaveNumberFormatting';

import FundingRoundManageModal from './modals/FundingRoundManageModal';
import FundingRoundDetailsModal from './modals/FundingRoundDetailsModal';
import LoadingSpinner from '../../../LoadingSpinner';
import ConfirmationModal from '../../../ConfirmationModal';


class FundingRounds extends Component {
  static propTypes = {
    funding: PropTypes.object.isRequired,
    investorId: PropTypes.number.isRequired,
    hasPermission: PropTypes.bool.isRequired,
    isCustom: PropTypes.bool.isRequired,
    currency: PropTypes.string,
    saveFundingRound: PropTypes.func.isRequired,
    createFundingRound: PropTypes.func.isRequired,
    deleteFundingRound: PropTypes.func.isRequired,
    deleteFundingRounds: PropTypes.func.isRequired,
  }

  static defaultProps = {
    currency: null,
  }

  state = {
    activeModal: null,
    currentRound: null,
    currentRow: null,
  }

  render() {
    const {
      funding,
      investorId,
      saveFundingRound,
      createFundingRound,
      deleteFundingRound,
      deleteFundingRounds,
      hasPermission,
      isCustom,
      currency,
    } = this.props;

    const { activeModal, currentRound, currentRow } = this.state;

    const inputClasses = classNames('col-10', {
      'p1 border border-lighter-gray': funding.edit,
      'hover hover-border-blue': funding.edit && isCustom,
      'bg-lighter-gray': funding.edit && !isCustom,
      'bg-none border-transparent': !funding.edit,
    });

    const inputStyling = {
      padding: funding.edit ? null : '0',
    };

    const fundingRounds = isCustom ? funding.rounds : funding.company_rounds;
    const loading = funding.loading || funding.rounds_loading;

    return (
      <div className="text-gray px3 pb2 mb2">
        <div className="flex flex-justify-between flex-wrap pt2 mb1">
          <div className="flex">
            <div className="fw400">Company Rounds and other Valuation Events</div>
            <div
              data-balloon="This section contains details of the funding rounds the company raised till today. As an investor, you can also add here other events that change the valuation of the company, e.g. a shares sale, or another fair-value change."
              data-balloon-pos="right"
              data-balloon-length="xlarge"
            >
              <i className="fa fa-info-circle text-blue ml1 h5" />
            </div>
            {currency &&
              <span className="h5 text-light-gray ml1 fw400">({currency})</span>
            }
          </div>
          {funding.edit &&
            <div className="flex flex-wrap">
              {!funding.custom_investment && funding.shares.length > 0 && !isCustom &&
                <ConfirmationModal
                  html='<div><i class="fa fa-copy mr1"></i><span>Copy Company Round Data as Custom Data</span></div>'
                  className="px1 mr1 h6 bg-black--transparent text-white cursor-pointer rounded sm-mt1"
                  onClick={() => createFundingRound('outside', false)}
                  message="<b>Please note copying company round data as custom round data will delete all existing custom round data. Are you sure you want to continue?"
                  width="auto"
                />
              }
              {isCustom &&
                <div
                  className="px1 mr1 bg-black--transparent h6 text-white z3 cursor-pointer rounded sm-mt1"
                  onClick={() => createFundingRound()}
                >
                  <i className="fa fa-plus mr1" />Add
                </div>
              }
              {!funding.custom_investment &&
                <div
                  className="px1 bg-black--transparent h6 text-white z3 cursor-pointer rounded sm-mt1"
                  onClick={() => this.setState({ activeModal: 'manageModal' })}
                >
                  <i className="fa fa-cog mr1" />Manage
                </div>
              }
            </div>
          }
        </div>

        {/* <div className={`flex ${isCustom ? `${loading ? 'flex-justify-center' : ''}` : 'col-12'}`}> */}
        <div className={`flex ${loading ? 'flex-justify-center' : ''}`}>
          {/* Table Rows Number */}
          {!loading &&
            <div className="mt-auto mb-auto mr1 bold" style={!isCustom ? { paddingTop: '28px' } : {}}>
              {fundingRounds.length > 0 &&
                  fundingRounds.map((r, index) => (
                    <div key={r.id} className="flex items-center" style={{ width: '30%', paddingTop: '35px' }}>
                      <span className="h5">{index + 1}</span>
                    </div>
                  ))
              }
            </div>
          }

          <div
            className={`${!loading ? 'overflow-x-auto mr1 pb2' : ''}`}
            style={isCustom ? { minHeight: '10em' } : {}}
          >
            {/* Table Header */}
            <div style={{ width: loading ? '100%' : '115em' }}>
              {!loading &&
                <div className="flex py1 mt3 text-light-gray border-top border-bottom border-lighter-gray">
                  <div
                    data-balloon="This is the date of closing of the round."
                    data-balloon-pos="left"
                    data-balloon-length="large"
                    style={{ width: '28%' }}
                  >
                    Closing Date
                  </div>
                  <div
                    data-balloon='This is the name of the round or valuation event. You will be able to select from these names in the "Your Investments" table, to link an investment to a round'
                    data-balloon-pos="right"
                    data-balloon-length="large"
                    style={{ width: '35%' }}
                  >
                    Round or event
                  </div>
                  <div
                    data-balloon="This is the total amount raised by the company in this round, from all the investors participating in the round."
                    data-balloon-pos="down"
                    data-balloon-length="large"
                    style={{ width: '25%' }}
                  >
                    Raised
                  </div>
                  <div
                    data-balloon="This is the pre-money valuation of this round."
                    data-balloon-pos="down"
                    data-balloon-length="large"
                    style={{ width: '25%' }}
                  >
                    Pre-Money
                  </div>
                  <div
                    data-balloon="This is the post-money valuation of this round."
                    data-balloon-pos="down"
                    data-balloon-length="large"
                    style={{ width: '25%' }}
                  >
                    Post-Money
                  </div>
                  <div
                    data-balloon="This is the price-per-share in this round. Should equal the post-money valuation divided into the total shares of the round."
                    data-balloon-pos="down"
                    data-balloon-length="large"
                    style={{ width: '25%' }}
                  >
                    Share Price
                  </div>
                  <div
                    data-balloon="This is the fully-diluted price-per-share in this round. I.e. the post-money valuation divided into shares + options + the options-equivalent of the convertible."
                    data-balloon-pos="down"
                    data-balloon-length="large"
                    style={{ width: '25%' }}
                  >
                    FD Share Price
                  </div>
                  <div
                    data-balloon="This is the number of investors that participated in this round."
                    data-balloon-pos="down"
                    data-balloon-length="large"
                    style={{ width: '25%' }}
                  >
                    # Investors
                  </div>
                  <div
                    data-balloon="This is the total number of shares in the company after the round. You can also enter the new shares issued in this round in the details box."
                    data-balloon-pos="down"
                    data-balloon-length="large"
                    style={{ width: '25%' }}
                  >
                    # Shares
                  </div>
                  {!isCustom &&
                    <div
                      data-balloon="This is the number of new shares issued by the company in this round, to all investors."
                      data-balloon-pos="down"
                      data-balloon-length="large"
                      style={{ width: '25%' }}
                    >
                      New Shares
                    </div>
                  }
                  <div
                    data-balloon="This is the total number of options in the company after the round. You can also enter the new options issued in this round in the details box."
                    data-balloon-pos="down"
                    data-balloon-length="large"
                    style={{ width: '25%' }}
                  >
                    # Options
                  </div>
                  {!isCustom &&
                    <div
                      data-balloon="This is the number of new options issued by the company in this round, to all investors/option holders."
                      data-balloon-pos="down"
                      data-balloon-length="large"
                      style={{ width: '25%' }}
                    >
                      New Options
                    </div>
                  }
                  <div
                    data-balloon="This is the options-equivalent of the outstanding convertible notes after this round (for all convertible investors). I.e. the number of options the convertible is expected to buy at conversion, assuming conversion will happen with a pre-money valuation equal to the cap, and with a pre-money number of shares equal to the FD shares (= shares + options) after this round"
                    data-balloon-pos="down"
                    data-balloon-length="xlarge"
                    style={{ width: '30%' }}
                  >
                    # Conv. Eqv. Options
                  </div>
                  {!isCustom &&
                    <div
                      data-balloon="This is the options-equivalent of the new convertible issued in this round. I.e. the number of options the convertible is expected to buy at conversion, assuming conversion will happen with a pre-money valuation equal to the cap, and with a pre-money number of shares equal to the total FD shares (= shares + options) after this round. (Note if outstanding covertible has gone down in the round, this value is negative.)"
                      data-balloon-pos="down"
                      data-balloon-length="large"
                      style={{ width: '25%' }}
                    >
                      New Conv. Eqv. Options
                    </div>
                  }
                  <div
                    style={{ width: '32%' }}
                    data-balloon="This is the convertible principal amount issued in this round (or cancelled if it is negative)."
                    data-balloon-pos="left"
                    data-balloon-length="large"
                  >
                    New Convertible Principal
                  </div>
                </div>
              }

              {/* Table Data */}
              {loading ?
                <LoadingSpinner show={loading} type="fit" height="300px" background="white" />
                :
                fundingRounds.length ?
                  fundingRounds.map((r, index) => (
                    <div key={`round_${r.id}`} className="flex items-center py1 border-bottom border-lighter-gray">
                      {/* <div className="flex items-center col-3"> */}
                      <div className="flex items-center" style={{ width: '28%' }}>
                        <div className="col-12">
                          <DatePicker
                            className={inputClasses}
                            value={(r.closing_date || '').toString()}
                            onBlur={e => saveFundingRound(r.id, { closing_date: e.target.value })}
                            disabled={!funding.edit || !isCustom}
                            style={inputStyling}
                          />
                        </div>
                      </div>
                      {/* <div className="col-3 relative"> */}
                      <div className="relative" style={{ width: '35%' }}>
                        <CustomAutocomplete
                          className={inputClasses}
                          value={r.name}
                          items={funding.options.stage_options}
                          onBlur={e => saveFundingRound(r.id, { name: e.target.value })}
                          onSelect={item => saveFundingRound(r.id, { name: item.value })}
                          disabled={!funding.edit}
                          style={inputStyling}
                          id={`round_name_${index + 1}`}
                        />
                      </div>
                      {/* <div className="col-2"> */}
                      <div style={{ width: '25%' }}>
                        {(!funding.edit || !isCustom) && r.readable_money_raised ?
                          <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
                            {r.readable_money_raised}
                          </div>
                          :
                          <CleaveNumberFormatting
                            className={inputClasses}
                            value={(r.money_raised || '').toString()}
                            onBlur={e => saveFundingRound(r.id, { money_raised: e.target.rawValue })}
                            disabled={!funding.edit}
                            style={inputStyling}
                          />
                        }
                      </div>
                      {/* <div className="col-2"> */}
                      <div style={{ width: '25%' }}>
                        {(!funding.edit || !isCustom) && r.readable_pre_money ?
                          <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
                            {r.readable_pre_money}
                          </div>
                          :
                          <CleaveNumberFormatting
                            className={inputClasses}
                            value={(r.pre_money || '').toString()}
                            onBlur={e => saveFundingRound(r.id, { pre_money: e.target.rawValue })}
                            disabled={!funding.edit}
                            style={inputStyling}
                          />
                        }
                      </div>
                      {/* <div className="col-2"> */}
                      <div style={{ width: '25%' }}>
                        {(!funding.edit || !isCustom) && r.readable_post_money ?
                          <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
                            {r.readable_post_money}
                          </div>
                          :
                          <CleaveNumberFormatting
                            className={inputClasses}
                            value={(r.post_money || '').toString()}
                            onBlur={e => saveFundingRound(r.id, { post_money: e.target.rawValue })}
                            disabled={!funding.edit}
                            style={inputStyling}
                          />
                        }
                      </div>
                      {/* <div className="col-2"> */}
                      <div style={{ width: '25%' }}>
                        {(!funding.edit || !isCustom) && r.readable_share_price ?
                          <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
                            {r.readable_share_price}
                          </div>
                          :
                          <CleaveNumberFormatting
                            className={inputClasses}
                            value={(r.share_price || '').toString()}
                            onBlur={e => saveFundingRound(r.id, { share_price: e.target.rawValue })}
                            disabled={!funding.edit}
                            style={inputStyling}
                            decimalScale={8}
                          />
                        }
                      </div>
                      <div style={{ width: '25%' }}>
                        {(!funding.edit || !isCustom) && r.readable_fd_share_price ?
                          <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
                            {r.readable_fd_share_price}
                          </div>
                          :
                          <CleaveNumberFormatting
                            className={inputClasses}
                            value={(r.fd_share_price || '').toString()}
                            onBlur={e => saveFundingRound(r.id, { fd_share_price: e.target.rawValue })}
                            disabled={!funding.edit}
                            style={inputStyling}
                            decimalScale={8}
                          />
                        }
                      </div>
                      {/* <div className="col-2"> */}
                      <div style={{ width: '25%' }}>
                        <NumberInput
                          className={inputClasses}
                          value={(r.no_of_investors || '').toString()}
                          onBlur={e => saveFundingRound(r.id, { no_of_investors: e.target.value })}
                          disabled={!funding.edit}
                          style={inputStyling}
                        />
                      </div>
                      {/* <div className="col-2"> */}
                      <div style={{ width: '25%' }}>
                        {(!funding.edit || !isCustom) && r.readable_total_no_of_shares ?
                          <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
                            {r.readable_total_no_of_shares}
                          </div>
                          :
                          <CleaveNumberFormatting
                            className={inputClasses}
                            value={(r.total_no_of_shares || '').toString()}
                            onBlur={e => saveFundingRound(r.id, { total_no_of_shares: e.target.rawValue })}
                            disabled={!funding.edit}
                            style={inputStyling}
                          />
                        }
                      </div>
                      {!isCustom &&
                        // <div className="col-2">
                        <div style={{ width: '25%' }}>
                          <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
                            {r.readable_new_shares}
                          </div>
                        </div>
                      }
                      {/* <div className="col-2"> */}
                      <div style={{ width: '25%' }}>
                        {(!funding.edit || !isCustom) && r.readable_total_no_of_options ?
                          <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
                            {r.readable_total_no_of_options}
                          </div>
                          :
                          <CleaveNumberFormatting
                            className={inputClasses}
                            value={(r.total_no_of_options || '').toString()}
                            onBlur={e => saveFundingRound(r.id, { total_no_of_options: e.target.rawValue })}
                            disabled={!funding.edit}
                            style={inputStyling}
                          />
                        }
                      </div>
                      {!isCustom &&
                        // <div className="col-2">
                        <div style={{ width: '25%' }}>
                          <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
                            {r.readable_new_options}
                          </div>
                        </div>
                      }
                      {/* <div className="col-3"> */}
                      <div style={{ width: '30%' }}>
                        {(!funding.edit || !isCustom) && r.readable_conv_eqv_options ?
                          <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
                            {r.readable_conv_eqv_options}
                          </div>
                          :
                          <CleaveNumberFormatting
                            className={inputClasses}
                            value={(r.conv_eqv_options || '').toString()}
                            onBlur={e => saveFundingRound(r.id, { conv_eqv_options: e.target.rawValue })}
                            disabled={!funding.edit}
                            style={inputStyling}
                          />
                        }
                      </div>
                      {!isCustom &&
                        // <div className="col-2">
                        <div style={{ width: '25%' }}>
                          <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
                            {r.readable_new_conv_eqv_options}
                          </div>
                        </div>
                      }
                      {/* <div className="col-3"> */}
                      <div style={{ width: '32%' }}>
                        {(!funding.edit || !isCustom) && r.readable_total_convertible_principal ?
                          <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
                            {r.readable_total_convertible_principal}
                          </div>
                          :
                          <CleaveNumberFormatting
                            className={inputClasses}
                            value={(r.total_convertible_principal || '').toString()}
                            onBlur={e => saveFundingRound(r.id, { total_convertible_principal: e.target.rawValue })}
                            disabled={!funding.edit}
                            style={inputStyling}
                          />
                        }
                      </div>
                    </div>
                  ))
                  :
                  <div className="center pt3 text-light-gray px2">No Funding Rounds added yet...</div>
              }
            </div>
          </div>

          {/* More details && Remove Share */}
          {isCustom && !loading &&
            <div className="mt-auto mb-auto">
              {fundingRounds.length > 0 &&
                  fundingRounds.map((r, index) => (
                    <div
                      key={`round_more_details${r.id}}`}
                      className="flex items-center"
                      style={{ width: '30%', paddingTop: '35px' }}
                    >
                      <div
                        className="mr1"
                        data-balloon="More details"
                        data-balloon-pos="bottom"
                        data-balloon-length="small"
                        onClick={() => this.setState({ activeModal: 'detailsModal', currentRound: r, currentRow: index + 1 })}
                      >
                        <span className="fa fa-info-circle cursor-pointer text-blue" />
                      </div>

                      {funding.edit &&
                        <div
                          className="fa fa-trash cursor-pointer"
                          onClick={() => deleteFundingRound(investorId, funding.investment_id, r.id)}
                        />
                      }
                    </div>
                  ))
              }
            </div>
          }
        </div>

        {!funding.custom_investment && activeModal === 'manageModal' &&
          <FundingRoundManageModal
            show={activeModal === 'manageModal'}
            closeModal={() => this.setState({ activeModal: null })}
            hasPermission={hasPermission}
            isCustom={isCustom}
            companyRounds={funding.company_rounds}
            investorId={investorId}
            investmentId={funding.investment_id}
            createFundingRound={createFundingRound}
            deleteFundingRounds={deleteFundingRounds}
          />
        }
        <FundingRoundDetailsModal
          show={activeModal === 'detailsModal'}
          currentRound={currentRound}
          currentRow={currentRow}
          closeModal={() => this.setState({ activeModal: null })}
          saveFundingRound={saveFundingRound}
          edit={funding.edit}
        />
      </div>
    );
  }
}

export default FundingRounds;
