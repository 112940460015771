import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';


class CustomAutocomplete extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    wrapperClass: PropTypes.string,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    baseKey: PropTypes.string,
    secondKey: PropTypes.string,
  }

  static defaultProps = {
    id: '',
    name: '',
    className: '',
    wrapperClass: '',
    onChange: null,
    onSelect: null,
    onBlur: null,
    disabled: false,
    baseKey: 'key',
    secondKey: '',
  }

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };

    this.menuStyle = {
      borderRadius: '3px',
      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '2px 0',
      fontSize: '90%',
      left: '0',
      position: 'absolute',
      overflow: 'auto',
      maxHeight: '300px',
      top: '100%',
      zIndex: '1',
    };
  }

  onBlur = e => {
    const { onBlur } = this.props;
    if (onBlur) onBlur(e);
  }

  dropdownItem = (item, isHighlighted) => {
    const { baseKey, secondKey } = this.props;
    let key = null;

    if (item[baseKey] && item[secondKey]) {
      key = `${item[baseKey]}_${item[secondKey]}`;
    } else if (item[baseKey]) {
      key = `${item[baseKey]}`;
    } else {
      key = item.value;
    }

    return (
      <div key={key} className={`flex p1 ${isHighlighted ? 'bg-orange text-white' : ''}`}>
        {item.avatar &&
          <span
            className="col-2 block bg-center bg-cover bg-no-repeat circle header__avatar--investor mr1"
            style={{ backgroundImage: `url(${item.avatar})` }}
          />
        }
        <span className="col-10 h4">{item.value}</span>
      </div>
    );
  }

  handleChange = (e) => {
    const { name, onChange } = this.props;

    this.setState({ value: e.target.value });
    e.target.name = name;
    if (onChange) onChange(e);
  }

  handleSelect = (value, item) => {
    const { id, name, onChange, onSelect } = this.props;

    this.setState({ value });
    if (onChange) onChange({ target: { id, name, value } });
    if (onSelect) onSelect({ name, ...item });
  }

  matchStateToTerm = (state, value) => {
    if (value != null) {
      return (
        state.value.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    }
    return '';
  }

  render() {
    const { className, wrapperClass, id, items, disabled } = this.props;
    const { value } = this.state;

    return (
      <Autocomplete
        value={value != null ? value : undefined}
        inputProps={{ className, id, onBlur: this.onBlur, disabled }}
        items={items}
        getItemValue={item => item.value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        renderItem={this.dropdownItem}
        shouldItemRender={this.matchStateToTerm}
        menuStyle={this.menuStyle}
        wrapperProps={{ className: wrapperClass }}
      />
    );
  }
}

export default CustomAutocomplete;
