import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WidgetWrapper from './WidgetWrapper';

class Start extends Component {
  static propTypes = {
    widgets: PropTypes.array.isRequired,
    investorId: PropTypes.number,
    setChartFilter: PropTypes.func.isRequired,
    colorTheme: PropTypes.string,
    blurred: PropTypes.bool,
  }

  static defaultProps = {
    colorTheme: null,
    investorId: null,
    blurred: null,
  }

  renderWidget = (position) => {
    const { widgets, investorId, setChartFilter, blurred } = this.props;
    const widget = widgets.find(element => position === element.position);

    return (
      <WidgetWrapper
        widget={widget}
        widgets={widgets}
        investorId={investorId}
        setChartFilter={widget.type === 'portfolio_by_stage' || widget.type === 'portfolio_by_industry' ? (filter, filterType) => setChartFilter(position, filter, filterType) : null}
        blurred={blurred}
      />
    );
  }

  render() {
    return (
      <div className="flex flex-column">
        <div className="flex mxn1 sm-block">
          <div className="col-4 flex px1 sm-col-12">
            {this.renderWidget(1)}
          </div>
          <div className="col-8 flex px1 sm-col-12">
            {this.renderWidget(2)}
          </div>
        </div>
        <div className="flex sm-flex-column pt2">
          <div className="col-4 flex pr1 sm-col-12 sm-p0 sm-mb1">
            {this.renderWidget(3)}
          </div>
          <div className="col-4 flex px1 sm-col-12 sm-p0 sm-mb1">
            {this.renderWidget(4)}
          </div>
          <div className="col-4 flex pl1 sm-col-12 sm-p0">
            {this.renderWidget(5)}
          </div>
        </div>
      </div>
    );
  }
}

export default Start;
