const DataRoomStatsUser = ({ data: stat }) => (
  <div className="px2">
    <div key={`${stat.id}_${Math.random() * stat.id}`} className="flex col-12 p2">
      <div
        className="mr1 block bg-center bg-cover bg-no-repeat circle header__avatar--investor"
        style={{ backgroundImage: `url(${stat.user_avatar})` }}
      >
      </div>
      <div className="col-10" title={stat.user_name}>{stat.user_name}</div>
      <div className="col-2 flex flex-justify-end" title={stat.last_update}>{stat.last_update}</div>
    </div>
  </div>
);

export default DataRoomStatsUser;
