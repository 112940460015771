import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Modal from '../Modal';

class UpgradeAccountModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    dashboard: PropTypes.bool,
    dashboardType: PropTypes.string,
    exportFeature: PropTypes.bool,
    topHeader: PropTypes.bool,
    sendEmailOnClick: PropTypes.bool,
    maxInvestments: PropTypes.number,
    paywallDescription: PropTypes.string,
  }

  static defaultProps = {
    dashboard: false,
    dashboardType: null,
    exportFeature: false,
    topHeader: false,
    sendEmailOnClick: false,
    maxInvestments: null,
    paywallDescription: null,
  }

  state = {
    sentEvent: false,
    emailSent: false,
  }

  componentDidUpdate = async () => {
    const { show, sendEmailOnClick, paywallDescription } = this.props;
    const { sentEvent, emailSent } = this.state;

    if (show && sendEmailOnClick && !emailSent) {
      this.setState({ emailSent: true }, async () => {
        await axios.post('/support_emails', { type: 'investor_upgrade_account_on_click' });
      });
    }
    if (show) {
      if (paywallDescription && !sentEvent) {
        this.setState({ sentEvent: true }, async () => {
          if (typeof Intercom !== 'undefined') {
            await Intercom('trackEvent', 'Paywall_Opened');
          }
          await axios.post('/set_intercom_attributes', { type: 'paywall', event_description: paywallDescription });
        });
      }
    }
  }

  submitAction = async () => {
    window.location.href = 'mailto:support@investory.com?subject=Investory account upgrade&body=Dear investory team%2C I am interested in upgrading my investor account.';
    await axios.post('/support_emails', { type: 'investor_upgrade_account' });
    if (typeof Intercom !== 'undefined') {
      Intercom('trackEvent', 'Clicked on Upgrade Button');
    }
  }

  handleCloseModal = () => {
    const { closeModal } = this.props;
    const { sentEvent, emailSent } = this.state;

    if (sentEvent === true) {
      this.setState({ sentEvent: false });
    }
    if (emailSent === true) {
      this.setState({ emailSent: false });
    }

    closeModal();
  }

  render() {
    const {
      show,
      dashboard,
      dashboardType,
      exportFeature,
      topHeader,
      maxInvestments,
    } = this.props;

    return (
      <Modal
        className="bg-white flex flex-column mb2 react_modal border-top border-blue bw-4"
        show={show}
        onClickOutside={this.handleCloseModal}
      >
        <>
          <div
            className="flex flex-auto flex-justify-end cursor-pointer m1"
            onClick={this.handleCloseModal}
          >
            <i className="fa fa-close text-gray" />
          </div>
          <div className="flex flex-column flex-justify-center items-center my2">
            <div className="flex flex-column items-center flex-justify-center">
              <h3 className="h3 center fw400">
                {topHeader ?
                  'Upgrade your account to access all features!'
                  :
                  <>Upgrade your account to access {dashboard && !exportFeature ? 'the blurred features' : 'this feature'}!</>
                }
              </h3>
              <p className="center m1">
                Thanks for using investory.
                <br />
                {!topHeader &&
                  <>
                    {exportFeature || !dashboard ?
                      'Sadly, the feature you are looking for is only available to paid accounts.'
                      :
                      `You have exceeded the maximum number of companies (${maxInvestments}) to use this feature.`
                    }
                    <br />
                  </>
                }

                To access all features, please contact us and upgrade to a paid plan: <a href="https://investory.io/investor-pricing/" target="_blank" rel="noopener noreferrer">details here.</a>

                <br />
                {(dashboard && dashboardType === 'fund') && !exportFeature ?
                  `Or remove companies from funds to keep using the free trial with up to ${maxInvestments} companies in any fund. (you may need to ask an Admin member of your account).`
                  :
                  ''
                }
                <br />
              </p>
              <div className="center mb2 mt1">
                <div
                  className="bg-blue center h4 inline-block px1 rounded py1 text-upper text-white w-200 cursor-pointer"
                  onClick={this.submitAction}
                >
                  CONTACT US NOW
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal>
    );
  }
}

export default UpgradeAccountModal;
