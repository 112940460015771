import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ChartOptions from './ChartOptions';
import TimeframeOptions from './TimeframeOptions';


class AdvancedChartOptions extends Component {
  static propTypes = {
    chart_title: PropTypes.string,
    show_markers: PropTypes.bool,
    show_data_labels: PropTypes.bool,
    values_as_percentage: PropTypes.bool,
    y_scaling: PropTypes.string,
    tick_start: PropTypes.number,
    tick_end: PropTypes.number,
    isNumberKpi: PropTypes.func,
    isFunnelKpi: PropTypes.func,
    isTableKpi: PropTypes.func,
    shown: PropTypes.number,
    date_options: PropTypes.array,
    period_step: PropTypes.string,
    period_start: PropTypes.string,
    period_end: PropTypes.string,
    show_comma_values: PropTypes.bool,
    show_human_readable: PropTypes.bool,
    invalidDates: PropTypes.bool,
    kpi_charts: PropTypes.array,
    setStateInKpiSection: PropTypes.func,
    chartTypesChosen: PropTypes.bool,
    last_data_info: PropTypes.bool,
    last_data_percentage: PropTypes.bool,
  }

  state = {
    advancedOptionsOpened: this.props.chartTypesChosen,
  };

  setAdvancedOptionsOpened = () => {
    const { advancedOptionsOpened } = this.state;
    this.setState({ advancedOptionsOpened: !advancedOptionsOpened });
  }

  render() {
    const { chartTypesChosen } = this.props;
    const { advancedOptionsOpened } = this.state;

    return (
      <div>
        <div className="px2">
          {
            chartTypesChosen &&
            <div
              className="cursor-pointer"
              onClick={(e) => { e.preventDefault(); this.setAdvancedOptionsOpened(); }}
            >
              <p className="flex flex-justify-between flex-center ml2 mt3 pb3 text-black" style={{ flexShrink: 0 }}>
                <span style={{ whiteSpace: 'nowrap' }}>Advanced chart options</span>
                <span style={{ width: '100%', borderBottom: '1px solid lightgray', margin: '5px' }} />
                {
                  advancedOptionsOpened ?
                    <span className="relative" style={{ marginRight: '0.7rem', top: '-2px' }}><i className="fa fa-caret-down" aria-hidden="true" /></span>
                    :
                    <span style={{ marginRight: '0.7rem' }}><i className="fa fa-caret-right" aria-hidden="true" /></span>
                }
              </p>
            </div>
          }
        </div>
        {
          advancedOptionsOpened && chartTypesChosen &&
          <ChartOptions
            chart_title={this.props.chart_title}
            show_markers={this.props.show_markers}
            show_data_labels={this.props.show_data_labels}
            values_as_percentage={this.props.values_as_percentage}
            y_scaling={this.props.y_scaling}
            tick_start={this.props.tick_start}
            tick_end={this.props.tick_end}
            show_comma_values={this.props.show_comma_values}
            show_human_readable={this.props.show_human_readable}
            isNumberKpi={this.props.isNumberKpi}
            isFunnelKpi={this.props.isFunnelKpi}
            isTableKpi={this.props.isTableKpi}
            last_data_info={this.props.last_data_info}
            last_data_percentage={this.props.last_data_percentage}
            setStateInKpiSection={this.props.setStateInKpiSection}
            kpi_charts={this.props.kpi_charts}
          />
        }
        {
          this.state.advancedOptionsOpened && this.props.chartTypesChosen && !this.props.isNumberKpi() && !this.props.isFunnelKpi() &&
          <TimeframeOptions
            shown={this.props.shown}
            period_step={this.props.period_step}
            date_options={this.props.date_options}
            period_start={this.props.period_start}
            period_end={this.props.period_end}
            invalidDates={this.props.invalidDates}
            setStateInKpiSection={this.props.setStateInKpiSection}
          />
        }
      </div>
    );
  }
}

export default AdvancedChartOptions;
