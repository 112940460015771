import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CompanyRow from './CompanyRow';


class RowContainer extends Component {
  static propTypes = {
    records: PropTypes.array.isRequired,
    updateTopState: PropTypes.func.isRequired,
  }

  state = {
    alphabetic: false,
  }

  alphabeticSort = (a, b) => {
    const { alphabetic } = this.state;
    if (!alphabetic) {
      return null;
    }
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return alphabetic === 'ASC' ? -1 : 1;
    }
    if (nameA > nameB) {
      return alphabetic === 'ASC' ? 1 : -1;
    }
    return 0;
  }

  displayNameSort = () => {
    const { alphabetic } = this.state;

    if (!alphabetic) {
      return (
        <div className="cursor-pointer text-light-gray" onClick={() => { this.setState({ alphabetic: 'ASC' }); }}>Name</div>
      );
    }
    if (alphabetic === 'ASC') {
      return (
        <div className="cursor-pointer" onClick={() => this.setState({ alphabetic: 'DESC' })}>
          Name
          <i className="ml1 fa fa-caret-up" />
        </div>
      );
    }
    return (
      <div className="cursor-pointer" onClick={() => this.setState({ alphabetic: false })}>
        Name
        <i className="ml1 fa fa-caret-down" />
      </div>
    );
  }

  render() {
    const { records, updateTopState } = this.props;

    return (
      <table className="bs-around mt3 col-12">
        <thead className="bg-alabaster">
          <tr>
            <td />
            <td className="bold text-upper text-gray">
              {this.displayNameSort()}
            </td>
            <td className="bold text-upper text-gray">
              Contact
            </td>
            <td className="bold text-upper text-gray">
              Following
            </td>
            <td className="bold text-upper text-gray">
              Status
            </td>
            <td />
            { records[0] !== undefined && records[0].manage_url.length > 0 ? <td className="text-gray" /> : null }
          </tr>
        </thead>
        <tbody className="bg-white">
          {records.slice(0).sort(this.alphabeticSort).map(record => (
            <CompanyRow record={record} key={record.id} updateTopState={updateTopState} />
          ))}
        </tbody>
      </table>
    );
  }
}

export default RowContainer;
