import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CompanyCardAdmin from './CompanyCardAdmin';


class CompanyCard extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
    updateTopState: PropTypes.func.isRequired,
  }

  state = {
    updatingFollow: false,
  }

  admin = () => {
    const { record, updateTopState } = this.props;
    if (record.manage_url.length > 0 && !record.pending) {
      return (
        <CompanyCardAdmin
          manageUrl={record.manage_url}
          resendUrl={record.resend_url}
          updateTopState={updateTopState}
          name={record.name}
          resendable={record.requested}
        />
      );
    }
    return null;
  }

  follow = () => {
    const { record } = this.props;
    const { updatingFollow } = this.state;

    if (record.pending || record.requested) {
      return null;
    }
    if (updatingFollow) {
      return (
        <div className="position-left-top absolute text-light-green h5">
          <i className="fa fa-refresh fa-spin fa-fw" />
            &nbsp;updating...
        </div>
      );
    }
    if (!record.following) {
      return (
        <div
          className="position-left-top absolute text-light-green h5 cursor-pointer"
          href="#"
          onClick={this.triggerFollow}
        >
          <i className="fa fa-star-o" />
            &nbsp;follow
        </div>
      );
    }
    return (
      <div
        className="position-left-top absolute text-green h5 cursor-pointer"
        href="#"
        onClick={this.triggerFollow}
      >
        <i className="fa fa-star" />
          &nbsp;following
      </div>
    );
  }

  triggerFollow = (e) => {
    const { record, updateTopState } = this.props;
    e.preventDefault();
    this.setState({ updatingFollow: true });
    App.Api.patch(record.follow_url).then((data) => {
      updateTopState(data);
      this.setState({ updatingFollow: false });
      App.State.setFlash({ name: 'notice', msg: 'Follow status updated!' });
    });
  }

  render() {
    const { record } = this.props;

    return (
      <div className="col-4 lg-col-6 sm-col-12 flex px2 mb3">
        <div className="bg-white card--has-hover card--has-layer bs-around-small center flex flex-auto flex-center flex-column p3 relative rounded">
          {this.follow()}
          <div className="flex-auto">
            {this.admin()}
            <i className="fa fa-briefcase fa--search-card position-left-bottom absolute text-extra-light-gray" />
            <a href={record.url}>
              <div
                className="search-card__img bg-center bg-cover bg-no-repeat border border-light-gray circle mb1 mx-auto"
                style={{ backgroundImage: `url(${record.avatar})` }}
              />
              <p className="bold h3 m0 mb1 text-outer-space">{record.name}</p>
              <p className="h5 m0 mb3 text-light-gray">{record.about}</p>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyCard;
