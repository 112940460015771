import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select from '../../Select';


class AboutYourselfInvestor extends Component {
  static propTypes = {
    userName: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    otherCategory: PropTypes.string,
    numberOfInvestments: PropTypes.string,
    numberOfInvestentsOptions: PropTypes.array.isRequired,
    stages: PropTypes.array,
    stagesOptions: PropTypes.array.isRequired,
    mainObjectives: PropTypes.array,
    mainObjectivesOptions: PropTypes.array.isRequired,
    jobFunction: PropTypes.string,
    jobFunctionOptions: PropTypes.array.isRequired,
  }

  static defaultProps = {
    numberOfInvestments: null,
    stages: null,
    mainObjectives: null,
    jobFunction: null,
    otherCategory: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      category: props.category,
      numberOfInvestments: props.numberOfInvestments,
      investorName: props.userName,
      stages: props.stages,
      mainObjectives: props.mainObjectives,
      jobFunctionState: props.jobFunction || 'partner_md_ceo',
    };
  }

  setCategory = (category) => {
    const { userName } = this.props;

    this.setState({ category, investorName: userName });
  }

  render() {
    const { numberOfInvestentsOptions, stagesOptions, mainObjectivesOptions, jobFunctionOptions, otherCategory } = this.props;
    const { investorName, category, numberOfInvestments, stages, mainObjectives, jobFunctionState } = this.state;

    const showNumberOfInvestmentsOption = option => {
      const lastOption = numberOfInvestentsOptions[numberOfInvestentsOptions.length - 1];
      return (
        <div
          key={option.value}
          className={`border hover hover-border-blue hover-text-blue cursor-pointer p1 ${lastOption.value === option.value ? '' : 'mr1'} ${numberOfInvestments === option.value ? 'border-blue text-blue' : 'border-lighter-gray text-light-gray'}`}
          style={{ width: lastOption.value === option.value ? '25%' : '18.75%' }}
          onClick={() => this.setState({ numberOfInvestments: option.value })}
        >
          {option.label}
        </div>
      );
    };

    const showOption = (option, type) => {
      const attribute = type === 'stages' ? stages : mainObjectives;

      return (
        <div
          key={option.value}
          className={`border hover hover-border-blue hover-text-blue cursor-pointer p1 mt1 ${(attribute && attribute.includes(option.value)) ? 'border-blue text-blue' : 'border-lighter-gray text-light-gray'}`}
          onClick={() => this.setState({ [type]: attribute ? (attribute.includes(option.value) ? attribute.filter(el => el !== option.value) : [...attribute, option.value]) : [option.value] })}
        >
          {option.label}
        </div>
      );
    };

    return (
      <div className="flex sm-flex-column flex-column bg-white bs-around p3 sm-p1 sm-py2 col-12 center mx-auto">
        <h2 className="m0 regular sm-h3 text-gray flex flex-justify-start">Setup your Account</h2>
        <p className="text-light-gray flex flex-justify-start text-align-left h5 lh-12 mb3 mt1">Provide some information about yourself and the organisation you are working with.</p>
        <div className="mb2">
          <div className="flex flex-column">
            <div className="flex flex-justify-start fw400 pb1">
              What type of investor are you?
            </div>
            <Select
              name="investor[category]"
              otherName="investor[other_category]"
              value={category}
              id="investor_category"
              options={[['angel', 'Angel Investor'], ['institutional_investor', 'Member of Institutional Investor (VC, etc.)'], ['accelerator', 'Member of Accelerator'], ['other', 'Other']]}
              placeholder="Select your type"
              other
              otherPlaceholder="Enter another type..."
              otherValue={otherCategory}
              required
              onChange={(e) => this.setCategory(e.target.value)}
              className="fw300 pr4 mb1 select-arrow border border-lighter-gray hover hover-border-blue col-12 block placeholder-gray h5 input-height__2"
            />
            <div className={`${category === 'angel' ? 'hide' : ''}`}>
              <div className="flex flex-justify-start fw400 pb1 mt2">
                What is your organisation called?
              </div>
              <input
                type="text"
                value={investorName}
                name="investor[name]"
                id="investor_name"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                onChange={(e) => this.setState({ investorName: e.target.value })}
                className="fw300 border border-lighter-gray mb1 pl1 hover hover-border-blue col-12 block placeholder-gray input-height__2"
              />
            </div>
            <div className={`${category === 'angel' ? 'hide' : ''}`}>
              <div className="flex flex-justify-start fw400 pb1 mt2">
                Job Function
              </div>
              {category !== 'angel' ?
                <Select
                  name="membership[job_function]"
                  value={jobFunctionState}
                  onChange={(e) => this.setState({ jobFunctionState: e.target.value })}
                  id="job_function"
                  options={jobFunctionOptions}
                  placeholder="Select your function"
                  required={category !== 'angel'}
                  className="fw300 pr4 mb1 select-arrow border border-lighter-gray hover hover-border-blue col-12 block placeholder-gray h5 input-height__2"
                />
                :
                <input type="hidden" name="membership[job_function]" value="single_investor_angel" />
              }
            </div>
            <div className="flex flex-justify-start flex-column fw400">
              <div className="flex flex-justify-start pb1 mt2">
                How many companies have you invested in?
              </div>
              <div className="flex items-center mb1">
                {numberOfInvestentsOptions.map(option => showNumberOfInvestmentsOption(option))}

                <input type="hidden" name="investor[number_of_investments]" value={numberOfInvestments} />
              </div>
            </div>
            <div className="flex flex-justify-start flex-column fw400">
              <div className="flex flex-justify-start pb1 mt2">
                In what stages do you typically invest?
              </div>
              <div className="flex flex-column mb1">
                {stagesOptions.map(option => showOption(option, 'stages'))}

                {stages ?
                  stages.map(stage => (
                    <input key={stage} type="hidden" name="investor[stages][]" multiple="multiple" value={stage} />
                  ))
                  :
                  <input type="hidden" name="investor[stages][]" value={null} />
                }

              </div>
            </div>
            <div className="flex flex-justify-start flex-column fw400">
              <div className="flex flex-justify-start pb1 mt2">
                What are your main objectives with investory?
              </div>
              <div className="flex flex-column mb1">
                {mainObjectivesOptions.map(option => showOption(option, 'mainObjectives'))}

                {mainObjectives ?
                  mainObjectives.map(objective => (
                    <input key={objective} type="hidden" name="investor[main_objectives][]" multiple="multiple" value={objective} />
                  ))
                  :
                  <input type="hidden" name="investor[main_objectives][]" value={null} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutYourselfInvestor;
