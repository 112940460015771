import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../Modal';


class FundraisingManageModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    hasPermission: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    isCustom: PropTypes.bool.isRequired,
    setCustomData: PropTypes.func.isRequired,
    setCompanyData: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.initialState = {
      custom: props.isCustom,
    };

    this.state = this.initialState;
  }

  onSelect = custom => this.setState({ custom });

  checkPermission = () => {
    const { hasPermission } = this.props;
    const { custom } = this.state;

    return (custom || hasPermission);
  }

  handleCloseModal = async () => {
    const { closeModal } = this.props;

    await closeModal();
    setTimeout(() => this.setState(this.initialState), 500);
  }

  submit = async () => {
    const {
      closeModal,
      setCustomData,
      setCompanyData,
    } = this.props;

    const { custom } = this.state;

    if (!this.checkPermission()) return;

    if (custom) {
      await setCustomData();
    } else {
      await setCompanyData();
    }
    closeModal();
  }

  render() {
    const { show, hasPermission } = this.props;
    const { custom } = this.state;

    return (
      <Modal
        show={show}
        onClickOutside={this.handleCloseModal}
      >
        <div className="flex flex-justify-between items-center semi-bold p2 border-bottom border-lighter-gray">
          <div className="h3">Manage your Fundraising</div>
          <i className="fa fa-close h5 cursor-pointer" onClick={this.handleCloseModal} />
        </div>

        <div className="fw400 px3 py2 overflow-y-auto" style={{ maxHeight: '65vh' }}>
          <div className="flex flex-justify-end">
            <div
              className={`h6 px1 py05rem cursor-pointer ${custom ? 'text-white bg-blue' : 'text-light-gray'}`}
              onClick={() => this.onSelect(true)}
            >
              Custom
            </div>
            <div
              className={`h6 px1 py05rem cursor-pointer ${custom ? 'text-light-gray' : 'text-white bg-green'}`}
              onClick={() => this.onSelect(false)}
            >
              Company
            </div>
          </div>

          <div className={`col-12 border p2 ${custom ? 'border-blue' : 'border-green'}`}>
            {custom ?
              <div>
                <span className="text-light-gray h6 lh-1 mb1">
                  * Data entered by you will be shown. You can always switch back to data provided by the company.
                </span>
              </div>
              :
              <div>
                <span className="text-light-gray h6 lh-1 mb1">
                  {hasPermission ? '* Data provided by the company will be shown.' : "You don't have access to the company's captable."}
                </span>
              </div>
            }
          </div>
        </div>

        <div className="flex flex-justify-end items-center p2 border-top border-lighter-gray">
          <div
            className="h5 text-blue mr2 cursor-pointer"
            onClick={this.handleCloseModal}
          >
            Cancel
          </div>
          <div
            className={`h5 p1 px2 rounded ${this.checkPermission() ? 'bg-blue text-white cursor-pointer' : 'bg-light-gray text-white cursor-disabled'}`}
            onClick={this.submit}
          >
            Save
          </div>
        </div>
      </Modal>
    );
  }
}

export default FundraisingManageModal;
