import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import Avatars from '../list/Avatars';
import DropdownMenu from '../DropdownMenu';
import ConfirmationUpdateModal from './company_report/ConfirmationUpdateModal';
import FactSheetSettingsModal from '../company_report/FactSheetSettingsModal';


class FactSheet extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    trackEvent: PropTypes.func,
  }

  static defaultProps = {
    trackEvent: null,
  }

  state = {
    loading: false,
    openFundraiseStatusDropdown: false,
  }

  getColClass = () => {
    const { data } = this.props;
    const { angel } = data;
    let name, modified, manager, status, settings, fundraiseStatus = null;
    manager = 'col-3';
    settings = 'col-1';
    fundraiseStatus = 'col-1';
    if (angel) {
      name = 'col-4';
      modified = 'col-3';
      status = 'col-3';
    } else {
      name = 'col-3';
      modified = 'col-2';
      status = 'col-2';
    }
    return { name, modified, manager, status, settings, fundraiseStatus };
  }

  displayAvatarAndName = () => {
    const { data, trackEvent } = this.props;
    const handleClick = async () => {
      if (trackEvent) await trackEvent(`${data.name}_fundraising_fact_sheet`);
      window.location = data.url;
    };

    return (
      <div className="col-12 flex items-center flex-justify-start py1 cursor-pointer" onClick={() => handleClick()}>
        <div className="text-gray fw400 flex flex-center">
          <span
            className="block col-3 bg-center bg-cover bg-no-repeat circle header__avatar--dash mr1"
            style={{ backgroundImage: `url( ${data.logo} )` }}
          />
          {data.real_company &&
            <div className="relative">
              <span className="real_company__notification" data-behavior="hide-bubble">
                <i className="fa fa-check-circle text-green" />
              </span>
            </div>
          }
          <div className="truncate text-gray">
            {data.name}
          </div>
        </div>
      </div>
    );
  }

  displayModified = () => {
    const { data } = this.props;

    return (
      <div className="flex items-center flex-justify-start py1">
        <span className="text-gray">{data.modified}</span>
      </div>
    );
  }

  displayStatus = () => {
    const { data } = this.props;

    const icon = this.isDraft() ? 'pencil' : 'check';
    const color = this.isDraft() ? 'light-gray' : 'green';
    return (
      <div className={`flex items-center py1 text-${color}`}>
        <i className={`fa fa-${icon}`} />
        <span className="fw400 ml1">{data.status}</span>
      </div>
    );
  }

  handlePublish = () => {
    const { data } = this.props;
    const { publish_url } = data;

    if (!this.isDraft()) return;
    this.setState({ loading: true });

    App.Api.post(publish_url).then((company_report) => {
      store.dispatch({ type: 'SET_ITEM', list: 'main', id: company_report.id, value: company_report });
      App.State.setFlash({ name: 'notice', msg: 'Fact Sheet published!' });
      this.setState({ loading: false });
    }, () => {
      App.State.setFlash({ name: 'alert', msg: 'Sorry, could not publish due to an error.' })
      this.setState({ loading: false });
    });
  }

  handleReset = (chosenSections) => {
    const { data } = this.props;
    const { reset_url } = data;

    this.setState({ loading: true });

    App.Api.post(reset_url, { sections: chosenSections }).then((company_report) => {
      store.dispatch({ type: 'SET_ITEM', list: 'main', id: company_report.id, value: company_report });
      App.State.setFlash({ name: 'notice', msg: 'Fact Sheet successfully updated!' });
      this.setState({ loading: false });
    }, () => {
      App.State.setFlash({ name: 'alert', msg: 'Sorry, could not reset due to an error.' })
      this.setState({ loading: false });
    });
  }

  handleUnpublish = () => {
    const { data } = this.props;
    const { unpublish_url } = data;

    if (this.isDraft()) return;
    this.setState({ loading: true });

    App.Api.post(unpublish_url).then((company_report) => {
      store.dispatch({ type: 'SET_ITEM', list: 'main', id: company_report.id, value: company_report });
      App.State.setFlash({ name: 'notice', msg: 'Fact Sheet unpublished!' });
      this.setState({ loading: false });
    }, () => {
      App.State.setFlash({ name: 'alert', msg: 'Sorry, could not unpublish due to an error.' })
      this.setState({ loading: false });
    });
  }

  isDraft = () => {
    const { data } = this.props;
    return data.status === 'Unpublished';
  }

  publishTextColor = () => {
    const color = this.isDraft() ? 'gray' : 'light-gray';
    return `text-${color}`;
  }

  saveFundraiseStatus = (value) => {
    const { data } = this.props;
    const { settings_url } = data;

    App.Api.post(settings_url, { company_report: { fundraise_status_id: value } }).then((company_report) => {
      store.dispatch({ type: 'SET_ITEM', list: 'main', id: company_report.id, value: company_report });
    });
  }

  selectFundraiseStatus = () => {
    const { data } = this.props;
    const { openFundraiseStatusDropdown } = this.state;

    const options = data.fundraise_status_options.map((option) => (
      <li key={`${data.id}_${option.id}`} className="p1">
        <div
          className="block hover hover-bs-light-white border border-alto cursor-pointer rounded"
          onClick={(e) => { e.preventDefault(); this.saveFundraiseStatus(option.id); }}
          style={{ height: '30px', width: '30px', backgroundColor: `#${option.color_hex}` }}
          data-balloon={option.name}
          data-balloon-pos="down"
          data-balloon-length="xsmall"
        />
      </li>
    ));
    const fundraiseStatusColor = data.fundraise_status ? data.fundraise_status.color_hex : '';
    return (
      <div className="">
        <div className="flex flex-row flex-center cursor-pointer" href="#" onClick={() => { this.setState({ openFundraiseStatusDropdown: !openFundraiseStatusDropdown }); }}>
          <span
            className="flex flex-justify-center border border-lighter-gray rounded"
            style={{ height: '25px', width: '25px', backgroundColor: `#${fundraiseStatusColor}` }}
            data-balloon={data.fundraise_status ? data.fundraise_status.name : null}
            data-balloon-pos="down"
            data-balloon-length="xsmall"
          />
          <div className="pl1 relative">
            <i className="h3 link-hover--blue fa fa-caret-down text-light-gray" aria-hidden="true" />
            <div className={`dropdown dropdown--center z2 absolute bg-white border border-alto bs-dropdown left-50 transform-horizontal-center z1 ${openFundraiseStatusDropdown ? '' : 'hidden'} py1`}>
              <ul className="flex flex-justify-center m0 fs18 mw200">
                {options}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  unPublishTextColor = () => {
    const color = this.isDraft() ? 'light-gray' : 'gray';
    return `text-${color}`;
  }

  renderSettings = () => {
    const { data } = this.props;
    const { loading } = this.state;
    return (
      <DropdownMenu
        setFalseWhenClickingInside
        faIcon={loading ? 'refresh fa-spin' : 'cog'}
      >
        <li>
          <div className={`flex items-center hover ${this.isDraft() ? 'hover-bg-wild-sand' : 'cursor-default'} hover-text-blue`}>
            {
              this.isDraft() ?
                <FactSheetSettingsModal
                  html="<span class='block bold h5 lh-36 px1 text-gray nowrap'>Fact Sheet Settings</span>"
                  report={data}
                />
                :
                <span className="block bold h5 lh-36 px1 text-light-gray">Fact Sheet Settings</span>
            }
          </div>
        </li>
        <li>
          <div className={`flex flex-center hover ${this.isDraft() ? 'hover-bg-wild-sand' : 'cursor-default'} hover-text-blue`}>
            {
              this.isDraft() ?
                <ConfirmationUpdateModal
                  className="block bold h5 lh-36 px1 text-gray nowrap"
                  onClick={(chosenSections) => this.handleReset(chosenSections)}
                  html="Update Fact Sheet"
                  message="You are about to update this Fact Sheet with the latest company data.<br/>All data in the selected sections below will be replaced.<br/><br/>Replaced data <b>cannot</b> be recovered.<br/>Are you sure you want to update the selected Fact Sheet Sections?"
                />
                :
                <span className="block bold h5 lh-36 px1 text-light-gray">Update Fact Sheet</span>
            }
          </div>
        </li>
        <li>
          <div
            className={`flex items-center hover ${this.isDraft() ? 'hover-bg-wild-sand cursor-pointer' : 'cursor-default'} hover-text-blue`}
            onClick={() => this.handlePublish()}
          >
            <span className={`block bold h5 lh-36 px1 ${this.publishTextColor()}`}>Publish</span>
          </div>
        </li>
        <li>
          <div
            className={`flex items-center hover ${this.isDraft() ? 'cursor-default' : 'hover-bg-wild-sand cursor-pointer'} hover-text-blue`}
            onClick={() => this.handleUnpublish()}
          >
            <span className={`block bold h5 lh-36 px1 ${this.unPublishTextColor()}`}>Unpublish</span>
          </div>
        </li>
      </DropdownMenu>
    );
  }

  render() {
    const { data } = this.props;
    const { angel } = data;

    return (
      <div className={`flex flex-wrap px2 h5 sm-px0 relative ${data.blurred ? 'hide-data' : ''}`}>
        <div className={`${this.getColClass().name} border-alto py1 pl2 flex sm-col-6 lg-col-6 truncate`}>
          {this.displayAvatarAndName()}
        </div>

        <div className={`${this.getColClass().modified} border-alto py1 pl2 flex lg-hide`}>
          {this.displayModified()}
        </div>

        {
          !angel &&
          <div className={`${this.getColClass().manager} border-alto py1 pl2 flex items-center lg-hide`}>
            <Avatars avatars={data.investment_managers} />
          </div>
        }

        <div className={`${this.getColClass().status} border-alto py1 pl2 flex sm-col-6 truncate`}>
          {this.displayStatus()}
        </div>

        <div className={`${this.getColClass().fundraiseStatus} border-alto py1 pl2 flex items-center sm-col-6 lg-hide`}>
          {this.selectFundraiseStatus()}
        </div>

        <div className={`${this.getColClass().settings} border-alto py1 pl2 flex items-center flex-justify-end lg-hide`}>
          {this.renderSettings()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => (
  {
    trackEvent: store.get('trackEvent'),
  }
);

export default connect(mapStateToProps)(FactSheet);
