import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Editor from 'react-medium-editor';


class EditorSection extends Component {
  static propTypes = {
    section: PropTypes.object.isRequired,
    saveSection: PropTypes.func.isRequired,
  };

  shouldComponentUpdate() {
    return false;
  }

  saveSection = text => {
    const { saveSection } = this.props;
    const { section } = this.props;

    section.text = text;

    saveSection(section);
  }

  render() {
    const { section } = this.props;

    return (
      <Editor
        text={section.text}
        onChange={this.saveSection}
        options={{
          toolbar: {
            buttons:
            [
              'bold',
              'italic',
              'underline',
              {
                name: 'anchor',
                contentDefault: '<i class="fa fa-link" aria-hidden="true"></i>',
              },
              'h2',
              'h3',
              'quote',
              'unorderedlist',
            ],
          },
          imageDragging: false,
        }}
      />
    );
  }
}

export default EditorSection;
