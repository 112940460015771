import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../Modal';


class FundingRoundManageModal extends Component {
  static defaultProps = {
    companyRounds: null,
  }

  static propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    hasPermission: PropTypes.bool.isRequired,
    isCustom: PropTypes.bool.isRequired,
    companyRounds: PropTypes.array,
    investorId: PropTypes.number.isRequired,
    investmentId: PropTypes.number.isRequired,
    createFundingRound: PropTypes.func.isRequired,
    deleteFundingRounds: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.initialState = {
      custom: props.isCustom,
    };

    this.state = this.initialState;
  }

  onSelect = custom => this.setState({ custom });

  checkPermission = () => {
    const { hasPermission } = this.props;
    const { custom } = this.state;

    return (custom || hasPermission);
  }

  handleCloseModal = async () => {
    const { closeModal } = this.props;

    await closeModal();
    setTimeout(() => this.setState(this.initialState), 500);
  }

  submit = async () => {
    const {
      closeModal,
      investorId,
      investmentId,
      createFundingRound,
      deleteFundingRounds,
    } = this.props;

    const { custom } = this.state;
    const round = { name: 'Untitled round' };

    if (!this.checkPermission()) return;

    if (custom) {
      await createFundingRound(investorId, investmentId, { funding_round: round });
    } else {
      await deleteFundingRounds(investorId, investmentId);
    }
    closeModal();
  }

  renderCompanyRounds = () => {
    const { companyRounds, hasPermission } = this.props;

    if (!hasPermission) {
      return (
        <div>
          <span className="text-light-gray h6 lh-1 mb1">
            You don&#39;t have access to the company&#39;s captable.
          </span>
        </div>
      );
    }

    return (
      <div>
        <div className="text-light-gray h6 lh-1 mb2">
          * Data provided by the company will be shown. This also overwrites all the custom data you have previously entered.
        </div>
        <div className="flex py1 text-light-gray border-top border-bottom border-lighter-gray">
          <div className="col-3">Name</div>
          <div className="col-2">Raised</div>
          <div className="col-2">Pre-Money</div>
          <div className="col-2">Post-Money</div>
          <div className="col-2">Share Price</div>
          <div className="col-2"># Investors</div>
        </div>
        {companyRounds.map(r => (
          <div key={`round_${Math.random()}`} className="flex py1 border-bottom border-lighter-gray">
            <div className="col-3 truncate">{r.name}</div>
            <div className="col-2">{r.readable_money_raised}</div>
            <div className="col-2">{r.readable_pre_money}</div>
            <div className="col-2">{r.readable_post_money}</div>
            <div className="col-2">{r.readable_share_price}</div>
            <div className="col-2">{r.no_of_investors}</div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { show } = this.props;
    const { custom } = this.state;

    return (
      <Modal
        show={show}
        onClickOutside={this.handleCloseModal}
        widthClass="react_modal--800"
      >
        <div className="flex flex-justify-between items-center semi-bold p2 border-bottom border-lighter-gray">
          <div className="h3">Manage your funding rounds</div>
          <i className="fa fa-close h5 cursor-pointer" onClick={this.handleCloseModal} />
        </div>

        <div className="fw400 px3 py2 overflow-y-auto" style={{ maxHeight: '65vh' }}>
          <div className="flex flex-justify-end">
            <div
              className={`h6 px1 py05rem cursor-pointer ${custom ? 'text-white bg-blue' : 'text-light-gray'}`}
              onClick={() => this.onSelect(true)}
            >
              Custom
            </div>
            <div
              className={`h6 px1 py05rem cursor-pointer ${custom ? 'text-light-gray' : 'text-white bg-green'}`}
              onClick={() => this.onSelect(false)}
            >
              Company
            </div>
          </div>

          <div className={`col-12 border p2 ${custom ? 'border-blue' : 'border-green'}`}>
            {custom ?
              <div>
                <span className="text-light-gray h6 lh-1 mb1">
                  * Data entered by you will be shown. You can always switch back to data provided by the company.
                </span>
              </div>
              :
              this.renderCompanyRounds()
            }
          </div>
        </div>

        <div className="flex flex-justify-end items-center p2 border-top border-lighter-gray">
          <div
            className="h5 text-blue mr2 cursor-pointer"
            onClick={this.handleCloseModal}
          >
            Cancel
          </div>
          <div
            className={`h5 p1 px2 rounded ${this.checkPermission() ? 'bg-blue text-white cursor-pointer' : 'bg-light-gray text-white cursor-disabled'}`}
            onClick={this.submit}
          >
            Save
          </div>
        </div>
      </Modal>
    );
  }
}

export default FundingRoundManageModal;
