import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FundingShareManageModal from './modals/FundingShareManageModal';
import FundingShareDetailsModal from './modals/FundingShareDetailsModal';
import FundingSharesSummary from './funding_shares/FundingSharesSummary';
import FundingSharesHeader from './funding_shares/FundingSharesHeader';
import FundingShareData from './funding_shares/FundingShareData';
import FundingShareOptions from './funding_shares/FundingShareMoreOptions';

import LoadingSpinner from '../../../LoadingSpinner';
import ConfirmationModal from '../../../ConfirmationModal';
import ValidationModal from '../../../modules/ValidationModal';

import ShareClassModal from '../../../company/cap_tables/ShareClassModal';
import ConvertibleModal from '../../../company/cap_tables/ConvertibleModal';
import DropdownMenu from '../../../DropdownMenu';


class FundingShares extends Component {
  static propTypes = {
    funding: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    hasPermission: PropTypes.bool.isRequired,
    isCustom: PropTypes.bool.isRequired,
    isCustomRounds: PropTypes.bool.isRequired,
    investorId: PropTypes.number.isRequired,
    showIrr: PropTypes.bool.isRequired,
    saveFundingShare: PropTypes.func.isRequired,
    createFundingShare: PropTypes.func.isRequired,
    deleteFundingShare: PropTypes.func.isRequired,
    deleteFundingShares: PropTypes.func.isRequired,
    createFundingShareClass: PropTypes.func.isRequired,
    updateFundingShareClass: PropTypes.func.isRequired,
    deleteFundingShareClass: PropTypes.func.isRequired,
    createFundingConvertible: PropTypes.func.isRequired,
    updateFundingConvertible: PropTypes.func.isRequired,
    deleteFundingConvertible: PropTypes.func.isRequired,
    copyDataFromCompany: PropTypes.func.isRequired,
    createFundingRound: PropTypes.func.isRequired,
  }

  state = {
    activeModal: null,
    currentShare: null,
    currentRow: null,
    currentShareClass: null,
    currentConvertible: null,
  }


  handleCompanyDataCopy = async (copyFrom = 'all') => {
    const {
      copyDataFromCompany,
      funding,
    } = this.props;

    if (funding.loading || funding.shares_loading) return;

    await copyDataFromCompany(copyFrom);
  }

  handleNewShareCreation = async () => {
    const {
      investorId,
      funding,
      createFundingShare,
    } = this.props;

    const today = new Date();
    const share = {
      date_of_investment: `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`,
      no_of_shares: null,
      share_options: null,
      ownership: null,
      amount: null,
      invested: null,
    };

    try {
      await createFundingShare(investorId, funding.investment_id, { funding_share: share });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
    }
  }

  renderDropdown = () => (
    <div className="btn-outline border border-alto rounded text-gray bg-black--transparent flex items-center cursor-pointer mr1">
      <DropdownMenu
        text="Add"
        textColor="white"
        textSize="h4"
        faIcon="plus"
        margin="mr1"
        setFalseWhenClickingInside
        balloonProps={{ text: 'Adds new custom Share Classes or Convertibles to the Security Type dropdown in the Your Investments table. (Note that if you add a Share Class or Covnertible type, you still need to add Transactions that use them).', position: 'right', length: 'xlarge' }}
      >
        <li>
          <div
            className="px2 hover hover-bg-wild-sand hover-text-blue text-gray fw400 cursor-pointer h5 lh-36"
            onClick={() => this.setState({ currentShareClass: null, activeModal: 'shareClassModal' })}
          >
            <span>New Share Class</span>
          </div>
        </li>
        <li>
          <div
            className="px2 hover hover-bg-wild-sand hover-text-blue text-gray fw400 cursor-pointer h5 lh-36"
            onClick={() => this.setState({ currentConvertible: null, activeModal: 'convertibleModal' })}
          >
            <span>New Convertible</span>
          </div>
        </li>
      </DropdownMenu>
    </div>
  )

  render() {
    const {
      funding,
      currentUser,
      investorId,
      showIrr,
      saveFundingShare,
      deleteFundingShare,
      deleteFundingShares,
      createFundingShareClass,
      updateFundingShareClass,
      deleteFundingShareClass,
      createFundingConvertible,
      updateFundingConvertible,
      deleteFundingConvertible,
      hasPermission,
      isCustom,
      isCustomRounds,
      createFundingRound,
    } = this.props;

    const {
      activeModal,
      currentShare,
      currentRow,
      currentShareClass,
      currentConvertible,
    } = this.state;

    const fundingShares = isCustom ? funding.shares : funding.company_shares;
    const fieldWidth = isCustom ? '80%' : '20%';
    const fieldWidthForSelect = isCustom ? '130%' : '20%';
    const fieldWidthForAssignedTo = isCustom ? '110%' : '';
    const fieldWidthForCurrentValue = isCustom ? (funding.edit ? '132%' : '123%') : '21%';
    const fieldWidthForSecuritySelect = isCustom ? (funding.edit ? '180%' : '100%') : '20%';
    const fieldWidthForCurrency = funding.edit ? '77%' : '60%';

    const loading = funding.loading || funding.shares_loading;

    const transactionsValues = () => {
      if (funding.transactions_data) {
        return funding.show_values_in_display_currency ? funding.transactions_data.values_converted : funding.transactions_data.values;
      }
      return [];
    };
    const transactionsData = { ...funding.transactions_data, values: transactionsValues() };

    return (
      <div className="text-gray px3 pb2 mb2">

        {/* Header & Control buttons */}
        <div className="flex flex-justify-between flex-wrap pt2 mb1">
          <div className="flex items-center fw400">
            Your Investment(s)
            <div
              data-balloon='This table contains all your transactions in this company. It is the basis for fund and company aggregates and performance metrics calculations (total invested, multiple, etc.).
              If you are connecting to an existing company, it initially contains the equity issued to you by the company. You can also add other transactions like exits.
              If you are creating a "custom company" on the investor side, you also need to enter the company rounds in the table further down.'
              data-balloon-pos="right"
              data-balloon-length="xlarge"
            >
              <i className="fa fa-info-circle text-blue ml1 h5" />
            </div>
            {funding.currency &&
              <span className="h5 text-light-gray ml1">({funding.show_values_in_display_currency ? currentUser.display_currency : funding.currency})</span>
            }
          </div>
          {funding.edit &&
            <div className="flex flex-wrap">
              {(funding.custom_investment || (isCustom && isCustomRounds)) &&
                <div
                  className="px1 mr1 text-white cursor-pointer border bg-green rounded sm-mt1"
                  data-balloon="This button checks the consistency of the data you have entered. If something looks odd, we'll give you a warning."
                  data-balloon-pos="down"
                  data-balloon-length="large"
                  onClick={() => this.setState({ activeModal: 'validationModal' })}
                >
                  <i className="fa fa-check-circle" />
                </div>
              }
              {!funding.custom_investment &&
                <ConfirmationModal
                  html={`<div data-balloon="This button is an aid for investors, in case you want to modify some data item entered by the company, but not enter all data from scratch.
                  It creates custom data in the tables below, based on the &quot;deltas&quot; (differences) between the rounds entered by the company. E.g. if you held 100 shares in company A after round 1, and hold 300 shares after round 2, the system infers 200 new shares were issued by the company to you, at the round&apos; share price.
                  After the data is copied, it remains as &quot;custom&quot; data, and the link with &quot;company&quot; data is broken. The company can&apos;t see what you entered here, and you need to enter future transactions as &quot;custom&quot; data." data-balloon-pos="down" data-balloon-length="xlarge"><i class="fa fa-copy mr1"></i><span>Copy Company Data as Custom Data</span></div>`}
                  className="px1 mr1 h6 bg-black--transparent text-white cursor-pointer rounded sm-mt1"
                  onClick={() => this.handleCompanyDataCopy()}
                  message="<b>Please note copying company data as custom data will delete all existing custom data. Are you sure you want to continue?"
                  width="auto"
                />
              }
              {isCustom &&
                <ConfirmationModal
                  html='<div data-balloon="Recalculate FD% ownership and current shares value columns, as well as the funding summary fields below, based on data from the latest round or event (table below)." data-balloon-pos="down" data-balloon-length="large"><i class="fa fa-copy mr1"></i><span>Recalculate Values</span></div>'
                  className="px1 mr1 h6 bg-black--transparent text-white cursor-pointer rounded sm-mt1"
                  onClick={() => this.handleCompanyDataCopy('recalculate_share_fields')}
                  message="<b>Please note that recalculating values will delete existing data for FD % ownership and Current Value of Investment. Company Funding Summary section bellow will also have custom values recalculated, if any."
                  width="auto"
                />
              }
              {isCustom &&
                <div
                  className="px1 mr1 bg-black--transparent h6 text-white z3 cursor-pointer rounded sm-mt1"
                  data-balloon='Adds new transactions to the "your investments" table.'
                  data-balloon-pos="down"
                  data-balloon-length="large"
                  onClick={this.handleNewShareCreation}
                >
                  <i className="fa fa-plus mr1" />Add
                </div>
              }
              {!funding.custom_investment &&
                <div
                  className="px1 h6 bg-black--transparent text-white cursor-pointer rounded sm-mt1"
                  onClick={() => this.setState({ activeModal: 'manageModal' })}
                >
                  <i className="fa fa-cog mr1" />Manage
                </div>
              }
            </div>
          }
        </div>
        {funding.edit &&
          <div className="col-12 flex flex-wrap pt1">
            <span className="text-light-gray h5 lh-12">
              Note: If you add custom funding data, it will not be visible/accessible by the company. To learn more about company vs. custom data,
              <a href="http://docs.investory.io/en/articles/6860231-company-custom-data-management" target="_blank" rel="noopener noreferrer"> click here.</a>
            </span>
          </div>
        }

        {/* Summary */}
        <FundingSharesSummary
          totalOwnership={funding.total_ownership.readable_value}
          totalShares={funding.total_shares.readable_value}
          totalInvested={funding.total_invested[funding.show_values_in_display_currency ? 'readable_converted_display_value' : 'readable_value']}
          totalRealized={funding.total_realized[funding.show_values_in_display_currency ? 'readable_converted_display_value' : 'readable_value']}
          currentShareValueRawData={funding.current_share_value[funding.show_values_in_display_currency ? 'raw_converted_display_value' : 'raw_value']}
          currentShareValueReadableData={funding.current_share_value[funding.show_values_in_display_currency ? 'readable_converted_display_value' : 'readable_value']}
          totalInvestmentMultiple={funding.total_investment_multiple.readable}
          transactionsData={transactionsData}
          showIrr={showIrr}
          displayCurrency={currentUser.display_currency}
          hasConvertedCashflow={funding.show_values_in_display_currency ? funding.has_converted_cashflow : false}
        />

        <div className={`flex ${loading ? 'flex-justify-center' : 'flex-justify-around'}`}>
          {/* Table Rows Number */}
          {!loading &&
            <div className="mr1 bold" style={isCustom ? { paddingTop: '80px' } : { paddingTop: '55px' }}>
              {fundingShares.length > 0 &&
                fundingShares.map((share, index) => (
                  <div key={share.id} className="h5" style={{ width: '30%', paddingTop: funding.edit ? (isCustom ? '33px' : '32px') : '31px' }}>
                    {index + 1}
                  </div>
                ))
              }
            </div>
          }

          <div
            className={`${!loading ? 'overflow-x-auto mr1 pb2' : ''}`}
            style={isCustom ? { minHeight: '16em' } : { minHeight: '15em' }}
            id="transactionsOverflow"
          >
            {/* Table Header */}
            <div style={{ width: loading ? '100%' : isCustom ? '150em' : '100em' }}>
              {!loading &&
                <FundingSharesHeader
                  edit={funding.edit}
                  isCustom={isCustom}
                  fieldWidth={fieldWidth}
                  fieldWidthForSelect={fieldWidthForSelect}
                  fieldWidthForAssignedTo={fieldWidthForAssignedTo}
                  fieldWidthForCurrentValue={fieldWidthForCurrentValue}
                  fieldWidthForSecuritySelect={fieldWidthForSecuritySelect}
                  fieldWidthForCurrency={fieldWidthForCurrency}
                  renderSecurityDropdown={this.renderDropdown}
                  currency={funding.currency}
                />
              }

              {/* Table Data */}
              {loading ?
                <LoadingSpinner show={loading} type="fit" height="300px" background="white" />
                :
                fundingShares.length ?
                  fundingShares.map(share => (
                    <FundingShareData
                      key={share.id}
                      share={share}
                      funding={funding}
                      isCustom={isCustom}
                      fieldWidth={fieldWidth}
                      fieldWidthForSelect={fieldWidthForSelect}
                      fieldWidthForCurrentValue={fieldWidthForCurrentValue}
                      fieldWidthForAssignedTo={fieldWidthForAssignedTo}
                      fieldWidthForSecuritySelect={fieldWidthForSecuritySelect}
                      fieldWidthForCurrency={fieldWidthForCurrency}
                      saveFundingShare={saveFundingShare}
                      createFundingRound={createFundingRound}
                    />
                  ))
                  :
                  <div className="center pt3 text-light-gray px2">{`No ${isCustom ? '' : 'Company'} Shares added yet...`}</div>
              }
            </div>
          </div>

          {/* More details && Remove Share */}
          {!loading &&
            <div style={isCustom ? { paddingTop: '80px' } : { paddingTop: '55px' }}>
              {fundingShares.length > 0 &&
                fundingShares.map((share, index) => (
                  <FundingShareOptions
                    key={share.id}
                    share={share}
                    isCustom={isCustom}
                    edit={funding.edit}
                    updateActiveModal={(newState) => this.setState({ ...newState, currentRow: index + 1 })}
                    deleteFundingShare={() => deleteFundingShare(investorId, funding.investment_id, share.id)}
                  />
                ))
              }
            </div>
          }
        </div>

        {/* Modals */}

        {!funding.custom_investment && activeModal === 'manageModal' &&
          <FundingShareManageModal
            show={activeModal === 'manageModal'}
            hasPermission={hasPermission}
            closeModal={() => this.setState({ activeModal: null })}
            isCustom={isCustom}
            companyShares={funding.company_shares}
            investorId={investorId}
            investmentId={funding.investment_id}
            createFundingShare={this.handleNewShareCreation}
            deleteFundingShares={deleteFundingShares}
          />
        }
        <FundingShareDetailsModal
          show={activeModal === 'detailsModal'}
          currentShare={currentShare}
          currentRow={currentRow}
          isCustom={isCustom}
          closeModal={() => this.setState({ activeModal: null })}
          saveFundingShare={saveFundingShare}
          edit={funding.edit}
        />
        <ShareClassModal
          shareClass={currentShareClass}
          show={activeModal === 'shareClassModal'}
          closeModal={() => this.setState({ activeModal: null, currentShareClass: null })}
          createShareClass={createFundingShareClass}
          updateShareClass={currentShareClass ? (params) => updateFundingShareClass(currentShareClass.id, params) : null}
          deleteShareClass={currentShareClass ? () => deleteFundingShareClass(currentShareClass.id) : null}
          canEdit={funding.edit && isCustom}
          successMessage="The new Share Class was added to the Security Type options below."
        />
        <ConvertibleModal
          convertible={currentConvertible}
          show={activeModal === 'convertibleModal'}
          closeModal={() => this.setState({ activeModal: null, currentConvertible: null })}
          createConvertible={createFundingConvertible}
          updateConvertible={currentConvertible ? (params) => updateFundingConvertible(currentConvertible.id, params) : null}
          deleteConvertible={currentConvertible ? () => deleteFundingConvertible(currentConvertible.id) : null}
          canEdit={funding.edit && isCustom}
          successMessage="The new Convertible was added to the Security Type options below."
        />
        <ValidationModal
          show={activeModal === 'validationModal'}
          closeModal={() => this.setState({ activeModal: null })}
          elementsId={fundingShares.map(share => share.id)}
          validationUrl={`/investor/${investorId}/investments/${funding.investment_id}/funding/validate_funding_sections`}
        />
      </div>
    );
  }
}

export default FundingShares;
