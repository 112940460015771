import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UpgradeAccountModal from '../../modals/UpgradeAccountModal';
import CreateFundModal from '../../investor/home/CreateFundModal';


class FundAddButton extends Component {
  static propTypes = {
    data: PropTypes.string,
    buttonText: PropTypes.string,
    buttonClass: PropTypes.string,
    showAddIcon: PropTypes.bool,
    createFundFunction: PropTypes.func,
    planAllowInvite: PropTypes.bool.isRequired,
    // html: PropTypes.bool,
  }

  static defaultProps = {
    data: null,
    buttonText: 'Add new Fund',
    buttonClass: 'col-12 center h3 bg-gallery py3 text-medium-gray bold cursor-pointer rounded',
    showAddIcon: true,
    createFundFunction: null,
    // html: false,
  }

  state = {
    showCreateFundModal: false,
    showUpgradeAccountModal: false,
  }

  createFund = async (params) => {
    const { data, createFundFunction } = this.props;

    if (createFundFunction) {
      try {
        await createFundFunction(params);
      } catch (err) {
        App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an unknown error happened.' });
      }
    } else {
      App.Api.post(data, params).then((res) => {
        if (res.success === false) {
          return App.State.setFlash({ name: res.flash.name, msg: res.flash.msg });
        }
        // if (!html) {
        //   this.setState({ showCreateFundModal: false });
        //   store.dispatch({ type: 'ADD_ITEM', list: 'main', id: data.id, value: data });
        // }
      }).catch(err => {
        this.setState({ showCreateFundModal: false });
        App.State.setFlash({ name: 'alert', msg: (err.responseJSON && err.responseJSON.errors) || 'Sorry, an unknow error happened.' });
      });
    }
  }

  handleAddFund = () => {
    const { planAllowInvite } = this.props;

    if (planAllowInvite) {
      this.setState({ showCreateFundModal: true });
    } else {
      this.setState({ showUpgradeAccountModal: true });
    }
  }

  render() {
    const { buttonText, buttonClass, showAddIcon } = this.props;
    const { showCreateFundModal, showUpgradeAccountModal } = this.state;

    return (
      <div className="flex">
        <div
          className={`${buttonClass} cursor-pointer`}
          onClick={this.handleAddFund}
          style={{ width: '100%' }}
        >
          {showAddIcon ? <i className="fa fa-plus mr1" /> : ''}{buttonText}
        </div>

        <CreateFundModal
          show={showCreateFundModal}
          closeModal={() => this.setState({ showCreateFundModal: false })}
          createFund={this.createFund}
        />

        <UpgradeAccountModal
          show={showUpgradeAccountModal}
          closeModal={() => this.setState({ showUpgradeAccountModal: false })}
          paywallDescription="Paywall_Portfolio"
        />
      </div>

    );
  }
}

export default FundAddButton;
