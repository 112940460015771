import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Textarea from '../Textarea';

import Contact from './modules/Contact';
import Kpi from './modules/Kpi';
import Investor from './modules/Investor';
import TeamMember from './modules/TeamMember';


class ReportColumn extends Component {
  static propTypes = {
    col: PropTypes.object.isRequired,
    edit: PropTypes.bool.isRequired,
    investorId: PropTypes.number.isRequired,
    updateReportColumn: PropTypes.func.isRequired,
    titleColor: PropTypes.string.isRequired,
    valueColor: PropTypes.string.isRequired,
  }

  components = {
    Contact,
    Kpi,
    Investor,
    TeamMember,
  }

  constructor(props) {
    super(props);

    this.state = {
      value: props.col.value || '',
    };
  }

  colSpacing = col => {
    if (col.width === '4' && col.border) {
      return col.position === '3' ? 'sm-mr0' : 'mr3 sm-mr0';
    }
    if (col.width === '3' && col.border) {
      return col.position === '4' ? 'sm-mr0' : 'mr2 sm-mr0';
    }
    return '';
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  inputWidth = col => {
    if (col.display === 'inline') {
      if ((col.title === 'Total Raised') || (col.title === 'Funding Rounds') || (col.title === 'Co-Investors')) {
        return '40%';
      }
      return '70%';
    }

    switch (col.width) {
      case 12: return '100%';
      case 4: return '65%';
      default: return '80%';
    }
  }

  saveChange = id => {
    const { investorId, updateReportColumn } = this.props;

    updateReportColumn(investorId, id, this.state);
  }

  render() {
    const { col, edit, titleColor, valueColor } = this.props;
    const { value } = this.state;
    const colSpacing = this.colSpacing(col);

    const container = classNames(`py1 col-${col.width} flex ${colSpacing}`, {
      'flex-center': col.display === 'inline',
      'px2 mt2': col.border,
      'sm-col-6': col.width === '3',
      'sm-col-12': col.width === '4',
      'flex-wrap': col.display !== 'inline',
      hidden: !edit && !col.title && !col.value,
    });
    const titleClasses = classNames('m0', 'text-light-gray', { 'flex-auto': col.display === 'inline' });
    const valueClasses = classNames('m0 fw400 lh-15', {
      'right-align h3': (col.border && col.display === 'inline'),
      h3: ((col.title === 'Seeking') || (col.title === 'Pre-Money') || (col.title === 'Planned Closing') || (col.title === 'Closing Date') || (col.title === 'Round Type') || (col.title === 'Total Raised') || (col.title === 'Funding Rounds') || (col.title === 'Latest Valuation') || (col.title === 'Latest Funding')),
      'border border-lighter-gray bg-none hover hover-border-blue': edit,
      'bg-none p0 border-transparent my1': !edit,
    });

    if (col.component) {
      const Module = this.components[col.component];
      return <Module {...this.props} />;
    }

    return (
      <div
        key={`col_${col.id}`}
        className={container}
        style={{ border: `1px solid ${col.border}` }}
        data-balloon={edit ? col.tip : null}
        data-balloon-pos="down"
        data-balloon-length="fit"
      >
        {col.title && (<p className={titleClasses} style={{ color: titleColor }}>{col.title}</p>)}
        {col.subtitle && (<p className="m0 h6 text-gray">{col.subtitle}</p>)}
        {(col.title !== 'Other connected investors' && col.title !== 'Major Co-Investors') &&
          <Textarea
            className={valueClasses}
            style={{ width: this.inputWidth(col), resize: 'none', color: valueColor, display: (!edit && !value) ? 'none' : '' }}
            name="value"
            value={value}
            onChange={e => this.handleChange(e)}
            onBlur={() => this.saveChange(col.id)}
            disabled={!edit}
          />
        }
      </div>
    );
  }
}

export default ReportColumn;
