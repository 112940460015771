import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import { defaultSelectStyles, defaultSelectTheme } from '../../../selects/SelectStyles';


class Show extends Component {
  static propTypes = {
    kpiOptions: PropTypes.array.isRequired,
    multiKpis: PropTypes.bool.isRequired,
    chosenKpi: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.array,
    ]),
    maxMultiOptionsLength: PropTypes.number.isRequired,
    source: PropTypes.bool.isRequired,
    custom: PropTypes.bool,
    handleKpiSelection: PropTypes.func.isRequired,
    blurred: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    chosenKpi: null,
    custom: null,
  }

  toBeRemoved = () => {
    return true;
  }

  render() {
    const {
      kpiOptions,
      multiKpis,
      chosenKpi,
      maxMultiOptionsLength,
      source,
      custom,
      handleKpiSelection,
      blurred,
    } = this.props;

    return (
      <div className={`flex items-center h5 ${multiKpis ? 'col-8' : 'col-7'} ${blurred ? 'hide-data' : ''}`}>
        <span className="mx2">Show</span>
        {multiKpis ?
          <ReactSelect
            className="col-10"
            defaultValue={kpiOptions[0]}
            options={chosenKpi && chosenKpi.length === maxMultiOptionsLength ? [] : kpiOptions}
            noOptionsMessage={() => (chosenKpi && chosenKpi.length === 2 && `Currently you can chart a maximum of ${maxMultiOptionsLength} KPIs`)}
            isClearable={false}
            onChange={(selectedOptions) => handleKpiSelection(selectedOptions)}
            styles={defaultSelectStyles}
            theme={defaultSelectTheme}
            isMulti
          />
          :
          <ReactSelect
            className={`col-9 ${source ? custom ? 'desktop__notification--changes-blue' : 'desktop__notification--changes-green' : ''}`}
            defaultValue={kpiOptions.filter(option => option.value === chosenKpi)}
            value={kpiOptions.filter(option => option.value === chosenKpi)}
            options={kpiOptions}
            isClearable={false}
            onChange={(selectedOption) => handleKpiSelection(selectedOption.value)}
            styles={defaultSelectStyles}
            theme={defaultSelectTheme}
          />
        }
      </div>
    );
  }
}

export default Show;
