function sectionFiles(state = {}, action) {
  switch (action.type) {
    case 'ADD_FILE':
      return {
        ...state,
        data_room_files: [...state.data_room_files, action.file],
      };

    case 'SET_FILE':
      return {
        ...state,
        data_room_files: state.data_room_files.map(f => {
          if (f.id === action.file.id) return action.file;
          return f;
        }),
      };

    case 'SET_FILES':
      return {
        ...state,
        data_room_files: action.files,
      };

    case 'REMOVE_FILE':
      return {
        ...state,
        data_room_files: state.data_room_files.filter(f => f.id !== action.file.id),
      };

    default: return state;
  }
}

const dataRoom = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_SECTION':
      return {
        ...state,
        data_room_sections: [...state.data_room_sections, action.section],
      };

    case 'UPDATE_SECTION':
      return {
        ...state,
        data_room_sections: state.data_room_sections.map(s => {
          if (s.id === action.section.id) return action.section;
          return s;
        }),
      };

    case 'UPDATE_SECTIONS':
      return {
        ...state,
        data_room_sections: action.sections.map(section => {
          const sec = state.data_room_sections.find(s => s.id === section.id);

          return {
            ...section,
            data_room_files: sec.data_room_files,
          };
        }),
      };

    case 'REMOVE_SECTION':
      return {
        ...state,
        data_room_sections: state.data_room_sections.filter(s => s.id !== action.section.id),
      };

    case 'ADD_FILE':
      return {
        ...state,
        data_room_sections: state.data_room_sections.map(section => {
          if (section.id === action.file.data_room_section_id) return sectionFiles(section, action);
          return section;
        }),
      };

    case 'SET_FILE':
      return {
        ...state,
        data_room_sections: state.data_room_sections.map(section => {
          if (section.id === action.file.data_room_section_id) return sectionFiles(section, action);
          return section;
        }),
      };

    case 'SET_FILES':
      return {
        ...state,
        data_room_sections: state.data_room_sections.map(section => {
          const file = action.files[0];
          if (section.id === file.data_room_section_id) return sectionFiles(section, action);
          return section;
        }),
      };

    case 'REMOVE_FILE':
      return {
        ...state,
        data_room_sections: state.data_room_sections.map(section => sectionFiles(section, action)),
      };

    default: return state;
  }
};

export default dataRoom;
