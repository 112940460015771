import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';


class PhotoSection extends Component {
  static propTypes = {
    section: PropTypes.object,
    photoUpload: PropTypes.func,
    uploadGif: PropTypes.string,
  }

  state = {
    uploading: false,
  }

  onDrop = (files) => {
    const { photoUpload, section } = this.props;

    this.setState({ uploading: true });
    photoUpload(files, section, () => { this.setState({ uploading: false }); });
  }

  photo = () => {
    const { section } = this.props;

    return (<img className="block mx-auto p1" src={section.photo} alt="Section..." />);
  }

  uploader = () => {
    const { uploading } = this.state;
    const { uploadGif } = this.props;

    if (uploading) {
      return (
        <div className="dropzone-placeholder border border-alto">
          <img
            src={uploadGif}
            style={{ opacity: 0.2 }}
            width="200"
            alt="Uploading..."
          />
        </div>
      );
    }
    return (
      <Dropzone
        style={{}}
        onDrop={this.onDrop}
        accept="image/*"
        multiple={false}
      >
        <div className="dropzone-placeholder border border-alto cursor-pointer">
          <i className="fa fa-file-image-o" />
          <p className="dropZone__text h1">Click or drop a photo</p>
        </div>
      </Dropzone>
    );
  }

  render() {
    const { section } = this.props;
    return (
      <div>
        {section.photo.length > 0 ? this.photo() : this.uploader()}
      </div>
    );
  }
}

export default PhotoSection;
