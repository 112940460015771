import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../Modal';
import TextInput from '../../../TextInput';
import Textarea from '../../../Textarea';
import CheckBox from '../../../CheckBox';

class CustomFieldsModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    customFields: PropTypes.array.isRequired,
    saveFields: PropTypes.func.isRequired,
    cloneField: PropTypes.func.isRequired,
    deleteFields: PropTypes.func.isRequired,
  };

  state = {
    fields: this.props.customFields,
  }

  componentWillMount() {
    const { customFields } = this.props;
    const fields = customFields.reduce((state, f) => ({
      ...state,
      [f.id]: {
        name: f.name,
        text: f.text,
        deletable: f.deletable || true,
        is_category: f.is_category || false,
      },
    }), {});

    this.setState({ fields });
  }

  getFieldInState = (id) => {
    const { fields } = this.state;

    return fields[id] || { name: '' };
  }

  cloneField = id => {
    const { cloneField } = this.props;
    const { fields } = this.state;
    const { name, is_category } = fields[id];

    const params = {
      investment_field: {
        values: { name, is_category },
      },
    };
    cloneField(id, params)
      .then(() => App.State.setFlash({ name: 'notice', msg: 'Field was cloned to all investments.' }))
      .catch(err => App.State.setFlash({ name: 'alert', msg: err.response.data.errors }));
  }

  createNewField = async () => {
    const { saveFields } = this.props;
    const { fields } = this.state;
    try {
      await saveFields('', { investment_field: { values: [{ name: 'Untitled Field', text: '' }] } });
      const { customFields } = this.props;

      const newField = customFields[customFields.length - 1];
      this.setState({ fields: { ...fields, [newField.id]: { name: newField.name, text: newField.text, deletable: newField.deletable, is_category: newField.is_category } } });
    } catch (err) {
      console.log(err);
    }
  }

  handleChange = (id, e) => {
    const { name, value } = e.target;

    this.setState(state => ({
      fields: {
        ...state.fields,
        [id]: {
          ...state.fields[id],
          [name]: value,
        },
      },
    }));
  }

  removeField = id => {
    const { deleteFields } = this.props;
    const { fields } = this.state;
    delete fields[id];

    const params = {
      investment_field: {
        ids: [id],
      },
    };

    deleteFields(params)
      .then(() => this.setState({ fields }))
      .catch(err => console.log(err));
  }

  submitCustomFields = () => {
    const { saveFields, closeModal } = this.props;
    const { fields } = this.state;

    const params = {
      investment_field: {
        ids: Object.keys(fields),
        values: Object.values(fields),
      },
    };

    saveFields(null, params)
      .then(() => closeModal())
      .catch(err => console.log(err.response.data));
  }

  render() {
    const {
      show,
      closeModal,
      isAdmin,
      customFields,
    } = this.props;

    return (
      <Modal
        show={show}
        onClickOutside={closeModal}
      >
        <div className="flex flex-justify-between items-center bold p2 border-bottom border-lighter-gray">
          <div className="h3">Custom Fields (Additional Info)</div>
          <i className="fa fa-close h5 cursor-pointer" onClick={closeModal} />
        </div>

        <div className="fw400 p2 overflow-y-auto" style={{ maxHeight: '65vh' }}>
          {customFields.map(f => {
            const field = this.getFieldInState(f.id);
            const fieldName = field.name && field.name.trim();

            return (
              <div key={`field_${f.id}`}>
                <div className="flex items-center">
                  <TextInput
                    className="flex-auto border-left-none border-right-none border-top-none border-bottom border-alto hover hover-border-blue mb2"
                    name="name"
                    value={f.name}
                    placeholder="Add a field title"
                    onChange={e => this.handleChange(f.id, e)}
                    disabled={!f.deletable}
                  />
                  {f.deletable &&
                    <div
                      className="ml1"
                      data-balloon="Is this a category that can be charted?"
                      data-balloon-pos="left"
                    >
                      <CheckBox
                        name="is_category"
                        onChange={() => this.handleChange(f.id, { target: { name: 'is_category', value: !this.getFieldInState(f.id).is_category } })}
                        fixedWidth
                        defaultChecked={f.is_category}
                      />
                    </div>
                  }
                  {isAdmin && fieldName !== 'Untitled Field' && fieldName !== '' &&
                    <span
                      className="ml2 cursor-pointer"
                      onClick={() => this.cloneField(f.id)}
                      data-balloon="Clone to all investments"
                      data-balloon-pos="left"
                    >
                      <i className="fa fa-copy" />
                    </span>
                  }
                  {f.deletable &&
                    <span
                      className="ml2 mr1 cursor-pointer"
                      onClick={() => this.removeField(f.id)}
                      data-balloon="Delete"
                      data-balloon-pos="down"
                    >
                      <i className="fa fa-trash" />
                    </span>
                  }
                </div>
                <Textarea
                  skipPropsUpdate
                  className="col-12 border border-alto hover hover-border-blue mb2 rounded"
                  name="text"
                  value={f.text}
                  onChange={e => this.handleChange(f.id, e)}
                />
              </div>
            );
          })}
          <div
            className="center py1 bg-lighter-gray cursor-pointer rounded"
            onClick={this.createNewField}
          >
            + Add New Custom Field
          </div>
        </div>

        <div className="flex flex-justify-end items-center p2 border-top border-lighter-gray">
          <div
            className="h5 text-blue mr2 cursor-pointer"
            onClick={closeModal}
          >
            Cancel
          </div>
          <div
            className="h5 p1 px2 cursor-pointer bg-blue text-white rounded"
            onClick={this.submitCustomFields}
          >
            Save
          </div>
        </div>
      </Modal>
    );
  }
}

export default CustomFieldsModal;
