import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Component that does something if you click outside of it
 */
export default class OutsideClick extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  /**
   * Do something if clicked on outside of element
   */
  handleClickOutside = (event) => {
    const { onClick } = this.props;

    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      onClick();
    }
  }

  render() {
    const { children } = this.props;

    return <div ref={this.setWrapperRef}>{children}</div>;
  }
}
