import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { CustomPicker, ChromePicker, TwitterPicker } from 'react-color';

import HtmlComponent from '../tab_view/HtmlComponent';
import OutsideClick from '../utils/OutsideClick';


class ColorPicker extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    leftText: PropTypes.string,
    rightText: PropTypes.string,
    icon: PropTypes.string,
    iconClass: PropTypes.string,
    type: PropTypes.string,
    closeWhenSelect: PropTypes.bool,
    url: PropTypes.string,
    redirectUrl: PropTypes.string,
    paramName: PropTypes.string,
    top: PropTypes.string,
    right: PropTypes.string,
    tooltipConfig: PropTypes.object,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    infoMessage: PropTypes.string,
  }

  static defaultProps = {
    onChange: null,
    name: null,
    leftText: null,
    rightText: null,
    icon: null,
    type: 'chrome',
    closeWhenSelect: false,
    url: null,
    redirectUrl: null,
    paramName: null,
    top: null,
    right: null,
    iconClass: '',
    tooltipConfig: null,
    color: '',
    disabled: false,
    infoMessage: 'Color changes apply to all partners!<br />Only admins can set this option.',
  }

  types = {
    chrome: ChromePicker,
    twitter: TwitterPicker,
  }

  constructor(props) {
    super(props);

    const { color } = this.props;

    this.state = {
      displayColorPicker: false,
      hex: color,
    };
  }

  handleChange = (color, event) => {
    const { onChange, url, redirectUrl, paramName, closeWhenSelect } = this.props;

    if (event.target.value === undefined || event.target.value.length >= 6) {
      this.setState({ hex: color.hex });
      if (closeWhenSelect) this.setState({ displayColorPicker: false });

      if (url) {
        axios.patch(url, { [paramName]: color.hex }).then(
          () => {
            if (redirectUrl) {
              window.location = redirectUrl;
            }
          },
        );
      }
      if (onChange) onChange(color);
    }
  }

  handleClick = () => {
    const { disabled } = this.props;
    const { displayColorPicker } = this.state;

    if (disabled) return;

    this.setState({ displayColorPicker: !displayColorPicker });
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  }


  renderColorPicker = () => {
    const { type, top, right, color, infoMessage } = this.props;
    const { displayColorPicker } = this.state;

    const ColorPickerComponent = this.types[type];

    if (displayColorPicker) {
      return (
        <OutsideClick onClick={this.handleClose}>
          <div style={{ position: 'absolute', zIndex: '2', top, right }}>
            <ColorPickerComponent
              disabled
              color={color}
              onChange={this.handleChange}
              triangle="hide"
              colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#2E75B5', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF']}
            />
            {
              type === 'twitter' && infoMessage &&
              <div className="bg-white rounded-bottom-left rounded-bottom-right bs-around mx2 px2 py1 h6 lh-12">
                <div className="flex items-center">
                  <i className="fa fa-info-circle mr1 text-light-gray" />
                  <HtmlComponent data={{ html: infoMessage, css_class: 'text-light-gray' }} />
                </div>
              </div>
            }
          </div>
        </OutsideClick>
      );
    }
    return null;
  }

  render() {
    const { icon, iconClass, leftText, rightText, name, tooltipConfig, color, disabled } = this.props;
    const { hex } = this.state;

    const pickerRender = this.renderColorPicker();

    return (
      <React.Fragment>
        <div
          className="flex flex-center"
          data-balloon={tooltipConfig ? tooltipConfig.text : null}
          data-balloon-pos={tooltipConfig ? tooltipConfig.pos : null}
          data-balloon-length={tooltipConfig ? tooltipConfig.length : null}
        >
          {
            leftText &&
            <span className={`mr1 ${disabled ? 'text-light-gray' : ''}`}>{leftText}</span>
          }
          {
            icon ?
              <div className={`cursor-pointer ${iconClass}`} onClick={this.handleClick}>
                <i className={`fa fa-${icon}`} />
              </div>
              :
              <div
                className={`${disabled ? 'cursor-disabled' : 'cursor-pointer'}`}
                style={{ paddign: '5px', background: '#fff', borderRadius: '1px', boxShadow: '0 0 0 1px rgba(0,0,0,.1)', width: 'fit-content' }}
                onClick={this.handleClick}
              >
                <div style={{ width: '20px', height: '20px', borderRadius: '2px', background: color }} />
              </div>
          }
          {
            rightText &&
            <span className={`ml1 ${disabled ? 'text-light-gray' : ''}`}>{rightText}</span>
          }
          {
            name &&
            <input name={name} value={hex} type="hidden" />
          }
        </div>
        { pickerRender }
      </React.Fragment>
    );
  }
}

export default CustomPicker(ColorPicker);
