import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ReportColumn from './ReportColumn';

const colWidth = idx => {
  switch (idx) {
    case 0: return 'col-8 sm-col-12';
    case 1: return 'col-4 sm-col-12';
    default: return 'col-12';
  }
};

const sectionClasses = (idx) => classNames({ pl0: idx === 1 });

const rowClasses = (idx, row) =>
  classNames('flex sm-flex-wrap', {
    mt2: row.border && idx !== 0,
    pl2: row.border && idx !== 0,
    'flex-start border-left border-lighter-gray sm-border-left-none pl2': idx === 1,
  });

const ReportSection = ({
  secIdx,
  section,
  edit,
  investorId,
  updateReportColumn,
  headingColor,
  subHeadingColor,
  stringColor,
}) => (
  <div className={`p2 ${sectionClasses(secIdx)} ${colWidth(secIdx)}`}>
    <p className={`m0 h3 fw400 ${secIdx === 1 && 'pl2'}`} style={{ color: headingColor }}>
      {section.title}
      {section.subtitle && <small className="text-light-gray pl1">({section.subtitle})</small>}
    </p>
    {section.report_rows.map(row => (
      <div
        key={`row_${row.id}`}
        className={rowClasses(secIdx, row)}
        style={{ border: `1px solid ${row.border}` }}
      >
        {row.report_columns.map(col => (
          <ReportColumn
            key={`col_${col.id}`}
            col={col}
            edit={edit}
            investorId={investorId}
            updateReportColumn={updateReportColumn}
            titleColor={subHeadingColor}
            valueColor={stringColor}
          />
        ))}
      </div>
    ))}
  </div>
);

ReportSection.propTypes = {
  section: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  investorId: PropTypes.number.isRequired,
  updateReportColumn: PropTypes.func.isRequired,
  headingColor: PropTypes.string.isRequired,
  subHeadingColor: PropTypes.string.isRequired,
  stringColor: PropTypes.string.isRequired,
  secIdx: PropTypes.number.isRequired,
};

export default ReportSection;
