import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../store/configureStore';
import CompanyReportContainer from '../containers/CompanyReportContainer';

// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const CompanyReport = props => (
  <Provider store={configureStore(props)}>
    <CompanyReportContainer />
  </Provider>
);

export default CompanyReport;
