import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import ColorPicker from '../selects/ColorPicker';
import { defaultSelectStyles, defaultSelectTheme } from '../selects/SelectStyles';


class FactSheetSettings extends Component {
  static propTypes = {
    kpiQuantityValue: PropTypes.number.isRequired,
    kpiQuantityOptions: PropTypes.array.isRequired,
    kpisMetricsValue: PropTypes.string.isRequired,
    kpisMetricsOptions: PropTypes.array.isRequired,
    headerBackground: PropTypes.string.isRequired,
    headingColor: PropTypes.string.isRequired,
    subHeadingColor: PropTypes.string.isRequired,
    stringColor: PropTypes.string.isRequired,
    borderColor: PropTypes.string.isRequired,
    headerTextColor: PropTypes.string.isRequired,
    closeModal: PropTypes.func,
  }

  constructor(props) {
    super(props);

    const {
      kpiQuantityValue, kpisMetricsValue, headerBackground,
      headingColor, subHeadingColor, stringColor,
      borderColor, headerTextColor,
    } = this.props;

    this.state = {
      stateKpiQuantityValue: kpiQuantityValue,
      stateKpisMetricsValue: kpisMetricsValue,
      stateKpisMetricsOptions: this.setKpisMetricsOptions(kpisMetricsValue, true),
      stateHeaderBackground: headerBackground,
      stateHeadingColor: headingColor,
      stateSubHeadingColor: subHeadingColor,
      stateStringColor: stringColor,
      stateBorderColor: borderColor,
      stateHeaderTextColor: headerTextColor,
    };
  }

  setKpisMetricsOptions = (values, initial = false) => {
    const { kpisMetricsOptions } = this.props;

    if (initial) {
      const options = kpisMetricsOptions;
      if (values.split(',').length === 3) {
        options.forEach(option => option.isDisabled = true);
      } else {
        options.forEach(option => option.isDisabled = false);
      }
      return options;
    }

    const { stateKpisMetricsOptions } = this.state;
    const options = stateKpisMetricsOptions;

    if (values.split(',').length === 3) {
      options.forEach(option => option.isDisabled = true);
    } else {
      options.forEach(option => option.isDisabled = false);
    }
    this.setState({ stateKpisMetricsOptions: options });
    return null;
  }

  handleKpiQuantityChange = (value) => {
    this.setState({ stateKpiQuantityValue: value });
  }

  handleKpisMetricsChange = (values) => {
    this.setState({ stateKpisMetricsValue: values });
    this.setKpisMetricsOptions(values);
  }

  render() {
    const { kpiQuantityValue, kpiQuantityOptions, closeModal } = this.props;
    const {
      stateKpiQuantityValue, stateKpisMetricsValue, stateKpisMetricsOptions,
      stateHeaderBackground, stateHeaderTextColor, stateHeadingColor,
      stateSubHeadingColor, stateStringColor, stateBorderColor,

    } = this.state;

    return (
      <div className="mb2">
        <div
          className="flex flex-column flex-start p2"
        >
          <span className="mb1 fw400">KPI Settings</span>
          <div className="col-4">
            <ReactSelect
              className="Select--new col-12 left-align Select--disabled is-disabled"
              defaultValue={kpiQuantityOptions.filter(option => option.value === kpiQuantityValue)}
              options={kpiQuantityOptions}
              idClearable={false}
              name="company_report[kpi_quantity]"
              onChange={(selectedOption) => this.handleKpiQuantityChange(selectedOption.value)}
              isDisabled
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
            />
          </div>

          <div className="mt3 mb1 flex col-12 flex-justify-between">
            <span className="flex fw400">Displayed KPI(s)</span>
            <small className="flex">(Requires update of each fact sheet(s))</small>
            { stateKpisMetricsValue.split(',').length < stateKpiQuantityValue &&
              <span className="text-red ml1">- Please choose {stateKpiQuantityValue} KPI(s)</span>
            }
          </div>

          <div className="col-12">
            <ReactSelect
              className="Select--new col-12 left-align"
              value={stateKpisMetricsOptions.filter(option => (stateKpisMetricsValue.includes(option.value)))}
              options={stateKpisMetricsOptions}
              isClearable={false}
              isMulti
              onChange={(selectedOptions) => this.handleKpisMetricsChange(selectedOptions.map(option => option.value).join(', '))}
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
            />
            <input type="hidden" name="company_report[kpis_metrics]" value={stateKpisMetricsValue} />
          </div>

          <div className="mt3 mb1 flex col-12 flex-justify-between">
            <span className="flex fw400 ">Color Palette</span>
            <small className="flex">(Applies to unpublished fact sheet(s))</small>
          </div>
          <div className="flex flex-column">
            <div className="flex">
              <div className="mr2">
                <ColorPicker
                  color={stateHeaderBackground}
                  onChange={color => this.setState({ stateHeaderBackground: color.hex })}
                  name="company_report[header_background_color]"
                  rightText="Header Background"
                />
              </div>

              <div className="mr2">
                <ColorPicker
                  color={stateHeaderTextColor}
                  onChange={color => this.setState({ stateHeaderTextColor: color.hex })}
                  name="company_report[header_text_color]"
                  rightText="Header Text"
                />
              </div>
            </div>

            <div className="flex mt2 mb2">
              <div className="mr2">
                <ColorPicker
                  color={stateHeadingColor}
                  onChange={color => this.setState({ stateHeadingColor: color.hex })}
                  name="company_report[heading_color]"
                  rightText="Headings"
                />
              </div>

              <div className="mr2">
                <ColorPicker
                  color={stateSubHeadingColor}
                  onChange={color => this.setState({ stateSubHeadingColor: color.hex })}
                  name="company_report[sub_heading_color]"
                  rightText="Sub-Headings"
                />
              </div>

              <div className="mr2">
                <ColorPicker
                  color={stateStringColor}
                  onChange={color => this.setState({ stateStringColor: color.hex })}
                  name="company_report[string_color]"
                  rightText="Text"
                />
              </div>
              <div className="mr2">
                <ColorPicker
                  color={stateBorderColor}
                  onChange={color => this.setState({ stateBorderColor: color.hex })}
                  name="company_report[border_color]"
                  rightText="Border"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-center flex-justify-center">
          <div
            className="modal_close_button center rounded px2 p1 mt2 bg-blue text-white cursor-pointer border-none mr2"
            href={closeModal ? null : '#'}
            style={{ width: '140px', height: '38px' }}
            onClick={closeModal}
          >
            Close
          </div>
          <input
            className="send_invite_submit center submit rounded px2 p1 mt2 bg-green text-white cursor-pointer border-none"
            name="send_invite_submit"
            style={{ width: '140px', height: '38px' }}
            type="submit"
            value="Save Settings"
          />
        </div>
      </div>
    );
  }
}

export default FactSheetSettings;
