const investor = (state = {}, action) => {
  switch (action.type) {
    case 'SUBSCRIBE_INVESTOR':
      return { ...state, customer: action.customer, subscription: action.subscription };

    case 'UNSUBSCRIBE_INVESTOR':
      return { ...state, subscription: action.subscription };

    case 'RESUBSCRIBE_INVESTOR':
      return { ...state, subscription: action.subscription };
    default: return state;
  }
};

export default investor;
