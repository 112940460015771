import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../Modal';
import CleaveNumberFormatting from '../../../../CleaveNumberFormatting';


class FundingRoundDetailsModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    currentRound: PropTypes.object,
    currentRow: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
    saveFundingRound: PropTypes.func.isRequired,
    edit: PropTypes.bool,
  };

  static defaultProps = {
    currentRound: null,
    currentRow: null,
    edit: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      ...this.buildRound({}),
    };

    this.initialState = this.state;
  }

  componentWillReceiveProps(nextProps) {
    const { currentRound } = this.props;

    if (currentRound) {
      if (currentRound.id !== nextProps.currentRound.id) {
        this.setState(this.buildRound({ ...nextProps.currentRound }));
      }
    } else {
      this.setState(this.buildRound({ ...nextProps.currentRound }));
    }
  }

  buildRound = ({
    readable_new_shares = null,
    readable_new_options = null,
    readable_new_conv_eqv_options = null,
    raw_new_shares = null,
    raw_new_options = null,
    raw_new_conv_eqv_options = null,
  }) => (
    {
      round: {
        new_shares: raw_new_shares,
        new_options: raw_new_options,
        new_conv_eqv_options: raw_new_conv_eqv_options,
        readable_new_shares,
        readable_new_options,
        readable_new_conv_eqv_options,
      },
    }
  )

  handleChange = e => {
    const { name, value, rawValue } = e.target;

    this.setState(state => ({
      ...state,
      round: {
        ...state.round,
        [name]: rawValue || value,
      },
    }));
  }

  submit = async () => {
    const {
      currentRound,
      closeModal,
      saveFundingRound,
    } = this.props;

    const { round } = this.state;

    try {
      await saveFundingRound(currentRound.id, { ...round });
      closeModal();
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
    }
  }

  render() {
    const {
      show,
      closeModal,
      edit,
      currentRow,
    } = this.props;

    const {
      error,
      round,
    } = this.state;

    const labelClasses = 'text-gray';
    const inputClasses = 'border border-lighter-gray hover hover-border-blue';

    return (
      <Modal
        show={show}
        onClickOutside={closeModal}
      >
        <div className="flex flex-justify-between items-center semi-bold p2 border-bottom border-lighter-gray">
          <div className="h3">More Details: round/other valuation event row {currentRow}</div>
          <i className="fa fa-close h5 cursor-pointer" onClick={closeModal} />
        </div>

        <div className="fw400 px2 py2 overflow-y-auto" style={{ maxHeight: '65vh' }}>
          {error && <div className="border border-red--transparent bg-red--transparent h5 mb2 p1">{error}</div>}

          <div className="flex flex-column mb-50">
            <div className="mt1">
              <div className={labelClasses}>
                <span
                  data-balloon="This is the number of new shares issued by the company in this round, to all investors."
                  data-balloon-pos="right"
                  data-balloon-length="xlarge"
                >
                  New Shares
                </span>
              </div>
              {!edit ?
                <div className={`${inputClasses} flex items-center col-6`} style={{ height: '36px', lineHeight: 'normal' }}>
                  {round.readable_new_shares}
                </div>
                :
                <CleaveNumberFormatting
                  className={inputClasses}
                  name="new_shares"
                  value={(round.new_shares || '').toString()}
                  onChange={this.handleChange}
                />
              }
            </div>

            <div className="mt1">
              <div className={labelClasses}>
                <span
                  data-balloon="This is the number of new options issued by the company in this round, to all investors/option holders."
                  data-balloon-pos="right"
                  data-balloon-length="xlarge"
                >
                  New Options
                </span>
              </div>

              {!edit ?
                <div className={`${inputClasses} flex items-center col-6`} style={{ height: '36px', lineHeight: 'normal' }}>
                  {round.readable_new_options}
                </div>
                :
                <CleaveNumberFormatting
                  className={inputClasses}
                  name="new_options"
                  value={(round.new_options || '').toString()}
                  onChange={this.handleChange}
                />
              }
            </div>

            <div className="mt1">
              <div className={labelClasses}>
                <span
                  data-balloon="This is the options-equivalent of the new convertible issued in this round. I.e. the number of options the convertible is expected to buy at conversion, assuming conversion will happen with a pre-money valuation equal to the cap, and with a pre-money number of shares equal to the FD shares after this round. (Note if outstanding covertible has go down in the round, this value is negative.)"
                  data-balloon-pos="right"
                  data-balloon-length="large"
                >
                  New Conv. Eqv. Options
                </span>
              </div>

              {!edit ?
                <div className={`${inputClasses} flex items-center col-6`} style={{ height: '36px', lineHeight: 'normal' }}>
                  {round.readable_new_conv_eqv_options}
                </div>
                :
                <CleaveNumberFormatting
                  className={inputClasses}
                  name="new_conv_eqv_options"
                  value={(round.new_conv_eqv_options || '').toString()}
                  onChange={this.handleChange}
                />
              }
            </div>
          </div>
        </div>

        {edit &&
          <div className="flex flex-justify-end items-center p2 border-top border-lighter-gray">
            <div
              className="h5 text-blue mr2 cursor-pointer"
              onClick={closeModal}
            >
              Cancel
            </div>
            <div
              className="h5 p1 px2 cursor-pointer bg-blue text-white rounded"
              onClick={this.submit}
            >
              Save
            </div>
          </div>
        }
      </Modal>
    );
  }
}

export default FundingRoundDetailsModal;
