import React from 'react';
import PropTypes from 'prop-types';

import Pending from './Pending';
import InvestmentCard from './InvestmentCard';
import InvestorCard from './InvestorCard';
import JoinInvestorCard from './JoinInvestorCard';
import CompanyCard from './CompanyCard';
import ProfilesInvestmentCard from './ProfilesInvestmentCard';
import TeamCard from './TeamCard';
import CompanySearchCard from './CompanySearchCard';
import TeamInfoCard from './TeamInfoCard';


const cardTypes = {
  InvestmentCard,
  InvestorCard,
  JoinInvestorCard,
  CompanyCard,
  ProfilesInvestmentCard,
  TeamCard,
  CompanySearchCard,
  TeamInfoCard,
};

const CardContainer = ({ records, component, updateTopState }) => {
  const Card = cardTypes[component];

  return (
    <div className="col-12 flex flex-grow flex-wrap mxn2 pt3">
      {records.map(record => (
        record.pending ?
          <Pending record={record} key={record.id} updateTopState={updateTopState} />
          :
          <Card record={record} key={record.id} updateTopState={updateTopState} />
      ))}
    </div>
  );
};

CardContainer.propTypes = {
  records: PropTypes.array.isRequired,
  component: PropTypes.string.isRequired,
  updateTopState: PropTypes.func,
};

CardContainer.defaultProps = {
  updateTopState: null,
};

export default CardContainer;
