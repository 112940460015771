import axios from 'axios';

import {
  setFunding,
  updateFundingFields,
  overwriteFundingFields,
  resetFundingFields,
  setFundingShare,
  addFundingShare,
  removeFundingShare,
  removeFundingShares,
  setFundingRound,
  addFundingRound,
  removeFundingRound,
  removeFundingRounds,
  applyCompanyData,
  applyTotals,
  setLoading,
  updateShareClass,
  removeShareClass,
  updateConvertible,
  removeConvertible,
} from '../../../actions/investor/investment/fundingActions';


const updateFunding = (investorId, investmentId, params) =>
  async dispatch => {
    const res = await axios.patch(`/investor/${investorId}/investments/${investmentId}/funding`, params);
    dispatch(setFunding(res.data));
  };

function createFields(investorId, investmentId, params) {
  return async dispatch => {
    const res = await axios.post(`/investor/${investorId}/investments/${investmentId}/funding_fields`, params);
    dispatch(overwriteFundingFields(res.data));
  };
}

function updateFields(investorId, investmentId, params) {
  return async dispatch => {
    const res = await axios.patch(`/investor/${investorId}/investments/${investmentId}/funding_fields`, params);
    dispatch(updateFundingFields(res.data));
  };
}

const createOrUpdateFundingFields = (investorId, investmentId, id, params) =>
  async dispatch => {
    if (typeof id === 'string') { // create new funding field
      await dispatch(createFields(investorId, investmentId, params));
    } else { // update existing funding field
      await dispatch(updateFields(investorId, investmentId, params));
    }
  };

const deleteFundingFields = (investorId, investmentId, params) =>
  async dispatch => {
    const res = await axios.delete(`/investor/${investorId}/investments/${investmentId}/funding_fields`, { data: params });
    dispatch(resetFundingFields(res.data.map(f => f.id)));
  };

const updateFundingShare = (investorId, investmentId, id, params) =>
  async dispatch => {
    const res = await axios.patch(`/investor/${investorId}/investments/${investmentId}/funding/funding_shares/${id}`, params);
    dispatch(setFundingShare(res.data));
  };

const createFundingShare = (investorId, investmentId, params) =>
  async dispatch => {
    const res = await axios.post(`/investor/${investorId}/investments/${investmentId}/funding/funding_shares`, params);
    dispatch(addFundingShare(res.data));
  };

const deleteFundingShare = (investorId, investmentId, id) =>
  async dispatch => {
    const res = await axios.delete(`/investor/${investorId}/investments/${investmentId}/funding/funding_shares/${id}`);
    dispatch(removeFundingShare(res.data));
  };

const deleteFundingShares = (investorId, investmentId) =>
  async dispatch => {
    await axios.delete(`/investor/${investorId}/investments/${investmentId}/funding/funding_shares/destroy_all`);
    dispatch(removeFundingShares());
  };

const updateFundingRound = (investorId, investmentId, id, params) =>
  async dispatch => {
    const res = await axios.patch(`/investor/${investorId}/investments/${investmentId}/funding/funding_rounds/${id}`, params);
    dispatch(setFundingRound(res.data));
  };

const createFundingRound = (investorId, investmentId, params) =>
  async dispatch => {
    const res = await axios.post(`/investor/${investorId}/investments/${investmentId}/funding/funding_rounds`, params);
    dispatch(addFundingRound(res.data));
  };

const deleteFundingRound = (investorId, investmentId, id) =>
  async dispatch => {
    const res = await axios.delete(`/investor/${investorId}/investments/${investmentId}/funding/funding_rounds/${id}`);
    dispatch(removeFundingRound(res.data));
  };

const deleteFundingRounds = (investorId, investmentId) =>
  async dispatch => {
    await axios.delete(`/investor/${investorId}/investments/${investmentId}/funding/funding_rounds/destroy_all`);
    dispatch(removeFundingRounds());
  };

const createFundingShareClass = (params) =>
  async (dispatch, getState) => {
    const investorId = getState().investmentHeader.investor.id;
    const investmentId = getState().investmentHeader.id;
    const res = await axios.post(`/investor/${investorId}/investments/${investmentId}/funding/funding_share_classes`, params);
    dispatch(updateShareClass(res.data));
  };

const updateFundingShareClass = (id, params) =>
  async (dispatch, getState) => {
    const investorId = getState().investmentHeader.investor.id;
    const investmentId = getState().investmentHeader.id;
    const res = await axios.patch(`/investor/${investorId}/investments/${investmentId}/funding/funding_share_classes/${id}`, params);
    dispatch(updateShareClass(res.data, id));
  };

const deleteFundingShareClass = (id) =>
  async (dispatch, getState) => {
    const investorId = getState().investmentHeader.investor.id;
    const investmentId = getState().investmentHeader.id;
    const res = await axios.delete(`/investor/${investorId}/investments/${investmentId}/funding/funding_share_classes/${id}`);
    dispatch(removeShareClass(res.data, id));
  };

const createFundingConvertible = (params) =>
  async (dispatch, getState) => {
    const investorId = getState().investmentHeader.investor.id;
    const investmentId = getState().investmentHeader.id;
    const res = await axios.post(`/investor/${investorId}/investments/${investmentId}/funding/funding_convertibles`, params);
    dispatch(updateConvertible(res.data));
  };

const updateFundingConvertible = (id, params) =>
  async (dispatch, getState) => {
    const investorId = getState().investmentHeader.investor.id;
    const investmentId = getState().investmentHeader.id;
    const res = await axios.patch(`/investor/${investorId}/investments/${investmentId}/funding/funding_convertibles/${id}`, params);
    dispatch(updateConvertible(res.data, id));
  };

const deleteFundingConvertible = (id) =>
  async (dispatch, getState) => {
    const investorId = getState().investmentHeader.investor.id;
    const investmentId = getState().investmentHeader.id;
    const res = await axios.delete(`/investor/${investorId}/investments/${investmentId}/funding/funding_convertibles/${id}`);
    dispatch(removeConvertible(res.data, id));
  };

const switchFundraisingData = (investorId, investmentId, companyData) =>
  async dispatch => {
    const params = { funding: { company_data: companyData } };
    const res = await axios.patch(`/investor/${investorId}/investments/${investmentId}/funding`, params);
    dispatch(setFunding(res.data));
  };

const copyDataFromCompany = (copyFrom) =>
  async (dispatch, getState) => {
    const investorId = getState().investmentHeader.investor.id;
    const investmentId = getState().investmentHeader.id;

    const res = await axios.post(`/investor/${investorId}/investments/${investmentId}/funding/company_data_copy`, { copy_from: copyFrom });
    dispatch(applyCompanyData(res.data));
  };

const updateFundingTotals = () =>
  async (dispatch, getState) => {
    const investorId = getState().investmentHeader.investor.id;
    const investmentId = getState().investmentHeader.id;

    const res = await axios.get(`/investor/${investorId}/investments/${investmentId}/funding/funding_totals`);
    dispatch(applyTotals(res.data));
  };

const setFundlingLoading = (isLoading, target = 'loading') =>
  async (dispatch) => {
    dispatch(setLoading(target, isLoading));
  };

export {
  updateFunding,

  createOrUpdateFundingFields,
  deleteFundingFields,

  updateFundingShare,
  createFundingShare,
  deleteFundingShare,
  deleteFundingShares,

  updateFundingRound,
  createFundingRound,
  deleteFundingRound,
  deleteFundingRounds,

  createFundingShareClass,
  updateFundingShareClass,
  deleteFundingShareClass,
  createFundingConvertible,
  updateFundingConvertible,
  deleteFundingConvertible,

  switchFundraisingData,

  copyDataFromCompany,

  updateFundingTotals,
  setFundlingLoading,
};
