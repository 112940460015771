import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckBoxTree from '../CheckBoxTree';
import FaButton from '../tab_view/FaButton';

class RecipientsList extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    groups: PropTypes.array,
    externalUsers: PropTypes.array,
    nodes: PropTypes.array.isRequired,
    checkedNodes: PropTypes.array.isRequired,
    expandedNodes: PropTypes.array.isRequired,
    addRecipients: PropTypes.func,
    removeRecipients: PropTypes.func.isRequired,
    withExternalRecipients: PropTypes.bool.isRequired,
    withContacts: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    groups: [],
    externalUsers: [],
    addRecipients: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      expanded: props.expandedNodes,
    };
  }

  handleCheck = (targetNode) => {
    const { addRecipients, removeRecipients } = this.props;

    if (targetNode.checked) {
      addRecipients(targetNode);
    } else {
      removeRecipients(targetNode);
    }
  }

  render() {
    const {
      show,
      externalUsers,
      nodes,
      checkedNodes,
      removeRecipients,
      withExternalRecipients,
      withContacts,
    } = this.props;
    const { expanded } = this.state;

    return (
      <React.Fragment>
        {
          show &&
          <div className="py1 mt1 overflow-y-scroll" style={{ maxHeight: '270px', minHeight: '80px' }}>
            {withExternalRecipients &&
              externalUsers.map(externalUser => (
                <div className="flex flex-center flex-justify-between pb1" key={`external_user_${externalUser.email}`}>
                  <div className="flex flex-center">
                    <div className="mr1">
                      <FaButton
                        faIcon="check-square"
                        color="alto"
                        size="h3"
                        tooltip={null}
                        tooltipPos="right"
                        margin="mx0"
                        disabled
                      />
                    </div>
                    {externalUser.email}
                  </div>
                  <div
                    className="flex flex-center text-red cursor-pointer"
                    onClick={() => removeRecipients(externalUser)}
                  >
                    <span className="fa fa-trash" />
                    <span className="ml1">Remove</span>
                  </div>
                </div>
              ))
            }
            {withContacts &&
            <CheckBoxTree
              nodes={nodes}
              checked={checkedNodes}
              expanded={expanded}
              onCheck={(_checkedNodes, targetNode) => this.handleCheck(targetNode)}
              onExpand={expandedNodes => this.setState({ expanded: expandedNodes })}
              onRemove={() => undefined}
              // removable not available
              // onRemove={targetNode => removeRecipients({ data: { update_recipient: { ids: targetNode.children.flatMap(child => child.recipient_ids) } } })}
              isRemovable={false}
            />
            }
          </div>
        }
      </React.Fragment>
    );
  }
}

export default RecipientsList;
