import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CompanyRowAdmin from './CompanyRowAdmin';
import Avatars from './Avatars';


class CompanyRow extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
    updateTopState: PropTypes.func.isRequired,
  }

  state = {
    updatingFollow: false,
  }

  accept = (e) => {
    const { record, updateTopState } = this.props;
    e.preventDefault();
    App.Api.patch(record.accept_url).then((data) => {
      updateTopState(data);
      App.State.setFlash({ name: 'notice', msg: 'Request has been accepted!' });
    });
  }

  admin = () => {
    const { record, updateTopState } = this.props;
    if (record.manage_url.length > 0) {
      return (
        <td className="relative py1">
          <CompanyRowAdmin
            manageUrl={record.manage_url}
            resendUrl={record.resend_url}
            updateTopState={updateTopState}
            name={record.name}
            resendable={record.requested}
          />
        </td>
      );
    }
    return (
      null
    );
  }

  displayStatus = () => {
    const { record } = this.props;
    if (record.requested) {
      return (
        <td className="py1 text-light-gray">
          Request sent.
        </td>
      );
    }
    if (record.pending) {
      return (
        <td className="py1">
          <div
            className="border border-orange button--has-hover-dark mr2 px3 text-orange cursor-pointer"
            onClick={this.accept}
            data-balloon="Accept request and add to the Portfolio"
            data-balloon-pos="up"
          >
            Accept
          </div>
          <div
            className="border border-orange button--has-hover-dark px3 text-orange cursor-pointer"
            onClick={this.reject}
            data-balloon="Reject request and DO NOT add to the Portfolio"
            data-balloon-pos="up"
          >
            Reject
          </div>
        </td>
      );
    }
    if (record.last_activity) {
      return (
        <td className="py1">
          Last active:
          <span className="pl1 text-blue">{record.last_activity}</span>
        </td>
      );
    }
    return (
      <td className="py1" />
    );
  }

  follow = () => {
    const { record } = this.props;
    const { updatingFollow } = this.state;

    if (record.pending || record.requested) {
      return null;
    }
    if (updatingFollow) {
      return (
        <div className="text-light-green cursor-pointer">
          <i className="fa fa-refresh fa-spin fa-fw" />
        </div>
      );
    }
    if (!record.following) {
      return (
        <div
          className="text-light-green cursor-pointer"
          data-balloon="Click to Follow"
          data-balloon-pos="left"
          onClick={this.triggerFollow}
        >
          <i className="fa fa-star-o fa-fw" />
        </div>
      );
    }
    return (
      <div
        className="text-green cursor-pointer"
        data-balloon="Click to Unfollow"
        data-balloon-pos="left"
        onClick={this.triggerFollow}
      >
        <i className="fa fa-star fa-fw" />
      </div>
    );
  }

  reject = (e) => {
    const { record, updateTopState } = this.props;
    e.preventDefault();
    App.Api.delete(record.manage_url).then((data) => {
      updateTopState(data);
      App.State.setFlash({ name: 'notice', msg: 'Request has been rejected!' });
    });
  }

  triggerFollow = (e) => {
    e.preventDefault();
    const { record, updateTopState } = this.props;

    this.setState({ updatingFollow: true });
    App.Api.patch(record.follow_url).then((data) => {
      updateTopState(data);
      this.setState({ updatingFollow: false });
      App.State.setFlash({ name: 'notice', msg: 'Follow status updated!' });
    });
  }

  render() {
    const { record } = this.props;
    return (
      <tr>
        <td className="py1">
          <span
            className="block bg-center bg-cover bg-no-repeat circle header__avatar--investor mr1"
            style={{ backgroundImage: `url( ${record.avatar} )` }}
          />
        </td>
        <td className="py1">
          <a className="text-gray" href={record.url}>
            {record.name}
          </a>
        </td>
        <td className="py1">
          <a className="text-medium-gray" href={record.contact_url}>
            {record.contact}
          </a>
        </td>
        <td className="py1">
          <Avatars avatars={record.followers} />
        </td>
        {this.displayStatus()}
        <td className="py1">
          {this.follow()}
        </td>
        {this.admin()}
      </tr>
    );
  }
}

export default CompanyRow;
