import React, { Component } from 'react';
import PropTypes from 'prop-types';


class Liker extends Component {
  static propTypes = {
    url: PropTypes.string,
    likers: PropTypes.string,
    liked: PropTypes.bool,
    count: PropTypes.number,
  }

  constructor(props) {
    super(props);

    this.state = {
      liked: props.liked,
      count: props.count,
    };
  }

  dislike = () => {
    const { url } = this.props;
    App.Api.submitPatch(url).then(
      () => this.setState({ liked: false, count: this.state.count - 1 }),
      err => console.log(err),
    );
  }

  like = () => {
    const { url } = this.props;
    App.Api.submitPatch(url).then(
      () => {
        App.State.setFlash({ name: 'notice', msg: 'High five sent!' });
        this.setState({ liked: true, count: this.state.count + 1 });
        Intercom('trackEvent', 'high_five_added');
      },
      err => console.log(err),
    );
  }

  render() {
    const { liked, count } = this.state;
    const { likers } = this.props;

    if (liked) {
      return (
        <a
          className="block flex flex-center flex-column cursor-pointer"
          onClick={this.dislike}
        >
          <span
            data-balloon="Remove high five"
            data-balloon-pos="right"
          >
            <i className="fa fa-fw fa-hand-paper-o text-orange" />
          </span>
          <span
            className="h6 text-light-gray"
            data-balloon={likers}
            data-balloon-pos="right"
          >{count}</span>
        </a>
      );
    }
    return (
      <a className="block flex flex-center flex-column cursor-pointer" onClick={this.like}>
        <span
          data-balloon="Give a high five"
          data-balloon-pos="right"
        >
          <i className="fa fa-fw fa-hand-paper-o text-light-gray" />
        </span>
        <span
          className="h6 text-light-gray"
          data-balloon={likers}
          data-balloon-pos="right"
        >{count || 0}</span>
      </a>
    );
  }
}

export default Liker;
