import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import Modal from '../../Modal';
import CleaveNumberFormatting from '../../CleaveNumberFormatting';
import Textarea from '../../Textarea';
import TextInput from '../../TextInput';
import CheckBox from '../../CheckBox';
import ConfirmationModal from '../../ConfirmationModal';

import { defaultSelectStyles, defaultSelectTheme } from '../../selects/SelectStyles';


class ShareClassModal extends Component {
  static propTypes = {
    shareClass: PropTypes.object,
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    capTable: PropTypes.object,
    company: PropTypes.object,
    createShareClass: PropTypes.func,
    updateShareClass: PropTypes.func,
    deleteShareClass: PropTypes.func,
    canEdit: PropTypes.bool,
    successMessage: PropTypes.string,
  }

  static defaultProps = {
    shareClass: null,
    capTable: null,
    company: null,
    createShareClass: null,
    updateShareClass: null,
    deleteShareClass: null,
    canEdit: true,
    successMessage: null,
  }

  constructor(props) {
    super(props);

    this.initialState = {
      shareClass: {
        name: null,
        preferred_share_type: false,
        voting_rights: false,
        dividends: false,
        multiplier: null,
        seniority: null,
        participating: false,
        note: null,
        data_room_file_id: null,
        liquidation_preference_type: 'none',
      },
      showInput: true,
      saving: false,
    };

    this.state = this.initialState;
  }

  componentWillReceiveProps = (nextProps) => {
    const { shareClass } = nextProps;

    if (shareClass) {
      this.setState({ shareClass, showInput: true });
    }
  }

  handleClose = () => {
    const { closeModal } = this.props;

    this.setState(this.initialState);

    closeModal();
  }

  handleTypeSelect = (type) => {
    const { canEdit } = this.props;
    const { shareClass } = this.state;

    if (!canEdit) return;

    const newState = {
      shareClass: {
        ...shareClass,
        liquidation_preference_type: type,
        multiplier: null,
        seniority: null,
        participating: false,
      },
      showInput: false,
    };

    this.setState(newState, () => this.setState({ showInput: true }));
  }

  handleChange = (name, value) => {
    const { canEdit } = this.props;

    if (!canEdit) return;

    this.setState(state => ({
      ...state,
      shareClass: {
        ...state.shareClass,
        [name]: value,
      },
    }));
  }

  submit = async () => {
    const { createShareClass, updateShareClass, shareClass: shareClassProps, canEdit, successMessage } = this.props;
    const { shareClass, saving } = this.state;

    if (saving || !canEdit) return;

    this.setState({ saving: true });

    const params = { share_class: shareClass };

    try {
      if (shareClassProps) {
        await updateShareClass(params);
      } else {
        await createShareClass(params);
        if (successMessage) App.State.setFlash({ name: 'notice', msg: successMessage });
      }
      this.handleClose();
    } catch (err) {
      const { data } = err.response;
      this.setState({ saving: false });
      App.State.setFlash({ name: 'alert', msg: data.error || data.errors });
    }
  }

  renderDeleteButton = () => {
    const { shareClass: shareClassProps, deleteShareClass, canEdit } = this.props;

    return (
      shareClassProps && deleteShareClass && canEdit &&
        <ConfirmationModal
          html={'(<i class="fa fa-times-circle"></i><span class="ml05">delete share class</span>)'}
          className="flex items-center text-red h6 cursor-pointer ml1"
          onClick={() => { deleteShareClass(); this.handleClose(); }}
          message="<b>You are about to permanently delete this custom share class. This cannot be undone.</b><br/><br/>Are you sure you want to delete?"
          width=""
        />
    );
  }

  render() {
    const {
      show,
      capTable,
      company,
      shareClass: shareClassProps,
      canEdit,
    } = this.props;

    const { shareClass, showInput, saving } = this.state;

    const liquidationPreferenceTypeOptions = [
      { value: 'none', label: 'None' },
      { value: 'exit_only', label: 'Exit Only' },
      { value: 'full_liquidation_priority', label: 'Full Liquidation Priority' },
    ];

    return (
      <Modal show={show} widthClass="react_modal--600">
        <div className="flex flex-justify-between items-center bold p2 px3 border-bottom border-lighter-gray">
          {canEdit ?
            <div className="flex h3">{
              shareClassProps ? 'Edit' : 'New'} Share Class
              {this.renderDeleteButton()}
            </div>
            :
            <div className="flex h3">
              Share Class Details
              {this.renderDeleteButton()}
            </div>
          }
          <i className="fa fa-close h5 cursor-pointer" onClick={this.handleClose} />
        </div>

        <div className="h5">
          <div className="text-gray fw400 px3 py2">
            <div className="flex flex-column">
              <span>
                Class Name
                {canEdit && <span className="text-red">*</span>}
              </span>
              <TextInput
                value={shareClass.name || ''}
                className="border border-gallery mt1 col-12"
                name="name"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                placeholder="Share class name"
                disabled={!canEdit}
              />

              <span className="pt1 h5 text-light-gray light lh-12">
                A class of shares is created to group certain rights and entitlements for different shareholders. The name of the share class should include the round it&apos;s been created on and if
                it&apos;s a preferred share. e.g. &apos;Seed Preferred&apos;, &apos;Series A Preferred&apos;. Common shares are usually not split up, as they all have the same rights.
              </span>
            </div>

            <div className="flex flex-column pt2">
              <div className="flex">
                <CheckBox
                  name="preferred_share_type"
                  onChange={() => this.handleChange('preferred_share_type', !shareClass.preferred_share_type)}
                  fixedWidth
                  manualCheck
                  checked={shareClass.preferred_share_type}
                  disabled={!canEdit}
                />
                <span className="fw400 ml2">Preferred Share Type</span>
              </div>

              <span className="pt1 h5 text-light-gray light lh-12">
                Preferred Share Types are usually issued to investors with special rights that take priority over the rights of common shareholders.
              </span>
            </div>

            <div className="flex flex-justify-between items-center">
              <div className="flex-auto border-top border-gallery my3" />
              <span className="center px2 text-medium-gray">Share Class Entitlements</span>
              <div className="flex-auto border-top border-gallery my3" />
            </div>

            <div className="flex flex-column">
              <div className="flex">
                <CheckBox
                  name="voting_rights"
                  onChange={() => this.handleChange('voting_rights', !shareClass.voting_rights)}
                  fixedWidth
                  manualCheck
                  checked={shareClass.voting_rights}
                  disabled={!canEdit}
                />
                <span className="fw400 ml2">Voting Right(s)</span>
              </div>

              <span className="pt1 h5 text-light-gray light lh-12">
                If the share class has voting right(s), any share class holder has the right to vote on who will be part of the board of directors and on matters of corporate policy, including
                decisions on issuing securities, initiating corporate actions and making substantial changes in the corporation&apos;s operations.
              </span>
            </div>

            <div className="flex flex-column pt2">
              <div className="flex">
                <CheckBox
                  name="dividends"
                  onChange={() => this.handleChange('dividends', !shareClass.dividends)}
                  fixedWidth
                  manualCheck
                  checked={shareClass.dividends}
                  disabled={!canEdit}
                />
                <span className="fw400 ml2">Dividends</span>
              </div>

              <span className="pt1 h5 text-light-gray light lh-12">
                A dividend is a payment made by the company to its shareholders, usually as a distribution of profits. Preferred shares usually carry rights to distribution of dividends.
                However, you may create a class of shares that has no rights in the company by unchecking this option.
              </span>
            </div>

            <div className="flex flex-justify-between items-center">
              <div className="flex-auto border-top border-gallery my3" />
              <span className="center px2 text-medium-gray">Liquidation Preferences</span>
              <div className="flex-auto border-top border-gallery my3" />
            </div>

            <div className="flex flex-column">
              <div className="flex flex-justify-between">
                <div className="flex flex-column col-5">
                  <div>Liquidation Preference Type</div>
                  <ReactSelect
                    className="mt1"
                    name="liquidation_preference_type"
                    value={liquidationPreferenceTypeOptions.filter(option => (option.value === shareClass.liquidation_preference_type))}
                    options={liquidationPreferenceTypeOptions}
                    onChange={selectedOption => this.handleTypeSelect(selectedOption.value)}
                    isClearable={false}
                    styles={defaultSelectStyles}
                    theme={defaultSelectTheme}
                    isDisabled={!canEdit}
                  />
                </div>

                <div className="flex flex-column col-5">
                  {shareClass.liquidation_preference_type !== 'none' &&
                    <div>
                      Multiplier
                      {canEdit && <span className="text-red">*</span>}
                    </div>
                  }
                  {showInput && shareClass.liquidation_preference_type !== 'none' &&
                    <CleaveNumberFormatting
                      value={`${shareClass.multiplier}`}
                      className="col-12 border border-gallery mt1"
                      name="multiplier"
                      onChange={e => this.handleChange(e.target.name, e.target.rawValue)}
                      unit="x"
                      alignUnit
                      disabled={!canEdit}
                    />
                  }
                </div>
              </div>

              {shareClass.liquidation_preference_type !== 'none' &&
                <div className="flex items-center flex-justify-between mt2">
                  <div className="flex flex-column col-5">
                    <span>
                      Seniority
                      {canEdit && <span className="text-red">*</span>}
                    </span>
                    {showInput &&
                      <CleaveNumberFormatting
                        value={`${shareClass.seniority}`}
                        className="col-12 border border-gallery mt1"
                        name="seniority"
                        onChange={e => this.handleChange(e.target.name, e.target.rawValue)}
                        decimal={false}
                        disabled={!canEdit}
                      />
                    }
                  </div>

                  <div className="flex col-5 pt2">
                    <CheckBox
                      name="participating"
                      onChange={() => this.handleChange('participating', !shareClass.participating)}
                      fixedWidth
                      manualCheck
                      checked={shareClass.participating}
                      disabled={!canEdit}
                    />
                    <span className="fw400 ml2">Participating</span>
                  </div>
                </div>
              }

              <span className="pt2 h5 text-light-gray light lh-12">
                A liquidation preference is one of the primary economic terms of a venture finance investment in a private company. The term describes how various investors claims on dividends or
                on other distributions are queued and covered. Liquidation preferences establishes that certain investors receive their investment money back first before other company owners
                in the event the company is sold, has a public offering, pays dividends, or has another liquidation (payout) event.
              </span>
            </div>

            <div className="flex flex-justify-between items-center">
              <div className="flex-auto border-top border-gallery my3" />
              <span className="center px2 text-medium-gray">Additional Entitlements</span>
              <div className="flex-auto border-top border-gallery my3" />
            </div>

            <div className="pt1">
              <Textarea
                value={shareClass.note || ''}
                name="note"
                className="border border-alto text-gray lh-15 col-12 h5 input-alto__focus"
                placeholder={canEdit ? 'Write a comment here...' : ''}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                disabled={!canEdit}
              />
            </div>

            {canEdit && capTable &&
              <div className="pt2 pb2">
                <span>Document for Entitlements</span>
                <ReactSelect
                  className="mt1 col-5"
                  name="data_room_file_id"
                  value={capTable.data_room_files.filter(option => (option.value === shareClass.data_room_file_id))}
                  options={capTable.data_room_files}
                  onChange={selectedOption => this.handleChange('data_room_file_id', selectedOption ? selectedOption.value : null)}
                  placeholder="Select Data Room File"
                  isClearable
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                />
              </div>
            }

            {canEdit && capTable &&
              (
                capTable.data_room_files.length === 0 &&
                  <div className="text-gray mt2">There are no documents. You can add documents <a href={`/company/${company.id}/data_rooms`}>here.</a></div>
              )
            }
          </div>
        </div>

        <div className="border-top border-gallery px3 py2 fw400 flex flex-justify-end items-center">
          {canEdit ?
            <>
              <div className="text-blue mr2 cursor-pointer" onClick={this.handleClose}>Cancel</div>
              <div className={`text-white bg-blue px3 py1 rounded ${saving ? 'cursor-disabled' : 'cursor-pointer'}`} onClick={this.submit}>{saving ? 'Saving' : 'Save'}</div>
            </>
            :
            <div className="text-blue mr2 cursor-pointer" onClick={this.handleClose}>Close</div>
          }
        </div>
      </Modal>
    );
  }
}

export default ShareClassModal;
