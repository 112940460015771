import React, { Component } from 'react';
import PropTypes from 'prop-types';

import KpiValuesImport from './KpiValuesImport';
import GoogleSheetBulkImport from './GoogleSheetBulkImport';
import DropdownMenu from '../DropdownMenu';
import Button from '../Button';

const initialComponentStep = 'choose_option';
const importFromFileStep = 'import_from_file';
const importFromGsheetStep = 'import_from_gsheet';

class AddOrImportKpis extends Component {
  static propTypes = {
    companyId: PropTypes.number.isRequired,
    newKpiUrl: PropTypes.string.isRequired,
    refreshDerivedKpisUrl: PropTypes.string,
    canRefreshGsheetsKpis: PropTypes.bool.isRequired,
    syncGsheets: PropTypes.string,
    googleSheetSource: PropTypes.object.isRequired,
  }

  static defaultProps = {
    syncGsheets: null,
    refreshDerivedKpisUrl: null,
  }

  constructor(props) {
    super(props);

    const { canRefreshGsheetsKpis } = props;

    const sheetSourceDataParsed = JSON.parse(props.googleSheetSource.data);

    this.state = {
      canRefreshGsheetsState: canRefreshGsheetsKpis,
      step: sheetSourceDataParsed.open_modal ? importFromGsheetStep : null,
      sheetSourceDataParsed,
    };

    this.initialState = { ...this.state, step: null };
  }

  componentDidMount() {
    const { canRefreshGsheetsKpis } = this.props;
    if (!canRefreshGsheetsKpis) setTimeout(() => this.setState({ canRefreshGsheetsState: true }), 30000);
  }

  closeModal = () => {
    this.setState(this.initialState);
  }

  initialStep = () => {
    this.setState({ step: initialComponentStep });
  }

  // Should change this later. Just for testing
  updateData = (values) => {
    const { sheetSourceDataParsed } = this.state;

    const newData = {
      ...sheetSourceDataParsed,
      sheet_name: values.sheet_name,
      sheet_id: values.sheet_id,
      worksheet: values.worksheet,
    };

    this.setState({ sheetSourceDataParsed: newData });
  }

  renderSyncGsheets = () => {
    const { syncGsheets } = this.props;
    const { canRefreshGsheetsState } = this.state;

    return (
      <div
        className="center h4 bg-gallery py1 rounded mb3"
        data-balloon={canRefreshGsheetsState ? 'Click here to update KPIs values from Google Sheets.' : 'Please, wait about 30 seconds to be able to try again.'}
        data-balloon-pos="left"
        data-balloon-length="xlarge"
      >
        <a
          className={`${canRefreshGsheetsState ? 'cursor-pointer' : 'opacity-35 cursor-default'} text-medium-gray bold`}
          href={canRefreshGsheetsState ? syncGsheets : '#'}
          onClick={(e) => { if (!canRefreshGsheetsState) e.preventDefault(); }}
        >
          <i className="fa fa-google mr2 text-medium-gray" aria-hidden="true" />
          Sync Gsheets
        </a>
      </div>
    );
  }

  renderRecalculateDerivedKpis = () => {
    const { refreshDerivedKpisUrl } = this.props;

    return (
      <div
        className="center h4 bg-gallery py1 rounded cursor-pointer text-medium-gray bold"
        data-balloon="Click here to refresh all your derived KPIs data values."
        data-balloon-pos="left"
        data-balloon-length="xlarge"
      >
        <a
          className="cursor-pointer text-medium-gray bold"
          data-method="patch"
          href={refreshDerivedKpisUrl}
        >
          <i className="fa fa-refresh mr2 text-medium-gray" aria-hidden="true" />
          Update-all-derived-KPIs
        </a>
      </div>
    );
  }

  render() {
    const { companyId, newKpiUrl, syncGsheets, refreshDerivedKpisUrl } = this.props;
    const { step, sheetSourceDataParsed, canRefreshGsheetsState } = this.state;

    return (
      <div className="flex flex-justify-between items-center sm-flex-column mb2">
        <a href={newKpiUrl}>
          <Button classNames="btn btn-outline btn-big border-alto bg-white sm-mb1 sm-col-12">
            <i className="fa fa-plus text-blue" />
            <span className="ml1 text-blue">Add KPI</span>
          </Button>
        </a>
        <div className="flex flex-justify-end col-6 sm-col-6 flex-wrap sm-flex-justify-center">
          <Button
            onClick={() => this.setState({ step: importFromFileStep })}
            classNames="btn btn-outline btn-small border-alto bg-white sm-mb1 sm-col-12 mr1"
          >
            <img
              src="/images/kpis_import/excel-logo.svg"
              alt="Excel Logo"
              style={{ width: '48px', height: '30px' }}
            />
            <span>Import with Excel</span>
          </Button>
          <Button
            onClick={() => this.setState({ step: importFromGsheetStep })}
            classNames={`btn btn-outline btn-small border-alto bg-white sm-mb1 sm-col-12 ${syncGsheets || refreshDerivedKpisUrl ? 'mr1' : ''}`}
          >
            <img
              className="ml1 mr1"
              src="/images/kpis_import/google-sheets-logo.svg"
              alt="Excel Logo"
              style={{ width: '26px', height: '30px' }}
            />
            Connect with Google Sheet
          </Button>
          {(syncGsheets || refreshDerivedKpisUrl) &&
            <div className="btn btn-outline border-alto bg-white sm-mb1 sm-col-4 sm-flex-justify-center">
              <DropdownMenu
                margin="py2"
                textColor="gray"
                faIcon="ellipsis-v"
                width="220px"
                dropdownClass="center__nav-item left-50"
                setFalseWhenClickingInside
              >
                {syncGsheets &&
                  <li>
                    <div
                      className="px2 hover hover-bg-wild-sand hover-text-blue text-gray fw400 cursor-pointer h5 lh-36"
                      data-balloon={canRefreshGsheetsState ? 'Click here to update KPIs values from Google Sheets.' : 'Please, wait about 30 seconds to be able to try again.'}
                      data-balloon-pos="left"
                      onClick={() => this.toggleModal('showShareholderModal')}
                    >
                      <a
                        className={`${canRefreshGsheetsState ? 'cursor-pointer' : 'opacity-35 cursor-default'} text-medium-gray bold`}
                        href={canRefreshGsheetsState ? syncGsheets : '#'}
                        onClick={(e) => { if (!canRefreshGsheetsState) e.preventDefault(); }}
                      >
                        <i className="fa fa-google mr2 text-medium-gray" aria-hidden="true" />
                        Sync Gsheets
                      </a>
                    </div>
                  </li>
                }
                {refreshDerivedKpisUrl &&
                  <div
                    className="px2 hover hover-bg-wild-sand hover-text-blue text-gray fw400 cursor-pointer h5 lh-36"
                    data-balloon="Click here to refresh all your derived KPIs data values."
                    data-balloon-pos="left"
                  >
                    <a
                      className="cursor-pointer text-medium-gray bold"
                      data-method="patch"
                      href={refreshDerivedKpisUrl}
                    >
                      <i className="fa fa-refresh mr2 text-medium-gray" aria-hidden="true" />
                      Update-all-derived-KPIs
                    </a>
                  </div>
                }
              </DropdownMenu>
            </div>
          }
        </div>

        {importFromFileStep === step &&
          <KpiValuesImport
            show
            companyId={companyId}
            closeModal={this.closeModal}
          />
        }

        {importFromGsheetStep === step &&
          <GoogleSheetBulkImport
            companyId={companyId}
            updateData={this.updateData}
            closeModal={this.closeModal}
            data={sheetSourceDataParsed}
          />
        }
      </div>
    );
  }
}

export default AddOrImportKpis;
