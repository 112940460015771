import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ReactSelect from 'react-select';

import Modal from '../../Modal';

import { defaultSelectStyles, defaultSelectTheme } from '../../selects/SelectStyles';
import InvestorCustomReminderFilterModal from './InvestorCustomReminderFilterModal';


class InvestorCustomReminderSetAutoMessageModal extends Component {
  static propTypes = {
    ids: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    const dayOptions = [];
    for (let index = 1; index <= 31; index++) {
      dayOptions.push({ label: index, value: index });
    }

    const {
      data,
    } = this.props;

    this.state = {
      updating: false,
      message: data.message || 'Dear company, we have not received the update.',
      day: data.day || 8,
      dayOptions,
      periodType: data.period_type || 'month',
      periodTypeOptions: [{ label: 'Month', value: 'month' }, { label: 'Quarter', value: 'quarter' }],
      currentModal: 'filter',
      lastMonthKpis: data.last_month_kpis || '',
      percentageCompleted: data.percentage_completed || '',
      lastMonthUpdates: data.last_month_updates || '',
      capTableUpdated: data.cap_table_updated_at || '',
    };
  }

  updateState = (stateName, value) => {
    this.setState({ [stateName]: value });
  }

  setAutomatedReminder = () => {
    const { ids, closeModal } = this.props;
    const {
      updating,
      message,
      day,
      periodType,
      lastMonthKpis,
      percentageCompleted,
      lastMonthUpdates,
      capTableUpdated,
    } = this.state;

    if (updating) return;

    this.setState({ updating: true }, async () => {
      try {
        const params = { investor_custom_reminder_params:
          { message, day, period_type: periodType, last_month_kpis: lastMonthKpis, percentage_completed: percentageCompleted, last_month_updates: lastMonthUpdates, cap_table_updated_at: capTableUpdated },
        };

        await axios.patch(`/investor/${ids.investorId}/investor_custom_reminders`, params);

        this.setState({ updating: false }, () => closeModal());
        App.State.setFlash({ name: 'notice', msg: 'Automatic Reminders created.' });
        window.location.reload();
      } catch (err) {
        App.State.setFlash({ name: 'alert', msg: err.response.data.error });
        this.setState({ updating: false });
      }
    });
  }

  render() {
    const { closeModal } = this.props;

    const {
      message,
      day,
      dayOptions,
      periodType,
      periodTypeOptions,
      currentModal,
      lastMonthKpis,
      percentageCompleted,
      lastMonthUpdates,
      capTableUpdated,
    } = this.state;

    return (
      <>
        {currentModal === 'filter' &&
          <InvestorCustomReminderFilterModal
            title="Automated Message"
            subTitle="Reminders will be sent to all companies meeting Any of the following criteria, on the dates selected in the next window. <br/> <b>Please note these reminders will be sent to ALL companies that meet the criteria, not just the ones in the selected portfolio.</b>"
            lastMonthKpis={lastMonthKpis}
            percentageCompleted={percentageCompleted}
            lastMonthUpdates={lastMonthUpdates}
            capTableUpdated={capTableUpdated}
            setFilter={() => this.setState({ currentModal: 'automaticReminder' })}
            closeModal={() => { this.setState({ currentModal: null }); closeModal(); }}
            updateState={this.updateState}
            confirmationText="Next"
          />
        }

        <Modal
          className="react_modal--300"
          show={currentModal === 'automaticReminder'}
          onClickOutside={() => closeModal()}
        >
          <div className="flex flex-justify-between items-center bold p2 border-bottom border-lighter-gray">
            <div className="h3">Automated Message</div>
            <i className="fa fa-close h5 cursor-pointer" onClick={() => closeModal()} />
          </div>

          <div className="text-gray p3 h4">
            <div className="flex items-center mb3 mt1">
              <span className="mr1 fw400 h3">Date:</span>
              <span className="mr1">day</span>
              <div className="col-2">
                <ReactSelect
                  value={dayOptions.filter(option => option.value === day)}
                  options={dayOptions}
                  onChange={(selectedOption) => this.setState({ day: selectedOption.value })}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                />
              </div>
              <span className="mx1">of the</span>
              <div className="col-3">
                <ReactSelect
                  value={periodTypeOptions.filter(option => option.value === periodType)}
                  options={periodTypeOptions}
                  onChange={(selectedOption) => this.setState({ periodType: selectedOption.value })}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                />
              </div>
            </div>
            <div className="flex flex-column">
              <div className="flex mb2 fw400">
                <span className="h3">Message text:</span>
              </div>
              <div className="flex items-center">
                <textarea
                  value={message}
                  onChange={(e) => this.setState({ message: e.target.value })}
                  placeholder="Your message goes in here."
                  className="border border-alto text-gray lh-15 col-12 h5 input-alto__focus"
                  style={{ height: '80px' }}
                />
              </div>
            </div>
          </div>

          <div className="bg-white p3">
            <div className="pt3 flex flex-center flex-justify-around">
              <div
                href="#"
                className="col-3 center py1 bg-light-gray rounded text-white cursor-pointer"
                onClick={(e) => { e.preventDefault(); this.setState({ currentModal: 'filter' }); }}
              >
                Back
              </div>

              <span
                className="col-3 center py1 bg-green rounded text-white cursor-pointer"
                onClick={() => this.setAutomatedReminder()}
              >
                Confirm
              </span>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default InvestorCustomReminderSetAutoMessageModal;
