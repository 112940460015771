const kpis = (state = [], action) => {
  switch (action.type) {
    case 'RESET_KPIS':
      return {
        ...state,
        data: action.kpis,
      };

    case 'RESET_KPIS_AND_CHART':
      return {
        ...state,
        data: action.data.kpis,
        chart_data: action.data.chart_data,
      };

    case 'RESET_KPIS_BY_CURRENT_DATE':
      return {
        ...state,
        data: action.kpis,
        current_date: action.currentDate,
      };

    case 'REMOVE_KPI':
      return {
        ...state,
        data: state.data.filter(s => s.id !== action.kpi.id),
      };

    case 'UPDATE_KPI_CHART_DATA':
      return {
        ...state,
        chart_data: action.chartData,
      };

    default: return state;
  }
};

export default kpis;
