import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CleaveNumberFormatting from '../CleaveNumberFormatting';


class MainField extends Component {
  static propTypes = {
    setData: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    edit: PropTypes.bool.isRequired,
    currency: PropTypes.string.isRequired,
  }

  setDataDebounce = (e, id, value) => {
    const { setData } = this.props;
    setData(e, id, value);
  }

  setPreValuation = e => {
    this.setDataDebounce(e, e.target.id, e.target.rawValue || '0');
  }

  componentWillMount() {
    this.setDataDebounce = _.debounce(this.setDataDebounce, 1000);
  }

  dispalyPreValuation = () => {
    const { edit, data, currency } = this.props;

    if (edit) {
      return (
        <CleaveNumberFormatting
          value={data.pre_valuation}
          onChange={this.setPreValuation}
          className="appearance-textfield block border border-alto col-8 mx-auto"
          name="cap_table[pre_valuation]"
          id="pre_valuation"
        />
      );
    }
    return (
      <span className="block bold h3 mb2 text-blue">{ data.pre_valuation_show } {currency}</span>
    );
  }

  render() {
    const { data, currency } = this.props;

    return (
      <div className="bg-white bs-around flex mb3 py2">
        <p className="border-right border-alto center col-3 m0 px2">
          <span className="block bold h3 mb2 text-blue">{ data.raised_seed } {currency}</span>
          <span className="bold">Raised</span>
        </p>

        <p className="border-right border-alto center col-3 m0 px2">
          {this.dispalyPreValuation()}
          <span className="bold">Pre-Money</span>
        </p>

        <p className="border-right border-alto center col-3 m0 px2">
          <span className="block bold h3 mb2 text-blue">{ data.post_money } {currency}</span>
          <span className="bold">Post-Money</span>
        </p>

        <p className="center col-3 m0 px2">
          <span className="block bold h3 mb2 text-blue">{ data.pps } {currency}</span>
          <span className="bold">Price / Share</span>
        </p>
      </div>
    );
  }
}

export default MainField;
