/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import TimeframeSelect from '../../investor/modules/generic_kpi_chart_components/TimeframeSelect';
import { defaultSelectStyles, defaultSelectStylesWithSeparator, defaultSelectTheme } from '../../selects/SelectStyles';
import Button from '../../Button';
import HtmlComponent from '../../tab_view/HtmlComponent';

const Benchmarking = (props) => {
  const { companyId, investorId, companyOptions } = props;
  const [investmentId, setInvestmentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [previewCount, setPreviewCount] = useState(null);
  // const [checkBoxState, setCheckBoxState] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [noDataMsg, setNoDataMsg] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([
    {
      type: 'base_metrics_filters',
      value: [],
    },
    {
      type: 'country',
      value: [],
    },
    {
      type: 'stage',
      value: [],
    },
    {
      type: 'industry',
      value: [],
    },
    {
      type: 'business_model',
      value: [],
    },
    {
      type: 'established',
      value: [],
    },
    {
      type: 'accumulator',
      value: [],
    },
    {
      type: 'derivation',
      value: [],
    },
    {
      type: 'verified_investor',
      value: [],
    },
    {
      type: 'currency',
      value: [],
    },
    {
      type: 'business_type',
      value: [],
    },
  ]);
  const [startDate, setStartDate] = useState(moment().startOf('year').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [chosenTimeline, setChosenTimeline] = useState('current');

  const updateDisabledOption = (preState, isDisabled) => (
    preState.derivationFilters.map(filter => (
      filter.value === 'base_metric' ?
        {
          ...filter,
          isDisabled,
        }
        :
        { ...filter }
    ))
  );

  const handleFilterSelect = (val, type) => {
    if (type === 'currency') {
      if (val.length !== 0) {
        setFilterOptions((preState) => (
          {
            ...preState,
            derivationFilters: updateDisabledOption(preState, false),
          }
        ));
      } else {
        setFilterOptions((preState) => {
          handleFilterSelect([], 'derivation');
          return (
            {
              ...preState,
              derivationFilters: updateDisabledOption(preState, true),
            }
          );
        });
      }
    }
    if (selectedFilters.find(fil => fil.type === type)) {
      setSelectedFilters((preState) => preState.map(filter => {
        if (filter.type === type) {
          return {
            type,
            value: val,
          };
        }
        return filter;
      }));
    } else {
      setSelectedFilters(
        [...selectedFilters, {
          type,
          value: val,
        }],
      );
    }
  };

  useEffect(() => {
    const url = companyId ? `/company/${companyId}/benchmarkings/fetch_filters_data` : `/investor/${investorId}/benchmarkings/fetch_filters_data`;

    axios.get(url).then(res => {
      if (res) {
        setFilterOptions(res.data);
        res.data.derivationFilters[0].isDisabled = true;
        handleFilterSelect(res.data.verifiedFilters.find(opt => opt.value), 'verified_investor');
        handleFilterSelect(res.data.baseMetricFilters.find(opt => opt.value), 'base_metrics_filters');
      }
    });
  }, []);

  const handleCustomDateChange = (customDate, type) => {
    if (type === 'startDate') setStartDate(customDate);
    else if (type === 'endDate') setEndDate(customDate);
  };

  const fetchCountryOptions = (query) => {
    if (query.replace(/^[ ]+|$/g, '').length < 3) {
      setCountryOptions([]);
      return;
    }
    const queryParams = { searchQuery: query };
    App.Api.get('/country_info_search', queryParams).then(
      (data) => {
        setCountryOptions(data.map((country) => ({ value: country.name, label: country.name })));
      },
    );
  };

  const changeTimeFrameSelection = (val) => {
    setChosenTimeline(val);
    if (val === 'current') {
      setStartDate(moment().startOf('year').format('YYYY-MM-DD'));
      setEndDate(moment().endOf('year').format('YYYY-MM-DD'));
    } else if (val === 'previous_year') {
      setStartDate(moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD'));
      setEndDate(moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD'));
    } else if (val === 'two_years') {
      setStartDate(moment().subtract(2, 'years').startOf('year').format('YYYY-MM-DD'));
      setEndDate(moment().format('YYYY-MM-DD'));
    } else if (val === 'three_years') {
      setStartDate(moment().subtract(3, 'years').startOf('year').format('YYYY-MM-DD'));
      setEndDate(moment().format('YYYY-MM-DD'));
    }
  };

  const timeRangeOptions = [
    { value: 'current', label: 'Current Year' },
    { value: 'previous_year', label: 'Previous Year' },
    { value: 'two_years', label: 'Last 2 Years' },
    { value: 'three_years', label: 'Last 3 Years' },
    { value: 'custom', label: 'Custom Range' },
  ];

  // const checkedClasses = `fa fa-check-square h2 ${false ? 'text-light-gray' : 'text-blue'}`;
  // const unCheckedClasses = `fa ${false ? 'fa-square' : 'fa-square-o'} h2 ${false ? 'text-lighter-gray' : 'text-alto'}`;

  const filterData = (preview = false) => {
    if (loading) return;

    const loadData = (filters) => {
      if ($.isEmptyObject(filters.find(fil => fil.type === 'derivation').value) && !preview) {
        App.State.setFlash({ name: 'alert', msg: 'Please, choose a derivation type.' });
        return;
      }

      const tempLoading = {
        chartTitle: '',
        accumulator: 'years',
        data: [],
        lineData: [],
      };
      if (!preview) window.boxplotChart('box-plot-chart', tempLoading).showLoading('Loading... it can take a few minutes');

      let url;
      if (preview) {
        url = companyId ? `/company/${companyId}/benchmarkings/fetch_preview_count` : `/investor/${investorId}/benchmarkings/fetch_preview_count`;
      } else {
        url = companyId ? `/company/${companyId}/benchmarkings/fetch_chart_data` : `/investor/${investorId}/benchmarkings/fetch_chart_data`;
      }
      const filteredParams = filters.filter(item => !(item.value && item.value.length === 0));
      const params = {
        filters: filteredParams
          .concat(
            [
              {
                type: 'startDate',
                value: startDate,
              },
              {
                type: 'endDate',
                value: endDate,
              },
            ],
          ),
      };

      if (companyId) {
        params.company_id = companyId;
      } else {
        if (!investmentId) {
          App.State.setFlash({ name: 'alert', msg: 'Please, choose a company' });
          return;
        }
        params.investor_id = investorId;
        params.investment_id = investmentId;
      }

      setLoading(true);
      axios.get(url, { params }).then(res => {
        if (res) {
          if (preview) {
            setPreviewCount(res.data.preview_count);
          } else {
            const resultData = res.data;

            if (resultData.lineData.length === 0) {
              setNoDataMsg("You can’t see the data because you have not entered your own KPIs for the selected period. Please enter the KPIs, and then try again");
            } else if (resultData.data.length === 0) {
              setNoDataMsg('There aren’t enough companies for the selected filter. Please try with a broader filter, or a longer period');
            }

            const baseMetricFilter = filters.find(fil => fil.type === 'base_metrics_filters');
            const derivationFilter = filters.find(fil => fil.type === 'derivation');
            const currency = selectedFilters.find(fil => fil.type === 'currency').value.label || '';
            if (!$.isEmptyObject(derivationFilter.value)) {
              resultData.chartTitle = `${baseMetricFilter.value.label} - ${derivationFilter.value.label} - ${currency}`;
              if (derivationFilter.value.value !== 'base_metric') {
                resultData.isGrowthValue = true;
              } else {
                resultData.currency = currency;
              }
            } else {
              resultData.chartTitle = baseMetricFilter.value.label;
            }

            const chart = window.boxplotChart('box-plot-chart', resultData);
            $('#boxplot-bullet-points').removeClass('d-none');
            chart.hideLoading();
          }
        }
        setLoading(false);
      });
    };

    if (selectedFilters[6].value.length === 0) {
      setSelectedFilters((preState) => {
        const filters = preState.map(filter => (
          filter.type === 'accumulator' ? { ...filter, value: { value: 'months', label: 'Month' } } : { ...filter }
        ));
        loadData(filters);
        return filters;
      });
    } else {
      loadData(selectedFilters);
    }
  };

  return (
    <div>
      <div className="mb2">
        <div className="relative">
          <span className="desktop__notification--bell" data-behavior="hide-bubble" style={{ borderRadius: '30%', width: '25px', top: '-10px', left: '125px', zIndex: 1 }}>
            Alpha
          </span>
        </div>
        <h3>BenchMarking</h3>
      </div>

      <div className="flex">
        <div className="flex flex-column col-6">
          <div className="mb2">
            <span className="h3">What to benchmark?</span>
          </div>
          {investorId && companyOptions &&
            <div className="mb1 mr1 sm-col-8">
              <ReactSelect
                placeholder="Company..."
                value={companyOptions.find(option => option.value === investmentId)}
                options={companyOptions}
                onChange={(selectedOption) => setInvestmentId(selectedOption.value)}
                noOptionsMessage={() => 'No Company'}
                styles={defaultSelectStyles}
                theme={defaultSelectTheme}
              />
            </div>
          }
          <div className="mb1 mr1 sm-col-8">
            <ReactSelect
              placeholder="Base Metric..."
              value={selectedFilters.find(fil => fil.type === 'base_metrics_filters').value}
              options={filterOptions.baseMetricFilters}
              onChange={(val) => handleFilterSelect(val, 'base_metrics_filters')}
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
            />
          </div>
          <div className="mb1 mr1 sm-col-8 tooltip">
            <span className="tooltiptext">To benchmark the ‘base metric’ for currency KPIs, please choose a currency</span>
            <ReactSelect
              placeholder="Derivation..."
              value={selectedFilters.find(fil => fil.type === 'derivation').value}
              options={filterOptions.derivationFilters}
              onChange={(val) => handleFilterSelect(val, 'derivation')}
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
            />
          </div>
          <div className="mb1 mr1 sm-col-8">
            <ReactSelect
              placeholder="Accumulate By..."
              value={selectedFilters.find(fil => fil.type === 'accumulator').value}
              options={filterOptions.aggregationFilter}
              onChange={(val) => handleFilterSelect(val, 'accumulator')}
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
            />
          </div>
          <div className="mr1 sm-col-8">
            <TimeframeSelect
              timeRangeOptions={timeRangeOptions}
              chosenTimeline={chosenTimeline}
              startDate={startDate}
              endDate={endDate}
              handleTimeFrameSelection={changeTimeFrameSelection}
              handleCustomDateChange={handleCustomDateChange}
              width=""
            />
          </div>
        </div>
        <div className="flex flex-column col-6">
          <div className="mb2">
            <span className="h3">Benchmark against which companies?</span>
          </div>
          <div className="mb1 sm-col-8">
            <ReactSelect
              placeholder="Geo..."
              isMulti
              value={selectedFilters.find(fil => fil.type === 'country').value}
              options={countryOptions}
              onChange={(val) => handleFilterSelect(val, 'country')}
              onInputChange={(newValue) => fetchCountryOptions(newValue)}
              noOptionsMessage={() => 'Please type..'}
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
            />
          </div>
          <div className="mb1 sm-col-8">
            <ReactSelect
              placeholder="Stage..."
              isMulti
              value={selectedFilters.find(fil => fil.type === 'stage').value}
              options={filterOptions.stageFilters}
              onChange={(val) => handleFilterSelect(val, 'stage')}
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
            />
          </div>
          <div className="mb1 sm-col-8">
            <ReactSelect
              placeholder="Sector..."
              isMulti
              value={selectedFilters.find(fil => fil.type === 'industry').value}
              options={filterOptions.sectorFilters}
              onChange={(val) => handleFilterSelect(val, 'industry')}
              styles={defaultSelectStylesWithSeparator}
              theme={defaultSelectTheme}
            />
          </div>
          <div className="mb1 sm-col-8">
            <ReactSelect
              placeholder="Business Model..."
              isMulti
              value={selectedFilters.find(fil => fil.type === 'business_model').value}
              options={filterOptions.businessModelFilters}
              onChange={(val) => handleFilterSelect(val, 'business_model')}
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
            />
          </div>
          <div className="mb1 sm-col-8">
            <ReactSelect
              placeholder="Business Type"
              isMulti
              value={selectedFilters.find(fil => fil.type === 'business_type').value}
              options={filterOptions.businessType}
              onChange={(val) => handleFilterSelect(val, 'business_type')}
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
            />
          </div>
          <div className="mb1 sm-col-8">
            <ReactSelect
              placeholder="Currency"
              isClearable
              value={selectedFilters.find(fil => fil.type === 'currency').value}
              options={filterOptions.currencyFilter}
              onChange={(val) => handleFilterSelect(val || [], 'currency')}
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
            />
          </div>
          <div
            className="mb1 sm-col-8"
            data-balloon="A verified company is a company that is connected to an Institutional Investor and has given them access to their KPIs. As companies have obligations with their investors, this indicates they are taking the data seriously"
            data-balloon-pos="top"
            data-balloon-length="xlarge"
          >
            <ReactSelect
              placeholder="Verified Companies..."
              value={selectedFilters.find(fil => fil.type === 'verified_investor').value}
              options={filterOptions.verifiedFilters}
              onChange={(val) => handleFilterSelect(val, 'verified_investor')}
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
            />
          </div>
          <div className="sm-col-12 flex flex-justify-between items-center">
            <div className="sm-col-4 nowrap mr1">
              <Button
                type="button"
                classNames={`btn-outline border-alto bg-white h5 p1 ${loading ? 'cursor-disabled' : 'btn'}`}
                onClick={() => filterData(true)}
              >
                {loading ? 'Loading...' : 'Preview Count'}
              </Button>
            </div>
            <div className="sm-col-8 border--dashed border-alto rounded p1 center">
              <div className="flex h5">
                Number of companies matching the filter: &nbsp; <HtmlComponent data={{ html: `<b>${previewCount || 'N/A'}</b>` }} />
              </div>
              {previewCount &&
                <div className="h5">
                  (some may not have data)
                </div>
              }
            </div>
          </div>
        </div>

        {/* Commented out for now */}
        {/* <div style={{ marginBottom: '10px', display: 'flex' }}>
          <div className="mr1 col-3 sm-col-8">
            <ReactSelect
              placeholder="Founding Date..."
              isMulti
              value={selectedFilters.find(fil => fil.type === 'established').value}
              options={filterOptions.foundingDateFilters}
              onChange={(val) => handleFilterSelect(val, 'established')}
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
            />
          </div>
          <div className="flex flex-center mr1 col-3 sm-col-8">
            <div className="flex flex-center">
              <div
                className="flex flex-center cursor-pointer"
                onClick={() => { setCheckBoxState(!checkBoxState); }}
              >
                <i
                  className={checkBoxState ? checkedClasses : unCheckedClasses}
                />
              </div>
            </div>
            <span className="ml1 cursor-default">
              Relative from founding ?
            </span>
          </div>
        </div> */}
      </div>
      <div className="flex flex-justify-end mt2">
        <Button
          type="button"
          classNames={`btn-outline border-alto bg-white h5 p1 ${loading ? 'cursor-disabled' : 'btn'}`}
          onClick={() => filterData()}
        >
          {loading ? 'Loading...' : 'Filter'}
        </Button>
      </div>
      <div>
        <div className="text-red" style={{marginTop: '20px'}}><span>{noDataMsg}</span></div>
        <div id="box-plot-chart" />
        <div className="text-muted d-none mt2" id="boxplot-bullet-points">
          <ul>
            <li>If you can’t see data for a certain period it could be because</li>
            <ul className="bullet-list">
              <li>You still need to enter data for this period. This is a give and take, so we’ll show you benchmarks only if you enter your data</li>
              <li>There are less than 5 companies for the criteria selected for this period. This is done to protect data confidentiality</li>
            </ul>
          </ul>
        </div>
      </div>
    </div>
  );
};
Benchmarking.propTypes = {
  companyId: PropTypes.number,
  investorId: PropTypes.number,
  companyOptions: PropTypes.array,
};

Benchmarking.defaultProps = {
  companyId: null,
  investorId: null,
  companyOptions: null,
};

export default Benchmarking;
