import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HeaderContainer from '../../../../containers/investor/investments/HeaderContainer';

import FundingField from './FundingField';
import FundingModal from './modals/FundingModal';
import FundingShares from './FundingShares';
import FundingRounds from './FundingRounds';
import SimpleOverview from '../../../cap_table/SimpleOverview';
import Fundraising from './Fundraising';
import LoadingSpinner from '../../../LoadingSpinner';


class Funding extends Component {
  static propTypes = {
    header: PropTypes.object.isRequired,
    funding: PropTypes.object.isRequired,
    capTable: PropTypes.object,
    currentUser: PropTypes.object.isRequired,
    updateFunding: PropTypes.func.isRequired,
    createOrUpdateFundingFields: PropTypes.func.isRequired,
    deleteFundingFields: PropTypes.func.isRequired,
    updateFundingShare: PropTypes.func.isRequired,
    createFundingShare: PropTypes.func.isRequired,
    deleteFundingShare: PropTypes.func.isRequired,
    deleteFundingShares: PropTypes.func.isRequired,
    updateFundingRound: PropTypes.func.isRequired,
    createFundingRound: PropTypes.func.isRequired,
    deleteFundingRound: PropTypes.func.isRequired,
    deleteFundingRounds: PropTypes.func.isRequired,
    createFundingShareClass: PropTypes.func.isRequired,
    updateFundingShareClass: PropTypes.func.isRequired,
    deleteFundingShareClass: PropTypes.func.isRequired,
    createFundingConvertible: PropTypes.func.isRequired,
    updateFundingConvertible: PropTypes.func.isRequired,
    deleteFundingConvertible: PropTypes.func.isRequired,
    copyDataFromCompany: PropTypes.func.isRequired,
    switchCapTable: PropTypes.func.isRequired,
    switchFundraisingData: PropTypes.func.isRequired,
    updateFundingTotals: PropTypes.func.isRequired,
    setFundlingLoading: PropTypes.func.isRequired,
  }

  static defaultProps = {
    capTable: null,
  }

  constructor(props) {
    super(props);

    const { capTable, funding } = props;

    this.state = {
      showEditModal: false,
      hasCaptable: !!capTable.has_captable,
      selectedTab: funding.edit ? 'options' : 'captable',
    };
  }

  state = {
    showEditModal: false,
  }

  saveFundingFields = async (id, params) => {
    const { header, funding, createOrUpdateFundingFields } = this.props;

    try {
      await createOrUpdateFundingFields(header.investor.id, funding.investment_id, id, params);
    } catch (err) {
      console.log(err.response.data.errors);
    }
  }

  removeFundingFields = async params => {
    const { header, funding, deleteFundingFields } = this.props;
    await deleteFundingFields(header.investor.id, funding.investment_id, params);
  }

  saveFundingRound = async (id, values) => {
    const {
      header,
      funding,
      updateFundingRound,
      updateFundingTotals,
    } = this.props;
    const params = { funding_round: { ...values } };

    try {
      await updateFundingRound(header.investor.id, funding.investment_id, id, params);
      await updateFundingTotals();
    } catch (err) {
      console.log(err.response.data.errors);
    }
  }

  saveFundingShare = async (id, values) => {
    const {
      header,
      funding,
      updateFundingShare,
      updateFundingTotals,
    } = this.props;
    const params = { funding_share: { ...values } };

    try {
      await updateFundingShare(header.investor.id, funding.investment_id, id, params);
      await updateFundingTotals();
    } catch (err) {
      console.log(err.response.data.errors);
    }
  }

  handleNewRoundCreation = async (eventFrom = 'default', isCustom) => {
    const {
      createFundingRound,
      copyDataFromCompany,
      setFundlingLoading,
      funding,
      header,
    } = this.props;

    const round = { name: 'Untitled round' };

    try {
      if (eventFrom === 'default' || isCustom) {
        await createFundingRound(header.investor.id, funding.investment_id, { funding_round: round });
      } else {
        setFundlingLoading(true, 'rounds_loading');
        await copyDataFromCompany('rounds');
        setFundlingLoading(false, 'rounds_loading');
      }
      setTimeout(() => {
        const inputTextField = $('#round_name_1')[0];
        if (inputTextField && eventFrom === 'outside') inputTextField.focus();
      }, 500);
      window.scrollTo(0, this.fundingRoundsRef.offsetTop);
    } catch (err) {
      setFundlingLoading(false, 'rounds_loading');
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
    }
  }

  handleCopyFromCompany = async (copyFrom) => {
    const {
      copyDataFromCompany,
      updateFundingTotals,
      setFundlingLoading,
    } = this.props;

    try {
      if (copyFrom === 'all') {
        await setFundlingLoading(true);
        await copyDataFromCompany();
        await setFundlingLoading(false);
      } else {
        await setFundlingLoading(true, 'shares_loading');
        await setFundlingLoading(true, 'funding_fields_loading');
        await copyDataFromCompany('recalculate_share_fields');
        await setFundlingLoading(false, 'shares_loading');
        await setFundlingLoading(false, 'funding_fields_loading');
      }
      await updateFundingTotals();
    } catch (err) {
      if (copyFrom === 'all') {
        await setFundlingLoading(false);
      } else {
        await setFundlingLoading(false, 'shares_loading');
        await setFundlingLoading(false, 'funding_fields_loading');
      }
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
    }
  }

  handleCreateFundingShare = async (...args) => {
    const { createFundingShare, updateFundingTotals } = this.props;

    await createFundingShare(...args);
    await updateFundingTotals();
  }

  handleDeleteFundingShares = async (...args) => {
    const { deleteFundingShares, updateFundingTotals } = this.props;

    await deleteFundingShares(...args);
    await updateFundingTotals();
  }

  handleDeleteFundingShare = async (...args) => {
    const { deleteFundingShare, updateFundingTotals } = this.props;

    await deleteFundingShare(...args);
    await updateFundingTotals();
  }

  render() {
    const {
      header, funding, capTable, currentUser,
      deleteFundingRound,
      deleteFundingRounds,
      switchCapTable,
      updateFunding,
      switchFundraisingData,
      setFundlingLoading,
      createFundingShareClass,
      updateFundingShareClass,
      deleteFundingShareClass,
      createFundingConvertible,
      updateFundingConvertible,
      deleteFundingConvertible,
    } = this.props;
    const { showEditModal, hasCaptable, selectedTab } = this.state;

    const hasPermission = !!funding.company_shares;
    const customShares = !hasPermission || funding.shares.length > 0 || !funding.company_shares;
    const customRounds = !hasPermission || funding.rounds.length > 0 || !funding.company_rounds;

    const fundingFieldsLoading = funding.loading || funding.funding_fields_loading;
    const investmentCurrency = funding.show_values_in_display_currency ? funding.currency : null;

    return (
      <div>
        <HeaderContainer
          activeTab="funding"
          tabUrl={`investments/${header.id}/funding`}
        />

        {/* Funding Shares section */}
        <div className="flex flex-column col-12 my2 relative">
          <div className={`flex flex-column col-12 bs-around bg-white mb2 ${funding.edit ? (customShares ? 'desktop__notification--changes-blue' : 'desktop__notification--changes-green') : ''}`}>
            <FundingShares
              funding={funding}
              currentUser={currentUser}
              hasPermission={hasPermission}
              isCustom={customShares}
              isCustomRounds={customRounds}
              investorId={header.investor.id}
              showIrr={header.investor.can_calculate_irr}
              saveFundingShare={this.saveFundingShare}
              createFundingShare={this.handleCreateFundingShare}
              deleteFundingShare={this.handleDeleteFundingShare}
              deleteFundingShares={this.handleDeleteFundingShares}
              createFundingShareClass={createFundingShareClass}
              updateFundingShareClass={updateFundingShareClass}
              deleteFundingShareClass={deleteFundingShareClass}
              createFundingConvertible={createFundingConvertible}
              updateFundingConvertible={updateFundingConvertible}
              deleteFundingConvertible={deleteFundingConvertible}
              copyDataFromCompany={this.handleCopyFromCompany}
              setFundlingLoading={setFundlingLoading}
              createFundingRound={() => this.handleNewRoundCreation('outside', customRounds)}
            />
          </div>

          {/* Funding section */}
          <div className="sm-col-12 bs-around p2 text-gray bg-white">
            <div className="flex flex-justify-between flex-wrap">
              <div className="flex">
                <div className="fw400 pl2">Company Funding Summary</div>
                <div
                  data-balloon="This section contains a summary of the company funding till today."
                  data-balloon-pos="right"
                  data-balloon-length="xlarge"
                >
                  <i className="fa fa-info-circle text-blue ml1 h5" />
                </div>
                {investmentCurrency &&
                  <span className="h5 text-light-gray ml1 fw400">({investmentCurrency})</span>
                }
              </div>
              {funding.edit && !funding.custom_investment &&
                <div
                  className="px1 bg-black--transparent h6 text-white cursor-pointer rounded mr2 sm-mt1 sm-ml2"
                  onClick={() => this.setState({ showEditModal: true })}
                >
                  <i className="fa fa-cog mr1" />Manage
                </div>
              }
            </div>
            {fundingFieldsLoading ?
              <LoadingSpinner show={fundingFieldsLoading} type="fit" height="300px" background="white" />
              :
              <div className="flex flex-justify-between flex-wrap p2">
                {funding.funding_fields.map(f => (
                  <FundingField
                    key={f.id}
                    edit={funding.edit}
                    field={f}
                    hasPermission={hasPermission}
                    component="CleaveNumberFormatting"
                    saveFundingFields={this.saveFundingFields}
                    balloonText
                  />
                ))}
              </div>
            }
          </div>

          {!funding.edit && !funding.custom_investment &&
            <div className="flex flex-justify-center">
              <div className="col-11 border border-bottom border-light-gray mt2" />
            </div>
          }

          {/* Section with the Switch & Captable & Rounds */}

          <div className="flex flex-column my2">
            {/* TAB - (Company Captable / Advanced Options) */}
            {hasCaptable &&
              <div className="flex flex-column flex-justify-start">
                <div className="flex items-center col-12">
                  {hasCaptable && capTable.data &&
                    <div
                      className={`${selectedTab === 'captable' ? 'bg-white' : 'bg-wild-sand'} flex flex-justify-center p2 bs-around fw400 cursor-pointer col-6`}
                      onClick={() => this.setState({ selectedTab: 'captable' })}
                    >
                      <span>Company Captable</span>
                    </div>
                  }
                  <div
                    className={`${selectedTab === 'options' ? 'bg-white' : 'bg-wild-sand'} ${funding.edit ? 'hide' : 'cursor-pointer'} flex flex-justify-center p2 bs-around fw400 col-6`}
                    onClick={() => this.setState({ selectedTab: 'options' })}
                  >
                    <span>Manage Funding Rounds</span>
                  </div>
                </div>
                {selectedTab === 'options' && !funding.edit &&
                  <div className="my2">
                    <span className="text-light-gray">The advanced funding options enable you to edit funding data extracted from the companies captable. Click Edit in the top right to change this.</span>
                  </div>
                }
              </div>
            }

            {/* Begin of custom investment or (Advanced Options) data */}
            {(!hasCaptable || (hasCaptable && selectedTab === 'options')) &&
              <div
                className="flex flex-column col-12 bs-around bg-white"
                ref={(c) => { this.fundingRoundsRef = c; }}
              >
                <FundingRounds
                  funding={funding}
                  investorId={header.investor.id}
                  hasPermission={hasPermission}
                  isCustom={customRounds}
                  currency={investmentCurrency}
                  saveFundingRound={this.saveFundingRound}
                  createFundingRound={this.handleNewRoundCreation}
                  deleteFundingRound={deleteFundingRound}
                  deleteFundingRounds={deleteFundingRounds}
                />
              </div>
            }
            {/* End of custom investment or (Advanced Options) data */}

            {/* Captable Info - for existing companies only */}
            {hasCaptable && capTable.data && selectedTab === 'captable' &&
              <div className="mt2">
                <SimpleOverview
                  capTable={capTable}
                  switchCapTable={switchCapTable}
                  currency={investmentCurrency}
                />
              </div>
            }
          </div>

          {!funding.edit && !funding.custom_investment &&
            <div className="flex flex-justify-center">
              <div className="col-11 border border-bottom border-light-gray mb2" />
            </div>
          }

          {/* Fundraising section */}
          <div className="sm-col-12 bs-around p2 sm-ml0 sm-mt2 text-white bg-light-gray">
            <Fundraising
              funding={funding}
              hasPermission={hasPermission}
              investorId={header.investor.id}
              currency={investmentCurrency}
              updateFunding={updateFunding}
              switchFundraisingData={switchFundraisingData}
            />
          </div>
        </div>
        {showEditModal &&
          <FundingModal
            show={showEditModal}
            fundingFields={funding.funding_fields}
            hasPermission={hasPermission}
            closeModal={() => this.setState({ showEditModal: false })}
            saveFundingFields={this.saveFundingFields}
            removeFundingFields={this.removeFundingFields}
          />
        }
      </div>
    );
  }
}

export default Funding;
