import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import TextInput from '../../TextInput';
import CheckBox from '../../CheckBox';
import { defaultSelectStyles, defaultSelectTheme } from '../../selects/SelectStyles';
import Circle from '../../Circle';


class Settings extends Component {
  static propTypes = {
    updateInvestorUpdate: PropTypes.func.isRequired,
    externalUsers: PropTypes.array,
    investorManaged: PropTypes.bool,
    subject: PropTypes.string,
    subject_placeholder: PropTypes.string.isRequired,
    preview: PropTypes.string,
    preview_placeholder: PropTypes.string.isRequired,
    include_in_mail: PropTypes.bool.isRequired,
    automatic_share: PropTypes.bool.isRequired,
    user_options: PropTypes.array.isRequired,
    user_id: PropTypes.number.isRequired,
  }

  static defaultProps = {
    subject: '',
    preview: '',
    externalUsers: [],
    investorManaged: false,
  }

  constructor(props) {
    super(props);

    const { subject, preview, user_id, include_in_mail, automatic_share } = props;

    this.state = {
      subject,
      preview,
      user_id,
      include_in_mail,
      previewMessage: preview,
      subjectMessage: subject,
      automatic_share,
      showAuthor: false,
      showSubject: false,
      showSecurity: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { updateInvestorUpdate } = this.props;
    const { automatic_share, include_in_mail } = this.state;

    if (automatic_share) return;

    if (nextProps.externalUsers.length > 0 && !include_in_mail) {
      updateInvestorUpdate({ automatic_share: true }).then(
        () => this.setState({ automatic_share: true }),
      );
    }
  }

  handleChange = (params, section = null) => {
    const { updateInvestorUpdate, externalUsers } = this.props;

    this.setState({ ...params, [section]: false });

    if (section === 'showSecurity' && !params.include_in_mail && externalUsers.length > 0) {
      updateInvestorUpdate({ ...params, automatic_share: true }).then(
        () => this.setState({ automatic_share: true }),
      );
    } else {
      updateInvestorUpdate(params);
    }
  }

  toogleOption = (option) => {
    const { [option]: optionValue } = this.state;

    this.setState({ [option]: !optionValue });
  }

  render() {
    const { subject_placeholder, user_options, preview_placeholder, externalUsers, investorManaged } = this.props;
    const {
      subject,
      user_id,
      preview,
      include_in_mail,
      automatic_share,
      previewMessage,
      subjectMessage,
      showAuthor,
      showSubject,
      showSecurity,
    } = this.state;

    const securitySettingOptions = [{ label: 'Send update inside mail', value: 1 }, { label: 'Send link to update only', value: 0 }];
    const authorName = user_options.filter(option => option.value === user_id)[0].label;
    const securitySetting = include_in_mail ? 'Send update inside email' : 'Send update link only';
    const conditionBlockAutomaticShare = externalUsers.length > 0 && !include_in_mail;

    return (
      <div className="flex mb3 mt2">
        <div><Circle content={investorManaged ? '2' : '3'} border="alto" text="light-gray" /></div>
        <div className="flex flex-column col-12">
          <span className="fw400">
            Advanced Settings (Optional)
          </span>
          <span className="text-light-gray">
            Settings below have been set automatically, but you can overwrite them if necessary.
          </span>
          <div className="flex flex-column col-12 lg-col-12">
            <React.Fragment>
              <div className="flex flex-column mt2 border border-alto rounded">
                {/* Author */}
                <div className="flex flex-row">
                  <div className="col-12 py2 border-bottom border-alto">
                    <div className="pl3 pr2">
                      {
                        showAuthor ?
                          <React.Fragment>
                            <div className="flex flex-column">
                              <span className="h5 fw400">From (Author)</span>
                              <span className="h5">Who should appear as the update author (update header and email sender)?</span>
                            </div>
                            <div className="my2 col-6">
                              <ReactSelect
                                name="shareholder_update[author]"
                                value={user_options.filter(option => option.value === user_id)}
                                options={user_options}
                                isClearable={false}
                                onChange={selectedOption => this.handleChange({ user_id: selectedOption.value }, 'showAuthor')}
                                styles={defaultSelectStyles}
                                theme={defaultSelectTheme}
                              />
                            </div>
                            <div className="flex">
                              <div
                                className="cursor-pointer text-blue"
                                onClick={() => this.toogleOption('showAuthor')}
                              >
                                Close
                              </div>
                            </div>
                          </React.Fragment>
                          :
                          <div className="col-12 flex flex-justify-between">
                            <div className="flex flex-column">
                              <span className="h5 fw400">From (Author)</span>
                              <span className="h5 text-light-gray">Author of this update is set to <span className="fw400">&quot;{authorName}&quot;</span></span>
                            </div>
                            <div className="flex flex-center">
                              <div
                                className="px1 bg-light-gray rounded cursor-pointer"
                                style={{ paddingTop: '5px', paddingBottom: '5px' }}
                                name="showAuthor"
                                onClick={() => this.toogleOption('showAuthor')}
                              >
                                <span className="text-white fw400 h5">Change Author</span>
                              </div>
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </div>
                {/* Subject & Preview */}
                <div className="flex flex-row">
                  <div className="col-12 py2 border-bottom border-alto">
                    <div className="pl3 pr2">
                      {
                        showSubject ?
                          <React.Fragment>
                            <div className="flex flex-column">
                              <span className="h5 fw400">Email Subject</span>
                              <span className="h5">What should appear as the email subject for this update?</span>
                            </div>
                            <div className="my2 flex">
                              <div className="col-6 mr2">
                                <span className="h6 fw400">Subject</span>
                                <TextInput
                                  className="border border-alto hover-border-light-gray col-12"
                                  onChange={e => this.setState({ subjectMessage: e.target.value})}
                                  placeholder={subject_placeholder}
                                  value={subjectMessage}
                                  name="shareholder_update[subject]"
                                  style={{ height: '38px' }}
                                />
                              </div>
                              <div className="col-6">
                                <span className="h6 fw400">Preview Text</span>
                                <TextInput
                                  className="border border-alto hover-border-light-gray col-12"
                                  onChange={e => this.setState({ previewMessage: e.target.value })}
                                  placeholder={preview_placeholder}
                                  value={preview}
                                  name="shareholder_update[preview]"
                                  style={{ height: '38px' }}
                                />
                              </div>
                            </div>
                            <div className="flex flex-center">
                              <div
                                className="px1 bg-light-gray rounded cursor-pointer"
                                style={{ paddingTop: '5px', paddingBottom: '5px' }}
                                onClick={() => {
                                  this.handleChange({ subject: subjectMessage, preview: previewMessage }, 'showSubject');
                                }}
                              >
                                <span className="text-white">Save</span>
                              </div>
                              <div
                                className="pl1 px1 cursor-pointer text-blue"
                                onClick={() => this.toogleOption('showSubject')}
                              >
                                <span>Cancel</span>
                              </div>
                            </div>
                          </React.Fragment>
                          :
                          <div className="col-12 flex flex-justify-between">
                            <div className="flex flex-column">
                              <span className="h5 fw400">Email Subject</span>
                              <span className="text-light-gray h5">Subject is set to <span className="fw400">&quot;{subject || subject_placeholder}&quot;</span></span>
                            </div>
                            <div className="flex flex-center">
                              <div
                                className="px1 bg-light-gray rounded cursor-pointer"
                                style={{ paddingTop: '5px', paddingBottom: '5px' }}
                                name="showAuthor"
                                onClick={() => this.toogleOption('showSubject')}
                              >
                                <span className="h5 text-white fw400">Change Subject</span>
                              </div>
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </div>
                {/* Security */}
                <div className="flex flex-row">
                  <div className="col-12 py2">
                    <div className="pl3 pr2">
                      {
                        showSecurity ?
                          <React.Fragment>
                            <div className="flex flex-column">
                              <span className="h5 fw400">Security</span>
                              <span className="h5">How should this update be sent/accessed?</span>
                            </div>
                            <div className="my2 col-6">
                              <ReactSelect
                                name="shareholder_update[author]"
                                value={securitySettingOptions.filter(option => !!option.value === include_in_mail)}
                                options={securitySettingOptions}
                                onChange={selectedOption => this.handleChange({ include_in_mail: !!selectedOption.value }, 'showSecurity')}
                                isClearable={false}
                                isSearchable={false}
                                styles={defaultSelectStyles}
                                theme={defaultSelectTheme}
                              />
                            </div>
                            <div className="flex">
                              <div
                                className="cursor-pointer text-blue"
                                onClick={() => this.toogleOption('showSecurity')}
                              >
                                Close
                              </div>
                            </div>
                          </React.Fragment>
                          :
                          <div className="col-12 flex flex-justify-between">
                            <div className="flex flex-column">
                              <span className="h5 fw400">Security</span>
                              <span className="h5 text-light-gray">Security set to <span className="fw400">&quot;{securitySetting}&quot;</span></span>
                            </div>
                            <div className="flex flex-center">
                              <div
                                className="px1 bg-light-gray rounded cursor-pointer"
                                style={{ paddingTop: '5px', paddingBottom: '5px' }}
                                name="showAuthor"
                                onClick={() => this.toogleOption('showSecurity')}
                              >
                                <span className="h5 text-white fw400">Change Setting</span>
                              </div>
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-justify-between mt2">
                <div className="flex items-center">
                  <CheckBox
                    defaultChecked={automatic_share}
                    manualCheck
                    checked={automatic_share}
                    disabled={conditionBlockAutomaticShare}
                    disabledMessage="Shareable Link will be automatically created when external recipients are added."
                    onChange={conditionBlockAutomaticShare ? null : () => this.handleChange({ automatic_share: !automatic_share })}
                  />
                  <span className="ml1 h5 text-light-gray">Generate a Shareable Link automatically after publish/send</span>
                  <span
                    data-balloon="Generating a shareable link on publish will let you share this update with anyone that has this link (on or off investory). Note: In some cases this setting is required, for example if you send/publish an update to users that are not on investory but set the security setting to 'login required'. Shareable link access can be revoked after they have been generated at any time."
                    data-balloon-pos="down"
                    data-balloon-length="xlarge"
                  >
                    <i
                      className="fa fa-question-circle-o ml1"
                    />
                  </span>
                </div>
              </div>
            </React.Fragment>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
