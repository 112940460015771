import React from 'react';
import PropTypes from 'prop-types';

const handleSelectTab = (index, isActive, callBack) => {
  if (!isActive) {
    callBack(index);
  }
};

const ConfigTabs = props => {
  const {
    options,
    active,
    onTabChange,
    contentCount
  } = props;

  return (
    <div className="slideshow-config-tabs">
      { options.map((option, index) => (
        <div
          className={`config-tab ${index === active ? 'active' : ''}`}
          key={option}
          onClick={() => handleSelectTab(index, index === active, onTabChange)}
        >
          <div className="my2">
            <span>{index === 1 ? `${option} (${contentCount})` : option}</span>
          </div>
        </div>
      ))
      }
    </div>
  );
};

ConfigTabs.propTypes = {
  options: PropTypes.array.isRequired,
  active: PropTypes.number,
  onTabChange: PropTypes.func.isRequired,
  contentCount: PropTypes.number,
};

ConfigTabs.defaultProps = {
  active: 0,
  contentCount: 0,
};

export default ConfigTabs;
