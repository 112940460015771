import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../../store/configureStore';
import InvestorHomeContainer from '../../containers/investor/InvestorHomeContainer';


const InvestorHome = props => (
  <Provider store={configureStore(props)}>
    <InvestorHomeContainer />
  </Provider>
);

export default InvestorHome;
