import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import { defaultSelectStyles, defaultSelectTheme } from './SelectStyles';

class MultiSelect extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    values: PropTypes.array,
    options: PropTypes.array.isRequired,
    attributeName: PropTypes.string.isRequired,
    clearable: PropTypes.bool,
    minimumElements: PropTypes.number,
    blockedOptions: PropTypes.array,
  };

  static defaultProps = {
    placeholder: 'Select...',
    values: [],
    clearable: false,
    minimumElements: 0,
    blockedOptions: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: props.values,
    };
  }

  handleChange = (selectedOption) => {
    const { minimumElements, blockedOptions } = this.props;
    const { selected } = this.state;

    if (selected.length <= minimumElements && selectedOption.length <= minimumElements) {
      App.State.setFlash({ name: 'alert', msg: 'There must be at least one option selected.' });
      return;
    }

    if (blockedOptions.length > 0 && !selectedOption.find(option => blockedOptions.includes(option.value))) {
      App.State.setFlash({ name: 'alert', msg: 'This option cannot be removed.' });
      return;
    }

    this.setState({ selected: selectedOption });
  }

  render() {
    const { name, placeholder, options, attributeName, clearable, ...other } = this.props;
    const { selected } = this.state;

    return (
      <>
        <ReactSelect
          name={name}
          placeholder={placeholder}
          value={selected}
          options={options}
          onChange={(selectedOption) => this.handleChange(selectedOption)}
          isClearable={clearable}
          styles={defaultSelectStyles}
          theme={defaultSelectTheme}
          isMulti
          joinValues
          {...other}
        />

        {selected.map(element => (
          <input key={element.value} type="hidden" name={`${name}[${attributeName}]`} value={element.value} multiple="multiple" />
        ))}
      </>
    );
  }
}

export default MultiSelect;
