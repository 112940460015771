const updateDealRoom = dealRoom => ({
  type: 'UPDATE_DEAL_ROOM',
  dealRoom,
});

const updateDealTerm = dealTerm => ({
  type: 'UPDATE_DEAL_TERM',
  dealTerm,
});

const addDealCommitment = dealCommitment => ({
  type: 'ADD_DEAL_COMMITMENT',
  dealCommitment,
});

const updateDealCommitment = dealCommitment => ({
  type: 'UPDATE_DEAL_COMMITMENTS',
  dealCommitment,
});

const removeDealCommitment = id => ({
  type: 'REMOVE_DEAL_COMMITMENT',
  id,
});

export {
  updateDealRoom,
  updateDealTerm,
  addDealCommitment,
  updateDealCommitment,
  removeDealCommitment,
};
