import React, { Component } from 'react';
import PropTypes from 'prop-types';


class FaButton extends Component {
  static defaultProps = {
    html_method: null,
    tooltip: null,
    tooltipPos: 'down',
    size: '',
    color: 'gray',
    margin: 'mx1',
    loading: true,
    disabled: false,
  }

  static propTypes = {
    url: PropTypes.string,
    faIcon: PropTypes.string.isRequired,
    html_method: PropTypes.string,
    tooltip: PropTypes.string,
    tooltipPos: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    margin: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  state = {
    used: false,
  }

  render() {
    const {
      disabled, color, margin, tooltip, tooltipPos, url,
      faIcon, size, loading, html_method,
    } = this.props;

    const { used } = this.state;

    if (disabled) {
      return (
        <span
          className={`${margin} ${size}`}
          data-balloon={tooltip}
          data-balloon-pos={tooltipPos}
        >
          <i className={`text-${color} fa fa-fw fa-${faIcon}`} />
        </span>
      );
    }
    if (used && loading) {
      return (
        <span className={`${margin} ${size}`}>
          <i className="text-gray fa fa-refresh fa-spin fa-fw" />
        </span>
      );
    }
    return (
      <a
        className={`text-${color} ${margin} ${size}`}
        data-balloon={tooltip}
        data-balloon-pos={tooltipPos}
        data-method={html_method}
        href={url}
        onClick={() => { this.setState({ used: true }); }}
      >
        <i className={`fa fa-fw fa-${faIcon}`} />
      </a>
    );
  }
}

export default FaButton;
