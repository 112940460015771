import React from 'react';
import PropTypes from 'prop-types';


const SpotRateWarning = ({ currency, balloonPosition }) => (
  <React.Fragment>
    <span
      data-balloon={`Some ${currency} cashflows were missing, so ${currency} cashflows had to be calculated using the company currency at today's spot rate. If you want to use the correct ${currency} amount at the transaction date, please enter it in the Investments' Funding tab, in the Fund Currency fields`}
      data-balloon-pos={balloonPosition}
      data-balloon-length="xlarge"
      className="mr1"
    >
      <i className="fa fa-info-circle text-red h5" />
    </span>
  </React.Fragment>
);

SpotRateWarning.propTypes = {
  currency: PropTypes.string.isRequired,
  balloonPosition: PropTypes.string,
};

SpotRateWarning.defaultProps = {
  balloonPosition: 'down',
};

export default SpotRateWarning;
