import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../../../LoadingSpinner';
import Avatar from '../../../../tab_view/Avatar';
import SpotRateWarning from '../investment_info/SpotRateWarning';

export default class VerticalListingWidget extends Component {
  static propTypes = {
    show: PropTypes.bool,
    data: PropTypes.array.isRequired,
    bottomValue: PropTypes.object,
    loading: PropTypes.bool,
    blurred: PropTypes.bool,
    showWarning: PropTypes.bool,
    currency: PropTypes.string,
    separators: PropTypes.array,
  }

  static defaultProps = {
    show: true,
    loading: false,
    blurred: null,
    bottomValue: null,
    showWarning: false,
    currency: null,
    separators: [],
  }

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  render() {
    const {
      show,
      loading,
      data,
      blurred,
      bottomValue,
      showWarning,
      currency,
      separators,
    } = this.props;

    const {
      open,
    } = this.state;

    return (
      show ?
        <>
          { loading ?
            <LoadingSpinner
              show={loading}
              type="fit"
              background="white"
              height="200px"
            />
            :
            <>
              <div className="flex flex-1 flex-column p2">
                {data ?
                  <>
                    {data.map((el, index) => (
                      <div key={el.id || index + 1}>
                        <div className={`flex items-center ${data.length !== index + 1 ? 'mb2' : ''}`}>
                          {el.avatar &&
                            <>
                              <Avatar avatarUrl={el.avatar} size={32} classNames="bg-white" shadow />
                              {el.real_company &&
                                <div className="relative">
                                  <span className="real_company__notification" style={{ top: '-25px', right: '-12px' }} data-behavior="hide-bubble">
                                    <i className="fa fa-check-circle text-green" />
                                  </span>
                                </div>
                              }
                            </>
                          }
                          <div className={`flex flex-column lh-12 ${el.avatar ? 'ml2' : ''}`}>
                            <a
                              className="mt-auto h4 fw400 text-gray"
                              href={el.link}
                              data-balloon={el.balloonText || (el.title.length > 20 ? el.title : null)}
                              data-balloon-pos="down"
                              data-balloon-length="large"
                            >
                              {el.title.length > 20 ? `${el.title.substring(0, 20)}...` : el.title}
                            </a>
                            {el.last_updated &&
                              <div className={`mb-auto h6 text-light-gray mt05 lh-12 ${blurred ? 'hide-data' : ''}`}>
                                {el.last_updated}
                              </div>
                            }
                          </div>
                          <div className={`ml-auto ${blurred ? 'hide-data' : ''} ${el.no_value ? 'text-red' : el.highlight ? 'text-orange' : ''}`}>{el.value !== null ? el.value : '-'}</div>
                          {el.beta &&
                            <div className="relative">
                              <span className="desktop__notification--bell" data-behavior="hide-bubble" style={{ borderRadius: '30%', width: '25px', top: '-20px', right: '180px', zIndex: 1 }}>
                                Beta
                              </span>
                            </div>
                          }
                        </div>
                        {separators.includes(index + 1) &&
                          <div className="border-bottom border-lighter-gray mb2" />
                        }
                      </div>
                    ))}
                  </>
                  :
                  <div className="flex items-center flex-justify-center text-light-gray">
                    Not enough data available.
                  </div>
                }
              </div>
              {bottomValue &&
                <div className="flex items-center p2">
                  <div className="flex flex-column lh-12">
                    <span className="mt-auto h4 fw400 text-gray">{bottomValue.title}</span>
                  </div>
                  <div className={`ml-auto ${blurred ? 'hide-data' : ''}`}>{bottomValue.value !== null ? bottomValue.value : '-'}</div>
                </div>
              }
              {showWarning &&
                <div className="flex ml2 mb2">
                  <SpotRateWarning currency={currency} />
                </div>
              }
            </>
          }
        </>
        : null
    );
  }
}
