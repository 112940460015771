import React, { Component } from 'react';


class RoleSelect extends Component {
  state = {
    selected: null,
  }

  render() {
    const { selected } = this.state;

    return (
      <div>
        <input
          type="hidden"
          name="selected"
          id="selected_role"
          value={selected}
        />

        <div className="flex flex-justify-around mb3 x-lg-mb2 sm-flex-column x-lg-mb3">
          <div
            onClick={() => this.setState({ selected: 1 })}
            className={selected === 1 ? 'onboard-box sm-mb3 sm-mx-auto active' : 'onboard-box sm-mb3 sm-mx-auto'}
            id="investor"
          >
            <div className="icon-container cursor-pointer flex flex-center flex-justify-center mx-auto mb2 relative">
              <span className="icon-role icon-role--user bg-center bg-no-repeat inline-block" />

              <span className="icon-76 absolute bg-green block bottom-0 circle right-0">
                <span className="icon-chart align-middle bg-center bg-no-repeat inline-block" />
              </span>
            </div>

            <p className="h4 semi-bold text-scorpion text-upper">Investor</p>

            <p className="light m0 text-light-gray px2">
              You invested into startups and always want to be up-to-date with their latest developments.
            </p>
          </div>

          <div
            onClick={() => this.setState({ selected: '0' })}
            className={selected === '0' ? 'onboard-box sm-mb3 sm-mx-auto active' : 'onboard-box sm-mb3 sm-mx-auto'}
            id="entrepreneur"
          >
            <div className="icon-container cursor-pointer flex flex-center flex-justify-center mx-auto mb2 relative">
              <span className="icon-role icon-role--user-group bg-center bg-no-repeat inline-block" />

              <span className="icon-76 absolute bg-green block bottom-0 circle right-0">
                <i className="fa fa-briefcase align-middle fs-40 text-white" />
              </span>
            </div>

            <p className="h4 semi-bold text-scorpion text-upper">Company</p>

            <p className="light m0 text-light-gray px2">
              You are a startup which received funding from one or several investors and want to manage all your investor communication in one place.
            </p>
          </div>
        </div>

        {selected ?
          <input
            className="bg-blue border-none cursor-pointer fs-18 inline-block px4 sm-px2 py2 rounded text-upper text-white"
            type="submit"
            value="Continue"
          />
          :
          <div className="bg-gallery border-gallery fs-18 inline-block px2 py2 rounded text-upper text-white fw400 cursor-disabled">
            Select Investor or Company
          </div>
        }
      </div>

    );
  }
}

export default RoleSelect;
