import React from 'react';
import PropTypes from 'prop-types';
import CustomerInfo from './CustomerInfo';
import Pricing from './Pricing';

const Billing = ({
  investor,
  stripe,
  subscribe,
  unsubscribe,
  resubscribe,
}) => {
  return (
    <div>
      {stripe.plan ?
        <CustomerInfo
          customer={investor.customer}
          subscription={investor.subscription}
          plans={stripe.plan}
          subscribe={subscribe}
          unsubscribe={unsubscribe}
          resubscribe={resubscribe}
        />
        :
        <Pricing stripe={stripe} subscribe={subscribe} />
      }
    </div>
  );
};

Billing.propTypes = {
  investor: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  subscribe: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired,
  resubscribe: PropTypes.func.isRequired,
};

export default Billing;
