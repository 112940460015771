import React, { Component } from 'react';
import PropTypes from 'prop-types';

class InvestmentFilter extends Component {
  static propTypes = {
    filters: PropTypes.array,
    currentFilter: PropTypes.string,
    counts: PropTypes.object,
    data: PropTypes.object,
    filterLabels: PropTypes.object,
    handleFilterChange: PropTypes.func,
  }

  renderFilters = () => {
    const { currentFilter, filters, counts, filterLabels, handleFilterChange } = this.props;

    const filterLinks = filters.map((innerFilter) => (
      <div
        key={`filter_${innerFilter}`}
        onClick={() => handleFilterChange(innerFilter)}
        className={`flex flex-justify-between py05 cursor-pointer ${innerFilter === currentFilter ? 'text-gray fw400' : 'text-light-gray hover hover-text-gray' }`}
      >
        <span className={innerFilter === currentFilter ? 'text-gray fw400' : 'text-light-gray hover hover-text-gray'}>
          {this.renderIcon(innerFilter, currentFilter)} {filterLabels[`${innerFilter}`]}
        </span>
        <span className={innerFilter === currentFilter ? 'text-gray fw400' : 'text-light-gray hover hover-text-gray'}>
          {counts[`${innerFilter}`]}
        </span>
      </div>
    ));

    return filterLinks;
  }

  renderIcon = (innerFilter, currentFilter) => {
    if (innerFilter === 'all') {
      return (<i className={innerFilter === currentFilter ? 'fa fa-fw fa-home text-gray mr1' : 'fa fa-fw fa-home text-light-gray mr1 hover hover-text-gray'} />);
    }
    if (innerFilter === 'assigned') {
      return (<i className={innerFilter === currentFilter ? 'fa fa-fw fa-user text-gray mr1' : 'fa fa-fw fa-user text-light-gray mr1 hover hover-text-gray'} />);
    }
    if (innerFilter === 'requested') {
      return (<i className={innerFilter === currentFilter ? 'fa fa-fw fa-envelope-o text-gray mr1' : 'fa fa-fw fa-envelope-o text-light-gray mr1 hover hover-text-gray'} />);
    }
    if (innerFilter === 'invited') {
      return (<i className={innerFilter === currentFilter ? 'fa fa-fw fa-user-plus text-gray mr1' : 'fa fa-fw fa-user-plus text-light-gray mr1 hover hover-text-gray'} />);
    }
    if (innerFilter === 'waiting_approval') {
      return (<i className={innerFilter === currentFilter ? 'fa fa-fw fa-check text-gray mr1' : 'fa fa-fw fa-check text-light-gray mr1 hover hover-text-gray'} />);
    }
    return (<i className={innerFilter === currentFilter ? 'fa fa-fw fa-circle text-gray mr1' : 'fa fa-fw fa-circle text-light-gray mr1 hover hover-text-gray'} />);
  }

  render() {
    return (
      <div>
        <div>
          <div className="mt1">
            {this.renderFilters()}
          </div>
        </div>
      </div>
    );
  }
}

export default InvestmentFilter;
