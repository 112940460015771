import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../../../store/configureStore';
import DataRoomContainer from '../../../containers/company/data_rooms/DataRoomContainer';


const CompanyDataRoom = props => (
  <Provider store={configureStore(props)}>
    <DataRoomContainer />
  </Provider>
);

export default CompanyDataRoom;
