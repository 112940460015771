import Highcharts from 'highcharts';
import HighchartsTheme from './highcharts_theme';

HighchartsTheme();

export default function createPie(selector, data, showLegend = true) {
  Highcharts.getOptions().plotOptions.pie.colors = (function colors() {
    return ['#1C6B7C', 'green', '#ff7f0e', '#f28f3b', '#c8553d', '#e0a890', '#70b77e', '#5AAA95', '#473335', '#fcaa67', '#BF1363', '#01A7C2'];
  }());

  return Highcharts.chart({
    chart: {
      renderTo: selector,
      type: 'pie',
      animation: {
        duration: 1000,
      },
      backgroundColor: null,
    },
    title: {
      text: null,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          formatter: function format() {
            if (this.percentage > 0) {
              return `${this.percentage.toFixed(2)} %`;
            }
            return null;
          },
          color: 'white',
          distance: -30,
          style: {
            fontWeight: 'normal',
            fontSize: '12px',
          },
        },
        showInLegend: true,
      },
    },
    legend: {
      enabled: showLegend,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      labelFormatter: function label() {
        if (this.name.length > 20) {
          return (`${this.name.slice(0, 20)}...`);
        }
        return this.name;
      }
    },
    tooltip: {
      pointFormat: '<b>{point.percentage:.2f}%</b>',
    },
    series: [{
      name: null,
      data,
    }],
    credits: {
      text: 'investory.io',
      href: 'http://investory.io',
    },
  });
}
