import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CustomAutocomplete from '../CustomAutocomplete';
import CleaveNumberFormatting from '../CleaveNumberFormatting';

class Convertible extends Component {
  static propTypes = {
    convertible: PropTypes.object.isRequired,
    updateItem: PropTypes.func.isRequired,
    updateTopState: PropTypes.func.isRequired,
    edit: PropTypes.bool.isRequired,
  }

  state = {
    currentConvertibleValue: null,
    currentDiscountValue: null,
  }

  componentWillMount() {
    this.updateItemDebounce = _.debounce(this.updateItemDebounce, 1000);
  }

  delete = (e) => {
    e.preventDefault();
    const { convertible, updateTopState } = this.props;
    App.Api.delete(convertible.url).then(
      data => updateTopState(data),
    );
  }

  displayCurrentConvertibleValue = () => {
    const { convertible } = this.props;
    const { currentConvertibleValue } = this.state;
    if (currentConvertibleValue) return currentConvertibleValue;
    return convertible.value;
  }

  displayCurrentDiscountValue = () => {
    const { convertible } = this.props;
    const { currentDiscountValue } = this.state;
    if (currentDiscountValue) return currentDiscountValue;
    return convertible.discount;
  }

  updateItem = (e) => {
    const { convertible, updateItem } = this.props;
    updateItem(convertible.url, e.target.id, e.target.value);
  }

  updateItemCleave = (e, type = 'convertible') => {
    const { convertible } = this.props;
    if (type === 'discount') {
      this.setState({ currentDiscountValue: e.target.rawValue });
    } else {
      this.setState({ currentConvertibleValue: e.target.rawValue });
    }
    this.updateItemDebounce(e.target.id, e.target.rawValue || 0, convertible.url);
  }

  updateItemDebounce = (id, rawValue, shareUrl) => {
    const { updateItem } = this.props;
    updateItem(shareUrl, id, rawValue);
  }

  render() {
    const { convertible, edit } = this.props;
    if (edit) {
      return (
        <div className="flex mxn2">
          <div className="col-4 px2">
            <div className="flex flex-center h6 m0 relative text-gray">
              <CustomAutocomplete
                className="input-like border border-alto col-12"
                onChange={this.updateItem}
                value={convertible.owner_name}
                items={convertible.investors}
                name="share[owner_name]"
                id="owner_name"
              />
            </div>
          </div>

          <div className="col-4 px2">
            <CleaveNumberFormatting
              value={this.displayCurrentConvertibleValue()}
              onChange={this.updateItemCleave}
              className="appearance-textfield border border-alto col-12"
              name="convertible[value]"
              id="value"
              placeholder="0"
            />
          </div>

          <div className="col-4 px2">
            <CleaveNumberFormatting
              value={this.displayCurrentDiscountValue()}
              onChange={e => this.updateItemCleave(e, 'discount')}
              className="appearance-textfield border border-alto"
              name="convertible[discount]"
              id="discount"
              placeholder="0"
            />

            <div className="ml2 text-light-gray cursor-pointer inline" onClick={this.delete}>
              <i className="fa fa-trash" />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="flex mxn2">
        <div className="flex flex-center col-4 px2">
          <span
            className="block bg-center bg-cover bg-no-repeat circle header__avatar--investor mr1"
            style={{ backgroundImage: `url(${convertible.avatar})` }}
          />
          <p className="col-8 h6 m0 text-gray truncate">{convertible.owner_name}</p>

        </div>

        <div className="col-4 px2">
          <p className="center h6 m0 center text-gray">{convertible.value_show}</p>

        </div>

        <div className="col-4 px2">
          <p className="center h6 m0 center text-gray">{convertible.discount}</p>
        </div>
      </div>
    );
  }
}

export default Convertible;
