import { connect } from 'react-redux';

import {
  refreshKpis,
  updateKpi,
  deleteKpi,
  createOrUpdateKpiValue,
  getChartData,
  changeCurrentDate,
} from '../../api/company/kpiApi';

import KpiList from '../../components/kpis';

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  company: state.company,
  kpis: state.kpis,
});

const mapDispatchToProps = {
  refreshKpis,
  updateKpi,
  deleteKpi,
  createOrUpdateKpiValue,
  getChartData,
  changeCurrentDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(KpiList);
