import React, { Component } from 'react';


class PostWithComments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      post: JSON.parse(props.post),
    };
  }

  render() {
    const post = this.props.shareholder_update;
    return (
      <div className='show-post'>
        <CommentBox
          post={this.state.post}
          authorAvatar={this.props.authorAvatar}
          canComment={this.props.canComment}
        />
        <div className="comments__anchor">
          <a className="anchor--shareholder" id="comments" />
        </div>
      </div>
    );
  }
}

export default PostWithComments;
