import axios from 'axios';

import {
  updateRecipients,
  toogleContacsListState,
} from '../../actions/company/recipientActions';


const updateShareholderUpdate = (companyId, updateId, params) =>
  async dispatch => {
    await axios.patch(`/company/${companyId}/shareholder_updates/${updateId}`, params);
    dispatch({ type: '' });
  };

const addRecipients = (companyId, updateId, params) =>
  async dispatch => {
    const res = await axios.post(`/company/${companyId}/shareholder_updates/${updateId}/recipients`, params);
    dispatch(updateRecipients(res.data));
  };

const removeRecipients = (companyId, updateId, params) =>
  async dispatch => {
    const res = await axios.delete(`/company/${companyId}/shareholder_updates/${updateId}/recipients`, params);
    dispatch(updateRecipients(res.data));
  };

const highlightMember = (companyId, updateId, recipientId, params) =>
  async dispatch => {
    const res = await axios.patch(`/company/${companyId}/shareholder_updates/${updateId}/recipients/${recipientId}`, params);
    dispatch(updateRecipients(res.data));
  };

const toogleContactsList = () =>
  async dispatch => {
    dispatch(toogleContacsListState());
  };


export {
  addRecipients,
  removeRecipients,
  toogleContactsList,
  updateShareholderUpdate,
  highlightMember,
};
