import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar-edit';

import Modal from './Modal';

class ImageEditor extends Component {
  static propTypes = {
    imageSrc: PropTypes.string,
    name: PropTypes.string,
  }

  static defaultProps = {
    imageSrc: null,
    name: null,
  }

  constructor(props) {
    super(props);
    const { imageSrc } = props;

    this.state = {
      show: false,
      src: imageSrc,
      imagePreview: null,
    };
  }

  componentDidMount() {
    const { imageSrc } = this.props;

    if (imageSrc) {
      document.getElementById('image').value = imageSrc;
    }
  }

  onImageLoad = (imagePreview) => {
    this.setState({ imagePreview });
  }

  onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size / 1024 / 1024 > 71680) {
      App.State.setFlash({ name: 'alert', msg: 'File is too big!' });
      elem.target.value = '';
    }
  }

  onCrop = (imagePreview) => {
    this.setState({ imagePreview });
  }

  onClose = () => {
    this.setState({ imagePreview: null });
  }

  onSave = () => {
    const { imagePreview } = this.state;

    if (!imagePreview) {
      App.State.setFlash({ name: 'alert', msg: 'No file Added!' });
      return;
    }

    document.getElementById('image').value = imagePreview;

    this.setState({ src: imagePreview, imagePreview: null, show: false });
  }

  onCancel = () => {
    this.setState({ imagePreview: null, show: false });
  }

  openModal = () => {
    this.setState({ show: true });
  }

  render() {
    const { name } = this.props;
    const { show, src } = this.state;
    const style = {};

    if (src) {
      style.backgroundImage = `url(${src})`;
    } else {
      style.backgroundImage = 'url("/static/missing_default_company_avatar_new.png")';
    }

    return (
      <div
        className="double-image-upload__image-small bg-center bg-no-repeat circle mb2 sm-mx-auto cursor-pointer"
        data-balloon="Choose Avatar"
        style={{ ...style, backgroundSize: '39px' }}
        onClick={this.openModal}
      >
        <Modal
          className="bg-white flex flex-column mb2 p2 border-top border-blue bw-4"
          show={show}
          onClickOutside={this.onCancel}
        >
          <div className="p2 flex flex-column" data-balloon="Click to upload your logo">
            <Avatar
              width={500}
              height={295}
              onImageLoad={(image) => this.onImageLoad(image)}
              onCrop={this.onCrop}
              onClose={this.onClose}
              onBeforeFileLoad={this.onBeforeFileLoad}
              imageWidth={500}
            />
            <div className="flex text-light-gray flex-justify-start mt1 h6">
              Upload your logo.
            </div>
            <div className="flex text-light-gray flex-justify-start h6 mt0">
              <span>PS: Size of Image should not exceed (<b>70 Mb</b>), Image types supported are (<b>.jpg</b> and <b>.png</b>).</span>
            </div>
          </div>
          <div className="border-top border-lighter-gray m1 pt1 flex flex-justify-end">
            <div
              className="btn  text-blue cursor-pointer py1 px2 sm-col-12 mr1"
              onClick={this.onCancel}
            >
              Cancel
            </div>
            <div
              className="btn bg-blue border-none cursor-pointer fs-18 inline-block px2 py1 rounded sm-col-12 fw400 text-white"
              onClick={this.onSave}
            >
              Save
            </div>
          </div>
        </Modal>
        <textarea name={name} id="image" style={{ display: 'none' }} />
      </div>
    );
  }
}

export default ImageEditor;
