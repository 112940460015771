import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from '../../LoadingSpinner';
import Avatar from '../../tab_view/Avatar';
import NumeralWrapper from '../../utils/NumeralWrapper';

class InvestmentDealsList extends Component {
  static propTypes = {
    dealRoomsData: PropTypes.array,
    linkNewTab: PropTypes.bool,
  }

  static defaultProps = {
    dealRoomsData: null,
    linkNewTab: false,
  }

  constructor(props) {
    super(props);

    const { dealRoomsData } = props;

    this.state = {
      loading: dealRoomsData == null || dealRoomsData === undefined,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { dealRoomsData } = nextProps;

    this.setState({ loading: dealRoomsData == null || dealRoomsData === undefined });
  }

  getDate = (date) => {
    const dateArray = new Date(new Date(date).getTime()).toString().split(' ');
    return `${dateArray[1]} ${dateArray[2]}, ${dateArray[3]}`;
  }

  totalAmount = (dealCommitments) => (dealCommitments.reduce((acc, commitment) => acc + commitment.total_amount_committed.raw, 0));

  goalPercentage = (dealRoom) => {
    const dealTerm = dealRoom.deal_term;
    return (100 * this.totalAmount(dealRoom.deal_commitments)) / ((dealTerm && dealTerm.funding_goal.raw) || 0.0);
  };

  render() {
    const { dealRoomsData, linkNewTab } = this.props;
    const { loading } = this.state;

    return (
      <React.Fragment>
        {!loading &&
          (
            dealRoomsData.map(dealData => {
              const dealTerm = dealData.deal_room.deal_term;
              const daysUntilClosing = dealTerm ? dealTerm.closing_goal.days_until_closing : null;

              return (
                <div key={dealData.deal_room.id} className="flex mxn2 mb2">
                  <div className="col-12 lg-col-12 px2">
                    <div className="bg-white border border-gallery bs-around flex flex-column">
                      <div className="flex flex-column p2">
                        <div className="flex flex-row mb2">
                          <div className="mr1">
                            <Avatar avatarUrl={dealData.company_avatar || window.images.userGray} size={45} shadow />
                            {dealData.real_company &&
                              <div className="relative">
                                <span className="real_company__notification" style={{ top: '-55px', right: '-10px' }} data-behavior="hide-bubble">
                                  <i className="fa fa-check-circle text-green" />
                                </span>
                              </div>
                            }
                          </div>
                          <div>
                            <span className="h3 bold text-gray bw-1">{dealData.company_name}</span>
                            <div className="h5 text-light-gray pb2 lh-12 fw400"><i className="fa fa-clock-o mr1" aria-hidden="true" />Last Updated {dealData.updated_at}</div>
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="h5 text-light-gray pb2 lh-12 fw400 col-6 pr1" style={{ whiteSpace: 'pre-wrap' }}>{dealData.deal_room.deal_description ? dealData.deal_room.deal_description : 'No deal description added yet...'}</div>
                          <div className="col-6 px3 border-left border-alto col-cap6 p1">
                            <div className="flex flex-column">
                              <div className="mb1">
                                <span className="fw400 text-gray">{dealData.company_name}
                                  <span className="text-light-gray"> wants to raise </span>
                                  {`${dealData.currency} ${dealTerm ? dealTerm.funding_goal.readable : '\u2013'}`}
                                  {(dealTerm && dealTerm.closing_goal && (dealTerm.closing_goal.readable !== null)) &&
                                    <React.Fragment>
                                      <span className="text-light-gray"> by </span>
                                      {dealTerm ? dealTerm.closing_goal.readable : '\u2013'}.
                                    </React.Fragment>
                                  }
                                </span>
                              </div>
                              <div className="mb1">
                                <span className="fw400 text-gray">
                                  {dealData.deal_room.deal_commitments.length}
                                  <span className="text-light-gray"> Investors </span>
                                </span>
                                <span className="fw400 text-gray">
                                  <span className="text-light-gray">have already committed </span>
                                  {NumeralWrapper(this.totalAmount(dealData.deal_room.deal_commitments).toFixed(2))}
                                  <span className="text-light-gray"> in </span>
                                  {dealData.currency}
                                </span>
                              </div>
                            </div>
                            <div className="col-12 border border-alto rounded text-medium-gray" style={{ height: '8px' }}>
                              <div className="bg-gradient-blue rounded" style={{ width: `${this.goalPercentage(dealData.deal_room) > 100 ? '100' : this.goalPercentage(dealData.deal_room)}%`, height: '6px' }} />
                            </div>
                            <div className="flex flex-justify-between pb2">
                              <div className="h6 text-gray">{(dealTerm && dealTerm.funding_goal.raw) ? `${this.goalPercentage(dealData.deal_room).toFixed(2)}%` : ''}<span className={`text-light-gray ${dealTerm && dealTerm.funding_goal.raw ? 'pl1' : ''}`}>{(dealTerm && dealTerm.funding_goal.readable) ? 'of Funding Goal' : ''}</span></div>
                              <div className="h6 text-gray">{daysUntilClosing}<span className="text-light-gray pl1">{daysUntilClosing == null ? '' : 'days until Closing'}</span></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-justify-between border-top border-alto p2">
                        <div className="flex flex-row">
                          <div>
                            <Avatar avatarUrl={dealData.deal_room.deal_contact.avatar || window.images.userGray} size={36} shadow />
                          </div>
                          <div className="flex flex-column ml1 lh-12">
                            <div className="text-gray fw400">
                              {dealData.deal_room.deal_contact.name}
                            </div>
                            <div className="text-gray">
                              {dealData.deal_room.deal_contact.title}
                            </div>
                          </div>
                          {dealData.deal_room.deal_captain.name &&
                            <>
                              <div className="ml4">
                                <Avatar avatarUrl={dealData.deal_room.deal_captain.avatar || window.images.userGray} size={36} shadow />
                              </div>
                              <div className="flex flex-column ml1 lh-12">
                                <div className="text-gray fw400">
                                  {dealData.deal_room.deal_captain.name}
                                </div>
                                <div className="text-gray">
                                  {dealData.deal_room.deal_captain.title}
                                </div>
                              </div>
                            </>
                          }
                        </div>
                        <a
                          className="flex btn bg-blue rounded text-white px1 fw400"
                          href={`/c/${dealData.company_id}-${dealData.company_permalink}/deal_rooms/${dealData.deal_room.id}`}
                          target={linkNewTab ? '_blank' : null}
                          rel={linkNewTab ? 'noopener noreferrer' : null}
                        >
                          Access this Deal &nbsp; {linkNewTab ? <i className="fa fa-external-link" /> : null}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )
        }

        <LoadingSpinner
          show={loading}
          type="fit"
          background="white"
          height="100px"
        />
      </React.Fragment>
    );
  }
}

export default InvestmentDealsList;
