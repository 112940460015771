import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import TextInput from '../TextInput';
// import Select from '../Select';


class KpiWidget extends Component {
  static propTypes = {
    genericKpi: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    selectedKpi: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]),
    // editGenericKpi: PropTypes.func,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    selectedKpi: null,
    onClick: null,
    // editGenericKpi: null,
    errorMessage: null,
    disabled: false,
  };

  state = {
    disabledOnClick: false,
  }

  handleClick = () => {
    const { genericKpi, disabled, onClick } = this.props;

    if (disabled && !genericKpi.core_kpi) {
      App.State.setFlash({ name: 'alert', msg: 'This KPI is already added in the list.' });
      return;
    }

    if (onClick) onClick();
  }

  render() {
    const { genericKpi, selectedKpi, errorMessage, disabled } = this.props;
    const { disabledOnClick } = this.state;

    return (
      <>
        <div
          className={`col-12 flex flex-column py1 border bw-2 rounded ${disabled ? 'cursor-disabled bg-lighter-gray' : 'cursor-pointer'} ${genericKpi.info_url ? 'flex-justify-between' : 'flex-justify-end'} ${selectedKpi ? 'border-green' : 'border-light-gray'}`}
          onClick={disabledOnClick ? null : this.handleClick}
        >
          <div className="col-12 pl2">
            <div className="border-bottom border-light-gray pt2 pb1">
              <div
                className="text-gray"
                // data-balloon={selectedKpi ? null : genericKpi.metric.length >= 25 ? genericKpi.metric : null}
              >
                <div className={`flex flex-end h4 ${selectedKpi ? '' : 'bold'}`}>
                  {/* {selectedKpi ?
                    <TextInput
                      value={selectedKpi.custom_metric}
                      onChange={(e) => editGenericKpi('custom_metric', e.target.value)}
                      className="col-12 bg-none bold border-left border-right-none border-top-none border-bottom-none italic"
                      onMouseOver={() => this.setState({ disabledOnClick: true })}
                      onFocus={() => this.setState({ disabledOnClick: true })}
                      onMouseOut={() => this.setState({ disabledOnClick: false })}
                      onBlur={() => this.setState({ disabledOnClick: false })}
                      style={{ paddingBottom: 0, paddingTop: 0, height: null }}
                    />
                    :
                    <div className="truncate col-12">
                      {genericKpi.metric}
                    </div>
                  } */}
                  <div className="col-11 bold">
                    <span>{genericKpi.metric}</span>
                  </div>
                  {(genericKpi.info_url || genericKpi.description) &&
                    <a
                      className={genericKpi.info_url ? 'cursor-pointer' : 'cursor-default'}
                      href={genericKpi.info_url ? genericKpi.info_url : null}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-balloon={genericKpi.description ? genericKpi.description : null}
                      data-balloon-pos="top"
                      onMouseEnter={() => this.setState({ disabledOnClick: true })}
                      onMouseLeave={() => this.setState({ disabledOnClick: false })}
                    >
                      <i className="fa fa-info-circle text-blue h4" />
                    </a>
                  }
                </div>
              </div>
            </div>
            <div className="flex flex-column pt1 h6 text-gray">
              <div className="pr1 pt1">Aggregation: <span className="bold text-upper">{genericKpi.aggregation_type === 'total' ? 'total/sum' : 'median'}</span></div>
              <div className="pr1 pt1">Unit: <span className="bold text-upper">{genericKpi.unit}</span></div>
            </div>
          </div>


          {/* <div className="pt1 px2">
            <div className={`flex flex-center ${selectedKpi && selectedKpi.use_for_benchmarking ? 'text-green' : 'text-light-gray'}`}>
              <div
                className={selectedKpi ? 'cursor-pointer hover-fw400' : ''}
                onClick={(e) => { e.preventDefault(); selectedKpi ? editGenericKpi('use_for_benchmarking', !selectedKpi.use_for_benchmarking) : null; }}
              >
                <i className="fa fa-check-circle h4" aria-hidden="true" />
                <span className="h6 ml1">Add to Benchmarking</span>
              </div>
            </div>
          </div> */}

          {selectedKpi && errorMessage &&
            <div className="text-red h6 pl2">
              ({errorMessage})
            </div>
          }
        </div>
      </>
    );
  }
}

export default KpiWidget;
