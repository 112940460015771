import { connect } from 'react-redux';

import { subscribe, updateSubscription, unsubscribe, resubscribe, updateCard, deleteCustomer } from '../../api/company/paymentApi';

import Billing from '../../components/company/billing';

const mapStateToProps = state => ({
  company: state.company,
  stripe: state.stripe,
});

const mapDispatchToProps = {
  subscribe,
  updateSubscription,
  unsubscribe,
  resubscribe,
  updateCard,
  deleteCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
