import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setSorting, toggleOrder } from '../../actions/sortingActions';


const SorterHeader = ({
  field,
  text,
  kpi,
  hideIcon,
  sorting,
  setSortingState,
  toggleSortingOrder,
  position,
  width,
  textWidth,
  overflowWrap,
}) => {
  if (sorting.field === field && sorting.order === 'DESC') {
    return (
      <div
        className={`cursor-pointer flex flex-justify-center ${position === 'right' || position === 'left' ? position : 'items-center'}`}
        style={{ width }}
        onClick={() => toggleSortingOrder()}
      >
        <span
          className={`${overflowWrap ? 'overflow-wrap' : ''}`}
          style={{ width: textWidth }}
        >
          {text}
        </span>
        <span className="h6 w-0">
          <i className="fa fa-caret-down ml1" />
        </span>
      </div>
    );
  }
  if (sorting.field === field && sorting.order === 'ASC') {
    return (
      <div
        className={`cursor-pointer flex flex-justify-center ${hideIcon && !overflowWrap ? 'show-sort-on-hover-margin' : 'show-sort-on-hover'} ${position === 'right' || position === 'left' ? position : 'items-center'}`}
        style={{ width }}
        onClick={() => setSortingState({})}
      >
        <span
          className={`${overflowWrap ? 'overflow-wrap' : ''}`}
          style={{ width: textWidth }}
        >
          {text}
        </span>
        <span className="h6 w-0">
          <i className="fa fa-caret-up ml1" />
        </span>
      </div>
    );
  }
  return (
    <div
      className={`cursor-pointer ${hideIcon && !overflowWrap ? 'show-sort-on-hover-margin' : 'show-sort-on-hover'} flex flex-justify-center ${position === 'right' || position === 'left' ? position : 'items-center'}`}
      style={{ width }}
      onClick={() => setSortingState({ field, order: 'DESC', kpi })}
    >
      <span
        className={`${overflowWrap ? 'overflow-wrap' : ''}`}
        style={{ width: textWidth }}
      >
        {text}
      </span>
      <span className={`h6 ${hideIcon ? 'w-0' : ''}`}>
        <i className={`fa fa-sort ${hideIcon ? 'w-0 opacity-0' : 'ml1'}`} />
      </span>
    </div>
  );
};

SorterHeader.propTypes = {
  sorting: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  hideIcon: PropTypes.bool,
  kpi: PropTypes.number,
  setSortingState: PropTypes.func.isRequired,
  toggleSortingOrder: PropTypes.func.isRequired,
  position: PropTypes.string,
  width: PropTypes.string,
  textWidth: PropTypes.string,
  overflowWrap: PropTypes.bool,
};

SorterHeader.defaultProps = {
  kpi: null,
  hideIcon: false,
  position: '',
  width: 'auto',
  textWidth: null,
  overflowWrap: false,
};

const mapStateToProps = state => ({
  sorting: state.sorting,
});

const mapDispatchToProps = dispatch => ({
  setSortingState: (sorting) => dispatch(setSorting(sorting)),
  toggleSortingOrder: () => dispatch(toggleOrder()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SorterHeader);
