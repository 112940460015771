import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import HtmlComponent from '../tab_view/HtmlComponent';


class ConfirmationModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    onSubmit: PropTypes.func.isRequired,
    cancelText: PropTypes.string,
    height: PropTypes.string,
    disabledConfirm: PropTypes.bool,
  }

  static defaultProps = {
    cancelText: null,
    height: 'auto',
    disabledConfirm: false,
  }

  state = {
    saving: false,
  }

  submit = () => {
    const { onSubmit, closeModal } = this.props;

    this.setState({ saving: true }, async () => {
      await onSubmit();
      closeModal();
      this.setState({ saving: false });
    });
  }

  render() {
    const { show, closeModal, title, text, cancelText, height, disabledConfirm } = this.props;
    const { saving } = this.state;

    return (
      <Modal
        className="bg-white flex flex-column mb2 react_modal border-top border-blue bw-4"
        show={show}
        onClickOutside={() => closeModal('cancel')}
      >
        <div
          className="flex flex-auto flex-justify-end cursor-pointer p1"
          onClick={() => closeModal('cancel')}
        >
          <i className="fa fa-close text-gray" />
        </div>
        <div className="col-12 flex flex-column flex-center sm-block bg-white p3 sm-center">
          <p className="fw400 h2">{title}</p>
          <div className="mt2" style={{ height, overflow: 'scroll' }}>
            {typeof text === 'string' ?
              <HtmlComponent data={{ html: text }} />
              :
              text()
            }
          </div>
          <div className={`flex flex-justify-between items-center mt2 ${cancelText ? 'col-5' : ''}`}>
            {cancelText &&
              <div
                className="cursor-pointer text-blue"
                onClick={() => closeModal('cancel')}
              >
                {cancelText}
              </div>
            }
            {saving ?
              <img src={window.images.loading} alt="Please wait..." width="32" height="32" className="mb1" />
              :
              <div className={`text-white semi-bold p1 px2 rounded ${disabledConfirm ? 'bg-wild-sand cursor-disabled' : 'bg-blue cursor-pointer'}`} onClick={disabledConfirm ? null : this.submit}>
                Confirm
              </div>
            }
          </div>
        </div>
      </Modal>
    );
  }
}

export default ConfirmationModal;
