import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { validateEmail } from '../utils/validate_email';
import RecipientsList from './RecipientsList';
import ContactsList from './ContactsList';
import TextInput from '../TextInput';

/* const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
*/

class ManageContacts extends Component {
  static propTypes = {
    contacts: PropTypes.array,
    groups: PropTypes.array,
    externalUsers: PropTypes.array,
    nodes: PropTypes.array,
    checkedNodes: PropTypes.array,
    expandedNodes: PropTypes.array,
    allContacts: PropTypes.array,
    addRecipients: PropTypes.func,
    removeRecipients: PropTypes.func,
    handleEmailInput: PropTypes.func,
    handleGroupContactSelect: PropTypes.func,
    toogleModal: PropTypes.func,
    canAddExternalRecipient: PropTypes.bool,
    withSearch: PropTypes.bool,
    withExternalRecipients: PropTypes.bool,
    withContacts: PropTypes.bool,
  }

  static defaultProps = {
    contacts: null,
    groups: null,
    externalUsers: null,
    nodes: [],
    checkedNodes: [],
    expandedNodes: [],
    allContacts: [],
    addRecipients: null,
    removeRecipients: null,
    handleEmailInput: null,
    handleGroupContactSelect: null,
    toogleModal: null,
    canAddExternalRecipient: true,
    withSearch: true,
    withExternalRecipients: true,
    withContacts: true,
  }

  state = {
    searchField: '',
    showTextInput: true,
  };

  searchResults = () => {
    const { allContacts } = this.props;
    const { searchField } = this.state;

    return allContacts.filter(recipient => (searchField ? recipient.email.includes(searchField) : false));
  }

  conditionToHighlight = () => {
    const { searchField } = this.state;
    const results = this.searchResults();

    return false;
    // highliting disabled for now
    // return results.length === 1 && results[0].can_be_highlighted && results[0].email === searchField;
  }

  handleExternalSingleRecipientSelect = (email) => {
    const { externalUsers, handleEmailInput } = this.props;
    const { searchField } = this.state;

    if (searchField.length === 0) {
      App.State.setFlash({ name: 'alert', msg: 'No Email found!' });
    } else
    if (!validateEmail(searchField)) {
      App.State.setFlash({ name: 'alert', msg: 'Email format not recognized! Enter a single valid email address.' });
    } else
    if (externalUsers.find(eu => eu.email === email)) {
      App.State.setFlash({ name: 'alert', msg: 'Email has already been added!' });
    } else
    if (this.searchResults().find(r => r.email === email)) {
      App.State.setFlash({ name: 'alert', msg: "Can't add existing user as an external one." }); // TODO: remove when search gets reactivated
    } else {
      handleEmailInput(email).then(
        () => this.setState({ showTextInput: false, searchField: '' }),
        () => App.State.setFlash({ name: 'alert', msg: 'Sorry, but an error happened while adding the recipient.' }),
      ).then(
        () => this.setState({ showTextInput: true }),
      );
    }
  }

  handleEmailInput = (e) => {
    const { searchField } = this.state;

    const results = this.searchResults();

    if (e && e.key === 'Enter') {
      if (results.length === 0 || (results.length === 1 && results[0].email !== searchField)) {
        this.handleExternalSingleRecipientSelect(searchField);
      } else if (!this.conditionToHighlight()) {
        App.State.setFlash({ name: 'alert', msg: "Can't add existing user as an external one." });
      } else {
        // Highliting is out for now
        // this.highlightInvestorMember(results[0]);
      }
      // else {
      // TODO: reactivate when search gets reactivated
      // App.State.setFlash({ name: 'alert', msg: 'Please, choose the contact you want to add.' });
      // }
    }
  }

  render() {
    const {
      contacts,
      groups,
      nodes,
      checkedNodes,
      expandedNodes,
      externalUsers,
      addRecipients,
      removeRecipients,
      handleGroupContactSelect,
      canAddExternalRecipient,
      toogleModal,
      withSearch,
      withExternalRecipients,
      withContacts,
    } = this.props;

    const { searchField, showTextInput } = this.state;

    return (
      <div className="col-12">
        {withSearch &&
          <div className="px1 py05 border border-left border-bottom border-right border-alto rounded">
            <div className="flex flex-center flex-justify-between">
              <div className="flex flex-center col-12">
                {showTextInput &&
                  <TextInput
                    className="col-12 border-none"
                    value={searchField}
                    onChange={e => this.setState({ searchField: e.target.value })}
                    name="searchField"
                    onKeyPress={this.handleEmailInput}
                    style={{ height: '24px' }}
                    placeholder="example@address.com"
                  />
                }
              </div>
              {/* Functionality to be reactivated when multiple groups need to be managed
              <span
                className={`cursor-pointer fa fa-angle-${showContactsList ? 'down' : 'right'}`}
                style={{ fontWeight: 'bold' }}
                onClick={() => toogleContactsList()}
              />
              */}
            </div>
          </div>
        }
        <div className="pt1" />
        <div className="px1 border border-alto rounded">
          <div className="relative">
            <div className="bg-white absolute mtn1" style={{ zIndex: '12' }}>
              <ContactsList
                canAddExternalRecipient={canAddExternalRecipient}
                show={searchField.length > 0}
                contacts={contacts}
                searchResults={this.searchResults()}
                searchField={searchField}
                handleExternalSingleRecipientSelect={this.handleExternalSingleRecipientSelect}
                handleGroupContactSelect={handleGroupContactSelect}
                // addExistingContactRecipient={this.addExistingContactRecipient}
                // highlightInvestorMember={this.highlightInvestorMember}
                // toogleContactsList={toogleContactsList}
                toogleModal={toogleModal}
                // conditionToHighlight={this.conditionToHighlight}
                withExternalRecipients={withExternalRecipients}
              />
            </div>
          </div>
          <RecipientsList
            show
            groups={withContacts ? groups.sort((a, b) => a.name.localeCompare(b.name)) : null}
            externalUsers={externalUsers}
            nodes={nodes}
            checkedNodes={checkedNodes}
            expandedNodes={expandedNodes}
            addRecipients={addRecipients}
            removeRecipients={removeRecipients}
            withExternalRecipients={withExternalRecipients}
            withContacts={withContacts}
          />
        </div>
      </div>
    );
  }
}

export default ManageContacts;
