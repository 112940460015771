import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import classNames from 'classnames';

import DatePicker from '../../../../selects/DatePicker';
import TextInputProps from '../../../../TextInputProps';
import CustomAutocomplete from '../../../../CustomAutocomplete';

import CleaveNumberFormatting from '../../../../CleaveNumberFormatting';
import { defaultSelectStyles, defaultSelectTheme, defaultBorderlessStyles } from '../../../../selects/SelectStyles';
import ConfirmationModal from '../../../../ConfirmationModal';


class FundingShareData extends Component {
  static propTypes = {
    share: PropTypes.object.isRequired,
    funding: PropTypes.object.isRequired,
    isCustom: PropTypes.bool.isRequired,
    fieldWidth: PropTypes.string.isRequired,
    fieldWidthForSelect: PropTypes.string.isRequired,
    fieldWidthForAssignedTo: PropTypes.string.isRequired,
    fieldWidthForSecuritySelect: PropTypes.string.isRequired,
    fieldWidthForCurrentValue: PropTypes.string.isRequired,
    fieldWidthForCurrency: PropTypes.string.isRequired,
    saveFundingShare: PropTypes.func.isRequired,
    createFundingRound: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      securityTypeOption: props.share.share_type_field ? 'free_text' : 'custom',
      showAutocomplete: true,
    };
  }

  dropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <i className="fa fa-caret-down text-blue" />
    </components.DropdownIndicator>
  );

  handleAutcompleteChange = async (params) => {
    const { share, saveFundingShare } = this.props;

    if ((params && share.assigned_to) && params.assigned_to.trim() === share.assigned_to.trim()) {
      return;
    }

    await saveFundingShare(share.id, params);
    this.setState(state => ({
      ...state,
      showAutocomplete: false,
    }), () => {
      this.setState({ showAutocomplete: true });
    });
  }

  handleSecuritySave = async (option, shareId, data) => {
    const { saveFundingShare } = this.props;

    let securityData = {};

    if (option === 'custom') {
      securityData = {
        security_id: data ? data.value : null,
        security_type: data ? data.type : null,
        share_type: null,
      };
    } else {
      securityData = {
        security_id: null,
        security_type: null,
        share_type: data.value,
      };
    }

    await saveFundingShare(shareId, securityData);
  }

  handleSecurityTypeSelect = (option, shareId) => {
    this.setState({ securityTypeOption: option }, () => {
      let newState = {};
      if (option === 'custom') {
        newState = { share_type: null };
      } else {
        newState = { security_id: null, security_type: null };
      }

      this.handleSecuritySave(option, shareId, newState);
    });
  }

  render() {
    const {
      share,
      funding,
      isCustom,
      fieldWidth,
      fieldWidthForSelect,
      fieldWidthForSecuritySelect,
      fieldWidthForAssignedTo,
      fieldWidthForCurrentValue,
      fieldWidthForCurrency,
      saveFundingShare,
      createFundingRound,
    } = this.props;

    const { securityTypeOption, showAutocomplete } = this.state;

    const inputStyling = {
      padding: funding.edit ? null : '0',
    };

    const inputClasses = classNames('col-10', {
      'p1 border border-lighter-gray': funding.edit,
      'hover hover-border-blue': funding.edit && isCustom,
      'bg-lighter-gray': funding.edit && !isCustom,
      'bg-none border-transparent': !funding.edit,
    });

    const roundsForOptions = funding.rounds.map(round => ({ value: round.id, label: round.name }));
    const securityTypeOptions = [{ value: 'custom', label: 'custom' }, { value: 'free_text', label: 'free text' }];
    const getCorrectSecurityOption = (option) => {
      if (option.type === 'Investor::FundingShareClass') {
        return option.value === (share.share_class && share.share_class.id);
      }
      return option.value === (share.convertible && share.convertible.id);
    };

    return (
      <div key={`share_${share.id}}`} className="flex items-center py1 border-bottom border-lighter-gray">
        <div className="flex items-center" style={{ width: fieldWidth }}>
          <DatePicker
            className={inputClasses}
            value={(share.date_of_investment || share.closing_date || '').toString()}
            onBlur={e => saveFundingShare(share.id, { date_of_investment: e.target.value })}
            disabled={!funding.edit || !isCustom}
            style={inputStyling}
          />
        </div>

        <div style={{ width: fieldWidthForSelect }}>
          {(!funding.edit || !isCustom) ?
            <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
              {share.round_name || '\u2013'}
            </div>
            :
            <div className="col-10">
              {roundsForOptions.length > 0 ?
                <ReactSelect
                  value={roundsForOptions.filter(option => option.value === share.funding_round_id)}
                  options={roundsForOptions}
                  onChange={selectedOption => saveFundingShare(share.id, { funding_round_id: selectedOption ? selectedOption.value : null })}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                  isClearable
                  placeholder={roundsForOptions.length > 0 ? 'Select...' : 'None'}
                  menuPortalTarget={document.body}
                />
                :
                <div className="flex">
                  {funding.custom_investment ?
                    <div
                      className="px1 mr1 h6 bg-black--transparent text-white cursor-pointer rounded"
                      onClick={createFundingRound}
                    >
                      <i className="fa fa-plus mr1" />
                      <span>Add Round</span>
                    </div>
                    :
                    <ConfirmationModal
                      html='<div><i class="fa fa-plus mr1"></i><span>Add Round</span></div>'
                      className="px1 mr1 h6 bg-black--transparent text-white cursor-pointer rounded"
                      onClick={createFundingRound}
                      message="<b>Please note adding rounds will copy company rounds data into custom rounds data. Are you sure you want to continue?"
                      width="auto"
                    />
                  }
                </div>
              }
            </div>
          }
        </div>

        {isCustom &&
          <div style={{ width: fieldWidthForSelect }}>
            {!funding.edit ?
              <div className={`${inputClasses} flex items-center capitalize`} style={{ height: '36px', lineHeight: 'normal' }}>
                {share.readable_transaction_type || '\u2013'}
              </div>
              :
              <div className="col-10">
                <ReactSelect
                  value={funding.options.transaction_types_for_options.filter(option => option.value === share.transaction_type)}
                  options={funding.options.transaction_types_for_options}
                  onChange={selectedOption => { saveFundingShare(share.id, { transaction_type: selectedOption ? selectedOption.value : null }); }}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                  isClearable
                  menuPortalTarget={document.body}
                />
              </div>
            }
          </div>
        }
        <div className="relative" style={{ width: fieldWidthForSecuritySelect }}>
          {isCustom ?
            <div className={`flex items-center ${securityTypeOption === 'custom' ? 'col-11' : 'col-12'}`}>
              {funding.edit ?
                <>
                  <ReactSelect
                    value={securityTypeOptions.filter(option => option.value === securityTypeOption)}
                    options={securityTypeOptions}
                    onChange={selectedOption => this.handleSecurityTypeSelect(selectedOption.value, share.id)}
                    styles={defaultBorderlessStyles}
                    theme={defaultSelectTheme}
                    className="mr1 col-4"
                    menuPortalTarget={document.body}
                    components={{ DropdownIndicator: this.dropdownIndicator, IndicatorSeparator: () => null }}
                  />
                  {
                    securityTypeOption === 'custom' ?
                      (
                        <ReactSelect
                          value={funding.options.security_type_options.filter(option => getCorrectSecurityOption(option))}
                          options={funding.options.security_type_options}
                          onChange={selectedOption => this.handleSecuritySave(securityTypeOption, share.id, selectedOption)}
                          styles={defaultSelectStyles}
                          theme={defaultSelectTheme}
                          className="col-8"
                          isClearable
                          menuPortalTarget={document.body}
                        />
                      )
                      :
                      (
                        <div className="flex col-8">
                          <TextInputProps
                            className={inputClasses}
                            onBlur={e => this.handleSecuritySave(securityTypeOption, share.id, { value: e.target.value })}
                            value={share.share_type || ''}
                          />
                        </div>
                      )
                  }
                </>
                :
                <div className={`${inputClasses} flex items-center`} style={{ ...inputStyling, height: '36px' }}>
                  <span>{share.share_type || '-' }</span>
                </div>
              }
            </div>
            :
            <div className={`${inputClasses} flex items-center`} style={{ ...inputStyling, height: '36px' }}>
              <span>{share.readable_security_type || '-'}</span>
            </div>
          }
        </div>

        {isCustom &&
          <div style={{ width: fieldWidthForAssignedTo }}>
            <div className="relative">
              {/* 'showAutocomplete' state is to handle the issue of the input field focus after selecting an element from the autocomplete.
                As 2 events are expected from the autocomplete (onBlur & onSelect) an issue was happening. */}
              {showAutocomplete &&
                <CustomAutocomplete
                  className={`${inputClasses} col-12`}
                  wrapperClass="col-10"
                  value={share.assigned_to || ''}
                  items={funding.options.assignable_options}
                  onBlur={e => this.handleAutcompleteChange({ partner_name: e.target.value, assignable_id: null, assignable_type: null, assigned_to: e.target.value })}
                  onSelect={item => this.handleAutcompleteChange({ assignable_id: item.assignable_id, assignable_type: item.assignable_type, partner_name: null, assigned_to: item.value })}
                  disabled={!funding.edit}
                  baseKey="assignable_id"
                  secondKey="value"
                />
              }
            </div>
          </div>
        }

        <div style={{ width: fieldWidth }}>
          {(!funding.edit || !isCustom) && share.readable_no_of_shares ?
            <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
              {share.no_of_shares}
            </div>
            :
            <CleaveNumberFormatting
              className={inputClasses}
              value={(share.no_of_shares || '').toString()}
              onBlur={e => saveFundingShare(share.id, { no_of_shares: e.target.rawValue })}
              disabled={!funding.edit || !isCustom}
              style={inputStyling}
            />
          }
        </div>

        <div style={{ width: fieldWidth }}>
          <CleaveNumberFormatting
            className={inputClasses}
            value={(share.ownership || '').toString()}
            onBlur={e => saveFundingShare(share.id, { ownership: e.target.rawValue })}
            disabled={!funding.edit || !isCustom}
            style={inputStyling}
          />
        </div>

        <div style={{ width: fieldWidth }}>
          {(!funding.edit || !isCustom) && share.readable_invested ?
            <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
              {share.invested}
            </div>
            :
            <CleaveNumberFormatting
              className={inputClasses}
              value={(share.invested || '').toString()}
              onBlur={e => saveFundingShare(share.id, { invested: e.target.rawValue })}
              disabled={!funding.edit || !isCustom}
              style={inputStyling}
            />
          }
        </div>
        {isCustom &&
          <div style={{ width: fieldWidth }}>
            {!funding.edit && share.readable_realized_cashflow ?
              <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
                {share.realized_cashflow}
              </div>
              :
              <CleaveNumberFormatting
                className={inputClasses}
                value={(share.realized_cashflow || '').toString()}
                onBlur={e => saveFundingShare(share.id, { realized_cashflow: e.target.rawValue })}
                disabled={!funding.edit || !isCustom}
                style={inputStyling}
              />
            }
          </div>
        }
        {isCustom &&
          <>
            <div style={{ width: fieldWidthForCurrency }}>
              {!funding.edit ?
                <div className={`${inputClasses} flex items-center capitalize`} style={{ height: '36px', lineHeight: 'normal' }}>
                  {share.invested_currency || '\u2013'}
                </div>
                :
                <div className="col-10">
                  <ReactSelect
                    value={funding.options.currency_options.filter(option => option.value === share.invested_currency)}
                    options={funding.options.currency_options}
                    onChange={selectedOption => { saveFundingShare(share.id, { invested_currency: selectedOption ? selectedOption.value : null }); }}
                    styles={defaultSelectStyles}
                    theme={defaultSelectTheme}
                    isClearable
                    menuPortalTarget={document.body}
                  />
                </div>
              }
            </div>
            <div style={{ width: fieldWidth }}>
              {!funding.edit && share.readable_invested_cashflow_custom ?
                <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
                  {share.invested_cashflow_custom}
                </div>
                :
                <CleaveNumberFormatting
                  className={inputClasses}
                  value={(share.invested_cashflow_custom || '').toString()}
                  onBlur={e => saveFundingShare(share.id, { invested_cashflow_custom: e.target.rawValue })}
                  disabled={!funding.edit || !isCustom}
                  style={inputStyling}
                />
              }
            </div>
            <div style={{ width: fieldWidth }}>
              {!funding.edit && share.readable_realized_cashflow_custom ?
                <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
                  {share.realized_cashflow_custom}
                </div>
                :
                <CleaveNumberFormatting
                  className={inputClasses}
                  value={(share.realized_cashflow_custom || '').toString()}
                  onBlur={e => saveFundingShare(share.id, { realized_cashflow_custom: e.target.rawValue })}
                  disabled={!funding.edit || !isCustom}
                  style={inputStyling}
                />
              }
            </div>
          </>
        }
        <div style={{ width: fieldWidthForCurrentValue }}>
          {(!funding.edit || !isCustom) && share.readable_amount ?
            <div className={`${inputClasses} flex items-center`} style={{ height: '36px', lineHeight: 'normal' }}>
              {share.amount}
            </div>
            :
            <CleaveNumberFormatting
              className={inputClasses}
              value={(share.amount || '').toString()}
              onBlur={e => saveFundingShare(share.id, { amount: e.target.rawValue })}
              disabled={!funding.edit || !isCustom}
              style={inputStyling}
            />
          }
        </div>
      </div>
    );
  }
}

export default FundingShareData;
