import axios from 'axios';

import {
  subscribeInvestor,
  unsubscribeInvestor,
  resubscribeInvestor,
} from '../../actions/investor/paymentActions';

const subscribe = params =>
  async (dispatch, getState) => {
    const investorId = getState().investor.id;
    const res = await axios.post(`/investor/${investorId}/subscription`, params);
    dispatch(subscribeInvestor(res.data));
  };

const unsubscribe = () =>
  async (dispatch, getState) => {
    const investorId = getState().investor.id;
    const res = await axios.delete(`/investor/${investorId}/subscription`);
    dispatch(unsubscribeInvestor(res.data));
  };

const resubscribe = params =>
  async (dispatch, getState) => {
    const investorId = getState().investor.id;
    const res = await axios.patch(`/investor/${investorId}/subscription/reactivate`, params);
    dispatch(resubscribeInvestor(res.data));
  };
export {
  subscribe,
  unsubscribe,
  resubscribe,
};
