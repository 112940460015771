import React, { Component } from 'react';
import PropTypes from 'prop-types';


class LinkTo extends Component {
  static propTypes = {
    url: PropTypes.string,
    css_id: PropTypes.string,
    css_class: PropTypes.string,
    used_css_class: PropTypes.string,
    html_method: PropTypes.string,
    remote: PropTypes.bool,
    text: PropTypes.string,
    used_text: PropTypes.string,
    used: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.state = {
      url: props.url,
      css_id: props.css_id,
      css_class: props.css_class,
      used_css_class: props.used_css_class,
      html_method: props.html_method,
      text: props.text,
      used_text: props.used_text,
      used: props.used ? props.used : false,
    };
  }

  ajax = (url, method) => (
    new Promise((resolve) => {
      $.ajax({
        url,
        type: method,
        success: resolve,
      });
    })
  )

  clickOnLink = () => {
    this.setState({ used: true });
    if (this.props.remote) {
      this.ajax(this.state.url, this.state.html_method)
        .then((data) => {
          this.setState(data);
          App.State.setFlash(data.flash);
        });
    }
  }

  createMarkup(text) { return { __html: text }; }

  render() {
    const { remote } = this.props;

    if (this.state.used) {
      return (
        <a
          className={this.state.used_css_class}
          style={{ cursor: 'default' }}
          href={null}
          dangerouslySetInnerHTML={this.createMarkup(this.state.used_text)}
        />
      );
    }
    return (
      <a
        className={this.state.css_class}
        id={this.state.css_id}
        data-method={remote ? null : this.state.html_method}
        href={remote ? '#' : this.state.url}
        dangerouslySetInnerHTML={this.createMarkup(this.state.text)}
        onClick={this.clickOnLink}
      />
    );
  }
}

export default LinkTo;
