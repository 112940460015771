import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from './Modal';
import HtmlComponent from './tab_view/HtmlComponent';


class ConfirmationModalOnComponent extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    link: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.string,
    confirmationText: PropTypes.string,
    cancelText: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    title: 'Warning',
    link: null,
    className: 'col-2 mx1 py1 flex flex-center flex-justify-center rounded bg-green text-white fw400 cursor-pointer',
    width: '100%',
    confirmationText: 'Confirm',
    cancelText: 'Cancel',
    onClick: null,
    disabled: false,
  }

  state = {
    open: false,
  }

  handleClick = () => {
    const { onClick, link } = this.props;

    if (link) window.open(link, '_blank');

    this.setState({ open: false }, () => {
      if (onClick) onClick();
    });
  }

  handleModalOpening = (e) => {
    const { disabled } = this.props;

    e.preventDefault();
    if (!disabled) this.setState({ open: true });
  }


  render() {
    const { children, title, message, className, width, confirmationText, cancelText } = this.props;
    const { open } = this.state;

    return (
      <>
        <div
          className={className}
          style={{ width }}
          onClick={(e) => this.handleModalOpening(e)}
        >
          {children}
        </div>
        <Modal
          className="react_modal--300"
          show={open}
          onClickOutside={() => { this.setState({ open: false }); }}
        >
          <div className="flex flex-justify-between items-center bold p2 border-bottom border-lighter-gray">
            <div className="h3">{title}</div>
            <i className="fa fa-close h5 cursor-pointer" onClick={(e) => { e.preventDefault(); this.setState({ open: false }); }} />
          </div>

          <div className="bg-white p3">
            <div className="flex flex-center flex-justify-center text-gray">
              <HtmlComponent data={{ html: message }} />
            </div>
            <div className="pt3 flex flex-center flex-justify-around">
              <div
                href="#"
                className="col-3 center py1 bg-light-gray rounded text-white cursor-pointer"
                onClick={(e) => { e.preventDefault(); this.setState({ open: false }); }}
              >
                {cancelText}
              </div>

              <span
                className="col-3 center py1 bg-green rounded text-white cursor-pointer"
                onClick={() => { this.handleClick(); }}
              >
                {confirmationText}
              </span>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default ConfirmationModalOnComponent;
