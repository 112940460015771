import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CheckBox from '../../CheckBox';

import SorterHeader from '../../SorterHeader';

class InvestorCustomReminderInvestmentCompletionHeader extends Component {
  static propTypes = {
    items: PropTypes.object.isRequired,
  }

  state = {
    selectedAll: false,
  }

  handleSelectAll = () => {
    const { selectedAll } = this.state;

    this.setState({ selectedAll: !selectedAll }, () => {
      const { selectedAll: newSelectAllState } = this.state;
      if (newSelectAllState) {
        const newList = this.filteredItems().map(element => ({ id: element.id }));
        store.dispatch({ type: 'SET_LIST', list: 'selected', value: newList });
      } else {
        store.dispatch({ type: 'SET_LIST', list: 'selected', value: [] });
      }
    });
  }

  filteredItems = () => {
    const { items } = this.props;

    return items.main.filter(element => !element.hide && !element.alwaysTop);
  }

  render() {
    return (
      <div>
        <div className="col-12 flex bg-extra-light-gray px2">
          <div className="col-2" />
          <div className="width-45p fw400 border-bottom border-light-gray mt1">Last month data for:</div>
        </div>
        <div className="col-12 text-gray h5 flex flex-justify-center items-center px2 bg-extra-light-gray">
          <div className="width-30p flex py1 bold sm-col-6 sm-pl0">
            <SorterHeader text="Investment" field="name" truncate={false} />
          </div>
          <div
            className="width-20p flex flex-justify-center items-center py1 bold sm-pl0"
            data-balloon="Last month cap table updated."
            data-balloon-pos="up"
            data-balloon-length="large"
          >
            <SorterHeader text="Cap table" field="raw_date_of_latest_cap_table_data" truncate={false} />
          </div>
          <div
            className="width-20p flex flex-justify-center items-center py1 bold sm-col-6 sm-pl0"
            data-balloon="Last month with scheduled update received."
            data-balloon-pos="up"
            data-balloon-length="large"
          >
            <SorterHeader text="Updates" field="raw_date_of_latest_update_received" truncate={false} />
          </div>
          <div
            className="width-20p flex flex-justify-center items-center py1 bold sm-col-6 sm-pl0"
            data-balloon="Last month with KPIs."
            data-balloon-pos="up"
            data-balloon-length="medium"
          >
            <SorterHeader text="KPIs" field="raw_date_of_latest_kpi_data" truncate={false} />
          </div>
          <div
            className="width-10p flex flex-justify-center items-center py1 bold sm-col-6 sm-pl0"
            data-balloon="% KPIs updated on that month."
            data-balloon-pos="up"
            data-balloon-length="large"
          >
            <SorterHeader text="KPIS %" field="kpis_completion" truncate={false} />
          </div>
          <div
            className="width-20p flex flex-justify-center items-center py1 bold sm-col-6"
            data-balloon="This is the date of the last reminder sent by you (investor) from this console (manual or automatic)."
            data-balloon-pos="up"
            data-balloon-length="xlarge"
          >
            <SorterHeader text="Last Investor Reminder Sent" field="raw_last_reminder_sent" width="70%" />
          </div>
          <div
            className="width-20p flex flex-justify-center items-center py1 sm-col-6"
            // data-balloon="These are the settings this company has for update reminders (independent of the investor). (X/Y) is the number of users that receive reminders out of the total number of users the company has (in case it's a custom investment, the reminder will be sent out to the investment managers). Quarterly/monthly etc is the frequency they receive reminders. We send them on the 15th and 18th of the relevant months."
            data-balloon="In addition to Reminders sent by Investors from this console, companies also receive Update Reminders sent automatically by the platform. In this column, you can see the settings connected companies have for Update Reminders, so can you avoid spamming them too much. (X/Y) is the number of company team members that are set up to receive platform reminders out of the total number of team members the company has. Quarterly/monthly etc is the frequency they receive these reminders. We send them on the 15th and 18th of the relevant months."
            data-balloon-pos="up"
            data-balloon-length="xlarge"
          >
            <span className="fw400" style={{ width: '70%' }}>Company Update Reminders Setting</span>
          </div>
          <div className="width-20p flex flex-justify-center items-center py1 sm-col-6">
            <div className="flex fw400 flex-justify-center items-center" style={{ width: '70%' }}>
              <span className="mr1">Select to send Manual Investor Reminder Now</span>
              <div
                data-balloon="Select All"
                data-balloon-pos="top"
                data-balloon-length="small"
              >
                <CheckBox
                  defaultChecked={false}
                  onChange={() => this.handleSelectAll()}
                  fixedWidth
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => (
  {
    items: store.get('items').toJS(),
  }
);

export default connect(mapStateToProps)(InvestorCustomReminderInvestmentCompletionHeader);
