import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HtmlComponent from './HtmlComponent';
import Recipient from './Recipient';
import FaButton from './FaButton';
import FaButtonFunction from './FaButtonFunction';


class VerticalTabViewContainer extends Component {
  static propTypes = {
    items: PropTypes.object,
    placeholders: PropTypes.object,
    bgColors: PropTypes.object,
    tabs: PropTypes.array,
    activeTab: PropTypes.string,
    searchEnabled: PropTypes.bool,
    withCheckBox: PropTypes.object,
  }

  getRecipientsIds = (tab) => {
    const { items } = this.props;

    const unflattenArrayOfRecipientsIds = items[tab].map(el => el.recipients_ids);
    return [].concat(...unflattenArrayOfRecipientsIds);
  }

  getUsersIds = (tab) => {
    const { items } = this.props;

    const unflattenArrayOfUsersIds = items[tab].map(el => el.users_ids);
    return [].concat(...unflattenArrayOfUsersIds);
  }

  addAllRecipients = (tab) => {
    const { withCheckBox } = this.props;
    App.Api.post(withCheckBox.manage_urls[tab], { type: tab, users_ids: this.getUsersIds(tab), select_all: true }).then((returnedData) => {
      if (returnedData) {
        if (returnedData.error) {
          store.dispatch({ type: 'SET_LIST', list: tab, value: returnedData.data });
          store.dispatch({ type: 'SET_CHECK_BOX', list: tab, value: withCheckBox.checked[tab] });

          App.State.setFlash({ name: 'alert', msg: returnedData.error });
        } else {
          store.dispatch({ type: 'SET_LIST', list: tab, value: returnedData });
          store.dispatch({ type: 'SET_CHECK_BOX', list: tab, value: true });
        }
      }
    });
  }

  checkBox = (tab) => {
    const { withCheckBox } = this.props;
    if (jQuery.isEmptyObject(withCheckBox) || !withCheckBox.items.includes(tab)) return null;
    if (withCheckBox.disabled[tab]) {
      return (
        <FaButton
          faIcon="square"
          color="alto"
          size="h2"
          margin="mr1 align-middle"
          disabled
        />
      );
    }
    if (withCheckBox.checked[tab]) {
      return (
        <FaButtonFunction
          key={`check_${Date.now()}`}
          faIcon="check-square"
          tooltip="Unselect All"
          tooltipPos="right"
          handleClick={() => this.destroyAllRecipient(tab)}
          color="green"
          size="h2"
          margin="mr1 align-middle"
        />
      );
    }
    return (
      <FaButtonFunction
        key={`uncheck_${Date.now()}`}
        faIcon="square-o"
        tooltip="Select All"
        tooltipPos="right"
        handleClick={() => this.addAllRecipients(tab)}
        color="alto"
        size="h2"
        margin="mr1 align-middle"
      />
    );
  }

  components = {
    HtmlComponent,
    Recipient,
  }

  destroyAllRecipient = (tab) => {
    const { withCheckBox } = this.props;
    App.Api.delete(withCheckBox.manage_urls[tab], { type: tab, recipients_ids: this.getRecipientsIds(tab), unselect_all: true }).then((returnedData) => {
      if (returnedData.error) {
        store.dispatch({ type: 'SET_LIST', list: tab, value: returnedData.data });
        store.dispatch({ type: 'SET_CHECK_BOX', list: tab, value: withCheckBox.checked[tab] });
        App.State.setFlash({ name: 'alert', msg: returnedData.error });
      } else {
        store.dispatch({ type: 'SET_LIST', list: tab, value: returnedData });
        store.dispatch({ type: 'SET_CHECK_BOX', list: tab, value: false });
      }
    });
  }

  tabClass = (tab) => {
    const { activeTab, bgColors } = this.props;

    if (activeTab === tab) {
      return `vertical-active z1 h4 semi-bold p2 pl3 capitalize ${bgColors.tabs}`;
    }
    return 'vertical-inactive h4 semi-bold p2 pl3 capitalize cursor-pointer';
  }

  renderTabs = () => {
    const { tabs } = this.props;

    if (tabs.length < 2) {
      return null;
    }
    return tabs.map(tab =>
      (
        <div
          key={tab}
          className={this.tabClass(tab)}
          onClick={() => { store.dispatch({ type: 'SET_TAB', value: tab }); }}
        >
          {this.checkBox(tab)} {tab}
        </div>
      ));
  }

  renderView = () => {
    const { items, activeTab, placeholders } = this.props;
    const activeTabItems = items[activeTab];

    if (activeTabItems.length === 0) {
      return (<HtmlComponent data={placeholders[activeTab]} />);
    }
    return activeTabItems.map((item, index) => {
      const ItemComponent = this.components[item.component];
      return (
        <ItemComponent
          key={item.key}
          data={item}
          activeTab={activeTab}
          is_last_item={index === activeTabItems.length - 1}
        />
      );
    });
  }

  render() {
    const { bgColors } = this.props;

    return (
      <div className="flex col-12 bg-white border border-alto">
        <div className="flex flex-column col-3 tab-trick-header">
          {this.renderTabs()}
        </div>
        <div className={`col-9 border-left border-alto overflow-y-auto pt1 ${bgColors.view}`} style={{ height: '40vh', maxHeight: '450px' }}>
          {this.renderView()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => (
  {
    items: store.get('items').toJS(),
    tabs: store.get('tabs').toJS(),
    activeTab: store.get('activeTab'),
    withCheckBox: store.get('withCheckBox').toJS(),
  }
);

export default connect(mapStateToProps)(VerticalTabViewContainer);
