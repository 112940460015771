import { connect } from 'react-redux';
import DataRoomEditForm from '../../../components/company/data_rooms/DataRoomEditForm';


const mapStateToProps = state => ({
  company: state.company,
  dataRoom: state.dataRoom,
  dataRoomers: state.dataRoomers,
});

export default connect(mapStateToProps)(DataRoomEditForm);
