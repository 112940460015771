import React from 'react';
import createReactClass from 'create-react-class';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import SetIntervalMixin from '../utils/SetIntervalMixin';
import CommentForm from './CommentFormSh';

export default createReactClass({
  displayName: 'CommentBoxSh',
  mixins: [SetIntervalMixin],

  getInitialState: function() {
    return {
      comments: this.props.post.comments,
      commentBody: ''
    };
  },

  componentDidMount: function() {
    this.setInterval(this.getComments, 60000);
  },

  displayCommentBox() {
    if (this.props.canComment) {
      return (
        <CommentForm
          submitComment={ this.submitComment }
          setCommentBody={ this.setCommentBody }
          authorAvatar={ this.props.authorAvatar }
          commentBody={ this.state.commentBody }
        />
      );
    }
    return (
      null
    );
  },

  render: function() {
    var comments = this.state.comments.map(function( comment ) {
      return <Comment key={ comment.id } comment={ comment } />;
    });

    const commentsContainer = this.state.comments.length ? <div className="comments__list mb2">{comments}</div> : '';

    return (
      <div>
        <div className="mxn2">
          <div className="px2">
            <ReactCSSTransitionGroup transitionName="fade" transitionEnterTimeout={500} transitionLeaveTimeout={300} >
              { commentsContainer }
            </ReactCSSTransitionGroup>
            {this.displayCommentBox()}
          </div>
        </div>
      </div>
    );
  },

  setCommentBody: function( commentBody ) {
    this.setState({
      commentBody: commentBody
    });
  },

  submitComment: function() {
    $.ajax({
      data: {
        comment: {
          body: this.state.commentBody
        }
      },
      url: this.props.post.comments_url,
      type: "POST",
      dataType: "json",
      success: function ( data ) {
        this.setState({
          comments: data.comments,
          commentBody: ''
        });
        App.feedbackLoops('comment');
        Intercom('trackEvent', 'comment_posted');
      }.bind(this)
    });
  },

  getComments: function() {
    if ( this.state.commentBody.length > 0 ) return;

    $.ajax({
      url: this.props.post.url,
      type: "GET",
      dataType: "json",
      success: function ( data ) {
        this.setState({
          comments: data.comments
        });
      }.bind(this)
    });
  },
});
