import React, { Component } from 'react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';

import dashboardReducer from '../../reducers/dashboard.js.es6';

import DashboardContainer from './DashboardContainer';

function initStore(initalState) {
  return createStore(dashboardReducer, initalState);
}

class Dashboard extends Component {
  componentWillMount() {
    this.store = initStore(this.props);
  }

  render() {
    window.store = this.store;

    return (
      <Provider store={this.store}>
        <DashboardContainer />
      </Provider>
    );
  }
}

export default Dashboard;
