import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import Modal from '../Modal';
import Switch from '../Switch';
import { defaultSelectStyles, defaultSelectTheme } from '../selects/SelectStyles';
import TextInput from '../TextInput';

const categoryOptions = [
  { value: 'co_founder', label: '(Co-)Founder' },
  { value: 'team_member', label: 'Team Member' },
  { value: 'advisor', label: 'Advisor' },
  { value: 'board_member', label: 'Board Member' },
];

const roleOptions = [
  { value: 'ceo', label: 'CEO' },
  { value: 'cto', label: 'CTO' },
  { value: 'coo', label: 'COO' },
  { value: 'cmo', label: 'CMO' },
  { value: 'other', label: 'Other' },
];

class TeamCardEdit extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
    companyRole: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    description: PropTypes.string,
    category: PropTypes.string.isRequired,
    updateAfterSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    description: '',
  }

  constructor(props) {
    super(props);

    const { record } = props;

    this.state = {
      open: false,
      bModalOpened: false,
      companyRole: props.companyRole,
      otherCompanyRole: record.other_company_role,
      isAdmin: props.isAdmin,
      description: props.description,
      category: props.category,
    };
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
    document.addEventListener('touchstart', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
    document.removeEventListener('touchstart', this.handleClick, false);
  }

  handleClick = (e) => {
    if (ReactDOM.findDOMNode(this).contains(e.target)) return;
    this.setState({ open: false });
  }

  handleChange = (name, value) => {
    if (name === 'companyRole') {
      this.setState({ [name]: value, otherCompanyRole: null });
    } else {
      this.setState({ [name]: value });
    }
  }

  toogleModal = () => {
    const { bModalOpened } = this.state;
    this.setState({ bModalOpened: !bModalOpened });
  }

  saveCollaborator = () => {
    const { record, updateAfterSubmit } = this.props;
    const { companyRole, otherCompanyRole, isAdmin, description, category } = this.state;

    App.Api.submitPatch(record.manage_url, { description, category, company_role: companyRole, other_company_role: otherCompanyRole, role: isAdmin ? 'owner' : 'editor' })
      .then(() => {
        this.toogleModal();
        updateAfterSubmit(
          {
            companyRole,
            otherCompanyRole,
            isAdmin,
            category,
            description,
            convertedCategory: categoryOptions.filter(opt => opt.value === category)[0].label,
          },
        );
      })
      .catch(() => {
        App.State.setFlash({ name: 'alert', msg: 'Sorry, something went wrong. Try again later or contact support!' });
      });
  }

  render() {
    const { open, bModalOpened, companyRole, otherCompanyRole, isAdmin, description, category } = this.state;

    return (
      <React.Fragment>
        <div className="absolute position-right-top cursor-pointer">
          <div className="text-light-gray" onClick={() => this.setState({ open: !open })}>
            <i className="fa fa-cog" />
          </div>

          <div className={`dropdown dropdown--center absolute bg-white border border-alto bs-dropdown left-50 transform-horizontal-center z1 ${open ? '' : 'hidden'} py1`}>
            <ul className="left-align m0 mw150">
              <li>
                <div
                  className="bold h5 link-hover--blue block nowrap px1 text-gray cursor-pointer"
                  href="#"
                  onClick={this.toogleModal}
                >
                  <i className="fa fa-fw fa-edit fa--dropdown right" />
                  Edit
                </div>
              </li>
            </ul>
          </div>
        </div>
        <Modal
          className="bg-white flex flex-column mb2 react_modal border-top border-blue bw-4"
          show={bModalOpened}
          onClickOutside={this.toogleModal}
        >
          <div className="flex flex-justify-between items-center semi-bold px3 py2 border-bottom border-lighter-gray">
            <div className="h3">Edit Collaborator</div>
            <i className="fa fa-close h5 cursor-pointer" onClick={this.toogleModal} />
          </div>
          <div className="flex flex-column px3 py2">
            <Switch
              labelLeft="Admin"
              onChange={() => this.setState({ isAdmin: !isAdmin })}
              defaultChecked={isAdmin}
            />
            <div className="flex flex-column pb2 pt2">
              <div className="flex flex-column col-12 pr1">
                <span className="h5 text-gray pb1">Role</span>
                <ReactSelect
                  className="col-12"
                  placeholder="Select your role"
                  value={roleOptions.filter(opt => opt.value === companyRole)}
                  options={roleOptions}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                  onChange={(selectedOption) => this.handleChange('companyRole', selectedOption.value)}
                />
              </div>
              {companyRole === 'other' &&
                <div className="mt1 mr1">
                  <TextInput
                    value={otherCompanyRole}
                    onChange={(e) => this.handleChange('otherCompanyRole', e.target.value)}
                    placeholder="Add a other role..."
                    className="col-12 border border-medium-lighter-gray"
                  />
                </div>
              }
            </div>
            <div className="flex flex-row pb2">
              <div className="flex flex-column col-12 pr1">
                <span className="h5 text-gray pb1">category</span>
                <ReactSelect
                  className="col-12"
                  value={categoryOptions.filter(opt => opt.value === category)}
                  placeholder="You are?"
                  options={categoryOptions}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                  onChange={(selectedOption) => this.handleChange('category', selectedOption.value)}
                />
              </div>
            </div>
            {
              description !== 'Invited' ?
                <div className="flex flex-row pb2">
                  <div className="flex flex-column col-12 pr1">
                    <span className="h5 text-gray pb1">Description</span>
                    <textarea name="description" className="border border-light-gray h-100 mb1" value={description || ''} onChange={(e) => this.handleChange(e.target.name, e.target.value)} />
                  </div>
                </div>
                : null
            }
          </div>
          <div className="flex flex-justify-end px3 pt1 mt1 mb2 border-top border-lighter-gray">
            <div className="flex">
              <button type="submit" onClick={this.toogleModal} className="bg-white text-blue border-none h4 fw400 px3 py1 cursor-pointer rounded">Back</button>
              <button type="submit" onClick={this.saveCollaborator} className="bg-blue text-white bs-around border-none h4 fw400 px3 py1 cursor-pointer rounded">Save</button>
            </div>
          </div>
        </Modal>
      </React.Fragment>

    );
  }
}

export default TeamCardEdit;
