import Highcharts from 'highcharts';
import numberFormat from '../user_number_format';
import applyDateFormat from './highcharts_date_format';

export default function createRunwayChart(selector, dates, data_values, title = false) {
  const dateParser = (date) => {
    const parts = date.split('-');
    return Date.UTC(parts[0], parts[1] - 1, parts[2]);
  };

  const titleText = `Runway${data_values.runway ? `: ${data_values.runway}` : ''}`;

  Highcharts.setOptions({
    lang: numberFormat().getNumberFormatKpiChart(),
  });
  applyDateFormat();

  return Highcharts.chart({
    chart: {
      renderTo: selector,
      animation: false,
      alignTicks: false,
      marginTop: title ? null : 40,
    },
    title: {
      text: title ? titleText : null,
      align: 'left',
      margin: 35,
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#555',
      },
    },
    tooltip: {
      xDateFormat: `${data_values.date_format}`,
    },
    xAxis: {
      type: 'datetime',
      categories: dates.map(date => dateParser(date)),
      labels: {
        format: `{value:${data_values.date_format}}`,
      },
    },
    yAxis: { // left y axis
      gridLineWidth: 1,
      softMin: 0,
      gridLineColor: '#DFE2E5',
      title: {
        text: null,
      },
      labels: {
        style: {
          color: '#7F8FA4',
        },
      },
    },
    plotOptions: {
      series: {
        minPointLength: 2,
        dataLabels: {
          enabled: false,
        },
        marker: {
          enabled: true,
        },
      },
    },
    legend: {
      align: 'center',
      layout: 'horizontal',
      verticalAlign: 'bottom',
      floating: false,
    },
    series: [
      {
        name: 'Revenue',
        data: data_values.revenue_values.map((value, index) => { return { y: parseFloat(value), color: `rgba(50, 205, 50, ${index === 0 ? '1' : '0.5'})` }; }),
        type: 'column',
        color: 'rgba(50, 205, 50, 0.5)',
      },
      {
        name: 'Cost Total',
        type: 'column',
        data: data_values.cost_total_values.map((value, index) => { return { y: parseFloat(value), color: `rgba(218, 18, 25, ${index === 0 ? '1' : '0.5'})` }; }),
        color: 'rgba(218, 18, 25, 0.5)',
      },
      {
        name: 'Cash On Hand',
        data: data_values.cash_on_hand_values.map((value, index) => { return { y: parseFloat(value), color: `rgba(128, 128, 128, ${index === 0 ? '1' : '0.5'})` }; }),
        color: 'rgba(128,128,128, 0.5)',
      },
    ],
  });
}
