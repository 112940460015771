import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HoverButton from '../HoverButton';


class RowManager extends Component {
  static propTypes = {
    addUrl: PropTypes.string.isRequired,
    removeUrl: PropTypes.string.isRequired,
  }

  state = {
    position: 'bottom',
  }

  addRow = () => {
    const { addUrl } = this.props;
    const { position } = this.state;

    App.Api.post(addUrl, { position })
      .then(data => {
        hot.loadData(data);
      });
  }

  displayToggle = () => {
    const { position } = this.state;
    if (position === 'bottom') {
      return (
        <div className="flex text-gray fw400 cursor-pointer" onClick={() => this.setState({ position: 'top' })}>
          <span>On Bottom</span>
          <img
            className="pl1"
            src="/images/kpi-management/toggle_butons_below_row.svg"
            style={{ width: '40px', height: '26px' }}
            alt="Add Below"
          />
        </div>
      );
    }
    return (
      <div className="flex text-gray fw400 cursor-pointer" onClick={() => this.setState({ position: 'bottom' })}>
        <span>On Top</span>
        <img
          className="pl1"
          src="/images/kpi-management/toggle_butons_above_row.svg"
          style={{ width: '40px', height: '26px' }}
          alt="Add Above"
        />
      </div>
    );
  }

  removeRow = () => {
    const { removeUrl } = this.props;
    const { position } = this.state;

    App.Api.delete(removeUrl, { position })
      .then(data => {
        hot.loadData(data);
      });
  }

  render() {
    return (
      <div className="flex border-bottom border-lighter-gray mb2 px1 pb1">
        <div className="text-gray fw400">Add/Delete Row</div>
        <div className="flex flex-auto flex-justify-end">
          {this.displayToggle()}
          <div className="pl2 cursor-pointer" onClick={this.removeRow}>
            <HoverButton
              hoverImg="/images/kpi-management/minus_selected.svg"
              img="/images/kpi-management/minus_deselected.svg"
              width="26px"
              height="26px"
            />
          </div>
          <div className="pl1 cursor-pointer" onClick={this.addRow}>
            <HoverButton
              hoverImg="/images/kpi-management/plus_selected.svg"
              img="/images/kpi-management/plus_deselected.svg"
              width="26px"
              height="26px"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default RowManager;
