import axios from 'axios';

import {
  addInvestmentFields,
  updateInvestmentFields,
  removeInvestmentFields,
  resetInvestmentFields,
  overwriteInvestmentFields,
  copyCompanyData,
} from '../../actions/investor/investmentActions';

function createFields(investorId, investmentId, params) {
  return async dispatch => {
    const res = await axios.post(`/investor/${investorId}/investments/${investmentId}/investment_fields`, params);
    const newField = res.data.length > 0 && [null, 'investor', 'collaborator'].includes(res.data[0].overwrite);
    if (newField) dispatch(addInvestmentFields(res.data));
    else dispatch(overwriteInvestmentFields(res.data));
  };
}

function updateFields(investorId, investmentId, params) {
  return async dispatch => {
    const res = await axios.patch(`/investor/${investorId}/investments/${investmentId}/investment_fields`, params);
    dispatch(updateInvestmentFields(res.data));
  };
}

const createOrUpdateFields = (investorId, investmentId, id, params) =>
  async dispatch => {
    if (typeof id === 'string') { // create new investment field
      await dispatch(createFields(investorId, investmentId, params));
    } else { // update existing investment field
      await dispatch(updateFields(investorId, investmentId, params));
    }
  };

const deleteFields = (investorId, investmentId, params) =>
  async dispatch => {
    const res = await axios.delete(`/investor/${investorId}/investments/${investmentId}/investment_fields`, { data: params });
    const customField = res.data.length > 0 && [null, 'investor', 'collaborator'].includes(res.data[0].overwrite);
    if (customField) dispatch(removeInvestmentFields(res.data.map(f => f.id)));
    else dispatch(resetInvestmentFields(res.data.map(f => f.id)));
  };

const cloneField = (investorId, investmentId, id, params) =>
  async dispatch => {
    const res = await axios.post(`/investor/${investorId}/investments/${investmentId}/investment_fields/${id}/clone`, params);
    dispatch(updateInvestmentFields([res.data]));
  };

const copyCoinvestorsData = () =>
  async (dispatch, getState) => {
    const investorId = getState().investmentHeader.investor.id;
    const investmentId = getState().investmentHeader.id;
    const res = await axios.post(`/investor/${investorId}/investments/${investmentId}/investment_fields/company_data_copy`, { type: 'set_shareholders' });

    dispatch(copyCompanyData(res.data.co_investors, 'co_investors'));
  };

export {
  createOrUpdateFields,
  deleteFields,
  cloneField,
  copyCoinvestorsData,
};
