import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Modal from '../Modal';
import SelectWithDate from '../selects/SelectWithDate';

class ModalWithDatePicker extends Component {
  static propTypes = {
    show: PropTypes.bool,
    buttonName: PropTypes.string,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    show: false,
    buttonName: 'Ok',
    onConfirm: null,
  }

  constructor(props) {
    super(props);

    const startDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
    const endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');

    this.state = {
      startDateLastYear: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
      endDateLastYear: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
      startDateLastQuarter: moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'),
      endDateLastQuarter: moment().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'),
      startDateLastMonth: startDate,
      endDateLastMonth: endDate,
      startDate,
      endDate,
    };
  }

  render() {
    const { show, buttonName, onConfirm, onClose } = this.props;
    const {
      startDateLastYear,
      endDateLastYear,
      startDateLastQuarter,
      endDateLastQuarter,
      startDateLastMonth,
      endDateLastMonth,
      startDate,
      endDate,
    } = this.state;

    return (
      show ?
        <>
          <Modal show={show} onClickOutside={onClose} widthClass="react-modal--300">
            <div className="p2">
              <SelectWithDate
                rangeOptions={{
                  start_date_last_year: startDateLastYear,
                  end_date_last_year: endDateLastYear,
                  start_date_last_quarter: startDateLastQuarter,
                  end_date_last_quarter: endDateLastQuarter,
                  start_date_last_month: startDateLastMonth,
                  end_date_last_month: endDateLastMonth,
                }}
                onChange={(_dateRangeType, startDateSelection, endDateSelection) => this.setState({ startDate: startDateSelection, endDate: endDateSelection })}
              />

              <div className="mt2 flex flex-justify-center">
                <div
                  onClick={onClose}
                  className="col-3 center py1 bg-light-gray rounded text-white cursor-pointer mr2"
                >
                  Cancel
                </div>
                <div
                  onClick={onConfirm ? () => onConfirm(`start_date=${startDate}&end_date=${endDate}`) : null}
                  className="col-3 center py1 bg-green rounded text-white cursor-pointer"
                >
                  {buttonName}
                </div>
              </div>
            </div>
          </Modal>
        </>
        :
        null
    );
  }
}

export default ModalWithDatePicker;
