import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import KpiWidget from './KpiWidget';
import RadioInput from '../RadioButton';
import Button from '../Button';

class ManageGenericKpis extends Component {
  static propTypes = {
    kpisLibrary: PropTypes.object.isRequired,
    singleKpiId: PropTypes.number,
    genericKpiId: PropTypes.number,
    company: PropTypes.object.isRequired,
    manageKpisLibrarySelection: PropTypes.func.isRequired,
    changeFrequency: PropTypes.func,
    editGenericKpi: PropTypes.func,
    createKpisFromLibrary: PropTypes.func,
    linkKpiToLibrary: PropTypes.func,
    afterSubmit: PropTypes.func,
    updateUsedGenericKpis: PropTypes.func,
  }

  static defaultProps = {
    singleKpiId: null,
    genericKpiId: null,
    changeFrequency: null,
    editGenericKpi: null,
    createKpisFromLibrary: null,
    linkKpiToLibrary: null,
    afterSubmit: null,
    updateUsedGenericKpis: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      searchQuery: '',
      errors: null,
      loading: false,
      menuExpandedCategories: this.filteredData('').map(category => category.name),
    };

    this.initialState = { ...this.state };
  }

  componentDidMount() {
    const { kpisLibrary, singleKpiId, genericKpiId, manageKpisLibrarySelection } = this.props;

    if (singleKpiId) {
      const genericKpis = kpisLibrary.categories.map(categorie => categorie.kpis).flat();
      const genericKpi = genericKpis.find(el => el.id === genericKpiId);

      if (genericKpi) manageKpisLibrarySelection(genericKpi);
    }
  }

  filteredData = (searchQuery) => {
    const { kpisLibrary } = this.props;

    const filterKpis = (kpis) => kpis.filter(kpi => kpi.metric.toLowerCase().includes(searchQuery.toLowerCase()));
    const filteredCategories = kpisLibrary.categories.filter(category => filterKpis(category.kpis).length > 0);

    return filteredCategories.map(category => {
      const filteredKpis = filterKpis(category.kpis);
      return filteredKpis.length > 0 ? { ...category, kpis: filteredKpis } : { ...category, kpis: [] };
    });
  }

  handleSubmit = () => {
    const { createKpisFromLibrary, company, singleKpiId, linkKpiToLibrary, afterSubmit, manageKpisLibrarySelection, updateUsedGenericKpis } = this.props;
    const { loading } = this.state;

    if (loading) return;

    this.setState({ loading: true }, async () => {
      try {
        if (singleKpiId) {
          await linkKpiToLibrary(singleKpiId);
          if (updateUsedGenericKpis) await updateUsedGenericKpis();
          if (afterSubmit) await afterSubmit();
          await manageKpisLibrarySelection(null);
        } else {
          await createKpisFromLibrary();
          window.location = `/c/${company.id}/kpis`;
        }
      } catch (err) {
        const { errors } = err.response.data;
        this.setState({ errors, loading: false });

        const errorMessage = errors.map(data => data.error).join(', ');
        App.State.setFlash({ name: 'alert', msg: `${errors.length > 1 ? 'Errors: ' : 'Error: '} ${errorMessage}` || 'Sorry, an unknown error prevented the KPIs from being created.' });
      }
    });
  }

  handleKpiSelection = (genericKpi) => {
    const { kpisLibrary, singleKpiId, manageKpisLibrarySelection } = this.props;

    if (singleKpiId && kpisLibrary.selectedKpis.length > 0 && kpisLibrary.selectedKpis[0].id !== genericKpi.id) {
      App.State.setFlash({ name: 'alert', msg: 'You can assign to just one KPI in the library.' });
      return;
    }

    manageKpisLibrarySelection(genericKpi);
  }

  renderGenericKpis = (categories, core = false) => {
    const { kpisLibrary, genericKpiId, singleKpiId, editGenericKpi } = this.props;
    const { menuExpandedCategories, errors } = this.state;

    const selectedKpi = (genericKpi) => (core ? false : kpisLibrary.selectedKpis.find(selectedGenericKpi => selectedGenericKpi.id === genericKpi.id));

    const errorMessage = (selectedGenericKpi) => {
      let errorDat;
      if (selectedGenericKpi) {
        errorDat = errors.find(errorData => errorData.id === selectedGenericKpi.id);
      }
      if (errorDat) return errorDat.error;
      return null;
    };

    return (
      categories.length > 0 ?
        categories.map((category, index) => (
          <div
            key={category.name}
            className={`bs-around rounded pl2 pr2 bg-white ${core || categories.length !== index + 1 ? 'mb2' : ''}`}
          >
            <div className="flex flex-justify-between items-center px2 py2 border-bottom border-alto mxn2">
              <div>
                <span className="h3">{category.name}</span>
                {category.name === 'Core KPIs' &&
                  <span className="h5 ml1">
                    (Please note that these are core KPIs of Investory and can not be edited, removed or mapped (linked) with any other KPIs)
                  </span>
                }
              </div>

              <i
                className={`fa fa-chevron-${menuExpandedCategories.includes(category.name) ? 'down' : 'right'} bold h6 cursor-pointer ml2 sm-ml1`}
                onClick={() => this.setState({ menuExpandedCategories: menuExpandedCategories.includes(category.name) ? menuExpandedCategories.filter(el => el !== category.name) : [...menuExpandedCategories, category.name] })}
              />
            </div>
            <ReactCSSTransitionGroup transitionName="modal" transitionEnterTimeout={300} transitionLeaveTimeout={300}>
              {menuExpandedCategories.includes(category.name) &&
                <div className="mt2 mb1 flex flex-wrap">
                  {category.kpis.map((genericKpi, i) => (
                    <div
                      key={genericKpi.metric}
                      className={`col-3 flex mb1 ${(i + 1) % 4 === 0 ? '' : 'pr1'}`}
                    >
                      <KpiWidget
                        genericKpi={genericKpi}
                        selectedKpi={selectedKpi(genericKpi)}
                        onClick={core ? null : () => this.handleKpiSelection(genericKpi)}
                        errorMessage={errors && errorMessage(selectedKpi(genericKpi))}
                        disabled={core || (genericKpi.used && genericKpi.id !== genericKpiId)}
                        // editGenericKpi={(field, value) => editGenericKpi(genericKpi, field, value)}
                      />
                    </div>
                  ))}
                </div>
              }
            </ReactCSSTransitionGroup>
          </div>
        ))
        :
        <div className="bg-white p3 bold">
          <span className="h3 flex flex-justify-center">No KPIs found</span>
        </div>
    );
  }

  render() {
    const { kpisLibrary, singleKpiId, genericKpiId, company, changeFrequency } = this.props;
    const { searchQuery } = this.state;

    const filteredData = this.filteredData(searchQuery);

    const frequencyOptions = [
      { value: 'months', label: 'Month' },
      { value: 'quarters', label: 'Quarter' },
    ];

    return (
      <div>
        <div className="bg-white bs-around rounded">
          <div className="px3 h2 semi-bold border-bottom border-alto" style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}>
            <span>{singleKpiId ? 'KPI Mapping' : 'KPI Library'}</span>
          </div>

          <div className="px3 py2 flex flex-column h5">
            <span className="bold">{singleKpiId ? 'You can select a KPI from the below list in order to map it with the KPI that you have selected' : 'Please select KPIs you would like to track and report on regular basis'}</span>
            {!singleKpiId &&
              <span className="mt1">
                Below is a library of KPIs for selection that can be added to your list of KPIs. You can easily see how each KPI is defined and aggregated based on its type. You can also select your desired reporting frequency – Monthly or Quarterly. If you have specific KPI requirements, you can add them as a Custom KPI
              </span>
            }
          </div>
        </div>

        <div className="flex flex-justify-between items-center bg-gallery rounded p2 my2">
          <div className="col-12 flex" style={{ height: '35px' }}>
            {/* Search bar */}
            <div className="flex items-center bs-around rounded col-5 bg-white">
              <i className="fa fa-search ml1 text-light-gray" />
              <input
                className="border-none h5 col-12 rounded"
                placeholder="Search All KPIs"
                type="text"
                onChange={e => this.setState({ searchQuery: e.target.value })}
              />
            </div>

            {/* Frequency switch */}
            {!singleKpiId &&
              <div className="flex items-center text-gray p1 px2 ml2">
                <div>
                  <div className="fw400">Reporting Frequency</div>
                  <div>
                    <RadioInput
                      value={kpisLibrary.frequency}
                      name="radio_button_frequency"
                      options={frequencyOptions}
                      onClick={(el) => changeFrequency(el.target.value)}
                    />
                  </div>
                </div>
                <div className="ml3">
                  <a className="text-gray" href={`/company/${company.id}/kpis/new`}>
                    <Button classNames="btn btn-outline border-alto bg-white h5 p1 mr1">
                      <i className="fa fa-plus mr1" />
                      Add Custom KPI
                    </Button>
                  </a>
                </div>
              </div>
            }
          </div>

          {/* Counter and submit button */}
          <div className="flex items-center">
            {!singleKpiId &&
              <span className="bold mr2">{kpisLibrary.selectedKpis.length}</span>
            }
            <div
              className={`bs-around rounded flex items-center py05 px2 nowrap ${!singleKpiId && kpisLibrary.selectedKpis.length === 0 ? 'cursor-disabled' : 'hover hover-bg-green hover-text-white cursor-pointer border border-green'}`}
              onClick={!singleKpiId && kpisLibrary.selectedKpis.length === 0 ? null : () => this.handleSubmit()}
            >
              {singleKpiId ? (kpisLibrary.selectedKpis.length === 0 && genericKpiId ? 'Unlink' : 'Save') : 'Add to KPIs list'}
            </div>
          </div>
        </div>

        {/* Categories and Generic KPIs */}
        <div className="flex flex-column rounded">
          {/* Core KPIs */}
          {this.renderGenericKpis(kpisLibrary.core_kpis, true)}

          {/* Library KPIs */}
          {this.renderGenericKpis(filteredData)}
        </div>
      </div>
    );
  }
}

export default ManageGenericKpis;
