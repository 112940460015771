export const updateCompanyReport = (id, params) => ({
  type: 'UPDATE_REPORT',
  id,
  params,
});

export const updateReportColumn = (id, column) => ({
  type: 'UPDATE_COLUMN',
  id,
  column,
});

export const removeReportColumn = id => ({
  type: 'REMOVE_COLUMN',
  id,
});
