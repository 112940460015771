import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ValueRow from './ValueRow';


class ValueTable extends Component {
  static propTypes = {
    values: PropTypes.array.isRequired,
    sources: PropTypes.object.isRequired,
  }

  displayRows = () => {
    const { values } = this.props;
    if (values.length > 0) {
      return values.map(row => (
        <ValueRow key={Math.random()} row={row} />
      ));
    }
    return (
      <div className="">Please check your data, make sure dates have date format in your sheet.</div>
    );
  }

  render() {
    const { sources } = this.props;

    return (
      <div className="flex pt1 flex-column mb3">
        <div className="flex py05 bg-lighter-gray">
          <div className="flex text-gray pl2 col-4 h6 bold">DATE
            <span className="text-blue pl1">{ sources.date }</span>
          </div>
          <div className="flex text-gray pl2 col-4 h6 bold">ACTUAL
            <span className="text-orange pl1">{ sources.actual }</span>
          </div>
          <div className="flex text-gray pl2 col-4 h6 bold">PLAN
            <span className="text-green pl1">{ sources.forecast }</span>
          </div>
        </div>
        {this.displayRows()}
      </div>
    );
  }
}

export default ValueTable;
