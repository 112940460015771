import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Textarea from './Textarea';
import Avatar from './tab_view/Avatar';
import HtmlComponent from './tab_view/HtmlComponent';
import SelectWithModal from './selects/SelectWithModal';


class Comments extends Component {
  static propTypes = {
    comments: PropTypes.array.isRequired,
    createComment: PropTypes.func.isRequired,
    addRecipient: PropTypes.func,
    removeRecipient: PropTypes.func,
    selectedRecipients: PropTypes.array,
    recipientListOptions: PropTypes.array,
    company: PropTypes.object,
    currentUser: PropTypes.object,
    allowence: PropTypes.bool,
    hasRecipients: PropTypes.bool,
    canEditRecipients: PropTypes.bool,
  }

  static defaultProps = {
    company: {},
    currentUser: {},
    allowence: true,
    hasRecipients: false,
    canEditRecipients: false,
    addRecipient: null,
    removeRecipient: null,
    selectedRecipients: null,
    recipientListOptions: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      comment: '',
      loading: false,
    };
  }

  handleCreateComment = async () => {
    const { createComment } = this.props;
    const { comment, loading } = this.state;

    if (loading) return;

    if (!comment.trim()) {
      App.State.setFlash({ name: 'alert', msg: 'Empty message!' });
      return;
    }

    this.setState({ loading: true });

    try {
      await createComment(comment);
      this.setState({ comment: '', loading: false });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      comments,
      company,
      allowence,
      currentUser,
      addRecipient,
      removeRecipient,
      selectedRecipients,
      recipientListOptions,
      hasRecipients,
      canEditRecipients,
    } = this.props;

    const { comment, loading } = this.state;

    return (
      <React.Fragment>
        <div className="py2 flex items-center flex-justify-center flex-column">
          { (allowence || comments.length > 0) &&
            <div className="col-12 flex-justify-center col-12 border-bottom border-gallery bw-2 m1 my2 center block" style={{ lineHeight: '0.1em' }}>
              <span className="fw400 text-gray bg-body text-medium-gray px2">{allowence ? 'Discuss this Data Room' : 'Data Room Discussion'}</span>
            </div>
          }

          {allowence &&
            <div className="flex flex-column col-7 sm-col-12">
              <div className="flex py2">
                <Avatar
                  avatarUrl={currentUser.avatar || window.images.userGray}
                  size="45px"
                />
                <div className="col-12 ml2">
                  <Textarea
                    value={comment}
                    className="border border-alto rounded lh-15 col-12"
                    placeholder="Add a comment here..."
                    style={{ minHeight: '38px', resize: 'vertical' }}
                    onChange={(e) => this.setState({ comment: e.target.value })}
                  />

                  {hasRecipients &&
                    <div className="">
                      <div className="flex items-center lh-12 pb1">
                        <span className="h5 mr1 text-gray">
                          <span
                            data-balloon="Comments on this data room will be visible by everyone that has access to it. In addition people listed on the right will be sent a email to inform them of the new comment."
                            data-balloon-pos="down"
                            data-balloon-length="large"
                          >
                            <i className="fa fa-info-circle pr05" />
                          </span>
                          Your comment will be emailed to:
                        </span>
                        <SelectWithModal
                          title="Select Recipients"
                          elementsToSelect={recipientListOptions}
                          selectedElements={selectedRecipients}
                          addElement={(recipientId) => addRecipient(recipientId)}
                          deleteElement={(recipientId) => removeRecipient(recipientId)}
                          disableTooltip={false}
                          avatarsQuantity={8}
                          size="18px"
                          disableSelect={!canEditRecipients}
                        />
                      </div>

                      <div className="flex">
                        <div
                          className={`px2 py1 bg-green rounded ${loading ? 'cusor-default' : 'cursor-pointer'}`}
                          onClick={this.handleCreateComment}
                        >
                          <span className="text-white fw400">{loading ? 'Adding this comment...' : 'Add this comment'}</span>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
        </div>

        <div className="flex flex-justify-center">
          <div className="flex flex-column col-7">
            {comments.map(c => (
              <div className="flex" key={c.id}>
                <div className="flex mr1">
                  <Avatar
                    avatarUrl={c.author.avatar || window.images.userGray}
                    size="35px"
                  />
                </div>
                <div className="col-12 ml2">
                  <span className="bold">{c.author.name}</span>
                  <div className="py1 flex flex-column">
                    <HtmlComponent
                      data={{ html: c.body }}
                    />
                    <div className="text-light-gray h6">
                      Posted {c.creation_time} ago
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Comments;
