import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';


const FileStatsModal = ({ show, file, fileIcon, closeModal }) => (
  <Modal
    show={show}
    widthClass="react_modal--600"
    onClickOutside={() => closeModal()}
  >
    <div className="col-12 flex flex-center p2 mt1">
      <div className="col-10 flex flex-auto fs-18">
        <span className="fa-fw-l">
          <i className={fileIcon} style={{ color: file.icon_color }} />
        </span>
        <span className="bold ml1 truncate-wrap">{file.name} - Statistics</span>
      </div>
      <div className="col-2 flex flex-justify-end">
        <div
          className="cursor-pointer"
          onClick={() => closeModal()}
        >
          <i className="fa fa-close text-gray" />
        </div>
      </div>
    </div>

    {file.stats.length ?
      <div className="flex flex-justify-center center p1 mt1 mb1 h4">
        Document was downloaded { file.num_of_downloads_times } and (pre)viewed { file.num_of_views_times }.
        <br />
        Here are the users who recently accessed this file:
      </div>
      :
      <div className="flex flex-justify-center center p1 h4" />
    }
    <div className="flex flex-column flex-nowrap bs-around mb3 mx3 text-gray">
      <div className="col-12 flex p2 bold bg-wild-sand">
        <div className="col-8 flex">Name</div>
        <div className="col-4 flex flex-justify-end items-center">Latest Download/View</div>
      </div>
      {file.stats.length ?
        file.stats.map(stat => (
          <div key={stat.key} className="flex items-center col-12 p2 overflow-y" style={{ maxHeight: '100px' }}>
            <div
              className="flex items-center mr1 block bg-center bg-cover bg-no-repeat circle header__avatar--investor"
              style={{ backgroundImage: `url(${stat.user_avatar})` }}
            />
            <div className="col-8 flex items-center">{stat.user_name ? stat.user_name : stat.investor_name}</div>
            <div className="col-4 flex flex-justify-end items-center">{stat.last_update}</div>
          </div>
        ))
        :
        <div className="col-12 p2 flex flex-justify-center items-center text-light-gray">
          No recorded downloads or (pre)views yet...
        </div>
      }
    </div>
    <div className="flex flex-justify-center my3">
      <div
        className="bg-blue text-white p1 px3 rounded fw400 cursor-pointer"
        onClick={() => closeModal()}
      >
        Close
      </div>
    </div>
  </Modal>
);

FileStatsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  file: PropTypes.object.isRequired,
  fileIcon: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default FileStatsModal;
