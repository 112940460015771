import React, { Component } from 'react';
import PropTypes from 'prop-types';
import applyLogoColorTheme from '../branding/logoColorTheme';


class InvestoryLogoSvg extends Component {
  static propTypes = {
    themeFor: PropTypes.string,
    themeColor: PropTypes.string,
    logoClass: PropTypes.string,
    useAsIconOnly: PropTypes.bool,
  }

  static defaultProps = {
    themeFor: null,
    themeColor: null,
    logoClass: null,
    useAsIconOnly: false,
  }

  componentDidMount() {
    const { themeColor, useAsIconOnly } = this.props;

    if (!useAsIconOnly) this.applyTheme(themeColor);
  }

  componentWillReceiveProps(nextProps) {
    const { themeColor, useAsIconOnly } = this.props;

    if (nextProps.themeColor !== themeColor) {
      if (!useAsIconOnly) this.applyTheme(nextProps.themeColor);
    }
  }

  applyTheme = (themeColor) => {
    const { themeFor, logoClass } = this.props;

    applyLogoColorTheme(themeFor, logoClass, themeColor);
  }

  render() {
    const { themeColor, logoClass, useAsIconOnly } = this.props;

    return (
      <div className="flex flex-center">
        <svg width="20" height="20">
          {useAsIconOnly ?
            <circle cx="10" cy="10" r="6" style={{ strokeWidth: 3, stroke: themeColor, fill: 'white' }} />
            :
            <circle cx="10" cy="10" r="6" className={logoClass} style={{ strokeWidth: 3 }} />
          }
        </svg>
      </div>
    );
  }
}

export default InvestoryLogoSvg;
