import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import HeaderContainer from '../../../../containers/investor/investments/HeaderContainer';
import BigKpiWidget from '../../../dashboard/BigKpiWidget';
import CleaveNumberFormatting from '../../../CleaveNumberFormatting';
import AddInvestmentKpiModal from './modals/AddInvestmentKpiModal';
import ManageInvestmentKpiModal from './modals/ManageInvestmentKpiModal';
import KpiValuesImport from '../../../kpis/KpiValuesImport';
import ConfirmationModal from '../../../ConfirmationModal';
import ConfirmationModalOnComponent from '../../../ConfirmationModalOnComponent';

import GenericKpiChart from '../../modules/GenericKpiChart';
import DatePicker from '../../../selects/DatePicker';
import UpgradeAccountModal from '../../../modals/UpgradeAccountModal';


class InvestmentKpis extends Component {
  static propTypes = {
    investmentHeader: PropTypes.object.isRequired,
    investmentKpis: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    saveKpiFieldValue: PropTypes.func.isRequired,
    createKpiField: PropTypes.func.isRequired,
    updateKpiField: PropTypes.func.isRequired,
    deleteKpiField: PropTypes.func.isRequired,
    changeKpiInChart: PropTypes.func.isRequired,
    changeCurrentDate: PropTypes.func.isRequired,
    updateDerivedKpis: PropTypes.func.isRequired,
  }

  state = {
    showAddKpiModal: false,
    showManageKpiModal: false,
    showImportModal: false,
    showUpgradeModal: false,
    kpiDefinitionModalFor: null,
  }

  handleDelete = async (kpi, removeKpiAllInvestments) => {
    const { investmentKpis, deleteKpiField, investmentHeader, changeCurrentDate } = this.props;

    try {
      await deleteKpiField(investmentHeader.investor.id, investmentHeader.id, kpi.id, removeKpiAllInvestments);
      // changeCurrentDate was just called to refresh the data
      await changeCurrentDate(investmentKpis.current_date);
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an error prevented the KPI from being deleted.' });
    }
  }

  indicator = kpi => {
    if (kpi.company_data) return 'desktop__notification--changes-green';
    return 'desktop__notification--changes-blue';
  }

  handleSaveKpiFieldValue = (value, kpi) => {
    const { investmentHeader, investmentKpis, saveKpiFieldValue } = this.props;
    const parsedCurrentDate = moment(investmentKpis.current_date).format('YYYY-MM-DD');

    saveKpiFieldValue(investmentHeader.investor.id, investmentHeader.id, kpi.id, { kpi_field_value: { actual: value, date: parsedCurrentDate } });
  }

  handleDerivedKpiUpdate = async (updateAllDerivedKpis) => {
    const { updateDerivedKpis } = this.props;

    try {
      await updateDerivedKpis(updateAllDerivedKpis);
      window.location.reload();
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an error prevented the KPIs from being updated.' });
    }
  }

  renderDerivedKpiUpdate = () => {
    const { currentUser } = this.props;

    const buttonClasses = classNames('px1 mx1 h6 rounded bg-black--transparent text-white', {
      'cursor-pointer': currentUser.canManageDerivedKpis,
      'cursor-disabled': !currentUser.canManageDerivedKpis,
    });

    return (
      <ConfirmationModal
        onClick={(param) => this.handleDerivedKpiUpdate(currentUser.admin_or_unlocked ? param.update_all_derived_kpis : false)}
        className="text-light-gray cursor-pointer"
        html={`<div class="${buttonClasses}"><i class="fa fa-refresh mr1"></i>Update derived KPIs</div>`}
        message="<div>You are about to update all derived KPIs values. All values will get deleted and replaced by new ones.</br></br> <b>Are you sure you want to perform this action?</b></div>"
        width=""
        checkBoxParam={currentUser.admin_or_unlocked ? 'update_all_derived_kpis' : null}
        checkBoxText={currentUser.admin_or_unlocked ? 'Also update all derived KPIs in all other investments' : null}
        disabled={!currentUser.canManageDerivedKpis}
        disabledBalloonText="Derived KPIs blocked for free account."
      />
    );
  }

  render() {
    const {
      investmentHeader, investmentKpis, currentUser, saveKpiFieldValue,
      createKpiField, updateKpiField, changeKpiInChart, changeCurrentDate,
    } = this.props;

    const { showAddKpiModal, showManageKpiModal, showImportModal, showUpgradeModal, kpiDefinitionModalFor } = this.state;

    const inputClasses = classNames('col-12', {
      'border border-lighter-gray': investmentKpis.edit,
      'hover hover-border-blue': investmentKpis.edit,
    });

    const address = investmentHeader.address_fields.map(a => a.text).filter(a => a !== '').join(', ');
    const timeRangeOptions = [
      { value: 'current', label: 'Current Year' },
      { value: 'previous_year', label: 'Previous Year' },
      { value: 'two_years', label: 'Last 2 Years' },
      { value: 'three_years', label: 'Last 3 Years' },
      { value: 'custom', label: 'Custom' },
    ];

    const datesAvailable = (frequency) => (
      frequency === 'quarterly' ? 'Jan/Apr/Jul/Oct' : 'Jan'
    );

    return (
      <div>
        <HeaderContainer
          activeTab="kpis"
          tabUrl={`investments/${investmentHeader.id}/kpi_fields/`}
          address={address}
        />
        {
          (!investmentKpis.edit) &&
          <div className="my2 pb2 bs-around bg-white">
            <GenericKpiChart
              title="KPI Charts"
              kpis={[...investmentKpis.predefined_kpis, ...investmentKpis.custom_kpis]}
              data={investmentKpis.chart_data}
              kpiOptions={investmentKpis.kpi_options}
              chosenKpi={investmentKpis.chosen_kpi}
              onKpiChange={changeKpiInChart}
              investmentName={investmentHeader.company_name.text}
              timeRangeOptions={timeRangeOptions}
              multiKpis
              cumulativeKpis
            />
          </div>
      }
        <div className="flex sm-flex-column col-12 my2">
          <div className="col-12 sm-col-12">
            <div className="bg-white bs-around flex flex-column p2">
              <div className="flex flex-justify-between pb2 px1">
                <div className="flex items-center fw400 text-gray">
                  KPI Overview
                  {
                    investmentKpis.edit &&
                    <DatePicker
                      value={investmentKpis.current_date}
                      className="col-4 ml1 border border-gallery text-orange"
                      onChange={(e, value) => changeCurrentDate(value)}
                      showMonthYear
                      blockDeletion
                    />
                  }
                </div>
                {!investmentKpis.edit &&
                  <div className="flex">
                    {!investmentHeader.guest && currentUser.canCreateDerivedKpis && this.renderDerivedKpiUpdate()}
                    <div
                      className="px1 h6 bg-black--transparent text-white cursor-pointer rounded"
                      onClick={() => { currentUser.canExport ? window.location = `/investor/${investmentHeader.investor.id}/investments/${investmentHeader.id}/kpi_fields/export` : this.setState({ showUpgradeModal: true });}}
                    >
                      <i className="fa fa-share-square mr1" />Export All KPIs
                    </div>
                    <UpgradeAccountModal
                      show={showUpgradeModal}
                      closeModal={() => this.setState({ showUpgradeModal: false })}
                      exportFeature
                      sendEmailOnClick
                      maxInvestments={currentUser.maxInvestments}
                      paywallDescription="Paywall_Export"
                    />
                  </div>
                }
                {investmentKpis.edit &&
                  <div className="flex py1">
                    {currentUser.canCreateDerivedKpis && this.renderDerivedKpiUpdate()}
                    <div
                      className="px1 mx1 h6 bg-black--transparent text-white cursor-pointer rounded"
                      onClick={() => this.setState({ showImportModal: true })}
                    >
                      <i className="fa fa-upload mr1" />Import
                    </div>
                    <div
                      className="px1 mx1 h6 bg-black--transparent text-white cursor-pointer rounded"
                      onClick={() => this.setState({ showManageKpiModal: true })}
                    >
                      <i className="fa fa-cog mr1" />Manage
                    </div>
                    <div
                      className="px1 mx1 h6 bg-black--transparent text-white cursor-pointer rounded"
                      onClick={() => this.setState({ showAddKpiModal: true })}
                    >
                      <i className="fa fa-plus mr1" />Add
                    </div>
                  </div>
                }
              </div>
              {investmentKpis.edit &&
                <div className="col-12 flex flex-wrap pl1 pb1">
                  <span className="text-light-gray h5 lh-12">
                    Note: If you add custom KPIs or overwrite values manually, they will not be visible/accessible by a company. To learn more about this topic,
                    <a href="http://docs.investory.io/en/articles/6860231-company-custom-data-management" target="_blank" rel="noopener noreferrer"> click here.</a>
                  </span>
                </div>
              }
              <div className="col-12 flex flex-wrap">
                {investmentKpis.predefined_kpis.map((kpi) => (
                  investmentKpis.edit ?
                    <div key={`${kpi.id}_${kpi.metric}`} className="flex flex-column flex-justify-end col-4 py1 px1">
                      <div className="fw400 pb1 flex">
                        {kpi.metric}
                        {kpi['blocked_value?'] &&
                          <div
                            className="ml1"
                            data-balloon={`Value shows N/A because this is a ${kpi.frequency} KPI. ${kpi['derived_kpi?'] ? `Value is available when date is ${datesAvailable(kpi.frequency)}. However derived KPIs are blocked for editing.` : `Available for editing only when date is ${datesAvailable(kpi.frequency)}.`}`}
                            data-balloon-pos="down"
                          >
                            <i className="fa fa-info-circle text-blue h5" />
                          </div>
                          }
                      </div>
                      <div className={`relative ${this.indicator(kpi)}`}>
                        {kpi['blocked_value?'] ?
                          <div
                            className={`${inputClasses} flex items-center bg-wild-sand`}
                            style={{ height: '40px' }}
                          >
                            <span className="ml2">N/A {kpi.frequency ? `(${kpi.frequency} KPI)` : ''}</span>
                          </div>
                          :
                          <CleaveNumberFormatting
                            className={inputClasses}
                            style={{ height: '40px' }}
                            value={`${kpi.raw_value}`}
                            onBlur={e => this.handleSaveKpiFieldValue(e.target.rawValue, kpi)}
                          />
                        }
                      </div>
                    </div>
                    :
                    <ConfirmationModalOnComponent
                      key={`${kpi.id}_${kpi.metric}`}
                      message="Clicking here opens a new tab, with the Company view. You can later close the new tab and continue in this one. </br></br> If instead you want to see more details of this KPI in the <b>Investor view</b>, i.e. including the custom data you may have entered on the investor side, you can use the chart, edit and export functions above. </br></br> Would you like to proceed?"
                      confirmationText="Yes"
                      cancelText="No"
                      className="col-4 flex"
                      width=""
                      link={kpi.url}
                    >
                      <BigKpiWidget
                        data={kpi}
                        col="12"
                        custom={this.indicator(kpi)}
                      />
                    </ConfirmationModalOnComponent>
                ))}
                {investmentKpis.custom_kpis.map((kpi) => (
                  investmentKpis.edit ?
                    <div key={`${kpi.id}_${kpi.metric}`} className="flex flex-column flex-justify-end col-4 py1 px1">
                      <div className="flex flex-justify-between pb1">
                        <div className="fw400 flex">
                          {kpi.metric} <i className="ml1">{kpi['derived_kpi?'] ? '(derived KPI)' : ''}</i>
                          {(kpi['blocked_value?'] || kpi['derived_kpi?']) &&
                            (kpi.frequency !== '' ?
                              <div
                                className="ml1"
                                data-balloon={`Value shows N/A because this is a ${kpi.frequency} KPI. ${kpi['derived_kpi?'] ? `Value is available when date is ${datesAvailable(kpi.frequency)}. However derived KPIs are blocked for editing.` : `Available for editing only when date is ${datesAvailable(kpi.frequency)}.`}`}
                                data-balloon-pos="down"
                              >
                                <i className="fa fa-info-circle text-blue h5" />
                              </div>
                              :
                              <div
                                className="ml1"
                                data-balloon="Derived KPIs are blocked for editing."
                                data-balloon-pos="down"
                              >
                                <i className="fa fa-info-circle text-blue h5" />
                              </div>
                            )
                          }
                          {kpi['derived_kpi?'] &&
                            <div
                              className="cursor-pointer"
                              data-balloon={currentUser.canManageDerivedKpis ? 'View/Edit KPI definition' : 'View KPI definition'}
                              onClick={() => this.setState({ kpiDefinitionModalFor: kpi.derived_kpi_definition })}
                            >
                              <i className={`fa fa-${currentUser.canManageDerivedKpis ? 'pencil' : 'eye'} text-blue h5 ml1`} />
                            </div>
                          }
                        </div>
                        <ConfirmationModal
                          onClick={(param) => this.handleDelete(kpi, currentUser.admin_or_unlocked ? param.remove_kpi_all_investments : false)}
                          className="text-light-gray cursor-pointer"
                          html='<i class="fa fa-trash" style="line-height: 22px" />'
                          message="<div>You are about to permanently delete this KPI and any associated custom values you may have entered on the investor side. If this is a company KPI, this will not delete data entered by the company on the company side. </br></br> <b>Are you sure you want to permanently delete this KPI?</b></div>"
                          width=""
                          disabled={kpi.source_for_derived_kpis}
                          disabledBalloonText="Source for derived KPIs, cannot be deleted."
                          disabledClassName="text-lighter-gray"
                          checkBoxParam={currentUser.admin_or_unlocked ? 'remove_kpi_all_investments' : null}
                          checkBoxText={currentUser.admin_or_unlocked ? 'Also remove this KPI from all other investments' : null}
                        />
                      </div>
                      <div className={`relative ${this.indicator(kpi)}`}>
                        {kpi['blocked_value?'] ?
                          <div
                            className={`${inputClasses} flex items-center bg-wild-sand`}
                            style={{ height: '40px' }}
                          >
                            <span className="ml2">N/A {kpi.frequency ? `(${kpi.frequency} KPI)` : ''}</span>
                          </div>
                          :
                          <CleaveNumberFormatting
                            className={inputClasses}
                            style={{ height: '40px' }}
                            value={`${kpi.raw_value}`}
                            onBlur={e => this.handleSaveKpiFieldValue(e.target.rawValue, kpi)}
                            disabled={kpi['derived_kpi?'] || kpi['blocked_value?']}
                          />
                        }
                      </div>
                    </div>
                    :
                    <ConfirmationModalOnComponent
                      key={`${kpi.id}_${kpi.metric}`}
                      message="Clicking here opens a new tab, with the Company view. You can later close the new tab and continue in this one. Would you like to proceed?"
                      confirmationText="Yes"
                      cancelText="No"
                      className="col-4 flex"
                      width=""
                      link={kpi.url}
                      disabled={!kpi.url}
                    >
                      <BigKpiWidget
                        data={kpi}
                        col="12"
                        custom={this.indicator(kpi)}
                      >
                        {kpi.derived_kpi_definition &&
                          <div
                            className="cursor-pointer"
                            data-balloon="View derived KPI definition"
                            onClick={() => this.setState({ kpiDefinitionModalFor: kpi.derived_kpi_definition })}
                          >
                            <i className="fa fa-eye text-blue h5 ml1" />
                          </div>
                      }
                      </BigKpiWidget>
                    </ConfirmationModalOnComponent>
                ))}
              </div>
            </div>
          </div>
        </div>
        <AddInvestmentKpiModal
          show={showAddKpiModal || !!kpiDefinitionModalFor}
          derivedKpiDefinition={kpiDefinitionModalFor}
          investment={investmentHeader}
          investmentKpis={investmentKpis}
          options={investmentKpis.company_kpis_options}
          unitOptions={investmentKpis.unit_options}
          createKpiField={createKpiField}
          updateKpiField={updateKpiField}
          allowKpiAllInvestments={currentUser.admin_or_unlocked}
          canManageDerivedKpis={currentUser.canManageDerivedKpis}
          canCreateDerivedKpis={currentUser.canCreateDerivedKpis}
          closeModal={() => this.setState({ showAddKpiModal: false, kpiDefinitionModalFor: null })}
          refreshKpisWithCurrentDate={() => changeCurrentDate(investmentKpis.current_date)}
          type={showAddKpiModal ? 'add' : (investmentKpis.edit && currentUser.canManageDerivedKpis ? 'edit' : 'view')}
          disabled={!investmentKpis.edit || (!showAddKpiModal && !currentUser.canManageDerivedKpis)}
        />

        {showManageKpiModal &&
          <ManageInvestmentKpiModal
            investment={investmentHeader}
            investmentKpis={investmentKpis}
            show={showManageKpiModal}
            closeModal={() => this.setState({ showManageKpiModal: false })}
            saveKpiFieldValue={saveKpiFieldValue}
            updateKpiField={updateKpiField}
          />
        }
        <KpiValuesImport
          investorId={investmentHeader.investor.id}
          investmentId={investmentHeader.id}
          show={showImportModal}
          closeModal={() => this.setState({ showImportModal: false })}
        />
      </div>
    );
  }
}

export default InvestmentKpis;
