import React from 'react';
import { connect } from 'react-redux';
import { StripeProvider, Elements } from 'react-stripe-elements';
import PropTypes from 'prop-types';

import IntentForm from './IntentForm';

const IntentModal = ({ stripe, ...props }) => (
  <StripeProvider apiKey={stripe.api_key}>
    <Elements>
      <IntentForm {...props} />
    </Elements>
  </StripeProvider>
);

IntentModal.propTypes = {
  stripe: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
  stripe: state.stripe,
  company: state.company,
});

export default connect(mapStateToProps)(IntentModal);
