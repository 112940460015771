import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FaButton from './FaButton';
import FaButtonFunction from './FaButtonFunction';
import TemplateEditButton from './TemplateEditButton';


class UpdateTemplate extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  state = {
    clicked: false,
  }

  deleteTemplate = () => {
    const { data } = this.props;

    App.Api.delete(data.url).then(() => {
      store.dispatch({ type: 'REMOVE_ITEM', list: 'templates', id: data.id });
    });
  }

  handleClick = () => {
    const { data } = this.props;
    const { clicked } = this.state;

    if (clicked) return;

    App.Api.post(data.use_url);
    this.setState({ clicked: true });
  }

  render() {
    const { data } = this.props;
    const { clicked } = this.state;

    return (
      <div className="col-12 flex flex-wrap sm-px0 flex-center p2 border-top border-alto">
        <div className="col-4 py1 pl2 sm-col-6">
          <div
            className={clicked ? '' : 'cursor-pointer'}
            onClick={this.handleClick}
          >
            <span data-balloon="Use template" className="text-blue">{data.name}</span>
          </div>
        </div>

        <div className="col-6 py1 pl2 sm-col-6 text-medium-gray">
          {data.description}
        </div>

        <div className="col-2 p1 flex sm-col-12 text-medium-gray">
          <div className="flex flex-auto flex-justify-end sm-flex-justify-center">
            <TemplateEditButton url={data.url} name={data.name} description={data.description} />
            <span className="text-light-gray">|</span>
            <FaButton url={data.url} faIcon="eye" tooltip="View Template" />
            <span className="text-light-gray">|</span>
            <FaButtonFunction faIcon="trash" tooltip="Delete" handleClick={this.deleteTemplate} />
          </div>
        </div>

      </div>
    );
  }
}

export default UpdateTemplate;
