import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ children, onClick, classNames, disabled, ...other }) => (
  <div
    onClick={onClick}
    className={`${classNames} ${disabled ? 'btn-disabled' : ''}`}
    {...other}
  >
    {children}
  </div>
);

Button.defaultProps = {
  onClick: null,
  disabled: false,
};

Button.propTypes = {
  classNames: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;
