import PropTypes from 'prop-types';

const FundingShareOptions = ({ share, isCustom, edit, updateActiveModal, deleteFundingShare }) => {
  const moreDetailsPading = edit ? (isCustom ? '33px' : '32px') : '31px';

  return (
    <div
      key={`share_more_details_${share.id}}`}
      className="flex items-center"
      style={{ width: '30%', paddingTop: moreDetailsPading }}
    >
      <div
        className="mr1"
        data-balloon="More details"
        data-balloon-pos="bottom"
        data-balloon-length="small"
        onClick={() => updateActiveModal({ activeModal: 'detailsModal', currentShare: share })}
      >
        <span className="fa fa-info-circle cursor-pointer text-blue" />
      </div>

      {share.share_class ?
        <div
          className="mr1"
          data-balloon="See the details entered by the company for the share class or convertible."
          data-balloon-pos="bottom"
          data-balloon-length="large"
          onClick={() => updateActiveModal({ activeModal: 'shareClassModal', currentShareClass: share.share_class })}
        >
          <span className="fa fa-plus-circle cursor-pointer text-green" />
        </div>
        :
        <div style={{ paddingTop: '11px', paddingBottom: '11px' }} />
      }

      {share.convertible &&
        <div
          className="mr1"
          data-balloon="See the details entered by the company for the share class or convertible."
          data-balloon-pos="bottom"
          data-balloon-length="large"
          onClick={() => updateActiveModal({ activeModal: 'convertibleModal', currentConvertible: share.convertible })}
        >
          <span className="fa fa-plus-circle cursor-pointer text-green" />
        </div>
      }

      {isCustom && edit &&
        <div
          className="fa fa-trash cursor-pointer"
          onClick={() => deleteFundingShare(share.id)}
        />
      }
    </div>
  );
};

FundingShareOptions.propTypes = {
  share: PropTypes.object.isRequired,
  isCustom: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  updateActiveModal: PropTypes.func.isRequired,
  deleteFundingShare: PropTypes.func.isRequired,
};

export default FundingShareOptions;
