import React from 'react';
import PropTypes from 'prop-types';


const ShUpdate = ({ data }) => (
  <a className="col-12 flex border-bottom border-alto mb2 pb2 text-gray" href={data.url} target="_blank" rel="noopener noreferrer">
    <div className="col-2">
      <span className="bg-blue circle flex flex-center flex-justify-center h6 mr2 text-white" style={{ height: '30px', width: '30px' }}>
        {data.month}
      </span>
    </div>

    <div className="col-11">
      <p className="bold lh-normal h5 m0">{data.title}</p>
      <div className="flex flex-row h6 lh-normal m0 text-light-gray">
        {data.subTitle}
        {data.comments > 0 &&
          <div className="ml1">
            <i className="fa mr02 fa-comments-o" />
            <span className="h6">{data.comments}</span>
          </div>
        }
        {data.likes > 0 &&
          <div className="ml1">
            <i className="fa mr02 fa-hand-paper-o" />
            <span className="h6">{data.likes}</span>
          </div>
        }
        {data.attachments > 0 &&
          <div className="ml1">
            <i className="fa mr02 fa-paperclip" />
            <span className="h6">{data.attachments}</span>
          </div>
        }
      </div>
    </div>

    <div className="col-1 flex flex-center flex-justify-end">
      <i className="fa fa-angle-right mr1" />
    </div>
  </a>
);

ShUpdate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ShUpdate;
