import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DataRoomSections from './DataRoomSections';
import DataRoomFiles from './DataRoomFiles';
import DataRoomComments from './DataRoomComments';


class DataRoom extends Component {
  static propTypes = {
    editor: PropTypes.bool.isRequired,
    dataRoom: PropTypes.object.isRequired,
    company: PropTypes.object,
    currentUser: PropTypes.object,
    blockFileDownload: PropTypes.bool,
    blockCreation: PropTypes.bool,
    comments: PropTypes.object,
    statsLink: PropTypes.string,
    createSection: PropTypes.func,
    renameSection: PropTypes.func,
    deleteSection: PropTypes.func,
    updateSectionPosition: PropTypes.func,
    createFile: PropTypes.func,
    moveFile: PropTypes.func,
    updateFile: PropTypes.func,
    updateFilePosition: PropTypes.func,
    deleteFile: PropTypes.func,
    createComment: PropTypes.func,
  }

  static defaultProps = {
    company: {},
    currentUser: {},
    blockFileDownload: false,
    blockCreation: false,
    comments: null,
    statsLink: null,
    createSection: null,
    renameSection: null,
    deleteSection: null,
    updateSectionPosition: null,
    createFile: null,
    moveFile: null,
    updateFile: null,
    updateFilePosition: null,
    deleteFile: null,
    createComment: null,
  }

  constructor(props) {
    super(props);

    this.commentRef = React.createRef();

    this.state = {
      loading: false,
      selectedSection: props.dataRoom.data_room_sections[0].id,
    };
  }

  selectSection = (id) => {
    this.setState({ selectedSection: id });
  }

  createSection = params => {
    const { dataRoom, createSection } = this.props;
    createSection(dataRoom.id, params);
  }

  renameSection = async (id, params) => {
    const { dataRoom, renameSection } = this.props;

    try {
      await renameSection(dataRoom.id, id, params);
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
    }
  }

  deleteSection = async id => {
    const { dataRoom, deleteSection } = this.props;
    const { selectedSection } = this.state;
    if (selectedSection === id) this.setState({ selectedSection: dataRoom.data_room_sections[0].id });

    try {
      await deleteSection(dataRoom.id, id);
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
    }
  }

  updateSectionPosition = async (id, params) => {
    const { dataRoom, updateSectionPosition } = this.props;

    try {
      await updateSectionPosition(dataRoom.id, id, params);
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
    }
  }

  uploadFiles = async (files) => {
    const { dataRoom, createFile } = this.props;
    const { selectedSection } = this.state;

    if (dataRoom.reached_limit) {
      App.State.setFlash({ name: 'alert', msg: 'No more files can be uploaded' });
      return;
    }

    this.setState({ loading: true });

    await Promise.all(files.map(file => {
      const formData = new FormData();
      formData.append('data_room_file[data_room_section_id]', selectedSection);
      formData.append('data_room_file[attachment]', file);
      return createFile(dataRoom.id, formData);
    }));

    this.setState({ loading: false });
  }

  saveLink = async (args) => {
    const { dataRoom, createFile } = this.props;
    const { selectedSection } = this.state;

    const params = {
      ...args,
      data_room_section_id: selectedSection,
    };

    this.setState({ loading: true });

    await createFile(dataRoom.id, params);
    this.setState({ loading: false });
  }

  updateFile = (id, params) => {
    const { dataRoom, updateFile } = this.props;
    updateFile(dataRoom.id, id, params);
  }

  updateFilePosition = async (id, params) => {
    const { dataRoom, updateFilePosition } = this.props;

    try {
      await updateFilePosition(dataRoom.id, id, params);
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
    }
  }

  moveFile = (id, params) => {
    const { dataRoom, moveFile } = this.props;
    moveFile(dataRoom.id, id, params);
  }

  deleteFile = id => {
    const { dataRoom, deleteFile } = this.props;
    deleteFile(dataRoom.id, id);
  }

  render() {
    const {
      editor,
      comments,
      currentUser,
      blockFileDownload,
      blockCreation,
      company,
      dataRoom,
      createComment,
      statsLink,
    } = this.props;
    const { loading, selectedSection } = this.state;

    const sectionOptions = dataRoom.data_room_sections.filter(s => s.id !== selectedSection).map(s => ({ value: s.id, label: s.name }));

    return (
      <div className="mb3">
        <div className="flex flex-justify-between pb2 sm-flex-column">
          <div className="flex flex-column lh-12 flex-justify-center">
            <div className="h3 bold">{dataRoom.name}</div>
            { dataRoom.last_upload === '-' ?
              <div className="h5 text-light-gray light">No documents uploaded yet...</div>
              :
              <div className="h5 text-light-gray light">Last upload {dataRoom.last_upload} - {dataRoom.files_count} ({dataRoom.total_size}) Documents</div>
            }
          </div>
          <div className="flex flex-center h5 text-light-gray">
            {!dataRoom['default_data_room?'] && comments && dataRoom.allow_comments &&
              <div
                className="bg-white text-gray fw400 bs-around card--has-hover-light-gray__small py05 px1 rounded mr2 cursor-pointer"
                onClick={() => this.commentRef.current.scrollIntoView({ behavior: 'smooth' })}
              >
                Comments
              </div>
            }
            {statsLink &&
              <a
                className="bg-white text-gray fw400 bs-around card--has-hover-light-gray__small py05 px1 rounded mr2 sm-mt1"
                href={statsLink}
              >
                View Stats
              </a>
            }
            {!blockFileDownload &&
              <a
                className="bg-white text-gray fw400 bs-around card--has-hover-light-gray__small py05 px1 rounded sm-mt1"
                href={`${dataRoom.shareable_link}/files/${comments && comments.access_token ? `?access_token=${comments.access_token}` : ''}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download All
              </a>
            }
          </div>
        </div>

        {loading &&
          <div className="bottom-0 flex flex-center flex-justify-center fixed left-0 right-0 top-0 z5" style={{ backgroundColor: 'rgba(0,0,0,.37)' }}>
            <div className="relative z1">
              <img
                alt="loading"
                src={window.images.loading}
                width="64"
              />
            </div>
          </div>
        }

        <div className="flex mxn1 sm-block px1">
          <div className="bg-white bs-around col-12 flex relative">
            <div className="col-3 sm-col-12 bg-gallery">
              <DataRoomSections
                editor={editor}
                blockCreation={blockCreation}
                selected={selectedSection}
                sections={dataRoom.data_room_sections}
                selectSection={this.selectSection}
                createSection={this.createSection}
                renameSection={this.renameSection}
                deleteSection={this.deleteSection}
                updateSectionPosition={this.updateSectionPosition}
                uploadFiles={this.uploadFiles}
                saveLink={this.saveLink}
              />
            </div>
            <div className="col-9 flex sm-col-12">
              <DataRoomFiles
                editor={editor}
                blockFileDownload={blockFileDownload}
                files={dataRoom.data_room_sections.find(s => s.id === selectedSection).data_room_files}
                uploadFiles={this.uploadFiles}
                moveFile={this.moveFile}
                updateFile={this.updateFile}
                updateFilePosition={this.updateFilePosition}
                deleteFile={this.deleteFile}
                sectionOptions={sectionOptions}
              />
            </div>
          </div>
        </div>

        {!dataRoom['default_data_room?'] && comments &&
          <div ref={this.commentRef}>
            <DataRoomComments
              editor={editor}
              comments={comments}
              company={company}
              currentUser={currentUser}
              createComment={createComment}
              allowence={dataRoom.allow_comments}
              hasRecipients
            />
          </div>
        }
      </div>
    );
  }
}

export default DataRoom;
