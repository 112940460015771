import PropTypes from 'prop-types';

import Modal from '../../../Modal';
import CrunchSearch from '../../../crunch_search/CrunchSearch';
import SelectWithModal from '../../../selects/SelectWithModal';


const SetupInvestmentModal = ({ show, companyType, currentResult, investor, stepBackward, closeModal, handleCrunchbaseResult, blockCreate, loading, creationType, addedFunds, addedManagers, addElement, removeElement, submit }) => (
  <Modal
    show={show}
    loading={loading}
    widthClass="react_modal--800"
  >
    <div className="flex flex-justify-between items-center bold p2 border-bottom border-lighter-gray">
      <div className="h3 text-gray center">Add Company</div>
      <i className="fa fa-close h5 cursor-pointer" onClick={closeModal} />
    </div>

    <div className="mx2">
      <div className="mt2 mb1">
        <span className="flex items-center flex-justify-between h5 text-gray fw400">
          Search or Add Company Name
          <a href="http://www.crunchbase.com" target="_blank" rel="noopener noreferrer">
            <img src="/images/powered_by_crunchbase.png" alt="powered_by_crunchbase" className="mnl1 mnb1" style={{ height: '50px' }} />
          </a>
        </span>
      </div>

      <div className="my1">
        <CrunchSearch
          currentResult={currentResult}
          items={{ main: [] }}
          url="/crunch_api/search"
          displayContinueButton={false}
          showDescription
          showCompaniesFromInvestory
          customInvestmentParams
          onChange={result => handleCrunchbaseResult(result)}
        />
      </div>
    </div>
    {!investor.angel && !(companyType === 'real_company' && creationType === 'new') &&
      <div className="flex col-11 mb2 mx2 pt1">
        <div className="flex-column col-6 h5">
          <span className="mr1 fw400">Assign Funds</span>
          {!blockCreate ?
            <SelectWithModal
              selectedElements={addedFunds}
              addElement={(selectedEl) => addElement('funds', selectedEl)}
              deleteElement={(fundId) => removeElement('funds', fundId)}
              url={`/investor/${investor.id}/queries/funds/?type=options_for_select`}
              title="Assign Funds"
              placeholder="Click here"
              selectRawOption
            />
            :
            <div className="h5">-</div>
          }
        </div>
        <div className="flex-column col-5 h5">
          <span className="mr1 fw400">Assign Managers</span>
          {!blockCreate ?
            <SelectWithModal
              selectedElements={addedManagers}
              addElement={(selectedEl) => addElement('managers', selectedEl)}
              deleteElement={(managerId) => removeElement('managers', managerId)}
              url={`/investor/${investor.id}/queries/investment_managers/?type=options_for_select`}
              title="Assign Managers"
              placeholder="Click here"
              selectRawOption
            />
            :
            <div className="h5">-</div>
          }
        </div>
      </div>
    }

    <div className="flex flex-justify-between items-center p2 border-top border-lighter-gray">
      <div
        className="h5 p1 px2 cursor-pointer bg-white text-blue border border-blue rounded"
        onClick={stepBackward}
      >
        &#60; Back
      </div>

      <div className="flex items-center">
        <div
          className="h5 text-blue mr2 cursor-pointer"
          onClick={closeModal}
        >
          Cancel
        </div>
        {companyType === 'real_company' ?
          (creationType === 'existing_company' ?
            <div
              className={`h5 p1 px2 text-white rounded ${blockCreate ? 'bg-lighter-gray' : 'bg-blue cursor-pointer'}`}
              onClick={submit}
            >
              {loading ? 'Creating...' : (creationType === 'existing_company' ? 'Create' : 'Continue')}
            </div>
            :
            <div
              className={`h5 p1 px2 text-white rounded ${blockCreate ? 'bg-lighter-gray' : 'bg-blue cursor-pointer'}`}
              onClick={submit}
            >
              {loading ? 'Continue...' : 'Continue'}
            </div>
          )
          :
            <div
              className={`h5 p1 px2 text-white rounded ${blockCreate ? 'bg-lighter-gray' : 'bg-blue cursor-pointer'}`}
              onClick={submit}
            >
              {loading ? 'Creating...' : (creationType === 'existing_company' ? 'Create' : 'Continue')}
            </div>
        }
      </div>
    </div>
  </Modal>
);

SetupInvestmentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  currentResult: PropTypes.object,
  investor: PropTypes.object.isRequired,
  stepBackward: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleCrunchbaseResult: PropTypes.func.isRequired,
  blockCreate: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  creationType: PropTypes.string.isRequired,
  companyType: PropTypes.string.isRequired,
  addedFunds: PropTypes.array.isRequired,
  addedManagers: PropTypes.array.isRequired,
  addElement: PropTypes.func.isRequired,
  removeElement: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

SetupInvestmentModal.defaultProps = {
  currentResult: null,
};

export default SetupInvestmentModal;
