import field from './funding_field';

const funding = (state = {}, action) => {
  switch (action.type) {
    case 'SET_FUNDING':
      return Object.assign(state, action.funding);

    case 'SET_FUNDING_SHARE':
      return {
        ...state,
        shares: state.shares.map(s => {
          if (s.id === action.share.id) return action.share;
          return s;
        }),
      };

    case 'OVERWRITE_FUNDING_FIELDS':
      return {
        ...state,
        funding_fields: state.funding_fields.map(f => field(f, action)),
      };

    case 'UPDATE_FUNDING_FIELDS':
      return {
        ...state,
        funding_fields: state.funding_fields.map(f => field(f, action)),
      };

    case 'RESET_FUNDING_FIELDS':
      return {
        ...state,
        funding_fields: state.funding_fields.map(f => field(f, action)),
      };

    case 'ADD_FUNDING_SHARE':
      return {
        ...state,
        shares: [...state.shares, action.share],
      };

    case 'REMOVE_FUNDING_SHARE':
      return {
        ...state,
        shares: state.shares.filter(s => s.id !== action.share.id),
      };

    case 'REMOVE_FUNDING_SHARES':
      return {
        ...state,
        shares: [],
        security_type_options: [],
      };

    case 'SET_FUNDING_ROUND':
      return {
        ...state,
        rounds: state.rounds.map(s => {
          if (s.id === action.round.id) return action.round;
          return s;
        }),
      };

    case 'ADD_FUNDING_ROUND':
      return {
        ...state,
        rounds: [...state.rounds, action.round],
      };

    case 'REMOVE_FUNDING_ROUND':
      return {
        ...state,
        rounds: state.rounds.filter(r => r.id !== action.round.id),
      };

    case 'REMOVE_FUNDING_ROUNDS':
      return {
        ...state,
        rounds: [],
      };

    case 'APPLY_COMPANY_DATA':
      return {
        ...state,
        ...action.data,
        options: {
          ...state.options,
          ...action.data.options,
        },
      };

    case 'APPLY_TOTALS':
      return {
        ...state,
        total_invested: action.data.total_invested,
        total_ownership: action.data.total_ownership,
        total_realized: action.data.total_realized,
        total_shares: action.data.total_shares,
        current_share_value: action.data.current_share_value,
        total_investment_multiple: action.data.total_investment_multiple,
        transactions_data: action.data.transactions_data,
        has_converted_cashflow: action.data.has_converted_cashflow,
      };

    case 'SET_lOADING':
      return {
        ...state,
        [action.target]: action.isLoading,
      };

    case 'UPDATE_SHARE_CLASS':
      return {
        ...state,
        options: {
          ...state.options,
          security_type_options: action.data.security_type_options,
        },
        shares: state.shares.map(share => (
          share.share_class ?
            (
              share.share_class.id === action.id ?
                {
                  ...share,
                  share_class: { ...share.share_class, ...action.data.share_class },
                }
                :
                { ...share }
            )
            :
            { ...share }
        )),
      };

    case 'REMOVE_SHARE_CLASS':
      return {
        ...state,
        options: {
          ...state.options,
          security_type_options: action.data.security_type_options,
        },
        shares: state.shares.map(share => (
          share.share_class ?
            (
              share.share_class.id === action.id ?
                {
                  ...share,
                  share_class: null,
                }
                :
                { ...share }
            )
            :
            { ...share }
        )),
      };

    case 'UPDATE_CONVERTIBLE':
      return {
        ...state,
        options: {
          ...state.options,
          security_type_options: action.data.security_type_options,
        },
        shares: state.shares.map(share => (
          share.convertible ?
            (
              share.convertible.id === action.id ?
                {
                  ...share,
                  convertible: { ...share.convertible, ...action.data.convertible },
                }
                :
                { ...share }
            )
            :
            { ...share }
        )),
      };

    case 'REMOVE_CONVERTIBLE':
      return {
        ...state,
        options: {
          ...state.options,
          security_type_options: action.data.security_type_options,
        },
        shares: state.shares.map(share => (
          share.convertible ?
            (
              share.convertible.id === action.id ?
                {
                  ...share,
                  convertible: null,
                }
                :
                { ...share }
            )
            :
            { ...share }
        )),
      };

    default: return state;
  }
};

export default funding;
