import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';

import InvestorCustomReminderFilterModal from './InvestorCustomReminderFilterModal';
import InvestorCustomReminderSendMessageModal from './InvestorCustomReminderSendMessageModal';
import InvestorCustomReminderSetAutoMessageModal from './InvestorCustomReminderSetAutoMessageModal';

import Switch from '../../Switch';


class InvestorCustomReminderMenu extends Component {
  static propTypes = {
    ids: PropTypes.object.isRequired,
    items: PropTypes.object.isRequired,
    data: PropTypes.object,
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    data: null,
  }

  constructor(props) {
    super(props);

    const { data } = this.props;

    this.initialState = {
      lastMonthKpis: data.filter_last_month_kpis || '',
      percentageCompleted: data.filter_percentage_completed || '',
      lastMonthUpdates: data.filter_last_month_updates || '',
      capTableUpdated: data.filter_cap_table_updated_at || '',
    };

    this.resetedState = {
      lastMonthKpis: '',
      percentageCompleted: '',
      lastMonthUpdates: '',
      capTableUpdated: '',
    };

    this.state = {
      modal: null,
      ...this.initialState,
      enabled: data.enabled,
      autoReminderFilteredCompaniesCount: data.filtered_investments_count,
      saving: false,
    };
  }

  componentDidMount() {
    this.setFilter(true);
  }

  closeModal = () => {
    this.setState({ modal: null });
  }

  updateState = (stateName, value) => {
    this.setState({ [stateName]: value });
  }

  investmentSelected = (investmentId) => {
    const { items } = this.props;

    return items.selected.find(element => element.id === investmentId);
  }

  removeInvestmentFromReminder = (investmentId) => {
    if (this.investmentSelected(investmentId)) {
      store.dispatch({ type: 'REMOVE_ITEM', list: 'selected', id: investmentId });
    }
  }

  addInvestmentToReminder = (investmentId) => {
    if (!this.investmentSelected(investmentId)) {
      store.dispatch({ type: 'ADD_ITEM', list: 'selected', value: { id: investmentId } });
    }
  }

  setFilter = async (onComponentMount = false) => {
    const { items, ids } = this.props;
    const { lastMonthKpis, percentageCompleted, lastMonthUpdates, capTableUpdated, saving } = this.state;

    if (saving) return;
    this.setState({ saving: true });

    const lastMonthKpisDate = lastMonthKpis ? moment().subtract(lastMonthKpis, 'months').format('YYYY-MM-DD') : null;
    const percentageCompletedParsed = percentageCompleted ? parseFloat(percentageCompleted) : null;
    const lastMonthUpdatesDate = lastMonthUpdates ? moment().subtract(lastMonthUpdates, 'months').format('YYYY-MM-DD') : null;
    const capTableUpdatedDate = capTableUpdated ? moment().subtract(capTableUpdated, 'months').format('YYYY-MM-DD') : null;

    const params = {
      update_filters: true,
      investor_custom_reminder_params: {
        filter_last_month_kpis: lastMonthKpis,
        filter_percentage_completed: percentageCompleted,
        filter_last_month_updates: lastMonthUpdates,
        filter_cap_table_updated_at: capTableUpdated,
      },
    };

    if (!onComponentMount) {
      try {
        await axios.patch(`/investor/${ids.investorId}/investor_custom_reminders`, params);
      } catch (err) {
        App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an error prevented the investment from being created.' });
      }
    }

    // alwaysTop attribute belongs to he Header
    const newList = items.main.filter(element => !element.alwaysTop).map(element => {
      // if (lastMonthKpisDate && percentageCompleted) {
      //   if (((element.raw_date_of_latest_kpi_data <= lastMonthKpisDate) && (element.kpis_completion < percentageCompletedParsed)) ||
      //      !element.raw_date_of_latest_kpi_data || !element.kpis_completion) {
      //     this.addInvestmentToReminder(element.id);
      //     return {
      //       ...element,
      //       hide: false,
      //     };
      //   }
      // } else if (lastMonthKpisDate && (element.raw_date_of_latest_kpi_data <= lastMonthKpisDate || !element.raw_date_of_latest_kpi_data)) {
      //   this.addInvestmentToReminder(element.id);
      //   return {
      //     ...element,
      //     hide: false,
      //   };
      // }
      if ((lastMonthKpisDate && (element.raw_date_of_latest_kpi_data <= lastMonthKpisDate || !element.raw_date_of_latest_kpi_data)) ||
         (percentageCompletedParsed && (element.kpis_completion < percentageCompletedParsed || !element.kpis_completion)) ||
         (lastMonthUpdatesDate && (element.raw_date_of_latest_update_received <= lastMonthUpdatesDate || !element.raw_date_of_latest_update_received)) ||
         (capTableUpdatedDate && (element.raw_date_of_latest_cap_table_data <= capTableUpdatedDate || !element.raw_date_of_latest_cap_table_data)) ||
         (!lastMonthKpisDate && !percentageCompletedParsed && !lastMonthUpdatesDate && !capTableUpdatedDate)) {
        // this.addInvestmentToReminder(element.id);
        return {
          ...element,
          hide: false,
        };
      }
      this.removeInvestmentFromReminder(element.id);
      return {
        ...element,
        hide: true,
      };
    });
    // the first element is the Header
    store.dispatch({ type: 'SET_LIST', list: 'main', value: [...items.main[0], ...newList] });
    this.setState({ modal: null, saving: false });
  }

  handleEnabled = () => {
    const { ids } = this.props;
    const { enabled } = this.state;

    this.setState({ enabled: !enabled }, async () => {
      const { enabled: innerEnabled } = this.state;
      const params = { investor_custom_reminder_params: { enabled: innerEnabled } };
      try {
        await axios.patch(`/investor/${ids.investorId}/investor_custom_reminders`, params);
      } catch (err) {
        App.State.setFlash({ name: 'alert', msg: err.response ? err.response.data.error : 'Sorry, an unknown error happened.' });
      }
    });
  }

  render() {
    const { ids, items, data, children } = this.props;

    const {
      modal,
      lastMonthKpis,
      percentageCompleted,
      lastMonthUpdates,
      capTableUpdated,
      enabled,
      autoReminderFilteredCompaniesCount,
    } = this.state;

    return (
      <>
        <div className="flex items-center flex-auto flex-wrap">
          <div>
            {children ?
              <>
                {children}
              </>
              :
              null
            }
          </div>
          <div className="flex flex-auto flex-justify-end">
            <div className="flex flex-column">
              <div className="mb2 h5 text-light-gray flex flex-column flex-center">
                <span>These are investor reminders, and can be set according to each investor's preferences. There are also update reminders</span>
                <span>sent to companies automatically by the platform, based on the frequency of updates they have selected for their account.</span>
              </div>

              <div className="flex items-center flex-justify-end">
                <div>
                  <div className="center text-light-gray fw400">Automatic Reminders</div>
                  <div className="flex items-center mr2 border border-light-gray rounded p2">
                    <div
                      className="px1 py1 rounded bg-blue cursor-pointer mr2 center"
                      style={{ width: '150px' }}
                      onClick={() => this.setState({ modal: 'automatic' })}
                    >
                      <span className="text-white fw400">Define Rules</span>
                    </div>
                    <div
                      data-balloon="Enable or disable automatic reminders"
                      data-balloon-pos="down"
                      data-balloon-length="large"
                    >
                      <Switch
                        defaultChecked={enabled}
                        onChange={this.handleEnabled}
                        labelLeft="Off"
                        labelRight="On"
                        withConfirmation
                        confirmationMessage={`If this reminder was sent today, it would go to <b>${autoReminderFilteredCompaniesCount} ${autoReminderFilteredCompaniesCount === 1 ? 'company' : 'companies'}.</b> Are you sure?`}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="center text-light-gray fw400">Manual Reminders</div>
                  <div className="flex flex-justify-end border border-light-gray rounded p2">
                    <div
                      className="px2 py1 rounded bg-light-gray cursor-pointer center"
                      style={{ width: '150px' }}
                      onClick={() => this.setState({ modal: 'filter' })}
                    >
                      <span className="text-white fw400">Select/Filter</span>
                    </div>
                    <div
                      className="px1 py1 rounded border border-light-gray ml2 cursor-pointer center"
                      style={{ width: '150px' }}
                      onClick={() => this.setState({ ...this.resetedState }, () => this.setFilter())}
                    >
                      <span className="text-light-gray fw400">Clear Filter</span>
                    </div>
                    <div
                      className="px2 py1 rounded bg-green ml2 cursor-pointer center"
                      style={{ width: '150px' }}
                      onClick={() => this.setState({ modal: 'send' })}
                    >
                      <span className="text-white fw400">Send</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {modal === 'send' &&
          <InvestorCustomReminderSendMessageModal ids={ids} items={items} closeModal={this.closeModal} />
        }

        {modal === 'automatic' &&
          <InvestorCustomReminderSetAutoMessageModal
            ids={ids}
            closeModal={this.closeModal}
            data={data}
          />
        }

        {modal === 'filter' &&
          <InvestorCustomReminderFilterModal
            lastMonthKpis={lastMonthKpis}
            percentageCompleted={percentageCompleted}
            lastMonthUpdates={lastMonthUpdates}
            capTableUpdated={capTableUpdated}
            setFilter={this.setFilter}
            closeModal={this.closeModal}
            updateState={this.updateState}
          />
        }
      </>
    );
  }
}

const mapStateToProps = (store) => (
  {
    items: store.get('items').toJS(),
  }
);

export default connect(mapStateToProps)(InvestorCustomReminderMenu);
