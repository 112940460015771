import React from 'react';
import PropTypes from 'prop-types';

const TeamInfoCard = ({ record }) => (
  <div className="col-4 lg-col-6 sm-col-12 flex px2 mb3">
    <div className="bg-white card--has-hover card--has-layer bs-around-small center flex flex-auto flex-center flex-column p3 relative rounded">
      <div className="flex-auto">
        <i className="fa fa-user fa--search-card position-left-bottom absolute text-extra-light-gray" />
        <a href={record.url}>
          <div
            className="search-card__img bg-center bg-cover bg-no-repeat border border-light-gray circle mb1 mx-auto"
            style={{ backgroundImage: `url(${record.avatar})` }}
          />
          <p className="bold h3 m0 mb1 text-outer-space">{record.name}</p>
          <p className="h5 m0 mb2 text-light-gray">{record.about}</p>
        </a>
      </div>
    </div>
  </div>
);

TeamInfoCard.propTypes = {
  record: PropTypes.object.isRequired,
};

export default TeamInfoCard;
