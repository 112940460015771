const addQuickAccessFile = file => ({
  type: 'ADD_QUICK_ACCESS_FILE',
  file,
});

const setQuickAccessFile = file => ({
  type: 'SET_QUICK_ACCESS_FILE',
  file,
});

const removeQuickAccessFile = file => ({
  type: 'REMOVE_QUICK_ACCESS_FILE',
  file,
});

const removeDataRoom = room => ({
  type: 'REMOVE_DATA_ROOM',
  room,
});

export {
  addQuickAccessFile,
  setQuickAccessFile,
  removeQuickAccessFile,
  removeDataRoom,
};
