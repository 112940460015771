import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../store/configureStore';
import SetupRequestContainer from '../containers/signing_room/SetupRequestContainer';


const SigningRoomSetupRequest = props => (
  <Provider store={configureStore(props)}>
    <SetupRequestContainer />
  </Provider>
);

export default SigningRoomSetupRequest;
