import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';


class DatePicker extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    invalidDates: PropTypes.bool,
    showMonthYear: PropTypes.bool,
    clearDateIfEmpty: PropTypes.bool,
    skipPropsUpdate: PropTypes.bool,
    blockDeletion: PropTypes.bool,
  }

  static defaultProps = {
    placeholder: 'YYYY-MM-DD',
    onChange: null,
    onBlur: null,
    invalidDates: false,
    showMonthYear: false,
    clearDateIfEmpty: false,
    skipPropsUpdate: true,
    blockDeletion: false,
  }

  constructor(props) {
    super(props);

    const { value } = this.props;

    this.state = {
      value,
      invalidDate: false,
    };
  }

  componentDidMount() {
    const { showMonthYear } = this.props;
    const { value } = this.state;

    if (showMonthYear) {
      $(this.input).datepicker({
        changeMonth: true,
        changeYear: true,
        showButtonPanel: true,
        dateFormat: 'yy-mm',
        defaultDate: moment(value).toDate(),

        beforeShow() {
          $('#ui-datepicker-div').addClass('ui-datepicker__month-year');
        },
      }).focus(() => {
        $('.ui-datepicker-calendar').detach();
        $('.ui-datepicker-close').on('click', () => {
          const month = $('#ui-datepicker-div .ui-datepicker-month :selected').val();
          const year = $('#ui-datepicker-div .ui-datepicker-year :selected').val();
          $(this.input).datepicker('setDate', new Date(year, month, 1));
          $(this.input).trigger('change');
        });
      });
    } else {
      $(this.input).datepicker({
        dateFormat: 'yy-mm-dd',
      });
      // .keyup((e) => {
      //   // 8 -> backspace; 46 -> delete
      //   if (e.keyCode === 8 || e.keyCode === 46) {
      //     const { onChange } = this.props;

      //     if (!blockDeletion) {
      //       $(this.input).datepicker('setDate', '');
      //       this.setState({ value: '' });
      //       if (onChange) onChange(e, '');
      //     }
      //   }
      // });
    }
    $(this.input).on('change', this.onChange);
  }

  componentDidUpdate() {
    const { skipPropsUpdate, value: propsValue } = this.props;
    const { value } = this.state;

    if (skipPropsUpdate) {
      return;
    }
    if (propsValue !== value) {
      this.setState({ value: propsValue });
    }
  }

  componentWillUnmount() {
    $(this.input).datepicker('destroy');
  }

  maskDate = value => {
    const v = value.replace(/\D/g, '').slice(0, 8);
    if (v.length >= 7) {
      return `${v.slice(0, 4)}-${v.slice(4, 6)}-${v.slice(6)}`;
    }
    if (v.length >= 5) {
      return `${v.slice(0, 4)}-${v.slice(4)}`;
    }
    return value;
  }

  onChange = (e, val = null, name = null, source = null) => {
    const { onChange, onBlur, clearDateIfEmpty, blockDeletion, showMonthYear } = this.props;
    const { value } = this.state;

    if (blockDeletion && source === 'input') return;

    const innerValue = (e && e.target) ? e.target.value : val;

    if (innerValue.length === 10 || showMonthYear) {
      if (Date.parse(innerValue)) {
        $(this.input).datepicker('option', 'defaultDate', moment(innerValue).toDate());

        this.setState({ value: innerValue, invalidDate: false }, () => {
          if (onChange) onChange(e || { target: { value: innerValue, name } }, innerValue);
          if (onBlur) onBlur(e || { target: { value: innerValue, name } }, innerValue);
          if (clearDateIfEmpty) {
            if (!value) {
              $(this.input).datepicker('setDate', null);
              $(this.input).blur();
            }
          }
        });
      } else {
        App.State.setFlash({ name: 'alert', msg: 'Date is not valid.' });
        this.setState({ invalidDate: true, value: '' });
      }
    } else {
      this.setState({ invalidDate: true, value: this.maskDate(innerValue) });
    }
  }

  render() {
    const { value: v, onChange, onBlur, invalidDates, showMonthYear, clearDateIfEmpty, skipPropsUpdate, blockDeletion, placeholder, ...other } = this.props;
    const { invalidDate, value } = this.state;

    return (
      <input
        type="text"
        ref={(c) => { this.input = c; }}
        className={`${(invalidDate || invalidDates) ? 'border-red' : ''}`}
        onChange={e => this.onChange(null, e.target.value, e.target.name, 'input')}
        value={value}
        placeholder={placeholder}
        {...other}
      />
    );
  }
}

export default DatePicker;
