import React from 'react';
import { Provider } from 'react-redux';
import configureStore from '../../store/configureStore';
import BillingContainer from '../../containers/investor/BillingContainer';

const InvestorBilling = props => (
  <Provider store={configureStore(props)}>
    <BillingContainer />
  </Provider>
);

export default InvestorBilling;
