import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../Modal';
import InvestorsAndCompaniesSearch from '../../../onboarding/utils/InvestorsAndCompaniesSearch';


const InviteCompanyContactModal = ({ show, companyContact, stepBackward, closeModal, addCompanyContact, removeCompanyContact, loading, submit }) => (
  <Modal
    show={show}
    loading={loading}
  >
    <div className="flex flex-justify-between items-center bold p2 border-bottom border-lighter-gray">
      <div className="h3 text-gray center">Add Company</div>
      <i className="fa fa-close h5 cursor-pointer" onClick={closeModal} />
    </div>

    <div className="mx2" style={{ maxWidth: '540px' }}>
      <div className="mt2 mb1">
        <span className="h5 text-gray fw400">
          Invite Main Company Contact
        </span>
      </div>

      <div className="my1">
        <InvestorsAndCompaniesSearch
          searchUrl="/onboarding/companies/step/2"
          resultSelect={(result) => addCompanyContact(result)}
          dropdownText="Search Company contact"
          placeholder="Add the company name or the contact email address"
          allowEmail
        />
      </div>

      <div className="flex flex-column text-light-gray mt1 mb2 h5">
        <span>Invite the company main contact, so they can setup a company account and provide their data (Only invite one person of a company).</span>
        {companyContact &&
          <div className="flex items-center mt1 text-black show-trash-on-hover">
            <span className="flex flex-column mr1 fw400">Invite will be sent to
              <span className="light">{companyContact.email || companyContact.name}</span>
            </span>
            <i
              className="fa fa-trash opacity-0 text-red cursor-pointer mt2"
              onClick={removeCompanyContact}
            />
          </div>
        }
      </div>
    </div>

    <div className="flex flex-justify-between items-center p2 border-top border-lighter-gray">
      <div
        className="h5 p1 px2 cursor-pointer bg-white text-blue border border-blue rounded"
        onClick={stepBackward}
      >
        &#60; Back
      </div>

      <div className="flex items-center">
        <div
          className="h5 text-blue mr2 cursor-pointer"
          onClick={closeModal}
        >
          Cancel
        </div>
        <div
          className="h5 p1 px2 text-white rounded bg-blue cursor-pointer"
          onClick={submit}
        >
          {loading ? 'Creating...' : 'Finish'}
        </div>
      </div>
    </div>
  </Modal>
);

InviteCompanyContactModal.propTypes = {
  show: PropTypes.bool.isRequired,
  companyContact: PropTypes.object,
  stepBackward: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  addCompanyContact: PropTypes.func.isRequired,
  removeCompanyContact: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
};

InviteCompanyContactModal.defaultProps = {
  companyContact: null,
};

export default InviteCompanyContactModal;
