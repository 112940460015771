export default (function state() {
  let state = {};
  let listeners = [];

  const getState = () => state;

  const setFlash = (flash) => {
    // flash = {name: name, msg: msg}

    state.flash = flash;
    broadcastState();

    if (flash) {
      setTimeout(() => setFlash(null), 2000);
    }
  };

  const broadcastState = () => {
    listeners.forEach(l => l());
  };

  const subscribe = (f) => {
    listeners = [...listeners, f];
    return () => {
      listeners = listeners.filter(f => f !== f);
    };
  };

  return {
    getState,
    setFlash,
    subscribe
  };
}());
