import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SubscriptionDetails from './SubscriptionDetails';
import PaymentModal from '../../payment/PaymentModal';
import ConfirmationModal from '../../modals/ConfirmationModal';


class CustomerInfo extends Component {
  static propTypes = {
    customer: PropTypes.object.isRequired,
    updateCard: PropTypes.func.isRequired,
    deleteCustomer: PropTypes.func.isRequired,
    subscription: PropTypes.object,
    plans: PropTypes.object.isRequired,
    subscribe: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,
    resubscribe: PropTypes.func.isRequired,
  }

  static defaultProps = {
    subscription: null,
  }

  state = {
    saving: false,
    showPaymentModal: false,
    deleteCustomerModal: false,
  }

  closeModal = () => this.setState({ showPaymentModal: false, deleteCustomerModal: false });

  updateCard = async (params) => {
    const { updateCard } = this.props;
    this.setState({ saving: true });

    await updateCard(params);
    App.State.setFlash({ name: 'notice', msg: 'Your card is saved successfully.' });
    this.setState({ saving: false });
  }

  deleteCustomer = async () => {
    const { deleteCustomer } = this.props;
    this.setState({ saving: true });

    try {
      await deleteCustomer();
      App.State.setFlash({ name: 'notice', msg: 'Your card details are gone.' });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
    }
    this.setState({ saving: false });
  }

  render() {
    const { customer, updateCard, ...props } = this.props;
    const { saving, showPaymentModal, deleteCustomerModal } = this.state;

    return (
      <div className="flex flex-column">
        <div className="bg-white fw400 mb3 bs-around text-gray">
          <div className="border-bottom border-gallery p2 h4 bold">Billing/Payment Settings</div>
          <SubscriptionDetails {...props} />
          {customer.vat_number &&
            <div className="flex pt2 mx2 h5">
              <div className="col-3 text-light-gray">VAT Number</div>
              <div className="col-7 flex text-gray">
                {customer.vat_number}
              </div>
            </div>
          }
          <div className="flex py2 mx2 h5">
            <div className="col-3 text-light-gray">Payment Method</div>
            <div className="col-7 flex text-gray">
              {customer.brand} ending in {customer.last4}, exp: {customer.exp_month}/{customer.exp_year}
            </div>
            {saving ?
              <img alt="Please wait..." src={window.images.loading} width="32" height="32" />
              :
              <div className="col-2 flex flex-justify-end">
                <div className="text-blue cursor-pointer" onClick={() => this.setState({ showPaymentModal: true })}>Update</div>
                {!props.subscription &&
                  <div className="flex items-center">
                    <i className="fa fa-circle mx1 text-light-gray fs-10" />
                    <div className="text-blue cursor-pointer" onClick={() => this.setState({ deleteCustomerModal: true })}>Delete</div>
                  </div>
                }
              </div>
            }
          </div>
        </div>

        <div className="bg-white fw400 bs-around text-gray">
          <div className="border-bottom border-gallery p2 h4 bold">Invoices</div>
          {customer.invoices.map(invoice => (
            <div key={`invoice_${invoice.uid}`} className="flex items-center mx2 py2 border-bottom border-gallery h5">
              <div className="col-3 text-light-gray">{invoice.period_start}</div>
              <div className="col-7">{invoice.description}</div>
              <a className="col-2 flex items-center flex-justify-end" href={invoice.pdf_link} target="_blank" rel="noopener noreferrer">
                <i className="fa fa-download mr1" />Invoice
              </a>
            </div>
          ))}
        </div>

        {showPaymentModal &&
          <PaymentModal
            closeModal={this.closeModal}
            onSubmit={this.updateCard}
            disableCoupon={!!props.subscription}
          />
        }
        {deleteCustomerModal &&
          <ConfirmationModal
            show={deleteCustomerModal}
            closeModal={this.closeModal}
            title="Delete you details!"
            text="Warning: Deleting you details will remove any subscriptions immediately."
            onSubmit={this.deleteCustomer}
          />
        }
      </div>
    );
  }
}

export default CustomerInfo;
