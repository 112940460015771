import React, { Component } from 'react';
import PropTypes from 'prop-types';
import objectAssign from 'object-assign';

import TextInput from '../TextInput';
import Titlearea from './Titlearea';
import SectionList from './SectionList';


class ShareholderUpdate extends Component {
  static propTypes = {
    post: PropTypes.string,
    uploadGif: PropTypes.string,
  }

  constructor(props) {
    super(props);

    const post = JSON.parse(this.props.post);

    this.state = {
      post,
    };
  }

  setTitle = (title) => {
    this.updatePostState('name', title);
    this.updatePost();
  }

  componentWillMount() {
    this.updateSection = _.debounce(this.updateSection, 1000);
    this.updatePost = _.debounce(this.updatePost, 500);
  }

  createKpiChart = (section, kpiChartParams, kpiPosition) => {
    App.Api.update(section.url, 'section', section).then((updateData) => {
      if (updateData.sections) {
        App.Api.postApplicationJson(section.create_url_kpi_charts, kpiChartParams).then((data) => { this.updatePostState('sections', data.sections); window.scrollTo(0, kpiPosition); });
      }
    },
    ).then();
  }

  createSection = (position, type) => {
    App.Api.createSection(
      this.state.post.create_url,
      type,
      position,
    ).then((data) => {
      this.updatePostState('sections', data.sections);
    });
  }

  deleteKpiChart = (url) => {
    App.Api.deleteSection(url).then((data) => {
      this.updatePostState('sections', data.sections);
    });
  }

  deleteSection = (url, e) => {
    const r = e.altKey || confirm('This section is going to be deleted. Are you sure?');
    if (r === true) {
      App.Api.deleteSection(url).then((data) => {
        this.updatePostState('sections', data.sections);
      });
    }
  }

  moveSection = (url, direction) => {
    App.Api.moveSection(url, direction).then((data) => {
      this.updatePostState('sections', data.sections);
    });
  }

  photoUpload = (files, section, onError) => {
    App.Api
      .uploadFile(section.upload_url, files[0])
      .then((data) => { this.updatePostState('sections', data.sections); })
      .catch(() => {
        onError();
      });
  }

  saveSection = (section) => {
    this.updateSection(section);
  }

  updateKpi = (url, section, kpi_chart_url) => {
    App.Api.update(url, 'section', section,
    ).then((updateData) => {
      if (updateData.sections && kpi_chart_url) {
        this.deleteKpiChart(kpi_chart_url);
      } else {
        this.updatePostState('sections', updateData.sections);
      }
    });
  }

  updatePost = () => {
    App.Api.update(this.state.post.url, 'name', this.state.post.name,
    ).then();
  }

  updatePostState = (key, value) => {
    const oldPost = this.state.post;
    const post = objectAssign({}, oldPost, { [key]: value });

    this.setState({ post });
  }

  updateSection = (section) => {
    App.Api.update(section.url, 'section', section,
    ).then((data) => {
      this.updatePostState('sections', data.sections);
    }).then(
    );
  }

  updateSectionNoDebounce = (section) => {
    App.Api.update(section.url, 'section', section,
    ).then((data) => {
      this.updatePostState('sections', data.sections);
    });
  }

  render() {
    const { post } = this.state;
    return (
      <div>
        <Titlearea
          className="border border-transparent col-12 fs-30 hover hover-border-alto hover-bs-light-gray lh-normal p1 bold sm-p2 text-gray"
          style={{ color: '#313131', resize: 'none' }}
          onChange={this.setTitle}
          placeholder="Please enter a title for the Update"
          value={post.name}
          name="shareholder_update[title]"
          id="shareholder_update_title"
          rows="1"
          preventEnter
          disableBreakLine
        />
        <SectionList
          sections={post.sections}
          kpis={post.kpis}
          openMenu={this.openMenu}
          createSection={this.createSection}
          deleteSection={this.deleteSection}
          moveSection={this.moveSection}
          saveSection={this.saveSection}
          updateSection={this.updateSectionNoDebounce}
          photoUpload={this.photoUpload}
          uploadGif={this.props.uploadGif}
          kpi_options={post.kpi_options}
          chart_types_for_selection={post.chart_types_for_selection}
          createKpiChart={this.createKpiChart}
          updateKpi={this.updateKpi}
        />
      </div>
    );
  }
}

export default ShareholderUpdate;
