import React from 'react';
import PropTypes from 'prop-types';

import { timeRangeOptions } from '../../../utils/kpisDataCalculations';


class SubTabs extends React.Component {
  static propTypes = {
    tab: PropTypes.string.isRequired,
    // recentUpdates: PropTypes.array.isRequired,
    // monthlyUpdates: PropTypes.array.isRequired,
    // investmentCount: PropTypes.number.isRequired,
    activeTab: PropTypes.string.isRequired,
    // angel: PropTypes.bool.isRequired,
    kpiTimeManage: PropTypes.object.isRequired,
    setTabAndResetSorting: PropTypes.func.isRequired,
  }

  tabClass = (tab) => {
    const { activeTab } = this.props;

    if (activeTab === tab) {
      return 'text-gray p2 fw400 bg-extra-light-gray flex items-center container-nav__item--clickable sm-col-12';
    }

    if (tab === 'portfolio_updates') {
      return 'cursor-pointer p2 text-medium-gray fw400 flex items-center container-nav__item--clickable sm-hide';
    }

    return 'cursor-pointer p2 text-medium-gray fw400 flex items-center container-nav__item--clickable sm-col-12';
  }

  // notification = (id) => {
  //   const { recentUpdates, monthlyUpdates, investmentCount } = this.props;

  //   if (id === 'recent_updates') {
  //     const unread = recentUpdates.reduce((previousValue, currentObject) => (
  //       previousValue + (currentObject.visited ? 0 : 1)
  //     ), 0);
  //     return (
  //       <span className="capsoff" data-balloon="# of unread updates">({unread})</span>
  //     );
  //   }
  //   if (id === 'portfolio_updates') {
  //     const posted = monthlyUpdates.reduce((previousValue, currentObject) => (
  //       previousValue + (currentObject.posted_last_month ? 1 : 0)
  //     ), 0);
  //     return (
  //       <span className="capsoff" data-balloon="# of received/total monthly updates" data-balloon-pos="left">({posted}/{investmentCount})</span>
  //     );
  //   }
  //   return null;
  // }

  render() {
    const { tab, kpiTimeManage, setTabAndResetSorting } = this.props;
    const { time_ago: timeAgo } = kpiTimeManage;

    const currentMonthsAgo = (timeRangeOptions(kpiTimeManage.frequency, true).find(timeFrame => timeFrame.value === timeAgo).label);

    if (tab === 'kpis') {
      return (
        <div className="flex flex-justify-end sm-block">
          <div className="relative">
            <div className="flex flex-row flex-center cursor-pointer container-nav__item--clickable" href="#" onClick={() => { setTabAndResetSorting(tab); }}>
              <span className={this.tabClass(tab)}>{currentMonthsAgo}</span>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="flex flex-justify-end sm-block">
        <div
          className={this.tabClass('recent_updates')}
          onClick={() => setTabAndResetSorting('recent_updates')}
        >
          Recent Updates
        </div>
        <div
          className={this.tabClass('portfolio_updates')}
          onClick={() => setTabAndResetSorting('portfolio_updates')}
        >
          Portfolio Updates
        </div>
        {/* {tab.text} {this.notification(tab.id)} */}
      </div>
    );
  }
}

export default SubTabs;
