import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import Modal from '../Modal';
import { defaultSelectStyles, defaultSelectTheme } from '../selects/SelectStyles';


class MoveFileModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    moveFile: PropTypes.func.isRequired,
    sectionOptions: PropTypes.array.isRequired,
  }

  submit = async (selectedId) => {
    const { id, moveFile, closeModal } = this.props;

    const params = {
      data_room_file: {
        data_room_section_id: selectedId,
      },
    };

    await moveFile(id, params);
    closeModal();
  }

  render() {
    const { show, closeModal, sectionOptions } = this.props;

    return (
      <Modal
        className="bg-white flex flex-column mb2 react_modal"
        show={show}
        onClickOutside={closeModal}
      >
        <div
          className="flex flex-auto flex-justify-end cursor-pointer p1"
          onClick={closeModal}
        >
          <i className="fa fa-close text-gray" />
        </div>
        <p className="col-12 center semi-bold py2 h2">Move Document to Folder</p>
        <div className="col-12 flex flex-center flex-justify-center sm-block bg-white p3 sm-center">
          <ReactSelect
            className="col-12"
            options={sectionOptions}
            onChange={selectedOption => this.submit(selectedOption.value)}
            isClearable={false}
            placeholder="Choose a folder..."
            styles={defaultSelectStyles}
            theme={defaultSelectTheme}
          />
        </div>
      </Modal>
    );
  }
}

export default MoveFileModal;
