import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import HtmlComponent from './HtmlComponent';
import InvestorUpdate from './InvestorUpdate';
import InvestorUpdateHeader from './InvestorUpdateHeader';
import InvestorUpdateGroup from './InvestorUpdateGroup';
import UpdateTemplate from './UpdateTemplate';
import UpdateTemplateHeader from './UpdateTemplateHeader';
import InvestorMember from './InvestorMember';
import InvestorMemberHeader from './InvestorMemberHeader';
import InvestorInvestment from './InvestorInvestment';
import InvestorInvestmentHeader from './InvestorInvestmentHeader';
import TextInput from '../TextInput';
import CompanyInvestment from './CompanyInvestment';
import CompanyInvestmentHeader from './CompanyInvestmentHeader';
import CompanyInvestmentInvited from './CompanyInvestmentInvited';
import InvestorDataRoomHeader from './InvestorDataRoomHeader';
import InvestmentDataRoom from './InvestmentDataRoom';
import CompanyDataRoomHeader from './CompanyDataRoomHeader';
import InvestorDataRoom from './InvestorDataRoom';
import InvestorFund from './InvestorFund';
import InvestorFundHeader from './InvestorFundHeader';
import UpdateRecipientHeader from './UpdateRecipientHeader';
import UpdateRecipient from './UpdateRecipient';
import SigningRoomRequest from './SigningRoomRequest';
import SigningRoomRequestHeader from './SigningRoomRequestHeader';
import FactSheet from './FactSheet';
import FactSheetHeader from './FactSheetHeader';
import InvestmentsFilter from '../investor/investments/InvestmentsFilter';
import {
  DataRoomStatsDocumentsHeader,
  DataRoomStatsDocument,
  DataRoomStatsUsersHeader,
  DataRoomStatsUser,
} from '../data_room_stats';
import {
  InvestorCustomReminderInvestmentCompletionHeader,
  InvestorCustomReminderInvestmentCompletion,
  InvestorCustomReminderMenu,
} from './investor_custom_reminder';

import sorter from '../utils/sorter';


class TabViewContainer extends Component {
  state = {
    searchQuery: '',
    loadingPagination: false,
  }

  components = {
    HtmlComponent,
    InvestorUpdate,
    InvestorUpdateHeader,
    InvestorUpdateGroup,
    UpdateTemplate,
    UpdateTemplateHeader,
    InvestorMember,
    InvestorMemberHeader,
    InvestorInvestment,
    InvestorInvestmentHeader,
    CompanyInvestment,
    CompanyInvestmentHeader,
    CompanyInvestmentInvited,
    InvestorDataRoomHeader,
    InvestmentDataRoom,
    CompanyDataRoomHeader,
    InvestorDataRoom,
    InvestorFund,
    InvestorFundHeader,
    UpdateRecipientHeader,
    UpdateRecipient,
    DataRoomStatsDocumentsHeader,
    DataRoomStatsDocument,
    DataRoomStatsUsersHeader,
    SigningRoomRequest,
    SigningRoomRequestHeader,
    DataRoomStatsUser,
    FactSheet,
    FactSheetHeader,
    InvestmentsFilter,
    InvestorCustomReminderInvestmentCompletionHeader,
    InvestorCustomReminderInvestmentCompletion,
  }

  menuComponents = {
    InvestorCustomReminderMenu,
  }

  static propTypes = {
    items: PropTypes.object,
    placeholders: PropTypes.object,
    tabs: PropTypes.array,
    activeTab: PropTypes.string,
    searchEnabled: PropTypes.bool,
    searchText: PropTypes.string,
    sorting: PropTypes.object.isRequired,
    tabCustomElement: PropTypes.object,
    filter: PropTypes.object,
    topHeader: PropTypes.object,
    searchTextPlaceholder: PropTypes.string,
    pagination: PropTypes.object,
  }

  static defaultProps = {
    topHeader: null,
    searchText: null,
    searchTextPlaceholder: '',
    pagination: null,
  }

  displaySearch = () => {
    const { searchEnabled, searchText, searchTextPlaceholder } = this.props;

    if (searchEnabled) {
      return (
        <div className="flex-auto flex flex-column">
          {searchText &&
            <span className="h6 text-light-gray ml2">{searchText}</span>
          }
          <div className="flex ml2 items-center">
            <i className="fa fa-search p2 text-light-gray" />
            <TextInput
              className="border-none col-12"
              onChange={(e) => { this.setState({ searchQuery: e.target.value }); }}
              placeholder={searchTextPlaceholder}
            />
          </div>
        </div>
      );
    }
    return (
      null
    );
  }

  handleFilterChange = (selectedFilter) => {
    const { filter } = this.props;
    const { manageFilterUrl } = filter.data;

    store.dispatch({ type: 'SET_CURRENT_FILTER', value: selectedFilter });

    App.Api.get(manageFilterUrl, { filter: selectedFilter }).then((data) => {
      store.dispatch({ type: 'SET_LIST', list: 'main', value: data.investments });
    });
  }

  handlePagination = () => {
    const { activeTab, pagination } = this.props;
    const { loadingPagination } = this.state;

    if (loadingPagination) return;

    this.setState({ loadingPagination: true }, async () => {
      try {
        const res = await axios.get(
          pagination.url,
          {
            params: { active_tab: activeTab, page: pagination.nextPages[activeTab] },
            // paramsSerializer helps to send the second layer of params correctly to rails controller
            paramsSerializer: (params) => (
              $.param(params)
            ),
          },
        );

        await store.dispatch({ type: 'ADD_ITEM', list: activeTab, value: res.data.pagination.items });
        await store.dispatch({ type: 'SET_CURRENT_PAGE', list: activeTab, value: res.data.pagination.next_page });
        this.setState({ loadingPagination: false });
      } catch (err) {
        this.setState({ loadingPagination: false });
        App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an unknown error happened.' });
      }
    });
  }

  tabClass = (tab) => {
    const { activeTab } = this.props;

    if (activeTab === tab) {
      return 'text-gray capitalize p2 fw400 bg-extra-light-gray';
    }
    return 'cursor-pointer capitalize p2 text-gray fw400';
  }

  betaTag = () => {
    const { topHeader } = this.props;

    return (
      topHeader.beta ?
        <div className="relative">
          <div className="pb1">
            <div className="desktop__notification--bell" style={{ borderRadius: '30%', width: '25px' }}>
              Beta
            </div>
          </div>
        </div>
        :
        null
    );
  }

  renderElement = () => {
    const { tabCustomElement, filter } = this.props;

    // Todo: This is hardcoded, need to figure out a way to make it better
    if (!tabCustomElement || (filter && filter.currentFilter !== 'all')) {
      return null;
    }
    return (
      <a
        className={tabCustomElement.class}
        href={tabCustomElement.link ? tabCustomElement.link : null}
        data-method={tabCustomElement.link ? 'get' : null}
      >
        <HtmlComponent data={{ html: tabCustomElement.element }} />
      </a>
    );
  }

  renderTabs = () => {
    const { tabs } = this.props;

    if (tabs.length < 2) {
      return null;
    }
    return tabs.map(tab =>
      <div
        key={tab}
        className={this.tabClass(tab)}
        onClick={() => { store.dispatch({ type: 'SET_TAB', value: tab }); store.dispatch({ type: 'SET_SORTING', value: {} }); }}
      >
        {tab.replace('_', ' ')}
      </div>);
  }

  renderView = () => {
    const { activeTab, items, placeholders, searchEnabled, sorting } = this.props;
    const { searchQuery } = this.state;

    let innerItems = sorter(items[activeTab], sorting);
    if (innerItems.length === 1) {
      if (!placeholders) return null;

      const placeholder = placeholders[activeTab];
      if (placeholder) {
        if (placeholder.html) {
          return (<HtmlComponent data={placeholder} />);
        }
        const PlaceholderComponent = this.components[placeholder.component];
        return (<PlaceholderComponent data={placeholder.data} />);
      }

      return null;
    }
    if (searchEnabled) {
      innerItems = innerItems.filter(item => !item.name || item.name.toLowerCase().includes(searchQuery.toLowerCase()));
    }
    return innerItems.filter(item => !item.hide || item.alwaysTop).map(item => {
      const ItemComponent = this.components[item.component];
      return (
        <ItemComponent
          key={item.key}
          data={item}
        />
      );
    });
  }

  render() {
    const { items, activeTab, filter, tabCustomElement, topHeader, sorting, pagination } = this.props;
    const { loadingPagination } = this.state;

    let RightMenuComponent = null;
    let headerCounter = null;
    if (topHeader && topHeader.rightMenuComponent) {
      RightMenuComponent = this.menuComponents[topHeader.rightMenuComponent];
      if (topHeader.counter) {
        headerCounter = sorter(items[activeTab], sorting).filter(item => !item.hide && !item.alwaysTop);
      }
    }

    if (filter) {
      const FilterComponent = this.components[filter.component];
      return (
        <div className="flex col-12">
          <div className="pr3 mr2 lg-hide" style={{ width: '250px' }}>
            <FilterComponent
              currentFilter={filter.currentFilter}
              counts={filter.counts}
              filters={filter.filters}
              filterLabels={filter.filterLabels}
              data={filter.data}
              handleFilterChange={(chosenFilter) => this.handleFilterChange(chosenFilter)}
            />
          </div>
          <div className="col-10 sm-col-12">
            <div className="flex col-12 flex-column bg-white bs-around-small mb3 sm-mb4">
              <div className={`flex col-12 ${tabCustomElement ? 'sm-flex-row' : 'sm-flex-column'}`}>
                {this.displaySearch()}
                <div className="flex flex-justify-end sm-block">
                  {this.renderTabs()}
                  {this.renderElement()}
                </div>
              </div>
              {this.renderView()}
            </div>
          </div>
        </div>
      );
    }
    return (
      <>
        {topHeader &&
          <div className="col-12 flex flex-justify-between items-center pb3 pt2 flex-wrap">
            {topHeader.rightMenuComponent ?
              <RightMenuComponent ids={topHeader.ids} data={topHeader.data}>
                <div className="h2 fw400 text-gray">
                  {this.betaTag()}
                  {topHeader.title}
                </div>
              </RightMenuComponent>
              :
              <div className="h2 fw400 text-gray">
                {this.betaTag()}
                {topHeader.title}
              </div>
            }
          </div>
        }
        {topHeader && topHeader.counter &&
          <div className="h3 fw400 mb2">
            Summary: {headerCounter.length} {topHeader.counterName}
          </div>
        }
        <div className="flex col-12 flex-column bg-white bs-around-small mb3 sm-mb4">
          <div className={`flex col-12 ${tabCustomElement ? 'sm-flex-row' : 'sm-flex-column'}`}>
            {this.displaySearch()}
            <div className="flex flex-justify-end sm-block">
              {this.renderTabs()}
              {this.renderElement()}
            </div>
          </div>
          <ReactCSSTransitionGroup transitionName="fade" transitionEnterTimeout={500} transitionLeaveTimeout={300} transitionEnter={loadingPagination} transitionLeave={loadingPagination}>
            {this.renderView()}
          </ReactCSSTransitionGroup>
        </div>

        {/* Pagination section */}
        { pagination && pagination.nextPages[activeTab] &&
          <div className="flex flex-justify-center">
            <div
              className="btn btn-outline btn-big border-alto bg-white sm-mb1 sm-col-12 mb3"
              onClick={() => this.handlePagination()}
            >
              <i className="fa fa-plus mr1" />
              {pagination.buttonText || 'Load More'}
            </div>
          </div>
        }
      </>
    );
  }
}

const mapStateToProps = (store) => (
  {
    items: store.get('items').toJS(),
    tabs: store.get('tabs').toJS(),
    activeTab: store.get('activeTab'),
    sorting: store.get('sorting').toJS(),
    filter: store.get('filter') ? store.get('filter').toJS() : null,
    pagination: store.get('pagination') ? store.get('pagination').toJS() : null,
  }
);

export default connect(mapStateToProps)(TabViewContainer);
