import React from 'react';


const Message = ({ message }) => {
  const message_img = {
    backgroundImage: 'url(' + message.avatar + ')',
    height: '35px',
    width: '35px',
  };

  return (
    <div className="border-bottom border-alto px2 py1 clearfix flex relative" key={message.id}>
      <div className="bg-cover bg-center bg-no-repeat circle inline-block mr2" style={message_img} />
      <div className="border-bottom border-alto col-11">
        <div className="semi-bold text-outer-space">{message.author}</div>
        <div className="fs-10 mb2 text-light-gray">
          <span>{message.date}</span>
        </div>
        <div className="break-word lh-28">{message.body}</div>
      </div>
    </div>
  );
};

export default Message;
