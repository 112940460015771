import Promise from 'promise-polyfill';
import ShareholderUpdateApi from './shareholder_update_api.js.es6';

export default {
  submitMessage(url, msg) {
    return new Promise((resolve) => {
      const data = {
        body: msg,
      };

      $.post(url, data, resolve);
    });
  },

  getConversation(url) {
    return new Promise((resolve) => {
      $.get(url, resolve);
    });
  },

  get(url, params) {
    return new Promise((resolve) => {
      $.get(url, params, resolve);
    });
  },

  search(url, search) {
    return new Promise((resolve) => {
      $.get(url, { search }, resolve);
    });
  },

  post(url, data = {}) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        data,
        type: 'post',
        success: resolve,
        error: reject,
      });
    });
  },

  postApplicationJson(url, data = {}) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        data: JSON.stringify(data),
        dataType: 'json',
        contentType: 'application/json',
        type: 'post',
        success: resolve,
        error: reject,
      });
    });
  },

  submitPatch(url, data) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        data: JSON.stringify(data),
        type: 'patch',
        dataType: 'json',
        contentType: 'application/json',
        success: resolve,
        error: reject,
      });
    });
  },

  submitPatchForFile(url, data) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        data,
        dataType: 'json',
        cache: false,
        contentType: false,
        processData: false,
        type: 'patch',
        success: resolve,
        error: reject,
      });
    });
  },

  patch(url, key, data) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        data: { [key]: data },
        type: 'patch',
        success: resolve,
        error: reject,
      });
    });
  },

  delete(url, data) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        data,
        type: 'delete',
        success: resolve,
        error: reject,
      });
    });
  },

  invite(url, email) {
    return new Promise((resolve) => {
      $.post(url, { email }, resolve);
    });
  },

  loadMoreMessages(url, lastMessageId) {
    return new Promise((resolve) => {
      $.get(
        url,
        { last_message_id: lastMessageId },
        resolve,
      );
    });
  },

  getConversationAttachedFiles(url) {
    return new Promise((resolve) => {
      $.get(url, resolve);
    });
  },

  uploadFile(url, file, uid = null, sectionId = null, paramName = 'attachment') {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      if ((file.size / 1024 / 1024) > 50) {
        reject({ message: 'The file you are trying to upload is too large. Please upload files not larger than 50 MB!' });
        return;
      }

      formData.append(paramName, file);
      if (uid !== null && uid !== undefined) formData.append('uid', uid);
      if (sectionId !== null && sectionId !== undefined) formData.append('data_room_section_id', sectionId);

      $.ajax({
        url,
        dataType: 'text',
        cache: false,
        contentType: false,
        processData: false,
        data: formData,
        type: 'post',
        success: (data) => {
          resolve(JSON.parse(data));
          window.onbeforeunload = null;
        },
        error: (XMLHttpRequest, textStatus, errorThrown) => {
          reject(JSON.parse(XMLHttpRequest.responseText));
        },
      });
    });
  },

  reuploadFile(url, file, fileToBeDeletedId, uid = null, sectionId, paramName = 'attachment') {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      if ((file.size / 1024 / 1024) > 50) {
        reject({ message: 'The file you are trying to upload is too large. Please upload files not larger than 50 MB!' });
        return;
      }

      formData.append(paramName, file);
      formData.append('delete_file_id', fileToBeDeletedId);
      formData.append('data_room_section_id', sectionId);
      if (uid !== null || uid !== undefined) formData.append('uid', uid);
      $.ajax({
        url,
        dataType: 'text',
        cache: false,
        contentType: false,
        processData: false,
        data: formData,
        type: 'post',
        success: (data) => {
          resolve(JSON.parse(data));
        },
        error: (XMLHttpRequest, textStatus, errorThrown) => {
          reject(JSON.parse(XMLHttpRequest.responseText));
        },
      });
    });
  },

  deleteFile(url, id) {
    return new Promise((resolve) => {
      $.ajax({
        url,
        data: {
          file_id: id,
        },
        type: 'delete',
        success: resolve,
      });
    });
  },

  chart(url, chartType) {
    return new Promise((resolve) => {
      $.ajax({
        url,
        dataType: 'script',
        data: { chart: chartType },
        type: 'get',
        success: resolve,
      });
    });
  },

  createSection(url, type, position) {
    return ShareholderUpdateApi.createSection(url, type, position);
  },

  deleteSection(url) {
    return ShareholderUpdateApi.deleteSection(url);
  },

  update(url, key, data) {
    return ShareholderUpdateApi.update(url, key, data);
  },

  moveSection(url, direction) {
    return ShareholderUpdateApi.moveSection(url, direction);
  },
};
