import { connect } from 'react-redux';
import InvestmentKpis from '../../../components/investor/investments/kpis/InvestmentKpis';

import {
  saveKpiFieldValue,
  createKpiField,
  updateKpiField,
  deleteKpiField,
  changeKpiInChart,
  changeCurrentDate,
  updateDerivedKpis,
} from '../../../api/investor/investment/investmentKpiApi';

const mapStateToProps = state => ({
  investmentHeader: state.investmentHeader,
  investmentKpis: state.investmentKpis,
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  saveKpiFieldValue,
  createKpiField,
  updateKpiField,
  deleteKpiField,
  changeKpiInChart,
  changeCurrentDate,
  updateDerivedKpis,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentKpis);
