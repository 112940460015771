import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ReactSelect from 'react-select';

import Pivot from '../../Pivot';
import SelectWithDate from '../../selects/SelectWithDate';
import { defaultSelectStyles, defaultSelectTheme } from '../../selects/SelectStyles';
import CheckBox from '../../CheckBox';

class InvestorDynamicReports extends Component {
  static propTypes = {
    show: PropTypes.bool,
    kpiDataUrl: PropTypes.string.isRequired,
    investmentDataUrl: PropTypes.string.isRequired,
    dateRangeOptions: PropTypes.object.isRequired,
    blurred: PropTypes.bool,
  }

  static defaultProps = {
    show: false,
    blurred: null,
  }

  investmentsReportSelectOptions = [
    {
      label: 'All Values',
      value: 'all',
    },
    {
      label: 'Total Ownership',
      value: 'total-ownership',
    },
    {
      label: 'Current Post-Money',
      value: 'current-post-money',
    },
    {
      label: 'Total Invested',
      value: 'total-invested',
    },
    {
      label: 'Current Share Value',
      value: 'current-share-value',
    },
    {
      label: 'Investment Multiple',
      value: 'investment-multiple',
    },
  ];

  state = {
    chartData: null,
    mode: 'kpi',
    kpiSelectedDateRange: 'previous_year',
    invSelectedOption: this.investmentsReportSelectOptions[0],
    startDate: null,
    endDate: null,
    columns: [],
    showChart: false,
    includeCustomInvestments: false,
  }

  onReportTemplateChanged = (option, startDate = null, endDate = null) => {
    const { mode } = this.state;
    if (mode === 'kpi') {
      let stateHash;

      if (option === 'custom') {
        stateHash = { kpiSelectedDateRange: option, startDate, endDate };
      } else {
        stateHash = { kpiSelectedDateRange: option };
      }

      this.setState(stateHash);
    } else {
      this.setState({ invSelectedOption: option,
        columns: [
          {
            uniqueName: 'Type',
            sort: 'asc',
            filter: {
              members: [`Type.${option.label}`],
            },
          },
        ] });
    }
  }

  highChartLoading = (data) => {
    this.setState({ chartData: data });
  }

  customizeToolbar = (toolbar) => {
    const tabs = toolbar.getTabs();
    const innerToolbar = toolbar;

    innerToolbar.getTabs = () => {
      delete tabs[0];
      return tabs;
    };
  }

  buildUrl = () => {
    const {
      kpiDataUrl,
      investmentDataUrl,
    } = this.props;

    const {
      mode,
      kpiSelectedDateRange,
      startDate,
      endDate,
      includeCustomInvestments,
    } = this.state;

    if (mode === 'kpi') {
      let params = '';

      if (kpiSelectedDateRange === 'custom') {
        params = `start_date=${startDate}&end_date=${endDate}`;
      }

      return `${kpiDataUrl}?date_range_type=${kpiSelectedDateRange}&${params}&with_custom_investments=${includeCustomInvestments}`;
    }

    return `${investmentDataUrl}/?with_custom_investments=${includeCustomInvestments}`;
  }

  render() {
    const {
      dateRangeOptions,
      show,
      blurred,
    } = this.props;

    const {
      chartData,
      mode,
      invSelectedOption,
      columns,
      showChart,
      includeCustomInvestments,
    } = this.state;

    return (show ?
      <div className={blurred ? 'hide-data' : ''}>
        {/* Type selector */}
        <div className="flex mb3">
          <div
            className={`h4 center bs-around mr3 cursor-pointer ${mode === 'kpi' ? 'bg-light-blue text-white' : 'bg-white text-gray'}`}
            style={{ width: '240px', lineHeight: '54px' }}
            onClick={() => this.setState({ mode: 'kpi' })}
          >
            Kpi Reports
          </div>
          <div
            className={`h4 center bs-around cursor-pointer ${mode === 'investment' ? 'bg-light-blue text-white' : 'bg-white text-gray'}`}
            style={{ width: '240px', lineHeight: '54px' }}
            onClick={() => this.setState({ mode: 'investment' })}
          >
            Investment Reports
          </div>
        </div>

        {/* (Date Range/Investment Dashboard) Selector */}
        <div className="mb2">
          <div className="h3 mb1">Select KPI date range</div>
          {mode === 'kpi' ?
            <SelectWithDate
              onChange={(selectedOption, startDate, endDate) => this.onReportTemplateChanged(selectedOption, startDate, endDate)}
              rangeOptions={dateRangeOptions}
              defaultOption="previous_year"
              customText="Select custom range:"
              showButton
            />
            :
            <ReactSelect
              value={invSelectedOption}
              options={this.investmentsReportSelectOptions}
              onChange={selectedOption => this.onReportTemplateChanged(selectedOption)}
              className="mb2 w-200"
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
            />
          }

          <div className="flex">
            <span className="mr1 fw400">Include Custom Companies</span>
            <CheckBox
              defaultChecked={includeCustomInvestments}
              onChange={() => this.setState({ includeCustomInvestments: !includeCustomInvestments })}
              fixedWidth
            />
          </div>
        </div>

        {showChart ?
          // The Pivot Table component
          <Pivot
            toolbar
            report={this.buildUrl()}
            beforetoolbarcreated={this.customizeToolbar}
            columns={columns}
            // highChartLoading={this.highChartLoading} remove highcharts for now
          />

          :

          <div className="flex flex-justify-center mt2">
            <div
              className="p1 bg-green rounded col-4 center cursor-pointer"
              onClick={() => this.setState({ showChart: true })}
            >
              <span className="fw400 text-white">Start report</span>
            </div>
          </div>
        }

        {/* Highchart associated to the Pivot Table */}
        {false && chartData &&
        <div className="my3 p3 bg-white bs-around">
          <HighchartsReact
            highcharts={Highcharts}
            options={chartData}
          />
        </div>}
      </div> : null
    );
  }
}

export default InvestorDynamicReports;
