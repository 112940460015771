export default function sortList(list, sorting) {
  const getSortValue = (value) => {
    if (sorting.kpi || sorting.kpi === 0) {
      return Number(value.kpis[sorting.kpi].sort_value);
    }
    if (value[sorting.field] == null) {
      return '';
    }
    if (typeof value[sorting.field] === 'string' || value[sorting.field] instanceof String) {
      return value[sorting.field].toUpperCase();
    }
    return value[sorting.field];
  };

  const alphabeticSort = (a, b) => {
    const valueA = getSortValue(a);
    const valueB = getSortValue(b);
    if (valueA < valueB) {
      return sorting.order === 'ASC' ? -1 : 1;
    }
    if (valueA > valueB) {
      return sorting.order === 'ASC' ? 1 : -1;
    }
    return 0;
  };

  if (!sorting.field) { return list; }
  if (list.length > 1 && list[0].alwaysTop) {
    const a = list.slice(1).sort(alphabeticSort);
    a.unshift(list[0]);
    return a;
  }
  return list.slice(0).sort(alphabeticSort);
}
