import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../../store/configureStore';
import FundingContainer from '../../containers/investor/investments/FundingContainer';


const InvestorFunding = props => (
  <Provider store={configureStore(props)}>
    <FundingContainer />
  </Provider>
);

export default InvestorFunding;
