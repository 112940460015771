import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import CountrySearch from './CountrySearch';

import Modal from '../../Modal';

class CountryValidator extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      saving: false,
    };
  }

  componentDidMount() {
    const { show } = this.state;

    setTimeout(() => (
      this.setState({ show: !show })
    ), 300);
  }

  toogleModal = () => {
    const { show } = this.state;

    this.setState({ show: !show });
  }

  submit = () => {
    const { company } = this.props;
    const params = { company: { country: $("[name='company[country]'").val() } };

    this.setState({ saving: true }, async () => {
      await axios.patch(`/company_info_update/${company.id}`, params);
      this.setState({ saving: false }, () => {
        this.toogleModal();
        window.location.reload();
      });
    });
  }

  render() {
    const { company } = this.props;

    const { show, saving } = this.state;

    return (
      <React.Fragment>
        <Modal
          show={show}
          widthClass="react_modal--600"
        >
          <div className="flex flex-justify-between items-center fw400 p2 border-bottom border-lighter-gray mb2">
            <div className="flex flex-column flex-justify-start pb1">
              <span>We are standardizing countries in our platform to enable data-driven features. You have informed country &quot;{company.country}&quot;, which is not in the standard list. Could you please inform one here</span>
            </div>
          </div>

          <div className="flex flex-justify-start flex-column fw400 px3">
            <div className="flex flex-column mb1">
              <CountrySearch
                currentCompany={company}
                url="/country_info_search"
                validateUrl="/validate_country"
              />
            </div>
          </div>

          <div
            className="py2 flex flex-justify-center"
            onClick={this.submit}
          >
            <div
              className={`px3 py1 bg-green rounded text-white ${saving ? 'cursor-disabled' : 'cursor-pointer'}`}
            >
              {saving ? 'Saving...' : 'Submit'}
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default CountryValidator;
