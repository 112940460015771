import React from 'react';

import SorterHeader from '../SorterHeader';


const InvestorUpdateHeader = () => (
  <div className="col-12 text-gray h5 flex px2 bg-extra-light-gray">
    <div className="col-4 py1 bold pl2 sm-col-6 sm-pl0">
      <SorterHeader text="NAME" field="name" />
    </div>

    <div className="col-2 py1 bold pl2 sm-col-6">
      <SorterHeader text="STATUS" field="status" />
    </div>

    <div className="col-3 py1 bold pl2 sm-hide">
      <SorterHeader text="LAST MODIFIED" field="last_modified_sort" />
    </div>

  </div>
);

export default InvestorUpdateHeader;
