import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import ShareClassesOverview from './share_classes/ShareClassesOverview';
import ShareholdersOverview from './shareholders/ShareholdersOverview';

import { defaultSelectStyles, defaultSelectTheme } from '../selects/SelectStyles';
import chartOptions from '../utils/highcharts/pieChartOptions';
import Convertibles from './Convertibles';


class SimpleOverview extends Component {
  static propTypes = {
    capTable: PropTypes.object,
    switchCapTable: PropTypes.func.isRequired,
    currency: PropTypes.string,
  }

  static defaultProps = {
    capTable: null,
    currency: null,
  }

  state = {
    viewType: 'shareholders',
  }

  chartOptions = () => {
    const { capTable } = this.props;
    const { viewType } = this.state;

    const data = viewType === 'shareholders' ? capTable.data.shareholders_overview.chart_data : capTable.data.share_class_overview.chart_data;

    return chartOptions(data);
  }

  render() {
    const { capTable, switchCapTable, currency } = this.props;
    const { viewType } = this.state;

    const noShareClasses = capTable && !capTable.data.share_class_overview;

    const viewTypeOptions = [
      { value: 'shareholders', label: 'Shareholders' },
      { value: 'share_classes', label: `Share Classes ${noShareClasses ? '(Not Available)' : ''}`, isDisabled: noShareClasses },
    ];

    const noteNumber = (elementId, type = 'shareholders') => {
      const scope = type === 'shareholders' ? capTable.data.notes : capTable.data.notes_for_convertibles;
      const elementNote = scope.find(note => note.element_id === elementId);
      const index = scope.indexOf(elementNote);
      return index === -1 ? '' : index + 1;
    };

    return (
      <>
        {/* Selectors */}
        { capTable && capTable.data.shareholders_overview.shareholders_legend.length > 0 ?
          <React.Fragment>
            <div className="flex items-center flex-auto mb2">
              <div className="mr1 col-5 sm-col-8">
                <ReactSelect
                  defaultValue={capTable.rounds_options.filter(option => option.value === capTable.data.id)}
                  options={capTable.rounds_options}
                  onChange={(selectedOption) => switchCapTable(selectedOption.value)}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                />
              </div>
              <div className="flex items-center flex-auto col-12 flex-justify-between">
                <div className="flex items-center flex-auto">
                  <span className="text-light-gray mr1 sm-col-4">View By</span>
                  <div className="col-5 sm-col-8">
                    <ReactSelect
                      defaultValue={viewTypeOptions.filter(option => option.value === viewType)}
                      value={viewTypeOptions.filter(option => option.value === viewType)}
                      options={viewTypeOptions}
                      onChange={(selectedOption) => this.setState({ viewType: selectedOption.value })}
                      styles={defaultSelectStyles}
                      theme={defaultSelectTheme}
                    />
                  </div>
                </div>
                {
                  capTable.company_cap_table_path &&
                  <a
                    className="px1 py05 h5 fw400 right-align"
                    href={capTable.company_cap_table_path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Full Captable <i className="fa fa-external-link" />
                  </a>
                }
              </div>
            </div>

            {viewType === 'shareholders' &&
              <ShareholdersOverview
                capTable={capTable}
                chartOptions={this.chartOptions()}
                currency={currency}
              />
            }

            {viewType === 'share_classes' &&
              <ShareClassesOverview
                capTable={capTable}
                chartOptions={this.chartOptions()}
              />
            }

            {!noShareClasses &&
              <Convertibles
                editor={false}
                capTable={capTable}
                noteNumber={noteNumber}
                marginTop="mt2"
              />
            }
          </React.Fragment>
          :
          <div className="flex col-12 bs-around bg-white flex-justify-center">
            <span className="py3 center text-light-gray px2">No Captable Data Available</span>
          </div>
        }
      </>
    );
  }
}

export default SimpleOverview;
