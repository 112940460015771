import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ProFeatures from './ProFeatures';
import PaymentModal from '../../payment/PaymentModal';

const StripePlan = (props) => {
  const { proPlans, monthlyPayments } = props;
  const [saving, setSaving] = useState(false);
  const [duration, setDuration] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const closeModal = () => setShowPaymentModal(false);
  const OpenDialog = (monthlyPayments, selectValue, proPlans) => {
    if(monthlyPayments) {
      if (selectValue == 'Unlimited') setSelectedPlan({id: proPlans.unlimitedMonthlyId});
      if (selectValue == '' || selectValue == '10 portfolio' || selectValue == '20 portfolio') setSelectedPlan({id: proPlans.limitedMonthlyId});
      if (selectValue == '30 portfolio') setSelectedPlan({id: proPlans.monthly30PortfolioId});
      App.trackInvestorEvent('stripe', `pricing_${proPlans.id}_monthly`);
    }
    else {
      if (selectValue == 'Unlimited') setSelectedPlan({id: proPlans.unlimitedYearlyId});
      if (selectValue == '' || selectValue == '20 portfolio' || selectValue == '10 portfolio') setSelectedPlan({id: proPlans.limitedYearlyId});
      if (selectValue == '30 portfolio') setSelectedPlan({id: proPlans.yearly30PortfolioId});
      App.trackInvestorEvent('stripe', `pricing_${proPlans.id}_yearly`);
    }
    setShowPaymentModal(true);
  }

  const onSubmit = async (params) => {
    const { subscribe } = props;
    setSaving(true);
    try {
      await subscribe({
        ...params,
        subscription: {
          ...params.subscription,
          plan_id: selectedPlan.id,
        },
      });
      App.State.setFlash({ name: 'notice', msg: 'Congratulations! Subscribed successfully.' });
      setSaving(false);
      window.location.reload();
    } catch (err) {
        setSaving(false);
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
    }
  }

  return (
    <div className="bg-white bs-around p2 mb2">
      <div className="flex flex-justify-center">
        <div className="flex col-12">
          <ProFeatures
            proPlans={proPlans}
            monthlyPayments={monthlyPayments}
            onClick={OpenDialog}
          />
        </div>
      </div>
      {showPaymentModal &&
        <PaymentModal
          closeModal={closeModal}
          plan={selectedPlan}
          onSubmit={onSubmit}
        />
      }
    </div>
  )
}

StripePlan.propTypes = {
  proPlans: PropTypes.object.isRequired,
  subscribe: PropTypes.func.isRequired,
};

StripePlan.defaultProps = {
  proPlans: {},
}
export default StripePlan;
