import { connect } from 'react-redux';

import {
  addRecipients,
  removeRecipients,
  toogleContactsList,
  updateShareholderUpdate,
  highlightMember,
} from '../../../api/company/recipientApi';

import RecipientStep from '../../../components/shareholder_update/recipient_step/RecipientStep';


const mapStateToProps = state => ({
  company: state.company,
  recipientStep: state.recipientStep,
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  addRecipients,
  removeRecipients,
  toogleContactsList,
  updateShareholderUpdate,
  highlightMember,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipientStep);
