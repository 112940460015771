import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import ShareholderRow from './ShareholderRow';
import ShareholdersOverview from './shareholders/ShareholdersOverview';

import HtmlComponent from '../tab_view/HtmlComponent';
import SorterHeader from '../modules/SorterHeader';
import withSorting from '../utils/withSorting';


class Shareholders extends Component {
  static propTypes = {
    editor: PropTypes.bool.isRequired,
    capTable: PropTypes.object.isRequired,
    sortedData: PropTypes.array.isRequired,
    showOverview: PropTypes.bool.isRequired,
    edit: PropTypes.bool.isRequired,
    showList: PropTypes.bool.isRequired,
    toggleOverview: PropTypes.func.isRequired,
    noteNumber: PropTypes.func.isRequired,
    actionsSection: PropTypes.func.isRequired,
    chartOptions: PropTypes.object.isRequired,
    toogleModal: PropTypes.func.isRequired,
    updateShare: PropTypes.func,
    updateEditingShareholderId: PropTypes.func.isRequired,
    deleteShare: PropTypes.func,
    company: PropTypes.object,
  }

  static defaultProps = {
    updateShare: null,
    deleteShare: null,
    company: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      shareholdersList: props.sortedData,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { sortedData } = nextProps;

    this.setState({ shareholdersList: sortedData });
  }

  handleDelete = async (shareClassId) => {
    const { deleteShare } = this.props;

    try {
      await deleteShare(shareClassId);
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
    }
  }

  handleFieldUpdate = (id, name, value) => {
    const { updateShare } = this.props;

    const params = { cap_share: { [name]: value } };

    updateShare(id, params).catch(
      () => App.State.setFlash({ name: 'warning', msg: 'Some error happened when updating the value.' }),
    );
  }

  handleEdit = async (shareholderId) => {
    const { toogleModal, updateEditingShareholderId } = this.props;

    await updateEditingShareholderId(shareholderId);
    toogleModal();
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = result => {
    if (!result.destination) return;

    const { sortedData, updateShare } = this.props;
    const { shareholdersList } = this.state;

    const share = sortedData.find(k => k.position === (result.source.index + 1));
    const params = { cap_share: { position: result.destination.index + 1 } };

    const list = this.reorder(
      shareholdersList,
      result.source.index,
      result.destination.index,
    );

    this.setState({ shareholdersList: list }, () => updateShare(share.id, params));
  }

  render() {
    const { editor, capTable, showOverview, edit, showList, toggleOverview, noteNumber, actionsSection, chartOptions, company } = this.props;
    const { shareholdersList } = this.state;

    return (
      <React.Fragment>
        {/* Overview */}
        <ShareholdersOverview
          capTable={capTable}
          showOverview={showOverview}
          toggleOverview={toggleOverview}
          chartOptions={chartOptions}
          company={company}
        />

        {/* Shareholders list */}
        <div className="bs-around bg-white mt3">
          {actionsSection()}

          <ReactCSSTransitionGroup transitionName="modal" transitionEnterTimeout={500} transitionLeaveTimeout={500}>
            {showList &&
            <div className="p3 sm-px2">
              {shareholdersList.length > 0 ?
                <div>
                  {edit &&
                    <div className="mb3">
                      <div className="p1 rounded border border-alto bg-extra-light-gray h5 lh-12">
                        <i className="fa fa-info-circle text-medium-gray" />
                        <span className="ml1 text-medium-gray fw400">
                          The edit all option, enables you to quickly edit the shares of all added shareholders. Please note that changes are saved automatically!
                        </span>
                      </div>
                    </div>
                  }
                  <div className="col-12 flex px2 py1 text-medium-gray h5 fw400 flex center bg-extra-light-gray border-bottom border-gallery lh-12">
                    <div className="col-25p sm-col-6 flex flex-justify-start items-center">
                      <SorterHeader text="Name" field="owner_name" />
                    </div>
                    <div className="col-15p sm-hide flex flex-justify-center items-center">
                      <SorterHeader text="Share Class" field="share_class" hideIcon />
                    </div>
                    <div className="col-10p sm-hide flex flex-justify-center items-center">
                      <span
                        data-balloon="The total # of shares the shareholder currently owns."
                        data-balloon-pos="up"
                        data-balloon-length="medium"
                      >
                        <SorterHeader text="Total Shares" field="total_shares" hideIcon />
                      </span>
                    </div>
                    <div className="col-10p sm-hide flex flex-justify-center items-center">
                      <span
                        data-balloon="The % of shares the shareholder owns relative to the total amount of shares outstanding/issued."
                        data-balloon-pos="up"
                        data-balloon-length="large"
                      >
                        <SorterHeader text="% of Shares" field="perc_of_share_capital" hideIcon />
                      </span>
                    </div>
                    <div className="col-10p sm-hide flex flex-justify-center items-center">
                      <span
                        data-balloon="The total # of share options the shareholder currently owns."
                        data-balloon-pos="up"
                        data-balloon-length="medium"
                      >
                        <SorterHeader text="Share Options" field="share_options" hideIcon />
                      </span>
                    </div>
                    <div className="col-10p sm-hide sm-col-6 flex flex-justify-center items-center">
                      <span
                        data-balloon="The total # of fully diluted (FD) shares the shareholder currently owns (calculated as the sum of total shares and share options)."
                        data-balloon-pos="up"
                        data-balloon-length="medium"
                      >
                        <SorterHeader text="FD Shares" field="fully_diluted_shares" hideIcon />
                      </span>
                    </div>
                    <div className="col-10p sm-col-6 flex flex-justify-center items-center">
                      <span
                        data-balloon="The % of fully diluted (FD) shares the shareholder owns relative to the total amount of fully diluted outstanding/issued."
                        data-balloon-pos="up"
                        data-balloon-length="medium"
                      >
                        <SorterHeader text="% of FD Shares" field="perc_of_fully_diluted_shares" hideIcon />
                      </span>
                    </div>
                    <div className="col-10p sm-col-6 flex flex-justify-center items-center sm-hide">
                      <span
                        data-balloon="Shows whether the shareholder has joined investory already."
                        data-balloon-pos="up"
                        data-balloon-length="medium"
                      >
                        <SorterHeader text="On Investory?" field="perc_of_fully_diluted_shares" hideIcon />
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-column h5">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="droppable">
                        {provided => (
                          <div ref={provided.innerRef}>
                            {shareholdersList.map(shareholder => (
                              <ShareholderRow
                                key={`shareholder_${shareholder.id}`}
                                shareholder={shareholder}
                                edit={edit}
                                editor={editor}
                                capTable={capTable}
                                noteNumber={noteNumber}
                                handleDelete={this.handleDelete}
                                handleEdit={this.handleEdit}
                                handleFieldUpdate={this.handleFieldUpdate}
                              />
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>

                    <div className="flex px2 py1 fw400 text-medium-gray mt1">
                      <div className="w-40p sm-col-6">
                        <div className="flex flex-justify-start pb1 pl2 mxn2 sm-mr2">Total</div>
                      </div>
                      <div
                        className="col-10p flex-column flex-justify-center center sm-hide"
                        data-balloon={capTable.data.shareholders_totals.total_shares.raw}
                        data-balloon-pos="bottom"
                        data-balloon-length="medium"
                      >
                        {capTable.data.shareholders_totals.total_shares.readable}
                        <span className="fw400 h6 text-gray show-print" style={{ display: 'none' }}>({capTable.data.shareholders_totals.total_shares.raw})</span>
                      </div>
                      <div
                        className="col-10p flex flex-justify-center center sm-hide"
                        data-balloon={capTable.data.shareholders_totals.perc_of_share_capital.raw}
                        data-balloon-pos="bottom"
                        data-balloon-length="medium"
                      >
                        {capTable.data.shareholders_totals.perc_of_share_capital.readable} %
                      </div>
                      <div
                        className="col-10p flex-column flex-justify-center center sm-hide"
                        data-balloon={capTable.data.shareholders_totals.share_options.raw}
                        data-balloon-pos="bottom"
                        data-balloon-length="medium"
                      >
                        {capTable.data.shareholders_totals.share_options.readable}
                        <span className="fw400 h6 text-gray show-print" style={{ display: 'none' }}>({capTable.data.shareholders_totals.share_options.raw})</span>
                      </div>
                      <div
                        className="col-10p flex-column flex-justify-center center sm-hide"
                        data-balloon={capTable.data.shareholders_totals.fully_diluted_shares.raw}
                        data-balloon-pos="bottom"
                        data-balloon-length="medium"
                      >
                        {capTable.data.shareholders_totals.fully_diluted_shares.readable}
                        <span className="fw400 h6 text-gray show-print" style={{ display: 'none' }}>({capTable.data.shareholders_totals.fully_diluted_shares.raw})</span>
                      </div>
                      <div
                        className="col-10p flex flex-justify-center center sm-col-6"
                        data-balloon={capTable.data.shareholders_totals.perc_of_fully_diluted_shares.raw}
                        data-balloon-pos="bottom"
                        data-balloon-length="medium"
                      >
                        {capTable.data.shareholders_totals.perc_of_fully_diluted_shares.readable} %
                      </div>
                      <div className="col-10p flex flex-justify-center sm-hide" />
                    </div>
                  </div>

                  {(capTable.data.notes && capTable.data.notes.length > 0) &&
                    <div className="text-alto mt2">
                      <div className="border-top border-gallery" style={{ width: '125px' }} />
                      {capTable.data.notes.map((note, index) => (
                        <div key={note.element_id} className="flex pt1 text-medium-gray">
                          <sup className="h6 lh-15 fw400 mr1">{index + 1}</sup>
                          <HtmlComponent data={{ html: note.text }} />
                        </div>
                      ))}
                    </div>
                  }
                </div>
                :
                <div className="flex flex-justify-center items-center text-medium-gray h5 p3">
                  No shareholders added to this round yet.
                </div>
              }
            </div>
            }
          </ReactCSSTransitionGroup>
        </div>
      </React.Fragment>
    );
  }
}

export default withSorting(Shareholders, (props) => props.capTable.data && props.capTable.data.shareholders);
