import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ViewAs from './ViewAs';
import Show from './Show';
import CogOfOptions from './CogOfOptions';
import TimeframeSelect from './TimeframeSelect';
import CumulativeOptions from './CumulativeOptions';
import UpgradeAccountModal from '../../../modals/UpgradeAccountModal';


class ChartOptionsBar extends Component {
  static propTypes = {
    withViewTypeSelect: PropTypes.bool.isRequired,
    chosenChartType: PropTypes.string.isRequired,
    multiKpis: PropTypes.bool.isRequired,
    kpiOptions: PropTypes.array.isRequired,
    chosenKpi: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.array,
    ]),
    chosenTimeline: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    maxMultiOptionsLength: PropTypes.number.isRequired,
    source: PropTypes.bool.isRequired,
    custom: PropTypes.bool,
    btnColorThemeOpened: PropTypes.bool.isRequired,
    chartValueTypes: PropTypes.array.isRequired,
    chosenChartValueType: PropTypes.string.isRequired,
    showDataLabels: PropTypes.bool.isRequired,
    actualColors: PropTypes.array.isRequired,
    planColors: PropTypes.array.isRequired,
    exportDataURL: PropTypes.string,
    canExport: PropTypes.bool,
    withTimeFrameSelect: PropTypes.bool.isRequired,
    timeRangeOptions: PropTypes.array.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    cumulativeKpis: PropTypes.bool.isRequired,
    accumulateByOptions: PropTypes.array.isRequired,
    operationOptions: PropTypes.array.isRequired,
    accumulateOption: PropTypes.string,
    operationOption: PropTypes.string.isRequired,
    // Functions listed below
    handleTimeFrameSelection: PropTypes.func.isRequired,
    handleCustomDateChange: PropTypes.func.isRequired,
    handleChartColorsUpdate: PropTypes.func.isRequired,
    handleColorPickerClick: PropTypes.func.isRequired,
    handleChartOptionUpdate: PropTypes.func.isRequired,
    handleKpiSelection: PropTypes.func.isRequired,
    handleAccumulatorSelection: PropTypes.func.isRequired,
    blurred: PropTypes.bool,
    children: PropTypes.node,
  }

  static defaultProps = {
    children: null,
    chosenKpi: null,
    custom: null,
    exportDataURL: null,
    canExport: true,
    accumulateOption: null,
    blurred: false,
  }

  state = {
    showUpgradeModal: false,
  }

  render() {
    const {
      withViewTypeSelect,
      chosenChartType,
      multiKpis,
      kpiOptions,
      chosenKpi,
      chosenTimeline,
      maxMultiOptionsLength,
      source,
      custom,
      btnColorThemeOpened,
      chartValueTypes,
      chosenChartValueType,
      showDataLabels,
      actualColors,
      planColors,
      exportDataURL,
      canExport,
      withTimeFrameSelect,
      timeRangeOptions,
      startDate,
      endDate,
      cumulativeKpis,
      handleTimeFrameSelection,
      handleCustomDateChange,
      handleChartColorsUpdate,
      handleColorPickerClick,
      handleChartOptionUpdate,
      handleKpiSelection,
      accumulateByOptions,
      operationOptions,
      accumulateOption,
      operationOption,
      handleAccumulatorSelection,
      children,
      blurred,
    } = this.props;

    const { showUpgradeModal } = this.state;

    return (
      <>
        <div className="px2 mb2 pt2 flex items-center flex-justify-between">
          <div className="flex items-center h5" style={{ width: cumulativeKpis ? '60%' : '100%' }}>
            {/* Children elements are rendered in here, i.e the 'View As' buttons */}
            {children}

            {/* View As */}
            {withViewTypeSelect &&
              <div className={`col-2 ${blurred ? 'hide-data' : ''}`}>
                <ViewAs
                  chosenChartType={chosenChartType}
                  multiKpis={multiKpis}
                  handleChartOptionUpdate={handleChartOptionUpdate}
                  blurred={blurred}
                />
              </div>
            }

            {/* Show */}
            <Show
              kpiOptions={kpiOptions}
              multiKpis={multiKpis}
              chosenKpi={chosenKpi}
              maxMultiOptionsLength={maxMultiOptionsLength}
              source={source}
              custom={custom}
              handleKpiSelection={handleKpiSelection}
              blurred={blurred}
            />

            {/*
              COG OF OPTIONS
            */}
            <CogOfOptions
              btnColorThemeOpened={btnColorThemeOpened}
              chartValueTypes={chartValueTypes}
              chosenChartValueType={chosenChartValueType}
              showDataLabels={showDataLabels}
              actualColors={actualColors}
              planColors={planColors}
              multiKpis={multiKpis}
              maxMultiOptionsLength={maxMultiOptionsLength}
              handleColorPickerClick={handleColorPickerClick}
              handleChartOptionUpdate={handleChartOptionUpdate}
              handleChartColorsUpdate={handleChartColorsUpdate}
              blurred={blurred}
            />

            {/*
              EXPORT BUTTON
              */}
            {exportDataURL &&
              <div className="flex items-center ml2">
                <div
                  className="text-light-gray hover-border-medium-gray hover-text-medium-gray border border-alto bg-white cursor-pointer flex items-center px1"
                  data-balloon="Excel Export"
                  data-balloon-pos="down"
                  data-balloon-length="small"
                  onClick={() => { canExport ? window.location = exportDataURL : this.setState({ showUpgradeModal: true }); }}
                  style={{ minHeight: '38px' }}
                >
                  <i className="fa fa-file-excel-o" />
                </div>
              </div>
            }
            <UpgradeAccountModal
              show={showUpgradeModal}
              closeModal={() => this.setState({ showUpgradeModal: false })}
              exportFeature
              sendEmailOnClick
              paywallDescription="Paywall_Export"
            />

            {/* TIMEFRAME SELECT */}
            {withTimeFrameSelect &&
              <div className={`h5 ${cumulativeKpis ? 'col-3 mr2' : 'flex items-center flex-justify-end'}`} style={{ width: cumulativeKpis ? '' : '35%' }}>
                <TimeframeSelect
                  withTimeFrameSelect={withTimeFrameSelect}
                  timeRangeOptions={timeRangeOptions}
                  chosenTimeline={chosenTimeline}
                  startDate={startDate}
                  endDate={endDate}
                  handleTimeFrameSelection={handleTimeFrameSelection}
                  handleCustomDateChange={handleCustomDateChange}
                  width={cumulativeKpis ? '' : undefined}
                />
              </div>
            }
          </div>

          {cumulativeKpis &&
            <div className="flex flex-justify-between" style={{ width: '40%' }}>
              {/* Options for cumulative Kpis */}
              <CumulativeOptions
                accumulateByOptions={accumulateByOptions}
                operationOptions={operationOptions}
                accumulateOption={accumulateOption}
                operationOption={operationOption}
                handleAccumulatorSelection={handleAccumulatorSelection}
              />
            </div>
          }
        </div>
      </>
    );
  }
}

export default ChartOptionsBar;
