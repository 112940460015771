
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Modal from '../../../Modal';
import CheckBox from '../../../CheckBox';


class CustomInvestmentGuidenceModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    investorId: PropTypes.number.isRequired,
  }

  state = {
    openModal: this.props.show,
    checked: false,
    saving: false,
  }

  handleClick = () => {
    const { checked } = this.state;

    this.setState({ checked: !checked });
  }

  handleClose = () => {
    const { investorId } = this.props;
    const { checked, saving } = this.state;

    if (saving) return;

    if (checked) {
      axios.patch(`/investor/${investorId}/quest`, { quest: { investor_custom_investment_guidance: true } }).then(
        () => {
          this.setState({ saving: false });
          this.setState({ openModal: false });
        },
        () => {
          this.setState({ saving: false });
        },
      );
    } else {
      this.setState({ openModal: false });
    }
  }

  render() {
    const { openModal } = this.state;

    return (
      <Modal
        show={openModal}
      >
        <div className="flex items-center bold p2">
          <div className="col-11">
            <div className="h3 text-gray center">Custom Data Management</div>
          </div>
          <div className="col-1 flex flex-justify-end">
            <i className="fa fa-close h5 cursor-pointer" onClick={() => this.setState({ openModal: false })} />
          </div>
        </div>

        <div className="border-bottom border-top border-lighter-gray">
          <div className="mx3 my2 fw400" style={{ maxWidth: '540px' }}>
            <div className="center light">
              <span className="h4 text-light-gray">
                Custom data management, enables  you to choose what data is used for each company and to manage
                it yourself or let a invited company <br /> fill-in their latest data.
              </span>
            </div>

            <img
              className="my2"
              alt="guidance"
              src="/images/investments/custom-data-management-image.png"
            />

            <div className="flex flex-justify-center">
              <a
                className="h3 text-blue"
                href="http://docs.investory.io/en/articles/6860231-company-custom-data-management"
                target="_blank"
                rel="noopener noreferrer"
              >
                To see how it works, click here!
              </a>
            </div>
          </div>
        </div>

        <div className="my2 flex flex-justify-between mx3">
          <div className="flex items-center">
            <CheckBox
              fixedWidth
              onChange={this.handleClick}
            />
            <span className="ml1 text-light-gray light">Don't show this message again.</span>
          </div>

          <div
            className="p1 px2 bg-green text-white rounded cursor-pointer"
            onClick={this.handleClose}
          >
            Close
          </div>
        </div>
      </Modal>
    );
  }
}

export default CustomInvestmentGuidenceModal;
