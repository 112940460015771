import React, { Component } from 'react';
import PropTypes from 'prop-types';


class Avatars extends Component {
  static defaultProps = {
    disableLinks: false,
    disableTooltip: false,
    avatarsShowLimit: 7,
    size: null,
    tooltipPos: 'down',
    tooltipSize: 'large',
  }

  static propTypes = {
    avatars: PropTypes.array.isRequired,
    disableLinks: PropTypes.bool,
    disableTooltip: PropTypes.bool,
    avatarsShowLimit: PropTypes.number,
    size: PropTypes.string,
    tooltipPos: PropTypes.string,
    tooltipSize: PropTypes.string,
  }

  avatars = () => {
    const { avatars, disableLinks, disableTooltip, avatarsShowLimit, size, tooltipPos, tooltipSize } = this.props;

    return (
      avatars.slice(0, avatarsShowLimit - 1).map(user => (
        <a
          key={`avatar_${user.name}`}
          href={disableLinks ? null : user.url}
        >
          <span
            className={`mr02 block bg-center bg-cover bg-no-repeat circle ${size ? '' : 'header__avatar--investor'}`}
            data-balloon={disableTooltip ? null : user.name}
            data-balloon-pos={tooltipPos}
            data-balloon-length={tooltipSize}
            style={this.style(user, size)}
          />
        </a>
      ))
    );
  }

  more = () => {
    const { avatars, avatarsShowLimit } = this.props;
    if (avatars.length < avatarsShowLimit) {
      return null;
    }
    return (
      <div
        className="text-light-gray bold"
        data-balloon={avatars.slice(avatarsShowLimit - 1, avatars.length).map((user) => user.name).join(', ')}
        data-balloon-pos="down"
      >
        +{avatars.length - (avatarsShowLimit - 1)}
      </div>
    );
  }

  style = (user, size) => {
    if (size) {
      return (
        { backgroundImage: `url( ${user.avatar} )`, height: size, width: size }
      );
    }
    return (
      { backgroundImage: `url( ${user.avatar} )` }
    );
  }

  render() {
    return (
      <div className="flex">
        {this.avatars()}
        {this.more()}
      </div>
    );
  }
}

export default Avatars;
