import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import moment from 'moment';

import DatePicker from './DatePicker';

import { defaultSelectStyles, defaultSelectTheme } from './SelectStyles';

/*
  rangeOptions elements:
  - start_date_last_year
  - end_date_last_year
  - start_date_last_quarter
  - end_date_last_quarter
  - start_date_last_month
  - end_date_last_month
*/

class SelectWithDate extends Component {
  dateRangeOptions = [
    { value: 'previous_year', label: 'Last Year' },
    { value: 'last_quarter', label: 'Last Quarter' },
    { value: 'last_month', label: 'Last Month' },
    { value: 'custom', label: 'Custom' },
  ]

  static propTypes = {
    rangeOptions: PropTypes.object.isRequired,
    defaultOption: PropTypes.string,
    onChange: PropTypes.func,
    showButton: PropTypes.bool,
    customText: PropTypes.string,
  }

  static defaultProps = {
    defaultOption: 'last_month',
    onChange: null,
    showButton: false,
    customText: 'Select KPI Date Range:',
  }

  constructor(props) {
    super(props);
    const { defaultOption } = this.props;

    this.state = this.dateRangeStatesSelector(defaultOption);
  }

  dateRangeStatesSelector = (val) => {
    const { rangeOptions } = this.props;

    let chosenStates = {};

    if (val === 'previous_year') {
      chosenStates = {
        startDateInside: rangeOptions.start_date_last_year,
        endDateInside: rangeOptions.end_date_last_year,
        dateRange: 'previous_year',
      };
    } else if (val === 'last_quarter') {
      chosenStates = {
        startDateInside: rangeOptions.start_date_last_quarter,
        endDateInside: rangeOptions.end_date_last_quarter,
        dateRange: 'last_quarter',
      };
    } else if (val === 'last_month') {
      chosenStates = {
        startDateInside: rangeOptions.start_date_last_month,
        endDateInside: rangeOptions.end_date_last_month,
        dateRange: 'last_month',
      };
    } else {
      chosenStates = { dateRange: 'custom' };
    }
    return chosenStates;
  }

  handleOptionSelect = (selectedOption) => {
    const { onChange } = this.props;

    this.setState(this.dateRangeStatesSelector(selectedOption.value), () => {
      if (onChange) {
        const {
          startDateInside,
          endDateInside,
        } = this.state;

        onChange(selectedOption.value, startDateInside, endDateInside);
      }
    });
  }

  handleSingleDateSelect = (date, type) => {
    const { onChange } = this.props;
    const { dateRange, startDateInside, endDateInside } = this.state;

    if (type === 'start_date') {
      this.setState({ startDateInside: date });
      onChange(dateRange, date, endDateInside);
    } else {
      this.setState({ endDateInside: date });
      onChange(dateRange, startDateInside, date);
    }
  }

  handleClick = () => {
    const { onChange } = this.props;
    const {
      dateRange,
      startDateInside,
      endDateInside,
    } = this.state;

    if (onChange) {
      onChange(dateRange, startDateInside, endDateInside);
    }
  }

  render() {
    const { showButton, customText } = this.props;

    const {
      startDateInside,
      endDateInside,
      dateRange,
    } = this.state;

    const disableDateInput = dateRange !== 'custom';
    const datePickerStyle = {
      height: '40px',
      borderRadius: '4px',
      width: '50%',
      borderColor: '#bcc7cf',
      padding: '5px',
    };

    return (
      <>
        <div className="pt1 pb2">
          <ReactSelect
            name="date_range"
            value={this.dateRangeOptions.filter(option => option.value === dateRange)}
            options={this.dateRangeOptions}
            isClearable={false}
            multi={false}
            onChange={selectedOption => this.handleOptionSelect(selectedOption)}
            styles={defaultSelectStyles}
            theme={defaultSelectTheme}
          />
        </div>

        <div className="col-12 flex flex-column flex-start">
          {
            !disableDateInput && <div className="mb1">{customText}</div>
          }

          <div className="flex">
            <div className="mb2 relative flex flex-center">
              <DatePicker
                placeholder="Start date"
                value={startDateInside}
                onChange={(e, value) => this.handleSingleDateSelect(moment(value).format('YYYY-MM-DD'), 'start_date')}
                disabled={disableDateInput}
                invalidDates={false}
                showMonthYear
                skipPropsUpdate={false}
                className={`border ${disableDateInput ? 'opacity-35' : ''}`}
                style={datePickerStyle}
                blockDeletion
              />

              <span className="mx1">&ndash;</span>

              <DatePicker
                placeholder="End date"
                value={endDateInside}
                onChange={(e, value) => this.handleSingleDateSelect(moment(value).format('YYYY-MM-DD'), 'end_date')}
                disabled={disableDateInput}
                invalidDates={false}
                showMonthYear
                skipPropsUpdate={false}
                className={`border ${disableDateInput ? 'opacity-35' : ''}`}
                style={datePickerStyle}
              />

              <input type="hidden" name="start" value={startDateInside} />
              <input type="hidden" name="end" value={endDateInside} />
            </div>

            {showButton && dateRange === 'custom' &&
            <div className="ml2">
              <div
                className="py1 px2 bg-blue text-white rounded cursor-pointer"
                onClick={this.handleClick}
              >
                <span>Request Data</span>
              </div>
            </div>
          }
          </div>
        </div>
      </>
    );
  }
}

export default SelectWithDate;
