import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HeaderContainer from '../../../../containers/investor/investments/HeaderContainer';
import LoadingSpinner from '../../../LoadingSpinner';


class DataRooms extends Component {
  static propTypes = {
    header: PropTypes.object,
    dataRooms: PropTypes.object.isRequired,
    hideDataRooms: PropTypes.bool.isRequired,
    linkNewTab: PropTypes.bool,
  }

  static defaultProps = {
    header: null,
    linkNewTab: false,
  }

  constructor(props) {
    super(props);

    const { dataRooms } = props;

    this.state = {
      searchQuery: '',
      loading: dataRooms.all_documents == null || dataRooms.all_documents === undefined,
    };
  }

  componentWillReceiveProps(props) {
    const { dataRooms } = props;

    this.setState({ loading: dataRooms.all_documents == null || dataRooms.all_documents === undefined });
  }

  getLinkIcon = (link) => {
    if (link.includes('google.com')) return ('fa-google');
    if (link.includes('dropbox.com')) return ('fa-dropbox');
    return ('fa-link');
  };

  render() {
    const { header, dataRooms, hideDataRooms, linkNewTab } = this.props;
    const { searchQuery, loading } = this.state;

    const documents = dataRooms.all_documents && dataRooms.all_documents.filter(d => d.name.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
      <div className="mb3">
        {header &&
          <HeaderContainer
            activeTab="data_rooms"
            tabUrl={`investments/${header.id}/data_rooms/`}
          />
        }

        {!loading ?
          <div className={`flex flex-column col-12 ${hideDataRooms ? null : 'my2'}`}>
            {
              hideDataRooms ? null :
              <div className="bs-around bg-white p2">
                <div className="flex px2 h5 fw400">
                  <div className="col-6 sm-col-10">Data Room Name</div>
                  <div className="col-2 sm-hide">Documents</div>
                  <div className="col-2 sm-hide">Managed By</div>
                  <div className="col-2">Last Upload</div>
                  <span className="mr1" />
                </div>
                <div className="mx2">
                  <a href={dataRooms.investor.path} className="bg-white bs-around col-12 flex flex-center relative text-gray p1 px1 mt1 card--has-hover-light-gray__small">
                    <div className="flex flex-column col-6 desktop__notification--changes-blue lh-12 sm-col-10">
                      <span className="fw400 truncate pr2">{header.company_name.text}</span>
                      <span className="h6 text-light-gray">Investor Data Room</span>
                    </div>
                    <div className="col-2 h5 truncate pr1 sm-hide">{`${dataRooms.investor.files_cnt} (${dataRooms.investor.total_size})`}</div>
                    <div className="col-2 h5 truncate pr1 sm-hide">{header.investor.name}</div>
                    <div className="col-2 h5 truncate px1 sm-hide">{dataRooms.investor.last_upload}</div>
                    <i className="fa fa-caret-right" />
                  </a>
                </div>
                {dataRooms.company &&
                  <div className="mx2">
                    <a
                      href={dataRooms.company.path}
                      className="bg-white bs-around col-12 flex flex-center relative text-gray p1 px1 mt2 card--has-hover-light-gray__small"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="flex flex-column col-6 desktop__notification--changes-green lh-12">
                        <div>
                          <span className="fw400 truncate">{dataRooms.company.original_name}</span>
                          <i className="ml1 fa fa-external-link" />
                        </div>
                        <span className="h6 text-light-gray">Company Data Room</span>
                      </div>
                      <div className="col-2 h5 truncate pr1">{`${dataRooms.company.files_cnt} (${dataRooms.company.total_size})`}</div>
                      <div className="col-2 h5 truncate pr1">{dataRooms.company.original_name}</div>
                      <div className="col-2 h5 truncate px1">{dataRooms.company.last_upload}</div>
                      <i className="fa fa-caret-right" />
                    </a>
                  </div>
                }
              </div>
            }

            <div className={`bs-around ${hideDataRooms ? null : 'mt2'}`}>
              <div className="flex items-center bg-white">
                <i className="fa fa-search p2 ml2 text-light-gray" />
                <input
                  className="border-none col-12 h5"
                  placeholder="Search All Documents"
                  type="text"
                  onChange={e => this.setState({ searchQuery: e.target.value })}
                />
              </div>
              <div className="flex bg-extra-light-gray text-gray px3 py1 fw400 h5">
                <div className="col-6">Name</div>
                <div className="col-2">Uploaded by</div>
                <div className="col-2">Size</div>
                <div className="col-2">Modified</div>
              </div>
              <div className="bg-white text-gray overflow-y-auto" style={{ height: '330px' }}>
                { documents.length > 0 &&
                    documents.map(d => (
                      <a
                        key={`doc_${d.id}`}
                        className="flex border-bottom border-gallery h5 text-black p2 px3"
                        href={d.preview_url}
                        data-method={d.link ? null : 'get'}
                        target={(d.link || d.from_existing_company) || linkNewTab ? '_blank' : null}
                        rel={linkNewTab ? 'noopener noreferrer' : null}
                      >
                        <div className="flex items-center col-6 sm-col-9">
                          <span className="h3 mr2" style={{ minWidth: '25px', width: '25px' }}>
                            <i className={d.link ? `fa ${this.getLinkIcon(d.link)}` : d.icon_class_name} style={{ color: d.icon_color }} />
                          </span>
                          <span className="text light-gray fw400 truncate pr2" title={d.link ? d.download_url : d.name}>{d.name} {d.from_existing_company || linkNewTab ? <i className="fa fa-external-link" /> : null}</span>
                        </div>
                        <div className="col-2 truncate">{ hideDataRooms ? d.company_name : d.uploader.name }</div>
                        <div className="col-2">
                          {d.link ?
                            '\u2013 (link)'
                            :
                            parseFloat(d.size) ? d.size : '\u2013'
                          }
                        </div>
                        <div className="col-2 truncate">{d.updated_at}</div>
                      </a>
                    ))
                }
                { documents.length === 0 &&
                  <div className="flex items-center flex-justify-center text-light-gray px2" style={{ height: '100%' }}>No documents uploaded to any data room yet...</div>
                }
              </div>
            </div>
          </div>
          :
          <LoadingSpinner
            show={loading}
            type="fit"
            background="white"
            height="100px"
          />
      }
      </div>
    );
  }
}

export default DataRooms;
