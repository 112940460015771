import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Textarea from '../../Textarea';

class Contact extends Component {
  static propTypes = {
    col: PropTypes.object.isRequired,
    edit: PropTypes.bool.isRequired,
    investorId: PropTypes.number.isRequired,
    updateReportColumn: PropTypes.func.isRequired,
    titleColor: PropTypes.string.isRequired,
    valueColor: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      value: props.col.value || '',
    };
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  saveChange = (id) => {
    const { investorId, updateReportColumn } = this.props;
    updateReportColumn(investorId, id, this.state);
  }

  renderCeoSiteOrMail = (col, valueColor) => {
    if (col.title === 'Website') {
      const link = (col.value.indexOf('://') === -1) ? `http://${col.value}` : col.value;
      return (
        <a
          className="lh-15 fw400 break-word underline"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ width: '70%', color: valueColor }}
        >
          {col.value}
        </a>
      );
    }
    if (col.title === 'Email') {
      return (
        <a
          className="lh-15 fw400 break-word underline"
          href={`mailto:${col.value}`}
          style={{ width: '70%', color: valueColor }}
        >
          {col.value}
        </a>
      );
    }

    if (col.value.includes('http://')) {
      return (
        <a
          href={col.value}
          target="_blank"
          rel="noopener noreferrer"
          className="lh-15 fw400 break-work"
          style={{ width: '70%', color: valueColor }}
        >
          {col.value}
        </a>
      );
    }
    return (
      <p
        className="m0 lh-15 fw400 break-word"
        style={{ width: '70%', color: valueColor }}
      >
        {col.value}
      </p>
    );
  }

  renderSocialLinks = (col, valueColor, titleClasses, titleColor) => {
    let link = col.value;
    let socialType = '';

    if (col.title === 'Facebook') {
      const regex = /http(s)?:\/\/(www.)?(facebook|fb).com/ig;
      if (!regex.test(col.value)) {
        if (col.value.charAt(0) === '@') {
          link = col.value.substr(1);
        }
        link = `https://facebook.com/${link}`;
      }
      socialType = 'facebook';
    }

    if (col.title === 'Twitter') {
      const regex = /http(s)?:\/\/(www.)?twitter.com/ig;
      if (!regex.test(col.value)) {
        if (col.value.charAt(0) === '@') {
          link = col.value.substr(1);
        }
        link = `https://twitter.com/${link}`;
      }
      socialType = 'twitter';
    }

    if (col.title === 'LinkedIn') {
      const regex = /http(s)?:\/\/(www.)?linkedin.com/ig;
      if (!regex.test(col.value)) {
        if (col.value.charAt(0) === '@') {
          link = col.value.substr(1);
        }
        link = `https://linkedin.com/in/${link}`;
      }
      socialType = 'linkedin';
    }

    const socialIcon = classNames(`fa fa-${socialType} text-color-${socialType}`, {
      'flex items-center': col.display === 'inline',
      'mr2 px2': col.border,
    });

    return (
      <div className="flex pt1 sm-pt1 col-12 flex items-center">
        {col.title && (<p className={titleClasses} style={{ color: titleColor }}>{col.title}</p>)}
        {col.subtitle && (<small className="m0 text-gray">{col.subtitle}</small>)}
        <a style={{ width: '70%' }} href={link} target="_blank" rel="noopener noreferrer">
          <i className={socialIcon} />
        </a>
      </div>
    );
  }

  render() {
    const { col, edit, titleColor, valueColor } = this.props;
    const { value } = this.state;
    const container = classNames(`pt1 sm-pt1 col-${col.width}`, {
      'flex items-center': col.display === 'inline',
      'mr2 px2': col.border,
    });
    const titleClasses = classNames('m0 text-light-gray truncate', {
      'flex-auto': col.display === 'inline',
    });

    if (!edit && !col.value) return null;

    if (!edit && (col.title === 'Facebook' || col.title === 'Twitter' || col.title === 'LinkedIn')) return this.renderSocialLinks(col, valueColor, titleClasses, titleColor);

    return (
      <div
        key={`col_${col.id}`}
        className={container}
        style={{ border: `1px solid ${col.border}` }}
        data-balloon={edit ? col.tip : null}
        data-balloon-pos="down"
        data-balloon-length="fit"
      >
        {col.title && (<p className={titleClasses} style={{ color: titleColor }}>{col.title}</p>)}
        {col.subtitle && (<small className="m0 text-gray">{col.subtitle}</small>)}
        {edit ?
          <Textarea
            className="m0 lh-15 border border-lighter-gray bg-none hover hover-border-blue fw400"
            style={{ width: '75%', resize: 'none', color: valueColor }}
            name="value"
            value={value}
            onChange={e => this.handleChange(e)}
            onBlur={() => this.saveChange(col.id)}
          />
          :
          this.renderCeoSiteOrMail(col, valueColor)
        }
      </div>
    );
  }
}

export default Contact;
