import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import TextInput from '../TextInput';


class SaveLinkModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    saveLink: PropTypes.func.isRequired,
  }

  state = {
    name: '',
    link: '',
    valid: false,
    error: null,
  }

  validateLink = e => {
    const { type } = this.props;
    const { value } = e.target;
    this.setState({ link: value });

    let valid = false;
    switch (type) {
      case 'drive':
        if (value.includes('google.com') || value === '') valid = true;
        break;
      case 'dropbox':
        if (value.includes('dropbox.com') || value === '') valid = true;
        break;
      default: valid = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
    }

    if (valid) {
      ($('#link').get(0).style.border = '');
      this.setState({ error: null });
    } else {
      ($('#link').get(0).style.border = '1px solid red');
      this.setState({ error: 'Looks like we do not recognize this link...' });
    }
    this.setState({ valid });
  }

  submit = async () => {
    const { saveLink, closeModal } = this.props;
    const { name, link, valid } = this.state;

    if (!link || link.trim().length === 0) return;
    if (!valid) return;

    const trimmedLink = link.trim();
    const includesHttpOrHttps = /http(s)?:\/\//.test(trimmedLink);

    const params = {
      name: name.trim().length ? name : link,
      link: includesHttpOrHttps ? trimmedLink : `http://${trimmedLink}`,
    };

    await saveLink(params);
    closeModal();
  }

  getPlaceholder = () => {
    const { type } = this.props;

    switch (type) {
      case 'drive':
        return 'http(s)://docs.google.com/...';
      case 'dropbox':
        return 'http(s)://www.dropbox.com/...';
      default:
        return 'http(s):// ...';
    }
  }

  getTitle = () => {
    const { type } = this.props;

    switch (type) {
      case 'drive':
        return 'Google Drive';
      case 'dropbox':
        return 'Dropbox';
      default:
        return '';
    }
  }

  render() {
    const { show, closeModal } = this.props;
    const { name, link, error } = this.state;

    return (
      <Modal
        className="bg-white flex flex-column mb2 react_modal border-top border-blue bw-4"
        show={show}
        onClickOutside={closeModal}
      >
        <div
          className="flex flex-auto flex-justify-end cursor-pointer p1"
          onClick={closeModal}
        >
          <i className="fa fa-close text-gray" />
        </div>

        <p className="col-12 center semi-bold pt2 h2">Add {this.getTitle()} Link</p>
        <div className="col-12 center text-orange h5 lh-12">
          Please note that only the link will be saved!<br />Link access &amp; availability needs to also be ensured outside of investory.
        </div>

        <div className="col-12 flex flex-column flex-center sm-block bg-white p3 sm-center">
          <TextInput
            className="col-12 border border-light-gray input--placeholder mb2"
            onChange={e => this.setState({ name: e.target.value })}
            placeholder="Enter the title of this document..."
            value={name}
            name="name"
            id="name"
          />
          <TextInput
            required
            className="col-12 border border-light-gray input--placeholder mt2"
            onChange={e => this.validateLink(e)}
            placeholder={`e.g. ${this.getPlaceholder()}`}
            value={link}
            name="link"
            id="link"
          />
          <p className="m0 text-red h5" style={{ width: '100%' }}>{error}</p>
          <div className="bg-blue mt2 text-white semi-bold p1 px2 cursor-pointer rounded" onClick={this.submit}>
            Save
          </div>
        </div>
      </Modal>
    );
  }
}

export default SaveLinkModal;
