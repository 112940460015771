import React from 'react';
import PropTypes from 'prop-types';

import Pricing from './Pricing';
import CustomerInfo from './CustomerInfo';
import TrialSubscription from './TrialSubscription';
import IntentModal from '../../payment/intentModal';


const Billing = ({
  company,
  stripe,
  subscribe,
  updateSubscription,
  unsubscribe,
  resubscribe,
  updateCard,
  deleteCustomer,
}) => {
  const customerInfo = () => (
    company.customer.last4 ?
      <CustomerInfo
        customer={company.customer}
        updateCard={updateCard}
        subscription={company.subscription}
        plans={stripe.plans}
        subscribe={subscribe}
        unsubscribe={unsubscribe}
        resubscribe={resubscribe}
        deleteCustomer={deleteCustomer}
      />
      :
      <TrialSubscription plans={stripe.plans} subscription={company.subscription} updateSubscription={updateSubscription} />
  );

  return (
    <div>
      {
        company.customer &&
        company.subscription &&
        (company.subscription.setup_intent_status === 'requires_payment_method' ||
        company.subscription.payment_intent_status === 'requires_payment_method') ?
          (
            customerInfo()
          ) : (
            company.customer &&
            company.subscription &&
            (company.subscription.setup_intent_status === 'requires_action' ||
            company.subscription.setup_intent_status === 'requires_confirmation' ||
            company.subscription.payment_intent_status === 'requires_action' ||
            company.subscription.payment_intent_status === 'requires_confirmation') ?
              (
                <IntentModal />
              ) : (
                company.customer && company.trial_used ? customerInfo() : <Pricing plans={stripe.plans} subscribe={subscribe} trialUsed={company.trial_used} />
              )
          )
      }
    </div>
  );
};

Billing.propTypes = {
  company: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  subscribe: PropTypes.func.isRequired,
  updateSubscription: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired,
  resubscribe: PropTypes.func.isRequired,
  updateCard: PropTypes.func.isRequired,
  deleteCustomer: PropTypes.func.isRequired,
};

export default Billing;
