import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactSelect from 'react-select';

import { defaultSelectStyles, defaultSelectTheme } from './SelectStyles';

// attributes:
// [
//   {
//      attributeName: 'attribute_name'
//      attributeLabel: 'Attribute Name'
//      options: [array of options]
//   }
//    ...
// ]
class MultipleSelects extends Component {
  static propTypes = {
    elements: PropTypes.array.isRequired,
    attributes: PropTypes.array,
    elementTitle: PropTypes.string,
    showAttributesHeader: PropTypes.bool,
    backgroundColor: PropTypes.string,
    name: PropTypes.string,
    blockedElements: PropTypes.array,
    hideData: PropTypes.bool,
  }

  static defaultProps = {
    attributes: null,
    elementTitle: 'Attribute',
    showAttributesHeader: false,
    backgroundColor: 'bg-alabaster',
    name: null,
    blockedElements: null,
    hideData: false,
  }

  constructor(props) {
    super();

    this.state = {
      elementsState: props.elements,
    };
  }

  handleStateUpdate = (value, elementIndex, attributeName) => {
    const { elementsState } = this.state;

    const newState = elementsState.map((element, index) => (
      index === elementIndex ?
        {
          ...element,
          [attributeName]: value,
        }
        :
        { ...element }
    ));

    this.setState({ elementsState: newState });
  }

  render() {
    const { elements, attributes, elementTitle, showAttributesHeader, backgroundColor, name, blockedElements, hideData } = this.props;
    const { elementsState } = this.state;

    return (
      <div className={`col-12 flex flex-column flex-center sm-block p2 sm-center ${backgroundColor}`}>
        {showAttributesHeader &&
          <div className="col-12">
            <div className="flex flex-center mb2 h3 border-top border-bottom mxn2 p1 border-light-gray">
              <div className="col-2" />
              {attributes.map(attribute => (
                <div className="col-3">{attribute.attributeLabel}</div>
              ))}
            </div>
          </div>
        }
        {elements.map((element, elementIndex) => (
          <div
            key={`element_${elementIndex + 1}`}
            className="flex items-center col-12 mb1"
          >
            <div className="col-2 fw400">
              {`${elementTitle} ${elementIndex + 1}`}
            </div>

            {attributes.map(attribute => (
              <ReactSelect
                key={attribute.attributeName}
                value={attribute.options.filter(option => option.value === elementsState[elementIndex][attribute.attributeName])}
                options={blockedElements ? blockedElements.includes(element.widget_type) ? [] : attribute.options.filter(option => !blockedElements.includes(option.value)) : attribute.options}
                onChange={(selectedOption) => this.handleStateUpdate(selectedOption.value, elementIndex, attribute.attributeName)}
                styles={defaultSelectStyles}
                theme={defaultSelectTheme}
                className={`col-4 ${hideData ? 'hide-data' : ''}`}
                isDisabled={blockedElements && blockedElements.includes(element.widget_type)}
              />
            ))}
          </div>
        ))}
        {elementsState.map(element => {
          const keys = Object.keys(element);
          return (
            keys.map(key => (
              <>
                <input type="hidden" name={`${name}[${key}]`} value={element[key]} multiple="multiple" />
              </>
            ))
          );
        })}
      </div>
    );
  }
}

export default MultipleSelects;
