import React from 'react';
import PropTypes from 'prop-types';

import SorterHeader from '../SorterHeader';

const getColClass = () => {
  const { data } = this.props;

  let status = 2;

  if (data.has_funds) {
    status = 1;
  }
  return { status };
};

const InvestorMemberHeader = ({ data }) => (
  <div className="col-12 text-gray h5 flex px2 bg-extra-light-gray">
    <div className="col-3 py1 bold pl2 sm-col-8 sm-pl0">
      <SorterHeader text="Name" field="name" />
    </div>
    <div className="col-2 py1 bold pl2 sm-hide">
      <SorterHeader text="Role" field="role" />
    </div>
    {
      data.has_funds &&
      <div className="col-2 py1 bold pl2 sm-col-4 sm-hide">
        <SorterHeader text="Access to" field="fund" />
      </div>
    }
    <div className="col-2 py1 bold pl2 sm-hide">
      <SorterHeader text="Following" field="following_count" />
    </div>

    <div className="col-1 py1 bold sm-hide">
      <SorterHeader text="Data Room" field="can_download_dr_files" />
    </div>

    <div className={`${getColClass.status} py1 bold pl2 sm-col-4`}>
      <SorterHeader text="Status" field="status" />
    </div>
  </div>
);

InvestorMemberHeader.propTypes = {
  data: PropTypes.object.isRequired,
};

export default InvestorMemberHeader;
