import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Modal from '../../Modal';
import SetupInvestmentModal from './new_investment/SetupInvestmentModal';
import InviteCompanyContactModal from './new_investment/InviteCompanyContactModal';


class AddInvestment extends Component {
  static propTypes = {
    investor: PropTypes.object.isRequired,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    text: PropTypes.string,
    customInvestmentsCount: PropTypes.number.isRequired,
    newConnection: PropTypes.bool,
  }

  static defaultProps = {
    className: 'bold h5 hover hover-bg-wild-sand hover-text-blue lh-36 px1 text-gray cursor-pointer',
    text: 'New Company',
    newConnection: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      correspondentHintMessage: true,
      step: 0,
      currentResult: null,
      initialComponentStep: 1,
      setupInvestmentStep: 2,
      inviteContactStep: 3,
      investment: {
        investment_fields_attributes: [],
      },
      companyContact: null,
      loading: false,
      creationType: 'new',
      companyType: null,
      addedFunds: [],
      addedManagers: [],
    };

    this.initialState = this.state;
  }

  blockCreate = () => {
    const { investment } = this.state;

    return (investment.investment_fields_attributes.length === 0 && !investment.company_id);
  }

  closeModal = () => {
    this.setState(this.initialState);

    this.setState({ step: 0 });
  }

  handlePrepareInvite = () => {
    setTimeout(() => $('[data-behavior="modal"][data-target="#modal-invite-simple-top-header"]').trigger('click'), 300);
    this.setState({ step: 0 });
  }

  handleSetup = (companyType) => {
    const { setupInvestmentStep } = this.state;

    this.setState({ step: setupInvestmentStep, companyType });
  }

  sendCompanyAttributes = async () => {
    const { investor } = this.props;
    const { investment, loading } = this.state;

    if (loading) return;

    const url = `/investor/${investor.id}/companies/store_params`;
    const formatVal = (val) => (val === undefined ? '' : val);

    const formData = new FormData();

    investment.investment_fields_attributes.filter(attr => attr.name !== 'id').forEach(param => formData.append(`company[${param.name}]`, formatVal(param.text || param.image_from_url)));

    try {
      this.setState({ loading: true });
      await axios.post(url, formData);
      window.location = `/investor/${investor.id}/companies/new`;
    } catch (err) {
      this.setState({ loading: false });
      App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an unknown error happened.' });
    }
  }

  stepBackward = ({ reset = false }) => {
    const { step } = this.state;
    const newStep = step - 1;

    this.setState({ step: newStep }, () => {
      if (reset) {
        this.setState({ ...this.initialState, step: newStep });
      }
    });
  }

  stepForward = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  }

  addElement = (type, selectedEl) => {
    const { addedFunds, addedManagers } = this.state;

    const newEl = {
      id: selectedEl.value,
      name: selectedEl.label,
      avatar: selectedEl.icon,
    };

    if (type === 'funds') {
      this.setState({ addedFunds: [...addedFunds, newEl] });
    } else {
      this.setState({ addedManagers: [...addedManagers, newEl] });
    }
  }

  removeElement = (type, elementId) => {
    const { addedFunds, addedManagers } = this.state;

    if (type === 'funds') {
      this.setState({ addedFunds: addedFunds.filter(fund => fund.id !== elementId) });
    } else {
      this.setState({ addedManagers: addedManagers.filter(manager => manager.id !== elementId) });
    }
  }

  addCompanyContact = (result) => {
    this.setState({ companyContact: result });
  }

  removeCompanyContact = () => {
    this.setState({ companyContact: null });
  }

  handleCrunchbaseResult = (result) => {
    const invesmentFields = result.attributes;
    const params = { investment_fields_attributes: [] };
    let creationType;

    if (result.existing_company) {
      creationType = 'existing_company';
      params.company_id = result.attributes.id;
    } else {
      creationType = 'new';

      Object.keys(invesmentFields).forEach(field => {
        if (field === 'avatar') {
          params.investment_fields_attributes.push({ name: 'avatar', overwrite: 'avatar', image_from_url: invesmentFields[field] });
        } else {
          params.investment_fields_attributes.push({ name: field, text: invesmentFields[field], overwrite: field });
        }
      });
    }

    this.setState(
      {
        currentResult: result,
        investment: params,
        creationType,
      },
    );
  }

  createCompany = async () => {
    const { customInvestmentsCount, investor } = this.props;
    const { investment, creationType, addedFunds, addedManagers, companyContact } = this.state;

    this.setState({ loading: true });
    const url = `/investor/${investor.id}/investments/?creation_type=${creationType}`;
    const params = {
      investment,
      fund_ids: addedFunds.map(fund => fund.id),
      membership_ids: addedManagers.map(membership => membership.id),
    };

    if (companyContact) {
      // Can be either a company id or a new contact email
      params.company_contact = companyContact.id ? { company_id: companyContact.id } : { email: companyContact.email };
    }

    try {
      const response = await axios.post(url, params);
      // Intercom is being checked because breaks development
      if (typeof Intercom !== 'undefined' && creationType !== 'existing_company') Intercom('trackEvent', 'custom_investment_created', { custom_investments_count: customInvestmentsCount + 1 });
      this.closeModal();
      window.location = `/investor/${investor.id}/investments/${response.data.investment_id}/edit`;
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an error prevented the investment from being created.' });
      this.setState({ loading: false });
    }
  }

  firstSubmit = () => {
    const { loading, creationType, companyType } = this.state;

    if (loading || this.blockCreate()) return;

    if (companyType === 'real_company' && creationType === 'new') {
      this.sendCompanyAttributes();
    } else if (creationType === 'existing_company') {
      this.createCompany();
    } else {
      this.stepForward();
    }
  }

  lastSubmit = () => {
    const { loading } = this.state;

    if (loading || this.blockCreate()) return;

    this.createCompany();
  }

  render() {
    const { className, wrapperClassName, text, investor, newConnection } = this.props;
    const {
      correspondentHintMessage,
      step,
      currentResult,
      companyContact,
      initialComponentStep,
      setupInvestmentStep,
      inviteContactStep,
      loading,
      creationType,
      companyType,
      addedFunds,
      addedManagers,
    } = this.state;

    const customCompaniesHintMessage = `Investory introduced this option in the past for investors to set up a company and manage data through their own dashboard, which has been discontinued now. ${correspondentHintMessage ? 'Click on "i" icon to know more about the new enhanced & upgraded version of custom company.' : ''}`;
    const investorManagedCompaniesHintMessage = `Investory has recently introduced an enhanced & upgraded version of custom company account which is more advanced in a way that it gives an option to investors to set up a company and have access to full startup interface view that is available for the portfolio companies in their dashboard, to manage a custom investment. ${correspondentHintMessage ? 'Click on "i" icon to know more about custom company account which has been discontinued.' : ''}`;

    const heightStyle = {};
    if (newConnection) {
      heightStyle.minHeight = '198px';
    }
    return (
      <>
        <div className={wrapperClassName} onClick={() => this.setState({ step: 1 })} style={heightStyle}>
          <div
            className={className}
          >
            {text}
          </div>
        </div>

        {/* Start of modals handling */}
        <Modal
          show={initialComponentStep === step}
          onClickOutside={this.closeModal}
        >
          <div className="flex flex-justify-between items-center bold p2 border-bottom border-lighter-gray">
            <div className="h3 text-gray center">Add Company</div>
            <i className="fa fa-close h5 cursor-pointer" onClick={this.closeModal} />
          </div>

          <div className="flex flex-column m3 fw400">
            <div className="px2 border border-green rounded">
              <div className="h3 py2 center">
                Add New Companies
              </div>

              <div className="center py2 text-light-gray border-bottom border-top border-alto">
                Invite new companies to your portfolio.
              </div>

              <div className="py2 flex items-center flex-justify-center">
                <div
                  className="h5 p1 px2 text-white bg-green bold rounded cursor-pointer"
                  onClick={this.handlePrepareInvite}
                >
                  Prepare Invites
                </div>
              </div>
            </div>

            {(investor.can_create_custom_companies || investor.can_create_real_companies) &&
              <>
                {investor.can_create_custom_companies &&
                  <div className="flex flex-justify-between items-center mt2">
                    <div className="col-8">
                      <span className="text-light-gray">Setup a <b>custom</b> company and manage its data yourself</span>
                      <span
                        className="ml1"
                        data-balloon={correspondentHintMessage ? customCompaniesHintMessage : investorManagedCompaniesHintMessage}
                        data-balloon-length="xlarge"
                        onClick={() => this.setState({ correspondentHintMessage: !correspondentHintMessage })}
                        onMouseLeave={() => setTimeout(() => this.setState({ correspondentHintMessage: true }), 500)}
                      >
                        <i className="fa fa-info-circle text-blue" />
                      </span>
                    </div>
                    <div
                      className="px1 py05 border border-blue rounded cursor-pointer hover hover-bg-blue hover-text-white text-blue"
                      onClick={() => this.handleSetup('custom_company')}
                    >
                      <span className="h5">Setup Company</span>
                    </div>
                  </div>
                }
                {investor.can_create_real_companies &&
                  <div className="flex flex-justify-between items-center mt2">
                    <div className="col-8">
                      <span className="text-light-gray">Setup a company and manage its data yourself</span>
                      <span
                        className="ml1"
                        data-balloon={correspondentHintMessage ? investorManagedCompaniesHintMessage : customCompaniesHintMessage}
                        data-balloon-length="xlarge"
                        onClick={() => this.setState({ correspondentHintMessage: !correspondentHintMessage })}
                        onMouseLeave={() => setTimeout(() => this.setState({ correspondentHintMessage: true }), 500)}
                      >
                        <i className="fa fa-info-circle text-blue" />
                      </span>
                    </div>
                    <div
                      className="px1 py05 border border-blue rounded cursor-pointer hover hover-bg-blue hover-text-white text-blue"
                      onClick={() => this.handleSetup('real_company')}
                    >
                      <span className="h5">Setup Company</span>
                    </div>
                  </div>
                }
              </>
            }
          </div>
        </Modal>

        <SetupInvestmentModal
          show={setupInvestmentStep === step}
          companyType={companyType}
          currentResult={currentResult}
          investor={investor}
          stepBackward={() => this.stepBackward({ reset: true })}
          closeModal={this.closeModal}
          handleCrunchbaseResult={this.handleCrunchbaseResult}
          blockCreate={this.blockCreate()}
          loading={loading}
          creationType={creationType}
          addedFunds={addedFunds}
          addedManagers={addedManagers}
          addElement={this.addElement}
          removeElement={this.removeElement}
          submit={this.firstSubmit}
        />

        <InviteCompanyContactModal
          show={inviteContactStep === step}
          companyContact={companyContact}
          stepBackward={this.stepBackward}
          closeModal={this.closeModal}
          addCompanyContact={this.addCompanyContact}
          removeCompanyContact={this.removeCompanyContact}
          loading={loading}
          submit={this.lastSubmit}
        />
      </>
    );
  }
}

export default AddInvestment;
