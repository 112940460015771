import React, { Component } from 'react';
import PropTypes from 'prop-types';


class KpisTotalSum extends Component {
  static defaultProps = {
    colorTheme: null,
  }

  static propTypes = {
    totalValues: PropTypes.object.isRequired,
    dashboardKpis: PropTypes.array.isRequired,
    colorTheme: PropTypes.string,
  }

  componentDidMount() {
    const { colorTheme } = this.props;

    $(this.div)[0].style.setProperty('--investor-header-chart-values-color', colorTheme);
  }

  addCurrency = (val) => {
    const { totalValues } = this.props;
    if ((val === '') || (val === 'N/A')) {
      return 'N/A';
    }
    return `${val} ${totalValues.currency}`;
  }

  formatValue = (val) => {
    if ((val === '') || (val === 'N/A')) {
      return 'N/A';
    }
    return val;
  }

  currencyStyleColor = (val) => {
    if (val === 'N/A') {
      return 'text-alto';
    }
    return this.addCurrency(val) === 'N/A' ? 'text-alto' : 'investor-dashboard__chart-value-color';
  }

  render() {
    const { totalValues, dashboardKpis } = this.props;

    return (
      <div className="col-12 flex hover-bg-wild-sand py1 px1 text-gray flex flex-center bg-wild-sand border-top border-bottom border-alto">
        <div className="width-30p ml1 text-gray fw400 flex flex-center h6">
          Total (converted to {totalValues.currency})
          <a
            data-balloon={`Based on foreign exchange reference rates from ${totalValues.rates_updated_at} provided by the european central bank. Click the info icon to check reference rates. Converted currency is changeable inside your user profile!`}
            data-balloon-pos="up"
            data-balloon-length="xlarge"
            href="https://www.ecb.europa.eu/stats/policy_and_exchange_rates/euro_reference_exchange_rates/html/index.en.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-info-circle text-light-gray ml1" aria-hidden="true" />
          </a>
        </div>
        <div
          className="width-70p flex flex-row"
          ref={(c) => { this.div = c; }}
        >
          {
            totalValues.values.map((value, index) => (
              <div
                key={`total_${index + 1}`}
                className={`width-20p center z1 bold h6 ${this.currencyStyleColor(value)} center`}
              >
                {dashboardKpis[index].unit === 'currency' ? this.addCurrency(value) : this.formatValue(value)}
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

export default KpisTotalSum;
