import React, { Component } from 'react';
import PropTypes from 'prop-types';


class InvestorUpdate extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  commentsCount = () => {
    const { data } = this.props;
    if (data.comments > 0) {
      return (
        <div className="h6">
          <i className="fa fa-comment-o mr1" />
          {data.comments} Comments
        </div>
      );
    }
    return null;
  }

  displayAttachments = () => {
    const { data } = this.props;
    const attachments = data.attachments.map(item =>
      <a key={item.key} className="cursor-pointer mr1 text-medium-gray" href={item.url} data-balloon={item.file_name}><i className="fa fa-file-text-o" /></a>);

    if (data.attachements_count > 0) {
      return (
        <div className="col-2 flex flex-auto flex-justify-end mr3">
          <div className="">
            <div className="col-12">{data.attachements_count} files attached</div>
            <div className="col-12 flex">{attachments}</div>
          </div>
        </div>
      );
    }
    return (<div className="col-2 sm-hide mr3" />);
  }

  likesCount = () => {
    const { data } = this.props;
    if (data.likes > 0) {
      return (
        <div className="h6 mr1">
          <i className="fa fa-hand-paper-o mr1" />
          {data.likes} High Five
        </div>
      );
    }
    return null;
  }

  render() {
    const { data } = this.props;

    return (
      <div className="col-12 flex py1 border-top border-alto text-medium-gray flex-center" style={{ height: '70px' }}>
        <div className={`col-1 sm-col-2 flex flex-column flex-justify-center ${data.visited ? '' : 'border-left bw-2 border-blue'}`}>
          <div className="h2 center text-gray">{data.date_day}</div>
          <div className="text-upper center text-gray">{data.date_month}</div>
        </div>
        <div className="col-3 sm-col-2 flex flex-center">
          <span
            className="block col-3 bg-center bg-cover bg-no-repeat circle header__avatar--dash mr1"
            style={{ backgroundImage: `url( ${data.avatar} )` }}
          />
          <a
            className="fw400 col-9 text-gray truncate mr1 sm-hide"
            href={data.company_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.company_name} &nbsp; <i className="fa fa-external-link" />
          </a>
        </div>
        <a
          className="col-6 sm-col-7 truncate text-light-gray"
          href={data.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="flex flex-center">
            <div className="fw400 text-gray">{data.name} &nbsp; <i className="fa fa-external-link" /></div>
            <div className="col-8 sm-hide ml1 truncate">{data.preview}</div>
          </div>
          <div className="flex flex-center lh-12">
            <div
              className={`text-alto h6 fw400 border border-alto rounded inline-block px1 text-upper mr1 ${data.target_date ? 'border-blue text-blue' : ''}`}
              style={data.target_date ? { itemitemopacity: 0.5 } : {}}
            >
              {data.target_date ? `${data.update_type} ` : 'custom '}
              update
            </div>
            <div className="h6 sm-hide">
              {data.info}
            </div>
          </div>
          <div className="flex">{this.likesCount()}{this.commentsCount()}</div>
        </a>
        {this.displayAttachments()}
      </div>
    );
  }
}

export default InvestorUpdate;
