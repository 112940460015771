import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Avatar from '../../../tab_view/Avatar';
import SelectWithImage from '../../../selects/SelectWithImage';

class TeamMember extends Component {
  static defaultProps = {
    options: [],
  }

  static propTypes = {
    field: PropTypes.object.isRequired,
    edit: PropTypes.bool.isRequired,
    options: PropTypes.array,
    saveFields: PropTypes.func.isRequired,
    deleteFields: PropTypes.func.isRequired,
  }

  state = {
    search: false,
    saving: false,
  }

  handleSelectChange = async (userId) => {
    const { field: { id }, saveFields } = this.props;
    const params = {
      investment_field: {
        ids: [id],
        values: [{
          user_id: userId,
          name: null,
          image: null,
        }],
      },
    };
    this.setState({ saving: true });
    await saveFields(id, params);
    this.setState({ saving: false });
  }

  removeField = async (id) => {
    const { deleteFields } = this.props;

    const params = {
      investment_field: {
        ids: [id],
      },
    };

    this.setState({ saving: true });
    await deleteFields(params);
    this.setState({ saving: false });
  }

  render() {
    const { edit, field, options } = this.props;
    const { search, saving } = this.state;

    const container = classNames('py1', {
      'flex flex-center flex-justify-between border border-transparent hover hover-border-blue px1 show-close-on-hover': edit,
      'desktop__notification--changes-green': edit && field.user_id,
      'desktop__notification--changes-blue': edit && !field.user_id,
    });

    if (saving) {
      return (
        <div className="mt1 col-6 sm-col-12">
          <span>Saving...</span>
        </div>
      );
    }

    return (
      <div className="mt1 col-6 sm-col-12 relative">
        {search ?
          <div className="flex items-center show-check-on-hover">
            <SelectWithImage
              className="col-12"
              value={options.filter(option => option.value === field.user_id)}
              options={options}
              placeholder="Select..."
              onChange={selectedOption => this.handleSelectChange(selectedOption.value)}
              isClearable={false}
            />
            <i
              className="fa fa-check opacity-0 ml1 cursor-pointer text-green"
              onClick={() => this.setState({ search: false })}
            />
          </div>
          :
          <div
            className={container}
            onClick={() => edit && this.setState({ search: true })}
          >
            <div className="flex items-center">
              <Avatar avatarUrl={field.image} size="50px" />
              <div className="flex flex-column">
                <p className="m0 pl1 fw400">{field.name}</p>
                <div>
                  {field.facebook_url &&
                    <a className="pl1" href={field.facebook_url}><i className="fa fa-facebook text-color-facebook" /></a>
                  }
                  {field.twitter_url &&
                    <a className="pl1" href={field.twitter_url}><i className="fa fa-twitter text-color-twitter" /></a>
                  }
                  {field.linkedin_url &&
                    <a className="pl1" href={field.linkedin_url}><i className="fa fa-linkedin text-color-linkedin" /></a>
                  }
                </div>
              </div>
            </div>
            {edit &&
              <i
                className="fa fa-close opacity-0 ml1 cursor-pointer text-gray ml1"
                onClick={() => this.removeField(field.id)}
              />
            }
          </div>
        }
      </div>
    );
  }
}

export default TeamMember;
