import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../store/configureStore';
import KpisLibraryContainer from '../containers/KpisLibraryContainer';


const KpisLibrary = props => (
  <Provider store={configureStore(props)}>
    <KpisLibraryContainer />
  </Provider>
);

export default KpisLibrary;
