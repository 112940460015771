import React, { Component } from 'react';
import PropTypes from 'prop-types';


// defaultChecked - the initializing value for the check box
// manualCheck - whether the check box will be automatic checked/unchecked after calling the onChange function.
// checked - value to be used for checking when manualCheck is true
class CheckBox extends Component {
  static propTypes = {
    defaultChecked: PropTypes.bool,
    onChange: PropTypes.func,
    manualCheck: PropTypes.bool,
    checked: PropTypes.bool,
    selectedColor: PropTypes.string,
    unSelectedColor: PropTypes.string,
    size: PropTypes.string,
    fixedWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledMessage: PropTypes.string,
    checkboxSize: PropTypes.string,
    useInStaticPage: PropTypes.bool,
    name: PropTypes.string,
  }

  static defaultProps = {
    defaultChecked: false,
    selectedColor: 'blue',
    unSelectedColor: 'alto',
    fixedWidth: false,
    size: null,
    manualCheck: false,
    checked: false,
    disabled: false,
    disabledMessage: 'Checkbox is disabled',
    onChange: null,
    checkboxSize: 'h2',
    useInStaticPage: false,
    name: null,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      manualCheck,
      checked,
    } = nextProps;

    if (manualCheck && prevState.checked !== checked) {
      return { checked };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      checked: props.defaultChecked,
    };
  }

  handleClick(e) {
    e.preventDefault();

    const { checked } = this.state;
    const { manualCheck, onChange } = this.props;

    if (!manualCheck) {
      this.setState({ checked: !checked });
    }

    if (onChange) onChange();
  }

  render() {
    const {
      fixedWidth,
      size,
      selectedColor,
      unSelectedColor,
      disabled,
      disabledMessage,
      checkboxSize,
      useInStaticPage,
      name,
    } = this.props;

    const { checked } = this.state;

    const styles = {};
    if (fixedWidth) styles.width = '15px';
    if (size) styles.fontSize = size;

    const checkedClasses = `fa fa-check-square ${checkboxSize} ${disabled ? 'text-light-gray' : `text-${selectedColor}`}`;
    const unCheckedClasses = `fa ${disabled ? 'fa-square' : 'fa-square-o'} ${checkboxSize} ${disabled ? 'text-lighter-gray' : `text-${unSelectedColor}`}`;

    return (
      <div
        className="flex flex-center"
        data-balloon={disabled ? disabledMessage : null}
        data-balloon-pos={disabled ? 'down' : null}
      >
        <div
          className={`flex flex-center ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
          onClick={disabled ? null : e => this.handleClick(e)}
        >
          <i
            className={checked ? checkedClasses : unCheckedClasses}
            style={styles}
          />
        </div>
        {useInStaticPage &&
          <input type="hidden" name={name} value={checked} />
        }
      </div>
    );
  }
}

export default CheckBox;
