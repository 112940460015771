import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ReactSelect from 'react-select';

import FundEditButton from './fund/FundEditButton';
import FundDashboardKpisButton from './fund/FundDashboardKpisButton';
import FileField from '../FileField';
import { defaultSelectStyles, defaultSelectTheme } from '../selects/SelectStyles';
import ConfirmationModal from '../ConfirmationModal';


class InvestorFund extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  assignIds = (selectedOptions) => {
    const { data } = this.props;
    const { assign_url } = data;

    const params = { fund_connection: { investments: selectedOptions.map(option => ({ id: option.value })) } };

    axios.post(assign_url, params).then((res) => {
      store.dispatch({ type: 'SET_ITEM', list: 'main', id: res.data.id, value: res.data });
    });
  }

  deleteFund = () => {
    const { data } = this.props;

    axios.delete(data.manage_url).then(
      (res) => {
        store.dispatch({ type: 'REMOVE_ITEM', list: 'main', id: res.data.id });
        App.State.setFlash({ name: 'alert', msg: 'Deleted!' });
        setTimeout(() => { window.location.reload(); }, 1000);
      },
      (err) => App.State.setFlash({ name: 'alert', msg: err.response.data.error }),
    );
  }

  render() {
    const { data } = this.props;
    return (
      <div className="col-12 flex flex-wrap flex-justify-center px2 sm-px0 sm-mb1 sm-flex-justify-start">
        <div className="col-1 flex flex-center pl2 border-alto border-bottom center double-image-upload double-image-upload--funds sm-mr3">
          <div className="avatar-has-icon inline-block relative">
            <div className="double-image-upload__smaller user-avatar bg-center bg-cover bg-no-repeat circle mx-auto relative" style={{ backgroundImage: `url(${data.logo})` }} data-behavior={`avatar-preview-file-${data.id}`} />
            {data.id &&
              <>
                <FileField
                  name="fund[logo]"
                  text=""
                  preview_target={`[data-behavior="avatar-preview-file-${data.id}"]`}
                  saveImageUrl={data.manage_url}
                  dataMethod="patch"
                />
                <i className="fa fa-camera fa--funds absolute left-25 text-white vertical-center transform-center" />
              </>
            }
          </div>
        </div>
        <div className="col-2 flex items-center border-alto border-bottom py1 pl2 sm-col-6">
          {data.name}
        </div>
        <div className="col-7 border-alto border-bottom py1 pl2 sm-border-right-none sm-col-6 sm-hide">
          <ReactSelect
            className="Select--new"
            isMulti
            isClearable={false}
            placeholder={data.id ? 'Select companies to assign to this fund...' : 'All companies are assigned'}
            value={data.investments.filter(option => (data.assigned_companies.includes(option.value)))}
            options={data.investments}
            onChange={(selectedOptions) => { this.assignIds(selectedOptions); }}
            styles={defaultSelectStyles}
            theme={defaultSelectTheme}
            isDisabled={!data.id}
          />
        </div>

        <div className="col-2 border-alto border-bottom p1 flex sm-border-top-none sm-col-12 sm-hide">
          <div className="flex flex-auto items-center flex-justify-end sm-flex-justify-center">
            {data.id &&
              <FundEditButton manage_url={data.manage_url} name={data.name} />
            }
            {data.can_manage_dashboard_kpis ?
              <>
                {data.id && <span className="text-light-gray">|</span>}
                <FundDashboardKpisButton fundId={data.id} fundName={data.name} kpiOptions={data.kpi_options} dashboardKpis={data.dashboard_kpis} />
                {data.id && <span className="text-light-gray">|</span>}
              </>
              :
              (data.id && <span className="text-light-gray">|</span>)
            }
            {data.id &&
              <ConfirmationModal
                html={'<div class="bold h5 link-hover--blue block nowrap text-gray cursor-pointer"><i class="fa fa-fw fa-trash fa--dropdown mx1" /></div>'}
                className="h5 text-gray"
                onClick={this.deleteFund}
                message="<b>You are about to permanently delete this fund. All fund associated portfolio companies will be visible in all other funds until a new fund is chosen.</b><br/><b>Also the Data Room associated to this fund will get deleted.</b><br/><br/>Are you sure you want to delete?"
                width=""
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default InvestorFund;
