import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';


import Switch from '../../Switch';


class DataRoomManagerSharedAccess extends Component {
  static propTypes = {
    dataRoomers: PropTypes.array.isRequired,
    restrictAccess: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);

    const { dataRoomers, restrictAccess } = this.props;

    this.state = {
      dataRoomers,
      restrictAccess,
    };
  }


  handleAccess = async () => {
    const { restrictAccess } = this.state;

    try {
      await axios.patch('manage_shared_access', { restrict_access: !restrictAccess });
      this.setState({ restrictAccess: !restrictAccess });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors || 'Sorry, an unknow error happened.' });
    }
  }

  handleDeletion = async (roomerId) => {
    const { restrictAccess } = this.state;

    if (!restrictAccess) return;

    try {
      const res = await axios.delete(`manage_shared_access/?data_roomer_id=${roomerId}`);
      this.setState({ dataRoomers: res.data.data_roomers });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors || 'Sorry, an unknow error happened.' });
    }
  }

  render() {
    const { dataRoomers, restrictAccess } = this.state;

    return (
      <React.Fragment>
        <div className="flex flex-column flex-justify-center px2 ">
          {/* render Access section */}
          <div className="col-12 py2">
            <div className="border border-lighter-gray">
              <div className="flex flex-column">
                <div className="flex items-center flex-justify-between p1 pl2 bg-wild-sand text-gray">
                  <div>
                    <i className="fa fa-lock mr1" />
                    <span className="fw400">
                      Accessed by
                    </span>
                  </div>
                  <div className="col-7">
                    <Switch
                      labelLeft="Everyone with the link"
                      labelRight="Restrict access to users below"
                      defaultChecked={restrictAccess}
                      onChange={this.handleAccess}
                    />
                  </div>
                </div>
                <div className="p2">
                  <div className="flex flex-column">
                    {dataRoomers.length > 0 ?
                      dataRoomers.map(roomer => (
                        <div className="flex items-center flex-justify-between mb1" key={roomer.id}>
                          <div className="flex flex-column">
                            <span className="fw400">{roomer.email}</span>
                            <span className="h5 text-light-gray">{roomer.last_access ? `Last access ${roomer.last_access} ago.` : ''}</span>
                          </div>
                          {restrictAccess &&
                            <div
                              className="cursor-pointer"
                              data-balloon="Remove access."
                              data-balloon-pos="down"
                              data-balloon-length="medium"
                              onClick={() => this.handleDeletion(roomer.id)}
                            >
                              <i className="fa fa-close fa-lg text-red" />
                            </div>
                          }
                        </div>
                      ))
                      :
                      <div className="flex items-center">
                        <span>No users have accessed with the shareable link. </span>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DataRoomManagerSharedAccess;
