import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ReactSelect from 'react-select';

import Modal from '../../Modal';
import HtmlComponent from '../../tab_view/HtmlComponent';
import Switch from '../../Switch';
import Circle from '../../Circle';

import { defaultSelectStyles, defaultSelectTheme } from '../../selects/SelectStyles';


class SetupUpdateSchedule extends Component {
  static propTypes = {
    text: PropTypes.string,
    textClass: PropTypes.string,
    showIcon: PropTypes.bool,
    next_available_updates_info: PropTypes.object.isRequired,
    schedule_infos: PropTypes.object.isRequired,
    reminder_infos: PropTypes.object.isRequired,
    allowUpdateReminders: PropTypes.bool.isRequired,
    showBackButton: PropTypes.bool,
    company: PropTypes.object.isRequired,
  }

  static defaultProps = {
    text: 'Customize Schedule Here',
    textClass: 'text-blue fw400 cursor-pointer',
    showIcon: true,
    showBackButton: true,
  }

  constructor(props) {
    super(props);

    const { company, allowUpdateReminders } = props;

    this.initialState = {
      show: false,
      scheduleType: company.schedule_type,
      allowUpdateReminders,
      saving: false,
    };

    this.state = this.initialState;
  }

  toogleModal = () => {
    const { show } = this.state;

    $('.modal_layer--update').click();

    setTimeout(() => (
      this.setState({
        ...this.initialState,
        show: !show,
      })
    ), 300);
  }

  closeModal = () => {
    this.setState({ show: false });

    setTimeout(() => ($('#new-sh-update').click()), 300);
  }

  handleSave = () => {
    const { company } = this.props;
    const { scheduleType, allowUpdateReminders, saving } = this.state;

    if (saving) return;

    const params = { company: { schedule_type: scheduleType }, user: { allow_update_reminder_notifications: allowUpdateReminders } };

    this.setState({ saving: true });

    axios.patch(`/company/${company.id}/info/update_schedule`, params).then(
      (response) => {
        this.setState({ saving: false });
        window.location = response.data.redirect_url;
      },
      (err) => {
        this.setState({ saving: false });
        App.State.setFlash({ name: 'alert', msg: `Sorry, but an error happened. ${err.response.data.error}` });
      },
    );
  }

  scheduleTypeText = () => {
    const { scheduleType } = this.state;

    switch (scheduleType) {
      case 'quarterly':
        return 'Quarterly Update';
      case 'monthly':
        return 'Monthly Update';
      default:
        return 'Weekly Update';
    }
  }

  render() {
    const {
      text,
      textClass,
      showIcon,
      next_available_updates_info,
      schedule_infos,
      reminder_infos,
      company,
      showBackButton,
    } = this.props;
    const { show, scheduleType, allowUpdateReminders } = this.state;

    const scheduleTypeOptions = [
      { value: 'quarterly', label: 'Quarterly' },
      { value: 'monthly', label: 'Monthly' },
      { value: 'weekly', label: 'Weekly' },
    ];

    return (
      <React.Fragment>
        <span
          className={textClass}
          onClick={this.toogleModal}
        >
          {showIcon ? <i className="fa fa-calendar mr1" /> : ''} {text}
        </span>
        <Modal
          show={show}
          onClickOutside={this.toogleModal}
          widthClass="react_modal--800"
        >
          <div className="flex flex-justify-between items-center fw400 p2 border-bottom border-lighter-gray mb2">
            <div className="h3 pl2">Company Update Schedule</div>
            <i className="fa fa-close h5 cursor-pointer" onClick={this.toogleModal} />
          </div>

          <div className="fw400 px3 pb3 overflow-y-auto" style={{ maxHeight: '65vh' }}>
            {!company.subscribed &&
              <div className="mb2">
                <div className="p1 rounded border border-alto bg-lighter-gray h5">
                  <i className="fa fa-info-circle text-medium-gray" />
                  <span className="ml1 text-medium-gray">
                    Customizing the Update Schedule is a Pro Feature. <a className="text-blue" href={`/company/${company.id}/billing`}>Upgrade here</a> to unlock he custom update schedule and more!
                  </span>
                </div>
              </div>
            }
            <div className="flex flex-column">
              <div className="flex">
                <div className="pr1">
                  <Circle
                    content="1"
                    border="light-gray"
                    text="light-gray"
                  />
                </div>
                <div className="flex flex-column">
                  <span className="text-gray fw400">Set Company Update Frequency</span>
                  <span className="text-light-gray light mb1 h5 lh-12">Choose yours companies update frequency. Note that there is only one scheduled update per company. The contents of the last scheduled update will be copied to a new one according to this setting.</span>
                  <div className="flex items-center flex-justify-between mt1">
                    <span className="light">Frequency</span>
                    <div className="col-6">
                      <ReactSelect
                        value={scheduleTypeOptions.filter(option => option.value === scheduleType)}
                        options={scheduleTypeOptions}
                        onChange={selectedOption => this.setState({ scheduleType: selectedOption.value })}
                        styles={defaultSelectStyles}
                        theme={defaultSelectTheme}
                        isClearable={false}
                        multi={false}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex my3">
                <div className="pr1">
                  <Circle
                    content="2"
                    border="light-gray"
                    text="light-gray"
                  />
                </div>
                <div className="flex flex-column col-12">
                  <span className="text-gray fw400">
                    Personal Reminder Option {!company.subscribed && <span className="text-orange">(pro NOT required to change this setting)</span>}
                  </span>
                  <span className="text-light-gray light mb1 h5 lh-12">Do you want to be reminded that the update has been generated, is due soon and overdue?</span>
                  <div className="flex items-center flex-justify-between mt1">
                    <span className="light">Send me Scheduled Update Reminders</span>
                    <Switch
                      defaultChecked={allowUpdateReminders}
                      onChange={() => this.setState({ allowUpdateReminders: !allowUpdateReminders })}
                    />
                  </div>
                </div>
              </div>

              <div className="flex">
                <div className="pr1">
                  <Circle
                    content="3"
                    border="light-gray"
                    text="light-gray"
                  />
                </div>
                <div className="flex flex-column">
                  <span className="text-gray fw400">Preview Update Schedule</span>
                  <span className="text-light-gray light mb1 h5 lh-12">Below you can preview when the next update will be made available and how the schedule will work.</span>

                  <div className="flex items-center mt1">
                    <div className="flex flex-column flex flex-justify-left relative col-6">
                      <div className="lh-12">
                        <span className="text-white fw400 h5 px1 pb1 border border-orange rounded-top-right rounded-top-left bg-orange">
                          {this.scheduleTypeText()}
                        </span>
                      </div>
                      <div className="col-12 bg-white border border-orange rounded p2 text-gray">
                        <div className="h1 fw400 lh-1 pb2 border-bottom border-orange">
                          <div className="flex flex-column">
                            <span className="mb1 nowrap">
                              {next_available_updates_info[scheduleType].update_info}
                            </span>
                            Update
                          </div>
                        </div>
                        <div className="flex">
                          <div className="mt1">
                            <HtmlComponent
                              data={{ html: next_available_updates_info[scheduleType].availability }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ml2 col-6">
                      <div className="mt2">
                        <span className="text-gray bold">Update Schedule</span>
                        <HtmlComponent
                          data={{ html: `${schedule_infos[scheduleType]} ${allowUpdateReminders ? reminder_infos[scheduleType] : ''}`, css_class: 'mt1 light text-gray h5 lh-12' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-justify-end items-center p2 border-top border-lighter-gray">
            {showBackButton &&
              <div
                className="h5 text-blue mr3 cursor-pointer"
                onClick={this.closeModal}
              >
                Back
              </div>
            }
            {company.subscribed ?
              <div
                className="h5 text-white bold px2 py1 bg-blue rounded cursor-pointer"
                onClick={this.handleSave}
              >
                Save Schedule
              </div>
              :
              <div
                className="h5 text-white bold px2 py1 bg-alto rounded"
                data-balloon="You can preview the schedule settings here but you need to upgrade to a paid account to save/change your schedule."
                data-balloon-pos="up"
                data-balloon-length="large"
              >
                Save Schedule
              </div>
            }
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default SetupUpdateSchedule;
