import React, { Component } from 'react';
import PropTypes from 'prop-types';


class EditButton extends Component {
  static propTypes = {
    section: PropTypes.object.isRequired,
    updateKpi: PropTypes.func.isRequired,
  }

  editSection = () => {
    const { section, updateKpi } = this.props;
    const params = { id: section.id, kpi: { id: null } };
    updateKpi(section.delete_kpi_id_url, params, section.kpi.kpi_charts[0].kpi_chart_url);
  }

  render() {
    return (
      <div onClick={() => this.editSection()} className="ml2">
        <span className="cursor-pointer">Edit</span>
      </div>
    );
  }
}

export default EditButton;
