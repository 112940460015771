import axios from 'axios';

import { buildChartQuery } from '../utils/homeAndDashboard';

import {
  setKpisTimeAgo,
  addFund,
  removeFund,
  updateChart,
  updateTable,
  cardsRemainingData,
  addUpdatesData,
  addDealRoomsData,
  addDocumentsData,
} from '../../actions/investor/investorHomeActions';

const setTimeAgo = (timeAgo, frequency) =>
  async (dispatch) => {
    dispatch(setKpisTimeAgo(timeAgo, frequency));
  };

const createFund = (params) =>
  async (dispatch, getState) => {
    const res = await axios.post(`/investor/${getState().investorHome.investor_id}/home/funds`, params);
    dispatch(addFund(res.data));
  };

const deleteFund = (fundId) =>
  async (dispatch, getState) => {
    const res = await axios.delete(`/investor/${getState().investorHome.investor_id}/home/funds/${fundId}`);
    dispatch(removeFund(res.data));
  };

const getChartData = (type, options = { filterType: null, kpiMetric: null, timeFrame: null, frequency: null, kpiType: null }) =>
  async (dispatch, getState) => {
    const investorId = getState().investorHome.investor_id;
    let url = `/investor/${investorId}/home/chart_data?type=${type}&&home=true`;

    let chartData = null;

    // // The query should be built if it's not a dashboard KPI or if the KPI inside the widget changed
    if (options.kpiType !== 'dashboard_kpi' || options.filterType) {
      url = buildChartQuery(url, options);
      const res = await axios.get(url);
      chartData = res.data;
    }

    dispatch(updateChart(chartData, type, options));
  };

const getDashboardData = (type, options = { timeAgo: null, frequency: null, lazyLoadingLayer: 'basic', kpiMetric: null, kpiType: null }) =>
  async (dispatch, getState) => {
    const investorId = getState().investorHome.investor_id;
    let url = `/investor/${investorId}/home/dashboard_data?lazy_loading_layer=${options.lazyLoadingLayer}&type=${type}&home=true`;

    if (options.timeAgo !== null) {
      url = `${url}&time_ago=${options.timeAgo}`;
    }

    // frequency is getting duplicated when kpiType is different than dashboard_kpi
    if (options.frequency !== null) {
      url = `${url}&frequency=${options.frequency}`;
    }

    if (options.kpiType !== 'dashboard_kpi') {
      url = buildChartQuery(url, options);
    }

    const res = await axios.get(url);
    dispatch(updateTable(res.data, type, options.lazyLoadingLayer));
  };

const getCardsData = () =>
  async (dispatch, getState) => {
    const investorId = getState().investorHome.investor_id;
    const url = `/investor/${investorId}/home/card_data`;

    const res = await axios.get(url);
    dispatch(cardsRemainingData(res.data));
  };

const getUpdatesData = () =>
  async (dispatch, getState) => {
    const investorId = getState().investorHome.investor_id;
    const url = `/investor/${investorId}/home/updates_data`;

    const res = await axios.get(url);
    dispatch(addUpdatesData(res.data));
  };

const getDealRoomsData = () =>
  async (dispatch, getState) => {
    const investorId = getState().investorHome.investor_id;
    const url = `/investor/${investorId}/home/deal_rooms_data`;

    const res = await axios.get(url);
    dispatch(addDealRoomsData(res.data));
  };

const getDocumentsData = () =>
  async (dispatch, getState) => {
    const investorId = getState().investorHome.investor_id;
    const url = `/investor/${investorId}/home/documents_data`;

    const res = await axios.get(url);
    dispatch(addDocumentsData(res.data));
  };

const updateChartData = (type, options = { filterType: null, kpiMetric: null }) =>
  async dispatch => {
    dispatch(updateChart(null, type, options));
  };

export {
  setTimeAgo,
  createFund,
  deleteFund,
  getChartData,
  getDashboardData,
  getCardsData,
  getUpdatesData,
  getDealRoomsData,
  getDocumentsData,
  updateChartData,
};
