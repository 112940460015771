const capTable = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_CAP_TABLE':
      return {
        ...state,
        header: action.capTable.header || state.header,
        data: action.capTable ? action.capTable.data : state.data,
      };

    default: return state;
  }
};

export default capTable;
