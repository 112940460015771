import Highcharts from 'highcharts';
import numberFormat from '../user_number_format';

export default function applyTheme() {
  const legend = () => (
    {
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      floating: false,
    }
  );

  Highcharts.theme = {
    lang: numberFormat().getNumberFormatKpiChart(),
    chart: {
      style: {
        fontFamily: '\'lato\', sans-serif',
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
      },
    },
    title: {
      align: 'left',
      margin: 35,
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#555',
      },
    },
    xAxis: {
      lineColor: '#DFE2E5',
      labels: {
        style: {
          color: '#7F8FA4',
        },
      },
    },
    yAxis: {
      gridLineColor: '#DFE2E5',
      labels: {
        style: {
          color: '#7F8FA4',
        },
        formatter: function formatter() {
          const label = this.axis.defaultLabelFormatter.call(this);
          if (/^[0-9]{4}$/.test(label)) {
            return Highcharts.numberFormat(this.value, 0, numberFormat().decimalPoint, numberFormat().thousandsSep);
          }
          return label;
        },
      },
    },
    plotOptions: {
      series: {
        fillOpacity: 0.5,
        pointPadding: 0,
        borderWidth: 0,
        animation: false,
        lineWidth: 1,
        marker: {
          enabled: true,
          fillColor: '#FFFFFF',
          lineWidth: 2,
          lineColor: null,
          symbol: 'circle',
          radius: 3,
          states: {
            hover: {
              radius: 4,
            },
          },
        },
        dataLabels: {
          allowOverlap: true,
        },
      },
      column: {
        shadow: false,
        borderWidth: -1,
      },
    },
    legend: legend(),
    credits: {
      enabled: false,
    },
  };

  // Apply the theme
  Highcharts.setOptions(Highcharts.theme);
}
