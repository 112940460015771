import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Color from 'color';

import TextInput from '../../../TextInput';
import Tabs from './Tabs';
import SubTabs from './SubTabs';
import CompanyInfo from './CompanyInfo';
import Kpis from './Kpis';
import Start from './Start';
import InvestmentInfo from './InvestmentInfo';
import Updates from './Updates';
import CoInvestors from './CoInvestors';
import Avatar from '../../../tab_view/Avatar';
import FilterBox from './FilterBox';
import DataRooms from './DataRooms';
import Reports from './Reports/index';

import { search } from '../../../utils/fundDashboardFormulas';
import companyFiltersOptions from './utils/companyFiltersOptions';
import UpgradeAccountModal from '../../../modals/UpgradeAccountModal';
import ModalWithDatePicker from '../../../modals/ModalWithDatePicker';

class Dashboard extends Component {
  static propTypes = {
    dashboard: PropTypes.object.isRequired,
    setTabAndResetSorting: PropTypes.func.isRequired,
    setTimeAgo: PropTypes.func.isRequired,
    setLoadingTab: PropTypes.func.isRequired,
    getKpisData: PropTypes.func.isRequired,
    getDataRoomInfoData: PropTypes.func.isRequired,
    getFactSheetTabData: PropTypes.func.isRequired,
    getExcelExportTabData: PropTypes.func.isRequired,
    getSlideshowTabData: PropTypes.func.isRequired,
    getCompanyInfoData: PropTypes.func.isRequired,
    getInvestmentInfoData: PropTypes.func.isRequired,
    getRecentUpdatesData: PropTypes.func.isRequired,
    getPortfolioUpdatesData: PropTypes.func.isRequired,
    getCoInvestorsData: PropTypes.func.isRequired,
    updateChartData: PropTypes.func.isRequired,
    changeCompanyFilters: PropTypes.func.isRequired,
    setWidgetChartFilter: PropTypes.func.isRequired,
    loadNewExportFile: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    const { dashboard } = this.props;

    this.state = {
      searchQuery: '',
      tabs: [
        { id: 'start', text: 'Start' },
        { id: 'company_info', text: 'Companies' },
        { id: 'investment_info', text: 'Investments' },
        { id: 'kpis', text: 'KPIs' },
        { id: 'recent_updates', text: 'Updates' },
        { id: 'portfolio_updates', text: 'Portfolio updates' },
        { id: 'co_investors', text: 'Co-Investors' },
      ],
      companyFilters: companyFiltersOptions,
      showUpgradeAccountModal: !dashboard.configs.show_investments,
      invitePartnersWarning: false,
      updateExportForUpgradeModal: false,
    };
  }

  componentDidMount() {
    this.lazyLoading();
  }

  lazyLoading = () => {
    const {
      dashboard,
      getKpisData,
      getCompanyInfoData,
      getInvestmentInfoData,
      getRecentUpdatesData,
      getPortfolioUpdatesData,
      getCoInvestorsData,
    } = this.props;

    if (dashboard.company_info) {
      (async () => {
        // Company Info
        await getCompanyInfoData();
        await getCompanyInfoData('remaining');
      })();
    }

    if (dashboard.investment_info) {
      (async () => {
        // Investment Info
        await getInvestmentInfoData();
        await getInvestmentInfoData('remaining');
      })();
    }

    // KPIs
    if (dashboard.kpis) {
      getKpisData();
    }

    // // Recent Updates & Portfolio Updates
    if (dashboard.portfolio_updates) {
      getRecentUpdatesData();
      getPortfolioUpdatesData();
    }

    // Co Investors
    if (dashboard.co_investors) {
      getCoInvestorsData();
    }
  }

  timeFrameChangeForKpisTab = async (timeAgo, frequency, options = {}) => {
    const { setLoadingTab, setTimeAgo, getKpisData } = this.props;

    try {
      await setLoadingTab('kpis');
      await setTimeAgo(timeAgo, frequency);
      await getKpisData(options);
      await setLoadingTab();
    } catch {
      App.State.setFlash({ name: 'alert', msg: 'Sorry. There was an error when getting KPIs data.' });
    }
  };

  arrayCompare = (arr1, arr2) => {
    if (!arr1 || !arr2) return false;

    for (let index = 0; index < arr1.length; index += 1) {
      if (!arr2.includes(arr1[index])) {
        return false;
      }
    }
    return true;
  }

  handleChangeFilteringCriteria = newFilter => {
    const { trackEvent } = this.props;
    const { companyFilters } = this.state;
    const {
      dashboard,
      changeCompanyFilters,
    } = this.props;

    trackEvent('dashboard_events', 'top filter');

    const newFilters = companyFilters.map(filter => {
      if (filter.name === newFilter.name) {
        if (filter.multiple) {
          if (newFilter.filter === 'all' || (filter.filter !== 'all' && Array.isArray(filter.filter) && filter.filter.length === 1 && filter.filter.includes(newFilter.filter))) return { ...filter, filter: 'all' };
          if (filter.filter === 'all' && newFilter.filter !== 'all') return { ...filter, filter: [newFilter.filter] };
          if (filter.filter !== 'all' && Array.isArray(filter.filter) && filter.filter.length > 0 && filter.filter.includes(newFilter.filter)) {
            filter.filter.splice(filter.filter.indexOf(newFilter.filter), 1);
            return { ...filter, filter: filter.filter };
          }
          if (filter.filter !== 'all' && Array.isArray(filter.filter) && filter.filter.length > 0 && !filter.filter.includes(newFilter.filter)) return { ...filter, filter: [...filter.filter, newFilter.filter] };
          return { ...filter, filter: 'all' };
        }
        return { ...filter, filter: newFilter.filter };
      }
      return filter;
    });
    // de-select filtered-out companies
    const companies = dashboard.filterOptions.investment_options;
    const selection = companies.map(company => {
      const selected = newFilters.reduce((allValid, filter) => {
        const currentValid = filter.filter === 'all'
          || (filter.multiple && Array.isArray(company[filter.name]) && this.arrayCompare(company[filter.name], filter.filter))
          || (filter.multiple && !Array.isArray(company[filter.name]) && filter.filter.includes(company[filter.name]))
          || (!filter.multiple && company[filter.name] === filter.filter)
          || (!filter.mutliple && Array.isArray(company[filter.name]) && company[filter.name].includes(filter.filter));
        return allValid && currentValid;
      }, true);
      return { id: company.id, selected };
    });
    changeCompanyFilters(selection);

    this.setState({
      companyFilters: newFilters,
    });
  };

  renderSearchBarAndTabs = () => {
    const { dashboard, setTabAndResetSorting } = this.props;
    const { showPortfolioReportModal } = this.state;

    return (
      <div className="flex col-12 sm-flex-column">
        <div className="flex flex-auto">
          <i className="fa fa-search p2 ml2 text-light-gray" />
          <TextInput
            className="mt05 col-12 border-none"
            placeholder="Search Companies"
            onChange={(e) => { this.setState({ searchQuery: e.target.value }); }}
          />
        </div>
        {(dashboard.configs.activeTab === 'recent_updates' || dashboard.configs.activeTab === 'portfolio_updates') &&
          <div className="flex flex-center mr2">
            <div
              className="px1 h6 bg-black--transparent text-white cursor-pointer rounded"
              onClick={dashboard.configs.can_export ? () => this.setState({ showPortfolioReportModal: true }) : () => this.setState({ showUpgradeAccountModal: true, updateExportForUpgradeModal: true })}
            >
              <i className="fa fa-share-square mr1" />Export Updates
            </div>
            <ModalWithDatePicker
              show={showPortfolioReportModal}
              onConfirm={(params) => { window.location = `${dashboard.portfolio_updates.export_url}?${params}`; this.setState({ showPortfolioReportModal: false }); }}
              onClose={() => this.setState({ showPortfolioReportModal: false })}
              buttonName="Export"
            />
          </div>
        }
        {
          dashboard.configs.activeTab === 'kpis' || dashboard.configs.activeTab === 'recent_updates' || dashboard.configs.activeTab === 'portfolio_updates' ?
            <SubTabs
              tab={dashboard.configs.activeTab}
              activeTab={dashboard.configs.activeTab}
              angel={dashboard.configs.angel}
              kpiTimeManage={dashboard.tabProps.time_manage}
              setTabAndResetSorting={setTabAndResetSorting}
            /> : null
        }
      </div>
    );
  }

  handleTabChange = async (tab) => {
    const { trackEvent, setTabAndResetSorting, dashboard, getDataRoomInfoData } = this.props;

    await setTabAndResetSorting(tab);
    trackEvent('dashboard_events', `${tab}_tab_select`);

    if (tab === 'data_rooms') getDataRoomInfoData(dashboard.configs.dashboard_type, dashboard.configs.fundId);

    if (!dashboard.configs.show_investments) {
      if (tab !== 'recent_updates' && tab !== 'portfolio_updates' && tab !== 'company_info') {
        if (tab === 'data_rooms' && dashboard.configs.dashboard_type === 'all_investments') return;

        this.setState({ showUpgradeAccountModal: true });
      }
    }
  }

  render() {
    const {
      dashboard,
      getFactSheetTabData,
      getExcelExportTabData,
      getSlideshowTabData,
      updateChartData,
      setWidgetChartFilter,
      loadNewExportFile,
      trackEvent,
      getKpisData,
      setLoadingTab,
    } = this.props;

    const { searchQuery, companyFilters, showUpgradeAccountModal, invitePartnersWarning, updateExportForUpgradeModal, tabs } = this.state;

    let color = '';
    if (dashboard.configs.color_theme) {
      if (Color(dashboard.configs.color_theme).white() <= 80) {
        color = dashboard.configs.color_theme;
      } else {
        color = window.ColorVariables.colorBlue;
      }
    }

    const paywallDescription = () => {
      if (invitePartnersWarning) return 'Paywall_New_Member';
      if (updateExportForUpgradeModal) return 'Paywall_Export';
      return 'Paywall_Portfolio';
    };

    return (
      <div className="container container--report lg-px2 flex flex-column">
        <div className="flex my2">
          <span className="h2 fw400">Advanced Portfolio Dashboard</span>
          {/* {dashboard.configs.plan_type === 'trialing' &&
            <div className="ml1">&mdash; Limited-time Trial</div>
          } */}
          {/* {dashboard.configs.plan_type === 'free' &&
            <div className="ml1">&mdash; Free-trial with up to {dashboard.configs.max_investments} companies</div>
          } */}
        </div>
        <div className="flex mt2 flex-justify-between">
          <div className="flex items-center">
            <Avatar avatarUrl={dashboard.configs.fundLogo} size={36} shadow />
            <div className="flex flex-column ml2">
              <div className="mt-auto h3 fw400">{dashboard.configs.fundName}</div>
              <a href="/settings">
                <div className="mb-auto h6 text-light-gray mt05 cursor-pointer lh-12">
                  {dashboard.configs.currency} &#183; Number Format {dashboard.configs.number_format}
                </div>
              </a>
            </div>
          </div>
          {!dashboard.configs.guest &&
            <div className="flex flex-column flex-end">
              <span className="h6 text-light-gray right-align">Partners</span>
              <div className="flex">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => (dashboard.configs.can_invite_partners ? $('[data-behavior="modal"][data-target="#modal-invite"]').click() : this.setState({ showUpgradeAccountModal: true, invitePartnersWarning: true }))}
                >
                  <span className="mr1 text-light-gray fw400 h5">Invite Partner</span>
                  <i className="fa fa-plus-circle text-light-gray" style={{ fontSize: '25px' }} />
                </div>
                {dashboard.partners.length > 0 &&
                  <a href={`/investor/${dashboard.configs.investorId}/memberships/`} className="flex flex-row-reversed mx1">
                    {
                      dashboard.partners.slice(0, 6).map(user => (<Avatar key={user.id} avatarUrl={user.avatar} tooltip={user.name} overlaped />))
                    }
                    {(dashboard.partners.length > 6) ?
                      <Avatar avatarUrl="" addition={dashboard.partners.length - 6} />
                      :
                      null
                    }
                  </a>
                }
              </div>
            </div>
          }
        </div>
        <div className="flex mt2 sm-hide">
          <section className="flex flex-row">
            <FilterBox
              options={dashboard.filterOptions}
              handleChangeFilteringCriteria={this.handleChangeFilteringCriteria}
              selectedFilters={companyFilters}
            />
          </section>
        </div>
        <div className="flex flex-column mb4">
          <Tabs
            dashboardTabs={dashboard.dashboard_tabs}
            activeTab={dashboard.configs.activeTab}
            setTabAndResetSorting={this.handleTabChange}
            timeFrameChangeForKpisTab={(frequency) => this.timeFrameChangeForKpisTab(dashboard.tabProps.time_manage.time_ago, frequency, { kpiMetric: dashboard.kpis.chart_data.chosen_kpi, kpiType: dashboard.kpis.chart_data.chosen_kpi_type })}
            color={color}
            frequency={dashboard.tabProps.time_manage.frequency}
            getKpisData={async () => { setLoadingTab(tabs[3].id); await getKpisData({ kpiMetric: dashboard.kpis.chart_data.chosen_kpi, kpiType: dashboard.kpis.chart_data.chosen_kpi_type }); setLoadingTab(); }}
            showInvestments={dashboard.configs.show_investments}
          />
          {
            dashboard.configs.activeTab === 'start' &&
            <Start
              widgets={dashboard.widgets}
              investorId={dashboard.configs.investorId}
              setChartFilter={setWidgetChartFilter}
              colorTheme={color}
              blurred={!dashboard.configs.show_investments}
            />
          }
          {dashboard.configs.activeTab === 'company_info' &&
            <CompanyInfo
              show
              data={search(dashboard, searchQuery, dashboard.company_info.data)}
              chartData={dashboard.company_info.chart_data}
              getChartData={(filterType) => updateChartData('company_info', { filterType })}
              loading={dashboard.company_info.loading}
              investorId={dashboard.configs.investorId}
              colorTheme={color}
              exportDataURL={dashboard.company_info.export_url}
              blurred={!dashboard.configs.show_investments}
              canExport={dashboard.configs.can_export}
            >
              {this.renderSearchBarAndTabs()}
            </CompanyInfo>
          }
          {dashboard.configs.activeTab === 'investment_info' &&
            <InvestmentInfo
              show
              data={search(dashboard, searchQuery, dashboard.investment_info.data)}
              chartData={dashboard.investment_info.chart_data}
              totalsInfo={{ currency: dashboard.configs.currency, ratesUpdatedAt: dashboard.configs.rates_updated_at }}
              totalValues={dashboard.investment_info.total_values}
              loading={dashboard.investment_info.loading}
              reavealSourceOption={dashboard.tabProps.revealSource}
              investorId={dashboard.configs.investorId}
              colorTheme={color}
              exportDataURL={dashboard.investment_info.export_url}
              showIrr={dashboard.investment_info.show_irr}
              showValuesInDisplayCurrency={dashboard.configs.show_values_in_display_currency}
              blurred={!dashboard.configs.show_investments}
              canExport={dashboard.configs.can_export}
            >
              {this.renderSearchBarAndTabs()}
            </InvestmentInfo>
          }
          {dashboard.configs.activeTab === 'kpis' &&
            <Kpis
              show
              data={search(dashboard, searchQuery, dashboard.kpis.data)}
              dashboardKpis={dashboard.kpis.dashboard_kpis}
              chartData={dashboard.kpis.chart_data}
              getChartData={(kpiMetric, timeFrame, kpiType) => updateChartData('kpis_info', { kpiMetric, timeFrame, kpiType })}
              onTimeFrameChange={(timeAgo, options) => this.timeFrameChangeForKpisTab(timeAgo, dashboard.tabProps.time_manage.frequency, options)}
              reavealSourceOption={dashboard.tabProps.revealSource}
              showUpdated={dashboard.tabProps.time_manage.time_ago === -1}
              frequency={dashboard.tabProps.time_manage.frequency}
              totalSumValues={dashboard.kpis.total_sum_values}
              totalMedianValues={dashboard.kpis.total_median_values}
              totalsInfo={{ currency: dashboard.configs.currency, ratesUpdatedAt: dashboard.configs.rates_updated_at }}
              loading={dashboard.kpis.loading || dashboard.loadingTab === 'kpis'}
              investorId={dashboard.configs.investorId}
              colorTheme={color}
              exportDataURL={dashboard.kpis.export_url}
              blurred={!dashboard.configs.show_investments}
              canExport={dashboard.configs.can_export}
            >
              {this.renderSearchBarAndTabs()}
            </Kpis>
          }
          {dashboard.configs.activeTab === 'data_rooms' &&
            <DataRooms
              show
              loading={dashboard.data_rooms.loading}
              data={dashboard.data_rooms.data}
              dashboardType={dashboard.configs.dashboard_type}
            />
          }
          {
            dashboard.configs.activeTab === 'recent_updates' &&
            <Updates
              investorId={dashboard.configs.investorId}
              updates={dashboard.recent_updates.data}
              groups={dashboard.recent_updates.groups}
              activeTab={dashboard.configs.activeTab}
              searchQuery={searchQuery}
              loading={dashboard.recent_updates.loading}
            >
              {this.renderSearchBarAndTabs()}
            </Updates>
          }
          {
            dashboard.configs.activeTab === 'portfolio_updates' &&
            <Updates
              updates={dashboard.portfolio_updates.data}
              groups={dashboard.portfolio_updates.groups}
              activeTab={dashboard.configs.activeTab}
              searchQuery={searchQuery}
              loading={dashboard.portfolio_updates.loading}
            >
              {this.renderSearchBarAndTabs()}
            </Updates>
          }
          {
            dashboard.configs.activeTab === 'co_investors' &&
            <CoInvestors
              data={dashboard.co_investors}
              loading={dashboard.co_investors.loading}
              blurred={!dashboard.configs.show_investments}
            />
          }
          { dashboard.configs.activeTab === 'reports' &&
            <Reports
              investorId={dashboard.configs.investorId}
              fundId={dashboard.configs.fundId}
              reports={dashboard.reports}
              getFactSheetTabData={getFactSheetTabData}
              getExcelExportTabData={getExcelExportTabData}
              getSlideshowTabData={getSlideshowTabData}
              loadNewExportFile={loadNewExportFile}
              trackEvent={(reportTab) => trackEvent('dashboard_reports_events', reportTab)}
              blurred={!dashboard.configs.can_export}
            />
          }
          <UpgradeAccountModal
            show={showUpgradeAccountModal}
            closeModal={() => this.setState({ showUpgradeAccountModal: false, invitePartnersWarning: false, updateExportForUpgradeModal: false })}
            dashboard={!invitePartnersWarning}
            dashboardType={dashboard.configs.dashboard_type}
            exportFeature={updateExportForUpgradeModal}
            maxInvestments={dashboard.configs.max_investments}
            paywallDescription={showUpgradeAccountModal ? paywallDescription() : null}
            sendEmailOnClick={updateExportForUpgradeModal}
          />
        </div>
      </div>
    );
  }
}

export default Dashboard;
