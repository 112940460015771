import React from 'react';
import createReactClass from 'create-react-class';
import ChatMixin from './ChatMixin';

export default createReactClass({
  displayName: 'ChatDesktop',
  mixins: [ChatMixin],

  componentDidMount() {
    setTimeout(this.scrollDownMessages, 50);
  },

  scrollDownMessages: () => {
    $('.comment__list').scrollTop($('.comment__list-container').height());
  },

  render() {
    return (
        <div>
          <div className="mxn2">
            <div className="col-4 sm-col-12 left px2 sm-mb3">
              <div className="conversation__list bg-white border border-alto">
                {this.conversations()}
              </div>
            </div>

            <div className="col-8 sm-col-12 left px2">
              {this.moreMessages()}

              <div className="comment__list bg-white border border-alto overflow-y-auto">
                <div className="comment__list-container">
                  {this.messages()}
                </div>
              </div>

              <div className="conversation__submit bottom-50 clearfix fixed lg-static lg-col-12 w-570">
                <div className="mb2">
                  <textarea
                    className="border border-alto col-12 h6 p2 resize-none"
                    style={{ height: '100px' }}
                    value={this.state.msg}
                    onChange={this.setMsg}
                    onKeyPress={this.handleKeyPress}
                    placeholder="Write something"
                  />
                </div>

                <i className="fa fa-pencil absolute mr2 mt2 right-0 text-light-gray top-0"></i>
                <input className="bg-blue border-none height-30 h5 px3 rounded right text-white cursor-pointer" type="submit" value="Post" onClick={this.submitMessage}/>
              </div>
            </div>
          </div>
        </div>
    );
  },
});
