import React from 'react';
import { connect } from 'react-redux';

import sort from './sorter';


function withSorting(WrappedComponent, selectData) {
  const mapStateToProps = state => ({
    sorting: state.sorting,
  });

  const WrappingComponent = ({ sorting, ...props }) => {
    const data = selectData(props);
    const sortedData = sort(data, sorting);

    return <WrappedComponent sortedData={sortedData} {...props} />;
  };

  return connect(mapStateToProps)(WrappingComponent);
}

export default withSorting;
