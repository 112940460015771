import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CheckBox from '../../CheckBox';


class InvestorCustomReminderInvestmentCompletion extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    items: PropTypes.object.isRequired,
  }

  investmentSelected = (investmentId) => {
    const { items } = this.props;

    return items.selected.find(investment => investment.id === investmentId);
  }

  addRemoveInvestmentFromReminder = (investmentId) => {
    if (this.investmentSelected(investmentId)) {
      store.dispatch({ type: 'REMOVE_ITEM', list: 'selected', id: investmentId });
    } else {
      store.dispatch({ type: 'ADD_ITEM', list: 'selected', value: { id: investmentId } });
    }
  }

  render() {
    const { data } = this.props;

    return (
      <div className="col-12 flex flex-center p2 sm-px0 fw400 border-bottom border-gallery">
        <div className="width-30p">
          <div className="ml1 text-gray flex flex-center">
            <span
              className="block col-3 bg-center bg-cover bg-no-repeat circle header__avatar--dash mr1"
              style={{ backgroundImage: `url( ${data.avatar} )` }}
            />
            {data.real_company &&
              <div className="relative">
                <span className="real_company__notification" data-behavior="hide-bubble">
                  <i className="fa fa-check-circle text-green" />
                </span>
              </div>
            }
            <div className="col-7 truncate">
              <a className="text-gray" href={`investments/${data.id}`}>{data.name}</a>
            </div>
          </div>
        </div>
        <div className="width-20p text-light-gray center ">
          {data.cap_tables_permission ?
            data.date_of_latest_cap_table_data || '—'
            :
            'No Access.'
          }
        </div>
        <div className="width-20p text-light-gray center">
          {data.cap_tables_permission ?
            data.date_of_latest_update_received || (data.custom_investment ? 'N/A' : '—')
            :
            'No Access.'
          }
        </div>
        <div className="width-20p text-light-gray center">
          {data.kpis_permission ?
            data.date_of_latest_kpi_data || '—'
            :
            'No Access.'
          }
        </div>
        <div className="width-10p text-light-gray center ">
          {data.kpis_permission ?
            data.kpis_completion ? `${data.kpis_completion}%` : '—'
            :
            'No Access.'
          }
        </div>
        <div className="width-20p text-light-gray center">
          {data.last_reminder_sent ?
            data.conversation_link ?
              <a href={`${data.conversation_link}`}>
                {data.last_reminder_sent}
              </a>
              :
              <div>
                {data.last_reminder_sent}
              </div>
            :
            '—'
          }
        </div>
        <div className="width-20p text-light-gray center">
          {data.company_setting}
        </div>
        <div className="width-20p center flex flex-justify-center">
          <CheckBox
            manualCheck
            checked={!!this.investmentSelected(data.id)}
            onChange={() => this.addRemoveInvestmentFromReminder(data.id)}
            fixedWidth
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => (
  {
    items: store.get('items').toJS(),
  }
);

export default connect(mapStateToProps)(InvestorCustomReminderInvestmentCompletion);
