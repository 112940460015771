import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Switch from '../../../Switch';
import TabView from '../../../tab_view/TabView';
import LoadingSpinner from '../../../LoadingSpinner';

class DataRooms extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    show: PropTypes.bool,
    data: PropTypes.object,
    dashboardType: PropTypes.string.isRequired,
  }

  static defaultProps = {
    loading: false,
    show: false,
    data: null,
  }

  handle = () => {

  }

  render() {
    const { loading, show, data, dashboardType } = this.props;
    return (show ?
      <React.Fragment>
        {!loading ?
          <div className="flex flex-column flex-wrap">
            <div className="col-12 flex flex-justify-end items-center py1 sm-flex-column">
              {data.isInvestorAdmin &&
              <div className="flex flex-justify-end sm-block text-light-gray h5">
                <Switch
                  labelLeft="Notify me of company changes (weekly):"
                  defaultChecked={data.defaultChecked}
                  toggleNotificationsUrl={data.toggleNotificationsUrl}
                />
              </div>
              }
            </div>
            <TabView
              searchEnabled
              tabs={dashboardType === 'all_investments' ? ['Company', 'Investor'] : ['Fund', 'Company', 'Investor']}
              activeTab={dashboardType === 'all_investments' ? 'Company' : 'Fund'}
              items={data.items}
            />
          </div> :
          <LoadingSpinner
            show={loading}
            type="fit"
            height="300px"
            background="transparent"
          />
        }

      </React.Fragment> : null
    );
  }
}

export default DataRooms;
