import React, { Component } from 'react';
import PropTypes from 'prop-types';

import InvestorUpdateGroup from '../../../tab_view/InvestorUpdateGroup';
import InvestorUpdate from './updates/InvestorUpdate';
import UpdatesByCompany from './updates/UpdatesByCompany';
import LoadingSpinner from '../../../LoadingSpinner';


class Updates extends Component {
  components = {
    InvestorUpdate,
    InvestorUpdateGroup,
    UpdatesByCompany,
  }

  static propTypes = {
    updates: PropTypes.array.isRequired,
    groups: PropTypes.array.isRequired,
    activeTab: PropTypes.string.isRequired,
    searchQuery: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    children: PropTypes.node,
    investorId: PropTypes.number.isRequired,
  }

  static defaultProps = {
    searchQuery: '',
    children: null,
  }

  portfolioSearch = (items) => {
    const { searchQuery } = this.props;

    const includes = (value) => value.toLowerCase().includes(searchQuery.toLowerCase());
    const filter = (updates) => updates.filter(update => update.name && includes(update.name));

    const updateFilter = items.filter(item => item.updates && filter(item.updates).length > 0);
    const companyFilter = items.filter(item => item.company && item.company.name && includes(item.company.name));

    const merged = updateFilter;

    companyFilter.forEach(el => {
      if (merged.indexOf(el) === -1) {
        merged.push(el);
      }
    });

    return merged;
  }

  search = (items) => {
    const { searchQuery } = this.props;

    const includes = (value) => value.toLowerCase().includes(searchQuery.toLowerCase());

    const updateFilter = items.filter(item => item.name && includes(item.name));
    const companyFilter = items.filter(item => item.company_name && includes(item.company_name));

    const merged = updateFilter;

    companyFilter.forEach(el => {
      if (merged.indexOf(el) === -1) {
        merged.push(el);
      }
    });

    return merged;
  }

  render() {
    const {
      updates,
      groups,
      activeTab,
      searchQuery,
      loading,
      children,
      investorId,
    } = this.props;

    const updatesWithGroups = updates.concat(groups).sort((a, b) => ((a.sorter < b.sorter) ? -1 : ((a.sorter > b.sorter) ? 1 : 0)));

    return (
      <React.Fragment>
        <div className="col-12 flex flex-column mt1 bg-white bs-around">
          <div className={`col-12 ${children ? 'border-wild-sand border-top' : ''}`}>
            <div className="col-12">
              {/* Renders the search bar and Recent/Portfolio tabs */}
              {children}

              <div className="col-12 overflow-y-auto xl-max-height x-max-height lg-max-height">
                {!loading ?
                  updates.length > 0 ?
                    activeTab === 'recent_updates' ?
                      (searchQuery ? this.search(updatesWithGroups) : updatesWithGroups).map((item) => {
                        const ItemComponent = item['group?'] ? InvestorUpdateGroup : InvestorUpdate;

                        return (
                          <ItemComponent
                            investorId={investorId}
                            key={`update_${item.id}_${item.display}`}
                            data={item}
                            activeTab={activeTab}
                          />
                        );
                      })
                      :
                      (searchQuery ? this.portfolioSearch(updatesWithGroups) : updatesWithGroups).map(item =>
                        (item['group?'] ?
                          <InvestorUpdateGroup
                            investorId={investorId}
                            key={`update_${item.display}`}
                            data={item}
                            activeTab={activeTab}
                          />
                          :
                          <UpdatesByCompany
                            invId={investorId}
                            key={`company_${item.company.id}_${item.sorter}`}
                            company={item.company}
                            updates={item.updates}
                            canRequestUpdate={item.can_request_update}
                          />
                        ))
                    :
                    (
                      <div className="px2 pt2 bg-white center py3">
                        <span className="text-medium-gray mx-auto h5">
                          No updates available...
                        </span>
                      </div>
                    )
                  :
                  (<LoadingSpinner
                    show={loading}
                    type="fit"
                    height="300px"
                    background="white"
                  />)
                }

              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Updates;
