const dealRoom = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_DEAL_ROOM':
      return {
        ...state,
        deal_term: action.dealRoom.deal_term || state.deal_term,
        deal_commitments: action.dealRoom.deal_commitments || state.deal_commitments,
        ...action.dealRoom,
      };

    case 'UPDATE_DEAL_TERM':
      return {
        ...state,
        deal_term: action.dealTerm,
      };

    case 'ADD_DEAL_COMMITMENT':
      return {
        ...state,
        deal_commitments: [...state.deal_commitments, ...action.dealCommitment],
      };

    case 'UPDATE_DEAL_COMMITMENTS':
      return {
        ...state,
        deal_commitments: state.deal_commitments.map(commitment => {
          if (commitment.id === action.dealCommitment.id) return action.dealCommitment;
          return commitment;
        }),
      };

    case 'REMOVE_DEAL_COMMITMENT':
      return {
        ...state,
        deal_commitments: state.deal_commitments.filter(commitment => commitment.id !== action.id),
      };

    default: return state;
  }
};

export default dealRoom;
