import React from 'react';
import { connect } from 'react-redux';
import { StripeProvider, Elements } from 'react-stripe-elements';

import CheckoutForm from './CheckoutForm';

const PaymentModal = ({ stripe, ...props }) => (
  <StripeProvider apiKey={stripe.api_key}>
    <Elements>
      <CheckoutForm {...props} />
    </Elements>
  </StripeProvider>
);

const mapStateToProps = state => ({
  stripe: state.stripe,
  company: state.company,
});

export default connect(mapStateToProps)(PaymentModal);
