import SorterHeader from '../SorterHeader';

const DataRoomStatsDocumentsHeader = props => (
  <div className="col-12 text-gray h5 flex px2 bg-extra-light-gray">
    
    <div className="col-6 py1 pl2 fw400">
      <SorterHeader text="Document Name" field="name" />
    </div>
    <div className="col-1 py1 flex flex-justify-end sm-hide fw400">
      <SorterHeader text="Views" field="num_of_views" />
    </div>
    <div className="col-2 py1 flex flex-justify-end sm-hide fw400">
      <SorterHeader text="Last View" field="latest_view_time" />
    </div>
    <div className="col-1 py1 flex flex-justify-end sm-hide fw400">
      <SorterHeader text="Downloads" field="num_of_downloads" />
    </div>
    <div className="col-2 py1 flex flex-justify-end sm-hide fw400">
      <SorterHeader text="Last Download" field="latest_download_time" />
    </div>
    
  </div>
);

export default DataRoomStatsDocumentsHeader;
