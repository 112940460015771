const slideshow = (state = {}, action) => {
  switch (action.type) {
    case 'CHANGE_COMPANY_FILTERS':
      return Object.assign({}, state, {
        investment_options: state.investment_options.map(company => {
          const matchingCompany = action.selection.find(selectedCompany => selectedCompany.id === company.id);
          if (matchingCompany) {
            company.selected = matchingCompany.selected;
          }
          return company;
        }),
      });

    default: return state;
  }
};

export default slideshow;
