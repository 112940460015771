import { connect } from 'react-redux';
import DealRoom from '../../../components/company/deal_room';

import {
  saveDealInfo,
  saveDealTerm,
  createDealCommitment,
  saveDealCommitment,
  deleteDealCommitment,
} from '../../../api/company/dealRoomApi';

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  company: state.company,
  dealRoom: state.dealRoom,
  dataRoom: state.dataRoom,
  comments: null,
});

const mapDispatchToProps = {
  saveDealInfo,
  saveDealTerm,
  createDealCommitment,
  saveDealCommitment,
  deleteDealCommitment,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealRoom);
