import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import TextInput from '../../TextInput';
import Avatar from '../../tab_view/Avatar';

import { validateEmail } from '../../utils/validate_email';

class InvestorsAndCompaniesSearch extends Component {
  static propTypes = {
    searchUrl: PropTypes.string.isRequired,
    resultSelect: PropTypes.func.isRequired,
    dropdownText: PropTypes.string,
    searchingMessage: PropTypes.string,
    allowEmail: PropTypes.bool,
    placeholder: PropTypes.string,
    emptyFieldAfterSelect: PropTypes.bool,
    height: PropTypes.string,
    inputClass: PropTypes.string,
  }

  static defaultProps = {
    dropdownText: 'Add/Invite someone',
    searchingMessage: 'Searching...',
    allowEmail: true,
    placeholder: 'Add a name or email address...',
    emptyFieldAfterSelect: true,
    height: '24px',
    inputClass: '',
  }

  state = {
    searchField: '',
    elements: [],
    searching: false,
    showDropdown: false,
    resetTextInput: false,
  }

  componentWillMount() {
    this.handleSearch = _.debounce(this.handleSearch, 500);
  }

  search = async () => {
    const { searchUrl } = this.props;
    const { searchField } = this.state;

    this.setState({ searching: true });

    const searchUrlWithParam = `${searchUrl}?email_or_name=${searchField}`;

    try {
      const result = await axios.get(searchUrlWithParam);
      this.setState({ elements: result.data, searching: false });
    } catch (err) {
      this.setState({ searching: false });
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
    }
  }

  handleSearch = (emailOrName) => {
    const emptySearch = emailOrName.trim().length === 0 || emailOrName.replace(/^[ ]+|$/g, '').length < 3;
    let newState = { searchField: emailOrName, showDropdown: true };

    if (emptySearch) {
      newState = { ...newState, elements: [], showDropdown: false };
    }

    this.setState(
      newState,
      () => this.search(),
    );
  }

  handleInvestorInput = (element) => {
    const { resultSelect, emptyFieldAfterSelect, allowEmail } = this.props;
    const { searchField } = this.state;

    if (element) {
      resultSelect(element);
    } else if (allowEmail) {
      if (!validateEmail(searchField)) {
        App.State.setFlash({ name: 'alert', msg: 'Invalid email!' });
        return;
      }
      resultSelect({ email: searchField });
    } else {
      resultSelect({ name: searchField });
    }

    this.setState(
      {
        showDropdown: false,
        searchField: emptyFieldAfterSelect ? '' : (element ? (element.email || element.name) : searchField),
        resetTextInput: true,
      }, () => this.setState({ resetTextInput: false }),
    );
  }

  render() {
    const { dropdownText, searchingMessage, placeholder, height, inputClass } = this.props;
    const { searchField, elements, searching, showDropdown, resetTextInput } = this.state;

    return (
      <React.Fragment>
        <div className={`px1 py05 border border-left border-bottom border-right border-alto ${inputClass}`}>
          <div className="flex flex-center flex-justify-between">
            <div className="flex flex-center col-12">
              {!resetTextInput &&
                <TextInput
                  className="col-12 border-none"
                  value={searchField}
                  onChange={e => this.handleSearch(e.target.value)}
                  name="searchField"
                  // onKeyPress={() => this.handleInvestorInput()}
                  style={{ height }}
                  placeholder={placeholder}
                />
              }
            </div>
          </div>
        </div>

        {showDropdown &&
          <div className="px1 border border-alto rounded">
            <div className="relative">
              <div className="bg-white absolute mtn1" style={{ zIndex: '12' }}>
                <div className="bg-white border border-alto rounded bs-around">
                  <div className="bg-lighter-gray">
                    <span className="h6 flex flex-center ml2 fw400 text-light-gray">{dropdownText}</span>
                  </div>
                  {/* Investors listing or nonexistent email invitation */}
                  <div className="py1">
                    {/* Searching message */}
                    {searching &&
                      <div className="text-light-gray">
                        {searchingMessage}
                      </div>
                    }
                    {!searching &&
                      (elements.length > 0 ?
                        // elements listing
                        elements.map(element => (
                          <div
                            key={element.id}
                            className="flex items-center cursor-pointer py1 hover-bg-wild-sand border-bottom border-gallery px2"
                            onClick={() => this.handleInvestorInput(element)}
                          >
                            <Avatar avatarUrl={element.avatar} />
                            <div className="ml1">
                              To add <span className="fw400">{element.name}</span> click here.
                            </div>
                          </div>
                        ))
                        :
                        // nonexistent email invitation
                        <div className="flex flex-center cursor-pointer px2 hover hover-bg-wild-sand">
                          <React.Fragment>
                            <div className="circle bg-alto flex flex-center flex-justify-center border-medium-gray" style={{ height: '30px', width: '30px' }}>
                              <span className="fa fa-user text-gray" />
                            </div>
                            <span
                              className="ml1"
                              onClick={() => this.handleInvestorInput()}
                            >
                              To add <span className="fw400">{searchField}</span> click here.
                            </span>
                          </React.Fragment>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default InvestorsAndCompaniesSearch;
