import { connect } from 'react-redux';
import DataRooms from '../../../components/investor/investments/data_rooms';

import {
} from '../../../api/investor/investment/dataRoomApi';

const mapStateToProps = state => ({
  header: state.investmentHeader,
  dataRooms: state.investmentDataRooms,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DataRooms);
