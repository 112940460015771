import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import DropdownMenu from '../DropdownMenu';
import ConfirmationModal from '../ConfirmationModal';
import RenameModal from '../modals/RenameModal';
import MoveFileModal from './MoveFileModal';
import FileStatsModal from './FileStatsModal';

class DataRoomFileRow extends Component {
  static propTypes = {
    file: PropTypes.object.isRequired,
    editor: PropTypes.bool,
    blockFileDownload: PropTypes.bool.isRequired,
    moveFile: PropTypes.func,
    updateFile: PropTypes.func,
    deleteFile: PropTypes.func,
    sectionOptions: PropTypes.array,
  }

  static defaultProps = {
    editor: false,
    moveFile: null,
    updateFile: null,
    deleteFile: null,
    sectionOptions: [],
  }

  constructor(props) {
    super(props);

    this.state = {
      showRenameModal: null,
      showMoveModal: null,
      showFileStatsModal: null,
    };
  }

  closeModal = () => {
    this.setState({
      showRenameModal: null,
      showMoveModal: null,
      showFileStatsModal: null,
    });
  }

  getLinkIcon = (link) => {
    if (link.includes('google.com')) return ('fa-google');
    if (link.includes('dropbox.com')) return ('fa-dropbox');
    return ('fa-link');
  };

  renderFileDropdown = file => {
    const { editor, blockFileDownload, moveFile, updateFile, deleteFile, sectionOptions } = this.props;
    const { showRenameModal, showMoveModal } = this.state;

    return (
      <DropdownMenu faIcon="ellipsis-h" dropdownClass="right__close">
        {editor &&
          <li>
            <div
              className="flex flex-center hover hover-bg-wild-sand hover-text-blue text-gray"
              onClick={() => this.setState({ showRenameModal: file.id })}
            >
              <i className="fa fa-edit fa-fw px2" />
              <span className="block bold h5 lh-36 px1">Rename</span>
            </div>
          </li>
        }
        {!file.link &&
          <div>
            <li>
              <a
                className="flex flex-center hover hover-bg-wild-sand text-gray lh-36 hover-text-blue"
                data-method={file.link ? null : 'get'}
                href={file.preview_url}
                target={file.link ? '_blank' : null}
              >
                <i className="fa fa-eye fa-fw px2" />
                <span className="bold h5 lh-36 px1">Preview</span>
              </a>
            </li>
            {!blockFileDownload &&
              <li>
                <a
                  className="flex flex-center hover hover-bg-wild-sand text-gray lh-36 hover-text-blue"
                  data-method={file.link ? null : 'get'}
                  href={file.download_url}
                  target={file.link ? '_blank' : null}
                >
                  <i className="fa fa-download fa-fw px2" />
                  <span className="bold h5 lh-36 px1">Download</span>
                </a>
              </li>
            }
          </div>
        }
        {editor &&
          <li>
            <div
              className="flex flex-center hover hover-bg-wild-sand text-gray lh-36 hover-text-blue"
              onClick={() => this.setState({ showMoveModal: file.id })}
            >
              <i className="fa fa-exchange fa-fw px2" />
              <span className="bold h5 lh-36 px1">Move</span>
            </div>
          </li>
        }
        {editor &&
          <li>
            <div className="flex flex-center hover hover-bg-wild-sand">
              <ConfirmationModal
                width="180px"
                className="block bold h5 lh-36 text-gray nowrap"
                onClick={() => deleteFile(file.id)}
                html='<div class="flex flex-center hover hover-text-blue"><i class="fa fa-trash fa-fw px2"></i><span class="px1">Delete</span></div>'
                message="<b>You are about to permanently delete this Item. This cannot be undone.</b><br/><br/>Are you sure you want to delete?"
                confirmationText="Delete"
                cancelText="Cancel"
              />
            </div>
          </li>
        }
        <RenameModal
          show={file.id === showRenameModal}
          closeModal={this.closeModal}
          title="Rename File"
          initialName={file.name}
          onSubmit={name => updateFile(file.id, { name })}
        />
        <MoveFileModal
          show={showMoveModal === file.id}
          closeModal={this.closeModal}
          id={file.id}
          moveFile={moveFile}
          sectionOptions={sectionOptions}
        />
      </DropdownMenu>
    );
  }

  render() {
    const { file, editor } = this.props;
    const { showFileStatsModal } = this.state;

    return (
      <Draggable draggableId={file.id} index={file.position - 1} isDragDisabled={!editor}>
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div
              key={`file_${file.id}`}
              className="col-12 flex items-center sm-px0 flex-shrink-none border-bottom border-gallery hover--drag__handle"
            >
              {editor ? <span {...provided.dragHandleProps} className="drag__handle mx1 hover" /> : <span {...provided.dragHandleProps} className="mx2" />}
              <a
                className="col-6 text-black flex-auto pr2 py2 sm-col-9"
                href={file.preview_url}
                data-method={file.link ? null : 'get'}
                target={file.link ? '_blank' : null}
              >
                <div className="flex items-center">
                  <span className="h3 mr2" style={{ minWidth: '25px', width: '25px' }}>
                    <i className={file.link ? `fa ${this.getLinkIcon(file.link)}` : file.icon_class_name} style={{ color: file.icon_color }} />
                  </span>

                  <span className="text h5 light-gray fw400 truncate" title={file.link ? file.download_url : file.name}>{file.name}</span>
                </div>
              </a>

              {editor &&
                <div
                  className="flex items-center col-2 py2 pl1 sm-hide h5 nowrap text-gray cursor-pointer"
                  onClick={() => this.setState({ showFileStatsModal: file.id })}
                >
                  {file.num_of_views > 0 ? `${file.num_of_views}` : '\u2013'}
                  <i className="fa fa-eye mx1 text-light-gray" />
                  {file.num_of_downloads > 0 ? `${file.num_of_downloads}` : '\u2013'}
                  <i className="fa fa-download ml1 text-light-gray" />
                </div>
              }

              <div className="col-2 py2 pl1 sm-hide h5 text-gray">
                {file.link ?
                  '\u2013 (link)'
                  :
                  parseFloat(file.size) ? file.size : '\u2013'
                }
              </div>

              <div className="col-1 py2 sm-hide h5 nowrap text-gray truncate">
                {file.updated_at}
              </div>

              <div className="col-1 p1 pr1 flex flex-center flex-justify-end sm-col-3">
                {this.renderFileDropdown(file)}
              </div>

              <FileStatsModal
                show={showFileStatsModal === file.id}
                closeModal={this.closeModal}
                file={file}
                fileIcon={file.link ? `fa ${this.getLinkIcon(file.link)}` : file.icon_class_name}
              />
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

export default DataRoomFileRow;
