import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../../../store/configureStore';
import DataRoomsFormContainer from '../../../containers/company/data_rooms/DataRoomEditFormContainer';


const DataRoomEditForm = props => (
  <Provider store={configureStore(props)}>
    <DataRoomsFormContainer />
  </Provider>
);

export default DataRoomEditForm;
