import { connect } from 'react-redux';
import Profile from '../../../components/investor/investments/profile';

import {
  createOrUpdateFields,
  cloneField,
  deleteFields,
  copyCoinvestorsData,
} from '../../../api/investor/investmentApi';

const mapStateToProps = state => ({
  header: state.investmentHeader,
  investment: state.investment,
});

const mapDispatchToProps = {
  createOrUpdateFields,
  cloneField,
  deleteFields,
  copyCoinvestorsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
