import React from 'react';
import PropTypes from 'prop-types';

import KpiSelector from './KpiSelector';
import SvgChart from './SvgChart';


const ChartKpiWidget = ({ data }) => (
  data.display ?
    <a data-turbolinks="false" className="col-12 flex mb2 text-gray" href={data.url}>
      <div className="bg-white bs-around col-12 p1 card--has-hover show-cog-on-hover flex relative">
        <div className="">
          <SvgChart table={data.table} forecastTable={data.forecastTable} />
          <div className="flex flex-center relative">
            <span className="bold h5 pr1">{data.title}</span>
            <KpiSelector url={data.manageUrl} />
          </div>
          <p className="text-light-gray bold col-12 h6 m0 lh-14 pb2">
            {data.date}&nbsp;
          </p>
          <div className="bold relative z1">
            <p className="h1 m0">
              {data.value}
              <span className="h4 ml1 text-light-gray">{data.unit}</span>
            </p>
            <p className="text-light-blue h6 m0 lh-14">
              {data.note}
            </p>
          </div>
        </div>
        {data.extra &&
          <div className="flex flex-auto flex-justify-end">
            <div className="">
              <p className={`text-${data.extra.color} h3 m0 bold right-align`}>{data.extra.text}</p>
              <p className="text-light-gray h6 bold right-align lh-14 m0">{data.extra.note}</p>
            </div>
          </div>
        }
      </div>
    </a>
    :
    <div className="col-12 flex mb2 text-gray cursor-pointer">
      <div className="bg-white bs-around col-12 p1 card--has-hover show-cog-on-hover flex">
        <div className="pb2">
          <div className="flex flex-center relative pb2 text-light-gray">
            <span className="bold h5 pr1">Select a KPI</span>
            <KpiSelector url={data.manageUrl} />
          </div>
        </div>
      </div>
    </div>
);

ChartKpiWidget.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ChartKpiWidget;
