import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import axios from 'axios';

import Modal from '../../Modal';

import { defaultSelectStyles, defaultSelectTheme } from '../../selects/SelectStyles';

class FundDashboardKpisButton extends Component {
  static propTypes = {
    fundId: PropTypes.number.isRequired,
    fundName: PropTypes.string.isRequired,
    kpiOptions: PropTypes.array.isRequired,
    dashboardKpis: PropTypes.array.isRequired,
  }

  constructor(props) {
    super();

    this.state = {
      open: false,
      saving: false,
      dashboardKpisState: props.dashboardKpis,
    };
  }

  setDashboardKpi = (selectedValue, index, field) => {
    const { dashboardKpisState } = this.state;

    const newDashboardKpisState = dashboardKpisState.map((kpi, innerIndex) => (
      index === innerIndex ?
        {
          ...kpi,
          [field]: selectedValue,
        }
        :
        { ...kpi }
    ));

    this.setState({ dashboardKpisState: newDashboardKpisState });
  }

  handleClose = () => {
    const { dashboardKpis } = this.props;

    this.setState({ open: false, dashboardKpisState: dashboardKpis });
  }

  submit = async () => {
    const { fundId } = this.props;
    const { dashboardKpisState, saving } = this.state;

    if (saving) return;

    this.setState({ saving: true });

    const url = fundId ? `funds/${fundId}/investor_dashboard_kpis` : 'investor_dashboard_kpis';

    const params = {
      investor_dashboard_kpis: {
        'values[]': dashboardKpisState.map(kpi => (
          {
            id: kpi.id,
            metric: kpi.value,
            unit: kpi.unit,
            sum_type: kpi.sum_type,
          }
        )),
      },
    };

    try {
      await axios.post(url, params);
      window.location.reload();
    } catch (err) {
      this.setState({ saving: false });
      App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an error prevented the Dashboard KPIs from being saved.' });
    }
  }

  render() {
    const { kpiOptions, fundName } = this.props;
    const { open, saving, dashboardKpisState } = this.state;

    const unitOptions = [{ value: 'currency', label: 'Currency' }, { value: 'number', label: 'Number' }];
    const sumTypeOptions = [{ value: 'total', label: 'Total' }, { value: 'median', label: 'Median' }];

    return (
      <div className="">
        <div
          className="cursor-pointer text-gray mx1"
          onClick={() => { this.setState({ open: true }); }}
          data-balloon="Edit Dashboard KPIs"
          data-balloon-pos="down"
        >
          <i className="fa fa-line-chart fa-fw" />
        </div>
        <Modal
          className="bg-white flex flex-column mb2 react_modal"
          show={open}
          onClickOutside={() => this.handleClose()}
        >
          <div
            className="flex flex-auto flex-justify-end cursor-pointer p1"
            onClick={() => this.handleClose()}
          >
            <i className="fa fa-close text-gray" />
          </div>
          <p className="col-12 center semi-bold py2 h2">Edit Dashboard KPIs - {fundName}</p>
          <div className="col-12 flex flex-column flex-center sm-block bg-alabaster p2 sm-center">
            <div className="col-12">
              <div className="flex flex-center mb2 h3 border-top border-bottom mxn2 p1 border-light-gray">
                <div className="col-2" />
                <div className="col-4">KPI</div>
                <div className="col-3">Unit</div>
                <div className="col-3">Summary Operation</div>
              </div>
            </div>
            {dashboardKpisState.map((dashboardKpi, index) => (
              <div
                key={`dashboard_kpi_${index + 1}`}
                className="flex items-center col-12 mb1"
              >
                <div className="col-2 fw400">
                  {`KPI ${index + 1}`}
                </div>

                <ReactSelect
                  value={kpiOptions.filter(option => option.value === dashboardKpi.label)}
                  options={kpiOptions}
                  onChange={async (selectedOption) => { await this.setDashboardKpi(selectedOption.value, index, 'value'); await this.setDashboardKpi(selectedOption.value, index, 'label'); }}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                  className="col-4"
                />

                <ReactSelect
                  value={unitOptions.filter(option => option.value === dashboardKpi.unit)}
                  options={unitOptions}
                  onChange={(selectedOption) => this.setDashboardKpi(selectedOption.value, index, 'unit')}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                  className="col-3 mx2"
                />

                <ReactSelect
                  value={sumTypeOptions.filter(option => option.value === dashboardKpi.sum_type)}
                  options={sumTypeOptions}
                  onChange={(selectedOption) => this.setDashboardKpi(selectedOption.value, index, 'sum_type')}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                  className="col-3"
                />
              </div>
            ))}

            <div className="border-top border-gallery px3 py2 fw400 flex flex-justify-end items-center">
              <div className="text-blue mr2 cursor-pointer" onClick={this.handleClose}>Cancel</div>
              <div className={`text-white bg-blue px3 py1 rounded ${saving ? 'cursor-disabled' : 'cursor-pointer'}`} onClick={this.submit}>{saving ? 'Saving...' : 'Save'}</div>
            </div>
          </div>
        </Modal>
      </div>

    );
  }
}

export default FundDashboardKpisButton;
