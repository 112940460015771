import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EditorSection from './update_step/EditorSection';
import PhotoSection from './update_step/PhotoSection';
import KpiSection from './update_step/KpiSection';
import RunwaySection from './update_step/RunwaySection';
import FileSection from './update_step/FileSection';
import AddButton from './AddButton';
import EditButton from './EditButton';


class SectionList extends Component {
  sections = {
    text: EditorSection,
    kpi: KpiSection,
    photo: PhotoSection,
    runway: RunwaySection,
    file: FileSection,
  }

  static propTypes = {
    sections: PropTypes.array,
    kpis: PropTypes.array,
    createSection: PropTypes.func.isRequired,
    deleteSection: PropTypes.func.isRequired,
    moveSection: PropTypes.func.isRequired,
    saveSection: PropTypes.func.isRequired,
    updateSection: PropTypes.func.isRequired,
    photoUpload: PropTypes.func.isRequired,
    uploadGif: PropTypes.string.isRequired,
    kpi_options: PropTypes.object,
    chart_types_for_selection: PropTypes.array,
    createKpiChart: PropTypes.func.isRequired,
    updateKpi: PropTypes.func.isRequired,
  }

  static defaultProps = {
    sections: [],
    kpis: [],
    kpi_options: null,
    chart_types_for_selection: null,
  }

  deleteButton = (section) => {
    const { deleteSection } = this.props;

    return (
      <span
        className="cursor-pointer inline-block"
        onClick={e => deleteSection(section.url, e)}
        data-balloon="Delete - Press the 'alt' key and click on the icon to skip confirmation!"
      >
        <i className="fa fa-trash" />
      </span>
    );
  }

  downButton = (section) => {
    const { moveSection } = this.props;

    return (
      <span
        className="cursor-pointer inline-block mr2"
        onClick={() => moveSection(section.move_url, 'down')}
      >
        <i className="fa fa-arrow-down" />
      </span>
    );
  }

  sectionType = (section) => {
    const { kpis, photoUpload, saveSection, kpi_options: kpiOptions, updateSection, uploadGif, chart_types_for_selection: chartTypes, createKpiChart, deleteSection } = this.props;
    const Section = this.sections[section.type];
    return (
      <Section
        section={section}
        photoUpload={photoUpload}
        saveSection={saveSection}
        kpis={kpis}
        kpi_options={kpiOptions}
        updateSection={updateSection}
        uploadGif={uploadGif}
        chart_types_for_selection={chartTypes}
        createKpiChart={createKpiChart}
        deleteSection={deleteSection}
      />
    );
  }

  upButton = (section) => {
    const { moveSection } = this.props;

    return (
      <span
        className="cursor-pointer inline-block mr2"
        onClick={() => moveSection(section.move_url, 'up')}
      >
        <i className="fa fa-arrow-up" />
      </span>
    );
  }

  render() {
    const { sections, createSection, updateKpi } = this.props;

    const renderSections = sections.map((section, i) => (
      <div className="sh-update-block-button-hover relative mb1" style={{ paddingTop: '25px' }} key={section.id}>
        <div className="sh-update-block-button absolute col-12 hover left-0 opacity-0 top-0">
          <div className="flex">
            <div className="col-6 flex">
              <AddButton
                position={i + 1}
                createSection={createSection}
              />
              {
                (section.type === 'kpi' && section.kpi_id > 0) &&
                <EditButton
                  section={section}
                  updateKpi={updateKpi}
                  position={i + 1}
                />
              }
            </div>
            <div className="col-6 right-align">
              { section.can_move_up ? this.upButton(section) : null }
              { section.can_move_down ? this.downButton(section) : null }
              { this.deleteButton(section) }
            </div>
          </div>
        </div>

        <div className="border border-transparent hover hover-border-alto hover-bs-light-gray">
          { this.sectionType(section)}
        </div>
      </div>
    ));
    return (
      <div>
        {renderSections}
        <div className="hover hover-opacity-100 mt2 opacity-20">
          <AddButton
            position={sections.length + 1}
            createSection={createSection}
          />
        </div>
      </div>
    );
  }
}

export default SectionList;
