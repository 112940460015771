import React from 'react';

import SorterHeader from '../SorterHeader';


const InvestorDataRoomHeader = () => (
  <div className="col-12 text-gray h5 flex px2 bg-extra-light-gray">
    <div className="col-6 py1 bold pl2 sm-col-6 sm-pl0">
      <SorterHeader text="Name" field="name" />
    </div>
    <div className="col-2 py1 bold sm-hide">
      <SorterHeader text="Documents" field="files_cnt" />
    </div>
    <div className="col-2 py1 bold sm-hide">
      <SorterHeader text="Managed By" field="managed_by" />
    </div>
    <div className="col-2 py1 bold sm-hide">
      <SorterHeader text="Last Upload" field="last_upload" />
    </div>
  </div>
);

export default InvestorDataRoomHeader;
