import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';


class AvatarListWidget extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.size = 4;

    this.state = {
      open: false,
    };
  }

  avatars = () => {
    const { data } = this.props;

    return (
      data.table.slice(0, this.size - 1).map(item => (
        <span
          key={`${item.id}_${Math.random()}`}
          className="block bg-center bg-cover bg-no-repeat circle header__avatar--dash mr1"
          style={{ backgroundImage: `url(${item.avatar})` }}
          data-balloon={item.name}
          data-balloon-pos="up"
        />
      ))
    );
  }

  displayLast = () => {
    const { data: { table } } = this.props;
    if (table.length === this.size) {
      return (
        <span
          className="block bg-center bg-cover bg-no-repeat circle header__avatar--dash mr1"
          data-balloon={table[this.size - 1].name}
          key={this.size - 1}
          data-balloon-pos="up"
          style={{ backgroundImage: `url( ${table[this.size - 1].avatar} )` }}
        />
      );
    }
    return (
      null
    );
  }

  displayModal = () => {
    const { data: { url, title, table } } = this.props;
    const { open } = this.state;

    if (!url && table.length > 0) {
      const list = table.map(item => (
        <a key={item.id} className="flex flex-center border-bottom border-alto p2 text-gray" href={item.url}>
          <span
            className="block bg-center bg-cover bg-no-repeat circle header__avatar--dash mr1"
            data-balloon-pos="up"
            style={{ backgroundImage: `url(${item.avatar})` }}
          />
          <span className="truncate">
            {item.name}
          </span>
        </a>
      ));

      return (
        <Modal
          className="react_modal--300"
          show={open}
          onClickOutside={() => { this.setState({ open: false }); }}
        >
          <div className="">
            <div className="flex flex-center flex-justify-center p2 text-gray bg-wild-sand border-bottom border-white bold">
              {title}
            </div>
            {list}
          </div>
        </Modal>
      );
    }
    return null;
  }

  more = () => {
    const { data: { table } } = this.props;
    if (table.length < this.size + 1) {
      return null;
    }
    return (
      <div
        className="h3 flex flex-center"
        data-balloon={table.slice(this.size - 1, table.length).map(item => item.name).join(', ')}
        data-balloon-pos="up"
      >
        +{table.length - this.size + 1}
      </div>
    );
  }

  render() {
    const { data } = this.props;

    return (
      <a
        className={`col-6 flex mb2 sm-col-12 text-gray cursor-pointer ${data.left ? 'pr1' : 'pl1'}`}
        href={data.url}
        onClick={() => { this.setState({ open: true }); }}
      >
        {this.displayModal()}
        <div className="bg-white bs-around col-12 p1 card--has-hover">
          <div className="flex pb3">
            <span className="bold h5">{data.title}</span>
          </div>
          <div className="flex">
            {this.avatars()}
            {this.displayLast()}
            {this.more()}
          </div>
        </div>
      </a>
    );
  }
}

export default AvatarListWidget;
