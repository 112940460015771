import React from 'react';
import PropTypes from 'prop-types';

import ConnectWithOthers from '../ConnectWithOthers';
import InvestorsAndCompaniesSearch from '../utils/InvestorsAndCompaniesSearch';
import Avatar from '../../tab_view/Avatar';

class InvestorConnectWithOthers extends ConnectWithOthers {
  static propTypes = {
    addedUsers: PropTypes.array,
    newCompanies: PropTypes.array,
    existingCompanies: PropTypes.array,
    confirmedUser: PropTypes.string.isRequired,
  }

  static defaultProps = {
    addedUsers: [],
    newCompanies: [],
    existingCompanies: [],
  }

  constructor(props) {
    super(props);
    const { newCompanies, existingCompanies } = props;

    this.state = {
      invitedUsers: [...newCompanies, ...existingCompanies],
    };
  }

  render() {
    const { addedUsers, confirmedUser } = this.props;
    const { invitedUsers } = this.state;

    return (
      <div className="flex flex-column">
        <div className="flex flex-justify-start text-light-gray fw400 pb1">
          Company Name or Email
        </div>

        <InvestorsAndCompaniesSearch
          searchUrl="/onboarding/companies/step/2"
          resultSelect={result => this.handleResult(result)}
        />

        {(invitedUsers.length > 0 || confirmedUser) &&
          <div className="flex flex-justify-start flex-column text-light-gray fw400 mt2">
            <div className="flex flex-justify-start pb1">
              Invited Companies
            </div>
            {(invitedUsers.length !== 0 || addedUsers.length !== 0) ?
              <div className="flex flex-column">
                {invitedUsers.map(invitedUser => (
                  <div
                    key={invitedUser.id || invitedUser.email}
                    className="flex items-center flex-justify-between mb1"
                  >
                    <div className="flex items-center">
                      <Avatar avatarUrl={invitedUser.avatar || window.images.userGray} />
                      <span className="ml1">{invitedUser.name || invitedUser.email}</span>
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => this.removeInvitedUser(invitedUser)}
                    >
                      <i className="fa fa-close text-red" />
                    </div>
                  </div>
                ))}
              </div>
              :
              <div className="flex flex-justify-start light">
                No companies added yet...
              </div>
            }
            {invitedUsers.filter(invitedUser => invitedUser.id).map(invitedUser => (
              <input key={invitedUser.id || invitedUser.email} type="hidden" name="existing_invited_users[]" multiple="multiple" value={invitedUser.id || invitedUser.email} />
            ))}
            {invitedUsers.filter(invitedUser => invitedUser.email).map(invitedUser => (
              <input key={invitedUser.id || invitedUser.email} type="hidden" name="new_invited_users[]" multiple="multiple" value={invitedUser.id || invitedUser.email} />
            ))}
          </div>
        }

        {addedUsers.length > 0 &&
          <div className="flex flex-justify-start flex-column text-light-gray fw400 mt2">
            <div className="flex flex-justify-start pb1">
              Connected Companies
            </div>
            <div className="flex flex-column">
              {addedUsers.map(addedUser => (
                <div
                  key={addedUser.name}
                  className="flex items-center mb1"
                >
                  <Avatar
                    avatarUrl={addedUser.avatar}
                  />
                  <span className="ml1 bold text-gray">{addedUser.name}</span>
                </div>
              ))}
            </div>
          </div>
        }
        {!confirmedUser &&
          <div className="flex flex-justify-start flex-column text-light-gray fw400 mt2">
            <div className="flex flex-justify-start pb1">
              Connected/Invited Companies
            </div>
            <div
              className="flex flex-justify-start items-center text-orange"
              data-balloon="Check your email account! You should have received a email with a link to verify that you have access to this email address."
              data-balloon-pos="down"
              data-balloon-length="large"
            >
              <i className="fa fa-warning mr1" />
              <span className="left-align fw400">Confirm your email adress first...</span>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default InvestorConnectWithOthers;
