import React, { Component } from 'react';
import PropTypes from 'prop-types';


class ContactsList extends Component {
  static propTypes = {
    canAddExternalRecipient: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired,
    contacts: PropTypes.array,
    searchResults: PropTypes.array.isRequired,
    searchField: PropTypes.string.isRequired,
    handleExternalSingleRecipientSelect: PropTypes.func.isRequired,
    handleGroupContactSelect: PropTypes.func,
    // addExistingContactRecipient: PropTypes.func.isRequired,
    // highlightInvestorMember: PropTypes.func.isRequired,
    // toogleContactsList: PropTypes.func.isRequired,
    toogleModal: PropTypes.func,
    // conditionToHighlight: PropTypes.func.isRequired,
    withExternalRecipients: PropTypes.bool.isRequired,
    withContacts: PropTypes.bool,
  }

  static defaultProps = {
    contacts: [],
    handleGroupContactSelect: null,
    toogleModal: null,
    withContacts: null,
  }

  handleExternalRecipient = () => {
    const { searchField, handleExternalSingleRecipientSelect } = this.props;

    handleExternalSingleRecipientSelect(searchField);
  }

  render() {
    const {
      canAddExternalRecipient,
      show,
      contacts,
      searchResults,
      searchField,
      handleGroupContactSelect,
      // addExistingContactRecipient,
      // highlightInvestorMember,
      toogleModal,
      // conditionToHighlight,
      withExternalRecipients,
      withContacts,
    } = this.props;

    // const text = conditionToHighlight() ? 'Highlight investor member' : 'Add as external update recipient only (Pro Feature)';
    const text = 'Add as external update recipient only (Pro Feature)';

    return (
      show &&
      <div className="bg-white border border-alto rounded bs-around">
        <div className="bg-lighter-gray">
          <span className="h6 flex flex-center ml2 fw400 text-light-gray">{text}</span>
        </div>
        <div className="py1 px2">
          {
            !searchField && withContacts &&
            contacts.map((contact, index) => (
              <div
                key={`contact${contact.name}`}
                className={`flex ${index !== 0 ? 'pt1' : ''}`}
                onClick={() => handleGroupContactSelect(contact)}
              >
                <div className="flex flex-center cursor-pointer hover-text-blue">
                  <div className="circle bg-alto flex flex-center flex-justify-center border-medium-gray" style={{ height: '30px', width: '30px' }}>
                    <span className="fa fa-calendar text-gray" />
                  </div>
                  <span className="ml1 fw400">
                    {contact.name} ({contact.recipients.length})
                  </span>
                </div>
              </div>
            ))
          }
          {
            searchField &&
            (
              // Disabled for now
              // searchResults.length > 0 ?
              // searchResults.map((recipient) => (
              //   <div
              //     key={`recipient_${recipient.email}`}
              //     className="flex flex-center cursor-pointer hover-text-blue py1"
              //     onClick={() => addExistingContactRecipient(recipient.user_ids)}
              //   >
              //     <div className="circle bg-alto flex flex-center flex-justify-center border-medium-gray" style={{ height: '30px', width: '30px' }}>
              //       {avatar(recipient.avatar) || <span className="fa fa-user text-gray" />}
              //     </div>
              //     <span className="ml1 fw400">
              //       {recipient.name} ({recipient.email})
              //     </span>
              //   </div>
              // ))
              // Highlighting disabled for now
              // conditionToHighlight() ?
              false ?
                <div
                  className="flex flex-center cursor-pointer"
                >
                  <div className="circle bg-alto flex flex-center flex-justify-center border-medium-gray" style={{ height: '30px', width: '30px' }}>
                    <span className="fa fa-user text-gray" />
                  </div>
                  <span
                    className="ml1"
                    // onClick={() => highlightInvestorMember(searchResults[0])}
                  >
                    To highlight <span className="fw400">{searchResults[0].email}</span> press enter or click here.
                  </span>
                </div>
                :
                (withExternalRecipients &&
                  <div
                    className="flex flex-center cursor-pointer"
                  >
                    {canAddExternalRecipient ?
                      <React.Fragment>
                        <div className="circle bg-alto flex flex-center flex-justify-center border-medium-gray" style={{ height: '30px', width: '30px' }}>
                          <span className="fa fa-user text-gray" />
                        </div>
                        <span
                          className="ml1 px2 hover hover-bg-wild-sand"
                          onClick={this.handleExternalRecipient}
                        >
                          To add <span className="fw400">{searchField}</span> press enter or click here.
                        </span>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <span
                          className="ml1"
                          onClick={toogleModal}
                        >
                          <span className="text-orange">Adding a update only recipient is a Pro feature. Click to upgrade!</span>
                        </span>
                      </React.Fragment>
                      }
                  </div>
                )
            )
          }
        </div>
      </div>
    );
  }
}

export default ContactsList;
