import Highcharts from 'highcharts';

const applyDateFormat = () => {
  // %W weekly and quarterly formating
  Highcharts.dateFormats = {
    W: (timestamp) => {
      const date = new Date(timestamp),
        day = date.getUTCDay() === 0 ? 7 : date.getUTCDay();

      date.setDate(date.getUTCDate() + 4 - day);
      const dayNumber = Math.floor((date.getTime() - new Date(date.getUTCFullYear(), 0, 1, -6)) / 86400000);

      return 1 + Math.floor(dayNumber / 7);
    },
    q: (timestamp) => {
      const date = new Date(timestamp),
        quarter = (Math.floor(date.getUTCMonth() / 3) + 1);

      return quarter;
    },
  };
};

export {
  applyDateFormat as default,
};
