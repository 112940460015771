import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Select from '../../../Select';
import Textarea from '../../../Textarea';

import HeaderContainer from '../../../../containers/investor/investments/HeaderContainer';
import Contact from './Contact';
import TeamMember from './TeamMember';
import Investor from './Investor';
import CompanyFieldsModal from './CompanyFieldsModal';
import CustomFieldsModal from './CustomFieldsModal';
import UserFieldModal from './UserFieldModal';
import ContactsModal from './ContactsModal';
import SmallKpiWidget from '../../../dashboard/SmallKpiWidget';
import ConfirmationModal from '../../../ConfirmationModal';
import LoadingSpinner from '../../../LoadingSpinner';


class Profile extends Component {
  static propTypes = {
    header: PropTypes.object.isRequired,
    investment: PropTypes.object.isRequired,
    createOrUpdateFields: PropTypes.func.isRequired,
    cloneField: PropTypes.func.isRequired,
    deleteFields: PropTypes.func.isRequired,
    copyCoinvestorsData: PropTypes.func.isRequired,
  }

  state = {
    showCompanyFieldModal: false,
    showCustomFieldModal: false,
    showUserFieldModal: false,
    userType: '',
    showContactsModal: false,
    loading: null,
  }

  cloneCustomField = async (id, params) => {
    const { header, investment, cloneField } = this.props;
    await cloneField(header.investor.id, investment.id, id, params);
  }

  overwriteField = (id, e) => {
    const { investment } = this.props;

    const field = investment[e.target.name.toLowerCase().split(' ').join('_')] ||
      investment.company_fields.find(f => f.name === e.target.name) ||
      investment.contacts.find(f => f.name === e.target.name);

    if (field && field.text === e.target.value) return;

    const params = {
      investment_field: {
        values: [{
          name: e.target.name,
          text: e.target.value,
        }],
      },
    };
    if (typeof id === 'string') {
      params.investment_field.values[0].overwrite = id;
    } else {
      params.investment_field.ids = [id];
    }

    this.saveFields(id, params);
  }

  removeFields = async params => {
    const { header, investment, deleteFields } = this.props;
    await deleteFields(header.investor.id, investment.id, params);
  }

  saveCustomField = (id, e) => {
    const { target: { name, value } } = e;

    const params = {
      investment_field: {
        ids: [id],
        values: [{
          [name]: value,
        }],
      },
    };

    this.saveFields(id, params);
  }

  saveFields = async (id, params) => {
    const { header, investment, createOrUpdateFields } = this.props;

    try {
      await createOrUpdateFields(header.investor.id, investment.id, id, params);
    } catch (err) {
      console.log(err.response.data.errors);
    }
  }

  handleCoInvestorsCopy = async () => {
    const { copyCoinvestorsData } = this.props;

    try {
      this.setState({ loading: 'co_investors' });
      await copyCoinvestorsData();
      this.setState({ loading: null });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
      this.setState({ loading: null });
    }
  }

  indicator = field => {
    if (typeof field.id === 'string') return 'desktop__notification--changes-green';
    return 'desktop__notification--changes-blue';
  }

  checkLink = link => {
    if (!link.includes('http')) return `https://${link}`;
    return link;
  }

  urlify = text => {
    if (!text) return { textHasUrl: false };
    const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;
    const hasUrl = text.split(' ').map(element => {
      if (element.search(urlRegex) === -1) { return null; }
      return 1;
    });
    if (hasUrl.every(element => element === null)) return { textHasUrl: false };
    const html2 = text.split(' ').map(str => `${str} `).map(str => { if (str.replace(/\s/g, '').match(urlRegex)) { return <a href={this.checkLink(str.replace(/\s/g, ''))} target="_blank" rel="noopener noreferrer">{str.replace(/\s/g, '')}</a>; } return <span>{str}</span>; });
    return {
      htmlText: html2,
      textHasUrl: !hasUrl.every(element => element === null),
    };
  }

  render() {
    const {
      investment,
    } = this.props;
    const {
      showCompanyFieldModal,
      showCustomFieldModal,
      showUserFieldModal,
      userType,
      showContactsModal,
      loading,
    } = this.state;

    const inputClasses = classNames('text-gray lh-125', {
      'border border-lighter-gray bg-none': investment.edit,
      'bg-none border-transparent p0': !investment.edit,
    });

    const address = investment.address_fields.map(a => a.text).filter(a => a !== '').join(', ');

    return (
      <div>
        <HeaderContainer
          activeTab="profile"
          tabUrl={`investments/${investment.id}`}
          address={address}
        />
        <div className="flex sm-flex-column col-12 my2">
          <div className="col-8 sm-col-12 mr1">
            <div className="bg-white bs-around flex flex-column p2">
              <div className="flex flex-justify-between px2 pb2">
                <div className="fw400 text-gray">Overview</div>
                {investment.edit && !investment.custom_investment &&
                  <div
                    className="px1 h6 bg-black--transparent text-white cursor-pointer rounded"
                    onClick={() => this.setState({ showCompanyFieldModal: true })}
                  >
                    <i className="fa fa-cog mr1" />Manage
                  </div>
                }
                {showCompanyFieldModal &&
                  <CompanyFieldsModal
                    show={showCompanyFieldModal}
                    closeModal={() => this.setState({ showCompanyFieldModal: false })}
                    investment={investment}
                    saveFields={this.saveFields}
                    deleteFields={this.removeFields}
                  />
                }
              </div>
              {investment.company_fields.map(f => (
                <div key={`field_${f.id}`} className={`flex ${investment.edit ? 'items-center' : ''} lh-125 px2 pb05 ${!investment.edit && !f.text && 'hidden'}`}>
                  <div className="col-4 text-light-gray border border-transparent capitalize">{f.name}</div>
                  <div className={`col-8 relative ${investment.edit && this.indicator(f)}`}>
                    {investment.edit ?
                      <Select
                        className={`col-12 ${inputClasses}`}
                        name={f.name}
                        value={f.text}
                        placeholder={`No ${f.name} selected...`}
                        options={investment[`${f.overwrite || f.id}_options`]}
                        onChange={e => this.overwriteField(f.id, e)}
                        disabled={!investment.edit}
                      />
                      :
                      <div className="col-8 text-gray capitalize">
                        {f.text && investment[`${f.overwrite || f.id}_options`].find(o => o[0].toString() === f.text)[1]}
                      </div>
                    }
                  </div>
                </div>
              ))}
              <div className={`flex ${investment.edit ? 'flex-column' : 'items-center'} px2`}>
                <div className="col-4 text-light-gray border border-transparent capitalize">Location</div>
                {investment.edit ?
                  investment.address_fields.map(f => (
                    <div key={`field_${f.id}`} className="flex items-center lh-125 pb05">
                      <div className={`col-4 text-light-gray border border-transparent capitalize ${investment.edit ? 'pl2' : ''}`}>{f.name}</div>
                      <div className={`col-8 relative ${investment.edit && this.indicator(f)}`}>
                        <Textarea
                          className={`col-12 ${inputClasses}`}
                          name={f.name}
                          value={f.text}
                          onBlur={e => this.overwriteField(f.id, e)}
                          disabled={!investment.edit}
                          rows="1"
                        />
                      </div>
                    </div>
                  ))
                  :
                  <div className="col-8 text-gray">{address}</div>
                }
              </div>

              {(investment.custom_fields.length || investment.edit) &&
                <div>
                  <div className="border-bottom border-lighter-gray my2" />
                  <div className="flex flex-justify-between pb2 px2">
                    <div className="fw400 text-gray">Additional Info</div>
                    {investment.edit &&
                      <div
                        className="px1 h6 bg-black--transparent text-white cursor-pointer rounded"
                        onClick={() => this.setState({ showCustomFieldModal: true })}
                      >
                        <i className="fa fa-cog mr1" />Manage & Add
                      </div>
                    }
                  </div>
                  <CustomFieldsModal
                    show={showCustomFieldModal}
                    closeModal={() => this.setState({ showCustomFieldModal: false })}
                    isAdmin={investment.admin}
                    customFields={investment.custom_fields}
                    saveFields={this.saveFields}
                    cloneField={this.cloneCustomField}
                    deleteFields={this.removeFields}
                  />
                </div>
              }
              {
                investment.custom_fields.map(f => (
                  <div key={`field_${f.id}`} className={`flex ${investment.edit ? 'items-center' : ''} lh-125 px2 pb05`}>
                    <div className="col-4 text-light-gray border border-transparent capitalize">{f.name}</div>
                    <div className={`col-8 relative ${investment.edit && this.indicator(f)}`}>
                      {!(this.urlify(f.text).textHasUrl) || investment.edit ?
                        (
                          <Textarea
                            className={`col-12 ${inputClasses}`}
                            name="text"
                            value={f.text}
                            onBlur={e => this.saveCustomField(f.id, e)}
                            disabled={!investment.edit}
                          />
                        ) : (
                          <div className="col-12 break-word">{this.urlify(f.text).htmlText}</div>
                        )}
                    </div>
                  </div>
                ))
              }
              {(investment.description.text || investment.edit) &&
                <div>
                  <div className="border-bottom border-lighter-gray my2" />
                  <div className="px2">
                    <div className="text-gray fw400 pb2">Description</div>
                    <div className={`col-12 relative ${investment.edit && this.indicator(investment.description)}`}>
                      <Textarea
                        className={`col-12 ${inputClasses}`}
                        name={investment.description.name}
                        value={investment.description.text}
                        onBlur={e => this.overwriteField(investment.description.id, e)}
                        disabled={!investment.edit}
                      />
                      <span className={`flex text-orange h6 ${investment.edit ? 'normal' : 'hidden'}`}>Note: Some reports (like the slideshow) may limit text shown to 5 sentences or ~350 characters.</span>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="bg-white bs-around flex flex-column p2 mt2">
              <div className="flex flex-justify-between px2">
                <div className="fw400">Team</div>
                {investment.edit &&
                  <div
                    className="px1 h6 bg-black--transparent text-white cursor-pointer rounded"
                    onClick={() => this.setState({ showUserFieldModal: true, userType: 'collaborator' })}
                  >
                    <i className="fa fa-plus mr1" />Add
                  </div>
                }
              </div>
              <div className="p2">
                <div className="flex flex-wrap items-center">
                  { investment.collaborators.length ?
                    investment.collaborators.map((f, idx) => (
                      <TeamMember
                        key={`key_people_${Math.random() * idx}`}
                        field={f}
                        edit={investment.edit}
                        options={investment.collaborator_options}
                        saveFields={this.saveFields}
                        deleteFields={this.removeFields}
                      />
                    ))
                    :
                    <div className="text-light-gray px2">No Team Members added yet...</div>
                  }
                  { (investment.collaborators.length > 0 && investment.edit) &&
                    <span className={`flex text-orange h6 ${investment.edit ? 'normal' : 'hidden'}`}>Note: Some reports may limit # of team members shown to the first 3.</span>
                  }
                </div>
              </div>
            </div>
            <div className="bg-white bs-around flex flex-column p2 mt2">
              <div className="flex flex-justify-between px2">
                <div className="fw400">(Co-)Investors</div>
                {investment.edit &&
                  <div className="flex">
                    {!investment.custom_investment &&
                      <ConfirmationModal
                        html='<div><i class="fa fa-copy mr1"></i><span>Copy Company Data as Custom Data</span></div>'
                        className="px1 mr1 h6 bg-black--transparent text-white cursor-pointer rounded sm-mt1"
                        onClick={this.handleCoInvestorsCopy}
                        message="<b>Please note copying company data as custom data will delete all existing custom data. Are you sure you want to continue?"
                        width="auto"
                      />
                    }

                    <div
                      className="px1 h6 bg-black--transparent text-white cursor-pointer rounded mr2"
                      onClick={() => this.setState({ showUserFieldModal: true, userType: 'investor' })}
                    >
                      <i className="fa fa-plus mr1" />Add
                    </div>
                  </div>
                }
              </div>
              {!investment.edit && !investment.custom_investment &&
                <div className="h5 text-light-gray ml2">To update this section from the latest company data, press Edit, and then Copy from Company Data.</div>
              }

              <div className="p2">
                {loading === 'co_investors' ?
                  <LoadingSpinner
                    show
                    type="fit"
                    height="150px"
                    background="transparent"
                  />
                  :
                  <div className="flex flex-wrap items-center">
                    { investment.co_investors.length ?
                      investment.co_investors.map((f, idx) => (
                        <Investor
                          key={`co_investors_${Math.random() * idx}`}
                          field={f}
                          edit={investment.edit}
                          options={investment.investor_options}
                          saveFields={this.saveFields}
                          deleteFields={this.removeFields}
                        />
                      ))
                      :
                      <div className="text-light-gray px2">No (Co-)Investors added yet...</div>
                    }
                    { (investment.co_investors.length > 0 && investment.edit) &&
                      <span className={`flex text-orange h6 ${investment.edit ? 'normal' : 'hidden'}`}>Note: Some reports (like slideshow) may limit # of investors shown to the first 3.</span>
                    }
                  </div>
                }
              </div>
              <UserFieldModal
                show={showUserFieldModal}
                userType={userType}
                options={userType === 'investor' ? investment.investor_options : investment.collaborator_options}
                closeModal={() => this.setState({ showUserFieldModal: false })}
                saveFields={this.saveFields}
              />
            </div>
          </div>
          <div className="col-4 sm-col-12 ml1 sm-mt2 sm-ml0">
            <div className="bg-white bs-around flex flex-column p2">
              <div className="flex flex-justify-between px2 pb2">
                <div className="fw400 text-gray">Contacts</div>
                {investment.edit && !investment.custom_investment &&
                  <div
                    className="px1 h6 bg-black--transparent text-white cursor-pointer rounded"
                    onClick={() => this.setState({ showContactsModal: true })}
                  >
                    <i className="fa fa-cog mr1" />Manage
                  </div>
                }
                {showContactsModal &&
                  <ContactsModal
                    show={showContactsModal}
                    closeModal={() => this.setState({ showContactsModal: false })}
                    contacts={investment.contacts}
                    saveFields={this.saveFields}
                    deleteFields={this.removeFields}
                  />
                }
              </div>
              <div className="px2 pb2">
                {investment.contacts.map(c => (
                  <Contact
                    key={`contact_${c.id}`}
                    contact={c}
                    edit={investment.edit}
                    saveInvestmentField={this.overwriteField}
                  />
                ))}
              </div>
            </div>
            {investment.funding_summary.last_round || investment.funding_summary.total_raised !== 'N/A' ?
              <div className="bg-light-gray border border-light-gray bs-around flex flex-column px3 py2 text-white mt2">
                {investment.funding_summary.last_round &&
                  <div className="col-12 flex flex-column h4 mb2">
                    <span className="mb1">Last Round</span>
                    <span className="fw400">{investment.funding_summary.last_round} {investment.funding_summary.last_round_date}</span>
                  </div>
                }
                <div className="col-6 flex flex-column">
                  <span className="h4 mb1">Total Raised</span>
                  <span className="h4 fw400">{investment.funding_summary.total_raised}</span>
                </div>
              </div>
              :
              <div className="bg-light-gray border border-light-gray bs-around flex p3 text-white mt2">
                <div className="col-12 flex flex-column items-center">
                  <span>No funding information available.</span>
                  <span>Go to the funding tab to set them up.</span>
                </div>
              </div>
            }
            <div className="col-12 flex mt2">
              {
                investment.kpis.map((kpi, idx) => (
                  <SmallKpiWidget
                    data={kpi}
                    col="6"
                    key={`kpi_${kpi.field_id}`}
                    padding={idx === 0 ? 'pr1' : 'pl1'}
                    kpiOptions={investment.kpi_options}
                    saveFields={this.saveFields}
                    fieldId={kpi.field_id}
                    showOptions={investment.edit}
                  />
                ))
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Profile;
