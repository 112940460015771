export const setKpisTimeAgo = (timeAgo, frequency) => ({
  type: 'SET_TIME_AGO_HOME_PAGE',
  timeAgo,
  frequency,
});

export const removeItem = (id) => ({
  type: 'REMOVE_ITEM',
  id,
});

export const removeFund = (fund) => ({
  type: 'REMOVE_FUND',
  fund,
});

export const addFund = (fund) => ({
  type: 'ADD_FUND',
  fund,
});

export const updateChart = (chartData, dashboardType, options = {}) => ({
  type: 'UPDATE_CHART_INVESTOR_HOME',
  chartData,
  dashboardType,
  ...options,
});

export const updateTable = (dashboardData, dashboardType, lazyLoadingLayer) => ({
  type: 'UPDATE_TABLE_INVESTOR_HOME',
  dashboardData,
  dashboardType,
  lazyLoadingLayer,
});

export const cardsRemainingData = (cardsData) => ({
  type: 'CARDS_REMAINING_DATA',
  cardsData,
});

export const addUpdatesData = (updatesData) => ({
  type: 'ADD_UPDATES_DATA',
  updatesData,
});

export const addDealRoomsData = (dealRoomsData) => ({
  type: 'ADD_DEAL_ROOMS_DATA',
  dealRoomsData,
});

export const addDocumentsData = (documentsData) => ({
  type: 'ADD_DOCUMENTS_DATA',
  documentsData,
});
