import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Color from 'color';
import KpisOverview from './KpisOverview';
import KpisQuickEdit from './KpisQuickEdit';
import GenericKpiChart from '../investor/modules/GenericKpiChart';
import UpgradeAccountModal from '../modals/UpgradeAccountModal';
import Button from '../Button';

class KpiList extends Component {
  tabs = ['kpi_overview', 'quick_edit']

  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    kpis: PropTypes.object.isRequired,
    refreshKpis: PropTypes.func.isRequired,
    updateKpi: PropTypes.func.isRequired,
    deleteKpi: PropTypes.func.isRequired,
    createOrUpdateKpiValue: PropTypes.func.isRequired,
    getChartData: PropTypes.func.isRequired,
    changeCurrentDate: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      searchQuery: '',
      activeTab: this.tabs[0],
      showUpgradeModal: false,
      editOverview: false,
    };
  }

  handleExport = (link = null) => {
    const { company, currentUser } = this.props;

    const exportPath = link || `/c/${company.id}/kpis/export_all`;

    if (currentUser.investor) {
      if (currentUser.investor.canExport) {
        window.location = exportPath;
      } else {
        this.setState({ showUpgradeModal: true });
      }
    } else {
      window.location = exportPath;
    }
  }

  render() {
    const {
      currentUser,
      kpis,
      refreshKpis, updateKpi, deleteKpi,
      createOrUpdateKpiValue,
      getChartData,
      changeCurrentDate,
    } = this.props;


    const { searchQuery, activeTab, showUpgradeModal, editOverview } = this.state;

    const filteredKpis = kpis.data.filter(d => d.metric.toLowerCase().includes(searchQuery.toLowerCase()));

    let color = '';
    if (kpis.color_theme) {
      if (Color(kpis.color_theme).white() <= 80) {
        color = kpis.color_theme;
      } else {
        color = window.ColorVariables.colorBlue;
      }
    }

    const timeRangeOptions = [
      { value: 'current', label: 'Current Year' },
      { value: 'previous_year', label: 'Previous Year' },
      { value: 'two_years', label: 'Last 2 Years' },
      { value: 'three_years', label: 'Last 3 Years' },
      { value: 'custom', label: 'Custom' },
    ];

    return (
      <React.Fragment>
        <div className="bs-around bg-white mb2 pb1">
          <GenericKpiChart
            kpiOptions={kpis.chart_data.kpi_options}
            data={kpis.chart_data.data}
            chosenKpi={kpis.chart_data.chosen_kpi}
            onKpiChange={getChartData}
            chartMarginBottom="60"
            color={color}
            timeRangeOptions={timeRangeOptions}
            multiKpis
            cumulativeKpis
          />
        </div>

        {editOverview &&
          <div className="mb2">
            <div className="p1 rounded border border-alto bg-extra-light-gray h5 lh-12">
              <i className="fa fa-info-circle text-medium-gray" />
              <span className="ml1 text-medium-gray fw400">
                The edit all option, enables you to quickly edit your KPIs. Please note that changes are saved automatically!
              </span>
            </div>
          </div>
        }

        <div className="bs-around">
          <div className="flex items-center bg-white">
            <i className="fa fa-search p2 ml2 text-light-gray" />
            <input
              className="border-none flex-auto h5"
              placeholder="Search All KPIs"
              type="text"
              onChange={e => this.setState({ searchQuery: e.target.value })}
            />
            <div className="flex mr2">
              <Button
                classNames="btn btn-outline btn-small border-alto bg-green text-white mr2"
                onClick={() => this.handleExport()}
              >
                Export KPI / Template
              </Button>
              <UpgradeAccountModal
                show={showUpgradeModal}
                closeModal={() => this.setState({ showUpgradeModal: false })}
                exportFeature
                sendEmailOnClick
                maxInvestments={currentUser.investor ? currentUser.investor.max_investments : null}
                paywallDescription="Paywall_Export"
              />
            </div>
            {activeTab === 'kpi_overview' &&
              <Button
                classNames="btn btn-outline btn-small border-alto bg-white text-gray mr2"
                onClick={() => this.setState(state => ({ editOverview: !state.editOverview }))}
              >
                <i className="fa fa-pencil h5" />
                <span className="ml1 h5">{editOverview ? 'Back to View' : 'Edit Freq/Unit'}</span>
              </Button>
            }
            {currentUser.can_edit &&
              <div className="flex">
                <div
                  className={`h4 fw400 p2 sm-hide ${activeTab === this.tabs[0] ? 'bg-extra-light-gray' : 'bg-white'} cursor-pointer`}
                  onClick={() => this.setState({ activeTab: this.tabs[0] })}
                >
                  KPI Overview
                </div>
                <div
                  className={`h4 fw400 p2 sm-hide ${activeTab === this.tabs[1] ? 'bg-extra-light-gray' : 'bg-white'} cursor-pointer`}
                  onClick={() => this.setState({ activeTab: this.tabs[1], editOverview: false })}
                >
                  Quick Edit
                </div>
              </div>
            }
          </div>

          {activeTab === this.tabs[0] &&
            <KpisOverview
              currentUser={currentUser}
              edit={editOverview}
              kpis={filteredKpis}
              periodStepOptions={kpis.period_step_options}
              unitOptions={kpis.unit_options}
              refreshKpis={refreshKpis}
              updateKpi={updateKpi}
              deleteKpi={deleteKpi}
              handleExport={(link) => this.handleExport(link)}
            />
          }

          {activeTab === this.tabs[1] &&
            <KpisQuickEdit
              kpis={[...filteredKpis.filter(kpi => (kpi.period_step === 'months' || kpi.period_step === 'quarters' || kpi.period_step === 'years') && !kpi.derived_kpi)]}
              createOrUpdateKpiValue={createOrUpdateKpiValue}
              currentDate={kpis.current_date}
              changeCurrentDate={changeCurrentDate}
            />
          }
        </div>
      </React.Fragment>
    );
  }
}

export default KpiList;
