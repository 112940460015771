import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import LoadingSpinner from '../../LoadingSpinner';
import Avatar from '../../tab_view/Avatar';

class InvestmentUpdatesList extends Component {
  static propTypes = {
    updates: PropTypes.array,
    paginationUrl: PropTypes.string,
    nextPage: PropTypes.number,
    isDiscussion: PropTypes.bool,
    investorId: PropTypes.number.isRequired,
    linkNewTab: PropTypes.bool,
  }

  static defaultProps = {
    updates: null,
    nextPage: null,
    paginationUrl: null,
    isDiscussion: false,
    linkNewTab: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      updates: props.updates,
      nextPage: props.nextPage,
      loading: props.updates == null || props.updates === undefined,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { updates } = nextProps;

    this.setState({ updates, loading: updates == null || updates === undefined });
  }

  loadNextPage = async () => {
    const { paginationUrl } = this.props;
    const { nextPage, updates } = this.state;

    if (!paginationUrl) return;

    try {
      const res = await axios.get(`${paginationUrl}/?page=${nextPage}`);
      this.setState({
        updates: updates.concat(res.data.updates),
        nextPage: res.data.nextPage,
      });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
    }
  }

  filterUpdates = () => {
    const { isDiscussion } = this.props;
    const { updates } = this.state;

    if (isDiscussion === true && updates) {
      return updates.filter(update => update.recent_comments && update.recent_comments.length);
    }
    return updates;
  }

  render() {
    const {
      paginationUrl,
      nextPage,
      investorId,
      isDiscussion,
      linkNewTab,
    } = this.props;

    const { loading } = this.state;

    return (
      <React.Fragment>
        {!loading &&
          (
            this.filterUpdates() && this.filterUpdates().length > 0 ?
              this.filterUpdates().map(update => (
                <div key={update.id} className="flex mxn2 pb1">
                  <div className="col-12 lg-col-12 px2">
                    <div className="bg-white border border-gallery bs-around mb1 relative">

                      {/* Update Publisher */}
                      <div className="flex flex-center sm-flex-column sm-pl1 lg-block pl3 pt1 mt1 pb1">
                        <div className="bg-center bg-cover bg-no-repeat border border-white z1 sm-pl2">
                          <Avatar avatarUrl={update.user_author.avatar} size="32px" />
                        </div>
                        <div className="ml2 pl1 lh-15">
                          <div className="h5 pr2 text-gray z1">
                            <span className="fw400">{update.user_author.name}</span> published this update for <a href={`/investor/${investorId}/investments/${update.investment_id}`}><span className="fw400">{update.company.name}</span></a>
                          </div>
                          <div className="h6 text-medium-gray z1">
                            <span>{update.published_date.month} {update.published_date.day} {update.published_date.year}</span>
                          </div>
                        </div>
                        <div className="h4 ml-auto flex flex-row mr3 sm-hide">
                          <div>
                            <span className="text-gray h5">{update.recent_comments.length || '0'}</span>
                            <i className="fa fa-comments-o ml1 mr2 text-light-gray" />
                          </div>
                          <div>
                            <span className="text-gray h5">{update.likes || '0'}</span>
                            <i className="fa fa-hand-paper-o ml1 text-light-gray" />
                          </div>
                        </div>
                      </div>
                      {/* Update Name + comment count */}
                      <div className="flex flex-center lg-block lg-pb3 pl3 pr3 relative">
                        <a
                          href={`/c/${update.company.id}/published_updates/${update.id}`}
                          target={linkNewTab ? '_blank' : null}
                          rel={linkNewTab ? 'noopener noreferrer' : null}
                        >
                          <span className="h3 lh-34 text-gray z1 fw400">{ update.name } {linkNewTab ? <i className="fa fa-external-link" /> : null}</span>
                        </a>
                      </div>

                      {/* Update content truncated */}
                      <div className="pl3 pb1 pr3">
                        <p className="break-word h5 mt1 text-gray">
                          {update.update_text_truncated ? update.update_text_truncated : '- (no content)'}
                          <a
                            className="ml1 h5"
                            href={`/c/${update.company.id}/published_updates/${update.id}?comments=true`}
                            target={linkNewTab ? '_blank' : null}
                            rel={linkNewTab ? 'noopener noreferrer' : null}
                          >
                            Read Update {linkNewTab ? <i className="fa fa-external-link" /> : null}
                          </a>
                        </p>
                      </div>
                      {update.recent_comments && update.recent_comments.length > 0 &&
                        <React.Fragment>
                          <div className="border-bottom border-gallery ml3 mr3" />
                          <div className="flex items-center lg-block pl3 pb2 pt2">
                            <span className="h5 text-gray z1 fw400">Most Recent Comment(s)</span>
                          </div>
                        </React.Fragment>
                      }
                      {update.recent_comments && update.recent_comments.length > 0 &&
                        update.recent_comments.map(comment => (
                          <div key={comment.id} className="border border-gallery rounded p1 mx3 mb1">
                            <div className="flex items-center lg-block p1">
                              <div className="relative bg-center bg-cover bg-no-repeat border border-white box-24 circle z1">
                                <Avatar avatarUrl={comment.author.avatar} />
                              </div>
                              <span className="flex-auto h5 ml1 pr1 text-gray z1 fw400">{comment.author.name}</span>
                              <div className="flex flex-end">
                                <span className="h5 text-medium-gray z1">
                                  <span>Posted {comment.date}</span>
                                  <span className="ml1">{comment.hour}</span>
                                </span>
                              </div>
                            </div>
                            <div className="pl1 pb1 pr3">
                              <p className="break-word h5 mb1 text-gray">
                                <div>{comment.body}</div>
                              </p>
                            </div>
                          </div>
                        ))
                      }
                      {(update.recent_comments && update.recent_comments.length > 0) &&
                      <div className="pb2 pt1 px3">
                        <a
                          className="h5"
                          href={`/c/${update.company.id}/published_updates/${update.id}?comments=true`}
                          target={linkNewTab ? '_blank' : null}
                          rel={linkNewTab ? 'noopener noreferrer' : null}
                        >
                          Respond here {linkNewTab ? <i className="fa fa-external-link" /> : null}
                        </a>
                      </div>
                      }
                    </div>
                  </div>
                </div>
              ))
              :
              <div className="bg-white border border-gallery bs-around items-center flex flex-justify-center py2" style={{ height: '70px' }}>
                <span className="text-light-gray center h5">There are no {`${isDiscussion ? 'Discussions' : 'Updates'}`} to show...</span>
              </div>
          )
        }

        {paginationUrl && nextPage &&
          <div className="btn btn-block btn-load-more" onClick={this.loadNextPage}>
            Load more Updates
          </div>
        }

        <LoadingSpinner
          show={loading}
          type="fit"
          background="white"
          height="100px"
        />
      </React.Fragment>
    );
  }
}

export default InvestmentUpdatesList;
