import axios from 'axios';
import {
  updateSigner,
} from '../actions/signingRoomRequestActions';

export const uploadSignerDocument = (companyId, requestId, signerId, formData) =>
  dispatch =>
    axios.post(
      `/c/${companyId}/signing_room_requests/${requestId}/signers/${signerId}/documents`,
      formData,
    ).then(
      res => dispatch(updateSigner(res.data)),
    );

export const deleteSignerDocument = (companyId, requestId, signerId, id) =>
  dispatch =>
    App.Api.delete(
      `/c/${companyId}/signing_room_requests/${requestId}/signers/${signerId}/documents/${id}`,
    ).then(
      res => dispatch(updateSigner(res)),
    );

export const requestReupload = (companyId, requestId, signerId, documentId, text) =>
  dispatch =>
    App.Api.post(
      `/company/${companyId}/signing_room_requests/${requestId}/signers/${signerId}/documents/${documentId}/reupload_requests`,
      { text },
    ).then(
      res => dispatch(updateSigner(res)),
    );
