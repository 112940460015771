import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../modals/ConfirmationModal';

class SubscriptionDetails extends Component {
  static propTypes = {
    subscription: PropTypes.object,
    plans: PropTypes.object.isRequired,
    subscribe: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,
    resubscribe: PropTypes.func.isRequired,
  }

  static defaultProps = {
    subscription: null,
  }

  state = {
    hover: false,
    saving: false,
    monthlyPayments: true,
    confirmationModal: null,
  }

  hideConfirmationModal = () => this.setState({ confirmationModal: null });

  subscribe = async planId => {
    const { subscribe } = this.props;
    this.setState({ saving: true });

    try {
      await subscribe({ subscription: { plan_id: planId } });
      App.State.setFlash({ name: 'notice', msg: 'Congratulations! Subscribed successfully.' });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
    }
    this.setState({ saving: false });
  }

  unsubscribe = async () => {
    const { unsubscribe } = this.props;
    this.setState({ saving: true });

    try {
      await unsubscribe();
      App.State.setFlash({ name: 'notice', msg: 'Unsubscribed successfully.' });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
    }
    this.setState({ saving: false });
  }

  reactivateSubscription = async () => {
    const { resubscribe } = this.props;
    this.setState({ saving: true });

    try {
      await resubscribe();
      App.State.setFlash({ name: 'notice', msg: 'Congratulations! Resubscribed successfully.' });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
    }
    this.setState({ saving: false });
  }

  render() {
    const { subscription } = this.props;
    const { hover, saving, confirmationModal } = this.state;

    return (
      subscription.canceled_at ?
        <div className="flex py2 mx2 text-gray border-bottom border-gallery h5">
          <div className="col-3 text-light-gray">Your Plan</div>
          <div className="col-8 fw400">{subscription.title} subscription ends on {subscription.current_period_end}</div>
          <div className="col-1 flex flex-justify-end">
            {saving ?
              <img src={window.images.loading} alt="Resubscribing..." width="24" height="24" />
              :
              <div>
                <div
                  onMouseEnter={() => this.setState({ hover: true })}
                  onMouseLeave={() => this.setState({ hover: false })}
                >
                  {hover ?
                    <span className="text-blue cursor-pointer" onClick={() => this.setState({ confirmationModal: 1 })}>Reactivate</span>
                    :
                    <span className="text-red">Cancelled</span>
                  }
                </div>
                <ConfirmationModal
                  show={!!confirmationModal}
                  closeModal={this.hideConfirmationModal}
                  title={'Reactivate subscription plan'}
                  text={'Are you sure you want to reactivate ' + subscription.title + ' Plan'}
                  onSubmit={() => this.reactivateSubscription()}
                  confirmationText="Reactivate Plan"
                />
              </div>
            }
          </div>
        </div>
        :
        <div className="mx2">
          <div className="flex text-gray pt2 pb1 h5">
            <div className="col-3 text-light-gray">Current Plan</div>
            <div className="col-8 fw400">{subscription.title}, billed {subscription.interval}ly</div>
            <div className="col-1 flex flex-justify-end">
              {saving ?
                <img src={window.images.loading} alt="Unsubscribing..." width="24" height="24" />
                :
                <div>
                  <div className="text-blue cursor-pointer" onClick={() => this.setState({ confirmationModal: 1 })}>
                    Unsubscribe<sup className="h6 text-light-gray">*</sup>
                  </div>
                  <ConfirmationModal
                    show={!!confirmationModal}
                    closeModal={this.hideConfirmationModal}
                    title={'Unsubscribe'}
                    text={'Are you sure you want to unsubscribe from ' + subscription.title + '?'}
                    onSubmit={() => this.unsubscribe()}
                    confirmationText="Unsubscribe Now"
                  />
                </div>
              }
            </div>
          </div>
          <div className="flex flex-justify-end text-light-gray h6 mb1 light border-bottom border-gallery">*If you unsubscribed, the subscription will not end until the end of the billed period.</div>
        </div>

    );
  }
}

export default SubscriptionDetails;
