import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import GenericPieChart from './GenericPieChart';
import InvestmentInfo from '../funds/dashboard/InvestmentInfo';
import CompanyInfo from '../funds/dashboard/CompanyInfo';
import Kpis from '../funds/dashboard/Kpis';
import GenericKpiChart from './GenericKpiChart';

import { defaultSelectStyles, defaultSelectTheme } from '../../selects/SelectStyles';
import UpgradeAccountModal from '../../modals/UpgradeAccountModal';
import { timeRangeOptions } from '../../utils/kpisDataCalculations';


class ChartOrTableViewer extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    chartData: PropTypes.object.isRequired,
    dashboardData: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    timeManage: PropTypes.object,
    getChartData: PropTypes.func,
    getDashboardData: PropTypes.func,
    tabScroll: PropTypes.bool,
    legendTitle: PropTypes.string,
    legendValues: PropTypes.string,
    tableType: PropTypes.string.isRequired,
    investorId: PropTypes.number,
    themeColor: PropTypes.string.isRequired,
    defaultViewType: PropTypes.string,
    chartOnly: PropTypes.bool,
    // showRightSection: PropTypes.bool, not being used for now
    totalsInfo: PropTypes.object,
    exportDataURL: PropTypes.string,
    canExport: PropTypes.bool,
    maxInvestments: PropTypes.number,
    chartTypeEventTracker: PropTypes.func,
    currency: PropTypes.string,
  }

  static defaultProps = {
    dashboardData: null,
    getChartData: null,
    getDashboardData: null,
    timeManage: null,
    tabScroll: false,
    legendTitle: null,
    legendValues: null,
    investorId: null,
    defaultViewType: 'chart',
    chartOnly: false,
    // showRightSection: true,
    totalsInfo: {},
    exportDataURL: null,
    canExport: true,
    maxInvestments: null,
    chartTypeEventTracker: null,
    currency: null,
  }

  constructor(props) {
    super(props);

    const { defaultViewType } = this.props;

    this.state = {
      // loadingChart: true,
      loadingTable: false,
      chosenViewType: defaultViewType,
      hoverChart: false,
      hoverTable: false,
      showUpdatedForKpis: false,
      showUpgradeModal: false,
    };
  }

  shouldComponentUpdate(_nextProps, nextState) {
    const { hoverChart, hoverTable } = this.state;

    if ((hoverChart !== nextState.hoverTable) || (hoverTable !== nextState.hoverTable)) {
      return false;
    }
    return true;
  }

  handleKpiInfoChartData = async (kpiMetric, timeFrame) => {
    const { getChartData, chartData } = this.props;

    const kpiType = chartData.kpi_options.find(kpiOption => kpiOption.value === kpiMetric).type;

    await getChartData(kpiMetric, timeFrame, kpiType);
  }

  loadTableData = async (timeFrame = null, kpiMetric = null, kpiType = null) => {
    const { getDashboardData } = this.props;

    this.setState({ loadingTable: true });

    try {
      await getDashboardData(timeFrame, { kpiMetric, kpiType });
      this.setState({ loadingTable: false });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
      this.setState({ loadingTable: false });
    }
  }

  handleChartTableDataLoad = (timeFrame, kpiMetric = null) => {
    const { chartData, tableType } = this.props;
    this.setState({ showUpdatedForKpis: timeFrame === -1 });
    let kpiType;

    if (tableType === 'kpis_info') {
      const metric = kpiMetric || chartData.chosen_kpi;
      kpiType = chartData.kpi_options.find(kpiOption => kpiOption.value === metric).type;
      this.loadTableData(timeFrame, metric, kpiType);
    } else {
      this.loadTableData(timeFrame);
    }
  }

  handleViewTypeChange = (chosenViewType) => {
    const { chartTypeEventTracker } = this.props;

    this.setState({ chosenViewType }, () => {
      if (chartTypeEventTracker) chartTypeEventTracker(chosenViewType);
    });
  }

  renderViewTypeButtons = () => {
    const { chosenViewType, showUpgradeModal } = this.state;
    const {
      timeManage,
      chartOnly,
      tableType,
      exportDataURL,
      canExport,
      maxInvestments,
    } = this.props;
    let timeOptions;

    const kpiChartOptions = [{ value: 'predefined', label: 'Dashboard KPIs' }];
    if (chosenViewType === 'table' && tableType === 'kpis_info') {
      timeOptions = timeRangeOptions(timeManage.frequency);
    }

    return (
      <React.Fragment>
        <div className="flex items-center col-2">
          {!chartOnly &&
            <React.Fragment>
              <span className="h5 mr1 text-gray">View as</span>
              <div className="flex text-light-gray">
                <div
                  className={`hover-border-medium-gray hover-text-medium-gray border ${chosenViewType === 'chart' ? 'border-medium-gray' : 'border-alto text-alto'} bg-white cursor-pointer flex items-center px1`}
                  onMouseEnter={() => { this.toggleHoverOnChartOrTable(true); }}
                  onMouseLeave={() => { this.toggleHoverOnChartOrTable(true); }}
                  data-balloon="Chart View"
                  data-balloon-pos="down"
                  data-balloon-length="small"
                  onClick={() => this.handleViewTypeChange('chart')}
                  style={{ minHeight: '38px' }}
                >
                  <i className="fa fa-pie-chart" />
                </div>
                <div
                  className={`hover-border-medium-gray hover-text-medium-gray border ${chosenViewType === 'table' ? 'border-medium-gray' : 'border-alto'} bg-white cursor-pointer flex items-center px1 ml1`}
                  onMouseEnter={() => { this.toggleHoverOnChartOrTable(false); }}
                  onMouseLeave={() => { this.toggleHoverOnChartOrTable(false); }}
                  data-balloon="Table View"
                  data-balloon-pos="down"
                  data-balloon-length="small"
                  onClick={() => this.handleViewTypeChange('table')}
                  style={{ minHeight: '38px' }}
                >
                  <i className="fa fa-table" />
                </div>
              </div>
            </React.Fragment>
          }
        </div>
        {chosenViewType === 'table' && tableType === 'kpis_info' &&
        <div className="flex flex-auto flex-justify-between items-center h5">
          <div className="flex items-center col-7">
            <span className="mx2">Show</span>
            <ReactSelect
              className="col-6"
              defaultValue={kpiChartOptions[0]}
              options={kpiChartOptions}
              isClearable={false}
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
              isDisabled
            />
            {
              exportDataURL ?
                <>
                  <div className="flex items-center ml2">
                    <div
                      className="text-light-gray hover-border-medium-gray hover-text-medium-gray border border-alto bg-white cursor-pointer flex items-center px1"
                      data-balloon="Excel Export"
                      data-balloon-pos="down"
                      data-balloon-length="small"
                      onClick={() => { canExport ? window.location = `${exportDataURL}?time_ago=${timeManage.timeAgo}&frequency=${timeManage.frequency}` : this.setState({ showUpgradeModal: true }); }}
                      style={{ minHeight: '38px' }}
                    >
                      <i className="fa fa-file-excel-o" />
                    </div>
                  </div>
                </>
                :
                null
            }
          </div>
          <ReactSelect
            className="col-4 z2"
            value={timeOptions.filter(option => option.value === timeManage.timeAgo)}
            options={timeOptions}
            onChange={(selectedOption) => this.handleChartTableDataLoad(selectedOption.value)}
            isClearable={false}
            styles={defaultSelectStyles}
            theme={defaultSelectTheme}
          />
        </div>
        }
        {chosenViewType === 'table' && tableType !== 'kpis_info' &&
          <div
            className="text-light-gray hover-border-medium-gray hover-text-medium-gray border border-alto bg-white cursor-pointer flex items-center px1 ml-auto"
            data-balloon="Excel Export"
            data-balloon-pos="down"
            data-balloon-length="small"
            onClick={() => { canExport ? window.location = exportDataURL : this.setState({ showUpgradeModal: true }); }}
            style={{ minHeight: '38px' }}
          >
            <i className="fa fa-file-excel-o" />
          </div>
        }
        <UpgradeAccountModal
          show={showUpgradeModal}
          closeModal={() => this.setState({ showUpgradeModal: false })}
          exportFeature
          maxInvestments={maxInvestments}
          paywallDescription="Paywall_Export"
        />
      </React.Fragment>
    );
  }

  toggleHoverOnChartOrTable = (chart) => {
    if (chart) {
      // this.setState({ hoverChart: !this.state.hoverChart });
    } else {
      // this.setState({ hoverTable: !this.state.hoverTable });
    }
  }

  render() {
    const {
      show,
      chartData,
      dashboardData,
      timeManage,
      tabScroll,
      legendTitle,
      legendValues,
      tableType,
      investorId,
      getChartData,
      themeColor,
      totalsInfo,
      exportDataURL,
      canExport,
      maxInvestments,
      currency,
    } = this.props;

    const { loadingTable, chosenViewType, showUpdatedForKpis } = this.state;

    return (
      show &&
        <React.Fragment>
          {chosenViewType !== 'chart' &&
            <div className="px2 pt2 h5 flex flex-center">
              {this.renderViewTypeButtons()}
            </div>
          }

          {/* Charts Rendering */}
          <React.Fragment>
            {chosenViewType === 'chart' &&
              (tableType === 'kpis_info' ?
                <GenericKpiChart
                  data={chartData.data}
                  kpiOptions={chartData.kpi_options}
                  timeRangeOptions={timeRangeOptions(timeManage.frequency)}
                  chosenKpi={chartData.chosen_kpi}
                  chosenTimeline={timeManage.timeAgo}
                  withViewTypeSelect={false}
                  onKpiChange={this.handleKpiInfoChartData}
                  onTimeFrameChange={this.handleChartTableDataLoad}
                  emptyMessage="No KPI data to show from selected months."
                  color={themeColor}
                  loading={chartData.loading}
                  exportDataURL={`${exportDataURL}?time_ago=${timeManage.timeAgo}&frequency=${timeManage.frequency}`}
                  maxInvestments={maxInvestments}
                  canExport={canExport}
                  currency={currency}
                >
                  {this.renderViewTypeButtons()}
                </GenericKpiChart>
                :
                <GenericPieChart
                  missingDataMessage="No data available..."
                  data={chartData.data}
                  withFilter={tableType === 'company_info'}
                  filterOptions={tableType === 'company_info' ? chartData.filter_options : null}
                  onFilterChange={tableType === 'company_info' ? getChartData : null}
                  chosenFilter={tableType === 'company_info' ? chartData.filter : null}
                  legendTitle={legendTitle}
                  legendValues={legendValues}
                  showRightSection={false}
                  loading={chartData.loading}
                  exportDataURL={exportDataURL}
                  maxInvestments={maxInvestments}
                  canExport={canExport}
                >
                  {this.renderViewTypeButtons()}
                </GenericPieChart>
              )
          }
          </React.Fragment>

          {/* Tables/Dashboards rendering */}
          {chosenViewType === 'table' &&
            <div className="p2">
              <div className="bs-around">
                {tableType === 'company_info' &&
                  <CompanyInfo
                    colorTheme={themeColor}
                    data={dashboardData.data}
                    investorId={investorId}
                    showLinks={false}
                    dataLoadInside={false}
                    loading={dashboardData.loading}
                    tabScroll={tabScroll}
                    showChart={false}
                  />
                  }
                {tableType === 'investment_info' &&
                  <InvestmentInfo
                    colorTheme={themeColor}
                    data={dashboardData.data}
                    investorId={investorId}
                    totalsInfo={totalsInfo}
                    totalValues={dashboardData.total_values}
                    loading={dashboardData.loading}
                    tabScroll={tabScroll}
                    showChart={false}
                    showIrr={dashboardData.show_irr}
                  />
                }
                {tableType === 'kpis_info' &&
                  <Kpis
                    data={dashboardData.data}
                    dashboardKpis={dashboardData.dashboard_kpis}
                    investorId={investorId}
                    totalSumValues={dashboardData.total_sum_values}
                    totalMedianValues={dashboardData.total_median_values}
                    totalsInfo={totalsInfo}
                    colorTheme={window.ColorVariables.colorBlue}
                    showUpdated={showUpdatedForKpis}
                    showLinks={false}
                    dataLoadInside={false}
                    loading={loadingTable}
                    tabScroll={tabScroll}
                    showChart={false}
                  />
                }
              </div>
            </div>
          }
        </React.Fragment>
    );
  }
}

export default ChartOrTableViewer;
