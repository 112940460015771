import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import InvestoryLogoSvg from '../../InvestoryLogoSvg';


const ShareholdersOverview = ({ capTable, showOverview, toggleOverview, chartOptions, linkTo, dashboard, currency }) => (
  <div className="bs-around bg-white" style={{ height: '100%' }}>
    <div className={`${dashboard ? '' : 'border-bottom border-gallery'}`}>
      <a
        className={`flex flex-justify-between items-center ${dashboard ? 'p1' : 'px3 sm-px1'}`}
        style={{ minHeight: dashboard ? '' : '48px' }}
        href={linkTo}
      >
        <div>
          <span className={`${dashboard ? 'bold' : 'fw400'} text-gray`}>{capTable.data.round_name} Overview</span>
          {currency &&
            <span className="h5 text-light-gray ml1 fw400">({currency})</span>
          }
        </div>
        {toggleOverview &&
          <span className="cursor-pointer hide-print" onClick={toggleOverview}>
            <i className={`fa fa-chevron-${showOverview ? 'down' : 'right'} fw400 h6 text-gray`} />
          </span>
        }
      </a>
    </div>
    <ReactCSSTransitionGroup transitionName="modal" transitionEnterTimeout={500} transitionLeaveTimeout={500}>
      {showOverview &&
        <div className="flex flex-justify-between p2">
          {capTable.data.shareholders_overview.shareholders_legend.length > 0 ?
            <div className="flex flex-auto pr2 sm-hide">
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
              />
              <div className="mt2 flex flex-column flex-auto mr2">
                <div className="h5 flex flex-justify-between mr2 col-12">
                  <span className="fw400 text-gray col-8">
                    <span
                      data-balloon="Shows the top 5 highest shareholders based on all fully diluted shares owned. All other shareholders are summed up in Others."
                      data-balloon-pos="up"
                      data-balloon-length="medium"
                    >
                      Shareholder
                    </span>
                  </span>
                  <span
                    className="fw400 text-gray col-4 flex flex-justify-center"
                    data-balloon="Total Ownership is the total percentage of fully diluted shares the shareholder is assigned to."
                    data-balloon-pos="up"
                    data-balloon-length="medium"
                  >
                    Ownership
                  </span>
                </div>
                <div
                  className="flex flex-justify-between flex-auto overflow-y col-12"
                  style={{ overflowX: 'hidden', height: '100px' }}
                >
                  <div className="h5 flex flex-column col-8">
                    {capTable.data.shareholders_overview.shareholders_legend.map((legend, index) => (
                      <div className="flex" key={legend.id}>
                        <InvestoryLogoSvg
                          themeColor={capTable.data.shareholders_overview.chart_data[index].color}
                          useAsIconOnly
                        />
                        <span className="mx1 truncate">{legend.value}</span>

                      </div>
                    ))}
                  </div>
                  <div className="h5 flex flex-column flex-end col-4 items-center">
                    {capTable.data.shareholders_overview.ownership_legend.map(legend => (
                      <span key={legend.id}>{legend.value} %</span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="flex flex-justify-center items-center text-medium-gray h5 p3 col-8" style={{ minHeight: '200px' }}>
              No shareholders added to this round yet.
            </div>
          }
          {dashboard ?
            <div className={`border-left border-gallery sm-col-12 pl2 flex flex-column ${dashboard ? 'col-3' : 'col-4'} flex-justify-evenly`}>
              <div className="flex flex-start flex-justify-evenly items-center sm-mb2">
                <div
                  className="flex flex-column items-center flex-1 center"
                  data-balloon={capTable.data.shareholders_overview.cash_raised.raw}
                  data-balloon-pos="bottom"
                  data-balloon-length="medium"
                >
                  <span className="fw400 h3 text-gray mb1">{capTable.data.shareholders_overview.cash_raised.readable}</span>
                  <span className="text-light-gray fw400">Cash Raised</span>
                </div>
              </div>

              <div className="border-bottom border-gallery" />

              <div className="flex flex-end flex-justify-evenly items-center sm-mb2">
                <div
                  className="flex flex-column items-center flex-1 center"
                  data-balloon={capTable.data.shareholders_overview.pre_money.raw}
                  data-balloon-pos="bottom"
                  data-balloon-length="medium"
                >
                  <span className="fw400 h3 text-gray mb1">{capTable.data.shareholders_overview.pre_money.readable}</span>
                  <span className="text-light-gray fw400">Pre-Money</span>
                </div>
              </div>
            </div>
            :
            <div className="border-left border-gallery pl2 flex flex-column sm-col-12 col-4 flex-justify-evenly sm-border-left-none">
              <div className="flex flex-start flex-justify-evenly items-center sm-mb2">
                <div
                  className="flex flex-column items-center flex-1 center"
                  data-balloon={capTable.data.shareholders_overview.cash_raised.raw}
                  data-balloon-pos="bottom"
                  data-balloon-length="medium"
                >
                  <span className="fw400 h3 text-gray mb1">{capTable.data.shareholders_overview.cash_raised.readable}</span>
                  <span className="fw400 h5 text-gray show-print" style={{ display: 'none' }}>({capTable.data.shareholders_overview.cash_raised.raw})</span>
                  <span className="text-light-gray fw400">Cash Raised</span>
                </div>

                <div className="border border-gallery bw-05 my2" style={{ height: '100%' }} />

                <div
                  className="flex flex-column items-center flex-1 center"
                  data-balloon={capTable.data.shareholders_overview.post_money.raw}
                  data-balloon-pos="bottom"
                  data-balloon-length="medium"
                >
                  <span className="fw400 h3 text-gray mb1">{capTable.data.shareholders_overview.post_money.readable}</span>
                  <span className="fw400 h5 text-gray show-print" style={{ display: 'none' }}>({capTable.data.shareholders_overview.post_money.raw})</span>
                  <span className="text-light-gray fw400">Post-Money</span>
                </div>
              </div>

              <div className="border-bottom border-gallery" />

              <div className="flex flex-end flex-justify-evenly items-center sm-mt2">
                <div
                  className="flex flex-column items-center flex-1 center"
                  data-balloon={capTable.data.shareholders_overview.pre_money.raw}
                  data-balloon-pos="bottom"
                  data-balloon-length="medium"
                >
                  <span className="fw400 h3 text-gray mb1">{capTable.data.shareholders_overview.pre_money.readable}</span>
                  <span className="fw400 h5 text-gray show-print" style={{ display: 'none' }}>({capTable.data.shareholders_overview.pre_money.raw})</span>
                  <span className="text-light-gray fw400">Pre-Money</span>
                </div>

                <div className="border border-gallery bw-05 my2" style={{ height: '100%' }} />

                <div
                  className="flex flex-column items-center flex-1 center"
                  data-balloon={capTable.data.shareholders_overview.share_price.raw}
                  data-balloon-pos="bottom"
                  data-balloon-length="medium"
                >
                  <span className="fw400 h3 text-gray mb1">{capTable.data.shareholders_overview.share_price.readable}</span>
                  <span className="fw400 h5 text-gray show-print" style={{ display: 'none' }}>({capTable.data.shareholders_overview.share_price.raw})</span>
                  <span className="text-light-gray fw400">Share Price</span>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </ReactCSSTransitionGroup>
  </div>
);

ShareholdersOverview.propTypes = {
  capTable: PropTypes.object.isRequired,
  showOverview: PropTypes.bool,
  toggleOverview: PropTypes.func,
  chartOptions: PropTypes.object.isRequired,
  linkTo: PropTypes.string,
  dashboard: PropTypes.bool,
  currency: PropTypes.string,
};

ShareholdersOverview.defaultProps = {
  showOverview: true,
  toggleOverview: null,
  linkTo: null,
  dashboard: false,
  currency: null,
};

export default ShareholdersOverview;
