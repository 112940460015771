import React from 'react';
import PropTypes from 'prop-types';

const RadioInput = ({ name, id, options, onClick, inline, value, ...other }) => {
  const alignment = inline ? { display: 'inline' } : { display: 'block' };
  const RadioButtons = options.map(option => (
    <label key={option.value} htmlFor={id} className={inline ? 'mr2' : ''}>
      <input
        type="radio"
        id={id}
        name={name}
        value={option.value}
        style={alignment}
        className="mr1"
        defaultChecked={option.value === value}
        {...other}
        onClick={onClick}
      />
      {option.label}
    </label>
  ));
  return (
    <div>
      {RadioButtons}
    </div>
  );
};

RadioInput.defaultProps = {
  id: '',
  inline: true,
  name: '',
  value: null,
};

RadioInput.propTypes = {
  onClick: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  id: PropTypes.string,
  inline: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
};
export default RadioInput;
