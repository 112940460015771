const getIndexOfTarget = (state, list, id) => state.getIn(['items', list]).findKey(item => item.get('id') === id);
const getIndexOfTab = (state, list, id) => state.getIn([list]).findKey(item => item.get('id') === id);
const fromJS = data => Immutable.fromJS(data);

export default (state = {}, action) => {
  switch (action.type) {
    case 'SET_TAB':
      return state.set('activeTab', action.value);
    case 'UPDATE_TABS':
      return state.setIn([action.list], fromJS(action.value));
    case 'REMOVE_TAB':
      return state.setIn([action.list], state.getIn([action.list]).delete(getIndexOfTab(state, action.list, action.id)));
    case 'ADD_ITEM': {
      const items = state.getIn(['items', action.list]);
      if (Array.isArray(action.value)) {
        const filteredValues = action.value.filter(el => !items.toJS().map(e => e.key).includes(el.key));
        return state.setIn(['items', action.list], fromJS([...items, ...fromJS(filteredValues)]));
      }
      return state.setIn(['items', action.list], items.push(fromJS(action.value)));
    }
    case 'REMOVE_ITEM':
      return state.setIn(['items', action.list], state.getIn(['items', action.list]).delete(getIndexOfTarget(state, action.list, action.id)));
    case 'SET_ITEM':
      return state.setIn(['items', action.list, getIndexOfTarget(state, action.list, action.id)], fromJS(action.value));
    case 'SET_LIST':
      return state.setIn(['items', action.list], fromJS(action.value));
    case 'SET_SORTING':
      return state.set('sorting', fromJS(action.value));
    case 'TOGGLE_ORDER':
      return state.setIn(['sorting', 'order'], 'ASC');
    case 'SET_CHECK_BOX':
      return state.setIn(['withCheckBox', 'checked', action.list], fromJS(action.value));
    case 'SET_FILTER_COUNTS':
      return state.setIn(['filter', 'counts'], fromJS(action.value));
    case 'SET_CURRENT_FILTER':
      return state.setIn(['filter', 'currentFilter'], action.value);
    case 'SET_CURRENT_PAGE':
      return state.setIn(['pagination', 'nextPages', action.list], action.value);
    default: return state;
  }
};
