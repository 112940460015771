import React, { Component } from 'react';
import PropTypes from 'prop-types';


class InvestorDataRoom extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  getLinkIcon = (link) => {
    if (link.includes('google.com')) {
      return ('fa-google');
    }
    if (link.includes('dropbox.com')) {
      return ('fa-dropbox');
    }
    return ('fa-link');
  }

  render() {
    const { data } = this.props;

    return (
      <div className="col-12 flex flex-wrap px2 h5 sm-px0 bg-white">
        <div className="col-4 flex items-center border-alto py2 pl2 sm-border-right-none sm-col-6 sm-pr2">
          <a
            href={data.company_room_url}
            className="flex"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span
              className="flex flex-shrink-none items-center center block col-3 bg-center bg-cover bg-no-repeat circle header__avatar--user mr1"
              style={{ backgroundImage: `url( ${data.company_avatar} )` }}
            />
            {data.real_company &&
              <div className="relative">
                <span className="real_company__notification" style={{ top: '-12px' }} data-behavior="hide-bubble">
                  <i className="fa fa-check-circle text-green" />
                </span>
              </div>
            }
            <span className="flex items-center truncate col-10">{data.name} &nbsp; <i className="fa fa-external-link" /></span>
          </a>
        </div>

        <div className="col-2 flex items-center border-alto py1 pl2 sm-hide">
          <a href={data.first_file_link} className={`${data.first_file ? data.first_file_icon_color : 'text-gray'}`} data-method="get">
            <div className="col-12 flex items-center">
              <i className={data.first_file_is_link ? `fa ${this.getLinkIcon(data.first_file_link)}` : data.first_file_icon_class_name} style={{ color: data.first_file_icon_color }} />
              <span className={`${data.first_file ? 'ml1 text-gray' : 'text-light-gray'}`} title="pitch_deck">{data.first_file ? data.first_file : 'N/A'}</span>
            </div>
          </a>
        </div>

        <div className="col-2 flex items-center border-alto py1 pl2 sm-hide">
          <a href={data.second_file_link} className={`${data.second_file ? data.second_file_icon_color : 'text-gray'}`} data-method="get">
            <div className="col-12 flex items-center">
              <i className={data.second_file_is_link ? `fa ${this.getLinkIcon(data.second_file_link)}` : data.second_file_icon_class_name} style={{ color: data.second_file_icon_color }} />
              <span className={`${data.second_file ? 'ml1 text-gray' : 'text-light-gray'}`} title="business_plan">{data.second_file ? data.second_file : 'N/A'}</span>
            </div>
          </a>
        </div>

        <div className="col-2 flex items-center border-alto py1 pl2 sm-hide">
          <a href={data.third_file_link} className={`${data.third_file ? data.third_file_icon_color : 'text-gray'}`} data-method="get">
            <div className="col-12 flex items-center">
              <i className={data.third_file_is_link ? `fa ${this.getLinkIcon(data.third_file_link)}` : data.third_file_icon_class_name} style={{ color: data.third_file_icon_color }} />
              <span className={`${data.third_file ? 'ml1 text-gray' : 'text-light-gray'}`} title="annual_report">{data.third_file ? data.third_file : 'N/A'}</span>
            </div>
          </a>
        </div>

        <div className="col-2 flex items-center border-alto py1 pl2 sm-hide">
          <a href={data.fourth_file_link} className={`${data.fourth_file ? data.fourth_file_icon_color : 'text-gray'}`} data-method="get">
            <div className="col-12 flex items-center">
              <i className={data.fourth_file_is_link ? `fa ${this.getLinkIcon(data.fourth_file_link)}` : data.fourth_file_icon_class_name} style={{ color: data.fourth_file_icon_color }} />
              <span className={`${data.fourth_file ? 'ml1 text-gray' : 'text-light-gray'}`} title="captable">{data.fourth_file ? data.fourth_file : 'N/A'}</span>
            </div>
          </a>
        </div>

      </div>
    );
  }
}

export default InvestorDataRoom;
