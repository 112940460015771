import axios from 'axios';

import {
  resetKpis,
  resetKpisAndChart,
  resetKpisByCurrentDate,
  removeKpi,
  updateKPIChartData,
} from '../../actions/company/kpiActions';

axios.defaults.headers.common.Accept = 'application/json';
const refreshKpis = () =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;

    const res = await axios.get(`/c/${companyId}/kpis`);
    dispatch(resetKpis(res.data));
  };

const updateKpi = (kpi, params) =>
  async dispatch => {
    const res = await axios.patch(`/company/${kpi.company.id}/kpis/${kpi.id}`, { kpi: params });
    dispatch(resetKpis(res.data));
  };

const deleteKpi = kpi =>
  async dispatch => {
    const res = await axios.delete(`/company/${kpi.company.id}/kpis/${kpi.id}`);
    dispatch(removeKpi(res.data));
  };

// KPI Values API Endpoints
function createKpiValue(kpi, params) {
  return async dispatch => {
    const res = await axios.post(`/company/${kpi.company.id}/kpis/${kpi.id}/kpi_values`, { kpi_value: params, from_quick_edit: true });
    dispatch(resetKpisAndChart(res.data));
  };
}

function updateKpiValue(kpi, id, params) {
  return async dispatch => {
    const res = await axios.patch(`/company/${kpi.company.id}/kpis/${kpi.id}/kpi_values/${id}`, { kpi_value: params });
    dispatch(resetKpisAndChart(res.data));
  };
}

const createOrUpdateKpiValue = (kpi, params) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const currentDate = getState().kpis.current_date;
    const searchRes = await axios.get(`/company/${companyId}/kpis/${kpi.id}/kpi_values/search_kpi_value?current_date=${currentDate}`);
    const kpiValueId = searchRes.data.id;

    if (kpiValueId) {
      await dispatch(updateKpiValue(kpi, kpiValueId, params));
    } else {
      await dispatch(createKpiValue(kpi, params));
    }
  };

const getChartData = (kpiMetric, timeRange, moreOptions = { startDate: null, endDate: null, accumulateOption: null, operationOption: null }) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    let url = `/c/${companyId}/kpis/chart_values?kpi_metric=${kpiMetric}`;
    url += `&time_range=${timeRange}&start_date=${moreOptions.startDate}&end_date=${moreOptions.endDate}`;

    const res = await axios.get(url, { params: { accumulator: moreOptions.accumulateOption, operation: moreOptions.operationOption } });
    await dispatch(updateKPIChartData(res.data));
  };

const changeCurrentDate = (currentDate) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;

    const res = await axios.get(`/company/${companyId}/kpis/load_kpis_data?current_date=${currentDate}`);
    dispatch(resetKpisByCurrentDate(res.data, currentDate));
  };

export {
  refreshKpis,
  updateKpi,
  deleteKpi,
  createOrUpdateKpiValue,
  getChartData,
  changeCurrentDate,
};
