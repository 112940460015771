import PropTypes from 'prop-types';

import { calculateXIRR } from '../../../../utils/fundDashboardFormulas';
import SpotRateWarning from '../../../funds/dashboard/investment_info/SpotRateWarning';

const FundingShareSummary = (
  {
    totalOwnership,
    totalShares,
    totalInvested,
    totalRealized,
    totalInvestmentMultiple,
    currentShareValueRawData,
    currentShareValueReadableData,
    transactionsData,
    showIrr,
    displayCurrency,
    hasConvertedCashflow,
  },
) => {
  let xirrError = null;
  let xirr = null;

  if (showIrr) {
    xirrError = transactionsData.error;
    xirr = calculateXIRR(transactionsData.values, transactionsData.dates, currentShareValueRawData);
  }

  return (
    <div className="flex flex-justify-around flex-wrap center pt1">
      <div className="cursor-default">
        <div className="h3 bold my1">{totalOwnership}</div>
        <div
          data-balloon="This is your current fully-diluted (FD) participation in the company equity, calculated as the sum of the participations in the table below."
          data-balloon-pos="down"
          data-balloon-length="xlarge"
        >
          Total % Ownership
        </div>
      </div>
      <div className="cursor-default">
        <div className="h3 bold my1">{totalShares}</div>
        <div
          data-balloon="Total shares is the number of fully-diluted (FD) shares you currently hold in the compay, calculated as the sum of the FD shares in the table below."
          data-balloon-pos="down"
          data-balloon-length="xlarge"
        >
          Total Shares
        </div>
      </div>
      <div className="cursor-default">
        <div className="h3 bold my1">{totalInvested}</div>
        <div
          data-balloon="Total Invested is calculated by summing up the invested amounts over all shares."
          data-balloon-pos="down"
          data-balloon-length="xlarge"
        >
          Total Invested
        </div>
      </div>
      <div className="cursor-default">
        <div className="h3 bold my1">{totalRealized}</div>
        <div
          data-balloon="Total Realized is calculated by summing up the realized cashflows over all shares."
          data-balloon-pos="down"
          data-balloon-length="xlarge"
        >
          Total Realized
        </div>
      </div>
      <div className="cursor-default">
        <div className="h3 bold my1">{currentShareValueReadableData}</div>
        <div
          data-balloon="This is the sum of current shares values of all your transactions with this company as per the table below."
          data-balloon-pos="down"
          data-balloon-length="xlarge"
        >
          Current Value of Investment
        </div>
      </div>
      <div className="cursor-default">
        <div className="h3 bold my1">{totalInvestmentMultiple}</div>
        <div
          data-balloon="Multiple is a performance metric for your investments in this company. It is calculated as current shares values plus realized cashflows, divided into invested amounts, from all transactions below."
          data-balloon-pos="down"
          data-balloon-length="xlarge"
        >
          Multiple
        </div>
      </div>
      {showIrr &&
        <div className="cursor-default">
          <div className="h3 bold my1">
            {xirr ? (xirr !== 'N/A' ? `${xirr}%` : 'N/A') : ''}
          </div>
          <div className="flex">
            <span
              className={`${xirrError ? 'text-red' : ''}`}
              data-balloon="IRR is used to determine the Internal Rate of Return when the cash flows are at Irregular intervals."
              data-balloon-pos="down"
              data-balloon-length="xlarge"
            >
              IRR
              <div className="relative">
                <span className="desktop__notification--bell" data-behavior="hide-bubble" style={{ borderRadius: '30%', width: '25px', top: '-25px', right: '-25px', zIndex: 1 }}>
                  Beta
                </span>
              </div>
            </span>
            {xirrError ?
              <span
                data-balloon="There are dates missing. Please fill them out so IRR can be calculated."
                data-balloon-pos="down"
                data-balloon-length="xlarge"
              >
                <i className="fa fa-info-circle text-red pl3 h5" />
              </span>
              :
              ''
            }
          </div>
        </div>
      }
      {hasConvertedCashflow &&
        <div className="flex flex-end">
          <SpotRateWarning currency={displayCurrency} />
        </div>
      }
    </div>
  );
};

FundingShareSummary.propTypes = {
  totalOwnership: PropTypes.string.isRequired,
  totalShares: PropTypes.string.isRequired,
  totalInvested: PropTypes.string.isRequired,
  totalRealized: PropTypes.string.isRequired,
  totalInvestmentMultiple: PropTypes.string.isRequired,
  currentShareValueRawData: PropTypes.number.isRequired,
  currentShareValueReadableData: PropTypes.string.isRequired,
  transactionsData: PropTypes.object.isRequired,
  showIrr: PropTypes.bool.isRequired,
  displayCurrency: PropTypes.string.isRequired,
  hasConvertedCashflow: PropTypes.bool.isRequired,
};

export default FundingShareSummary;
