import React from 'react';
import ConversationLink from '../ConversationLink';
import Message from '../Message';
import SetIntervalMixin from '../utils/SetIntervalMixin';

export default {
  mixins: [SetIntervalMixin],

  getInitialState() {
    const data = JSON.parse(this.props.data);

    return {
      conversations: data.conversations,
      messages: data.messages,
      selected_id: data.selected_id,
      author: data.author,
      message_count: data.message_count,
      msg: '',
    };
  },

  componentDidMount() {
    this.setInterval(this.getConversation, 20000);
  },

  setMsg(e) {
    this.setState({
      msg: e.target.value,
    });
  },

  submitMessage() {
    const { msg } = this.state;

    App.Api.submitMessage(
      this.props.conversation_messages_url,
      msg,
    ).then(
      this.setState({ msg: '' }),
    ).then(
      this.getConversation,
    );
  },

  getConversation() {
    const { messages } = this.state;

    App.Api.getConversation(
      this.props.conversation_url,
    ).then((data) => {
      this.setState({
        message_count: data.message_count,
        messages: App.mergeById(messages, data.messages),
        conversations: data.conversations,
      });
    }).then(() => {
      setTimeout(this.scrollDownMessages, 50);
    });
  },

  messages() {
    return (this.state.messages.map((message) => (
      <Message key={message.id} message={message} />
    )));
  },

  conversations() {
    return (this.state.conversations.map((conversation) => (
      <ConversationLink
        key={conversation.id}
        conversation={conversation}
        selected={this.state.selected_id === conversation.id}
      />
    )));
  },

  loadMoreMessages() {
    const currentMessages = this.state.messages;
    const { more_messages_url } = this.props;

    const lastMessageId = currentMessages[0].id;

    App.Api.loadMoreMessages(
      more_messages_url,
      lastMessageId,
    ).then((data) => {
      const { messages, message_count } = data;

      this.setState({
        messages: App.mergeById(currentMessages, messages),
        message_count,
      });
    });
  },

  moreMessages() {
    const { messages, message_count } = this.state;

    if (messages.length === message_count) return null;

    return <div className="btn-more-conversation border border-alto bg-white center mb2 py1 text-outer-space cursor-pointer" onClick={this.loadMoreMessages}>More...</div>;
  },
};
