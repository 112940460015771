const DataRoomStatsDocument = ({ data: file }) => (
  <div key={`file_${file.id}_${Math.random() * file.id}`} className="col-12 flex flex-wrap px2 sm-px0 flex-shrink-none">
    <div className="col-6 py2 pl2 sm-col-9 truncate flex items-center border-bottom border-gallery">
      <span className="h3 fa-fw-l">
        <i className={file.is_link ? 'fa fa-link' : file.icon_class_name} style={{ color: file.icon_color }} />
      </span>
      <span className="text h5 ml2 light-gray" title={ file.name }>
        { file.name }
      </span>
    </div>
    <div className="col-1 py2 flex flex-justify-end sm-hide h5 border-bottom border-gallery" >
      <span className="" title={ file.num_of_views }>{ file.num_of_views > 0 ? `${file.num_of_views}` : '\u2013' }</span>
    </div>
    <div className="col-2 flex flex-justify-end py2 sm-hide h5 border-bottom border-gallery" title={ file.latest_view_time }>
      { file.num_of_views == 0 ? '\u2013' : '' }
      { file.num_of_views > 0 &&
        <div className="flex flex-justify-end col-12">
          { file.latest_view_time }
          <div
            className="col-2 ml1 block bg-center bg-cover bg-no-repeat circle header__avatar--investor"
            style={{ backgroundImage: `url(${file.latest_view_user_avatar})` }}
            data-balloon={file.latest_view_user_name}
            data-balloon-pos='down'
          ></div>
        </div>
      }
    </div>
    <div className="col-1 py2 flex flex-justify-end sm-hide h5 border-bottom border-gallery" title={ file.num_of_downloads }>
      { file.num_of_downloads > 0 ? `${file.num_of_downloads}` : '\u2013' }
    </div>
    <div className="flex flex-justify-end col-2 py2 sm-hide h5 border-bottom border-gallery" title={ file.latest_download_time }>
      { file.num_of_downloads == 0 ? '\u2013' : '' }
      { file.num_of_downloads > 0 &&
        <div className="flex flex-justify-end col-12">
          { file.latest_download_time }
          <div
            className="col-2 ml1 block bg-center bg-cover bg-no-repeat circle header__avatar--investor"
            style={{ backgroundImage: `url(${file.latest_download_user_avatar})` }}
            data-balloon={file.latest_download_user_name}
            data-balloon-pos='down'
          ></div>
        </div>
      }
    </div>
  </div>
);

export default DataRoomStatsDocument;
