import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../store/configureStore';
import DataRoomContainer from '../containers/DataRoomContainer';


const DataRoom = props => (
  <Provider store={configureStore(props)}>
    <DataRoomContainer />
  </Provider>
);

export default DataRoom;
