import { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

class ConnectWithOthers extends Component {
  static propTypes = {
    addedUsers: PropTypes.array,
  }

  static defaultProps = {
    addedUsers: [],
  }

  state = {
    invitedUsers: [],
  }

  removeInvitedUser = (user) => {
    const { invitedUsers } = this.state;

    if (user.id) {
      this.setState({ invitedUsers: invitedUsers.filter(invitedUser => invitedUser.id !== user.id) });
    } else {
      this.setState({ invitedUsers: invitedUsers.filter(invitedUser => invitedUser.email !== user.email) });
    }
  }

  addInvitedUser = (user) => {
    const { invitedUsers } = this.state;

    this.setState({ invitedUsers: [...invitedUsers, user] });
  }

  handleResult = async (result) => {
    const { addedUsers } = this.props;
    const { invitedUsers } = this.state;
    let validateInvitationUrl = null;

    if (result.id) {
      validateInvitationUrl = `/onboarding/invitations?id=${result.id}`;
      if ([...addedUsers, ...invitedUsers].find(user => user.id === result.id)) {
        App.State.setFlash({ name: 'alert', msg: 'Failed to add! The contact was already added.' });
        return;
      }
    } else {
      validateInvitationUrl = `/onboarding/invitations?email=${result.email}`;
      if (invitedUsers.find(user => user.email === result.email)) {
        App.State.setFlash({ name: 'alert', msg: 'Failed to add! The contact was already added.' });
        return;
      }
    }

    try {
      await axios.get(validateInvitationUrl);
      this.addInvitedUser(result);
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
    }
  }
}

export default ConnectWithOthers;
