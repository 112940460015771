import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CleaveNumberFormatting from '../CleaveNumberFormatting';


class OptionPool extends Component {
  static propTypes = {
    setData: PropTypes.func.isRequired,
    optionPoolCurrent: PropTypes.string.isRequired,
    optionPoolNew: PropTypes.string.isRequired,
    optionPoolNewShow: PropTypes.string.isRequired,
    optionPoolPercentage: PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
  }

  setDataDebounce = (e, id, value) => {
    const { setData } = this.props;
    setData(e, id, value);
  }

  setOptionPool = (e) => {
    this.setDataDebounce(e, e.target.id, e.target.rawValue || '0');
  }

  componentWillMount() {
    this.setDataDebounce = _.debounce(this.setDataDebounce, 1000);
  }

  dispalySecondColumn = () => {
    const { settings, optionPoolNew, optionPoolNewShow } = this.props;
    if (settings.edit) {
      return (
        <CleaveNumberFormatting
          value={optionPoolNew}
          onChange={this.setOptionPool}
          className="appearance-textfield border border-alto col-12"
          name="cap_table[optionPoolNew]"
          id="option_pool_new"
        />
      );
    }
    return (
      <p className="center h6 m0 text-gray text-upper">{optionPoolNewShow}</p>
    );
  }

  secondColumn = {
    funding: 'Issued equity',
    later_stage: 'New equity',
  }

  render() {
    const { optionPoolCurrent, optionPoolPercentage, settings: { round } } = this.props;

    return (
      <div className="bg-white bs-around mb2">
        <div className="bg-gallery px2 py1">
          <div className="flex mxn2">
            <div className="w-20p px2">
              <p className="bold h5 center m0 text-gray text-upper">Option pool</p>
            </div>

            <div className="w-20p px2" />

            <div className="w-20p px2">
              <p className="bold center h5 m0 text-gray text-upper">{this.secondColumn[round]}</p>
            </div>

            <div className="w-20p px2">
              <p className="bold center h5 m0 text-gray text-upper">Total equity</p>
            </div>

            <div className="w-20p px2 relative">
              <p className="bold center h5 m0 text-gray text-upper">%</p>

              <span className="absolute mr2 right-0 top-0">
                <div
                  className="text-gray cursor-pointer"
                  data-balloon="The option for employees to buy shares for a discounted or fixed price, guaranteed by the company."
                  data-balloon-pos="up"
                  data-balloon-length="xlarge"
                  title="test"
                >
                  <i className="fa fa-info-circle" />
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className="p2">
          <div className="flex flex-center mxn2">
            <div className="w-20p px2">
              <p className="h6 m0 text-gray center text-upper">Total</p>
            </div>

            <div className="w-20p px2" />

            <div className="w-20p px2">
              {this.dispalySecondColumn()}
            </div>

            <div className="w-20p px2">
              <p className="center h6 m0 text-gray text-upper">{optionPoolCurrent}</p>
            </div>

            <div className="center w-20p px2">
              <span className="h6 m0 text-gray text-upper">{optionPoolPercentage}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OptionPool;
