import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../Modal';
import TextInput from '../../TextInput';


class CreateFundModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    createFund: PropTypes.func.isRequired,
  }

  state = {
    fundName: '',
  }

  handleClose = () => {
    const { closeModal } = this.props;

    this.setState({ fundName: '' });

    closeModal();
  }

  handleCreateFund = async () => {
    const { createFund } = this.props;
    const { fundName } = this.state;

    if (!fundName) App.State.setFlash({ name: 'alert', msg: 'Fund name is missing.' });

    const params = { fund: { name: fundName } };

    try {
      await createFund(params);
      this.handleClose();
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an unknown error happened.' });
      this.handleClose();
    }
  }

  render() {
    const { show } = this.props;
    const { fundName } = this.state;

    return (
      <Modal
        className="bg-white flex flex-column mb2 react_modal bs-around-modal"
        show={show}
        onClickOutside={this.handleClose}
      >
        {/* Modal Header */}
        <div className="p2 px25 relative border-bottom border-lighter-gray">
          <div className="flex items-center flex-justify-between">
            <p className="h3 text-gray fw400 m0">
              Add New Fund
            </p>
            <div
              className="text-gray cursor-pointer"
              onClick={this.handleClose}
            >
              <i className="fa fa-close" />
            </div>
          </div>
        </div>

        {/* Modal Body */}
        <div className="col-12 flex flex-column sm-block p2 px25 overflow-y-auto mxh-70-vh">
          <span className="text-gray mb1">Name</span>
          <TextInput
            className="col-12 border border-light-gray input--placeholder mb2"
            onChange={(e) => { this.setState({ fundName: e.target.value }); }}
            placeholder="Add the name of the new fund here..."
            value={fundName}
            name="fund[name]"
            id="name"
          />
        </div>

        {/* Modal Actions */}
        <div className="col-12 p2 px25 flex flex-justify-end items-center sm-block border-top border-lighter-gray cursor-pointer">
          <div
            className="text-blue mr2 cursor-pointer h5"
            onClick={this.handleClose}
          >
            Cancel
          </div>
          <div className={`${fundName === '' ? 'btn-disabled' : ''} bg-blue text-white fw400 p1 px2 cursor-pointer rounded h5`} onClick={this.handleCreateFund}>
            Create Fund
          </div>
        </div>
      </Modal>
    );
  }
}

export default CreateFundModal;
