import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import InvestoryLogoSvg from '../../InvestoryLogoSvg';


const ShareClassesOverview = ({ capTable, showOverview, toggleOverview, chartOptions }) => (
  <div className="bs-around bg-white">
    <div className="border-bottom border-gallery">
      <div
        className="px3 sm-px1 flex flex-justify-between items-center"
        style={{ minHeight: '48px' }}
      >
        <span className="fw400">{capTable.data.round_name} Overview</span>
        {toggleOverview &&
          <span className="cursor-pointer" onClick={toggleOverview}>
            <i className={`fa fa-chevron-${showOverview ? 'down' : 'right'} fw400 h6`} />
          </span>
        }
      </div>
    </div>
    <ReactCSSTransitionGroup transitionName="modal" transitionEnterTimeout={500} transitionLeaveTimeout={500}>
      {showOverview &&
        <div className="flex flex-justify-between p2 px3">
          <div className="flex flex-auto pr2">
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions}
            />
            <div className="mt2 flex flex-column flex-auto mr2">
              <div className="h5 flex flex-justify-between mr2 col-12">
                <span className="fw400 text-gray col-10">Share Class</span>
                <span className="fw400 text-gray col-2">Ownership</span>
              </div>
              <div
                className="flex flex-justify-between flex-auto overflow-y col-12"
                style={{ overflowX: 'hidden', height: '100px' }}
              >
                <div className="h5 flex flex-column col-10">
                  {capTable.data.share_class_overview.share_class_legend.map((legend, index) => (
                    <div className="flex" key={legend.id}>
                      <InvestoryLogoSvg
                        themeColor={capTable.data.share_class_overview.chart_data[index].color}
                        useAsIconOnly
                      />
                      <span className="mx1 truncate">{legend.value}</span>

                    </div>
                  ))}
                </div>
                <div className="h5 flex flex-column flex-end col-2 items-center">
                  {capTable.data.share_class_overview.ownership_legend.map(legend => (
                    <span key={legend.id}>{legend.value} %</span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="border-left border-gallery pl2 flex flex-column col-4 flex-justify-evenly">
            <div className="flex flex-start flex-justify-evenly items-center">
              <div
                className="flex flex-column items-center flex-1 center"
                data-balloon={capTable.data.shareholders_totals.fully_diluted_shares.raw}
                data-balloon-pos="bottom"
                data-balloon-length="medium"
              >
                <span className="fw400 h3 text-gray mb1">{capTable.data.shareholders_totals.fully_diluted_shares.readable}</span>
                <span className="fw400 h5 text-gray show-print" style={{ display: 'none' }}>({capTable.data.shareholders_totals.fully_diluted_shares.raw})</span>
                <span className="text-light-gray fw400 center lh-12">Fully Diluted Shares</span>
              </div>

              <div className="border border-gallery bw-05 my2" style={{ height: '100%' }} />

              <div
                className="flex flex-column items-center flex-1 center"
                data-balloon={capTable.data.shareholders_totals.total_shares.raw}
                data-balloon-pos="bottom"
                data-balloon-length="medium"
              >
                <span className="fw400 h3 text-gray mb1">{capTable.data.shareholders_totals.total_shares.readable}</span>
                <span className="fw400 h5 text-gray show-print" style={{ display: 'none' }}>({capTable.data.shareholders_totals.total_shares.raw})</span>
                <span className="text-light-gray fw400 center lh-12">Total Shares</span>
              </div>
            </div>

            <div className="border-bottom border-gallery" />

            <div className="flex flex-start flex-justify-evenly items-center">
              <div className="flex flex-column items-center flex-1 center">
                <span className="fw400 h3 text-gray mb1">{capTable.data.share_class_overview.share_class_count}</span>
                <span className="text-light-gray fw400 center lh-12">Share Class(es)</span>
              </div>

              <div className="border border-gallery bw-05 my2" style={{ height: '100%' }} />

              <div
                className="flex flex-column items-center flex-1 center"
                data-balloon={capTable.data.convertible_totals.principal.raw}
                data-balloon-pos="bottom"
                data-balloon-length="medium"
              >
                <span className="fw400 h3 text-gray mb1">{capTable.data.convertible_totals.principal.readable}</span>
                <span className="fw400 h5 text-gray show-print" style={{ display: 'none' }}>({capTable.data.convertible_totals.principal.raw})</span>
                <span className="text-light-gray fw400 cefnter lh-12">Total Principal</span>
              </div>
            </div>
          </div>
        </div>
      }
    </ReactCSSTransitionGroup>
  </div>
);

ShareClassesOverview.propTypes = {
  capTable: PropTypes.object.isRequired,
  showOverview: PropTypes.bool,
  toggleOverview: PropTypes.func,
  chartOptions: PropTypes.object.isRequired,
};

ShareClassesOverview.defaultProps = {
  showOverview: true,
  toggleOverview: null,
};

export default ShareClassesOverview;
