import React from 'react';

const ProFeatures = () => (
  <div className="flex flex-column mr1 light">
    <p className="h2 text-orange ls-n-1">INVESTORY PRO</p>
    <p className="mb1">All Basic Features plus:</p>
    <ul className="flex flex-wrap mx2 mt2 h5">
      <li className="col-12">+ Unlimited External Update Recipients</li>
      <li className="col-12">+ Custom Update Schedule</li>
      <li className="col-12">+ Multiple Shareable Data Rooms (5 Gb each)</li>
      <li className="col-12">+ Company Branding</li>
      <li className="col-12">+ Dedicated Support</li>
    </ul>
  </div>
);
export default ProFeatures;
