import { connect } from 'react-redux';

import Header from '../../../components/investor/investments/Header';

import {
  createOrUpdateFields,
} from '../../../api/investor/investmentApi';

import {
  addManager,
  deleteManager,
  addFundConnection,
  deleteFundConnection,
  selectOverallStatus,
  selectFundraiseStatus,
  deleteCover,
} from '../../../api/investor/investment/headerApi';

const mapStateToProps = state => ({
  investment: state.investmentHeader,
});

const mapDispatchToProps = {
  createOrUpdateFields,
  addManager,
  deleteManager,
  addFundConnection,
  deleteFundConnection,
  selectOverallStatus,
  selectFundraiseStatus,
  deleteCover,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
