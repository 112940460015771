const updateCustomer = customer => ({
  type: 'UPDATE_CUSTOMER',
  customer,
});

const removeCustomer = customer => ({
  type: 'REMOVE_CUSTOMER',
  customer,
});

const subscribeCompany = ({ customer, subscription }) => ({
  type: 'SUBSCRIBE_COMPANY',
  customer,
  subscription,
});

const unsubscribeCompany = subscription => ({
  type: 'UNSUBSCRIBE_COMPANY',
  subscription,
});

const resubscribeCompany = subscription => ({
  type: 'RESUBSCRIBE_COMPANY',
  subscription,
});

export {
  updateCustomer,
  removeCustomer,
  subscribeCompany,
  unsubscribeCompany,
  resubscribeCompany,
};
