import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ConfirmationModal from '../ConfirmationModal';
import HtmlComponent from '../tab_view/HtmlComponent';
import ShareClassesOverview from './share_classes/ShareClassesOverview';

import SorterHeader from '../modules/SorterHeader';
import withSorting from '../utils/withSorting';

class ShareClasses extends Component {
  static propTypes = {
    editor: PropTypes.bool.isRequired,
    capTable: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    sortedData: PropTypes.array.isRequired,
    showOverview: PropTypes.bool.isRequired,
    showList: PropTypes.bool.isRequired,
    toggleOverview: PropTypes.func.isRequired,
    actionsSection: PropTypes.func.isRequired,
    chartOptions: PropTypes.object.isRequired,
    toogleModal: PropTypes.func.isRequired,
    updateEditingShareClassId: PropTypes.func.isRequired,
    deleteShareClass: PropTypes.func.isRequired,
  }

  handleEdit = async (shareClassId) => {
    const { toogleModal, updateEditingShareClassId } = this.props;

    await updateEditingShareClassId(shareClassId);
    toogleModal();
  }

  handleDelete = async (shareClassId) => {
    const { deleteShareClass } = this.props;

    try {
      await deleteShareClass(shareClassId);
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
    }
  }

  render() {
    const {
      editor,
      capTable,
      company,
      sortedData,
      showOverview,
      showList,
      toggleOverview,
      actionsSection,
      chartOptions,
    } = this.props;

    return (
      <React.Fragment>
        {/* Overview */}
        <ShareClassesOverview
          capTable={capTable}
          showOverview={showOverview}
          toggleOverview={toggleOverview}
          chartOptions={chartOptions}
        />

        {/* ShareClasses list */}
        <div className="bs-around bg-white mt3">
          {actionsSection()}

          {showList &&
            <div className="p3">
              <div>
                <div className="col-12 flex px2 py1 text-medium-gray h5 fw400 flex center bg-extra-light-gray border-bottom border-gallery lh-12">
                  <div className="col-3 sm-col-6 flex flex-justify-start items-center">
                    <SorterHeader text="Name" field="name" />
                  </div>
                  <div className="col-4 sm-hide flex flex-justify-start items-center">
                    <span
                      data-balloon="The rights attached to the share class that owners of shares with this share clas are entitled to."
                      data-balloon-pos="up"
                      data-balloon-length="medium"
                    >
                      <SorterHeader text="Entitlements" field="entitlements_sort" hideIcon />
                    </span>
                  </div>
                  <div className="col-1 sm-hide flex flex-justify-center items-center">
                    <span
                      data-balloon="The seniority determines the liquidation preference order. A higher number representing a higher priority. Lowest priority is usually 1 (by default)."
                      data-balloon-pos="up"
                      data-balloon-length="xlarge"
                    >
                      <SorterHeader text="Seniority" field="seniority" hideIcon />
                    </span>
                  </div>
                  <div className="col-2 sm-hide flex flex-justify-center items-center">
                    <span
                      data-balloon="The % of total shares the shareholder owns relative to the total amount of shares outstanding/issued."
                      data-balloon-pos="up"
                      data-balloon-length="medium"
                    >
                      <SorterHeader text="% of Share Capital" field="perc_of_share_capital" hideIcon />
                    </span>
                  </div>
                  <div className="col-2 sm-col-6 flex flex-justify-center items-center">
                    <span
                      data-balloon="The % of fully diluted shares the shareholder owns relative to the total amount of shares outstanding/issued."
                      data-balloon-pos="up"
                      data-balloon-length="medium"
                    >
                      <SorterHeader text="% of Fully Diluted Shares" field="perc_of_fully_diluted_shares" hideIcon />
                    </span>
                  </div>
                </div>

                <div className="flex flex-column h5">
                  {sortedData.map(shareClass => (
                    <div key={shareClass.id} className="flex border-bottom border-gallery show-pencil-on-hover show-trash-on-hover p2 hover hover-bg-wild-sand">
                      <div className="flex flex-auto">
                        <div className={`col-3 flex flex-justify-start fw400 ${editor ? 'cursor-pointer' : ''}`} onClick={editor ? () => this.handleEdit(shareClass.id) : null}>
                          {shareClass.name}
                        </div>
                        <div className="col-4 flex flex-justify-start">
                          <div className="flex flex-column">
                            {shareClass.entitlements.map(el => (
                              <div key={el.text} className={`flex text-${el.value ? 'green' : 'light-gray'} items-center`}>
                                <i className={`fa fa-fw fa-${el.value ? 'check' : 'close'}`} />
                                <span className="ml1">{el.text}</span>
                              </div>
                            ))}
                            <div className={`flex text-${(shareClass.values.note || shareClass.values.data_room_file_id) ? 'green' : 'light-gray'} ${shareClass.values.note ? 'flex-justify-start' : 'items-center'}`}>
                              <i className={`fa fa-fw fa-${(shareClass.values.note || shareClass.values.data_room_file_id) ? 'check pt05' : 'close'}`} />
                              <div className="flex-column">
                                <span className="ml1">Other{(shareClass.values.note || shareClass.values.data_room_file_id) ? ': ' : ''}</span>
                                {shareClass.values.note &&
                                  <HtmlComponent data={{ html: shareClass.other.note, css_class: 'ml1 lh-15 text-gray' }} />
                                }
                                {shareClass.values.data_room_file_id &&
                                  <a className="ml1 h5" href={` /c/${company.id}/data_room_files/${shareClass.values.data_room_file_id}`}>
                                    <i className={shareClass.other.icon_class_name} style={{ color: shareClass.other.icon_color }} />
                                    <span className="pl1 lh-15">{shareClass.other.file_name}</span>
                                  </a>
                                }
                              </div>
                            </div>

                          </div>
                        </div>
                        <div className="col-1 flex flex-justify-center pr3">{shareClass.seniority}</div>
                        <div className="col-2 flex flex-justify-center pr3">{shareClass.readable_perc_of_share_capital} %</div>
                        <div className="col-2 flex flex-justify-center pr3">{shareClass.readable_perc_of_fully_diluted_shares} %</div>
                      </div>

                      {editor &&
                        <div className="flex flex-column flex-justify-center items-center mnr-25em">
                          <div
                            className="cursor-pointer hover-text-blue"
                            data-balloon="Edit Share Class"
                            data-balloon-pos="right"
                            data-balloon-length="small"
                            onClick={() => this.handleEdit(shareClass.id)}
                          >
                            <i className="fa fa-pencil opacity-0" />
                          </div>

                          <div
                            className="cursor-pointer"
                            data-balloon="Delete Share Class"
                            data-balloon-pos="right"
                            data-balloon-length="small"
                          >
                            <ConfirmationModal
                              html={'<i class="fa fa-trash opacity-0" />'}
                              className="hover-text-red"
                              message="<b>You are about to permanently delete this Share Class. This cannot be undone.</b><br/><br/>Are you sure you want to delete?"
                              onClick={() => this.handleDelete(shareClass.id)}
                            />
                          </div>
                        </div>
                      }
                    </div>
                  ))}

                  {(capTable.data.unassigned_shares.perc_of_share_capital || capTable.data.unassigned_shares.perc_of_fully_diluted_shares) &&
                    <div className="flex border-bottom border-gallery">
                      <div className="flex flex-auto h5 p2">
                        <div className="col-8 flex">
                          <div className="col-8 flex flex-justify-start mb1 pl2 mxn2 fw400">N/A</div>
                        </div>
                        <div className="col-2 flex flex-justify-center">
                          {capTable.data.unassigned_shares.perc_of_share_capital} %
                        </div>
                        <div className="col-2 flex flex-justify-center">
                          {capTable.data.unassigned_shares.perc_of_fully_diluted_shares} %
                        </div>
                      </div>
                    </div>
                  }
                  <div className="flex px2 py1 fw400 text-medium-gray mt1 items-center h5">
                    <div className="col-8 flex">
                      <div className="col-8 flex flex-justify-start mb1 pl2 mxn2">Total</div>
                    </div>
                    <div className="col-2 flex flex-justify-center">{capTable.data.share_class_totals.perc_of_share_capital} %</div>
                    <div className="col-2 flex flex-justify-center">{capTable.data.share_class_totals.perc_of_fully_diluted_shares} %</div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </React.Fragment>
    );
  }
}

export default withSorting(ShareClasses, (props) => props.capTable.data && props.capTable.data.share_classes);
