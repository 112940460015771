const field = (state = {}, action) => {
  switch (action.type) {
    case 'OVERWRITE_FUNDING_FIELDS':
      return action.fields.find(f => f.overwrite === state.id) || state;

    case 'UPDATE_FUNDING_FIELDS':
      return action.fields.find(f => f.id === state.id) || state;

    case 'RESET_FUNDING_FIELDS':
      if (action.ids.includes(state.id)) {
        return {
          ...state,
          id: state.overwrite,
          value: state.original,
          readable_value: state.original,
        };
      }
      return state;

    default: return state;
  }
};

export default field;
