import React, { Component } from 'react';
import PropTypes from 'prop-types';

import KpiChart from './kpis/KpiChart';
import withSorting from '../../../utils/withSorting';
import SorterHeader from '../../../modules/SorterHeader';
import KpisTotalSum from './kpis/KpisTotalSum';
import KpisMedian from './kpis/KpisMedian';
import LoadingSpinner from '../../../LoadingSpinner';
import GenericKpiChart from '../../modules/GenericKpiChart';
import CheckBox from '../../../CheckBox';

import { kpiMetricForTotals, timeRangeOptions } from '../../../utils/kpisDataCalculations';


class Kpis extends Component {
  static propTypes = {
    show: PropTypes.bool,
    data: PropTypes.array,
    dashboardKpis: PropTypes.array.isRequired,
    sortedData: PropTypes.array.isRequired,
    chartData: PropTypes.object,
    getChartData: PropTypes.func,
    onTimeFrameChange: PropTypes.func,
    investorId: PropTypes.number.isRequired,
    colorTheme: PropTypes.string.isRequired,
    showUpdated: PropTypes.bool,
    frequency: PropTypes.string,
    totalSumValues: PropTypes.array,
    totalMedianValues: PropTypes.array,
    totalsInfo: PropTypes.object,
    loading: PropTypes.bool,
    showLinks: PropTypes.bool,
    tabScroll: PropTypes.bool,
    showChart: PropTypes.bool,
    children: PropTypes.node,
    reavealSourceOption: PropTypes.bool,
    exportDataURL: PropTypes.string,
    blurred: PropTypes.bool.isRequired,
    canExport: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    show: true,
    data: [],
    chartData: {},
    getChartData: null,
    onTimeFrameChange: null,
    totalsInfo: {},
    showUpdated: false,
    frequency: null,
    totalSumValues: [],
    totalMedianValues: [],
    loading: false,
    showLinks: false,
    tabScroll: false,
    showChart: true,
    children: null,
    reavealSourceOption: false,
    exportDataURL: null,
  }

  constructor(props) {
    super(props);

    const { reavealSourceOption } = props;

    this.state = {
      loading: false,
      source: reavealSourceOption,
    };
  }

  componentDidMount = () => {
    const { colorTheme } = this.props;

    if ($(this.divKpis)[0]) {
      $(this.divKpis)[0].style.setProperty('--investor-header-chart-values-color', colorTheme);
    }
  }

  textColor = (val, color) => {
    if (val === 'N/A' || val === '') {
      return '';
    }
    return color;
  }

  handleKpiInfoChartData = async (kpiMetric, timeFrame) => {
    const { getChartData, chartData } = this.props;

    const kpiType = chartData.kpi_options.find(kpiOption => kpiOption.value === kpiMetric).type;

    await getChartData(kpiMetric, timeFrame, kpiType);
  }

  handleTimeFrameChange = async (timeFrame, kpiMetric) => {
    const { onTimeFrameChange, chartData } = this.props;

    const kpiType = chartData.kpi_options.find(kpiOption => kpiOption.value === kpiMetric).type;

    await onTimeFrameChange(timeFrame, { kpiMetric, kpiType });
  }

  renderHeaders = () => {
    const { showUpdated, dashboardKpis } = this.props;

    return (
      <div className="col-12 flex py1 px1 text-light-gray h5 fw400 flex p1 center bg-wild-sand border-top border-bottom border-alto">
        <div className="width-20p pr2 sm-width-50p">
          <SorterHeader text="Name" field="name" />
        </div>
        <div className="width-10p sm-hide">
          {
            showUpdated &&
            <SorterHeader text="Updated" field="kpi0" kpi={0} hideIcon />
          }
        </div>
        {dashboardKpis.map((kpi, index) => (
          <div
            key={`${kpi.metric}_${index + 1}`}
            className={`width-11p ${index === 0 ? 'sm-width-50p' : 'sm-hide'}`}
          >
            <SorterHeader text={kpi.metric} field={`${kpi.metric}${index + 1}`} kpi={index + 1} textWidth="90px" hideIcon overflowWrap />
          </div>
        ))}
      </div>
    );
  }

  showKpisOrPlaceholder = (item) => {
    const { colorTheme } = this.props;
    const { source } = this.state;

    if (item.can_see_company_kpis) {
      const kpis = item.kpis.filter(kpi => kpi.type === 'dashboard_kpi' || kpi.lastest_kpis_update_date).map((kpi, index) => {
        if (kpi.lastest_kpis_update_date) {
          if (kpi.lastest_kpis_update_date.value) {
            if (kpi.lastest_kpis_update_date.value === '-') {
              return (
                <span
                  key={kpi.key}
                  className="width-10p fw400 h6 center sm-hide"
                  data-balloon="This company has not updated these KPIs in the last 5 months."
                  data-balloon-pos="down"
                  data-balloon-length="large"
                >
                  {kpi.lastest_kpis_update_date.value}
                </span>
              );
            }
            return <span key={kpi.key} className="width-10p fw400 h6 center">{kpi.lastest_kpis_update_date.value}</span>;
          }
          // For preventing the 'jumps' in the dashboard
          if (index === 0) {
            return (<span key={kpi.key} className="width-10p fw400 h6 center" />);
          }
        } else {
          return (
            <KpiChart
              key={kpi.key}
              data={kpi}
              colorTheme={colorTheme}
              customInvestment={item.custom_investment}
              showSource={source}
            />
          );
        }
        return null;
      });
      return kpis;
    }
    return (
      <div className="pl1 flex flex-justify-center text-alto h6" style={{ width: '77%' }}>
        Access to KPIs not granted by this company
      </div>
    );
  }

  render() {
    const {
      show,
      sortedData,
      chartData,
      dashboardKpis,
      investorId,
      totalSumValues,
      totalMedianValues,
      totalsInfo,
      colorTheme,
      loading: loadingProps,
      showLinks,
      tabScroll,
      showChart,
      children,
      reavealSourceOption,
      exportDataURL,
      blurred,
      canExport,
      frequency,
    } = this.props;

    const { loading, source } = this.state;

    const isLoading = loadingProps || loading;

    const medianValues = {
      currency: totalsInfo.currency,
      rates_updated_at: totalsInfo.ratesUpdatedAt,
      values: totalMedianValues,
    };

    const totalValues = {
      currency: totalsInfo.currency,
      rates_updated_at: totalsInfo.ratesUpdatedAt,
      values: totalSumValues,
    };

    return (
      show ?
        <React.Fragment>
          {/* Totals overview bar */}
          {showChart &&
            <div className="bg-white bs-around mb2 p2">
              <GenericKpiChart
                data={chartData.data}
                kpiOptions={chartData.kpi_options}
                timeRangeOptions={timeRangeOptions(frequency)}
                chosenKpi={chartData.chosen_kpi}
                chosenTimeline={chartData.chosen_time_frame}
                onKpiChange={this.handleKpiInfoChartData}
                onTimeFrameChange={this.handleTimeFrameChange}
                emptyMessage="No KPI data to show from selected months."
                loading={chartData.loading || loadingProps}
                color={colorTheme}
                exportDataURL={`${exportDataURL}?time_ago=${chartData.chosen_time_frame}&frequency=${frequency}`}
                blurred={blurred}
                canExport={canExport}
                currency={totalsInfo.currency}
              />
              {/* Totals Section */}
              <div className="flex col-12 mt1 mb1 pt2 pb2 flex flex-justify-between bg-white bs-around">
                {dashboardKpis.map((kpi, index) => (
                  <div key={`${kpi.metric}_${index + 1}`} className={`flex flex-column w-142p border-lighter-gray ${index !== dashboardKpis.length - 1 ? 'border-right' : ''}`}>
                    <div
                      className={`bold center investor-dashboard__chart-value-color--na ${blurred ? 'hide-data' : ''}`}
                      style={{ color: this.textColor((kpi.sum_type === 'total' ? totalValues.values[index] : medianValues.values[index]), colorTheme), height: '22px' }}
                    >
                      {(kpi.sum_type === 'total' ? totalValues.values[index] : medianValues.values[index]) === '' ? 'N/A' : `${(kpi.sum_type === 'total' ? totalValues.values[index] : medianValues.values[index])} ${kpi.unit === 'currency' ? totalsInfo.currency : ''}`}
                    </div>
                    <div className="h5 center text-light-gray overflow-wrap">
                      {`${kpi.sum_type === 'total' ? 'Total' : 'Median'} ${kpiMetricForTotals(kpi.metric)}`}
                    </div>
                  </div>
                ))
                }
              </div>
            </div>
          }
          {reavealSourceOption &&
            <div className="flex">
              <div className="relative">
                <div
                  className="cursor-pointer flex flex-center h5"
                  data-balloon="Reveals the data source (company or custom meaning from investment profile) for each KPI and investment info on this dashboard."
                  data-balloon-pos="right"
                  data-balloon-length="large"
                >
                  <CheckBox
                    fixedWidth
                    checkboxSize="h4"
                    defaultChecked={source}
                    onChange={() => this.setState({ source: !source })}
                  />
                  <span className="ml1 cursor-default">
                    Reveal Data Source
                  </span>
                </div>
              </div>
            </div>
          }

          <div className="col-12 flex flex-column mt1 bg-white bs-around">
            <div className={`col-12 ${children ? 'border-wild-sand border-top' : ''}`}>
              <div className="col-12">

                {/* Renders the search bar and month select tab */}
                {children}

                {this.renderHeaders()}

                <div
                  className={`col-12 ${tabScroll ? 'overflow-y-auto xl-max-height x-max-height lg-max-height' : 'overflow-hidden'}`}
                  ref={(c) => { this.divKpis = c; }}
                >
                  {
                    !isLoading && (
                      sortedData.length === 0 ?
                        <img className="col-12" alt="Nothing to display" src={window.images.investorEmpty} />
                        :
                        sortedData.map(item => (
                          <a
                            key={`kpi_info_${item.id}`}
                            className="col-12 flex hover-bg-wild-sand px1 text-gray flex flex-center relative cursor-pointer py1"
                            style={{ paddingTop: '20px', paddingBottom: '20px' }}
                            href={`/investor/${investorId}/investments/${item.id}/kpi_fields`}
                          >
                            <div className="width-20p pr2 py1 sm-width-50p">
                              <div className="ml1 text-gray fw400 flex flex-center">
                                <span
                                  className="block col-3 bg-center bg-cover bg-no-repeat circle header__avatar--user mr1"
                                  style={{ backgroundImage: `url( ${item.avatar} )` }}
                                />
                                {item.real_company &&
                                  <div className="relative">
                                    <span className="real_company__notification" data-behavior="hide-bubble">
                                      <i className="fa fa-check-circle text-green" />
                                    </span>
                                  </div>
                                }
                                <div className="col-9 flex flex-center h5">
                                  <div className="truncate">
                                    {item.name}
                                  </div>
                                  <i className={`fa ${item.followed ? 'fa-star' : ''} text-green ml1`} />

                                  {showLinks &&
                                    <div className="flex lh-15">
                                      <a
                                        className="flex border rounded border-alto hover hover-border-gray text-alto hover-text-gray px1 sm-hide"
                                        href={`/investor/${investorId}/investments/${item.id}`}
                                      >
                                        <span className="h6">Profile</span>
                                      </a>
                                      {!item.custom_investment &&
                                        <a
                                          className="flex border rounded border-alto hover hover-border-gray text-alto hover-text-gray px1 ml1 sm-hide"
                                          href={`/investor/${investorId}/investments/${item.id}`}
                                        >
                                          <span className="h6">Dashboard</span>
                                        </a>
                                      }
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                            {this.showKpisOrPlaceholder(item)}
                          </a>
                        ))
                    )
                  }
                  <LoadingSpinner
                    show={isLoading}
                    type="fit"
                    height="300px"
                    background="white"
                  />
                </div>
                {
                  !isLoading &&
                  <div className="flex flex-column col-12 bs-around sm-hide">
                    {
                      !$.isEmptyObject(medianValues) &&
                      <KpisMedian
                        medianValues={medianValues}
                        dashboardKpis={dashboardKpis}
                        colorTheme={colorTheme}
                      />
                    }
                    {
                      !$.isEmptyObject(totalValues) &&
                      <KpisTotalSum
                        totalValues={totalValues}
                        dashboardKpis={dashboardKpis}
                        colorTheme={colorTheme}
                      />
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        </React.Fragment>
        :
        null
    );
  }
}

export default withSorting(Kpis, (props) => props.data);
