const defaultState = {
  field: '',
  order: '',
};

const sorting = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_SORTING':
      return action.sorting;

    case 'TOGGLE_ORDER':
      return {
        ...state,
        order: state.order === 'DESC' ? 'ASC' : 'DESC',
      };

    default: return state;
  }
};

export default sorting;
