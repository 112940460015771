import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import TextInput from '../TextInput';


class TemplateEditButton extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
  }

  defaultProps = {
    name: '',
    description: '',
  }

  state = {
    open: false,
    name: this.props.name,
    description: this.props.description,
  }

  updateTemplate = () => {
    const { url } = this.props;
    const { name, description } = this.state;

    App.Api.submitPatch(url, { name, description }).then((data) => {
      this.setState({ open: false });
      store.dispatch({ type: 'SET_ITEM', list: 'templates', id: data.id, value: data });
    });
  }

  render() {
    const { open, name, description } = this.state;

    return (
      <div className="">
        <div
          className="cursor-pointer text-gray mx1"
          onClick={() => { this.setState({ open: true }); }}
          data-balloon="Edit name and description"
          data-balloon-pos="down"
        >
          <i className="fa fa-pencil fa-fw" />
        </div>
        <Modal
          className="bg-white flex flex-column mb2 react_modal"
          show={open}
          onClickOutside={() => { this.setState({ open: false }); }}
        >
          <div
            className="flex flex-auto flex-justify-end cursor-pointer p1"
            onClick={() => { this.setState({ open: false }); }}
          >
            <i className="fa fa-close text-gray" />
          </div>
          <p className="col-12 center semi-bold py2 h2">Edit template name and description</p>
          <div className="col-12 flex flex-column flex-center sm-block bg-alabaster p3 sm-center">
            <TextInput
              className="col-12"
              onChange={e => { this.setState({ name: e.target.value }); }}
              placeholder="Name of the template"
              value={name}
              name="template[name]"
              id="name"
            />
            <TextInput
              className="col-12 mt2"
              onChange={e => { this.setState({ description: e.target.value }); }}
              placeholder="Short descripton of the template"
              value={description}
              name="template[description]"
              id="name"
            />
            <div className="bg-blue mt2 text-white semi-bold p1 px2 cursor-pointer" onClick={this.updateTemplate}>
              Save changes
            </div>
          </div>
        </Modal>
      </div>

    );
  }
}

export default TemplateEditButton;
