import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Avatar from './Avatar';
import FaButtonFunction from './FaButtonFunction';
import InvestmentCardAdmin from './InvestmentCardAdmin';
import SelectWithModal from '../selects/SelectWithModal';


class InvestorInvestment extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  state = {
    openOverallStatusDropdown: false,
    openFundraisingStatusDropdown: false,
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
    document.addEventListener('touchstart', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
    document.removeEventListener('touchstart', this.handleClick, false);
  }


  getColClass = () => {
    const { data } = this.props;
    let company,
      added,
      funds,
      managers;

    const fundraisingStatus = 'col-1';
    let overallStatus = 'col-1';

    // angel has no funds, no fundraising status and no fund manager
    if (data.angel) {
      company = 'col-5';
      added = 'col-3';
      overallStatus = 'col-3';
    } else if (!data.has_funds) {
      company = 'col-4';
      added = 'col-2';
      managers = 'col-3';
    } else {
      company = 'col-4';
      added = 'col-1';
      funds = 'col-2';
      managers = 'col-2';
    }
    return { company, added, funds, managers, fundraisingStatus, overallStatus };
  }

  accept = () => {
    const { data } = this.props;

    App.Api.patch(data.accept_url).then((returnedData) => {
      store.dispatch({ type: 'SET_ITEM', list: 'main', id: returnedData.id, value: returnedData });
      App.State.setFlash({ name: 'notice', msg: 'Portfolio request accepted!' });
    }).then(() => {
      this.updateFiltersCount();
    });
  }

  addFundConnection = (id) => {
    const { data } = this.props;

    App.Api.post(data.fund_assign_url, { fund_connection: { fund_id: id } }).then((returnedData) => {
      store.dispatch({ type: 'SET_ITEM', list: 'main', id: returnedData.id, value: returnedData });
    }).then(() => {
      this.updateFiltersCount();
    });
  }

  addManager = (id) => {
    const { data } = this.props;

    App.Api.post(data.manager_assign_url, { investment_manager: { membership_id: id } }).then((returnedData) => {
      store.dispatch({ type: 'SET_ITEM', list: 'main', id: returnedData.id, value: returnedData });
    }).then(() => {
      this.updateFiltersCount();
    });
  }

  decline = () => {
    const { data } = this.props;

    App.Api.delete(data.manage_url).then(() => {
      store.dispatch({ type: 'REMOVE_ITEM', list: 'main', id: data.id });
      App.State.setFlash({ name: 'notice', msg: 'Portfolio request declined!' });
    }).then(() => {
      this.updateFiltersCount();
    });
  }

  deleteFundConnection = (id) => {
    const { data } = this.props;
    const destroyUrl = `/investor/${data.investor_id}/investments/${data.id}/fund_connections/${id}`;

    App.Api.delete(destroyUrl).then((returnedData) => {
      if (!returnedData.in_all_investments_filter) {
        store.dispatch({ type: 'REMOVE_ITEM', list: 'main', id: returnedData.id });
      } else {
        store.dispatch({ type: 'SET_ITEM', list: 'main', id: returnedData.id, value: returnedData });
      }
    }).then(() => {
      this.updateFiltersCount();
    });
  }

  deleteManager = (id) => {
    const { data } = this.props;
    const destroyUrl = `/investor/${data.investor_id}/investments/${data.id}/investment_managers/${id}`;

    App.Api.delete(destroyUrl).then((returnedData) => {
      store.dispatch({ type: 'SET_ITEM', list: 'main', id: returnedData.id, value: returnedData });
    }).then(() => {
      this.updateFiltersCount();
    });
  }

  displayActions = () => {
    const { data } = this.props;

    if (data.admin && data.pending) {
      return (
        <div className="flex flex-column flex-justify-end">
          <FaButtonFunction faIcon="check" tooltip="Accept" handleClick={this.accept} color="green" />
          <FaButtonFunction faIcon="times" tooltip="Reject" handleClick={this.decline} color="red" />
        </div>
      );
    }
    if (data.admin) {
      return (
        <InvestmentCardAdmin
          id={data.id}
          investorId={data.investor_id}
          manageUrl={data.manage_url}
          name={data.name}
          reinviteUrl={data.reinvite_url}
        />
      );
    }
    return null;
  }

  displayFollow = () => {
    const { data } = this.props;

    if (!data.connected || data.angel) {
      return null;
    }

    if (data.user_follow) {
      return (
        <FaButtonFunction key={`f_${Date.now()}`} faIcon="star" tooltip="Unfollow" handleClick={this.follow} color="green" />
      );
    }
    if (data.custom_investment) {
      return (
        <span className="mx1">
          &mdash;
        </span>
      );
    }
    return (
      <FaButtonFunction key={`uf_${Date.now()}`} faIcon="star-o" tooltip="Follow" handleClick={this.follow} color="green" />
    );
  }

  follow = () => {
    const { data } = this.props;

    App.Api.patch(data.follow_url).then((res) => {
      store.dispatch({ type: 'SET_ITEM', list: 'main', id: res.id, value: res });
      App.State.setFlash({ name: 'notice', msg: (res.user_follow ? 'Following!' : 'Unfollowed!') });
      this.updateFiltersCount();
    }, () => {
      App.State.setFlash({ name: 'alert', msg: 'Angel investors cannot unfollow!' });
    });
  }

  fundRaisingStatusSelect = () => {
    const { data } = this.props;

    if (data.status === 'Waiting for approval' || data.status === 'Requested' || data.status === 'Invited') {
      return null;
    }

    return this.renderStatusDropDown('fundraise');
  }

  handleClick = (e) => {
    const { openOverallStatusDropdown, openFundraisingStatusDropdown } = this.state;
    if (ReactDOM.findDOMNode(this).contains(e.target)) return;
    if (openOverallStatusDropdown === true) this.setState({ openOverallStatusDropdown: false });
    if (openFundraisingStatusDropdown === true) this.setState({ openFundraisingStatusDropdown: false });
  }

  overallStatusSelect = () => {
    const { data } = this.props;
    const { status, angel } = data;

    if (angel && !(status === 'Waiting for approval' || status === 'Requested' || status === 'Invited')) {
      return (
        <div className={`col-3 text-${status === 'Requested' ? 'light-gray' : 'gray'} border-alto py1 sm-border-right-none sm-hide flex flex-justify-center`}>
          Active
        </div>
      );
    }

    if (status === 'Waiting for approval' || status === 'Requested' || status === 'Invited') {
      return (
        <div className={`${status === 'Waiting for approval' ? 'col-12' : 'col-3'} text-${status === 'Requested' ? 'light-gray' : 'gray'} border-alto py1 sm-border-right-none sm-hide flex flex-justify-center`}>
          {status}
        </div>
      );
    }
    return this.renderStatusDropDown('overall');
  }

  removeHttp = (companyUrl) => {
    if (!companyUrl) return null;
    return companyUrl.replace(/^(https?:|)\/\//, '');
  }

  removeWWW = (companyUrl) => {
    if (!companyUrl) return null;
    return companyUrl.replace(/^(www?.|)/, '');
  }


  saveFundraiseStatus = (value) => {
    const { data } = this.props;

    App.Api.submitPatch(data.fundraise_status_url, { company_report: { fundraise_status_id: value } }).then((returnedData) => {
      store.dispatch({ type: 'SET_ITEM', list: 'main', id: returnedData.id, value: returnedData });
    });
  }

  saveStateOfCompany = (value) => {
    const { data } = this.props;

    App.Api.patch(data.state_of_company_url, 'status_option_id', value).then((returnedData) => {
      store.dispatch({ type: 'SET_ITEM', list: 'main', id: returnedData.id, value: returnedData });
    });
  }

  updateFiltersCount = () => {
    const { data } = this.props;
    const investmentsUrl = `/investor/${data.investor_id}/investments`;

    App.Api.get(investmentsUrl).then((res) => {
      store.dispatch({ type: 'SET_FILTER_COUNTS', value: res.counts });
    });
  }

  renderStatusDropDown = (type) => {
    const { data } = this.props;
    let statusOptions,
      saveFunction,
      status,
      open;

    if (type === 'fundraise') {
      statusOptions = data.fundraise_status_options;
      saveFunction = this.saveFundraiseStatus;
      status = data.fundraise_status;
      open = 'openFundraisingStatusDropdown';
    } else {
      statusOptions = data.state_of_company_options;
      saveFunction = this.saveStateOfCompany;
      status = data.state_of_company;
      open = 'openOverallStatusDropdown';
    }
    const statusColor = status ? status.color_hex : null;

    const options = statusOptions.map((option) => (
      <li key={`${data.investment_id}_${option.color_hex}`} className="p1">
        <div
          className="block hover hover-bs-light-white border border-alto cursor-pointer rounded"
          onClick={(e) => { e.preventDefault(); saveFunction(option.id); }}
          style={{ height: '30px', width: '30px', backgroundColor: `#${option.color_hex}` }}
          data-balloon={option.name}
          data-balloon-pos="down"
          data-balloon-length="xsmall"
        />
      </li>
    ));
    return (
      <div className="">
        <div className="flex flex-row items-center cursor-pointer" href="#" onClick={() => { this.setState({ [open]: !this.state[open] }); }}>
          <div className="relative">
            <span
              data-balloon={status ? status.name : null}
              data-balloon-pos="down"
              data-balloon-length="xsmall"
              className="flex flex-justify-center border border-alto rounded"
              style={{ height: '20px', width: '20px', backgroundColor: `#${statusColor}` }}
            />
            <div className={`dropdown dropdown--center z2 absolute bg-white border border-alto bs-dropdown left-50 transform-horizontal-center z1 ${this.state[open] ? '' : 'hidden'} py1`}>
              <ul className="flex flex-justify-center m0 fs18 mw200">
                {options}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { data } = this.props;

    const unconfirmedInvestment = ['Waiting for approval', 'Requested', 'Invited'].includes(data.status);

    return (
      <div className={`col-12 flex flex-wrap px2 h5 sm-px0 py1 relative ${data.status === 'Invited' ? 'bg-wild-sand' : 'bg-white'}`}>
        {/* Company */}
        <div
          className={`${this.getColClass().company} border-alto py1 sm-border-right-none sm-col-6 sm-pr2 flex items-center`}
        >
          <div className="flex items-center col-12">
            {/* Follow */}
            <div
              className="flex items-center flex-shrink-none"
              style={{ width: '38px' }}
            >
              {this.displayFollow()}
            </div>
            {data.status !== 'Invited' ?
              <a className="fw400 text-gray hover link-hover--blue lh-125" href={`/investor/${data.investor_id}/investments/${data.id}`}>
                <Avatar avatarUrl={data.avatar} size="40px" />
                {data.real_company &&
                  <div className="relative">
                    <span className="real_company__notification" style={{ top: '-48px', right: '-8px' }} data-behavior="hide-bubble">
                      <i className="fa fa-check-circle text-green" />
                    </span>
                  </div>
                }
              </a>
              :
              <Avatar avatarUrl={data.avatar} size="40px" />
            }
            {data.status !== 'Invited' ?
              <div className="flex flex-column ml1 lh-15 mr2 truncate-wrap">
                <div className="flex items-center">
                  <a className="fw400 text-gray hover link-hover--blue lh-125" href={`/investor/${data.investor_id}/investments/${data.id}`}>{data.name}</a>
                  {/* {data.real_company &&
                    <i className="fa fa-check-circle text-green ml1" />
                  } */}
                </div>
                <a
                  href={data.company_url}
                  target="_blank"
                  className="text-light-gray h5 hover link-hover--blue truncate"
                  rel="noopener noreferrer"
                >
                  {this.removeWWW(this.removeHttp(data.company_url))}
                </a>
              </div>
              :
              <>
                <span className="flex items-center fw400 ml1">{data.name}</span>
                {data.custom_investment_invite_name &&
                  <div className="flex items-center ml2">
                    <span className="ml2 mr1 nowrap">Invited for Company:</span>
                    <Avatar avatarUrl={data.custom_investment_invite_avatar} size="18px" />
                    <span className="ml1 nowrap">{data.custom_investment_invite_name}</span>
                  </div>
                }
              </>
            }
          </div>
        </div>

        {/* Added */}
        <div className={`${this.getColClass().added} py1 sm-hide flex items-center truncate`}>
          <span className="text-light-gray">{data.added}</span>
        </div>

        {
          /* Fund connections */
          data.connected && data.has_funds && !data.angel &&
          <div className={`${this.getColClass().funds} py1 sm-hide flex items-center`}>
            <SelectWithModal
              elementsToSelect={data.funds_for_options}
              selectedElements={data.assigned_funds_array}
              addElement={(fundId) => this.addFundConnection(fundId)}
              deleteElement={(fundId) => this.deleteFundConnection(fundId)}
              disableSelect={!data.admin || data.angel}
              disableTooltip={false}
              title="Manage Fund Connections"
            />
          </div>
        }

        {
          /* Managers */
          data.connected && !data.angel &&
          <div className={`${this.getColClass().managers} py1 sm-hide flex items-center`}>
            <SelectWithModal
              elementsToSelect={data.investment_managers_for_options}
              selectedElements={data.assigned_investment_managers_array}
              addElement={(managerId) => this.addManager(managerId)}
              deleteElement={(managerId) => this.deleteManager(managerId)}
              disableSelect={!data.admin || data.angel || unconfirmedInvestment}
              title="Manage Investment Managers"
            />
          </div>
        }

        {
          /* Fundraising Status */
          !data.angel &&
          <div className={`${this.getColClass().fundraisingStatus} flex items-center flex-auto flex-justify-center text-gray py1 sm-border-right-none sm-hide`}>
            {this.fundRaisingStatusSelect()}
          </div>
        }

        {/* Overall Status */}
        <div className={`${this.getColClass().overallStatus} flex items-center flex-auto flex-justify-center text-gray py1 sm-border-right-none sm-hide`}>
          {this.overallStatusSelect()}
        </div>

        {/* Actions */}
        <div className="col-1 flex items-center p1 flex flex-justify-end sm-border-top-none sm-col-6 pr1">
          {this.displayActions()}
        </div>
      </div>
    );
  }
}

export default InvestorInvestment;
