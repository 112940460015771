import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

//
// 'options' prop is assigned thought the store. Which works out fine in Company Dashboard.
//  But this component is also used in the Investment Profile. Where 'kpiOptions' prop need
//  to be used instead of 'options'.
//
class KpiSelector extends Component {
  static propTypes = {
    options: PropTypes.array,
    kpiOptions: PropTypes.array,
    url: PropTypes.string,
    saveFields: PropTypes.func,
    fieldId: PropTypes.number,
  }

  static defaultProps = {
    options: null,
    kpiOptions: null,
    url: null,
    saveFields: null,
    fieldId: null,
  }

  state = {
    open: false,
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
    document.removeEventListener('touchstart', this.handleClick, false);
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
    document.addEventListener('touchstart', this.handleClick, false);
  }

  handleClick = e => {
    if (ReactDOM.findDOMNode(this).contains(e.target)) return;
    this.setState({ open: false });
  }

  updateWidget = kpi => {
    const { url, saveFields, fieldId } = this.props;

    if (saveFields) {
      const params = {
        investment_field: {
          ids: [fieldId],
          values: [{
            kpi_field_id: kpi,
          }],
        },
      };
      saveFields(fieldId, params);
    } else {
      App.Api.patch(url, 'kpi_id', kpi).then((data) => {
        store.dispatch({ type: 'SET_WIDGET', widget: data });
      });
    }
  }

  render() {
    const { url, options, kpiOptions } = this.props;
    const optionsForKpiChange = options || kpiOptions;
    const { open } = this.state;

    if (url && url.length < 1) {
      return <div />;
    }

    return (
      <div>
        <div className="text-light-gray lg-hide" href="#" onClick={e => { e.preventDefault(); this.setState({ open: !open }); }}>
          <i className="fa fa-cog opacity-0" />
        </div>

        <div className={`z2 dropdown dropdown--center absolute bg-white border border-alto bs-dropdown left-50 transform-horizontal-center z1 ${open ? '' : 'hidden'} py1`}>
          <ul className="left-align m0 mw200">
            {optionsForKpiChange.map(item => (
              <li key={item.id}>
                <div
                  className="bold h5 link-hover--blue block nowrap px1 text-gray cursor-pointer"
                  onClick={(e) => { e.preventDefault(); this.updateWidget(item.id); }}
                >
                  {item.metric}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  options: store ? store.kpiOptions : null,
});

export default connect(mapStateToProps)(KpiSelector);
