import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../../store/configureStore';
import DataRoomsContainer from '../../containers/investor/investments/DataRoomsContainer';


const InvestmentDataRooms = props => (
  <Provider store={configureStore(props)}>
    <DataRoomsContainer />
  </Provider>
);

export default InvestmentDataRooms;
