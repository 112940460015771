const comments = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_COMMENT':
      return {
        ...state,
        all: [action.comment, ...state.all],
      };

    default: return state;
  }
};

export default comments;
