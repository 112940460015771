const reportColumn = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_COLUMN':
      if (state.id !== action.id) {
        return state;
      }
      return action.column;

    default:
      return state;
  }
};

const companyReport = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_REPORT':
      return Object.assign({}, state, action.params);
    case 'UPDATE_COLUMN':
      return {
        ...state,
        report_pages: state.report_pages.map(page => ({
          ...page,
          report_sections: page.report_sections.map(section => ({
            ...section,
            report_rows: section.report_rows.map(row => ({
              ...row,
              report_columns: row.report_columns.map(col => reportColumn(col, action)),
            })),
          })),
        })),
      };
    default:
      return state;
  }
};

export default companyReport;
