import PropTypes from 'prop-types';

import SorterHeader from '../../../../modules/SorterHeader';
import { multipleText } from '../../../../utils/fundDashboardFormulas';


const Header = ({ multipleFormula, showIrr }) => (
  <div className="col-12 flex py1 px1 text-light-gray h5 fw400 flex p1 center bg-wild-sand border-top border-bottom border-alto pr2">
    <div className="col-2 sm-col-6 flex items-center flex-justify-center pr4">
      <SorterHeader text="Name" field="name" />
    </div>
    <div className="col-10 sm-col-6 flex flex-row items-center flex-justify-center">
      <div
        className="width-20p sm-hide flex items-center flex-justify-center"
        data-balloon="Shows the percentage of ownership assigned."
        data-balloon-pos="down"
        data-balloon-length="large"
      >
        <SorterHeader text="Total Ownership" field="ownership" hideIcon width="65%" />
      </div>
      <div
        className="width-20p sm-width-50p flex items-center flex-justify-center"
        data-balloon="Shows the current post-money valuation of the company."
        data-balloon-pos="down"
        data-balloon-length="large"
      >
        <SorterHeader text="Current Post-Money" field="current_valuation" hideIcon width="65%" />
      </div>
      <div
        className="width-20p sm-hide flex items-center flex-justify-center"
        data-balloon="Shows the total amount of money invested."
        data-balloon-pos="down"
        data-balloon-length="large"
      >
        <SorterHeader text="Total Invested" field="invested" hideIcon width="55%" />
      </div>
      <div
        className="width-20p sm-hide flex items-center flex-justify-center"
        data-balloon="Shows the total realized cashflow."
        data-balloon-pos="down"
        data-balloon-length="large"
      >
        <SorterHeader text="Total Realized" field="realized_cashflow" hideIcon width="55%" />
      </div>
      <div
        className="width-20p sm-hide flex items-center flex-justify-center"
        data-balloon="Shows the value of shares assigned to your account."
        data-balloon-pos="down"
        data-balloon-length="large"
      >
        <SorterHeader text="Current Value of Investment" field="current_share_value" hideIcon width="70%" />
      </div>
      <div
        className="width-20p sm-hide flex items-center flex-justify-center"
        data-balloon={multipleText(multipleFormula)}
        data-balloon-pos="down"
        data-balloon-length="large"
      >
        <SorterHeader text="Investment Multiple" field="investment_multiple" hideIcon width="65%" />
      </div>
      {showIrr &&
        <div
          className="width-20p sm-hide flex items-center flex-justify-center"
          data-balloon="IRR is used to determine the Internal Rate of Return when the cash flows are at Irregular intervals."
          data-balloon-pos="down"
          data-balloon-length="large"
        >
          <SorterHeader text="IRR" field="xirr" hideIcon width="65%" />
          <div className="relative">
            <span className="desktop__notification--bell" data-behavior="hide-bubble" style={{ borderRadius: '30%', width: '25px', top: '-15px', right: '0px', zIndex: 1 }}>
              Beta
            </span>
          </div>
        </div>
      }
    </div>
  </div>
);

Header.propTypes = {
  multipleFormula: PropTypes.string.isRequired,
  showIrr: PropTypes.bool.isRequired,
};

export default Header;
