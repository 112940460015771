import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Cleave from 'cleave.js/react';
import numberFormat from '../user_number_format';


// formatValue: props set to true by default because the value can be passed unformatted,
// or with just the decimal point, not the thousand separator (Ex: 1111; 1111.12).
class CleaveNumberFormatting extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    decimal: PropTypes.bool,
    unit: PropTypes.string,
    decimalScale: PropTypes.number,
  }

  static defaultProps = {
    decimal: true,
    unit: null,
    decimalScale: 2,
  }

  getOptions = (isDecimal) => {
    const { decimalScale } = this.props;
    if (isDecimal) {
      return {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalMark: numberFormat().decimalPoint,
        delimiter: numberFormat().thousandsSep,
        numeralDecimalScale: decimalScale,
      };
    }
    return {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralDecimalMark: '*',
      delimiter: numberFormat().thousandsSep,
    };
  }

  // Somehow when the thousand delimiter is a dot, Cleave gets confused whith float numbers,
  // that's why the decimal point needs to be replaced with a comma;
  // The same weird thing happens when the decimal mark is a comma, thus we need to replace
  // the point for a comma too.
  formatValue = (value) => {
    if (value !== undefined && value !== null) {
      const innerValue = value.toString();
      if (numberFormat().thousandsSep === '.' || numberFormat().decimalPoint === ',') {
        // There's no issue for well formatted values. Ex: 1.123,23. Only for values like Ex: 1123,23 or 1.123
        if (!(innerValue.includes(',') && innerValue.includes('.'))) {
          return innerValue.replace('.', ',');
        }
      }
      return innerValue;
    }
    return '';
  }

  render() {
    const { unit, value, decimal, decimalScale, ...other } = this.props;
    return (

      <div className="col-12">
        <div className="flex flex-row">
          <Cleave
            value={this.formatValue(value)}
            options={this.getOptions(decimal)}
            {...other}
          />
          {unit &&
            <span className="text-gray flex items-center mt1 unit-margin-left">
              {unit}
            </span>
          }
        </div>
      </div>
    );
  }
}

export default CleaveNumberFormatting;
