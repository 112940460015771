const companyDataRooms = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_QUICK_ACCESS_FILE':
      return {
        ...state,
        quick_access_files: [...state.quick_access_files, action.file],
      };

    case 'SET_QUICK_ACCESS_FILE':
      return {
        ...state,
        quick_access_files: state.quick_access_files.map(f => {
          if (f.uid === action.file.uid) return action.file;
          return f;
        }),
      };

    case 'REMOVE_QUICK_ACCESS_FILE':
      return {
        ...state,
        quick_access_files: state.quick_access_files.filter(f => f.id !== action.file.id),
      };

    case 'REMOVE_DATA_ROOM':
      return {
        ...state,
        shared_rooms: state.shared_rooms.filter(r => r.id !== action.room.id),
      };

    default: return state;
  }
};

export default companyDataRooms;
