import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import moment from 'moment';

import Modal from '../../Modal';
import CleaveNumberFormatting from '../../CleaveNumberFormatting';
import Textarea from '../../Textarea';
import TextInput from '../../TextInput';
import SelectWithImage from '../../selects/SelectWithImage';
import DatePicker from '../../selects/DatePicker';

import DropdownMenu from '../../DropdownMenu';
import ConfirmationModal from '../../ConfirmationModal';

import { defaultSelectStyles, defaultSelectTheme } from '../../selects/SelectStyles';


class ConvertibleModal extends Component {
  static propTypes = {
    convertible: PropTypes.object,
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    capTable: PropTypes.object,
    company: PropTypes.object,
    createConvertible: PropTypes.func,
    updateConvertible: PropTypes.func,
    deleteConvertible: PropTypes.func,
    canEdit: PropTypes.bool,
    successMessage: PropTypes.string,
  }

  static defaultProps = {
    convertible: null,
    capTable: null,
    company: null,
    createConvertible: null,
    updateConvertible: null,
    deleteConvertible: null,
    canEdit: true,
    successMessage: null,
  }

  constructor(props) {
    super(props);

    this.initialState = {
      convertible: {
        name: null,
        issue_date: moment().format('YYYY-MM-DD'),
        principal: null,
        valuation: null,
        discount: null,
        interest_rate: null,
        maturity_date: moment().format('YYYY-MM-DD'),
        interest_accrual: 'never',
        qualified_financing_amount: null,
        note: null,
        investor_id: null,
        beneficiary_name: null,
        data_room_file_id: null,
      },
      beneficiaryType: 'connections',
      saving: false,
    };

    this.state = this.initialState;
  }

  componentWillReceiveProps = (nextProps) => {
    const { convertible } = nextProps;

    if (convertible) {
      this.setState({ convertible }, () => {
        if (!convertible.investor_id) {
          this.setState({ beneficiaryType: 'new' });
        }
      });
    }
  }

  handleClose = () => {
    const { closeModal } = this.props;

    this.setState(this.initialState);

    closeModal();
  }

  handleTypeSelect = (type) => {
    const { canEdit } = this.props;
    const { convertible } = this.state;

    if (!canEdit) return;

    let newState = { beneficiaryType: type };

    if (type === 'connections') {
      newState = { ...newState, convertible: { ...convertible, beneficiary_name: null } };
    } else {
      newState = { ...newState, convertible: { ...convertible, investor_id: null, beneficiary_name: null } };
    }

    this.setState(newState);
  }

  handleChange = (name, value) => {
    const { canEdit } = this.props;

    if (!canEdit) return;

    this.setState(state => ({
      ...state,
      convertible: {
        ...state.convertible,
        [name]: value,
      },
    }));
  }

  submit = async () => {
    const { convertible: convertibleProps, createConvertible, updateConvertible, canEdit, capTable, successMessage } = this.props;
    const { convertible, saving } = this.state;

    if (saving || !canEdit) return;

    this.setState({ saving: true });

    let params = null;
    if (capTable) {
      params = { manual_cap_convertible: convertible };
    } else {
      params = { convertible };
    }

    try {
      if (convertibleProps) {
        await updateConvertible(params);
      } else {
        await createConvertible(params);
        if (successMessage) App.State.setFlash({ name: 'notice', msg: successMessage });
      }
      this.handleClose();
    } catch (err) {
      const { data } = err.response;
      this.setState({ saving: false });
      App.State.setFlash({ name: 'alert', msg: data.error || data.errors });
    }
  }

  renderDeleteButton = () => {
    const { convertible: convertibleProps, deleteConvertible, canEdit } = this.props;
    return (
      convertibleProps && deleteConvertible && canEdit &&
        <ConfirmationModal
          html={'(<i class="fa fa-times-circle"></i><span class="ml05">delete convertible</span>)'}
          className="flex items-center text-red h6 cursor-pointer ml1"
          onClick={() => { deleteConvertible(); this.handleClose(); }}
          message="<b>You are about to permanently delete this custom convertible. This cannot be undone.</b><br/><br/>Are you sure you want to delete?"
          width=""
        />
    );
  }

  render() {
    const {
      show,
      capTable,
      company,
      convertible: convertibleProps,
      canEdit,
    } = this.props;

    const { convertible, beneficiaryType, saving } = this.state;

    const typeOptions = [
      { value: 'connections', label: 'Beneficiary from Connections' },
      { value: 'new', label: 'New Beneficiary' },
    ];

    const accruesOptions = [
      { value: 'yearly', label: 'yearly (360 days)' },
      { value: 'monthly', label: 'monthly' },
      { value: 'daily', label: 'daily' },
      { value: 'never', label: 'never' },
    ];

    return (
      <Modal show={show} widthClass="react_modal--600">
        <div className="flex flex-justify-between items-center bold p2 px3 border-bottom border-lighter-gray">
          {canEdit ?
            <div className="flex h3">
              {convertibleProps ? 'Edit' : 'New'} Convertible
              {this.renderDeleteButton()}
            </div>
            :
            <div className="flex h3">
              Convertible Details
              {this.renderDeleteButton()}
            </div>
          }
          <i className="fa fa-close h5 cursor-pointer" onClick={this.handleClose} />
        </div>

        <div className="text-gray fw400 p3 h5">
          <div className="flex flex-column">
            <div className="flex flex-column">
              <span>
                Convertible Note Name
                {canEdit && <span className="text-red">*</span>}
              </span>
              <TextInput
                value={convertible.name || ''}
                className="border border-gallery mt1 h5 col-12"
                name="name"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                disabled={!canEdit}
              />
            </div>

            {capTable &&
              <div className="flex pt2">
                <div className="col-6 flex flex-column mr3">
                  <span>Beneficiary Type</span>
                  <ReactSelect
                    className="mt1"
                    name="beneficiaryType"
                    value={typeOptions.filter(option => (option.value === beneficiaryType))}
                    options={typeOptions}
                    onChange={selectedOption => this.handleTypeSelect(selectedOption.value)}
                    isClearable={false}
                    styles={defaultSelectStyles}
                    theme={defaultSelectTheme}
                    isDisabled={!canEdit}
                  />
                </div>

                <div className="col-6 flex flex-column">
                  {beneficiaryType === 'new' ?
                    <>
                      <span>Beneficiary Name</span>
                      <TextInput
                        value={convertible.beneficiary_name || ''}
                        className="col-12 border border-gallery mt1 input-alto__focus"
                        name="beneficiary_name"
                        onChange={e => this.handleChange(e.target.name, e.target.value)}
                        disabled={!canEdit}
                      />
                    </>
                    :
                    <>
                      {canEdit ?
                        <>
                          <span>Select Beneficiary from Connections</span>
                          <SelectWithImage
                            className="mt1"
                            name="investor_id"
                            value={capTable.shareholder_options_for_convertibles.filter(option => (option.value === convertible.investor_id))}
                            options={capTable.shareholder_options_for_convertibles}
                            onChange={selectedOption => { this.handleChange('investor_id', selectedOption.value); this.handleChange('beneficiary_name', selectedOption.label); }}
                            isClearable={false}
                            styles={defaultSelectStyles}
                            theme={defaultSelectTheme}
                          />
                        </>
                        :
                        <>
                          <span>Beneficiary Name</span>
                          <TextInput
                            value={convertible.beneficiary_name}
                            className="border border-gallery mt1 h5 col-12"
                            name="name"
                            disabled={!canEdit}
                          />
                        </>
                      }
                    </>
                  }
                </div>
              </div>
            }

            {canEdit && capTable &&
              <div className="mt1 h5 lh-12 flex flex-column">
                <span className="light text-light-gray">
                  Adding a beneficiary from your connections, enables us to aggregate/show all shares assigned to
                  the beneficiary on investory (if access is enabled). While shareholders from connections require an invite
                  to investory a new beneficiary can be added without a previous invitation.
                </span>

                {capTable.shareholder_options.length === 0 &&
                  <div className="text-gray mt2">There are no connections. You can invite investors <a href={`/c/${company.id}/investments`}>here.</a></div>
                }
              </div>
            }

            <div className="flex pt2">
              <div className="col-6 flex flex-column mr3">
                <span>Issue Date</span>
                <DatePicker
                  value={convertible.issue_date}
                  className="col-12 border border-gallery mt1"
                  name="issue_date"
                  onChange={e => this.handleChange(e.target.name, e.target.value)}
                  disabled={!canEdit}
                />
              </div>

              <div className="col-6 flex flex-column">
                <span>Principal/Investment</span>
                <CleaveNumberFormatting
                  value={`${convertible.principal}`}
                  unit={company ? company.currency : null}
                  alignUnit={false}
                  className="col-12 border border-gallery mt1 input-alto__focus col-12"
                  name="principal"
                  onChange={e => this.handleChange(e.target.name, e.target.rawValue)}
                  disabled={!canEdit}
                />
              </div>
            </div>

            <span className="pt2 h5 text-light-gray light lh-12">
              A convertible note is a form of debt (usually short-term) that will convert into equity for the beneficiary in the future. Typically a convertible note is used between financing rounds
              as it does not require a share price/valuation to determine how many shares the beneficiary receives immediately, but converts to a amount of shares later. That is also why convertible
              notes are usually kept separate in the captable, due to the fact that the amount of shares and their purchase price is not determined yet.
            </span>
          </div>

          <div className="flex flex-justify-between items-center">
            <div className="flex-auto border-top border-gallery my3" />
            <span className="center px2 text-medium-gray">Equity Conversion</span>
            <div className="flex-auto border-top border-gallery my3" />
          </div>

          <div className="flex flex-column">
            <div className="flex">
              <div className="col-6 flex flex-column mr3">
                <span>Valuation Cap</span>
                <CleaveNumberFormatting
                  value={(convertible && convertible.valuation) || ''}
                  unit={company ? company.currency : null}
                  alignUnit={false}
                  className="col-12 border border-gallery mt1 input-alto__focus col-12"
                  name="valuation"
                  onChange={e => this.handleChange(e.target.name, e.target.rawValue)}
                  disabled={!canEdit}
                />
              </div>

              <div className="col-6 flex flex-column">
                <span>Discount</span>
                <CleaveNumberFormatting
                  value={(convertible && convertible.discount) || ''}
                  className="col-12 border border-gallery mt1 input-alto__focus col-12"
                  name="discount"
                  unit="%"
                  onChange={e => this.handleChange(e.target.name, e.target.rawValue)}
                  disabled={!canEdit}
                />
              </div>
            </div>

            <div className="flex pt2">
              <div className="col-6 flex flex-column mr3">
                <span>Interest Rate</span>
                <CleaveNumberFormatting
                  value={(convertible && convertible.interest_rate) || ''}
                  className="col-12 border border-gallery mt1 input-alto__focus col-12"
                  name="interest_rate"
                  onChange={e => this.handleChange(e.target.name, e.target.rawValue)}
                  unit="%"
                  alignUnit={false}
                  disabled={!canEdit}
                />
                <span className="flex pt1 text-light-gray">
                  Interest accrues
                  {canEdit ?
                    <DropdownMenu
                      text={accruesOptions.find(accrual => accrual.value === convertible.interest_accrual).label}
                      textColor="blue"
                      textSize="h5"
                      faIcon="caret-down"
                      margin="ml05"
                      iconPos="right"
                      setFalseWhenClickingInside
                    >
                      {accruesOptions.map(accrual => (
                        <li key={accrual.value}>
                          <div
                            className="px2 hover hover-bg-wild-sand hover-text-blue text-gray fw400 cursor-pointer h5 lh-36"
                            onClick={() => this.handleChange('interest_accrual', accrual.value)}
                          >
                            <span>{accrual.label}</span>
                          </div>
                        </li>
                      ))}
                    </DropdownMenu>
                    :
                    <span className="ml1 text-blue">{accruesOptions.find(accrual => accrual.value === convertible.interest_accrual).label}</span>
                  }
                </span>
              </div>

              <div className="col-6 flex flex-column">
                <span>Maturity Date</span>
                <DatePicker
                  value={convertible.maturity_date}
                  className="col-12 border border-gallery mt1"
                  name="maturity_date"
                  onChange={e => this.handleChange(e.target.name, e.target.value)}
                  disabled={!canEdit}
                />
              </div>
            </div>
            <div className="col-6 flex flex-column pr2 pt2">
              <span>Qualified Financing Amount (Floor)</span>
              <CleaveNumberFormatting
                value={(convertible && convertible.qualified_financing_amount) || ''}
                unit={company ? company.currency : null}
                alignUnit={false}
                className="col-12 border border-gallery mt1 input-alto__focus col-12"
                name="qualified_financing_amount"
                onChange={e => this.handleChange(e.target.name, e.target.rawValue)}
                disabled={!canEdit}
              />
            </div>

            <span className="pt1 h5 text-light-gray light lh-12">
              Equity conversion parameters detail when and how the convertible note will be converted to equity. There can be many different combination and conditions attached to a note, the most
              common ones are listed above. The valuation cap and the discount are usually the most important terms of a note.
            </span>
          </div>

          <div className="flex flex-justify-between items-center">
            <div className="flex-auto border-top border-gallery my3" />
            <span className="center px2 text-medium-gray">Additional Comments</span>
            <div className="flex-auto border-top border-gallery my3" />
          </div>

          <div className="pt1">
            <Textarea
              value={convertible.note || ''}
              name="note"
              className="border border-alto lh-22 col-12 h5 input-alto__focus"
              placeholder="Write a comment here..."
              onChange={e => this.handleChange(e.target.name, e.target.value)}
              disabled={!canEdit}
            />
          </div>

          {canEdit && capTable &&
            <div className="pt2">
              <span>Linked Document</span>
              <ReactSelect
                className="mt1 col-5"
                name="data_room_file_id"
                value={capTable.data_room_files.filter(option => (option.value === convertible.data_room_file_id))}
                options={capTable.data_room_files}
                onChange={selectedOption => this.handleChange('data_room_file_id', selectedOption ? selectedOption.value : null)}
                placeholder="Select Data Room File"
                isClearable
                styles={defaultSelectStyles}
                theme={defaultSelectTheme}
              />
            </div>
          }

          {canEdit && capTable &&
            (
              capTable.data_room_files.length === 0 &&
                <div className="text-gray mt2">There are no documents. You can add documents <a href={`/company/${company.id}/data_rooms`}>here.</a></div>
            )
          }
        </div>

        <div className="border-top border-gallery px3 py2 fw400 flex flex-justify-end items-center">
          {canEdit ?
            <>
              <div className="text-blue mr2 cursor-pointer" onClick={this.handleClose}>Cancel</div>
              <div className={`text-white bg-blue px3 py1 rounded ${saving ? 'cursor-disabled' : 'cursor-pointer'}`} onClick={this.submit}>{saving ? 'Saving...' : 'Save'}</div>
            </>
            :
            <div className="text-blue mr2 cursor-pointer" onClick={this.handleClose}>Close</div>
          }
        </div>
      </Modal>
    );
  }
}

export default ConvertibleModal;
