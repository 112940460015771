import React, { Component } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';


class Flash extends Component {
  state = {
    flash: null,
  }

  componentDidMount() {
    this.unSubscribe = App.State.subscribe(() => {
      const flash = App.State.getState().flash;

      this.setState({ flash });
    });

    if (this.props.flash) {
      App.State.setFlash(this.props.flash);
    }
  }

  componentWillUnmount() {
    this.unSubscribe();
  }

  flashDiv = () => {
    const { flash } = this.state;

    if (!flash) return null;

    const { name, msg } = flash;

    const flashClassName = `flash flash--${name}`;

    return (
      <div className={flashClassName}>
        <h2 className="flash__message">{msg}</h2>
      </div>
    );
  }

  render() {
    return (
      <ReactCSSTransitionGroup transitionName="flash" transitionEnterTimeout={500} transitionLeaveTimeout={300} >
        {this.flashDiv()}
      </ReactCSSTransitionGroup>
    );
  }
}

export default Flash;
