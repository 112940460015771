import axios from 'axios';

import { setSorting } from '../../../actions/sortingActions';
import { buildChartQuery } from '../../utils/homeAndDashboard';

import {
  setDashboardTab,
  setLoadingOnTab,
  setKpisTimeAgo,
  addCompanyInfoData,
  addKpisData,
  addDataRoomInfoData,
  addFactSheetTabData,
  addExcelExportTabData,
  addSlideshowTabData,
  addInvestmentInfoData,
  addRecentUpdatesData,
  addPortfolioUpdatesData,
  addCoInvestorsData,
  updateChart,
  updateCompanyFilters,
  updateKpiTableAndChart,
  updateWidgetChart,
  updatePortfolioReportFileData,
} from '../../../actions/investor/funds/fundDashboardActions';

const setTabAndResetSorting = (tabId) =>
  async dispatch => {
    dispatch(setDashboardTab(tabId));
    dispatch(setSorting({}));
  };

const setTimeAgo = (timeAgo, frequency) =>
  async (dispatch, getState) => {
    const { investorId, fundId } = getState().fundDashboard.configs;
    const params = { time_ago: timeAgo, frequency };
    let url;

    // change later controller name to time ago
    if (fundId) {
      url = `/investor/${investorId}/funds/${fundId}/tabs/months_ago_kpis`;
    } else {
      url = `/investor/${investorId}/funds/all_investments/months_ago_kpis`;
    }
    const res = await axios.post(url, params);
    dispatch(setKpisTimeAgo(res.data));
  };

const setLoadingTab = (tabNumber) =>
  async dispatch => {
    dispatch(setLoadingOnTab(tabNumber));
  };

const getCompanyInfoData = (lazyLoadingLayer = 'basic') =>
  async (dispatch, getState) => {
    const { investorId, fundId } = getState().fundDashboard.configs;
    let url;

    if (fundId) {
      url = `/investor/${investorId}/funds/${fundId}/company_infos?lazy_loading_layer=${lazyLoadingLayer}`;
    } else {
      url = `/investor/${investorId}/funds/all_investments/company_infos?lazy_loading_layer=${lazyLoadingLayer}`;
    }

    const res = await axios.get(url);
    dispatch(addCompanyInfoData(res.data, lazyLoadingLayer));
  };

const getDataRoomInfoData = (dashboardType, fundId) =>
  async (dispatch, getState) => {
    const { investorId } = getState().fundDashboard.configs;
    const dataRoom = getState().fundDashboard.data_rooms;
    if (dataRoom.loading === true && !dataRoom.data) {
      const url = `/investor/${investorId}/data_room_files?dashboard_type=${dashboardType}&fund_id=${fundId || ''}`;

      const res = await axios.get(url);
      dispatch(addDataRoomInfoData(res.data));
    }
  };

const getFactSheetTabData = () =>
  async (dispatch, getState) => {
    const { investorId, fundId } = getState().fundDashboard.configs;
    const { reports } = getState().fundDashboard;
    if (reports.fact_sheet.loading === true && !reports.fact_sheet.data) {
      const url = `/investor/${investorId}/reports/company_reports?fund_id=${fundId || ''}`;

      const res = await axios.get(url);
      dispatch(addFactSheetTabData(res.data));
    }
  };

const getExcelExportTabData = (lazyLoadingLayer = 'basic') =>
  async (dispatch, getState) => {
    const { investorId } = getState().fundDashboard.configs;
    const { reports } = getState().fundDashboard;
    if (reports.excel_export.loading === true && !reports.excel_export.data) {
      const url = `/investor/${investorId}/reports/investor_exports?lazy_loading_layer=${lazyLoadingLayer}`;

      const res = await axios.get(url);
      dispatch(addExcelExportTabData(res.data, lazyLoadingLayer));
    }
  };

const getSlideshowTabData = () =>
  async (dispatch, getState) => {
    const { investorId, fundId } = getState().fundDashboard.configs;
    const { reports } = getState().fundDashboard;
    if (reports.slideshow.loading === true && !reports.slideshow.data) {
      const url = `/investor/${investorId}/slideshows/new?fund_id=${fundId || ''}`;

      const res = await axios.get(url);
      dispatch(addSlideshowTabData(res.data));
    }
  };

const getInvestmentInfoData = (lazyLoadingLayer = 'basic') =>
  async (dispatch, getState) => {
    const { investorId, fundId } = getState().fundDashboard.configs;
    let url;

    if (fundId) {
      url = `/investor/${investorId}/funds/${fundId}/investment_infos?lazy_loading_layer=${lazyLoadingLayer}`;
    } else {
      url = `/investor/${investorId}/funds/all_investments/investment_infos?lazy_loading_layer=${lazyLoadingLayer}`;
    }

    const res = await axios.get(url);
    dispatch(addInvestmentInfoData(res.data, lazyLoadingLayer));
  };

const getRecentUpdatesData = () =>
  async (dispatch, getState) => {
    const { investorId, fundId } = getState().fundDashboard.configs;
    let url;

    if (fundId) {
      url = `/investor/${investorId}/funds/${fundId}/recent_updates`;
    } else {
      url = `/investor/${investorId}/funds/all_investments/recent_updates`;
    }

    const res = await axios.get(url);
    dispatch(addRecentUpdatesData(res.data));
  };

const getPortfolioUpdatesData = () =>
  async (dispatch, getState) => {
    const { investorId, fundId } = getState().fundDashboard.configs;
    let url;

    if (fundId) {
      url = `/investor/${investorId}/funds/${fundId}/portfolio_updates`;
    } else {
      url = `/investor/${investorId}/funds/all_investments/portfolio_updates`;
    }

    const res = await axios.get(url);
    dispatch(addPortfolioUpdatesData(res.data));
  };

const getKpisData = (options = { kpiMetric: null, kpiType: null }) =>
  async (dispatch, getState) => {
    const { investorId, fundId } = getState().fundDashboard.configs;
    let url;

    if (fundId) {
      url = `/investor/${investorId}/funds/${fundId}/kpis`;
    } else {
      url = `/investor/${investorId}/funds/all_investments/kpis_info`;
    }

    // The query should be built if it's not a dashboard KPI
    if (options.kpiType !== 'dashboard_kpi') {
      url = buildChartQuery(`${url}?`, options);
    }

    const res = await axios.get(url);
    dispatch(addKpisData(res.data));
  };

const getCoInvestorsData = () =>
  async (dispatch, getState) => {
    const { investorId, fundId } = getState().fundDashboard.configs;
    let url;

    if (fundId) {
      url = `/investor/${investorId}/funds/${fundId}/co_investors`;
    } else {
      url = `/investor/${investorId}/funds/all_investments/co_investors`;
    }

    const res = await axios.get(url);
    dispatch(addCoInvestorsData(res.data));
  };

const updateChartData = (type, options = { filterType: null, kpiMetric: null, kpiType: null, timeFrame: null }) =>
  async (dispatch, getState) => {
    const { investorId, fundId } = getState().fundDashboard.configs;
    const { frequency } = getState().fundDashboard.tabProps.time_manage;
    const innerOptions = { ...options, frequency };
    let url;

    if (fundId) {
      url = `/investor/${investorId}/home/chart_data?type=${type}&fund_id=${fundId}`;
    } else {
      url = `/investor/${investorId}/home/chart_data?type=${type}`;
    }

    // A requisition should only be made if it's not a dashboard kpi
    if (type !== 'company_info' && innerOptions.kpiType !== 'dashboard_kpi') {
      url = buildChartQuery(url, innerOptions);
      const res = await axios.get(url);
      dispatch(updateKpiTableAndChart(res.data, type, innerOptions));
    } else {
      dispatch(updateChart(null, type, innerOptions));
    }
  };

const changeCompanyFilters = selection =>
  async dispatch => {
    dispatch(updateCompanyFilters(selection));
  };

const setWidgetChartFilter = (widgetPosition, filter, filterType) =>
  async (dispatch, getState) => {
    const { investorId } = getState().fundDashboard.configs;
    await axios.post(`/investor/${investorId}/funds//widgets/chart_filter_save`, { filter, filter_type: filterType || 'standard', widget_position: widgetPosition });
    dispatch(updateWidgetChart(widgetPosition, filter, filterType));
  };


const loadNewExportFile = () =>
  async (dispatch, getState) => {
    const { reports } = getState().fundDashboard;
    const { investorId } = getState().fundDashboard.configs;
    const report = reports.excel_export;
    const url = `/investor/${investorId}/memberships/${report.data.membership_id}/data_room_files/check_new_report`;

    const res = await axios.get(url);
    dispatch(updatePortfolioReportFileData(res.data));
  };

export {
  setTabAndResetSorting,
  setTimeAgo,
  setLoadingTab,
  getCompanyInfoData,
  getInvestmentInfoData,
  getRecentUpdatesData,
  getPortfolioUpdatesData,
  getKpisData,
  getDataRoomInfoData,
  getFactSheetTabData,
  getExcelExportTabData,
  getSlideshowTabData,
  getCoInvestorsData,
  updateChartData,
  changeCompanyFilters,
  setWidgetChartFilter,
  loadNewExportFile,
};
