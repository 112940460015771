import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../Modal';
import SelectableField from '../modules/SelectableField';


class CompanyFieldsModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    investment: PropTypes.object.isRequired,
    saveFields: PropTypes.func.isRequired,
    deleteFields: PropTypes.func.isRequired,
  };

  state = {
    fields: {},
  }

  static getDerivedStateFromProps(props, state) {
    const fields = props.investment.company_fields.reduce((objects, f) => ({
      ...objects,
      [f.id]: {
        type: typeof f.id === 'string' ? 'company' : 'custom',
        name: f.name,
        text: f.text,
        overwrite: f.overwrite,
      },
    }), {});

    if (props.investment.company_fields !== state.prevProps) {
      return {
        fields,
        prevProps: props.investment.company_fields,
      };
    }
    return null;
  }

  handleChange = (id, e) => {
    const { value, type } = e.target;
    const { fields } = this.state;
    const f = { ...fields[id], type };

    if (type === 'custom') f.text = value;

    this.setState({
      fields: {
        ...fields,
        [id]: f,
      },
    });
  }

  createFields = async (values) => {
    if (!values.length) return;

    const { saveFields } = this.props;
    const createParams = {
      investment_field: {
        values,
      },
    };
    await saveFields('', createParams);
  }

  destroyFields = async (ids) => {
    if (!ids.length) return;

    const { deleteFields } = this.props;
    const destroyParams = {
      investment_field: {
        ids,
      },
    };
    await deleteFields(destroyParams);
  }

  updateFields = async (fields) => {
    const ids = Object.keys(fields),
      values = Object.values(fields);

    if (!ids.length) return;

    const { saveFields } = this.props;
    const updateParams = {
      investment_field: {
        ids,
        values,
      },
    };
    await saveFields(null, updateParams);
  }

  submit = async () => {
    const { closeModal } = this.props;
    const { fields } = this.state;
    const fieldsToBeUpdated = {},
      fieldsToBeCreated = [],
      fieldsToBeRemoved = [];

    Object.entries(fields).forEach(([id, f]) => {
      const { type, ...params } = f;
      if (parseInt(id, 10) && type === 'company') {
        fieldsToBeRemoved.push(id);
      } else if (parseInt(id, 10) && type === 'custom') {
        fieldsToBeUpdated[id] = params;
      } else if (type === 'custom') {
        fieldsToBeCreated.push(params);
      }
    });

    try {
      await Promise.all([
        this.createFields(fieldsToBeCreated),
        this.destroyFields(fieldsToBeRemoved),
        this.updateFields(fieldsToBeUpdated),
      ]);
      closeModal();
    } catch (err) {
      console.log(err.response.data.errors);
    }
  }

  render() {
    const { show, closeModal, investment } = this.props;
    const { fields } = this.state;

    return (
      <Modal
        show={show}
        onClickOutside={closeModal}
      >
        <div className="flex flex-justify-between items-center bold p2 border-bottom border-lighter-gray">
          <div className="h3">Overview Fields</div>
          <i className="fa fa-close h5 cursor-pointer" onClick={closeModal} />
        </div>

        <div className="fw400 p2 overflow-y-auto" style={{ maxHeight: '65vh' }}>
          {investment.company_fields.map(f => (
            <div key={`field_${f.id}`}>
              <SelectableField
                label={f.name}
                name={f.name}
                defaultValue={fields[f.id].text}
                original={f.original || ''}
                isCustom={typeof f.id === 'number'}
                component="Select"
                placeholder={`Select ${f.name}...`}
                options={investment[`${f.overwrite || f.id}_options`]}
                onChange={e => this.handleChange(f.id, e)}
              />
            </div>
          ))}
        </div>

        <div className="flex flex-justify-between items-center p2 border-top border-lighter-gray">
          <div className="flex flex-start items-center h6 text-light-gray fw400 lh-125">
            <i className="fa fa-info-circle mr1" />
            Note: After switching to company data,
            <br />custom data will be deleted on save!
          </div>
          <div className="flex flex-justify-end items-center">
            <div
              className="h5 text-blue mr2 cursor-pointer"
              onClick={closeModal}
            >
              Cancel
            </div>
            <div
              className="h5 p1 px2 cursor-pointer bg-blue text-white rounded"
              onClick={this.submit}
            >
              Save
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default CompanyFieldsModal;
