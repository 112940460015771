import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import CheckBox from '../CheckBox';
import TextInput from '../TextInput';

import { defaultSelectStyles, defaultSelectTheme } from '../selects/SelectStyles';
import { filterAccumulatorPeriodOptions, accumPeriodToPeriodStep } from '../utils/kpis/accumulatorUtils';
import Textarea from '../Textarea';
import HtmlComponent from '../tab_view/HtmlComponent';
import ConfirmationModal from '../modals/ConfirmationModal';

class CreateNewKpi extends Component {
  static propTypes = {
    billingUrl: PropTypes.string,
    metric: PropTypes.string,
    unit: PropTypes.string,
    periodStep: PropTypes.string,
    firstKpiId: PropTypes.number,
    secondKpiId: PropTypes.number,
    kpiOperation: PropTypes.string,
    timeShift: PropTypes.string,
    accumulatorPeriod: PropTypes.string,
    accumulatorOperation: PropTypes.string,
    description: PropTypes.string,
    unitOptions: PropTypes.array.isRequired,
    periodStepOptions: PropTypes.array.isRequired,
    kpiOptions: PropTypes.array.isRequired,
    kpiOperationsOptions: PropTypes.array.isRequired,
    timeShiftOptions: PropTypes.array.isRequired,
    accumulatorPeriodOptions: PropTypes.array.isRequired,
    accumulatorOperationOptions: PropTypes.array.isRequired,
    aggregationType: PropTypes.string,
    aggregationOptions: PropTypes.array,
    isDerivedKpi: PropTypes.bool,
    isPredefined: PropTypes.bool,
    edit: PropTypes.bool,
    maxDerivedKpis: PropTypes.number,
    derivedKpisCount: PropTypes.number,
    activeSubscription: PropTypes.bool,
    canCreateDerivedKpis: PropTypes.bool,
    backButtonLink: PropTypes.string,
    submitLink: PropTypes.string,
  }

  static defaultProps = {
    billingUrl: null,
    metric: '',
    unit: '',
    periodStep: '',
    firstKpiId: null,
    secondKpiId: null,
    kpiOperation: null,
    timeShift: null,
    accumulatorPeriod: null,
    accumulatorOperation: null,
    description: null,
    aggregationType: null,
    aggregationOptions: null,
    isDerivedKpi: false,
    isPredefined: false,
    edit: false,
    maxDerivedKpis: null,
    derivedKpisCount: null,
    activeSubscription: false,
    canCreateDerivedKpis: false,
    backButtonLink: null,
    submitLink: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      metricState: props.metric,
      unitState: props.unit,
      initialPeriodStepState: props.periodStep,
      periodStepState: props.periodStep,
      firstKpiState: props.kpiOptions.find(kpi => kpi.value === props.firstKpiId) || {},
      secondKpiState: props.kpiOptions.find(kpi => kpi.value === props.secondKpiId) || {},
      kpiOperationState: props.kpiOperation || 'sum',
      timeShiftState: props.timeShift || 'none',
      accumulatorPeriodState: props.accumulatorPeriod || 'year',
      accumulatorOperationState: props.accumulatorOperation || 'sum',
      accumulatorOperationOptionsFiltered: props.accumulatorOperationOptions.filter(option => option.value !== 'none'),
      derivedKpichecked: props.isDerivedKpi,
      descriptionState: props.description,
      aggregationTypeState: props.aggregationType,
    };
  }

  componentDidMount = () => {
    this.filterAccumulatorOperationOptions();
  }

  // In the Acummulator operation, add an option of “none”.
  // However, need to validate that it can only be “none” if cumm-period equals the frequency of both KPIs.
  // Otherwise it must be informed
  filterAccumulatorOperationOptions = () => {
    const { accumulatorOperationOptions } = this.props;
    const { firstKpiState, secondKpiState, accumulatorPeriodState } = this.state;

    const validTypes = ['month', 'quarter', 'year'];
    const optionNone = accumulatorOperationOptions.filter(option => option.value === 'none');

    if (validTypes.includes(accumulatorPeriodState)) {
      if (!$.isEmptyObject(firstKpiState) && !$.isEmptyObject(secondKpiState)) {
        if ((firstKpiState.period_step === secondKpiState.period_step) && (firstKpiState.period_step === accumPeriodToPeriodStep(accumulatorPeriodState))) {
          this.setState({ accumulatorOperationState: 'none', accumulatorOperationOptionsFiltered: optionNone });
          return;
        }
      } else if (!$.isEmptyObject(firstKpiState)) {
        if (firstKpiState.period_step === accumPeriodToPeriodStep(accumulatorPeriodState)) {
          this.setState({ accumulatorOperationState: 'none', accumulatorOperationOptionsFiltered: optionNone });
          return;
        }
      } else if (!$.isEmptyObject(secondKpiState)) {
        if (secondKpiState.period_step === accumPeriodToPeriodStep(accumulatorPeriodState)) {
          this.setState({ accumulatorOperationState: 'none', accumulatorOperationOptionsFiltered: optionNone });
          return;
        }
      }
    }

    this.setState({ accumulatorOperationState: 'sum', accumulatorOperationOptionsFiltered: accumulatorOperationOptions.filter(option => option.value !== 'none') });
  }

  kpiSelect = (selectedOption, state) => {
    const { accumulatorPeriodOptions, periodStepOptions } = this.props;

    this.setState({ [state]: selectedOption || {} }, () => {
      const { firstKpiState, secondKpiState } = this.state;

      const filteredOptions = filterAccumulatorPeriodOptions(accumulatorPeriodOptions, periodStepOptions, firstKpiState, secondKpiState);
      this.setState({ accumulatorPeriodState: filteredOptions[filteredOptions.length - 1].value }, () => {
        this.filterAccumulatorOperationOptions();
      });
    });
  }

  renderUnit = () => {
    const { unitOptions, isPredefined } = this.props;
    const { unitState } = this.state;

    return (
      <div
        className="mb2"
        data-balloon="How the values will be displayed."
        data-balloon-pos="right"
      >
        <span className="flex flex-start">Unit</span>
        <ReactSelect
          value={unitOptions.filter(option => option.value === unitState)}
          options={unitOptions}
          onChange={(selectedOption) => this.setState({ unitState: selectedOption.value })}
          styles={defaultSelectStyles}
          theme={defaultSelectTheme}
          isDisabled={isPredefined}
          placeholder="Select unit"
        />
        {/* <i className="fa fa-fw fa-calculator absolute h6 right-0 transform-vertical-center vertical-center" style={{ margin: '3px' }} /> */}
        <input type="hidden" name="kpi[unit]" value={unitState} />
      </div>
    );
  }

  render() {
    const {
      billingUrl,
      accumulatorPeriodOptions,
      periodStepOptions,
      kpiOptions,
      kpiOperationsOptions,
      timeShiftOptions,
      aggregationOptions,
      edit,
      isPredefined,
      maxDerivedKpis,
      derivedKpisCount,
      activeSubscription,
      canCreateDerivedKpis,
      backButtonLink,
      submitLink,
    } = this.props;

    const {
      derivedKpichecked,
      metricState,
      initialPeriodStepState,
      periodStepState,
      firstKpiState,
      secondKpiState,
      kpiOperationState,
      timeShiftState,
      accumulatorPeriodState,
      accumulatorOperationState,
      descriptionState,
      aggregationTypeState,
      accumulatorOperationOptionsFiltered,
      openConfirmationModal,
    } = this.state;

    const formattedKpiLabels = { sum: 'KPI_1 + KPI_2', subtract: 'KPI_1 - KPI_2', multiply: 'KPI_1 * KPI_2', ratio: 'KPI_1 / KPI_2', growth: '(KPI_1 / KPI_2) - 1' };
    const formattedKpiOperations = kpiOperationsOptions.map(option => ({ label: `${option.label}: ${formattedKpiLabels[option.value]}`, value: option.value }));
    const accumulatorPeriodOptionsFiltered = filterAccumulatorPeriodOptions(accumulatorPeriodOptions, periodStepOptions, firstKpiState, secondKpiState);
    const disabledDerivedKpis = !activeSubscription && derivedKpisCount >= maxDerivedKpis;

    return (
      <>
        <div>
          <div className="flex items-center">
            {!edit && canCreateDerivedKpis &&
              <>
                <CheckBox
                  defaultChecked={derivedKpichecked}
                  onChange={() => this.setState({ derivedKpichecked: !derivedKpichecked })}
                  fixedWidth
                  disabled={disabledDerivedKpis}
                  disabledMessage="Can't create more derived KPIs"
                />
                <span className="ml2 fw400 nowrap">Derived KPI</span>
                <div className="relative">
                  <span className="desktop__notification--bell" data-behavior="hide-bubble" style={{ borderRadius: '30%', width: '25px', top: '-17px', right: '-15px', zIndex: 1 }}>
                    Beta
                  </span>
                </div>
                {disabledDerivedKpis && billingUrl &&
                  <span className="h6 ml1">{`You have reached the limit of ${maxDerivedKpis} derived KPIs.`} <a className="text-blue" href={billingUrl}>Upgrade here</a> to unlock more.</span>
                }
              </>
            }
            <input type="hidden" name="is_derived_kpi" value={derivedKpichecked} />
          </div>

          <div className="flex flex-column">
            {/* Metric */}
            <div className="mb2 relative">
              <TextInput
                value={metricState}
                onChange={(e) => this.setState({ metricState: e.target.value })}
                placeholder="Metric name"
                className={`border border-gallery mt1 h5 col-12 ${isPredefined ? 'placeholder-gray-input text-medium-gray' : ''}`}
                disabled={isPredefined}
              />
              {/* <i className="fa fa-fw fa-list-ol absolute h6 right-0 transform-vertical-center vertical-center" style={{ margin: '3px' }} /> */}
              <input type="hidden" name="kpi[metric]" value={metricState} />
            </div>

            {/* Custom KPI properties */}
            {!edit && !derivedKpichecked &&
              <>
                <div
                  className="mb2"
                  data-balloon="Indicates the KPI frequency, which means daily, weekly, monthly or quarterly type."
                  data-balloon-pos="right"
                >
                  <span className="flex flex-start">Frequency</span>
                  <ReactSelect
                    value={periodStepOptions.filter(option => option.value === periodStepState)}
                    options={periodStepOptions}
                    onChange={(selectedOption) => this.setState({ periodStepState: selectedOption.value })}
                    placeholder="Select frequency"
                    styles={defaultSelectStyles}
                    theme={defaultSelectTheme}
                  />
                  {/* <i className="fa fa-fw fa-calculator absolute h6 right-0 transform-vertical-center vertical-center" style={{ margin: '3px' }} /> */}
                  <input type="hidden" name="kpi[period_step]" value={periodStepState} />
                </div>
                {/* Unit */}
                {this.renderUnit()}
              </>
            }
          </div>

          {/* Derived KPI properties */}
          {derivedKpichecked &&
            <div className="flex flex-column">
              {/* 1st KPI */}
              <div
                className="mb2"
                data-balloon="The first KPI of the calculation to derive from."
                data-balloon-pos="right"
              >
                <span className="flex flex-start">1st KPI</span>
                <ReactSelect
                  value={kpiOptions.filter(option => option.value === firstKpiState.value)}
                  options={kpiOptions}
                  onChange={(selectedOption) => this.kpiSelect(selectedOption, 'firstKpiState')}
                  styles={defaultSelectStyles}
                  isClearable
                  isDisabled={edit}
                  theme={defaultSelectTheme}
                  placeholder={edit ? 'None' : 'Select...'}
                />
                {['Zero', 'One'].includes(firstKpiState.value) ?
                  <input type="hidden" name="kpi[derived_kpi_attributes][first_kpi_type]" value={firstKpiState.value} />
                  :
                  <input type="hidden" name="kpi[derived_kpi_attributes][first_kpi_id]" value={firstKpiState.value || ''} />
                }
              </div>

              <div
                className="mb2"
                data-balloon="The second KPI of the calculation to derive from."
                data-balloon-pos="right"
              >
                {/* 2nd KPI */}
                <span className="flex flex-start">2nd KPI</span>
                <ReactSelect
                  value={kpiOptions.filter(option => option.value === secondKpiState.value)}
                  options={kpiOptions}
                  onChange={(selectedOption) => this.kpiSelect(selectedOption, 'secondKpiState')}
                  styles={defaultSelectStyles}
                  isClearable
                  isDisabled={edit}
                  theme={defaultSelectTheme}
                  placeholder={edit ? 'None' : 'Select...'}
                />
                {['Zero', 'One'].includes(secondKpiState.value) ?
                  <input type="hidden" name="kpi[derived_kpi_attributes][second_kpi_type]" value={secondKpiState.value} />
                  :
                  <input type="hidden" name="kpi[derived_kpi_attributes][second_kpi_id]" value={secondKpiState.value || ''} />
                }
              </div>

              {/* Unit */}
              {this.renderUnit()}

              {/* KPI Operation */}
              <div
                className="mb2"
                data-balloon="How data values will be calculated and the resulting values will get saved into the derived KPI."
                data-balloon-pos="right"
              >
                <span className="flex flex-start">KPI Operation</span>
                <ReactSelect
                  value={formattedKpiOperations.filter(option => option.value === kpiOperationState)}
                  options={formattedKpiOperations}
                  onChange={(selectedOption) => this.setState({ kpiOperationState: selectedOption.value })}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                  placeholder="KPI Operation"
                />
                <input type="hidden" name="kpi[derived_kpi_attributes][kpi_operation]" value={kpiOperationState} />
              </div>

              {/* Time Shift */}
              <div
                className="mb2"
                data-balloon="The amount of time that will get shifted between the first and second KPI's data values."
                data-balloon-pos="right"
              >
                <span className="flex flex-start">Time Shift</span>
                <ReactSelect
                  value={timeShiftOptions.filter(option => option.value === timeShiftState)}
                  options={timeShiftOptions}
                  onChange={(selectedOption) => this.setState({ timeShiftState: selectedOption.value })}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                  placeholder="Time Shift"
                />
                <input type="hidden" name="kpi[derived_kpi_attributes][time_shift]" value={timeShiftState} />
              </div>

              {/* Accumulator Period */}
              <div
                className="mb2"
                data-balloon="This is the frequency of the resulting KPI. If no accumulation is needed, keep this as the frequency of the input KPIs."
                data-balloon-pos="right"
              >
                <span className="flex flex-start">Accumulator Period</span>
                <ReactSelect
                  value={accumulatorPeriodOptionsFiltered.filter(option => option.value === accumulatorPeriodState)}
                  options={accumulatorPeriodOptionsFiltered}
                  onChange={(selectedOption) => this.setState({ accumulatorPeriodState: selectedOption.value }, () => this.filterAccumulatorOperationOptions())}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                  placeholder="Accumulator Period"
                  isDisabled={edit}
                />
                <input type="hidden" name="kpi[derived_kpi_attributes][accumulator_period]" value={accumulatorPeriodState} />
              </div>

              {/* Accumulator Operation */}
              <div
                className="mb2"
                data-balloon="This is the operation in which the data values will be accumulated by."
                data-balloon-pos="right"
              >
                <span className="flex flex-start">Accumulator Operation</span>
                <ReactSelect
                  value={accumulatorOperationOptionsFiltered.filter(option => option.value === accumulatorOperationState)}
                  options={accumulatorOperationOptionsFiltered}
                  onChange={(selectedOption) => this.setState({ accumulatorOperationState: selectedOption.value })}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                  placeholder="Accumulator Operation"
                />
                <input type="hidden" name="kpi[derived_kpi_attributes][accumulator_operation]" value={accumulatorOperationState} />
              </div>
            </div>
          }

          {/* Aggregation Type */}
          <div className="mb2">
            <span className="flex flex-start">Aggregation</span>
            <ReactSelect
              value={aggregationOptions.filter(option => option.value === aggregationTypeState)}
              options={aggregationOptions}
              onChange={(selectedOption) => this.setState({ aggregationTypeState: selectedOption.value })}
              placeholder="Select aggregation"
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
              isDisabled={isPredefined}
            />

            <div className="h6">
              <span>Aggregate monthly into quarterly and yearly values by using:</span>
              {aggregationTypeState === 'total' ?
                <div className="flex flex-column">
                  <span><b>Sum</b> of monthly values</span>
                  <span>E.g. Quarter 1 revenue is a sum of revenues for January, February and March</span>
                </div>
                :
                <div className="flex flex-column">
                  <span><b>Median</b> of monthly values</span>
                  <span>E.g. Quarter 1 revenue is the median of revenues for January, February and March</span>
                </div>
              }
            </div>
            <input type="hidden" name="kpi[aggregation_type]" value={aggregationTypeState} />
          </div>

          {/* Description */}
          <div className="flex flex-column">
            <div className="mb2 mt1">
              <Textarea
                value={descriptionState}
                className={`border-lighter-gray col-12 ${isPredefined ? 'placeholder-gray-input text-medium-gray' : ''}`}
                placeholder="Description"
                style={{ minHeight: '70px' }}
                onChange={(e) => this.setState({ descriptionState: e.target.value })}
                disabled={isPredefined}
              />
              <input type="hidden" name="kpi[description]" value={descriptionState} />
            </div>
          </div>
        </div>

        {isPredefined && edit &&
          <div className="mb2 text-light-gray h5">
            <span>Some fields can not be edited as they are pre-defined!</span>
          </div>
        }

        {edit &&
          <div className="flex mxn2">
            <div className="col-6 px2">
              <a
                href={backButtonLink}
                className="bg-blue block border-none h5 py1 rounded text-white z1 cursor-pointer"
              >
                Back
              </a>
            </div>

            <div
              className={`col-6 px2 ${initialPeriodStepState === periodStepState ? 'display-none' : ''}`}
              onClick={() => this.setState({ openConfirmationModal: true })}
            >
              <div className="flex items-center flex-justify-center bg-green border-none block col-12 cursor-pointer h5 height-40 rounded text-center text-white">
                Save Settings
              </div>
            </div>

            <div className={`col-6 px2 ${initialPeriodStepState !== periodStepState ? 'display-none' : ''}`}>
              <HtmlComponent data={{ html: submitLink }} />
            </div>

            <ConfirmationModal
              text="Changing the KPI frequency will cause all data to be deleted. <br/><br/> <b>Are you sure?</b>"
              cancelText="Cancel"
              onSubmit={() => $('#submit_button').trigger('click')}
              closeModal={() => this.setState({ openConfirmationModal: false })}
              show={openConfirmationModal}
            />
          </div>
        }
      </>
    );
  }
}

export default CreateNewKpi;
