import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

class AddButton extends Component {
  static propTypes = {
    position: PropTypes.number.isRequired,
    createSection: PropTypes.func.isRequired,
  }

  state = {
    open: false,
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
    document.addEventListener('touchstart', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
    document.removeEventListener('touchstart', this.handleClick, false);
  }

  createButton = () => {
    const { position } = this.props;
    return (
      <div>
        <span
          className="cursor-pointer inline-block mr2"
          data-balloon="Text"
          data-balloon-pos="down"
        >
          <i
            className="fa fa-font"
            onClick={() => this.createSection(position, 'text')}
          />
        </span>
        <span
          className="cursor-pointer inline-block mr2"
          data-balloon="Photo"
          data-balloon-pos="down"
        >
          <i
            className="fa fa-picture-o"
            onClick={() => this.createSection(position, 'photo')}
          />
        </span>
        <span
          className="cursor-pointer inline-block mr2"
          data-balloon="Graph"
          data-balloon-pos="down"
        >
          <i
            className="fa fa-line-chart"
            onClick={() => this.createSection(position, 'kpi')}
          />
        </span>
        <span
          className="cursor-pointer inline-block mr2"
          data-balloon="Upload"
          data-balloon-pos="down"
        >
          <i
            className="fa fa-upload"
            onClick={() => this.createSection(position, 'file')}
          />
        </span>
      </div>
    );
  }

  createMenuButton = () => (
    <div onClick={() => this.setState({ open: true })}>
      <span className="cursor-pointer inline-block mr2">
        <i className="fa fa-plus" />
      </span>
      <span className="cursor-pointer">Add</span>
    </div>
  );

  createSection = (position, type) => {
    const { createSection } = this.props;
    createSection(position, type);
    this.setState({ open: false });
  }

  handleClick = (e) => {
    if (ReactDOM.findDOMNode(this).contains(e.target)) return;
    this.setState({ open: false });
  }

  render() {
    const { open } = this.state;

    return (
      open ? this.createButton() : this.createMenuButton()
    );
  }
}

export default AddButton;
