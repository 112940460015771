import React from 'react';
import PropTypes from 'prop-types';


const Invite = ({ email }) => (
  <div className="invite__item clearfix">
    <span className="invite__email">{email}</span>
    <span className="btn btn-default invite__item-btn">
      <i className="fa fa-check" />
      Invited
    </span>
  </div>
);

Invite.propTypes = {
  email: PropTypes.string.isRequired,
};

export default Invite;
