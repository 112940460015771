import {
  updateManagers,
  updateFunds,
  updateOverallStatus,
  updateFundraiseStatus,
  removeCover,
} from '../../../actions/investor/investment/headerActions';


const addManager = (investorId, investmentId, membershipId) =>
  dispatch =>
    App.Api.post(`/investor/${investorId}/investments/${investmentId}/investment_managers`, { investment_manager: { membership_id: membershipId } }).then(
      res => dispatch(updateManagers(res)),
    );

const deleteManager = (investorId, investmentId, managerId) =>
  dispatch =>
    App.Api.delete(`/investor/${investorId}/investments/${investmentId}/investment_managers/${managerId}`).then(
      res => dispatch(updateManagers(res)),
    );

const addFundConnection = (investorId, investmentId, fundId) =>
  dispatch =>
    App.Api.post(`/investor/${investorId}/investments/${investmentId}/fund_connections`, { fund_connection: { fund_id: fundId } }).then(
      res => dispatch(updateFunds(res)),
    );

const deleteFundConnection = (investorId, investmentId, fundConnectionId) =>
  dispatch =>
    App.Api.delete(`/investor/${investorId}/investments/${investmentId}/fund_connections/${fundConnectionId}`).then(
      res => dispatch(updateFunds(res)),
    );

const selectOverallStatus = (investorId, value, investmentId) =>
  dispatch =>
    App.Api.patch(`/investor/${investorId}/investments/${investmentId}`, 'status_option_id', value).then(
      res => dispatch(updateOverallStatus(res)),
    );

const selectFundraiseStatus = (investorId, value, investmentId, companyReportId) =>
  dispatch =>
    App.Api.submitPatch(`/investor/${investorId}/investments/${investmentId}/company_reports/${companyReportId}`, { company_report: { fundraise_status_id: value } }).then(
      res => dispatch(updateFundraiseStatus(res)),
    );

const deleteCover = (investorId, investmentId, params) =>
  async dispatch => {
    await App.Api.delete(`/investor/${investorId}/investments/${investmentId}/investment_fields`, params);
    dispatch(removeCover());
  };

export {
  addManager,
  deleteManager,
  addFundConnection,
  deleteFundConnection,
  selectOverallStatus,
  selectFundraiseStatus,
  deleteCover,
};
