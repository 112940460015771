import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import ReactSelect from 'react-select';

import { defaultSelectStyles, defaultSelectTheme } from '../../selects/SelectStyles';


class FileSection extends Component {
  static propTypes = {
    section: PropTypes.object.isRequired,
    uploadGif: PropTypes.string.isRequired,
    deleteSection: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      file: props.section.file,
      uploading: false,
    };
  }

  handleFile(fileId) {
    const { file } = this.state;
    const url = file.update_url;

    this.setState({ uploading: true });
    App.Api.patch(url, 'section', { data_room_file_id: fileId }).then((data) => {
      this.setState({ file: data });
      this.setState({ uploading: false });
    }).catch(() => {
      this.setState({ uploading: false });
      App.State.setFlash({
        name: 'alert',
        msg: 'Upload failed',
      });
    });
  }

  uploadFile(fileToUpload) {
    const { file } = this.state;
    const url = file.upload_url;

    this.setState({ uploading: true });

    App.Api.uploadFile(url, fileToUpload[0]).then((data) => {
      this.setState({ file: data });
      this.setState({ uploading: false });
    }).catch((msg) => {
      this.setState({ uploading: false });
      App.State.setFlash({
        name: 'alert',
        msg: `Sorry, Upload failed. ${msg.error}`,
      });
    });
  }

  renderDropzone() {
    return (
      <div className="col-6">
        <div className="flex flex-center">
          <span className="h4">Upload Document</span>
          <div
            data-balloon="Uploading a Document inside this update, will enable all recipients to download it, even if they not on investory or have access to the update through a shareable link."
            data-balloon-pos="up"
            data-balloon-length="xlarge"
          >
            <i className="fa fa-link pl1 text-light-gray h4" />
          </div>
        </div>
        <Dropzone
          className="border-light-gray flex flex-center cursor-pointer"
          style={{ height: '42px', borderWidth: '2px', borderStyle: 'dotted', borderRadius: '5px' }}
          onDrop={(file) => this.uploadFile(file)}
          multiple={false}
        >
          <div className="px1 text-light-gray flex flex-center flex-auto flex-justify-between">
            <span className="h4 italic">Upload Document...</span>
          </div>
        </Dropzone>
      </div>
    );
  }

  renderFile() {
    const { file } = this.state;
    return (
      <div className="flex flex-center flex-justify-between">
        <a className="flex flex-center" href={file.url} target="_blank" rel="noopener noreferrer">
          <span className="fa fa-2x"><i className={file.icon_class_name} style={{ color: file.icon_color }} /></span>
          <div className="flex flex-column pl1">
            <span className="fw400 text-medium-editor lh-12">{file.file_name}</span>
            <span className="fs-12 text-light-gray">{file.size_and_type} {file.login_required ? '(login required to access)' : ''}</span>
          </div>
        </a>
        {
          /*
          <span
            className="border border-alto cursor-pointer px1 rounded"
            onClick={() => this.deleteFile(file.id)}
            style={{
              paddingBottom: 3,
              paddingTop: 3
            }}
          >
            <i className="fa fa-trash-o h5" />
          </span>
          */
        }
      </div>
    );
  }

  renderFileSelect() {
    const { file } = this.state;

    return (
      <div className="col-6">
        <div className="flex flex-center">
          <span className="h4">Add Document from Data Room</span>
          <div
            data-balloon="Selecting a Document from the Data Room, will require recipients to have a registered account to view/download the document. Invited recipients or users viewing updates via shareable link will not have access until sign-up."
            data-balloon-pos="up"
            data-balloon-length="xlarge"
          >
            <i className="fa fa-lock pl1 text-light-gray h4" />
          </div>
        </div>
        <span className="h4 italic">
          <ReactSelect
            value=""
            options={file.data_room_files_options}
            isClearable={false}
            onChange={(selectedOption) => this.handleFile(selectedOption.value)}
            placeholder="Select or Search Document"
            styles={defaultSelectStyles}
            theme={defaultSelectTheme}
          />
        </span>
      </div>
    );
  }

  render() {
    const { section, uploadGif, deleteSection } = this.props;
    const { file, uploading } = this.state;

    if (uploading) {
      return (
        <div className="p2 flex flex-justify-center">
          <img src={uploadGif} alt="Uploading Gif" width="32" height="32" />
        </div>
      );
    }
    return (
      <div className="p2 border border-alto">
        {
          file.url ?
            this.renderFile()
            :
            <div className="flex">
              {this.renderFileSelect()}

              <span className="col-1 flex flex-end flex-justify-center h5 pb1">OR</span>

              {this.renderDropzone()}
            </div>
        }
        {
          !file.url &&
          <div className="flex flex-justify-center pt3">
            <div
              className="button rounded p1 bg-lighter-gray text-gray flex flex-justify-center cursor-pointer"
              style={{ width: '100px' }}
              onClick={(e) => deleteSection(section.url, e)}
            >
              <span>Cancel</span>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default FileSection;
