import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../../../store/configureStore';
import DealRoomContainer from '../../../containers/company/deal_rooms/DealRoomContainer';


const CompanyDealRoom = props => (
  <Provider store={configureStore(props)}>
    <DealRoomContainer />
  </Provider>
);

export default CompanyDealRoom;
