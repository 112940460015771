import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Avatar from './Avatar';


class UpdateRecipient extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  renderInvestorMembersRecipients = () => {
    const { data } = this.props;

    if (!data.members_recipients || data.members_recipients.length === 0) return null;
    const investorMembers = data.members_recipients.map((member) => (
      <div
        key={`member_${member.email}`}
        className="col-12 flex flex-center px2 pb2 sm-px0 fw400"
      >
        <div className="col-5 sm-col-7 pl3">
          <div className="ml1 text-gray flex flex-center">
            <Avatar avatarUrl={member.avatar} />
            <div className="truncate text-gray col-7 ml1">{member.name}</div>
          </div>
        </div>
        <a className="col-3 sm-hide truncate" href={`mailto:${member.email}`}>
          {member.email}
        </a>
        <div className="col-1 center sm-hide">
          {member.delivered ? <i className="fa fa-check-circle text-green" /> : '-'}
        </div>
        <div className="col-1 center sm-col-2">
          {member.read ? <i className="fa fa-check-circle text-green" /> : '-'}
        </div>
        <div className="col-1 center sm-hide">
          {member.read_in_app ? <i className="fa fa-check-circle text-green" /> : '-'}
        </div>
        <div className="col-1 center sm-col-2">
          {member.bounced ? <i className="fa fa-times-circle text-red" /> : '-'}
        </div>
      </div>
    ));

    return (
      <div>
        {investorMembers}
      </div>
    );
  }

  render() {
    const { data } = this.props;
    return (
      <div>
        <div className="col-12 flex flex-center p2 sm-px0 fw400">
          <div className="col-3 sm-col-7">
            <div className="ml1 text-gray flex flex-center">
              <span
                className="block col-3 bg-center bg-cover bg-no-repeat circle header__avatar--dash mr1"
                style={{ backgroundImage: `url( ${data.avatar} )` }}
              />
              <div className="truncate text-gray col-7">{data.name}</div>
            </div>
          </div>
          <div className="col-2 sm-hide text-light-gray truncate">
            {(data.type === 'Group') ? `${data.type} (Counted as One)` : data.type }
          </div>
          <a className="col-3 sm-hide truncate" href={`mailto:${data.email}`}>
            {data.email}
          </a>
          <div className="col-1 center sm-hide">
            {data.delivered ? <i className="fa fa-check-circle text-green" /> : '-'}
          </div>
          <div className="col-1 center sm-col-2">
            {data.read ? <i className="fa fa-check-circle text-green" /> : '-'}
          </div>
          <div className="col-1 center sm-hide">
            {data.read_in_app ? <i className="fa fa-check-circle text-green" /> : '-'}
          </div>
          <div className="col-1 center sm-col-2">
            {data.bounced ? <i className="fa fa-times-circle text-red" /> : '-'}
          </div>
        </div>
        {
          this.renderInvestorMembersRecipients()
        }
      </div>
    );
  }
}

export default UpdateRecipient;
