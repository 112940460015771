import React, { Component } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import PropTypes from 'prop-types';

import ColorPicker from './selects/ColorPicker';
import Circle from './Circle';
import CheckBox from './CheckBox';
import SelectWithDate from './selects/SelectWithDate';


const avatar = url => <img alt="*" src={url} width="15" height="15" />;

class ExportSettings extends Component {
  static propTypes = {
    investments: PropTypes.array,
    sections: PropTypes.array,
    submitFunc: PropTypes.func,
    rangeOptions: PropTypes.object,
    generatingReport: PropTypes.bool,
  }

  static defaultProps = {
    investments: [],
    sections: [],
    submitFunc: null,
    rangeOptions: {},
    generatingReport: false,
  }

  constructor(props) {
    super(props);
    const { investments, rangeOptions } = this.props;

    this.state = {
      checked: investments.map(i => i.id),
      expanded: ['investments'],
      checkedOptions: this.checkedOptions(),
      expandedOptions: ['profile', 'captable', 'predefined_kpis'],
      headerBackground: '#0084D1',
      headerColor: '#ffffff',
      dataFieldBackground: '#efefef',
      dataFieldColor: '#707070',
      revenueColor: '#0000FF',
      costColor: '#FF0000',
      showCompanyLogo: investments.length <= 30,
      dateRange: 'last_month',
      startDate: rangeOptions.start_date_last_month,
      endDate: rangeOptions.end_date_last_month,
    };
  }

  getSectionChildren = (sectionLabel) => {
    if (sectionLabel === 'profile') return this.profileOptionNodes();
    if (sectionLabel === 'captable') return this.sharesChartNode();
    if (sectionLabel === 'predefined_kpis') return this.rvcChartNode();
    return null;
  }

  checkedOptions = () => {
    const unselectedSections = ['performance', 'other_kpis'];
    const options = this.optionNodes().filter(option => !unselectedSections.includes(option.value)).map(option => option.value);
    options.push(...this.profileOptionNodes().map(option => option.value));
    options.push(...this.rvcChartNode().map(option => option.value));
    return options;
  }

  invstmentNodes = () => (
    [
      { value: 'investments', label: 'All Investments', icon: '', children: this.listCompanies(), className: 'h4 text-gray parent-node' },
    ]
  )

  listCompanies = () => {
    const { investments } = this.props;
    return investments.map((i) => ({ value: i.id, label: i.name, icon: avatar(i.avatar), className: 'h4 text-gray truncate' }));
  }

  optionNodes = () => {
    const { sections } = this.props;
    const options = sections.map((section) => (
      { value: section.option, label: section.label, icon: '', className: 'h4 text-gray parent-node', children: this.getSectionChildren(section.option) }
    ));
    return options;
  }

  profileOptionNodes = () => (
    [
      { value: 'profile_description', label: 'Description', icon: '', className: 'h4 text-gray' },
      { value: 'profile_founding', label: 'Founding', icon: '', className: 'h4 text-gray' },
      { value: 'profile_industry', label: 'Industry', icon: '', className: 'h4 text-gray' },
      { value: 'profile_website', label: 'Website', icon: '', className: 'h4 text-gray' },
      { value: 'profile_team_members', label: 'Team Members', icon: '', className: 'h4 text-gray' },
      { value: 'profile_investors', label: 'Investors', icon: '', className: 'h4 text-gray' },
      { value: 'profile_managers', label: 'Managers', icon: '', className: 'h4 text-gray' },
      { value: 'profile_investment_fields', label: 'Custom Fields', icon: '', className: 'h4 text-gray' },
    ]
  )

  rvcChartNode = () => (
    [
      { value: 'rvc_chart', label: 'Revenue vs Cost Chart', icon: '', className: 'h4 text-gray' },
    ]
  )

  sharesChartNode = () => (
    [
      { value: 'shares_chart', label: 'Captable Chart', icon: '', className: 'h4 text-gray' },
    ]
  )

  submit = async () => {
    const { submitFunc } = this.props;
    const {
      checked,
      checkedOptions,
      showCompanyLogo,
      headerBackground,
      headerColor,
      dataFieldBackground,
      dataFieldColor,
      revenueColor,
      costColor,
      dateRange,
      startDate,
      endDate,
    } = this.state;

    const params = {
      investments: checked,
      options: checkedOptions,
      show_company_logo: showCompanyLogo,
      header_background: headerBackground,
      header_color: headerColor,
      data_field_background: dataFieldBackground,
      data_field_color: dataFieldColor,
      revenue_color: revenueColor,
      cost_color: costColor,
      date_range: dateRange,
      start: startDate,
      end: endDate,
    };

    submitFunc(params);
  }

  render() {
    const {
      checked, expanded, checkedOptions, expandedOptions,
      headerBackground, headerColor, dataFieldBackground,
      dataFieldColor, revenueColor, costColor, showCompanyLogo,
    } = this.state;

    const { rangeOptions, generatingReport } = this.props;

    return (
      <div className="flex mxn2 flex-wrap">
        <div className="col-4 flex px2 lg-col-12 lg-mb4">
          <div className="bg-white bs-around center flex flex-column p2 relative col-12">

            <div className="flex-auto">
              <div className="flex flex-center mb2 mr2">
                <Circle content="1" border="gray" text="gray" />
                <div className="h3 fw400 text-gray">Select Investments</div>
              </div>

              <div className="m0 mb3 border-top border-wild-sand">
                <div className="mt2 mx2">
                  <CheckboxTree
                    nodes={this.invstmentNodes()}
                    checked={checked}
                    expanded={expanded}
                    onCheck={checkedInvestments => this.setState({ checked: checkedInvestments })}
                    onExpand={expandedInvestments => this.setState({ expanded: expandedInvestments })}
                  />
                  {/* <input type="hidden" name="investments" value={checked} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-4 flex px2 lg-col-12 lg-mb4">
          <div className="bg-white bs-around center flex flex-column p2 relative col-12">

            <div className="flex-auto">
              <div className="flex flex-center mb2 mr2">
                <Circle content="2" border="gray" text="gray" />
                <div className="h3 fw400 text-gray">Select Sections</div>
              </div>

              <div className="m0 mb3 border-top border-wild-sand">
                <div className="mb2 mt2 ml2">
                  <CheckboxTree
                    nodes={this.optionNodes()}
                    checked={checkedOptions}
                    expanded={expandedOptions}
                    onCheck={checkedValues => this.setState({ checkedOptions: checkedValues })}
                    onExpand={expandedValues => this.setState({ expandedOptions: expandedValues })}

                  />
                  {/* <input type="hidden" name="options" value={checkedOptions} /> */}
                </div>

                <SelectWithDate
                  rangeOptions={rangeOptions}
                  onChange={(dateRange, startDate, endDate) => this.setState({ dateRange, startDate, endDate })}
                />

                <div className="mb2 mx2 flex">
                  <CheckBox
                    defaultChecked={showCompanyLogo}
                    onChange={() => this.setState({ showCompanyLogo: !showCompanyLogo })}
                    fixedWidth
                  />
                  <span className="ml2">Add Company Logos</span>
                  {/* <input type="hidden" name="show_company_logo" value={showCompanyLogo} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-4 flex px2 lg-col-12 lg-mb4">
          <div className="bg-white bs-around center flex flex-column p2 relative col-12">

            <div className="flex-auto">
              <div className="flex flex-center mb2 mr2">
                <Circle content="3" border="gray" text="gray" />
                <div className="h3 fw400 text-gray">Select Layout</div>
              </div>

              <div className="m0 mb2 border-top border-wild-sand">
                <div className="flex flex-column flex-start m2">
                  <div className="mb1 flex flex-center">
                    <ColorPicker
                      color={headerBackground}
                      onChange={color => this.setState({ headerBackground: color.hex })}
                    />
                    <span className="m1 nowrap"> Header Background </span>
                    {/* <input type="hidden" name="header_background" value={headerBackground} /> */}
                  </div>
                  <div className="mb1 flex flex-center">
                    <ColorPicker
                      color={headerColor}
                      onChange={color => this.setState({ headerColor: color.hex })}
                    />
                    <span className="m1 nowrap"> Header Text Color </span>
                    {/* <input type="hidden" name="header_color" value={headerColor} /> */}
                  </div>
                  <div className="mb1 flex flex-center col-6">
                    <ColorPicker
                      color={dataFieldBackground}
                      onChange={color => this.setState({ dataFieldBackground: color.hex })}
                    />
                    <span className="m1 nowrap"> Data Field Background </span>
                    {/* <input type="hidden" name="data_field_background" value={dataFieldBackground} /> */}
                  </div>
                  <div className="mb1 flex flex-center col-6">
                    <ColorPicker
                      color={dataFieldColor}
                      onChange={color => this.setState({ dataFieldColor: color.hex })}
                    />
                    <span className="m1 nowrap"> Data Field Text Color </span>
                    {/* <input type="hidden" name="data_field_color" value={dataFieldColor} /> */}
                  </div>
                  { checkedOptions.find(option => option === 'rvc_chart') &&
                    <div>
                      <div className="mb1 flex flex-center col-6">
                        <ColorPicker
                          color={revenueColor}
                          onChange={color => this.setState({ revenueColor: color.hex })}
                        />
                        <span className="m1 nowrap"> Bar Chart: Revenue </span>
                      </div>
                      <div className="mb1 flex flex-center col-6">
                        <ColorPicker
                          color={costColor}
                          onChange={color => this.setState({ costColor: color.hex })}
                        />
                        <span className="m1 nowrap"> Bar Chart: Cost </span>
                      </div>
                    </div>
                  }
                  {/* <input type="hidden" name="revenue_color" value={revenueColor} />
                  <input type="hidden" name="cost_color" value={costColor} /> */}
                </div>

                <div className="mt2 m2 bs-around">
                  <div className="p1 flex">
                    <div className="border border-white col-6" style={{ backgroundColor: `${headerBackground}`, color: `${headerColor}` }}>Sample Header</div>
                    <div className="border border-white col-6" style={{ backgroundColor: `${dataFieldBackground}`, color: `${dataFieldColor}` }}>Sample Data</div>
                  </div>
                </div>
                { checkedOptions.find(option => option === 'rvc_chart') &&
                  <div className="mt2 m2 bs-around">
                    <svg width="150" height="120">
                      <rect x="10" width="31" y="5.66367501180912" height="115.3363249881909" style={{ fill: revenueColor }} />
                      <rect x="44" width="31" y="87.1421823334908" height="35.85781766650922" style={{ fill: costColor }} />
                      <rect x="78" width="31" y="51.4407179971658" height="68.55928200283421" style={{ fill: revenueColor }} />
                      <rect x="112" width="31" y="29.3268776570619" height="90.6731223429381" style={{ fill: costColor }} />
                    </svg>
                  </div>
                }
              </div>

              <div className="mt2">
                <div
                  className={`bg-alto border-none text-gray inline-block height-38 px3 sm-px2 py1 m2 rounded fw400 z1 ${generatingReport ? 'cursor-disabled' : 'cursor-pointer'}`}
                  onClick={this.submit}
                >
                  <span>{generatingReport ? 'Exporting' : 'Export'}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExportSettings;
