import Color from 'color';

// filterType can be 'standard' or 'custom'
const groupBy = (data, filter, filterType) => (
  data.reduce((acc, curr) => {
    let current;

    if (filterType !== 'custom') {
      if (!curr[filter]) {
        current = '';
      } else {
        current = curr[filter].toString().toLowerCase();
      }
    } else {
      const customField = curr.investment_fields.find(field => field.name === filter);
      if (!customField || customField.text === null || customField.text === undefined) {
        current = '';
      } else {
        current = customField.text;
      }
    }

    if (!acc[current]) acc[current] = []; // If this type wasn't previously stored

    acc[current].push(curr);
    return acc;
  }, {})
);

const color = (index, arraySize, colorTheme) => {
  let colorPalette = colorTheme && colorTheme !== '#ffffff' ? Color(colorTheme) : Color(window.ColorVariables.colorBlue);

  if (colorPalette.luminosity() > 0.9) colorPalette = colorPalette.darken(0.3);

  if (colorPalette.isDark()) {
    let c = colorPalette;
    if (c.luminosity() < 0.1) {
      if (c.rgb().array().every((el, _i, array) => el === array[0])) {
        c = Color('#0a0a0a');
        c.lighten(index * 2).hex();
      }

      c = Color.rgb(c.rgb().array().map(el => el + 10));
      return c.lighten(index * 0.7).hex();
    }
    let shadeValue = 0.22;
    if (c.lighten((arraySize - 1) * shadeValue).luminosity() > 0.9) shadeValue = 0.17;

    return c.lighten(index * shadeValue).hex();
  }

  return colorPalette.darken((arraySize - index) * 0.1).hex();
};

export default function (data, type, options = { filter: null, filterType: 'standard', kpiMetric: null, colorTheme: null, hideData: false }, useConvertedValues = false) {
  if (type === 'company_info') {
    if (options.hideData) {
      return [{
        id: 0,
        y: 99,
        name: 'Dummy Name',
        color: color(0, 2, options.colorTheme),
      }];
    }

    const sortedData = [...data].sort((a, b) => (a.y > b.y ? -1 : 0)).slice(0, 6);
    const grouped = groupBy(data, options.filter, options.filterType);
    const sortedKeys = Object.keys(grouped).sort((a, b) => (grouped[a].length > grouped[b].length ? -1 : 0)).slice(0, 5);

    if (data[0] && data[0].company_url === undefined) {
      return sortedData.map((key, index) => (
        {
          id: index,
          y: key.y,
          name: key.name || 'N/A',
          color: color(index, sortedData.length, options.colorTheme),
        }
      ));
    }
    return sortedKeys.map((key, index) => (
      {
        id: index,
        y: grouped[key].length,
        name: key || 'N/A',
        color: color(index, sortedKeys.length, options.colorTheme),
      }
    ));
  }

  if (type === 'investment_info') {
    const sortedData = [...data].sort((a, b) => (a.current_share_value_converted > b.current_share_value_converted ? -1 : 0)).slice(0, 6);

    return sortedData.map((d, index) => (
      {
        id: index,
        y: d.current_share_value_converted,
        readable_value: `${d.current_share_value_converted_readable} ${d.chosen_currency}`,
        name: d.name,
        color: color(index, sortedData.length, options.colorTheme),
      }
    ));
  }

  if (type === 'kpis_info') {
    const arrayOfKpis = data.map(d => d.kpis);
    const filteredKpis = arrayOfKpis.map(array => array.find(kpi => (kpi.metric ? kpi.metric.localeCompare(options.kpiMetric, undefined, { sensitivity: 'accent' }) === 0 : undefined)));

    return (
      options.hideData ?
        {
          actuals: [99, 88, 77, 66],
          forecasts: [55, 44, 33, 22],
          categories: ['Dummy data'],
        }
        :
        {
          actuals: filteredKpis.map(value => (useConvertedValues && value.kpi_unit === 'currency' ? value.actual_converted_value : value.actual_raw_value) || null),
          forecasts: filteredKpis.map(value => (useConvertedValues && value.kpi_unit === 'currency' ? value.forecast_converted_value : value.forecast_raw_value) || null),
          categories: data.map(value => value.name),
          kpiUnit: filteredKpis.length > 0 ? filteredKpis.find(kpi => kpi.kpi_unit !== null && kpi.kpi_unit !== undefined).kpi_unit : null,
        }
    );
  }
  return null;
}
