import { connect } from 'react-redux';
import CompanyReport from '../components/company_report/CompanyReport';

import {
  saveCompanyReport,
  saveReportColumn,
  destroyReportColumn,
} from '../api/investor/companyReportApi';

const mapStateToProps = state => ({
  companyReport: state.companyReport,
});

const mapDispatchToProps = {
  saveCompanyReport,
  saveReportColumn,
  destroyReportColumn,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyReport);
