import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';

import Modal from '../../Modal';


class RoundsImportModal extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.initialState = {
      capTables: [],
      errors: [],
      loading: false,
      file: null,
    };

    this.state = this.initialState;
  }

  blockSubmit = () => {
    const { capTables, errors } = this.state;

    return (capTables.length === 0 || errors.length > 0);
  }

  handleCloseModal = () => {
    const { closeModal } = this.props;

    this.setState(this.initialState);
    closeModal();
  }

  checkImportedFile = (file) => {
    if ((file.size / 1024 / 1024) > 50) {
      this.setState({ loading: false });
      App.State.setFlash({ name: 'alert', msg: 'The file you are trying to upload is too large. Please upload files not larger than 50 MB!' });
      return false;
    }
    if (file.name.split('.').pop() !== 'xlsx') {
      this.setState({ loading: false });
      App.State.setFlash({ name: 'alert', msg: 'Only .xlsx fiels can be used.' });
      return false;
    }
    return true;
  }

  uploadFile = (file) => {
    const { company } = this.props;
    const { loading } = this.state;
    const formData = new FormData();

    const url = `/company/${company.id}/manual_cap_tables/import_info`;

    if (loading) return;

    this.setState({ loading: true });

    if (this.checkImportedFile(file[0])) {
      this.setState({ file: file[0] });
      formData.append('attachment', file[0]);
    } else {
      return;
    }

    axios.post(url, formData).then(
      (res) => {
        this.setState({ capTables: res.data });
        this.setState({ loading: false });
      },
      (error) => {
        this.setState({ loading: false, errors: error.response.data.error });
      },
    );
  }

  submit = () => {
    const { company } = this.props;
    const { file } = this.state;
    const formData = new FormData();

    const url = `/company/${company.id}/manual_cap_tables/import`;

    this.setState({ loading: true });

    formData.append('attachment', file);

    if (typeof Intercom !== 'undefined') Intercom('trackEvent', 'excel_new_import_cap_table');

    axios.post(url, formData).then(
      () => {
        App.State.setFlash({ name: 'notice', msg: 'Rounds imported successfully!' });
        this.setState({ loading: false });
        this.handleCloseModal();
        window.location = `/c/${company.id}/manual_cap_tables`;
      },
      (error) => {
        const errorMessage = error.response ? error.response.data.error : 'An error prevented this file from being uploaded.';
        App.State.setFlash({ name: 'alert', msg: errorMessage });
        this.setState({ loading: false });
      },
    );
  }

  render() {
    const { show } = this.props;
    const { loading, errors, file, capTables } = this.state;

    return (
      <Modal
        show={show}
        loading={loading}
      >
        <div className="flex flex-justify-between items-center bold p2 border-bottom border-lighter-gray">
          <div className="h3 text-gray center">Import Rounds from Excel</div>
          <i className="fa fa-close h5 cursor-pointer" onClick={this.handleCloseModal} />
        </div>

        <div className="flex flex-column items-center center mx2 mb1" style={{ maxWidth: '540px' }}>
          <div className="col-11">
            {
            !file &&
            <div>
              <div className="my2">
                <p className="text-light-gray mb1">
                To upload Rounds from a spreadsheet, it needs to be formatted similar to the example below.
                </p>
                <a className="" href="https://investory.io/2021_cap_table_import_template_v2_example/">Download the example template here</a>
              </div>
            </div>
            }

            <div className="my2">
              {
                capTables.length > 0 &&
                <div>
                  <div className="border border-green rounded mt3" style={{ borderStyle: 'dashed' }}>
                    <div className="flex items-center m1">
                      <i className="fa fa-check fa-lg mx1 text-green" />
                      <div className="ml1 flex flex-wrap truncate">
                      Upload of <span className="fw400">{`"${file.name}" `}</span> successful!
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-column flex-start my2 left-align lh-15 overflow-y-auto" style={{ maxHeight: '120px' }}>
                    <span>Detected and will be imported:</span>
                    <span>- <span className="fw400">{capTables.length}</span> Investment Rounds</span>
                    <span>- <span className="fw400">{capTables[0].manual_cap_shares_attributes.length}</span> Shareholders</span>
                    {/* {
                      cap_tables.map((capTable, index) => (
                        <span key={capTable.round_name}>- <span className="fw400 mr1">Round {index + 1}:</span>{capTable.manual_cap_shares_attributes.length} Shareholders </span>
                      ))
                    } */}
                  </div>
                </div>
              }
              {
                errors.length > 0 &&
                <div>
                  <div className="border border-red rounded mt3" style={{ borderStyle: 'dashed' }}>
                    <div className="flex items-center m1">
                      <i className="fa fa-close fa-lg mx1 text-red" />
                      <div className="ml1 flex flex-wrap truncate">
                        Upload of <span className="fw400">{` "${file.name}" `}</span> unsuccessful!
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-column flex-start my2 left-align lh-15">
                    <span>The import failed due to following reason(s):</span>
                    {
                      errors.map(errorMessage => (
                        <span key={Date.now} className="fw400">- {errorMessage}</span>
                      ))
                    }
                  </div>
                </div>
              }
              {
                errors.length === 0 && capTables.length === 0 &&
                <Dropzone
                  className="border-light-gray bg-lighter-gray flex flex-justify-center cursor-pointer"
                  style={{ borderWidth: '2px', borderStyle: 'dashed', borderRadius: '5px' }}
                  onDrop={(fileToUpload) => this.uploadFile(fileToUpload)}
                  multiple={false}
                  activeStyle={{ borderColor: window.ColorVariables.colorBlue }}
                >
                  <div className="px1 my3 text-light-gray center flex flex-column">
                    <i className="fa fa-file-o fa-lg mb2" />
                    <span className="h4">Click or Drag and drop a .xlsx file here</span>
                  </div>
                </Dropzone>
              }
              {
                ((errors.length === 0) && file) &&
                  <span className="text-orange">
                    WARNING: Importing overwrites all existing CapTable data!
                  </span>
              }
              {
                file &&
                  <div className="cursor-pointer" onClick={() => this.setState(this.initialState)}>
                    <span className="text-blue">To upload again, click here</span>
                  </div>
              }
            </div>

          </div>
        </div>

        <div className="flex flex-justify-end items-center p2 border-top border-lighter-gray">
          <div className="flex items-center">
            <div
              className="h5 text-blue mr2 cursor-pointer"
              onClick={this.handleCloseModal}
            >
              Cancel
            </div>
            <div
              className={`h5 p1 px2 text-white rounded ${this.blockSubmit() ? 'bg-lighter-gray' : 'bg-green cursor-pointer'}`}
              onClick={this.blockSubmit() ? null : this.submit}
            >
              {loading ? 'Importing...' : 'Import Rounds'}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default RoundsImportModal;
