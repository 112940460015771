import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SubscriptionDetails from './SubscriptionDetails';

class CustomerInfo extends Component {
  static propTypes = {
    customer: PropTypes.object.isRequired,
    subscription: PropTypes.object,
    plans: PropTypes.object.isRequired,
    subscribe: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,
    resubscribe: PropTypes.func.isRequired,
  }

  static defaultProps = {
    subscription: null,
  }

  state = {
    saving: false,
  }

  render() {
    const { customer, ...props } = this.props;
    const { saving } = this.state;

    return (
      <div className="flex flex-column">
        <div className="bg-white fw400 mb3 bs-around text-gray">
          <div className="border-bottom border-gallery p2 h4 bold">Billing/Payment Settings</div>
          <SubscriptionDetails {...props} />
          {customer.vat_number &&
            <div className="flex pt2 mx2 h5">
              <div className="col-3 text-light-gray">VAT Number</div>
              <div className="col-7 flex text-gray">
                {customer.vat_number}
              </div>
            </div>
          }
          <div className="flex py2 mx2 h5">
            <div className="col-3 text-light-gray">Payment Method</div>
            <div className="col-7 flex text-gray">
              {customer.brand} ending in {customer.last4}, exp: {customer.exp_month}/{customer.exp_year}
            </div>
            {saving ?
              <img alt="Please wait..." src={window.images.loading} width="32" height="32" />
              :
              <></>
            }
          </div>
        </div>

        <div className="bg-white fw400 bs-around text-gray">
          <div className="border-bottom border-gallery p2 h4 bold">Invoices</div>
          {customer.invoices.map(invoice => (
            <div key={`invoice_${invoice.uid}`} className="flex items-center mx2 py2 border-bottom border-gallery h5">
              <div className="col-3 text-light-gray">{invoice.period_start}</div>
              <div className="col-7">{invoice.description}</div>
              <a className="col-2 flex items-center flex-justify-end" href={invoice.pdf_link} target="_blank" rel="noopener noreferrer">
                <i className="fa fa-download mr1" />Invoice
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default CustomerInfo;
