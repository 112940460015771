import moment from 'moment';
import {
  kpisMedian,
  kpisSum,
} from './fundDashboardFormulas';

const kpiMetricForTotals = (metric) => {
  switch (metric) {
    case 'Cost Total':
      return 'Cost';
    case 'Net Cash Burn':
      return 'NCB';
    case 'Cash On Hand':
      return 'CoH';
    default:
      return metric;
  }
};

// kpisDashboardData -> refers to the filtered KPIs data from the reducer
// dashboardKpis -> all dashboard KPIs specific for each Fund
const kpiTotalValues = (kpisDashboardData, dashboardKpis) => {
  let sumValues = [];
  let medianValues = [];

  sumValues = dashboardKpis.map(kpi => kpisSum(kpisDashboardData, kpi.metric));
  medianValues = dashboardKpis.map(kpi => kpisMedian(kpisDashboardData, kpi.metric));

  return {
    sumValues,
    medianValues,
  };
};

const kpisWidgetData = (totals, dashboardKpis, currency) => {
  let data = [];

  dashboardKpis.forEach((element, index) => {
    const titleSuffix = element.sum_type === 'total' ? 'Total' : 'Median';
    const title = `${kpiMetricForTotals(dashboardKpis[index].metric)} (${titleSuffix})`;

    if (element.sum_type === 'total') {
      data = [
        ...data,
        {
          value: totals.sumValues[index] ? `${totals.sumValues[index]} ${element.unit === 'currency' ? currency : ''}` : null,
          title,
        },
      ];
    } else {
      data = [
        ...data,
        {
          value: totals.medianValues[index] ? `${totals.medianValues[index]} ${element.unit === 'currency' ? currency : ''}` : null,
          title,
        },
      ];
    }
  });

  return data;
};

const timeRangeOptions = (frequency, forTitle = false) => {
  const result = [];

  switch (frequency) {
    case 'quarters':
      for (let index = 0; index <= 3; index += 1) {
        const label = `Q${moment().subtract(index, 'quarter').startOf('quarter').format('Q YYYY')} ${forTitle ? 'KPIs' : ''}`;
        result.push({ value: index, label });
      }
      result.push({ value: -1, label: 'Latest KPIs (max 4 quarters)' });
      break;
    case 'years':
      for (let index = 0; index <= 3; index += 1) {
        const label = `${moment().subtract(index, 'year').startOf('year').format('YYYY')} ${forTitle ? 'KPIs' : ''}`;
        result.push({ value: index, label });
      }
      result.push({ value: -1, label: 'Latest KPIs (max 3 years)' });
      break;
    default:
      for (let index = 0; index <= 3; index += 1) {
        const label = `${moment().subtract(index, 'month').startOf('month').format('MMMM')} ${forTitle ? 'KPIs' : ''}`;
        result.push({ value: index, label });
      }
      result.push({ value: -1, label: 'Latest KPIs (max 5 months)' });
      break;
  }
  return result;
};

export {
  kpiMetricForTotals,
  kpiTotalValues,
  kpisWidgetData,
  timeRangeOptions,
};
