import React, { Component } from 'react';


class ListMenu extends Component {
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
    document.removeEventListener('touchstart', this.handleClick, false);
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
    document.addEventListener('touchstart', this.handleClick, false);
  }

  handleClick = (e) => {
    if (ReactDOM.findDOMNode(this).contains(e.target)) return;

    this.props.onClickOutSide();
  }

  render() {
    return (
      <ul className="list-menu">
        { this.props.children }
      </ul>
    );
  }
}

export default ListMenu;
