import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withSorting from '../../../utils/withSorting';
import SorterHeader from '../../../modules/SorterHeader';
import LoadingSpinner from '../../../LoadingSpinner';
import { contactIcon, contactLink } from '../../../modules/investmentContacts';
import GenericPieChart from '../../modules/GenericPieChart';


class CompanyInfo extends Component {
  static propTypes = {
    show: PropTypes.bool,
    data: PropTypes.array.isRequired,
    sortedData: PropTypes.array.isRequired,
    chartData: PropTypes.object,
    getChartData: PropTypes.func,
    investorId: PropTypes.number.isRequired,
    showLinks: PropTypes.bool,
    loading: PropTypes.bool,
    tabScroll: PropTypes.bool,
    showChart: PropTypes.bool,
    children: PropTypes.node,
    colorTheme: PropTypes.string.isRequired,
    exportDataURL: PropTypes.string,
    canExport: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    show: true,
    chartData: {},
    getChartData: null,
    showLinks: false,
    loading: false,
    tabScroll: false,
    showChart: true,
    children: null,
    exportDataURL: null,
  }

  state = {
    loading: false,
  }

  renderHeaders = () => (
    <div className="col-12 flex py1 px1 text-light-gray h5 fw400 flex p1 center bg-wild-sand border-top border-bottom border-alto pr2">
      <div className="col-3 sm-col-6">
        <SorterHeader text="Name" field="name" />
      </div>
      <div className="col-1 sm-hide">
        <SorterHeader text="Stage" field="stage" hideIcon />
      </div>
      <div className="col-2 sm-hide">
        <SorterHeader text="Type" field="business_type" hideIcon />
      </div>
      <div className="col-1 sm-hide">
        <SorterHeader text="Industry" field="industry" hideIcon />
      </div>
      <div className="col-2 nowrap sm-hide">
        <SorterHeader text="Location" field="country" hideIcon />
      </div>
      <div className="col-1 sm-hide">
        <SorterHeader text="Founded" field="established_year" hideIcon />
      </div>
      <div className="col-2 sm-col-6">
        <SorterHeader text="Contacts" field="contacts" hideIcon />
      </div>
    </div>
  )

  render() {
    const {
      show,
      sortedData,
      chartData,
      investorId,
      showLinks,
      loading: loadingProps,
      tabScroll,
      getChartData,
      showChart,
      children,
      colorTheme,
      exportDataURL,
      canExport,
    } = this.props;

    const { loading } = this.state;

    const isLoading = loadingProps || loading;

    return (
      show ?
        <React.Fragment>
          {showChart &&
            <div className="col-12 flex flex-column mt1 bg-white bs-around mb2">
              <GenericPieChart
                missingDataMessage="The is no connected company"
                data={chartData.data}
                filterOptions={chartData.filter_options}
                onFilterChange={getChartData}
                chosenFilter={chartData.filter}
                loading={chartData.loading}
                withFilter
                showRightSection={false}
                legendValues="#"
                exportDataURL={exportDataURL}
                canExport={canExport}
              />
            </div>
          }

          <div className="col-12 flex flex-column mt1 bg-white bs-around">
            <div className={`col-12 ${children ? 'border-wild-sand border-top' : ''}`}>
              <div className="col-12">
                {/* Renders the search bar */}
                {children}

                {this.renderHeaders()}

                <div className={`col-12 ${tabScroll ? 'overflow-y-auto xl-max-height x-max-height lg-max-height' : 'overflow-hidden'}`}>
                  {
                    sortedData.length === 0 && !isLoading ?
                      <img className="col-12" alt="Nothing to display" src={window.images.investorEmpty} />
                      :
                      sortedData.map(item => (
                        <div
                          key={`investment_${item.id}`}
                          className="col-12 flex hover-bg-wild-sand py3 px1 text-gray flex flex-center relative"
                        >
                          <div className="col-3 sm-col-6">
                            <a
                              className="ml1 text-gray fw400 flex flex-center"
                              href={`/investor/${investorId}/investments/${item.id}`}
                            >
                              <span
                                className="block col-3 bg-center bg-cover bg-no-repeat circle header__avatar--user mr1"
                                style={{ backgroundImage: `url( ${item.avatar} )` }}
                              />
                              {item.real_company &&
                                <div className="relative">
                                  <span className="real_company__notification" data-behavior="hide-bubble">
                                    <i className="fa fa-check-circle text-green" />
                                  </span>
                                </div>
                              }
                              <div className="col-9 flex flex-center">
                                <div className="truncate text-gray h5">
                                  {item.name}
                                </div>
                                <i className={`fa ${item.followed ? 'fa-star' : ''} text-green ml1`} />
                                {/* showLinks &&
                                  <div className="flex lh-15">
                                    <a
                                      className="flex border rounded border-alto hover hover-border-gray text-alto hover-text-gray px1 sm-hide"
                                      href={`/investor/${investorId}/investments/${item.id}`}
                                    >
                                      <span className="h6">Profile</span>
                                    </a>
                                    {/*! item.custom_investment &&
                                      <a
                                        className="flex border rounded border-alto hover hover-border-gray text-alto hover-text-gray px1 ml1 sm-hide"
                                        href={`/c/${item.company_id}`}
                                      >
                                        <span className="h6">Dashboard</span>
                                      </a>
                                   }
                                  </div>
                                 */}
                              </div>
                            </a>
                          </div>
                          <div className="col-1 sm-hide truncate center fw400 text-light-gray h5 capitalize truncate-wrap" style={{ color: colorTheme }}>
                            {item.stage && item.stage.replace('_', ' ')}
                          </div>
                          <div className="col-2 sm-hide truncate center fw400 text-light-gray h5 capitalize truncate-wrap" style={{ color: colorTheme }}>
                            {item.business_model && item.business_model.replace('_', ' ')}{item.business_model && item.business_type && ','} <span className="text-upper">{item.business_type}</span>
                          </div>
                          <div className="col-1 sm-hide center fw400 text-light-gray h5 capitalize truncate-wrap" style={{ color: colorTheme }}>
                            {item.industry && item.industry.replace('_', ' ')}
                          </div>
                          <div className="col-2 sm-hide center fw400 text-light-gray h5 truncate-wrap" style={{ color: colorTheme }}>
                            {item.country}{item.country && item.city && ','} {item.city}
                          </div>
                          <div className="col-1 sm-hide truncate center fw400 text-light-gray h5 truncate-wrap" style={{ color: colorTheme }}>
                            {item.established_month}{item.established_month && item.established_year && ','} {item.established_year}
                          </div>
                          <div className="col-2 sm-col-6 flex flex-justify-center">
                            <div className="flex flex-center">
                              {
                                item.contacts && item.contacts.map(contact => {
                                  if (!contact.text) return null;
                                  return (
                                    <a key={`${contact.text}`} href={contactLink(contact)} target={`${contact.name === 'Email' ? '' : '_blank'}`} rel="noopener noreferrer">
                                      {contactIcon(contact.name, { marginRight: '5px' })}
                                    </a>
                                  );
                                })
                              }
                            </div>
                          </div>
                        </div>
                      ))
                  }
                  <LoadingSpinner
                    show={isLoading}
                    type="fit"
                    height="300px"
                    background="white"
                  />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
        :
        null
    );
  }
}

export default withSorting(CompanyInfo, (props) => props.data);
