import PropTypes from 'prop-types';
import React, { Component } from 'react';
import axios from 'axios';

import Modal from '../Modal';
import SheetsSource from './SheetsSource';
import ConfirmationModal from '../modals/ConfirmationModal';


class GoogleSheetBulkImport extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    initialStep: PropTypes.func,
    updateData: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    initialStep: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      kpis: [],
      errors: [],
      loading: false,
      showConfirmationModal: false,
    };

    this.initialState = this.state;
  }

  blockSubmit = () => {
    const { data } = this.props;

    return (data.sheet_id === 'N/A' || data.worksheet === 'N/A');
  }

  handleCloseModal = () => {
    const { closeModal } = this.props;

    this.setState(this.initialState);
    closeModal();
  }

  handleGoBack = () => {
    const { initialStep } = this.props;

    this.setState(this.initialState);
    initialStep();
  }

  requestInfo = () => {
    const { data } = this.props;
    const { loading } = this.state;

    if (loading) return;

    this.setState({ loading: true }, async () => {
      try {
        const res = await axios.get(`/company/${data.company_id}/kpis/gsheets_import`);
        this.setState({ kpis: res.data.kpis, errors: res.data.errors, showConfirmationModal: true });
      } catch (err) {
        this.setState({ loading: false });
        App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an error prevented the KPIs from being imported.' });
      }
    });
  }

  submit = () => {
    const { data } = this.props;

    this.setState({ showConfirmationModal: false }, async () => {
      try {
        await axios.post(`/company/${data.company_id}/kpis/gsheets_import`);
        window.location.href = data.kpi_url;
      } catch (err) {
        App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an error prevented the KPIs from being imported.' });
      }
    });
  }

  messageForConfirmation = () => {
    const { kpis, errors } = this.state;

    if (errors.length > 0) {
      return (
        <>
          <div className="border border-red rounded" style={{ borderStyle: 'dashed' }}>
            <div className="flex items-center m1">
              <i className="fa fa-close fa-lg mx1 text-red" />
              <div className="ml1 flex flex-wrap truncate">
                Data is not ok, please fix the following issues
              </div>
            </div>
          </div>
          <div className="flex flex-column flex-start my2 left-align lh-15">
            <span>The import failed due to following reason(s):</span>
            {
              errors.map(errorMessage => (
                <span key={Date.now} className="fw400">- {errorMessage}</span>
              ))
            }
          </div>
        </>
      );
    }
    return (
      <>
        <div className="border border-green rounded" style={{ borderStyle: 'dashed' }}>
          <div className="flex items-center m1">
            <i className="fa fa-check fa-lg mx1 text-green" />
            <div className="ml1 flex flex-wrap truncate">
              Data is ready for import!
            </div>
          </div>
        </div>
        <div className="flex flex-column flex-start my2 left-align lh-15 overflow-y-auto" style={{ maxHeight: '120px' }}>
          <span>{`Following ${Object.keys(kpis).length} KPIs were detected and will be imported:`}</span>
          {
            kpis.map(kpi => (
              <div key={kpi.metric}>
                - <span className="fw400">{kpi.metric}</span> ({kpi.actual_values_present_count} actual &amp; {kpi.plan_values_present_count} plan values) {kpi.warning && <span className="text-red">{`- ${kpi.warning}`}</span>}
              </div>
            ))
          }
        </div>
        {kpis.find(kpi => kpi.warning) &&
          <div className="flex flex-column mt3">
            {kpis.find(kpi => kpi.warning && kpi.warning.includes('already linked to a single sheet')) &&
              <span className="h5 text-light-gray">*KPIs single linked to their own sheet will be linked to this new bulk import.</span>
            }
            {kpis.find(kpi => kpi.warning && kpi.warning.includes('different frequency')) &&
              <span className="h5 text-red">*KPIs with different frequencies will have them changed to the new ones specified in the template.</span>
            }
          </div>
        }
      </>
    );
  }

  render() {
    const { initialStep, data, updateData } = this.props;
    const { loading, showConfirmationModal, errors } = this.state;

    return (
      <>
        <Modal
          show
          loading={loading}
          widthClass="react_modal--wide"
        >
          <div className="flex flex-justify-between items-center bold p2 border-bottom border-lighter-gray">
            <div className="h3 text-gray center">Import KPIs from Google Sheet</div>
            <i className="fa fa-close h5 cursor-pointer" onClick={this.handleCloseModal} />
          </div>

          <div className="flex flex-column items-center center mx2 mb1">
            <div className="col-11">
              <div>
                <div className="my2">
                  <p className="text-light-gray mb1">
                  Please, you may copy our template to your Google Drive to be able to use it here.
                  </p>
                  <a className="" href="https://docs.google.com/spreadsheets/d/12M705gbMADjAJHUoehS29b_ImgRA8eq_SJ2d26FEAgE/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Gsheet template</a>
                </div>
              </div>

              <div className="my2">
                <SheetsSource data={data} updateData={updateData} bulkImport />
              </div>
            </div>
          </div>

          <div className={`flex ${initialStep ? 'flex-justify-between' : 'flex-justify-end'} items-center p2 border-top border-lighter-gray`}>
            {
              initialStep &&
              <div
                className="h5 p1 px2 cursor-pointer bg-white text-blue border border-blue rounded"
                onClick={this.handleGoBack}
              >
                &#60; Back
              </div>
            }

            <div className="flex items-center">
              <div
                className="h5 text-blue mr2 cursor-pointer"
                onClick={this.handleCloseModal}
              >
                Cancel
              </div>
              <div
                className={`h5 p1 px2 text-white rounded ${this.blockSubmit() ? 'bg-lighter-gray cursor-disabled' : 'bg-green cursor-pointer'}`}
                onClick={this.blockSubmit() ? null : this.requestInfo}
              >
                {loading ? 'Importing...' : 'Import KPIs'}
              </div>
            </div>
          </div>
        </Modal>

        <ConfirmationModal
          show={showConfirmationModal}
          title="Confirm Import"
          text={this.messageForConfirmation}
          onSubmit={this.submit}
          cancelText="Cancel"
          closeModal={(type) => this.setState(type === 'cancel' ? { showConfirmationModal: false, loading: false } : { showConfirmationModal: false })}
          disabledConfirm={errors.length > 0}
        />
      </>
    );
  }
}

export default GoogleSheetBulkImport;
