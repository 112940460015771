import axios from 'axios';

import {
  updateCustomer,
  removeCustomer,
  subscribeCompany,
  unsubscribeCompany,
  resubscribeCompany,
} from '../../actions/company/paymentActions';


const updateCard = params =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const res = await axios.patch(`/company/${companyId}/stripe_customer`, params);
    dispatch(updateCustomer(res.data));
  };

const deleteCustomer = () =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const res = await axios.delete(`/company/${companyId}/stripe_customer`);
    dispatch(removeCustomer(res.data));
  };

const subscribe = params =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const res = await axios.post(`/company/${companyId}/subscription`, params);
    dispatch(subscribeCompany(res.data));
  };

const updateSubscription = params =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const res = await axios.patch(`/company/${companyId}/subscription`, params);
    dispatch(subscribeCompany(res.data));
  };

const unsubscribe = () =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const res = await axios.delete(`/company/${companyId}/subscription`);
    dispatch(unsubscribeCompany(res.data));
  };

const resubscribe = params =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const res = await axios.patch(`/company/${companyId}/subscription/reactivate`, params);
    dispatch(resubscribeCompany(res.data));
  };

export {
  updateCard,
  deleteCustomer,
  subscribe,
  updateSubscription,
  unsubscribe,
  resubscribe,
};
