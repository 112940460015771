import objectAssign from 'object-assign';

export default (state = { widgets: [] }, action) => {
  switch (action.type) {
    case 'SET_WIDGET':
      const targetWidget = state.widgets.filter(widget => widget.position === action.widget.position);
      const otherWidgets = state.widgets.filter(widget => widget.position !== action.widget.position);
      const updatedWidget = objectAssign({}, targetWidget, action.widget);
      return objectAssign({}, state, { widgets: [updatedWidget, ...otherWidgets] });
    default: return state;
  }
};
