import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SorterHeader from '../SorterHeader';


class SigningRoomRequestHeader extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  getColClass = () => {
    const { data } = this.props;
    let signed_by,
      options = null;
    const name = 'col-3';
    const date = 'col-4';
    signed_by = 'col-2';
    const status = 'col-2';
    options = 'col-1';
    if (data.investor) {
      signed_by = 'col-3';
    }
    return { name, date, signed_by, status, options };
  }

  render() {
    const { data } = this.props;
    return (
      <div className="col-12 text-gray h5 flex px2 bg-extra-light-gray">
        <div className={`${this.getColClass().name} flex py1 bold pl2 sm-col-6 sm-pl0`}>
          <SorterHeader text="Name" field="name" />
        </div>
        <div className={`${this.getColClass().date} flex py1 bold pl2 sm-hide sm-pl0 lg-hide`}>
          <SorterHeader text="Date" field="raw_date" />
        </div>
        <div className={`${this.getColClass().signed_by} flex py1 bold pl2 sm-hide sm-pl0 lg-hide`}>
          <SorterHeader text="Signed by" field="signed_by" />
        </div>
        <div className={`${this.getColClass().status} flex py1 bold pl2 sm-col-6`}>
          <SorterHeader text="Status" field="state" />
        </div>
        {
          !data.investor &&
          <div className={`${this.getColClass().options} border-alto py1 pl2 flex items-center sm-hide`} />
        }
      </div>
    );
  }
}

export default SigningRoomRequestHeader;
