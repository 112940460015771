import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Modal from '../Modal';
import LoadingSpinner from '../LoadingSpinner';


class FundingRoundDetailsModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    elementsId: PropTypes.array.isRequired,
    validationUrl: PropTypes.string.isRequired,
  };

  state = {
    loading: false,
    validations: [],
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show) {
      this.validate();
    }
  }

  validate = async () => {
    const { validationUrl, elementsId } = this.props;
    const { loading } = this.state;
    let response;

    if (loading) return;

    this.setState({ loading: true });

    try {
      if (elementsId.length > 0) {
        response = await axios.get(validationUrl, { params: { elements_id: elementsId } });
      } else {
        response = await axios.get(validationUrl);
      }
      this.setState({ loading: false, validations: response.data });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      show,
      closeModal,
    } = this.props;

    const { loading, validations } = this.state;

    return (
      <Modal
        show={show}
        onClickOutside={closeModal}
        widthClass="react_modal--wide"
      >
        <div className="flex flex-justify-between items-center fw400 p2 border-bottom border-lighter-gray">
          <div className="h3"><b>Warnings:</b> These are things that look odd. They may still be right in specific cases.  </div>
          <i className="fa fa-close h5 cursor-pointer" onClick={closeModal} />
        </div>

        {loading ?
          <LoadingSpinner
            show={loading}
            type="fit"
            height="300px"
            background="white"
          />
          :
          <div className="fw400 px2 py2 overflow-y-auto" style={{ maxHeight: '65vh' }}>
            {Object.keys(validations).map(section => (
              <div className="flex flex-column mb2" key={section}>
                <span className="h3 capitalize mb1">{section.replace(/_/g, ' ')}</span>

                {validations[section].length > 0 ?
                  validations[section].flatMap(validation => validation.messages.map(message => {
                    const completeMessage = `${message.wrong_value_message} ${message.correct_value_message}`;
                    return (
                      <div className="flex flex-wrap" key={`${message.row_info}_${message.field}`}>
                        <span className="text-gray">{message.row_info ? `${message.row_info}: ${completeMessage}` : `${completeMessage}`}</span>
                      </div>
                    );
                  }))
                  :
                  <span className="text-green"> No warnings</span>
                }
              </div>
            ))}
          </div>
        }

        <div className="flex flex-justify-end items-center p2 border-top border-lighter-gray">
          <div
            className="h5 text-blue mr2 cursor-pointer"
            onClick={closeModal}
          >
            Close
          </div>
        </div>
      </Modal>
    );
  }
}

export default FundingRoundDetailsModal;
