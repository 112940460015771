import Color from 'color';
import Highcharts from 'highcharts';
import HighchartsTheme from './highcharts_theme';
import applyDateFormat from './highcharts_date_format';


const blueColorPalette = ['#2974b7', '#77AAD8', '#4D8CC5', '#2E75B5', '#0C60AB', '#08477F'];
const orangeColorPalette = ['#ff9d05', '#FFC366', '#FFB23C', '#FF9C05', '#C87900', '#9D5F00'];

const getColor = (chosenColor, index, type, brandingColors = null) => {
  if (chosenColor) return chosenColor;

  if (brandingColors && brandingColors.length > 0) {
    const color = Color(brandingColors[type === 'actual' ? 1 : 2]);
    return (color.isDark() ? color.lighten(index * 0.3).string() : color.darken(index * 0.3).string());
  }

  return type === 'actual' ? blueColorPalette[index] : orangeColorPalette[index];
};

const dateParser = (date, periodStep) => {
  const parts = date.split('-');
  if (periodStep && ['months', 'quarters', 'years'].includes(periodStep)) {
    return Date.UTC(parts[0], parts[1] - 1, 1);
  }
  return Date.UTC(parts[0], parts[1] - 1, parts[2]);
};

const getTickPositions = (options) => {
  const dates = [];
  options.individualOptions.forEach(option => dates.push(...option.dates));
  const parsedDates = dates.map(date => dateParser(date, options.period_step));

  return parsedDates.filter((v, i, a) => a.indexOf(v) === i);
};

const intervalformatForPeriodStep = (periodStep) => {
  if (periodStep) {
    if (periodStep === 'quarters') return 3 * 24 * 3600 * 1000 * 30;
    if (periodStep === 'years') return 12 * 24 * 3600 * 1000 * 30;
  }
  return null;
};

const generateSeries = (options) => {
  const group = [];

  options.individualOptions.forEach((option, index) => {
    const actualText = option.unit ? `Actual in ${option.unit}` : 'Actual';
    const planText = option.unit ? `Plan in ${option.unit}` : 'Plan';
    const actual = {
      name: options.individualOptions.length === 1 ? actualText : `${option.name} (${actualText})`,
      data: option.actuals.map((value, innerIndex) => (
        {
          y: parseFloat(value),
          x: dateParser(option.dates[innerIndex], options.period_step),
          dataLabels: { format: option.actual_labels ? option.actual_labels[innerIndex] : '{y}' },
        }
      )),
      type: option.type,
      color: getColor(option.actual_color, index, 'actual', options.branding_colors),
      zIndex: 2,
    };
    const plan = {
      name: options.individualOptions.length === 1 ? planText : `${option.name} (${planText})`,
      data: option.forecasts.map((value, innerIndex) => (
        {
          y: parseFloat(value),
          x: dateParser(option.dates[innerIndex], options.period_step),
          dataLabels: { format: option.forecast_labels ? option.forecast_labels[innerIndex] : '{y}' },
        }
      )),
      type: option.type,
      color: getColor(option.plan_color, index, 'forecast', options.branding_colors),
      zIndex: 1,
    };
    option.show_actual_data ? group.push(actual) : null;
    option.show_plan_data ? group.push(plan) : null;
  });
  return group;
};

export default function createChart(options, height) {
  const chartHeight = height || '400';

  const series = generateSeries(options);

  HighchartsTheme();
  applyDateFormat();

  return Highcharts.chart({
    chart: {
      renderTo: options.selector,
      animation: false,
      height: chartHeight,
    },
    title: {
      text: options.chart_title ? options.chart_title : null,
    },
    tooltip: {
      xDateFormat: `${options.date_format}`,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        format: `{value:${options.date_format}}`,
      },
      tickInterval: intervalformatForPeriodStep(options.period_step),
      tickPositions: options.period_step === 'weeks' ? getTickPositions(options) : undefined,
    },
    yAxis: {
      min: options.tick_start,
      softMin: options.tick_start || 0,
      max: options.tick_end,
      title: {
        text: null,
      },
    },
    plotOptions: {
      series: {
        valueDecimals: 2,
        minPointLength: 2,
        dataLabels: {
          enabled: options.show_data_labels,
        },
        marker: {
          enabled: options.show_markers,
        },
      },
    },
    series,
  });
}
