import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select from '../../../Select';
import Textarea from '../../../Textarea';
import NumberInput from '../../../NumberInput';
import CleaveNumberFormatting from '../../../CleaveNumberFormatting';


class SelectableField extends Component {
  static propTypes = {
    component: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    original: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    customValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isCustom: PropTypes.bool,
    hideCompanyTab: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    defaultValue: '',
    original: '',
    customValue: '',
    isCustom: false,
    hideCompanyTab: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      custom: props.isCustom,
      value: props.defaultValue,
    };

    this.components = {
      Select,
      Textarea,
      NumberInput,
      CleaveNumberFormatting,
    };
  }


  onChange = e => {
    const { name, original, onChange } = this.props;
    const target = {
      name,
      value: e.target.rawValue || e.target.value,
      type: 'custom',
    };


    this.setState({
      custom: (target.type === 'custom'),
      value: target.value,
    });

    onChange({ target });
  }

  onSelect = custom => {
    const { name, defaultValue, original, customValue, onChange } = this.props;
    const target = {
      name,
      // value: custom ? (customValue !== null && customValue !== undefined ? customValue : defaultValue) : original,
      value: custom ? (customValue === '' ? defaultValue : customValue) : original,
      type: custom ? 'custom' : 'company',
    };
    this.setState({
      custom,
      value: target.value,
    });

    onChange({ target });
  }

  render() {
    const {
      component,
      label,
      name,
      original,
      defaultValue,
      isCustom,
      hideCompanyTab,
      onChange,
      ...other
    } = this.props;
    const {
      custom,
      value,
    } = this.state;
    const InputComponent = this.components[component];

    return (
      <div className="flex flex-column lh-15 pt2">
        <div className="flex">
          <div className="flex-auto">{label}</div>
          <div
            className={`h6 px1 py05rem cursor-pointer ${custom ? 'text-white bg-blue' : 'text-light-gray'}`}
            onClick={() => this.onSelect(true)}
          >
            Custom
          </div>
          {
            !hideCompanyTab &&
            <div
              className={`h6 px1 py05rem cursor-pointer ${custom ? 'text-light-gray' : 'text-white bg-green'}`}
              onClick={() => this.onSelect(false)}
            >
              Company
            </div>
          }
        </div>
        <InputComponent
          className={`col-12 border ${custom ? 'border-blue' : 'border-green'}`}
          name={name}
          value={value}
          onChange={this.onChange}
          {...other}
        />
      </div>
    );
  }
}

export default SelectableField;
