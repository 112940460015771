import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import Immutable from 'immutable';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import TabViewContainer from './TabViewContainer';
import VerticalTabViewContainer from './VerticalTabViewContainer';
import tabViewReducer from '../../reducers/tabView.js.es6';

/*
  New prop 'tabCustomElement' can be used like the 'tabs' props, to display another
  maybe together within the search bar for example. Working for 'TabViewContainer'
  not the vertical one.
*/
class TabView extends Component {
  static propTypes = {
    items: PropTypes.object,
    placeholders: PropTypes.object,
    bgColors: PropTypes.object,
    tabs: PropTypes.array,
    activeTab: PropTypes.string,
    searchEnabled: PropTypes.bool,
    searchText: PropTypes.string,
    vertical: PropTypes.bool,
    withCheckBox: PropTypes.object,
    tabCustomElement: PropTypes.object,
    sorting: PropTypes.object,
    topHeader: PropTypes.object,
    searchTextPlaceholder: PropTypes.string,
  }

  static defaultProps = {
    items: [],
    placeholders: null,
    tabs: ['main'],
    sorting: {},
    activeTab: 'main',
    searchEnabled: false,
    searchText: null,
    vertical: false,
    withCheckBox: {},
    bgColors: {
      tabs: 'bg-white',
      view: 'bg-white',
    },
    topHeader: null,
    searchTextPlaceholder: '',
  }

  componentWillMount() {
    this.store = this.initStore(this.props);
  }

  initStore = (initalState) => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const immutableInitalState = Immutable.fromJS(initalState);
    return createStore(tabViewReducer, immutableInitalState, composeEnhancers(applyMiddleware(thunk, logger)));
  }

  render() {
    const { vertical, searchEnabled, searchText, placeholders, searchTextPlaceholder, bgColors, tabCustomElement, topHeader } = this.props;

    window.store = this.store;

    const TabViewComponent = vertical ? VerticalTabViewContainer : TabViewContainer;

    return (
      <Provider store={this.store}>
        <TabViewComponent
          topHeader={topHeader}
          searchEnabled={searchEnabled}
          searchText={searchText}
          placeholders={placeholders}
          searchTextPlaceholder={searchTextPlaceholder}
          bgColors={bgColors}
          tabCustomElement={tabCustomElement}
        />
      </Provider>
    );
  }
}

export default TabView;
