import React, { Component } from 'react';
import PropTypes from 'prop-types';


class PreviewTable extends Component {
  static propTypes = {
    values: PropTypes.array.isRequired,
    actual: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    forecast: PropTypes.string.isRequired,
    active: PropTypes.any.isRequired,
    bulkImport: PropTypes.bool.isRequired,
    magicSelect: PropTypes.func.isRequired,
  }

  colorHelper = (id, type) => {
    const { active, date, actual, forecast, bulkImport } = this.props;

    const defaultColor = 'bg-alto';

    if (bulkImport && type === 'header') return defaultColor;

    if (active) {
      return 'bg-white';
    }
    if (id === date) {
      return 'bg-green';
    }
    if (id === actual) {
      return 'bg-blue';
    }
    if (id === forecast) {
      return 'bg-orange';
    }
    return defaultColor;
  }

  displayCells = (row, r) => {
    const cells = row.map((value, i) => (
      <td key={`${r}${i}`} className="border border-alto">{value}</td>
    ));
    return cells;
  }

  handleHeaderSelect = (selection) => {
    const { bulkImport, magicSelect } = this.props;

    if (bulkImport) return;

    magicSelect(selection);
  }

  displayHeader = () => {
    const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    const { values, bulkImport } = this.props;
    const header = values[0].map((_, i) => (
      <th
        key={alphabet[i]}
        className={`${this.colorHelper(alphabet[i].toUpperCase(), 'header')} border border-alto center regular ${bulkImport ? 'cursor-default' : 'cursor-pointer'}`}
        onClick={() => this.handleHeaderSelect(alphabet[i].toUpperCase())}
      >
        {alphabet[i].toUpperCase()}
      </th>
    ));
    return header;
  }

  displayRows = () => {
    const { values } = this.props;
    const rows = values.map((row, i) => (
      <tr key={Math.random()}>
        <td
          key={`n${i}`}
          className={`${this.colorHelper(String(i + 1), 'row')} border border-alto cursor-pointer`}
          width="20"
          onClick={() => this.props.magicSelect(String(i + 1))}
        >
          {i + 1}
        </td>
        {this.displayCells(row, i)}
      </tr>
    ));
    return rows;
  }

  render() {
    const { values } = this.props;
    if (values.length === 0) {
      return <div />;
    }

    return (
      <div className="mb2 overflow-auto" style={{ maxHeight: '350px', maxWidth: '920px' }}>
        <table>
          <tbody>
            <tr key="0">
              <th key="empty" className="bg-alto border border-alto" width="20" />
              {this.displayHeader()}
            </tr>
            {this.displayRows()}
          </tbody>
        </table>
      </div>
    );
  }
}

export default PreviewTable;
