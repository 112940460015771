const addSection = section => ({
  type: 'ADD_SECTION',
  section,
});

const updateSection = section => ({
  type: 'UPDATE_SECTION',
  section,
});

const updateSections = sections => ({
  type: 'UPDATE_SECTIONS',
  sections,
});

const removeSection = section => ({
  type: 'REMOVE_SECTION',
  section,
});

const addFile = file => ({
  type: 'ADD_FILE',
  file,
});

const setFile = file => ({
  type: 'SET_FILE',
  file,
});

const setFiles = files => ({
  type: 'SET_FILES',
  files,
});

const removeFile = file => ({
  type: 'REMOVE_FILE',
  file,
});

export {
  addSection,
  updateSection,
  updateSections,
  removeSection,
  addFile,
  setFile,
  setFiles,
  removeFile,
};
