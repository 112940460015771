import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RowContainer from './RowContainer';
import CardContainer from './CardContainer';


class List extends Component {
  static defaultProps = {
    listSwitch: false,
  }

  static propTypes = {
    records: PropTypes.string.isRequired,
    component: PropTypes.string.isRequired,
    listSwitch: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.state = {
      records: JSON.parse(props.records),
      list: false,
    };
  }

  displaySwitch = () => {
    const { list } = this.state;

    if (list) {
      return (
        <div className="flex flex-auto flex-justify-end">
          <div
            className="flex flex-center cursor-pointer"
            href="#"
            onClick={(e) => { e.preventDefault(); this.setState({ list: false }); }}
          >
            <span className="mr1 regular text-medium-gray">List view</span>
            <span className="h3">
              <i className="fa fa-toggle-on" />
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className="flex flex-auto flex-justify-end">
        <div
          className="flex flex-center cursor-pointer"
          href="#"
          onClick={(e) => { e.preventDefault(); this.setState({ list: true }); }}
        >
          <span className="mr1 regular text-medium-gray">Card view</span>
          <i className="fa fa-toggle-off h2" />
        </div>
      </div>
    );
  }

  updateTopState = (data) => {
    this.setState({ records: data });
  }

  render() {
    const { component, listSwitch } = this.props;
    const { records, list } = this.state;

    return (
      <div className="flex flex-wrap">
        {listSwitch && this.displaySwitch()}
        {list ?
          <RowContainer
            records={records}
            component={component}
            updateTopState={this.updateTopState}
          />
          :
          <CardContainer
            records={records}
            component={component}
            updateTopState={this.updateTopState}
          />
        }
      </div>
    );
  }
}

export default List;
