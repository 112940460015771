import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../../store/configureStore';
import FundDashboardContainer from '../../containers/investor/funds/FundDashboardContainer';


const FundDashboard = props => (
  <Provider store={configureStore(props)}>
    <FundDashboardContainer />
  </Provider>
);

export default FundDashboard;
