import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../../../store/configureStore';
import DataRoomsContainer from '../../../containers/company/data_rooms/DataRoomsContainer';


const CompanyDataRooms = props => (
  <Provider store={configureStore(props)}>
    <DataRoomsContainer />
  </Provider>
);

export default CompanyDataRooms;
