import React from 'react';
import PropTypes from 'prop-types';

import Textarea from '../../../Textarea';
import FileField from '../../../FileField';
import ColorPicker from '../../../selects/ColorPicker';

class SettingsConfigForm extends React.Component {
  static propTypes = {
    mainColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
    onColorChange: PropTypes.func.isRequired,
    onLogoChange: PropTypes.func.isRequired,
    logo: PropTypes.string,
    file: PropTypes.object,
    contactEmail: PropTypes.string,
    onContactEmailChange: PropTypes.func.isRequired,
    contactUrl: PropTypes.string,
    onContactUrlChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    logo: '',
    file: null,
  };

  state = {
    image: '',
  };

  componentDidMount = () => {
    const { file } = this.props;
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.setState({
          image: reader.result,
        });
      };
    }
  }

  handleChangeLogo = img => {
    const { onLogoChange } = this.props;
    onLogoChange(img);
  };

  render() {
    const { image } = this.state;
    const {
      mainColor,
      secondaryColor,
      onColorChange,
      logo,
      onContactEmailChange,
      contactEmail,
      onContactUrlChange,
      contactUrl,
    } = this.props;
    const currentLogo = image || logo;

    return (
      <div className="settings-config-form-wrapper">
        <form className="settings-config-form">
          <section className="settings-config-palette-input-section">
            <div className="row-select col-6">
              <label htmlFor="slideshow-logo">
                <span>Main Logo</span>
              </label>
              <div
                id="slideshow-logo"
              >
                <div className="image-preview inline-block relative flex">
                  <div
                    className="image-preview--50px user-avatar bg-center bg-cover bg-no-repeat circle"
                    style={{ backgroundImage: `url(${currentLogo})` }}
                    data-behavior="investment-avatar-preview"
                  />
                  <FileField
                    name="image"
                    preview_target="[data-behavior='investment-avatar-preview']"
                    onChange={img => this.handleChangeLogo(img)}
                  />
                  <i className="fa fa-camera fa--funds absolute left-25 text-white vertical-center transform-center" />
                </div>
              </div>
            </div>
            <div className="row-select col-6">
              <label htmlFor="slideshow-colors">
                <span>Color Option(s)</span>
              </label>
              <div id="slideshow-colors flex-column">
                <div className="slideshow-color-container items-center sm-mt0 sm-h6 flex">
                  <ColorPicker
                    color={secondaryColor}
                    onChange={color => onColorChange(color, 'secondaryColor')}
                  />
                  <span className="ml2">Intro/Outro Background Color</span>
                </div>
                <div className="slideshow-color-container items-center sm-mt0 sm-h6 flex mt1">
                  <ColorPicker
                    color={mainColor}
                    onChange={color => onColorChange(color, 'mainColor')}
                  />
                  <span className="ml2">Company Accent Color</span>
                </div>
              </div>
            </div>
          </section>
          <section className="general-config-title-input-section mt1">
            <div className="row-input mt2">
              <label htmlFor="slideshow-email">
                <span className="text-medium-gray">Contact Email (Outro Slide)</span>
              </label>
              <Textarea
                className="border border-lighter-gray hover hover-border-blue"
                id="slideshow-contact-email"
                onChange={onContactEmailChange}
                value={contactEmail}
              />
            </div>
          </section>
          <section className="general-config-title-input-section mt1">
            <div className="row-input">
              <label htmlFor="slideshow-url">
                <span className="text-medium-gray">Contact Url (Outro Slide)</span>
              </label>
              <Textarea
                className="border border-lighter-gray hover hover-border-blue"
                id="slideshow-contact-email"
                onChange={onContactUrlChange}
                value={contactUrl}
              />
            </div>
          </section>
        </form>
      </div>
    );
  }
}

SettingsConfigForm.propTypes = {
  mainColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  onColorChange: PropTypes.func.isRequired,
  onLogoChange: PropTypes.func.isRequired,
};

SettingsConfigForm.defaultProps = {
  contactEmail: '',
  contactUrl: '',
};

export default SettingsConfigForm;
