import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import RenameModal from '../modals/RenameModal';
import ConfirmationModal from '../ConfirmationModal';
import DropdownMenu from '../DropdownMenu';

class DataRoomSectionRow extends Component {
  static propTypes = {
    section: PropTypes.object.isRequired,
    editor: PropTypes.bool,
    selected: PropTypes.number.isRequired,
    selectSection: PropTypes.func.isRequired,
    renameSection: PropTypes.func,
    deleteSection: PropTypes.func,
  }

  static defaultProps = {
    editor: false,
    renameSection: false,
    deleteSection: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      showRenameModal: null, // saves section id
    };
  }

  closeModal = () => {
    this.setState({
      showRenameModal: false,
    });
  }

  renderSectionDropdown = section => {
    const { renameSection, deleteSection } = this.props;
    const { showRenameModal } = this.state;

    return (
      <DropdownMenu faIcon="ellipsis-h opacity-0" margin="col-1 ml1" dropdownClass="right__close">
        <li>
          <div
            className="flex flex-center hover hover-bg-wild-sand hover-text-blue text-gray"
            onClick={() => this.setState({ showRenameModal: section.id })}
          >
            <i className="fa fa-edit fa-fw px2" />
            <span className="block bold h5 lh-36 px1">Rename Folder</span>
          </div>
        </li>
        <li>
          <div className="flex flex-center hover hover-bg-wild-sand">
            <ConfirmationModal
              width="180px"
              className="block bold h5 lh-36 px1 text-gray nowrap pl2"
              onClick={() => deleteSection(section.id)}
              html='<div class="flex flex-center hover hover-text-blue"><i class="fa fa-trash fa-fw"></i><span class="pl1">Delete Folder</span></div>'
              message='<div class="flex flex-column flex-center"><span><b>You are about to delete this folder and all the documents in it. This cannot be undone.</b></span> <br/><span>Are you sure you want to delete this folder and all the documents in it?</span></div>'
              confirmationText="Delete Folder"
              cancelText="Cancel"
            />
          </div>
        </li>
        <RenameModal
          show={section.id === showRenameModal}
          closeModal={this.closeModal}
          title="Rename Section"
          initialName={section.name}
          onSubmit={name => renameSection(section.id, { name })}
        />
      </DropdownMenu>
    );
  }

  render() {
    const { section, editor, selected, selectSection } = this.props;

    return (
      <Draggable draggableId={section.id} index={section.position - 1} isDragDisabled={!editor}>
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div
              className={`show-ellipsis-h-on-hover flex flex-center px1 my05 border-left-2 cursor-pointer flex-shrink-none hover--drag__handle ${section.id === selected ? 'border-gray fw400' : 'border-gallery'}`}
              onClick={() => selectSection(section.id)}
              style={{ minHeight: '30px' }}
            >
              {(editor) ? <span {...provided.dragHandleProps} className="drag__handle mr1 hover" /> : <span {...provided.dragHandleProps} className="mx1" />}
              <div className="text-black col-10 lg-col-9 sm-col-10 lh-16 flex">
                <div className="col-11 lg-col-10 truncate">
                  <span>{section.name}</span>
                </div>
                <div className={`${section.general ? 'col-2 lg-col-2' : 'col-1 lg-col-2'}`}>
                  <span className="right">{section.data_room_files.length !== 0 ? `(${section.data_room_files.length})` : ''}</span>
                </div>
              </div>
              {!section.general && editor && this.renderSectionDropdown(section)}
            </div>
          </div>)}
      </Draggable>
    );
  }
}

export default DataRoomSectionRow;
