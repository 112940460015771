import React from 'react';

import SorterHeader from '../SorterHeader';


const UpdateTemplateHeader = () => (
  <div className="col-12 text-gray h5 flex p2 bg-extra-light-gray border-top border-alto">
    <div className="col-4 py1 bold pl2 sm-pl0">
      <SorterHeader text="NAME" field="name" />
    </div>
    <div className="col-4 py1 bold pl2">
      <SorterHeader text="DESCRIPTION" field="description" />
    </div>
  </div>
);

export default UpdateTemplateHeader;
