import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ManageGenericKpis from './ManageGenericKpis';
import Modal from '../Modal';

class ManageGenericKpisWithModal extends Component {
  static propTypes = {
    kpisLibrary: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    singleKpiId: PropTypes.number,
    genericKpiId: PropTypes.number,
    manageKpisLibrarySelection: PropTypes.func.isRequired,
    linkKpiToLibrary: PropTypes.func.isRequired,
    updateSingleKpiLibraryId: PropTypes.func.isRequired,
    updateUsedGenericKpis: PropTypes.func.isRequired,
  }

  static defaultProps = {
    singleKpiId: null,
    genericKpiId: null,
  }

  handleClose = () => {
    const { manageKpisLibrarySelection, updateSingleKpiLibraryId } = this.props;

    updateSingleKpiLibraryId();
    manageKpisLibrarySelection(null);
  }

  render() {
    const {
      kpisLibrary,
      company,
      singleKpiId,
      genericKpiId,
      manageKpisLibrarySelection,
      linkKpiToLibrary,
      updateSingleKpiLibraryId,
      updateUsedGenericKpis,
    } = this.props;

    return (
      <Modal
        show
        widthClass="react_modal--wide-large"
        onClickOutside={() => this.handleClose()}
      >
        <>
          <div className="flex flex-justify-between items-center bold p2 border-bottom border-lighter-gray">
            <div className="h3">Upgrade KPI from Library</div>
            <i className="fa fa-close h5 cursor-pointer" onClick={() => this.handleClose()} />
          </div>
          <div className="p1">
            <ManageGenericKpis
              kpisLibrary={kpisLibrary}
              company={company}
              singleKpiId={singleKpiId}
              genericKpiId={genericKpiId}
              manageKpisLibrarySelection={manageKpisLibrarySelection}
              linkKpiToLibrary={linkKpiToLibrary}
              afterSubmit={updateSingleKpiLibraryId}
              updateUsedGenericKpis={updateUsedGenericKpis}
            />
          </div>
        </>
      </Modal>
    );
  }
}

export default ManageGenericKpisWithModal;
