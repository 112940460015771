import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import DropdownMenu from '../../DropdownMenu';
import ConfirmationModal from '../../ConfirmationModal';
import RenameModal from '../../modals/RenameModal';


class QuickAccessFiles extends Component {
  static propTypes = {
    defaultRoom: PropTypes.object.isRequired,
    files: PropTypes.array.isRequired,
    createFile: PropTypes.func.isRequired,
    updateFile: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
  }

  state = {
    processing: null,
    dropzoneLayerVisibleUID: null,
    renameModalID: null,
  }

  showDropzoneLayer = uid => this.setState({ dropzoneLayerVisibleUID: uid })

  hideDropzoneLayer = () => this.setState({ dropzoneLayerVisibleUID: null })

  blockUploadOrDeletion = () => {
    const { processing } = this.state;

    if (processing) {
      App.State.setFlash({
        name: 'alert',
        msg: 'A file is being processed. Please wait while the process is finished.',
      });
      return true;
    }
    return false;
  }

  toggleRenameModal = id => {
    if (this.blockUploadOrDeletion()) return;
    this.setState({ renameModalID: id });
  }

  onDrop = async (file, uid) => {
    if (this.blockUploadOrDeletion()) return;

    const { defaultRoom, createFile } = this.props;

    this.setState({ processing: uid });

    try {
      const formData = new FormData();
      formData.append('data_room_file[attachment]', file);
      formData.append('data_room_file[uid]', uid);
      await createFile(defaultRoom.id, formData);

      this.setState({ processing: null });
    } catch (err) {
      console.log(err.response.data.errors);
    }
  }

  renameFile = async name => {
    const { defaultRoom, updateFile } = this.props;
    const { renameModalID: id } = this.state;

    try {
      await updateFile(defaultRoom.id, id, { name });
    } catch (err) {
      console.log(err.response.data.errors);
    }
  }

  deleteFile = async file => {
    if (this.blockUploadOrDeletion()) return;

    const { defaultRoom, deleteFile } = this.props;

    this.setState({ processing: file.uid });

    try {
      await deleteFile(defaultRoom.id, file.id);

      this.setState({ processing: null });
    } catch (err) {
      console.log(err.response.data.errors);
    }
  }

  reuploadQuickAccessFile = async (oldFile, newFile) => {
    if (this.blockUploadOrDeletion()) return;

    try {
      await this.deleteFile(oldFile);
      await this.onDrop(newFile, oldFile.uid);
    } catch (err) {
      console.log(err.response.data.errors);
    }
  }

  renderDropdown = file => {
    const { renameModalID } = this.state;

    return (
      <DropdownMenu faIcon="ellipsis-h opacity-0" margin="">
        <li>
          <div
            onClick={() => this.toggleRenameModal(file.id)}
            className="flex flex-center hover hover-bg-wild-sand hover-text-blue text-gray cursor-pointer"
          >
            <i className="fa fa-edit fa-fw px2" />
            <span className="block bold h5 lh-36 px1">Rename</span>
          </div>
        </li>
        <li>
          <div className="flex flex-center hover hover-bg-wild-sand text-gray">
            <ConfirmationModal
              width="180px"
              className="block bold h5 lh-36 pl2 text-gray"
              onClick={() => this.deleteFile(file)}
              html='<div class="flex flex-center hover hover-text-blue"><i class="fa fa-trash fa-fw"></i><span class="pl1">Delete</span></div>'
              message='<div class="flex flex-column flex-center"><span><b>You are about to permanently delete this document. This cannot be undone.</b></span><br/><span>Are you sure you want to permanently delete this document?</span></div>'
              confirmationText="Delete"
              cancelText="Cancel"
            />
          </div>
        </li>
        <li>
          <div className="flex flex-center hover hover-bg-wild-sand text-gray">
            <ConfirmationModal
              confirmationWithDropzone
              width="180px"
              className="block bold h5 lh-36 text-gray pl2"
              message="<div class='flex flex-column flex-center'><b>You are about to permanently delete this document and upload a new one. This cannot be undone.</b><br/>Are you sure you want to do re-upload?</div>"
              html='<div class="flex flex-center hover hover-text-blue"><i class="fa fa-upload fa-fw"></i><span class="pl1">Re-Upload</span></div>'
              confirmationText="Delete &amp; Upload"
              cancelText="Cancel"
              onDrop={droppedFiles => this.reuploadQuickAccessFile(file, droppedFiles[0])}
            />
          </div>
        </li>
        <RenameModal
          show={file.id === renameModalID}
          closeModal={() => this.toggleRenameModal(null)}
          title="Rename Quick Access File"
          initialName={file.name}
          onSubmit={this.renameFile}
        />
      </DropdownMenu>
    );
  }

  renderDropzoneUpload = (uid, heading) => {
    const { dropzoneLayerVisibleUID } = this.state;

    return (
      <Dropzone
        multiple={false}
        className="col-12 relative"
        onDrop={files => {
          this.onDrop(files[0], uid);
          this.hideDropzoneLayer();
        }}
        onDragEnter={() => this.showDropzoneLayer(uid)}
        onDragLeave={() => this.hideDropzoneLayer()}
      >
        <div className="cursor-pointer text-light-gray bg-white bs-around col-12 p1 card--has-hover-light-gray__small flex flex-justify-center center relative" style={{ minHeight: '80px' }}>
          {dropzoneLayerVisibleUID === uid &&
            <div
              className="absolute bottom-0 flex flex-center flex-justify-center left-0 right-0 top-0 z1"
              style={{ background: 'rgba(0,0,0,0.4)', margin: '-2px' }}
            >
              <span className="bold text-white">Drop file to upload</span>
            </div>
          }

          <span className="flex flex-justify-center items-center col-12 center h6 p1 lh-14">Upload your {heading} <br />to share with your investors!</span>
          <i className="pl1 fa fa-upload" />
        </div>
      </Dropzone>
    );
  }

  renderQuickAccessBox = (uid, heading, icon) => {
    const { processing } = this.state;
    const { files } = this.props;
    const file = files.find(f => f.uid === uid);

    if (processing === uid) {
      return (
        <div key={`qaf_${uid}`} className="flex flex-column col-3 sm-col-12 lg-col-6 px1 lg-p2">
          <div className="col-12 flex items-center text-gray h5 fw400 mb1">
            <i className={`${icon} fa-fw mr1`} />
            {heading}
          </div>

          <div className="bg-white bs-around col-12 flex flex-center flex-justify-center relative" style={{ minHeight: '80px' }}>
            <img
              src={window.images.loading}
              alt="Processing..."
              style={{ opacity: 0.2 }}
              width="32"
            />
          </div>
        </div>
      );
    }

    return (
      <div key={`qaf_${uid}`} className="flex flex-column col-3 sm-col-12 lg-col-6 px1 lg-p2">
        <div className="col-12 flex items-center text-gray h5 fw400 mb1">
          <i className={`${icon} fa-fw mr1`} />
          {heading}
        </div>

        {file ?
          <div className="col-12 flex sm-px0 flex-shrink-none bg-white bs-around card--has-hover-light-gray__small show-ellipsis-h-on-hover cursor-pointer">
            <a
              className="col-11 p1 py2 flex relative text-black"
              data-method="get"
              href={file.preview_url}
            >
              <div className="flex flex-column truncate">
                <div className="flex flex-center relative nowrap">
                  <span className="fw400 h4 pr1 ml1">{file.name}</span>
                </div>
                <div className="flex items-center flex-justify-center">
                  <i className={`h6 ml1 mr1 ${file.icon_class_name}`} style={{ color: file.icon_color }} />
                  <p className="text-light-gray col-12 h6 m0 lh-16 nowrap">
                    {file.created_at} - {file.size}
                  </p>
                </div>
              </div>
            </a>
            <div className="col-1 text-light-gray">
              {this.renderDropdown(file, uid)}
            </div>
          </div>
          :
          this.renderDropzoneUpload(uid, heading, icon)
        }
      </div>
    );
  }

  render() {
    return (
      <div className="flex flex-wrap lg-flex-justify-around mxn1 sm-block">
        {this.renderQuickAccessBox('pitch_deck', 'Pitch Deck', 'fa fa-plane')}
        {this.renderQuickAccessBox('financial_plans', 'Business Plan', 'fa fa-tachometer')}
        {this.renderQuickAccessBox('annual_report', 'Annual Report', 'fa fa-signal')}
        {this.renderQuickAccessBox('financial_statements', 'Captable', 'fa fa-book')}
      </div>
    );
  }
}

export default QuickAccessFiles;
