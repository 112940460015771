import { connect } from 'react-redux';
import DataRoom from '../components/data_room';

import createComment from '../api/commentApi';

const mapStateToProps = state => ({
  editor: false,
  company: state.company,
  dataRoom: state.dataRoom,
  comments: state.comments,
  blockFileDownload: state.currentUser ? state.currentUser.blockFileDownload : false,
});

const mapDispatchToProps = {
  createComment,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataRoom);
