import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ConversationLink from './ConversationLink';


class ConversationLinkIndex extends Component {
  constructor(props) {
    super(props);

    const data = JSON.parse(props.data);

    this.state = {
      conversations: data.conversations,
    };
  }

  render() {
    const conversations = this.state.conversations.map((c) => (<ConversationLink conversation={c} />));

    return (
      <div>
        {conversations}
      </div>
    );
  }
}

export default ConversationLinkIndex;
