import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import ShareholderUpdatePost from './ShareholderUpdatePost';


class PostList extends Component {
  static propTypes = {
    posts: PropTypes.string.isRequired,
    postsUrl: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);

    const data = JSON.parse(props.posts);

    this.state = {
      posts: data.posts,
      nextPage: data.next_page,
      filter: 'all_discussions',
      filterWidth: 100,
      keyHelper: 'all_discussions',
    };
  }

  componentDidMount() {
    App.Timeline = this;
  }

  filter = (e) => {
    const { filters } = this.props;
    this.setState({
      filter: e.target.value,
      filterWidth: filters.find(filter => filter.type === e.target.value).width,
    });
    setTimeout(this.refresh, 50);
  }

  loadNextPage = () => {
    const { postsUrl } = this.props;
    const { nextPage, filter, posts } = this.state;

    $.ajax({
      data: {
        page: nextPage,
        filter,
      },
      url: postsUrl,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        this.setState({
          posts: posts.concat(data.posts),
          nextPage: data.next_page,
        });
      },
    });
  }

  refresh = () => {
    const { postsUrl } = this.props;
    const { filter } = this.state;

    $.ajax({
      url: postsUrl,
      data: {
        page: 1,
        filter,
      },
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        this.setState({
          posts: data.posts,
          nextPage: data.next_page,
          keyHelper: filter,
        });
      },
    });
  }

  render() {
    const { filters } = this.props;
    const { posts, filter, filterWidth, nextPage, keyHelper } = this.state;

    let loadMore;
    if (nextPage) {
      loadMore = (
        <div className="flex flex-justify-center">
          <div
            className="btn btn-outline btn-big border-alto bg-white sm-mb1 sm-col-12 mb3"
            onClick={this.loadNextPage}
          >
            <i className="fa fa-plus mr1" />
            Load More Discussions
          </div>
        </div>
      );
    }

    return (
      <div className="post-list">
        <div className="flex mxn2 pb1">
          <div className="col-2 lg-hide mt2 px2 relative" />
          <div className="col-8 lg-col-12 flex flex-center px2">
            Showing:
            <select
              value={filter}
              className="ml1 p0 text-blue"
              style={{ borderWidth: '0 0 0 0', color: '#2E75B5', fontStyle: 'normal', width: `${filterWidth}px`, backgroundColor: 'inherit' }}
              onBlur={e => this.filter(e)}
            >
              {filters.map(f => (
                <option key={f.type} value={f.type}>
                  {f.description}
                </option>
              ))}
            </select>
            <i className="fa fa-caret-down" />
          </div>
          <div className="col-2 lg-hide mt2 px2" />
        </div>
        <ReactCSSTransitionGroup transitionName="fade" transitionEnterTimeout={500} transitionLeaveTimeout={300}>
          {posts.map((post, index) => (
            <ShareholderUpdatePost
              post={post}
              key={keyHelper + post.id}
              index={index}
              showViewComments
            />
          ))}
        </ReactCSSTransitionGroup>
        {loadMore}
      </div>
    );
  }
}

export default PostList;
