import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SelectWithImage from '../../selects/SelectWithImage';

import Avatar from '../../tab_view/Avatar';
import FileField from '../../FileField';


class Investor extends Component {
  static propTypes = {
    col: PropTypes.object.isRequired,
    edit: PropTypes.bool.isRequired,
    investorId: PropTypes.number.isRequired,
    updateReportColumn: PropTypes.func.isRequired,
    valueColor: PropTypes.string.isRequired,
  }

  state = {
    custom: false,
    search: false,
    saving: false,
    customUser: {
      value: null,
    },
  }

  handleChange = (e) => {
    const { customUser } = this.state;

    const innerCustomUser = {
      ...customUser,
      [e.target.name]: e.target.value,
    };
    this.setState({ customUser: innerCustomUser });
  }

  handleSelectChange = (id) => {
    const { investorId, updateReportColumn, col } = this.props;

    this.setState({ custom: false, search: false, saving: true });
    const params = {
      investor_id: id,
      value: null,
      image: null,
    };
    updateReportColumn(investorId, col.id, params).then(
      () => this.setState({ saving: false, search: false }),
      err => console.log(err),
    );
  }

  submitCustomUser = (e) => {
    const { investorId, updateReportColumn, col } = this.props;
    const { customUser } = this.state;

    e.preventDefault();
    this.setState({ custom: false, search: false, saving: true });
    updateReportColumn(investorId, col.id, customUser).then(
      () => this.setState({ saving: false }),
      err => console.log(err),
    );
  }

  render() {
    const { col, edit, valueColor, investorId } = this.props;
    const { search, saving, custom } = this.state;
    const container = classNames(`py1 col-${!edit && !col.value ? 0 : col.width} sm-col-6`, {
      'flex flex-center': col.display === 'inline',
      'mr2 px2': col.border,
    });
    const memberClasses = classNames('flex flex-center py1', { 'cursor-pointer border border-transparent hover hover-border-blue p1 show-close-on-hover': edit });
    const valueClasses = classNames('m0 fw400 lh-15', {
      'border border-lighter-gray bg-none hover hover-border-blue': edit,
      'p0 border-transparent my1': !edit,
    });

    return (
      <div
        key={`col_${col.id}`}
        className={container}
        style={{ border: `1px solid ${col.border}` }}
      >
        { edit && !saving && custom &&
          <form
            className="flex items-center col-10 text-light-gray"
            onSubmit={e => this.submitCustomUser(e)}
          >
            <div className="col-4 mr1 flex flex-center double-image-upload double-image-upload--funds">
              <div className="avatar-has-icon inline-block relative">
                <div
                  className="double-image-upload__smaller user-avatar bg-center bg-cover bg-no-repeat circle mx-auto relative"
                  style={{ backgroundImage: `url(${col.image_url})` }}
                  data-behavior={`avatar-preview-file-${col.id}`}
                />
                <FileField
                  name="report_column[image]"
                  preview_target={`[data-behavior="avatar-preview-file-${col.id}"]`}
                  saveImageUrl={`/investor/${investorId}/reports/report_columns/${col.id}`}
                  dataMethod="patch"
                />
                <i className="fa fa-camera fa--funds absolute left-25 text-white vertical-center transform-center" />
              </div>
            </div>
            <input
              type="text"
              name="value"
              className={valueClasses}
              onChange={e => this.handleChange(e)}
            />
            <button
              type="submit"
              style={{ background: 'none', border: 'none' }}
              className="fa fa-check ml1 cursor-pointer text-green"
            />
            <i
              className="fa fa-close ml1 cursor-pointer text-red"
              onClick={() => this.handleSelectChange(null)}
            />
          </form>
        }
        { edit && !saving && search && !custom &&
          <div className="flex col-10 items-center show-check-on-hover">
            <SelectWithImage
              className="col-12"
              value={col.investor_options_for_select.filter(option => option.value === col.investor_id)}
              options={col.investor_options_for_select}
              placeholder="Select..."
              onChange={selectedOption => this.handleSelectChange(selectedOption.value)}
              isClearable={false}
            />
            <i
              className="fa fa-check opacity-0 ml1 cursor-pointer text-green"
              onClick={() => this.setState({ search: false })}
            />
          </div>
        }
        { !saving && !search && !custom &&
          <div onClick={() => { if (edit) this.setState({ search: true }); }}>
            {col.value ?
              <div className={memberClasses} style={{ color: valueColor }}>
                <Avatar avatarUrl={col.image_url} size="30px" />
                <p className="m0 pl1 fs-14 fw400">{col.value}</p>
                <i
                  className="fa fa-close opacity-0 ml1 cursor-pointer text-gray ml1"
                  onClick={() => this.handleSelectChange(null)}
                />
              </div>
              :
              edit &&
              <div className="flex-column">
                <div className={`${memberClasses} text-light-gray`}>
                  <i className="fa fa-user fs-30" />
                  <p className="m0 pl1 fs-14 fw400">Unassigned</p>
                </div>
                <div
                  className="flex flex-justify-center underline h6 text-light-gray cursor-pointer"
                  onClick={() => this.setState({ custom: true })}
                >
                  Assign custom user
                </div>
              </div>
            }
          </div>
        }
        { saving &&
          <div className="text-light-gray">
            <span>Saving data...</span>
          </div>
        }
      </div>
    );
  }
}

export default Investor;
