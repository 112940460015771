import React, { Component } from 'react';
import PropTypes from 'prop-types';

// const FundingSharesHeader = ({ edit, isCustom, fieldWidth, fieldWidthForSelect, fieldWidthForSecuritySelect }) => {
class FundingSharesHeader extends Component {
  static propTypes = {
    edit: PropTypes.bool.isRequired,
    isCustom: PropTypes.bool.isRequired,
    fieldWidth: PropTypes.string.isRequired,
    fieldWidthForSelect: PropTypes.string.isRequired,
    fieldWidthForAssignedTo: PropTypes.string.isRequired,
    fieldWidthForCurrentValue: PropTypes.string.isRequired,
    fieldWidthForSecuritySelect: PropTypes.string.isRequired,
    fieldWidthForCurrency: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    renderSecurityDropdown: PropTypes.func,
  };

  static defaultProps = {
    renderSecurityDropdown: null,
  }

  state = {
    showHintFor: null,
  }

  render() {
    const {
      edit,
      isCustom,
      fieldWidth,
      fieldWidthForSelect,
      fieldWidthForSecuritySelect,
      fieldWidthForAssignedTo,
      fieldWidthForCurrentValue,
      fieldWidthForCurrency,
      renderSecurityDropdown,
      currency,
    } = this.props;

    const { showHintFor } = this.state;

    const currentSharesValueMessage = 'Current value of the shares represented in this specific transaction. If # of shares is negative (e.g. in an exit), % ownership should also be negative. You should update all transactions according to the latest share price whenever the valuation changes. If the last transaction is a convertible, you may want to update all transactions according to the valuation implied by the convertible (e.g. valuation cap).';

    return (
      <>
        {isCustom &&
          <div className="flex mt3">
            <div style={{ width: edit ? '60%' : '58%' }} />
            <div className={`center border-left border-right border-top border-lighter-gray rounded text-light-gray ${edit ? 'mr3' : 'ml1 mr2'}`} style={{ width: edit ? '11%' : '12%' }}>
              In company currency ({currency})
            </div>
            <div className="center border-left border-right border-top border-lighter-gray rounded text-light-gray" style={{ width: edit ? '17%' : '18%' }}>
              In fund currency
            </div>
          </div>
        }
        <div className={`flex py1 text-light-gray border-top border-bottom border-lighter-gray ${isCustom ? '' : 'mt3'}`}>
          <div className="flex items-center cursor-default" style={{ width: fieldWidth }}>
            <span
              data-balloon="This is the date in which the securities were acquired."
              data-balloon-pos="right"
              data-balloon-length="large"
            >
                Date
            </span>
            {edit &&
              <div style={{ width: '6%' }} />
            }
          </div>
          <div className="cursor-default" style={{ width: fieldWidthForSelect }}>
            <span
              data-balloon="This is the Round in which the share holdings change and/or cashflow happened. You can add Rounds/Events and their details in the table further down. In the case of other transactions (e.g. equity sales), it can be left blank."
              data-balloon-pos="down"
              data-balloon-length="large"
            >
              Round/event
            </span>
          </div>
          {isCustom &&
            <div className="cursor-default" style={{ width: fieldWidthForSelect }}>
              <span
                data-balloon="Transaction type"
                data-balloon-pos="down"
                data-balloon-length="large"
              >
                Transaction
              </span>
            </div>
          }
          <div className={`cursor-default ${isCustom ? 'flex items-center' : ''}`} style={{ width: fieldWidthForSecuritySelect }}>
            <span
              // className="py1"
              data-balloon="Security type is the Share Class or Convertible invoived in this transaction. If it's a share class, # of shares refers to this share class. If it is a convertible, # of shares should be zero. You can either choose from the shares classes / convertibles defined by the company ('company') or name your own custom security type ('custom'), and enter the details as comments."
              data-balloon-pos="down"
              data-balloon-length="xlarge"
              data-balloon-break
              onMouseEnter={() => this.setState({ showHintFor: 'securityType' })}
              onMouseLeave={() => this.setState({ showHintFor: null })}
            >
              Security Type
            </span>
            {isCustom && edit &&
              <div className="pl3">
                {renderSecurityDropdown()}
              </div>
            }
          </div>
          {isCustom &&
            <div className="cursor-default" style={{ width: fieldWidthForAssignedTo }}>
              <span
                data-balloon="This field allows you to assign the shares to a fund or a partner. If you assign them to a fund, shares will appear only in the individual fund.
                If you assign them to a partner directly, shares will not appear in any fund, and will only appear in the all-investments page of the selected partner."
                data-balloon-pos="down"
                data-balloon-length="xlarge"
              >
                Assigned to
              </span>
            </div>
          }
          <div className="cursor-default" style={{ width: fieldWidth }}>
            <span
              data-balloon="# of shares represents the fully diluted (FD) shares (i.e. if options were informed, they are considered in the calculation). You can enter separately number of shares/options in the details box (i).
              # of shares should be postivie if you increase your position (e.g. equity issued to you by the company), and negative if you decrease your position (e.g. sell your equity)."
              data-balloon-pos="down"
              data-balloon-length="xlarge"
            >
              FD # of shares
            </span>
          </div>
          <div className="cursor-default" style={{ width: fieldWidth }}>
            <span
              data-balloon="% ownership that this specific transaction represents. If # of shares is negative (e.g. in an exit), % ownership should also be negative (the % you sold)
              You should update all transactions with the current % ownership whenever the total number of shares outstading change (e.g. dilution with a new round)"
              data-balloon-pos="down"
              data-balloon-length="xlarge"
            >
              FD % ownership
            </span>
          </div>
          <div className="cursor-default" style={{ width: fieldWidth }}>
            <span
              data-balloon="This is the cashflow you invested in this transaction. When you have a cash outflow, this field  should be a positive amount."
              data-balloon-pos="down"
              data-balloon-length="xlarge"
            >
              {isCustom ? 'Invested Cashflow' : 'Invested'}
            </span>
          </div>
          {isCustom &&
            <div className="cursor-default" style={{ width: fieldWidth }}>
              <span
                data-balloon="This is the cashflow you realized from this transaction. When you have a cash inflow, this field  should be a positive amount."
                data-balloon-pos="down"
                data-balloon-length="xlarge"
              >
                Realized Cashflow
              </span>
            </div>
          }
          {isCustom &&
            <div className="cursor-default" style={{ width: fieldWidthForCurrency }}>
              <span
                data-balloon="This is an optional field, in case the currency of your fund is different from the company currency. Here you should inform the currency for the invested/realized cashflows on the right."
                data-balloon-pos="down"
                data-balloon-length="xlarge"
              >
                Fund Currency
              </span>
            </div>
          }
          {isCustom &&
            <div className="cursor-default" style={{ width: fieldWidth }}>
              <span
                data-balloon="This is an optional field, in case the currency of your fund is different from the company currency. These amounts, if informed, are used when the calculation currency is different from the company currency (e.g. for IRR, multiples, etc.)"
                data-balloon-pos="down"
                data-balloon-length="xlarge"
              >
                Invested Cashflow
              </span>
            </div>
          }
          {isCustom &&
            <div className="cursor-default" style={{ width: fieldWidth }}>
              <span
                data-balloon="This is an optional field, in case the currency of your fund is different from the company currency. These amounts, if informed, are used when the calculation currency is different from the company currency (e.g. for IRR, multiples, etc.)"
                data-balloon-pos="down"
                data-balloon-length="xlarge"
              >
                Realized Cashflow
              </span>
            </div>
          }
          <div className="cursor-default" style={{ width: fieldWidthForCurrentValue }}>
            <span
              className="py1"
              data-balloon={showHintFor === 'currentSharesValue' ? currentSharesValueMessage : null}
              data-balloon-pos="left"
              data-balloon-length="xlarge"
              onMouseEnter={() => this.setState({ showHintFor: 'currentSharesValue' }, () => $('#transactionsOverflow').addClass('pt2'))}
              onMouseLeave={() => this.setState({ showHintFor: null }, () => $('#transactionsOverflow').removeClass('pt2'))}
            >
              Current Value of Investment {isCustom ? `(${currency})` : ''}
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default FundingSharesHeader;
