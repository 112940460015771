import React, { Component } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';


class UpdatesByCompany extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    updates: PropTypes.array.isRequired,
    canRequestUpdate: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      canRequestUpdate: props.canRequestUpdate,
    };
  }

  commentsCount = (update) => {
    if (update.comments > 0) {
      return (
        <div className="h6">
          <i className="fa fa-comment-o mr1" />
          {update.comments} Comments
        </div>
      );
    }
    return null;
  }

  displayAttachments = (update) => {
    const attachments = update.attachments.map(item =>
      <a key={item.key} className="cursor-pointer mr1 text-medium-gray" href={item.url} data-balloon={item.file_name}><i className="fa fa-file-text-o" /></a>);

    if (update.attachements_count > 0) {
      return (
        <div className="col-2 sm-hide">
          <div className="">{update.attachements_count} files attached</div>
          <div className="flex">{attachments}</div>
        </div>
      );
    }
    return (<div className="col-2 sm-hide" />);
  }

  likesCount = (update) => {
    if (update.likes > 0) {
      return (
        <div className="h6 mr1">
          <i className="fa fa-hand-paper-o mr1" />
          {update.likes} High Five
        </div>
      );
    }
    return null;
  }

  handleUpdateRequest = () => {
    const { company } = this.props;
    const { canRequestUpdate } = this.state;

    if (!canRequestUpdate) return;

    this.setState({ loading: true });

    axios.post(`/c/${company.id}/request_updates`).then(
      () => this.setState({ loading: false, canRequestUpdate: false }),
      (err) => {
        this.setState({ loading: false });
        App.State.setFlash({ name: 'alert', msg: err.response.data.error });
      },
    );
  }

  render() {
    const { company, updates } = this.props;
    const { loading, canRequestUpdate } = this.state;

    return (
      <div className="col-12 overflow-y-auto xl-max-height x-max-height lg-max-height border-top border-alto">
        {updates.length > 0 && updates.map((update, index) => (
          <div
            key={`update_${update.id}`}
            className={`col-12 flex hover-bg-wild-sand px2 text-gray flex flex-center relative ${index > 0 ? 'pb2' : 'py2'}`}
          >
            <div className="col-3 flex flex-center">
              {index === 0 &&
                <React.Fragment>
                  <span
                    className="block col-3 bg-center bg-cover bg-no-repeat circle header__avatar--dash mr1"
                    style={{ backgroundImage: `url( ${update.avatar} )` }}
                  />
                  <a
                    className="fw400 col-9 text-gray truncate mr1"
                    href={update.company_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {update.company_name} &nbsp; <i className="fa fa-external-link" />
                  </a>
                </React.Fragment>
              }
            </div>
            <a
              className={`flex col-5 sm-col-6 truncate text-light-gray ${index > 0 ? 'border-top border-alto pt1' : ''}`}
              href={` /c/${company.id}/published_updates/${update.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="col-1 flex flex-column flex-justify-center mr2">
                <div className="h2 center text-gray">{update.date_year}</div>
                <div className="text-upper center text-gray">{update.date_month}</div>
              </div>

              <div className="flex flex-column">
                <div className="flex flex-center">
                  <div className="fw400 text-gray">{update.name} &nbsp; <i className="fa fa-external-link" /></div>
                  <div className="col-8 sm-hide ml1 truncate">{update.preview}</div>
                </div>
                <div className="flex flex-center lh-12">
                  <div
                    className={`text-alto h6 fw400 border border-alto rounded inline-block px1 text-upper mr1 ${update.target_date ? 'border-blue text-blue' : ''}`}
                    style={update.target_date ? { opacity: 0.5 } : {}}
                  >
                    {update.target_date ? `${update.update_type} ` : 'custom '}
                    update
                  </div>
                  <div className="h6 sm-hide">
                    {update.info}
                  </div>
                </div>
                <div className="flex">{this.likesCount(update)}{this.commentsCount(update)}</div>
              </div>
            </a>
            {!update.disabled && this.displayAttachments(update)}
            <div className="flex flex-center flex-auto flex-justify-end">
              <a
                className="flex text-green items-center"
                href={` /c/${company.id}/published_updates/${update.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-eye mr1" />
                <div className="fw400">Read Update &nbsp; <i className="fa fa-external-link" /></div>
              </a>
            </div>
          </div>
        ))}

        {updates.length === 0 &&
          <React.Fragment>
            <div className="flex flex-justify-between flex hover-bg-wild-sand py2 px2 text-gray flex flex-center relative">
              <div className="col-3 flex flex-center">
                <span
                  className="block col-3 bg-center bg-cover bg-no-repeat circle header__avatar--dash mr1"
                  style={{ backgroundImage: `url( ${company.avatar} )` }}
                />
                <a
                  className="fw400 col-9 text-gray truncate mr1"
                  href={`/c/${company.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {company.name} &nbsp; <i className="fa fa-external-link" />
                </a>
              </div>
              <div
                className={`flex items-center ${canRequestUpdate ? 'cursor-pointer text-orange' : 'cursor-default text-green'}`}
                onClick={this.handleUpdateRequest}
              >
                <i className={`mr1 ${canRequestUpdate ? 'fa fa-retweet' : ''}`} />
                {loading ?
                  <div className="fw400">Requesting...</div>
                  :
                  <div className="fw400">{canRequestUpdate ? 'Request Update' : 'Update Requested'}</div>
                }
              </div>
            </div>
          </React.Fragment>
        }
      </div>
    );
  }
}

export default UpdatesByCompany;
