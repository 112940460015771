import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import UpgradeAccountModal from '../../modals/UpgradeAccountModal';


class HandleMembersInvite extends Component {
  static propTypes = {
    topHeader: PropTypes.bool,
    canInvitePartner: PropTypes.bool.isRequired,
    planAllowInvite: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    topHeader: false,
  }

  state = {
    show: false,
  }

  handleAddPartner = () => {
    const { canInvitePartner, planAllowInvite } = this.props;

    if (!canInvitePartner) return;
    if (!planAllowInvite) this.setState({ show: true });
  }

  render() {
    const { topHeader, canInvitePartner, planAllowInvite } = this.props;
    const { show } = this.state;

    const classes = classNames(topHeader ? '' : 'bg-alto p1 h5 rounded', {
      'cursor-pointer text-gray': canInvitePartner,
      'text-light-gray cursor-default': !canInvitePartner,
    });

    return (
      <>
        <div
          className={classes}
          data-balloon={canInvitePartner ? null : 'You are not able to invite. Please contact the fund admin.'}
          data-balloon-pos="up"
          data-balloon-length="medium"
          data-behavior={canInvitePartner && planAllowInvite ? 'modal' : null}
          data-target={canInvitePartner && planAllowInvite ? '#modal-invite' : null}
        >
          {topHeader ?
            <span
              className="block bold h5 hover hover-bg-wild-sand hover-text-blue lh-36 px1"
              onClick={this.handleAddPartner}
            >
              New Partner
            </span>
            :
            <span
              className="px1 fw400"
              onClick={this.handleAddPartner}
            >
              Add Partner
            </span>
          }
        </div>

        <UpgradeAccountModal
          show={show}
          closeModal={() => this.setState({ show: false })}
          paywallDescription="Paywall_New_Member"
        />
      </>
    );
  }
}

export default HandleMembersInvite;
