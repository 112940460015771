const updateKpiFieldState = kpiField => ({
  type: 'UPDATE_KPI_FIELD',
  kpiField,
});

const addKpiField = kpiField => ({
  type: 'ADD_KPI_FIELD',
  kpiField,
});

const removeKpiField = kpiField => ({
  type: 'REMOVE_KPI_FIELD',
  kpiField,
});

const updateKpiInChart = kpiField => ({
  type: 'UPDATE_KPI_IN_CHART',
  kpiField,
});

const updateKpisByDate = (kpisData, currentDate) => ({
  type: 'UPDATE_KPIS_BY_DATE',
  kpisData,
  currentDate,
});

const sameState = () => ({
  type: '',
});

export {
  updateKpiFieldState,
  addKpiField,
  removeKpiField,
  updateKpiInChart,
  updateKpisByDate,
  sameState,
};
