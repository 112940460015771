import SorterHeader from '../SorterHeader';

const DataRoomStatsUsersHeader = props => (
  <div className="col-12 flex text-gray h5 flex px2 bg-extra-light-gray">
    <div className="col-10 flex py1 pl2 fw400">
      <SorterHeader text="User" field="user_name" />
    </div>
    <div className="col-2 flex flex-justify-end mr2 py1 sm-hide fw400">
      <SorterHeader text="Last Access" field="last_update" />
    </div>
  </div>
);

export default DataRoomStatsUsersHeader;
