export default function numberFormat() {
  const getNumberFormatKpiChart = () => {
    if (window.number_format !== undefined) {
      if (window.number_format === 1) {
        return { thousandsSep: ',', decimalPoint: '.' };
      }
      if (window.number_format === 3) {
        return { thousandsSep: ' ', decimalPoint: ',' };
      }
    }
    return { thousandsSep: '.', decimalPoint: ',' };
  };

  return {
    getNumberFormatKpiChart,
    thousandsSep: getNumberFormatKpiChart().thousandsSep,
    decimalPoint: getNumberFormatKpiChart().decimalPoint,
  };
}
