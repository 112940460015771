export default function options(data, { width = '300', chartAnimation = true } = {}) {
  return (
    {
      title: {
        text: null,
      },
      chart: {
        type: 'pie',
        height: '200',
        width,
      },
      plotOptions: {
        series: {
          animation: chartAnimation ? { duration: 1000 } : false,
        },
      },
      series: [{
        data,
        borderColor: null,
        borderWidth: '1',
        innerSize: '60%',
        dataLabels: {
          enabled: false,
        },
      }],
      tooltip: {
        pointFormat: '<b>{point.percentage:.2f}%</b>',
      },
      legend: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
    }
  );
}
