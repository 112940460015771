import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import axios from 'axios';
import AsyncSelect from 'react-select/lib/Async';

import Modal from '../../Modal';
import CheckBox from '../../CheckBox';
import DatePicker from '../../selects/DatePicker';
import CleaveNumberFormatting from '../../CleaveNumberFormatting';

import { defaultSelectStyles, defaultSelectTheme } from '../../selects/SelectStyles';

export default class DealTermsWidget extends Component {
  static propTypes = {
    dealTerm: PropTypes.object,
    canEdit: PropTypes.bool.isRequired,
    companyId: PropTypes.string.isRequired,
    dealRoomId: PropTypes.number.isRequired,
    roundTypeOptions: PropTypes.array.isRequired,
    currency: PropTypes.string.isRequired,
    saveDealTerm: PropTypes.func,
    showModal: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    dealTerm: null,
    saveDealTerm: null,
    showModal: false,
  }

  constructor(props) {
    super(props);

    const { dealTerm } = this.props;

    /* eslint-disable camelcase */
    if (dealTerm) {
      const {
        funding_goal: { raw: fundingGoalRaw },
        company_valuation: { raw: companyValuationRaw },
        shares_offered: { raw: sharesOfferedRaw },
        price_per_share: { raw: pricePerShareRaw },
        closing_goal: { raw: closingGoalRaw },
        round_type: { raw: roundTypeRaw },
        data_room_file_id,
        notify_committed_investors,
      } = dealTerm;

      this.initialDealTerm = {
        dealTerm: {
          funding_goal: fundingGoalRaw,
          company_valuation: companyValuationRaw,
          shares_offered: sharesOfferedRaw,
          price_per_share: pricePerShareRaw,
          closing_goal: closingGoalRaw,
          round_type: roundTypeRaw,
          data_room_file_id,
          notify_committed_investors,
        },
      };
    } else {
      this.initialDealTerm = {
        dealTerm: {
          notify_committed_investors: false,
        },
      };
    }
    this.state = {
      ...this.initialDealTerm,
      termSheetFileOptions: [],
    };
    /* eslint-enable camelcase */
  }

  loadTermSheetOptions = async (inputValue) => {
    const { companyId, dealRoomId } = this.props;

    try {
      const res = await axios.get(`/company/${companyId}/deal_rooms/${dealRoomId}/data_room_files?file_name=${inputValue}`);
      this.setState({ termSheetFileOptions: res.data });
      return res.data;
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error || 'Error when loading files.' });
      return null;
    }
  }

  handleChange = (name, value) => {
    this.setState(state => ({
      ...state,
      dealTerm: {
        ...state.dealTerm,
        [name]: value,
      },
    }), () => {
      if (['funding_goal', 'shares_offered'].includes(name)) {
        this.setState(state => {
          const fundingGoal = parseFloat(state.dealTerm.funding_goal);
          const parsedSharesOffered = parseFloat(state.dealTerm.shares_offered);
          let result = '';
          if (fundingGoal && parsedSharesOffered) {
            result = (fundingGoal / parsedSharesOffered).toFixed(2);
          }
          return {
            ...state,
            dealTerm: {
              ...state.dealTerm,
              price_per_share: result,
            },
          };
        });
      }
    });
  }

  handleSubmit = async () => {
    const { saveDealTerm, dealTerm, closeModal } = this.props;
    const { dealTerm: dealTermState } = this.state;

    const params = { deal_room_term: dealTermState };
    let dealTermId = null;
    if (dealTerm) dealTermId = dealTerm.id;

    try {
      await saveDealTerm(dealTermId, params);
      closeModal();
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
    }
  }

  render() {
    const {
      dealTerm,
      canEdit,
      companyId,
      currency,
      roundTypeOptions,
      showModal,
      closeModal,
    } = this.props;
    const { dealTerm: dealTermState, termSheetFileOptions } = this.state;

    return (
      <React.Fragment>
        <span className="h5 text-light-gray">Terms</span>
        <div className="flex items-center flex-justify-between border-bottom border-wild-sand bw-1 pb2">
          <span className="h3 bold text-gray">Funding Goal</span>
          <div className="flex items-center">
            {dealTerm && dealTerm.funding_goal.readable &&
              <span className="text-light-gray h6">{currency}</span>
            }
            <span
              className="h3 text-gray bold ml1"
              data-balloon={(dealTerm && dealTerm.funding_goal.raw) ? dealTerm.funding_goal.raw : null}
              data-balloon-pos="down"
              data-balloon-length="medium"
            >
              {(dealTerm && dealTerm.funding_goal.readable) ? dealTerm.funding_goal.readable : '\u2013'}
            </span>
          </div>
        </div>
        <div className="pt2 flex items-center flex-justify-between">
          <span className="h5 fw400 text-light-gray">Round Type</span>
          <span className="text-gray fw400">{(dealTerm && dealTerm.round_type.readable) ? dealTerm.round_type.readable : '\u2013'}</span>
        </div>
        <div className="pt2 flex items-center flex-justify-between">
          <span className="h5 fw400 text-light-gray">Company Valuation</span>
          <div className="flex items-center">
            {dealTerm && dealTerm.company_valuation.readable &&
            <span className="text-light-gray h6">{currency}</span>
            }
            <span
              className="text-gray fw400 ml1"
              data-balloon={(dealTerm && dealTerm.company_valuation.raw) ? dealTerm.company_valuation.raw : null}
              data-balloon-pos="down"
              data-balloon-length="medium"
            >
              {(dealTerm && dealTerm.company_valuation.readable) ? dealTerm.company_valuation.readable : '\u2013'}
            </span>
          </div>
        </div>
        <div className="pt2 flex items-center flex-justify-between">
          <span className="h5 fw400 text-light-gray">Shares Offered</span>
          <span
            className="h4 text-gray fw400"
            data-balloon={(dealTerm && dealTerm.shares_offered.raw) ? dealTerm.shares_offered.raw : null}
            data-balloon-pos="down"
            data-balloon-length="medium"
          >
            {(dealTerm && dealTerm.shares_offered.readable) ? dealTerm.shares_offered.readable : '\u2013'}
          </span>
        </div>
        <div className="pt2 flex items-center flex-justify-between">
          <span className="h5 fw400 text-light-gray">Price per Share</span>
          <div className="flex items-center">
            {dealTerm && dealTerm.price_per_share.readable &&
            <span className="text-light-gray h6">{currency}</span>
            }
            <span
              className="h4 text-gray fw400 ml1"
              data-balloon={(dealTerm && dealTerm.price_per_share.raw) ? dealTerm.price_per_share.raw : null}
              data-balloon-pos="down"
              data-balloon-length="medium"
            >
              {(dealTerm && dealTerm.price_per_share.readable) ? dealTerm.price_per_share.readable : '\u2013'}
            </span>
          </div>
        </div>
        <div className="pt2 flex items-center flex-justify-between">
          <span className="h5 fw400 text-light-gray">Closing Goal</span>
          <span
            className="text-gray fw400"
            data-balloon={(dealTerm && dealTerm.closing_goal.raw) ? dealTerm.closing_goal.raw : null}
            data-balloon-pos="down"
            data-balloon-length="medium"
          >
            {(dealTerm && dealTerm.closing_goal.readable) ? dealTerm.closing_goal.readable : '\u2013'}
          </span>
        </div>
        <div
          className="pt2 flex items-center flex-justify-center col-12"
          data-balloon={(dealTerm && dealTerm.data_room_file_id) ? 'Download the latest Term Sheet. It is linked from the documents shown below.' : 'No Term Sheet uploaded (latest version will be linked from the documents uploaded below).'}
          data-balloon-pos="down"
          data-balloon-length="large"
        >
          <a
            className={`flex col-12 flex-justify-center items-center btn btn-outline fw400 py05 mt1 ${(dealTerm && dealTerm.data_room_file_id) ? 'text-blue' : 'text-light-gray is-disabled'}`}
            href={(dealTerm && dealTerm.data_room_file_id) ? `/c/${companyId}/data_room_files/${dealTerm.data_room_file_id}/download` : null}
            target="_blank"
            rel="noopener noreferrer"
          >
            {(dealTerm && dealTerm.data_room_file_id) ? 'Download Term Sheet' : <span className="cursor-disabled">Term Sheet not available yet...</span> }
          </a>
        </div>

        {/* Modal starts here */}
        {canEdit &&
          <Modal
            show={showModal}
            onClickOutside={closeModal}
            widthClass="react_modal--600"
          >
            <div className="flex flex-justify-between items-center semi-bold px3 py2 border-bottom border-lighter-gray">
              <div className="h3">Deal Room - Manage Deal Terms</div>
              <i className="fa fa-close h5 cursor-pointer" onClick={closeModal} />
            </div>
            <div className="flex flex-column px3 py2">
              <div className="flex flex-row pb2">
                <div className="flex flex-column col-6 pr1">
                  <span className="h5 text-gray pb1">
                    Funding Goal
                    <span
                      className="ml1"
                      data-balloon="Is the amount of money you would like to get."
                      data-balloon-pos="up"
                      data-balloon-length="medium"
                    >
                      <i className="fa fa-info-circle text-light-gray" />
                    </span>
                  </span>
                  <CleaveNumberFormatting
                    value={`${dealTermState.funding_goal}`}
                    className="col-12 border border-medium-lighter-gray hover-border-medium-light-gray mr1"
                    name="funding_goal"
                    onChange={e => this.handleChange(e.target.name, e.target.rawValue)}
                  />
                </div>
                <div className="flex flex-column col-6 pl1 pr1">
                  <span className="h5 text-gray pb1">
                    Round Type
                    <span
                      className="ml1"
                      data-balloon="Select the type of round you are raising."
                      data-balloon-pos="up"
                      data-balloon-length="medium"
                    >
                      <i className="fa fa-info-circle text-light-gray" />
                    </span>
                  </span>
                  <ReactSelect
                    value={roundTypeOptions.filter(opt => opt.value === dealTermState.round_type)}
                    options={roundTypeOptions}
                    onChange={selectedOption => this.handleChange('round_type', selectedOption.value)}
                    styles={defaultSelectStyles}
                    theme={defaultSelectTheme}
                    name="round_type"
                  />
                </div>
              </div>
              <div className="flex flex-row pb2">
                <div className="flex flex-column col-6 pr1">
                  <span className="h5 text-gray pb1">
                    Company Valuation (Pre-Money)
                    <span
                      className="ml1"
                      data-balloon="Set the company valuation you are raising the round for."
                      data-balloon-pos="up"
                      data-balloon-length="medium"
                    >
                      <i className="fa fa-info-circle text-light-gray" />
                    </span>
                  </span>
                  <CleaveNumberFormatting
                    value={`${dealTermState.company_valuation}`}
                    className="col-12 border border-medium-lighter-gray hover-border-medium-light-gray mr1"
                    name="company_valuation"
                    onChange={e => this.handleChange(e.target.name, e.target.rawValue)}
                  />
                </div>
                <div className="flex flex-column col-6 pl1">
                  <span className="h5 text-gray pb1">Shares Offered
                    <span
                      className="ml1"
                      data-balloon="Set the amount of shares you are offering."
                      data-balloon-pos="up"
                      data-balloon-length="medium"
                    >
                      <i className="fa fa-info-circle text-light-gray" />
                    </span>
                  </span>
                  <CleaveNumberFormatting
                    value={`${dealTermState.shares_offered}`}
                    className="col-12 border border-medium-lighter-gray hover-border-medium-light-gray mr1"
                    name="shares_offered"
                    onChange={e => this.handleChange(e.target.name, e.target.rawValue)}
                  />
                </div>
              </div>
              <div className="flex flex-row pb2">
                <div className="flex flex-column col-6 pr2">
                  <span className="h5 text-gray pb1">
                    Price per Share
                    <span
                      className="ml1"
                      data-balloon="This is the price per share for each share offered."
                      data-balloon-pos="up"
                      data-balloon-length="medium"
                    >
                      <i className="fa fa-info-circle text-light-gray" />
                    </span>
                  </span>
                  <input
                    value={dealTermState.price_per_share || ''}
                    type="text"
                    className="border border-medium-lighter-gray hover-border-medium-light-gray"
                    disabled
                  />
                </div>
                <div className="flex flex-column col-6 pl1 pr1">
                  <span className="h5 text-gray pb1">Closing Goal
                    <span
                      className="ml1"
                      data-balloon="This is the date you want the round to be closed by."
                      data-balloon-pos="up"
                      data-balloon-length="medium"
                    >
                      <i className="fa fa-info-circle text-light-gray" />
                    </span>
                  </span>
                  <DatePicker
                    value={dealTermState.closing_goal || ''}
                    name="closing_goal"
                    className="border border-medium-lighter-gray hover-border-medium-light-gray"
                    onChange={e => this.handleChange(e.target.name, e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-row pb2">
                <div className="flex flex-column col-6 pr2">
                  <span className="h5 text-gray pb1">
                    Term Sheet
                    <span
                      className="ml1"
                      data-balloon="Select the Term Sheet uploaded to the documents in the Deal Room."
                      data-balloon-pos="up"
                      data-balloon-length="medium"
                    >
                      <i className="fa fa-info-circle text-light-gray" />
                    </span>
                  </span>
                  <AsyncSelect
                    value={termSheetFileOptions.filter(opt => opt.value === dealTermState.data_room_file_id)}
                    cacheOptions
                    defaultOptions
                    onChange={selectedOption => this.handleChange('data_room_file_id', selectedOption ? selectedOption.value : null)}
                    loadOptions={this.loadTermSheetOptions}
                    styles={defaultSelectStyles}
                    theme={defaultSelectTheme}
                    isClearable
                    name="data_room_file_id"
                  />
                </div>
              </div>
              <span className="h6 text-light-gray lh-125">The deal terms are set by you or any of your team members. We will not inform anyone of changes made to a deal, except if you share the deal or add commitments to it. The information provided here is independent of existing captable data.</span>
            </div>
            <div className="flex flex-justify-end px3 pt1 mt1 mb2 border-top border-lighter-gray">
              {/*
              <div className="flex items-center">
                <CheckBox
                  name="notify_committed_investors"
                  defaultChecked={dealTermState.notify_committed_investors}
                  onChange={() => this.handleChange('notify_committed_investors', !dealTermState.notify_committed_investors)}
                  fixedWidth
                />
                <span className="h5 ml2">Notify Commited investors of changes</span>
              </div>
              */}
              <div className="flex">
                <div
                  type="submit"
                  onClick={closeModal}
                  className="bg-white text-blue border-none h4 fw400 px3 py1 cursor-pointer rounded"
                >
                  Close
                </div>
                <div
                  type="submit"
                  className="bg-blue text-white bs-around border-none h4 fw400 px3 py1 cursor-pointer rounded"
                  onClick={() => this.handleSubmit()}
                >
                  Save
                </div>
              </div>
            </div>
          </Modal>
        }
      </React.Fragment>
    );
  }
}
