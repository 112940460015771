import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TextInput from '../../../TextInput';
import CleaveNumberFormatting from '../../../CleaveNumberFormatting';
import DatePicker from '../../../selects/DatePicker';
import FundraisingManageModal from './modals/FundraisingManageModal';


class Fundraising extends Component {
  static propTypes = {
    funding: PropTypes.object.isRequired,
    hasPermission: PropTypes.bool.isRequired,
    investorId: PropTypes.number.isRequired,
    currency: PropTypes.string,
    updateFunding: PropTypes.func.isRequired,
    switchFundraisingData: PropTypes.func.isRequired,
  }

  static defaultProps = {
    currency: null,
  }

  state = {
    showManageModal: false,
  }

  isCustom = () => {
    const { hasPermission, funding } = this.props;
    return !hasPermission || funding.custom_investment || !funding.company_data;
  }

  switchFundraisingData = async (useCustomValues) => {
    const { investorId, funding, switchFundraisingData } = this.props;

    await switchFundraisingData(investorId, funding.investment_id, !useCustomValues);
  }

  saveFunding = async (values) => {
    const { investorId, funding, updateFunding } = this.props;
    const params = { funding: { ...values } };

    try {
      await updateFunding(investorId, funding.investment_id, params);
    } catch (err) {
      console.log(err.response.data.errors);
    }
  }

  // TODO: if values come from the company, just show them
  renderRowField = (title, name, attr, component = 'CleaveNumberFormatting') => {
    const {
      funding,
    } = this.props;

    const components = { TextInput, CleaveNumberFormatting, DatePicker };
    const InputComponent = components[component];
    const inputClasses = classNames('col-12 bg-none right-align', {
      'mb1 border border-lighter-gray hover hover-border-blue pr1': funding.edit,
      'p0 border-transparent': !funding.edit,
    });
    const indicator = classNames({
      'desktop__notification--changes-green': funding.edit && !this.isCustom(),
      'desktop__notification--changes-blue': funding.edit && this.isCustom(),
    });
    const inputStyling = {
      padding: funding.edit ? null : '0',
      height: '36px',
    };

    return (
      <div className="flex flex-column py05 lh-125">
        <div className="pb1 fw400">{title}</div>
        <div className={`relative ${indicator}`}>
          {
            ((!funding.edit && attr.readable_value) || (!funding.custom_investment && funding.company_data)) ?
              <div className={`${inputClasses} flex items-center flex-justify-end`} style={{ height: '36px', lineHeight: 'normal' }}>
                {attr.readable_value}
              </div>
              :
              <InputComponent
                className={inputClasses}
                name={name}
                value={(attr.value || '').toString()}
                onBlur={e => this.saveFunding({ [e.target.name]: e.target.rawValue || e.target.value })}
                disabled={!funding.edit}
                style={inputStyling}
              />
          }
        </div>
      </div>
    );
  }

  render() {
    const {
      funding,
      hasPermission,
      currency,
    } = this.props;

    const { showManageModal } = this.state;

    return (
      <>
        <div className="flex flex-justify-between flex-wrap">
          <div className="flex">
            <div className="bold text-white pl2">Company Fundraising Summary</div>
            <div
              data-balloon="If the company is raising a new financing round, this section contains the details. If you connect to a company, it initially takes the details from the company's Deal Room section."
              data-balloon-pos="right"
              data-balloon-length="xlarge"
            >
              <i className="fa fa-info-circle text-blue ml1 h5" />
            </div>
            {currency &&
              <span className="h5 text-white ml1 fw400">({currency})</span>
            }
          </div>
          {funding.edit && !funding.custom_investment &&
            <div
              className="px1 bg-white h6 text-light-gray z3 cursor-pointer rounded mr2 sm-mt1 sm-ml2"
              onClick={() => this.setState({ showManageModal: true })}
            >
              <i className="fa fa-cog mr1" />
              <span className="bold">Manage</span>
            </div>
          }
        </div>

        {!funding.custom_investment && funding.company_data && !funding.deal_room_opened ?
          <div className="p2 flex flex-column flex-justify-center">
            <span>
              This data comes from the Deal Room. But unfortunately Deal Room is closed.
            </span>
            {funding.edit &&
              <>
                <span className="my1">
                  Get in touch with the company by the button bellow.
                </span>
                <div className="flex">
                  <a
                    className="py1 px2 bg-green text-white rounded"
                    href={`mailto:${funding.company_contact}`}
                  >
                    <span>Get in Touch</span>
                  </a>
                </div>
              </>
            }
          </div>
          :
          <div className="flex flex-justify-between flex-wrap p2">
            {this.renderRowField('Seeking', 'seeking', funding.seeking)}
            {this.renderRowField('At Pre-Money', 'fundraising_pre_money', funding.fundraising_pre_money)}
            {this.renderRowField('Round Type', 'round_type', funding.round_type, 'TextInput')}
            {this.renderRowField('Est. Closing', 'estimated_closing', funding.estimated_closing, 'DatePicker')}
          </div>
        }

        {!funding.custom_investment &&
          <FundraisingManageModal
            show={showManageModal}
            hasPermission={hasPermission}
            isCustom={this.isCustom()}
            closeModal={() => this.setState({ showManageModal: false })}
            setCustomData={() => this.switchFundraisingData(true)}
            setCompanyData={() => this.switchFundraisingData(false)}
          />
        }
      </>
    );
  }
}

export default Fundraising;
