import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DropdownMenu from '../DropdownMenu';
import ConfirmationModal from '../ConfirmationModal';


class SigningRoomRequest extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  state = {
    loading: false,
  }

  getColClass = () => {
    const { data } = this.props;
    const name = 'col-3';
    const date = 'col-4';
    let signed_by = 'col-2';
    const status = 'col-2';
    const options = 'col-1';
    if (data.investor) {
      signed_by = 'col-3';
    }
    return { name, date, signed_by, status, options };
  }

  cancelSigningRequest = () => {
    const { data } = this.props;

    this.setState({ loading: true });
    App.Api.post(data.cancel_signing_room_request_url).then(
      res => {
        store.dispatch({ type: 'SET_ITEM', list: 'main', id: res.id, value: res });
        this.setState({ loading: false });
        App.State.setFlash({ name: 'notice', msg: 'Signing request has been cancelled successfully!' });
      },
      err => {
        console.log(err);
        this.setState({ loading: false });
      },
    );
  }

  classNamesForCompleted = (type) => {
    const { data } = this.props;

    if (type === 'link') {
      return (data.completed ? 'hover-text-blue' : 'cursor-default');
    }
    return (data.completed ? '' : 'text-light-gray');
  }

  deleteSigningRequest = () => {
    const { data } = this.props;

    this.setState({ loading: true });
    App.Api.delete(data.delete_signing_room_request_url).then(
      () => {
        store.dispatch({ type: 'REMOVE_ITEM', list: 'main', id: data.id });
        this.setState({ loading: false });
        App.State.setFlash({ name: 'notice', msg: 'Signing request has been deleted successfully!' });
      },
      err => {
        console.log(err);
        this.setState({ loading: false });
      },
    );
  }

  displayData = (field) => {
    const { data } = this.props;

    return (
      <div className="flex items-center flex-justify-start py1">
        <span className="text-gray">{data[field]}</span>
      </div>
    );
  }

  displayOptions = () => {
    const { data } = this.props;
    const { loading } = this.state;

    return (
      <DropdownMenu
        setFalseWhenClickingInside
        faIcon={loading ? 'refresh fa-spin' : 'cog'}
      >
        {data.state === 'draft' &&
          <li>
            <a
              className="hover hover-text-blue hover-bg-wild-sand text-gray"
              href={data.signing_room_request_edit_url}
            >
              <span className="block bold h5 lh-36 px1">Change Settings</span>
            </a>
          </li>
        }
        <li>
          <a
            className={`hover hover-bg-wild-sand text-gray ${this.classNamesForCompleted('link')}`}
            href={data.completed ? data.signing_room_request_summary_url : null}
          >
            <span className={`block bold h5 lh-36 px1 ${this.classNamesForCompleted('text')}`}>Send Summary</span>
          </a>
        </li>
        {data.state === 'ongoing' ?
          <li>
            <ConfirmationModal
              className="hover hover-text-blue hover-bg-wild-sand text-gray"
              onClick={() => this.cancelSigningRequest()}
              html="Cancel Signing"
              hmtlClassName="bold h5 lh-36 px1"
              message="<b>You are about to permanently cancel this Signing and inform all signers of the cancellation.</b><br/>Are you sure you want to permanently cancel this Signing?"
              confirmationText="Yes"
              cancelText="Cancel"
            />
          </li>
          :
          <li>
            <ConfirmationModal
              className="hover hover-text-blue hover-bg-wild-sand text-gray"
              onClick={() => this.deleteSigningRequest()}
              html="Delete Signing"
              hmtlClassName="bold h5 lh-36 px1"
              message="<b>You are about to permanently delete this Signing with all content.</b><br/>Are you sure you want to permanently delete this Signing?"
              confirmationText="Delete"
              cancelText="Cancel"
            />
          </li>
        }
      </DropdownMenu>
    );
  }

  displaySignedBy = () => {
    const { data } = this.props;

    return (
      <div className="flex items-center flex-justify-start py1">
        <span className="text-gray">{`${data.signed_by} of ${data.total_signers_count}`}</span>
      </div>
    );
  }

  displayStatus = () => {
    const { data } = this.props;

    return (
      <div className={`flex items-center flex-justify-start py1 ${this.statusColor()}`}>
        <i className={`fa ${this.statusIcon()}`} />
        <span className="ml1">{data.state}</span>
      </div>
    );
  }

  statusBorderColor = () => {
    const { data } = this.props;

    switch (data.state) {
      case 'ongoing':
        return 'border-blue';
      case 'completed':
        return 'border-green';
      case 'cancelled':
        return 'border-red';
      default:
        return 'border-light-gray';
    }
  }

  statusColor = () => {
    const { data } = this.props;

    switch (data.state) {
      case 'ongoing':
        return 'text-blue';
      case 'completed':
        return 'text-green';
      case 'cancelled':
        return 'text-red';
      default:
        return 'text-light-gray';
    }
  }

  statusIcon = () => {
    const { data } = this.props;

    switch (data.state) {
      case 'ongoing':
        return 'fa-hourglass-half';
      case 'completed':
        return 'fa-check';
      case 'cancelled':
        return 'fa-times';
      default:
        return 'fa-pencil';
    }
  }

  render() {
    const { data } = this.props;
    return (
      <div className={`flex flex-wrap px2 h5 sm-px0 border-left bw-2 ${this.statusBorderColor()}`}>
        <a
          className={`${this.getColClass().name} border-alto py1 pl2 flex sm-col-6 truncate fw400`}
          href={data.signing_room_request_url}
        >
          {this.displayData('name')}
        </a>

        <div className={`${this.getColClass().date} border-alto py1 pl2 flex lg-hide sm-hide`}>
          {this.displayData('date_text')}
        </div>

        <div className={`${this.getColClass().signed_by} border-alto py1 pl2 flex items-center lg-hide sm-hide`}>
          {this.displaySignedBy()}
        </div>

        <div className={`${this.getColClass().status} border-alto py1 pl2 flex sm-col-6 capitalize truncate`}>
          {this.displayStatus()}
        </div>

        {
          !data.investor &&
          <div className={`${this.getColClass().options} border-alto py1 pl2 flex items-center sm-hide`}>
            {this.displayOptions()}
          </div>
        }
      </div>
    );
  }
}

export default SigningRoomRequest;
