import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ManageContacts from '../../contacts/ManageContacts';

import Circle from '../../Circle';
import InvestorInvite from '../../invites/InvestorInvite';


class RecipientStepContacts extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    groups: PropTypes.array.isRequired,
    contacts: PropTypes.array.isRequired,
    externalUsers: PropTypes.array,
    addRecipients: PropTypes.func.isRequired,
    removeRecipients: PropTypes.func.isRequired,
    // highlightMember: PropTypes.func.isRequired,
    toogleModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    externalUsers: [],
  }

  nodes = () => {
    const { groups } = this.props;

    const disabledIcon = (node) => (node.disabled ? (node.investor_id && 'exclamation-triangle') : null);
    const disabledColor = (node) => (node.disabled ? (node.investor_id && 'orange') : null);
    const disabledTooltip = (node) => (node.disabled ? (node.investor_id && "Can't select recipient due to an unaccepted invitation!") : null);

    const nodeTemplate = (node, parentName = '') => {
      if (node.recipients && node.recipients.length > 0) {
        return (
          {
            value: node.name.toLocaleLowerCase(),
            label: `${node.name}`,
            disabled: node.disabled,
            user_ids: node.recipients.flatMap(member => member.user_ids),
            recipient_ids: node.recipient_ids,
            disabled_icon: disabledIcon(node),
            disabled_color: disabledColor(node),
            disabled_tooltip: disabledTooltip(node),

            children: node.recipients.map(recipient => nodeTemplate(recipient, node.name)),
          }
        );
      }
      if (parentName) {
        return (
          {
            value: `${parentName.toLocaleLowerCase()}_${node.id}`,
            label: `${node.name} ${node.email ? `(${node.email})` : ''}`,
            icon: node.avatar,
            disabled: node.disabled,
            user_ids: node.user_ids,
            recipient_ids: node.recipient_ids,
            disabled_icon: disabledIcon(node),
            disabled_color: disabledColor(node),
            disabled_tooltip: disabledTooltip(node),
            highlight: node.highlight,
          }
        );
      }
      return (
        {
          value: node.name.toLocaleLowerCase(),
          label: `${node.name}`,
          disabled: node.disabled,
          user_ids: node.recipients.flatMap(member => member.user_ids),
          recipient_ids: node.recipient_ids,
          disabled_icon: disabledIcon(node),
          disabled_color: disabledColor(node),
          disabled_tooltip: disabledTooltip(node),
        }
      );
    };

    return groups.map(group => nodeTemplate(group));
  }

  checkedNodes = () => {
    const { groups } = this.props;

    const checkedRecipients = (node, parentName) => (
      (node.recipients && node.recipients.length > 0) ?
        node.recipients.filter(member => member.checked).flatMap(child => checkedRecipients(child, node.name))
        :
        (`${parentName.toLocaleLowerCase()}_${node.id}`)
    );

    return groups.flatMap(group => checkedRecipients(group, group.name));
  }

  allContacts = () => {
    const { contacts } = this.props;

    const mapContact = (node) => (
      node.recipients && node.recipients.length > 0 ?
        node.recipients.flatMap(child => mapContact(child))
        :
        node
    );

    return contacts.filter(contact => contact.recipients.length > 0).flatMap(contact => mapContact(contact));
  }

  // Not used anymore, left for reference
  openInvestorModal = () => {
    $('#modal-invite-investor').next('.modal__layer').show();
    $('#modal-invite-investor').fadeIn();
  }

  openTeamModal = () => {
    $('#modal-invite').next('.modal__layer').show();
    $('#modal-invite').fadeIn();
  }

  // highlightInvestorMember = (contact) => {
  //   const { highlightMember } = this.props;

  //   if (!this.conditionToHighlight()) {
  //     App.State.setFlash({ name: 'alert', msg: "Can't add existing user as an external one." });
  //     return;
  //   }

  //   const params = { update_recipient: { display_member: true } };

  //   if (contact.recipient_id) {
  //     highlightMember(contact.recipient_id, params).then(
  //       () => this.setState({ showTextInput: false, searchField: '' }),
  //       () => App.State.setFlash({ name: 'alert', msg: 'Sorry, but an error happened while highlighting the recipient.' }),
  //     ).then(
  //       () => this.setState({ showTextInput: true }),
  //     );
  //   } else {
  //     App.State.setFlash({ name: 'alert', msg: 'Before highlighting a member, please add the fund as a recipient.' });
  //   }
  // }

  handleGroupContactSelect = (contact) => {
    const { addRecipients } = this.props;
    // const { addRecipients } = this.props;

    const params = { update_recipient: { values: { user_ids: contact.recipients.flatMap(recipient => recipient.user_ids) } } };

    addRecipients(params);
  }

  // addExistingContactRecipient = (userIds) => {
  //   const { addRecipients } = this.props;

  //   const params = { update_recipient: { values: { user_ids: userIds } } };

  //   addRecipients(params).then(
  //     () => this.setState({ showTextInput: false, searchField: '' }),
  //     () => App.State.setFlash({ name: 'alert', msg: 'Sorry, but an error happened while adding the recipient.' }),
  //   ).then(
  //     () => this.setState({ showTextInput: true }),
  //   );
  // }

  removeRecipients = (recipient) => {
    const { removeRecipients } = this.props;

    removeRecipients({ data: { update_recipient: { ids: recipient.recipient_id || recipient.recipient_ids } } });
  }

  addRecipients = (recipient) => {
    const { addRecipients } = this.props;

    const params = { update_recipient: { values: { user_ids: recipient.user_ids } } };

    addRecipients(params);
  }

  addExternalSingleRecipient = (email) => {
    const { addRecipients } = this.props;

    const params = { update_recipient: { values: { email } } };

    return addRecipients(params);
  }

  handleEmailInput = (email) => {
    const { company, toogleModal } = this.props;

    if (company.subscribed || company.free_trial_available) {
      return this.addExternalSingleRecipient(email);
    }
    return toogleModal();
  }

  render() {
    const {
      company,
      currentUser,
      groups,
      externalUsers,
      toogleModal,
    } = this.props;

    const freeTrialText = company.free_trial_available ? 'Click here to Upgrade Now. If you want to try this feature, you can test it twice by using it for two updates that you send out!' : 'Click here to Upgrade Now. There are no more free trials.';

    return (
      <React.Fragment>
        <div className="flex mb3 mt2">
          <div><Circle content="1" border="alto" text="light-gray" /></div>
          <div className="flex flex-column col-12">
            <span className="fw400">
              Select Recipients from Your Connections
            </span>
            <span className="text-light-gray">
              Select or invite people to investory below. After you have invited them, you will be able to send them an update immediately.
            </span>
            <div className="flex flex-column col-12 lg-col-10">
              <div className="col-10">
                <div className="pt2">
                  <span className="h6 flex flex-center fw400 text-gray">Connections</span>
                </div>
                <ManageContacts
                  groups={groups}
                  allContacts={this.allContacts()}
                  nodes={this.nodes()}
                  checkedNodes={this.checkedNodes()}
                  expandedNodes={['']}
                  addRecipients={this.addRecipients}
                  removeRecipients={this.removeRecipients}
                  withSearch={false}
                  withExternalRecipients={false}
                  withContacts
                />
              </div>
              <div className="flex py1">
                <InvestorInvite
                  company={{ id: company.id, name: company.name }}
                  currentUser={{ id: currentUser.id, email: currentUser.email }}
                  html='<div class="px2 py05 cursor-pointer bg-white text-blue border border-blue rounded hover hover-bg-blue hover-text-white">Invite Investor(s)</div>'
                />
                <div
                  className="ml1 px2 py05 cursor-pointer bg-white text-green border border-green rounded hover hover-bg-green hover-text-white"
                  onClick={(e) => { e.preventDefault(); this.openTeamModal(); }}
                >
                  Invite Team Member(s)
                </div>
              </div>
            </div>
          </div>
        </div>
        {!company.investor_managed &&
          <div className="flex mb3 mt2">
            <div><Circle content="2" border="alto" text="light-gray" /></div>
            <div className="flex flex-column col-12">
              <div className="flex fw400">
                External Recipients&nbsp;
                {(!company.subscribed && !company.unlimited_external_recipients) &&
                  <span
                    data-balloon={freeTrialText}
                    data-balloon-pos="down"
                    data-balloon-length="large"
                  >
                    <div onClick={toogleModal} className="text-orange fw400 cursor-pointer"><i className="fa fa-info-circle pl1 pr05" />Pro Feature {company.free_trial_available ? `(${company.free_trials_left})` : ''}</div>
                  </span>
                }
              </div>
              <span className="text-light-gray">
                Send external recipients (outside of your connections) an update without invitation.
                <br />Note that these recipients are only saved in this update (they are not saved anywhere else).
              </span>
              <div className="flex flex-column col-12 lg-col-10">
                <div className="pt2">
                  <span className="text-gray fs-12 fw400">Add a External Recipient</span>
                </div>
                <ManageContacts
                  externalUsers={externalUsers}
                  allContacts={this.allContacts()}
                  removeRecipients={this.removeRecipients}
                  handleEmailInput={this.handleEmailInput}
                  toogleModal={toogleModal}
                  canAddExternalRecipient={company.subscribed || company.free_trial_available}
                  withSearch
                  withExternalRecipients
                  withContacts={false}
                />
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default RecipientStepContacts;
