import React from 'react';
import PropTypes from 'prop-types';


const ValueRow = ({ row }) => (
  <div className="flex">
    <div className="border-bottom border-left border-alto pl2 bg-extra-light-gray col-4 py05 px2 h6 fw400 italic">{ row.date }</div>
    <div className="border-bottom border-left border-alto pl2 col-4 right-align py05 px2 h6 fw400 italic">{ row.actual }</div>
    <div className="border-bottom border-left border-right border-alto pl2 col-4 right-align py05 px2 h6 fw400 italic">{ row.forecast }</div>
  </div>
);

ValueRow.propTypes = {
  row: PropTypes.object.isRequired,
};

export default ValueRow;
