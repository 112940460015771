export const addInvestmentFields = fields => ({
  type: 'ADD_INVESTMENT_FIELDS',
  fields,
});

export const updateInvestmentFields = fields => ({
  type: 'UPDATE_INVESTMENT_FIELDS',
  fields,
});

export const overwriteInvestmentFields = fields => ({
  type: 'OVERWRITE_INVESTMENT_FIELDS',
  fields,
});

export const removeInvestmentFields = ids => ({
  type: 'REMOVE_INVESTMENT_FIELDS',
  ids,
});

export const resetInvestmentFields = ids => ({
  type: 'RESET_INVESTMENT_FIELDS',
  ids,
});

export const copyCompanyData = (fields, fieldType) => ({
  type: 'COPY_COMPANY_DATA',
  fields,
  fieldType,
});
