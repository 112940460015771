import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

class TeamCardAdmin extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
    updateTopState: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
    document.addEventListener('touchstart', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
    document.removeEventListener('touchstart', this.handleClick, false);
  }

  deleteMember = (e) => {
    e.preventDefault();
    const { record, updateTopState } = this.props;

    App.Api.delete(record.manage_url).then((data) => {
      updateTopState(data);
      App.State.setFlash({ name: 'notice', msg: 'User removed!' });
    });
  }

  reinvite = async (e) => {
    e.preventDefault();
    const { record } = this.props;

    try {
      await axios.patch(record.reinvite_url);
      App.State.setFlash({ name: 'notice', msg: 'Collaborator reinvited successfully!' });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an error prevented the reinvite.' });
    }
  }

  handleClick = (e) => {
    if (ReactDOM.findDOMNode(this).contains(e.target)) return;
    this.setState({ open: false });
  }

  toogleModal = () => {
    const { bModalOpened } = this.state;
    this.setState({ bModalOpened: !bModalOpened });
  }

  render() {
    const { record } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
        <div className="absolute position-right-top cursor-pointer">
          <div className="text-light-gray" onClick={() => this.setState({ open: !open })}>
            <i className="fa fa-cog" />
          </div>

          <div className={`dropdown dropdown--center absolute bg-white border border-alto bs-dropdown left-50 transform-horizontal-center z1 ${open ? '' : 'hidden'} py1`}>
            <ul className="left-align m0 mw150">
              {record.reinvite_url &&
                <li>
                  <div
                    className="bold h5 link-hover--blue block nowrap px1 text-gray cursor-pointer"
                    href="#"
                    onClick={this.reinvite}
                  >
                    <i className="fa fa-fw fa-refresh fa--dropdown right" />
                    Reinvite
                  </div>
                </li>
              }
              <li>
                <div
                  className="bold h5 link-hover--blue block nowrap px1 text-gray cursor-pointer"
                  href="#"
                  onClick={this.deleteMember}
                >
                  <i className="fa fa-fw fa-trash fa--dropdown right" />
                  Remove
                </div>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>

    );
  }
}

export default TeamCardAdmin;
