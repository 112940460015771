import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import ConfirmationModal from '../../ConfirmationModal';
import Modal from '../../Modal';


class InvestorCustomReminderSendMessageModal extends Component {
  static propTypes = {
    ids: PropTypes.object.isRequired,
    items: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      sending: false,
      message: 'Dear company, we have not received the update.',
    };
  }

  sendReminder = () => {
    const { ids, items, closeModal } = this.props;
    const { message, sending } = this.state;

    if (sending) return;

    const params = { investor_custom_reminder_params: { message, investment_ids: items.selected.map(investment => investment.id) } };

    this.setState({ sending: true }, async () => {
      try {
        await axios.post(`/investor/${ids.investorId}/investor_custom_reminders`, params);
        this.setState({ sending: false }, () => closeModal());
        App.State.setFlash({ name: 'notice', msg: 'Message sent.' });
        window.location.reload();
      } catch (err) {
        App.State.setFlash({ name: 'alert', msg: err.response.data.error });
        this.setState({ sending: false });
      }
    });
  }

  render() {
    const { closeModal, items } = this.props;

    const {
      message,
    } = this.state;

    return (
      <Modal
        className="react_modal--300"
        show
        onClickOutside={() => closeModal()}
      >
        <div className="flex flex-justify-between p2 border-bottom border-lighter-gray">
          <div className="flex flex-column">
            <div className="h3 bold">Manual Message</div>
            <div className="mt1">
              <span className="h5 text-light-gray">This message will be sent immediately to all companies selected with the tick boxes below</span>
            </div>
          </div>
          <i className="fa fa-close h5 cursor-pointer bold" onClick={() => closeModal()} />
        </div>

        <div className="text-gray p3 h4">
          <div className="flex flex-column">
            <div className="flex mb2 fw400">
              <span className="h3">Message text:</span>
            </div>
            <div className="flex items-center">
              <textarea
                value={message}
                onChange={(e) => this.setState({ message: e.target.value })}
                placeholder="Your message goes in here."
                className="border border-alto text-gray lh-15 col-12 h5 input-alto__focus"
                style={{ height: '80px' }}
              />
            </div>
          </div>
        </div>

        <div className="bg-white p3">
          <div className="pt3 flex flex-center flex-justify-around">
            <div
              href="#"
              className="col-3 center py1 bg-light-gray rounded text-white cursor-pointer"
              onClick={(e) => { e.preventDefault(); closeModal(); }}
            >
              Close
            </div>

            <ConfirmationModal
              html={'<span>Send</span>'}
              className="col-3 center py1 bg-green rounded text-white cursor-pointer"
              onClick={this.sendReminder}
              message={`You are about to send this reminder to <b>${items.selected.length} companies</b>. Are you sure?`}
              width=""
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default InvestorCustomReminderSendMessageModal;
