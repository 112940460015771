import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Draggable } from 'react-beautiful-dnd';
import ReactSelect from 'react-select';

import DropdownMenu from '../DropdownMenu';
import ConfirmationModal from '../ConfirmationModal';
import ConfirmationModal2 from '../modals/ConfirmationModal';

import { defaultSelectStyles, defaultSelectTheme } from '../selects/SelectStyles';
import TextInput from '../TextInput';
import Button from '../Button';

// import KpiChartModal from './KpiChartModal';


class KpiRow extends Component {
  // Preview won't be used anymore for now
  // state = {
  //   showChartModal: null,
  // }

  // chartTypes = [
  //   { name: 'line', icon: '/images/kpi-icons/chart-line-gray.svg', display: 'Line' },
  //   { name: 'spline', icon: '/images/kpi-icons/chart-spline-gray.svg', display: 'Spline' },
  //   { name: 'area-spline', icon: '/images/kpi-icons/chart-area-gray.svg', display: 'Area' },
  //   { name: 'bar', icon: '/images/kpi-icons/chart-column-gray.svg', display: 'Bar' },
  //   { name: 'bar_horizontal', icon: '/images/kpi-icons/chart-bar-gray.svg', display: 'Horizontal Bar' },
  //   { name: 'number', icon: '/images/kpi-icons/chart-number-gray.svg', display: 'Last data only' },
  // ]

  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    periodStepOptions: PropTypes.array.isRequired,
    unitOptions: PropTypes.array.isRequired,
    edit: PropTypes.bool.isRequired,
    kpi: PropTypes.object.isRequired,
    updateKpi: PropTypes.func.isRequired,
    deleteKpi: PropTypes.func.isRequired,
    refreshKpis: PropTypes.func.isRequired,
    handleExport: PropTypes.func.isRequired,
    updateSingleKpiLibraryId: PropTypes.func.isRequired,
  }

  state = {
    currentParam: null,
    showConfirmation: false,
  }

  // componentDidMount() {
  //   const { kpi } = this.props;
  //   const metric = kpi.metric.replace(/[\ \-\/\\^$*+?.()|[\]{}]/g, '-');
  //   const regex = new RegExp(metric, 'g');

  //   if (window.location.hash.match(regex)) this.setState({ showChartModal: kpi.id });
  // }

  periodStepFormat = kpi => {
    if (!kpi.last_value) return '-';
    const lastDate = kpi.last_value.date;

    switch (kpi.period_step) {
      case 'quarters': {
        return `${moment(lastDate).format('[Q]Q, YYYY')}`;
      }
      case 'years': {
        return `${moment(lastDate).format('YYYY')}`;
      }
      case 'months': {
        return `${moment(lastDate).format('MMM, YYYY')}`;
      }
      case 'weeks': {
        return `Week ${moment(lastDate).format('w, YYYY')}`;
      }
      case 'days': {
        return `${moment(lastDate).format('D MMM, YYYY')}`;
      }
      default: {
        return `${moment(lastDate).format('D, MMM, YYYY')}`;
      }
    }
  }

  upToDateCheck = kpi => {
    if (!kpi.last_value) return false;

    const last = moment(kpi.last_value.date);
    const now = moment();
    const current = moment();

    if (last === current) return true;
    if (last.isAfter(now)) return false;

    switch (kpi.period_step) {
      case 'quarters': {
        if (last.isSame(now, 'quarter') || last.isSame(now.subtract(1, 'quarter'), 'quarter')) {
          return true;
        }
        break;
      }
      case 'years': {
        if (last.isSame(now, 'year') || last.isSame(now.subtract(1, 'year'), 'year')) {
          return true;
        }
        break;
      }
      case 'months': {
        if (last.isSame(now, 'month') || last.isSame(now.subtract(1, 'month'), 'month')) {
          return true;
        }
        break;
      }
      case 'weeks': {
        if (last.isSame(now, 'week') || last.isSame(now.subtract(1, 'week'), 'week')) {
          return true;
        }
        break;
      }
      case 'days': {
        if (last.isSame(now, 'day') || last.isSame(now.subtract(1, 'day'), 'day')) {
          return true;
        }
        break;
      }
      default: {
        return false;
      }
    }
    return false;
  }

  // closeModal = () => this.setState({ showChartModal: null });

  handleDelete = async (kpi) => {
    const { deleteKpi, refreshKpis } = this.props;

    try {
      await deleteKpi(kpi);
      await refreshKpis();
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an error prevented the KPI from being deleted.' });
    }
  }

  handleAttributeUpdate = async (kpi, params) => {
    const { updateKpi } = this.props;

    try {
      await updateKpi(kpi, params);
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an error prevented the KPI from being saved.' });
    }
  }

  renderKpiDropdown = kpi => {
    const { currentUser, handleExport } = this.props;

    return (
      <DropdownMenu margin="m0" dropdownClass="right__close">
        {currentUser.can_edit &&
          <li>
            <a
              className="flex flex-center lh-36 text-gray hover hover-bg-wild-sand hover-text-blue"
              data-turbolinks={false}
              href={`/company/${kpi.company.slug}/kpis/${kpi.id}/edit`}
            >
              <i className="fa fa-pencil px2" />
              Edit
            </a>
          </li>
        }
        <li>
          <div
            className="flex flex-center lh-36 text-gray hover hover-bg-wild-sand hover-text-blue"
            onClick={() => handleExport(`/c/${kpi.company.slug}/kpis/${kpi.id}/export`)}
          >
            <i className="fa fa-file-excel-o px2" />
            Export
          </div>
        </li>
        {!kpi.predefined && currentUser.can_edit &&
          <li>
            <ConfirmationModal
              className="flex flex-center lh-36 text-gray hover hover-bg-wild-sand hover-text-blue"
              html='<div><i class="fa fa-trash-o px2"></i><span>Delete</span></div>'
              message={`<b>Deleting this KPI permanently erases all associated data.${kpi.source_for_derived_kpis ? '<br/><br/><i>This KPI is a source for other derived KPIs. Deleting this KPI will make these derived KPIs not being able to update anymore.</i>' : ''}</b><br/><br/> Are you sure you want to permanently delete this KPI?`}
              onClick={() => this.handleDelete(kpi)}
              confirmationText="Delete"
              cancelText="Cancel"
              disabled={kpi.source_for_derived_kpis}
              disabledBalloonText="Source for derived KPIs, cannot be deleted."
              disabledClassName="text-light-gray"
            />
          </li>
        }
      </DropdownMenu>
    );
  }

  render() {
    const { currentUser, kpi, edit, periodStepOptions, unitOptions, handleExport, updateSingleKpiLibraryId } = this.props;
    const { currentParam, showConfirmation } = this.state;

    const kpiUpToDate = this.upToDateCheck(kpi);
    const kpiDateFormat = this.periodStepFormat(kpi);

    const frequencyTypeOptions = {
      years: 'Yearly',
      quarters: 'Quarterly',
      months: 'Monthly',
      weeks: 'Weekly',
      days: 'Daily',
    };

    // Preview removed
    // const { showChartModal } = this.state;
    // const chartType = this.chartTypes.find(type => type.name === kpi.chart_data.type) || this.chartTypes[0];

    const disabledCondition = !currentUser.can_edit;
    const editLink = (currentUser.can_edit && !edit) ? `/company/${kpi.company.slug}/kpis/${kpi.id}/edit` : null;

    return (
      <Draggable draggableId={`kpi_${kpi.id}`} index={kpi.position - 1} disabled={disabledCondition}>
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div
              className="flex items-center border-bottom border-gallery py2 pr2 h5 bg-white hover--drag__handle hover hover-bg-wild-sand show-trash-on-hover show-excel-file-on-hover"
              style={{ minHeight: '68px' }}
            >
              {disabledCondition ? <span {...provided.dragHandleProps} className="ml3" /> : <span {...provided.dragHandleProps} className="drag__handle mx1 hover" />}
              <div className="flex col-5 sm-col-5 text-gray">
                <a
                  className="col-11 flex items-center text-gray"
                  data-turbolinks={false}
                  href={editLink}
                >
                  <div className="flex flex-column">
                    <div className="flex items-center">
                      {edit && currentUser.can_edit && !kpi.predefined ?
                        <TextInput
                          className="fw400 bg-none border-left-none border-right-none border-top-none border-bottom border-alto"
                          onBlur={(e) => this.handleAttributeUpdate(kpi, { metric: e.target.value })}
                          placeholder="Metric name"
                          value={kpi.metric}
                        />
                        :
                        <span className="fw400 h4 truncate">{kpi.metric}</span>
                      }
                      {kpi.description &&
                        <span
                          className="ml1"
                          data-balloon={kpi.description}
                          data-balloon-pos="right"
                          data-balloon-length="xlarge"
                        >
                          <a href={kpi.info_link} target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-info-circle text-light-gray h6" />
                          </a>
                        </span>
                      }
                    </div>
                    {kpi.predefined ?
                      <span className={`h6 lh-1 regular text-orange opacity-50 ${edit ? 'mt1' : ''}`}>Core KPI</span>
                      :
                      <span className={`h6 lh-1 regular text-green opacity-50 ${edit ? 'mt1' : ''}`}>Custom KPI {kpi.derived_kpi ? '(Derived)' : null}</span>
                    }
                  </div>
                </a>
              </div>
              <div className="flex col-2 text-gray sm-hide">
                {kpi.generic_kpi_metric ?
                  <div
                    className={`border-light-gray ${kpi.predefined ? '' : 'show-border-bottom-on-hover cursor-pointer'}`}
                    onClick={kpi.predefined ? null : () => updateSingleKpiLibraryId(kpi.id)}
                  >
                    {kpi.generic_kpi_metric}
                    {kpi.predefined &&
                      <span
                        className="ml1"
                        data-balloon="Please note that this is a core KPI of Investory and can not be edited, removed or mapped (linked) with any other KPI."
                        data-balloon-pos="down"
                      >
                        <i className="fa fa-question-circle text-blue" />
                      </span>
                    }
                  </div>
                  :
                  <Button
                    classNames="btn btn-outline btn-small border-alto bg-white text-gray sm-hide mr2"
                    onClick={() => updateSingleKpiLibraryId(kpi.id)}
                  >
                    <i className="fa fa-link h5" />
                    <span className="ml1 h5">Link to KPI Library</span>
                  </Button>
                }
              </div>
              <div className="flex col-2 text-gray sm-hide">
                <div className={`col-12 capitalize light mr2 flex ${edit && currentUser.can_edit ? 'flex-justify-end' : 'flex-justify-center'}`}>
                  <div className={`flex flex-column lh-12 ${edit && currentUser.can_edit ? 'col-10' : ''}`}>
                    {edit && currentUser.can_edit ?
                      <ReactSelect
                        value={periodStepOptions.filter(option => (option.value === kpi.period_step))}
                        options={periodStepOptions}
                        onChange={selectedOption => this.setState({ showConfirmation: true, currentParam: { period_step: selectedOption.value } })}
                        isClearable={false}
                        styles={defaultSelectStyles}
                        theme={defaultSelectTheme}
                        className="col-10 mb1"
                      />
                      :
                      <span>{frequencyTypeOptions[kpi.period_step]}</span>
                    }
                    {edit && currentUser.can_edit && !kpi.predefined && !kpi.generic_kpi_id ?
                      <ReactSelect
                        value={unitOptions.filter(option => (option.value === kpi.unit))}
                        options={unitOptions}
                        onChange={selectedOption => this.handleAttributeUpdate(kpi, { unit: selectedOption.value })}
                        isClearable={false}
                        styles={defaultSelectStyles}
                        theme={defaultSelectTheme}
                        className="col-10"
                      />
                      :
                      <span>{kpi.unit}</span>
                    }
                  </div>
                </div>
                <ConfirmationModal2
                  show={showConfirmation}
                  closeModal={() => this.setState({ showConfirmation: false, currentParam: null })}
                  title="KPI Frequency Change Warning"
                  text="Changing the KPI frequency will cause all data to be deleted. <br/><br/> <b>Are you sure?</b>"
                  cancelText="Cancel"
                  onSubmit={() => this.handleAttributeUpdate(kpi, currentParam)}
                />
              </div>
              <div className="flex col-2 text-gray light sm-hide">
                {kpi.active_source === 'sheets' &&
                  <div
                    className="content__hover hover"
                    data-hover={kpi.sheet_name}
                    data-active="Google Sheets"
                  />
                }
                {kpi.active_source === 'manual' && !kpi.derived_kpi &&
                  <div>Manual Input</div>
                }
                {kpi.active_source === 'manual' && kpi.derived_kpi &&
                  <div>Derived KPI</div>
                }
              </div>
              <div className="col-2 sm-col-6 light">
                {kpi.last_value ?
                  <div className={`flex ${kpiUpToDate ? 'text-dark-green' : 'text-light-gray'}`}>
                    <span
                      className="h5 mr1"
                      data-balloon={kpiUpToDate ? 'Last actual data is up to date.' : 'Your last actual data seems to be out of date (e.g either outdated or in the future).'}
                      data-balloon-pos="down"
                      data-balloon-length="large"
                    >
                      <i className={`${kpiUpToDate ? 'fa fa-check-circle' : 'fa fa-question-circle'}`} />
                    </span>
                    <div
                      className="col-12 content__hover hover"
                      data-hover={`Last Actual: ${kpi.last_value.readable_actual}`}
                      data-active={kpiDateFormat}
                    />
                  </div>
                  :
                  <div> - </div>
                }
              </div>
              {/* Preview won't be used anymore for now */}
              {/* <div className="col-1 sm-hide">
                <div
                  className="flex text-light-gray h5 mx1"
                >
                  <span
                    onClick={() => this.setState({ showChartModal: kpi.id })}
                    className="flex cursor-pointer"
                    data-balloon={`Preview as ${chartType.display} Chart`}
                    data-balloon-pos="down"
                    data-balloon-length="large"
                  >
                    <img
                      className="flex"
                      style={{ height: '18px', width: '18px' }}
                      src={chartType.icon}
                      alt="Type"
                    />
                  </span>
                </div>
                <KpiChartModal show={showChartModal === kpi.id} closeModal={this.closeModal} kpi={kpi} />
              </div> */}
              <div className="flex flex-justify-end sm-hide">
                {/* {this.renderKpiDropdown(kpi)} */}
                <div className="flex flex-column flex-justify-center items-center">
                  <div
                    className="cursor-pointer hover-text-blue"
                    data-balloon="Export KPI"
                    data-balloon-pos="right"
                    data-balloon-length="small"
                    onClick={() => handleExport(`/c/${kpi.company.slug}/kpis/${kpi.id}/export`)}
                  >
                    <i className="fa fa-file-excel-o opacity-0" />
                  </div>
                  {!kpi.predefined && currentUser.can_edit &&
                    <div
                      className="cursor-pointer"
                      data-balloon="Delete KPI"
                      data-balloon-pos="right"
                      data-balloon-length="small"
                    >
                      <ConfirmationModal
                        className="hover-text-red"
                        html={'<i class="fa fa-trash opacity-0" />'}
                        message={`<b>Deleting this KPI permanently erases all associated data.${kpi.source_for_derived_kpis ? '<br/><br/><i>This KPI is a source for other derived KPIs. Deleting this KPI will make these derived KPIs not being able to update anymore.</i>' : ''}</b><br/><br/> Are you sure you want to permanently delete this KPI?`}
                        onClick={() => this.handleDelete(kpi)}
                        confirmationText="Delete"
                        cancelText="Cancel"
                        disabled={kpi.source_for_derived_kpis}
                        disabledBalloonText="Source for derived KPIs, cannot be deleted."
                        disabledClassName="text-light-gray"
                      />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

export default KpiRow;
