import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import TextInput from '../TextInput';


class ToTemplateButton extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    updateId: PropTypes.number.isRequired,
  }

  state = {
    open: false,
    name: null,
    description: null,
  }

  createTemplate = () => {
    const { url, updateId } = this.props;
    const { name, description } = this.state;

    App.Api.post(url, { update_id: updateId, name, description }).then((data) => {
      this.setState({ open: false });
      store.dispatch({ type: 'ADD_ITEM', list: 'templates', value: data });
      store.dispatch({ type: 'SET_TAB', value: 'templates' });
    });
  }

  render() {
    const { name, open, description } = this.state;

    return (
      <li className="">
        <div
          className="cursor-pointer text-gray mx1"
          onClick={() => { this.setState({ open: true }); }}
        >
          <i className="fa fa-clipboard fa-fw mr1" /> Save as template
        </div>
        <Modal
          className="bg-white flex flex-column mb2 react_modal"
          show={open}
          onClickOutside={() => { this.setState({ open: false }); }}
        >
          <div
            className="flex flex-auto flex-justify-end cursor-pointer p1"
            onClick={() => { this.setState({ open: false }); }}
          >
            <i className="fa fa-close text-gray" />
          </div>
          <p className="col-12 center semi-bold py2 h2">Create template from Investor Update</p>
          <div className="col-12 flex flex-column flex-center sm-block bg-alabaster p3 sm-center">
            <TextInput
              className="col-12"
              onChange={e => { this.setState({ name: e.target.value }); }}
              placeholder="Name of the template"
              value={name}
              name="template[name]"
              id="name"
            />
            <TextInput
              className="col-12 mt2"
              onChange={e => { this.setState({ description: e.target.value }); }}
              placeholder="Short descripton of the template"
              value={description}
              name="template[description]"
              id="name"
            />
            <div className="bg-blue mt2 text-white semi-bold p1 px2 cursor-pointer" onClick={this.createTemplate}>
              Save as template
            </div>
          </div>
        </Modal>
      </li>

    );
  }
}

export default ToTemplateButton;
