import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const SorterHeader = ({
  sorting,
  text,
  field,
  hideIcon,
  kpi,
  truncate,
  textColor,
  width,
}) => {
  if (sorting.field === field && sorting.order === 'DESC') {
    return (
      <div
        className={`text-${textColor} fw400 cursor-pointer ${truncate ? 'truncate' : ''}`}
        style={{ width }}
        onClick={() => { store.dispatch({ type: 'TOGGLE_ORDER', value: {} }); }}
      >
        <span>{text}</span>
        <i className={`fa fa-caret-down ${sorting.order === 'DESC' ? 'ml1' : ''}`} />
      </div>
    );
  }
  if (sorting.field === field && sorting.order === 'ASC') {
    return (
      <div
        className={`text-${textColor} fw400 cursor-pointer ${truncate ? 'truncate' : ''}`}
        style={{ width }}
        onClick={() => { store.dispatch({ type: 'SET_SORTING', value: {} }); }}
      >
        <span>{text}</span>
        <i className={`fa fa-caret-up ${sorting.order === 'ASC' ? 'ml1' : ''}`} />
      </div>
    );
  }
  return (
    <div
      className={`text-${textColor} ${hideIcon ? 'show-sort-on-hover-margin' : 'show-sort-on-hover'} fw400 cursor-pointer`}
      style={{ width }}
      onClick={() => { store.dispatch({ type: 'SET_SORTING', value: { field, order: 'DESC', kpi } }); }}
    >
      <span>{text}</span>
      <span className={`h6 ${hideIcon ? 'w-0' : ''}`}>
        <i className={`fa fa-sort ${hideIcon ? 'w-0 opacity-0' : 'ml1'}`} />
      </span>
    </div>
  );
};

SorterHeader.propTypes = {
  hideIcon: PropTypes.bool,
  sorting: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  kpi: PropTypes.any,
  truncate: PropTypes.bool,
  textColor: PropTypes.string,
  width: PropTypes.string,
};

SorterHeader.defaultProps = {
  hideIcon: false,
  kpi: false,
  truncate: true,
  textColor: 'gray',
  width: 'auto',
};

const mapStateToProps = (store) => {
  let currentStore = store;
  if (store.tabView !== undefined) currentStore = store.tabView;
  return {
    sorting: currentStore.get('sorting').toJS(),
  };
};

export default connect(mapStateToProps)(SorterHeader);
