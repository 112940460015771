export const updateManagers = data => ({
  type: 'UPDATE_MANAGERS',
  managers: data.assigned_investment_managers_array,
  managers_for_options: data.investment_managers_for_options,
});

export const updateFunds = data => ({
  type: 'UPDATE_FUNDS',
  funds: data.assigned_funds_array,
  funds_for_options: data.funds_for_options,
});

export const updateOverallStatus = data => ({
  type: 'UPDATE_OVERAL_STATUS',
  status: data.state_of_company,
});

export const updateFundraiseStatus = data => ({
  type: 'UPDATE_FUNDRAISE_STATUS',
  fundraise_status: data.fundraise_status,
});

export const removeCover = () => ({
  type: 'REMOVE_COVER',
});
