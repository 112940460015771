import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import { defaultSelectStyles, defaultSelectTheme } from '../../../selects/SelectStyles';


class CumulativeOptions extends Component {
  static propTypes = {
    accumulateByOptions: PropTypes.array.isRequired,
    operationOptions: PropTypes.array.isRequired,
    accumulateOption: PropTypes.string,
    operationOption: PropTypes.string.isRequired,
    handleAccumulatorSelection: PropTypes.func.isRequired,
  }

  static defaultProps = {
    accumulateOption: null,
  }

  toBeDeleted = () => {
    return true;
  }

  render() {
    const {
      accumulateByOptions,
      operationOptions,
      accumulateOption,
      operationOption,
      handleAccumulatorSelection,
    } = this.props;

    return (
      // <div className="flex flex-column items-center flex-auto flex-justify-end h5">
      <div className="flex items-center h5 col-12">
        <div className="col-12 flex items-center mr2">
          <span className="mr1 text-gray">Accumulate by</span>
          <ReactSelect
            className="col-8"
            value={accumulateByOptions.filter(option => option.value === accumulateOption)}
            options={accumulateByOptions}
            isClearable
            onChange={selectedOption => handleAccumulatorSelection(selectedOption ? selectedOption.value : null, 'accumulator')}
            styles={defaultSelectStyles}
            theme={defaultSelectTheme}
          />
        </div>

        <div className="col-12 flex items-center">
          <span className="mr1 text-gray">Operation</span>
          <ReactSelect
            className="col-8"
            value={accumulateOption ? operationOptions.filter(option => option.value === operationOption) : null}
            options={accumulateOption ? operationOptions : []}
            onChange={selectedOption => handleAccumulatorSelection(selectedOption.value, 'operation')}
            noOptionsMessage={() => (accumulateOption ? null : 'Select an accumulator')}
            placeholder="Select an accumulator"
            styles={defaultSelectStyles}
            theme={defaultSelectTheme}
          />
        </div>
      </div>
    );
  }
}

export default CumulativeOptions;
