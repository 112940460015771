import React, { Component } from 'react';
import PropTypes from 'prop-types';


class CompaniesCaptablesTotal extends Component {
  static propTypes = {
    totalValues: PropTypes.object.isRequired,
    colorTheme: PropTypes.string,
    showIrr: PropTypes.bool,
  }

  static defaultProps = {
    colorTheme: null,
    showIrr: false,
  }

  componentDidMount() {
    const { colorTheme } = this.props;

    $(this.div)[0].style.setProperty('--investor-header-chart-values-color', colorTheme);
  }

  addCurrency = (val) => {
    const { totalValues } = this.props;
    if ((val === '') || (val === 'N/A') || (val === '0')) {
      return 'N/A';
    }
    return `${val} ${totalValues.currency}`;
  }

  currencyStyleColor = (val) => {
    if (val === 'N/A') {
      return 'text-alto';
    }
    return this.addCurrency(val) === 'N/A' ? 'text-alto' : 'investor-dashboard__chart-value-color';
  }

  render() {
    const { totalValues, showIrr } = this.props;

    return (
      <div className="col-12 flex hover-bg-wild-sand px1 text-gray flex flex-center bg-wild-sand border-top border-bottom border-alto bs-around py2">
        <div className="col-2">
          <div className="h5 ml1 text-gray fw400 flex flex-center">
            <div className="flex flex-column">
              <span>Total</span>
              <span className="h5">(converted to {totalValues.currency})</span>
            </div>
            <a
              data-balloon={`Based on foreign exchange reference rates from ${totalValues.rates_updated_at} provided by the european central bank. Click the info icon to check reference rates. Converted currency is changeable inside your user profile!`}
              data-balloon-pos="up"
              data-balloon-length="xlarge"
              href="https://www.ecb.europa.eu/stats/policy_and_exchange_rates/euro_reference_exchange_rates/html/index.en.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-info-circle text-light-gray ml1" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div
          className="col-10 flex flex-row h5"
          ref={(c) => { this.div = c; }}
        >
          <div className={`w-20p center z1 bold h5 ${this.currencyStyleColor(totalValues.total_ownership)} center`} />

          <div className={`w-20p center z1 bold h5 ${this.currencyStyleColor(totalValues.total_post_money_valuation)} center`}>
            {totalValues.total_post_money_valuation ? this.addCurrency(totalValues.total_post_money_valuation) : '-'}
          </div>

          <div className={`w-20p center z1 bold h5 ${this.currencyStyleColor(totalValues.total_invested)} center`}>
            {totalValues.total_invested ? this.addCurrency(totalValues.total_invested) : '-'}
          </div>

          <div className={`w-20p center z1 bold h5 ${this.currencyStyleColor(totalValues.total_realized)} center`}>
            {totalValues.total_realized ? this.addCurrency(totalValues.total_realized) : '-'}
          </div>

          <div className={`w-20p center z1 bold h5 ${this.currencyStyleColor(totalValues.total_value_of_shares)} center`}>
            {totalValues.total_value_of_shares ? this.addCurrency(totalValues.total_value_of_shares) : '-'}
          </div>

          <div className="w-20p center z1 bold h5 center" />
          {showIrr &&
            <div className="w-20p center z1 bold h5 center" />
          }
        </div>
      </div>
    );
  }
}

export default CompaniesCaptablesTotal;
