import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../../../LoadingSpinner';
import ExportSettings from '../../../../ExportSettings';
import UpgradeAccountModal from '../../../../modals/UpgradeAccountModal';

class InvestmentReports extends Component {
  static propTypes = {
    show: PropTypes.bool,
    investorId: PropTypes.number.isRequired,
    fundId: PropTypes.number,
    report: PropTypes.object,
    dateRangeOptions: PropTypes.object.isRequired,
    loadNewExportFile: PropTypes.func.isRequired,
    trackEvent: PropTypes.func,
    blurred: PropTypes.bool,
  }

  static defaultProps = {
    show: false,
    report: null,
    trackEvent: null,
    blurred: null,
    fundId: null,
  }

  state = {
    generatingReport: false,
    showUpgradeModal: false,
  }

  handleNewFileLoad = () => {
    const { loadNewExportFile } = this.props;

    // wait 10 seconds
    setTimeout(async () => {
      await loadNewExportFile();
      const { report } = this.props;

      if (report.data && report.data.file_id) {
        this.setState({ generatingReport: false });
      } else {
        this.handleNewFileLoad();
      }
    }, 10000);
  }

  submitFunc = async (params) => {
    const { investorId, trackEvent, fundId } = this.props;
    const { generatingReport } = this.state;

    if (generatingReport) {
      App.State.setFlash({ name: 'notice', msg: 'Your report will be ready soon.' });
      return;
    }

    this.setState({ generatingReport: true });

    try {
      await axios.get(`/investor/${investorId}/reports/investor_exports/excel`, { params: { ...params, fund_id: fundId } });
      if (trackEvent) await trackEvent('portfolio_report_export');
      App.State.setFlash({ name: 'notice', msg: 'Your report is being generated and will get ready soon.' });
      this.handleNewFileLoad();
    } catch (err) {
      this.setState({ generatingReport: false });
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
    }
  }

  render() {
    const {
      show,
      investorId,
      report,
      dateRangeOptions,
      blurred,
    } = this.props;

    const { generatingReport, showUpgradeModal } = this.state;

    return (show ?
      <React.Fragment>
        {!report.loading ?
          <>
            <div className="flex items-center h3">
              <p className="fw400 text-gray">Portfolio Report (Excel)</p>
              {generatingReport ?
                <div className="flex btn btn-outline fw400 y05 px05 text-gren mb2 ml2 h4">
                  <span className="mr2">Your report link will appear in here. This can take several minutes.</span>
                  <LoadingSpinner show={generatingReport} type="fit" background="transparent" height="25px" imgHeight="20px" imgWidth="20px" />
                </div>
                :
                (report.data.file_id &&
                  (blurred ?
                    <div
                      className="flex btn btn-outline fw400 y05 px05 text-white bg-green mb2 ml2 h4"
                      onClick={blurred ? () => this.setState({ showUpgradeModal: true }) : null}
                    >
                      Latest report ready! Click to download ({report.data.file_generated_on})
                    </div>
                    :
                    <a
                      className="flex btn btn-outline fw400 y05 px05 text-white bg-green mb2 ml2 h4"
                      href={`/investor/${investorId}/memberships/${report.data.membership_id}/data_room_files/${report.data.file_id}/download`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Latest report ready! Click to download ({report.data.file_generated_on})
                    </a>
                  )
                )
              }
            </div>
            <div className={blurred ? 'hide-data' : ''}>
              <ExportSettings
                investments={report.data.investments}
                sections={report.data.sections}
                submitFunc={(params) => this.submitFunc(params)}
                rangeOptions={dateRangeOptions}
                generatingReport={generatingReport}
              />
            </div>
          </>
          :
          <LoadingSpinner
            show={report.loading}
            type="fit"
            height="300px"
            background="transparent"
          />
        }
        <UpgradeAccountModal
          show={showUpgradeModal}
          closeModal={() => this.setState({ showUpgradeModal: false })}
          exportFeature
          sendEmailOnClick
          paywallDescription="Paywall_Export"
        />
      </React.Fragment> : null
    );
  }
}

export default InvestmentReports;
