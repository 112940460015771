const field = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_INVESTMENT_FIELDS':
      return action.fields.find(f => f.id === state.id) || state;

    case 'OVERWRITE_INVESTMENT_FIELDS':
      return action.fields.find(f => f.overwrite === state.id) || state;

    case 'RESET_INVESTMENT_FIELDS':
      if (action.ids.includes(state.id)) {
        return {
          ...state,
          id: state.overwrite,
          text: state.original,
        };
      }
      return state;

    default: return state;
  }
};

export default field;
