import { combineReducers } from 'redux';

import currentUser from './currentUser';
import sorting from './sorting';
import stripe from './stripe';

// Common
import kpisLibrary from './kpis_library';
import dataRoom from './data_room';
import dataRoomers from './data_roomers';
import comments from './comments';
import capTable from './capTable';
import dealRoom from './dealRoom';

// Company
import company from './company';
import companyReport from './companyReport';
import request from './request';
import kpis from './company/kpis';
import recipientStep from './company/shareholder_update/recipientStep';
import companyDataRooms from './company/company_data_rooms';

// Investor
import investor from './investor';
import investmentHeader from './investor/investment_header';
import investment from './investor/investment_profile';
import funding from './investor/investment_funding';
import investmentKpis from './investor/investment_kpis';
import investmentDataRooms from './investor/investment_data_rooms';
import investmentWidget from './investor/investment_widget';
import stream from './investor/stream';
import investmentUpdates from './investor/investment_updates';
import slideshow from './investor/slideshow';
import fundDashboard from './investor/fund_dashboard';
import investorHome from './investor/investor_home';
import elasticSearchResults from './investor/elastic_search_results';

import mixpanel from './mixpanel';


const rootReducer = combineReducers({
  currentUser,
  sorting,
  stripe,

  kpisLibrary,
  dataRoom,
  dataRoomers,
  comments,
  capTable,
  dealRoom,

  company,
  companyReport,
  request,
  kpis,
  recipientStep,
  companyDataRooms,

  investor,
  investmentHeader,
  investment,
  funding,
  investmentKpis,
  investmentDataRooms,
  investmentWidget,
  investmentUpdates,
  stream,
  slideshow,
  fundDashboard,
  investorHome,
  elasticSearchResults,

  mixpanel,
});

export default rootReducer;
