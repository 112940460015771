import React, { Component } from 'react';
import PropTypes from 'prop-types';

import KpiSelector from './KpiSelector';


class BigKpiWidget extends Component {
  static propTypes = {
    data: PropTypes.any.isRequired,
    col: PropTypes.string,
    padding: PropTypes.string,
    custom: PropTypes.string,
    children: PropTypes.node.isRequired,
  }

  static defaultProps ={
    col: '6',
    padding: 'px1',
    custom: '',
  }

  display = () => {
    const { data, col, padding, custom, children } = this.props;

    if (data.display) {
      return (
        <a data-turbolinks="false" className={`col-${col} ${padding} flex mb2 sm-col-12 text-gray`} href={data.url}>
          <div className={`bg-white bs-around col-12 p1 show-cog-on-hover flex relative ${custom} ${data.url ? 'card--has-hover' : ''}`}>
            <div className="">
              <div className="flex flex-center relative">
                <span className="bold h5 pr1">{data.title}</span>
                {
                  data.manageUrl &&
                  <KpiSelector
                    url={data.manageUrl}
                  />
                }
                {/* Info act like an extra info beside the KPI's metric name, e.g like derived KPIs definition in the investment side */}
                {children}
              </div>
              <p className="text-light-gray bold col-12 h6 m0 lh-14 pb2">
                {data.date}&nbsp;
              </p>
              <div className="bold">
                <p className="h1 m0">
                  {data.value}
                  <span className="h3 ml1 text-light-gray">{data.unit}</span>
                </p>
                <p className="text-light-blue h6 m0 lh-14">
                  {data.note}
                </p>
              </div>
            </div>
            {this.displayExtra()}
          </div>
        </a>
      );
    }
    return (
      <div className={`col-${col} flex mb2 ${padding} text-gray cursor-pointer`}>
        <div className="bg-white bs-around col-12 p1 card--has-hover show-cog-on-hover">
          <div className="flex flex-center relative pb3 text-light-gray">
            <span className="bold h5 pr1">Select a KPI</span>
            <KpiSelector
              url={data.manageUrl}
            />
          </div>
        </div>
      </div>
    );
  }

  displayExtra = () => {
    const { data } = this.props;
    if (data.extra) {
      return (
        <div className="flex flex-auto flex-justify-end">
          <div className="">
            <p className={`text-${data.extra.color} h3 m0 bold right-align`}>{data.extra.text}</p>
            <p className="text-light-gray h6 bold right-align lh-14 m0">{data.extra.note}</p>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      this.display()
    );
  }
}

export default BigKpiWidget;
