import React, { Component } from 'react';

import ListMenu from './ListMenu';


class TabWithMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuIsVisible: false,
      links: JSON.parse(props.links),
    };
  }

  hideMenu = (event) => {
    this.setState({
      menuIsVisible: false,
    });
  }

  listMenuItems = () => (
    this.state.links.map((link, i) =>
      <li key={i} className="list-menu__item">
        <a href={link.url}>{ link.name }</a>
      </li>,
    )
  )

  menu = () => {
    if (!this.state.menuIsVisible) return null;

    return (
      <ListMenu onClickOutSide={this.hideMenu}>
        { this.listMenuItems() }
      </ListMenu>
    );
  }

  showMenu = (event) => {
    this.setState({
      menuIsVisible: true,
    });
  }

  render() {
    const divStyle = {
      width: '100%',
      height: '100%',
      display: 'inline-block',
      verticalAlign: 'middle',
      padding: '15px 0',
    };

    const relativeStyle = {
      position: 'relative',
    };

    return (
      <div style={relativeStyle}>
        <div style={divStyle} onClick={this.showMenu}>
          MORE
          <i className="fa fa-ellipsis-v" />
        </div>
        { this.menu() }
      </div>
    );
  }
}

export default TabWithMenu;
