import { connect } from 'react-redux';
import ManageGenericKpis from '../components/kpis_library/ManageGenericKpis';

import {
  manageKpisLibrarySelection,
  changeFrequency,
  createKpisFromLibrary,
  editGenericKpi,
} from '../api/kpisLibraryApi';

const mapStateToProps = state => ({
  kpisLibrary: state.kpisLibrary,
  company: state.company,
});

const mapDispatchToProps = {
  manageKpisLibrarySelection,
  changeFrequency,
  createKpisFromLibrary,
  editGenericKpi,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageGenericKpis);
