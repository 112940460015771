import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
  Markers,
  Marker,
} from 'react-simple-maps';


class MapCover extends Component {
  static propTypes = {
    address: PropTypes.string.isRequired,
  }

  state = {
    coordinates: [25, 49],
  }

  componentWillMount() {
    const { address } = this.props;
    this.fetchCoordinates(address);
  }

  componentWillReceiveProps(nextProps) {
    const { address } = nextProps;
    this.fetchCoordinates(address);
  }

  fetchCoordinates = async (address) => {
    if (!address) return;

    try {
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBNTPc4dPxfsrt5_Zx5vJ-kaIdHJKMz7hY`);
      const { results } = response.data;
      const coordinates = results[0].geometry.location;
      this.setState({ coordinates: Object.values(coordinates).reverse() });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { address } = this.props;
    const { coordinates } = this.state;

    return (
      <div style={{ height: '' }}>
        <ComposableMap
          projectionConfig={{ scale: 2000, yOffset: -40 }}
          style={{
            width: '100%',
            height: '100px',
          }}
        >
          <ZoomableGroup center={coordinates} disablePanning>
            <Geographies geography="/static/world-110m.json">
              {(geographies, projection) =>
                geographies.map((geography, i) => (
                  <Geography
                    key={`geo_${Math.random()}`}
                    geography={geography}
                    projection={projection}
                    cacheId={`geography-${i}`}
                    style={{
                      default: {
                        fill: '#E5E5E5',
                        stroke: '#ffffff',
                        strokeWidth: 1.5,
                        outline: 'none',
                      },
                      hover: {
                        fill: '#E5E5E5',
                        stroke: '#ffffff',
                        strokeWidth: 1,
                        outline: 'none',
                      },
                      pressed: {
                        fill: '#E5E5E5',
                        stroke: '#ffffff',
                        strokeWidth: 1,
                        outline: 'none',
                      },
                    }}
                  />
                ))
              }
            </Geographies>
            {address &&
              <Markers>
                <Marker
                  marker={{ coordinates }}
                  style={{
                    default: { fill: '#2E75B5' },
                    hover: { fill: '#2E75B5' },
                    pressed: { fill: '#2E75B5' },
                  }}
                >
                  <circle
                    cx={0}
                    cy={0}
                    r={16}
                    style={{
                      fill: '#2E75B5',
                      stroke: '#ffffff',
                      strokeWidth: 6,
                      opacity: 1,
                    }}
                  />
                </Marker>
              </Markers>
            }
          </ZoomableGroup>
        </ComposableMap>
      </div>
    );
  }
}

export default MapCover;
