import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import ReactSelect from 'react-select';
import Dropzone from 'react-dropzone';

import DatePicker from '../selects/DatePicker';
import CheckBox from '../CheckBox';
import ConfirmationModal from '../ConfirmationModal';
import TextInputProps from '../TextInputProps';
import Textarea from '../Textarea';
import { defaultSelectStyles, defaultSelectTheme } from '../selects/SelectStyles';


class SetupRequest extends Component {
  static propTypes = {
    request: PropTypes.object.isRequired,
    saveRequest: PropTypes.func.isRequired,
    sendRequest: PropTypes.func.isRequired,
    completeRequest: PropTypes.func.isRequired,
    uploadRequestDocument: PropTypes.func.isRequired,
  }

  state = {
    name: '', /* for external signer */
    email: '', /* for external signer */
    userId: null, /* for existing signer */
    uploading: false,
    saving: false,
    deletingFileId: null,
    deletingSignerId: null,
    existingSignerField: true,
    externalSignerField: false,
    settingsTime: '',
    automatedReminders: true,
    dueDateReminders: false,
    firstReminderAfter: 1,
    secondReminderAfter: 1,
    reminderBeforeDueDate: 1,
    reminderAfterDueDate: 1,
  }

  chooseSigningType = (type) => {
    const { request, saveRequest } = this.props;
    const { existingSignerField, externalSignerField } = this.state;

    saveRequest(request.company.id, request.id, { signing_type: type }).then(
      () => {
        if (type === 'me_and_others' && !request.current_signer_id) {
          this.saveSigner('user_id', request.current_user.id);
        } else if (type === 'others' && request.current_signer_id) {
          this.deleteSigner(request.current_signer_id);
        } else if (type === 'archive') {
          this.updateRequest('due_date', null);
        }
        if (type !== 'archive') {
          if (!existingSignerField && !externalSignerField) this.setState({ existingSignerField: true });
        }
      },
      err => {
        App.State.setFlash({ name: 'alert', msg: err.responseJSON.error });
      },
    ).then(() => {
      if (type === 'me_and_others' || type === 'others') {
        this.updateFiles(request.signed_documents, false, true);
      } else if (type === 'archive') {
        this.updateFiles(request.documents_to_sign, true, false);
      }
    },
    err => {
      App.State.setFlash({ name: 'alert', msg: err.responseJSON.error });
    });
  }

  createSigner = (name, email) => {
    const { request, saveRequest } = this.props;
    const params = { signers_attributes: [{ id: null, name, email }] };
    this.setState({ saving: true });

    saveRequest(request.company.id, request.id, params).then(
      () => this.setState({ saving: false }),
      (err) => {
        this.setState({ saving: false });
        App.State.setFlash({ name: 'alert', msg: err.responseJSON.error });
        $('#email_field_signer_null').get(0).style.border = '1px solid red';
      },
    );
  }

  deleteDocument = (documentId) => {
    const { request, saveRequest } = this.props;
    const { deletingFileId } = this.state;

    if (deletingFileId !== null) return;

    this.setState({ deletingFileId: documentId });
    const params = { attached_files_attributes: [{ id: documentId, _destroy: '1' }] };

    saveRequest(request.company.id, request.id, params).then(
      () => this.setState({ deletingFileId: null }),
      (err) => {
        this.setState({ deletingFileId: null });
        App.State.setFlash({ name: 'alert', msg: err.responseJSON.error });
      },
    );
  }

  deleteSigner = (signerId = null) => {
    const { request, saveRequest } = this.props;
    const { deletingSignerId } = this.state;
    if (deletingSignerId !== null) return;

    this.setState({ deletingSignerId: signerId });
    const params = { signers_attributes: [{ id: signerId, _destroy: '1' }] };

    saveRequest(request.company.id, request.id, params).then(
      () => this.setState({ deletingSignerId: null }),
      err => {
        this.setState({ deletingSignerId: null });
        App.State.setFlash({ name: 'alert', msg: err.responseJSON.error });
      },
    );
  }

  handleSendButton = () => {
    const { request, sendRequest, completeRequest } = this.props;
    const {
      automatedReminders, dueDateReminders, firstReminderAfter,
      secondReminderAfter, reminderBeforeDueDate, reminderAfterDueDate,
    } = this.state;


    const hasSigners = request.signers.length > 0;
    const hasDocuments = request.documents_to_sign.length > 0 || request.signed_documents.length > 0;

    if (!request.signing_type_selected) {
      App.State.setFlash({ name: 'alert', msg: 'No Signing Type selected!' });
    } else if (!hasSigners) {
      App.State.setFlash({ name: 'alert', msg: 'No Signer added!' });
    } else if (!hasDocuments) {
      App.State.setFlash({ name: 'alert', msg: 'No Documents uploaded!' });
    } else if (request.type_archive) {
      completeRequest(request.company.id, request.id).catch((err) => {
        App.State.setFlash({ name: 'alert', msg: err.responseJSON.error });
      });
    } else {
      const params = {
        automatedReminders,
        dueDateReminders,
        firstReminderAfter,
        secondReminderAfter,
        reminderBeforeDueDate,
        reminderAfterDueDate,
      };
      sendRequest(request.company.id, request.id, params).catch((err) => {
        App.State.setFlash({ name: 'alert', msg: err.responseJSON.error });
      });
    }
  }

  saveSigner = (field, value, signerId = null) => {
    const { request, saveRequest } = this.props;
    const params = { signers_attributes: [{ id: signerId, [field]: value }] };
    this.setState({ saving: true });

    saveRequest(request.company.id, request.id, params).then(
      () => this.setState({ saving: false }),
      (err) => {
        this.setState({ saving: false });
        App.State.setFlash({ name: 'alert', msg: err.responseJSON.error });
      },
    );
  }

  updateFiles = (files, signed, signature_required) => {
    const { request, saveRequest } = this.props;
    const params = [];
    let i;

    this.setState({ saving: true });

    for (i = 0; i < files.length; i += 1) {
      params.push({ id: files[i].id, signed, signature_required });
    }

    saveRequest(request.company.id, request.id, { attached_files_attributes: params }).then(
      () => this.setState({ saving: false }),
      (err) => {
        this.setState({ saving: false });
        App.State.setFlash({ name: 'alert', msg: err.responseJSON.error });
      },
    );
  }

  updateRequest = (field, value) => {
    const { request, saveRequest } = this.props;
    const params = { [field]: value };
    this.setState({ saving: true });

    saveRequest(request.company.id, request.id, params).then(
      () => { this.setState({ saving: false }); },
      (err) => {
        this.setState({ saving: false });
        App.State.setFlash({ name: 'alert', msg: err.responseJSON.error });
      },
    );
  }

  uploadFiles = (files, signature_required = false, signed_document = false) => {
    const { request, uploadRequestDocument } = this.props;
    let i;

    this.setState({ uploading: true });

    const formData = new FormData();
    if ((signature_required || signed_document) && files.length > 0 && request.title === 'Untitled Signing') {
      formData.append('signing_room_request[title]', files[0].name.split('.')[0]);
    }

    for (i = 0; i < files.length; i += 1) {
      formData.append(`signing_room_request[attached_files_attributes][${i}][uid]`, uuidv4());
      formData.append(`signing_room_request[attached_files_attributes][${i}][attachment]`, files[i]);
      formData.append(`signing_room_request[attached_files_attributes][${i}][signature_required]`, signature_required);
      formData.append(`signing_room_request[attached_files_attributes][${i}][signed]`, signed_document);
    }

    uploadRequestDocument(request.company.id, request.id, formData).then(
      () => this.setState({ uploading: false }),
      err => {
        this.setState({ uploading: false });
        App.State.setFlash({ name: 'alert', msg: err.responseJSON.error });
      },
    );
  }

  uploadingGif = () => (
    <div className="p2 flex flex-justify-center">
      <img src={window.images.loading} alt="loading..." width="32" height="32" />
    </div>
  )

  /* method that validates the name and email fields */
  validateAndSaveExternalSigner = (event = null, signer = null, fields = null) => {
    const { name, email } = this.state;

    const innerName = signer ? fields.name : name;
    const innerEmail = signer ? fields.email : email;
    let valid = true;
    /* gives a red border or clears it based on validation */
    const validate = () => {
      const { request } = this.props;
      if (innerName.trim().length === 0) {
        $(`#name_field_signer_${signer ? signer.id : null}`).get(0).style.border = '1px solid red';
        ($(`#name_error_msg_signer_${signer ? signer.id : null}`).text("Looks like there's no name..."));
        valid = false;
      }

      if (innerEmail.trim().length === 0 && !request.type_archive) {
        $(`#email_field_signer_${signer ? signer.id : null}`).get(0).style.border = '1px solid red';
        ($(`#email_error_msg_signer_${signer ? signer.id : null}`).text("Looks like there's no email..."));
        valid = false;
      }

      if (innerName.trim().length > 0) {
        $(`#name_field_signer_${signer ? signer.id : null}`).get(0).style.border = '';
        ($(`#name_error_msg_signer_${signer ? signer.id : null}`).text(''));
      }

      if (innerEmail.trim().length > 0) {
        $(`#email_field_signer_${signer ? signer.id : null}`).get(0).style.border = '';
        ($(`#email_error_msg_signer_${signer ? signer.id : null}`).text(''));
      }
      return valid;
    };

    const save = () => {
      const { request } = this.props;
      /* for archive signing type, only name field is required */
      if (request.type_archive) {
        if (signer) {
          this.saveSigner('name', innerName, signer ? signer.id : null);
        } else {
          this.createSigner(innerName, null);
        }
        this.setState({ name: '', email: '' });
      } else {
        if (signer) {
          this.saveSigner('name', innerName, signer.id);
          this.saveSigner('email', innerEmail, signer.id);
        } else {
          this.createSigner(innerName, innerEmail);
        }
        this.setState({ name: '', email: '' });
      }
    };
    if (!event || (event && event.key === 'Enter')) {
      if (validate()) save();
    }
  }

  renderDocuments = (signature_required = false) => {
    const { request } = this.props;
    const { deletingFileId } = this.state;
    let documents = request.attached_documents;
    if (request.type_archive && signature_required) {
      documents = request.signed_documents;
    } else if (signature_required) {
      documents = request.documents_to_sign;
    }
    const render = documents.map((document) => {
      return (
        <div className="col-12 my1" key={`document_${document.id}`}>
          {deletingFileId === document.id ?
            this.uploadingGif()
            :
            <div className="flex flex-center flex-justify-start">
              <a className="flex flex-center" href={document.url} target="_blank" rel="noopener noreferrer">
                <span className="fa fa-2x">
                  <i className={`mr1 ${document.icon_class_name}`} style={{ color: document.icon_color }} />
                </span>
                <div className="flex flex-column pl1">
                  <span className="fw400 text-medium-editor lh-12">{document.name}</span>
                </div>
              </a>
              <div className="cursor-pointer ml1" onClick={() => this.deleteDocument(document.id)}>
                <i className="fa fa-close text-light-gray" />
              </div>
            </div>
          }
        </div>
      );
    });
    return (
      <div className={`flex flex-column items-center ${documents.length > 0 ? 'mx2 mb2' : ''}`}>
        {render}
      </div>
    );
  }

  renderDropzone = (signature_required = false) => {
    const { request } = this.props;
    return (
      <div className="col-12">
        <Dropzone
          className="border-alto flex flex-center cursor-pointer"
          style={{ height: '42px', borderWidth: '2px', borderStyle: 'dotted', borderRadius: '5px' }}
          onDrop={(files) => {
            request.type_archive && signature_required ?
            this.uploadFiles(files, false, true)
            :
            this.uploadFiles(files, signature_required)
          }}
          activeStyle={{ borderColor: '#2E75B5' }}
          multiple
        >
          <div className="px1 text-alto flex flex-center flex-auto flex-justify-between">
            <span className="h4 italic">Click or Drag & Drop Documents here...</span>
          </div>
        </Dropzone>
      </div>
    );
  }

  renderExistingSigner = (signer = null, removable = true) => {
    const { request } = this.props;
    const { userId } = this.state;

    return (
      <div className="flex mb1 col-12">
        <div className="col-6">
          <ReactSelect
            className="col-12"
            value={request.options_for_signers.filter(option => option.value === (signer ? signer.user_id : userId))}
            options={request.options_for_signers}
            isClearable={false}
            onChange={(selectedOption) => { signer ? this.saveSigner('user_id', selectedOption.value, signer.id) : this.setState({ userId: selectedOption.value }) }}
            styles={defaultSelectStyles}
            theme={defaultSelectTheme}
          />
        </div>
        {
          !request.type_archive &&
          <div className="col-6 ml2">
            <div
              className="border border-alto p1 rounded bg-wild-sand col-12"
              style={{ height: '40px' }}
            >
              <span className="text-gray">{signer ? signer.email : ''}</span>
            </div>
          </div>
        }
        {
          removable ?
            <div className="flex items-center ml2">
              <div className="cursor-pointer" onClick={() => this.deleteSigner(signer ? signer.id : null)}>
                <i className="h3 fa fa-close text-light-gray" />
              </div>
            </div>
            :
            <div className="pr3" />
        }
      </div>
    );
  }

  renderExternalSigner = (signer = null, removable = true) => {
    const { request } = this.props;
    const { name, email } = this.state;

    const fields = { name: signer ? signer.name : name, email: signer ? signer.email : email };
    const update = (field, value, event) => {
      fields[field] = value;
      this.validateAndSaveExternalSigner(event, signer, fields);
    };

    return (
      <div className="flex mb1 col-12">
        <div className="col-6">
          <TextInputProps
            className="border border-alto rounded lh-15 col-12"
            style={{ height: '40px' }}
            value={fields.name}
            placeholder="Name of external Signer"
            onBlur={e => { signer ? update('name', e.target.value) : null}}
            onChange={(e) => { signer ? null : this.setState({ name: e.target.value })}}
            onKeyPress={(e) => { signer ? null : update('name', e.target.value, e)}}
            id={`name_field_signer_${signer ? signer.id : null}`}
          />
          <p id={`name_error_msg_signer_${signer ? signer.id : null}`} className="flex flex-start text-red h5" />
        </div>
        {
          !request.type_archive &&
          <div className="col-6 ml2">
            <TextInputProps
              className="border border-alto rounded lh-15 col-12"
              style={{ height: '40px' }}
              value={fields.email}
              placeholder="Email of external Signer"
              onBlur={e => { signer ? update('email', e.target.value, null) : null}}
              onChange={(e) => { signer ? null : this.setState({ email: e.target.value })}}
              onKeyPress={(e) => { signer ? null : update('email', e.target.value, e)}}
              id={`email_field_signer_${signer ? signer.id : null}`}
            />
            <p id={`email_error_msg_signer_${signer ? signer.id : null}`} className="flex flex-start text-red h5" />
          </div>
        }
        {
          removable ?
            <div className="flex items-center ml2">
              <div className="cursor-pointer" onClick={() => this.deleteSigner(signer ? signer.id : null)}>
                <i className="h3 fa fa-close text-light-gray" />
              </div>
            </div>
            :
            <div className="pr3" />
        }
      </div>
    );
  }

  renderSigners = () => {
    const { request } = this.props;
    const { deletingSignerId } = this.state;
    const render = request.signers.map((signer) => (
      <div className="col-12 my2" key={`signer_${signer.id}`}>
        {deletingSignerId === signer.id ?
          this.uploadingGif()
          :
          (signer.user_id ? this.renderExistingSigner(signer) : this.renderExternalSigner(signer))
        }
      </div>
    ));
    return render;
  }

  renderSigningType = (type) => {
    const { request } = this.props;
    let icon = 'fa-user';
    if (type === 'me_and_others') icon = 'fa-users';
    if (type === 'archive') icon = 'fa-hourglass-end';
    return (
      <div
        onClick={() => this.chooseSigningType(type)}
        className={`border bs-around rounded p2 col-11 cursor-pointer ${request.signing_type === type ? 'border-blue' : 'border-alto'}`}
      >
        <div className="flex">
          <i className={`fa ${icon} fa-3x ${request.signing_type === type ? 'text-blue' : 'text-light-gray'}`} />
          {type === 'others' && <i className={`fa fa-user fa-3x ml1 ${request.signing_type === type ? 'text-blue' : 'text-light-gray'}`} />}
          <div className={`ml2 ${request.signing_type === type ? 'text-gray' : 'text-light-gray'}`}>
            {type === 'me_and_others' && <span>Get Signatures <br /> from Me & Others</span>}
            {type === 'others' && <span>Get Signatures <br /> from Others</span>}
            {type === 'archive' && <span>Archive already <br /> Signed Document(s)</span>}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { request } = this.props;
    const { uploading, saving, deletingFileId, deletingSignerId } = this.state;
    const { existingSignerField, externalSignerField } = this.state;
    const { automatedReminders, dueDateReminders } = this.state;
    const { firstReminderAfter, secondReminderAfter, reminderBeforeDueDate, reminderAfterDueDate } = this.state;
    const { userId } = this.state;
    const hasDocuments = request.documents_to_sign.length > 0 || request.signed_documents.length > 0;
    const hasSigners = request.signers.length > 0;
    let confirmationMessage = '';

    if (request.type_archive) {
      confirmationMessage = "<b>You are about to archive signed document(s).<br/>You won't be able to change these documents and signers won't be notified.</b><br>Are you sure you want to archive signed documents?";
    } else {
      confirmationMessage = "Requesting Signature(s) will notify all signers to sign the documents.<br/>Are you sure you want to send a request for signatures?<br/><br/><i><b>NOTE:</b> These settings can't be changed afterwards!</i>";
    }

    return (
      <div className="container container-report lg-px2 bg-white mt3 py3 my2 px3 bs-around relative sm-h-auto sm-mh-auto mb3">
        <div className="flex flex-column flex-justify-center px2 ">
          {/* render Choose Signing Type Section */}
          <div className="col-12 mb2">
            <div className="mb2 py1">
              <span className="fw400 h2">
                What do you want to do?
              </span>
            </div>
            <div className="flex flex-justify-between col-12">
              <div className="col-4">
                {this.renderSigningType('me_and_others')}
              </div>
              <div className="col-4 flex flex-justify-center">
                {this.renderSigningType('others')}
              </div>
              <div className="col-4 flex flex-justify-end">
                {this.renderSigningType('archive')}
              </div>
            </div>
          </div>

          {/* render Upload Documents Section */}
          { request.signing_type_selected &&
            <div className="col-12 py2">
              <div className="border border-lighter-gray">
                <div className="flex flex-column">
                  <div className="flex items-center p1 pl2 bg-wild-sand text-gray">
                    <i className="fa fa-upload mr1" />
                    <span className="fw400">
                      {!request.type_archive ? 'Upload Document(s) to be Signed' : 'Upload Signed Document(s)'}
                    </span>
                  </div>
                  <div className="p2">
                    {this.renderDocuments(true)}
                    {uploading ? this.uploadingGif() : this.renderDropzone(true)}
                  </div>
                </div>
              </div>
            </div>
          }

          {/* render Add Signers Section */}
          { request.signing_type_selected &&
            <div className="col-12 py2">
              <div className="border border-lighter-gray">
                <div className="flex flex-column">
                  <div className="flex items-center p1 pl2 mb2 bg-wild-sand text-gray">
                    <i className="fa fa-edit mr1" />
                    <span className="fw400">
                      {!request.type_archive ? 'Add Document Signers' : 'Log Document Signers'}
                    </span>
                  </div>
                  <div className="px2">
                    {
                      request.signers.length === 0 ?
                        <div className="mb2">
                          <span className="text-gray fw400">No Document Signers have been added yet!</span>
                        </div>
                        :
                        <span className="text-gray">Already Added</span>
                    }
                    {/* Render all Signers */}
                    {this.renderSigners()}

                    <div className="border-top border-alto py2 mb2">
                      <span className="text-gray">Add New Document Signer</span>
                      {/* Render new Signer Input */}
                      <div className={(existingSignerField || externalSignerField) && 'py2'}>
                        {existingSignerField && this.renderExistingSigner(null, false)}
                        {externalSignerField && this.renderExternalSigner(null, false)}
                      </div>
                      {
                        existingSignerField &&
                        <div
                          className="py1 px2 bg-light-gray rounded cursor-pointer text-white inline"
                          onClick={() => { this.saveSigner('user_id', userId); this.setState({ userId: null }); }}
                        >
                          <span className="fw400">Add Signer</span>
                        </div>
                      }
                      {
                        externalSignerField &&
                        <div
                          className="py1 px2 bg-light-gray rounded cursor-pointer text-white inline"
                          onClick={() => this.validateAndSaveExternalSigner()}
                        >
                          <span className="fw400">Add External Signer</span>
                        </div>
                      }
                      <div
                        className="cursor-pointer ml2 inline text-blue"
                        onClick={() => this.setState({ existingSignerField: !existingSignerField, externalSignerField: !externalSignerField })}
                      >
                        {existingSignerField ? 'Click to add External Signer' : 'Click to add Team/Investor Signer'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          {/* render Title & Message Section */}
          { request.signing_type_selected &&
            <div className="col-12 py2">
              <div className="border border-lighter-gray">
                <div className="flex flex-column">
                  <div className="flex items-center p1 pl2 mb2 bg-wild-sand text-gray">
                    <i className="fa fa-envelope mr1" />
                    <span className="fw400">
                      Title & Message
                    </span>
                  </div>
                  <div className="px2">
                    <div className="mb2">
                      <div className="mb1">
                        <span>Title</span>
                      </div>
                      <TextInputProps
                        className="border border-alto rounded lh-15 col-12"
                        value={request.title}
                        placeholder="Add a different title than the first document name..."
                        onBlur={e => this.updateRequest('title', e.target.value)}
                      />
                    </div>
                    <div className="mb2">
                      <div className="mb1">
                        <span>Message (optional)</span>
                      </div>
                      <Textarea
                        className="border border-alto rounded lh-15 col-12"
                        style={{ minHeight: '80px', resize: 'none' }}
                        value={request.message}
                        placeholder="Include a message to the signers..."
                        onBlur={e => this.updateRequest('message', e.target.value)}
                      />
                    </div>
                    <div className="mb2">
                      <div className="mb1">
                        <span>Attachments (not to be signed - optional)</span>
                      </div>
                      {this.renderDocuments()}

                      {uploading ? this.uploadingGif() : this.renderDropzone()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          {/* render Settings Section */}
          {
            request.signing_type_selected && request.state === 'draft' && !request.type_archive &&
            <div className="col-12 py2">
              <div className="border border-lighter-gray">
                <div className="flex flex-column">
                  <div className="flex items-center p1 pl2 mb2 bg-wild-sand text-gray">
                    <i className="fa fa-cog mr1" />
                    <span className="fw400">
                      Settings
                    </span>
                  </div>
                  <div className="px2">
                    <div className="flex flex-column">
                      <div className="mb3 flex flex-column col-4">
                        <span className="flex mb1">Due Date & Time (optional)</span>
                        <DatePicker
                          style={{ height: '38px' }}
                          className="border border-alto rounded lh-15"
                          value={request.due_date_unformated}
                          placeholder="yyyy-mm-dd"
                          onChange={e => this.updateRequest('due_date', e.target.value)}
                          invalidDates={false}
                          clearDateIfEmpty={!request.due_date_unformated}
                        />
                      </div>
                      <div className="flex flex-column">
                        {/* Automated Reminders */}
                        <div className="flex mb2">
                          <CheckBox
                            fixedWidth
                            defaultChecked={automatedReminders}
                            onChange={() => this.setState({ automatedReminders: !automatedReminders })}
                          />
                          <span className="ml2">Enable automated reminders</span>
                        </div>
                        <div className="flex mb2">
                          <div className="mr2 col-4">
                            <div className="mb1">
                              <span className="">Send First Reminder After</span>
                            </div>
                            <ReactSelect
                              value={request.first_options_for_automated_reminders.filter(option => option.value === firstReminderAfter)}
                              options={request.first_options_for_automated_reminders}
                              isClearable={false}
                              onChange={(selectedOption) => this.setState({ firstReminderAfter: selectedOption.value })}
                              styles={defaultSelectStyles}
                              theme={defaultSelectTheme}
                            />
                          </div>
                          <div className="col-4">
                            <div className="mb1">
                              <span className="">Send Second Reminder After</span>
                            </div>
                            <ReactSelect
                              value={request.second_options_for_automated_reminders.filter(option => option.value === secondReminderAfter)}
                              options={request.second_options_for_automated_reminders}
                              isClearable={false}
                              onChange={(selectedOption) => this.setState({ secondReminderAfter: selectedOption.value })}
                              styles={defaultSelectStyles}
                              theme={defaultSelectTheme}
                            />
                          </div>
                        </div>

                        {/* Due Date Reminders */}
                        {
                          request.due_date &&
                          <div className="">
                            <div className="flex mb2">
                              <CheckBox
                                fixedWidth
                                defaultChecked={dueDateReminders}
                                onChange={() => this.setState({ dueDateReminders: !dueDateReminders })}
                              />
                              <span className="ml2">Enable due date reminders</span>
                            </div>
                            <div className="flex">
                              <div className="mr2 col-4">
                                <div className="mb1">
                                  <span className="">Send Reminder Before Due Date</span>
                                </div>
                                <ReactSelect
                                  value={request.first_options_for_automated_reminders.filter(option => option.value === reminderBeforeDueDate)}
                                  options={request.first_options_for_automated_reminders}
                                  isClearable={false}
                                  onChange={(selectedOption) => this.setState({ reminderBeforeDueDate: selectedOption.value })}
                                  styles={defaultSelectStyles}
                                  theme={defaultSelectTheme}
                                />
                              </div>
                              <div className="mb2 col-4">
                                <div className="mb1">
                                  <span className="">Send Reminder After Due Date</span>
                                </div>
                                <ReactSelect
                                  value={request.first_options_for_automated_reminders.filter(option => option.value === reminderAfterDueDate)}
                                  options={request.first_options_for_automated_reminders}
                                  isClearable={false}
                                  onChange={(selectedOption) => this.setState({ reminderAfterDueDate: selectedOption.value })}
                                  styles={defaultSelectStyles}
                                  theme={defaultSelectTheme}
                                />
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        {/* footer */}
        { request.signing_type_selected &&
          <div className="mb2 mx2">
            <div className="flex flex-justify-end items-center pb1 flex-end">
              <span className="text-light-gray fw400 lh-1 mr2">
                {(saving || uploading || deletingFileId || deletingSignerId) ? 'Saving...' : 'Auto Saved'}
                <i className="fa fa-cloud-upload ml1" />
              </span>
              <ConfirmationModal
                className=""
                onClick={() => this.handleSendButton()}
                html={`<span class="fw400">${request.type_archive ? 'Archive Document(s)' : 'Request Signature(s)'}</span>`}
                hmtlClassName={`py1 px2 text-white rounded cursor-pointer ${request.type_archive ? 'bg-green' : 'bg-blue'}`}
                message={confirmationMessage}
                confirmationText={request.type_archive ? 'Archive Document(s)' : 'Request'}
                cancelText="Cancel"
                disabled={!hasSigners || !hasDocuments}
                disabledClassName="py1 px2 text-white rounded cursor-default bg-light-gray"
                width="auto"
              />
            </div>
          </div>
        }
      </div>
    );
  }
}

export default SetupRequest;
