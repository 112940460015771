import React, { Component } from 'react';
import PropTypes from 'prop-types';


class CompanyRowAdmin extends Component {
  static propTypes = {
    manageUrl: PropTypes.string.isRequired,
    resendUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    resendable: PropTypes.bool.isRequired,
    updateTopState: PropTypes.func.isRequired,
  }

  state = {
    open: false,
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
    document.removeEventListener('touchstart', this.handleClick, false);
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
    document.addEventListener('touchstart', this.handleClick, false);
  }

  deleteMember = (e) => {
    const { name, manageUrl, updateTopState } = this.props;
    e.preventDefault();
    const confrimed = confirm(`Are your sure to remove ${name} from your Portfolio?`);
    if (confrimed) {
      App.Api.delete(manageUrl).then((data) => {
        updateTopState(data);
        App.State.setFlash({ name: 'notice', msg: 'Investment removed!' });
      });
    }
  }

  handleClick = (e) => {
    if (ReactDOM.findDOMNode(this).contains(e.target)) return;
    this.setState({ open: false });
  }

  resend = (e) => {
    const { resendUrl } = this.props;
    e.preventDefault();
    App.Api.patch(resendUrl).then(() => {
      App.State.setFlash({ name: 'notice', msg: 'Notification email sent again!' });
    });
  }

  resendButton = () => {
    const { resendable } = this.props;
    if (resendable) {
      return (
        <li>
          <div
            className="bold h5 link-hover--blue mb1 block nowrap px1 text-gray cursor-pointer"
            onClick={this.resend}
          >
            <i className="fa fa-fw fa-envelope fa--dropdown ml1 right" />
            Resend Invitation
          </div>
        </li>
      );
    }
    return null;
  }

  render() {
    const { open } = this.state;

    return (
      <div className="">
        <div
          className="text-light-gray"
          onClick={e => { e.preventDefault(); this.setState({ open: !open }); }}
        >
          <i className="fa fa-cog" />
        </div>

        <div className={`dropdown dropdown--center absolute bg-white border border-alto bs-dropdown left-50 transform-horizontal-center z1 ${open ? '' : 'hidden'} py1`}>
          <ul className="left-align m0 mw200">
            {this.resendButton()}
            <li>
              <div
                className="bold h5 link-hover--blue block nowrap px1 text-gray cursor-pointer"
                onClick={this.deleteMember}
              >
                <i className="fa fa-fw fa-trash fa--dropdown ml1 right" />
                Remove from Portfolio
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default CompanyRowAdmin;
