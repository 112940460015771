import React from 'react';
import PropTypes from 'prop-types';

const LargeButton = props => {
  const { active, faIcon } = props;
  const butAdditionalStyle = active ? 'text-gray bg-gallery border rounded border-alto fw400 bs-around' : 'text-gray border rounded border-alto bs-around hover hover-border-medium-gray hover-text-gray';

  return (
    <div className="slideshow-large-button-wrapper">
      <div
        className={`slideshow-large-button ${butAdditionalStyle}`}
        onClick={() => { props.onClick(props.labelText) }}
      >
        <div className="slideshow-large-button-label flex items-center sm-px1 py1 sm-h6 center lh-12">
          <i className={`fa ${faIcon} mr1`} />
          <span>{props.labelText}</span>
        </div>
      </div>
    </div>
  );
}

LargeButton.propTypes = {
  labelText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  faIcon: PropTypes.string,
};

LargeButton.defaultProps = {
  active: false,
}

export default LargeButton;
