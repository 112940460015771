import React from 'react';

import SorterHeader from '../SorterHeader';


const UpdateRecipientHeader = () => (
  <div className="col-12 text-gray h5 flex p2 bg-extra-light-gray">
    <div className="col-3 sm-col-7 bold pl2">
      <SorterHeader text="Name" field="name" />
    </div>
    <div className="col-2 sm-hide text-light-gray truncate">
      <SorterHeader text="Type" field="type" />
    </div>
    <div className="col-3 sm-hide truncate">
      <SorterHeader text="Email" field="email" />
    </div>
    <div className="col-1 center sm-hide">
      <SorterHeader text="Delivered" field="delivered" />
    </div>
    <div className="col-1 center sm-col-2">
      <SorterHeader text="Read" field="read" />
    </div>
    <div className="col-1 center sm-hide">
      <SorterHeader text="Read in APP" field="read_in_app" />
    </div>
    <div className="col-1 center sm-col-2">
      <SorterHeader text="Bounced" field="bounced" />
    </div>
  </div>
);

export default UpdateRecipientHeader;
