import moment from 'moment';
import xirr from 'xirr';
import { Finance } from 'financejs';
import NumeralWrapper from './NumeralWrapper';

const sum = (dataValues, field) => {
  if (dataValues.every(d => d[field] === null || d[field] === undefined)) return null;

  const value = dataValues.reduce((acc, val) => acc + ((val[field] && parseFloat(val[field])) || 0.0), 0);
  return parseFloat(value);
};

const avg = (dataValues, field) => {
  const value = sum(dataValues, field);

  // get only the existing values (i.e allowed values - sometimes the company can block access to cap table for example)
  const totalElements = dataValues.filter(dataVal => dataVal[field] !== null).length;
  if (totalElements > 0) {
    return parseFloat((value || 0.0) / totalElements);
  }
  return 0.0;
};

const kpisSum = (dashboardKPisData, kpiMetric) => {
  const arrayOfKpis = [...dashboardKPisData].map(el => el.kpis);

  const filteredKpis = arrayOfKpis.map(array => array.find(kpi => (kpi.metric ? kpi.metric.localeCompare(kpiMetric, undefined, { sensitivity: 'accent' }) === 0 : undefined)));

  let onlyNullValues = true;
  const value = filteredKpis.reduce((acc, kpi) => {
    const val = kpi.kpi_unit === 'currency' ? kpi.actual_converted_value : kpi.actual_raw_value;

    if (val) onlyNullValues = false;

    return acc + (val || 0);
  }, 0);

  const result = onlyNullValues ? '' : parseFloat(value).toFixed(2);
  return NumeralWrapper(result) || '';
};

const kpisMedian = (dashboardKPisData, kpiMetric) => {
  const arrayOfKpis = [...dashboardKPisData].map(el => el.kpis);

  const filteredKpis = arrayOfKpis.map(array => array.find(kpi => (kpi.metric ? kpi.metric.localeCompare(kpiMetric, undefined, { sensitivity: 'accent' }) === 0 : undefined)));

  const values = filteredKpis.map(kpi => (
    kpi.kpi_unit === 'currency' ? kpi.actual_converted_value : kpi.actual_raw_value
  ));

  if (values.every(element => element === null || element === undefined)) return '';

  const sorted = values.filter(val => val).sort();
  const len = sorted.length;
  const result = (sorted[Math.trunc((len - 1) / 2)] + sorted[Math.trunc(len / 2)]) / 2.0;

  return NumeralWrapper(result) || '';
};

const investmentMultipleCalculation = (multipleFormula, data) => {
  let totalValueOfShares;
  let totalInvested;
  let totalRealized;
  let totalPostMoneyValuation;
  let totalPreMoneyValuation;
  let totalOwnership;

  switch (multipleFormula) {
    case 'pre_money':
      totalPreMoneyValuation = sum(data, 'pre_money_converted');
      totalValueOfShares = sum(data, 'current_share_value_converted');

      if (totalValueOfShares === null) return null;

      if (totalPreMoneyValuation === null) return null;
      if (totalPreMoneyValuation == 0) return 0.00;
      return (totalValueOfShares / totalPreMoneyValuation).toFixed(2);
    case 'post_money':
      totalPostMoneyValuation = sum(data, 'current_valuation_converted');
      totalValueOfShares = sum(data, 'current_share_value_converted');

      if (totalValueOfShares === null) return null;

      if (totalPostMoneyValuation === null) return null;
      if (totalPostMoneyValuation == 0) return 0.00;
      return (totalValueOfShares / totalPostMoneyValuation).toFixed(2);
    case 'custom_1':
      totalInvested = sum(data, 'invested_converted');
      totalPostMoneyValuation = sum(data, 'current_valuation_converted');
      totalOwnership = sum(data, 'raw_ownership');

      if (totalInvested === null) return null;
      if (totalInvested == 0) return 0.00;
      return (totalPostMoneyValuation * totalOwnership / totalInvested).toFixed(2);
    case 'custom_2':
      return avg(data, 'investment_multiple_raw');
    case 'custom_3':
      return avg(data, 'investment_multiple_raw');
    default:
      totalInvested = sum(data, 'invested_converted');
      totalRealized = sum(data, 'realized_converted');
      totalValueOfShares = sum(data, 'current_share_value_converted');

      if (totalValueOfShares === null) return null;

      if (totalInvested === null) return null;
      if (totalInvested == 0) return 0.00;

      return ((totalValueOfShares + totalRealized) / totalInvested).toFixed(2);
  }
};

const xirrFailSafe = (values, dates) => {
  try {
    console.log('trying xirr failsafe');
    const finance = new Finance();
    const rates = finance.XIRR(values, dates, 0);
    if (Number.isNaN(rates)) return 'N/A';

    return rates.toFixed(1);
  } catch (e) {
    console.log('failsafe xirr error: ', e.message);
    return 'N/A';
  }
};

const calculateXIRR = (values = [], dates = [], currentSharesValue) => {
  if (values === null && dates === null) return undefined;
  if (values.length === 0 && dates.length === 0) return 'N/A';

  const finalValues = [...values.map(val => parseFloat(val)), parseFloat(currentSharesValue || 0.0)];
  const finalDates = [...dates.map(el => moment(el).toDate()), new Date()];

  if (!(finalValues.some(el => el > 0) && finalValues.some(el => el < 0))) return 'N/A';

  try {
    const flow = finalValues.map((val, index) => ({ amount: val, when: finalDates[index] }));

    const rate = xirr(flow);

    if (Number.isNaN(rate)) return xirrFailSafe(finalValues, finalDates);
    return String((rate * 100).toFixed(1));
  } catch (e) {
    if (e.message === 'Transactions must not all be nonnegative.') {
      return 'N/A';
    }
    console.log('xirr error: ', e.message);
    return xirrFailSafe(finalValues, finalDates);
  }
};

// for now, there's only the default type
const multipleText = (multipleFormula) => {
  switch (multipleFormula) {
    case 'pre_money':
      return 'The portfolio multiple is calculated by dividing the total value of shares by the total pre money valuation for each company.';
    case 'post_money':
      return 'The portfolio multiple is calculated by dividing the total value of shares by the total post money valuation for each company.';
    case 'custom_1':
      return 'The portfolio multiple is calculated by post-Money valuation (latest round) * % total ownership) /  total invested.';
    case 'custom_2':
      return 'The portfolio multiple is calculated by post-money valuation (latest round) / post-money valuation (in round where investor first appeared).';
    case 'custom_3':
      return 'The portfolio multiple is calculated by pre-money valuation (latest round) / pre-money valuation (in round where investor first appeared).';
    default:
      return 'The portfolio multiple is calculated by dividing the total value of shares plus the total realized cashflow by the total amount invested for each company.';
  }
};

const search = (dashboard, searchQuery, items) => {
  return items.filter(item => {
    if (!item.name) return true;

    if (dashboard && dashboard.filterOptions && dashboard.filterOptions.investment_options && dashboard.filterOptions.investment_options.length > 0) {
      const investmentOptions = dashboard.filterOptions.investment_options;
      for (let index = 0; index < investmentOptions.length; index += 1) {
        if (investmentOptions[index].name && investmentOptions[index].name === item.name && investmentOptions[index].selected === true && item.name.toLowerCase().includes(searchQuery.toLowerCase())) return true;
      }
    } else return item.name.toLowerCase().includes(searchQuery.toLowerCase());
  });
};

export {
  sum,
  avg,
  kpisMedian,
  kpisSum,
  investmentMultipleCalculation,
  calculateXIRR,
  multipleText,
  search,
};
