import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import axios from 'axios';

import Modal from '../Modal';
import HtmlComponent from '../tab_view/HtmlComponent';
import TextInput from '../TextInput';
import Textarea from '../Textarea';

import { defaultSelectStyles, defaultSelectTheme } from '../selects/SelectStyles';
import CheckBox from '../CheckBox';
import ConfirmationModal from '../modals/ConfirmationModal';

class InvestorInvite extends Component {
  static propTypes = {
    show: PropTypes.bool,
    company: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    html: PropTypes.string.isRequired,
    isDealRoomInvite: PropTypes.bool,
    suggestedInvites: PropTypes.string,
  }

  static defaultProps = {
    show: false,
    isDealRoomInvite: false,
    suggestedInvites: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      emails: props.suggestedInvites || '',
      optionValue: props.isDealRoomInvite ? 'deal_room' : 'all',
      showCustomMessage: true,
      subject: props.isDealRoomInvite ? `Details about ${props.company.name} latest fundraising round` : `${props.company.name} invites you to read their latest updates on investory.io`,
      personalMessage: props.isDealRoomInvite ? 'I would like to share our deal room with you, which is a secure space that contains all information about our current fundraising round.' : 'I would like to invite you to join us on investory.io. In the future you will find our regular updates, latest developments & numbers on your personal dashboard on investory.',
      infoAboutInvestory: true,
      inviting: null,
      confirming: null,
      openConfirmationModal: false,
      dataForConfirmation: null,
      heightForConfirmation: 'auto'
    };
  }

  componentDidMount() {
    const { suggestedInvites } = this.props;
    const { show } = this.state;

    if (!show && suggestedInvites) {
      setTimeout(() => { this.openModal(); }, 1000);
    }
  }

  openModal = () => {
    this.setState({ show: true });
  }

  closeModal = () => {
    const { inviting } = this.state;

    if (inviting) return;

    this.setState({ show: false });
  }

  submit = (testEmail = false) => {
    const { currentUser, company } = this.props;
    const { inviting, emails, subject, personalMessage, infoAboutInvestory, optionValue } = this.state;

    if (inviting) return;

    this.setState({ inviting: testEmail ? 'test_invite' : 'default_invite' }, async () => {
      const params = {
        type: 'investor',
        emails,
        other: company.id,
        company_id: company.id,
        email_subject: subject,
        email_message: personalMessage,
        personal_message: personalMessage,
        email_has_company_logo: true,
        email_has_short_introduction: infoAboutInvestory,
        permissions: optionValue,
      };

      if (testEmail) {
        params.send_test_submit = testEmail;
      }

      const res = await axios.post(`/users/${currentUser.id}/invite`, params);
      if (res.data.redirect_to) {
        window.location = res.data.redirect_to;
      }
    });
  }

  submitCheck = () => {
    const { currentUser } = this.props;
    const { emails, confirming } = this.state;

    if (confirming) return;

    this.setState({ confirming: true }, async () => {
      const emailsNoSpace = emails.replace(/\s/g, '');
      if (emailsNoSpace) {
        const url = `/users/${currentUser.id}/invites/check_investors_emails?emails=${emailsNoSpace.split(',')}`;
        try {
          const res = await axios.get(url);
          if (res.data.for_confirmation) {
            if (emailsNoSpace.split(',').length === 1) {
              const message = res.data.for_confirmation[0].message;
              this.setState({ openConfirmationModal: true, dataForConfirmation: message, confirming: false, heightForConfirmation: 'auto' });
            } else {
              const message = res.data.for_confirmation.reduce((acc, obj) => `${acc}<b>${obj.email}</b> => ${obj.message} </br></br>`, '');
              this.setState({ openConfirmationModal: true, dataForConfirmation: message, confirming: false, heightForConfirmation: res.data.for_confirmation.length >= 2 ? '250px' : '200px' });
            }
          } else {
            this.submit();
          }
        } catch (err) {
          this.setState({ confirming: false }, () => {
            App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an error happened.' });
          });
        }
      } else {
        this.setState({ confirming: false }, () => {
          App.State.setFlash({ name: 'alert', msg: 'No email added.' });
        });
      }
    });
  }

  render() {
    const { html, isDealRoomInvite, currentUser } = this.props;
    const {
      show, emails, optionValue, showCustomMessage, subject,
      personalMessage,infoAboutInvestory, inviting, openConfirmationModal,
      dataForConfirmation, heightForConfirmation } = this.state;

    let modalHeader,
      modalText,
      options;

    if (isDealRoomInvite) {
      modalHeader = 'Share your Deal Room!';
      modalText = 'Share access to your deal room by via their email addresses below. Each email will receive a personal invite notification.';
      options = [{ value: 'deal_room', label: 'Deal Room Only' }];
    } else {
      modalHeader = 'Invite your Investors!';
      modalText = 'Invite your investors by adding their email addresses below. Each email will receive a personal invite notification.';
      options = [{ value: 'all', label: 'All Access' }, { value: 'updates', label: 'Updates Only' }];
    }

    return (
      <>
        <div className="cursor-pointer" onClick={this.openModal}>
          <HtmlComponent data={{ html }} />
        </div>

        <Modal
          className="bg-white flex flex-column mb2 react_modal border-top border-blue bw-4"
          show={show}
        >
          <div className="flex flex-column">
            <div className="flex flex-justify-between items-center p2 border-bottom border-lighter-gray">
              <div className="h3 text-gray fw400">{modalHeader}</div>
              <i className="fa fa-close h5 cursor-pointer" onClick={this.closeModal} />
            </div>
            <div className="m2">
              <p className="mt1" style={{ color: '#9B9B9B' }}>
                {modalText}
              </p>
              <div className="col-12 flex pb1">
                <div className="flex flex-column col-8">
                  <span>Email</span>
                  <TextInput
                    value={emails}
                    placeholder="For example one@test.com, two@test.com"
                    className="border border-lighter-gray h5 col-12"
                    onChange={(e) => this.setState({ emails: e.target.value })}
                  />
                </div>
                <div className="col-4 flex flex-column ml2">
                  <span>Set Initial Access To</span>
                  <ReactSelect
                    value={options.filter(option => option.value === optionValue)}
                    options={options}
                    onChange={(selectedOption) => this.setState({ optionValue: selectedOption.value })}
                    styles={defaultSelectStyles}
                    theme={defaultSelectTheme}
                    className="h5"
                  />
                </div>
              </div>
              <div className="bg-wild-sand mt2 mxn2">
                <div
                  className="bg-wild-sand cursor-pointer ml2 pt2 pb2"
                  onClick={() => this.setState({ showCustomMessage: !showCustomMessage })}
                >
                  <i className={`fa fa-caret-${showCustomMessage ? 'down' : 'right'}`} />
                  <span className="ml1">Customize Invitation</span>
                </div>
                {showCustomMessage &&
                  <>
                    <div className="ml3">
                      <span>Subject</span>
                      <TextInput
                        value={subject}
                        className="border border-lighter-gray col-11"
                        onChange={(e) => this.setState({ subject: e.target.value })}
                      />
                    </div>
                    <div className="ml3 mt2">
                      <div>Personal message <span className="text-orange"><i>(recommended)</i></span></div>
                      <Textarea
                        value={personalMessage}
                        className="border border-lighter-gray col-11"
                        style={{ minHeight: '45px', resize: 'vertical' }}
                        onChange={(e) => this.setState({ personalMessage: e.target.value })}
                      />
                    </div>
                    <div className="ml3 mt2 pb2 flex">
                      <CheckBox
                        checked={infoAboutInvestory}
                        onChange={() => this.setState({ infoAboutInvestory: !infoAboutInvestory })}
                        manualCheck
                        fixedWidth
                        size="1rem"
                      />
                      <span className="ml1">Include information about investory.io</span>
                    </div>
                  </>
                }
              </div>
              <div className="flex items-center flex-justify-between border-top border-bottom border-lighter-gray pt1 pb1 mt2 mxn2 h5">
                <div className="text-light-gray ml2">Test email recipient: <span className="text-gray">{currentUser.email}</span></div>
                <div
                  className="border rounded border-orange py05 px2 mr2 cursor-pointer"
                  onClick={() => this.submit(true)}
                >
                  <span className="text-orange">{inviting === 'test_invite' ? 'Sending...' : 'Send Test Email'}</span>
                </div>
              </div>

              <div className="mt2 flex items-center flex-justify-end">
                <div
                  className="text-blue mr2 cursor-pointer"
                  onClick={this.closeModal}
                >
                  Cancel
                </div>
                <div
                  className={`rounded py05 px2 cursor-pointer ${inviting === 'default_invite' ? 'bg-green' : 'bg-blue'}`}
                  onClick={() => this.submitCheck()}
                >
                  <span className="text-white">{inviting === 'default_invite' ? 'Sending...' : 'Send Invitation'}</span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <ConfirmationModal
          show={openConfirmationModal}
          closeModal={() => this.setState({ openConfirmationModal: false, dataForConfirmation: null, confirming: false })}
          title="Confirm Investors"
          text={`${dataForConfirmation} </br></br> <b>Are you sure?</b>`}
          onSubmit={() => this.submit()}
          cancelText="Cancel"
          height={heightForConfirmation}
        />
      </>
    );
  }
}

export default InvestorInvite;
