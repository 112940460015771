import React, { Component } from 'react';
import PropTypes from 'prop-types';


class CapPieChartWidget extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { data } = this.props;
    if (data.has_access) window.createPie('pie_chart', data.table, false);
  }

  componentDidUpdate() {
    const { data } = this.props;
    if (data.has_access) window.createPie('pie_chart', data.table, false);
  }

  render() {
    const { data } = this.props;

    if (!data.has_access) {
      return (
        <div className="col-3 sm-col-12 px1 p1 flex">
          <div className="bs-around bg-white p1">
            <div className="text-gray bold flex mb2">Captable</div>
            <div className="flex col-12 center flex-column text-medium-gray">
              Access to the Captable has been disabled.
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="col-3 px1 p1 sm-col-12">
        <div className="bs-around bg-white p1">
          <a href={data.captable_url}>
            <div className="text-gray bold">Captable - {data.name}</div>
          </a>
          <div id="pie_chart" />
          {data.valuation &&
            <div className="center">
              <div className="h3">Current valuation:</div>
              <div className="bold">{data.amount} {data.currency}</div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default CapPieChartWidget;
