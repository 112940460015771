import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { Draggable } from 'react-beautiful-dnd';

import Avatar from '../tab_view/Avatar';
import ConfirmationModal from '../ConfirmationModal';

import CleaveNumberFormatting from '../CleaveNumberFormatting';

import { defaultSelectStyles, defaultSelectTheme } from '../selects/SelectStyles';


class ShareholderRow extends Component {
  static propTypes = {
    shareholder: PropTypes.object.isRequired,
    editor: PropTypes.bool.isRequired,
    capTable: PropTypes.object.isRequired,
    edit: PropTypes.bool.isRequired,
    noteNumber: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleFieldUpdate: PropTypes.func.isRequired,
  }

  render() {
    const { editor, capTable, edit, noteNumber, shareholder, handleEdit, handleDelete, handleFieldUpdate } = this.props;

    const checkedClasses = 'fa fa-fw fa-check h3 text-green';
    const unCheckedClasses = 'fa fa-fw fa-times h3 text-light-gray';

    return (
      <Draggable draggableId={`share_${shareholder.id}`} index={shareholder.position - 1} disabled={!edit}>
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div className={`flex border-bottom border-gallery px2 py1 items-center show-pencil-on-hover show-trash-on-hover hover--drag__handle ${!edit && 'hover-bg-wild-sand'}`} key={shareholder.id}>
              <div className="flex flex-auto col-12 items-center" style={{ marginRight: editor ? '1.7em' : '' }}>
                {edit ? <span {...provided.dragHandleProps} className="drag__handle margin-drag__handle--left hover" /> : <span {...provided.dragHandleProps} className="" />}
                {editor ?
                  <div className={`col-25p flex items-center sm-col-6 ${editor ? 'cursor-pointer' : ''}`} onClick={editor ? () => handleEdit(shareholder.id) : null}>
                    <Avatar avatarUrl={shareholder.avatar || window.images.userGray} classNames={shareholder.avatar || 'opacity-50'} />
                    <div className="flex flex-justify-start fw400 ml1 lh-12">{shareholder.owner_name}</div>
                    <span className="h6 fw400 lh-15 text-medium-gray pb1" style={{ marginLeft: '4px' }}>{noteNumber(shareholder.id)}</span>
                  </div>
                  :
                  <div className="col-25p flex items-center sm-col-6">
                    <Avatar avatarUrl={shareholder.avatar || window.images.userGray} classNames={shareholder.avatar || 'opacity-50'} />
                    <div className="flex flex-justify-start fw400 ml1 lh-12">{shareholder.owner_name}</div>
                    <span className="h6 fw400 lh-15 text-medium-gray pb1" style={{ marginLeft: '4px' }}>{noteNumber(shareholder.id)}</span>
                  </div>
                }
                {edit ?
                  <div className="col-15p flex flex-justify-center sm-hide">
                    <ReactSelect
                      defaultValue={capTable.data.share_class_options.filter(option => option.value === shareholder.share_class_id)}
                      options={capTable.data.share_class_options}
                      onChange={selectedOption => handleFieldUpdate(shareholder.id, 'share_class_id', selectedOption ? selectedOption.value : null)}
                      styles={defaultSelectStyles}
                      theme={defaultSelectTheme}
                      className="col-12"
                    />
                  </div>
                  :
                  <div className="col-15p flex flex-justify-center sm-hide">{shareholder.share_class}</div>
                }
                {edit ?
                  <div className="col-10p flex flex-justify-center sm-hide">
                    <CleaveNumberFormatting
                      value={`${shareholder.total_shares.raw}`}
                      className="col-10 border border-gallery input-alto__focus"
                      decimal
                      name="total_shares"
                      alignInput="flex-justify-end"
                      onBlur={(e) => handleFieldUpdate(shareholder.id, e.target.name, e.target.rawValue)}
                    />
                  </div>
                  :
                  <div
                    className="col-10p flex-column flex-justify-center center sm-hide"
                    data-balloon={shareholder.total_shares.raw}
                    data-balloon-pos="bottom"
                    data-balloon-length="medium"
                  >
                    {shareholder.total_shares.readable}
                    <span className="fw400 h6 text-gray show-print" style={{ display: 'none' }}>({shareholder.total_shares.raw})</span>
                  </div>
                }
                <div
                  className="col-10p flex flex-justify-center center sm-hide"
                  data-balloon={shareholder.perc_of_share_capital.raw}
                  data-balloon-pos="bottom"
                  data-balloon-length="medium"
                >
                  {shareholder.perc_of_share_capital.readable} %
                </div>
                {edit ?
                  <div className="col-10p flex flex-justify-center sm-hide">
                    <CleaveNumberFormatting
                      value={`${shareholder.share_options.raw}`}
                      className="col-10 border border-gallery input-alto__focus"
                      decimal
                      name="share_options"
                      alignInput="flex-justify-center"
                      onBlur={(e) => handleFieldUpdate(shareholder.id, e.target.name, e.target.rawValue)}
                    />
                  </div>
                  :
                  <div
                    className="col-10p flex-column flex-justify-center center sm-hide"
                    data-balloon={shareholder.share_options.raw}
                    data-balloon-pos="bottom"
                    data-balloon-length="medium"
                  >
                    {shareholder.share_options.readable}
                    <span className="fw400 h6 text-gray show-print" style={{ display: 'none' }}>({shareholder.share_options.raw})</span>
                  </div>
                }
                <div
                  className="col-10p flex-column flex-justify-center center sm-hide"
                  data-balloon={shareholder.fully_diluted_shares.raw}
                  data-balloon-pos="bottom"
                  data-balloon-length="medium"
                >
                  {shareholder.fully_diluted_shares.readable}
                  <span className="fw400 h6 text-gray show-print" style={{ display: 'none' }}>({shareholder.fully_diluted_shares.raw})</span>
                </div>
                <div
                  className="col-10p flex flex-justify-center center sm-col-6"
                  data-balloon={shareholder.perc_of_fully_diluted_shares.raw}
                  data-balloon-pos="bottom"
                  data-balloon-length="medium"
                >
                  {shareholder.perc_of_fully_diluted_shares.readable} %
                </div>
                <div className="col-10p flex flex-justify-center sm-hide">
                  <span
                    data-balloon={shareholder.avatar ? 'This shareholder has been signed-up/invited to investory.' : 'This shareholder is NOT yet on investory.'}
                    data-balloon-pos="down"
                    data-balloon-length="large"
                  >
                    <i
                      className={shareholder.avatar ? checkedClasses : unCheckedClasses}
                    />
                  </span>
                </div>
              </div>
              {editor &&
                <div className="flex flex-column flex-justify-center items-center mnr-25em">
                  <div
                    className="cursor-pointer hover-text-blue"
                    data-balloon="Edit Shareholder"
                    data-balloon-pos="right"
                    data-balloon-length="small"
                    onClick={() => handleEdit(shareholder.id)}
                  >
                    <i className="fa fa-pencil opacity-0" />
                  </div>
                  <div
                    className="cursor-pointer"
                    data-balloon="Delete Share"
                    data-balloon-pos="right"
                    data-balloon-length="small"
                  >
                    <ConfirmationModal
                      html={'<i class="fa fa-trash opacity-0" />'}
                      className="hover-text-red"
                      message="<b>You are about to permanently delete this Shareholder. This cannot be undone.</b><br/><br/>Are you sure you want to delete?"
                      onClick={() => handleDelete(shareholder.id)}
                    />
                  </div>
                </div>
                }
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

export default ShareholderRow;
