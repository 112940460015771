import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Color from 'color';

import ColorPicker from '../../selects/ColorPicker';
import InvestoryLogoSvg from '../../InvestoryLogoSvg';
import Avatar from '../../tab_view/Avatar';


class CompanyBrandingSettings extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    brandingColors: PropTypes.array,
    themeFor: PropTypes.string.isRequired,
  }

  static defaultProps = {
    brandingColors: null,
  }

  constructor(props) {
    super(props);

    const { brandingColors, company } = this.props;

    this.state = {
      primaryColor: brandingColors[0] || window.ColorVariables.primaryDefaultColor,
      actualColor: brandingColors[1] || window.ColorVariables.primaryDefaultColor,
      planColor: brandingColors[2] || window.ColorVariables.secondaryDefaultColor,
      disabled: !company.subscribed,
    };
  }

  componentDidMount() {
    const { primaryColor } = this.state;

    this.applyTheme(primaryColor);
  }

  applyTheme = (color) => {
    const primaryColor = Color(color);

    const textColorSelected = (primaryColor.isLight() && (primaryColor.luminosity() > 0.6)) ? window.ColorVariables.colorGray : Color('white').string();
    const textColorUnselected = (primaryColor.isLight() && (primaryColor.luminosity() > 0.6)) ? Color(window.ColorVariables.colorGray).alpha(0.54).string() : Color('white').alpha(0.64).string();

    $(this.companyName)[0].style.setProperty('color', textColorSelected);
    $(this.divider)[0].style.setProperty('background-color', textColorUnselected);
    App.trackCompanyEvent('branding_page', 'changed_theme');
  };

  handleClick = () => {
    const { company } = this.props;
    const {
      primaryColor: header_bg_primary_color,
      actualColor: actual_color,
      planColor: plan_color,
      disabled,
    } = this.state;

    if (disabled) return;

    const url = `/company/${company.id}/branding`;
    const params = { company: { header_bg_primary_color, actual_color, plan_color } };

    axios.patch(url, params).then(
      () => { window.location = `${url}/edit`; },
      (err) => App.State.setFlash({ name: 'alert', msg: `Sorry, but an error happened. ${err.response.data.error}` }),
    );
  }

  render() {
    const { themeFor, company } = this.props;
    const { primaryColor, actualColor, planColor, disabled } = this.state;

    return (
      <React.Fragment>
        <div className="bg-white center">
          <div className="p2 flex flex-justify-between border-bottom border-gallery">
            <div className="text-gray bold">
              Branding Options
            </div>
            {disabled &&
              <div className="flex items-center text-light-gray">
                <i className="fa fa-lock" />
                <div className="flex">
                  <span className="ml1">Only available Pro plan <a href={`/company/${company.id}/billing`} className="cursor-pointer">Upgrade Here!</a></span>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="bg-white center p2">
          <div className="flex items-center">
            <div className="col-12">
              <span className="flex flex-justify-left">Color Options</span>
              <div className="flex pt2 mb2">
                <div className="flex col-6 mr3">
                  <div className="flex">
                    <div className="flex mr2">
                      <ColorPicker
                        color={actualColor}
                        rightText="Actual Chart Color"
                        onChange={(color) => { this.setState({ actualColor: color.hex }); Intercom('trackEvent', 'changed_chart_color'); }}
                      />
                    </div>
                    <ColorPicker
                      color={planColor}
                      rightText="Plan Chart Color"
                      onChange={(color) => { this.setState({ planColor: color.hex }); Intercom('trackEvent', 'changed_chart_color'); }}
                    />
                  </div>
                </div>
                <div className="flex col-6">
                  <ColorPicker
                    color={primaryColor}
                    rightText="Header Color"
                    onChange={(color) => { this.setState({ primaryColor: color.hex }); this.applyTheme(color.hex); Intercom('trackEvent', 'changed_nav_color'); }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt2 bs-around">
            <div className="p2 flex flex-justify-left border-bottom border-gallery">
              <div className="text-gray bold">
                Branding Preview
              </div>
            </div>
            <div className="flex flex-justify-between flex-start p2">
              <div className="col-6 flex flex-column m2">
                <div className="flex items-center mb2">
                  <span className="mr1">Default Chart Color(s)</span>
                  <span
                    data-balloon="The actual/plan chart colors are used for the actual/plan values that are charted (e.g. on KPIs page or updates). You can still change these colors inside updates for specific charts, however these are the default ones used."
                    data-balloon-pos="up"
                    data-balloon-length="large"
                  >
                    <i className="fa fa-info-circle text-light-gray" />
                  </span>
                </div>
                <div className="flex flex-justify-left">
                  <svg width="170" height="150">
                    <rect x="0" width="40" y="0" height="150" style={{ fill: actualColor }} />
                    <rect x="44" width="40" y="67" height="150" style={{ fill: planColor }} />
                    <rect x="88" width="40" y="55" height="150" style={{ fill: actualColor }} />
                    <rect x="132" width="40" y="29" height="150" style={{ fill: planColor }} />
                  </svg>
                </div>
              </div>

              <div className="col-6 flex flex-column m2">
                <div className="flex items-center mb2">
                  <span className="mr1">Main Header</span>
                  <span
                    data-balloon="The main header background color is based on the selected header color. The settings page does not have a branded color, as it will only be accessible/visible by your team members."
                    data-balloon-pos="up"
                    data-balloon-length="large"
                  >
                    <i className="fa fa-info-circle text-light-gray" />
                  </span>
                </div>
                <div className="flex flex-1 flex-justify-start items-center container-nav flex-row bs-around" style={{ backgroundColor: primaryColor }}>
                  <div className="flex items-center py2">
                    <div className="px2">
                      {
                        <InvestoryLogoSvg
                          themeFor={themeFor}
                          themeColor={primaryColor}
                          logoClass="branding_preview__logo"
                        />
                      }
                    </div>
                  </div>
                  <div
                    className="divider"
                    ref={(c) => { this.divider = c; }}
                  />
                  <div className="flex items-center ml2">
                    <div>
                      <Avatar avatarUrl={company.logoUrl} />
                    </div>
                    <span
                      className="ml1"
                      ref={(c) => { this.companyName = c; }}
                    >{company.name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-justify-end bg-white py2 pr2">
          <div
            onClick={() => this.handleClick()}
            className={`border-none inline-block height-38 px3 sm-px2 py1 rounded text-white fw400 ${disabled ? 'bg-light-gray cursor-disabled' : 'bg-green cursor-pointer'}`}
            data-balloon={disabled ? 'Only available on Pro plan' : null}
            data-balloon-pos={disabled ? 'left' : null}
            data-balloon-length={disabled ? 'medium' : null}
          >
            Save Settings
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CompanyBrandingSettings;
