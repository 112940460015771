import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import SelectorDropDown from './SelectorDropDown'

const ProFeatures = (props) => {
  const { proPlans, monthlyPayments, onClick } = props;
  const [selectValue, setSelectValue] = useState('');
  const [planType, setPlanType] = useState('');
  const isVcPro = proPlans.name === 'VC Pro';
  const isAnglePro = proPlans.name === 'Angel Pro';
  const getCompanyValue = (feature) => {
    if ((isAnglePro) && (selectValue === '')) {
      return feature.nameFor10Portfolio
    } else if ((isVcPro) && (selectValue === '')) {
      return feature.nameFor20Portfolio
    } else if (selectValue === '10 portfolio') {
      return feature.nameFor10Portfolio
    } else if (selectValue === '20 portfolio') {
      return feature.nameFor20Portfolio
    } else if (selectValue === '30 portfolio') {
      return feature.nameFor30Portfolio
    } else {
      return feature.nameForUnlimitedPortfolio
    }
  }

  const getCompanyDescription = () => {
    if(isAnglePro || isVcPro) {
      return proPlans.desc;
    }
  }
  const getYearlyPrice = () => {
    return isAnglePro ? getYearlyPriceForAnglePro() : getYearlyPriceForVcPro()
  }

  const getMonthlyPrice = () => {
    return isAnglePro ? getMonthlyPriceForAnglePro() : getMonthlyPriceForVcPro()
  }
  const getYearlyPriceForVcPro = () => {
    if ((selectValue === '') || (selectValue === '20 portfolio')) {
      return proPlans.yearlyPrice
    } else if (selectValue === '30 portfolio') {
      return proPlans.yearlyPriceFor30Portfolio
    } else {
      return proPlans.yearlyPriceForUnlimited
    }
  }
  const getMonthlyPriceForAnglePro = () => {
    if ((selectValue === '') || (selectValue === '10 portfolio')) {
      return proPlans.monthlyPrice
    } else {
      return proPlans.monthlyPriceForUnlimited
    }
  }
  const getMonthlyPriceForVcPro = () => {
    if ((selectValue === '') || (selectValue === '20 portfolio')) {
      return proPlans.monthlyPrice
    } else if (selectValue === '30 portfolio') {
      return proPlans.monthlyPriceFor30Portfolio
    } else {
      return proPlans.monthlyPriceForUnlimited
    }
  }
  const getYearlyPriceForAnglePro = () => {
    if ((selectValue === '') || (selectValue === '10 portfolio')) {
      return proPlans.yearlyPrice
    } else {
      return proPlans.yearlyPriceForUnlimited
    }
  }
  const checkProfolioComany = (object) => {
    if (Object.keys(object)[0] !== 'name') {
      return true
    } return false
  }
  const optionForVcPro = [
    { value: '20 portfolio', label: '20 portfolio companies' },
    { value: '30 portfolio', label: '30 portfolio companies' },
    { value: 'Unlimited', label: 'Unlimited portfolio companies' }
  ]
  const optionForAnglePro = [
    { value: '10 portfolio', label: '10 portfolio companies' },
    { value: 'Unlimited', label: 'Unlimited portfolio companies' }
  ]
  const planYearly = (
    <Fragment>
      <div className="h1">€{getYearlyPrice()} <span className="h4">/ Year</span></div>
      <div className="text-light-gray h5 mb1">Billed annually</div>
    </Fragment>
  )
  const planMonthy = (
    <Fragment>
      <div className="h1">€{getMonthlyPrice()} <span className="h4">/ Month</span></div>
      <div className="text-light-gray h5 mb1">Billed monthly</div>
    </Fragment>
  )

  const findPrice = monthlyPayments ? planMonthy : planYearly;
  const portfolioFeatureName = (feature, index) => (
    <li className="col-12" key={index}>+ {getCompanyValue(feature)}</li>
  )
  const featureName = (feature, index) => (
    <li className="col-12" key={index}>+ {feature.name} </li>
  )

  return (
    <Fragment>
      <div className="col-12 mr1 light">
        <div>
          <p className="h2 text-orange ls-n-1">{proPlans.name}</p>
          {isVcPro &&
            <SelectorDropDown
              options={optionForVcPro}
              selectValue={selectValue}
              onChangeValue={(selectedValue) => setSelectValue(selectedValue.value)}
            />
          }
          {isAnglePro &&
            <SelectorDropDown
              options={optionForAnglePro}
              selectValue={selectValue}
              onChangeValue={(selectedValue) => setSelectValue(selectedValue.value)}
            />
          }
        </div>
        <div>{getCompanyDescription()}</div>
        {!proPlans.yearlyPrice &&
            <div style={{ marginTop: '30px' }}>
              <a href='https://meetings.hubspot.com/katrina-amolina/15-minute-meeting' target={"_blank"}>
                <span
                  className="border border-green text-white bg-green bold hover hover-text-green hover-bg-white px2 py1 mt rounded bs-around cursor-pointer"
                >
                  Contact sales
                </span>
              </a>
            </div>
        }
        <ul className="flex flex-wrap mx2 mt2 h5">
          {((isVcPro) || isAnglePro) ?
            proPlans.features.map((feature, index) => (
              checkProfolioComany(feature) ? portfolioFeatureName(feature, index) : featureName(feature, index)
            )):
            proPlans.features &&
            proPlans.features.map((feature, index) => (
              <li className="col-12" key={index}>+ {feature.name}</li>
            ))
          }
        </ul>
      </div>
      {
        proPlans.yearlyPrice &&
        <div className="col-6 flex flex-column flex-justify-center ml1">
          <div className="col-12">
            <div className="col-12 center pt2">
              <React.Fragment>{findPrice}</React.Fragment>
            </div>
            <div className="col-12 center pt2">
              <div>
                <span
                  className="border border-green text-white bg-green bold hover hover-text-green hover-bg-white px2 py1 rounded bs-around cursor-pointer"
                  onClick={event => onClick(monthlyPayments, selectValue, proPlans)}
                >
                  Upgrade now
                </span>
              </div>
            </div>
          </div>
        </div>
      }
    </Fragment>
  )
};

ProFeatures.propTypes = {
  proPlans: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

ProFeatures.defaultProps = {
  proPlans: {},
  onClick: null,
};
export default ProFeatures;
