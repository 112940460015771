import Highcharts from 'highcharts';
import numberFormat from '../../../user_number_format';
import applyDateFormat from '../../../charts/highcharts_date_format';

const blueColorPalette = ['#2974b7', '#77AAD8', '#4D8CC5', '#2E75B5', '#0C60AB', '#08477F'];
const orangeColorPalette = ['#ff9d05', '#FFC366', '#FFB23C', '#FF9C05', '#C87900', '#9D5F00'];

const getColor = (index, type) => (
  type === 'actual' ? blueColorPalette[index] : orangeColorPalette[index]
);

const dateParser = date => {
  const parts = date.split('-');
  return Date.UTC(parts[0], parts[1] - 1, 1);
};

const axisLabel = (unit, currency) => {
  if (unit && currency) {
    if (unit === 'currency') {
      return currency;
    }
    if (unit === 'percentage') {
      return '%';
    }
    return null;
  }
  return null;
};

const dataObject = (data, value, index) => (
  data.dates ?
    {
      y: parseFloat(value),
      x: dateParser(data.dates[index]),
    }
    :
    {
      y: parseFloat(value),
      name: data.categories[index],
    }
);

// function only gets called when multiKpis is activated. For now there are only these 2 types
const oppositeChartType = (charType) => {
  if (charType === 'line') {
    return 'column';
  }
  return 'line';
};

// Can handle single and multi KPIs in the chart
const generateSeries = (data, valueType, actualColors = null, planColors = null, chartType = null) => {
  const series = [];
  let actual;
  let forecast;

  if (data.values) {
    data.values.forEach((kpi, index) => {
      if (valueType.includes('actual') && kpi.actuals.find(value => value !== null) !== undefined) {
        const label = `Actual for ${kpi.metric}`;
        actual = {
          name: label,
          data: kpi.actuals.map((value, innerIndex) => dataObject(data, value, innerIndex)),
          color: (actualColors && actualColors[index]) || getColor(index, 'actual'),
          type: (index % 2 === 0) ? chartType : oppositeChartType(chartType),
          yAxis: index,
        };
        series.push(actual);
      }

      if (valueType.includes('plan') && kpi.forecasts.find(value => value !== null) !== undefined) {
        const label = `Plan for ${kpi.metric}`;
        forecast = {
          name: label,
          data: kpi.forecasts.map((value, innerIndex) => dataObject(data, value, innerIndex)),
          color: (planColors && planColors[index]) || getColor(index, 'forecast'),
          type: (index % 2 === 0) ? chartType : oppositeChartType(chartType),
          yAxis: index,
        };
        series.push(forecast);
      }
    });
    // Currency is only implemented for single KPI chart
  } else {
    if (valueType.includes('actual')) {
      series.push({
        name: 'Actual',
        data: data.actuals.map((value, index) => dataObject(data, value, index)),
        color: (actualColors && actualColors[0]) || getColor(0, 'actual'),
      });
    }

    if (valueType.includes('plan')) {
      series.push({
        name: 'Plan',
        data: data.forecasts.map((value, index) => dataObject(data, value, index)),
        color: (planColors && planColors[0]) || getColor(0, 'forecast'),
      });
    }
  }
  return series;
};

const formatForPeriodStep = (periodStep, periodStepFormat) => {
  if (periodStep) {
    return periodStepFormat;
  }
  return '%b` %y';
};

const intervalformatForPeriodStep = (periodStep) => {
  if (periodStep) {
    if (periodStep === 'quarter') return 3 * 24 * 3600 * 1000 * 30;
    if (periodStep === 'year') return 12 * 24 * 3600 * 1000 * 30;
  }
  return null;
};

const chartOptions = (data, {
  chartTitle = null,
  exportTitle = '',
  type = 'line',
  width = '300',
  height = '250',
  marginBottom = '60',
  actualColors = '',
  planColors = '',
  valueType = 'actual-plan',
  showDataLabels = false,
  chartAnimation = true,
  currency = false,
}) => {
  const lang = numberFormat().getNumberFormatKpiChart();
  const series = generateSeries(data, valueType, actualColors, planColors, type, currency);
  applyDateFormat();

  // options outside normal chart options
  Highcharts.setOptions({
    lang,
  });

  const unitLabel = axisLabel(data.kpiUnit, currency);

  return (
    {
      title: {
        text: chartTitle,
      },
      navigation: {
        buttonOptions: {
          verticalAlign: 'bottom',
          y: 10,
          theme: {
            states: {
              hover: {
                fill: '#fff',
              },
              select: {
                stroke: '#fff',
                color: '#313131',
                fill: '#f6f6f6',
              },
            },
            style: {
              color: '#313131',
              textDecoration: 'none',
              fontWeight: 'lighter',
            },
          },
        },
      },
      chart: {
        type,
        height,
        width,
        marginBottom,
      },
      plotOptions: {
        series: {
          pointPadding: 0.1,
          minPointLength: 2,
          animation: chartAnimation ? { duration: 1000 } : false,
          dataLabels: {
            enabled: showDataLabels,
          },
        },
      },
      series,
      legend: {
        enabled: !!data.values,
        y: 10,
      },
      tooltip: {
        xDateFormat: data.dates ? formatForPeriodStep(data.periodStep, data.periodStepFormat) : null,
        valueSuffix: unitLabel,
      },
      xAxis: {
        type: data.dates ? 'datetime' : 'category',
        labels: {
          format: data.dates ? `{value: ${formatForPeriodStep(data.periodStep, data.periodStepFormat)}}` : null,
        },
        uniqueNames: !data.dates && false,
        tickInterval: data.dates ? intervalformatForPeriodStep(data.periodStep) : null,
      },
      // 'values' attribute means it's a multi KPIs chart
      yAxis: data.values ?
        data.values.map((kpiData, index) => (
          {
            title: {
              text: kpiData.metric,
            },
            opposite: index % 2 !== 0,
          }
        ))
        :
        {
          title: {
            text: null,
          },
          labels: {
            format: unitLabel ? `{value} ${unitLabel}` : null,
          },
        },
      exporting: {
        enabled: exportTitle,
        filename: exportTitle || 'Exported Chart',
        chartOptions: {
          title: {
            text: exportTitle,
          },
          plotOptions: {
            series: {
              dataLabels: {
                enabled: showDataLabels,
              },
            },
          },
        },
        buttons: {
          contextButton: {
            enabled: false,
          },
          exportButton: {
            text: 'Export Chart',
            // Use only the download related menu items from the default
            // context button
            menuItems: [
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
            ],
          },
        },
      },
    }
  );
};

export {
  generateSeries,
  chartOptions,
};
