import React, { Component } from 'react';


class InvestorRoleSelect extends Component {
  state = {
    selected: 3,
  }

  render() {
    const { selected } = this.state;

    return (
      <div>
        <input
          type="hidden"
          name="selected"
          value={selected}
          id="selected_investor_role"
        />

        <div className="flex flex-justify-around mb3 x-lg-mb2 sm-flex-column x-lg-mb3">
          <div
            className={selected === 3 ? 'sm-mb3 sm-mx-auto active' : 'sm-mb3 sm-mx-auto'}
            id="individual"
          >
            <div className="icon-container flex flex-center flex-justify-center mx-auto mb2 relative">
              <span className="icon-role icon-role--user-selected bg-center bg-no-repeat inline-block" />
            </div>

            <p className="h4 semi-bold text-gray">Part of an investor group (fund, accelerator, etc)? </p>

            <p className="light m0 text-light-gray px2">
              If you want to share multiple investments with your partners/investment<br />
              managers, create a single investor account first and get in touch with us<br />
              through <a href="mailto:support@investory.io">mail</a> or our chat (bottom right) to upgrade your account later.
            </p>
          </div>
        </div>
        {selected ?
          <input
            className="bg-green border-none cursor-pointer fs-18 inline-block px4 sm-px2 py2 rounded text-upper text-white"
            id="create_account"
            type="submit"
            value="CONTINUE"
          />
          :
          <div className="bg-blue border-none cursor-pointer fs-18 inline-block px4 sm-px2 py2 rounded text-upper text-white">
            Select your Investor role
          </div>
        }
      </div>

    );
  }
}

export default InvestorRoleSelect;
