import PropTypes from 'prop-types';
import Modal from '../../Modal';

const BillingModal = ({ show, url, toogleModal, topic }) => (
  <Modal
    show={show}
    onClickOutside={toogleModal}
  >
    <div className="flex flex-justify-between items-center bold p2 border-bottom border-lighter-gray">
      {topic ?
        <div className="h3">Upgrade to Unlock Pro Features</div>
        :
        <div className="h3">Upgrade to Pro</div>
      }
      <i className="fa fa-close h5 cursor-pointer" onClick={toogleModal} />
    </div>

    <div className="fw400 px3 py3 overflow-y-auto" style={{ maxHeight: '65vh' }}>
      <div className="flex flex-justify-center">
        <span className="light">Upgrade now and unlock all of the Pro features:</span>
      </div>
      <div className="flex col-12">
        <div className="flex flex-column col-5">
          <img
            className="mb2"
            src="https://i1.wp.com/investory.io/wp-content/uploads/2019/02/Company-Branding.png"
            alt="Upgrade to pro..."
          />
        </div>
        <div className="flex flex-column ml2 col-7">
          <ul className="flex flex-wrap mt3 pt1 h5 light">
            <li className="col-12 mb1"><i className="fa fa-check text-green mr1" />Unlimited <span className="fw400">External</span> Update Recipients</li>
            <li className="col-12 mb1"><i className="fa fa-check text-green mr1" /><span className="fw400">Custom</span> Update Schedule</li>
            <li className="col-12 mb1"><i className="fa fa-check text-green mr1" /><span className="fw400">Multiple Shareable</span> Data Rooms</li>
            <li className="col-12 mb1"><i className="fa fa-check text-green mr1" />Company/Chart <span className="fw400">Branding</span></li>
          </ul>
        </div>
      </div>
    </div>

    <div className="flex flex-justify-end items-center p2 border-top border-lighter-gray">
      <div
        className="h5 text-blue mr2 cursor-pointer"
        onClick={toogleModal}
      >
        Cancel
      </div>
      <a
        className="border border-blue bg-blue text-white h5 px2 py1 mx2 rounded cursor-pointer"
        href={url}
        onClick={() => { App.trackCompanyEvent(topic, 'view_pricing'); Intercom('trackEvent', `${topic}_view_pricing`); }}
      >
        View Pricing Plans
      </a>
    </div>
  </Modal>
);

BillingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  toogleModal: PropTypes.func.isRequired,
  topic: PropTypes.string,
};

BillingModal.defaultProps = {
  topic: '',
};

export default BillingModal;
