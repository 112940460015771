import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Share from './Share';


class SharesTable extends Component {
  static propTypes = {
    shares: PropTypes.array.isRequired,
    createUrl: PropTypes.string.isRequired,
    updateTopState: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
    totals: PropTypes.object.isRequired,
    edit: PropTypes.bool.isRequired,
  }

  createShare = (e) => {
    e.preventDefault();
    const { createUrl, updateTopState } = this.props;
    App.Api.post(createUrl, { category: 'common' }).then(
      data => updateTopState(data),
    );
  }

  displayAdd = () => {
    const { edit } = this.props;
    if (edit) {
      return (
        <div className="flex mxn2 cursor-pointer" onClick={this.createShare}>
          <div className="col-3 px2">
            <p className="center h5 m0 text-green">New Share</p>
          </div>

          <div className="col-3 px2">
            <p className="center h6 m0 text-light-gray">-</p>
          </div>

          <div className="col-3 px2">
            <p className="center h6 m0 text-light-gray">Calculated</p>
          </div>

          <div className="center col-3 px2">
            <span className="h6 m0 text-light-gray">%</span>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    const { totals, shares, updateItem, updateTopState, edit } = this.props;

    return (
      <div className="bg-white bs-around mb2 py2">
        <div className="flex mb2 px2">
          <p className="bold fs-18 m0 text-gray">Common shares</p>
          <div className="flex flex-auto flex-justify-end">
            <div
              className="text-gray cursor-pointer"
              data-balloon="The basic form of equity ownership in a company. These are mostly shares of the founders."
              data-balloon-pos="up"
              data-balloon-length="xlarge"
            >
              <i className="fa fa-info-circle" />
            </div>
          </div>
        </div>

        <div className="bg-gallery px2 py1">
          <div className="flex mxn2">
            <div className="col-3 px2">
              <p className="bold center h5 m0 text-gray text-upper">Name</p>
            </div>

            <div className="col-3 px2">
              <p className="bold center h5 m0 text-gray text-upper">Issued equity</p>
            </div>

            <div className="col-3 px2">
              <p className="bold center h5 m0 text-gray text-upper">Total equity</p>
            </div>

            <div className="col-3 px2">
              <p className="bold center h5 m0 text-gray text-upper">%</p>
            </div>
          </div>
        </div>

        <div className="px2 py1">
          {shares.map(share => (
            <Share
              key={share.id}
              share={share}
              updateTopState={updateTopState}
              updateItem={updateItem}
              edit={edit}
            />
          ))}
          {this.displayAdd()}
        </div>

        <div className="px2 py1">
          <div className="bg-blue flex mxn2">
            <div className="col-3 px2">
              <p className="center h6 m0 text-white text-upper">Total</p>
            </div>

            <div className="col-3 px2">
              <p className="center h6 m0 text-white text-upper">{totals.issued}</p>
            </div>

            <div className="col-3 px2">
              <p className="center h6 m0 text-white text-upper">{totals.current}</p>
            </div>

            <div className="center col-3 px2">
              <span className="h6 m0 text-white text-upper">{totals.percentage}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SharesTable;
