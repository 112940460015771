import axios from 'axios';

import {
  addSection,
  updateSection,
  removeSection,
  updateSections,
  addFile,
  setFile,
  setFiles,
  removeFile,
} from '../../../actions/dataRoomActions';


const createSection = (dataRoomId, params) =>
  async (dispatch, getState) => {
    const { fundId, investorId } = getState().currentUser;
    const res = await axios.post(`/investor/${investorId}/funds/${fundId}/data_rooms/data_room_sections`, params);
    dispatch(addSection(res.data));
  };

const renameSection = (dataRoomId, id, params) =>
  async (dispatch, getState) => {
    const { fundId, investorId } = getState().currentUser;
    const res = await axios.patch(`/investor/${investorId}/funds/${fundId}/data_rooms/data_room_sections/${id}`, params);
    dispatch(updateSection(res.data));
  };

const deleteSection = (dataRoomId, id) =>
  async (dispatch, getState) => {
    const { fundId, investorId } = getState().currentUser;
    const res = await axios.delete(`/investor/${investorId}/funds/${fundId}/data_rooms/data_room_sections/${id}`);
    dispatch(removeSection(res.data));
  };

const updateSectionPosition = (dataRoomId, id, params) =>
  async (dispatch, getState) => {
    const { fundId, investorId } = getState().currentUser;
    const res = await axios.patch(`/investor/${investorId}/funds/${fundId}/data_rooms/data_room_sections/${id}/update_position`, params);
    dispatch(updateSections(res.data));
  };

const createFile = (dataRoomId, params) =>
  async (dispatch, getState) => {
    const { fundId, investorId } = getState().currentUser;
    const res = await axios.post(`/investor/${investorId}/funds/${fundId}/data_rooms/data_room_files`, params);
    dispatch(addFile(res.data));
  };

const moveFile = (dataRoomId, id, params) =>
  async (dispatch, getState) => {
    const { fundId, investorId } = getState().currentUser;
    const res = await axios.patch(`/investor/${investorId}/funds/${fundId}/data_rooms/data_room_files/${id}`, params);
    dispatch(removeFile(res.data));
    dispatch(addFile(res.data));
  };

const updateFile = (dataRoomId, id, params) =>
  async (dispatch, getState) => {
    const { fundId, investorId } = getState().currentUser;
    const res = await axios.patch(`/investor/${investorId}/funds/${fundId}/data_rooms/data_room_files/${id}`, params);
    dispatch(setFile(res.data));
  };

const updateFilePosition = (dataRoomId, id, params) =>
  async (dispatch, getState) => {
    const { fundId, investorId } = getState().currentUser;
    const res = await axios.patch(`/investor/${investorId}/funds/${fundId}/data_rooms/data_room_files/${id}/update_position`, params);
    dispatch(setFiles(res.data));
  };

const deleteFile = (dataRoomId, id) =>
  async (dispatch, getState) => {
    const { fundId, investorId } = getState().currentUser;
    const res = await axios.delete(`/investor/${investorId}/funds/${fundId}/data_rooms/data_room_files/${id}`);
    dispatch(removeFile(res.data));
  };


export {
  createSection,
  renameSection,
  deleteSection,
  updateSectionPosition,
  createFile,
  moveFile,
  updateFile,
  updateFilePosition,
  deleteFile,
};
