import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CompanyFilterBy from '../../slideshow/config/CompanyFilterBy';

class FilterBox extends Component {
  static propTypes = {
    options: PropTypes.object.isRequired,
    handleChangeFilteringCriteria: PropTypes.func.isRequired,
    selectedFilters: PropTypes.array.isRequired,
  }

  static defaultProps = {
  }

  formatOptions = option => {
    const { options } = this.props;
    const optionField = `${option}_options`;
    return options[optionField].map(statusObj => [statusObj.id, statusObj.name, statusObj.color_hex]);
  };

  collectOptions = optionName => {
    const { options } = this.props;
    const companies = options.investment_options;
    let optionFields = [];
    companies.map(company => {
      if (!optionFields.includes(company[optionName]) && company[optionName]) optionFields.push(company[optionName]);
      return company;
    });
    return optionFields.map(statusObj => [statusObj, statusObj]);
  }

  render() {
    const { options, handleChangeFilteringCriteria, selectedFilters } = this.props;
    const companies = options.investment_options;
    const filterOptions = {
      status: [['all', 'All']].concat(companies.length > 0 ? this.formatOptions('status') : []),
      fundraising_status: [['all', 'All']].concat(companies.length > 0 ? this.formatOptions('fundraising') : []),
      industry: [['all', 'All']].concat(companies.length > 0 ? options.industry_options : []),
      business_type: [['all', 'All']].concat(companies.length > 0 ? options.business_type_options : []),
      stage: [['all', 'All']].concat(companies.length > 0 ? options.stage_options : []),
      country: [['all', 'All']].concat(companies.length > 0 ? this.collectOptions('country') : []),
    };

    return (
      <div className="flex flex-row">
        <div className="flex flex-row sm-mb2 lh-12 h6">
          <span>Filter by</span>
          <div className="ml1 flex flex-row">
            {
              selectedFilters.map(filter => (
                <CompanyFilterBy
                  key={filter.name}
                  name={filter.label}
                  options={filterOptions[filter.name]}
                  color={filter.color}
                  onChangeFilteringCriteria={handleChangeFilteringCriteria}
                  countOptions={filterOptions[filter.name].map(option => {
                    const count = {};
                    count[option[0]] = companies.filter((company) => company[filter.name] === option[0]).length;
                    return count;
                  })}
                  currentFilter={filter}
                />
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

export default FilterBox;
