import React, { Component } from 'react';


class MenuButton extends Component {
  componentDidMount() {
    $('body').on('click touchstart', '.content', () => {
      $('.wrapper').removeClass('wrapper--slided');
    });
  }

  slideWrapper() {
    $('.wrapper').toggleClass('wrapper--slided');
  }

  render() {
    return (
      <button className="fa fa-bars pull-left btn-menu" onClick={this.slideWrapper} />
    );
  }
}

export default MenuButton;
