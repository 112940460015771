import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DealInfoWidget from './DealInfoWidget';
import DealTermsWidget from './DealTermsWidget';
import DealCommitmentsWidget from './DealCommitmentsWidget';

import DealDataRoomContainer from '../../../containers/company/deal_rooms/DealDataRoomContainer';
import DealDataRoomReadOnlyContainer from '../../../containers/company/deal_rooms/DealDataRoomReadOnlyContainer';

class DealRoom extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    dealRoom: PropTypes.object.isRequired,
    company: PropTypes.object,
    dataRoom: PropTypes.object.isRequired,
    saveDealInfo: PropTypes.func,
    saveDealTerm: PropTypes.func,
    createDealCommitment: PropTypes.func,
    saveDealCommitment: PropTypes.func,
    deleteDealCommitment: PropTypes.func,
  }

  static defaultProps = {
    company: null,
    saveDealInfo: null,
    saveDealTerm: null,
    createDealCommitment: null,
    saveDealCommitment: null,
    deleteDealCommitment: null,
  }

  state = {
    showDealInfosModal: false,
    showDealTermsModal: false,
    showDealCommitmentsModal: false,
  }

  closeModal = (stateName) => {
    this.setState({ [stateName]: false });
  }

  showModal = (stateName) => {
    this.setState({ [stateName]: true });
  }

  render() {
    const {
      currentUser,
      dealRoom,
      company,
      dataRoom,
      saveDealInfo,
      saveDealTerm,
      createDealCommitment,
      saveDealCommitment,
      deleteDealCommitment,
    } = this.props;

    const {
      showDealInfosModal,
      showDealTermsModal,
      showDealCommitmentsModal,
    } = this.state;

    return (
      <>
        {dealRoom.state === 'draft' &&
          <div className="bg-orange bs-around border-none text-white center bold h5 p1 rounded">
            <i className="fa fa-info-circle mr1" />
            <span>This Data Room is in Draft State! Only your Team Members can access and change data. To make this deal room accessible to everyone, you need to </span>
            <a
              href={`/company/${company.id}/deal_rooms/${dealRoom.id}/state?state=opened`}
              data-method="patch"
            >
              publish it here.
            </a>
          </div>
        }
        {dealRoom.can_edit &&
          <div className="flex pt2 mxn1 sm-flex-column">
            <div className="col-4 mx1 sm-col-12 sm-mx0 sm-mb1">
              <div
                className="rounded border border-gallery px3 py2 bg-gallery cursor-pointer hover card--has-hover-shadow-gallery__small"
                onClick={() => this.showModal('showDealInfosModal')}
              >
                <div className="flex items-center flex-justify-center bold text-light-gray">
                  <i className="fa fa-home" />
                  <span className="h4 ml1">Manage Deal Infos</span>
                </div>
              </div>
            </div>
            <div className="col-4 mx1 sm-col-12 sm-mx0 sm-mb1">
              <div
                className="rounded border border-gallery px3 py2 bg-gallery cursor-pointer hover card--has-hover-shadow-gallery__small"
                onClick={() => this.showModal('showDealTermsModal')}
              >
                <div className="flex items-center flex-justify-center bold text-light-gray">
                  <i className="fa fa-money" />
                  <span className="h4 ml1">Manage Deal Terms</span>
                </div>
              </div>
            </div>
            <div className="col-4 mx1 sm-col-12 sm-mx0 sm-mb1">
              <div
                className="rounded border border-gallery px3 py2 bg-gallery cursor-pointer hover card--has-hover-shadow-gallery__small"
                onClick={() => this.showModal('showDealCommitmentsModal')}
              >
                <div className="flex items-center flex-justify-center bold text-light-gray">
                  <i className="fa fa-book" />
                  <span className="h4 ml1">Manage Deal Commitments</span>
                </div>
              </div>
            </div>
          </div>
        }
        <div className="flex pt2 mxn1 sm-flex-column">
          <div className="flex flex-column col-4 mx1 py1 px2 bg-white bs-around sm-col-12 sm-mx0 sm-mb1">
            <DealInfoWidget
              dealRoom={dealRoom}
              company={company}
              currentUser={currentUser}
              saveDealInfo={saveDealInfo}
              showModal={showDealInfosModal}
              closeModal={() => this.closeModal('showDealInfosModal')}
            />
          </div>
          <div className="flex flex-column col-4 mx1 py1 px2 bg-white bs-around sm-col-12 sm-mx0 sm-mb1">
            <DealTermsWidget
              dealTerm={dealRoom.deal_term}
              canEdit={dealRoom.can_edit}
              companyId={company.id}
              dealRoomId={dealRoom.id}
              roundTypeOptions={dealRoom.round_type_options}
              currency={company.currency}
              saveDealTerm={saveDealTerm}
              showModal={showDealTermsModal}
              closeModal={() => this.closeModal('showDealTermsModal')}
            />
          </div>
          <div className="flex flex-column col-4 mx1 py1 px2 bg-white bs-around sm-col-12 sm-mx0 sm-mb1">
            <DealCommitmentsWidget
              dealCommitments={dealRoom.deal_commitments}
              currentUser={currentUser}
              canEdit={dealRoom.can_edit}
              companyId={company.id}
              dealContactEmail={dealRoom.deal_contact.email}
              dealRoomId={dealRoom.id}
              fundingGoal={dealRoom.deal_term ? dealRoom.deal_term.funding_goal.raw : null}
              daysUntilClosing={dealRoom.deal_term ? dealRoom.deal_term.closing_goal.days_until_closing : null}
              investorConnectionOptions={dealRoom.investor_connection_options}
              commitmentStateOptions={dealRoom.commitment_state_options}
              currency={company.currency}
              createDealCommitment={createDealCommitment}
              saveDealCommitment={saveDealCommitment}
              deleteDealCommitment={deleteDealCommitment}
              showModal={showDealCommitmentsModal}
              closeModal={() => this.closeModal('showDealCommitmentsModal')}
            />
          </div>
        </div>
        <div className="my3">
          {dealRoom.can_edit ?
            <DealDataRoomContainer
              company={company}
              dataRoom={dataRoom}
              statsLink={currentUser.investor ? null : `/company/${company.id}/deal_rooms/${dealRoom.id}/data_room_stats`}
            />
            :
            <DealDataRoomReadOnlyContainer
              dataRoom={dataRoom}
              statsLink={currentUser.investor ? null : `/company/${company.id}/deal_rooms/${dealRoom.id}/data_room_stats`}
              currentUser={currentUser}
            />
          }
        </div>
      </>
    );
  }
}

export default DealRoom;
