import PropTypes from 'prop-types';
import classNames from 'classnames';


const ValuesRow = ({ item, source, addCurrency, textColor, showIrr }) => {
  const investmentClasses = classNames('center z1 bold h5 center relative');
  const showDataSource = item.custom_investment ?
    'Custom'
    :
    item.from_investment_profile ?
      'Custom'
      :
      'Company';

  return (
    <div className="col-10 sm-col-6 flex flex-row items-center">
      <div className="flex flex-column width-20p h5 center items-center flex-justify-center lh-12">
        <div className={`${investmentClasses} sm-hide`} style={{ color: textColor(item.ownership) }}>
          {item.ownership}
        </div>
        {(source && (item.ownership !== 'N/A' || item.ownership !== 0)) &&
          <div className={`h6 ${item.from_investment_profile || item.custom_investment ? 'text-blue' : 'text-green'}`}>
            {showDataSource}
          </div>
        }
      </div>
      <div className="flex flex-column width-20p h5 center items-center flex-justify-center lh-12">
        <div className={`${investmentClasses} sm-width-100p`} style={{ color: textColor(item.current_valuation) }}>
          {addCurrency(item.current_valuation, item.currency)}
        </div>
        {(source && (item.current_valuation !== 'N/A' || item.current_valuation !== 0)) &&
          <div className={`h6 ${item.from_investment_profile || item.custom_investment ? 'text-blue' : 'text-green'}`}>
            {showDataSource}
          </div>
        }
      </div>
      <div className="flex flex-column width-20p h5 center items-center flex-justify-center lh-12">
        <div className={`${investmentClasses} sm-hide`} style={{ color: textColor(item.invested) }}>
          {addCurrency(item.invested, item.currency)}
        </div>
        {(source && (item.invested !== 'N/A' || item.invested !== 0)) &&
          <div className={`h6 ${item.from_investment_profile || item.custom_investment ? 'text-blue' : 'text-green'}`}>
            {showDataSource}
          </div>
        }
      </div>
      <div className="flex flex-column width-20p h5 center items-center flex-justify-center lh-12">
        <div className={`${investmentClasses} sm-hide`} style={{ color: textColor(item.realized_cashflow) }}>
          {addCurrency(item.realized_cashflow, item.currency)}
        </div>
        {(source && (item.realized_cashflow !== 'N/A' || item.realized_cashflow !== 0)) &&
          <div className="h6 text-blue">
            Custom
          </div>
        }
      </div>
      <div className="flex flex-column width-20p h5 center items-center flex-justify-center lh-12">
        <div className={`${investmentClasses} sm-hide`} style={{ color: textColor(item.current_share_value) }}>
          {addCurrency(item.current_share_value, item.currency)}
        </div>
        {(source && (item.current_share_value !== 'N/A' || item.current_share_value !== 0)) &&
          <div className={`h6 ${item.from_investment_profile || item.custom_investment ? 'text-blue' : 'text-green'}`}>
            {showDataSource}
          </div>
        }
      </div>
      <div className="flex flex-column width-20p h5 center items-center flex-justify-center lh-12">
        <div className={`${investmentClasses} sm-hide`} style={{ color: textColor(item.investment_multiple) }}>
          {item.investment_multiple}
        </div>
        {(source && (item.investment_multiple !== 'N/A' || item.investment_multiple !== 0)) &&
          <div className={`h6 ${item.from_investment_profile || item.custom_investment ? 'text-blue' : 'text-green'}`}>
            {showDataSource}
          </div>
        }
      </div>
      {showIrr &&
        <div className="flex flex-column width-20p h5 center items-center flex-justify-center lh-12">
          <div className={`${investmentClasses} sm-hide`} style={{ color: textColor(item.xirr) }}>
            {item.xirr ? (item.xirr !== 'N/A' ? `${item.xirr}%` : 'N/A') : ''}
          </div>
          {(source && (item.realized_cashflow !== 'N/A' || item.realized_cashflow !== 0)) &&
            <div className={`h6 ${item.from_investment_profile || item.custom_investment ? 'text-blue' : 'text-green'}`}>
              {showDataSource}
            </div>
          }
        </div>
      }
    </div>
  );
};

ValuesRow.propTypes = {
  item: PropTypes.object.isRequired,
  source: PropTypes.bool.isRequired,
  addCurrency: PropTypes.func.isRequired,
  textColor: PropTypes.func.isRequired,
  showIrr: PropTypes.bool.isRequired,
};

export default ValuesRow;
