import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FaButtonFunction from './tab_view/FaButtonFunction';
import FaButton from './tab_view/FaButton';
import Avatar from './tab_view/Avatar';
import Circle from './Circle';

class CheckBoxTree extends Component {
  static propTypes = {
    nodes: PropTypes.array.isRequired,
    checked: PropTypes.array.isRequired,
    expanded: PropTypes.array.isRequired,
    onExpand: PropTypes.func.isRequired,
    onCheck: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    isRemovable: PropTypes.bool,
  }

  static defaultProps = {
    isRemovable: true,
  }

  handleExpand = (node, isExpanded) => {
    const { expanded, onExpand } = this.props;

    if (isExpanded) {
      onExpand(expanded.filter(value => value !== node.value));
    } else {
      onExpand(expanded.concat(node.value));
    }
  }

  checkedNodes = (node) => {
    const { checked } = this.props;

    return this.isChecked(node) ? checked.filter(value => value !== node.value) : checked.concat(node.value);
  }

  isChecked = (node) => {
    const { checked } = this.props;

    if (node.children) {
      return !!node.children.every(child => this.isChecked(child));
    }

    return checked.includes(node.value) || node.disabled;
  }

  isUnchecked = (node) => {
    const { checked } = this.props;

    if (node.children) {
      return !!node.children.every(child => this.isUnchecked(child));
    }

    return !checked.includes(node.value);
  }

  checkBox = (node) => {
    const { onCheck } = this.props;
    let tooltip;
    let icon;
    let color;

    if (node.disabled || node.highlight) {
      return (
        <FaButton
          faIcon={node.disabled_icon || 'check-square'}
          color={node.disabled_color || 'alto'}
          size="h3"
          tooltip={node.disabled_tooltip || null}
          tooltipPos="right"
          margin="mx0"
          disabled
        />
      );
    }

    if (this.isChecked(node)) {
      tooltip = 'Remove Recipient(s)';
      icon = 'check-square';
      color = 'green';
    } else if (this.isUnchecked(node)) {
      tooltip = 'Add Recipient(s)';
      icon = 'square-o';
      color = 'alto';
    } else {
      tooltip = 'Add Recipient(s)';
      icon = 'check-square-o';
      color = 'alto';
    }

    const targetNode = {
      ...node,
      checked: !this.isChecked(node),
    };

    return (
      <FaButtonFunction
        key={`check_${Date.now()}`}
        faIcon={icon}
        tooltip={tooltip}
        tooltipPos="right"
        handleClick={() => onCheck(this.checkedNodes(node), targetNode)}
        color={color}
        size="h3"
        margin="mx0"
      />
    );
  }

  commonLayout = (node, hasChildren, isExpanded = false) => {
    const { onRemove, isRemovable, checked } = this.props;

    const allNodeChildren = (n, parent = null) => {
      if (n.children) {
        return n.children.flatMap(child => allNodeChildren(child, n));
      }
      if (parent) {
        return [n.value];
      }
      return [];
    };

    return (
      <div className="flex flex-center flex-justify-between pb1" key={node.value}>
        <div className="flex flex-center">
          <div className={`flex ${!hasChildren ? 'mr1' : ''}`}>
            {this.checkBox(node)}
          </div>

          {
            node.icon ?
              <Avatar
                avatarUrl={node.icon}
              />
              :
              <span>
                { !hasChildren &&
                  <Circle
                    bg="wild-sand"
                    border="alto"
                    icon="envelope"
                    size={25}
                    margin=""
                    text="medium-gray"
                    iconSize="12"
                  />
                }
              </span>
          }
          {
            hasChildren ?
              <span>
                <span
                  className="mx1 cursor-pointer"
                  onClick={() => this.handleExpand(node, isExpanded)}
                >
                  {node.label} {hasChildren && `(${allNodeChildren(node).filter(value => checked.includes(value)).length}/${allNodeChildren(node).length})`}
                </span>
                {allNodeChildren(node).length > 0 &&
                  <span
                    className={`cursor-pointer fa fa-caret-${isExpanded ? 'down' : 'right'}`}
                    onClick={() => this.handleExpand(node, isExpanded)}
                  />
                }
              </span>
              :
              <span className="mx1">{node.label}</span>
          }
        </div>

        {
          hasChildren && isRemovable ?
            <div
              className="flex flex-center text-red cursor-pointer"
              onClick={() => onRemove(node)}
            >
              <span className="fa fa-trash" />
              <span className="ml1">Remove</span>
            </div>
            :
            <div />
        }
      </div>
    );
  }

  nodeWithChildren = (node) => {
    const { expanded } = this.props;

    if (expanded.includes(node.value)) {
      return (
        <div className="flex flex-column" key={node.value}>
          {this.commonLayout(node, true, true)}
          <div className="ml2">
            {
              node.children.map(child => (
                child.children ? this.nodeWithChildren(child) : this.commonLayout(child, false)
              ))
            }
          </div>
        </div>
      );
    }
    return (
      <React.Fragment key={node.value}>
        {this.commonLayout(node, true, false)}
      </React.Fragment>
    );
  }

  nodeWithoutChildren = (node) => (
    <div className="flex flex-column" key={node.value}>
      {this.commonLayout(node, true, true)}
    </div>
  )

  render() {
    const { nodes } = this.props;

    return (
      <React.Fragment>
        {
          nodes.map(node => (
            node.children ?
              this.nodeWithChildren(node)
              :
              this.nodeWithoutChildren(node)
          ))
        }
      </React.Fragment>
    );
  }
}

export default CheckBoxTree;
