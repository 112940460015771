import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Avatar from '../tab_view/Avatar';
import ReUploadModal from './ReUploadModal';
import CheckBox from '../CheckBox';
import Circle from '../Circle';
import ConfirmationModal from '../ConfirmationModal';
import HtmlComponent from '../tab_view/HtmlComponent';

class Request extends Component {
  static propTypes = {
    request: PropTypes.object.isRequired,
    uploadSignerDocument: PropTypes.func.isRequired,
    deleteSignerDocument: PropTypes.func.isRequired,
    requestReupload: PropTypes.func.isRequired,
    uploadRequestDocument: PropTypes.func.isRequired,
    deleteRequestDocument: PropTypes.func.isRequired,
    completeRequest: PropTypes.func.isRequired,
  }

  state = {
    loading: false,
    hover: false,
    docId: null,
    docUid: null,
    checkbox: true,
    showModal: false,
    selectedSigner: null,
    selectedDoc: null,
    explanation: true,
  }

  askForReUpload = (signer, doc) => {
    this.setState({
      showModal: true,
      selectedSigner: signer,
      selectedDoc: doc,
    });
  }

  closeModal = () => this.setState({
    showModal: false,
    selectedSigner: null,
    selectedDoc: null,
  })

  completeRequest = () => {
    const { request, completeRequest } = this.props;
    completeRequest(request.company.id, request.id);
  }

  deleteAggregateDocument = id => {
    const { request, deleteRequestDocument } = this.props;
    this.setState({ loading: true });

    const params = {
      signing_room_request: {
        attached_files_attributes: {
          id,
          _destroy: true,
        },
      },
    };

    deleteRequestDocument(request.company.id, request.id, params).then(
      () => {
        this.setState({ loading: false });
        App.State.setFlash({ name: 'notice', msg: 'Signed document removed, Now upload a new copy' });
      },
      () => this.setState({ loading: false }),
    );
  }

  deleteSignedDocument = (id, signerId) => {
    const { request, deleteSignerDocument } = this.props;
    this.setState({ loading: true });

    deleteSignerDocument(request.company.id, request.id, signerId, id).then(
      () => {
        this.setState({ loading: false });
        App.State.setFlash({ name: 'notice', msg: 'Signed document removed, Now upload a new copy' });
      },
      () => this.setState({ loading: false }),
    );
  }

  uploadAggregateDocument = (files, uid) => {
    const { request, uploadRequestDocument } = this.props;
    this.setState({ loading: true, docUid: uid });

    const formData = new FormData();
    files.forEach((file, idx) => {
      formData.append(`signing_room_request[attached_files_attributes][${idx}][uid]`, uid);
      formData.append(`signing_room_request[attached_files_attributes][${idx}][attachment]`, file);
      formData.append(`signing_room_request[attached_files_attributes][${idx}][signed]`, true);
    });

    uploadRequestDocument(request.company.id, request.id, formData).then(
      () => this.setState({ loading: false }),
      () => this.setState({ loading: false }),
    );
  }

  uploadDocument = (files, uid, signerId) => {
    const { request, uploadSignerDocument } = this.props;
    this.setState({ loading: true, docUid: uid });

    const formData = new FormData();
    formData.append('signer_document[uid]', uid);
    formData.append('signer_document[attachment]', files[0]);
    formData.append('signer_document[signed]', true);

    uploadSignerDocument(request.company.id, request.id, signerId, formData).then(
      () => this.setState({ loading: false }),
      err => console.log(err),
    );
  }

  statusColor() {
    const { request } = this.props;
    switch (request.state) {
      case 'ongoing':
        return 'text-blue';
      case 'completed':
        return 'text-green';
      case 'cancelled':
        return 'text-red';
      default:
        return 'text-light-gray';
    }
  }

  statusIcon() {
    const { request } = this.props;
    switch (request.state) {
      case 'ongoing':
        return 'fa-hourglass-half';
      case 'completed':
        return 'fa-check';
      case 'cancelled':
        return 'fa-times';
      default:
        return 'fa-pencil';
    }
  }

  statusMessage() {
    const { request } = this.props;
    switch (request.state) {
      case 'ongoing':
        return 'Ongoing (Awaiting Signatures)';
      case 'completed':
        return 'Completed';
      case 'cancelled':
        return 'Cancelled';
      default:
        return '';
    }
  }

  statusText() {
    const { request } = this.props;
    switch (request.state) {
      case 'ongoing':
        return 'Signing(s) Requested by';
      case 'completed':
        return '';
      case 'cancelled':
        return '';
      default:
        return '';
    }
  }

  render() {
    const { request, requestReupload } = this.props;
    const {
      loading, hover, docId, docUid, checkbox,
      showModal, selectedSigner, selectedDoc,
      explanation,
    } = this.state;

    const currentSigner = request.signers.filter(signer => signer.id === request.current_signer_id)[0];

    return (
      <div className="container container-report mt3 pt3 lg-px2 bg-white py3 px3 bs-around relative sm-h-auto sm-mh-auto mb4">
        <div className="flex flex-column items-center text-gray mb3 pb2 col-12">
          <div className="flex items-center col-12 mt2">
            <p className="flex flex-justify-end col-3 pr2 m0 fs-38">
              <i className={`fa ${this.statusIcon()} ${this.statusColor()}`} />
            </p>
            <div className="flex flex-column col-7">
              <p className="flex h1 fs-28 text-gray lh-12 fw400 m0">{request.title}</p>
              <p className="flex m0 h5 text-gray">{request.state !== 'completed' ? `Signature(s) Requested by ${request.user.name}` : ''}</p>
            </div>
          </div>
          <div className="flex col-8 border-none border-lighter-gray my05 pt1" />
          <div className="flex items-center col-12">
            <p className="flex flex-justify-end col-3 text-gray fw400 pr2 m0">
              Status
            </p>
            <div className="flex flex-column col-7 text-gray capitalize">
              <p className="m0"> {this.statusMessage()}</p>
            </div>
          </div>
          {request.due_date && (
            <div className="flex flex-column items-center col-12">
              <div className="flex col-8 border-none border-lighter-gray my05" />
              <div className="flex items-center col-12">
                <p className="flex flex-justify-end col-3 text-gray fw400 pr2  m0">
                  Due on
                </p>
                <div className="flex flex-column col-8 text-gray capitalize">
                  <p className="m0">{request.due_date}</p>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-column items-center col-12">
            <div className="flex col-8 border-none border-lighter-gray my05" />
            <div className="flex items-center col-12">
              <p className="flex flex-justify-end col-3 text-gray fw400 pr2  m0">
                Signer(s)
              </p>
              <div className="flex flex-wrap col-8 text-gray">
                {request.signers.map(signer => (
                  <div key={`signer_${signer.id}`} className="flex text-gray mr1">
                    <div className="flex items-center h5">
                      <Avatar avatarUrl={signer.avatar} size="20px" />
                      <span className="mx05">{signer.name}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {request.message && (
            <div className="flex flex-column items-center col-12">
              <div className="flex col-8 border-none border-lighter-gray my05" />
              <div className="flex col-12">
                <p className="flex flex-justify-end col-3 text-gray fw400 pr2 m0">
                  Message
                </p>
                <div className="flex flex-column col-7 text-gray">
                  <HtmlComponent
                    data={{ html: request.formatted_message, css_class: 'm0' }}
                  />
                </div>
              </div>
            </div>
          )}
          {request.attached_documents.length > 0 && (
            <div className="flex flex-column items-center col-12">
              <div className="flex col-8 border-none border-lighter-gray my05" />
              <div className="flex col-12">
                <p
                  className="flex flex-justify-end col-3 text-gray fw400 pr2 m0"
                >
                  <span
                    data-balloon="These documents do NOT need to be signed."
                    data-balloon-pos="down"
                    data-balloon-length="large"
                  >
                    Attachment(s)
                  </span>
                </p>
                <div className="flex flex-column col-8 text-gray capitalize">
                  {request.attached_documents.map(doc => (
                    <div key={`doc_${doc.id}`} className="flex items-center">
                      <a
                        className="flex items-center"
                        href={doc.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className={`mr1 ${doc.icon_class_name}`} style={{ color: doc.icon_color }} />
                        {doc.name}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        { (request.documents_to_sign.length > 0 && currentSigner && !currentSigner.signed_all_documents && request.state === 'ongoing') &&
        <div className="m2 border border-lighter-gray mx3 mb3">
          <div
            className="fw400 p1 pl2 cursor-pointer"
            onClick={() => this.setState({ explanation: !explanation })}
          >
            How to Sign Documents
            <i className={`fa fa-caret-${explanation ? 'down' : 'right'} ml1 cursor-pointer`} />
          </div>
          <ReactCSSTransitionGroup transitionName="modal" transitionEnterTimeout={500} transitionLeaveTimeout={300}>
            {
              explanation &&
              <div className="flex flex-column px2 text-gray">
                <div className="flex items-center mb1">
                  <Circle content="1" text="light-gray" border="light-gray" />
                  <span className="fw400 text-gray">Download all &quot;Document( s) to Sign&quot;</span>
                </div>
                <div className="flex items-center mb1">
                  <Circle content="2" text="light-gray" border="light-gray" />
                  <span className="fw400 text-gray">Review and sign all document(s)</span>
                </div>
                <div className="flex items-center mb1">
                  <Circle content="3" text="light-gray" border="light-gray" />
                  <span className="fw400 text-gray">Upload each signed document in the orange &quot;Signing Status&quot; section</span>
                </div>
                <div className="flex items-center mb1">
                  <Circle content="4" text="light-gray" border="light-gray" />
                  <span className="fw400 text-gray">After uploading all documents you are done!</span>
                </div>
              </div>
            }
          </ReactCSSTransitionGroup>
        </div>
        }

        {request.documents_to_sign.length > 0 &&
          <div className="m2 border border-lighter-gray mx3 mb3">
            <div className="fw400 bg-wild-sand p1">
              <i className="fa fa-pencil mx1" />
              Document(s) to Sign
            </div>
            <div className="flex flex-column px2 pt2 text-gray">
              <div>
                {request.documents_to_sign.map(doc => (
                  <div key={`doc_${doc.id}`} className="flex items-center text-gray mb2">
                    <span className="flex col-10">
                      <a
                        href={doc.url}
                        className="flex items-center"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className={`mr1 ${doc.icon_class_name} fa-2x`} style={{ color: doc.icon_color }} />
                        {doc.name}
                      </a>
                    </span>
                    <a
                      className="col-2 text-light-gray"
                      href={doc.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-download mr1" />Download
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }

        {
          !request.type_archive &&
          <div className={`m2 mb3 mx3 border border-${!currentSigner ? 'lighter-gray' : (currentSigner.signed_all_documents ? 'green' : 'orange')} `}>
            <div className={`fw400 ${!currentSigner ? 'bg-wild-sand' : (currentSigner.signed_all_documents ? 'bg-green text-white' : 'bg-orange text-white')} p1`}>
              <i className="fa fa-bar-chart mx1" />
              {!currentSigner ?
                'Document Signer(s)' :
                (currentSigner.signed_all_documents ?
                  'Signed Document(s) - All Done! You uploaded all required document(s) successfully.' :
                  'Signed Document(s) - Awaiting Your Signature(s)'
                )
              }
            </div>
            {request.signers.map(signer => (
              <div key={`signer_${signer.id}`} className="m2 pb1 text-gray">
                <div className="flex items-center mb2">
                  <Avatar avatarUrl={signer.avatar} />
                  <span className="ml1 fw400" data-balloon={signer.email}>{signer.name}</span>
                </div>
                {request.documents_to_sign.map(doc => {
                  const signedDoc = signer.signed_documents.find(d => d.uid === doc.uid);
                  if (signedDoc) {
                    return (
                      <div key={`doc_${doc.id}`} className="flex flex-justify-between mb1 pl1">
                        <a
                          href={signedDoc.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="col-10"
                        >
                          <i
                            className={`mr1 ${doc.icon_class_name}`}
                            style={{ color: doc.icon_color }}
                          />
                          {signedDoc.name}
                        </a>
                        <span
                          className="flex items-center"
                          onMouseEnter={() => this.setState({ hover: true, docId: signedDoc.id })}
                          onMouseLeave={() => this.setState({ hover: false, docId: null })}
                        >
                          {hover && docId === signedDoc.id ?
                            <div>
                              {((currentSigner && currentSigner.id === signer.id) || request.current_user.id === request.user.id) && request.state === 'ongoing' ?
                                <span
                                  className="text-gray cursor-pointer"
                                  onClick={() => this.deleteSignedDocument(signedDoc.id, signer.id)}
                                >
                                  <i className="fa fa-repeat mr1" />Re-Upload
                                </span>
                                :
                                request.current_user.collaborator && request.state === 'ongoing' ?
                                  <span
                                    className="text-gray cursor-pointer"
                                    onClick={() => this.askForReUpload(signer, signedDoc)}
                                  >
                                    <i className="fa fa-repeat mr1" />Ask for Re-Upload
                                  </span>
                                  :
                                  <span className="text-green">
                                    <i className="fa fa-check mr1" />Uploaded
                                  </span>
                              }
                            </div>
                            :
                            <span className="text-green">
                              <i className="fa fa-check mr1" />Uploaded
                            </span>
                          }
                        </span>
                      </div>
                    );
                  }
                  if ((currentSigner && currentSigner.id === signer.id) || request.current_user.id === request.user.id) {
                    return (
                      <div key={`doc_${doc.id}`} className="flex col-12 items-center ml3 pb1">
                        {loading && docUid === doc.uid ?
                          <img src={window.images.loading} alt="loading..." width="32" height="32" />
                          :
                          request.state === 'ongoing' &&
                          <Dropzone
                            className="col-4 center border--dashed border-light-gray text-light-gray cursor-pointer px2 py1 rounded"
                            onDrop={files => this.uploadDocument(files, doc.uid, signer.id)}
                          >
                            Click or Drag & Drop here...
                          </Dropzone>
                        }
                        { ((request.current_user.id === request.user.id) && (currentSigner && currentSigner.id !== signer.id)) ?
                          <div
                            className="col-7 ml2 text-light-gray"
                            data-balloon="If you have received signed document(s) outside of the platform, you can upload them on behalf of the requested signer here.
                            Please note: The original recipient will receive a notification of a successful upload."
                            data-balloon-pos="down"
                            data-balloon-length="large"
                          >
                            Upload signed copy for <span className="text-gray">&quot;{signer.name}&quot;</span>;
                          </div>
                          :
                          <div className="col-7 ml2 text-light-gray">
                            Upload your signed copy of &quot;<a href={doc.url}>{doc.name}</a>&quot;
                          </div>
                        }
                      </div>
                    );
                  }
                  return (
                    <div key={`doc_${doc.id}`} className="flex flex-justify-between mb1 ml3">
                      <div className="text-light-gray">
                        Signed copy of &quot;{doc.name}&quot; not uploaded yet
                      </div>
                      <span className="text-orange fw400">
                        <i className="fa fa-question mr1" />Outstanding
                      </span>
                    </div>
                  );
                })}
              </div>
            ))}
            <ReUploadModal
              show={showModal}
              closeModal={this.closeModal}
              request={request}
              signer={selectedSigner}
              document={selectedDoc}
              requestReupload={requestReupload}
            />
          </div>
        }

        {request.current_user.collaborator && request.state === 'ongoing' &&
          <div className="border border-lighter-gray m2 mx3 mb3">
            <div className="flex bg-wild-sand p1">
              <div className="flex-auto bold cursor pointer fw400">
                <span className="fw400">
                  <i className="fa fa-flag-checkered mx1" />
                  Finalize Signing
                </span>
              </div>
              <div className="flex items-center h5">
                <CheckBox
                  size="17px"
                  defaultChecked={checkbox}
                  onChange={() => this.setState({ checkbox: !checkbox })}
                />
                <span className="ml1">Upload Completed Document(s)</span>
              </div>
            </div>
            {checkbox &&
              <div className="m2 text-gray border-bottom border-lighter-gray">
                {request.documents_to_sign.map(doc => {
                  const signedDoc = request.signed_documents.find(d => d.uid === doc.uid);
                  if (signedDoc) {
                    return (
                      <div key={`doc_${doc.id}`} className="flex flex-justify-between mb2 pl1">
                        <a href={signedDoc.url} target="_blank" rel="noopener noreferrer" className="flex items-center">
                          <i
                            className={`mr1 fa-2x ${signedDoc.icon_class_name}`}
                            style={{ color: signedDoc.icon_color }}
                          />
                          {signedDoc.name}
                        </a>
                        <span
                          className="flex items-center"
                          onMouseEnter={() => this.setState({ hover: true, docId: signedDoc.id })}
                          onMouseLeave={() => this.setState({ hover: false, docId: null })}
                        >
                          {hover && docId === signedDoc.id ?
                            <div>
                              <span
                                className="text-gray cursor-pointer"
                                onClick={() => this.deleteAggregateDocument(signedDoc.id)}
                              >
                                <i className="fa fa-repeat mr1" />Re-Upload
                              </span>
                            </div>
                            :
                            <span className="text-green">
                              <i className="fa fa-check mr1" />Uploaded
                            </span>
                          }
                        </span>
                      </div>
                    );
                  }
                  return (
                    <div key={`doc_${doc.id}`} className="flex items-center mb2 pl1">
                      {loading && docUid === doc.uid ?
                        <img src={window.images.loading} alt="loading..." width="32" height="32" />
                        :
                        <Dropzone
                          className="col-4 center border--dashed border-light-gray text-light-gray cursor-pointer px2 py1 rounded mr2"
                          onDrop={files => this.uploadAggregateDocument(files, doc.uid)}
                        >
                          Click or Drag & Drop here...
                        </Dropzone>
                      }
                      <div className="col-7 text-light-gray">
                        Upload complete copy of &quot;<a href={doc.url}>{doc.name}</a>&quot;
                      </div>
                    </div>
                  );
                })}
              </div>
            }
            <div className="flex items-center text-gray m2">
              <div className="flex flex-auto">
                <div className="fw400">Check & Finish Signing</div>
                <div
                  className="text-gray ml1 cursor-pointer"
                  data-balloon="Make sure that all signers have signed the document(s).
                  If a signature is missing or not correct, hover over the status above to request re-upload or send a reminder."
                  data-balloon-pos="down"
                  data-balloon-length="large"
                >
                  <i className="fa fa-info-circle" />
                </div>
              </div>
              <ConfirmationModal
                className="border border-green bg-green text-white rounded px2 py1 cursor-pointer"
                html="Finish Signing"
                confirmationText="Finish Signing"
                message="Finish Signing will finish the signing process and prevent any further documents to be uploaded.</br>Are you sure you want to finish signing?<br/><br/><i><b>Note:</b> No document upload is possible afterwards!</i>"
                onClick={() => this.completeRequest()}
                width="auto"
              />
            </div>
          </div>
        }

        {request.state === 'completed' &&
          <div className="m2 mx3 mb3 flex flex-column">
            {request.signed_documents.length > 0 &&
              <div className="border border-lighter-gray mb2">
                <div className="flex bg-wild-sand p1">
                  <div className="flex-auto bold cursor pointer fw400">
                    <span className="fw400">
                      <i className="fa fa-flag-checkered mx1" />
                      Completed Document(s)
                    </span>
                  </div>
                </div>
                <div className="m2 text-gray">
                  {request.signed_documents.map(signedDoc => (
                    <div key={`doc_${signedDoc.id}`} className="flex flex-justify-between mb2 pl1">
                      <a href={signedDoc.url} target="_blank" rel="noopener noreferrer">
                        <i
                          className={`mr1 ${signedDoc.icon_class_name}`}
                          style={{ color: signedDoc.icon_color }}
                        />
                        {signedDoc.name}
                      </a>
                      <span
                        className="flex items-center"
                      >
                        <span className="text-green">
                          <i className="fa fa-check mr1" />Uploaded
                        </span>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            }
            <div className="flex flex-justify-end">
              <a
                className="border border-light-gray text-light-gray rounded px2 py1"
                href={`/company/${request.company.id}/signing_room_requests/${request.id}/summaries/new`}
              >
                Send Summary
              </a>
            </div>
          </div>
        }

      </div>
    );
  }
}

export default Request;
