import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../../store/configureStore';
import ProfileContainer from '../../containers/investor/investments/ProfileContainer';


const InvestorPortfolio = props => (
  <Provider store={configureStore(props)}>
    <ProfileContainer />
  </Provider>
);

export default InvestorPortfolio;
