import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import Axios from 'axios';

import Modal from '../../Modal';
import TextInput from '../../TextInput';
import DatePicker from '../../selects/DatePicker';
import CleaveNumberFormatting from '../../CleaveNumberFormatting';
import CheckBox from '../../CheckBox';

import { defaultSelectStyles, defaultSelectTheme } from '../../selects/SelectStyles';

class RoundModal extends Component {
  static propTypes = {
    round: PropTypes.object,
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    company: PropTypes.object.isRequired,
    updateRound: PropTypes.func.isRequired,
  }

  static defaultProps = {
    round: {},
  }

  constructor(props) {
    super(props);

    /* eslint-disable camelcase */
    const roundParams = (({
      round_type,
      closing_date,
      round_name,
      visible,
      cash_raised,
      share_price,
      pre_money,
      post_money,
      disable_share_price,
      disable_pre_money,
      disable_cash_raised,
    }) => ({
      round_type,
      closing_date,
      round_name,
      visible,
      cash_raised,
      share_price,
      pre_money,
      post_money,
      disable_share_price,
      disable_pre_money,
      disable_cash_raised,
    }))(props.round);
    /* eslint-enable camelcase */

    this.state = {
      title: props.round.id ? 'Edit Round' : 'New Round',
      round: roundParams,
      saving: false,
    };
  }

  handleChange = (name, value) => {
    this.setState(state => ({
      ...state,
      round: {
        ...state.round,
        [name]: value,
      },
    }), () => {
      if (['cash_raised', 'pre_money'].includes(name)) {
        this.setState(state => ({
          ...state,
          round: {
            ...state.round,
            post_money: parseFloat(state.round.cash_raised) + parseFloat(state.round.pre_money),
          },
        }));
      }
    });
  }

  submit = async () => {
    const { round: { id }, company, closeModal, updateRound } = this.props;
    const { round, saving } = this.state;

    if (saving) return;
    this.setState({ saving: true });

    if (typeof round.round_name === 'undefined' || typeof round.round_type === 'undefined' || [round.round_name, round.round_type].includes('')) {
      this.setState({ saving: false });
      App.State.setFlash({ name: 'alert', msg: 'Required fields are missing!' });
      return;
    }

    try {
      if (id) {
        await updateRound(round);
        closeModal();
        App.State.setFlash({ name: 'notice', msg: 'Round updated successfully!' });
      } else {
        const res = await Axios.post(`/company/${company.id}/manual_cap_tables`, { manual_cap_table: round });
        window.location.href = `/c/${company.id}/manual_cap_tables/${res.data.id}`;
      }
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.headers.errors || 'Something went wrong! Refresh the page or try again later.' });
    }
  }

  render() {
    const { show, company, closeModal, options } = this.props;
    const { title, round, saving } = this.state;

    return (
      <Modal show={show}>
        <div className="flex flex-justify-between items-center bold p2 px3 border-bottom border-lighter-gray">
          <div className="h3">{title}</div>
          <i className="fa fa-close h5 cursor-pointer" onClick={closeModal} />
        </div>

        <div className="text-gray fw400 px3 py2">
          <div className="flex">
            <div className="col-6 mr3">
              <span>
                <span
                  className="h5"
                  data-balloon="The round type defines the category of a round falls into (list includes a common selection of round types)."
                  data-balloon-pos="right"
                  data-balloon-length="xlarge"
                >
                  Round Type
                  <span className="text-red">*</span>
                  <i className="fa fa-info-circle text-light-gray ml1" />
                </span>
              </span>
              <ReactSelect
                className="mt1"
                name="round_type"
                value={options.find(o => o.value === round.round_type)}
                options={options}
                onChange={selectedOption => this.handleChange('round_type', selectedOption.value)}
                styles={defaultSelectStyles}
                theme={defaultSelectTheme}
              />
            </div>
            <div className="col-6">
              <span>
                <span
                  className="h5"
                  data-balloon="The closing date is the date when the round was signed or otherwise finalised."
                  data-balloon-pos="right"
                  data-balloon-length="large"
                >
                  Closing Date
                  <i className="fa fa-info-circle text-light-gray ml1" />
                </span>
              </span>
              <DatePicker
                value={round.closing_date || ''}
                className="col-12 border border-gallery mt1 input-alto__focus"
                name="closing_date"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-column mt2">
            <span>
              <span
                className="h5"
                data-balloon="The round name describes the funding round in a word or two."
                data-balloon-pos="right"
                data-balloon-length="xlarge"
              >
                Round Name
                <span className="text-red">*</span>
                <i className="fa fa-info-circle text-light-gray ml1" />
              </span>
            </span>
            <TextInput
              className="col-12 border border-gallery mt1 input-alto__focus"
              name="round_name"
              value={round.round_name}
              onChange={e => this.handleChange(e.target.name, e.target.value)}
            />
          </div>

          <div className="flex flex-justify-between items-center">
            <div className="flex-auto border-top border-gallery my3" />
            <span className="center px2 h5 text-medium-gray">Round Details</span>
            <div className="flex-auto border-top border-gallery my3" />
          </div>

          <div className="flex">
            <div className="col-6 flex flex-column mr3">
              <div className="flex">
                <span
                  className="h5"
                  data-balloon="Cash Raised is how much money was raised from the shareholders in this round."
                  data-balloon-pos="right"
                  data-balloon-length="xlarge"
                >
                  Cash Raised
                  <i className="fa fa-info-circle text-light-gray ml1" />
                </span>
                <div className="ml1 h6 text-light-gray flex">
                  (
                  <span className="mr1">Disable Value</span>
                  <CheckBox
                    defaultChecked={round.disable_cash_raised}
                    onChange={() => { this.handleChange('disable_cash_raised', !round.disable_cash_raised); this.handleChange('cash_raised', null); }}
                    size="15px"
                    fixedWidth
                  />
                  )
                </div>
              </div>
              <CleaveNumberFormatting
                value={round.cash_raised || ''}
                unit={company.currency}
                alignUnit={false}
                className="col-12 border border-gallery mt1 input-alto__focus"
                name="cash_raised"
                onChange={e => this.handleChange(e.target.name, e.target.rawValue)}
                disabled={round.disable_cash_raised}
                placeholder={round.disable_cash_raised ? 'Not Applicable' : null}
              />
            </div>
            <div className="col-6 flex flex-column">
              <div className="flex">
                <span
                  className="h5"
                  data-balloon="The share price is the amount of money paid for each share."
                  data-balloon-pos="right"
                  data-balloon-length="xlarge"
                >
                  Share Price
                  <i className="fa fa-info-circle text-light-gray ml1" />
                </span>
                <div className="ml1 h6 text-light-gray flex">
                  (
                  <span className="mr1">Disable Value</span>
                  <CheckBox
                    defaultChecked={round.disable_share_price}
                    onChange={() => { this.handleChange('disable_share_price', !round.disable_share_price); this.handleChange('share_price', null); }}
                    size="15px"
                    fixedWidth
                  />
                  )
                </div>
              </div>
              <CleaveNumberFormatting
                value={round.share_price || ''}
                unit={company.currency}
                alignUnit={false}
                className="col-12 border border-gallery mt1 input-alto__focus"
                name="share_price"
                onChange={e => this.handleChange(e.target.name, e.target.rawValue)}
                disabled={round.disable_share_price}
                placeholder={round.disable_share_price ? 'Not Applicable' : null}
              />
            </div>
          </div>
          <div className="flex mt2">
            <div className="col-6 flex flex-column mr3">
              <div className="flex">
                <span
                  className="h5"
                  data-balloon="The pre-money valuation refers to the company's valuation before the funding round (without the raised cash)."
                  data-balloon-pos="right"
                  data-balloon-length="xlarge"
                >
                  Pre-Money
                  <i className="fa fa-info-circle text-light-gray ml1" />
                </span>
                <div className="ml1 h6 text-light-gray flex">
                  (
                  <span className="mr1">Disable Value</span>
                  <CheckBox
                    defaultChecked={round.disable_pre_money}
                    onChange={() => { this.handleChange('disable_pre_money', !round.disable_pre_money); this.handleChange('pre_money', null); }}
                    size="15px"
                    fixedWidth
                  />
                  )
                </div>
              </div>
              <CleaveNumberFormatting
                value={round.pre_money || ''}
                unit={company.currency}
                alignUnit={false}
                className="col-12 border border-gallery mt1 input-alto__focus"
                name="pre_money"
                onChange={e => this.handleChange(e.target.name, e.target.rawValue)}
                disabled={round.disable_pre_money}
                placeholder={round.disable_pre_money ? 'Not Applicable' : null}
              />
            </div>
            <div className="col-6 flex flex-column">
              <div>
                <span
                  className="h5"
                  data-balloon="The post-money valuation is automatically calculated by adding the amount of cash raised to the pre-money value."
                  data-balloon-pos="right"
                  data-balloon-length="xlarge"
                >
                  Post-Money
                  <i className="fa fa-info-circle text-light-gray ml1" />
                </span>
              </div>
              <CleaveNumberFormatting
                value={round.post_money || ''}
                unit={company.currency}
                alignUnit={false}
                className="col-12 border border-gallery bg-gallery mt1 input-alto__focus"
                name="post_money"
                disabled
                placeholder={(round.disable_cash_raised || round.disable_pre_money) ? 'Not Applicable' : null}
              />
            </div>
          </div>
        </div>

        <div className="border-top border-gallery px3 py2 fw400 flex flex-justify-end items-center">
          <div className="text-blue mr2 cursor-pointer" onClick={closeModal}>Cancel</div>
          <div className={`text-white bg-blue px3 py1 rounded ${saving ? 'cursor-disabled' : 'cursor-pointer'}`} onClick={this.submit}>{saving ? 'Saving...' : 'Save'}</div>
        </div>
      </Modal>
    );
  }
}

export default RoundModal;
