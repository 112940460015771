import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import RenameModal from '../modals/RenameModal';
import DropdownMenu from '../DropdownMenu';
import SaveLinkModal from './SaveLinkModal';

import DataRoomSectionRow from './DataRoomSectionRow';
import UpgradeAccountModal from '../modals/UpgradeAccountModal';


class DataRoomSections extends Component {
  static propTypes = {
    editor: PropTypes.bool.isRequired,
    blockCreation: PropTypes.bool.isRequired,
    sections: PropTypes.array.isRequired,
    selected: PropTypes.number.isRequired,
    selectSection: PropTypes.func.isRequired,
    createSection: PropTypes.func.isRequired,
    renameSection: PropTypes.func.isRequired,
    deleteSection: PropTypes.func.isRequired,
    updateSectionPosition: PropTypes.func.isRequired,
    uploadFiles: PropTypes.func.isRequired,
    saveLink: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { sections } = this.props;

    this.state = {
      showNewSectionModal: false,
      showNewLinkModal: false,
      newLinkType: 'others',
      sectionsList: sections,
      showPaywall: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { sections } = nextProps;

    this.setState({ sectionsList: sections });
  }

  closeModal = () => {
    this.setState({
      showNewSectionModal: false,
      showNewLinkModal: false,
    });
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = result => {
    const { updateSectionPosition } = this.props;

    if (!result.destination) return;

    const { sectionsList } = this.state;
    const params = { position: result.destination.index + 1 };

    const list = this.reorder(
      sectionsList,
      result.source.index,
      result.destination.index,
    );

    // Somehow there's an issue when the position is not quite in sequence.
    // The reorder method adds an undefined element to the list. The filter below removes it.
    this.setState({ sectionsList: list.filter(el => el) }, () => updateSectionPosition(result.draggableId, params));
  }

  renderAddButtonDropdown = text => {
    const { blockCreation, createSection, uploadFiles, saveLink } = this.props;
    const { showNewSectionModal, showNewLinkModal, newLinkType, showPaywall } = this.state;

    return (
      <DropdownMenu faIcon="plus-circle" text={text} textColor="gray" dropdownClass="center__data-room left-100px">
        <li>
          <Dropzone
            multiple={false}
            className="flex px1 sm-col-12 cursor-pointer hover hover-bg-wild-sand hover-text-blue"
            onDrop={file => { blockCreation ? this.setState({ showPaywall: true }) : uploadFiles(file) }}
          >
            <div className="flex flex-center">
              <span className="block bold h5 lh-36 px1 text-gray nowrap">New Document</span>
            </div>
          </Dropzone>
        </li>
        <li>
          <div
            className="flex px1 sm-col-12 cursor-pointer hover hover-bg-wild-sand hover-text-blue"
            onClick={() => { blockCreation ? this.setState({ showPaywall: true }) : this.setState({ showNewSectionModal: true }) }}
            role="menuitem"
            tabIndex="0"
          >
            <div className="block bold h5 lh-36 px1 nowrap">
              <span className="text-gray">New Folder</span>
            </div>
          </div>
        </li>
        <li>
          <div className="flex px1 sm-col-12 cursor-default">
            <span className="block h6 hover fw400 px1 pt1 text-light-gray">Or link to documents from...</span>
          </div>
        </li>
        <li>
          <div
            className="flex flex-center hover hover-bg-wild-sand hover-text-blue"
            onClick={() => { blockCreation ? this.setState({ showPaywall: true }) : this.setState({ showNewLinkModal: true, newLinkType: 'drive' })}}
          >
            <i className="fa fa-google fa-fw px2 text-gray" />
            <span className="block bold h5 lh-36 px1 text-gray">Google Drive</span>
          </div>
        </li>
        <li>
          <div
            className="flex flex-center hover hover-bg-wild-sand hover-text-blue"
            onClick={() => { blockCreation ? this.setState({ showPaywall: true }) : this.setState({ showNewLinkModal: true, newLinkType: 'dropbox' }) }}
          >
            <i className="fa fa-dropbox fa-fw px2 text-gray" />
            <span className="block bold h5 lh-36 px1 text-gray">Dropbox</span>
          </div>
        </li>
        <li>
          <div
            className="flex flex-center hover hover-bg-wild-sand hover-text-blue"
            onClick={() => { blockCreation ? this.setState({ showPaywall: true }) : this.setState({ showNewLinkModal: true, newLinkType: 'others' }) }}
          >
            <i className="fa fa-link fa-fw px2 text-gray" />
            <span className="block bold h5 lh-36 px1 text-gray">Others</span>
          </div>
        </li>
        <RenameModal
          show={showNewSectionModal}
          closeModal={this.closeModal}
          title="New Folder"
          initialName="New Folder"
          onSubmit={name => createSection({ name })}
        />
        <SaveLinkModal
          show={showNewLinkModal}
          closeModal={this.closeModal}
          type={newLinkType}
          saveLink={saveLink}
        />
        <UpgradeAccountModal
          show={showPaywall}
          closeModal={() => this.setState({ showPaywall: false })}
        />
      </DropdownMenu>
    );
  }

  render() {
    const {
      editor,
      selected,
      selectSection,
      renameSection,
      deleteSection,
    } = this.props;
    const { sectionsList } = this.state;

    return (
      <div className="flex flex-column">
        {editor ?
          <div>
            <div className="p2 text-black bold lh-16">
              {this.renderAddButtonDropdown('Add...')}
            </div>
            <div className="divider__comments--gray mx2 mb1" />
          </div>
          :
          <div className="my1" />
        }
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.dragHandleProps}
                {...provided.draggableProps}
              >
                {sectionsList.map(section => (
                  <DataRoomSectionRow
                    key={section.id}
                    section={section}
                    editor={editor}
                    selected={selected}
                    selectSection={selectSection}
                    renameSection={renameSection}
                    deleteSection={deleteSection}
                  />
                ))}
                {provided.placeholder}
              </div>)}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

export default DataRoomSections;
