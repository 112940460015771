import React, { Component } from 'react';
import Dropzone from 'react-dropzone';


class ConversationAttachmentBox extends Component {
  constructor(props) {
    super(props);

    const data = JSON.parse(props.data);

    this.state = {
      attachedFiles: data.attached_files,
      uploading: false,
    };
  }

  onDrop = (files) => {
    for (const file of files) {
      this.uploadFile(file);
    }
  }

  getAttachedFiles = () => {
    const url = this.props.attached_files_url;

    App.Api
      .getConversationAttachedFiles(url)
      .then((data) => {
        this.setState({
          attachedFiles: data.attached_files,
          uploading: false,
        });
      });
  }

  attachments = () => {
    const { attachedFiles } = this.state;

    return attachedFiles.map((attachedFile, index) => {
      const iconColorStyle = {
        backgroundImage: attachedFile.icon_color,
      };

      return (
        <div key={index} className="border-bottom border-alto flex flex-center p1 relative">
          <a
            href={attachedFile.url}
            className="absolute bottom-0 left-0 right-0 top-0"
            target="_blank"
          />
          <div style={iconColorStyle} className="bg-white border border-alto box-40 flex flex-center flex-justify-center mr1 relative">
            <i className={`fs-24 text-white ${attachedFile.icon_class_name}`} />
          </div>

          <div className="col-9">
            <p className="h5 m0 text-gray truncate">{attachedFile.file_name}</p>
            <p className="fs-10 m0 text-light-gray">{attachedFile.updated_at}</p>
          </div>
        </div>
      );
    });
  }

  dropZone = () => {
    const dropZoneStyle = {};

    return (
      <Dropzone
        style={dropZoneStyle}
        onDrop={this.onDrop}
      >
        {this.dropZonePlaceHolder()}
        <p className="bold m0 p2">Drop files here to upload</p>
      </Dropzone>
    );
  }

  dropZonePlaceHolder = () => {
    if (this.state.attachedFiles.length > 0 || this.state.uploading) return null;

    return (
      <div className="border-bottom border-alto flex flex-center flex-justify-center fs-40 height-240 text-outer-space">
        <i className="fa fa-upload" />
      </div>
    );
  }

  uploadFile = (file) => {
    const url = this.props.attached_file_upload_url;

    this.setState({ uploading: true });
    App.Api
      .uploadFile(url, file)
      .then(this.getAttachedFiles)
      .catch(() => {
        this.setState({ uploading: false });
        App.State.setFlash({
          name: 'alert',
          msg: 'upload failed',
        });
      });
  }

  uploading = () => {
    if (this.state.uploading) {
      return (
        <div className="border-bottom border-alto p2 relative">
          <img
            src={this.props.uploadGif}
            style={{ opacity: 0.2 }}
            width={'150'}
            alt="Uploading..."
          />
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div id="attachments">
        <div className="bg-white border border-alto">
          {this.attachments()}
          {this.uploading()}
          {this.dropZone()}
        </div>
      </div>
    );
  }
}

export default ConversationAttachmentBox;
