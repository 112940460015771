import axios from 'axios';

import {
  updateKpiFieldState,
  addKpiField,
  removeKpiField,
  updateKpiInChart,
  updateKpisByDate,
  sameState,
} from '../../../actions/investor/investment/investmentKpiActions';

//
// Either send a post request to create a new kpi_field_value or send a patch request to update the already
// existing kpi_field_value depending on the return value when consulting the kpi_field_value.
//
const saveKpiFieldValue = (investorId, investmentId, kpiFieldId, params) =>
  async (dispatch, getState) => {
    const currentDate = getState().investmentKpis.current_date;
    const searchRes = await axios.get(`/investor/${investorId}/investments/${investmentId}/kpi_fields/${kpiFieldId}/kpi_field_values/search_kpi_field_value?current_date=${currentDate}`);
    const kpiFieldValueId = searchRes.data.id;
    let res;

    if (kpiFieldValueId) {
      res = await axios.patch(`/investor/${investorId}/investments/${investmentId}/kpi_fields/${kpiFieldId}/kpi_field_values/${kpiFieldValueId}`, params);
    } else {
      res = await axios.post(`/investor/${investorId}/investments/${investmentId}/kpi_fields/${kpiFieldId}/kpi_field_values`, params);
    }

    dispatch(updateKpiFieldState(res.data));
  };

const createKpiField = (investorId, investmentId, params) =>
  async dispatch => {
    await axios.post(`/investor/${investorId}/investments/${investmentId}/kpi_fields`, params).then((res) => {
      dispatch(addKpiField(res.data));
    });
  };

const updateKpiField = (kpiFieldId, params) =>
  async (dispatch, getState) => {
    const investorId = getState().investmentHeader.investor.id;
    const investmentId = getState().investmentHeader.id;
    const currentDate = getState().investmentKpis.current_date;

    await axios.patch(`/investor/${investorId}/investments/${investmentId}/kpi_fields/${kpiFieldId}?current_date=${currentDate}`, params).then((res) => {
      dispatch(updateKpiFieldState(res.data));
    });
  };

const deleteKpiField = (investorId, investmentId, kpiFIeldId, removeKpiFromInvestments) =>
  async dispatch => {
    await axios.delete(`/investor/${investorId}/investments/${investmentId}/kpi_fields/${kpiFIeldId}`, { params: { remove_kpi_from_investments: removeKpiFromInvestments } }).then((res) => {
      dispatch(removeKpiField(res.data));
    });
  };

// const changeKpiInChart = (kpiMetric, timeRange) =>
const changeKpiInChart = (kpiMetric, timeRange, moreOptions = { startDate: null, endDate: null, accumulateOption: null, operationOption: null }) =>
  async (dispatch, getState) => {
    const investorId = getState().investmentHeader.investor.id;
    const investmentId = getState().investmentHeader.id;
    let url = `/investor/${investorId}/investments/${investmentId}/kpi_fields?kpi_metric=${kpiMetric}`;
    url += `&time_range=${timeRange}&start_date=${moreOptions.startDate}&end_date=${moreOptions.endDate}`;

    const res = await axios.get(url, { params: { accumulator: moreOptions.accumulateOption, operation: moreOptions.operationOption } });
    dispatch(updateKpiInChart(res.data));
  };

const changeCurrentDate = (currentDate) =>
  async (dispatch, getState) => {
    const investorId = getState().investmentHeader.investor.id;
    const investmentId = getState().investmentHeader.id;

    const res = await axios.get(`/investor/${investorId}/investments/${investmentId}/kpi_fields/load_kpis_data?current_date=${currentDate}`);
    dispatch(updateKpisByDate(res.data, currentDate));
  };

const updateDerivedKpis = (updateForAllInvestments) =>
  async (dispatch, getState) => {
    const investorId = getState().investmentHeader.investor.id;
    const investmentId = getState().investmentHeader.id;

    await axios.patch(`/investor/${investorId}/investments/${investmentId}/update_derived_kpis`, { update_for_all_investments: updateForAllInvestments });
    dispatch(sameState);
  };

export {
  saveKpiFieldValue,
  createKpiField,
  updateKpiField,
  deleteKpiField,
  changeKpiInChart,
  changeCurrentDate,
  updateDerivedKpis,
};
