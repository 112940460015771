export default function apply(themeFor, logoClass, themeColor) {
  let logoStroke;
  let logoFill;

  const logoElement = document.body.getElementsByClassName(logoClass)[0];

  if (themeFor === 'investor') {
    logoFill = themeColor || 'white';
  } else {
    logoFill = themeColor || window.ColorVariables.colorBlue;
  }

  if (window.Color(logoFill).isLight() && (window.Color(logoFill).luminosity() > 0.6)) {
    logoStroke = window.ColorVariables.colorBlue;
  } else {
    logoStroke = 'white';
  }

  logoElement.style.setProperty('stroke', logoStroke);
  logoElement.style.setProperty('fill', logoFill);
}
