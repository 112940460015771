
export default function apply(primaryColor, setHeaderColor = false) {
  const textColorSelected = (primaryColor.isLight() && (primaryColor.luminosity() > 0.6)) ? window.Color('white').string() : window.Color('white').alpha(0.64).string();
  const textColorUnselected = (primaryColor.isLight() && (primaryColor.luminosity() > 0.6)) ? window.ColorVariables.colorGray : window.Color('white').alpha(0.64).string();
  const iconsColorSelected = (primaryColor.isLight() && (primaryColor.luminosity() > 0.6)) ? window.ColorVariables.colorGray : window.Color('white').string();
  const iconsColorUnselected = (primaryColor.isLight() && (primaryColor.luminosity() > 0.6)) ? window.Color(window.ColorVariables.colorGray).alpha(0.54).string() : window.Color('white').alpha(0.64).string();
  const requestButtonBgColor = (primaryColor.isLight() && (primaryColor.luminosity() > 0.6)) ? window.ColorVariables.colorBlue : window.Color('white').string();
  const selectedHeaderBgColor = (primaryColor.isLight() && (primaryColor.luminosity() > 0.6)) ? window.ColorVariables.colorLightBlue : window.Color(window.ColorVariables.colorBlue).alpha(0.70).string();
  const unselectedHeaderBgColor = (primaryColor.isLight() && (primaryColor.luminosity() > 0.6)) ? window.ColorVariables.colorLighterBlack : window.Color('white').alpha(0.20).string();
  // const subHeaderBorderColor = (primaryColor.white() >= 90) ? window.ColorVariables.colorGray : primaryColor.string();

  const headerElement = document.body.getElementsByTagName('header');
  const requestButtonElement = document.body.getElementsByClassName('company-header__button-theme');

  // TODO: Remove when deployed. Subheader was removed from the company layout
  // const subHeaderElement = document.body.getElementsByClassName('company-sub-header__border-bottom-color');

  if (headerElement.length > 0) {
    if (setHeaderColor) {
      headerElement[0].style.setProperty('--company-header-bg-color', primaryColor);
    }

    headerElement[0].style.setProperty('--company-header-text-color-selected', textColorSelected);
    headerElement[0].style.setProperty('--company-header-text-color-unselected', textColorUnselected);

    headerElement[0].style.setProperty('--company-header-icons-color-unselected', iconsColorUnselected);
    headerElement[0].style.setProperty('--company-header-icons-color-selected', iconsColorSelected);

    headerElement[0].style.setProperty('--company-header-bg-color-unselected', unselectedHeaderBgColor);
    headerElement[0].style.setProperty('--company-header-bg-color-selected', selectedHeaderBgColor);
  }

  if (requestButtonElement.length > 0 && requestButtonBgColor) {
    requestButtonElement[0].style.setProperty('--company-request-button-background-color', requestButtonBgColor);
  }

  // if (subHeaderElement.length > 0) {
  //   subHeaderElement[0].style.setProperty('--company-sub-header-border-color', subHeaderBorderColor);
  // }
}
