const request = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_SIGNING_ROOM_REQUEST':
      return Object.assign({}, state, action.signing_room_request);

    case 'UPDATE_SIGNER':
      return {
        ...state,
        signers: state.signers.map(signer => {
          if (signer.id === action.signer.id) {
            return action.signer;
          }
          return signer;
        }),
      };

    default:
      return state;
  }
};

export default request;
