import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import axios from 'axios';

import SelectCustomOption from './modules/SelectCustomOption';
import ImageValue from './modules/ImageValue';
import { defaultSelectStyles, defaultSelectTheme } from './SelectStyles';

const loadTermSheetOptions = async (url) => {
  try {
    const res = await axios.get(`${url}`);
    return res.data;
  } catch (err) {
    App.State.setFlash({ name: 'alert', msg: err.response.data.error || 'Error when loading files.' });
    return null;
  }
};

const SelectWithImage = ({ className, onChange, value, options, url, placeholder, isClearable }) => {
  return (
    url ?
      <AsyncSelect
        value={value}
        cacheOptions
        defaultOptions
        onChange={onChange}
        loadOptions={() => loadTermSheetOptions(url)}
        components={{ Option: SelectCustomOption, SingleValue: ImageValue }}
        styles={defaultSelectStyles}
        theme={defaultSelectTheme}
        isClearable={isClearable}
      />
      :
      <Select
        className={className}
        onChange={onChange}
        value={value}
        options={options}
        placeholder={placeholder}
        components={{ Option: SelectCustomOption, SingleValue: ImageValue }}
        isClearable={isClearable}
        isMulti={false}
        styles={defaultSelectStyles}
        theme={defaultSelectTheme}
      />
  );
};

SelectWithImage.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  url: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  isClearable: PropTypes.bool,
};

SelectWithImage.defaultProps = {
  className: '',
  placeholder: 'Select...',
  value: [],
  isClearable: true,
  url: null,
};

export default SelectWithImage;
