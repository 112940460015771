import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CardContainer from './CardContainer';


class OnboardSearchList extends Component {
  static propTypes = {
    records: PropTypes.string.isRequired,
    component: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    finishUrl: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      records: JSON.parse(props.records),
    };
  }

  componentDidMount() {
    $('#inviter').click(() => {
      $('#modal-invite').next('.modal__layer').show();
      $('#modal-invite').fadeIn();
    });
  }

  componentWillMount() {
    this.search = _.throttle(this.search, 500);
  }

  queryChange = e => {
    this.search(e.target.value);
  }

  search = query => {
    const { url } = this.props;
    App.Api.search(url, query).then(
      data => this.setState({ records: data }),
    );
  }

  render() {
    const { placeholder, component, finishUrl } = this.props;
    const { records } = this.state;

    return (
      <div className="flex flex-wrap">
        <div className="flex-grow mr2 relative sm-m0 sm-mb2 sm-col-12">
          <i className="fa fa-search fa--onboard-search absolute h3 text-green transform-vertical-center vertical-center" />
          <input
            className="onboard__search-input bg-gray border-none block col-12 fs-18 placeholder-white rounded text-white"
            type="text"
            placeholder={placeholder}
            onChange={this.queryChange}
          />

          <button className="btn-search-invite absolute bg-blue border-none cursor-pointer h4 px2 rounded text-white" type="button" id="inviter">
            <i className="fa fa-user-plus mr1" />
            Invite

          </button>
        </div>

        <div
          className="btn-finish-onboard bg-green border-none flex flex-center left-align px2 rounded sm-flex-justify-center sm-p2 text-white cursor-pointer"
          href={finishUrl}
          onClick={() => { App.mixpanelOnboarding('finished'); }}
          data-method="patch"
        >
          <i className="fa fa-check fa--finish-onboard" />
          <span>Finish <br /> Onboarding</span>
        </div>

        <CardContainer records={records} component={component} />
      </div>
    );
  }
}

export default OnboardSearchList;
