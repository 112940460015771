import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../TextInput';

import MainFields from './MainFields';
import Chart from './Chart';
import OptionPool from './OptionPool';
import ConvertiblesTable from './ConvertiblesTable';
import CommonSharesTable from './CommonSharesTable';
import PreferedSharesTable from './PreferedSharesTable';


class CapTableLater extends Component {
  static propTypes = {
    capTable: PropTypes.string.isRequired,
    switchUrl: PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      capTable: JSON.parse(props.capTable),
      saveStatus: 'Saved',
    };
  }

  // Null params added because of new input fields that needed them when saving without event;
  // event was being sent with null because of debounce
  setData = (e, id = null, value = null) => {
    const { capTable } = this.state;
    const innerId = id || e.target.id;
    const innerValue = (value === null) ? e.target.value : value;

    this.updateCapTableState(innerId, innerValue);
    this.setState({ saveStatus: 'Saving...' });
    this.updateAjax(capTable.update_url, innerId, innerValue);
  }

  componentWillMount() {
    this.updateAjax = _.debounce(this.updateAjax, 500);
  }

  displayChart = () => {
    const { settings } = this.props;
    const { capTable } = this.state;

    if (!settings.edit && capTable.shares.length > 0) {
      return (
        <Chart data={capTable.chart_data} />
      );
    }
    return (
      null
    );
  }

  displaySaveStatus = () => {
    const { settings } = this.props;
    const { saveStatus } = this.state;

    if (settings.edit) {
      return (
        <span className="bold h5 text-blue" href="#">
          {saveStatus}
          <i className="fa fa-cloud-upload ml1" />
          <span className="mx2 text-alto">|</span>

        </span>
      );
    }
    return (
      null
    );
  }

  displaySwitch = () => {
    const { settings, switchUrl } = this.props;
    const { capTable } = this.state;
    if (capTable.editable) {
      return (
        <span className="flex flex-center">
          <a className="flex flex-center" href={switchUrl}>
            <span className="mr1 regular text-gray">{settings.edit ? 'Edit mode' : 'View mode'}</span>
            <span className="h3">
              <i className={settings.edit ? 'fa fa-toggle-on' : 'fa fa-toggle-off'} />
            </span>
          </a>
        </span>
      );
    }
    return null;
  }

  displayTitle = () => {
    const { settings } = this.props;
    const { capTable } = this.state;

    if (settings.edit) {
      return (
        <div>
          <i className="fa fa-pencil mr1" />
          <TextInput
            className="bg-none bold border-left-none border-right-none border-top-none border-bottom border-alto h3"
            onChange={this.setData}
            placeholder="Cap table name"
            value={capTable.name}
            name="cap_table[name]"
            id="name"
          />
        </div>
      );
    }
    return (
      <p className="bold h2 m0 mb1 mt1 text-gray">{capTable.name}</p>
    );
  }

  updateAjax = (url, key, value) => {
    App.Api.patch(url, key, value).then((data) => {
      this.setState({
        capTable: data,
        saveStatus: 'Saved',
      });
    });
  }

  updateCapTableState = (key, value) => {
    const { capTable: oldCapTable } = this.state;

    const capTable = Object.assign({}, oldCapTable, { [key]: value });
    this.setState({ capTable });
  }

  updateItem = (url, key, value) => {
    this.setState({ saveStatus: 'Saving...' });
    this.updateAjax(url, key, value);
  }

  updateTopState = (data) => {
    this.setState({ capTable: data });
  }

  render() {
    const { settings } = this.props;
    const { capTable } = this.state;

    return (
      <div>
        <div className="flex flex-center mb1 bg-white bs-around mb3 p2">
          {this.displayTitle()}
          <div className="flex flex-auto flex-justify-end">
            {this.displaySaveStatus()}
            {this.displaySwitch()}
          </div>
        </div>

        <MainFields
          setData={this.setData}
          data={capTable.main_data}
          edit={settings.edit}
          currency={capTable.currency}
        />

        {this.displayChart()}

        <CommonSharesTable
          shares={capTable.shares}
          createUrl={capTable.share_create_url}
          updateTopState={this.updateTopState}
          updateItem={this.updateItem}
          totals={capTable.common_shares_totals}
          edit={settings.edit}
          currency={capTable.currency}
        />

        <OptionPool
          onChange={this.setData}
          optionPoolNew={capTable.option_pool_new}
          optionPoolNewShow={capTable.option_pool_new_show}
          optionPoolCurrent={capTable.option_pool_current}
          optionPoolPercentage={capTable.option_pool_percentage}
          setData={this.setData}
          settings={settings}
          currency={capTable.currency}
        />

        <PreferedSharesTable
          shares={capTable.prefered_shares}
          createUrl={capTable.share_create_url}
          updateTopState={this.updateTopState}
          updateItem={this.updateItem}
          totals={capTable.prefered_shares_totals}
          edit={settings.edit}
          currency={capTable.currency}
        />

        <ConvertiblesTable
          convertibles={capTable.convertibles}
          createUrl={capTable.convertible_create_url}
          updateTopState={this.updateTopState}
          updateItem={this.updateItem}
          edit={settings.edit}
          currency={capTable.currency}
        />
      </div>
    );
  }
}

export default CapTableLater;
