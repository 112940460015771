import {
  updateSigningRoomRequest,
} from '../actions/signingRoomRequestActions';

export const saveRequest = (companyId, id, params) =>
  dispatch =>
    App.Api.submitPatch(`/company/${companyId}/signing_room_requests/${id}`, {
      signing_room_request: params,
    }).then(
      res => dispatch(updateSigningRoomRequest(res)),
    );

export const uploadRequestDocument = (companyId, id, formData) =>
  dispatch =>
    App.Api.submitPatchForFile(`/company/${companyId}/signing_room_requests/${id}`, formData).then(
      res => dispatch(updateSigningRoomRequest(res)),
    );

export const deleteRequestDocument = (companyId, id, formData) =>
  dispatch =>
    App.Api.submitPatch(`/company/${companyId}/signing_room_requests/${id}`, formData).then(
      res => dispatch(updateSigningRoomRequest(res)),
    );

export const sendRequest = (companyId, id, params) =>
  dispatch =>
    App.Api.post(`/company/${companyId}/signing_room_requests/${id}/send_request`, {
      settings: params,
    }).then(
      res => dispatch(updateSigningRoomRequest(res)),
    );

export const completeRequest = (companyId, id) =>
  dispatch =>
    App.Api.post(`/company/${companyId}/signing_room_requests/${id}/complete`).then(
      res => dispatch(updateSigningRoomRequest(res)),
    );
