const optionFunc = (custom, customColor = '#adadad') => {
  const opts = (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#ebebeb' : 'white',
    color: custom ? (state.data.afterSeparator ? customColor : '#313131') : '#313131',
    '&:hover': {
      backgroundColor: '#ebebeb',
      color: '#313131',
    },
    '&:focus': {
      backgroundColor: '#ebebeb',
      color: '#313131',
    },
  });
  return opts;
};

const defaultStyles = {
  option: optionFunc(),
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? '#ebebeb' : 'white',
  }),
  multiValue: (styles) => ({
    ...styles,
    fontSize: '1em',
    lineHeight: '1.25',
  }),
};

export const defaultSelectStyles = {
  ...defaultStyles,
};

export const defaultSelectStylesWithSeparator = {
  ...defaultStyles,
  option: optionFunc(true),
};

export const defaultBorderlessStyles = {
  ...defaultStyles,
  control: (styles) => ({
    ...styles,
    border: 'none',
    boxShadow: 'none',
  }),
};

export const defaultSelectTheme = (theme) => ({
  ...theme,
  borderRadius: 0,
  borderColor: '#d7d7d7',
  colors: {
    ...theme.colors,
    primary: '#989898',
  },
});
