import React, { Component } from 'react';
import PropTypes from 'prop-types';

import KpiSelector from '../KpiSelector';
import KpiPortal from '../KpiPortal';
import TableKpiPortal from '../TableKpiPortal';
import KpiChartsList from '../KpiChartsList';
import AdvancedChartOptions from '../AdvancedChartOptions';


class KpiSection extends Component {
  static propTypes = {
    section: PropTypes.object,
    kpi_options: PropTypes.object,
    updateSection: PropTypes.func,
    kpis: PropTypes.array,
    chart_types_for_selection: PropTypes.array,
    createKpiChart: PropTypes.func,
    deleteSection: PropTypes.func,
  }

  constructor(props) {
    super(props);

    const { section } = this.props;
    const kpi_charts = section.kpi.kpi_charts;
    const hasKpiCharts = kpi_charts !== undefined;

    this.state = {
      kpi_id: hasKpiCharts ? kpi_charts[0].kpi_id : null,
      kpiIds: section.kpi ? section.kpi.kpiIds : '',
      kpi_charts: hasKpiCharts ? kpi_charts : [],

      /* advanced options (for every chart) */
      chart_title: hasKpiCharts ? kpi_charts[0].chart_title : null,
      show_markers: hasKpiCharts ? kpi_charts[0].show_markers : true,
      show_data_labels: hasKpiCharts ? kpi_charts[0].show_data_labels : null,
      values_as_percentage: hasKpiCharts ? kpi_charts[0].values_as_percentage : null,
      y_scaling: hasKpiCharts ? kpi_charts[0].y_scaling : 'automatic',
      tick_start: hasKpiCharts ? kpi_charts[0].tick_start : null,
      tick_end: hasKpiCharts ? kpi_charts[0].tick_end : null,
      shown: hasKpiCharts ? kpi_charts[0].values_shown : null,
      period_step: hasKpiCharts ? kpi_charts[0].period_step : 'months',
      period_start: hasKpiCharts ? kpi_charts[0].period_start : null,
      period_end: hasKpiCharts ? kpi_charts[0].period_end : null,
      show_comma_values: hasKpiCharts ? kpi_charts[0].show_comma_values : null,
      show_human_readable: hasKpiCharts ? kpi_charts[0].show_human_readable : true,
      last_data_info: hasKpiCharts ? this.getFieldValue(kpi_charts[0].last_data_info, true) : true,
      last_data_percentage: hasKpiCharts ? this.getFieldValue(kpi_charts[0].last_data_percentage, true) : false,

      /* states for control */
      kpiOptionsOpened: section.kpi.kpiIds !== undefined ? (section.kpi.kpiIds.length !== '') : false,
      invalidDates: false,
      chartTypesChosen: this.allChartTypesChosen(kpi_charts),
      useDefaultTitle: !hasKpiCharts || (hasKpiCharts && !kpi_charts[0].kpi_chart_present),
      /* states for building select options */
      date_options: this.initializeDateOptions(),
      kpisForSelection: this.initializeKpisForSelection(),

    };
  }

  getFieldValue = (fieldValue, defaultValue) => {
    if (fieldValue !== null && fieldValue !== undefined) {
      return fieldValue;
    }
    return defaultValue;
  }

  setChartTypeForKpiChart = (kpiId, chartType) => {
    const kpi_charts = _.cloneDeep(this.state.kpi_charts);
    kpi_charts.find(kpiChart => kpiChart.kpi_id === kpiId).type = chartType;
    if (chartType === 'funnel') this.setState({ show_data_labels: true });
    // Verify, for all kpi, that all chart types are chosen
    this.setState({ kpi_charts, chartTypesChosen: this.allChartTypesChosen(kpi_charts) });
  }

  setKpiOptionsOpened = () => {
    this.setState({ kpiOptionsOpened: !this.state.kpiOptionsOpened });
  }

  // When a kpi or more kpis are included in the chart many things need to be set;
  // It all start by inserting a kpi;
  setKpis = (values) => {
    let kpiValues = '';
    if (values) {
      kpiValues = values.split(',');
      this.setState({ kpiIds: values });
    }
    if (kpiValues.length === 1) {
      const val = parseInt(kpiValues[0], 10);
      const kpiSelected = this.props.kpis.find(kpi => kpi.id === val)
      const period_step = kpiSelected.period_step;
      const date_options = this.props.kpi_options[period_step].concat({ value: 0, label: 'Custom', className: 'text-black' });
      const kpisForSelection = this.props.kpis.filter(kpi => kpi.period_step === period_step);

      if (!this.state.kpiOptionsOpened) this.setKpiOptionsOpened();

      this.setState({ kpi_id: val, kpiIds: values, period_step, date_options, kpisForSelection, shown: date_options[date_options.length - 2].value, chartTypesChosen: true });
    } else if (kpiValues.length === 0) {
      this.setKpiOptionsOpened();
      this.setState({ kpi_id: null, kpiIds: '', kpi_charts: [], kpisForSelection: this.props.kpis, chartTypesChosen: false });
    }
  }

  setStateInKpiSection = (state) => {
    this.setState(state);
  }

  allChartTypesChosen = (kpi_charts) => {
    if (kpi_charts && kpi_charts.length > 0) {
      return kpi_charts.map(el => el.type !== '').reduce((el1, el2) => el1 && el2);
    }
    return false;
  }

  canSubmit = () => {
    return this.state.chartTypesChosen && !this.state.invalidDates;
  }

  cancelButton = () => {
    return (
      ((this.state.kpi_id === null) || !this.state.chartTypesChosen) &&
      <div
        href="#"
        className="bg-lighter-gray border-none px3 py1 text-gray cursor-pointer"
        style={{ borderRadius: '4px' }}
        onClick={(e) => { e.preventDefault(); this.closeWindow(); }}
      >
        Cancel
      </div>
    );
  }

  closeWindow = () => {
    this.props.deleteSection(this.props.section.url);
  }

  initializeDateOptions = () => {
    const { section } = this.props;
    if (section.kpi.kpi_charts && section.kpi.kpi_charts[0].period_step) {
      return this.props.kpi_options[section.kpi.kpi_charts[0].period_step].concat({ value: 0, label: 'Custom', className: 'text-black' });
    }
    return null;
  }

  initializeKpisForSelection = () => {
    const { section } = this.props;
    if (section.kpi.kpi_charts) {
      return this.props.kpis.filter(kpi => kpi.period_step === section.kpi.kpi_charts[0].period_step);
    }
    return this.props.kpis;
  }

  isFunnelKpi = () => {
    return ((this.state.kpi_charts[0] !== undefined) && (this.state.kpi_charts[0].type === 'funnel'));
  }

  isNumberKpi = () => {
    return ((this.state.kpi_charts[0] !== undefined) && (this.state.kpi_charts[0].type === 'number'));
  }

  isTableKpi = () => {
    return ((this.state.kpi_charts[0] !== undefined) && (this.state.kpi_charts[0].type === 'table'));
  }

  kpiGraph = () => {
    const { section } = this.props;
    const { kpi_charts } = section.kpi;

    if ((kpi_charts !== undefined) && !kpi_charts[0].number_chart) {
      if (kpi_charts[0].table_chart) {
        return (
          <TableKpiPortal id={section.id} kpi_charts={kpi_charts} />
        );
      }
      return (
        <KpiPortal id={section.id} kpiCharts={kpi_charts} brandingColors={section.branding_colors} />
      );
    }
    const extraInfo = (
      kpi_charts[0].extra &&
      <div className="flex flex-auto mt1">
        <div className="flex flex-center">
          <p className={`text-${kpi_charts[0].extra.color} h4 m0 fw400 right-align`}>{kpi_charts[0].extra.text}</p>
          <p className="text-light-gray h4 fw400 right-align lh-14 m0 ml1"> {kpi_charts[0].extra.note.toLowerCase()}</p>
        </div>
      </div>
    );
    return (
      <div className="p2 border-alto" id={this.props.section.id}>
        <h4 className="mt0 bold fs-18" style={{ color: '#555' }}>{kpi_charts[0].chart_title}</h4>
        <div className="flex flex-auto flex-center flex-justify-center text-center">
          <div className="pl3 pb3 pr3 pt2">
            {
              this.state.last_data_info && <span className="fw400 h4 text-light-gray pl1">{kpi_charts[0].date}</span>
            }
            <p className="bold fs-96 m0 sm-h1 sm-lh-normal text-scorpion mt1 pt3 pb3">
              {kpi_charts[0].value}
              <span className="fw400 pl1 fs-40 sm-h3 sm-ml1 text-light-gray">{kpi_charts[0].symbol}{kpi_charts[0].name === 'Runway' && kpi_charts[0].type === 'number' && 'Months'}</span>
            </p>
            {
              kpi_charts[0].extra && this.state.last_data_percentage && extraInfo
            }
          </div>
        </div>
      </div>
    );
  }

  kpiName = (kpiId = this.state.kpi_id) => {
    return this.props.kpis.find(kpi => kpi.id === kpiId).name;
  }

  kpiOptions = () => {
    const { kpis, section } = this.props;

    if (kpis.length === 0) {
      return (
        <div>
          <p className="center pt2 text-gray">Please, create a KPI first!</p>
        </div>
      );
    }

    return (
      <div className="border border-alto">
        <KpiSelector
          kpi_id={this.state.kpi_id}
          kpis={this.props.kpis}
          kpi_charts={this.state.kpi_charts}
          kpiName={this.kpiName}
          chart_title={this.state.chart_title}
          setKpis={this.setKpis}
          kpisForSelection={this.state.kpisForSelection}
          kpiOptionsOpened={this.state.kpiOptionsOpened}
          useDefaultTitle={this.state.useDefaultTitle}
          kpiIds={this.state.kpiIds}
          setStateInKpiSection={this.setStateInKpiSection}
          setChartTypeForKpiChart={this.setChartTypeForKpiChart}
          isNumberKpi={this.isNumberKpi}
          isFunnelKpi={this.isFunnelKpi}
          isTableKpi={this.isTableKpi}
        />

        {
          /* Multi Kpi's component */
          (this.state.kpiIds && this.state.kpiIds.split(',').length > 0) && this.state.kpiOptionsOpened &&
          <KpiChartsList
            kpi_charts={this.state.kpi_charts}
            chart_types={this.props.chart_types_for_selection}
            isNumberKpi={this.isNumberKpi}
            isFunnelKpi={this.isFunnelKpi}
            isTableKpi={this.isTableKpi}
            setChartTypeForKpiChart={this.setChartTypeForKpiChart}
            setStateInKpiSection={this.setStateInKpiSection}
            brandingColors={section.branding_colors}
            billingUrl={section.billing_url}
            subscribed={section.subscribed}
          />
        }

        <AdvancedChartOptions
          chart_title={this.state.chart_title}
          show_markers={this.state.show_markers}
          show_data_labels={this.state.show_data_labels}
          values_as_percentage={this.state.values_as_percentage}
          y_scaling={this.state.y_scaling}
          tick_start={this.state.tick_start}
          tick_end={this.state.tick_end}
          shown={this.state.shown}
          period_step={this.state.period_step}
          period_start={this.state.period_start}
          period_end={this.state.period_end}
          show_comma_values={this.state.show_comma_values}
          show_human_readable={this.state.show_human_readable}
          isNumberKpi={this.isNumberKpi}
          isFunnelKpi={this.isFunnelKpi}
          isTableKpi={this.isTableKpi}
          date_options={this.state.date_options}
          invalidDates={this.state.invalidDates}
          kpi_charts={this.state.kpi_charts}
          chartTypesChosen={this.state.chartTypesChosen}
          last_data_info={this.state.last_data_info}
          last_data_percentage={this.state.last_data_percentage}
          setStateInKpiSection={this.setStateInKpiSection}
        />

        <div className="mt3 mb3 flex flex-justify-center">
          {this.cancelButton()}
          {this.submitButton()}
        </div>
      </div>
    );
  }

  submitButton = () => {
    if (this.canSubmit()) {
      return (
        <input
          className="bg-green border-none px3 py1 text-white ml3 cursor-pointer"
          style={{ borderRadius: '4px' }}
          onClick={this.submitKpis}
          type="submit"
          value="Confirm"
        />
      );
    }
    return null;
  }

  submitKpis = () => {
    const section = {};
    Object.assign(section, this.props.section);
    section.kpi_id = this.state.kpi_id;
    section.values_shown = this.state.shown;
    const kpiChartsParams = {
      kpi_charts_attributes: this.updateChartsAdvancedOptions(),
    };
    const top = $(this.kpi_chart).offset().top;
    const kpiPosition = top - ($(window).height() / 2);
    this.props.createKpiChart(section, kpiChartsParams, kpiPosition + 150);
  }

  updateChartsAdvancedOptions = () => {
    const { kpi_charts, chart_title, shown, period_step, period_start, period_end,
      show_markers, show_data_labels, values_as_percentage, y_scaling,
      tick_start, tick_end, show_comma_values, show_human_readable } = this.state;

    const updatedKpiCharts = kpi_charts.map(kpiChart => (
      {
        ...kpiChart,
        chart_title,
        values_shown: shown,
        period_step,
        period_start,
        period_end,
        show_markers,
        show_data_labels,
        values_as_percentage,
        y_scaling,
        tick_start,
        tick_end,
        show_comma_values,
        show_human_readable,
        // this is needed because of old data.
        chart_type: kpiChart.type,
        show_actual: kpiChart.show_actual_data,
        show_plan: kpiChart.show_plan_data,
        actual_color: kpiChart.actual_color,
        plan_color: kpiChart.plan_color,
      }
    ));

    this.setState({ kpi_charts: updatedKpiCharts });
    return updatedKpiCharts;
  }

  render() {
    const { section } = this.props;

    return (
      <div ref={(c) => { this.kpi_chart = c; }}>
        {section.kpi_id > 0 ? this.kpiGraph() : this.kpiOptions()}
      </div>
    );
  }
}

export default KpiSection;
