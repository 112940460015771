import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TeamCardAdmin from './TeamCardAdmin';
import TeamCardEdit from './TeamCardEdit';
import Switch from '../Switch';

class TeamCard extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
    updateTopState: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      companyRole: props.record.company_role,
      otherCompanyRole: props.record.other_company_role,
      isAdmin: props.record.role === 'owner',
      description: props.record.about,
      category: props.record.category,
      convertedCategory: props.record.converted_category,
    };
  }

  updateAfterSubmit = (data) => {
    this.setState(data);
  }

  settings = () => {
    const { record, updateTopState } = this.props;
    const { companyRole, isAdmin, description, category } = this.state;
    if (record.is_current_user) {
      return (
        <TeamCardEdit
          record={record}
          companyRole={companyRole}
          isAdmin={isAdmin}
          description={description}
          category={category}
          updateAfterSubmit={this.updateAfterSubmit}
        />
      );
    }
    return (
      <TeamCardAdmin
        updateTopState={updateTopState}
        record={record}
      />
    );
  }

  updatesVisibilitySwitchAction = () => {
    const { record } = this.props;
    App.Api.patch(record.manage_updates_visibility_url)
      .catch(() => {
        App.State.setFlash({ name: 'alert', msg: 'Sorry, something went wrong. Try again later or contact support!' });
      });
  }

  article = (word) => {
    if (word) {
      return ['a', 'e', 'i', 'o', 'u'].includes(word[0].toLowerCase()) ? 'an' : 'a';
    }
    return 'a';
  }

  render() {
    const { record } = this.props;
    const { companyRole, otherCompanyRole, description, category, convertedCategory, isAdmin } = this.state;

    const humanReadableRole = companyRole ? `${companyRole.charAt(0).toUpperCase()}${companyRole.slice(1)}` : '';
    const getRole = companyRole ? (`My Role is ${(companyRole === 'other') ? otherCompanyRole : humanReadableRole}`) : '';

    return (
      <div className="col-4 lg-col-6 sm-col-12 flex px2 mb3">
        <div className="bg-white card--has-hover card--has-layer bs-around-small center flex flex-auto flex-center flex-column p3 relative rounded">
          <div className="flex-auto">
            {this.settings()}
            <i className="fa fa-briefcase fa--search-card position-left-bottom absolute text-extra-light-gray" />
            <a href={record.url}>
              <div
                className="search-card__img bg-center bg-cover bg-no-repeat border border-light-gray circle mb1 mx-auto"
                style={{ backgroundImage: `url(${record.avatar})` }}
              />
              <p className="bold h3 m0 mb1 text-outer-space">{description === 'Invited' ? 'Invited' : record.name}{isAdmin ? <span className="ml1 h4">(owner)</span> : ''}</p>
              {
                record.email &&
                <p className="h4 m0 mb1 text-outer-space">{record.email}</p>
              }
              <p className={`h5 m0 mb1 text-light-gray ${category ? '' : 'hide'}`}>{category ? `I am ${this.article(convertedCategory)} ${convertedCategory}` : ''}</p>
              <p className={`h5 m0 mb1 text-light-gray ${companyRole ? '' : 'hide'}`}>{getRole}</p>
              <p className="h5 m0 mb1 text-light-gray">{description === 'Invited' ? '' : description}</p>
            </a>
          </div>
          {
            record.manage_updates_visibility_url &&
            <div className="flex items-center pb2">
              <div
                className="flex text-light-gray mr1 h5 cursor-pointer"
                data-balloon="Enables/Disables access to all published Updates (even if user is not a recipient)."
                data-balloon-pos="down"
                data-balloon-length="large"
                href="#"
              >
                <i className="fa fa-info-circle text-light-gray" />
              </div>
              <Switch
                labelLeft="All Update Access"
                labelRight=""
                onChange={this.updatesVisibilitySwitchAction}
                defaultChecked={record.all_updates_visible}
              />

            </div>
          }
        </div>
      </div>
    );
  }
}

export default TeamCard;
