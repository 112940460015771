import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';


class DropdownMenu extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    margin: PropTypes.string,
    text: PropTypes.string,
    textSize: PropTypes.string,
    textColor: PropTypes.string,
    faIcon: PropTypes.string,
    iconPos: PropTypes.string,
    setFalseWhenClickingInside: PropTypes.bool,
    dropdownClass: PropTypes.string,
    balloonProps: PropTypes.object,
    width: PropTypes.string,
  }

  static defaultProps = {
    margin: 'mx1',
    text: '',
    textColor: 'light-gray',
    textSize: 'h3',
    faIcon: 'cog',
    iconPos: 'left',
    setFalseWhenClickingInside: false,
    dropdownClass: 'center left-50',
    balloonProps: null,
    width: '180px',
  }

  state = {
    open: false,
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
    document.addEventListener('touchstart', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
    document.removeEventListener('touchstart', this.handleClick, false);
  }

  handleClick = (e) => {
    const { setFalseWhenClickingInside } = this.props;
    const { open } = this.state;

    if (ReactDOM.findDOMNode(this).contains(e.target)) {
      if (setFalseWhenClickingInside) {
        if (open) {
          this.setState({ open: false });
        } else {
          this.setState({ open: true });
        }
      }
      return;
    }
    this.setState({ open: false });
  }

  renderIconOrText = () => {
    const { text, textSize, faIcon, iconPos } = this.props;

    if (text && faIcon) {
      if (iconPos === 'left') {
        return (
          <div className={`flex flex-center ${textSize}`}>
            <i className={`fa fa-${faIcon} fa-fw ml1 mr1`} />
            <span style={{ width: 'auto' }}>{text}</span>
          </div>
        );
      }
      return (
        <div className={`flex flex-center ${textSize}`}>
          <span style={{ width: 'auto' }}>{text}</span>
          <i className={`fa fa-${faIcon} fa-fw ml1 mr1`} />
        </div>
      );
    }

    if (text) {
      return (
        <div className={`flex flex-center ${textSize}`}>
          <span style={{ width: 'auto' }}>{text}</span>
        </div>
      );
    }

    return (
      <i className={`fa fa-${faIcon} fa-fw`} />
    );
  }

  render() {
    const { margin, textColor, setFalseWhenClickingInside, dropdownClass, children, balloonProps, width } = this.props;
    const { open } = this.state;

    return (
      <div className={`relative cursor-pointer ${margin}`}>
        <div
          className={`text-${textColor}`}
          onClick={() => { setFalseWhenClickingInside ? this.handleClick() : this.setState({ open: !open }) }}
          data-balloon={balloonProps ? balloonProps.text : null}
          data-balloon-pos={balloonProps ? balloonProps.position : null}
          data-balloon-length={balloonProps ? balloonProps.length : null}
        >
          {this.renderIconOrText()}
        </div>
        <div className={`dropdown dropdown--${dropdownClass} absolute bg-white border border-alto bs-dropdown transform-horizontal-center z1 ${open ? '' : 'hidden'} py1`}>
          <ul className="left-align m0" style={{ width }}>
            { children }
          </ul>
        </div>
      </div>
    );
  }
}

export default DropdownMenu;
