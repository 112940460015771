export default {
  createSection(url, type, position) {
    return new Promise((resolve) => {
      const data = {
        type,
        position,
      };

      $.post(url, data, resolve);
    });
  },

  deleteSection(url) {
    return new Promise((resolve) => {
      $.ajax({
        url,
        type: 'delete',
        success: resolve,
      });
    });
  },

  update(url, key, data) {
    return new Promise((resolve) => {
      $.ajax({
        url,
        data: { [key]: data },
        type: 'patch',
        success: resolve,
      });
    });
  },

  moveSection(url, direction) {
    return new Promise((resolve) => {
      $.ajax({
        url,
        data: { direction },
        type: 'patch',
        success: resolve,
      });
    });
  },
};
