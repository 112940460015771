import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Modal from '../../../../Modal';
import SelectableField from '../../modules/SelectableField';


class ManageInvestmentKpiModal extends Component {
  static propTypes = {
    investment: PropTypes.object.isRequired,
    investmentKpis: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    saveKpiFieldValue: PropTypes.func.isRequired,
    updateKpiField: PropTypes.func.isRequired,
  };

  state = {
    kpiFieldValues: {},
    saving: false,
  }

  componentWillMount() {
    const { investmentKpis } = this.props;
    const kpiFieldValues = [...investmentKpis.predefined_kpis, ...investmentKpis.custom_kpis].reduce((state, kpi) => ({
      ...state,
      [kpi.id]: {
        type: kpi.company_data ? 'company' : 'custom',
        name: kpi.metric,
        value: kpi.unformatted_raw_value,
      },
    }), {});

    this.setState({ kpiFieldValues });
  }

  handleChange = (id, e) => {
    const { name, value, type } = e.target;

    this.setState(state => ({
      kpiFieldValues: {
        ...state.kpiFieldValues,
        [id]: {
          ...state.kpiFieldValues[id],
          type,
          name,
          value,
        },
      },
    }));
  }

  saveAllKpiFieldValues = () => {
    const { investmentKpis, saveKpiFieldValue, updateKpiField, investment } = this.props;
    const { kpiFieldValues } = this.state;

    return new Promise((response, reject) => {
      Object.entries(kpiFieldValues).forEach(([kpiId, f]) => {
        if (f.type === 'company') {
          updateKpiField(kpiId, { kpi_field: { company_data: true } }).catch((err) => reject(err));
        } else if (f.value !== null) {
          saveKpiFieldValue(investment.investor.id, investment.id, kpiId, { kpi_field_value: { actual: f.value, date: moment(investmentKpis.current_date).toDate() } }).catch((err) => reject(err));
        }
      });
      response();
    });
  }

  submit = () => {
    const { closeModal } = this.props;

    this.setState({ saving: true });

    this.saveAllKpiFieldValues().then(
      () => {
        this.setState({ saving: false });
        closeModal();
      },
      (error) => {
        const errorMessage = error.response ? error.response.data.error : 'An error prevented the KPIs values from being saved.';
        App.State.setFlash({ name: 'alert', msg: errorMessage });
        this.setState({ saving: false });
      },
    );
  }

  render() {
    const { investment, show, closeModal, investmentKpis } = this.props;
    const { saving } = this.state;

    return (
      <Modal
        show={show}
        onClickOutside={closeModal}
      >
        <div className="flex flex-justify-between items-center bold p2 border-bottom border-lighter-gray">
          <div className="h3 flex">
            Manage KPIs
            <span className="light text-gray h5 ml2">(Values for {investmentKpis.current_date})</span>
          </div>
          <i className="fa fa-close h5 cursor-pointer" onClick={closeModal} />
        </div>

        <div className="fw400 px3 pb3 overflow-y-auto" style={{ maxHeight: '65vh' }}>
          {[...investmentKpis.predefined_kpis, ...investmentKpis.custom_kpis.filter(kpi => !kpi['derived_kpi?'] && !kpi['blocked_value?'])].map(kpi => (
            <div key={kpi.id}>
              <SelectableField
                label={kpi.metric}
                name={kpi.metric}
                defaultValue={kpi.unformatted_raw_value}
                original={kpi.company_kpi_value}
                customValue={kpi.unformatted_custom_value}
                isCustom={!kpi.company_data}
                hideCompanyTab={investment.custom_investment || !kpi.company_kpi || !investmentKpis.can_access_kpis}
                component="CleaveNumberFormatting"
                onChange={e => this.handleChange(kpi.id, e)}
              />
            </div>
          ))}
        </div>

        <div className="flex flex-justify-end items-center p2 border-top border-lighter-gray">
          <div
            className="h5 text-blue mr2 cursor-pointer"
            onClick={closeModal}
          >
            Cancel
          </div>
          <div
            className={`h5 p1 px2 bg-blue text-white rounded ${saving ? '' : 'cursor-pointer'}`}
            onClick={saving ? null : this.submit}
          >
            Save
          </div>
        </div>
      </Modal>
    );
  }
}

export default ManageInvestmentKpiModal;
