import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import classNames from 'classnames';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import InvestoryLogoSvg from '../../InvestoryLogoSvg';

import chartOptions from '../../utils/highcharts/pieChartOptions';

import { defaultSelectStyles, defaultSelectTheme } from '../../selects/SelectStyles';
import LoadingSpinner from '../../LoadingSpinner';
import UpgradeAccountModal from '../../modals/UpgradeAccountModal';


class GenericPieChart extends Component {
  static propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    data: PropTypes.array,
    rightSectionData: PropTypes.object,
    filterOptions: PropTypes.array,
    chosenFilter: PropTypes.string,
    onFilterChange: PropTypes.func,
    loading: PropTypes.bool,
    withFilter: PropTypes.bool,
    legendTitle: PropTypes.string,
    legendValues: PropTypes.string,
    children: PropTypes.node,
    showRightSection: PropTypes.bool,
    missingDataMessage: PropTypes.string,
    smallVersion: PropTypes.bool,
    exportDataURL: PropTypes.string,
    canExport: PropTypes.bool,
    blurred: PropTypes.bool,
  }

  static defaultProps = {
    show: true,
    title: null,
    data: [],
    rightSectionData: null,
    filterOptions: [],
    chosenFilter: null,
    loading: false,
    onFilterChange: null,
    withFilter: false,
    legendTitle: null,
    legendValues: null,
    children: null,
    showRightSection: true,
    missingDataMessage: '',
    smallVersion: false,
    exportDataURL: null,
    canExport: true,
    blurred: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      chosenFilter: props.chosenFilter,
      chartAnimation: true,
      renderChart: true,
      showUpgradeModal: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.props;

    if (nextProps.data !== data) {
      this.setState({ chartAnimation: true });
    } else {
      this.setState({ chartAnimation: false });
    }
  }

  handleFilterChange = async (selectedOption) => {
    const { onFilterChange } = this.props;

    if (selectedOption.custom) {
      await onFilterChange(selectedOption.value, 'custom');
    } else {
      await onFilterChange(selectedOption.value);
    }
    this.setState({ renderChart: false }, () => {
      this.setState({ renderChart: true });
    });
  }

  render() {
    const {
      show,
      title,
      data,
      rightSectionData,
      filterOptions,
      loading,
      withFilter,
      legendTitle,
      legendValues,
      children,
      showRightSection,
      missingDataMessage,
      smallVersion,
      exportDataURL,
      canExport,
      blurred,
    } = this.props;
    const { chosenFilter, chartAnimation, renderChart, showUpgradeModal } = this.state;

    const classes = classNames('flex sm-hide', {
      'flex-wrap flex-justify-center': smallVersion,
      'flex-auto pr2': !smallVersion,
    });

    const legendClasses = classNames('flex flex-column flex-auto', {
      ' mx2 mb2': smallVersion,
      ' mr2': !smallVersion,
    });

    return (
      show ?
        <React.Fragment>
          {title &&
            <div className="mb2 pt2 pl2">
              <span className="text-gray fw400">{title}</span>
            </div>
          }
          <React.Fragment>
            <div className="px2 mb2 flex items-center flex-justify-between pt2">
              <div className="flex flex-auto h5">
                {/* Children elements are rendered in here, i.e the 'View As' buttons */}
                {children}

                {withFilter &&
                  <div className={`flex items-center h5 ${smallVersion ? 'col-12' : 'col-7'}`}>
                    {!smallVersion &&
                      <span className="mx2">Show</span>
                    }
                    <ReactSelect
                      className="col-9"
                      defaultValue={filterOptions.filter(option => option.value === chosenFilter)}
                      value={filterOptions.filter(option => option.value === chosenFilter)}
                      options={filterOptions}
                      isClearable={false}
                      onChange={(selectedOption) => { this.handleFilterChange(selectedOption); this.setState({ chosenFilter: selectedOption.value }); }}
                      styles={defaultSelectStyles}
                      theme={defaultSelectTheme}
                    />
                  </div>
                }

                {exportDataURL && !smallVersion &&
                  <div className="flex items-center ml-auto mr1">
                    <div
                      className="text-light-gray hover-border-medium-gray hover-text-medium-gray border border-alto bg-white cursor-pointer flex items-center px1"
                      data-balloon="Excel Export"
                      data-balloon-pos="down"
                      data-balloon-length="small"
                      onClick={() => { canExport ? window.location = exportDataURL : this.setState({ showUpgradeModal: true }); }}
                      style={{ minHeight: '38px' }}
                    >
                      <i className="fa fa-file-excel-o" />
                    </div>
                  </div>
                }
                <UpgradeAccountModal
                  show={showUpgradeModal}
                  closeModal={() => this.setState({ showUpgradeModal: false })}
                  exportFeature
                  sendEmailOnClick
                  paywallDescription="Paywall_Export"
                />
              </div>
            </div>

            <LoadingSpinner
              show={loading}
              type="fit"
              background="white"
              height="200px"
            />

            {!loading &&
              (data.length > 0 ?
                <div className={classes}>
                  {renderChart &&
                    <div className={`${blurred ? 'hide-data' : ''}`}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions(data, { chartAnimation })}
                      />
                    </div>
                  }
                  <div className={legendClasses}>
                    {(legendTitle !== '') &&
                      <div className={`h5 flex flex-justify-between mr2 col-12 ${smallVersion ? '' : 'mt2'}`}>
                        <span className="fw400 text-gray col-8 capitalize">{legendTitle || (chosenFilter && chosenFilter.replace('_', ' '))}</span>
                        <span className="fw400 text-gray col-4 flex flex-justify-center">{legendValues || ''}</span>
                      </div>
                    }
                    <div
                      className="flex flex-justify-between flex-auto overflow-y col-12"
                      style={{ overflowX: 'hidden', height: '110px' }}
                    >
                      <div className={`h5 flex flex-column col-8 ${blurred ? 'hide-data' : ''}`}>
                        {data.map(value => (
                          <div className="flex" key={value.id}>
                            <InvestoryLogoSvg
                              themeColor={value.color}
                              useAsIconOnly
                            />
                            <span className="mx1 truncate capitalize text-gray">{value.name || 'n/a'}</span>

                          </div>
                        ))}
                      </div>
                      <div className={`h5 flex flex-column flex-end col-4 items-center text-gray ${blurred ? 'hide-data' : ''}`}>
                        {data.map(value => (
                          <span key={value.id}>{value.readable_value || value.y || 'n/a'}</span>
                        ))}
                      </div>
                    </div>
                  </div>

                  {(rightSectionData && showRightSection) && !smallVersion &&
                  <div className="border-left border-gallery pl2 flex flex-column sm-col-12 col-4 flex-justify-evenly sm-border-left-none">
                    <div className="flex flex-start flex-justify-evenly items-center sm-mb2">
                      <div className="flex flex-column items-center flex-1 center">
                        <span className="fw400 h3 text-gray mb1">{rightSectionData.top[0].value || '–'}</span>
                        <span className="text-light-gray fw400">{rightSectionData.top[0].field_name}</span>
                      </div>

                      <div className="border border-gallery bw-05 my2" style={{ height: '100%' }} />

                      <div className="flex flex-column items-center flex-1 center">
                        <span className="fw400 h3 text-gray mb1">{rightSectionData.top[1].value || '–'}</span>
                        <span className="text-light-gray fw400">{rightSectionData.top[1].field_name}</span>
                      </div>
                    </div>

                    <div className="border-bottom border-gallery" />

                    <div className="flex flex-end flex-justify-evenly items-center sm-mt2">
                      <div className="flex flex-column items-center flex-1 center">
                        <span className="fw400 h3 text-gray mb1">{rightSectionData.bottom[0].value || '–'}</span>
                        <span className="text-light-gray fw400">{rightSectionData.bottom[0].field_name}</span>
                      </div>

                      <div className="border border-gallery bw-05 my2" style={{ height: '100%' }} />

                      <div className="flex flex-column items-center flex-1 center">
                        <span className="fw400 h3 text-gray mb1">{rightSectionData.bottom[1].value || '–'}</span>
                        <span className="text-light-gray fw400">{rightSectionData.bottom[1].field_name}</span>
                      </div>
                    </div>
                  </div>
                }
                </div>
                :
                <div className="px2 pt2 bg-white center py3">
                  <span className="text-medium-gray mx-auto h5">
                    {missingDataMessage}
                  </span>
                </div>
              )}
          </React.Fragment>
        </React.Fragment>
        :
        null
    );
  }
}

export default GenericPieChart;
