import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';


class CustomDropzone extends Component {
  static propTypes = {
    data: PropTypes.string,
    upload_url: PropTypes.string,
    files_url: PropTypes.string,
    delete_url: PropTypes.string,
    attachedFiles: PropTypes.array,
    uploadGif: PropTypes.string,
  }

  constructor(props) {
    super(props);

    const data = JSON.parse(props.data);

    this.state = {
      attachedFiles: data.attached_files,
      uploading: false,
    };
  }

  onDrop = (files) => {
    for (const file of files) {
      this.uploadFile(file);
    }
  }

  getAttachedFiles = () => {
    const url = this.props.files_url;

    App.Api
      .getConversationAttachedFiles(url)
      .then((data) => {
        this.setState({
          uploading: false,
          attachedFiles: data.attached_files,
        });
      });
  }

  attachments = () => {
    const { attachedFiles } = this.state;

    return attachedFiles.map((attachedFile, index) => {
      const iconColorStyle = {
        backgroundImage: attachedFile.icon_color,
      };

      return (
        <div key={index} className="conversation__attachment-item">

          <div style={iconColorStyle} className="attachment__img">
            <i className={attachedFile.icon_class_name} />
          </div>

          <p className="conversation__attachment-file">{attachedFile.file_name}</p>
          <p className="conversation__attachment-author">{attachedFile.updated_at}</p>
          <span
            className="absolute border border-alto cursor-pointer px1 right-0 rounded top-0"
            onClick={() => this.deleteFile(attachedFile.id)}
            style={{
              marginRight: '10',
              marginTop: '10',
              paddingBottom: '3',
              paddingTop: '3',
            }}
          >
            <i className="fa fa-trash-o h5" />
          </span>
        </div>
      );
    });
  }

  deleteFile = (id) => {
    const url = this.props.delete_url;

    App.Api
      .deleteFile(url, id)
      .then(this.getAttachedFiles)
      .catch(() => {
        App.State.setFlash({
          name: 'alert',
          msg: 'Delete failed',
        });
      });
  }

  dropZone = () => {
    const dropZoneStyle = {};

    return (
      <Dropzone
        style={dropZoneStyle}
        onDrop={this.onDrop}
      >
        {this.dropZonePlaceHolder()}
        <p className="dropZone__text">Drop files here to upload</p>
      </Dropzone>
    );
  }

  dropZonePlaceHolder = () => {
    if (this.state.attachedFiles.length > 0 || this.state.uploading) return null;

    return (
      <div className="dropzone-placeholder">
        <i className="fa fa-upload" />
      </div>
    );
  }

  uploadFile = (file) => {
    const url = this.props.upload_url;

    this.setState({ uploading: true });
    App.Api
      .uploadFile(url, file)
      .then(this.getAttachedFiles)
      .catch(() => {
        this.setState({ uploading: false });
        App.State.setFlash({
          name: 'alert',
          msg: 'Upload failed',
        });
      });
  }

  uploading = () => {
    if (this.state.uploading) {
      return (
        <div className="conversation__attachment-item">
          <img
            src={this.props.uploadGif}
            style={{ opacity: 0.2 }}
            width={'150'}
            alt="Uploading..."
          />
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div id="attachments">
        <div className="conversation__attachment">
          {this.attachments()}
          {this.uploading()}
          {this.dropZone()}
        </div>
      </div>
    );
  }
}

export default CustomDropzone;
