import React from 'react';
import PropTypes from 'prop-types';

class CompanyFilterBy extends React.Component {
  static defaultProps = {
    options: [],
    color: false,
    countOptions: {},
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array,
    color: PropTypes.bool,
    onChangeFilteringCriteria: PropTypes.func.isRequired,
    countOptions: PropTypes.array,
    currentFilter: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      currentIndex: 0,
    };
  }

  formatFilterName = name => name.toLowerCase().replace(' ', '_');

  handleClick = (e) => {
    const { open } = this.state;
    document.addEventListener('click', this.handleClick, false);
    if (ReactDOM.findDOMNode(this).contains(e.target)) {
      if (!this.dropDown.contains(e.target) && open) {
        this.setState({
          open: false,
        });
        document.removeEventListener('click', this.handleClick, false);
      } else if (open) {
        this.setState({ open: false });
      } else {
        this.setState({ open: true });
      }
      return;
    }
    this.setState({ open: false });
    document.removeEventListener('click', this.handleClick, false);
  }


  getCounts = option => {
    const { countOptions } = this.props;
    const { currentIndex } = this.state;
    const count = countOptions.filter(optionCount => optionCount[option]);
    if (currentIndex > 0) {
      return '';
    }
    if (count[0] !== undefined) {
      return `(${count[0][option]})`;
    }
    return '(0)';
  }

  render() {
    const {
      open,
      currentIndex,
    } = this.state;
    const {
      name,
      options,
      color,
      currentFilter,
      onChangeFilteringCriteria,
    } = this.props;

    const label = currentFilter ?
      (currentFilter.filter === 'all' ?
        `${currentFilter.label}`
        :
        currentFilter.multiple ?
          `${currentFilter.label}: Filtered`
          :
          `${options[currentIndex][1]}: Filtered`)
      :
      (color || options[currentIndex][0] === 'all' ? name
        :
        options[currentIndex][1]);

    return (
      <div className="ml1">
        <div className="relative">
          <div className="cursor-pointer flex flex-center" onClick={(e) => { this.handleClick(e); }}>
            <span className="text-blue right-align flex">
              {label}
              {((currentFilter && currentFilter.filter !== 'all' && !currentFilter.multiple) || (!currentFilter && options[currentIndex][0] !== 'all')) && color &&
                <span className="rounded" style={{ width: '21px', marginLeft: '5px', backgroundColor: `#${options[currentIndex][2]}` }} />
              }
            </span>
            <i className="ml1 fa fa-caret-down" />
            <div className={`dropdown dropdown--center absolute bg-white border border-alto bs-dropdown left-70 transform-horizontal-center z5 top-30 ${open ? '' : 'hidden'} py1`} style={{ maxHeight: '300px' }} ref={(r) => { this.dropDown = r; }}>
              <div className="overflow-y-scroll" style={{ maxHeight: '300px', width: 'max-content' }}>
                <ul className="left-align m0 mw200">
                  { options.map((option, index) => (
                    <li
                      className={`fw400 ${
                        (!currentFilter && currentIndex === index) ||
                        (currentFilter &&
                          ((!currentFilter.multiple && currentFilter.filter === option[0]) ||
                          (currentFilter.multiple &&
                            ((currentFilter.filter === 'all' && option[0] === 'all') ||
                            (Array.isArray(currentFilter.filter) && currentFilter.filter.includes(option[0])))))) ? 'text-green' : ''} h5 hover-bg-wild-sand`}
                      key={option[0]}
                    >
                      <div
                        onClick={() => {
                          onChangeFilteringCriteria({ name: this.formatFilterName(name), filter: options[index][0] });
                          this.setState({
                            currentIndex: index,
                            open: true,
                          });
                        }}
                        className="flex p1 capitalize"
                      >
                        {((!currentFilter && currentIndex === index) || (currentFilter && ((!currentFilter.multiple && currentFilter.filter === option[0]) || (currentFilter.multiple && ((currentFilter.filter === 'all' && option[0] === 'all') || (Array.isArray(currentFilter.filter) && currentFilter.filter.includes(option[0]))))))) ?
                          <i className="fa fa-fw fa-check pr05" />
                          :
                          <div className="fa fa-fw pr05" />
                        }
                        {option[1]} {option[0] !== 'all' ? `${this.getCounts(option[0])}` : ''}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyFilterBy;
