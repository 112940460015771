import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import withSorting from '../utils/withSorting';
import SorterHeader from '../modules/SorterHeader';

import KpiRow from './KpiRow';

import SingleKpisLibraryContainer from '../../containers/SingleKpisLibraryContainer';


class KpisOverview extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    edit: PropTypes.bool.isRequired,
    periodStepOptions: PropTypes.array.isRequired,
    unitOptions: PropTypes.array.isRequired,
    sortedData: PropTypes.array.isRequired,
    refreshKpis: PropTypes.func.isRequired,
    updateKpi: PropTypes.func.isRequired,
    deleteKpi: PropTypes.func.isRequired,
    handleExport: PropTypes.func.isRequired,
  }

  state = {
    kpiId: null,
  }

  onDragEnd = result => {
    if (!result.destination) return;

    const { sortedData, updateKpi } = this.props;
    const kpi = sortedData.find(k => k.position === (result.source.index + 1));

    updateKpi(kpi, { position: result.destination.index + 1 });
  }

  renderHeader = () => (
    <div className="flex bg-extra-light-gray text-light-gray fw400 h5 px3 py1">
      <div className="col-5 sm-col-5 flex flex-justify-start"><SorterHeader text="Name" field="metric" /></div>
      <div className="col-2 sm-col-2 flex flex-justify-start"><SorterHeader text="From Library" field="generic_kpi_metric" /></div>
      <div className="col-2 sm-hide flex flex-justify-center"><SorterHeader text="Freq./Unit" field="period_step" /></div>
      <div className="col-2 sm-hide flex flex-justify-start"><SorterHeader text="Source" field="active_source" /></div>
      {/* <div className="col-2"><SorterHeader text="Last Updated" field="updated_at" /></div> */}
      <div className="col-2 sm-col-6 flex flex-justify-start"><SorterHeader text="Last Actual Data" field="last_value_date" /></div>
      {/* Preview is not being used anymore for now */}
      {/* <div className="col-1 sm-hide">Preview</div> */}
      <div className="pr1 mx1" />
    </div>
  )

  render() {
    const {
      currentUser,
      edit,
      periodStepOptions,
      unitOptions,
      sortedData,
      updateKpi,
      deleteKpi,
      refreshKpis,
      handleExport,
    } = this.props;

    const { kpiId } = this.state;

    const genericKpiId = kpiId ? sortedData.find(el => el.id === kpiId).generic_kpi_id : null;

    return (
      <>
        {this.renderHeader()}
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div ref={provided.innerRef}>
                {sortedData.map(kpi => (
                  <KpiRow
                    key={`kpi_${kpi.id}`}
                    kpi={kpi}
                    periodStepOptions={periodStepOptions}
                    unitOptions={unitOptions}
                    edit={edit}
                    currentUser={currentUser}
                    updateKpi={updateKpi}
                    deleteKpi={deleteKpi}
                    refreshKpis={refreshKpis}
                    handleExport={handleExport}
                    updateSingleKpiLibraryId={(id) => this.setState({ kpiId: id })}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {kpiId !== null &&
          <SingleKpisLibraryContainer
            singleKpiId={kpiId}
            genericKpiId={genericKpiId}
            updateSingleKpiLibraryId={() => this.setState({ kpiId: null })}
          />
        }
      </>
    );
  }
}

export default withSorting(KpisOverview, props => props.kpis);
