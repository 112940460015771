
const contactIcon = (name, style = {}) => {
  switch (name) {
    case 'Facebook':
      return <i
        className={`fa fa-facebook-square text-color-facebook ${style.marginRight ? '' : 'mr1'}`}
        style={style}
      />;
    case 'Twitter':
      return <i
        className={`fa fa-twitter-square text-color-twitter ${style.marginRight ? '' : 'mr1'}`}
        style={style}
      />;
    case 'Linkedin':
      return <i
        className={`fa fa-linkedin-square text-color-linkedin ${style.marginRight ? '' : 'mr1'}`}
        style={style}
      />;
    case 'Email':
      return <i
        className={`fa fa-envelope-o text-light-gray ${style.marginRight ? '' : 'mr1'}`}
        style={style}
      />;
    case 'Other':
      return <i
        className={`fa fa-link text-light-gray ${style.marginRight ? '' : 'mr1'}`}
        style={style}
      />;
    case 'Crunchbase':
      return (
        <div
          className="bg-center bg-cover bg-no-repeat circle mr-7"
          style={{
            backgroundImage: `url(${window.images.crunchbase})`,
            height: '16px',
            width: '17px',
          }}
        />
      );
    default:
      return <i
        className={`fa fa-globe text-light-gray ${style.marginRight ? '' : 'mr1'}`}
        style={style}
      />;
  }
};

const contactLink = (contact) => {
  let link = contact.text;

  switch (contact.name) {
    case 'Facebook': {
      const regex = /http(s)?:\/\/(www.)?(facebook|fb).com/ig;
      if (!regex.test(contact.text)) {
        if (contact.text.charAt(0) === '@') {
          link = contact.text.substr(1);
        }
        return `https://facebook.com/${link}`;
      }
      return link;
    }

    case 'Twitter': {
      const regex = /http(s)?:\/\/(www.)?twitter.com/ig;
      if (!regex.test(contact.text)) {
        if (contact.text.charAt(0) === '@') {
          link = contact.text.substr(1);
        }
        return `https://twitter.com/${link}`;
      }
      return link;
    }

    case 'Linkedin': {
      const regex = /http(s)?:\/\/(www.)?linkedin.com/ig;
      if (!regex.test(contact.text)) {
        if (contact.text.charAt(0) === '@') {
          link = contact.text.substr(1);
        }
        return `https://linkedin.com/in/${link}`;
      }
      return link;
    }

    case 'Email': {
      return `mailto: ${contact.text}`;
    }

    default:
      return (contact.text.indexOf('://') === -1) ? `http://${contact.text}` : contact.text;
  }
};

export {
  contactIcon,
  contactLink,
};
