import React from 'react';
import PropTypes from 'prop-types';

import DropdownMenu from '../../../DropdownMenu';

// some are repeated because they are being used in different dashboards (Home and Fund)
const tabNames = {
  start: 'Start',
  company_info: 'Companies',
  infos: 'Info',
  investment_info: 'Investments',
  investments: 'Investments',
  kpis: 'KPIs',
  data_rooms: 'Data Room(s)',
  portfolio_updates: 'Updates',
  co_investors: 'Co-Investors',
  reports: 'Reports',
};

const frequencyOptions = [
  { value: 'months', label: 'Monthly Frequency' },
  { value: 'quarters', label: 'Quarterly Frequency' },
  { value: 'years', label: 'Yearly Frequency' },
];

const correctTabName = (tabName, activeTab) => {
  if (tabName === 'portfolio_updates') {
    return activeTab === 'recent_updates' || activeTab === 'portfolio_updates';
  }
  return activeTab === tabName;
};

const Tabs = ({ dashboardTabs, activeTab, setTabAndResetSorting, timeFrameChangeForKpisTab, color, frequency, getKpisData, showInvestments, fundDashboard }) => (
  <div className="mt1 mb2 border-bottom border-alto sm-vw-85 flex items-center flex-justify-between">
    <div className="flex col-7 h5">
      {dashboardTabs.map((dashboardTab, index) => (
        <div
          key={dashboardTab.tab_name}
          className={`${correctTabName(dashboardTab.tab_name, activeTab) ? 'border-bottom border-blue fw400 bw-2' : 'text-light-gray cursor-pointer'} pt1 pb05 mr2 ${index !== 0 ? 'sm-hide' : ''}`}
          onClick={() => setTabAndResetSorting(dashboardTab.tab_name)}
          style={{ borderColor: color }}
        >
          {tabNames[dashboardTab.tab_name]}
        </div>
      ))
      }
    </div>
    {activeTab === 'kpis' &&
      <div className="flex">
        <div>
          <DropdownMenu
            text={frequencyOptions.find(options => options.value === frequency).label}
            faIcon="caret-down"
            iconPos="right"
            textColor="blue"
            textSize="h5 bold"
            setFalseWhenClickingInside
            margin={fundDashboard ? '' : 'mxn2'}
          >
            {frequencyOptions.map(option => (
              <li
                key={option.value}
                className={`px2 hover hover-bg-wild-sand hover-text-blue text-gray fw400 cursor-pointer h5 lh-36 ${option.value === frequency ? 'bg-wild-sand' : ''}`}
              >
                <div onClick={() => timeFrameChangeForKpisTab(option.value)}>
                  {option.label}
                </div>
              </li>
            ))}
          </DropdownMenu>
        </div>

        {showInvestments && fundDashboard &&
          <div
            className="cursor-pointer text-light-gray ml2"
            data-balloon="Refresh all data on this tab."
            data-balloon-pos="down"
            onClick={() => getKpisData()}
          >
            <span>refresh tab</span>
            <i className="fa fa-refresh ml1 h3" />
          </div>
        }
      </div>
    }
  </div>
);

Tabs.propTypes = {
  dashboardTabs: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  setTabAndResetSorting: PropTypes.func.isRequired,
  timeFrameChangeForKpisTab: PropTypes.func.isRequired,
  color: PropTypes.string,
  frequency: PropTypes.string.isRequired,
  getKpisData: PropTypes.func,
  showInvestments: PropTypes.bool,
  fundDashboard: PropTypes.bool,
};

Tabs.defaultProps = {
  color: null,
  fundDashboard: true,
  showInvestments: false,
  getKpisData: null,
};

export default Tabs;
