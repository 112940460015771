const resetKpis = kpis => ({
  type: 'RESET_KPIS',
  kpis,
});

const resetKpisAndChart = data => ({
  type: 'RESET_KPIS_AND_CHART',
  data,
});

const resetKpisByCurrentDate = (kpis, currentDate) => ({
  type: 'RESET_KPIS_BY_CURRENT_DATE',
  kpis,
  currentDate,
});

const removeKpi = kpi => ({
  type: 'REMOVE_KPI',
  kpi,
});

const updateKPIChartData = chartData => ({
  type: 'UPDATE_KPI_CHART_DATA',
  chartData,
});

export {
  resetKpis,
  resetKpisAndChart,
  resetKpisByCurrentDate,
  removeKpi,
  updateKPIChartData,
};
