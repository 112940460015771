import React, { Component } from 'react';
import PropTypes from 'prop-types';


class ViewAs extends Component {
  static propTypes = {
    chosenChartType: PropTypes.string.isRequired,
    multiKpis: PropTypes.bool.isRequired,
    handleChartOptionUpdate: PropTypes.func.isRequired,
    blurred: PropTypes.bool.isRequired,
  }

  toBeDeleted = () => {
    return true;
  }

  render() {
    const {
      chosenChartType,
      multiKpis,
      handleChartOptionUpdate,
      blurred,
    } = this.props;

    return (
      <div className={`flex items-center ${blurred ? 'hide-data' : ''}`}>
        <span className="mr1 text-gray">View as</span>
        <div className="flex">
          <div
            className={`hover-border-medium-gray border ${(chosenChartType === 'column' || chosenChartType === 'bar') ? 'border-medium-gray' : 'border-alto'} bg-white cursor-pointer flex items-center px1`}
            data-balloon={multiKpis ? 'If you choose more than one KPI, the selected chart type will be used for the 1st one, and the 2nd one will use the other chart type' : 'Bar chart'}
            data-balloon-pos="down"
            data-balloon-length={multiKpis ? 'large' : 'small'}
            onClick={() => handleChartOptionUpdate('chosenChartType', 'column')}
            style={{ minHeight: '38px', minWidth: '33px' }}
          >
            <img src={window.images.bar} alt="Select bar chart..." height="15" width="15" />
          </div>
          <div
            className={`hover-border-medium-gray border ${chosenChartType === 'line' ? 'border-medium-gray' : 'border-alto'} bg-white cursor-pointer flex items-center px1 ml1`}
            data-balloon={multiKpis ? 'If you choose more than one KPI, the selected chart type will be used for the 1st one, and the 2nd one will use the other chart type' : 'Line chart'}
            data-balloon-pos="down"
            data-balloon-length={multiKpis ? 'large' : 'small'}
            onClick={() => handleChartOptionUpdate('chosenChartType', 'line')}
            style={{ minHeight: '38px', minWidth: '33px' }}
          >
            <img src={window.images.line} alt="Select line chart..." height="15" width="15" />
          </div>
        </div>
      </div>
    );
  }
}

export default ViewAs;
