import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../Modal';
import CleaveNumberFormatting from '../../CleaveNumberFormatting';
import HtmlComponent from '../HtmlComponent';


const InvestorCustomReminderFilterModal = ({ title, subTitle, confirmationText, lastMonthKpis, percentageCompleted, lastMonthUpdates, capTableUpdated, setFilter, closeModal, updateState }) => (
  <Modal
    className="react_modal--300"
    show
    onClickOutside={() => closeModal()}
  >
    <div className="flex flex-justify-between p2 border-bottom border-lighter-gray">
      <div>
        <div className="h3 bold">{title}</div>
        <div className="mt1">
          <HtmlComponent data={{ html: subTitle, css_class: 'h5 text-light-gray' }} />
        </div>
      </div>
      <i className="fa fa-close h5 cursor-pointer bold" onClick={() => closeModal()} />
    </div>

    <div className="text-gray p3 h4">
      <div className="flex flex-column">
        <div className="flex flex-column mb3 fw400">
          <span className="h3 pb1">Select Criteria</span>
          <span className="h4">Company is shown if it meets ANY of the following:</span>
        </div>
        <>
          <div className="flex items-center">
            <span className="mr1 fw400">Last month with KPIs:</span>
            <span className="mr1">earlier than</span>
            <div className="col-4">
              <CleaveNumberFormatting
                value={`${lastMonthKpis}`}
                onChange={(e) => updateState('lastMonthKpis', e.target.rawValue)}
                className="appearance-textfield block border border-alto rounded col-4"
                unit="month(s) ago"
              />
            </div>
          </div>
          <div className="flex items-center mt2">
            <span className="mr1 fw400">% completed:</span>
            <span className="mr1">less than</span>
            <div className="col-4">
              <CleaveNumberFormatting
                value={`${percentageCompleted}`}
                onChange={(e) => updateState('percentageCompleted', e.target.rawValue)}
                className="appearance-textfield block border border-alto rounded col-4"
                unit="%"
              />
            </div>
          </div>
          <div className="flex items-center mt2">
            <span className="mr1 fw400">Last month with updates:</span>
            <span className="mr1">earlier than</span>
            <div className="col-4">
              <CleaveNumberFormatting
                value={`${lastMonthUpdates}`}
                onChange={(e) => updateState('lastMonthUpdates', e.target.rawValue)}
                className="appearance-textfield block border border-alto rounded col-4"
                unit="month(s) ago"
              />
            </div>
          </div>
          <div className="flex items-center mt2">
            <span className="mr1 fw400">Cap table updated:</span>
            <span className="mr1">earlier than</span>
            <div className="col-4">
              <CleaveNumberFormatting
                value={`${capTableUpdated}`}
                onChange={(e) => updateState('capTableUpdated', e.target.rawValue)}
                className="appearance-textfield block border border-alto rounded col-4"
                unit="month(s) ago"
              />
            </div>
          </div>
        </>
      </div>
    </div>

    <div className="bg-white p3">
      <div className="pt3 flex flex-center flex-justify-around">
        <div
          href="#"
          className="col-3 center py1 bg-light-gray rounded text-white cursor-pointer"
          onClick={(e) => { e.preventDefault(); closeModal(); }}
        >
          Close
        </div>

        <span
          className="col-3 center py1 bg-green rounded text-white cursor-pointer"
          onClick={() => setFilter()}
        >
          {confirmationText}
        </span>
      </div>
    </div>
  </Modal>
);

InvestorCustomReminderFilterModal.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  confirmationText: PropTypes.string,
  lastMonthKpis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentageCompleted: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  lastMonthUpdates: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  capTableUpdated: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setFilter: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateState: PropTypes.func.isRequired,
};

InvestorCustomReminderFilterModal.defaultProps = {
  title: 'Selection/Filter',
  subTitle: null,
  confirmationText: 'Confirm',
};

export default InvestorCustomReminderFilterModal;
