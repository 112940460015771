import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import axios from 'axios';

import Avatar from '../tab_view/Avatar';
import HtmlComponent from '../tab_view/HtmlComponent';
import { defaultSelectStyles, defaultSelectTheme } from '../selects/SelectStyles';
import RoundModal from '../company/cap_tables/RoundModal';
import ConfirmationModal from '../ConfirmationModal';
import Button from '../Button';

import RoundsImportModal from '../company/cap_tables/RoundsImportModal';
import UpgradeAccountModal from '../modals/UpgradeAccountModal';


class CapTableHeader extends Component {
  static propTypes = {
    editor: PropTypes.bool.isRequired,
    company: PropTypes.object.isRequired,
    capTable: PropTypes.object,
    capTableHeader: PropTypes.object.isRequired,
    viewType: PropTypes.string.isRequired,
    setViewType: PropTypes.func.isRequired,
    updateRound: PropTypes.func,
    canExport: PropTypes.bool,
    maxInvestments: PropTypes.number,
  }

  static defaultProps = {
    capTable: null,
    updateRound: null,
    canExport: true,
    maxInvestments: null,
  }

  state = {
    showRoundModal: false,
    showImportModal: false,
    selectedRound: {},
    showUpgradeModal: false,
  }

  deleteRound = () => {
    const { company, capTable } = this.props;

    axios.delete(`/company/${company.id}/manual_cap_tables/${capTable.id}`).then(
      () => { window.location = `/c/${company.id}/manual_cap_tables`; },
      (err) => { App.State.setFlash({ name: 'alert', msg: err.response.data.error }); },
    );
  }

  toggleRoundModal = (round = {}) => this.setState(state => ({ showRoundModal: !state.showRoundModal, selectedRound: round }));

  render() {
    const { editor, company, capTable, capTableHeader, viewType, setViewType, updateRound, canExport, maxInvestments } = this.props;
    const { showRoundModal, showImportModal, selectedRound, showUpgradeModal } = this.state;

    const viewTypeOptions = [{ value: 'shareholders', label: 'Shareholders' }, { value: 'share_class', label: 'Share Class' }];

    return (
      <React.Fragment>
        <div className="flex flex-justify-between hide-print mt1">
          {editor &&
            <div className="flex flex-justify-start items-center text-medium-gray h5">
              Want to know about Cap Table? Click here!
              <a
                href="http://docs.investory.io/en/articles/6860237-how-to-build-a-cap-table-on-investory"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="fw400 h5 regular text-green">
                  <i className="fa fa-fw fa-life-ring ml1" />
                  Captable Tutorial
                </span>
              </a>
            </div>
          }
          <div className="flex flex-justify-end items-center sm-hide">
            <Button
              classNames="btn btn-outline border-alto bg-white h5 p1 mr1"
              onClick={() => { window.print(); return false; }}
            >
              <i className="fa fa-print text-gray" />
            </Button>
            <Button classNames="btn btn-outline btn-small border-alto bg-white h5 p1">
              <div
                onClick={() => { canExport ? window.location = `/c/${company.id}/manual_cap_tables/${capTableHeader.last_round_id}/export` : this.setState({ showUpgradeModal: true }); }}
              >
                <i className="fa fa-file-excel-o text-gray" />
              </div>
            </Button>
            <UpgradeAccountModal
              show={showUpgradeModal}
              closeModal={() => this.setState({ showUpgradeModal: false })}
              exportFeature
              sendEmailOnClick
              paywallDescription="Paywall_Export"
              maxInvestments={maxInvestments}
            />
            {editor &&
              <Button
                classNames="btn btn-outline btn-small border-alto bg-white h5 ml1"
                onClick={() => this.setState({ showImportModal: true })}
              >
                <i className="fa fa-cloud-upload h5 text-gray" />
                <span className="ml1 h5 text-gray">Import</span>
              </Button>
            }
          </div>
        </div>
        <div className="bs-around bg-white">
          <div className="mx3 mt2 py2 pb3">
            <div className="flex flex-justify-between items-center mt1 sm-flex-column">
              <div className="flex items-center col-12 sm-flex-column">
                <Avatar size="54px" avatarUrl={company.logo} shadow />
                <div className="flex flex-column ml2 mr2 flex-auto col-12 sm-mt2">
                  <span className="h3 col-12 sm-col-12 fw400 p0 lh-125 bg-none text-gray border-transparent">
                    {company.name}
                  </span>
                  <span className="h5 col-12 sm-col-12 p0 lh-125 bg-none text-gray border-transparent">
                    {capTableHeader.rounds_count > 1 ?
                      <HtmlComponent
                        data={{ html: `${company.name} has raised a total of <span class="fw400">${company.currency} ${capTableHeader.total_raised.readable}</span> in funding over <span class="fw400">${capTableHeader.rounds_count} rounds</span> since their
                        founding in <span class="fw400">${company.established_month}, ${company.established_year}</span>. <br/>Their latest funding was raised in <span class="fw400">${capTableHeader.last_funding_date || '&mdash;'}</span>
                        from a <span class="fw400">${capTableHeader.last_round_name || 'Unspecified'}</span> round where they raised <span class="fw400">${company.currency} ${capTableHeader.last_cash_raised}</span>.` }}
                      />
                      :
                      <HtmlComponent
                        data={{ html: `${company.name} has raised <span class="fw400">${company.currency} ${capTableHeader.total_raised.readable}</span> with a Post-Money valuation of <span class="fw400">${company.currency} ${capTableHeader.last_post_money.readable}</span> and a Pre-Money valuation of <span class="fw400">${company.currency} ${capTableHeader.last_pre_money}</span></br>
                        from their most recent <span class="fw400">${capTableHeader.last_round_name || 'Unspecified'}</span> round, which was raised in <span class="fw400">${capTableHeader.last_funding_date || '&mdash;'}</span>.` }}
                      />
                    }
                  </span>
                </div>
              </div>
              {capTableHeader.cap_table_file &&
                <div
                  className="flex-auto col-4 hide-print sm-col-10 sm-mt2"
                  data-balloon={editor ? 'Captable document is linked from/managed in the company documents (see Data Rooms in the main navigation).' : null}
                  data-balloon-pos="down"
                  data-balloon-length="large"
                >
                  <div className="border border-gallery rounded py1 px1 card--has-hover-shadow-light-gray__small">
                    <a className="flex items-center ml1 h5 fw400" href={` /c/${company.id}/data_room_files/${capTableHeader.cap_table_file.id}`}>
                      <i className={`${capTableHeader.cap_table_file.icon_class_name} fa-2x`} style={{ color: capTableHeader.cap_table_file.icon_color }} />
                      <div className="flex flex-column col-10">
                        <div className="pl1 lh-15 text-gray truncate">{capTableHeader.cap_table_file.file_name}</div>
                        <span className="pl1 lh-15 text-light-gray h6 lh-05">{capTableHeader.cap_table_file.created_at}</span>
                      </div>
                    </a>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="border-top border-gallery sm-hide">
            {capTableHeader.rounds_count > 1 ?
              <div className="flex flex-justify-evenly">
                <div
                  className="flex flex-column items-center my15 px3 center lh-12"
                  data-balloon={capTableHeader.last_post_money.raw}
                  data-balloon-pos="bottom"
                  data-balloon-length="medium"
                >
                  <span className="fw400 h4 text-gray">{capTableHeader.last_post_money.readable}</span>
                  <span className="fw400 h5 text-gray show-print" style={{ display: 'none' }}>({capTableHeader.last_post_money.raw})</span>
                  <span className="text-light-gray fw400 h5 mt05">Post-Money</span>
                </div>
                <div className="border border-gallery bw-05 my2" />
                <div
                  className="flex flex-column items-center my15 px3 center lh-12"
                  data-balloon={capTableHeader.total_raised.raw}
                  data-balloon-pos="bottom"
                  data-balloon-length="medium"
                >
                  <span className="fw400 h4 text-gray">{capTableHeader.total_raised.readable}</span>
                  <span className="fw400 h5 text-gray show-print" style={{ display: 'none' }}>({capTableHeader.total_raised.raw})</span>
                  <span className="text-light-gray fw400 h5 mt05">Total Raised</span>
                </div>
                <div className="border border-gallery bw-05 my2" />
                <div className="flex flex-column items-center my15 px3 center lh-12">
                  <span className="fw400 h4 text-gray">{capTableHeader.last_round_name}</span>
                  <span className="text-light-gray fw400 h5 mt05">Latest Round</span>
                </div>
                <div className="border border-gallery bw-05 my2" />
                <div className="flex flex-column items-center my15 px3 center lh-12">
                  {capTableHeader.last_funding_date ?
                    <span className="fw400 h4 text-gray">{capTableHeader.last_funding_date}</span>
                    :
                    <span className="fw400 h4 text-gray">&mdash;</span>
                  }
                  <span className="text-light-gray fw400 h5 mt05">Last Raised</span>
                </div>
                <div className="border border-gallery bw-05 my2" />
                <div className="flex flex-column items-center my15 px3 center lh-12">
                  <span className="fw400 h4 text-gray">{capTableHeader.rounds_count}</span>
                  <span className="text-light-gray fw400 h5 mt05">Investment Rounds</span>
                </div>
                <div className="border border-gallery bw-05 my2" />
                <div className="flex flex-column items-center my15 px3 center lh-12">
                  <span className="fw400 h4 text-gray">{capTableHeader.shareholders_count}</span>
                  <span className="text-light-gray fw400 h5 mt05">Shareholders</span>
                </div>
              </div>
              :
              <div className="flex flex-justify-evenly">
                <div
                  className="flex flex-column items-center my15 px3 center lh-12"
                  data-balloon={capTableHeader.last_post_money.raw}
                  data-balloon-pos="bottom"
                  data-balloon-length="medium"
                >
                  <span className="fw400 h4 text-gray">{capTableHeader.last_post_money.readable}</span>
                  <span className="fw400 h5 text-gray show-print" style={{ display: 'none' }}>({capTableHeader.last_post_money.raw})</span>
                  <span className="text-light-gray fw400 h5 mt05">Post-Money</span>
                </div>
                <div className="border border-gallery bw-05 my2" />
                <div
                  className="flex flex-column items-center my15 px3 center lh-12"
                  data-balloon={capTableHeader.total_raised.raw}
                  data-balloon-pos="bottom"
                  data-balloon-length="medium"
                >
                  <span className="fw400 h4 text-gray">{capTableHeader.total_raised.readable}</span>
                  <span className="fw400 h5 text-gray show-print" style={{ display: 'none' }}>({capTableHeader.total_raised.raw})</span>
                  <span className="text-light-gray fw400 h5 mt05">Cash Raised</span>
                </div>
                <div className="border border-gallery bw-05 my2" />
                <div className="flex flex-column items-center my15 px3 center lh-12">
                  <span className="fw400 h4 text-gray">{capTableHeader.last_round_name}</span>
                  <span className="text-light-gray fw400 h5 mt05">Latest Round</span>
                </div>
                <div className="border border-gallery bw-05 my2" />
                <div className="flex flex-column items-center my15 px3 center lh-12">
                  <span className="fw400 h4 text-gray">
                    {capTableHeader.latest_round_type ?
                      <span className="fw400 h4 text-gray capitalize">{capTableHeader.latest_round_type.replace('_', ' ')}</span>
                      :
                      <span className="fw400 h4 text-gray">&mdash;</span>
                    }
                  </span>
                  <span className="text-light-gray fw400 h5 mt05">Round Type</span>
                </div>
                <div className="border border-gallery bw-05 my2" />
                <div className="flex flex-column items-center my15 px3 center lh-12">
                  {capTableHeader.last_funding_date ?
                    <span className="fw400 h4 text-gray">{capTableHeader.last_funding_date}</span>
                    :
                    <span className="fw400 h4 text-gray">&mdash;</span>
                  }
                  <span className="text-light-gray fw400 h5 mt05">Last Raised</span>
                </div>
                <div className="border border-gallery bw-05 my2" />
                <div className="flex flex-column items-center my15 px3 center lh-12">
                  <span className="fw400 h4 text-gray">{capTableHeader.shareholders_count}</span>
                  <span className="text-light-gray fw400 h5 mt05">Shareholders</span>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="mb3-print" />
        <div className="my3 flex hide-print">
          <div className="flex flex-justify-between flex-auto h5 sm-flex-column">
            <div className="flex items-center flex-auto">
              {capTable &&
                <React.Fragment>
                  <div className="mr1 col-5 sm-col-8">
                    <ReactSelect
                      defaultValue={capTableHeader.rounds.filter(option => option.value === capTable.id)}
                      options={capTableHeader.rounds}
                      onChange={(selectedOption) => { window.location = `/c/${company.id}/manual_cap_tables/${selectedOption.value}`; }}
                      styles={defaultSelectStyles}
                      theme={defaultSelectTheme}
                    />
                  </div>
                  <div className="flex items-center flex-auto col-12">
                    <span className="text-light-gray mr1 sm-col-4">View By</span>
                    <div className="col-5 sm-col-8">
                      <ReactSelect
                        defaultValue={viewTypeOptions.filter(option => option.value === viewType)}
                        value={viewTypeOptions.filter(option => option.value === viewType)}
                        options={viewTypeOptions}
                        onChange={(selectedOption) => setViewType(selectedOption.value)}
                        styles={defaultSelectStyles}
                        theme={defaultSelectTheme}
                      />
                    </div>
                  </div>
                </React.Fragment>
              }
            </div>
            {editor &&
              <div className="flex sm-hide">
                <Button
                  classNames="btn btn-outline btn-small border-alto bg-white sm-mb1 sm-col-6"
                  onClick={() => this.toggleRoundModal()}
                >
                  <i className="fa fa-plus h5 text-gray" />
                  <span className="ml1 h5 text-gray">New Round</span>
                </Button>
                {capTable &&
                  <React.Fragment>
                    <Button
                      classNames="btn btn-outline btn-small border-alto bg-white ml2 sm-mr0 sm-ml0 sm-mb1 sm-col-6"
                      onClick={() => this.toggleRoundModal(capTable)}
                    >
                      <i className="fa fa-pencil h5 text-gray" />
                      <span className="ml1 h5 text-gray">Edit Round</span>
                    </Button>
                    {capTableHeader.rounds_count > 1 &&
                      <Button
                        classNames="btn btn-outline btn-small border-alto bg-white sm-col-6 ml2"
                      >
                        <i className="fa fa-trash h5 text-gray" />
                        <ConfirmationModal
                          html={'<span>Delete Round</span>'}
                          className="ml1 h5 text-gray"
                          onClick={this.deleteRound}
                          message="<b>You are about to permanently delete this round. This cannot be undone.</b><br/><br/>Are you sure you want to delete?"
                        />
                      </Button>
                    }
                  </React.Fragment>
                }
              </div>
            }
          </div>
        </div>
        {showRoundModal &&
          <RoundModal
            show={showRoundModal}
            closeModal={this.toggleRoundModal}
            options={capTableHeader.stages}
            company={company}
            round={selectedRound}
            updateRound={updateRound}
          />
        }
        <RoundsImportModal
          company={company}
          show={showImportModal}
          closeModal={() => this.setState({ showImportModal: false })}
        />
      </React.Fragment>
    );
  }
}

export default CapTableHeader;
