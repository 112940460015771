import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../../../store/configureStore';
import CaptableContainer from '../../../containers/company/cap_tables/CapTableContainer';


const CompanyCaptable = props => (
  <Provider store={configureStore(props)}>
    <CaptableContainer />
  </Provider>
);

export default CompanyCaptable;
