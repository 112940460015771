import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import OutsideClick from '../../../utils/OutsideClick';
import CheckBox from '../../../CheckBox';
import ColorPicker from '../../../selects/ColorPicker';

import { defaultSelectStyles, defaultSelectTheme } from '../../../selects/SelectStyles';


class CogOfOptions extends Component {
  static propTypes = {
    btnColorThemeOpened: PropTypes.bool.isRequired,
    chartValueTypes: PropTypes.array.isRequired,
    chosenChartValueType: PropTypes.string.isRequired,
    showDataLabels: PropTypes.bool.isRequired,
    actualColors: PropTypes.array.isRequired,
    planColors: PropTypes.array.isRequired,
    multiKpis: PropTypes.bool.isRequired,
    maxMultiOptionsLength: PropTypes.number.isRequired,
    handleColorPickerClick: PropTypes.func.isRequired,
    handleChartOptionUpdate: PropTypes.func.isRequired,
    handleChartColorsUpdate: PropTypes.func.isRequired,
    blurred: PropTypes.bool.isRequired,
  }

  toBeDeleted = () => {
    return true;
  }

  render() {
    const {
      btnColorThemeOpened,
      chartValueTypes,
      chosenChartValueType,
      showDataLabels,
      actualColors,
      planColors,
      multiKpis,
      maxMultiOptionsLength,
      handleColorPickerClick,
      handleChartOptionUpdate,
      handleChartColorsUpdate,
      blurred,
    } = this.props;

    return (
      <div className={`flex items-center h5 text-light-gray relative ${blurred ? 'hide-data' : ''}`}>
        <div
          className={`hover-border-medium-gray border ${btnColorThemeOpened ? 'border-medium-gray' : 'border-alto'} bg-white cursor-pointer flex items-center px1 mx1`}
          onClick={() => handleChartOptionUpdate('btnColorThemeOpened', !btnColorThemeOpened)}
          style={{ minHeight: '38px' }}
        >
          <i className="fa fa-cog mr1" />
          <i className="fa fa-angle-down" />
        </div>
        {
          btnColorThemeOpened ? (
            <OutsideClick onClick={handleColorPickerClick}>
              <div className="absolute bg-white border border-alto bs-dropdown top-45 left-0 z1 p2" style={{ width: '200px' }}>
                <div>Values to Chart</div>
                <ReactSelect
                  className="fit mt1"
                  defaultValue={chartValueTypes.filter(option => option.value === chosenChartValueType)}
                  value={chartValueTypes.filter(option => option.value === chosenChartValueType)}
                  options={chartValueTypes}
                  idClearable={false}
                  onChange={selectedOption => handleChartOptionUpdate('chosenChartValueType', selectedOption.value)}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                />
                <div className="flex mt1">
                  <CheckBox
                    defaultChecked={showDataLabels}
                    name="show_data_labels"
                    onChange={() => handleChartOptionUpdate('showDataLabels', !showDataLabels)}
                    fixedWidth
                    size="h5"
                  />
                  <div className="ml1">Show Data Labels</div>
                </div>

                <>
                  <div className="mt1 mb1">Chart Colors</div>
                  <div className="flex">
                    <div className="flex flex-column">
                      {actualColors.map((color, index) => (
                        <div className={`flex mr2 ${!multiKpis || maxMultiOptionsLength === (index + 1) ? '' : 'mb2'}`} key={`actual_${index + 1}`}>
                          {multiKpis ? <span className="mr1">{`${index + 1}-`}</span> : null}
                          <ColorPicker
                            color={color}
                            onChange={c => handleChartColorsUpdate('actual', c.hex, index)}
                            infoMessage={null}
                            leftText="Actual"
                            type="twitter"
                          />
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-column">
                      {planColors.map((color, index) => (
                        <div className={`flex mr2 ${!multiKpis || maxMultiOptionsLength === (index + 1) ? '' : 'mb2'}`} key={`plan_${index + 1}`}>
                          <ColorPicker
                            color={color}
                            onChange={c => handleChartColorsUpdate('plan', c.hex, index)}
                            infoMessage={null}
                            leftText="Plan"
                            type="twitter"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              </div>
            </OutsideClick>
          ) : null
          }
      </div>
    );
  }
}

export default CogOfOptions;
