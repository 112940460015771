import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Convertible from './Convertible';


class ConvertiblesTable extends Component {
  static propTypes = {
    convertibles: PropTypes.array.isRequired,
    createUrl: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    updateTopState: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
    edit: PropTypes.bool.isRequired,
  }

  createConvertible = e => {
    e.preventDefault();
    const { createUrl, updateTopState } = this.props;

    App.Api.post(createUrl, {}).then(
      data => updateTopState(data),
    );
  }

  displayAdd = () => {
    const { edit, currency } = this.props;

    if (edit) {
      return (
        <div className="flex mxn2 cursor-pointer" onClick={this.createConvertible}>
          <div className="col-4 px2">
            <p className="h6 m0 center text-green">Add New</p>
          </div>

          <div className="col-4 px2">
            <p className="h6 m0 center text-light-gray">{currency}</p>
          </div>

          <div className="col-4 px2">
            <p className="h6 m0 center text-light-gray">%</p>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    const { convertibles, currency, updateItem, updateTopState, edit } = this.props;

    return (
      <div className="bg-white bs-around mb2">
        <div className="flex mb2 px2 pt2">
          <p className="bold fs-18 m0 text-gray">Convertible note</p>
          <div className="flex flex-auto flex-justify-end">
            <div
              className="text-gray cursor-pointer"
              data-balloon="Convertible Notes are loans with the intention of converting to equity. For the additional risk a certain discount is allowed."
              data-balloon-pos="up"
              data-balloon-length="xlarge"
              title="test"
            >
              <i className="fa fa-info-circle" />
            </div>
          </div>
        </div>

        <div className="bg-gallery px2 py1">
          <div className="flex mxn2">
            <div className="col-4 px2">
              <p className="bold h5 m0 text-gray ml3 text-upper">Name</p>
            </div>

            <div className="col-4 px2">
              <p className="bold center h5 m0 text-gray text-upper">Invested({currency})</p>
            </div>

            <div className="col-4 px2">
              <p className="bold center h5 m0 text-gray text-upper">Discount</p>
            </div>
          </div>
        </div>

        <div className="p2">
          {convertibles.map(convertible => (
            <Convertible
              key={convertible.id}
              convertible={convertible}
              updateTopState={updateTopState}
              updateItem={updateItem}
              edit={edit}
            />
          ))}
          {this.displayAdd()}
        </div>
      </div>
    );
  }
}

export default ConvertiblesTable;
