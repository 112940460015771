import React, { Component } from 'react';
import PropTypes from 'prop-types';

class RunwayChartPortal extends Component {
  static propTypes = {
    id: PropTypes.number,
    dates: PropTypes.array,
    data_values: PropTypes.object,
  }

  componentDidMount() {
    const { id, dates, data_values, title } = this.props;

    window.createRunwayChart(`runwayChart_${id}`, dates, data_values, title);
  }

  componentDidUpdate() {
    const { id, dates, data_values, title } = this.props;

    window.createRunwayChart(`runwayChart_${id}`, dates, data_values, title);
  }

  render() {
    const { id } = this.props;

    return (
      <div className="col-12 sm-p0">
        <div id={`runwayChart_${id}`} />
      </div>
    );
  }
}

export default RunwayChartPortal;
