import React from 'react';
import PropTypes from 'prop-types';


const CommentSh = ({ comment }) => (
  <div className="flex">
    <div
      className="bg-center bg-cover bg-no-repeat box-36 circle mr2 relative"
      style={{ backgroundImage: `url(${comment.author.avatar})` }}
    >
      <a className="absolute bottom-0 left-0 right-0 top-0" href={comment.author.url} />
    </div>

    <div className="flex-auto col-10">
      <p className="m0 semi-bold h5 pr-25"><a className="text-gray" href={comment.author.url}>{comment.author.name}</a></p>

      <p className="break-word h5 mb1 pr-25 regular text-gray" dangerouslySetInnerHTML={{ __html: comment.body }} />

      <p className="h5 mb1 text-light-gray">
        <i className="fa fa-clock-o mr1" />
        {comment.ago} ago
      </p>
    </div>
  </div>
);

CommentSh.propTypes = {
  comment: PropTypes.object.isRequired,
};

export default CommentSh;
