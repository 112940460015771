import field from './investment_field';

const investmentHeader = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_MANAGERS':
      return {
        ...state,
        managers: action.managers,
        managers_for_options: action.managers_for_options,
      };

    case 'UPDATE_FUNDS':
      return {
        ...state,
        funds: action.funds,
        funds_for_options: action.funds_for_options,
      };

    case 'UPDATE_OVERAL_STATUS':
      return {
        ...state,
        status: action.status,
      };

    case 'UPDATE_FUNDRAISE_STATUS':
      return {
        ...state,
        fundraise_status: action.fundraise_status,
      };

    case 'REMOVE_COVER':
      return {
        ...state,
        cover: { id: 'cover' },
      };

    case 'UPDATE_INVESTMENT_FIELDS':
      return {
        ...state,
        company_name: field(state.company_name, action),
        about: field(state.about, action),
      };

    case 'OVERWRITE_INVESTMENT_FIELDS':
      return {
        ...state,
        company_name: field(state.company_name, action),
        about: field(state.about, action),
      };

    default: return state;
  }
};

export default investmentHeader;
