import Numeral from 'numeral';
import numberFormat from '../../user_number_format';

const addNumeralLocales = () => {
  if (!Numeral.locales.custom) {
    Numeral.register('locale', 'custom', {
      delimiters: {
        thousands: numberFormat().thousandsSep || '.',
        decimal: numberFormat().decimalPoint || ',',
      },
      abbreviations: {
        thousand: ' K',
        million: ' M',
        billion: ' B',
        trillion: ' T',
      },
      currency: {
        symbol: '€',
      },
    });
  }
};

export default function initializeNumeralLocales(locale) {
  addNumeralLocales();

  if (locale === 'custom') Numeral.locale('custom');

  return Numeral;
}
