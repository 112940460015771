import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HtmlComponent from './tab_view/HtmlComponent';


class ShareholderUpdatePost extends Component {
  static propTypes = {
    post: PropTypes.object.isRequired,
  }

  commentsLink = () => {
    const { post } = this.props;
    let text = '';
    if (post.comments_count === undefined || post.comments_count <= 2) {
      text = 'Respond here';
    } else {
      text = 'Read all comments';
    }
    return (
      <div className="pl3 pb3 pr3">
        <a className="mt2 h5" href={post.update_show_page_opening_comments}>{text}</a>
      </div>
    );
  }

  viewComments = () => {
    const comments = this.props.post.recent_comments.map((comment) => {
      const avatarStyle = {
        backgroundImage: `url(${comment.author.avatar})`,
      };
      return (
        <div className="" key={`comment_${comment.id}`}>
          <div className="flex flex-center lg-block pl3 pr3 pb1">
            <div className="relative bg-center bg-cover bg-no-repeat border border-white box-24 circle z1" style={avatarStyle} />
            <span className="h5 ml1 pr1 text-gray z1 fw400">
              {comment.author.comenter_first_name}
            </span>
            <span className="h5 text-medium-gray z1">
              <span>{comment.date}</span><span className="ml1">{comment.hour}</span>
            </span>
          </div>

          <div className="pl3 pb1 pr3">
            <p className="break-word h5 mb1 text-black">
              <HtmlComponent data={{ html: comment.body }} />
            </p>
          </div>
        </div>
      );
    });
    return comments;
  }

  viewCommentsCount = () => {
    const { post } = this.props;
    if (!post.comments_count) { return null; }
    return (
      <div className="">
        <i className="fa fa-comments-o mr1 text-light-gray" />
        {post.comments_count}
      </div>
    );
  }

  viewLikes = () => {
    const { post } = this.props;
    if (!post.likes_count) { return null; }
    return (
      <div className={post.comments_count ? 'ml2' : ''}>
        <i className="fa fa-hand-paper-o mr1 text-light-gray" />
        {post.likes_count}
      </div>
    );
  }

  render() {
    const { post } = this.props;
    const avatarStyle = {
      backgroundImage: `url(${post.user_author.avatar})`,
    };
    return (
      <div className="flex mxn2 pb1">
        <div className="col-2 lg-hide mt2 px2 relative" />

        <div className="col-8 lg-col-12 px2">
          <div className="bg-white border border-alto bs-bottom-small-lighter mb3 relative">

            {/*
              Update Preview content starts here
            */}
            <div className="flex flex-center lg-block lg-pb3 pl3 pr3 pt3 relative">
              <a className="absolute bottom-0 left-0 right-0 top-0 z2" href={post.shareholder_update_url} />
              <span className="h2 lh-34 text-cod-gray z1 bold">{ post.body }</span>
              <div className="h5 ml-auto flex flex-row">
                {this.viewCommentsCount()} {this.viewLikes()}
              </div>
            </div>

            {/*
              Update author & published info
            */}
            <div className="flex flex-center lg-block pl3 pr3 pb1">
              <div className="bg-center bg-cover bg-no-repeat border border-white box-36 circle z1" style={avatarStyle} />
              <span className="h5 ml1 pr1 text-gray z1">
                by
                <span className="fw400"> {post.user_author.name } </span>
              </span>
              <span className="h5 text-medium-gray z1">
                {post.month} {post.day}, {post.year}
              </span>

            </div>

            {/*
              Update content
            */}
            <div className="pl3 pb1 pr3">

              <p className="break-word h5 mt1 text-gray">
                {post.update_text_truncated }
                <a className="ml1 h5" href={post.update_show_page_opening_comments}>Read more</a>
              </p>

            </div>

            {/*
              Comments start here
            */}
            <div className="divider__comments--gray ml3 mr3" />

            <div className="flex flex-center lg-block pl3 pb2 pt2">
              <span className="h5 text-black z1 fw400">Most Recent Comments</span>
            </div>
            {this.viewComments()}
            {this.commentsLink()}
          </div>
        </div>

        <div className="col-2 lg-hide mt2 px2" />
      </div>
    );
  }
}

export default ShareholderUpdatePost;
