const buildChartQuery = (url, options) => {
  let query = url;

  if (options.filterType) {
    query = `${query}&filter_type=${options.filterType}`;
  }

  if (options.kpiMetric) {
    query = `${query}&kpi_metric=${options.kpiMetric}`;
  }

  if (options.timeFrame !== null && options.timeFrame !== undefined) {
    query = `${query}&time_frame=${options.timeFrame}`;
  }

  if (options.frequency !== null && options.frequency !== undefined) {
    query = `${query}&frequency=${options.frequency}`;
  }
  return query;
};

module.exports = {
  buildChartQuery,
};
