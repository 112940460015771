import React from 'react';
import PropTypes from 'prop-types';

import SorterHeader from '../SorterHeader';


const CompanyInvestmentHeader = ({ data }) => (
  <div className="col-12 text-gray h5 flex px2 bg-extra-light-gray flex-center">
    <div className="col-3 py1 fw400 pl2 sm-pl0">
      <SorterHeader text="Name" field="name" />
    </div>
    {data.is_investor ?
      null
      :
      <div className="col-2 py1 fw400 pl2 sm-hide">
        <SorterHeader text="# of Members" field="number_of_members" />
      </div>
    }
    {data.is_investor ?
      <div className="col-6 flex flex-row" />
      :
      <div className="col-7 flex flex-row">
        <div
          className="py1 fw400 center sm-hide"
          style={{ width: '12%' }}
          data-balloon="Access to the dashboard means that the dashboard page can be viewed by investors. However, only explicilty shared data is visible. For example with dashboard access, investors will only be able to access updates sent to them or the captable will only be visible to investors, that captable access was granted as well."
          data-balloon-pos="up"
          data-balloon-length="xlarge"
        >
          Dashboard access
        </div>
        <div
          className="py1 fw400 center sm-hide"
          style={{ width: '12%' }}
          data-balloon="Update access means that the updates page is accessible, where all previously published updates are listed. This will not allow access to draft updates and investors will only be able to see/access updates sent to them."
          data-balloon-pos="up"
          data-balloon-length="xlarge"
        >
          Update access
        </div>
        <div
          className="py1 fw400 center sm-hide"
          style={{ width: '12%' }}
          data-balloon="Discussion access means that the discussion page is accessible, where all previously published updates with comments are listed. This will not allow access to updates and investors will only be able to see/access updates sent to them."
          data-balloon-pos="up"
          data-balloon-length="xlarge"
        >
          Discussions access
        </div>
        <div
          className="py1 fw400 center sm-hide"
          style={{ width: '12%' }}
          data-balloon="KPIs access means that the KPI page is accessible, where all KPIs are listed. This will not allow access to KPI edit page. KPIs on the dashboard will still be visible if dashboard access is enabled."
          data-balloon-pos="up"
          data-balloon-length="xlarge"
        >
          KPIs<br />access
        </div>
        <div
          className="py1 fw400 center sm-hide"
          style={{ width: '12%' }}
          data-balloon="Data room access means that the Data Room page is accessible, where all Documents inside are listed. This will not allow access to data room stats or access to the documents from the dashboard."
          data-balloon-pos="up"
          data-balloon-length="xlarge"
        >
          Data room access
        </div>
        <div
          className="py1 fw400 center sm-hide"
          style={{ width: '12%' }}
          data-balloon=""
          data-balloon-pos="up"
          data-balloon-length="xlarge"
        >
          Deal room access
        </div>
        <div
          className="py1 fw400 center sm-hide"
          style={{ width: '12%' }}
          data-balloon="Captable access means that the Captable page is accessible, where all funding rounds are listed. This will also allow showing of the latest captable round on the dashboard. If access is denied, the last round on the dashboard is also not visible anymore."
          data-balloon-pos="up"
          data-balloon-length="xlarge"
        >
          Captable access
        </div>
        <div
          className="py1 fw400 center sm-hide"
          style={{ width: '12%' }}
          data-balloon="Doc signing access means that the document signing page is accessible, where all signing requests are listed. This will not allow accessing of draft/ongoing or creating of signing requests, only viewing historic and accessing ongoing/completed requests."
          data-balloon-pos="up"
          data-balloon-length="xlarge"
        >
          Doc Signing access
        </div>
        <div
          className="py1 fw400 center sm-hide"
          style={{ width: '12%' }}
          data-balloon="Connections access means that this investors page is accessible, where all connections are listed. This will not allow changing of access rights or accessing the team page."
          data-balloon-pos="up"
          data-balloon-length="xlarge"
        >
          Connections access
        </div>

        <div className="flex mx2 p1" />
      </div>
    }
  </div>
);

CompanyInvestmentHeader.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CompanyInvestmentHeader;
