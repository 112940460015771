import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
  Markers,
  Marker,
} from 'react-simple-maps';
import LoadingSpinner from '../../LoadingSpinner';
import Modal from '../../Modal';
import Avatar from '../../tab_view/Avatar';


class Map extends Component {
  static propTypes = {
    mapData: PropTypes.array,
    loading: PropTypes.bool,
    investorId: PropTypes.number,
    blurred: PropTypes.bool,
  }

  static defaultProps = {
    mapData: [],
    loading: true,
    investorId: null,
    blurred: null,
  }

  state = {
    zoom: 1,
    coordinates: [],
    hideModal: true,
    companiesInCountry: [],
    country: '',
  }

  componentWillReceiveProps = (nextProps) => {
    this.getCoordinates(nextProps, true);
  }

  updateCoordinatesState = (context, mapDataNextProps) => {
    if (mapDataNextProps.length > 0) {
      const filteredMapData = JSON.parse(JSON.stringify(mapDataNextProps));

      for (let index = 0; index < filteredMapData.length; index += 1) {
        const company = filteredMapData[index];
        if (company.coordinates === null && company.country !== null) {
          let newCoordinates = '';
          this.fetchCordinates(company.country).then(resp => {
            newCoordinates = { coords: `${resp.geometry.location.lng},${resp.geometry.location.lat}`, id: company.id };
            context.setState((prevState) => ({ coordinates: [...prevState.coordinates, newCoordinates] }));
          });
        } else if (!(company.coordinates === null && company.country !== null) && company.coordinates !== null) {
          this.setState((prevState) => ({ coordinates: [...prevState.coordinates, { coords: company.coordinates, id: company.id }] }));
        }
      }
    }
  }

  getCoordinates = (nextProps, propReceived) => {
    const { mapData: mapDataNextProps } = nextProps;
    const { mapData: mapDataCurrentProps } = nextProps;

    if (mapDataNextProps !== mapDataCurrentProps || !propReceived) {
      if (propReceived) {
        this.setState({ coordinates: [] }, () => {
          const that = this;
          this.updateCoordinatesState(that, mapDataNextProps);
        });
      } else {
        const that = this;
        this.updateCoordinatesState(that, mapDataNextProps);
      }
    }
  }


  handleZoomIn = () => {
    const { zoom } = this.state;
    this.setState({
      zoom: zoom * 2,
    });
  }

  handleZoomOut = () => {
    const { zoom } = this.state;
    this.setState({
      zoom: zoom / 2,
    });
  }

  handleCountryClick = (country) => {
    const { mapData } = this.props;
    const companiesInCountry = mapData.filter(company => company.country === country);
    if (companiesInCountry.length > 0) {
      this.setState({
        companiesInCountry,
        hideModal: false,
        country,
      });
    }
  }

  filteredMapData = (mapDataFiltered) => {
    if (mapDataFiltered[0] === undefined) return [];
    if (mapDataFiltered === [] || !('coords' in mapDataFiltered[0])) return [];
    const { zoom } = this.state;
    const mapDataCompare = mapDataFiltered.slice();
    const filteredMapData = [];
    const stack = [];
    if (mapDataFiltered.length > 0) {
      mapDataFiltered.forEach((company, index) => {
        if (company.coords !== null) {
          filteredMapData.push(company);
          const coordinates = company.coords.split(',').map(Number);
          const compareArrayLength = mapDataCompare.length;
          const filteredStack = [];
          for (let i = 0; i < compareArrayLength; i += 1) {
            const c = mapDataCompare[i];
            const flag = stack.find(comp => comp === c);
            if (c.coords !== null && i > index && !flag) {
              const cor = c.coords.split(',').map(Number);
              let factor = 0;
              if (Math.abs(cor[0] - coordinates[0]) < (4) && Math.abs(cor[1] - coordinates[1]) <= (4)) {
                filteredStack.push({
                  i,
                  company: mapDataCompare[i],
                });
                factor = filteredStack.length;
                let offset = 3 / zoom;
                if (offset < 1) offset = 1;
                if ((cor[0] > coordinates[0]) && (cor[1] > coordinates[1])) {
                  filteredMapData[filteredMapData.length - 1].coords = `${coordinates[0] - offset * (factor > 4 ? Math.sqrt(factor * 2) : Math.sqrt(factor))},${coordinates[1] - offset * (factor > 4 ? Math.sqrt(factor * 2) : Math.sqrt(factor))}`;
                } else if ((cor[0] < coordinates[0]) && (cor[1] < coordinates[1])) {
                  filteredMapData[filteredMapData.length - 1].coords = `${coordinates[0] + offset * (factor > 4 ? Math.sqrt(factor * 2) : Math.sqrt(factor))},${coordinates[1] + offset * (factor > 4 ? Math.sqrt(factor * 2) : Math.sqrt(factor))}`;
                } else if ((cor[0] < coordinates[0]) && (cor[1] > coordinates[1])) {
                  filteredMapData[filteredMapData.length - 1].coords = `${coordinates[0] + offset * (factor > 4 ? Math.sqrt(factor * 2) : Math.sqrt(factor))},${coordinates[1] - offset * (factor > 4 ? Math.sqrt(factor * 2) : Math.sqrt(factor))}`;
                } else if ((cor[0] > coordinates[0]) && (cor[1] < coordinates[1])) {
                  filteredMapData[filteredMapData.length - 1].coords = `${coordinates[0] - offset * (factor > 4 ? Math.sqrt(factor * 2) : Math.sqrt(factor))},${coordinates[1] + offset * (factor > 4 ? Math.sqrt(factor * 2) : Math.sqrt(factor))}`;
                } else {
                  filteredMapData[filteredMapData.length - 1].coords = `${coordinates[0] + (offset * (factor > 4 ? Math.sqrt(factor * 2) : Math.sqrt(factor)))},${coordinates[1] + (offset * (factor > 4 ? Math.sqrt(factor * 2) : Math.sqrt(factor)))}`;
                }
              }
            }
          }
          stack.push(company);
        }
      });
    }

    let data = filteredMapData.slice();
    data = Array.from(new Set(mapDataFiltered));

    return data;
  }

  checkUrl = (url) => {
    if (!url.includes('http')) return `https://${url}`;
    return url;
  }

  fetchCordinates = (address) => axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBNTPc4dPxfsrt5_Zx5vJ-kaIdHJKMz7hY`)
    .then(resp => resp.data.results[0])

  render() {
    const { loading, investorId, mapData, blurred } = this.props;
    const { coordinates, zoom, hideModal, companiesInCountry, country } = this.state;

    if (coordinates.length === 0) this.getCoordinates(this.props, false);
    const uniqueCoordinates = JSON.parse(JSON.stringify(coordinates)).filter((s1, pos, arr) => arr.findIndex((s2) => s2.id === s1.id) === pos);
    const filteredCoordinates = this.filteredMapData(uniqueCoordinates);

    return (
      loading ?
        <LoadingSpinner
          show={loading}
          type="fit"
          background="white"
          height="200px"
        />
        :
        <div className={`m0 ${blurred ? 'hide-data' : ''}`}>
          <div onClick={() => this.handleZoomIn()} className="absolute bg-white bs-around cursor-pointer" style={{ top: '20%', right: '2%' }}>
            <i className="fa fa-fw fa-plus" />
          </div>
          <div onClick={() => this.handleZoomOut()} className="absolute bg-white bs-around" style={{ top: '28%', right: '2%' }}>
            <i className="fa fa-fw fa-minus" />
          </div>
          <ComposableMap
            projectionConfig={{
              scale: 205,
              rotation: [-11, 0, 0],
            }}
            height={320}
            style={{ width: '100%', height: 'auto' }}
          >
            <ZoomableGroup center={[0, 20]} zoom={zoom}>
              <Geographies geography="/static/world-50m.json" disableOptimization>
                {(geographies, projection) =>
                  geographies.map((geography, i) => (
                    <Geography
                      key={geography.properties.NAME_LONG}
                      geography={geography}
                      projection={projection}
                      onClick={() => { this.handleCountryClick(geography.properties.NAME_LONG); }}
                      cacheId={`geography-${i}`}
                      style={{
                        default: {
                          fill: mapData.find(c => c.country === geography.properties.NAME_LONG) ? '#aaaaaa' : '#cccccc',
                          stroke: '#ffffff',
                          strokeWidth: Math.round((1 / zoom) * 100) / 100,
                          outline: 'none',
                        },
                        hover: {
                          fill: '#cccc',
                          stroke: '#ffffff',
                          strokeWidth: Math.round((1 / zoom) * 100) / 100,
                          outline: 'none',
                        },
                        pressed: {
                          fill: '#cccc',
                          stroke: '#ffffff',
                          strokeWidth: Math.round((1 / zoom) * 100) / 100,
                          outline: 'none',
                        },
                      }}
                    />
                  ))
                }
              </Geographies>
              <Markers>
                {filteredCoordinates.map(company => {
                  if (company && company.coords && mapData.find(c => c.id === company.id)) {
                    const reverseCoords = company.coords.split(',').map(Number);
                    return (
                      <Marker
                        key={company.id}
                        marker={{ coordinates: reverseCoords }}
                        onClick={() => window.open(`/investor/${investorId}/investments/${company.id}`)}
                      >
                        <image
                          className="cursor-pointer"
                          width={`${18 + (zoom * 2)}px`}
                          xlinkHref={mapData.find(c => c.id === company.id).avatar}
                          data-tip={mapData.find(c => c.id === company.id).name}
                        />
                      </Marker>
                    );
                  }
                  return null;
                })}
              </Markers>
            </ZoomableGroup>
          </ComposableMap>
          <ReactTooltip />
          <Modal
            className="bg-white flex flex-column mb2 p2 border-top border-blue bw-4"
            show={!hideModal}
            onClickOutside={() => { this.setState({ hideModal: true, companiesInCountry: [], country: '' }); }}
          >
            <div className="flex flex-justify-between px2 border-bottom border-lighter-gray py2 semi-bold mb1">
              Companies in {country}
            </div>
            <div className={`overflow-y-auto ${companiesInCountry.length > 0 ? '' : 'center'}`} style={{ maxHeight: '500px' }}>
              {companiesInCountry.map(company => (
                <div className="flex flex-row text-gray mb1 mx2">
                  <Avatar avatarUrl={company.avatar} size={28} classNames="bg-white" shadow />
                  <a href={`/investor/${investorId}/investments/${company.id}`} className="fw400 h5 pt05 ml1 text-gray cursor-pointer">{company.name}</a>
                  <a href={this.checkUrl(company.company_url)} target="#" className="h5 cursor-pointer ml-auto mr1 pt05">{company.company_url}</a>
                </div>
              ))}
            </div>
            <div className="border-top border-lighter-gray m1 pt1 flex flex-justify-end">
              <div className="btn fw400 bg-blue rounded text-white cursor-pointer py1 px2 sm-col-12 mr1" onClick={() => { this.setState({ hideModal: true, companiesInCountry: [], country: '' }); }}>
                Close
              </div>
            </div>

          </Modal>
        </div>
    );
  }
}

export default Map;
