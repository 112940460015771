import React, { Component } from 'react';
import PropTypes from 'prop-types';


class ChartSelect extends Component {
  chartTypes = [
    { name: 'line', icon: '/images/kpi-icons/chart-line-white.svg', display: 'Line' },
    { name: 'spline', icon: '/images/kpi-icons/chart-spline-white.svg', display: 'Spline' },
    { name: 'area-spline', icon: '/images/kpi-icons/chart-area-white.svg', display: 'Area' },
    { name: 'bar', icon: '/images/kpi-icons/chart-column-white.svg', display: 'Bar' },
    { name: 'bar_horizontal', icon: '/images/kpi-icons/chart-bar-white.svg', display: 'Horizontal Bar' },
    { name: 'number', icon: '/images/kpi-icons/chart-number-white.svg', display: 'Last data only' },
  ]

  static propTypes = {
    selected: PropTypes.number.isRequired,
    // url: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      selected: props.selected || 0,
    };
  }

  decSelected = () => {
    const { selected } = this.state;
    if (selected === 0) {
      return (
        this.setState({ selected: 5 })
      );
    }
    return (
      this.setState({ selected: selected - 1 })
    );
  }

  incSelected = () => {
    const { selected } = this.state;
    if (selected === 5) {
      return (
        this.setState({ selected: 0 })
      );
    }
    return (
      this.setState({ selected: selected + 1 })
    );
  }

  // UNSAFE_componentWillUpdate(nextProps, nextState) {
  //   const { url } = this.props;
  //   App.Api.chart(url, this.chartTypes[nextState.selected].name);
  // }

  render() {
    const { selected } = this.state;
    return (
      <div className="lh-1">
        <div className="flex items-center flex-justify-between mb2">
          <div className="flex flex-column flex-start lh-12">
            <p className="text-light-gray fs-14 m0">Default Chart</p>
            <p className="fw400 fs-16 m0 text-gray">{this.chartTypes[selected].display}</p>
          </div>
          <div className="flex items-center flex-justify-center">
            <div onClick={this.decSelected}>
              <span className="h1">
                <i className="fa fa-caret-left text-light-gray cursor-pointer" />
              </span>
            </div>
            <div className="bg-blue box-40 circle flex items-center flex-justify-center mx1 p1">
              <img
                src={this.chartTypes[selected].icon}
                alt="Type"
              />
            </div>

            <div onClick={this.incSelected}>
              <span className="h1">
                <i className="fa fa-caret-right text-light-gray cursor-pointer" />
              </span>
            </div>
          </div>
        </div>
        <input type="hidden" name="kpi[chart_type]" value={this.chartTypes[selected].name} />
      </div>
    );
  }
}

export default ChartSelect;
