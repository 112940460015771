import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextInput from './TextInput';
import { defaultSelectStyles, defaultSelectTheme } from './selects/SelectStyles';


// New change: Added 'separator' as a disabled option. So, if the option includes a separator, all options after the separator will have different color
class Select extends Component {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    otherName: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    other: PropTypes.bool,
    otherPlaceholder: PropTypes.string,
    otherClassName: PropTypes.string,
    otherValue: PropTypes.string,
  }

  static defaultProps = {
    className: 'normal-select normal-select__onboard',
    value: '',
    placeholder: '',
    onChange: null,
    required: false,
    disabled: false,
    other: false,
    otherClassName: 'fw300 pr4 mb1 border border-lighter-gray hover hover-border-blue col-12 block placeholder-gray h5 input-height__2',
    otherPlaceholder: '',
    otherName: '',
    otherValue: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      otherSelected: props.value === 'other',
    };
  }

  componentWillReceiveProps(props) {
    this.setState(props);
  }

  setSelected = e => {
    const { onChange, other } = this.props;
    if (onChange) onChange(e);

    if (e.target.value === 'other' && other) {
      this.setState({
        otherSelected: true,
      })
    } else {
      this.setState({
        otherSelected: false,
      })
    }

    this.setState({
      value: e.target.value,
    });
  }

  isPlaceHolderSelected = () => {
    const { value } = this.state;

    if (!value) return true;

    return value === '';
  }

  options = () => {
    const { options } = this.props;
    let hasSeparator = false;

    return options.map(option => {
      if (option[0] === 'separator') {
        if (!hasSeparator) hasSeparator = true;
        return <option key={option[1]} value="" disabled>{option[1]}</option>;
      }
      return <option className={`${hasSeparator ? 'text-light-gray' : 'text-gray'}`} key={option[0]} value={option[0]}>{option[1]}</option>;
    });
  }

  placeholder = () => {
    const { placeholder, value } = this.props;

    if (!placeholder) return null;

    return (
      <option value="" disabled selected={!value}>{placeholder}</option>
    );
  }

  render() {
    const { required, name, otherName, className, disabled, otherPlaceholder, otherClassName, otherValue } = this.props;
    const { value, otherSelected } = this.state;

    let selectStyle;
    if (this.isPlaceHolderSelected()) {
      selectStyle = {
        color: '#d7d7d7',
      };
    }

    return (
      <div>
        <select
          style={selectStyle}
          className={className}
          name={name}
          value={value}
          onBlur={this.setSelected}
          onChange={this.setSelected}
          required={required}
          disabled={disabled}
          styles={defaultSelectStyles}
          theme={defaultSelectTheme}
        >
          {this.placeholder()}
          {this.options()}
        </select>
        {otherSelected &&
          <TextInput
            value={otherValue}
            placeholder={otherPlaceholder}
            className={otherClassName}
            name={otherName}
          />
        }
      </div>
    );
  }
}

export default Select;
