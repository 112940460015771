import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CleaveNumberFormatting from '../CleaveNumberFormatting';


class ManualMainFields extends Component {
  static propTypes = {
    setData: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    edit: PropTypes.bool.isRequired,
    currency: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      currentRaiseSeed: props.data.raised_seed,
      currentPostValuation: props.data.post_valuation,
      currentPreValuation: props.data.pre_valuation,
      currentSharePrice: props.data.pps,
    };
  }

  setDataDebounce = (e, id, value) => {
    const { setData } = this.props;
    setData(e, id, value);
  }

  setDataFor = (type, e) => {
    this.setState({ [type]: e.target.rawValue });
    this.setDataDebounce(e, e.target.id, e.target.rawValue || '0');
  }

  componentWillMount() {
    this.setDataDebounce = _.debounce(this.setDataDebounce, 1000);
  }

  dispalyPPS = () => {
    const { edit, data, currency } = this.props;
    const { currentSharePrice } = this.state;
    if (edit) {
      return (
        <CleaveNumberFormatting
          value={currentSharePrice}
          onChange={e => this.setDataFor('currentSharePrice', e)}
          className="appearance-textfield block border border-alto col-8 mx-auto"
          name="cap_table[share_price]"
          id="share_price"
        />
      );
    }
    return (
      <span className="block bold h3 mb2 text-blue">{data.pps_shown} {currency}</span>
    );
  }

  dispalyPostValuation = () => {
    const { edit, data, currency } = this.props;
    const { currentPostValuation } = this.state;
    if (edit) {
      return (
        <CleaveNumberFormatting
          value={currentPostValuation}
          onChange={e => this.setDataFor('currentPostValuation', e)}
          className="appearance-textfield block border border-alto col-8 mx-auto"
          name="cap_table[post_valuation]"
          id="post_valuation"
        />
      );
    }
    return (
      <span className="block bold h3 mb2 text-blue">{data.post_valuation_shown} {currency}</span>
    );
  }

  dispalyPreValuation = () => {
    const { edit, data, currency } = this.props;
    const { currentPreValuation } = this.state;
    if (edit) {
      return (
        <CleaveNumberFormatting
          value={currentPreValuation}
          onChange={e => this.setDataFor('currentPreValuation', e)}
          className="appearance-textfield block border border-alto col-8 mx-auto"
          name="cap_table[pre_valuation]"
          id="pre_valuation"
        />
      );
    }
    return (
      <span className="block bold h3 mb2 text-blue">{data.pre_valuation_shown} {currency}</span>
    );
  }

  dispalySeed = () => {
    const { edit, data, currency } = this.props;
    const { currentRaiseSeed } = this.state;
    if (edit) {
      return (
        <CleaveNumberFormatting
          value={currentRaiseSeed}
          onChange={e => this.setDataFor('currentRaiseSeed', e)}
          className="appearance-textfield block border border-alto col-8 mx-auto"
          name="cap_table[raised_seed]"
          id="raised_seed"
        />
      );
    }
    return (
      <span className="block bold h3 mb2 text-blue">{data.raised_seed_shown} {currency}</span>
    );
  }

  render() {
    const { edit, data } = this.props;

    if (data.hidden && !edit) {
      return null;
    }

    return (
      <div className="bg-white bs-around flex mb3 py2">
        <p className="border-right border-alto center col-3 m0 px2">
          {this.dispalySeed()}
          <span className="bold">Raised</span>
        </p>

        <p className="border-right border-alto center col-3 m0 px2">
          {this.dispalyPreValuation()}
          <span className="bold">Pre-Money</span>
        </p>

        <p className="border-right border-alto center col-3 m0 px2">
          {this.dispalyPostValuation()}
          <span className="bold">Post-Money</span>
        </p>

        <p className="center col-3 m0 px2">
          {this.dispalyPPS()}
          <span className="bold">Price / Share</span>
        </p>
      </div>
    );
  }
}

export default ManualMainFields;
