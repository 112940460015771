import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../Modal';
import SelectWithImage from '../../../selects/SelectWithImage';


class ConnectInvestmentModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    companyOptions: PropTypes.array.isRequired,
    investorId: PropTypes.number.isRequired,
    investmentId: PropTypes.number.isRequired,
  }

  state = {
    selected: null,
  }

  showInviteCompanyModal = () => {
    const { closeModal } = this.props;
    closeModal();
    $('[data-behavior="modal"][data-target="#modal-invite-simple"]').click();
  }

  submit = () => {
    const { investorId, investmentId } = this.props;
    const { selected } = this.state;

    App.Api.post(`/investor/${investorId}/investments/${investmentId}/connect`, { company_investment: selected });
  }

  render() {
    const { show, closeModal, companyOptions } = this.props;
    const { selected } = this.state;

    return (
      <Modal
        show={show}
        onClickOutside={closeModal}
      >
        <div className="flex flex-justify-between items-center p2 border-bottom border-lighter-gray">
          <div className="h3 text-gray fw400">Connect Company</div>
          <i className="fa fa-close h5 cursor-pointer" onClick={closeModal} />
        </div>

        <div className="flex flex-column items-center p2 mt2">
          <SelectWithImage
            className="col-8"
            value={companyOptions.filter(option => option.value === selected)}
            options={companyOptions}
            placeholder="Select company to merge..."
            onChange={selsectedOption => this.setState({ selected: selsectedOption.value })}
            isClearable={false}
          />

          <div className="flex flex-justify-end pt2">
            <span
              className="bg-green text-white px2 py05 rounded h5 fw400 cursor-pointer"
              onClick={this.showInviteCompanyModal}
            >
              Invite new company
            </span>
          </div>
        </div>

        <div className="flex flex-justify-between items-center p2 border-top border-lighter-gray">
          <div className="flex flex-start items-center h6 text-light-gray fw400 lh-125">
            <i className="fa fa-info-circle mr1" />
            Note: After connecting to a company,
            <br />your custom data will overwrite company data!
          </div>
          <div className="flex flex-justify-end items-center">
            <div
              className="h5 text-blue mr2 cursor-pointer"
              onClick={closeModal}
            >
              Cancel
            </div>
            <div
              className="h5 p1 px2 cursor-pointer bg-blue text-white rounded"
              onClick={this.submit}
            >
              Save
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ConnectInvestmentModal;
