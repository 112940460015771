import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../TextInput';

import Chart from './Chart';
import ManualMainFields from './ManualMainFields';
import ManualSharesTable from './ManualSharesTable';


class ManualCapTable extends Component {
  static propTypes = {
    capTable: PropTypes.object.isRequired,
    switchUrl: PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      capTable: props.capTable,
      saveStatus: 'Saved',
    };
  }

  componentWillMount() {
    this.updateAjax = _.debounce(this.updateAjax, 500);
  }

  // Null params added because of new input fields that needed when saving without event;
  // event was being sent with null because of debounce
  setData = (e, id = null, value = null) => {
    const { capTable } = this.state;
    const innerId = id || e.target.id;
    const innerValue = (value === null) ? e.target.value : value;
    this.updateCapTableState(innerId, innerValue);
    this.setState({ saveStatus: 'Saving...' });
    this.updateAjax(capTable.update_url, innerId, innerValue);
  }

  displayChart = () => {
    const { settings } = this.props;
    const { capTable } = this.state;
    if (!settings.edit && capTable.shares.length > 0) {
      return (
        <Chart data={capTable.chart_data} />
      );
    }
    return (
      null
    );
  }

  displaySaveStatus = () => {
    const { settings } = this.props;
    const { saveStatus } = this.state;
    if (settings.edit) {
      return (
        <span className="bold h5 text-blue" href="#">
          {saveStatus}
          <i className="fa fa-cloud-upload ml1" />
          <span className="mx2 text-alto">|</span>
        </span>
      );
    }
    return (
      null
    );
  }

  displaySwitch = () => {
    const { switchUrl, settings } = this.props;
    const { capTable } = this.state;
    if (capTable.editable) {
      return (
        <span className="flex flex-center">
          <a className="flex flex-center" href={switchUrl}>
            <span className="mr1 regular text-gray">{settings.edit ? 'Edit mode' : 'View mode'}</span>
            <span className="h3">
              <i className={settings.edit ? 'fa fa-toggle-on' : 'fa fa-toggle-off'} />
            </span>
          </a>
        </span>
      );
    }
    return null;
  }

  displayTitle = () => {
    const { settings } = this.props;
    const { capTable } = this.state;
    if (settings.edit) {
      return (
        <div>
          <i className="fa fa-pencil mr1" />
          <TextInput
            className="bg-none bold border-left-none border-right-none border-top-none border-bottom border-alto h3"
            onChange={this.setData}
            placeholder="Cap table name"
            value={capTable.name}
            name="cap_table[name]"
            id="name"
          />
        </div>
      );
    }
    return (
      <p className="bold h2 m0 mb1 mt1 text-gray">{capTable.name}</p>
    );
  }

  updateAjax = (url, key, value) => {
    App.Api.patch(url, key, value).then(
      data => {
        this.setState({
          capTable: data,
          saveStatus: 'Saved',
        });
      },
    );
  }

  updateCapTableState = (key, value) => {
    const { capTable: oldCapTable } = this.state;
    const capTable = Object.assign({}, oldCapTable, { [key]: value });

    this.setState({ capTable });
  }

  updateItem = (url, key, value) => {
    this.setState({ saveStatus: 'Saving...' });
    this.updateAjax(url, key, value);
  }

  updateTopState = (data) => {
    this.setState({ capTable: data });
  }

  render() {
    const { settings } = this.props;
    const { capTable } = this.state;

    return (
      <div>
        <div className="flex flex-center mb1 bg-white bs-around mb3 p2">
          {this.displayTitle()}
          <div className="flex flex-auto flex-justify-end">
            {this.displaySaveStatus()}
            {this.displaySwitch()}
          </div>
        </div>

        <ManualMainFields
          setData={this.setData}
          data={capTable.main_data}
          edit={settings.edit}
          currency={capTable.currency}
        />

        {this.displayChart()}

        <ManualSharesTable
          shares={capTable.shares}
          createUrl={capTable.share_create_url}
          updateTopState={this.updateTopState}
          updateItem={this.updateItem}
          shareTotals={capTable.share_totals}
          edit={settings.edit}
        />
      </div>
    );
  }
}

export default ManualCapTable;
