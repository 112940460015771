import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import CleaveNumberFormatting from '../CleaveNumberFormatting';
import DatePicker from '../selects/DatePicker';
import LoadingSpinner from '../LoadingSpinner';


class KpisQuickEdit extends Component {
  static propTypes = {
    kpis: PropTypes.array.isRequired,
    createOrUpdateKpiValue: PropTypes.func.isRequired,
    currentDate: PropTypes.string.isRequired,
    changeCurrentDate: PropTypes.func.isRequired,
  }

  state = {
    updating: false,
  }

  handleKpiValueChange = async (value, kpi) => {
    const { currentDate } = this.props;

    const parsedCurrentDate = moment(currentDate).format('YYYY-MM-DD');

    if (value === null || value === undefined) return;

    const { createOrUpdateKpiValue } = this.props;
    const params = {
      date: parsedCurrentDate,
      actual: value,
    };

    const metadata = {
      kpi: kpi.metric,
    };

    try {
      await createOrUpdateKpiValue(kpi, params);
      if (typeof Intercom !== 'undefined') {
        Intercom('trackEvent', 'quick_edit_kpi_updated', metadata);
      }
    } catch (err) {
      const error = err && err.response ? (err.response.data.error || err.response.data.errors) : 'Sorry, an error prevented the KPI value from being saved.';
      App.State.setFlash({ name: 'alert', msg: error });
    }
  }

  handleDateChange = (date) => {
    const { changeCurrentDate } = this.props;

    this.setState({ updating: true }, async () => {
      await changeCurrentDate(date);
      this.setState({ updating: false });
    });
  }

  render() {
    const { kpis, currentDate } = this.props;
    const { updating } = this.state;

    const datesAvailable = (frequency) => (
      frequency === 'quarterly' ? 'Jan/Apr/Jul/Oct' : 'Jan'
    );

    const inputClasses = 'col-12 border border-lighter-gray';

    return (
      <div className="bg-white text-gray pb2">
        <div className="flex flex-center border-gallery bg-extra-light-gray h3 fw400 px3 py1">
          Monthly KPIs

          <DatePicker
            value={currentDate}
            className="col-1 ml1 border border-gallery text-orange"
            onChange={(_e, value) => this.handleDateChange(value)}
            showMonthYear
            blockDeletion
          />
        </div>
        <div className="text-light-gray px3 py1">
          Here you can quickly update all your monthly KPIs actual values for a given date
        </div>

        {updating ?
          <LoadingSpinner
            show
            type="fit"
            height="300px"
            background="white"
          />
          :
          <div className="flex flex-wrap px3 py1">
            {kpis.map((kpi) => (
              <div key={kpi.id} className="flex flex-column flex-justify-end py1 pr2 col-4">
                <div className={`flex ${(kpi.unit === 'currency' && !kpi['blocked_value?']) ? 'col-11' : 'col-12'}`}>
                  <a
                    className="text-gray link-hover--blue flex-auto"
                    href={`/company/${kpi.company.slug}/kpis/${kpi.id}/edit`}
                  >
                    <span className="h4 flex">{kpi.metric} <span className="h6"><i className="pl05 fa fa-pencil" /></span></span>
                  </a>
                  {kpi.description &&
                    <a
                      className="text-light-gray"
                      href={kpi.info_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-balloon={kpi.description}
                      data-balloon-pos="down"
                      data-balloon-length="large"
                    >
                      <i className="fa fa-info-circle" />
                    </a>
                  }
                  {kpi['blocked_value?'] &&
                    <div
                      className="ml1"
                      data-balloon={`Value shows N/A because this is a ${kpi.frequency} KPI. ${kpi['derived_kpi?'] ? `Value is available when date is ${datesAvailable(kpi.frequency)}. However derived KPIs are blocked for editing.` : `Available for editing only when date is ${datesAvailable(kpi.frequency)}.`}`}
                      data-balloon-pos="down"
                    >
                      <i className="fa fa-exclamation-circle text-red" />
                    </div>
                  }
                </div>
                <div
                  className="flex flex-center col-12"
                  data-balloon={kpi.active_source === 'sheets' ? 'You cannot edit this field because you have Google sheets connected.' : null}
                  data-balloon-pos="top"
                  data-balloon-length="large"
                >
                  {kpi.active_source !== 'sheets' && kpi['blocked_value?'] ?
                    <div
                      className={`${inputClasses} flex items-center bg-wild-sand hover hover-border-blue`}
                      style={{ height: '40px' }}
                    >
                      <span className="ml2">N/A {kpi.frequency ? `(${kpi.frequency} KPI)` : ''}</span>
                    </div>
                    :
                    <CleaveNumberFormatting
                      value={kpi.current_value.formatted_actual}
                      style={{ height: '40px' }}
                      className={`${inputClasses} ${kpi.active_source === 'sheets' ? 'opacity-40' : 'hover hover-border-blue'}`}
                      decimal={kpi.metric !== 'Runway'}
                      unit={kpi.unit === 'currency' && kpi.company.currency_symbol}
                      onBlur={e => this.handleKpiValueChange(e.target.rawValue, kpi)}
                      disabled={kpi.active_source === 'sheets'}
                    />
                  }
                </div>
                <div className="flex col-11 h5 fw400 text-light-gray">{kpi.additional_info}</div>
              </div>
            ))}
          </div>
        }

        {/*
        <div className="flex border-gallery bg-extra-light-gray h3 fw400 px3 py1">
          Custom KPIs
        </div>
        */}
      </div>
    );
  }
}

export default KpisQuickEdit;
