import React, { Component } from 'react';
import PropTypes from 'prop-types';


/*
  Added 2 new props.
  'saveImageUrl' as the name says, the url used to save the image when this
  component is not used inside a form, but within an autosave feature.
  'dataMethod' needs to be specified togeher with 'saveImageUrl' to know wether
  it will be a post or a patch.
*/
class FileField extends Component {
  static defaultProps = {
    text: null,
    saveImageUrl: null,
    dataMethod: null,
    onChange: null,
    isRelative: false,
    isButton: false,
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    text: PropTypes.string,
    saveImageUrl: PropTypes.string,
    dataMethod: PropTypes.string,
    onChange: PropTypes.func,
    isRelative: PropTypes.bool,
    isButton: PropTypes.bool,
  }

  previewImage = (event) => {
    const { onChange } = this.props;
    const files = event.target.files ? event.target.files : [];

    if (!files.length || !window.FileReader) return; // no file selected, or no FileReader support

    if (/^image/.test(files[0].type)) { // only image file
      this.saveImageFunction(files[0]).then(() => {
        // console.log('Image uploaded');
      }).catch(data => {
        const msg = data.responseJSON ? data.responseJSON.error : data.error;
        App.State.setFlash({ name: 'alert', msg });
      });

      const reader = new FileReader(); // instance of the FileReader
      reader.readAsDataURL(files[0]); // read the local file

      const self = this;

      reader.onloadend = () => { // set image data as background of div
        $(self.props.preview_target).css('background-image', `url(${reader.result})`).css('background-size', 'cover');
      };
    }

    if (onChange) onChange(files[0]);
  }

  saveImageFunction = (file) => {
    const { name, saveImageUrl, dataMethod } = this.props;
    return new Promise((resolve, reject) => {
      if (saveImageUrl) {
        if ((file.size / 1024 / 1024) > 10) {
          reject({ error: 'The file you are trying to upload is too large. Please upload files not larger than 10 MB!' });
          return;
        }
        const formData = new FormData();
        formData.append(name, file);
        if (dataMethod === 'post') {
          App.Api.post(saveImageUrl, formData).then(() => {
            resolve();
            App.State.setFlash({ name: 'notice', msg: 'Success! Image will appear after reload.' });
          }).catch(data => {
            App.State.setFlash({ name: 'notice', msg: 'Something went wrong, try again later!' });
            reject(data);
          });
        } else {
          App.Api.submitPatchForFile(saveImageUrl, formData).then(() => {
            resolve();
            if (!name.match(/report_column|investment_field/)) {
              App.State.setFlash({ name: 'notice', msg: 'Success! Image will appear after reload.' });
            }
          }).catch(data => {
            App.State.setFlash({ name: 'notice', msg: 'Something went wrong, try again later!' });
            reject(data);
          });
        }
      } else {
        resolve();
      }
    });
  }

  render() {
    const { name, text, isRelative, isButton } = this.props;
    const style = {};
    if (isRelative) style.position = 'relative';

    return (
      <div className="file-field">
        <div className={`btn ${!isButton ? 'btn-block file-field-btn' : 'btn-outline border rounded bg-white border-green text-green col-7 cursor-pointer'} `} style={style}>
          <div className={`${isButton ? 'px05 py05' : ''}`}>
            {text}
          </div>
          <input
            type="file"
            name={name}
            onChange={this.previewImage}
            className="cursor-pointer"
            accept=".png, .jpg, .jpeg, .gif"
            style={{ height: isButton ? '100%' : 'auto' }}
          />
        </div>
      </div>
    );
  }
}

export default FileField;
