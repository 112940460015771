import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {proPlans} from './data.js'
import StripePlan from './StripePlan.jsx';

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proPlans: proPlans,
      monthlyPayments: true
    };
  }

  static propTypes = {
    stripe: PropTypes.object.isRequired,
    subscribe: PropTypes.func.isRequired,
  }

  renderBasicFeatures = () => (
    <React.Fragment>
      <ul className="flex flex-wrap mx2 mt2 h5">
        <li className="col-12 mb1"><i className="fa fa-check text-green mr1" />Unlimited Updates and Users</li>
        <li className="col-12 mb1"><i className="fa fa-check text-green mr1" />Unlimited Investor Connections</li>
        <li className="col-12 mb1"><i className="fa fa-check text-green mr1" />Document Signing</li>
        <li className="col-12 mb1"><i className="fa fa-check text-green mr1" />CapTable Management</li>
        <li className="col-12 mb1"><i className="fa fa-check text-green mr1" />API (google sheets)</li>
        <li className="col-12 pb2 mb2 border-bottom border-gallery"><i className="fa fa-check text-green mr1" />Data Room (with 100 Mb storage)</li>
        <li className="col-12 mb1"><i className="fa fa-check text-green mr1" />Email and Chat Support</li>
      </ul>
    </React.Fragment>
  )

  render() {
    const { showPaymentModal, selectedPlan, monthlyPayments } = this.state;
    return (
      <div className="flex flex-column">
        <div className="flex flex-justify-between h4">
          <div className="h2 text-blue mb1">Plans</div>
          <Switch
            labelLeft="Monthly"
            labelRight="Yearly"
            onChange={() => this.setState({ monthlyPayments: !monthlyPayments })}
          />
        </div>
        {
          this.state.proPlans.length > 0 &&
          this.state.proPlans.map((plan, index) => (
            <StripePlan
              proPlans={plan}
              subscribe={this.props.subscribe}
              key={index}
              monthlyPayments={monthlyPayments}
            />
          ))
        }
        <div className="mt1 h6"><span className="fw400">Note: </span>Prices exclude VAT.</div>
      </div>
    );
  }
}

export default Pricing;
