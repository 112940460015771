import React from 'react';
import PropTypes from 'prop-types';
import { Modal as ReactstrapModal } from 'reactstrap';

import LoadingSpinner from './LoadingSpinner';


const Modal = ({
  show,
  children,
  widthClass,
  onClickOutside,
  loading,
}) => (
  <ReactstrapModal
    centered
    className={widthClass}
    isOpen={show}
    toggle={onClickOutside}
    fade={false}
  >
    <div className="border-top border-blue bw-4 bs-around-modal">
      {children}
    </div>
    <LoadingSpinner show={loading} />
  </ReactstrapModal>
);

Modal.defaultProps = {
  widthClass: 'react_modal',
  onClickOutside: null,
  loading: false,
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  widthClass: PropTypes.string,
  onClickOutside: PropTypes.func,
  loading: PropTypes.bool,
};

export default Modal;
