const subscribeInvestor = ({ customer, subscription }) => ({
  type: 'SUBSCRIBE_INVESTOR',
  customer,
  subscription,
});

const unsubscribeInvestor = subscription => ({
  type: 'UNSUBSCRIBE_INVESTOR',
  subscription,
});

const resubscribeInvestor = subscription => ({
  type: 'RESUBSCRIBE_INVESTOR',
  subscription,
});

export {
  subscribeInvestor,
  unsubscribeInvestor,
  resubscribeInvestor,
};
