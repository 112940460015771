import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Color from 'color';
import classNames from 'classnames';


class KpiChart extends Component {
  static defaultProps = {
    colorTheme: null,
    customInvestment: false,
  }

  static propTypes = {
    data: PropTypes.object.isRequired,
    colorTheme: PropTypes.string,
    customInvestment: PropTypes.bool,
    showSource: PropTypes.bool.isRequired,
  }

  showZeroOrNoValue = (actualValue) => {
    const { colorTheme } = this.props;
    const textColor = colorTheme || '#2E75B5';
    if (actualValue === null || actualValue === undefined) {
      return (
        <span className="absolute left-0 top-50 transform-vertical-center col-12 center z1 bold h6 investor-dashboard__chart-value-color--na">
          N/A
        </span>
      );
    }
    return (
      <span className="absolute left-0 top-50 transform-vertical-center col-12 center z1 bold h5" style={{ color: textColor }}>
        {actualValue}
      </span>
    );
  }

  render() {
    const { data, colorTheme, customInvestment, showSource } = this.props;
    const re = /.*(\s\d+),/;
    const polygonize = (string) => (string.match(re) ? string.match(re)[1] : ' 0');
    const polygonizeLeft = (string) => (string.match(/(\d+),/) ? string.match(/(\d+),/)[1] : ' 0');
    const chartClasses = classNames('width-11p pl1 relative flex flex-justify-end flex-column pb1', {
      'sm-hide': data.metric !== 'Revenue',
      'sm-width-50p': data.metric === 'Revenue',
    });

    let chartColor;
    if (colorTheme && Color(colorTheme).white() <= 80) {
      chartColor = Color(colorTheme).mix(Color('white'), 0.84);
    } else {
      chartColor = window.ColorVariables.colorLighterGray;
    }

    if (!data.chart || (data.actual === '')) {
      return (
        <div className={chartClasses}>
          {this.showZeroOrNoValue(data.actual_value)}
          <svg viewBox="0 0 220 110">
            <polyline
              fill="none"
              stroke={chartColor}
              strokeWidth="7"
              points="0,103 220,103"
            />
          </svg>
        </div>
      );
    }
    if (data.negative) {
      return (
        <div className={chartClasses}>
          {showSource ?
            <React.Fragment>
              <span className="absolute left-0 top-50 transform-vertical-center-off-top col-12 center z1 bold h5">
                {this.showZeroOrNoValue(data.actual_value)}
              </span>
              <span className={`absolute left-0 top-50 transform-vertical-center-off-below col-12 center z1 light h6 ${data.from_investment_profile ? 'text-blue' : 'text-green'}`}>
                {data.from_investment_profile ? 'Custom' : 'Company'}
              </span>
            </React.Fragment>
            :
            <span className="absolute left-0 top-50 transform-vertical-center col-12 center z1 bold h5">
              {this.showZeroOrNoValue(data.actual_value)}
            </span>
          }
          <svg viewBox="0 0 200 110">
            <polygon
              fill={chartColor}
              stroke={chartColor}
              strokeWidth="1"
              points={`${polygonizeLeft(data.actual)},55 ${data.actual + polygonize(data.actual)},55`}
            />
          </svg>
        </div>
      );
    }
    return (
      <div className={chartClasses}>
        {showSource ?
          <React.Fragment>
            <span className="absolute left-0 top-50 transform-vertical-center-off-top col-12 center z1 bold h5">
              {this.showZeroOrNoValue(data.actual_value)}
            </span>
            <span className={`absolute left-0 top-50 transform-vertical-center-off-below col-12 center z1 light h6 ${data.from_investment_profile ? 'text-blue' : 'text-green'}`}>
              {data.from_investment_profile ? 'Custom' : 'Company'}
            </span>
          </React.Fragment>
          :
          <span className="absolute left-0 top-50 transform-vertical-center col-12 center z1 bold h5">
            {this.showZeroOrNoValue(data.actual_value)}
          </span>
        }
        <svg viewBox="0 0 200 110">
          <polygon
            fill={chartColor}
            stroke={chartColor}
            strokeWidth="1"
            points={`${polygonizeLeft(data.actual)},110 ${data.actual + polygonize(data.actual)},110`}
          />
        </svg>
      </div>
    );
  }
}

export default KpiChart;
