import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import CheckBox from '../../../CheckBox';
import { defaultSelectStyles, defaultSelectTheme } from '../../../selects/SelectStyles';
import CompanyFilterBy from './CompanyFilterBy';
import CompanyList from './CompanyListFilters';

class ContentConfigForm extends React.Component {
  constructor(props) {
    super(props);

    this.slideshowContentSelectOptions = props.slideshowContentOptions.map(option => ({ label: option, value: option }));

    this.state = {
      selectedSlideshowContentOption: this.getSelectedContentOptions(props.slides),
    };

    this.handleSlideshowChange = this.handleSlideshowChange.bind(this);
  }

  handleCheckAll = () => {
    const {
      companies,
      onChangeCompanyFilters,
    } = this.props;

    const allSelected = this.checkIfAllSelected(companies);
    const newSelection = companies.map(company => ({ id: company.id, selected: !allSelected }));

    onChangeCompanyFilters(newSelection);
  };

  handleSlideshowChange = selected => {
    const { onChangeSlideshowContent } = this.props;
    this.setState({
      selectedSlideshowContentOption: selected,
    });
    onChangeSlideshowContent(selected);
  };

  getSelectedContentOptions = slides => slides.map(slide => ({ label: slide.slide_type, value: slide.slide_type }));

  checkIfAllSelected = companies => companies.reduce((allIncluded, company) => company.selected && allIncluded, true);

  render() {
    const {
      selectedSlideshowContentOption,
    } = this.state;

    const {
      companies,
      companyInfoOptions,
      industryOptions,
      statusOptions,
      fundraisingOptions,
      businessOptions,
      stageOptions,
      fundOptions,
      onChangeCompanyFilters,
      onChangeFilteringCriteria,
    } = this.props;

    const allSelected = this.checkIfAllSelected(companies);

    return (
      <div className="content-config-form-wrapper">
        <form className="content-config-form">
          <section className="content-config-select-section sm-hide">
            <div className="row-select col-8">
              <label htmlFor="slideshow-content">
                <span>Slideshow Content</span>
              </label>
              <Select
                id="slideshow-content"
                options={this.slideshowContentSelectOptions}
                value={selectedSlideshowContentOption}
                onChange={this.handleSlideshowChange}
                isMulti
                styles={defaultSelectStyles}
                theme={defaultSelectTheme}
              />
            </div>
            <div className="row-select col-4">
              <label htmlFor="slideshow-company-info">
                <span>Company Information</span>
              </label>
              <Select
                id="slideshow-content"
                options={companyInfoOptions}
                value={companyInfoOptions[0]}
                isDisabled
                styles={defaultSelectStyles}
                theme={defaultSelectTheme}
              />
            </div>
          </section>
          <section className="content-config-filter-section">
            <div className="filter-section-header sm-mb2 lh-15">
              <div className="companies">
                <span>Included Companies</span>
              </div>
              <div className="company-filters">
                <CompanyFilterBy
                  name="Status"
                  options={statusOptions}
                  color
                  onChangeFilteringCriteria={onChangeFilteringCriteria}
                  countOptions={statusOptions.map(option => {
                    const count = {};
                    count[option[0]] = companies.filter((company) => company.status === option[0]).length;
                    return count;
                  })}
                />
                <CompanyFilterBy
                  name="Fundraising Status"
                  options={fundraisingOptions}
                  color
                  onChangeFilteringCriteria={onChangeFilteringCriteria}
                  countOptions={fundraisingOptions.map(option => {
                    const count = {};
                    count[option[0]] = companies.filter((company) => company.fundraising_status === option[0]).length;
                    return count;
                  })}
                />
                <CompanyFilterBy
                  name="Funds"
                  options={fundOptions}
                  onChangeFilteringCriteria={onChangeFilteringCriteria}
                  countOptions={fundOptions.map(option => {
                    const count = {};
                    count[option[0]] = companies.filter((company) => company.funds.includes(option[0])).length;
                    return count;
                  })}
                />
                <CompanyFilterBy
                  name="Stage"
                  options={stageOptions}
                  onChangeFilteringCriteria={onChangeFilteringCriteria}
                  countOptions={stageOptions.map(option => {
                    const count = {};
                    count[option[0]] = companies.filter((company) => company.stage === option[0]).length;
                    return count;
                  })}
                />
                <CompanyFilterBy
                  name="Industry"
                  options={industryOptions}
                  onChangeFilteringCriteria={onChangeFilteringCriteria}
                  countOptions={industryOptions.map(option => {
                    const count = {};
                    count[option[0]] = companies.filter((company) => company.industry === option[0]).length;
                    return count;
                  })}
                />
                <CompanyFilterBy
                  name="Business Type"
                  options={businessOptions}
                  onChangeFilteringCriteria={onChangeFilteringCriteria}
                  countOptions={businessOptions.map(option => {
                    const count = {};
                    count[option[0]] = companies.filter((company) => company.business_type === option[0]).length;
                    return count;
                  })}
                />
              </div>
            </div>
            <div className="company-list-item">
              <div className="check-item">
                <CheckBox
                  manualCheck
                  checked={allSelected}
                  onChange={this.handleCheckAll}
                />
                <label className="title">
                  <span>Name</span>
                </label>
              </div>
            </div>
            <div className="company-list-container">
              <CompanyList
                companies={companies}
                onChangeCompanyFilters={onChangeCompanyFilters}
              />
            </div>
          </section>
        </form>
      </div>
    );
  }
}

ContentConfigForm.propTypes = {
  companies: PropTypes.array,
  companyInfoOptions: PropTypes.array,
  statusOptions: PropTypes.array,
  fundraisingOptions: PropTypes.array,
  industryOptions: PropTypes.array,
  fundOptions: PropTypes.array,
  businessOptions: PropTypes.array,
  stageOptions: PropTypes.array,
  onChangeCompanyFilters: PropTypes.func.isRequired,
  onChangeFilteringCriteria: PropTypes.func.isRequired,
  onChangeSlideshowContent: PropTypes.func.isRequired,
  slides: PropTypes.array,
  slideshowContentOptions: PropTypes.array.isRequired,
};

ContentConfigForm.defaultProps = {
  companies: [],
  companyInfoOptions: [
    { value: 'basics_only', label: 'Profile Data' },
  ],
  statusOptions: [['all', 'All']],
  fundraisingOptions: [['all', 'All']],
  industryOptions: [['all', 'All']],
  businessOptions: [['all', 'All']],
  fundOptions: [['all', 'All']],
  stageOptions: [['all', 'All']],
  slides: [
    {
      slide_type: 'intro',
    },
    {
      slide_type: 'overview',
    },
    {
      slide_type: 'companies',
    },
    {
      slide_type: 'outro',
    },
  ],
};

export default ContentConfigForm;
