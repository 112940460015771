import axios from 'axios';

import {
  addSection,
  updateSection,
  updateSections,
  removeSection,
  addFile,
  setFile,
  setFiles,
  removeFile,
} from '../../../actions/dataRoomActions';

const createSection = (_dataRoomId, params) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const dealRoomId = getState().dealRoom.id;
    const res = await axios.post(`/company/${companyId}/deal_rooms/${dealRoomId}/data_room_sections`, params);
    dispatch(addSection(res.data));
  };

const renameSection = (_dataRoomId, id, params) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const dealRoomId = getState().dealRoom.id;
    const res = await axios.patch(`/company/${companyId}/deal_rooms/${dealRoomId}/data_room_sections/${id}`, params);
    dispatch(updateSection(res.data));
  };

const deleteSection = (_dataRoomId, id) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const dealRoomId = getState().dealRoom.id;
    const res = await axios.delete(`/company/${companyId}/deal_rooms/${dealRoomId}/data_room_sections/${id}`);
    dispatch(removeSection(res.data));
  };

const updateSectionPosition = (dataRoomId, id, params) =>
  async (dispatch, getState) => {
    const { dealRoom: { id: dealRoomId }, company: { id: companyId } } = getState();
    const res = await axios.patch(` /company/${companyId}/deal_rooms/${dealRoomId}/data_room_sections/${id}/update_position`, params);
    dispatch(updateSections(res.data));
  };

const createFile = (_dataRoomId, params) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const dealRoomId = getState().dealRoom.id;
    const res = await axios.post(`/company/${companyId}/deal_rooms/${dealRoomId}/data_room_files`, params);
    dispatch(addFile(res.data));
  };

const moveFile = (_dataRoomId, id, params) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const dealRoomId = getState().dealRoom.id;
    const res = await axios.patch(`/company/${companyId}/deal_rooms/${dealRoomId}/data_room_files/${id}`, params);
    dispatch(removeFile(res.data));
    dispatch(addFile(res.data));
  };

const updateFile = (_dataRoomId, id, params) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const dealRoomId = getState().dealRoom.id;
    const res = await axios.patch(`/company/${companyId}/deal_rooms/${dealRoomId}/data_room_files/${id}`, params);
    dispatch(setFile(res.data));
  };

const deleteFile = (_dataRoomId, id) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const dealRoomId = getState().dealRoom.id;
    const res = await axios.delete(`/company/${companyId}/deal_rooms/${dealRoomId}/data_room_files/${id}`);
    dispatch(removeFile(res.data));
  };

const updateFilePosition = (dataRoomId, id, params) =>
  async (dispatch, getState) => {
    const { dealRoom: { id: dealRoomId }, company: { id: companyId } } = getState();
    const res = await axios.patch(`/company/${companyId}/deal_rooms/${dealRoomId}/data_room_files/${id}/update_position`, params);
    dispatch(setFiles(res.data));
  };

export {
  createSection,
  renameSection,
  deleteSection,
  updateSectionPosition,
  createFile,
  moveFile,
  updateFile,
  deleteFile,
  updateFilePosition,
};
