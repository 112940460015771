import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

class MemberCardAdmin extends Component {
  static propTypes = {
    manage_url: PropTypes.string,
    reinvite_url: PropTypes.string,
    name: PropTypes.string,
    userAdmin: PropTypes.bool,
    id: PropTypes.number,
  }

  state = {
    open: false,
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
    document.removeEventListener('touchstart', this.handleClick, false);
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
    document.addEventListener('touchstart', this.handleClick, false);
  }

  deleteMember = (e) => {
    e.preventDefault();

    const { name, manage_url, id } = this.props;

    const confrimed = confirm(`Are your sure to remove ${name} from Partners?`);
    if (confrimed) {
      App.Api.delete(manage_url).then(() => {
        store.dispatch({ type: 'REMOVE_ITEM', list: 'main', id });
        App.State.setFlash({ name: 'notice', msg: 'Manager removed!' });
      });
    }
  }

  handleClick = (e) => {
    if (ReactDOM.findDOMNode(this).contains(e.target)) return;
    this.setState({ open: false });
  }

  reinvite = (e) => {
    e.preventDefault();

    const { reinvite_url } = this.props;

    App.Api.patch(reinvite_url).then(() => {
      App.State.setFlash({ name: 'notice', msg: 'Invite email sent again!' });
    });
  }

  reinviteButton = () => {
    const { reinvite_url } = this.props;

    if (reinvite_url.length > 0) {
      return (
        <li>
          <div
            className="bold h5 link-hover--blue mb1 block nowrap px1 text-gray cursor-pointer"
            onClick={this.reinvite}
          >
            <i className="fa fa-fw fa-envelope fa--dropdown ml1 right" />
            Resend Invitation
          </div>
        </li>
      );
    }
    return null;
  }

  render() {
    const { open } = this.state;
    return (
      <div className="relative cursor-pointer mx1">
        <div
          className="text-light-gray cursor-pointer"
          onClick={() => { this.setState({ open: !open }); }}
        >
          <i className="fa fa-cog fa-fw" />
        </div>

        <div className={`dropdown dropdown--center absolute bg-white border border-alto bs-dropdown left-50 transform-horizontal-center z1 ${open ? '' : 'hidden'} py1`}>
          <ul className="left-align m0 mw150">
            {this.reinviteButton()}
            <li>
              <div
                className="bold h5 link-hover--blue block nowrap px1 text-gray cursor-pointer"
                onClick={this.deleteMember}
              >
                <i className="fa fa-fw fa-trash fa--dropdown ml1 right" />
                Remove Partner
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default MemberCardAdmin;
