import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import UpgradeAccountModal from './UpgradeAccountModal';

class ModalWithButton extends Component {
  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    modalType: PropTypes.string.isRequired,
    eventDescription: PropTypes.string,
  }

  static defaultProps = {
    eventDescription: null,
  }

  state = {
    showModal: false,
  }

  modals = {
    UpgradeAccountModal,
  }

  render() {
    const { buttonText, modalType, eventDescription } = this.props;
    const { showModal } = this.state;

    const ModalComponent = this.modals[modalType];

    return (
      <>
        <Button
          classNames="btn btn-outline btn-small border-alto bg-white text-gray sm-hide"
          onClick={() => this.setState({ showModal: true })}
        >
          {buttonText}
        </Button>
        <ModalComponent
          show={showModal}
          closeModal={() => this.setState({ showModal: false })}
          topHeader
          paywallDescription={modalType === 'UpgradeAccountModal' ? eventDescription : null}
        />
      </>
    );
  }
}

export default ModalWithButton;
