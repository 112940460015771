import axios from 'axios';

import {
  updateCapTable,
} from '../../../actions/company/capTableActions';

const switchCapTable = capTableId =>
  async (dispatch, getState) => {
    const investmentId = getState().investmentHeader.id;
    const investorId = getState().investmentHeader.investor.id;
    const res = await axios.get(`/investor/${investorId}/investments/${investmentId}/chosen_cap_tables/${capTableId}`);
    dispatch(updateCapTable(res.data));
  };

export { switchCapTable };
