import chartDataStructure from '../../components/investor/modules/chartDataStructure';

import {
  totalValues,
  xirr,
} from '../../components/utils/investmentInfoDataCalculations';

import {
  kpiTotalValues,
} from '../../components/utils/kpisDataCalculations';

import { structureKpiStateData } from '../utils/homeAndDashboard';

const investorHome = (state = {}, action) => {
  switch (action.type) {
    case 'SET_TIME_AGO_HOME_PAGE':
      return {
        ...state,
        kpis_info: {
          ...state.kpis_info,
          time_ago: action.timeAgo,
          frequency: action.frequency,
        },
      };
    case 'REMOVE_ITEM':
      return {
        ...state,
        companies: state.companies.filter((x) => (x.id !== action.id)),
      };
    case 'REMOVE_FUND':
      return {
        ...state,
        can_create_funds: action.fund.can_create_funds,
        funds: state.funds.filter((fund) => (fund.id !== action.fund.fund_data.id)),
      };
    case 'ADD_FUND': {
      return {
        ...state,
        can_create_funds: action.fund.can_create_funds,
        funds: [...state.funds, action.fund.fund_data],
      };
    }
    case 'UPDATE_CHART_INVESTOR_HOME': {
      switch (action.dashboardType) {
        case 'company_info': return {
          ...state,
          company_info: {
            ...state.company_info,
            chart_data: {
              ...state.company_info.chart_data,
              data: chartDataStructure(state.company_info.dashboard_data.data, 'company_info', { filter: action.filterType, colorTheme: state.color_theme }),
            },
          },
        };
        case 'investment_info': return {
          ...state,
          investment_info: {
            ...state.investment_info,
            chart_data: {
              data: action.chartData.data,
              right_section_data: action.chartData.right_section_data,
            },
          },
        };

        case 'kpis_info': {
          let newKpiChartState;
          if (action.chartData) {
            const newKpisState = state.kpis_info.dashboard_data.data.map(tableData => (
              structureKpiStateData(action.chartData.data, tableData)
            ));
            newKpiChartState = chartDataStructure(newKpisState, 'kpis_info', { kpiMetric: action.kpiMetric }, true);
          } else {
            newKpiChartState = chartDataStructure(state.kpis_info.dashboard_data.data, 'kpis_info', { kpiMetric: action.kpiMetric }, true);
          }
          return {
            ...state,
            kpis_info: {
              ...state.kpis_info,
              chart_data: {
                ...state.kpis_info.chart_data,
                data: newKpiChartState,
                chosen_kpi: action.kpiMetric,
                chosen_kpi_type: action.kpiType,
              },
            },
          };
        }
        default: return state;
      }
    }

    //
    // TABLE VIEW UPDATE FOR EACH TAB
    //
    case 'UPDATE_TABLE_INVESTOR_HOME': {
      switch (action.dashboardType) {
        //
        // BEGIN OF COMPANIES TAB DATA HADLING
        //
        case 'company_info': {
          // ADD BASIC DATA TO STATE
          if (action.lazyLoadingLayer === 'basic') {
            return {
              ...state,
              company_info: {
                ...state.company_info,
                dashboard_data: {
                  data: action.dashboardData.map(companyData => ({ ...companyData })),
                  loading: false,
                },
              },
            };
          }

          // merge basic data with remaining data
          const remainingData = state.company_info.dashboard_data.data.map(companyData => (
            {
              ...companyData,
              ...action.dashboardData.find(company => company.id === companyData.id),
            }
          ));

          // ADD REMAINING DATA TO STATE
          return {
            ...state,
            company_info: {
              ...state.company_info,
              dashboard_data: {
                data: remainingData,
              },
              chart_data: {
                ...state.company_info.chart_data,
                data: chartDataStructure(remainingData, 'company_info', { filter: state.company_info.chart_data.filter, colorTheme: state.color_theme }),
                loading: false,
              },
            },
          };
        }
        //
        // END OF COMPANIES TAB DATA HANDLING
        //

        //
        // BEGIN OF INVESTMENTS TAB DATA HANDLING
        //
        case 'investment_info': {
          if (action.lazyLoadingLayer === 'basic') {
            return {
              ...state,
              investment_info: {
                ...state.investment_info,
                dashboard_data: {
                  ...state.investment_info.dashboard_data,
                  data: action.dashboardData.data,
                },
                chart_data: {
                  ...state.investment_info.chart_data,
                  data: chartDataStructure(action.dashboardData.data, 'investment_info', { colorTheme: state.color_theme }),
                  loading: false,
                },
              },
            };
          }

          // MERGING UP BASIC DATA WITH REMAINING DATA
          const investmentInfoDashboardData = state.investment_info.dashboard_data.data.map(companyData => {
            const investmentInfoData = action.dashboardData.data.find(company => company.id === companyData.id);
            const completeData = { ...companyData, ...investmentInfoData };
            return {
              ...completeData,
              xirr: xirr(completeData, state.investment_info.dashboard_data.show_irr),
            };
          });

          // ADD REMAINING DATA TO STATE
          return {
            ...state,
            investment_info: {
              ...state.investment_info,
              dashboard_data: {
                ...state.investment_info.dashboard_data,
                data: investmentInfoDashboardData,
                total_values: totalValues(investmentInfoDashboardData, state.investment_info.dashboard_data.show_irr, 'home'),
                loading: false,
              },
            },
          };
        }

        //
        // BEGIN OF KPIS TAB DATA HANDLING
        //
        case 'kpis_info': {
          const totals = kpiTotalValues(action.dashboardData.data, state.kpis_info.dashboard_data.dashboard_kpis);

          return {
            ...state,
            kpis_info: {
              ...state.kpis_info,
              dashboard_data: {
                ...state.kpis_info.dashboard_data,
                data: action.dashboardData.data,
                total_sum_values: totals.sumValues,
                total_median_values: totals.medianValues,
              },
              chart_data: {
                ...state.kpis_info.chart_data,
                data: chartDataStructure(action.dashboardData.data, 'kpis_info', { kpiMetric: state.kpis_info.chart_data.chosen_kpi }, true),
                loading: false,
              },
            },
          };
        }
        default: return state;
      }
      //
      // END OF KPIS TAB DATA HANDLING
      //
    }
    case 'CARDS_REMAINING_DATA': {
      return {
        ...state,
        funds: state.funds.map(fundState => (
          {
            ...fundState,
            ...action.cardsData.funds.find(fundCard => fundCard.id === fundState.id),
          }
        )),
        companies: state.companies.map(companyState => (
          {
            ...companyState,
            ...action.cardsData.companies.find(companyCard => companyCard.id === companyState.id),
          }
        )),
      };
    }
    case 'ADD_UPDATES_DATA':
      return {
        ...state,
        updates: {
          data: action.updatesData.updates,
          next_page: action.updatesData.next_page,
        },
      };
    case 'ADD_DEAL_ROOMS_DATA':
      return {
        ...state,
        dealRoomsData: action.dealRoomsData.dealRoomsData,
      };
    case 'ADD_DOCUMENTS_DATA':
      return {
        ...state,
        documents: action.documentsData.files,
      };

    default: return state;
  }
};

export default investorHome;
