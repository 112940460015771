import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import Modal from './Modal';
import HtmlComponent from './tab_view/HtmlComponent';
import CheckBox from './CheckBox';


// 'link' will be used as the confirmation link. dataMethod needs to be set properly.
// An 'onClick' function can be passed as a props from the parent component
// 'message' will be used as the main component information to the user
// 'html' will render a button, a link, whatever the user wants implement the modal
// If 'confirmationWithDropzone' is set to true, the Dropzone component will be used instead of the normal link
// and the 'onDdrop' function will beed to be passed as props
class ConfirmationModal extends Component {
  static propTypes = {
    html: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    link: PropTypes.string,
    dataMethod: PropTypes.string,
    className: PropTypes.string,
    confirmationText: PropTypes.string,
    cancelText: PropTypes.string,
    onClick: PropTypes.func,
    onDrop: PropTypes.func,
    confirmationWithDropzone: PropTypes.bool,
    width: PropTypes.string,
    hmtlClassName: PropTypes.string,
    disabled: PropTypes.bool,
    disabledClassName: PropTypes.string,
    disabledBalloonText: PropTypes.string,
    checkBoxParam: PropTypes.string,
    checkBoxText: PropTypes.string,
  }

  static defaultProps = {
    link: null,
    dataMethod: null,
    className: 'col-2 mx1 py1 flex flex-center flex-justify-center rounded bg-green text-white fw400 cursor-pointer',
    onClick: null,
    onDrop: null,
    confirmationWithDropzone: false,
    width: '100%',
    confirmationText: 'Confirm',
    cancelText: 'Cancel',
    hmtlClassName: '',
    disabled: false,
    disabledClassName: '',
    disabledBalloonText: null,
    checkBoxParam: null,
    checkBoxText: 'Extra option',
  }

  state = {
    open: false,
    checkBoxState: false,
    loading: false,
  }

  handleClick = async () => {
    const { onClick } = this.props;
    const { loading } = this.state;

    if (onClick) {
      if (loading) return;

      const { checkBoxParam } = this.props;
      const { checkBoxState } = this.state;

      this.setState({ loading: true });
      await onClick(checkBoxParam ? { [checkBoxParam]: checkBoxState } : null);
      this.setState({ loading: false }, () => (
        this.setState({ open: false, checkBoxState: false })
      ));
    }
  }

  handleDrop = (file) => {
    const { onDrop } = this.props;

    this.setState({ open: false });
    if (onDrop) onDrop(file);
  }

  renderLinkOrDropzone = () => {
    const { confirmationText, link, dataMethod, confirmationWithDropzone } = this.props;
    if (confirmationWithDropzone) {
      return (
        <Dropzone
          multiple={false}
          className="col-3 center py1 bg-green rounded text-white cursor-pointer"
          onDrop={file => this.handleDrop(file)}
        >
          {confirmationText}
        </Dropzone>
      );
    }
    return (
      <a
        href={link}
        data-method={dataMethod}
        data-disable-with="Loading..."
        className="col-3 center py1 bg-green rounded text-white cursor-pointer"
        onClick={() => { this.handleClick(); }}
      >
        {confirmationText}
      </a>
    );
  }

  handleClose = () => {
    const { checkBoxParam } = this.props;
    if (checkBoxParam) {
      this.setState({ open: false, checkBoxState: false });
    } else {
      this.setState({ open: false });
    }
  }

  render() {
    const { className, width, message, cancelText } = this.props;
    const { html, hmtlClassName, disabled, disabledClassName, disabledBalloonText, checkBoxParam, checkBoxText } = this.props;
    const { open, checkBoxState } = this.state;

    return (
      disabled ?
        <div
          data-balloon={disabledBalloonText}
        >
          <HtmlComponent data={{ html, css_class: disabledClassName }} />
        </div>
        :
        <>
          <div
            className={className}
            style={{ width }}
            onClick={(e) => { e.preventDefault(); this.setState({ open: true }); }}
          >
            <HtmlComponent data={{ html, css_class: hmtlClassName }} />
          </div>
          <Modal
            className="react_modal--300"
            show={open}
            onClickOutside={this.handleClose}
          >
            <div className="bg-white p3">
              <div className="flex flex-center flex-justify-center text-gray">
                <HtmlComponent data={{ html: message }} />
              </div>

              {checkBoxParam &&
                <div className="col-12 flex mt2">
                  <CheckBox
                    manualCheck
                    checked={checkBoxState}
                    fixedWidth
                    onChange={() => this.setState({ checkBoxState: !checkBoxState })}
                  />
                  <span className="text-light-gray ml1 h5">{checkBoxText}</span>
                </div>
              }

              <div className="pt3 flex flex-center flex-justify-around">
                <div
                  href="#"
                  className="col-3 center py1 bg-light-gray rounded text-white cursor-pointer"
                  onClick={(e) => { e.preventDefault(); this.handleClose(); }}
                >
                  {cancelText}
                </div>
                {this.renderLinkOrDropzone()}
              </div>
            </div>
          </Modal>
        </>
    );
  }
}

export default ConfirmationModal;
