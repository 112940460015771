const setSorting = sorting => ({
  type: 'SET_SORTING',
  sorting,
});

const toggleOrder = () => ({
  type: 'TOGGLE_ORDER',
});

export {
  setSorting,
  toggleOrder,
};
