import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import { defaultSelectStyles, defaultSelectTheme } from '../../../selects/SelectStyles';
import DatePicker from '../../../selects/DatePicker';


class TimeframeSelect extends Component {
  static propTypes = {
    timeRangeOptions: PropTypes.array.isRequired,
    chosenTimeline: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    handleTimeFrameSelection: PropTypes.func.isRequired,
    handleCustomDateChange: PropTypes.func.isRequired,
    width: PropTypes.string,
  }

  static defaultProps = {
    width: 'col-10',
  }

  toBeDeleted = () => {
    return true;
  }

  render() {
    const {
      timeRangeOptions,
      chosenTimeline,
      startDate,
      endDate,
      handleTimeFrameSelection,
      handleCustomDateChange,
      width,
    } = this.props;

    return (
      <>
        <ReactSelect
          className={width}
          defaultValue={timeRangeOptions.filter(option => option.value === chosenTimeline)}
          value={timeRangeOptions.filter(option => option.value === chosenTimeline)}
          options={timeRangeOptions}
          idClearable={false}
          onChange={selectedOption => handleTimeFrameSelection(selectedOption.value)}
          styles={defaultSelectStyles}
          theme={defaultSelectTheme}
        />
        {chosenTimeline === 'custom' &&
          <div className="flex flex-justify-between col-8">
            <div className="flex flex-column" style={{ width: '45%' }}>
              <span>From</span>
              <DatePicker
                value={startDate}
                className="border border-gallery"
                name="issue_date"
                onChange={e => handleCustomDateChange(e.target.value, 'startDate')}
              />
            </div>
            <div className="flex flex-column" style={{ width: '45%' }}>
              <span>To</span>
              <DatePicker
                value={endDate}
                className="border border-gallery"
                name="issue_date"
                onChange={e => handleCustomDateChange(e.target.value, 'endDate')}
              />
            </div>
          </div>
        }
      </>
    );
  }
}

export default TimeframeSelect;
