import { connect } from 'react-redux';
import SigningRoomRequest from '../../components/signing_room/Request';

import {
  uploadSignerDocument,
  deleteSignerDocument,
  requestReupload,
} from '../../api/signerApi';

import {
  uploadRequestDocument,
  deleteRequestDocument,
  completeRequest,
} from '../../api/signingRoomRequestApi';

const mapStateToProps = state => ({
  request: state.request,
});

const mapDispatchToProps = {
  uploadSignerDocument,
  deleteSignerDocument,
  requestReupload,
  uploadRequestDocument,
  deleteRequestDocument,
  completeRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SigningRoomRequest);
