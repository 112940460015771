import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Avatar from '../../tab_view/Avatar';
import AddInvestment from '../investments/AddInvestment';


export default class CompanyCard extends Component {
  static defaultProps = {
    bNewConnection: false,
  }

  static propTypes = {
    bNewConnection: PropTypes.bool,
    company: PropTypes.object,
    removeItem: PropTypes.func,
    customInvestmentCount: PropTypes.number,
    investor: PropTypes.object,
    scroll: PropTypes.number,
    parentRef: PropTypes.object.isRequired,
  }

  static defaultProps = {
    customInvestmentCount: null,
    investor: null,
    removeItem: null,
    company: null,
    scroll: 0,
  }

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      showCompanyUrls: false,
      hover: false,
      connecting: false,
    };
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
    document.addEventListener('touchstart', this.handleClick, false);
  }

  componentDidUpdate() {
    const { parentRef } = this.props;
    if (parentRef && parentRef.current) {
      parentRef.current.addEventListener('scroll', this.handleClick, false);
    }
  }

  componentWillUnmount() {
    const { parentRef } = this.props;
    document.removeEventListener('click', this.handleClick, false);
    document.removeEventListener('touchstart', this.handleClick, false);
    if (parentRef && parentRef.current) {
      parentRef.current.removeEventListener('scroll', this.handleClick, false);
    }
  }

  deleteMember = (e) => {
    e.preventDefault();
    const { company, removeItem } = this.props;

    const confrimed = confirm(`Are your sure to remove ${company.company_name} from Porfolio?`);
    if (confrimed) {
      App.Api.delete(company.manage_url).then(() => {
        removeItem(company.id);
        App.State.setFlash({ name: 'notice', msg: 'Deleted Company!' });
      });
    }
  }

  accept = (e) => {
    e.preventDefault();
    const { company } = this.props;
    const { connecting } = this.state;

    if (connecting) return;

    this.setState({ connecting: true });

    App.Api.patch(company.accept_url);
  }

  decline = (e) => {
    e.preventDefault();
    const { company, removeItem } = this.props;

    const confrimed = confirm(`Are your sure to decline ${company.company_name} from Porfolio?`);

    if (confrimed) {
      App.Api.delete(company.manage_url).then(() => {
        removeItem(company.id);
        App.State.setFlash({ name: 'notice', msg: 'Invitation declined!' });
      });
    }
  }

  handleClick = (e) => {
    if (ReactDOM.findDOMNode(this).contains(e.target)) return;
    this.setState({ open: false, showCompanyUrls: false });
  }

  reinvite = (e) => {
    e.preventDefault();

    const { company } = this.props;

    App.Api.patch(company.reinvite_url).then(() => {
      App.State.setFlash({ name: 'notice', msg: 'Invite email sent again!' });
    });
  }

  reinviteButton = () => {
    const { company } = this.props;

    if (company.reinvite_url) {
      return (
        <li>
          <div
            className="bold h5 link-hover--blue mb1 block nowrap px1 text-gray cursor-pointer"
            onClick={this.reinvite}
          >
            <i className="fa fa-fw fa-envelope fa--dropdown ml1 right" />
            Resend Invitation
          </div>
        </li>
      );
    }
    return null;
  }

  collaborators = () => {
    const { company } = this.props;

    if (!company.collaborators) return null;

    return (
      <div className="flex flex-justify-center my1">
        <div className="flex flex-row-reversed">
          {
            company.collaborators.slice(0, 6).map(user => (<Avatar key={user.id} avatarUrl={user.avatar} overlaped />))
          }
          {(company.collaborators.length > 6) ?
            <Avatar avatarUrl="" addition={company.collaborators.length - 6} />
            :
            null
          }
        </div>
      </div>
    );
  }

  toggleHover=() => {
    const { hover } = this.state;
    this.setState({
      hover: !hover,
    });
  }

  toggleDropdown() {
    const { showCompanyUrls } = this.state;

    if (showCompanyUrls) {
      this.setState({ showCompanyUrls: false });
    } else {
      this.setState({ showCompanyUrls: true });
    }
  }


  render() {
    const { company, bNewConnection, customInvestmentCount, investor, scroll } = this.props;
    const { showCompanyUrls, open, hover, connecting } = this.state;
    let strStatus;

    if (company) {
      if (company.connected_investment === true) {
        strStatus = 'Connected';
      } else if (company.accept_url || company.invited_not_registered) {
        strStatus = 'Invited';
      } else if (company.reinvite_url) {
        strStatus = 'Requested';
      }
    }
    const textWhite = {};

    if (hover) {
      textWhite.color = 'white';
    }

    if (!company && !bNewConnection) {
      return null;
    }

    if (!bNewConnection) {
      return (
        <div className="mr2 flex flex-column bg-white bs-around border-blue border-top bw-2 hover company-card card--has-hover-shadow-light-gray__small" style={{ width: '198px' }}>
          <div className="flex flex-justify-center mx1 mt2 mb1 relative">
            <Avatar avatarUrl={company.avatar} size={40} shadow />
            {company.real_company &&
              <div className="relative">
                <span className="real_company__notification" style={{ top: '-10px', right: '-10px' }} data-behavior="hide-bubble">
                  <i className="fa fa-check-circle text-green" />
                </span>
              </div>
            }
            <div className="absolute cursor-pointer mx1 top-0 right-0">
              <div
                className="text-light-gray cursor-pointer"
                onClick={() => { this.setState({ open: !open }); }}
              >
                <i className="fa fa-ellipsis-h" />
              </div>

              <div className={`dropdown dropdown--center absolute bg-white border border-alto bs-dropdown left-50 transform-horizontal-center z1 ${open ? '' : 'hidden'} py1`}>
                <ul className="left-align m0 mw150">
                  {this.reinviteButton()}
                  <li>
                    <div
                      className="bold h5 link-hover--blue block nowrap px1 text-gray cursor-pointer"
                      onClick={this.deleteMember}
                    >
                      <i className="fa fa-fw fa-trash fa--dropdown ml1 right" />
                      Remove Company
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex flex-justify-center items-center fs-18 fw400 center px1" style={{ minHeight: '2.4em' }}>
            { company.company_name }
          </div>
          <div className="flex flex-justify-center h5 text-light-gray center px1">
            {strStatus} { company.date_of_creation }
          </div>

          { this.collaborators() }

          {!company.connected_investment ?
            !company.accept_url ?
              null
              :
              <div className="btn-group mt-auto flex-justify-between">
                <div className="btn btn-outline__company btn-cancel border border-red rounded" onClick={this.decline}>
                  <i className="fa fa-times" aria-hidden="true" />
                </div>
                <div className="btn btn-outline__company btn-accept border border-green rounded" onClick={this.accept}>
                  <i className="fa fa-check" aria-hidden="true" /> &nbsp; {connecting ? 'Connecting...' : 'Accept'}
                </div>
              </div>
            :
              <div className="mt-auto">
                <div
                  className="btn-group flex-justify-center"
                >
                  { !company.custom_investment ? (
                    <React.Fragment>
                      {(company.urls && company.urls.every(el => el.link == null)) ?
                        <div className="bg-white" style={{ padding: '3px 0px 3px 0px' }}>
                          <a className="text-medium-gray cursor-disabled" href={`/c/${company.company_id}`}>
                            <i className="fa fa-times-circle mr1" aria-hidden="true" />
                            <span
                              className="text"
                              data-balloon="This company has not granted you acces to their private data yet. You can still view basic information as well as Updates below. Ask the company to provide access."
                              data-balloon-pos="up"
                              data-balloon-length="medium"
                            >
                              No Access Granted
                            </span>
                          </a>
                        </div>
                        :
                        <div className="btn btn-outline__company border border-green rounded">
                          <a
                            className="btn-go-to-company text-green"
                            href={`/c/${company.company_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            data-balloon="This button opens in a new tab the same view of the company that the conpany team sees. To come back to the investor view, just close the new tab."
                            data-balloon-pos="right"
                            data-balloon-length="large"
                          >
                            <i className="fa fa-home" aria-hidden="true" />
                            <span className="text border-right border-green">Go To Company</span>
                          </a>
                          <i className="fa fa-caret-down text-green" aria-hidden="true" onClick={this.toggleDropdown.bind(this)} />
                        </div>
                      }
                    </React.Fragment>
                  ) : (
                    <div className="px2 btn btn-outline__profile" onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                      <a className="btn-go-to-profile text-blue" href={`/investor/${investor.id}/investments/${company.id}`}>
                        <i className="fa fa-book" aria-hidden="true" style={textWhite} />
                        <span className="text-no-border" style={textWhite}>Go To Profile</span>
                      </a>
                    </div>
                  )
                  }
                </div>
                {
                  showCompanyUrls ? (
                    <div className="dropdown-container" style={{ transform: `translateX(${-scroll}px)` }}>
                      <ul className="dropdown-menu bs-around">
                        {company.urls ?
                          company.urls.map((url) => (
                            <li key={`${company.id}_${Math.random()}`} className={`${url.link !== null ? '' : 'bg-gallery cursor-disabled'}`}>
                              <a
                                className="flex text-gray fw400"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={url.link}
                                data-balloon={url.link !== null ? null : 'Access has not been granted.'}
                                data-balloon-pos="right"
                                data-balloon-length="small"
                              >
                                {url.name}
                              </a>
                            </li>
                          ))
                          : null
                        }
                      </ul>
                    </div>) : null
                  }
              </div>
              }
        </div>
      );
    }
    return (
      <AddInvestment
        customInvestmentsCount={customInvestmentCount}
        investor={investor}
        wrapperClassName="col-2 p2 flex flex-column relative border--dashed__simple bw-2 border-alto full-height cursor-pointer new-connection-box"
        className="new-connection-box-text"
        text="+ New Connection"
        newConnection
      />
    );
  }
}
