import React from 'react';
import PropTypes from 'prop-types';

import RunwayChartPortal from '../kpi_step/RunwayChartPortal';

const RunwaySection = ({ section }) => (
  section.runway_chart.has_different_frequencies ?
    <div className="text-red fw400">
      For this chart to work Revenue, Cost Total and Cash on Hand frequencies should be the same.
    </div>
    :
    <RunwayChartPortal
      id={section.id}
      dates={section.runway_chart.dates}
      data_values={section.runway_chart.plan_data}
      title
    />
);

RunwaySection.propTypes = {
  section: PropTypes.object.isRequired,
};

export default RunwaySection;
