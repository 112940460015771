import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';


import Modal from '../Modal';

class FileUpload extends Component {
  static propTypes = {
    file: PropTypes.string,
    name: PropTypes.string,
    text: PropTypes.string,
  }

  static defaultProps = {
    file: null,
    name: null,
    text: "Drag 'n' drop a file here, or click to select file",
  }

  constructor(props) {
    super(props);
    const { file } = props;

    this.state = {
      show: false,
      src: file,
      files: [],
    };
  }

  removeFiles = () => {
    this.setState({ src: null, files: [] }, () => {
      document.getElementById('image').value = null;
    });
  }

  onFileLoad = (acceptedFiles) => {
    if (!acceptedFiles || acceptedFiles.length === 0) return;

    this.setState({ files: acceptedFiles }, () => {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onloadend = () => {
        const base64data = reader.result;
        this.setState({ src: base64data });
      };
    });
  }

  onSave = () => {
    const { src } = this.state;

    if (!src) {
      App.State.setFlash({ name: 'alert', msg: 'No file Added!' });
      return;
    }

    document.getElementById('image').value = src;

    this.setState({ show: false });
  }

  onCancel = () => {
    this.setState({ show: false }, () => {
      this.removeFiles();
    });
  }

  openModal = () => {
    this.setState({ show: true });
  }

  render() {
    const { name, text } = this.props;
    const { show, files } = this.state;

    return (
      <>
        <div>
          <div className="flex items-center">
            <i className="fa fa-paperclip" />
            {files.length > 0 ?
              <>
                <div className="cursor-pointer" onClick={this.openModal}>
                  <span className="ml1">Attachment added</span>
                  <span className="text-green ml05">({files[0].name})</span>
                </div>
                <i className="fa fa-close text-red ml1 cursor-pointer" onClick={(this.removeFiles)} />
              </>
              :
              <div className="cursor-pointer ml1" onClick={this.openModal}>Click to add attachment</div>
            }
          </div>
          <textarea name={name} id="image" style={{ display: 'none' }} />
        </div>

        <Modal
          className="bg-white flex flex-column mb2 p2 border-top border-blue bw-4"
          show={show}
        >
          <div className="p2 flex flex-column">
            <div className="flex text-light-gray flex-justify-start mt1 h6">
              Upload your file.
            </div>
            <div className="flex text-light-gray flex-justify-start h6 mt0">
              <span>PS: Size of file should not exceed (<b>5 Mb</b>), File type supported is (<b>.xlsx</b>).</span>
            </div>

            <Dropzone
              className="border border-transparent col-12 flex sm-col-12 mt2"
              onDrop={this.onFileLoad}
              onDropRejected={() => App.State.setFlash({ name: 'alert', msg: 'File not accepted. Please check if the type and size are correct.' })}
              style={{ position: 'relative' }}
              multiple={false}
              maxSize={5000000}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
              <div>
                <div className="py1 px2 border--dashed border-light-gray rounded cursor-pointer">
                  {text}
                </div>
                <ul className="mt2">
                  {files.map(file => (
                    <li key={file.name}>
                      - {file.name}
                    </li>
                  ))}
                </ul>
              </div>
            </Dropzone>
          </div>

          <div className="border-top border-lighter-gray m1 pt1 flex flex-justify-end">
            <div
              className="btn  text-blue cursor-pointer py1 px2 sm-col-12 mr1"
              onClick={this.onCancel}
            >
              Cancel
            </div>
            <div
              className="btn bg-blue border-none cursor-pointer fs-18 inline-block px2 py1 rounded sm-col-12 text-white"
              onClick={this.onSave}
            >
              Save
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default FileUpload;
