const accumPeriodToPeriodStep = (accumPeriod) => ({ month: 'months', quarter: 'quarters', year: 'years' }[accumPeriod]);

const accumulatorPeriodOptions = () => (
  [
    { label: 'Year', value: 'year' },
    { label: 'Quarter', value: 'quarter' },
    { label: 'Month', value: 'month' },
  ]
);

//  Acummulator period should be higher than the frequency of the largest frequency KPI.
// E.g. if you have one weekly and one quarterly, cumm period can be only quarterly or yearly
const filterAccumulatorPeriodOptions = (accumPeriodOptions, periodStepOptions, firstKpiState, secondKpiState) => {
  const periodStepOrder = periodStepOptions.map(option => option.value);

  return accumPeriodOptions.filter(option => {
    if (!$.isEmptyObject(firstKpiState) && !$.isEmptyObject(secondKpiState)) {
      const indexOfFirstKpi = periodStepOrder.indexOf(firstKpiState.period_step);
      const indexOfSecondKpi = periodStepOrder.indexOf(secondKpiState.period_step);
      if (indexOfFirstKpi === indexOfSecondKpi) {
        return periodStepOrder.slice(indexOfFirstKpi).includes(accumPeriodToPeriodStep(option.value));
      }
      if (indexOfFirstKpi > indexOfSecondKpi) {
        return periodStepOrder.slice(indexOfFirstKpi).includes(accumPeriodToPeriodStep(option.value));
      }
      return periodStepOrder.slice(indexOfSecondKpi).includes(accumPeriodToPeriodStep(option.value));
    }
    if (!$.isEmptyObject(firstKpiState)) {
      const indexOfFirstKpi = periodStepOrder.indexOf(firstKpiState.period_step);
      return periodStepOrder.slice(indexOfFirstKpi).includes(accumPeriodToPeriodStep(option.value));
    }
    if (!$.isEmptyObject(secondKpiState)) {
      const indexOfSecondKpi = periodStepOrder.indexOf(secondKpiState.period_step);
      return periodStepOrder.slice(indexOfSecondKpi).includes(accumPeriodToPeriodStep(option.value));
    }
    return true;
  });
};

export {
  accumPeriodToPeriodStep,
  accumulatorPeriodOptions,
  filterAccumulatorPeriodOptions,
};
