import React from 'react';
import { components } from 'react-select';

const { SingleValue } = components;


const ImageValue = (props) => {
  const { data, hasValue, placeholder } = props;

  if (!hasValue && placeholder) {
    return (
      <SingleValue {...props}>
        <span className="Select-value-label">
          <span className="center relative">{placeholder}</span>
        </span>
      </SingleValue>
    );
  }
  if (!hasValue) {
    return null;
  }
  return (
    <SingleValue {...props}>
      <span className="Select-value-label">
        <div
          className="center align-middle relative inline-block"
          style={{
            marginRight: '10px',
            top: '-4px',
          }}
        >
          <img
            className="align-middle inline-block"
            height="18px"
            width="18px"
            alt={data.label}
            src={data.icon || window.images.userBlue}
          />
        </div>
        <span className="center relative">{data.label}</span>
      </span>
    </SingleValue>
  );
};

export default ImageValue;
