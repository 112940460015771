import { connect } from 'react-redux';
import DataRoom from '../../../components/data_room';

import {
  createSection,
  renameSection,
  deleteSection,
  updateSectionPosition,
  createFile,
  moveFile,
  updateFile,
  updateFilePosition,
  deleteFile,
} from '../../../api/investor/funds/dataRoomApi';

const mapStateToProps = state => ({
  editor: !state.currentUser.investorGuest,
  dataRoom: state.dataRoom,
  currentUser: state.currentUser,
  blockFileDownload: state.currentUser ? state.currentUser.blockFileDownload : false,
  blockCreation: state.currentUser ? state.currentUser.blockCreation : false,
  statsLink: state.dataRoom.stats_link,
});

const mapDispatchToProps = {
  createSection,
  renameSection,
  deleteSection,
  updateSectionPosition,
  createFile,
  moveFile,
  updateFile,
  updateFilePosition,
  deleteFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataRoom);
