const setFunding = funding => ({
  type: 'SET_FUNDING',
  funding,
});

// Funding fields actions
const overwriteFundingFields = fields => ({
  type: 'OVERWRITE_FUNDING_FIELDS',
  fields,
});

const updateFundingFields = fields => ({
  type: 'UPDATE_FUNDING_FIELDS',
  fields,
});

const resetFundingFields = ids => ({
  type: 'RESET_FUNDING_FIELDS',
  ids,
});

// Funding share actions
const setFundingShare = share => ({
  type: 'SET_FUNDING_SHARE',
  share,
});

const addFundingShare = share => ({
  type: 'ADD_FUNDING_SHARE',
  share,
});

const removeFundingShare = share => ({
  type: 'REMOVE_FUNDING_SHARE',
  share,
});

const removeFundingShares = () => ({
  type: 'REMOVE_FUNDING_SHARES',
});

// Funding round actions
const setFundingRound = round => ({
  type: 'SET_FUNDING_ROUND',
  round,
});

const addFundingRound = round => ({
  type: 'ADD_FUNDING_ROUND',
  round,
});

const removeFundingRound = round => ({
  type: 'REMOVE_FUNDING_ROUND',
  round,
});

const removeFundingRounds = () => ({
  type: 'REMOVE_FUNDING_ROUNDS',
});

const applyCompanyData = (data) => ({
  type: 'APPLY_COMPANY_DATA',
  data,
});

const applyTotals = (data) => ({
  type: 'APPLY_TOTALS',
  data,
});

const setLoading = (target, isLoading) => ({
  type: 'SET_lOADING',
  target,
  isLoading,
});

const updateShareClass = (data, id = null) => ({
  type: 'UPDATE_SHARE_CLASS',
  data,
  id,
});

const removeShareClass = (data, id) => ({
  type: 'REMOVE_SHARE_CLASS',
  data,
  id,
});

const updateConvertible = (data, id = null) => ({
  type: 'UPDATE_CONVERTIBLE',
  data,
  id,
});

const removeConvertible = (data, id) => ({
  type: 'REMOVE_CONVERTIBLE',
  data,
  id,
});


export {
  setFunding,

  overwriteFundingFields,
  updateFundingFields,
  resetFundingFields,

  setFundingShare,
  addFundingShare,
  removeFundingShare,
  removeFundingShares,

  setFundingRound,
  addFundingRound,
  removeFundingRound,
  removeFundingRounds,

  applyCompanyData,
  applyTotals,
  setLoading,

  updateShareClass,
  removeShareClass,
  updateConvertible,
  removeConvertible,
};
