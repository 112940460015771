import objectAssign from 'object-assign';
const getIndexOfTarget = (state, metric) => state.getIn(['core_kpis', 'kpis']).findKey(kpi => kpi.get('name') === metric);
const fromJS = data => Immutable.fromJS(data);

export default (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_VALUE': {
      return fromJS(action.data)
        .set('runway_chart_opened', state.get('runway_chart_opened'))
        .set('show_estimate', state.get('show_estimate'))
        .set('refresh_all_link', state.get('refresh_all_link'));
    }
    case 'SAVING_STATUS': {
      return (
        state.setIn(['core_kpis', 'kpis', getIndexOfTarget(state, action.metric), 'value'], fromJS(action.value))
          .set('saveStatus', 'Saving...')
      );
    }
    case 'SAVED_STATUS': {
      return state.set('saveStatus', 'Saved');
    }
    case 'HANDLE_RUNWAY_CHART_TAB': {
      return state.set('runway_chart_opened', !state.get('runway_chart_opened'))
    }
    default: return state;
  }
};
