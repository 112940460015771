import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CapTableHeader from './CapTableHeader';
import Shareholders from './Shareholders';
import ShareClasses from './ShareClasses';
import Convertibles from './Convertibles';
import ShareholderModal from '../company/cap_tables/ShareholderModal';
import ShareClassModal from '../company/cap_tables/ShareClassModal';
import ConvertibleModal from '../company/cap_tables/ConvertibleModal';

import DropdownMenu from '../DropdownMenu';
import Button from '../Button';

import chartOptions from '../utils/highcharts/pieChartOptions';


class CapTable extends Component {
  static propTypes = {
    editor: PropTypes.bool.isRequired,
    capTable: PropTypes.object,
    company: PropTypes.object,
    currentUser: PropTypes.object,
    updateRound: PropTypes.func,
    createShare: PropTypes.func,
    updateShare: PropTypes.func,
    deleteShare: PropTypes.func,
    createShareClass: PropTypes.func,
    updateShareClass: PropTypes.func,
    deleteShareClass: PropTypes.func,
    createConvertible: PropTypes.func,
    updateConvertible: PropTypes.func,
    deleteConvertible: PropTypes.func,
  }

  static defaultProps = {
    capTable: null,
    company: null,
    currentUser: null,
    updateRound: null,
    createShare: null,
    updateShare: null,
    deleteShare: null,
    createShareClass: null,
    updateShareClass: null,
    deleteShareClass: null,
    createConvertible: null,
    updateConvertible: null,
    deleteConvertible: null,
  }

  state = {
    showOverview: true,
    showList: true,
    edit: false,
    showShareholderModal: false,
    showShareClassModal: false,
    showConvertibleModal: false,
    viewType: 'shareholders',
    shareholderId: null,
    shareClassId: null,
    convertibleId: null,
  }

  toggleModal = (modal) => this.setState(state => ({ [modal]: !state[modal] }));

  chartOptions = () => {
    const { capTable } = this.props;
    const { viewType } = this.state;

    const data = viewType === 'shareholders' ? capTable.data.shareholders_overview.chart_data : capTable.data.share_class_overview.chart_data;

    return chartOptions(data);
  }

  actionsSection = (type) => {
    const { editor } = this.props;
    const { edit, showList, viewType } = this.state;

    return (
      <div className="border-bottom border-gallery">
        <div className="px3 sm-px1 flex flex-justify-between items-center" style={{ minHeight: '48px' }}>
          <span className="fw400">{type === 'shareholders' ? 'Shareholders' : 'Share Classes'}</span>
          {editor &&
            <div className="flex items-center hide-print">
              <div className="btn-outline py05 border border-alto rounded text-gray bg-white flex items-center cursor-pointer mr1 sm-hide">
                <DropdownMenu
                  text="Add"
                  textColor="gray"
                  textSize="h5"
                  faIcon="plus"
                  margin="mr1"
                  height="py05"
                  setFalseWhenClickingInside
                >
                  <li>
                    <div
                      className="px2 hover hover-bg-wild-sand hover-text-blue text-gray fw400 cursor-pointer h5 lh-36"
                      onClick={() => this.toggleModal('showShareholderModal')}
                    >
                      <span>New Shareholder</span>
                    </div>
                  </li>
                  <li>
                    <div
                      className="px2 hover hover-bg-wild-sand hover-text-blue text-gray fw400 cursor-pointer h5 lh-36"
                      onClick={() => this.toggleModal('showShareClassModal')}
                    >
                      <span>New Share Class</span>
                    </div>
                  </li>
                </DropdownMenu>
              </div>
              {viewType === 'shareholders' &&
                <div className="sm-hide">
                  <Button
                    classNames="btn btn-outline btn-small border-alto bg-white text-gray sm-hide"
                    onClick={() => this.setState(state => ({ edit: !state.edit }))}
                  >
                    <i className="fa fa-pencil h5" />
                    {edit ?
                      <span className="ml1 h5">View</span>
                      :
                      <span className="ml1 h5">Edit All</span>
                  }
                  </Button>
                </div>
              }

              <i
                className={`fa fa-chevron-${showList ? 'down' : 'right'} bold h6 cursor-pointer ml2 sm-ml1`}
                onClick={() => this.setState({ showList: !showList })}
              />
            </div>
          }
        </div>
      </div>
    );
  }

  render() {
    const {
      editor, capTable, company, currentUser, updateRound, createShare, updateShare,
      deleteShare, createShareClass, updateShareClass, deleteShareClass, createConvertible,
      updateConvertible, deleteConvertible,
    } = this.props;

    const {
      showOverview,
      showList,
      edit,
      showShareholderModal,
      showShareClassModal,
      showConvertibleModal,
      viewType,
      shareholderId,
      shareClassId,
      convertibleId,
    } = this.state;

    const noteNumber = (elementId, type = 'shareholders') => {
      const scope = type === 'shareholders' ? capTable.data.notes : capTable.data.notes_for_convertibles;
      const elementNote = scope.find(note => note.element_id === elementId);
      const index = scope.indexOf(elementNote);
      return index === -1 ? '' : index + 1;
    };

    const share = (shareholderId && showShareholderModal) ? capTable.data.shareholders.find(sh => sh.id === shareholderId) : null;
    const shareClass = (shareClassId && showShareClassModal) ? capTable.data.share_classes.find(sc => sc.id === shareClassId) : null;
    const convertible = (convertibleId && showConvertibleModal) ? capTable.data.convertibles.find(c => c.id === convertibleId) : null;

    return (
      <React.Fragment>
        <CapTableHeader
          editor={editor}
          company={company}
          capTable={capTable.data}
          capTableHeader={capTable.header}
          viewType={viewType}
          setViewType={(type) => this.setState({ viewType: type })}
          updateRound={updateRound}
          canExport={currentUser ? (currentUser.investor ? currentUser.investor.canExport : false) : true}
          maxInvestments={currentUser ? (currentUser.investor ? currentUser.investor.max_investments : null) : null}
        />

        {capTable.data && viewType === 'shareholders' &&
          <Shareholders
            editor={editor}
            capTable={capTable}
            showOverview={showOverview}
            edit={edit}
            showList={showList}
            toggleOverview={() => this.setState({ showOverview: !showOverview })}
            noteNumber={noteNumber}
            actionsSection={() => this.actionsSection('shareholders')}
            chartOptions={this.chartOptions()}
            toogleModal={() => this.toggleModal('showShareholderModal')}
            updateShare={updateShare}
            updateEditingShareholderId={(id) => this.setState({ shareholderId: id })}
            deleteShare={deleteShare}
            company={company}
          />
        }

        {capTable.data && viewType === 'share_class' &&
          <ShareClasses
            editor={editor}
            capTable={capTable}
            company={company}
            showOverview={showOverview}
            edit={edit}
            showList={showList}
            toggleOverview={() => this.setState({ showOverview: !showOverview })}
            actionsSection={() => this.actionsSection('share_classes')}
            chartOptions={this.chartOptions()}
            toogleModal={() => this.toggleModal('showShareClassModal')}
            updateEditingShareClassId={(id) => this.setState({ shareClassId: id })}
            deleteShareClass={deleteShareClass}
          />
        }

        {capTable.data &&
          <Convertibles
            editor={editor}
            capTable={capTable}
            noteNumber={noteNumber}
            toogleModal={() => this.toggleModal('showConvertibleModal')}
            updateEditingConvertibleId={(id) => this.setState({ convertibleId: id })}
            deleteConvertible={deleteConvertible}
          />
        }

        {capTable.data && editor &&
          <React.Fragment>
            <ShareholderModal
              share={share && share.values}
              isInvited={share && share.invited}
              show={showShareholderModal}
              closeModal={() => { this.toggleModal('showShareholderModal'); this.setState({ shareholderId: null }); }}
              openShareClassModal={() => this.toggleModal('showShareClassModal')}
              capTable={capTable.data}
              company={company}
              createShare={(params) => createShare(params)}
              updateShare={(params) => updateShare(shareholderId, params)}
            />

            <ShareClassModal
              shareClass={shareClass && shareClass.values}
              show={showShareClassModal}
              closeModal={() => { this.toggleModal('showShareClassModal'); this.setState({ shareClassId: null }); }}
              capTable={capTable.data}
              company={company}
              createShareClass={(params) => createShareClass(params)}
              updateShareClass={(params) => updateShareClass(shareClassId, params)}
            />

            <ConvertibleModal
              convertible={convertible && convertible.values}
              show={showConvertibleModal}
              closeModal={() => { this.toggleModal('showConvertibleModal'); this.setState({ convertibleId: null }); }}
              capTable={capTable.data}
              company={company}
              createConvertible={createConvertible}
              updateConvertible={(params) => updateConvertible(convertibleId, params)}
            />
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

export default CapTable;
