import { connect } from 'react-redux';
import { subscribe, unsubscribe, resubscribe } from '../../api/investor/investorPaymentApi';
import Billing from '../../components/investor/billing/Billing';

const mapStateToProps = state => ({
  investor: state.investor,
  stripe: state.stripe,
});

const mapDispatchToProps = {
  subscribe,
  unsubscribe,
  resubscribe,
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
