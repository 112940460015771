import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../TextInput';
import Avatar from '../tab_view/Avatar';


class CrunchSearch extends Component {
  static propTypes = {
    currentResult: PropTypes.object,
    url: PropTypes.string,
    nextUrl: PropTypes.string,
    prevUrl: PropTypes.string,
    onChange: PropTypes.func,
    displayContinueButton: PropTypes.bool,
    showDescription: PropTypes.bool,
    showCompaniesFromInvestory: PropTypes.bool,
    customInvestmentParams: PropTypes.bool,
  }

  static defaultProps = {
    currentResult: null,
    url: null,
    nextUrl: null,
    prevUrl: null,
    onChange: null,
    displayContinueButton: true,
    showDescription: false,
    showCompaniesFromInvestory: false,
    customInvestmentParams: false,
  }

  constructor(props) {
    super(props);

    const { currentResult } = props;

    this.state = {
      currentResult,
      searchQuery: currentResult ? currentResult.attributes.name : '',
      companiesFromInvestory: [],
      companiesFromCrunchBase: [],
      loading: false,
      none: false,
      companyAttributes: null,
      showDropdown: false,
      hasValue: false,
    };
  }

  componentWillMount() {
    this.search = _.debounce(this.search, 500);
  }

  setCurrentResult = (result) => {
    const { onChange } = this.props;
    this.setState(
      {
        currentResult: result,
        searchQuery: result.attributes.name,
        companiesFromCrunchBase: [],
        companiesFromInvestory: [],
        showDropdown: false,
      },
    );

    onChange(result);
  }

  setCompanyAttributes = (result) => {
    this.setState(
      {
        companyAttributes: result.attributes,
        searchQuery: result.attributes.name,
        companiesFromCrunchBase: [],
        companiesFromInvestory: [],
        showDropdown: false,
        hasValue: true,
      },
    );
  }

  displayContinue = () => {
    const { prevUrl, nextUrl } = this.props;
    const { searchQuery } = this.state;

    if (searchQuery.length > 1) {
      return (
        <div className="flex col-12">
          <div className="flex col-6 flex-justify-start">
            <a className="rounded bg-white border border-light-gray text-light-gray py1 px3 center mr2" href={`${prevUrl}`}>
              Back
            </a>
          </div>
          <div className="flex col-6 flex-justify-end">
            <a className="rounded bg-blue text-white py1 px3 center" href={`${nextUrl}?continue=true&name=${searchQuery}`}>
              Continue
            </a>
          </div>
        </div>
      );
    }
    return (
      <div className="flex col-12">
        <div className="flex col-6 flex-justify-start">
          <a className="flex flex-justify-start rounded bg-white border border-light-gray text-light-gray py1 px3 center mr2" href={`${prevUrl}`}>
            Back
          </a>
        </div>
        <div className="flex col-6 flex-justify-end">
          <div className="flex flex-justify-end rounded bg-light-gray text-white py1 px3 center">
            Continue
          </div>
        </div>
      </div>
    );
  }

  displayLoading = () => {
    const { loading, none, searchQuery } = this.state;

    if (searchQuery.replace(/^[ ]+|$/g, '').length < 3) return null;

    if (loading) {
      return (
        <div className="text-light-gray p2 border-top border-wild-sand italic">
          <i className="fa fa-refresh fa-spin fa-fw mr1" />
          Looking for your company...
        </div>
      );
    }
    if (none) {
      return (
        <div className="text-light-gray p2 border-top border-wild-sand italic">
          No company found with this name :(
        </div>
      );
    }
    return null;
  }

  search = (query) => {
    const {
      url,
      showCompaniesFromInvestory,
    } = this.props;

    if (query.searchQuery.replace(/^[ ]+|$/g, '').length < 3) return;

    this.setState({
      searchQuery: query.searchQuery,
      loading: true,
      showDropdown: true,
    });

    const urlWithParams = `${url}?with_existing_companies=${showCompaniesFromInvestory}`;

    App.Api.get(urlWithParams, query).then(
      (data) => {
        if (data.length === 0) {
          this.setState({ none: true, loading: false });
        } else {
          this.setState({ none: false, loading: false });
        }
        this.setState({
          companiesFromCrunchBase: data.companies_from_crunch_base,
          companiesFromInvestory: data.companies_from_investory,
        });
      },
      () => App.State.setFlash({ name: 'alert', msg: 'Sorry, an error happened with the search.' }),
    );
  }

  renderView = () => {
    const { showDescription, showCompaniesFromInvestory, customInvestmentParams } = this.props;
    const {
      companiesFromCrunchBase,
      companiesFromInvestory,
      showDropdown,
      searchQuery,
    } = this.state;

    const newInvestment = { new_custom_investment: true, attributes: { id: 'new_investment', name: searchQuery } };

    if (searchQuery.replace(/^[ ]+|$/g, '').length < 3) {
      return null;
    }

    if (!showDropdown) return null;

    const showCompanies = (companies, addNewCompany = false) => (
      companies.map((item) => (
        <div key={item.attributes.id} className={`col-12 flex flex-center p1 sm-px0 fw400 ${addNewCompany ? '' : 'hover hover-bg-wild-sand border-top border-wild-sand'}`}>
          <div
            className={`col-12 sm-col-7 text-gray cursor-pointer ${addNewCompany ? 'bg-wild-sand border border-gallery rounded hover hover-bs-lighter-gray' : ''}`}
            onClick={showDescription ? () => this.setCurrentResult(item) : () => this.setCompanyAttributes(item)}
          >
            <div className={`ml1 text-gray flex items-center h5 ${addNewCompany ? 'flex-justify-center' : ''}`}>
              {addNewCompany ?
                (showCompaniesFromInvestory && customInvestmentParams ?
                  <span style={{ marginTop: '5px', marginBottom: '5px' }}>If none of the above is what you were looking for, add <b>{item.attributes.name}</b> to Investory, to manage their data yourself</span>
                  :
                  <span style={{ marginTop: '5px', marginBottom: '5px' }}>Add <b>{item.attributes.name}</b> to Investory</span>
                )
                :
                  <>
                    <span
                      className="block col-1 bg-center bg-cover bg-no-repeat circle header__avatar--dash mr1"
                      style={{ backgroundImage: `url( ${item.attributes.avatar || window.images.userBlue} )` }}
                    />
                    <div className="truncate text-gray col-11 left-align">{item.attributes.name}</div>
                  </>
              }
            </div>
          </div>
        </div>
      ))
    );

    return (
      <div className="flex flex-column col-12">
        {companiesFromInvestory.length > 0 &&
          <div className="flex flex-column">
            <div className="center bg-wild-sand">
              <span className="fw400 h5">Companies already in investory - if you choose one of these, they will get invited to connect with you on the platform</span>
            </div>
            {showCompaniesFromInvestory && showCompanies(companiesFromInvestory)}
          </div>
        }
        {companiesFromCrunchBase.length > 0 &&
          <div className="flex flex-column">
            {customInvestmentParams &&
              <div className="center bg-wild-sand">
                <span className="fw400 h5">Companies in crunchbase - if you choose one of these, they will not get an invite. <br /> You can manage their data yourself, and invite them later if you choose so </span>
              </div>
            }
            {showCompanies(companiesFromCrunchBase)}
          </div>
        }

        <div className="flex flex-column">
          {showCompanies([newInvestment], true)}
        </div>
      </div>
    );
  }

  render() {
    const { displayContinueButton, showDescription } = this.props;
    const { currentResult, companyAttributes, searchQuery, hasValue, loading } = this.state;

    return (
      <div className="">
        <div className="flex flex-center col-12 flex-column bg-white bs-around-small mb2 sm-mb3">
          <TextInput
            className="border-none col-12"
            onChange={(e) => { this.search({ searchQuery: e.target.value }); this.setState({ hasValue: false }); }}
            placeholder="Type in the name of your company"
            value={searchQuery}
            hasValue={hasValue}
          />
          {this.displayLoading()}
          {!loading && this.renderView()}

          {companyAttributes && Object.keys(companyAttributes).map(attribute => (
            <input key={attribute} type="hidden" name={`company[${attribute}]`} value={companyAttributes[attribute]} />
          ))}
        </div>
        {
          showDescription && (
          <>
            <div className="mb2">
              <div className="mt2 mb1">
                <span className="h5 text-gray fw400">
                  Selected Company
                </span>
              </div>
            </div>

            {currentResult ?
              <div className="flex col-12 flex-column bg-white sm-mb4 my2">
                <div className="flex flex-justify-start">
                  <Avatar
                    avatarUrl={currentResult.attributes.avatar || window.images.userGray}
                    size="50px"
                  />
                  <div className="flex flex-column ml1">
                    <div className="truncate text-gray left-align"><span className="fw400">{currentResult.attributes.name}</span> <div className="truncate text-gray left-align">{currentResult.attributes.url}</div></div>
                    <div className="text-gray left-align">
                      {currentResult.new_custom_investment ? 'This company will be added to your portfolio.' : currentResult.attributes.about}
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className="py2 border-top border-bottom border-lighter-gray flex items-center flex-justify-center">
                <span className="text-light-gray h5">No Company selected. Try searching for one above.</span>
              </div>
            }
          </>
          )}
        {displayContinueButton && this.displayContinue()}
      </div>
    );
  }
}

export default CrunchSearch;
