import { connect } from 'react-redux';
import Funding from '../../../components/investor/investments/funding';

import {
  updateFunding,
  createOrUpdateFundingFields,
  deleteFundingFields,
  createFundingShare,
  updateFundingShare,
  deleteFundingShare,
  deleteFundingShares,
  createFundingRound,
  updateFundingRound,
  deleteFundingRound,
  deleteFundingRounds,
  createFundingShareClass,
  updateFundingShareClass,
  deleteFundingShareClass,
  createFundingConvertible,
  updateFundingConvertible,
  deleteFundingConvertible,
  switchFundraisingData,
  copyDataFromCompany,
  updateFundingTotals,
  setFundlingLoading,
} from '../../../api/investor/investment/fundingApi';

import { switchCapTable } from '../../../api/investor/investment/capTableApi';

const mapStateToProps = state => ({
  header: state.investmentHeader,
  funding: state.funding,
  capTable: state.capTable,
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  updateFunding,
  createOrUpdateFundingFields,
  deleteFundingFields,
  createFundingShare,
  updateFundingShare,
  deleteFundingShare,
  deleteFundingShares,
  createFundingRound,
  updateFundingRound,
  deleteFundingRound,
  deleteFundingRounds,
  copyDataFromCompany,
  createFundingShareClass,
  updateFundingShareClass,
  deleteFundingShareClass,
  createFundingConvertible,
  updateFundingConvertible,
  deleteFundingConvertible,
  switchCapTable,
  switchFundraisingData,
  updateFundingTotals,
  setFundlingLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Funding);
