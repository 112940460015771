import axios from 'axios';

import {
  updateDealRoom,
  updateDealTerm,
  addDealCommitment,
  updateDealCommitment,
  removeDealCommitment,
} from '../../actions/company/dealRoomActions';

const saveDealInfo = (params) =>
  async (dispatch, getState) => {
    const { dealRoom: { id }, company: { id: companyId } } = getState();
    const res = await axios.patch(`/company/${companyId}/deal_rooms/${id}`, params);
    dispatch(updateDealRoom(res.data));
  };

const saveDealTerm = (termId, params) =>
  async (dispatch, getState) => {
    const { dealRoom: { id: dealRoomId }, company: { id: companyId } } = getState();
    let res;
    if (termId) {
      res = await axios.patch(`/company/${companyId}/deal_rooms/${dealRoomId}/deal_room_term`, params);
    } else {
      res = await axios.post(`/company/${companyId}/deal_rooms/${dealRoomId}/deal_room_term`, params);
    }
    dispatch(updateDealTerm(res.data));
  };

const createDealCommitment = (params) =>
  async (dispatch, getState) => {
    const { dealRoom: { id: dealRoomId }, company: { id: companyId } } = getState();
    const res = await axios.post(`/company/${companyId}/deal_rooms/${dealRoomId}/deal_room_commitments`, params);
    dispatch(addDealCommitment(res.data));
  };

const saveDealCommitment = (id, params) =>
  async (dispatch, getState) => {
    const { dealRoom: { id: dealRoomId }, company: { id: companyId } } = getState();
    const res = await axios.patch(`/company/${companyId}/deal_rooms/${dealRoomId}/deal_room_commitments/${id}`, params);
    dispatch(updateDealCommitment(res.data));
  };

const deleteDealCommitment = (id) =>
  async (dispatch, getState) => {
    const { dealRoom: { id: dealRoomId }, company: { id: companyId } } = getState();
    await axios.delete(`/company/${companyId}/deal_rooms/${dealRoomId}/deal_room_commitments/${id}`);
    dispatch(removeDealCommitment(id));
  };

export {
  saveDealInfo,
  saveDealTerm,
  createDealCommitment,
  saveDealCommitment,
  deleteDealCommitment,
};
