import React, { Component } from 'react';
import PropTypes from 'prop-types';


class InvestorUpdateGroup extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    activeTab: PropTypes.string,
  }

  displayExcel = () => {
    const { activeTab, data } = this.props;

    if (activeTab === 'monthly') {
      return (
        <div className="flex flex-auto flex-justify-end">
          <a className="text-light-gray mr3" href={data.excel_url} target="blank" data-balloon={`Export update status from ${data.display}.`} data-balloon-pos="left">
            <i className="fa fa-download" />
          </a>
        </div>
      );
    }
    return (
      null
    );
  }

  render() {
    const { data } = this.props;

    return (
      <div className="col-12 py2 bg-wild-sand h2 pl3 border-top border-alto text-light-gray flex flex-center" style={{ height: '50px' }}>
        {data.display}
        {this.displayExcel()}
      </div>
    );
  }
}

export default InvestorUpdateGroup;
