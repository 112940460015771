import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import Modal from '../../../../Modal';
import TextInput from '../../../../TextInput';
import Select from '../../../../Select';
import CheckBox from '../../../../CheckBox';

import DerivedKpiOptions from '../DerivedKpiOptions';

class AddInvestmentKpiModal extends Component {
  static propTypes = {
    investment: PropTypes.object.isRequired,
    investmentKpis: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    derivedKpiDefinition: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
    createKpiField: PropTypes.func.isRequired,
    updateKpiField: PropTypes.func.isRequired,
    refreshKpisWithCurrentDate: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    allowKpiAllInvestments: PropTypes.bool.isRequired,
    canManageDerivedKpis: PropTypes.bool.isRequired,
    canCreateDerivedKpis: PropTypes.bool.isRequired,
    unitOptions: PropTypes.array.isRequired,
    type: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    type: 'add',
    disabled: false,
    derivedKpiDefinition: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      metric: null,
      unit: null,
      kpi_id: null,
      saving: false,
      addToAllInvestments: false,
      kpiType: '',
      // derived KPI properties
      firstKpiIdState: null,
      secondKpiIdState: null,
      kpiOperationState: 'sum',
      timeShiftState: 'none',
      accumulatorPeriodState: 'year',
      accumulatorOperationState: 'sum',
      accumulatorOperationOptionsFiltered: props.investmentKpis.accumulator_operation_options.filter(option => option.value !== 'none'),
    };

    this.initialState = this.state;
  }

  componentDidUpdate() {
    const { derivedKpiDefinition } = this.props;
    const { kpiType } = this.state;

    if (derivedKpiDefinition) {
      if (kpiType !== 'derived') {
        this.onUpdate(() => {
          this.setState({
            kpiType: 'derived',
            kpi_id: derivedKpiDefinition.kpi_source_id,
            metric: derivedKpiDefinition.metric,
            unit: derivedKpiDefinition.unit,
            firstKpiIdState: derivedKpiDefinition.first_kpi_id,
            secondKpiIdState: derivedKpiDefinition.second_kpi_id,
            kpiOperationState: derivedKpiDefinition.kpi_operation,
            accumulatorPeriodState: derivedKpiDefinition.accumulator_period,
            timeShiftState: derivedKpiDefinition.time_shift,
            accumulatorOperationState: derivedKpiDefinition.accumulator_operation,
          }, () => this.filterAccumulatorOperationOptions());
        });
      }
    }
  }

  onUpdate = (callback) => {
    callback();
  }

  submit = () => {
    const { type, createKpiField, updateKpiField, investment, closeModal, refreshKpisWithCurrentDate } = this.props;
    const {
      kpi_id: kpiId,
      metric,
      unit,
      firstKpiIdState,
      secondKpiIdState,
      kpiOperationState,
      timeShiftState,
      accumulatorPeriodState,
      accumulatorOperationState,
      addToAllInvestments,
      kpiType,
    } = this.state;

    this.setState({ saving: true });

    if (metric === null || (unit === null && ['custom', 'derived'].includes(kpiType))) {
      const errorMsgs = { custom: 'Please add a metric and a unit.', company: 'Please select a KPI.', derived: 'Please add a metric, a unit and KPI source(s).' };
      App.State.setFlash({ name: 'alert', msg: errorMsgs[kpiType] });
      this.setState({ saving: false });
      return;
    }

    const kpiFieldsParams = {
      kpi_field: {
        kpi_id: kpiId,
        metric,
        unit,
        company_data: kpiType === 'company',
        derived_kpi_attributes: {
          first_kpi_id: ['Zero', 'One'].includes(firstKpiIdState) ? null : firstKpiIdState,
          first_kpi_type: ['Zero', 'One'].includes(firstKpiIdState) ? firstKpiIdState : null,
          second_kpi_id: ['Zero', 'One'].includes(secondKpiIdState) ? null : secondKpiIdState,
          second_kpi_type: ['Zero', 'One'].includes(secondKpiIdState) ? secondKpiIdState : null,
          kpi_operation: kpiOperationState,
          time_shift: timeShiftState,
          accumulator_period: accumulatorPeriodState,
          accumulator_operation: accumulatorOperationState,
        },
      },
      add_to_all_investments: addToAllInvestments,
      is_derived_kpi: kpiType === 'derived',
    };

    if (type === 'add') {
      createKpiField(investment.investor.id, investment.id, kpiFieldsParams).then(
        () => {
          this.setState(this.initialState);
          refreshKpisWithCurrentDate();
          closeModal();
        },
        (error) => {
          const errorMessage = error.response ? (error.response.data.error || error.response.data.errors) : 'An error prevented this KPI from being created.';
          App.State.setFlash({ name: 'alert', msg: errorMessage });
          this.setState({ saving: false });
        },
      );
    } else if (type === 'edit') {
      updateKpiField(kpiId, kpiFieldsParams).then(
        () => {
          this.closeKpiModal();
        },
        (error) => {
          const errorMessage = error.response ? (error.response.data.error || error.response.data.errors) : 'An error prevented this KPI from being created.';
          App.State.setFlash({ name: 'alert', msg: errorMessage });
          this.setState({ saving: false });
        },
      );
    }
  }

  // In the Acummulator operation, add an option of “none”.
  // However, need to validate that it can only be “none” if cumm-period is month.
  // Otherwise it must be informed
  filterAccumulatorOperationOptions = () => {
    const { investmentKpis } = this.props;
    const { accumulatorPeriodState } = this.state;

    const optionNone = investmentKpis.accumulator_operation_options.filter(option => option.value === 'none');

    if (accumulatorPeriodState === 'month') {
      this.setState({ accumulatorOperationState: 'none', accumulatorOperationOptionsFiltered: optionNone });
      return;
    }

    this.setState({ accumulatorOperationState: 'sum', accumulatorOperationOptionsFiltered: investmentKpis.accumulator_operation_options.filter(option => option.value !== 'none') });
  }

  closeKpiModal = async () => {
    const { closeModal } = this.props;

    await closeModal();
    this.setState(this.initialState);
  }

  render() {
    const { type, disabled, show, investment, investmentKpis, options, unitOptions, allowKpiAllInvestments, canManageDerivedKpis, canCreateDerivedKpis } = this.props;
    const {
      saving,
      metric,
      unit,
      kpiType,
      firstKpiIdState,
      secondKpiIdState,
      kpiOperationState,
      timeShiftState,
      accumulatorPeriodState,
      accumulatorOperationState,
      accumulatorOperationOptionsFiltered,
      addToAllInvestments,
    } = this.state;

    const accessToCompanyKpis = investmentKpis.can_access_kpis && !investment.custom_investment;

    const typeOptions = [
      { value: 'custom', label: 'Add custom KPI' },
      { value: 'company', label: 'Add company KPI' },
      { value: 'derived', label: `Add derived KPI ${canManageDerivedKpis ? '(Beta)' : '(blocked for free account)'}`, isDisabled: !canManageDerivedKpis },
    ].filter(opt => (accessToCompanyKpis ? true : opt.value !== 'company'))
      .filter(opt => (canCreateDerivedKpis ? true : opt.value !== 'derived'));

    let title;
    if (type === 'edit') {
      title = 'Edit KPI';
    } else if (type === 'view') {
      title = 'View KPI definition';
    } else {
      title = 'Add KPI';
    }

    return (
      <Modal
        show={show}
        onClickOutside={this.closeKpiModal}
      >
        <div className="flex flex-justify-between items-center p2 border-bottom border-lighter-gray">
          <div className="h3 text-gray fw400">{title}</div>
          <i className="fa fa-close h5 cursor-pointer" onClick={this.closeKpiModal} />
        </div>

        <div className="flex flex-column items-center p2 mt2">
          {type === 'add' &&
            <div className="col-8 flex flex-column items-center">
              <div className="col-12 mb1 fw400">
                <span>Select Type of KPI to be added</span>
              </div>
              <ReactSelect
                className="col-12"
                name="Custom KPI"
                options={typeOptions}
                placeholder="Select KPI type..."
                onChange={selectedOption => this.setState({ kpiType: selectedOption.value, metric: null, unit: null, kpi_id: null })}
                isClearable={false}
              />
              <div className="mt1">
                <span className="text-light-gray h5 light">
                  <ul className="pl2 list-style-disc">
                    <li className="lh-12">Custom KPIs are KPIs that you manage yourself and are not visible/accessible by companies.</li>
                    {accessToCompanyKpis &&
                      <li className="lh-12 my1">Company KPIs were created/managed by the Company but you can overwrite the values later.</li>
                    }
                    <li className="lh-12">Derived KPIs are KPIs that are created from other ones and are not visible/accessible by companies.</li>
                  </ul>
                </span>
              </div>
            </div>
          }

          {/* Custom KPI properties */}
          {kpiType === 'custom' &&
            <form className="col-8 flex flex-column items-center text-gray">
              <div className="col-12 mb1 fw400">
                <span>Specify Custom KPI</span>
              </div>
              <TextInput
                name="name"
                value={metric || undefined}
                className="col-12 border border-lighter-gray hover hover-border-blue mb2"
                placeholder="Metric name"
                onChange={e => this.setState({ metric: e.target.value })}
              />
              <Select
                className="col-12 text-gray lh-125 border border-lighter-gray bg-none mb2"
                style={{ height: '36px' }}
                value={unit || undefined}
                name="unit_select"
                placeholder="Choose appropriate units"
                options={unitOptions}
                onChange={e => this.setState({ unit: e.target.value })}
              />
              {allowKpiAllInvestments &&
                <div className="col-12 flex">
                  <CheckBox
                    defaultValue={addToAllInvestments}
                    fixedWidth
                    onChange={() => this.setState({ addToAllInvestments: !addToAllInvestments })}
                  />
                  <span className="text-light-gray ml1 h5">Add KPI to all investments</span>
                </div>
              }
            </form>
          }

          {/* Existing company's KPI properties */}
          {(kpiType === 'company' && investmentKpis.can_access_kpis) &&
            <div className="col-8">
              <div className="col-12 mb1 fw400">
                <span>Select a existing Company KPI below</span>
              </div>
              <ReactSelect
                className="col-12"
                value={options.filter(option => option.value === metric)}
                options={options}
                placeholder="Select..."
                onChange={selectedOption => this.setState({ kpi_id: selectedOption.id, metric: selectedOption.value, unit: selectedOption.unit })}
                isClearable={false}
              />
              <div className="lh-12">
                <span className="text-light-gray h5">Note: Existing KPIs can only be added once. If the list is empty, all KPIs created by the company have been added</span>
              </div>
            </div>
          }

          {/* Derived KPI properties */}
          {kpiType === 'derived' &&
            <DerivedKpiOptions
              metric={metric}
              unit={unit}
              firstKpiId={firstKpiIdState}
              secondKpiId={secondKpiIdState}
              kpiOperation={kpiOperationState}
              timeShift={timeShiftState}
              accumulatorPeriod={accumulatorPeriodState}
              accumulatorOperation={accumulatorOperationState}
              unitOptions={unitOptions}
              kpiOptions={investmentKpis.all_investment_kpis_options}
              kpiOperations={investmentKpis.kpi_operation_options}
              timeShiftOptions={investmentKpis.time_shift_options}
              accumulatorPeriodOptions={investmentKpis.accumulator_period_options}
              accumulatorOperationOptions={accumulatorOperationOptionsFiltered}
              addToAllInvestments={addToAllInvestments}
              allowKpiAllInvestments={allowKpiAllInvestments && type === 'add'}
              updateState={(state, value, setFilter = false) => this.setState({ [state]: value }, () => { if (setFilter) this.filterAccumulatorOperationOptions(); })}
              type={type}
            />
          }
        </div>

        <div className="flex flex-justify-end items-center p2 border-top border-lighter-gray">
          {disabled ?
            <div
              className="h5 text-blue mr2 cursor-pointer"
              onClick={this.closeKpiModal}
            >
              close
            </div>
            :
            <>
              <div
                className="h5 text-blue mr2 cursor-pointer"
                onClick={this.closeKpiModal}
              >
                Cancel
              </div>
              <div
                className="h5 p1 px2 cursor-pointer bg-blue text-white rounded"
                onClick={saving ? null : this.submit}
              >
                Save
              </div>
            </>
          }
        </div>
      </Modal>
    );
  }
}

export default AddInvestmentKpiModal;
