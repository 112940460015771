import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import ConfirmationModal from '../ConfirmationModal';
import Avatar from '../tab_view/Avatar';
import HtmlComponent from '../tab_view/HtmlComponent';

import SorterHeader from '../modules/SorterHeader';
import withSorting from '../utils/withSorting';

import Button from '../Button';

class Convertibles extends Component {
  static propTypes = {
    editor: PropTypes.bool.isRequired,
    capTable: PropTypes.object.isRequired,
    noteNumber: PropTypes.func.isRequired,
    sortedData: PropTypes.array.isRequired,
    toogleModal: PropTypes.func,
    updateEditingConvertibleId: PropTypes.func,
    deleteConvertible: PropTypes.func,
    marginTop: PropTypes.string,
  }

  static defaultProps = {
    updateEditingConvertibleId: null,
    deleteConvertible: null,
    toogleModal: null,
    marginTop: 'mt3',
  }

  state = {
    showList: true,
  }

  handleEdit = async (convertibleId) => {
    const { toogleModal, updateEditingConvertibleId } = this.props;

    await updateEditingConvertibleId(convertibleId);
    toogleModal();
  }

  handleDelete = async (convertibleId) => {
    const { deleteConvertible } = this.props;

    try {
      await deleteConvertible(convertibleId);
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
    }
  }

  render() {
    const {
      editor,
      capTable,
      noteNumber,
      sortedData,
      toogleModal,
      marginTop,
    } = this.props;

    const { showList } = this.state;

    return (
      <React.Fragment>
        {/* Convertibles list */}
        <div className={`bs-around bg-white ${marginTop}`}>
          <div className="border-bottom border-gallery">
            <div className="px3 sm-px1 flex flex-justify-between items-center" style={{ minHeight: '48px' }}>
              <span className="fw400">Convertibles</span>
              {editor &&
                <div className="flex items-center hide-print">
                  <div className="sm-hide">
                    <Button
                      classNames="btn btn-outline btn-small border-alto bg-white text-gray sm-hide"
                      onClick={toogleModal}
                    >
                      <i className="fa fa-plus h5" />
                      <span className="ml1 h5">Add</span>
                    </Button>
                  </div>
                  <i
                    className={`fa fa-chevron-${showList ? 'down' : 'right'} bold h6 cursor-pointer ml2`}
                    onClick={() => this.setState({ showList: !showList })}
                  />
                </div>
              }
            </div>
          </div>

          <ReactCSSTransitionGroup transitionName="modal" transitionEnterTimeout={500} transitionLeaveTimeout={500}>
            {showList &&
              <React.Fragment>
                {(sortedData && sortedData.length > 0) ?
                  <div className="p3 sm-px2">
                    <div>
                      <div className="col-12 flex px2 py1 text-light-gray h5 fw400 flex center bg-extra-light-gray border-bottom border-gallery lh-12">
                        <div className="col-15p sm-col-6 flex flex-justify-start items-center">
                          <SorterHeader text="Name" field="name" />
                        </div>
                        <div className="col-15p sm-hide flex flex-justify-center items-center">
                          <span
                            data-balloon="The beneficiary of the convertible once it converts."
                            data-balloon-pos="up"
                            data-balloon-length="medium"
                          >
                            <SorterHeader text="Beneficiary" field="beneficiary_name" hideIcon />
                          </span>
                        </div>
                        <div className="col-875p sm-hide flex flex-justify-center items-center">
                          <span
                            data-balloon="The date on which the convertible contract was granted."
                            data-balloon-pos="up"
                            data-balloon-length="medium"
                          >
                            <SorterHeader text="Issue Date" field="issue_date" hideIcon />
                          </span>
                        </div>
                        <div className="col-875p sm-hide flex flex-justify-center items-center">
                          <span
                            data-balloon="The date on which the notes are to be repaid with interest."
                            data-balloon-pos="up"
                            data-balloon-length="medium"
                          >
                            <SorterHeader text="Maturity On" field="maturity_date" hideIcon />
                          </span>
                        </div>
                        <div className="col-875p sm-col-6 flex flex-justify-center items-center">
                          <span
                            data-balloon="The amount of money provided by the investor."
                            data-balloon-pos="up"
                            data-balloon-length="medium"
                          >
                            <SorterHeader text="Principal" field="principal" hideIcon />
                          </span>
                        </div>
                        <div className="col-875p sm-hide flex flex-justify-center items-center">
                          <span
                            data-balloon="The valuation cap is the maximum valuation at which a note will convert into equity."
                            data-balloon-pos="up"
                            data-balloon-length="medium"
                          >
                            <SorterHeader text="Cap" field="valuation" hideIcon />
                          </span>
                        </div>
                        <div className="col-875p sm-hide flex flex-justify-center items-center">
                          <span
                            data-balloon="The qualified financing amount (floor) is the minimum valuation at which a note will convert into equity."
                            data-balloon-pos="up"
                            data-balloon-length="medium"
                          >
                            <SorterHeader text="Floor" field="qualified_financing_amount" hideIcon />
                          </span>
                        </div>
                        <div className="col-875p sm-hide flex flex-justify-center items-center">
                          <span
                            data-balloon="The valuation discount in % the beneficiary receives relative to other investors in a subsequent financing round."
                            data-balloon-pos="up"
                            data-balloon-length="medium"
                          >
                            <SorterHeader text="Discount" field="discount" hideIcon />
                          </span>
                        </div>
                        <div className="col-875p sm-hide flex flex-justify-center items-center">
                          <span
                            data-balloon="The % at which the principal increases until it is matured."
                            data-balloon-pos="up"
                            data-balloon-length="medium"
                          >
                            <SorterHeader text="Interest" field="interest_rate" hideIcon />
                          </span>
                        </div>
                        <div className="col-875p sm-hide flex flex-justify-center items-center">
                          <span
                            data-balloon="The rate at which the interest % is applied to the principal."
                            data-balloon-pos="up"
                            data-balloon-length="medium"
                          >
                            <SorterHeader text="Accrual" field="interest_accrual" hideIcon />
                          </span>
                        </div>

                      </div>

                      <div className="flex flex-column h5">
                        {sortedData.map(convertible => (
                          <div key={convertible.id} className="flex border-bottom border-gallery px2 show-pencil-on-hover show-trash-on-hover hover hover-bg-wild-sand" style={{ minHeight: '58px' }}>
                            <div className="flex flex-auto col-12 items-center" style={{ marginRight: editor ? '1.7em' : '' }}>
                              <div
                                className={`col-15p flex items-center fw400 truncate sm-col-6 ${editor ? 'cursor-pointer' : ''}`}
                                onClick={editor ? () => this.handleEdit(convertible.id) : null}
                              >
                                {convertible.name}
                                <span className="h6 fw400 lh-15 text-medium-gray pb1" style={{ marginLeft: '4px' }}>{noteNumber(convertible.id, 'convertibles')}</span>
                              </div>
                              <div className="col-15p sm-hide flex items-center flex-justify-center">
                                {convertible.beneficiary_name &&
                                  <React.Fragment>
                                    <Avatar avatarUrl={convertible.beneficiary_avatar || window.images.userGray} classNames={convertible.beneficiary_avatar || 'opacity-50'} />
                                    <div className="flex flex-justify-start ml1 lh-12 truncate mr1">{convertible.beneficiary_name}</div>
                                  </React.Fragment>
                                }
                              </div>

                              <div className="col-875p sm-hide flex flex-justify-center items-center center">{convertible.issue_date || '&mdash;'}</div>
                              <div className="col-875p sm-hide flex flex-justify-center items-center center">{convertible.maturity_date || '&mdash;'}</div>
                              <div
                                className="col-875p sm-col-6 flex-column flex-justify-center items-center center"
                                data-balloon={convertible.principal.raw}
                                data-balloon-pos="bottom"
                                data-balloon-length="medium"
                              >
                                {convertible.principal.readable || '&mdash;'}
                                <span className="fw400 h6 text-gray show-print" style={{ display: 'none' }}>({convertible.principal.raw})</span>
                              </div>
                              <div
                                className="col-875p sm-hide flex-column flex-justify-center items-center center"
                                data-balloon={convertible.valuation.raw}
                                data-balloon-pos="bottom"
                                data-balloon-length="medium"
                              >
                                {convertible.valuation.readable || '&mdash;'}
                                <span className="fw400 h6 text-gray show-print" style={{ display: 'none' }}>({convertible.valuation.raw})</span>
                              </div>
                              <div
                                className="col-875p sm-hide flex-column flex-justify-center items-center center"
                                data-balloon={convertible.qualified_financing_amount.raw}
                                data-balloon-pos="bottom"
                                data-balloon-length="medium"
                              >
                                {convertible.qualified_financing_amount.readable || '&mdash;'}
                                <span className="fw400 h6 text-gray show-print" style={{ display: 'none' }}>({convertible.qualified_financing_amount.raw})</span>
                              </div>
                              <div
                                className="col-875p sm-hide flex-column flex-justify-center items-center center"
                                data-balloon={convertible.discount.raw}
                                data-balloon-pos="bottom"
                                data-balloon-length="medium"
                              >
                                {convertible.discount.readable || '&mdash;' } %
                                <span className="fw400 h6 text-gray show-print" style={{ display: 'none' }}>({convertible.discount.raw})</span>
                              </div>
                              <div
                                className="col-875p sm-hide flex-column flex-justify-center items-center center"
                                data-balloon={convertible.interest_rate.raw}
                                data-balloon-pos="bottom"
                                data-balloon-length="medium"
                              >
                                {convertible.interest_rate.readable || '&mdash;'} %
                                <span className="fw400 h6 text-gray show-print" style={{ display: 'none' }}>({convertible.interest_rate.raw})</span>
                              </div>
                              <div
                                className="col-875p sm-hide flex flex-justify-center items-center"
                              >
                                {convertible.interest_accrual || '&mdash;'}
                              </div>

                            </div>

                            {editor &&
                              <div className="flex flex-column flex-justify-center items-center mnr-25em">
                                <div
                                  className="cursor-pointer hover-text-blue"
                                  data-balloon="Edit Convertible"
                                  data-balloon-pos="right"
                                  data-balloon-length="small"
                                  onClick={() => this.handleEdit(convertible.id)}
                                >
                                  <i className="fa fa-pencil opacity-0" />
                                </div>

                                <div
                                  className="cursor-pointer"
                                  data-balloon="Delete Convertible"
                                  data-balloon-pos="right"
                                  data-balloon-length="small"
                                >
                                  <ConfirmationModal
                                    html={'<i class="fa fa-trash opacity-0" />'}
                                    className="hover-text-red"
                                    message="<b>You are about to permanently delete this Convertible. This cannot be undone.</b><br/><br/>Are you sure you want to delete?"
                                    onClick={() => this.handleDelete(convertible.id)}
                                  />
                                </div>
                              </div>
                            }
                          </div>
                        ))}

                        <div className="flex px2 py1 fw400 text-medium-gray mt1 items-center">
                          <div className="col-475p sm-col-6">
                            <div className="flex flex-justify-start pb1 pl2 mxn2">Total</div>
                          </div>
                          <div
                            className="col-875p sm-col-6 flex-column flex-justify-center center"
                            data-balloon={capTable.data.convertible_totals.principal.raw}
                            data-balloon-pos="bottom"
                            data-balloon-length="medium"
                          >
                            {capTable.data.convertible_totals.principal.readable}
                            <span className="fw400 h6 text-gray show-print" style={{ display: 'none' }}>({capTable.data.convertible_totals.principal.raw})</span>
                          </div>
                        </div>
                      </div>

                      {(capTable.data.notes_for_convertibles && capTable.data.notes_for_convertibles.length > 0) &&
                        <div className="text-alto mt2">
                          <div className="border-top border-gallery" style={{ width: '125px' }} />
                          {capTable.data.notes_for_convertibles.map((note, index) => (
                            <div key={note.element_id} className="flex pt1 text-medium-gray">
                              <sup className="h6 lh-15 fw400 mr1">{index + 1}</sup>
                              <HtmlComponent data={{ html: note.text }} />
                            </div>
                          ))}
                        </div>
                      }
                    </div>
                  </div>
                  :
                  <div className="flex flex-justify-center items-center text-medium-gray h5 p3">
                    No convertibles setup in this round.
                  </div>
                }
              </React.Fragment>
            }
          </ReactCSSTransitionGroup>
        </div>
      </React.Fragment>
    );
  }
}

export default withSorting(Convertibles, (props) => props.capTable.data && props.capTable.data.convertibles);
