// responseData is an array of hashes with the following arrangement:
// {
//   id: number
//   kpi_data: {} => which contains the template for each row in the KPI table (both Investor Home and Fund Dashboard)
// }
const structureKpiStateData = (responseData, tableData) => {
  const selectedData = responseData.find(data => data.id === tableData.id);
  const selectedKpiData = selectedData ? selectedData.kpi_data : null;
  // Substitute a chart data that already exists in the state. Or add the new custom KPI values to the state
  const updateKpiData = () => (
    selectedKpiData ?
      (tableData.kpis.find(kpiHash => kpiHash.key === selectedKpiData.key) ?
        tableData.kpis.map(kpiHash => (
          (kpiHash.key === selectedKpiData.key ? selectedKpiData : kpiHash)
        ))
        :
        [
          ...tableData.kpis,
          ...[selectedKpiData],
        ]
      )
      :
      tableData.kpis
  );
  return (
    {
      ...tableData,
      kpis: updateKpiData(),
    }
  );
};

module.exports = {
  structureKpiStateData,
};
