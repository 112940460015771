import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CustomAutocomplete from '../CustomAutocomplete';
import CleaveNumberFormatting from '../CleaveNumberFormatting';


class ManualShare extends Component {
  static propTypes = {
    share: PropTypes.object.isRequired,
    updateItem: PropTypes.func.isRequired,
    updateTopState: PropTypes.func.isRequired,
    edit: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      currentPercentageValue: props.share.percentage,
    };
  }

  componentWillMount() {
    this.updateItemDebounce = _.debounce(this.updateItemDebounce, 1000);
  }

  delete = e => {
    e.preventDefault();
    const { share, updateTopState } = this.props;
    App.Api.delete(share.url).then((data) => {
      updateTopState(data);
    });
  }

  updateItem = e => {
    const { share, updateItem } = this.props;
    updateItem(share.url, e.target.id, e.target.value);
  }

  updateItemCleave = (e, type = 'amount') => {
    const { share } = this.props;
    if (type === 'percentage') {
      this.setState({ currentPercentageValue: e.target.rawValue });
    }
    this.updateItemDebounce(e.target.id, e.target.rawValue || 0, share.url);
  }

  updateItemDebounce = (id, rawValue, shareUrl) => {
    const { updateItem } = this.props;
    updateItem(shareUrl, id, rawValue);
  }

  render() {
    const { share, edit } = this.props;
    const { currentPercentageValue } = this.state;

    if (edit) {
      return (
        <div className="flex flex-center mb2 mxn2">
          <div className="col-3 px2">
            <div className="flex flex-center h6 m0 relative text-gray">
              <CustomAutocomplete
                className="input-like border border-alto col-12"
                onChange={this.updateItem}
                value={share.owner_name}
                items={share.investors}
                name="share[owner_name]"
                id="owner_name"
              />
            </div>
          </div>

          <div className="col-3 px2">
            <div className="relative">
              <CustomAutocomplete
                className="input-like border border-alto col-12"
                onChange={this.updateItem}
                value={share.category}
                items={[{ key: 'common', value: 'Common' }, { key: 'preferred', value: 'Preferred' }]}
                name="share[category]"
                id="category"
              />
            </div>
          </div>

          <div className="col-3 px2">
            <CleaveNumberFormatting
              value={share.amount}
              onChange={this.updateItemCleave}
              className="appearance-textfield border border-alto col-12"
              name="share[amount]"
              id="amount"
              placeholder="0"
            />
          </div>

          <div className="center col-3 px2 flex flex-center">
            <CleaveNumberFormatting
              value={currentPercentageValue}
              onChange={e => this.updateItemCleave(e, 'percentage')}
              className="appearance-textfield border border-alto flex-auto"
              name="share[percentage]"
              id="percentage"
              placeholder="0"
            />

            <div className="ml2 cursor-pointer" href="#" onClick={this.delete}>
              <i className="fa fa-trash text-light-gray" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="flex mxn2">
        <div className="flex flex-center col-3 px2">
          <span
            className="block bg-center bg-cover bg-no-repeat circle header__avatar--investor mr1"
            style={{ backgroundImage: share.avatar ? `url(${share.avatar})` : '' }}
          />
          <p className="flex flex-start h6 m0 text-gray truncate">{ share.owner_name }</p>
        </div>

        <div className="col-3 px2">
          <p className="center h6 m0 text-gray">{ share.category }</p>
        </div>

        <div className="col-3 px2">
          <p className="center h6 m0 text-gray">{ share.amount }</p>
        </div>

        <div className="center col-3 px2">
          <span className="h6 m0 text-gray">{ share.percentage_show } %</span>
        </div>
      </div>
    );
  }
}

export default ManualShare;
