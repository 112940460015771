import { connect } from 'react-redux';
import DataRoom from '../../../components/data_room';

import {
  createSection,
  renameSection,
  deleteSection,
  updateSectionPosition,
  createFile,
  moveFile,
  updateFile,
  updateFilePosition,
  deleteFile,
} from '../../../api/company/deal_room/dealDataRoomApi';

const mapStateToProps = state => ({
  editor: true,
  company: state.company,
  dataRoom: state.dataRoom,
  comments: null,
});

const mapDispatchToProps = {
  createSection,
  renameSection,
  deleteSection,
  updateSectionPosition,
  createFile,
  moveFile,
  updateFile,
  updateFilePosition,
  deleteFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataRoom);
