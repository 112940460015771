import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import TextInput from '../TextInput';


class RenameModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    initialName: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      name: props.initialName,
    };
  }

  handleChange = e => {
    this.setState({ name: e.target.value });
  }

  submit = async () => {
    const { onSubmit, closeModal } = this.props;
    const { name } = this.state;

    await onSubmit(name);
    closeModal();
  }

  render() {
    const { show, closeModal, title } = this.props;
    const { name } = this.state;

    return (
      <Modal
        className="bg-white flex flex-column mb2 react_modal border-top border-blue bw-4"
        show={show}
        onClickOutside={closeModal}
      >
        <div className="flex flex-justify-between">
          <p className="col-12 center semi-bold py2 h2">{title}</p>
          <div
            className="flex flex-auto flex-justify-end cursor-pointer p1"
            onClick={closeModal}
          >
            <i className="fa fa-close text-gray" />
          </div>
        </div>
        <div className="col-12 flex flex-column flex-center sm-block bg-white p3 sm-center">
          <TextInput
            className="col-12 input--placeholder border border-light-gray"
            onChange={this.handleChange}
            value={name}
            name="name"
            id="name"
          />
          <div className="bg-blue mt2 text-white semi-bold p1 px2 cursor-pointer rounded" onClick={this.submit}>
            Save changes
          </div>
        </div>
      </Modal>
    );
  }
}

export default RenameModal;
