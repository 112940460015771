import React, { Component } from 'react';
import PropTypes from 'prop-types';


class Pending extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
    updateTopState: PropTypes.func.isRequired,
  }

  accept = (e) => {
    const { record, updateTopState } = this.props;
    e.preventDefault();
    App.Api.patch(record.accept_url).then((data) => {
      updateTopState(data);
      App.State.setFlash({ name: 'notice', msg: 'Request has been accepted!' });
    });
  }

  reject = (e) => {
    const { record, updateTopState } = this.props;
    e.preventDefault();
    App.Api.delete(record.manage_url).then((data) => {
      updateTopState(data);
      App.State.setFlash({ name: 'notice', msg: 'Request has been rejected!' });
    });
  }

  render() {
    const { record } = this.props;
    return (
      <div className="col-4 lg-col-6 sm-col-12 flex px2 mb3">
        <div className="bg-white bs-around-small flex flex-auto flex-column rounded">
          <div className="bg-orange center p3 rounded-top-left rounded-top-right">
            <p className="m0 text-upper text-white">Request pending</p>

            <p className="m0 mb2 text-light-white-medium">Accept or reject this Investment</p>

            <div className="flex flex-justify-center">
              <div
                className="border border-light-white button--has-hover mr2 px3 py1 text-white cursor-pointer"
                href="#"
                onClick={this.accept}
              >
                Accept
              </div>
              <div
                className="border border-light-white button--has-hover px3 py1 text-white cursor-pointer"
                href="#"
                onClick={this.reject}
              >
                Reject
              </div>
            </div>
          </div>

          <div>
            <a className="flex flex-center p2" href={record.url}>
              <div
                className="search-card__img bg-cover bg-no-repeat border border-light-gray circle mr2"
                style={{ backgroundImage: `url(${record.avatar})`, height: '40px', width: '40px' }}
              />

              <div className="col-8">
                <p className="h3 m0 regular text-outer-space truncate">{record.name}</p>
                <p className="h4 m0 text-blue truncate">{record.email}</p>
              </div>

              <div className="flex flex-auto flex-justify-end">
                <i className="fa fa-chevron-right text-gull-gray" />
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Pending;
