import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Circle extends Component {
  static defaultProps = {
    content: '',
    border: 'transparent',
    bg: 'white',
    text: 'gray',
    icon: null,
    size: 30,
    margin: 'mr2',
    iconSize: '15',
  }

  static propTypes = {
    content: PropTypes.string,
    border: PropTypes.string,
    bg: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    size: PropTypes.number,
    margin: PropTypes.string,
    iconSize: PropTypes.string,
  }

  state = {
    content: '',
    border: 'transparent',
    bg: 'white',
    text: 'gray',
  }

  render() {
    const { bg, border, text, content, icon, size, margin, iconSize } = this.props;
    return (
      <span
        className={`bg-${bg} circle flex flex-center flex-justify-center border border-${border} h4 fw400 ${margin} text-${text}`}
        style={{ height: `${size}px`, width: `${size}px` }}
      >
        {content || <span className={`fa fa-${icon}`} style={{ fontSize: `${iconSize}px` }} />}
      </span>
    );
  }
}

export default Circle;
