import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import Networkgraph from 'highcharts/modules/networkgraph';
import HighchartsReact from 'highcharts-react-official';

import Avatar from '../../../tab_view/Avatar';
import TextInput from '../../../TextInput';
import withSorting from '../../../utils/withSorting';
import SorterHeader from '../../../modules/SorterHeader';
import LoadingSpinner from '../../../LoadingSpinner';


class CoInvestors extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    sortedData: PropTypes.array.isRequired,
    tabScroll: PropTypes.bool,
    loading: PropTypes.bool,
    blurred: PropTypes.bool,
  }

  static defaultProps = {
    tabScroll: false,
    loading: false,
    blurred: null,
  }

  constructor(props) {
    super(props);

    Networkgraph(Highcharts);

    this.state = {
      searchQuery: '',
      randomKey: Math.round(Math.random() * 100),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.props;
    if (nextProps.data !== data) {
      this.setState({ randomKey: Math.round(Math.random() * 100) });
    }
  }

  search = (items) => {
    const { searchQuery } = this.state;
    return items.filter(item => !item.name || item.name.toLowerCase().includes(searchQuery.toLowerCase()));
  }

  chartOptions = () => {
    const { data } = this.props;
    const { addEvent } = Highcharts;

    const firstName = data.chart_data[0] ? data.chart_data[0][0] : '';

    addEvent(Highcharts.seriesTypes.networkgraph,
      'afterSetOptions',
      (e) => {
        let { colors } = Highcharts.getOptions(),
          i = 0,
          nodes = {};
        e.options.data.forEach((link) => {
          if (link[0] === firstName) {
            nodes[firstName] = {
              id: firstName,
              marker: {
                radius: 20,
                states: {
                  hover: {
                    enabled: false,
                  },
                },
              },
            };
            nodes[link[1]] = {
              id: link[1],
              marker: {
                radius: 10,
              },
              color: colors[i++],
            };
          } else if (nodes[link[0]] && nodes[link[0]].color) {
            nodes[link[1]] = {
              id: link[1],
              color: nodes[link[0]].color,
            };
          }
        });
        e.options.nodes = Object.keys(nodes).map((id) => nodes[id]);
      });

    return (
      {
        title: {
          text: null,
        },
        chart: {
          type: 'networkgraph',
          height: '250px',
        },
        plotOptions: {
          series: {
            animation: {
              duration: 1000,
            },
          },
          networkgraph: {
            keys: ['from', 'to'],
            layoutAlgorithm: {
              enableSimulation: true,
              friction: -0.7,
              integration: 'verlet',
            },
          },
        },
        legend: {
          enabled: false,
        },
        series: [{
          dataLabels: {
            enabled: false,
            textPath: {
              enabled: true,
            },
            linkFormat: '',
            allowOverlap: true,
          },
          data: data.chart_data,
        }],
        exporting: {
          enabled: false,
        },
      }
    );
  }

  renderHeaders = () => (
    <div className="col-12 flex py1 px3 text-light-gray h5 fw400 flex center bg-wild-sand border-top border-bottom border-alto">
      <div className="col-4 sm-col-6">
        <SorterHeader text="Co-Investors (*)" field="name" position="left" />
      </div>
      <div className="col-4 sm-col-6">
        <SorterHeader text="Co-Investments" field="investments_name" hideIcon />
      </div>
      <div className="col-4 sm-hide">
        <SorterHeader text="Total Co-Investments" field="investment_count" position="right" hideIcon />
      </div>
    </div>
  )

  render() {
    const { data, sortedData, tabScroll, loading, blurred } = this.props;
    const { randomKey } = this.state;
    const searchedData = this.search(sortedData).sort((a, b) => ((a.investment_count > b.investment_count) ? 1 : ((b.investment_count > a.investment_count) ? -1 : 0)));

    return (
      !loading ?
        <div className="mxn3 sm-block">
          <div className="col-12 flex mb3">
            <div className="col-6 flex pl3 pr1 sm-col-12">
              <div className="bg-white bs-around col-12 flex flex-column relative">
                <div className="flex px2 border-bottom border-lighter-gray p2">
                  <div className="h4 fw400 text-gray">Co-Investor Overview <span className="h5 text-light-gray">(*)</span></div>
                </div>

                {data.co_investors.length > 0 ?
                  (
                    <div>
                      {data.overview &&
                        <div className="flex flex-row m2 center bg-extra-light-gray bs-around rounded" >
                          <div className="col-4 my2 border-right border-lighter-gray">
                            <div className={`h4 fw400 ${blurred ? 'hide-data' : ''}`}>{data.overview.total_investments}</div>
                            <div className="h5 text-light-gray">Total Investments</div>
                          </div>
                          <div className="col-4 my2 border-right border-lighter-gray">
                            <div className={`h4 fw400 ${blurred ? 'hide-data' : ''}`}>{data.overview.total_co_investors}</div>
                            <div className="h5 text-light-gray">Co-Investors</div>
                          </div>
                          <div className="col-4 my2">
                            <div className={`h4 fw400 ${blurred ? 'hide-data' : ''}`}>{data.overview.total_co_investments}</div>
                            <div className="h5 text-light-gray">Co-Investments</div>
                          </div>
                        </div>
                      }
                      <div className="flex flex-justify-between px2">
                        <div className="h5 fw400 text-gray">Co-Investors (Top 5)</div>
                        <div className="h5 fw400 text-gray">Co-Investments</div>
                      </div>

                      {data.overview.biggest_co_investors ?
                        data.overview.biggest_co_investors.map(i => (
                          <div key={i.id} className={`flex flex-justify-between mt1 mr1 px2 ${blurred ? 'hide-data' : ''}`}>
                            <div className="flex items-center">
                              <Avatar classNames="mr1" avatarUrl={i.logo || '/apple-touch-icon-76x76.png'} size={24} />
                              <div className="h5 text-gray">{i.name}</div>
                            </div>
                            <div className="flex">
                              {i.investments.slice(0, 5).map(investment => (
                                <Avatar key={investment.id} classNames="overlapedIcon bg-white border border-white" avatarUrl={investment.logo || '/apple-touch-icon-76x76.png'} size={20} />
                              ))
                              }
                              {(i.investments.length > 5) ?
                                <Avatar avatarUrl="" addition={i.investments.length - 5} />
                                :
                                null
                              }
                            </div>
                          </div>
                        ))
                        :
                        null
                      }
                    </div>
                  )
                  :
                  (
                    <div className="relative top-15 flex items-center flex-justify-center text-light-gray px2">
                      There are not enough connections to identify co-investors yet!
                    </div>
                  )
                }
              </div>
            </div>
            <div className="col-6 flex pl1 pr3 sm-col-12">
              <div className="bg-white bs-around col-12 flex flex-column relative pb3">
                <div className="flex px2 border-bottom border-lighter-gray p2">
                  <div className="h4 fw400 text-gray">Co-Investments Graph</div>
                </div>
                {(data.co_investors.length > 0) ?
                  <div className={`pt2 ${blurred ? 'hide-data' : ''}`}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={this.chartOptions()}
                      key={randomKey}
                    />
                  </div>
                  :
                  <div className="relative top-15 flex items-center flex-justify-center text-light-gray px2">
                    There are not enough connections to identify co-investors yet!
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="px3">
            <span className="h5 text-light-gray">(*) This feature is based on investors that share the same connections. Some of these connections may not be actual investors in the company</span>
          </div>
          <div className="col-12 px3">
            <div className="bg-white bs-around flex flex-column relative border-wild-sand border-top">
              <div className={`flex flex-auto ${blurred ? 'hide-data' : ''}`}>
                <i className="fa fa-search p2 ml2 text-light-gray" />
                <TextInput
                  className="mt05 col-12 border-none"
                  placeholder="Search Co-Investor"
                  onChange={(e) => { this.setState({ searchQuery: e.target.value }); }}
                />
              </div>
              <div className="flex">
                {this.renderHeaders()}
              </div>
              <div className={`col-12 ${tabScroll ? 'overflow-y-auto xl-max-height x-max-height lg-max-height' : 'overflow-hidden'} ${blurred ? 'hide-data' : ''}`}>
                {
                  searchedData.length === 0 ?
                    <img className="col-12" alt="Nothing to display" src={window.images.investorEmpty} />
                    : searchedData.map(item => (
                      <div
                        key={item.id}
                        className="col-12 flex hover-bg-wild-sand py2 px3 text-gray flex items-center relative border-bottom border-wild-sand"
                      >
                        <div className="col-4 left-align">
                          <div className="flex items-center">
                            <Avatar classNames="mr1" avatarUrl={item.logo || '/apple-touch-icon-76x76.png'} size={28} />
                            <div className="flex flex-column lh-12">
                              <div className="h5 text-gray fw400">{item.name}</div>

                              {item.email &&
                                <a
                                  href={`mailto:${item.email}?subject=Co-Investments on Investory – Let's connect`}
                                  className="h6"
                                  onClick={() => Intercom('trackEvent', 'co-investor_contact_clicked')}
                                >
                                  Message
                                </a>
                              }
                            </div>
                          </div>
                        </div>
                        <div className="col-4 center h5 text-gray">{item.investments_name}</div>
                        <div className="col-4 right-align h5 text-gray">{item.investment_count}/{item.total_investments}</div>
                      </div>
                    ))
                }
              </div>
            </div>
          </div>
        </div>
        :
        <LoadingSpinner
          show={loading}
          type="fit"
          height="400px"
          background="white"
        />
    );
  }
}

export default withSorting(CoInvestors, (props) => props.data.co_investors);
