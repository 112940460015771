import React from 'react';
import PropTypes from 'prop-types';

import Map from '../../modules/Map';
import GenericPieChart from '../../modules/GenericPieChart';
import VerticalListingWidget from './widgets/VerticalListingWidget';

class WidgetWrapper extends React.Component {
    static propTypes = {
      widget: PropTypes.object.isRequired,
      widgets: PropTypes.array.isRequired,
      investorId: PropTypes.number,
      setChartFilter: PropTypes.func,
      blurred: PropTypes.bool,
    }

    static defaultProps = {
      investorId: null,
      setChartFilter: null,
      blurred: null,
    }

    state = {
      open: false,
    }

    componentWillMount() {
      document.addEventListener('click', this.handleClick, false);
      document.addEventListener('touchstart', this.handleClick, false);
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.handleClick, false);
      document.removeEventListener('touchstart', this.handleClick, false);
    }

    renderWidget = () => {
      const { widget, investorId, setChartFilter, blurred } = this.props;

      switch (widget.type) {
        case 'overview':
          return <VerticalListingWidget data={widget.data.total_values} loading={widget.data.loading} blurred={blurred} showWarning={widget.data.showWarning} currency={widget.data.currency} separators={[2, 5]} />;
        case 'kpis_summary':
          return <VerticalListingWidget data={widget.data.total_values} bottomValue={widget.data.bottomValue} loading={widget.data.loading} blurred={blurred} />;
        case 'map':
          return <Map mapData={widget.data.map_data} loading={widget.data.loading} investorId={investorId} blurred={blurred} />;
        case 'portfolio_by_stage':
        case 'portfolio_by_industry':
          return (
            <GenericPieChart
              missingDataMessage="There is no connected company"
              data={widget.data.chart_data}
              chosenFilter={widget.data.filter}
              loading={widget.data.loading}
              filterOptions={widget.data.filter_options}
              withFilter
              onFilterChange={setChartFilter}
              showRightSection={false}
              legendValues="#"
              smallVersion
              blurred={blurred}
            />
          );
        default:
          return (
            <VerticalListingWidget
              data={widget.data.table_data}
              chosenFilter={widget.data.chosen_filter}
              options={widget.data.table_options}
              loading={widget.data.loading}
              investorId={investorId}
              blurred={blurred}
            />
          );
      }
    }

    handleClick = (e) => {
      if (ReactDOM.findDOMNode(this).contains(e.target)) return;
      this.setState({ open: false });
    }

    fullText = () => {
      const { widget } = this.props;
      const preText = 'You can now reorder/modify this widget. Go to Account Settings and there you may find the Dashboard Widgets section where you may choose the one that suits you better.';

      let postText = '';

      if (widget.type === 'kpis_summary') {
        postText = 'To reorder the KPIs inside this widget, go to Manage Funds. There you may find an option to edit the Dashboard KPIs for each fund.';
      } else if (widget.type === 'portfolio_by_stage' || widget.type === 'portfolio_by_industry') {
        postText = "You can now choose the categories to chart your portfolio. If you need a category that is not listed, you can create it: 1 - go to the Companies tab | 2 - choose a Company | 3 - go to the Company's Profile tab | 4 - press Edit on the top right | 5 - press Manage&Add under Additional Info | 6 - press Add New Custom Field | 7 - Name your category field, select the Checkbox to indicate it is a category that can be charted, and press the copy button to 'Clone' the field to the rest of your investments";
      }

      return `${preText}     ${postText}`;
    }

    toggleDropdown() {
      const { open } = this.state;
      if (open) {
        this.setState({ open: false });
      } else {
        this.setState({ open: true });
      }
    }

    render() {
      const { widget, widgets } = this.props;
      const { open } = this.state;

      return (
        <div className="bg-white bs-around col-12 flex flex-column relative">
          <div className="flex flex-justify-between px2 border-bottom border-lighter-gray py2">
            <div className="flex flex-auto flex-justify-between">
              <div className="h4 fw400 text-gray">{widget.title}</div>
              {widget.type !== 'map' &&
                <div
                  data-balloon={this.fullText()}
                  data-balloon-pos="down"
                  data-balloon-length="xlarge"
                  className="ml1"
                >
                  <i className="fa fa-info-circle text-blue" />
                </div>
              }
            </div>
            <div className="relative hide">
              <i onClick={this.toggleDropdown.bind(this)} className="fa fa-ellipsis-v text-light-gray cursor-pointer" />
              <div className={`dropdown dropdown--center absolute bg-white border border-alto bs-dropdown left-50 transform-horizontal-center z1 ${open ? '' : 'hidden'} py1`}>
                <ul className="left-align m0 mw150">
                  {widgets ?
                    widgets.map(el => (
                      <li key={el.position} className="bold lh-1 h5 link-hover--blue block nowrap px1 text-gray cursor-pointer">{el.title}</li>
                    ))
                    :
                    null
                  }
                </ul>
              </div>
            </div>
          </div>
          <div
            className="flex flex-1 flex-column"
          >
            {this.renderWidget()}
          </div>
        </div>
      );
    }
}

export default WidgetWrapper;
