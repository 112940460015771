import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Avatar extends Component {
  static defaultProps = {
    tooltip: null,
    size: null,
    circle: true,
    shadow: false,
    classNames: '',
    overlaped: false,
    addition: null,
  }

  static propTypes = {
    avatarUrl: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    size: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    circle: PropTypes.bool,
    shadow: PropTypes.bool,
    classNames: PropTypes.string,
    overlaped: PropTypes.bool,
    addition: PropTypes.number,
  }

  style = () => {
    const { size, avatarUrl } = this.props;

    if (size) {
      return (
        { backgroundImage: `url( ${avatarUrl} )`, height: size, width: size }
      );
    }
    return (
      { backgroundImage: `url( ${avatarUrl} )` }
    );
  }

  render() {
    const { size, tooltip, avatarUrl, circle, shadow, classNames, overlaped, addition } = this.props;

    if (addition && addition > 0) {
      return (
        <span
          className="additionIcon overlapedIcon z1"
        >
          {`+${addition}`}
        </span>
      );
    }

    if (!avatarUrl) return <span />;

    return (
      <span
        className={`col-2 flex-shrink-none block bg-center bg-cover bg-no-repeat ${shadow ? 'filter-shadow' : ''} ${circle ? 'circle' : ''} ${size ? '' : 'header__avatar--investor'} ${classNames || ''} ${overlaped ? 'overlapedIcon' : ''}`}
        data-balloon={tooltip}
        data-balloon-pos="down"
        style={this.style()}
      />
    );
  }
}

export default Avatar;
