import moment from 'moment';
import {
  sum,
  calculateXIRR,
  investmentMultipleCalculation,
} from './fundDashboardFormulas';

import NumeralWrapper from './NumeralWrapper';

const xirr = (investmentInfoData, showIrr) => {
  if (showIrr) {
    const values = (investmentInfoData.transactions_data && investmentInfoData.transactions_data.values);
    const dates = (investmentInfoData.transactions_data && investmentInfoData.transactions_data.dates);

    return calculateXIRR(values, dates, investmentInfoData.raw_current_share_value);
  }
  return null;
};

const totalValues = (investmentInfoDashboardData, showIrr, type = 'dashboard', hideData = false) => {
  let values = null;
  let dates = null;
  let portfolioIRR = null;
  let portfolioMultiple = null;

  const totalValueOfShares = sum(investmentInfoDashboardData, 'current_share_value_converted');

  if (showIrr && type === 'dashboard') {
    values = investmentInfoDashboardData.map(item => item.transactions_data_converted).map(transactionData => (transactionData ? transactionData.values : [])).flat();
    dates = investmentInfoDashboardData.map(item => item.transactions_data_converted).map(transactionData => (transactionData ? transactionData.dates : [])).flat();

    const finalValues = values.map((val, index) => ({ value: val, date: moment(dates[index]) }));
    const sortedValues = finalValues.sort((a, b) => a.date.diff(b.date));
    if (hideData) {
      portfolioIRR = 9999;
    } else {
      portfolioIRR = investmentInfoDashboardData.length === 0 ? null : calculateXIRR(sortedValues.map(val => val.value), sortedValues.map(val => val.date), totalValueOfShares);
    }
  }
  if (type === 'dashboard') {
    if (hideData) {
      portfolioMultiple = 9999;
    } else {
      portfolioMultiple = investmentMultipleCalculation(undefined, investmentInfoDashboardData);
    }
  }

  return {
    total_value_of_shares: totalValueOfShares,
    total_invested: sum(investmentInfoDashboardData, 'invested_converted'),
    total_realized: sum(investmentInfoDashboardData, 'realized_converted'),
    total_post_money_valuation: sum(investmentInfoDashboardData, 'current_valuation_converted'),
    portfolio_multiple: portfolioMultiple,
    portfolio_irr: investmentInfoDashboardData.length === 0 ? null : portfolioIRR,
  };
};

const overviewWidgetData = (totals, companiesCount, showIrr, currency) => {
  const balloonText = 'The portfolio multiple is calculated by dividing the total value of shares plus the total realized cashflow by the total amount invested for each company.';
  const totalPmValuation = NumeralWrapper(totals.total_post_money_valuation);
  const totalInvested = NumeralWrapper(totals.total_invested);
  const totalRealized = NumeralWrapper(totals.total_realized);
  const totalSharesValue = NumeralWrapper(totals.total_value_of_shares);

  const data = [
    { title: 'Companies', value: companiesCount || 0 },
    { title: 'Total P.M Valuation', value: totalPmValuation ? `${totalPmValuation} ${currency}` : null },
    { title: 'Total Invested', value: totalInvested ? `${totalInvested} ${currency}` : null },
    { title: 'Total Realized', value: totalRealized ? `${totalRealized} ${currency}` : null },
    { title: 'Total Shares Value', value: totalSharesValue ? `${totalSharesValue} ${currency}` : null },
    { title: 'Multiple', value: totals.portfolio_multiple ? `${totals.portfolio_multiple} ${totals.portfolio_multiple ? 'x' : ''}` : null, balloonText },
  ];

  if (showIrr) {
    return ([...data, { title: 'Portfolio IRR', value: totals.portfolio_irr ? (totals.portfolio_irr !== 'N/A' ? `${totals.portfolio_irr}%` : 'N/A') : null, beta: true }]);
  }
  return data;
};

export {
  totalValues,
  xirr,
  overviewWidgetData,
};
