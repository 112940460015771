import React from 'react';
import PropTypes from 'prop-types';


const getSpinnerType = (imgPath, background, type, height, imgHeight, imgWidth) => {
  if (type === 'fit') {
    return (
      <div className="flex flex-center flex-justify-center z4" style={{ backgroundColor: background, height }}>
        <img
          alt="loading..."
          src={imgPath}
          width="64"
          style={{ height: imgHeight, width: imgWidth }}
        />
      </div>
    );
  }
  return (
    <div className="bottom-0 flex flex-center flex-justify-center absolute left-0 right-0 top-0 z4" style={{ backgroundColor: background }}>
      <div className="relative z1">
        <img
          alt="loading..."
          src={imgPath}
          width="64"
        />
      </div>
    </div>
  );
};


const LoadingSpinner = props => {
  const { show, imgPath, background, type, height, imgHeight, imgWidth } = props;

  return (
    show &&
      getSpinnerType(imgPath, background, type, height, imgHeight, imgWidth)
  );
};

LoadingSpinner.propTypes = {
  show: PropTypes.bool.isRequired,
  imgPath: PropTypes.string,
  background: PropTypes.string,
  type: PropTypes.string,
  height: PropTypes.string,
  imgHeight: PropTypes.string,
  imgWidth: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  imgPath: window.images.loading,
  background: 'rgba(0,0,0,.37)',
  type: '',
  height: '',
  imgHeight: '',
  imgWidth: '',
};

export default LoadingSpinner;
