import React, { Component } from 'react';


class Link extends Component {
  href = () => {
    const { xButton, link } = this.props;
    if (xButton) return null;

    return link.link;
  }

  image = () => (
    <div className="bg-center bg-no-repeat box-120 relative" style={this.imageBoxStyle()} />
  )

  imageBoxStyle = () => {
    const { link } = this.props;
    if (link.loading) return {};

    if (!link.image) {
      return {
        backgroundImage: "url('/static/link.svg')",
        backgroundSize: "30%",
      };
    }

    return {
      backgroundImage: "url('" + link.image + "')",
      backgroundSize: "cover",
      backgroundPosition: "center"
    };
  }

  xButton = () => {
    const { xButton, link, removeLink } = this.props;
    if (!xButton || !link.link) return;
    return <div className="absolute cursor-pointer left-0 ml1 mt1 text-gray top-0 z2" onClick={removeLink }><span>X</span></div>;
  }

  render() {
    const { link } = this.props;
    if (!link) return null;

    return (
      <div className="mb2">
        <div className="bg-wild-sand border border-alto flex flex-center relative">
          { this.xButton() }

          <a
            className="absolute bottom-0 left-0 right-0 top-0"
            href={ this.href() }
            target="_blank"
          />

          { this.image() }

          <div className="col-9 p1 text-gray">
            <p className="bold m0">{ link.title }</p>
            <p className="break-word h5 m0">{ link.text }</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Link;
