import React from "react";
import Select from 'react-select';
import PropTypes from 'prop-types';

const SelectorDropDown = (props) => {
  const { options, selectValue, onChangeValue } = props;
  return (
    <Select
      defaultValue={options[0]}
      className="mb1 col-7"
      placeholder= "Select plan with"
      options={options}
      value={options.find(option => option.value === selectValue)}
      onChange={onChangeValue}
    />
  )
}
SelectorDropDown.propTypes = {
  options: PropTypes.array.isRequired,
  onChangeValue: PropTypes.func,
};

SelectorDropDown.defaultProps = {
  options: [],
  onChangeValue: null,
};
export default SelectorDropDown;