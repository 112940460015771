import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Modal from '../Modal';
import HtmlComponent from '../tab_view/HtmlComponent';


class CompanyMainObjectives extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    mainObjectives: PropTypes.array,
    mainObjectivesOptions: PropTypes.array.isRequired,
    newFeatures: PropTypes.array,
    newFeaturesOptions: PropTypes.array.isRequired,
  }

  static defaultProps = {
    mainObjectives: null,
    newFeatures: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      saving: false,
      mainObjectives: props.mainObjectives,
      newFeatures: props.newFeatures,
    };
  }

  componentDidMount() {
    const { show } = this.state;

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('mainObjective', 'true');
    window.history.replaceState({}, '', `${window.location.pathname}?${queryParams}`);

    setTimeout(() => (
      this.setState({ show: !show })
    ), 300);
  }

  toogleModal = () => {
    const { show } = this.state;

    this.setState({ show: !show });
  }

  submit = () => {
    const { company } = this.props;
    const { mainObjectives, newFeatures, saving } = this.state;

    if (saving) {
      return;
    }

    if (mainObjectives.length === 0) {
      App.State.setFlash({ name: 'alert', msg: 'Please, choose at least one option.' });
      return;
    }

    const params = { company: { main_objectives: mainObjectives, new_features: newFeatures } };

    this.setState({ saving: true }, async () => {
      await axios.patch(`/company/${company.id}/main_objectives`, params);
      this.setState({ saving: false }, () => {
        this.toogleModal();
        window.location.reload();
      });
    });
  }

  render() {
    const {
      mainObjectivesOptions,
      newFeaturesOptions,
    } = this.props;

    const { show, mainObjectives, newFeatures, saving } = this.state;

    const showOption = (option, type) => {
      const attribute = type === 'mainObjectives' ? mainObjectives : newFeatures;

      return (
        <div
          key={option.value}
          className={`border hover hover-border-blue hover-text-blue cursor-pointer p1 mt1 ${(attribute && attribute.includes(option.value)) ? 'border-blue text-blue bg-lighter-blue' : 'border-lighter-gray text-light-gray'}`}
          onClick={() => this.setState({ [type]: attribute ? (attribute.includes(option.value) ? attribute.filter(el => el !== option.value) : [...attribute, option.value]) : [option.value] })}
        >
          <HtmlComponent data={{ html: option.label }} />
        </div>
      );
    };

    return (
      <React.Fragment>
        <Modal
          show={show}
          widthClass="react_modal--600"
        >
          <div className="flex flex-justify-between items-center fw400 p2 border-bottom border-lighter-gray mb2">
            <div className="h3 pl2">Main Objectives</div>
          </div>

          <div className="flex flex-justify-start flex-column fw400 px3">
            <div className="flex flex-column flex-justify-start pb1">
              <span className="mb2">To give you the best experience, we&apos;d like to ask you two questions:</span>

              <span>How are you planning to use Investory?</span>
              <span className="h6 text-light-gray">*Choose one or many</span>
            </div>

            <div className="flex flex-column mb1">
              {mainObjectivesOptions.map(option => showOption(option, 'mainObjectives'))}
            </div>

            <div className="flex flex-column flex-justify-start pb1 pt2">
              Would any of these potential new features be relevant for you?
              <span className="h6 text-light-gray">*Choose one or both</span>
            </div>

            <div className="flex flex-column mb1">
              {newFeaturesOptions.map(option => showOption(option, 'newFeatures'))}
            </div>
          </div>

          <div
            className="py2 flex flex-justify-center"
            onClick={this.submit}
          >
            <div
              className={`px3 py1 bg-green rounded text-white ${saving ? 'cursor-disabled' : 'cursor-pointer'}`}
            >
              {saving ? 'Saving...' : 'Submit'}
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default CompanyMainObjectives;
