import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import withSorting from '../utils/withSorting';
import SorterHeader from '../modules/SorterHeader';

import DataRoomFileRow from './DataRoomFileRow';


class DataRoomFiles extends Component {
  static propTypes = {
    editor: PropTypes.bool.isRequired,
    blockFileDownload: PropTypes.bool.isRequired,
    files: PropTypes.array.isRequired,
    sortedData: PropTypes.array.isRequired,
    uploadFiles: PropTypes.func.isRequired,
    moveFile: PropTypes.func.isRequired,
    updateFile: PropTypes.func.isRequired,
    updateFilePosition: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    sectionOptions: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props);

    const { sortedData } = props;

    this.state = {
      dropzoneLayerVisible: false,
      searchQuery: '',
      dataRoomFilesList: sortedData,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { sortedData } = nextProps;

    this.setState({ dataRoomFilesList: sortedData });
  }

  showDropzoneLayer = () => {
    this.setState({ dropzoneLayerVisible: true });
  };

  hideDropzoneLayer = () => {
    this.setState({ dropzoneLayerVisible: false });
  };

  renderHeaders = editor => (
    <div className="col-12 text-gray h5 flex pl3 bg-extra-light-gray">
      <div className="flex-auto flex items-center py1 pr2 fw400">
        <SorterHeader text="Name" field="name" />
      </div>

      {editor &&
        <div className="col-2 flex items-center py1 pl1 sm-hide fw400">
          Statistics
        </div>
      }

      <div className="col-2 flex items-center py1 pl1 sm-hide fw400">
        <SorterHeader text="Size" field="size" />
        <span className="ml1 h6 text-light-gray">
          (max 50 MB)
        </span>
      </div>

      <div className="col-1 flex items-center py1 sm-hide fw400 nowrap">
        <SorterHeader text="Modified" field="updated_at" />
      </div>
      <div className="col-1 py1" />
    </div>
  );

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = result => {
    const { updateFilePosition } = this.props;

    if (!result.destination) return;

    const { dataRoomFilesList } = this.state;
    const params = { position: result.destination.index + 1 };

    const list = this.reorder(
      dataRoomFilesList,
      result.source.index,
      result.destination.index,
    );

    // Somehow there's an issue when the position is not quite in sequence.
    // The reorder method adds an undefined element to the list. The filter below removes it.
    this.setState({ dataRoomFilesList: list.filter(el => el) }, () => updateFilePosition(result.draggableId, params));
  }

  render() {
    const {
      editor,
      blockFileDownload,
      uploadFiles,
      moveFile,
      updateFile,
      deleteFile,
      sectionOptions,
    } = this.props;

    const { dropzoneLayerVisible, searchQuery, dataRoomFilesList } = this.state;

    const filteredFiles = dataRoomFilesList.filter(file => file.name.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
      <Dropzone
        disableClick
        className="border border-transparent col-12 flex sm-col-12"
        onDrop={(droppedFiles) => {
          uploadFiles(droppedFiles);
          this.hideDropzoneLayer();
        }}
        style={{ position: 'relative' }}
        onDragEnter={() => this.showDropzoneLayer()}
        onDragLeave={() => this.hideDropzoneLayer()}
        disabled={!editor}
      >
        {dropzoneLayerVisible &&
          <div className="absolute bottom-0 flex flex-center flex-justify-center left-0 right-0 top-0 z1" style={{ background: 'rgba(0,0,0,0.4)' }}>
            <span className="bold text-white">Drop file to upload</span>
          </div>
        }

        <div className="flex flex-column bg-white col-12 flex relative">
          <div className="flex col-12 sm-flex-column my05">
            <div className="flex-auto flex items-center">
              <i className="fa fa-search p2 ml2 text-light-gray" />
              <input className="border-none col-12 sm-col-4" type="text" placeholder="Search" onChange={e => this.setState({ searchQuery: e.target.value })} />
            </div>
          </div>

          {this.renderHeaders(editor)}
          <div className="flex flex-column overflow-y-auto" style={{ height: '440px' }}>

            {
              filteredFiles.length ?
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {provided => (
                      <div
                        ref={provided.innerRef}
                      >
                        {filteredFiles.map(file => (
                          <DataRoomFileRow
                            key={`file_${file.id}`}
                            file={file}
                            editor={editor}
                            blockFileDownload={blockFileDownload}
                            moveFile={moveFile}
                            updateFile={updateFile}
                            deleteFile={deleteFile}
                            sectionOptions={sectionOptions}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                :
                <div className="flex flex-column flex-justify-center" style={{ height: '100%' }}>
                  <img alt="Empty section" src={window.images.dataRoomEmpty} />
                  {editor &&
                    <p className="center text-light-gray mt2">Drag &amp; Drop documents here...</p>
                  }
                </div>
            }
          </div>
        </div>
      </Dropzone>
    );
  }
}

export default withSorting(DataRoomFiles, (props) => props.files);
