import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CompaniesCaptablesTotal from './investment_info/CompaniesCaptablesTotal';
import withSorting from '../../../utils/withSorting';
import Header from './investment_info/Header';
import LoadingSpinner from '../../../LoadingSpinner';
import GenericPieChart from '../../modules/GenericPieChart';
import CheckBox from '../../../CheckBox';
import NumeralWrapper from '../../../utils/NumeralWrapper';
import ValuesRow from './investment_info/ValuesRow';
import SpotRateWarning from './investment_info/SpotRateWarning';


class InvestmentInfo extends Component {
  static propTypes = {
    show: PropTypes.bool,
    data: PropTypes.array,
    sortedData: PropTypes.array.isRequired,
    chartData: PropTypes.object,
    colorTheme: PropTypes.string,
    totalsInfo: PropTypes.object,
    totalValues: PropTypes.object,
    loading: PropTypes.bool,
    tabScroll: PropTypes.bool,
    showChart: PropTypes.bool,
    children: PropTypes.node,
    investorId: PropTypes.number.isRequired,
    reavealSourceOption: PropTypes.bool,
    exportDataURL: PropTypes.string,
    showIrr: PropTypes.bool.isRequired,
    showValuesInDisplayCurrency: PropTypes.bool.isRequired,
    blurred: PropTypes.bool.isRequired,
    canExport: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    show: true,
    data: [],
    chartData: {},
    totalsInfo: {},
    totalValues: {},
    loading: false,
    colorTheme: null,
    reavealSourceOption: false,
    tabScroll: false,
    showChart: true,
    children: null,
    exportDataURL: null,
  }

  constructor(props) {
    super(props);

    const { reavealSourceOption } = props;

    this.state = {
      loading: false,
      source: reavealSourceOption,
    };
  }

  addCurrency = (val, currency) => {
    if ((val === '') || (val === 'N/A') || (val === '0')) {
      return 'N/A';
    }
    if (val) {
      return `${val} ${currency}`;
    }
    return null;
  }

  textColorOverviewTotals = (val) => {
    const { colorTheme } = this.props;

    if (val === 'N/A' || val === '') {
      return '';
    }
    return colorTheme;
  }

  textColor = (val) => {
    const { colorTheme } = this.props;
    const textColor = colorTheme || '#2E75B5';
    if (val === 'N/A') {
      return '#D7D7D7';
    }
    return this.addCurrency(val) === 'N/A' ? '#D7D7D7' : textColor;
  }

  render() {
    const {
      show,
      data,
      sortedData,
      chartData,
      totalsInfo,
      totalValues,
      colorTheme,
      loading: loadingProps,
      tabScroll,
      showChart,
      children,
      investorId,
      reavealSourceOption,
      exportDataURL,
      showIrr,
      showValuesInDisplayCurrency,
      blurred,
      canExport,
    } = this.props;

    const { loading, source } = this.state;

    const totals = {
      currency: totalsInfo.currency,
      rates_updated_at: totalsInfo.ratesUpdatedAt,
      total_ownership: 'N/A',
      total_invested: NumeralWrapper(totalValues.total_invested),
      total_realized: NumeralWrapper(totalValues.total_realized),
      total_post_money_valuation: NumeralWrapper(totalValues.total_post_money_valuation),
      total_value_of_shares: NumeralWrapper(totalValues.total_value_of_shares),
      portfolio_multiple: totalValues.portfolio_multiple,
    };

    const isLoading = loadingProps || loading;

    return (
      show ?
        <React.Fragment>
          {showChart &&
            <div className="col-12 flex flex-column mt1 bg-white bs-around mb2">

              <GenericPieChart
                data={chartData.data}
                missingDataMessage="The is no investment"
                loading={chartData.loading}
                withFilter={false}
                legendTitle="Company"
                legendValues="Shares Value"
                showRightSection={false}
                exportDataURL={exportDataURL}
                canExport={canExport}
              />

              <div className="flex items-center col-12">
                <div className="mt1 mb2 pt2 pb2 flex flex-justify-between bg-white bs-around mx2 col-12">
                  <div className="flex col-12">
                    <div className="flex col-3 flex-column border-right border-lighter-gray">
                      <div className={`bold center investor-dashboard__chart-value-color--na ${blurred ? 'hide-data' : ''}`} style={{ color: this.textColorOverviewTotals(data.length), height: '22px' }}>
                        {data.length === 0 ? '-' : data.length}
                      </div>
                      <div className="text-light-gray h5 center">
                        Investments
                      </div>
                    </div>
                    <div className="flex col-3 flex-column border-right border-lighter-gray">
                      <div className={`bold center investor-dashboard__chart-value-color--na ${blurred ? 'hide-data' : ''}`} style={{ color: this.textColorOverviewTotals(totals.total_post_money_valuation), height: '22px' }}>
                        {totals.total_post_money_valuation ? `${totals.total_post_money_valuation} ${totalsInfo.currency}` : '-'}
                      </div>
                      <div className="text-light-gray h5 center mx1">
                        Total Post-Money Valuation
                      </div>
                    </div>
                    <div className="flex col-3 flex-column border-right border-lighter-gray">
                      <div className={`bold center investor-dashboard__chart-value-color--na ${blurred ? 'hide-data' : ''}`} style={{ color: this.textColorOverviewTotals(totals.total_invested), height: '22px' }}>
                        {totals.total_invested ? `${totals.total_invested} ${totalsInfo.currency}` : '-'}
                      </div>
                      <div className="text-light-gray h5 center">
                        Total Invested
                      </div>
                    </div>
                    <div className="flex col-3 flex-column border-right border-lighter-gray">
                      <div className={`bold center investor-dashboard__chart-value-color--na ${blurred ? 'hide-data' : ''}`} style={{ color: this.textColorOverviewTotals(totals.total_realized), height: '22px' }}>
                        {totals.total_realized ? `${totals.total_realized} ${totalsInfo.currency}` : '-'}
                      </div>
                      <div className="text-light-gray h5 center">
                        Total Realized
                      </div>
                    </div>
                    <div className="flex col-3 flex-column border-right border-lighter-gray">
                      <div className={`bold center investor-dashboard__chart-value-color--na ${blurred ? 'hide-data' : ''}`} style={{ color: this.textColorOverviewTotals(totals.total_value_of_shares), height: '22px' }}>
                        {totals.total_value_of_shares ? `${totals.total_value_of_shares} ${totalsInfo.currency}` : '-'}
                      </div>
                      <div className="text-light-gray h5 center">
                        Current Value of Investment
                      </div>
                    </div>
                    <div className="flex col-3 flex-column border-right border-lighter-gray">
                      <div className={`bold center investor-dashboard__chart-value-color--na ${blurred ? 'hide-data' : ''}`} style={{ color: this.textColorOverviewTotals(totals.portfolio_multiple), height: '22px' }}>
                        {totals.portfolio_multiple ? `${totals.portfolio_multiple} x` : '-'}
                      </div>
                      <div className="text-light-gray h5 center">
                        Portfolio Multiple
                      </div>
                    </div>
                    {showIrr &&
                      <div className="flex col-3 flex-column">
                        <div className={`bold center investor-dashboard__chart-value-color--na ${blurred ? 'hide-data' : ''}`} style={{ color: this.textColorOverviewTotals(totalValues.portfolio_irr), height: '22px' }}>
                          {totalValues.portfolio_irr ? (totalValues.portfolio_irr !== 'N/A' ? `${totalValues.portfolio_irr}%` : 'N/A') : '-'}
                        </div>
                        <div className="text-light-gray h5 center">
                          Portfolio IRR
                        </div>
                        <div className="relative">
                          <span className="desktop__notification--bell" data-behavior="hide-bubble" style={{ borderRadius: '30%', width: '25px', top: '-25px', right: '5px', zIndex: 1 }}>
                            Beta
                          </span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                {sortedData.length > 0 && sortedData.find(dataEl => dataEl.has_converted_cashflow) &&
                  <div className={blurred ? 'hide-data' : ''}>
                    <SpotRateWarning currency={totalsInfo.currency} balloonPosition="left" />
                  </div>
                }
              </div>
            </div>
          }

          {reavealSourceOption &&
            <div className="flex">
              <div className="relative">
                <div
                  className="cursor-pointer flex flex-center h5"
                  data-balloon="Reveals the data source (company or custom meaning from investment profile) for each KPI and investment info on this dashboard."
                  data-balloon-pos="right"
                  data-balloon-length="large"
                >
                  <CheckBox
                    fixedWidth
                    checkboxSize="h4"
                    defaultChecked={source}
                    onChange={() => this.setState({ source: !source })}
                  />
                  <span className="ml1 cursor-default">
                    Reveal Data Source
                  </span>
                </div>
              </div>
            </div>
          }

          <div className="col-12 flex flex-column mt1 bg-white bs-around">
            <div className={`col-12 ${children ? 'border-wild-sand border-top' : ''}`}>
              <div className="col-12">
                {/* Renders the search bar */}
                {children}

                <Header multipleFormula="default" showIrr={showIrr} />

                <div className={`col-12 ${tabScroll ? 'overflow-y-auto xl-max-height x-max-height lg-max-height' : 'overflow-hidden'}`}>
                  { sortedData.length > 0 ?
                    (
                      sortedData.map(item => (
                        <a
                          key={`investment_${item.id}`}
                          className="col-12 flex hover-bg-wild-sand py3 px1 text-gray flex flex-center relative h5"
                          href={`/investor/${investorId}/investments/${item.id}/funding`}
                        >
                          <div className="col-2 sm-col-6">
                            <div className="ml1 text-gray fw400 flex flex-center">
                              <span
                                className="block col-3 bg-center bg-cover bg-no-repeat circle header__avatar--user mr1"
                                style={{ backgroundImage: `url( ${item.avatar} )` }}
                              />
                              {item.real_company &&
                                <div className="relative">
                                  <span className="real_company__notification" data-behavior="hide-bubble">
                                    <i className="fa fa-check-circle text-green" />
                                  </span>
                                </div>
                              }
                              <div className="col-9 flex flex-center truncate text-gray h5">
                                <div className="truncate text-gray h5">
                                  {item.name}
                                </div>
                                <i className={`fa ${item.followed ? 'fa-star' : ''} text-green ml1`} />
                              </div>
                            </div>
                          </div>

                          <ValuesRow
                            item={item}
                            source={source}
                            addCurrency={this.addCurrency}
                            textColor={this.textColor}
                            showIrr={showIrr}
                          />
                          {item.has_converted_cashflow && showValuesInDisplayCurrency &&
                            <SpotRateWarning currency={totalsInfo.currency} balloonPosition="left" />
                          }
                        </a>
                      ))

                    ) : (
                      !isLoading &&
                      <div className="px2 pt2 bg-white center py3">
                        <span className="text-medium-gray mx-auto h5">
                          There is no connected company
                        </span>
                      </div>
                    )
                }
                  <LoadingSpinner
                    show={isLoading}
                    type="fit"
                    height="300px"
                    background="white"
                  />
                </div>
                {
                  !$.isEmptyObject(totals) &&
                  <CompaniesCaptablesTotal
                    totalValues={totals}
                    colorTheme={colorTheme}
                    showIrr={showIrr}
                  />
                }
              </div>
            </div>
          </div>
        </React.Fragment>
        :
        null
    );
  }
}

export default withSorting(InvestmentInfo, (props) => props.data);
