import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../TextInput';

class CountrySearch extends Component {
  static propTypes = {
    currentCompany: PropTypes.object,
    url: PropTypes.string,
    validateUrl: PropTypes.string,
    country: PropTypes.string,
    margin: PropTypes.string,
    italic: PropTypes.bool,
  }

  static defaultProps = {
    currentCompany: null,
    url: null,
    validateUrl: null,
    country: '',
    margin: 'mb2 sm-mb3 bs-around-small',
    italic: false,
  }

  constructor(props) {
    super(props);
    const { currentCompany } = props;

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      currentCompany,
      searchQuery: currentCompany.country ? currentCompany.country : '',
      loading: false,
      none: false,
      showDropdown: false,
      countries: [],
      countryAttributes: currentCompany ? currentCompany.country : '',
      hasValue: false,
      validCountryValue: !!currentCompany,
    };
  }

  componentWillMount() {
    this.search = _.debounce(this.search, 500);
  }

  setCompanyAttributes = (result) => {
    this.setState(
      {
        countryAttributes: result,
        searchQuery: result,
        showDropdown: false,
        hasValue: true,
      },
    );
  }

  validCountry = (country) => {
    const { none } = this.state;
    if (!none) return;

    const { validateUrl } = this.props;
    App.Api.get(validateUrl, country).then(
      (data) => {
        if (data.isValid === false) {
          this.setState({
            validCountryValue: false,
            countryAttributes: null,
          });
          App.State.setFlash({ name: 'alert', msg: 'Country name is invalid' });
        }
        this.setState({
          validCountryValue: true,
        });
      },
    );
  }

  search = (query) => {
    const { url } = this.props;
    if (query.searchQuery.replace(/^[ ]+|$/g, '').length < 3) {
      $('#country_suggestion_drop_down').html('');
      return;
    }
    this.setState({
      searchQuery: query.searchQuery,
      loading: true,
      showDropdown: true,
    });

    const urlWithParams = `${url}`;
    App.Api.get(urlWithParams, query).then(
      (data) => {
        if (data.length === 0) {
          this.setState({ none: true, loading: false });
        } else {
          this.setState({ none: false, loading: false });
        }
        this.setState({ countries: data });
      },
      () => App.State.setFlash({ name: 'alert', msg: 'Sorry, an error happened with the search.' }),
    );
  }

  displayLoading = () => {
    const { loading, none, searchQuery } = this.state;

    if (searchQuery.replace(/^[ ]+|$/g, '').length < 3) return null;

    if (loading) {
      return (
        <div className="text-light-gray p2 border-top border-wild-sand italic">
          <i className="fa fa-refresh fa-spin fa-fw mr1" />
          Looking for your country...
        </div>
      );
    }
    if (none) {
      return (
        <div className="text-light-gray p2 border-top border-wild-sand italic">
          No country found with this name :(
        </div>
      );
    }
    return null;
  }

  renderView = () => {
    const {
      showDropdown,
      searchQuery,
      countries,
    } = this.state;

    if (searchQuery.replace(/^[ ]+|$/g, '').length < 3) {
      return null;
    }

    if (!showDropdown) return null;

    const showCountries = (allCountries) => (
      allCountries.map((item) => (
        <div
          key={item.name}
          className="col-12 flex flex-center p1 sm-px0 fw400 hover hover-bg-wild-sand border-top border-wild-sand"
        >
          <div
            className="col-12 sm-col-7 text-gray cursor-pointer"
            onClick={() => this.setCompanyAttributes(item.name)}
          >
            <div className="ml1 text-gray flex items-center h5">
              <div className="truncate text-gray col-11 left-align">{item.name}</div>
            </div>
          </div>
        </div>
      ))
    );

    return (
      <div className="flex flex-column col-12" id="country_suggestion_drop_down">
        {countries.length > 0 &&
          <div className="flex flex-column">
            {showCountries(countries)}
          </div>
        }
      </div>
    );
  }

  render() {
    const { margin, italic } = this.props;
    const { loading, countryAttributes, searchQuery, hasValue, validCountryValue } = this.state;

    return (
      <div className={`flex flex-center col-12 flex-column bg-white select-arrow ${margin}`}>
        <TextInput
          className={`border-none col-12 ${italic ? 'italic' : ''}`}
          onChange={(e) => { this.search({ searchQuery: e.target.value }); this.setState({ hasValue: false }); }}
          onBlur={(e) => { this.validCountry({ country: e.target.value }); }}
          placeholder="Type country name"
          value={searchQuery}
          hasValue={hasValue}
        />
        {this.displayLoading()}
        {!loading && this.renderView()}
        {countryAttributes && validCountryValue &&
          <input key={countryAttributes} type="hidden" name="company[country]" value={countryAttributes} />
        }
      </div>
    );
  }
}

export default CountrySearch;
