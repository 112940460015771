import field from './investment_field';

function addFields(state, newFields) {
  switch (newFields[0].overwrite) {
    case 'collaborator':
      return {
        ...state,
        collaborators: [...state.collaborators, ...newFields],
      };
    case 'investor':
      return {
        ...state,
        co_investors: [...state.co_investors, ...newFields],
      };
    default:
      return {
        ...state,
        custom_fields: [...state.custom_fields, ...newFields],
      };
  }
}

function updateKpiField(stateKpi, fields) {
  const updateKpi = fields.find(f => f.kpi_field_id === stateKpi.field_id);
  return updateKpi ? updateKpi.kpi_field : stateKpi;
}

const investment = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_INVESTMENT_FIELDS':
      return addFields(state, action.fields);

    case 'UPDATE_INVESTMENT_FIELDS':
      return {
        ...state,
        description: field(state.description, action),
        contacts: state.contacts.map(c => field(c, action)),
        company_fields: state.company_fields.map(f => field(f, action)),
        address_fields: state.address_fields.map(f => field(f, action)),
        custom_fields: state.custom_fields.map(f => field(f, action)),
        collaborators: state.collaborators.map(f => field(f, action)),
        co_investors: state.co_investors.map(f => field(f, action)),
        kpis: state.kpis.map(kpi => updateKpiField(kpi, action.fields)),
      };

    case 'OVERWRITE_INVESTMENT_FIELDS':
      return {
        ...state,
        description: field(state.description, action),
        company_fields: state.company_fields.map(f => field(f, action)),
        address_fields: state.address_fields.map(f => field(f, action)),
        contacts: state.contacts.map(c => field(c, action)),
      };

    case 'REMOVE_INVESTMENT_FIELDS':
      return {
        ...state,
        custom_fields: state.custom_fields.filter(f => !action.ids.includes(f.id)),
        collaborators: state.collaborators.filter(f => !action.ids.includes(f.id)),
        co_investors: state.co_investors.filter(f => !action.ids.includes(f.id)),
      };

    case 'RESET_INVESTMENT_FIELDS':
      return {
        ...state,
        company_fields: state.company_fields.map(f => field(f, action)),
        contacts: state.contacts.map(c => field(c, action)),
      };

    case 'COPY_COMPANY_DATA':
      return {
        ...state,
        [action.fieldType]: action.fields,
      };

    default: return state;
  }
};

export default investment;
