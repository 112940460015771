import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import TextInput from '../../../TextInput';
import Select from '../../../Select';
import CheckBox from '../../../CheckBox';

const DerivedKpiOptions = ({ type, metric, unit, firstKpiId, secondKpiId, kpiOperation, timeShift, accumulatorPeriod, accumulatorOperation, unitOptions, kpiOptions, kpiOperations, timeShiftOptions, accumulatorPeriodOptions, accumulatorOperationOptions, addToAllInvestments, updateState, allowKpiAllInvestments }) => {
  const formattedKpiLabels = { sum: 'KPI_1 + KPI_2', subtract: 'KPI_1 - KPI_2', multiply: 'KPI_1 * KPI_2', ratio: 'KPI_1 / KPI_2', growth: '(KPI_1 / KPI_2) - 1' };
  const formattedKpiOperations = kpiOperations.map(option => ({ label: `${option.label}: ${formattedKpiLabels[option.value]}`, value: option.value }));
  const disabled = (block = false) => {
    if (type === 'view') {
      return true;
    }
    if (type === 'edit') {
      if (block) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <div className="col-8 flex flex-column items-center">
      <div className="col-12 mb1 fw400">
        <span>{type === 'add' ? 'Specify metric' : 'Metric'}</span>
      </div>
      <TextInput
        name="metric"
        value={metric || undefined}
        className="col-12 border border-lighter-gray hover hover-border-blue mb2"
        placeholder="Metric name"
        onChange={e => updateState('metric', e.target.value)}
        disabled={disabled()}
      />

      <Select
        className="col-12 text-gray lh-125 border border-lighter-gray bg-none mb2"
        style={{ height: '36px' }}
        value={unit || undefined}
        name="unit_select"
        placeholder="Choose appropriate units"
        options={unitOptions}
        onChange={e => updateState('unit', e.target.value)}
        disabled={disabled()}
      />

      {/* First KPI */}
      <div
        className="mb2 col-12"
        data-balloon="The first KPI of the calculation to derive from."
        data-balloon-pos="right"
      >
        <div className="col-12 mb1 fw400">
          <span>{disabled(true) ? 'First KPI' : 'Select an existing First KPI below'}</span>
        </div>
        <ReactSelect
          value={kpiOptions.filter(option => option.value === firstKpiId)}
          options={kpiOptions}
          onChange={(selectedOption) => updateState('firstKpiIdState', selectedOption ? selectedOption.value : null)}
          isClearable={!disabled(true)}
          placeholder="Select..."
          isDisabled={disabled(true)}
        />
      </div>

      {/* Second KPI */}
      <div
        className="mb2 col-12"
        data-balloon="The second KPI of the calculation to derive from."
        data-balloon-pos="right"
      >
        <div className="col-12 mb1 fw400">
          <span>{disabled(true) ? 'Second KPI' : 'Select an existing Second KPI below'}</span>
        </div>
        <ReactSelect
          value={kpiOptions.filter(option => option.value === secondKpiId)}
          options={kpiOptions}
          onChange={(selectedOption) => updateState('secondKpiIdState', selectedOption ? selectedOption.value : null)}
          isClearable={!disabled(true)}
          placeholder="Select..."
          isDisabled={disabled(true)}
        />
      </div>

      {/* KPI Operation */}
      <div
        className="mb2 flex flex-column col-12"
        data-balloon="How data values will be calculated and the resulting values will get saved into the derived KPI."
        data-balloon-pos="right"
      >
        <span className="flex flex-start">KPI Operation</span>
        <ReactSelect
          value={formattedKpiOperations.filter(option => option.value === kpiOperation)}
          options={formattedKpiOperations}
          onChange={(selectedOption) => updateState('kpiOperationState', selectedOption.value)}
          placeholder="KPI Operation"
          className="col-12"
          isDisabled={disabled()}
        />
      </div>

      {/* Time Shift */}
      <div
        className="mb2 flex flex-column col-12"
        data-balloon="The amount of time that will get shifted between the first and second KPI's data values."
        data-balloon-pos="right"
      >
        <span className="flex flex-start">Time Shift</span>
        <ReactSelect
          value={timeShiftOptions.filter(option => option.value === timeShift)}
          options={timeShiftOptions}
          onChange={(selectedOption) => updateState('timeShiftState', selectedOption.value)}
          placeholder="Time Shift"
          className="col-12"
          isDisabled={disabled()}
        />
      </div>

      {/* Accumulator Period */}
      <div
        className="mb2 flex flex-column col-12"
        data-balloon="This is the frequency of the resulting KPI."
        data-balloon-pos="right"
      >
        <span className="flex flex-start">Accumulator Period</span>
        <ReactSelect
          value={accumulatorPeriodOptions.filter(option => option.value === accumulatorPeriod)}
          options={accumulatorPeriodOptions}
          onChange={(selectedOption) => updateState('accumulatorPeriodState', selectedOption.value, true)}
          placeholder="Accumulator Period"
          className="col-12"
          isDisabled={disabled(true)}
        />
      </div>

      {/* Accumulator Operation */}
      <div
        className="mb2 flex flex-column col-12"
        data-balloon="This is the operation in which the data values will be accumulated by."
        data-balloon-pos="right"
      >
        <span className="flex flex-start">Accumulator Operation</span>
        <ReactSelect
          value={accumulatorOperationOptions.filter(option => option.value === accumulatorOperation)}
          options={accumulatorOperationOptions}
          onChange={(selectedOption) => updateState('accumulatorOperationState', selectedOption.value)}
          placeholder="Accumulator Operation"
          className="col-12"
          isDisabled={disabled()}
        />
      </div>

      {allowKpiAllInvestments && !disabled(true) &&
        <div className="col-12 flex">
          <CheckBox
            defaultValue={addToAllInvestments}
            fixedWidth
            onChange={() => updateState('addToAllInvestments', !addToAllInvestments)}
          />
          <span className="text-light-gray ml1 h5">Add KPI to all investments</span>
        </div>
      }
    </div>
  );
};

DerivedKpiOptions.propTypes = {
  type: PropTypes.string,
  metric: PropTypes.string,
  unit: PropTypes.string,
  firstKpiId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  secondKpiId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  kpiOperation: PropTypes.string.isRequired,
  timeShift: PropTypes.string.isRequired,
  accumulatorPeriod: PropTypes.string.isRequired,
  accumulatorOperation: PropTypes.string.isRequired,
  unitOptions: PropTypes.array.isRequired,
  kpiOptions: PropTypes.array.isRequired,
  kpiOperations: PropTypes.array.isRequired,
  timeShiftOptions: PropTypes.array.isRequired,
  accumulatorPeriodOptions: PropTypes.array.isRequired,
  accumulatorOperationOptions: PropTypes.array.isRequired,
  addToAllInvestments: PropTypes.bool.isRequired,
  updateState: PropTypes.func,
  allowKpiAllInvestments: PropTypes.bool.isRequired,
};

DerivedKpiOptions.defaultProps = {
  metric: null,
  unit: null,
  firstKpiId: null,
  secondKpiId: null,
  updateState: null,
  type: 'add',
};

export default DerivedKpiOptions;
