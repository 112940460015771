import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../store/configureStore';
import CaptableContainer from '../containers/CapTableContainer';


const Captable = props => (
  <Provider store={configureStore(props)}>
    <CaptableContainer />
  </Provider>
);

export default Captable;
