import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import Modal from '../../Modal';
import TextInput from '../../TextInput';
import CleaveNumberFormatting from '../../CleaveNumberFormatting';
import SelectWithImage from '../../selects/SelectWithImage';
import Textarea from '../../Textarea';
import InvestorsAndCompaniesSearch from '../../onboarding/utils/InvestorsAndCompaniesSearch';

import { defaultSelectStyles, defaultSelectTheme } from '../../selects/SelectStyles';


class ShareholderModal extends Component {
  static propTypes = {
    share: PropTypes.object,
    isInvited: PropTypes.bool,
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    openShareClassModal: PropTypes.func.isRequired,
    capTable: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    createShare: PropTypes.func.isRequired,
    updateShare: PropTypes.func.isRequired,
  }

  static defaultProps = {
    share: null,
    isInvited: false,
  }

  constructor(props) {
    super(props);

    this.initialState = {
      share: {
        owner_name: null,
        shareholder_id: null,
        shareholder_type: null,
        total_shares: null,
        share_options: null,
        note: null,
        share_class_id: null,
      },
      shareholderType: 'connections',
      saving: false,
    };

    this.state = this.initialState;
  }

  componentWillReceiveProps = (nextProps) => {
    const { share } = nextProps;

    if (share) {
      this.setState({ share }, () => {
        if (!share.shareholder_id) {
          this.setState({ shareholderType: 'new' });
        }
      });
    }
  }

  handleOpenShareClassModal = async () => {
    const { closeModal, openShareClassModal } = this.props;

    await closeModal();

    setTimeout(() => openShareClassModal(), 300);
  }


  handleClose = () => {
    const { closeModal } = this.props;

    this.setState(this.initialState);

    closeModal();
  }

  handleTypeSelect = (type) => {
    const { share } = this.state;

    let newState = { shareholderType: type };

    if (type === 'connections') {
      newState = { ...newState, share: { ...share, owner_name: null } };
    } else if (type === 'new' || type === 'invited') {
      newState = { ...newState, share: { ...share, shareholder_id: null, shareholder_type: null, owner_name: null } };
    }

    this.setState(newState);
  }

  handleResult = (result) => {
    if (result.id) {
      this.handleChange('shareholder_id', result.id);
      this.handleChange('shareholder_type', 'User');
    } else {
      this.handleChange('shareholder_id', null);
      this.handleChange('shareholder_type', null);
    }

    this.handleChange('owner_name', result.email);
  }

  handleChange = (name, value) => {
    this.setState(state => ({
      ...state,
      share: {
        ...state.share,
        [name]: value,
      },
    }));
  }

  submit = async () => {
    const { share: shareProps, createShare, updateShare } = this.props;
    const { share, saving, shareholderType } = this.state;

    if (saving) return;

    this.setState({ saving: true });

    if (!share.owner_name) {
      App.State.setFlash({ name: 'alert', msg: 'Required fields are missing!' });
      this.setState({ saving: false });
      return;
    }

    let params = { cap_share: share };

    try {
      if (shareProps) {
        await updateShare(params);
      } else {
        params = { ...params, invited: shareholderType === 'invited' };
        await createShare(params);
      }
      this.handleClose();
    } catch (err) {
      this.setState({ saving: false });
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
    }
  }

  render() {
    const { show, capTable, company, share: shareProps, isInvited } = this.props;
    const { shareholderType, share, saving } = this.state;

    const typeOptions = [
      { value: 'connections', label: 'Shareholder from Connections' },
      { value: 'new', label: 'New Shareholder' },
      // { value: 'invited', label: 'Invite Shareholder' }, add & invited is blocked for now
    ];

    return (
      <Modal show={show} widthClass="react_modal--600">
        <div className="flex flex-justify-between items-center bold p2 px3 border-bottom border-lighter-gray">
          <div className="h3">{shareProps ? 'Edit' : 'Add'} Shareholder</div>
          <i className="fa fa-close h5 cursor-pointer" onClick={this.handleClose} />
        </div>

        <div className="text-gray fw400 px3 py2 h5">
          {!isInvited &&
          <>
            <div className="flex col-12">
              <div className="flex flex-column col-6 mr2">
                <span>Type</span>
                <ReactSelect
                  className="mt1"
                  name="shareholderType"
                  value={typeOptions.filter(option => (option.value === shareholderType))}
                  options={typeOptions}
                  onChange={selectedOption => this.handleTypeSelect(selectedOption.value)}
                  isClearable={false}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                />
              </div>
              <div className="flex flex-column col-6">
                {shareholderType === 'new' &&
                  <React.Fragment>
                    <span>Shareholder Name <span className="text-red">*</span></span>
                    <TextInput
                      value={share.owner_name || ''}
                      className="col-12 border border-gallery mt1 input-alto__focus"
                      name="owner_name"
                      onChange={e => this.handleChange(e.target.name, e.target.value)}
                    />
                  </React.Fragment>
                }
                {shareholderType === 'connections' &&
                  <React.Fragment>
                    <span>Select below <span className="text-red">*</span></span>
                    <SelectWithImage
                      className="mt1"
                      name="shareholder"
                      value={capTable.shareholder_options.filter(option => (option.value === share.shareholder_id))}
                      options={capTable.shareholder_options}
                      onChange={selectedOption => { this.handleChange('shareholder_id', selectedOption.value); this.handleChange('shareholder_type', selectedOption.type); this.handleChange('owner_name', selectedOption.label); }}
                      isClearable={false}
                      styles={defaultSelectStyles}
                      theme={defaultSelectTheme}
                    />
                  </React.Fragment>
                }
                {/* add & invite is blocked for now */}
                {shareholderType === 'invited' && false &&
                  <React.Fragment>
                    <span className="mb1">Search/Add below <span className="text-red">*</span></span>
                    <InvestorsAndCompaniesSearch
                      searchUrl={`/c/${company.id}/invited_investors`}
                      resultSelect={result => this.handleResult(result)}
                      placeholder="Add an email address..."
                      emptyFieldAfterSelect={false}
                      height="26px"
                      inputClass="input-alto__focus"
                    />
                  </React.Fragment>
                }
              </div>
            </div>
          </>
          }

          {isInvited ?
            <div className="mt1 lh-12 flex flex-column">
              <span className="text-light-gray h5 light">
                Invited Shareholders cannot have their email changed because they have already received an invitation email.
              </span>
            </div>
            :
            <div className="mt1 lh-12 flex flex-column">
              <span className="text-light-gray h5 light">
                Adding a shareholder from your connections, enables your connected investors to see all shares assigned to
                them inside their own investory account (if access is enabled). While shareholders from connections require an invite
                to investory a shareholder can be added without invitation.
              </span>

              {capTable.shareholder_options.length === 0 &&
                <span className="text-gray mt2">There are no connections. You can invite investors <a href={`/c/${company.id}/investments`}>here.</a></span>
              }
            </div>
          }

          <div className="flex flex-justify-between items-center">
            <div className="flex-auto border-top border-gallery my3" />
            <span className="center px2 text-medium-gray">Total Owned Shares</span>
            <div className="flex-auto border-top border-gallery my3" />
          </div>

          <div className="flex">
            <div className="col-6 flex flex-column mr3">
              <span>Total Shares</span>
              <CleaveNumberFormatting
                value={`${share.total_shares}`}
                className="col-12 border border-gallery mt1 input-alto__focus"
                name="total_shares"
                onChange={e => this.handleChange(e.target.name, e.target.rawValue)}
              />
            </div>
            <div className="col-6 flex flex-column">
              <span>Total Share Options</span>
              <CleaveNumberFormatting
                value={`${share.share_options}`}
                className="col-12 border border-gallery mt1 input-alto__focus"
                name="share_options"
                onChange={e => this.handleChange(e.target.name, e.target.rawValue)}
              />
            </div>
          </div>
          <div className="mt2 lh-12">
            <span className="text-light-gray h5 light">
              The total amount of shares or share options owned by the shareholder, represents the total amount of shares/share options the shareholder has/had in a given funding round. Share Options are shares that
              have not yet been fully issued to the shareholder, due to reasons like unfulfilled contractual obligations (vesting periods) or pools of shares that will be issued to individual shareholders later.
            </span>
          </div>

          <div className="flex flex-justify-between items-center mt2">
            <div className="flex-auto border-top border-gallery my2" />
            <span className="center px2 text-medium-gray">Share Class</span>
            <div className="flex-auto border-top border-gallery my2" />
          </div>

          <div>
            <ReactSelect
              className="mt1"
              name="share_class"
              value={capTable.share_class_options.filter(option => (option.value === share.share_class_id))}
              options={capTable.share_class_options}
              onChange={selectedOption => this.handleChange('share_class_id', selectedOption.value)}
              isClearable={false}
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
            />
          </div>

          <div className="mt2 lh-12 flex flex-column">
            <span className="text-light-gray h5 light">
              A share class is a description applied to a category of shares and carry different rights and privileges. The share classes carry different rights and privileges - only Common and Preferred share classes are pre-defined by default.
              You can create more by adding them manually.
            </span>

            {capTable.share_class_options.length === 0 &&
              <div className="mt2">
                There are no Share Classes. You can create a new one by clicking <span className="text-blue cursor-pointer" onClick={this.handleOpenShareClassModal}>here.</span>
              </div>
            }
          </div>

          <div className="flex flex-justify-between items-center mt2">
            <div className="flex-auto border-top border-gallery my2" />
            <span className="center px2 text-medium-gray">Additional Notes</span>
            <div className="flex-auto border-top border-gallery my2" />
          </div>

          <div className="pt1">
            <Textarea
              value={share.note || ''}
              name="note"
              className="border border-alto lh-15 col-12 input-alto__focus h5"
              placeholder="Write a comment here..."
              onChange={e => this.handleChange(e.target.name, e.target.value)}
            />
          </div>
        </div>

        <div className="border-top border-gallery px3 py2 fw400 flex flex-justify-end items-center">
          <div className="text-blue mr2 cursor-pointer" onClick={this.handleClose}>Cancel</div>
          <div className={`text-white bg-blue px3 py1 rounded ${saving ? 'cursor-disabled' : 'cursor-pointer'}`} onClick={this.submit}>{saving ? 'Saving...' : 'Save'}</div>
        </div>
      </Modal>
    );
  }
}

export default ShareholderModal;
