import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import axios from 'axios';

class CompanyInvestmentAdmin extends Component {
  static propTypes = {
    manage_url: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
  }

  state = {
    open: false,
    loading: false,
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
    document.addEventListener('touchstart', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
    document.removeEventListener('touchstart', this.handleClick, false);
  }

  handleClick = (e) => {
    if (ReactDOM.findDOMNode(this).contains(e.target)) return;
    this.setState({ open: false });
  }

  deleteInvestment = async () => {
    const { manage_url: manageUrl, name, id } = this.props;
    const confirmed = window.confirm(`Are your sure to remove ${name} from Investors?`);

    if (confirmed) {
      this.setState({ loading: true });
      try {
        await axios.delete(manageUrl);

        this.setState({ loading: false }, () => {
          App.State.setFlash({ name: 'notice', msg: 'Investor is removed!' });
          store.dispatch({ type: 'REMOVE_ITEM', list: 'main', id });
        });
      } catch (err) {
        this.setState({ loading: false }, () => {
          App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
        });
      }
    }
  }

  render() {
    const {
      open,
      loading,
    } = this.state;

    return (
      <div className="relative cursor-pointer">
        <div className="text-light-gray cursor-pointer" onClick={() => { this.setState({ open: !open }); }}>
          <i className={`fa fa-fw ${loading ? 'fa-refresh fa-spin' : 'fa-cog'} ml2`} />
        </div>

        <div className={`dropdown dropdown--center absolute bg-white border border-alto bs-dropdown left-50 transform-horizontal-center z1 ${open ? '' : 'hidden'} py1`}>
          <ul className="left-align m0 mw150">
            <li>
              <div
                className="bold h5 link-hover--blue block nowrap px1 text-gray cursor-pointer"
                onClick={this.deleteInvestment}
              >
                <i className="fa fa-fw fa-trash fa--dropdown ml1 right" />
                Remove investor
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default CompanyInvestmentAdmin;
