const recipientStep = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_RECIPIENTS':
      return {
        ...state,
        groups: action.response.groups || state.groups,
        externalUsers: action.response.externalUsers || state.externalUsers,
        contacts: action.response.contacts || state.contacts,
      };
    case 'TOOGLE_CONTACTS_LIST':
      return {
        ...state,
        showContactsList: !state.showContactsList,
      };

    default: return state;
  }
};

export default recipientStep;
