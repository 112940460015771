import axios from 'axios';

import addComment from '../actions/commentActions';

const createComment = (params) =>
  async (dispatch, getState) => {
    const linkToken = getState().dataRoom.link_token;
    const res = await axios.post(`/data_rooms/${linkToken}/comments`, params);
    dispatch(addComment(res.data));
  };

export default createComment;
