import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ConfirmationModal from './ConfirmationModal';

class Switch extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    onChange: PropTypes.func,
    labelLeft: PropTypes.string,
    labelRight: PropTypes.string,
    toggleNotificationsUrl: PropTypes.string,
    name: PropTypes.string,
    withConfirmation: PropTypes.bool,
    confirmationMessage: PropTypes.string,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    checked: null,
    defaultChecked: false,
    onChange: null,
    labelLeft: null,
    labelRight: null,
    toggleNotificationsUrl: null,
    name: null,
    withConfirmation: false,
    confirmationMessage: 'Are you sure?',
    disabled: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      checkedState: props.checked || props.defaultChecked,
    };
  }

  handleClick = () => {
    const { checkedState } = this.state;
    const { toggleNotificationsUrl, onChange, disabled } = this.props;

    if (disabled) return;

    this.setState({ checkedState: !checkedState });
    if (toggleNotificationsUrl) {
      this.toggleNotifications();
    } else {
      onChange();
    }
  }

  toggleNotifications = () => {
    const { toggleNotificationsUrl } = this.props;
    App.Api.post(toggleNotificationsUrl);
  }

  render() {
    const { checkedState } = this.state;
    const { checked, labelLeft, labelRight, name, withConfirmation, confirmationMessage, disabled } = this.props;
    const checkedSource = checked !== null && checked !== undefined ? checked : checkedState;

    const checkedClass = checkedSource ? 'fa fa-toggle-on' : 'fa fa-toggle-off';

    return (
      <span className="flex flex-center">
        <div
          className={`flex flex-center ${disabled ? 'text-light-gray cursor-disabled' : 'text-blue cursor-pointer'}`}
        >
          { labelLeft &&
            <span className="mr1 regular text-gray">{labelLeft}</span>
          }
          {withConfirmation && !checkedSource ?
            <ConfirmationModal
              html={`<span><i class="${checkedClass}" /></span>`}
              className="h2"
              onClick={this.handleClick}
              message={confirmationMessage}
              width=""
            />
            :
            <span
              className="h2"
              onClick={this.handleClick}
            >
              <i className={checkedClass} />
            </span>
          }

          { labelRight &&
            <span className="ml1 regular text-gray">{labelRight}</span>
          }
        </div>
        {name &&
          <input type="hidden" name={name} value={checkedSource} />
        }
      </span>
    );
  }
}

export default Switch;
