/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import NavButton from './common/NavButton';
import ConfigTabs from './common/ConfigTabs';
import GeneralConfigForm from './config/GeneralConfigForm';
import ContentConfigForm from './config/ContentConfigForm';
import SettingsConfigForm from './config/SettingsConfigForm';


class Slideshow extends React.Component {
  static propTypes = {
    slideshow: PropTypes.object.isRequired,
    changeCompanyFilters: PropTypes.func.isRequired,
    trackEvent: PropTypes.func,
  };

  static defaultProps = {
    trackEvent: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      companyFilters: [
        {
          name: 'industry',
          filter: 'all',
        },
        {
          name: 'status',
          filter: 'all',
        },
        {
          name: 'business_type',
          filter: 'all',
        },
        {
          name: 'fundraising_status',
          filter: 'all',
        },
        {
          name: 'funds',
          filter: 'all',
        },
        {
          name: 'stage',
          filter: 'all',
        },
      ],
      slideshowContent: {
        intro: true,
        overview: true,
        companies: true,
        outro: true,
      },
      activeTab: 0,
      mainColor: props.slideshow.main_color || '#2E75B5',
      secondaryColor: props.slideshow.secondary_color || '#2E75B5',
      autoplay: props.slideshow.autoplay,
      autoplayDuration: props.slideshow.autoplay_duration,
      expiresIn: 180,
      expiresInDate: props.slideshow.expires_at || '',
      logo: null,
      link: '',
      protectedLink: '',
    };

    this.configTabs = [
      'General',
      'Content',
      'Settings',
    ];
    this.slideshowTitle = 'Portfolio Overview';
    this.slideshowSubtitle = props.slideshow.subtitle || '';
    this.slideshowContactEmail = props.slideshow.contact_email || '';
    this.slideshowContactUrl = props.slideshow.website_url || '';
  }

  componentDidMount = () => {
    // All companies selected by default
    const {
      slideshow,
      changeCompanyFilters,
    } = this.props;
    const initialSelection = slideshow.investment_options.map(company => ({ id: company.id, selected: true }));
    changeCompanyFilters(initialSelection);
  };

  handleTabChange = tabIndex => {
    this.setState({ activeTab: tabIndex });
  }

  handleTitleChange = e => {
    this.slideshowTitle = e.target.value;
  }

  handleSubtitleChange = e => {
    this.slideshowSubtitle = e.target.value;
  }

  handleContactEmailChange = e => {
    this.slideshowContactEmail = e.target.value;
  }

  handleContactUrlChange = e => {
    this.slideshowContactUrl = e.target.value;
  }

  handleChangeSlideShowContent = slides => {
    const { slideshowContent } = this.state;
    const newContent = Object.keys(slideshowContent).reduce((newObj, type) => {
      newObj[type] = slides.find(slide => slide.value === type) !== undefined;
      return newObj;
    }, {});
    this.setState({
      slideshowContent: newContent,
    });
  };

  handleChangeCompanyFilters = selection => {
    const { changeCompanyFilters } = this.props;
    changeCompanyFilters(selection);
  };

  handleChangeFilteringCriteria = newFilter => {
    const { companyFilters } = this.state;
    const {
      slideshow,
      changeCompanyFilters,
    } = this.props;
    const newFilters = companyFilters.map(filter => {
      if (filter.name === newFilter.name) {
        return newFilter;
      }
      return filter;
    });
    // de-select filtered-out companies
    const companies = slideshow.investment_options;
    const selection = companies.map(company => {
      const selected = newFilters.reduce((allValid, filter) => {
        const currentValid = filter.filter === 'all'
          || company[filter.name] === filter.filter
          || (Array.isArray(company[filter.name]) && company[filter.name].includes(filter.filter));
        return allValid && currentValid;
      }, true);
      return { id: company.id, selected };
    });
    changeCompanyFilters(selection);

    this.setState({
      companyFilters: newFilters,
    });
  };

  handleSubmitSlideshowConfig = async (start = true, password) => {
    const {
      autoplay,
      autoplayDuration,
      mainColor,
      secondaryColor,
      logo,
      expiresInDate,
    } = this.state;
    const {
      slideshow,
      trackEvent,
    } = this.props;

    // build payload
    const formData = new FormData();
    formData.append('slideshow[expires_at]', expiresInDate);
    this.slideConfig(slideshow.slides).forEach(slide => {
      formData.append('slideshow[slides_attributes][][slide_type]', slide.slide_type);
    });
    this.companySelection().forEach(id => {
      formData.append('slideshow[investment_ids][]', id);
    });
    formData.append('slideshow[title]', this.slideshowTitle);
    formData.append('slideshow[subtitle]', this.slideshowSubtitle);
    formData.append('slideshow[contact_email]', this.slideshowContactEmail);
    formData.append('slideshow[website_url]', this.slideshowContactUrl);
    formData.append('slideshow[main_color]', mainColor);
    formData.append('slideshow[secondary_color]', secondaryColor);
    formData.append('slideshow[autoplay]', autoplay);
    formData.append('slideshow[autoplay_duration]', autoplayDuration);
    if (logo) {
      formData.append('slideshow[logo]', logo);
    }
    const newWindow = start && window.open('', '_target');
    if (password) {
      formData.append('slideshow[password]', password);
    }

    try {
      const res = await axios.post(`/investor/${slideshow.investor.id}/slideshows?fund_id=${slideshow.fund_id}`, formData);
      if (trackEvent) await trackEvent('dashboard_reports_events', 'start_slideshow');
      if (password) {
        this.setState({
          protectedLink: res.data.link,
        });
      } else {
        this.setState({
          link: res.data.link,
        });
      }
      if (newWindow) {
        newWindow.location = res.data.link;
      }
    } catch (err) {
      console.log(err.response.data.errors);
    }
  };

  handleLogoChange = newLogo => {
    this.setState({
      logo: newLogo,
    });
  };

  handleColorChange = (newColor, colorField) => {
    this.setState({
      [colorField]: newColor.hex,
    });
  };

  handleExpiresInChange = days => {
    const { expiresIn } = this.state;
    if (days !== expiresIn) {
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + days);
      endDate.setHours(23, 59, 59, 999);

      this.setState({
        expiresIn: days,
        expiresInDate: endDate,
      });
    }
  };

  handleAutoplayChange = () => {
    const { autoplay } = this.state;
    this.setState({
      autoplay: !autoplay,
    });
    this.handleSubmitSlideshowConfig(false);
  };

  handleAutoplayDurationChange = duration => {
    const { autoplayDuration } = this.state;
    if (duration !== autoplayDuration) {
      this.setState({
        autoplayDuration: duration,
      });
      this.handleSubmitSlideshowConfig(false);
    }
  };

  handleExpirationDateChange = days => {
    const { expiresIn } = this.state;
    if (days !== expiresIn) {
      const endDate = new Date();
      endDate.setHours(23, 59, 59, 999);
      endDate.setDate(endDate.getDate() + days);

      this.setState({
        expiresIn: days,
        expiresInDate: endDate,
      });
      this.handleSubmitSlideshowConfig(false);
    }
  };

  handleRequireLink = password => {
    this.handleSubmitSlideshowConfig(false, password);
  }

  slideConfig = () => {
    const { slideshowContent } = this.state;
    return Object.keys(slideshowContent).reduce((arr, slideType) => {
      if (slideshowContent[slideType]) {
        arr.push({ slide_type: slideType });
      }
      return arr;
    }, []);
  };

  companySelection = () => {
    const { slideshow } = this.props;
    return slideshow.investment_options.reduce((selectedIds, company) => {
      if (company.selected) {
        selectedIds.push(company.id);
      }
      return selectedIds;
    }, []);
  };

  applyCompanyFilters = companies => {
    const { companyFilters } = this.state;
    return companyFilters.reduce((filteredArray, filter) => {
      if (filter.filter === 'all') {
        return filteredArray;
      }
      return filteredArray.filter(company => company[filter.name] === filter.filter
        || (Array.isArray(company[filter.name]) && company[filter.name].includes(filter.filter)));
    }, companies);
  };

  formatOptions = option => {
    const { slideshow } = this.props;
    const optionField = `${option}_options`;
    return slideshow[optionField].map(statusObj => [statusObj.id, statusObj.name, statusObj.color_hex]);
  };

  validateConfig = () => {
    const { slideshowContent } = this.state;
    const { slideshow } = this.props;
    const noSlides = Object.keys(slideshowContent).find(slide => slideshowContent[slide]) === undefined;
    const noCompanies = slideshow.investment_options.find(company => company.selected) === undefined;
    if (noCompanies || noSlides) {
      return false;
    }
    return true;
  }

  renderTabContent = tab => {
    const {
      autoplay,
      mainColor,
      secondaryColor,
      autoplayDuration,
      slideshowContent,
      link,
      protectedLink,
      logo,
      expiresIn,
    } = this.state;
    const { slideshow } = this.props;
    const slides = this.slideConfig();
    const companies = slideshow.investment_options;
    const statusOptions = [['all', 'All']].concat(companies.length > 0 ? this.formatOptions('status') : []);
    const fundOptions = [['all', 'All']].concat(companies.length > 0 ? slideshow.fund_options.map(fund => [fund, fund]) : []);
    const fundraisingOptions = [['all', 'All']].concat(companies.length > 0 ? this.formatOptions('fundraising') : []);
    const industryOptions = [['all', 'All']].concat(companies.length > 0 ? slideshow.industry_options : []);
    const businessOptions = [['all', 'All']].concat(companies.length > 0 ? slideshow.business_type_options : []);
    const stageOptions = [['all', 'All']].concat(companies.length > 0 ? slideshow.stage_options : []);
    const filteredCompanies = this.applyCompanyFilters(companies);
    const currentLogo = logo ? slideshow.logo : slideshow.investor.avatar;

    switch (tab) {
      case 'General':
        return (
          <GeneralConfigForm
            className="slideshow-config-form"
            onTitleChange={this.handleTitleChange}
            onSubtitleChange={this.handleSubtitleChange}
            onAutoplayChange={this.handleAutoplayChange}
            onAutoplayDurationChange={this.handleAutoplayDurationChange}
            onExpiresInChange={this.handleExpirationDateChange}
            onRequireLink={this.handleRequireLink}
            title={this.slideshowTitle}
            subtitle={this.slideshowSubtitle}
            shareableLink={link}
            protectedLink={protectedLink}
            autoplayDuration={autoplayDuration}
            autoplay={autoplay}
            expiresIn={expiresIn}
          />
        );
      case 'Content':
        return (
          <ContentConfigForm
            className="slideshow-config-form"
            slides={slides}
            statusOptions={statusOptions}
            fundraisingOptions={fundraisingOptions}
            industryOptions={industryOptions}
            businessOptions={businessOptions}
            fundOptions={fundOptions}
            stageOptions={stageOptions}
            slideshowContentOptions={Object.keys(slideshowContent)}
            companies={filteredCompanies}
            onChangeSlideshowContent={this.handleChangeSlideShowContent}
            onChangeCompanyFilters={this.handleChangeCompanyFilters}
            onChangeFilteringCriteria={this.handleChangeFilteringCriteria}
          />
        );
      case 'Settings':
        return (
          <SettingsConfigForm
            className="slideshow-settings-form"
            onLogoChange={this.handleLogoChange}
            onColorChange={this.handleColorChange}
            mainColor={mainColor}
            secondaryColor={secondaryColor}
            logo={currentLogo}
            file={logo}
            contactEmail={this.slideshowContactEmail}
            onContactEmailChange={this.handleContactEmailChange}
            contactUrl={this.slideshowContactUrl}
            onContactUrlChange={this.handleContactUrlChange}
          />
        );
      default: return null;
    }
  };

  startWithFundsFilter = fundName => {
    const { companyFilters } = this.state;
    const resetFilters = companyFilters.map(filter => ({ name: filter.name, filter: 'all' }));
    this.handleChangeFilteringCriteria({ name: 'funds', filter: fundName });
    this.handleSubmitSlideshowConfig();
    this.handleChangeFilteringCriteria(resetFilters);
  }

  render() {
    const { activeTab } = this.state;
    const { slideshow } = this.props;
    const slideshowButtonEnabled = this.validateConfig();
    const countSelected = slideshow.investment_options.reduce((counter, company) => counter += company.selected ? 1 : 0, 0);
    const fundFilter = slideshow.fund_options.find(option => option === 'Scouts');

    return (
      <div className="slideshow-config mt1">
        <div className="slideshow-config centered">
          <div className="slideshow-config-card">
            <div className="slideshow-config-card-content">
              <div className="slideshow-config-nav">
                <ConfigTabs
                  options={this.configTabs}
                  onTabChange={this.handleTabChange}
                  active={activeTab}
                  contentCount={countSelected}
                />
              </div>
              <div className="slideshow-config-body mt3 px2">
                {this.renderTabContent(this.configTabs[activeTab])}
              </div>
            </div>
            <nav className="slideshow-config-actions">
              <NavButton
                labelText="Start Slideshow Now"
                onClick={this.handleSubmitSlideshowConfig}
                enabled={slideshowButtonEnabled}
              />
              {fundFilter &&
                <div className="ml2">
                  <NavButton
                    labelText="Start Pre-Seed Slideshow Now"
                    onClick={() => this.startWithFundsFilter(fundFilter)}
                    enabled={slideshowButtonEnabled}
                    bgColor="bg-blue"
                  />
                </div>
              }
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default Slideshow;
