import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TextInput from '../../../TextInput';
import CleaveNumberFormatting from '../../../CleaveNumberFormatting';
import DatePicker from '../../../selects/DatePicker';


class FundingField extends Component {
  components = {
    TextInput,
    CleaveNumberFormatting,
    DatePicker,
  }

  static propTypes = {
    edit: PropTypes.bool.isRequired,
    field: PropTypes.object.isRequired,
    hasPermission: PropTypes.bool.isRequired,
    component: PropTypes.string.isRequired,
    saveFundingFields: PropTypes.func.isRequired,
    balloonText: PropTypes.bool,
    balloonPos: PropTypes.string,
    balloonSize: PropTypes.string,
  }

  static defaultProps = {
    balloonText: false,
    balloonPos: 'top',
    balloonSize: 'large',
  }

  overwriteField = (id, e) => {
    const { field, saveFundingFields } = this.props;
    const value = e.target.rawValue || e.target.value;

    if (field.value === value) return;

    const params = {
      funding_field: {
        values: [{
          name: e.target.name,
          value,
        }],
      },
    };
    if (typeof id === 'string') {
      params.funding_field.values[0].overwrite = id;
    } else {
      params.funding_field.ids = [id];
    }

    saveFundingFields(id, params);
  }

  render() {
    const {
      edit,
      field,
      component,
      hasPermission,
      balloonText,
      balloonPos,
      balloonSize,
    } = this.props;

    const InputComponent = this.components[component];
    const inputClasses = classNames('col-12 bg-none right-align', {
      'mb1 border border-lighter-gray hover hover-border-blue': edit,
      'p0 border-transparent': !edit,
    });
    const indicator = classNames({
      'desktop__notification--changes-green': edit && typeof field.id === 'string',
      'desktop__notification--changes-blue': edit && (!hasPermission || typeof field.id === 'number'),
    });
    const inputStyling = {
      padding: edit ? null : '0',
      height: '36px',
    };

    return (
      <div className="flex flex-column py05 lh-125">
        <div
          className="pb1 fw400 cursor-default"
          data-balloon={balloonText ? field.hint : null}
          data-balloon-pos={balloonPos}
          data-balloon-length={balloonSize}
        >
          {field.name}
        </div>
        <div className={`relative ${indicator}`}>
          {!edit && field.readable_value ?
            <div className={`${inputClasses} flex items-center flex-justify-end`} style={{ height: '36px', lineHeight: 'normal' }}>
              {field.readable_value}
            </div>
            :
            <InputComponent
              className={inputClasses}
              name={field.name}
              value={(field.value || '').toString()}
              onBlur={e => this.overwriteField(field.id, e)}
              disabled={!edit}
              style={inputStyling}
            />
          }
        </div>
      </div>
    );
  }
}

export default FundingField;
