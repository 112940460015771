import React, { Component } from 'react';
import PropTypes from 'prop-types';


class DataRoomWidget extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  getLinkIcon = link => {
    if (link.includes('google.com')) {
      return ('fa-google');
    }
    if (link.includes('dropbox.com')) {
      return ('fa-dropbox');
    }
    return ('fa-link');
  }

  attachments = () => {
    const { data } = this.props;

    if (data.is_investor && !data.has_access) {
      return (
        <div className="flex col-12 center flex-column text-medium-gray">
          Access to the data room has been disabled.
        </div>
      );
    }
    if (data.files.length === 0) {
      return (
        <div className="flex col-12 center flex-column text-medium-gray">
          No files in the data room yet...
        </div>
      );
    }

    return data.files.map(file => (
      <a
        key={`file_${file.id}`}
        data-method={file.is_link ? null : 'get'}
        href={file.preview_url}
        target={file.is_link ? '_blank' : null}
      >
        <div className="conversation__attachment-item flex flex-center" style={{ borderTop: '1px solid #ddd' }}>

          <div className="block left mr1">
            <i className={`${file.is_link ? `fa ${this.getLinkIcon(file.preview_url)}` : file.icon_class_name} fa-2x`} style={{ color: file.icon_color }} />
          </div>

          <div className="lh-15">
            <p className="conversation__attachment-file" style={{ maxWidth: '140px' }} title={file.name}>
              {file.name}
            </p>
            <p className="conversation__attachment-author" style={{ maxWidth: '140px' }}>
              {file.updated_at}
            </p>
          </div>

          <span
            className="absolute cursor-pointer px1 right-0 rounded top-0"
            style={{
              marginRight: '10px',
              marginTop: '10px',
              paddingBottom: '3px',
              paddingTop: '3px',
              color: 'black',
            }}
          />
        </div>
      </a>
    ));
  }

  render() {
    const { data } = this.props;

    return (
      <div id="attachments" className="col-3 flex p1 sm-col-12">
        <div className="bg-white bs-around col-12 flex flex-column p1">
          <div className="flex mb2">
            <a className="bold text-gray" href={data.url}>Data Room</a>
            <div className="flex flex-auto flex-justify-end ml2" />
          </div>
          <div className="conversation__attachment" style={{ width: '100%', border: 'none', maxHeight: '270px', overflowY: 'auto' }}>
            {this.attachments()}
          </div>
        </div>
      </div>
    );
  }
}

export default DataRoomWidget;
