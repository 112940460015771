const updateRecipients = response => ({
  type: 'UPDATE_RECIPIENTS',
  response,
});

const toogleContacsListState = () => ({
  type: 'TOOGLE_CONTACTS_LIST',
});

export {
  updateRecipients,
  toogleContacsListState,
};
