import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Comments from '../Comments';


class DataRoomComments extends Component {
  static propTypes = {
    comments: PropTypes.object.isRequired,
    company: PropTypes.object,
    currentUser: PropTypes.object,
    editor: PropTypes.bool,
    createComment: PropTypes.func.isRequired,
    allowence: PropTypes.bool.isRequired,
    hasRecipients: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    company: {},
    currentUser: {},
    editor: false,
  }

  constructor(props) {
    super(props);

    if (props.hasRecipients) {
      const { collaborators, roomers } = props.comments.selectOptions;

      let contacts = collaborators || [];
      if (roomers) {
        contacts = [...contacts, ...roomers];
      }

      this.state = {
        recipients: contacts.map(r => this.recipientTemplate(r)),
        recipientListOptions: contacts.map(r => ({ ...r, isDisabled: true })),
      };
    } else {
      this.state = {
        recipients: null,
        recipientListOptions: null,
      };
    }
  }

  recipientTemplate = (recipient) => (
    {
      id: recipient.value,
      name: recipient.label,
      email: recipient.email,
      avatar: recipient.icon || window.images.userGray,
    }
  )

  removeRecipient = (recipientId) => {
    const { recipients, recipientListOptions } = this.state;

    const recipient = recipientListOptions.find(r => r.value === recipientId);
    recipient.isDisabled = false;

    this.setState(
      {
        recipients: recipients.filter(r => r.id !== recipientId),
        recipientListOptions: recipientListOptions.map(r => (r.value === recipientId ? recipient : r)),
      },
    );
  }

  addRecipient = (recipientId) => {
    const { recipients, recipientListOptions } = this.state;

    const recipient = recipientListOptions.find(r => r.value === recipientId);
    recipient.isDisabled = true;

    const template = this.recipientTemplate(recipient);

    this.setState(
      {
        recipients: [...recipients, template],
        recipientListOptions: recipientListOptions.map(r => (r.value === recipientId ? recipient : r)),
      },
    );
  }

  handleCreateComment = (comment) => {
    const { comments, createComment, hasRecipients } = this.props;
    const { recipients } = this.state;

    let params = { comment: { values: { body: comment } }, access_token: comments.access_token };

    if (hasRecipients) {
      params = { ...params, comment: { ...params.comment, emails: recipients.map(r => r.email) } };
    }

    return createComment(params);
  }

  render() {
    const { comments, allowence, currentUser, company, editor, hasRecipients } = this.props;
    const { recipients, recipientListOptions } = this.state;

    return (
      <Comments
        company={company}
        comments={comments.all}
        createComment={this.handleCreateComment}
        selectedRecipients={recipients}
        recipientListOptions={recipientListOptions}
        addRecipient={this.addRecipient}
        removeRecipient={this.removeRecipient}
        currentUser={currentUser}
        allowence={allowence}
        hasRecipients={allowence}
        canEditRecipients={allowence && hasRecipients && editor}
      />
    );
  }
}

export default DataRoomComments;
