import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextInputProps from '../TextInputProps';
import CheckBox from '../CheckBox';
import { validateEmail } from '../utils/validate_email';

class SigningRoomRequestSummary extends Component {
  static propTypes = {
    settings: PropTypes.object.isRequired,
  }

  state = {
    currentEmail: '',
    emailsRecipients: this.props.settings.emails.slice(),
    addAll: true,
    signingHeader: true,
    signingInformation: true,
    signingStatus: true,
    finalDocuments: true,
  }

  addRecipient = (e = null) => {
    const { emailsRecipients, currentEmail } = this.state;

    if (!e || (e && e.key === 'Enter')) {
      if (currentEmail.length === 0) {
        App.State.setFlash({ name: 'alert', msg: 'Please add an e-mail in the field' });
      } else if (emailsRecipients.includes(currentEmail)) {
        App.State.setFlash({ name: 'alert', msg: 'E-mail already added.' });
      } else if (!validateEmail(currentEmail)) {
        App.State.setFlash({ name: 'alert', msg: 'Invalid e-mail.' });
      } else {
        emailsRecipients.push(currentEmail);
        this.setState({ emailsRecipients });
      }
      this.setState({ currentEmail: '' });
    }
  }

  deleteRecipient = (email) => {
    const { settings } = this.props;
    const { emails } = settings;
    const { emailsRecipients } = this.state;
    const index = emailsRecipients.indexOf(email);

    if (index > -1) {
      emailsRecipients.splice(index, 1);
    }

    if (emails.includes(email)) {
      this.setState({ addAll: false });
    }
    this.setState({ emailsRecipients });
  }

  handleAddAll = () => {
    const { settings } = this.props;
    const { emails } = settings;
    const { addAll, emailsRecipients } = this.state;
    const innerAddAll = !addAll;

    if (innerAddAll) {
      emails.forEach((email) => {
        if (!emailsRecipients.includes(email)) {
          emailsRecipients.unshift(email);
        }
      });
    } else {
      emails.forEach(email => this.deleteRecipient(email));
    }
    this.setState({ addAll: innerAddAll, emailsRecipients });
  }

  handleSendSummary = (preview = false) => {
    const { settings } = this.props;
    const { emailsRecipients, signingHeader, signingInformation, signingStatus, finalDocuments } = this.state;
    const url = preview ? settings.send_preview_email_link : settings.send_summary_link;

    if (emailsRecipients.length === 0) {
      App.State.setFlash({ name: 'alert', msg: 'No e-mail added.' });
      return;
    }

    const params = {
      emailsRecipients,
      signingHeader,
      signingInformation,
      signingStatus,
      finalDocuments,
    };

    App.Api.post(url, params).then(() => {
      App.State.setFlash({ name: 'notice', msg: 'Summary Email Sent!' });
    },
    err => {
      App.State.setFlash({ name: 'alert', msg: err.responseJSON.error });
    });
  }

  /* validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
*/
  renderRecipients = () => {
    const { settings } = this.props;
    const { emails } = settings;
    const { emailsRecipients } = this.state;
    const recipients = emailsRecipients.map((email) => (
      <div className="flex mb2 col-12" key={email}>
        <div className="col-12">
          <div
            className="border border-alto p1 rounded bg-wild-sand col-12"
            style={{ height: '40px' }}
          >
            <span className="text-gray">{emails.includes(email) ? `${email} - Signer` : email}</span>
          </div>
        </div>
        <div className="flex items-center ml2">
          <div className="cursor-pointer" onClick={() => this.deleteRecipient(email)}>
            <i className="h3 fa fa-close text-light-gray" />
          </div>
        </div>
      </div>
    ));
    return recipients;
  }

  render() {
    const { addAll, signingHeader, signingInformation, signingStatus, finalDocuments } = this.state;
    const { currentEmail } = this.state;
    return (
      <div className="container container-report lg-px2 bg-white py3 my3 px3 bs-around relative sm-h-auto sm-mh-auto">
        <div className="flex flex-column flex-justify-center px2">
          <div className="col-12 my2">
            <span className="h2 fw400 mb1 py1">Send a Summary via Email</span>
          </div>
          <div className="col-12 py2">
            <div className="border border-lighter-gray">
              <div className="flex flex-column">
                {/* Summary Recipients */}
                <div className="flex flex-justify-between p1 px2 bg-wild-sand text-gray">
                  <div className="flex items-center">
                    <i className="fa fa-envelope-o mr1" />
                    <span className="fw400">
                      Recipients
                    </span>
                  </div>
                  <div className="flex">
                    <CheckBox
                      fixedWidth
                      defaultChecked={addAll}
                      onChange={() => this.handleAddAll()}
                      manualCheck
                      checked={addAll}
                    />
                    <span className="ml2">Add all Signer&apos;s</span>
                  </div>
                </div>
                <div className="p2">
                  {this.renderRecipients()}

                  <div className="mb2">
                    <div className="col-12 pr3">
                      <TextInputProps
                        className="border border-alto rounded lh-15 col-12"
                        value={currentEmail}
                        placeholder="Add recipient email address.."
                        onChange={e => this.setState({ currentEmail: e.target.value })}
                        onKeyPress={this.addRecipient}
                      />
                    </div>
                  </div>
                  <div className="border-top border-alto pt2 flex">
                    <div
                      className="bg-light-gray py1 px2 mr2 rounded cursor-pointer text-white"
                      onClick={() => this.addRecipient()}
                    >
                      <span className="fw400">Add Recipient</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 pb2">
            <div className="border border-lighter-gray">
              <div className="flex flex-column">
                {/* Summary Content */}
                <div className="flex items-center p1 px2 bg-wild-sand text-gray">
                  <i className="fa fa-list-alt mr1" />
                  <span className="fw400">
                    Content
                  </span>
                </div>
                <div className="p2 flex flex-column">
                  <div className="flex mb2">
                    <CheckBox
                      fixedWidth
                      defaultChecked={signingHeader}
                      onChange={() => this.setState({ signingHeader: !signingHeader })}
                    />
                    <span className="ml2">Signing Infos</span>
                  </div>
                  <div className="flex mb2">
                    <CheckBox
                      fixedWidth
                      defaultChecked={signingInformation}
                      onChange={() => this.setState({ signingInformation: !signingInformation })}
                    />
                    <span className="ml2">Document(s) to Sign</span>
                  </div>
                  <div className="flex mb2">
                    <CheckBox
                      fixedWidth
                      defaultChecked={signingStatus}
                      onChange={() => this.setState({ signingStatus: !signingStatus })}
                    />
                    <span className="ml2">Signer(s) Overview (with signed documents if available)</span>
                  </div>
                  <div className="flex">
                    <CheckBox
                      fixedWidth
                      defaultChecked={finalDocuments}
                      onChange={() => this.setState({ finalDocuments: !finalDocuments })}
                    />
                    <span className="ml2">Completed (aggregate) Document(s) (if available)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="py2 flex">
            <div className="p1 bg-blue rounded border border-blue mr2 cursor-pointer" onClick={() => this.handleSendSummary()}>
              <span className="text-white fw400">Send Summary</span>
            </div>
            <div className="p1 bg-white rounded border border-alto cursor-pointer" onClick={() => this.handleSendSummary(true)}>
              <span className="text-light-gray fw400">Send Preview</span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default SigningRoomRequestSummary;
