import React, { Component } from 'react';
import PropTypes from 'prop-types';


class NumberInput extends Component {
  static defaultProps = {
    onChange: null,
  }

  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  }

  constructor(props) {
    super(props);

    const value = props.value || '';

    this.state = {
      value: value.replace(/[^\.\d]/g, ''),
    };
  }

  onChange = e => {
    const { onChange } = this.props;
    const reg = /^\d+\.?\d*$/;
    if (reg.test(e.target.value)) {
      if (onChange) onChange(e);
      this.setState({ value: e.target.value });
    }
    if (e.target.value === '') {
      e.target.value = 0;
      if (onChange) onChange(e);
      this.setState({ value: '' });
    }
  }

  render() {
    const { onChange, value: v, ...other } = this.props;
    const { value } = this.state;

    return (
      <input
        type="number"
        onChange={this.onChange}
        value={value}
        {...other}
      />
    );
  }
}

export default NumberInput;
