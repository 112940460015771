import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../../../LoadingSpinner';
import InvestorSlideshow from '../../../../../startup/investor/InvestorSlideshow';

class SlideShow extends Component {
  static propTypes = {
    show: PropTypes.bool,
    report: PropTypes.object,
    blurred: PropTypes.bool,
  }

  static defaultProps = {
    show: false,
    report: null,
    blurred: null,
  }

  handle = () => {

  }

  render() {
    const { show, report, blurred } = this.props;

    return (show ?
      <React.Fragment>
        {!report.loading ?
          <React.Fragment>
            <div className="flex flex-justify-between h3 pt1 pb2 fw400 text-gray">
              Slideshow Setup
              <a className={blurred ? 'hide-data' : ''} data-method="get" href={report.data.slideshow_export_url}>
                <div className="flex flex-center text-light-gray h4">
                  <i className="fa fa-table" />
                  <span
                    className="ml1 light"
                    data-balloon="Excel export of all slideshow data (without formatting)."
                    data-balloon-length="large"
                    data-balloon-pos="down"
                  >Export All
                  </span>
                </div>
              </a>
            </div>
            <div className={blurred ? 'hide-data' : ''}>
              <InvestorSlideshow
                slideshow={report.data.slideshow}
              />
            </div>
          </React.Fragment>
          :
          <LoadingSpinner
            show={report.loading}
            type="fit"
            height="300px"
            background="transparent"
          />
        }
      </React.Fragment> : null
    );
  }
}

export default SlideShow;
