import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FaButtonFunction from './FaButtonFunction';


class CompanyPermissions extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  renderSwitch = (permission, name) => {
    if (permission) {
      return <FaButtonFunction key={`${name}_on`} faIcon="toggle-on" size="h2" color="green" handleClick={() => this.removePermission(name)} />;
    }

    return <FaButtonFunction key={`${name}_off`} faIcon="toggle-off" size="h2" color="light-gray" handleClick={() => this.addPermission(name)} />;
  }

  addPermission = async (permission) => {
    const { data } = this.props;
    const params = {
      permission_name: permission,
      permission_assignment: {
        assignable_type: data.assignable_type,
        assignable_id: data.assignable_id,
      },
    };
    const res = await App.Api.post(`/company/${data.company_id}/permission_assignments/`, params);
    store.dispatch({ type: 'SET_ITEM', list: 'main', id: res.id, value: res });
  }

  removePermission = async (permission) => {
    const { data } = this.props;
    const params = {
      permission_name: permission,
      permission_assignment: {
        assignable_type: data.assignable_type,
        assignable_id: data.assignable_id,
      },
    };
    const res = await App.Api.delete(`/company/${data.company_id}/permission_assignments/`, params);
    store.dispatch({ type: 'SET_ITEM', list: 'main', id: res.id, value: res });
  }

  render() {
    const { data } = this.props;

    return (
      <div className="flex col-12 sm-col-6">
        <div className="center py1 sm-hide" style={{ width: '12.5%' }}>
          {this.renderSwitch(data.dashboard_access, 'dashboard.show')}
        </div>

        <div className="center py1 sm-hide" style={{ width: '12.5%' }}>
          {this.renderSwitch(data.updates_access, 'updates.show')}
        </div>

        <div className="center py1 sm-hide" style={{ width: '12.5%' }}>
          {this.renderSwitch(data.discussions_access, 'discussions.show')}
        </div>

        <div className="center py1 sm-hide" style={{ width: '12.5%' }}>
          {this.renderSwitch(data.kpis_access, 'kpis.show')}
        </div>

        <div className="center py1 sm-hide" style={{ width: '12.5%' }}>
          {this.renderSwitch(data.data_rooms_access, 'data_rooms.show')}
        </div>

        <div className="center py1 sm-hide" style={{ width: '12.5%' }}>
          {this.renderSwitch(data.deal_rooms_access, 'deal_rooms.show')}
        </div>

        <div className="center py1 sm-hide" style={{ width: '12.5%' }}>
          {this.renderSwitch(data.cap_tables_access, 'cap_tables.show')}
        </div>

        <div className="center py1 sm-hide" style={{ width: '12.5%' }}>
          {this.renderSwitch(data.signing_rooms_access, 'signing_rooms.show')}
        </div>

        <div className="center py1 sm-hide" style={{ width: '12.5%' }}>
          {this.renderSwitch(data.connections_access, 'connections.show')}
        </div>
      </div>
    );
  }
}

export default CompanyPermissions;
