import React from 'react';
import { components } from 'react-select';

const { Option } = components;


const SelectCustomOption = (props) => {
  const { data, isSelected } = props;

  return (
    <Option {...props}>
      <div
        className="center align-middle relative inline-block"
        style={{
          marginRight: '10px',
          top: '-4px',
        }}
      >
        <img
          className="align-middle inline-block"
          height="18px"
          width="18px"
          alt={data.label}
          src={isSelected ? (data.selected_icon || data.icon || window.images.userBlue) : (data.icon || window.images.userGray)}
        />
      </div>
      <span className="center relative">{data.label}</span>
    </Option>
  );
};

export default SelectCustomOption;
