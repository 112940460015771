import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../Modal';
import Textarea from '../../../../Textarea';
import CleaveNumberFormatting from '../../../../CleaveNumberFormatting';


class FundingShareDetailsModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    currentShare: PropTypes.object,
    currentRow: PropTypes.number,
    isCustom: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    saveFundingShare: PropTypes.func.isRequired,
    edit: PropTypes.bool,
  };

  static defaultProps = {
    currentShare: null,
    currentRow: null,
    edit: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      showAutocomplete: true,
      ...this.buildShare({}),
    };

    this.initialState = this.state;
  }

  componentWillReceiveProps(nextProps) {
    const { currentShare } = this.props;

    if (currentShare) {
      if (currentShare.id !== nextProps.currentShare.id) {
        this.setState(this.buildShare({ ...nextProps.currentShare }));
      }
    } else {
      this.setState(this.buildShare({ ...nextProps.currentShare }));
    }
  }

  buildShare = ({
    comments = '',
    shares = null,
    share_options = null,
    book_value,
    convertible_principal = null,
    raw_shares = null,
    raw_share_options = null,
    raw_book_value,
    raw_convertible_principal = null,
  }) => (
    {
      share: {
        comments,
        shares: raw_shares,
        share_options: raw_share_options,
        book_value: raw_book_value,
        convertible_principal: raw_convertible_principal,
        formatted_shares: shares,
        formatted_share_options: share_options,
        formatted_book_value: book_value,
        formatted_convertible_principal: convertible_principal,
      },
    }
  )

  handleChange = e => {
    const { name, value, rawValue } = e.target;

    this.setState(state => ({
      ...state,
      share: {
        ...state.share,
        [name]: rawValue || value,
      },
    }));
  }

  submit = async () => {
    const {
      currentShare,
      closeModal,
      saveFundingShare,
    } = this.props;

    const { share } = this.state;

    try {
      await saveFundingShare(currentShare.id, { ...share });
      closeModal();
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
    }
  }

  render() {
    const {
      show,
      isCustom,
      closeModal,
      edit,
      currentRow,
    } = this.props;

    const {
      error,
      share,
    } = this.state;

    const labelClasses = 'flex-auto text-gray';
    const inputClasses = 'border border-lighter-gray hover hover-border-blue';

    return (
      <Modal
        show={show}
        onClickOutside={closeModal}
      >
        <div className="flex flex-justify-between items-center semi-bold p2 border-bottom border-lighter-gray">
          <div className="h3">More Details: investment row {currentRow}</div>
          <i className="fa fa-close h5 cursor-pointer" onClick={closeModal} />
        </div>

        <div className="fw400 px2 py2 overflow-y-auto" style={{ maxHeight: '65vh' }}>
          {error && <div className="border border-red--transparent bg-red--transparent h5 mb2 p1">{error}</div>}

          <div className="flex flex-column mb-50">
            <div className="mt2">
              <div className={labelClasses}>
                <span
                  data-balloon="If you entered FD # of shares, you can enter here how many of those are shares. # of Shares plus # of Options should equal # of FD shares."
                  data-balloon-pos="right"
                  data-balloon-length="xlarge"
                >
                  # of shares
                </span>
              </div>
              {!edit || !isCustom ?
                <div className={`${inputClasses} flex items-center col-6`} style={{ height: '36px', lineHeight: 'normal' }}>
                  {share.formatted_shares}
                </div>
                :
                <CleaveNumberFormatting
                  className={`${inputClasses} col-6`}
                  name="shares"
                  value={`${share.shares}`}
                  onChange={this.handleChange}
                />
              }
            </div>

            <div className="mt2">
              <div className={labelClasses}>
                <span
                  data-balloon="If you entered FD # of shares, you can enter here how many of those are options. # of Shares plus # of Options should equal # of FD shares."
                  data-balloon-pos="right"
                  data-balloon-length="large"
                >
                  # of options
                </span>
              </div>

              {!edit || !isCustom ?
                <div className={`${inputClasses} flex items-center col-6`} style={{ height: '36px', lineHeight: 'normal' }}>
                  {share.formatted_share_options}
                </div>
                :
                <CleaveNumberFormatting
                  className={`${inputClasses} col-6`}
                  name="share_options"
                  value={(share.share_options || '').toString()}
                  onChange={this.handleChange}
                />
              }
            </div>

            <div className="mt2">
              <div className={labelClasses}>
                <span>
                  Convertible Principal
                </span>
              </div>

              {!edit || !isCustom ?
                <div className={`${inputClasses} flex items-center col-6`} style={{ height: '36px', lineHeight: 'normal' }}>
                  {share.formatted_convertible_principal}
                </div>
                :
                <CleaveNumberFormatting
                  className={`${inputClasses} col-6`}
                  name="convertible_principal"
                  value={(share.convertible_principal || '').toString()}
                  onChange={this.handleChange}
                />
              }
            </div>

            {isCustom &&
              <div className="mt2">
                <div className={labelClasses}>
                  <span
                    data-balloon="This is the accounting value of the investment in your books. "
                    data-balloon-pos="right"
                    data-balloon-length="large"
                  >
                    Book Value
                  </span>
                </div>

                {!edit ?
                  <div className={`${inputClasses} flex items-center col-6`} style={{ height: '36px', lineHeight: 'normal' }}>
                    {share.formatted_book_value}
                  </div>
                  :
                  <CleaveNumberFormatting
                    className={`${inputClasses} col-6`}
                    name="book_value"
                    value={(share.book_value || '').toString()}
                    onChange={this.handleChange}
                  />
                }
              </div>
            }
          </div>

          {isCustom &&
            <div className="flex flex-column mb1">
              <div className={labelClasses}>
                <span> Comments </span>
              </div>
              <Textarea
                value={share.comments}
                onChange={this.handleChange}
                className={inputClasses}
                style={{ minHeight: '70px' }}
                name="comments"
                readOnly={!edit}
              />
            </div>
          }
        </div>

        {edit && isCustom &&
          <div className="flex flex-justify-end items-center p2 border-top border-lighter-gray">
            <div
              className="h5 text-blue mr2 cursor-pointer"
              onClick={closeModal}
            >
              Cancel
            </div>
            <div
              className="h5 p1 px2 cursor-pointer bg-blue text-white rounded"
              onClick={this.submit}
            >
              Save
            </div>
          </div>
        }
      </Modal>
    );
  }
}

export default FundingShareDetailsModal;
