import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../store/configureStore';
import RequestContainer from '../containers/signing_room/RequestContainer';


const SigningRoomRequest = props => (
  <Provider store={configureStore(props)}>
    <RequestContainer />
  </Provider>
);

export default SigningRoomRequest;
