import React, { Component } from 'react';
import PropTypes from 'prop-types';


class TextInput extends Component {
  static propTypes = {
    alignUnit: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    alignInput: PropTypes.string,
    sizeClass: PropTypes.string,
    hasValue: PropTypes.bool,
  }

  static defaultProps = {
    alignUnit: true,
    value: '',
    onChange: null,
    onBlur: null,
    alignInput: '',
    sizeClass: 'col-12',
    hasValue: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  onChange = e => {
    const { onChange } = this.props;
    if (onChange) onChange(e);

    this.setState({ value: e.target.value });
  }

  onBlur = e => {
    const { onBlur } = this.props;
    if (onBlur) onBlur(e);

    this.setState({ value: e.target.value });
  }

  render() {
    const { onChange, onBlur, sizeClass, alignUnit, unit, value, alignInput, hasValue, ...other } = this.props;
    const { value: valueState } = this.state;

    return (
      <div className={sizeClass}>
        {alignUnit ? (
          <div className={`col-12 flex flex-row ${alignInput}`}>
            <input
              type="text"
              style={{ height: '38px' }}
              onChange={this.onChange}
              onBlur={this.onBlur}
              value={hasValue ? value : valueState}
              {...other}
            />
            <span className={`${unit ? 'text-gray relative top-15 unit-margin-left' : 'hidden'}`}>{unit}</span>
          </div>
        ) : (
          <div className={`col-12 flex flex-row ${alignInput}`}>
            <span className={`${unit ? 'text-gray relative top-15 unit-margin-right' : 'hidden'}`}>{unit}</span>
            <input
              type="text"
              style={{ height: '38px' }}
              onChange={this.onChange}
              onBlur={this.onBlur}
              value={hasValue ? value : valueState}
              {...other}
            />
          </div>
        )}
      </div>


    );
  }
}

export default TextInput;
