import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import axios from 'axios';

import Modal from '../Modal';
import TextInputProps from '../TextInputProps';

import ValueTable from './ValueTable';
import PreviewTable from './PreviewTable';
import MagicStick from './MagicStick';

import { defaultSelectStyles, defaultSelectTheme } from '../selects/SelectStyles';


class SheetsSource extends Component {
  static propTypes = {
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    bulkImport: PropTypes.bool,
    updateData: PropTypes.func,
  }

  static defaultProps = {
    bulkImport: false,
    updateData: null,
  }

  constructor(props) {
    super(props);

    const data = typeof props.data === 'string' ? JSON.parse(props.data) : props.data;

    this.state = {
      data,
      showManage: false,
      sheetOptions: [],
      worksheetOptions: [],
      preview: [],
      date: data.sources.date,
      actual: data.sources.actual,
      forecast: data.sources.forecast,
      selectedSheet: { value: data.sheet_id, label: data.sheet_name === 'N/A' ? 'Search all Files / Select from Drop Down' : data.sheet_name },
      selectedWorksheet: { value: data.worksheet, label: data.worksheet },
      magicStick: false,
      failedCreation: false,
      failedManage: false,
      savingSubmit: false,
      openingManage: false,
    };
  }

  componentDidMount() {
    const { data } = this.state;
    if (data.open_modal) {
      this.manageModal();
    }
  }

  getPreview = selectedOption => {
    const { data, selectedSheet } = this.state;

    this.setState({ selectedWorksheet: selectedOption });
    App.Api.get(data.preview_url, { sheet_id: selectedSheet.value, worksheet: selectedOption.value })
      .then((res) => {
        this.setState({
          preview: res,
        });
      });
  }

  getWorksheetOptions = selectedOption => {
    const { data } = this.state;

    this.setState({
      selectedSheet: selectedOption,
      selectedWorksheet: '',
      preview: [],
    });
    App.Api.get(data.worksheets_url, { sheet_id: selectedOption.value })
      .then(res => {
        this.setState({
          worksheetOptions: res,
        });
      });
  }

  disconnect = () => {
    const { data } = this.state;

    const confirmed = confirm('Disconnecting your account permanently erases all imported KPI data (not just this chart) and prevents all automatic updates. Are you sure you want to permanently disconnect your account?');
    if (confirmed) {
      App.Api.delete(data.disconnect_url).then(() => {
        window.location.href = data.kpi_url;
      });
    }
  }

  displayAccount = () => {
    const { data } = this.state;
    if (data.connected) {
      return (
        <div className="text-gray py1 bold flex">
          Google account:
          <span className="text-blue pl1">{data.account_name}</span>
          <div
            className="ml1 cursor-pointer"
            data-balloon="Remove your connected Google Account from Investory"
            data-balloon-pos="up"
            method="delete"
            onClick={this.disconnect}
          >
            <i className="fa fa-times text-gray" />
          </div>
        </div>
      );
    }
    return (
      <a className="text-white bg-orange py1 px3 rounded" href={data.oauth_url}>
        Connect Google Account
      </a>
    );
  }

  displayError = () => {
    const { failedCreation, selectedSheet } = this.state;

    if (failedCreation) {
      return (
        <div className="flex flex-center h5 mb2 text-red">
          <p>
            <i className="fa fa-exclamation-circle mr1" />
            The dates could not be imported properly.<br />Please make sure that the dates are formatted according to google „Date“ format. See Format-&gt;Number-&gt;Date in
            <a
              className="text-blue"
              href={`https://docs.google.com/spreadsheets/d/${selectedSheet.value}`}
              target="blank"
            >
              &nbsp; your Google sheet.
            </a>
          </p>
        </div>
      );
    }
    return null;
  }

  displayManage = () => {
    const { data, openingManage } = this.state;

    if (data.connected && data.category !== 'sheets_bulk_link') {
      return (
        <div
          className={`ml1 py1 px3 text-white bg-blue rounded ${openingManage ? 'cursor-disabled' : 'cursor-pointer'}`}
          data-balloon={data.category === 'sheets' ? 'Linked to a single import file. Click to manage data sources.' : 'Click to manage bulk data import.'}
          data-balloon-pos="up"
          onClick={this.manageModal}
        >
          {openingManage ? 'Loading...' : 'Manage'}
        </div>
      );
    }
    return (
      <div
        className="ml1 py1 px3 text-white bg-light-gray rounded cursor-default"
        data-balloon={data.category === 'sheets_bulk_link' ? 'Linked to a bulk import file. Can be managed in the KPIs page.' : null}
        data-balloon-pos="up"
      >
        Manage
      </div>
    );
  }

  magicSelect = (value) => {
    const { bulkImport } = this.props;
    const { magicStick } = this.state;


    if (!magicStick || (bulkImport && magicStick !== 'date')) return;
    const key = magicStick;
    this.setState({ [key]: value });
  }

  manageModal = () => {
    const { data, openingManage } = this.state;

    if (openingManage) return;

    this.setState({ openingManage: true }, async () => {
      try {
        const res = await axios.get(data.manage_url);
        this.setState({
          sheetOptions: res.data.sheets || [],
          worksheetOptions: res.data.worksheets || [],
          preview: res.data.preview,
          openingManage: false,
          failedManage: false,
        });
        setTimeout(() => { this.setState({ showManage: true, openingManage: false }); }, 10);
      } catch (err) {
        this.setState({ failedManage: true, openingManage: false });
      }
    });
  }

  handleClose = () => {
    const { savingSubmit } = this.state;

    if (savingSubmit) return;

    this.setState({ showManage: false });
  }

  submitChanges = () => {
    const { bulkImport, updateData } = this.props;
    const {
      data, date, actual, forecast, selectedSheet, selectedWorksheet, savingSubmit,
    } = this.state;

    if (savingSubmit) return;

    const params = {
      sheet: {
        date_source: date,
        actual_source: actual,
        forecast_source: forecast,
        google_sheet_id: selectedSheet.value,
        google_sheet_name: selectedSheet.label,
        google_worksheet: selectedWorksheet.value,
      },
    };

    if (bulkImport) {
      if (date === null || date === undefined || date === 'N/A') {
        App.State.setFlash({ name: 'alert', msg: 'Please, select the relevant date mapping from your Gsheet.' });
        return;
      }
    }

    this.setState({ savingSubmit: true }, async () => {
      try {
        const res = await axios.patch(data.update_url, params);
        if (res.data) {
          if (bulkImport) {
            this.setState({ savingSubmit: false }, () => {
              // Should change this later. Just for testing
              updateData(res.data);
              this.setState({ data: { ...data, sheet_name: res.data.sheet_name, sheet_id: res.data.sheet_id, worksheet: res.data.worksheet } });
              this.handleClose();
            });
          } else {
            window.location = data.kpi_url;
          }
        } else {
          this.setState({ failedCreation: true, savingSubmit: false });
        }
      } catch (err) {
        this.setState({ savingSubmit: false }, () => {
          App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an error prevented the Gsheet from being uploaded.' });
        });
      }
    });
  }

  render() {
    const { bulkImport } = this.props;

    const {
      data, date, actual, forecast,
      preview, magicStick, showManage,
      selectedSheet, sheetOptions,
      selectedWorksheet, worksheetOptions,
      savingSubmit, failedManage,
    } = this.state;

    return (
      <div className="flex flex-column">
        <div className="flex">

          {/* Manage Google Account */}
          {this.displayAccount()}

          <div className="text-gray py1 bold flex flex-auto flex-justify-end">
            Sheet name:
            <span className="text-blue pl1">{data.sheet_name}</span>
          </div>

          {/* Manage button */}
          {this.displayManage()}
        </div>

        {failedManage &&
          <div className="flex flex-center h5 text-red mt2">
            <p>
              <i className="fa fa-exclamation-circle mr1" />
              There was an issue with your token. Please, access "Third-party apps with account access" in your Google Account and check if everything is fine.
              <a
                className="text-blue"
                href="https://myaccount.google.com/data-and-privacy"
                target="blank"
              >
                &nbsp; Link to your account.
              </a>
            </p>
          </div>
        }

        {!bulkImport &&
          <>
            {/* KPI values table */}
            <ValueTable sources={data.sources} values={data.values} />

            {/* Foot note info and button to update values from Gsheet */}
            <div className="flex absolute left-0 bottom-0 col-12 p3">
              <div className="text-gray py1 bold mt1">
                Last updated:
                <span className="text-blue pl1">{data.last_refresh}</span>
              </div>
              <div className="text-light-gray py1 flex flex-auto flex-justify-end">
                (Updates every 24 h) |
                <a className="pl1" href={data.refresh_url}>
                  <i className="fa fa-refresh text-gray" />
                </a>
              </div>
            </div>
          </>
        }

        <Modal
          show={showManage}
          widthClass="react_modal--wide"
        >
          <div className="flex flex-column p2">
            <div className="flex items-center mb2">
              <p className="bold h4 m0">Choose Document and Data Fields</p>

              <div className="flex flex-auto flex-justify-end">
                <div
                  className={`border border-alto px3 py1 text-gray ${savingSubmit ? 'cursor-disabled' : 'cursor-pointer'}`}
                  onClick={this.handleClose}
                >
                  Discard
                </div>
                <div
                  className={`bg-green ml2 px3 py1 text-white ${savingSubmit ? 'cursor-disabled' : 'cursor-pointer'}`}
                  onClick={this.submitChanges}
                >
                  {savingSubmit ? 'Saving...' : 'Save'}
                </div>
              </div>
            </div>

            <div className="flex mb2 mxn2">
              <div className="col-6 px2">
                <ReactSelect
                  value={selectedSheet}
                  options={sheetOptions}
                  placeholder="Search all files / Select from Drop Down"
                  isClearable={false}
                  onChange={this.getWorksheetOptions}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                  isDisabled={savingSubmit}
                />
              </div>

              <div className="col-6 px2">
                <ReactSelect
                  value={selectedWorksheet}
                  options={worksheetOptions}
                  isClearable={false}
                  placeholder="Select worksheet"
                  onChange={this.getPreview}
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                  isDisabled={savingSubmit}
                />
              </div>
            </div>

            {bulkImport ?
              <>
                <p className="flex flex-center h5 mb2 text-light-gray">
                  <i className="fa fa-info-circle mr1" />
                  Select the relevant date mapping from you Google Sheet
                </p>

                <div className="flex mb2 mxn2">
                  <div className="col-4 flex px2">
                    <span className="h5 lh-normal mr1 text-light-gray">Select <br /> Date</span>

                    <TextInputProps
                      className="bg-none border border-alto center flex-auto h4"
                      onChange={e => { this.setState({ date: e.target.value.toUpperCase() }); }}
                      placeholder="Date column or Row"
                      value={date}
                      disabled={bulkImport}
                    />

                    <MagicStick
                      color="green"
                      field="date"
                      active={magicStick}
                      setMagic={value => this.setState({ magicStick: value })}
                    />
                  </div>
                </div>
              </>
              :
              <>
                <p className="flex flex-center h5 mb2 text-light-gray">
                  <i className="fa fa-info-circle mr1" />
                  Type in or select the relevant &nbsp;<b>columns</b>&nbsp; or &nbsp;<b>rows</b>&nbsp; from your Google Sheet to set as KPI values (Plan is optional)
                </p>

                <div className="flex mb2 mxn2">
                  <div className="col-4 flex px2">
                    <span className="h5 lh-normal mr1 text-light-gray">Select <br /> Date</span>

                    <TextInputProps
                      className="bg-none border border-alto center flex-auto h4"
                      onChange={e => { this.setState({ date: e.target.value.toUpperCase() }); }}
                      placeholder="Date column or Row"
                      value={date}
                    />

                    <MagicStick
                      color="green"
                      field="date"
                      active={magicStick}
                      setMagic={value => this.setState({ magicStick: value })}
                    />
                  </div>

                  <div className="col-4 flex px2">
                    <span className="h5 lh-normal mr1 text-light-gray">Select <br /> Actual</span>

                    <TextInputProps
                      className="bg-none border border-alto center flex-auto h4"
                      onChange={e => { this.setState({ actual: e.target.value.toUpperCase() }); }}
                      placeholder="Actual column or Row"
                      value={actual}
                    />

                    <MagicStick
                      color="blue"
                      field="actual"
                      active={magicStick}
                      setMagic={value => this.setState({ magicStick: value })}
                    />
                  </div>

                  <div className="col-4 flex px2">
                    <span className="h5 lh-normal mr1 text-light-gray">Select <br /> Plan</span>

                    <TextInputProps
                      className="bg-none border border-alto center flex-auto h4"
                      onChange={e => { this.setState({ forecast: e.target.value.toUpperCase() }); }}
                      placeholder="Plan column or Row"
                      value={forecast}
                    />

                    <MagicStick
                      color="orange"
                      field="forecast"
                      active={magicStick}
                      setMagic={value => this.setState({ magicStick: value })}
                    />
                  </div>
                </div>

                {this.displayError()}
              </>
            }

            <PreviewTable
              values={preview}
              date={date}
              actual={actual}
              forecast={forecast}
              active={magicStick}
              bulkImport={bulkImport}
              magicSelect={this.magicSelect}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default SheetsSource;
