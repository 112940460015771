const companyFiltersOptions = [
  {
    label: 'Status',
    name: 'status',
    filter: 'all',
    multiple: true,
  },
  {
    label: 'Fundraising Status',
    name: 'fundraising_status',
    filter: 'all',
    multiple: true,
  },
  {
    label: 'Stage',
    name: 'stage',
    filter: 'all',
    multiple: true,
  },
  {
    label: 'Industry',
    name: 'industry',
    filter: 'all',
    multiple: true,
  },
  {
    label: 'Business Type',
    name: 'business_type',
    filter: 'all',
    multiple: true,
  },
  {
    label: 'Country',
    name: 'country',
    filter: 'all',
    multiple: true,
  },
];

export default companyFiltersOptions;
