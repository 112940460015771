import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CompanyCardAdmin from './CompanyCardAdmin';


class ProfilesInvestmentCard extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
    updateTopState: PropTypes.func.isRequired,
  }

  fund = () => {
    const { record: { individual } } = this.props;

    if (!individual) {
      return (
        <div className="position-left-top absolute text-light-gray h5">
          <i className="fa fa-users pr1" />
            Group
        </div>
      );
    }
    return (
      <div className="position-left-top absolute text-light-gray h5">
        <i className="fa fa-male pr1" />
          Single
      </div>
    );
  }

  render() {
    const { record, updateTopState } = this.props;

    return (
      <div className="col-4 lg-col-6 sm-col-12 flex px2 mb3">
        <div className="card--has-hover card--has-layer bg-white bs-around-small center flex flex-auto flex-center flex-column p3 relative rounded">
          {this.fund()}

          <div className="flex-auto">
            {record.manage_url.length > 0 && !record.pending &&
              <CompanyCardAdmin
                manageUrl={record.manage_url}
                updateTopState={updateTopState}
              />
            }
            <i className="fa fa-briefcase fa--search-card position-left-bottom absolute text-extra-light-gray" />
            <a href={record.url}>
              <div
                className="search-card__img bg-center bg-cover bg-no-repeat border border-light-gray circle mb1 mx-auto"
                style={{ backgroundImage: `url(${record.avatar})` }}
              />
              <p className="bold h3 m0 mb1 text-outer-space">{record.name}</p>
              <p className="h5 m0 mb3 text-light-gray">{record.about}</p>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfilesInvestmentCard;
