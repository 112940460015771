import React, { Component } from 'react';
import PropTypes from 'prop-types';


class NumberInputWithNegative extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    // Props for specific behavior
    emptyValue: PropTypes.bool,
    update: PropTypes.bool,
    decimal: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  onChange = (e) => {
    const reg = /^[+-]?\d+\.?\d*$/;
    if (reg.test(e.target.value)) {
      if (this.props.onChange) this.props.onChange(e);
      this.setState({ value: e.target.value });
      if (this.props.decimal) {
        this.updateValueToDecimal(e.target.value);
      }
    }
    if (e.target.value === '' && !this.props.emptyValue) {
      e.target.value = 0;
      if (this.props.onChange) this.props.onChange(e);
      this.setState({ value: '' });
    } else if (e.target.value === '' && this.props.emptyValue) {
      e.target.value = null;
      if (this.props.onChange) this.props.onChange(e);
      this.setState({ value: '' });
    }
  }

  componentWillMount() {
    const { decimal } = this.props;
    if (decimal) {
      this.updateValueToDecimal = _.debounce(this.updateValueToDecimal, 600);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { update } = this.props;
    if (update) {
      this.setState({ value: nextProps.value });
    }
  }

  updateValueToDecimal = (value) => {
    this.setState({ value: Number(value).toFixed(2) });
  }

  render() {
    const { onChange, value, emptyValue, update, decimal, ...other } = this.props;
    return (
      <input
        type="number"
        onChange={this.onChange}
        value={this.state.value}
        {...other}
      />
    );
  }
}

export default NumberInputWithNegative;
