import React, { Component } from 'react';


class ConversationLink extends Component {
  classes = () => {
    const { selected, conversation } = this.props;
    let classes = 'flex flex-center p1 relative clearfix';

    if (selected) classes += ' border-right border-blue bw-4 bg-lighter-gray';
    if (conversation.seen) classes += ' conversation-unread';

    return classes;
  }

  render() {
    const { conversation } = this.props;

    const conversations_img = {
      backgroundImage: 'url(' + conversation.avatar + ')',
      height: '36px',
      width: '36px',
    };

    return (
      <div key={conversation.id} className={this.classes()}>
        <a
          className="absolute bottom-0 left-0 right-0 top-0"
          href={conversation.url}
        />
        <div
          className="bg-center bg-cover bg-no-repeat circle mr2"
          style={conversations_img}
        />
        <div className="col-9">
          <p className="m0">
            <span className="semi-bold h5 text-outer-space">{conversation.name}</span>
          </p>
          <p className="h6 m0 text-light-gray">{conversation.last_message}</p>
        </div>
      </div>
    );
  }
}

export default ConversationLink;
