const investmentKpis = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_KPI_FIELD':
      return {
        ...state,
        predefined_kpis: state.predefined_kpis.map(kpi => ((kpi.id === action.kpiField.id) ? action.kpiField : kpi)),
        custom_kpis: state.custom_kpis.map(kpi => ((kpi.id === action.kpiField.id) ? action.kpiField : kpi)),
      };

    case 'ADD_KPI_FIELD':
      return {
        ...state,
        company_kpis_options: action.kpiField.company_kpis_options,
        custom_kpis: [...state.custom_kpis, action.kpiField],
      };

    case 'REMOVE_KPI_FIELD':
      return {
        ...state,
        company_kpis_options: action.kpiField.company_kpis_options,
        custom_kpis: state.custom_kpis.filter(kpi => kpi.id !== action.kpiField.id),
      };

    case 'UPDATE_KPI_IN_CHART':
      return {
        ...state,
        chart_data: action.kpiField.data,
        chosen_kpi: action.kpiField.chosen_kpi,
      };

    case 'UPDATE_KPIS_BY_DATE':
      return {
        ...state,
        predefined_kpis: action.kpisData.predefined_kpis,
        custom_kpis: action.kpisData.custom_kpis,
        current_date: action.currentDate,
        all_investment_kpis_options: action.kpisData.all_investment_kpis_options,
      };

    default: return state;
  }
};

export default investmentKpis;
