import React, { Component } from 'react';
import PropTypes from 'prop-types';


class FaButtonFunction extends Component {
  static defaultProps = {
    tooltip: null,
    color: 'gray',
    size: '',
    tooltipPos: 'down',
    margin: 'mx1',
  }

  static propTypes = {
    faIcon: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    tooltipPos: PropTypes.string,
    margin: PropTypes.string,
    handleClick: PropTypes.func.isRequired,
  }

  state = {
    used: false,
  }

  handleClick = () => {
    const { handleClick } = this.props;

    this.setState({ used: true });
    handleClick();
  }

  render() {
    const { margin, size, color, faIcon, tooltip, tooltipPos } = this.props;
    const { used } = this.state;
    if (used) {
      return (
        <span className={`${margin}`}>
          <i className={`text-gray fa fa-refresh fa-spin fa-fw ${size}`} />
        </span>
      );
    }
    return (
      <span
        className={`${margin} cursor-pointer`}
        data-balloon={tooltip}
        data-balloon-pos={tooltipPos}
        onClick={this.handleClick}
      >
        <span className={`${size}`}>
          <i className={`fa fa-fw text-${color} fa-${faIcon}`} />
        </span>
      </span>
    );
  }
}

export default FaButtonFunction;
