import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import Avatar from '../../tab_view/Avatar';
import Modal from '../../Modal';
import CheckBox from '../../CheckBox';

import { contactLink } from '../../modules/investmentContacts';
import { defaultSelectStyles, defaultSelectTheme } from '../../selects/SelectStyles';
import InvestorInvite from '../../invites/InvestorInvite';

export default class DealInfoWidget extends Component {
  static propTypes = {
    dealRoom: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    saveDealInfo: PropTypes.func,
    showModal: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    saveDealInfo: null,
    showModal: false,
  }

  constructor(props) {
    super(props);

    /* eslint-disable camelcase */
    const {
      deal_contact_id,
      deal_captain_id,
      deal_description,
      deal_contact_title,
      deal_captain_title,
      share_type,
      editor_type,
      contact_via_email = false,
      captain_via_email = false,
      allow_sharing = true,
    } = props.dealRoom;

    this.state = {
      dealRoom: {
        deal_contact_id,
        deal_captain_id,
        deal_description,
        deal_contact_title,
        deal_captain_title,
        share_type,
        editor_type,
        contact_via_email,
        captain_via_email,
        allow_sharing,
      },
    };
    /* eslint-enable camelcase */
  }

  handleDealCaptainSelect = (selectedOption) => {
    this.handleChange('deal_captain_id', selectedOption ? selectedOption.value : null);
    if (!selectedOption) {
      this.handleChange('captain_via_email', false);
    }
  }

  handleChange = (name, value) => {
    this.setState(state => ({
      ...state,
      dealRoom: {
        ...state.dealRoom,
        [name]: value,
      },
    }));
  }

  handleSubmit = async () => {
    const { saveDealInfo, closeModal } = this.props;
    const { dealRoom } = this.state;

    const params = { deal_room: dealRoom };

    try {
      await saveDealInfo(params);
      closeModal();
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
    }
  }

  render() {
    const {
      dealRoom,
      company,
      currentUser,
      showModal,
      closeModal,
    } = this.props;

    const { dealRoom: dealRoomState } = this.state;
    return (
      <React.Fragment>
        <span className="h5 text-light-gray">Deal Information</span>
        <span className="h3 pb2 bold text-gray border-bottom border-wild-sand bw-1">{company.name}</span>
        <div className="h5 text-light-gray pb2 pt2 lh-12 fw400" style={{ minHeight: '170px', whiteSpace: 'pre-wrap' }}>{dealRoom.deal_description ? dealRoom.deal_description : 'No deal description added yet...'}</div>
        <div className="flex flex-justify-between col-12 pb2">
          <div className={`flex items-center ${dealRoom.deal_captain.name ? 'col-6' : 'col-12'}`}>
            <Avatar avatarUrl={dealRoom.deal_contact.avatar || window.images.userGray} size={30} shadow />
            <div className="flex flex-column ml1">
              <div className="mt-auto h4 text-gray fw400 lh-12">{dealRoom.deal_contact.name || '\u2013'}</div>
              {dealRoomState.contact_via_email ?
                <a className="mb-auto h6 text-gray cursor-pointer lh-12" href={`mailto:${dealRoom.deal_contact.email}`}>{dealRoom.deal_contact.title}<i className="fa fa-envelope pl1 text-light-gray" /></a>
                :
                <span className="mb-auto h6 text-gray lh-12">{dealRoom.deal_contact.title}</span>
              }
            </div>
          </div>
          {dealRoom.deal_captain.name &&
            <div className="flex items-center col-6">
              <Avatar avatarUrl={dealRoom.deal_captain.avatar || window.images.userGray} size={30} shadow />
              <div className="flex flex-column ml1">
                <div className="mt-auto h4 text-gray fw400 lh-12">{dealRoom.deal_captain.name || '\u2013'}</div>
                {dealRoomState.captain_via_email ?
                  <a className="mb-auto h6 text-gray cursor-pointer lh-12" href={`mailto:${dealRoom.deal_captain.email}`}>{dealRoom.deal_captain.title}<i className="fa fa-envelope pl1 text-light-gray" /></a>
                  :
                  <span className="mb-auto h6 text-gray lh-12">{dealRoom.deal_captain.title}</span>
                }
              </div>
            </div>
          }
        </div>
        <div className="flex flex-justify-between items-center">
          <div className="flex flex-row">
            {dealRoom.allow_sharing && dealRoom.can_share &&
             (currentUser.investor ?
               <div
                 className="rounded h5 cursor-pointer bg-green text-white px1 py05 fw400"
                 data-behavior="modal"
                 data-target="#modal-suggest-invites"
                 href="#"
               >
                 Share this Deal
               </div>
               :
               <InvestorInvite
                 company={{ id: company.id, name: company.name }}
                 currentUser={{ id: currentUser.id, email: currentUser.email }}
                 html='<div class="rounded h5 cursor-pointer bg-green text-white px1 py05 fw400">Share this Deal</div>'
                 isDealRoomInvite
               />
             )
            }
          </div>
          <div className="flex flex-row">
            {company.facebook_url &&
              <a href={contactLink({ name: 'Facebook', text: company.facebook_url })} target="_blank" rel="noopener noreferrer">
                <i className="fa fa-facebook-square text-light-gray" />
              </a>
            }
            {company.twitter_url &&
              <a className="pl2" href={contactLink({ name: 'Twitter', text: company.twitter_url })} target="_blank" rel="noopener noreferrer">
                <i className="fa fa-twitter text-light-gray" />
              </a>
            }
            {company.linkedin_url &&
              <a className="pl2" href={contactLink({ name: 'Linkedin', text: company.linkedin_url })} target="_blank" rel="noopener noreferrer">
                <i className="fa fa-link text-light-gray" />
              </a>
            }
          </div>
        </div>

        {/* Modal starts here */}
        {dealRoom.can_edit &&
          <Modal
            show={showModal}
            widthClass="react_modal--600"
            onClickOutside={closeModal}
          >
            <div className="flex flex-justify-between items-center semi-bold px3 py2 border-bottom border-lighter-gray">
              <div className="h3">Deal Room - Manage Deal Infos</div>
              <i className="fa fa-close h5 cursor-pointer" onClick={closeModal} />
            </div>
            <div className="flex flex-column px3 py2">
              <span className="h6 text-light-gray pb2 lh-125">Company Information like Name, Social Links are managed in the company profile, to change <a href={`/company/${company.id}/info/edit`}>click here.</a></span>
              <span className="h5 text-gray pb1">Company/Deal Description</span>
              <textarea
                value={dealRoomState.deal_description || ''}
                name="deal_description"
                maxLength="350"
                className="border border-medium-lighter-gray hover-border-medium-light-gray h-100 mb1 lh-12"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
              />
              <span className="h6 text-light-gray right-align pb1">Max. 350 Characters</span>
              <div className="flex flex-justify-between items-center pb2">
                <div className="col-4 border-bottom border-lighter-gray" />
                <span className="h5 text-gray">Deal Contacts</span>
                <div className="col-4 border-bottom border-lighter-gray" />
              </div>
              <div className="flex flex-row pb1">
                <div className="flex flex-column col-6 pr1">
                  <span className="h5 text-gray pb1">
                    Deal Contact
                    <span
                      className="ml1"
                      data-balloon="Select the person that appears as contact in the deal info part of the deal room."
                      data-balloon-pos="up"
                      data-balloon-length="medium"
                    >
                      <i className="fa fa-info-circle text-light-gray" />
                    </span>
                  </span>
                  <ReactSelect
                    value={dealRoom.deal_contact_options.filter(opt => opt.value === dealRoomState.deal_contact_id)}
                    options={dealRoom.deal_contact_options}
                    onChange={selectedOption => this.handleChange('deal_contact_id', selectedOption.value)}
                    styles={defaultSelectStyles}
                    theme={defaultSelectTheme}
                    name="deal_contact_id"
                  />
                </div>
                <div className="flex flex-column col-6 pl1">
                  <span className="h5 text-gray pb1">
                    Contact Person Title
                    <span
                      className="ml1"
                      data-balloon="Add the contact person's title that appears below the name in the deal info part of the deal room."
                      data-balloon-pos="up"
                      data-balloon-length="medium"
                    >
                      <i className="fa fa-info-circle text-light-gray" />
                    </span>
                  </span>
                  <input
                    type="text"
                    value={dealRoomState.deal_contact_title}
                    onChange={e => this.handleChange(e.target.name, e.target.value)}
                    name="deal_contact_title"
                    className="border border-medium-lighter-gray hover-border-medium-light-gray"
                  />
                </div>
              </div>
              <div className="flex pb2">
                <CheckBox
                  name="contact_via_email"
                  onChange={() => this.handleChange('contact_via_email', !dealRoomState.contact_via_email)}
                  defaultChecked={dealRoomState.contact_via_email}
                  fixedWidth
                />
                <span className="h5 ml2">Enable Contact via Email</span>
              </div>
              <div className="flex flex-row pb1">
                <div className="flex flex-column col-6 pr1">
                  <span className="h5 text-gray pb1">
                    Deal Captain/Lead Investor
                    <span
                      className="ml1"
                      data-balloon="Select the person that appears as deal captain/lead investor that is sponsoring this deal and may be contacted."
                      data-balloon-pos="up"
                      data-balloon-length="medium"
                    >
                      <i className="fa fa-info-circle text-light-gray" />
                    </span>
                  </span>
                  <ReactSelect
                    value={dealRoom.deal_contact_options.filter(opt => opt.value === dealRoomState.deal_captain_id)}
                    options={dealRoom.deal_contact_options}
                    onChange={selectedOption => this.handleDealCaptainSelect(selectedOption)}
                    styles={defaultSelectStyles}
                    theme={defaultSelectTheme}
                    isClearable
                    name="deal_captain_id"
                  />
                </div>
                <div className="flex flex-column col-6 pl1">
                  <span className="h5 text-gray pb1">
                    Deal Captain/Lead Investor Title
                    <span
                      className="ml1"
                      data-balloon="Add the deal captain/lead investors title that appears below the name in the deal info part of the deal room."
                      data-balloon-pos="up"
                      data-balloon-length="medium"
                    >
                      <i className="fa fa-info-circle text-light-gray" />
                    </span>
                  </span>
                  <input
                    type="text"
                    value={dealRoomState.deal_captain_title}
                    onChange={e => this.handleChange(e.target.name, e.target.value)}
                    name="deal_captain_title"
                    className="border border-medium-lighter-gray hover-border-medium-light-gray"
                  />
                </div>
              </div>
              <div className="flex pb2">
                <CheckBox
                  name="captain_via_email"
                  checked={dealRoomState.captain_via_email}
                  manualCheck
                  onChange={() => this.handleChange('captain_via_email', !dealRoomState.captain_via_email)}
                  disabled={!dealRoomState.deal_captain_id}
                  fixedWidth
                />
                <span className="h5 ml2">Enable Contact via Email</span>
              </div>
              <span className="h6 text-light-gray pb2 lh-125">The contact person and deal captain/lead investors can be set to show who is sponsoring the deal and who should be contacted in case of any questions.</span>
              <div className="flex flex-justify-between items-center pb2">
                <div className="col-4 border-bottom border-lighter-gray" />
                <span className="h5 text-gray">Deal Settings</span>
                <div className="col-4 border-bottom border-lighter-gray" />
              </div>
              <div className="flex pb2">
                <CheckBox
                  name="allow_sharing"
                  defaultChecked={dealRoomState.allow_sharing}
                  onChange={() => this.handleChange('allow_sharing', !dealRoomState.allow_sharing)}
                  fixedWidth
                />
                <span className="h5 ml2">Allow Deal Sharing</span>
              </div>
              <div className="flex flex-row pb2">
                <div className="flex flex-column col-6 pr1">
                  <span className="h5 text-gray pb1">Who can share deals?</span>
                  <ReactSelect
                    value={dealRoom.share_type_options.filter(opt => opt.value === dealRoomState.share_type)}
                    options={dealRoom.share_type_options}
                    onChange={selectedOption => this.handleChange('share_type', selectedOption.value)}
                    styles={defaultSelectStyles}
                    theme={defaultSelectTheme}
                    name="share_type"
                    isDisabled={!dealRoomState.allow_sharing}
                  />
                </div>
                <div className="flex flex-column col-6 pl1">
                  <span className="h5 text-gray pb1">Deal Editors</span>
                  <ReactSelect
                    value={dealRoom.editor_type_options.filter(opt => opt.value === dealRoomState.editor_type)}
                    options={dealRoom.editor_type_options}
                    onChange={selectedOption => this.handleChange('editor_type', selectedOption.value)}
                    styles={defaultSelectStyles}
                    theme={defaultSelectTheme}
                    name="editor_type"
                  />
                </div>
              </div>
              <span className="h6 text-light-gray lh-125">Deal sharing enables users to share the deal with others. The deal will only be accessible after registering for an account and when the appropriate access rights have been setup.</span>
            </div>
            <div className="flex flex-justify-end px3 pt1 mt1 mb2 border-top border-lighter-gray">
              <div
                className="bg-white text-blue border-none h4 fw400 px3 py1 cursor-pointer rounded"
                onClick={closeModal}
              >
                Close
              </div>
              <div
                className="bg-blue text-white bs-around border-none h4 fw400 px3 py1 cursor-pointer rounded"
                onClick={this.handleSubmit}
              >
                Save
              </div>
            </div>
          </Modal>
        }
      </React.Fragment>
    );
  }
}
