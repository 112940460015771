import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SelectWithImage from './SelectWithImage';
import Modal from '../Modal';
import Avatar from '../tab_view/Avatar';
import Avatars from '../list/Avatars';


class SelectWithModal extends Component {
  static propTypes = {
    elementsToSelect: PropTypes.array,
    selectedElements: PropTypes.array,
    addElement: PropTypes.func.isRequired,
    deleteElement: PropTypes.func.isRequired,
    disableSelect: PropTypes.bool,
    disableLinks: PropTypes.bool,
    disableTooltip: PropTypes.bool,
    title: PropTypes.string,
    url: PropTypes.string,
    size: PropTypes.string,
    placeholder: PropTypes.string,
    avatarsQuantity: PropTypes.number,
    selectRawOption: PropTypes.bool,
  }

  static defaultProps = {
    disableLinks: true,
    disableTooltip: true,
    disableSelect: false,
    size: '25px',
    placeholder: null,
    avatarsQuantity: 5,
    title: '',
    url: null,
    selectRawOption: false,
    elementsToSelect: [],
    selectedElements: [],
  }

  state = {
    modalOpened: false,
  }

  open = () => {
    const { modalOpened } = this.state;

    this.setState({ modalOpened: !modalOpened });
  }

  renderElements = () => {
    const { selectedElements, deleteElement, size } = this.props;

    const elements = selectedElements.map((el) => (
      <div className="py1 hover hover-bg-wild-sand" key={el.id}>
        <div className="px3 flex items-center flex-justify-between">
          <div className="flex items-center">
            <Avatar avatarUrl={el.avatar} size={size} />
            <span className="ml2">{el.name}</span>
          </div>
          <div>
            <div
              className="cursor-pointer"
              onClick={() => deleteElement(el.id)}
            >
              <i className="fa fa-close text-red" />
            </div>
          </div>
        </div>
      </div>
    ));

    return (
      <div className="py2">
        {elements}
      </div>
    );
  }

  render() {
    const {
      title,
      elementsToSelect,
      selectedElements,
      addElement,
      disableSelect,
      disableLinks,
      disableTooltip,
      size,
      placeholder,
      avatarsQuantity,
      url,
      selectRawOption,
    } = this.props;

    const { modalOpened } = this.state;

    if (!modalOpened || disableSelect) {
      return (
        <div
          onClick={this.open}
          className={`border-alto py1 sm-hide flex flex-center ${disableSelect ? 'cursor-default' : 'cursor-pointer'}`}
        >
          {
            selectedElements.length > 0 ?
              <Avatars
                avatars={selectedElements}
                disableLinks={disableLinks}
                disableTooltip={disableTooltip}
                avatarsShowLimit={avatarsQuantity}
                size={size}
              />
              :
              (
                // If select is disabled, just show a dash
                disableSelect ?
                  <span>&mdash;</span>
                  :
                  // If not disabled, show either the placeholder or the plus icon
                  (placeholder ?
                    <span className={`pr1 ${disableSelect ? 'text-gray' : 'text-blue'}`}>{placeholder}</span>
                    :
                    <i
                      className="fa fa-plus text-alto hover link-hover--blue cursor-pointer"
                      onClick={this.open}
                    />
                  )
              )
          }
        </div>
      );
    }

    return (
      <Modal
        show={modalOpened}
        onClickOutside={() => this.setState({ modalOpened: false })}
      >
        <div className="flex flex-justify-between items-center bold p2 border-bottom border-lighter-gray">
          <div className="h3 fw400">{title}</div>

          <div
            className="cursor-pointer"
            onClick={() => this.setState({ modalOpened: false })}
          >
            <i className="fa fa-close text-gray" />
          </div>
        </div>

        <div>
          <div className="px3 pt3">
            <SelectWithImage
              options={elementsToSelect}
              onChange={(selectedOption) => addElement(selectRawOption ? selectedOption : selectedOption.value)}
              url={url}
            />
          </div>

          {this.renderElements()}
        </div>
      </Modal>
    );
  }
}

export default SelectWithModal;
