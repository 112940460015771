import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ManualShare from './ManualShare';


class ManualSharesTable extends Component {
  static propTypes = {
    shares: PropTypes.array.isRequired,
    createUrl: PropTypes.string.isRequired,
    updateTopState: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
    shareTotals: PropTypes.object.isRequired,
    edit: PropTypes.bool.isRequired,
  }

  createShare = e => {
    e.preventDefault();
    const { createUrl, updateTopState } = this.props;
    App.Api.post(createUrl, { category: 'common' }).then((data) => {
      updateTopState(data);
    });
  }

  displayAdd() {
    const { edit } = this.props;

    if (edit) {
      return (
        <div className="flex mxn2 cursor-pointer" onClick={this.createShare}>
          <div className="col-3 px2">
            <p className="center h5 m0 text-green">New Share</p>
          </div>

          <div className="col-3 px2">
            <p className="center h6 m0 text-light-gray">common/preferred</p>
          </div>

          <div className="col-3 px2">
            <p className="center h6 m0 text-light-gray">-</p>
          </div>

          <div className="center col-3 px2">
            <span className="h6 m0 text-light-gray">%</span>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    const { shareTotals, shares, updateTopState, updateItem, edit } = this.props;
    const limitExceeded = parseFloat(shareTotals.percentage) > 100;
    return (
      <div className="bg-white bs-around mb2 py2">
        <div className="flex mb2 px2">
          <p className="bold fs-18 m0 text-gray">Shareholders</p>
          <div className="flex flex-auto flex-justify-end">
            <span
              className="text-gray cursor-pointer"
              data-balloon="List of all shareholders of a certain investment round. Shareholders are usually people but  there can be pools of shares that are not assigned to any person."
              data-balloon-pos="up"
              data-balloon-length="xlarge"
            >
              <i className="fa fa-info-circle" />
            </span>
          </div>
        </div>

        <div className="bg-gallery px2 py1">
          <div className="flex mxn2">
            <div className="col-3 px2">
              <p className="bold center h5 m0 text-gray text-upper">Name</p>
            </div>

            <div className="col-3 px2">
              <p className="bold center h5 m0 text-gray text-upper">Share type</p>
            </div>

            <div className="col-3 px2">
              <p className="bold center h5 m0 text-gray text-upper">Amount</p>
            </div>

            <div className="col-3 px2">
              <p className="bold center h5 m0 text-gray text-upper">%</p>
            </div>
          </div>
        </div>

        <div className="px2 py1">
          {shares.map(share => (
            <ManualShare
              key={share.id}
              share={share}
              updateTopState={updateTopState}
              updateItem={updateItem}
              edit={edit}
            />
          ))}
          {this.displayAdd()}
        </div>

        <div className="px2 py1">
          <div className="bg-blue flex mxn2">
            <div className="col-3 center px2">
              <span className="h6 m0 text-white text-upper">Total</span>
            </div>

            <div className="col-3 center px2" />

            <div className="col-3 center px2">
              <span className="h6 m0 text-white text-upper">{shareTotals.amount}</span>
            </div>

            <div className="col-3 center px2">
              <span className="h6 text-upper text-white">{shareTotals.percentage}</span><span className={`${(limitExceeded && edit) ? 'text-orange' : ''}`}>{(limitExceeded && edit) ? '*' : ''}</span>
            </div>
          </div>
        </div>
        { (edit && limitExceeded) &&
          <div className="flex flex-justify-end h6 text-orange pr3">
            <span
              data-balloon="The total percentage is calculated as the sum of percentages entered manually above. Please change the percentages assigned to the shareholders, so the total does not exceed 100. This will lead to incorrect pie chart values!"
              data-balloon-pos="down"
              data-balloon-length="large"
            >
              WARNING: The total % of shares should never exceed 100!
            </span>
          </div>
        }
      </div>
    );
  }
}

export default ManualSharesTable;
