import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import axios from 'axios';

import Avatars from '../list/Avatars';
import Avatar from './Avatar';
import FaButtonFunction from './FaButtonFunction';
import MemberCardAdmin from './MemberCardAdmin';
import SelectWithModal from '../selects/SelectWithModal';
import { defaultSelectStyles, defaultSelectTheme } from '../selects/SelectStyles';
import CheckBox from '../CheckBox';
import Switch from '../Switch';


class InvestorMember extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      canDownloadDrFiles: props.data.can_download_dr_files,
    };
  }

  accept = () => {
    const { data } = this.props;
    const { accept_url } = data;

    App.Api.patch(accept_url).then((returnedData) => {
      store.dispatch({ type: 'SET_ITEM', list: 'main', id: returnedData.id, value: returnedData });
      App.State.setFlash({ name: 'notice', msg: 'Partner request accepted!' });
    });
  }

  assignFund = (fundId) => {
    const { data } = this.props;
    const params = { fund_connection: { fund_id: fundId } };

    const url = `/investor/${data.investorId}/memberships/${data.membershipId}/fund_connections`;

    (async () => {
      await axios.post(url, params).then((response) => {
        store.dispatch({ type: 'SET_ITEM', list: 'main', id: response.data.id, value: response.data });
      });
    })();
  }

  removeFund = (fundConnectionId) => {
    const { data } = this.props;

    const url = `/investor/${data.investorId}/memberships/${data.membershipId}/fund_connections/${fundConnectionId}`;

    (async () => {
      await axios.delete(url).then(
        (response) => {
          store.dispatch({ type: 'SET_ITEM', list: 'main', id: response.data.id, value: response.data });
        },
      );
    })();
  }

  assignRole = (value) => {
    const { data } = this.props;
    const { manage_url } = data;

    App.Api.patch(manage_url, 'role', value).then((returnedData) => {
      store.dispatch({ type: 'SET_ITEM', list: 'main', id: returnedData.id, value: returnedData });
      App.State.setFlash({ name: 'notice', msg: `User is set to ${returnedData.role}` });
      this.setState({ canDownloadDrFiles: returnedData.can_download_dr_files });
    });
  }

  decline = () => {
    const { data } = this.props;
    const { manage_url } = data;

    App.Api.delete(manage_url).then(() => {
      store.dispatch({ type: 'REMOVE_ITEM', list: 'main', id: data.id });
      App.State.setFlash({ name: 'notice', msg: 'Partner request declined!' });
    });
  }

  handleDataRoomDownloadFlag = async () => {
    const { data } = this.props;
    const { canDownloadDrFiles } = this.state;

    const newState = !canDownloadDrFiles;

    try {
      await axios.patch(data.manage_url, { can_download_dr_files: newState });
      this.setState({ canDownloadDrFiles: newState }, () => {
        App.State.setFlash({ name: 'notice', msg: `Data Room download permission set to ${newState}.` });
      });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an error prevented the membership from being updated.' });
    }
  }

  displayActions = () => {
    const { data } = this.props;

    if (data.admin && data.pending) {
      return (
        <div className="">
          <FaButtonFunction faIcon="times" tooltip="Reject" handleClick={this.decline} color="red" />
          <FaButtonFunction faIcon="check" tooltip="Accept" handleClick={this.accept} color="green" />
        </div>
      );
    }
    if (data.admin && !data.self) {
      return (
        <MemberCardAdmin
          id={data.id}
          manage_url={data.manage_url}
          userAdmin={data.status === 'Admin'}
          name={data.name}
          reinvite_url={data.reinvite_url}
        />
      );
    }
    return null;
  }

  displayFund = () => {
    const { data } = this.props;

    return (
      <div className="col-2 border-alto py1 pl2 sm-hide">
        <SelectWithModal
          elementsToSelect={data.fund_options}
          selectedElements={data.funds_assigned}
          addElement={this.assignFund}
          deleteElement={this.removeFund}
          disableSelect={!data.admin || data.self || data.isMemberAdmin}
          disableTooltip={false}
          title="Assign Partner to Fund"
          placeholder={data.isMemberAdmin || data.funds_assigned.length === 0 ? 'All Funds & Companies' : null}
        />
      </div>
    );
  }

  displayRole = () => {
    const { data } = this.props;
    if (data.admin && !data.self) {
      return (
        <div className="col-2 border-alto py1 pl2 sm-hide">
          <ReactSelect
            value={data.role_options.filter(option => option.value === data.role)}
            options={data.role_options}
            onChange={(selectedOption) => this.assignRole(selectedOption.value)}
            isClearable={false}
            isSearchable={false}
            styles={defaultSelectStyles}
            theme={defaultSelectTheme}
          />
        </div>
      );
    }
    return (
      <div className="col-2 border-alto py1 pl2 sm-hide flex flex-center truncate capitalize">
        {data.role}
      </div>
    );
  }

  getColClass = () => {
    const { data } = this.props;

    let status = 2;
    let actions = 2;

    if (data.has_funds) {
      status = 1;
      actions = 1;
    }
    return { status, actions };
  }

  render() {
    const { data } = this.props;
    const { canDownloadDrFiles } = this.state;

    return (
      <div className="col-12 flex flex-wrap flex-center px2 h5 sm-px0">
        <div className="col-3 border-alto py1 pl2 sm-border-right-none sm-col-8 sm-pr2">
          <a
            href={data.url}
            className="flex"
            data-balloon={data.email}
            data-balloon-pos="down"
          >
            <Avatar avatarUrl={data.avatar} />
            <span
              className="ml1 truncate"
              style={{ maxWidth: '150px' }}
            >
              {data.name}
            </span>
          </a>
        </div>

        {this.displayRole()}

        {data.has_funds && this.displayFund()}

        <div className="col-2 border-alto py1 pl2 sm-hide">
          <Avatars avatars={data.following} />
        </div>

        <div className="col-1 border-alto py1 pl2 sm-hide">
          <div
            className="col-4"
            data-balloon={data.role !== 'guest' ? 'User can always download Data Room files.' : 'Set if user can download Data Room files'}
            data-balloon-pos="right"
          >
            <Switch
              checked={canDownloadDrFiles}
              onChange={this.handleDataRoomDownloadFlag}
              disabled={data.role !== 'guest'}
            />
          </div>
        </div>

        <div className={`${this.getColClass.status} text-${data.status === 'Invited' ? 'light-gray' : 'gray'} border-alto py1 pl2 sm-border-right-none sm-col-3`}>
          {data.status}
        </div>

        <div className={`${this.getColClass.actions} border-alto p1 flex sm-border-top-none sm-col-1`}>
          <div className="flex flex-auto flex-justify-end sm-flex-justify-center relative">
            {this.displayActions()}
          </div>
        </div>

      </div>
    );
  }
}

export default InvestorMember;
