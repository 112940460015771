import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Modal from '../../Modal';
import HtmlComponent from '../../tab_view/HtmlComponent';
import SetupUpdateSchedule from '../pro_features/SetupUpdateSchedule';


class ScheduleUpdateReminder extends Component {
  static propTypes = {
    updateId: PropTypes.number.isRequired,
    company: PropTypes.object.isRequired,
    dueUpdate: PropTypes.bool.isRequired,
    updateType: PropTypes.string.isRequired,
    updateInfo: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    setupProps: PropTypes.object.isRequired,
  }

  state = {
    show: false,
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('updateReminder', 'true');
    window.history.replaceState({}, '', `${window.location.pathname}?${queryParams}`);

    const { show } = this.state;

    setTimeout(() => (
      this.setState({ show: !show })
    ), 300);
  }

  updateTypeText = () => {
    const { updateType } = this.props;

    switch (updateType) {
      case 'Quarterly Recurring Update':
        return 'of the quarter';
      case 'Weekly Recurring Update':
        return 'of the week';
      default:
        return 'of the month';
    }
  }

  removeUpdateReminderParam = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('updateReminder')) {
      queryParams.delete('updateReminder');
      const newUrl = `${window.location.pathname}?${queryParams}`;
      window.history.replaceState({}, '', newUrl);
    }
  }

  toogleModal = () => {
    const { show } = this.state;

    if (show) {
      this.removeUpdateReminderParam();
    }

    this.setState({ show: !show });
  }

  handleHide = () => {
    const { company } = this.props;

    axios.post(`/company/${company.id}/notifications/scheduled_update_reminders`).then(
      () => this.toogleModal(),
    );
  }

  render() {
    const {
      updateId,
      company,
      dueUpdate,
      updateType,
      updateInfo,
      color,
      status,
      setupProps,
    } = this.props;

    const { show } = this.state;

    return (
      <React.Fragment>
        <Modal
          show={show}
          onClickOutside={this.toogleModal}
          widthClass="react_modal--600"
        >
          <div className="flex flex-justify-between items-center fw400 p2 border-bottom border-lighter-gray mb2">
            <div className="h3 pl2">Scheduled Update Reminder</div>
            <i className="fa fa-close h5 cursor-pointer" onClick={this.toogleModal} />
          </div>

          <div className="fw400 px3 pb2 overflow-y-auto" style={{ maxHeight: '65vh' }}>
            <div className="flex items-center mt1">
              <a
                href={`/company/${company.id}/shareholder_updates/${updateId}/recipients`}
                className={`col-7 bg-white rounded p2 text-gray border border-${color}`}
              >
                <div className="h1 semi-bold lh-1 pb2 border-bottom border-alto">
                  <div className="flex flex-column">
                    <span className="mb1 nowrap">
                      {updateInfo}
                    </span>
                    Update
                  </div>
                </div>
                <div className="flex">
                  <div className="mt1">
                    <HtmlComponent
                      data={{ html: status }}
                    />
                  </div>
                </div>
              </a>
              <div className="ml2 col-5">
                <div>
                  <span className="text-gray bold">{updateType}</span>
                  <div className="mt1 light text-light-gray h5 lh-12">
                    {dueUpdate ?
                      `Regular updates are the best way to build relationships that matter. Click on ${updateInfo} Update to continue.`
                      :
                      `Oh no, seems like you didn't get around to sending an update yet. Make sure that you update your recipients in a timely manner. Luckily you still have time until the end ${this.updateTypeText()}.`
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-justify-end items-center p2 border-top border-lighter-gray mt1">
            <div
              className="h5 text-light-gray px2 py1 cursor-pointer hover hover-text-gray"
              onClick={this.handleHide}
              data-balloon="Snoozing the Reminder will not show this info again, until the next Update is available."
              data-balloon-pos="up"
              data-balloon-length="large"
            >
              Snooze Reminder
            </div>
            <div
              className="h5 text-blue bold px2 py05 border rounded border-blue cursor-pointer mr1"
              onClick={this.closeModal}
            >
              <span className="text-blue">
                <SetupUpdateSchedule
                  text={`Change Schedule ${company.subscribed ? '' : '(Pro)'}`}
                  schedule_infos={setupProps.schedule_infos}
                  reminder_infos={setupProps.reminder_infos}
                  allowUpdateReminders={setupProps.allowUpdateReminders}
                  next_available_updates_info={setupProps.next_available_updates_info}
                  company={company}
                  showIcon={false}
                  showBackButton={false}
                />
              </span>
            </div>

          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ScheduleUpdateReminder;
