import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactSelect from 'react-select';

import { defaultSelectStyles, defaultSelectTheme } from '../../selects/SelectStyles';

import Textarea from '../../Textarea';
import FileField from '../../FileField';
import Avatar from '../../tab_view/Avatar';
import SelectWithModal from '../../selects/SelectWithModal';

import MapCover from '../modules/MapCover';
import ConnectInvestmentModal from './modals/ConnectInvestmentModal';
import Button from '../../Button';
import ConfirmationModalOnComponent from '../../ConfirmationModalOnComponent';


class Header extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    tabUrl: PropTypes.string.isRequired,
    address: PropTypes.string,
    investment: PropTypes.object.isRequired,
    createOrUpdateFields: PropTypes.func.isRequired,
    deleteCover: PropTypes.func.isRequired,
    addManager: PropTypes.func.isRequired,
    deleteManager: PropTypes.func.isRequired,
    addFundConnection: PropTypes.func.isRequired,
    deleteFundConnection: PropTypes.func.isRequired,
    selectOverallStatus: PropTypes.func.isRequired,
    selectFundraiseStatus: PropTypes.func.isRequired,
  }

  static defaultProps = {
    address: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      coverImage: props.investment.cover.image,
      savingCover: false,
      address: props.address || props.investment.address_fields.map(a => a.text).filter(a => a !== '').join(', '),
      showConnectInvestmentModal: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.address) {
      this.setState({ address: nextProps.address });
    }
  }

  prevNextLink = (id) => {
    const { activeTab, investment } = this.props;

    switch (activeTab) {
      case 'funding':
        return `/investor/${investment.investor.id}/investments/${id}/funding/${investment.edit ? 'edit' : ''}`;
      case 'kpis':
        return `/investor/${investment.investor.id}/investments/${id}/kpi_fields/${investment.edit ? 'edit' : ''}`;
      case 'data_rooms':
        return `/investor/${investment.investor.id}/investments/${id}/data_rooms`;
      case 'updates':
        return `/investor/${investment.investor.id}/investments/${id}/updates`;
      case 'newsfeed':
        return `/investor/${investment.investor.id}/investments/${id}/feeds`;
      default:
        return `/investor/${investment.investor.id}/investments/${id}/${investment.edit ? 'edit' : ''}`;
    }
  }

  overwriteField = async (id, name, value, overwrite) => {
    const { investment, createOrUpdateFields } = this.props;

    if (overwrite === 'cover') this.setState({ coverImage: true, savingCover: true });

    const formData = new FormData();
    formData.append('investment_field[ids][]', id);
    formData.append(`investment_field[values][][${name}]`, value);
    formData.append('investment_field[values][][overwrite]', overwrite);

    await createOrUpdateFields(investment.investor.id, investment.id, id, formData);
    this.setState({ savingCover: false });
  }

  removeCover = () => {
    const { investment, deleteCover } = this.props;
    this.setState({ coverImage: false });

    if (typeof investment.cover.id === 'number') {
      const params = {
        investment_field: {
          ids: [investment.cover.id],
        },
      };
      deleteCover(investment.investor.id, investment.id, params);
    }
  }

  renderStatusDropDown = (type) => {
    const { investment, selectOverallStatus, selectFundraiseStatus } = this.props;

    let statusOptions,
      saveFunction,
      innerStatus,
      open;
    if (type === 'fundraise') {
      statusOptions = investment.fundraise_status_options;
      saveFunction = selectFundraiseStatus;
      innerStatus = investment.fundraise_status;
      open = 'showFundraisingStatusDropdown';
    } else {
      statusOptions = investment.overall_status_options;
      saveFunction = selectOverallStatus;
      innerStatus = investment.status;
      open = 'showOverallStatusDropdown';
    }

    const statusColor = innerStatus ? innerStatus.color_hex : null;
    const { [open]: show } = this.state;

    const options = statusOptions.map(option => (
      <li key={`${type}_${option.color_hex}`} className="p1">
        <div
          className="block hover hover-bs-light-white border border-alto rounded"
          onClick={(e) => { e.preventDefault(); saveFunction(investment.investor.id, option.id, investment.id, investment.company_report); }}
          style={{ height: '30px', width: '30px', backgroundColor: `#${option.color_hex}` }}
          data-balloon={option.name}
          data-balloon-pos="down"
          data-balloon-length="xsmall"
        />
      </li>
    ));

    return (
      <div className="ml2">
        <div className="flex flex-row items-center cursor-pointer" onClick={() => this.setState(state => ({ [open]: !state[open] }))}>
          <div className="relative">
            <span
              className="flex flex-justify-center border border-alto rounded"
              style={{ height: '15px', width: '15px', backgroundColor: `#${statusColor}` }}
              data-balloon={innerStatus ? innerStatus.name : null}
              data-balloon-pos="down"
              data-balloon-length="xsmall"
            />
            <div className={`dropdown dropdown--center z2 absolute bg-white border border-alto bs-dropdown left-50 transform-horizontal-center z1 ${show ? '' : 'hidden'} py1`}>
              <ul className="flex flex-justify-center m0 fs18 mw200">
                {options}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      activeTab,
      tabUrl,
      investment,
      addManager, deleteManager,
      addFundConnection, deleteFundConnection,
    } = this.props;
    const { coverImage, savingCover, address, showConnectInvestmentModal } = this.state;

    const inputClasses = classNames('p0 lh-125 bg-none text-gray', {
      'border border-lighter-gray hover hover-border-blue': investment.edit,
      'border-transparent': !investment.edit,
    });

    const overallStatusColor = investment.status ? investment.status.color_hex : null;
    const fundRaiseStatusColor = investment.fundraise_status ? investment.fundraise_status.color_hex : null;
    const customDataHint = 'As an investor, you have an option to add profile and company data of your choice regardless of what your portfolio has entered, and is visible only to you in your dashboard. Click on &ldquo;i&rdquo; icon to get more information on custom data.';
    const hintIconUrl = `<a href=" http://docs.investory.io/en/articles/6860231-company-custom-data-management" target="_blank" rel="noopener noreferrer" class="h5 fw400 cursor-pointer" data-balloon="${customDataHint}" data-balloon-length="xlarge">What is Custom Data? <i class="fa fa-info-circle text-blue ml1"/><span/>`;

    return (
      <div>
        <div className="flex flex-justify-between mb1 pt2 sm-flex-column sm-items-center">
          {!investment.investor.individual &&
            <div className="flex text-light-gray h5">
              <div className="flex items-center mr3 sm-mr2">
                Status
                {!investment.guest ?
                  this.renderStatusDropDown('overall')
                  :
                  <span
                    style={{ height: '15px', width: '15px', backgroundColor: `#${overallStatusColor}` }}
                    className="ml1 border border-alto rounded"
                    data-balloon={investment.status ? investment.status.name : null}
                    data-balloon-pos="down"
                    data-balloon-length="xsmall"
                  />
                }
              </div>
              <div className="flex items-center mr3 sm-mr2">
                Fundraising
                {!investment.guest ?
                  this.renderStatusDropDown('fundraise')
                  :
                  <span
                    style={{ height: '15px', width: '15px', backgroundColor: `#${fundRaiseStatusColor}` }}
                    className="ml1 border border-alto rounded"
                    data-balloon={investment.fundraise_status ? investment.fundraise_status.name : null}
                    data-balloon-pos="down"
                    data-balloon-length="xsmall"
                  />
                }
              </div>
              <div className="flex items-center mr3 sm-mr2">
                <span className="mr1">Fund{investment.funds.length === 1 ? '' : '(s)' }</span>
                <SelectWithModal
                  elementsToSelect={investment.funds_for_options}
                  selectedElements={investment.funds}
                  addElement={(fundId) => addFundConnection(investment.investor.id, investment.id, fundId)}
                  deleteElement={(fundConnectionId) => deleteFundConnection(investment.investor.id, investment.id, fundConnectionId)}
                  disableSelect={investment.guest || !investment.admin || investment.investor.individual}
                  disableTooltip={false}
                  title="Manage Fund Assignments"
                  size="18px"
                />
              </div>
              <div className="flex items-center mr3 sm-mr2">
                <span className="mr1">Manager{investment.managers.length === 1 ? '' : '(s)' }</span>
                <SelectWithModal
                  elementsToSelect={investment.managers_for_options}
                  selectedElements={investment.managers}
                  addElement={(membershipId) => addManager(investment.investor.id, investment.id, membershipId)}
                  deleteElement={(managerId) => deleteManager(investment.investor.id, investment.id, managerId)}
                  disableSelect={investment.guest || !investment.admin}
                  title="Manage Manager Assignments"
                  size="18px"
                />
              </div>
            </div>
          }
          <div className="flex flex-auto flex-justify-end fw400 h6 items-center sm-mt1 sm-mb1">
            <ReactSelect
              value={investment.investments_for_options.filter(inv => inv.value === investment.id)}
              options={investment.investments_for_options}
              onChange={(selectedOption) => { window.location = this.prevNextLink(selectedOption.value); }}
              className="col-4 mr2 z2 mb1"
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
            />

            {investment.previous_investment_id &&
              <a
                className="text-light-gray mr2"
                href={this.prevNextLink(investment.previous_investment_id)}
              >
                &lt; Prev
              </a>
            }
            {investment.next_investment_id &&
              <a
                className="text-light-gray mr2"
                href={this.prevNextLink(investment.next_investment_id)}
              >
                Next &gt;
              </a>
            }
            {investment.edit ?
              <a href={`/investor/${investment.investor.id}/${tabUrl}`} className="border border-alto bg-alto text-gray text-gray px2 rounded">Back to View</a>
              :
              !investment.guest &&
                (activeTab === 'data_rooms' || activeTab === 'newsfeed') ?
                  <span
                    className="border border-alto bg-alto text-gray px2 rounded sm-hide cursor-disabled"
                    data-balloon="No editing options available!"
                    data-balloon-pos="down"
                    data-balloon-length="medium"
                  >
                    Edit
                  </span>
                :
                  <a
                    href={`/investor/${investment.investor.id}/${tabUrl}/edit`}
                    className={`border border-alto bg-alto text-gray px2 rounded sm-hide ${activeTab === 'data_rooms' || activeTab === 'newsfeed' ? 'disabled' : ''}`}
                  >
                    Edit
                  </a>
            }
          </div>
        </div>

        <div className="bs-around">
          <div className="relative" style={{ height: '120px' }}>
            {investment.custom_investment &&
              <div className="desktop__notification--changes-blue" />
            }
            <div className="investment__header-overlay relative" />
            {coverImage ?
              <div
                className="bg-center bg-cover bg-no-repeat"
                style={{ backgroundImage: `url(${investment.cover.image})`, weight: '100%', height: '100px' }}
                data-behavior="investment-cover-preview"
              />
              :
              <MapCover address={address} />
            }
            {investment.edit &&
              <div>
                {savingCover ?
                  <div className="absolute top-0 right-0 px1 h6 bg-black--transparent text-white image-preview rounded">
                    Saving...
                  </div>
                  :
                  <div
                    className="absolute top-0 px1 h6 bg-black--transparent text-white image-preview rounded"
                    style={{ right: coverImage ? '26px' : '0' }}
                  >
                    <FileField
                      name="image"
                      preview_target="[data-behavior='investment-cover-preview']"
                      onChange={img => this.overwriteField(investment.cover.id, 'image', img, 'cover')}
                    />
                    <i className="fa fa-camera mr1" />Upload
                  </div>
                }
                {coverImage && !savingCover &&
                  <div
                    className="absolute top-0 right-0 z4 px1 h6 bg-black--transparent text-white cursor-pointer rounded"
                    onClick={this.removeCover}
                  >
                    <i className="fa fa-trash" />
                  </div>
                }
              </div>
            }
            <div className="absolute top-0 ml3 mt3 z1 filter-shadow-lighter col-12 pr3">
              <div className="flex items-center mt1">
                {investment.edit ?
                  <div className="image-preview inline-block relative">
                    <div
                      className="image-preview--50px user-avatar bg-center bg-cover bg-no-repeat circle"
                      style={{ backgroundImage: `url(${investment.avatar.image})` }}
                      data-behavior="investment-avatar-preview"
                    />
                    <FileField
                      name="image"
                      preview_target="[data-behavior='investment-avatar-preview']"
                      onChange={img => this.overwriteField(investment.avatar.id, 'image', img, 'avatar')}
                    />
                    <i className="fa fa-camera fa--funds absolute left-25 text-white vertical-center transform-center" />
                  </div>
                  :
                  <>
                    <Avatar size="50px" avatarUrl={investment.avatar.image} />
                    {investment.real_company &&
                      <div className="relative">
                        <span className="real_company__notification" style={{ top: '-30px', right: '-12px' }} data-behavior="hide-bubble">
                          <i className="fa fa-check-circle text-green" />
                        </span>
                      </div>
                    }
                  </>
                }
                <div className="flex flex-column ml2 mr2 flex-auto">
                  <Textarea
                    className={`h3 col-8 sm-col-12 fw400 ${inputClasses}`}
                    name="text"
                    value={investment.company_name.text}
                    onBlur={e => this.overwriteField(investment.company_name.id, 'text', e.target.value, 'name')}
                    disabled={!investment.edit}
                    maxLength="60"
                    rows="1"
                  />
                  <Textarea
                    className={`h5 ${investment.edit ? 'col-10' : 'col-12'} sm-col-12 truncate ${inputClasses}`}
                    name="text"
                    value={investment.about.text}
                    onBlur={e => this.overwriteField(investment.about.id, 'text', e.target.value, 'about')}
                    disabled={!investment.edit}
                    maxLength="120"
                    rows="1"
                  />
                </div>
                {!investment.edit && (investment.custom_investment ?
                  <div className="flex flex-column flex-end col-3 sm-hide mr2">
                    <Button
                      className="btn btn-outline border-alto bg-green text-white col-9 px2 py05 rounded h5 fw400 flex flex-justify-center"
                      // onClick={() => this.setState({ showConnectInvestmentModal: true })}
                      onClick={() => $('[data-behavior="modal"][data-target="#modal-invite-simple"]').trigger('click')}
                    >
                      Invite Company
                    </Button>
                    <ConnectInvestmentModal
                      show={showConnectInvestmentModal}
                      closeModal={() => this.setState({ showConnectInvestmentModal: false })}
                      companyOptions={investment.company_options}
                      investorId={investment.investor.id}
                      investmentId={investment.id}
                    />
                    {investment.can_convert_to_investor_managed_company &&
                      <ConfirmationModalOnComponent
                        message={`Converting a custom company account to investor-managed company account will remove custom profile and custom KPI data. However, it will still be available in the investor-managed company account and will be editable from the company's view.<br/><br/> Please note that investor-managed companies count towards your assigned companies count. <br/><br/> ${hintIconUrl}`}
                        confirmationText="Convert"
                        cancelText="Cancel"
                        className="col-9"
                        width=""
                        onClick={() => { window.location = `/investor/${investment.investor.id}/companies/new?investment_id=${investment.id}`; }}
                      >
                        <Button
                          type="button"
                          classNames="btn btn-outline border-alto bg-blue text-white center px2 py05 rounded h5 fw400 mt1 flex flex-justify-center"
                        >
                          Convert to Investor-Managed Company
                        </Button>
                      </ConfirmationModalOnComponent>
                    }
                  </div>
                  :
                  <div className="flex flex-column flex-end col-2 sm-hide mr2">
                    <a
                      href={`/c/${investment.company_permalink}`}
                      className="bg-blue text-white center col-12 px2 py05 rounded h5 fw400"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-balloon="This button opens in a new tab the same view of the company that the conpany team sees. To come back to the investor view, just close the new tab."
                      data-balloon-pos="left"
                      data-balloon-length="large"
                    >
                      Go To Company <i className="fa fa-external-link" />
                    </a>

                    <div
                      className="bg-orange text-white center col-12 px2 py05 rounded h5 fw400 mt1 cursor-pointer"
                      data-behavior="modal"
                      data-target="#modal-message"
                    >
                      Send Message
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex bg-white px2 sm-items-center">
            <a
              href={`/investor/${investment.investor.id}/investments/${investment.id}/${investment.edit ? 'edit' : ''}`}
              className={`mx2 py1 h5 ${activeTab === 'profile' ? 'text-gray border-bottom-2 border-blue' : 'text-light-gray'}`}
            >
              Profile
            </a>
            <a
              href={`/investor/${investment.investor.id}/investments/${investment.id}/funding/${investment.edit ? 'edit' : ''}`}
              className={`mx2 py1 h5 ${activeTab === 'funding' ? 'text-gray border-bottom-2 border-blue' : 'text-light-gray'}`}
            >
              Funding
            </a>
            <a
              href={`/investor/${investment.investor.id}/investments/${investment.id}/kpi_fields/${investment.edit ? 'edit' : ''}`}
              className={`mx2 py1 h5 ${activeTab === 'kpis' ? 'text-gray border-bottom-2 border-blue' : 'text-light-gray'}`}
            >
              KPIs
            </a>
            <a
              href={`/investor/${investment.investor.id}/investments/${investment.id}/data_rooms/`}
              className={`mx2 py1 h5 ${activeTab === 'data_rooms' ? 'text-gray border-bottom-2 border-blue' : 'text-light-gray'}`}
            >
              Data Rooms
            </a>
            <a
              href={`/investor/${investment.investor.id}/investments/${investment.id}/feeds/`}
              className={`mx2 py1 h5 ${activeTab === 'newsfeed' ? 'text-gray border-bottom-2 border-blue' : 'text-light-gray'}`}
            >
              Notes
            </a>
            {!investment.custom_investment &&
              <a
                href={`/investor/${investment.investor.id}/investments/${investment.id}/updates/`}
                className={`mx2 py1 h5 ${activeTab === 'updates' ? 'text-gray border-bottom-2 border-blue' : 'text-light-gray'}`}
              >
                Updates
              </a>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
