import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import DatePicker from '../selects/DatePicker';
import { defaultSelectStyles, defaultSelectTheme } from '../selects/SelectStyles';


class TimeframeOptions extends Component {
  static propTypes = {
    shown: PropTypes.number,
    date_options: PropTypes.array,
    period_step: PropTypes.string,
    period_start: PropTypes.string,
    period_end: PropTypes.string,
    invalidDates: PropTypes.bool,
    setStateInKpiSection: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.state = {
      disabledInterval: this.props.shown !== 0,
      period_start: this.props.period_start,
      period_end: this.props.period_end,
    };
  }


  setPeriodEnd = (period_end) => {
    const { setStateInKpiSection } = this.props;
    const { period_start } = this.state;

    setStateInKpiSection({ period_end });
    this.setState({ period_end });
    this.invalidDates(period_start, period_end);
  }

  setPeriodStart = (period_start) => {
    const { setStateInKpiSection } = this.props;
    const { period_end } = this.state;

    setStateInKpiSection({ period_start });
    this.setState({ period_start });
    this.invalidDates(period_start, period_end);
  }

  setTimeFrame = (val) => {
    const { setStateInKpiSection } = this.props;

    this.setState({ disabledInterval: val !== 0 });
    setStateInKpiSection({ shown: val, period_start: null, period_end: null });
  }

  invalidDates = (date1, date2) => {
    const { setStateInKpiSection } = this.props;

    if (date1 && date2) {
      if (date1 < date2) {
        setStateInKpiSection({ invalidDates: false, period_start: date1, period_end: date2 });
      } else {
        setStateInKpiSection({ invalidDates: true });
      }
    }
  }

  isCustomInterval = () => (this.props.shown === 0)

  render() {
    const { shown, date_options, invalidDates } = this.props;
    const { disabledInterval, period_start, period_end } = this.state;

    return (
      <div className="px3 pb2 border-bottom sm-mt2" style={{ borderColor: '#bcc7cf', backgroundColor: '#ededed' }}>
        <div className="flex">
          <p className="h5 mb1 text-light-gray">Select Timeframe</p>
          {
            this.isCustomInterval() &&
            <span
              className="btn btn-primary col-1 ml1"
              data-balloon="Set the timeframe you want to display in the chart. Please note that only available data is shown. For example if you select dates from January to March, but only data for February and March is available, January will not be included. Add KPI data to expand the timeframe!"
              data-balloon-pos="down"
              data-balloon-length="xlarge"
            >
              <i className="fa text-blue fa-question-circle-o fs-18" />
            </span>
          }
        </div>
        <div className="h5 mb2 relative flex flex-justify-between sm-flex sm-flex-column">
          <div className="h5 left-align relative col-4 sm-col-12">
            <ReactSelect
              name="kpi_chart[values_shown]"
              value={date_options.filter(option => option.value === shown)}
              options={date_options}
              onChange={(selectedOption) => { this.setTimeFrame(selectedOption.value); }}
              isClearable={false}
              isMulti={false}
              placeholder="Select a Kpi"
              styles={defaultSelectStyles}
              theme={defaultSelectTheme}
            />
          </div>
          <div className={`col-3 sm-col-12 sm-ml0 italic ${disabledInterval ? 'opacity-40' : ''}`}>
            <DatePicker
              placeholder="From"
              value={period_start}
              onChange={val => this.setPeriodStart(val.target.value)}
              disabled={disabledInterval}
              invalidDates={invalidDates}
              style={{ height: '38px', borderRadius: '4px', width: '100%', borderColor: '#bcc7cf' }}
              className="border rounded"
            />
          </div>
          <div className={`col-3 sm-col-12 sm-ml0 italic ${disabledInterval ? 'opacity-40' : ''}`}>
            <DatePicker
              placeholder="To"
              value={period_end}
              onChange={val => this.setPeriodEnd(val.target.value)}
              disabled={disabledInterval}
              invalidDates={invalidDates}
              style={{ height: '38px', borderRadius: '4px', width: '100%', borderColor: '#bcc7cf' }}
              className="border rounded"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TimeframeOptions;
