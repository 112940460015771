import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ShareholdersOverview from '../cap_table/shareholders/ShareholdersOverview';

import chartOptions from '../utils/highcharts/pieChartOptions';

class CapTableWidget extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  chartOptions = () => {
    const { data } = this.props;

    return chartOptions(data.capTable.data.shareholders_overview.chart_data, { width: '200' });
  }

  render() {
    const { data } = this.props;

    if (!data.visible) {
      return (
        <div className="col-9 flex p1 sm-col-12">
          <div className="bg-white bs-around col-12 flex flex-center flex-justify-center p1">
            {data.admin ?
              <a className="bg-blue p1 bs-around-white-light flex hover hover-layer relative rounded sm-col-12 center" href={data.edit_url}>
                <span className="text-white h4">Configure your captable now</span>
              </a>
              :
              <span className="text-light-gray p2 flex relative sm-col-12 center">
                Cap Table is currently unavailable.
              </span>
            }
          </div>
        </div>
      );
    }

    if (!data.has_access) {
      return (
        <div className="col-9 sm-col-12 px1 p1 flex">
          <div className="bs-around bg-white p1 col-12">
            <div className="text-gray bold flex mb2">Overview</div>
            <div className="flex col-12 center flex-column text-medium-gray">
              Access to the Captable has been disabled.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="col-9 p1" style={{ minHeight: '270px' }}>
        <ShareholdersOverview
          capTable={data.capTable}
          chartOptions={this.chartOptions()}
          linkTo={data.captable_url}
          dashboard
        />
      </div>
    );
  }
}

export default CapTableWidget;
