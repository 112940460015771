import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../../store/configureStore';
import SlideshowContainer from '../../containers/investor/SlideshowContainer';


const InvestorSlideshow = props => (
  <Provider store={configureStore(props)}>
    <SlideshowContainer />
  </Provider>
);

export default InvestorSlideshow;
