import axios from 'axios';
import {
  addGenericKpi,
  removeGenericKpi,
  resetGenericKpisSelection,
  updateFrequency,
  createGenericKpis,
  changeGenericKpiValues,
  updateGenericKpisUsedState,
} from '../actions/kpisLibraryActions';
import {
  resetKpis,
} from '../actions/company/kpiActions';

const manageKpisLibrarySelection = (genericKpi) =>
  (dispatch, getState) => {
    const { selectedKpis } = getState().kpisLibrary;

    if (genericKpi === null) {
      dispatch(resetGenericKpisSelection());
    } else if (selectedKpis.map(kpi => kpi.id).includes(genericKpi.id)) {
      dispatch(removeGenericKpi(genericKpi));
    } else {
      dispatch(addGenericKpi(genericKpi));
    }
  };

const changeFrequency = (frequency) =>
  async (dispatch, getState) => {
    // const companyId = getState().company.id;
    // const res = await axios.get(`/company/${companyId}/generic_kpis?frequency=${frequency}`);

    // dispatch(updateFrequency(frequency, res.data));
    dispatch(updateFrequency(frequency));
  };

const createKpisFromLibrary = () =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const { frequency, selectedKpis } = getState().kpisLibrary;
    const params = { kpis_library: { generic_kpis: selectedKpis, frequency } };

    const res = await axios.post(`/company/${companyId}/generic_kpis`, params);

    dispatch(createGenericKpis(res.data));
  };

const linkKpiToLibrary = (singleKpiId) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const { selectedKpis } = getState().kpisLibrary;

    const url = `/company/${companyId}/kpis/${singleKpiId}/generic_kpis`;
    const params = { kpis_library: { generic_kpis: selectedKpis } };

    const res = await axios.post(url, params);

    dispatch(resetKpis(res.data));
  };

const updateUsedGenericKpis = () =>
  async (dispatch, getState) => {
    const { data } = getState().kpis;

    const usedGenericKpiIds = data.map(el => el.generic_kpi_id).filter(id => id !== null);

    dispatch(updateGenericKpisUsedState(usedGenericKpiIds));
  };

const editGenericKpi = (genericKpi, field, value) =>
  async (dispatch) => {
    dispatch(changeGenericKpiValues(genericKpi, field, value));
  };

export {
  manageKpisLibrarySelection,
  changeFrequency,
  createKpisFromLibrary,
  editGenericKpi,
  linkKpiToLibrary,
  updateUsedGenericKpis,
};
