import React from 'react';
import PropType from 'prop-types';

import CopyToClipboard from 'react-copy-to-clipboard';


const CopyToClipboardIcon = ({ text, icon, showCopy }) => (
  <CopyToClipboard
    text={text}
    onCopy={() => App.State.setFlash({ name: 'notice', msg: 'The link was copied to your clipboard!' })}
  >
    <div
      className="lh-12 text-light-gray inline-block cursor-pointer"
      data-balloon="Copy Link to Clipboard."
      data-balloon-pos="down"
      data-balloon-length="small"
    >
      <i className={`fa fa-${icon} fa-fw`} /> {showCopy && 'Copy'}
    </div>
  </CopyToClipboard>
);

CopyToClipboardIcon.propTypes = {
  text: PropType.string.isRequired,
  icon: PropType.string,
  showCopy: PropType.bool,
};

CopyToClipboardIcon.defaultProps = {
  icon: 'clipboard',
  showCopy: true,
};

export default CopyToClipboardIcon;
