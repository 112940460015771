import React from 'react';
import PropTypes from 'prop-types';

import SorterHeader from '../../modules/SorterHeader';
import withSorting from '../../utils/withSorting';

const ElasticSearchResults = ({ elasticSearchResults, sortedData }) => (
  <div className="bs-around">
    <div className="col-12 flex py1 px1 text-light-gray h5 fw400 flex p1 center bg-wild-sand border-top border-bottom border-alto pr2">
      <div className="col-3 sm-col-6 flex flex-justify-center">
        <SorterHeader text="Name" field="name" />
      </div>
      <div className="col-1 flex flex-justify-center sm-hide">
        <SorterHeader text="Stage" field="readable_stage" />
      </div>
      <div className="col-2 flex flex-justify-center sm-hide">
        <SorterHeader text="Type" field="type_for_sorter" />
      </div>
      <div className="col-1 flex flex-justify-center sm-hide">
        <SorterHeader text="Industry" field="readable_industry" />
      </div>
      <div className="col-3 flex flex-justify-center nowrap sm-hide">
        <SorterHeader text="Location" field="readable_location" />
      </div>
      <div className="col-1 flex flex-justify-center sm-hide">
        <SorterHeader text="Founded" field="founded_for_sorter" />
      </div>
      <div className="col-2 flex flex-justify-center sm-hide">
        <SorterHeader text="Fundraising (Deal Room open)" field="fundraising" width="70%" />
      </div>
      <div className="col-2 sm-hide flex flex-justify-center">
        <SorterHeader text="Status" field="status" />
      </div>
    </div>
    <div className="col-12">
      {sortedData.length > 0 ?
        sortedData.map(company => (
          <div key={company.id} className="col-12 flex py3 px1 text-gray flex flex-center relative">
            <div className="col-3 sm-col-6">
              <a
                className="ml1 text-gray fw400 flex flex-center"
                href={`/company_info_search/${company.id}`}
                data-balloon={company.about || 'No description'}
                data-balloon-length="xlarge"
                data-balloon-pos="down"
                data-turbolinks={false}
              >
                <div
                  className="block col-3 bg-center bg-cover bg-no-repeat circle header__avatar--user mr1"
                  style={{ backgroundImage: `url(${company.avatar})` }}
                />
                <div className="col-9">
                  <div className="text-gray h4 flex items-center bold">
                    {company.name}
                  </div>
                </div>
              </a>
            </div>

            <div className="col-1 sm-hide truncate center fw400 text-light-gray h5 capitalize truncate-wrap">
              {company.readable_stage}
            </div>

            <div className="col-2 sm-hide truncate center fw400 text-light-gray h5 capitalize truncate-wrap">
              {company.readable_type}
              <span className="text-upper">{` ${company.business_type}`}</span>
            </div>

            <div className="col-1 sm-hide center fw400 text-light-gray h5 capitalize truncate-wrap">
              {company.readable_industry}
            </div>

            <div className="col-3 sm-hide center fw400 text-light-gray h5 truncate-wrap">
              {company.readable_location}
            </div>

            <div className="col-1 sm-hide truncate center fw400 text-light-gray h5 truncate-wrap">
              {company.readable_founded}
            </div>

            <div className="col-2 sm-hide truncate center fw400 text-light-gray h5 truncate-wrap">
              {company.fundraising}
            </div>

            <div className="col-2 sm-hide truncate center fw400 text-light-gray h5 truncate-wrap">
              {company.status}
            </div>
          </div>
        ))
        :
        <p className="empty-notifications">There are no results for this search.</p>
      }
    </div>
  </div>
);

ElasticSearchResults.propTypes = {
  elasticSearchResults: PropTypes.array.isRequired,
  sortedData: PropTypes.array.isRequired,
};

export default withSorting(ElasticSearchResults, (props) => props.elasticSearchResults);
