import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';

import HeaderContainer from '../../../../containers/investor/investments/HeaderContainer';
import InvestmentUpdatesList from '../../modules/InvestmentUpdatesList';


class InvestmentUpdates extends Component {
  static propTypes = {
    investmentHeader: PropTypes.object.isRequired,
    investmentUpdates: PropTypes.object.isRequired,
  }

  state = {
  }

  render() {
    const {
      investmentHeader,
      investmentUpdates,
    } = this.props;

    const address = investmentHeader.address_fields.map(a => a.text).filter(a => a !== '').join(', ');

    return (
      <div>
        <HeaderContainer
          activeTab="updates"
          tabUrl={`investments/${investmentHeader.id}`}
          address={address}
        />
        <div className="flex sm-flex-column col-12 my2">
          <div className="col-12 bg-white flex flex-column">
            <InvestmentUpdatesList
              updates={investmentUpdates.data}
              paginationUrl={`/investor/${investmentHeader.investor.id}/investments/${investmentHeader.id}/updates/`}
              nextPage={investmentUpdates.nextPage}
              investorId={investmentHeader.investor.id}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default InvestmentUpdates;
