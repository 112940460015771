import React from 'react';
import PropTypes from 'prop-types';

import KpiSelector from './KpiSelector';


const SmallKpiWidget = ({ data, col, padding, kpiOptions, saveFields, fieldId, showOptions }) => (
  data.display ?
    <a data-turbolinks="false" className={`col-${col} flex mb2 ${padding} sm-col-12 text-gray`} href={data.url}>
      <div className="bg-white bs-around col-12 p1 card--has-hover show-cog-on-hover">
        <div className="flex flex-center relative">
          <span className="bold h5 pr1">{data.title}</span>
          {
            (data.manageUrl || showOptions) &&
            <KpiSelector
              url={data.manageUrl}
              kpiOptions={kpiOptions}
              saveFields={saveFields}
              fieldId={fieldId}
            />
          }
        </div>
        <p className="text-light-gray bold col-12 h6 m0 lh-14 pb2">
          {data.date}&nbsp;
        </p>
        <div className="bold break-word  ls-n-half">
          <p className="h1 m0">
            {data.value}
            <span className="h3 ml1 text-light-gray">{data.unit}</span>
          </p>
          <p className="text-light-blue h6 m0 lh-14">
            {data.note}
          </p>
        </div>
      </div>
    </a>
    :
    <div className={`col-${col} flex mb2 ${padding} text-gray cursor-pointer`}>
      <div className="bg-white bs-around col-12 p1 card--has-hover show-cog-on-hover">
        <div className="flex flex-center relative pb3 text-light-gray">
          <span className="bold h5 pr1">Select a KPI</span>
          <KpiSelector
            url={data.manageUrl}
            kpiOptions={kpiOptions}
            saveFields={saveFields}
            fieldId={fieldId}
          />
        </div>
      </div>
    </div>
);

SmallKpiWidget.propTypes = {
  data: PropTypes.object.isRequired,
  col: PropTypes.string,
  padding: PropTypes.string,
  kpiOptions: PropTypes.array,
  saveFields: PropTypes.func,
  fieldId: PropTypes.number,
  showOptions: PropTypes.bool,
};

SmallKpiWidget.defaultProps = {
  col: '3',
  padding: 'px1',
  kpiOptions: null,
  saveFields: null,
  fieldId: null,
  showOptions: false,
};

export default SmallKpiWidget;
