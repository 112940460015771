import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SorterHeader from '../SorterHeader';


class InvestorInvestmentHeader extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  getColClass = () => {
    const { data } = this.props;
    const { angel, has_funds } = data;
    let company,
      added,
      funds,
      managers;

    const fundraisingStatus = 'col-1';
    let overallStatus = 'col-1';

    // angel has no funds, no fundraising status and no fund manager
    if (angel) {
      company = 'col-5';
      added = 'col-3';
      overallStatus = 'col-3';
    } else if (!has_funds) {
      company = 'col-4';
      added = 'col-2';
      managers = 'col-3';
    } else {
      company = 'col-4';
      added = 'col-1';
      funds = 'col-2';
      managers = 'col-2';
    }
    return { company, added, funds, managers, fundraisingStatus, overallStatus };
  }

  render() {
    const { data } = this.props;
    const { angel, has_funds } = data;

    return (
      <div className="col-12 text-gray h5 flex px2 bg-extra-light-gray border-top border-bottom border-alto">

        {/* Company */}
        <div className={`${this.getColClass().company} py1 fw400 sm-col-6 sm-pl0 flex flex-center`}>
          {/* Follow */}
          <div
            className="sm-hide"
            style={{ width: '38px' }}
          />
          <SorterHeader text="Company" field="company_name" textColor="gray" />
        </div>

        {/* Added */}
        <div className={`${this.getColClass().added} py1 fw400 flex flex-center sm-hide`}>
          <SorterHeader text="Added" field="added" textColor="gray" />
        </div>

        {
          /* Funds */
          has_funds && !angel &&
          <div className={`${this.getColClass().funds} py1 fw400 flex flex-center sm-hide`}>
            <span className="text-gray">Fund(s)</span>
          </div>
        }

        {
          /* Managers */
          !angel &&
          <div className={`${this.getColClass().managers} py1 fw400 flex flex-center sm-hide`}>
            <span className="text-gray">Manager(s)</span>
          </div>
        }

        {
          /* Fundraising Status */
          !angel &&
          <div className={`${this.getColClass().fundraisingStatus} py1 lh-12 fw400 flex flex-center sm-hide center`}>
            <SorterHeader text="Fundraising Status" field="fundraise_status_for_sorting" textColor="gray" />
          </div>
        }

        {/* Overall Status */}
        <div className={`${this.getColClass().overallStatus} py1 px1 lh-12 flex flex-justify-center flex-center sm-hide center`}>
          <SorterHeader text="Overall Status" field="status" textColor="gray" />
        </div>

        {/* Actions */}
        <div className="col-1 sm-col-6" />
      </div>
    );
  }
}

export default InvestorInvestmentHeader;
