export const setDashboardTab = tabId => ({
  type: 'SET_TAB',
  tabId,
});

export const setKpisTimeAgo = tabProps => ({
  type: 'SET_TIME_AGO',
  tabProps,
});

export const setLoadingOnTab = tabId => ({
  type: 'SET_LOADING_ON_TAB',
  tabId,
});

export const addCompanyInfoData = (companyInfo, lazyLoadingLayer) => ({
  type: 'ADD_COMPANY_INFO_DATA',
  companyInfo,
  lazyLoadingLayer,
});

export const addKpisData = kpis => ({
  type: 'ADD_KPIS_DATA',
  kpis,
});

export const addDataRoomInfoData = dataRooms => ({
  type: 'ADD_DATA_ROOM_DATA',
  dataRooms,
});

export const addFactSheetTabData = factSheetData => ({
  type: 'ADD_FACT_SHEET_TAB_DATA',
  factSheetData,
});

export const addExcelExportTabData = excelExportData => ({
  type: 'ADD_EXCEL_EXPORT_TAB_DATA',
  excelExportData,
});

export const addSlideshowTabData = slideshowData => ({
  type: 'ADD_Slideshow_TAB_DATA',
  slideshowData,
});

export const addInvestmentInfoData = (investmentInfo, lazyLoadingLayer) => ({
  type: 'ADD_INVESTMENT_INFO_DATA',
  investmentInfo,
  lazyLoadingLayer,
});

export const addRecentUpdatesData = recentUpdates => ({
  type: 'ADD_RECENT_UPDATES_DATA',
  recentUpdates,
});

export const addPortfolioUpdatesData = monthlyUpdates => ({
  type: 'ADD_PORTFOLIO_UPDATES_DATA',
  monthlyUpdates,
});

export const addCoInvestorsData = coInvestors => ({
  type: 'ADD_CO_INVESTORS_DATA',
  coInvestors,
});

export const updateChart = (chartData, dashboardType, options) => ({
  type: 'UPDATE_CHART_FUND_DASHBOARD',
  chartData,
  dashboardType,
  ...options,
});

export const updateKpiTableAndChart = (chartData, dashboardType, options) => ({
  type: 'UPDATE_KPI_TABLE_AND_CHART_FUND_DASHBOARD',
  chartData,
  dashboardType,
  ...options,
});

export const updateCompanyFilters = selection => ({
  type: 'CHANGE_FILTERS',
  selection,
});

export const updateWidgetChart = (widgetPosition, filter, filterType) => ({
  type: 'UPDATE_WIDGET_CHART',
  widgetPosition,
  filter,
  filterType,
});

export const updatePortfolioReportFileData = (fileData) => ({
  type: 'UPDATE_REPORT_FILE_DATA',
  fileData,
});
