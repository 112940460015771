import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import Textarea from '../Textarea';


class ReUploadModal extends Component {
  static defaultProps = {
    signer: {},
    document: {},
  }

  static propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    requestReupload: PropTypes.func.isRequired,
    request: PropTypes.object.isRequired,
    signer: PropTypes.object,
    document: PropTypes.object,
  }

  state = {
    text: 'Please re-upload this document with your signature again!',
  }

  handleTextChange = e => this.setState({ text: e.target.value })

  submit = () => {
    const { request, signer, document, requestReupload, closeModal } = this.props;
    const { text } = this.state;

    requestReupload(request.company.id, request.id, signer.id, document.id, text).then(
      () => {
        closeModal();
        App.State.setFlash({ name: 'notice', msg: 'Signer has been notified!' });
      },
      err => console.log(err),
    );
  }

  render() {
    const { show, closeModal } = this.props;
    const { text } = this.state;

    return (
      <Modal
        className="flex flex-column react_modal bg-white p3 border-top border-blue rounded bw-3"
        show={show}
        onClickOutside={closeModal}
      >
        <div className="flex items-center mb2 text-gray">
          <div className="flex-auto semi-bold h2">Request to re-upload this document</div>
          <div
            className="cursor-pointer"
            onClick={closeModal}
            role="link"
            tabIndex={0}
          >
            <i className="fa fa-close text-gray" />
          </div>
        </div>
        <div className="flex fw400 mb2 sm-block sm-center" style={{ width: '700px' }}>
          Asking for a re-upload will delete the uploaded document and notifying the signer by email to upload a new version of the document with his signature.
        </div>
        <div className="flex flex-column mb2">
          <span className="fw400">Send a message:</span>
          <Textarea
            className="border border-lighter-gray hover hover-border-blue mt1 rounded"
            rows={3}
            value={text}
            onChange={e => this.handleTextChange(e)}
          />
        </div>
        <div className="flex flex-justify-end">
          <button
            type="button"
            className="border border-green bg-green text-white h5 px2 py1 mx2 rounded cursor-pointer"
            onClick={() => this.submit()}
          >
            Send
          </button>
          <button
            type="button"
            className="border border-light-gray h5 px2 py1 rounded cursor-pointer"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    );
  }
}

export default ReUploadModal;
