import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UpgradeAccountModal from '../modals/UpgradeAccountModal';


class InvestmentDataRoom extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  state = {
    showPaywall: false,
  }

  renderInfo = () => {
    const { data } = this.props;

    return (
      <>
        <span
          className="flex flex-shrink-none items-center center block col-3 bg-center bg-cover bg-no-repeat circle header__avatar--user mr1"
          style={{ backgroundImage: `url( ${data.avatar} )` }}
        />
        {data.real_company &&
          <div className="relative">
            <span className="real_company__notification" style={{ top: '-12px' }} data-behavior="hide-bubble">
              <i className="fa fa-check-circle text-green" />
            </span>
          </div>
        }
        <span className="flex items-center truncate col-10">{data.name}</span>
      </>
    );
  }

  render() {
    const { data } = this.props;
    const { showPaywall } = this.state;

    return (
      <div className={`col-12 flex items-center px2 h5 sm-px0 bg-white ${data.blurred ? 'hide-data' : ''}`}>
        <div className="col-6 flex items-center border-alto py2 pl2 sm-border-right-none sm-col-6 sm-pr2">
          {data.show_paywall ?
            <div
              className="flex cursor-pointer text-blue"
              onClick={() => this.setState({ showPaywall: true })}
            >
              {this.renderInfo()}
            </div>
            :
            <a
              className="flex"
              href={data.data_room_url}
            >
              {this.renderInfo()}
            </a>
          }
        </div>
        <div className="col-2 h5 truncate pr1">{`${data.files_cnt} (${data.total_size})`}</div>
        <div className="col-2 h5 truncate pr1">{data.managed_by}</div>
        <div className="col-2 h5 truncate px1">{data.last_upload}</div>

        <UpgradeAccountModal
          show={showPaywall}
          closeModal={() => this.setState({ showPaywall: false })}
        />
      </div>
    );
  }
}

export default InvestmentDataRoom;
