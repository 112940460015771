import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../Modal';
import SelectableField from '../../modules/SelectableField';


class FundingModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    fundingFields: PropTypes.array.isRequired,
    hasPermission: PropTypes.bool.isRequired,
    saveFundingFields: PropTypes.func.isRequired,
    removeFundingFields: PropTypes.func.isRequired,
  };

  state = {
    fields: {},
  }

  static getDerivedStateFromProps(props, state) {
    const fields = props.fundingFields.reduce((objects, f) => ({
      ...objects,
      [f.id]: {
        type: typeof f.id === 'string' ? 'company' : 'custom',
        name: f.name,
        value: f.value,
        overwrite: f.overwrite,
      },
    }), {});

    if (props.fundingFields !== state.prevProps) {
      return {
        fields,
        prevProps: props.fundingFields,
      };
    }
    return null;
  }

  handleChange = (id, e) => {
    const { value, type } = e.target;
    const { fields } = this.state;
    const f = { ...fields[id], type };

    if (type === 'custom') f.value = value;

    this.setState({
      fields: {
        ...fields,
        [id]: f,
      },
    });
  }

  createFields = async (values) => {
    if (!values.length) return;

    const { saveFundingFields } = this.props;
    const createParams = {
      funding_field: {
        values,
      },
    };
    await saveFundingFields('', createParams);
  }

  destroyFields = async (ids) => {
    if (!ids.length) return;

    const { removeFundingFields } = this.props;
    const destroyParams = {
      funding_field: {
        ids,
      },
    };
    await removeFundingFields(destroyParams);
  }

  updateFields = async (fields) => {
    const ids = Object.keys(fields),
      values = Object.values(fields);

    if (!ids.length) return;

    const { saveFundingFields } = this.props;
    const updateParams = {
      funding_field: {
        ids,
        values,
      },
    };
    await saveFundingFields(null, updateParams);
  }

  submit = async () => {
    const { closeModal } = this.props;
    const { fields } = this.state;
    const fieldsToBeUpdated = {},
      fieldsToBeCreated = [],
      fieldsToBeRemoved = [];

    Object.entries(fields).forEach(([id, f]) => {
      const { type, ...params } = f;
      if (parseInt(id, 10) && type === 'company') {
        fieldsToBeRemoved.push(id);
      } else if (parseInt(id, 10) && type === 'custom') {
        fieldsToBeUpdated[id] = params;
      } else if (type === 'custom') {
        fieldsToBeCreated.push(params);
      }
    });

    try {
      await Promise.all([
        this.createFields(fieldsToBeCreated),
        this.destroyFields(fieldsToBeRemoved),
        this.updateFields(fieldsToBeUpdated),
      ]);
      closeModal();
    } catch (err) {
      console.log(err.response.data.errors);
    }
  }

  render() {
    const { show, closeModal, fundingFields, hasPermission } = this.props;
    const { fields } = this.state;

    return (
      <Modal
        show={show}
        onClickOutside={closeModal}
      >
        <div className="flex flex-justify-between items-center bold p2 border-bottom border-lighter-gray">
          <div className="h3">Funding</div>
          <i className="fa fa-close h5 cursor-pointer" onClick={closeModal} />
        </div>

        <div className="fw400 px3 pb3 overflow-y-auto" style={{ maxHeight: '65vh' }}>
          {fundingFields.map(f => (
            <Fragment key={`field_${f.id}`}>
              <SelectableField
                label={f.name}
                name={f.name}
                defaultValue={fields[f.id].value}
                original={f.original}
                isCustom={!hasPermission || typeof f.id === 'number'}
                hideCompanyTab={!hasPermission}
                component="CleaveNumberFormatting"
                onChange={e => this.handleChange(f.id, e)}
              />
            </Fragment>
          ))}
        </div>

        <div className="flex flex-justify-end items-center p2 border-top border-lighter-gray">
          <div
            className="h5 text-blue mr2 cursor-pointer"
            onClick={closeModal}
          >
            Cancel
          </div>
          <div
            className="h5 p1 px2 cursor-pointer bg-blue text-white rounded"
            onClick={this.submit}
          >
            Save
          </div>
        </div>
      </Modal>
    );
  }
}

export default FundingModal;
