import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import axios from 'axios';

import TextInput from '../../TextInput';
import TextInputProps from '../../TextInputProps';
import CopyToClipboardIcon from '../../CopyToClipboardIcon';

import { defaultSelectStyles, defaultSelectTheme } from '../../selects/SelectStyles';
import DataRoomManagerSharedAccess from './DataRoomManageSharedAccess';

class DataRoomEditForm extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    dataRoom: PropTypes.object.isRequired,
    dataRoomers: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props);

    const { dataRoom } = props;

    this.state = {
      name: dataRoom.name,
      password: '',
      fakePassword: dataRoom.protected ? '12345678' : '',
      hasPassword: dataRoom.protected,
      newAccessNotification: dataRoom.new_access_notification,
      alowComments: dataRoom.allow_comments,
      saving: false,
    };
  }

  conditionToUpdate = () => {
    const { name, password, fakePassword, hasPassword } = this.state;

    if (!name.trim()) {
      return false;
    }

    if (hasPassword && !password && !fakePassword) {
      return false;
    }

    return true;
  }

  handlePassword = (value) => {
    const { fakePassword } = this.state;

    if (fakePassword) {
      this.setState({ fakePassword: '' });
    } else {
      this.setState({ password: value });
    }
  }

  handleAccessTypeSelect = (selectedOption) => {
    const hasPassword = !!selectedOption.value;

    let state = { hasPassword };

    if (!hasPassword) {
      state = { ...state, password: '', fakePassword: '' };
    }

    this.setState(state);
  }

  handleUpdate = async () => {
    const { company, dataRoom } = this.props;
    const {
      name,
      password,
      hasPassword,
      newAccessNotification: new_access_notification,
      alowComments: allow_comments,
      saving,
    } = this.state;

    if (saving) return;
    if (!this.conditionToUpdate()) return;

    let params = { data_room: { name, new_access_notification, allow_comments, password: null } };

    if (hasPassword) {
      params = { data_room: { ...params.data_room, password } };
    }

    this.setState({ saving: true });

    try {
      const res = await axios.patch(`/company/${company.id}/data_rooms/${dataRoom.id}`, params);
      window.location = res.data.location;
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
      this.setState({ saving: false });
    }
  }

  render() {
    const { dataRoom, dataRoomers, company } = this.props;

    const { hasPassword, newAccessNotification, alowComments, saving, password, fakePassword } = this.state;

    const acessTypeOptions = [
      { value: 0, label: 'Everyone with link can access' },
      { value: 1, label: 'Everyone with link and password can access' },
    ];
    const newAccessNotificationsOptions = [
      { value: 0, label: 'Disabled' },
      { value: 1, label: 'Enabled' },
    ];
    const commentingOptions = [
      { value: 0, label: 'Disabled' },
      { value: 1, label: 'Enabled' },
    ];

    return (
      <React.Fragment>
        <div className="container container-report lg-px2 bg-white mt3 py3 my2 px3 bs-around relative sm-h-auto sm-mh-auto mb3">
          <div className="flex flex-column flex-justify-center px2 ">
            <div className="pb2">
              <span className="fw400 h2">Data Room Setup</span>
            </div>
            {/* render Name section */}
            <div className="col-12 py2">
              <div className="border border-lighter-gray">
                <div className="flex flex-column">
                  <div className="flex flex-justify-between p1 pl2 bg-wild-sand text-gray">
                    <div className="flex items-center">
                      <i className="fa fa-pencil mr1" />
                      <span className="fw400 mr1">
                        Name
                      </span>
                    </div>
                    <TextInput
                      sizeClass="col-7"
                      value={dataRoom.name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                      className="border border-alto lh-15 col-12"
                      style={{ height: '40px' }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* render Access section */}
            <div className="col-12 py2">
              <div className="border border-lighter-gray">
                <div className="flex flex-column">
                  <div className="flex items-center flex-justify-between p1 pl2 bg-wild-sand text-gray">
                    <div>
                      <i className="fa fa-lock mr1" />
                      <span className="fw400">
                        Access
                      </span>
                    </div>
                    <div className="col-7">
                      <ReactSelect
                        defaultValue={acessTypeOptions.filter(option => !!option.value === hasPassword)}
                        options={acessTypeOptions}
                        onChange={(selectedOption => this.handleAccessTypeSelect(selectedOption))}
                        isClearable={false}
                        styles={defaultSelectStyles}
                        theme={defaultSelectTheme}
                      />
                    </div>
                  </div>
                  <div className="p2">
                    <div className="flex flex-column">
                      <div className="flex items-center">
                        <span>Shareable Link</span>
                      </div>
                      <div className="flex items-center mt1">
                        <TextInput
                          value={dataRoom.shareable_link}
                          className="border border-alto lh-15 col-10 mr1"
                          style={{ height: '40px' }}
                          placeholder="The link will appear here after creation"
                          readOnly
                        />
                        {dataRoom.link_token &&
                          <CopyToClipboardIcon text={dataRoom.shareable_link} />
                        }
                      </div>
                    </div>
                    {hasPassword &&
                      <div className="flex flex-column pt2">
                        <span>Password</span>
                        <TextInputProps
                          value={password || fakePassword}
                          onChange={(e) => this.handlePassword(e.target.value)}
                          className="border border-alto rounded lh-15 col-8 mt1"
                          style={{ height: '40px' }}
                          type="password"
                        />
                      </div>
                    }
                    {company.subscribed &&
                      <DataRoomManagerSharedAccess
                        dataRoomers={dataRoomers}
                        restrictAccess={dataRoom.restrict_access}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>

            {/* render Comments section */}
            <div className="col-12 py2">
              <div className="border border-lighter-gray">
                <div className="flex flex-column">
                  <div className="flex items-center p1 pl2 bg-wild-sand text-gray">
                    <i className="fa fa-comments-o mr1" />
                    <span className="fw400">
                      Comments
                    </span>
                  </div>
                  <div className="p2">
                    <div className="flex flex-column">
                      <div className="flex">
                        <span>Data Room Comments</span>
                        <span
                          className="text-medium-gray"
                          data-balloon="If data room comments are enabled, everyone that has access to the data room will be able to see and post comments on this data room."
                          data-balloon-pos="down"
                          data-balloon-length="large"
                        >
                          <i className="fa fa-info-circle ml1" />
                        </span>
                      </div>
                      <div className="mt1 col-6">
                        <ReactSelect
                          defaultValue={commentingOptions.filter(option => !!option.value === alowComments)}
                          options={commentingOptions}
                          onChange={(selectedOption => this.setState({ alowComments: !!selectedOption.value }))}
                          isClearable={false}
                          styles={defaultSelectStyles}
                          theme={defaultSelectTheme}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* render Notifications section */}
            <div className="col-12 py2">
              <div className="border border-lighter-gray">
                <div className="flex flex-column">
                  <div className="flex items-center p1 pl2 bg-wild-sand text-gray">
                    <i className="fa fa-envelope-o mr1" />
                    <span className="fw400">
                      Notifications
                    </span>
                  </div>
                  <div className="p2">
                    <div className="flex flex-column">
                      <div className="flex">
                        <span>Data Room Access Notifications</span>
                        <span
                          className="text-medium-gray"
                          data-balloon="If data room access notifications are enabled, everyone in the team will receive a notification, when someone accessed the data room."
                          data-balloon-pos="down"
                          data-balloon-length="large"
                        >
                          <i className="fa fa-info-circle ml1" />
                        </span>
                      </div>
                      <div className="mt1 col-6">
                        <ReactSelect
                          defaultValue={newAccessNotificationsOptions.filter(option => !!option.value === newAccessNotification)}
                          options={newAccessNotificationsOptions}
                          onChange={(selectedOption => this.setState({ newAccessNotification: !!selectedOption.value }))}
                          isClearable={false}
                          styles={defaultSelectStyles}
                          theme={defaultSelectTheme}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-justify-end px2">
            <div
              className={`px2 py1 ${this.conditionToUpdate() ? `bg-blue ${saving ? 'cursor-default' : 'cursor-pointer'}` : 'bg-light-gray'} text-white rounded`}
              onClick={this.conditionToUpdate() ? this.handleUpdate : null}
            >
              <span className="bold">{saving ? 'Saving...' : 'Save Settings'}</span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DataRoomEditForm;
