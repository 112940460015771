import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';


class InvestmentCardAdmin extends Component {
  static propTypes = {
    manageUrl: PropTypes.string.isRequired,
    reinviteUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.any.isRequired,
    investorId: PropTypes.number,
  }

  static defaultProps = {
    investorId: null,
  }

  state = {
    open: false,
    loading: false,
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
    document.addEventListener('touchstart', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
    document.removeEventListener('touchstart', this.handleClick, false);
  }

  handleClick = (e) => {
    if (ReactDOM.findDOMNode(this).contains(e.target)) return;
    this.setState({ open: false });
  }

  deleteButton = () => {
    const { manageUrl } = this.props;

    if (manageUrl.length > 0) {
      return (
        <li>
          <div
            className="bold h5 link-hover--blue block nowrap px1 text-gray cursor-pointer"
            onClick={this.deleteMember}
          >
            <i className="fa fa-fw fa-trash fa--dropdown ml1 right" />
            Remove
          </div>
        </li>
      );
    }
    return null;
  }

  deleteMember = async () => {
    const { manageUrl, name, id } = this.props;
    const confirmed = window.confirm(`Are your sure to remove ${name} from Portfolio?`);

    if (confirmed) {
      this.setState({ loading: true });
      try {
        await axios.delete(manageUrl);

        this.updateFiltersCount();
        this.setState({ loading: false }, () => {
          App.State.setFlash({ name: 'notice', msg: 'Removed!' });
          store.dispatch({ type: 'REMOVE_ITEM', list: 'main', id });
        });
      } catch (err) {
        this.setState({ loading: false }, () => {
          App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
        });
      }
    }
  }

  reinvite = async () => {
    const { reinviteUrl } = this.props;
    this.setState({ loading: true });

    try {
      await axios.patch(reinviteUrl);

      this.setState({ loading: false }, () => {
        App.State.setFlash({ name: 'notice', msg: 'Invite email sent again!' });
      });
    } catch (err) {
      this.setState({ loading: false }, () => {
        App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
      });
    }
  }

  reinviteButton = () => {
    const { reinviteUrl } = this.props;

    if (reinviteUrl.length > 0) {
      return (
        <li>
          <div
            className="bold h5 link-hover--blue mb1 block nowrap px1 text-gray cursor-pointer"
            onClick={this.reinvite}
          >
            <i className="fa fa-fw fa-envelope fa--dropdown ml1 right" />
            Resend Request
          </div>
        </li>
      );
    }
    return null;
  }

  updateFiltersCount = () => {
    const { investorId } = this.props;
    const investmentsUrl = `/investor/${investorId}/investments`;

    App.Api.get(investmentsUrl).then((data) => {
      store.dispatch({ type: 'SET_FILTER_COUNTS', value: data.counts });
    });
  }

  render() {
    const {
      open,
      loading,
    } = this.state;

    return (
      <div className="relative cursor-pointer mx1">
        <div
          className="text-light-gray"
          onClick={() => this.setState({ open: !open })}
        >
          <i className={`fa fa-fw ${loading ? 'fa-refresh fa-spin' : 'fa-cog'}`} />
        </div>
        <div className={`dropdown dropdown--center absolute bg-white border border-alto bs-dropdown left-50 transform-horizontal-center z1 ${open ? '' : 'hidden'} py1`}>
          <ul className="left-align m0 mw150">
            {this.reinviteButton()}
            {this.deleteButton()}
          </ul>
        </div>
      </div>
    );
  }
}

export default InvestmentCardAdmin;
