import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

import Modal from '../Modal';


class CheckoutForm extends Component {
  static propTypes = {
    stripe: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    plan: PropTypes.object,
    coupon: PropTypes.string,
    disableCoupon: PropTypes.bool,
  }

  static defaultProps = {
    plan: null,
    coupon: null,
    disableCoupon: false,
  }

  constructor(props) {
    super();

    const initialCustomer = {
      name: props.company.name,
      email: props.company.email,
      address: '',
      city: '',
      country: props.company.country,
      postal_code: '',
      state: '',
      vat_number: '',
    };

    this.state = {
      error: null,
      saving: false,
      customer: props.company.customer || initialCustomer,
      subscription: {
        coupon: props.coupon,
      },
    };
  }

  handleCustomerChange = e => {
    const { name, value } = e.target;
    this.setState(state => ({
      ...state,
      customer: {
        ...state.customer,
        [name]: value,
      },
    }));
  }

  handleSubscriptionChange = e => {
    const { name, value } = e.target;
    this.setState(state => ({
      ...state,
      subscription: {
        ...state.subscription,
        [name]: value,
      },
    }));
  }

  handleSelect = (value, e) => {
    const { name } = e.target;
    this.setState(state => ({
      ...state,
      customer: {
        ...state.customer,
        [name]: value,
      },
    }));
  }

  submit = async e => {
    e.preventDefault();
    this.setState({ saving: true });
    const { stripe, closeModal, onSubmit } = this.props;
    const { customer, subscription } = this.state;
    localStorage.removeItem('setup_intent_status');
    localStorage.removeItem('payment_intent_status');

    try {
      const res = await stripe.createToken();

      if (res.error) {
        this.setState({ error: res.error.message, saving: false });
        return;
      }

      const params = {
        stripe_customer: {
          ...customer,
          source: res.token.id,
        },
        subscription,
      };

      await onSubmit(params);
      closeModal();
      App.trackCompanyEvent('stripe', 'subscription_successful');
      Intercom('trackEvent', 'start_subscription_successful');
    } catch (err) {
      this.setState({ error: err.response.data.errors, saving: false });
    }
  }

  render() {
    const { closeModal, company, plan, disableCoupon } = this.props;
    const {
      error,
      saving,
      customer: { name, email, address, city, country, postal_code: zip, state, vat_number: vat },
      subscription: { coupon },
    } = this.state;

    return (
      <Modal show widthClass="react_modal modal-sm">
        <div className="flex flex-justify-between items-center semi-bold p2 border-bottom border-lighter-gray">
          <div className="flex flex-column col-12 items-center">
            <div className="h3">{plan ? 'Upgrade to Pro' : 'Update Your Credit Card'}</div>
            <div className="h5 text-medium-gray light">{company.name}</div>
          </div>
          <div className="flex flex-start" style={{ height: '38px' }}>
            <i className="fa fa-close h5 cursor-pointer" onClick={closeModal} />
          </div>
        </div>
        <div className="mx3 mt2">
          <div className="flex flex-justify-center mb2">
            <img
              className="mr2"
              height="18px"
              src={window.images.MasterCard}
              alt="MasterCard"
            />
            <img
              className="mr2"
              height="18px"
              src={window.images.Maestro}
              alt="Maestro"
            />
            <img
              className="mr2"
              height="18px"
              src={window.images.Visa}
              alt="Visa"
            />
            <img
              height="18px"
              src={window.images.AmericanExpress}
              alt="AmericanExpress"
            />
          </div>
        </div>
        <form className="mx3 mt1" onSubmit={this.submit}>
          <span className="light text-medium-gray">Credit Card Number</span>
          <span className="text-red">*</span>
          <CardNumberElement />
          <div className="flex mt1">
            <div className="col-6 mr3">
              <span className="light text-medium-gray">Expiry Date</span>
              <span className="text-red">*</span>
              <CardExpiryElement />
            </div>
            <div className="col-6">
              <span className="light text-medium-gray">Security Code</span>
              <span className="text-red">*</span>
              <CardCVCElement />
            </div>
          </div>
          <div className="mt1">
            <span className="light text-medium-gray">Name</span>
            <span className="text-red">*</span>
            <input
              type="text"
              value={name || ''}
              className="col-12 mt1 border border-gallery rounded focus-bs-light-gray h5 fw400"
              style={{ padding: '10px 14px' }}
              name="name"
              required
              onChange={this.handleCustomerChange}
            />
          </div>
          <div className="flex mt2">
            <div className="col-8 mr2">
              <span className="light text-medium-gray">Billing Email</span>
              <input
                type="email"
                value={email || ''}
                className="col-12 mt1 border border-gallery rounded focus-bs-light-gray h5 fw400"
                style={{ padding: '10px 14px' }}
                name="email"
                onChange={this.handleCustomerChange}
              />
            </div>
            <div className="col-4">
              <span className="light text-medium-gray">ZIP</span>
              <span className="text-red">*</span>
              <input
                type="text"
                placeholder="ZIP"
                value={zip || ''}
                className="col-12 mt1 border border-gallery rounded focus-bs-light-gray h5 fw400"
                style={{ padding: '10px 14px' }}
                name="postal_code"
                required
                onChange={this.handleCustomerChange}
              />
            </div>
          </div>
          <div className="flex mt2">
            <div className="col-8 mr2">
              <span className="light text-medium-gray">Address line</span>
              <span className="text-red">*</span>
              <input
                type="text"
                placeholder="Address"
                value={address || ''}
                className="col-12 mt1 border border-gallery rounded focus-bs-light-gray h5 fw400"
                style={{ padding: '10px 14px' }}
                name="address"
                required
                onChange={this.handleCustomerChange}
              />
            </div>
            <div className="col-4">
              <span className="light text-medium-gray">City</span>
              <span className="text-red">*</span>
              <input
                type="text"
                placeholder="City"
                value={city || ''}
                className="col-12 mt1 border border-gallery rounded focus-bs-light-gray h5 fw400"
                style={{ padding: '10px 14px' }}
                name="city"
                required
                onChange={this.handleCustomerChange}
              />
            </div>
          </div>
          <div className="flex mt2">
            <div className="col-6 mr2">
              <span className="light text-medium-gray">Country</span>
              <span className="text-red">*</span>
              <CountryDropdown
                value={country || ''}
                classes="col-12 mt1 border border-gallery rounded focus-bs-light-gray h5 fw400"
                style={{ padding: '7px 14px' }}
                name="country"
                required
                onChange={this.handleSelect}
              />
            </div>
            <div className="col-6">
              <span className="light text-medium-gray">State</span>
              <RegionDropdown
                country={country}
                value={state || ''}
                classes="col-12 mt1 border border-gallery rounded focus-bs-light-gray h5 fw400"
                style={{ padding: '7px 14px' }}
                name="state"
                onChange={this.handleSelect}
              />
            </div>
          </div>
          <div className="flex mt2">
            <div className="col-6 mr2">
              <span className="light text-medium-gray">Coupon Code</span>
              <input
                type="text"
                value={coupon}
                className={`col-12 mt1 border border-gallery rounded focus-bs-light-gray h5 fw400 ${disableCoupon ? 'bg-gallery' : ''}`}
                style={{ padding: '10px 14px' }}
                name="coupon"
                onChange={this.handleSubscriptionChange}
                disabled={disableCoupon}
              />
            </div>
            <div className="col-6">
              <span className="light text-medium-gray">VAT Number</span>
              <input
                type="text"
                placeholder="ABC12345678"
                value={vat || ''}
                className="col-12 mt1 border border-gallery rounded focus-bs-light-gray h5 fw400"
                style={{ padding: '10px 14px' }}
                name="vat_number"
                onChange={this.handleCustomerChange}
              />
            </div>
          </div>
          {error && <div className="mx3 mt2 text-red h5">{error}</div>}
          <div className="flex flex-justify-center px3 pt2 mt2 mb3 border-top border-lighter-gray">
            {saving ?
              <img
                alt="loading"
                src={window.images.loading}
                width="32"
                height="32"
              />
              :
              <button
                type="submit"
                className="bg-green col-12 text-white bs-around border-none h4 fw400 px2 py1 cursor-pointer rounded"
              >
                <i className="fa fa-lock mr1" />Upgrade Subscription
              </button>
            }
          </div>
        </form>
      </Modal>
    );
  }
}

export default injectStripe(CheckoutForm);
