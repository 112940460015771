import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../Modal';
import HtmlComponent from '../HtmlComponent';
import CheckBox from '../../CheckBox';


class ConfirmationUpdateModal extends Component {
  static defaultProps = {
    className: 'col-2 mx1 py1 flex flex-center flex-justify-center rounded bg-green text-white fw400',
    width: '90px',
    confirmationText: 'Confirm',
    cancelText: 'Cancel',
  }

  static propTypes = {
    link: PropTypes.string,
    message: PropTypes.string,
    html: PropTypes.string,
    confirmationText: PropTypes.string,
    cancelText: PropTypes.string,
    data_method: PropTypes.string,
    onClick: PropTypes.func,
    width: PropTypes.string,
    className: PropTypes.string,
  }

  state = {
    open: false,
    sections: {
      quick_facts: false,
      contact: false,
      fundraising: false,
      funding_history: false,
      kpis: false,
      team: false,
    },
    sections_names: {
      quick_facts: 'Header & Facts',
      contact: 'Contact',
      fundraising: 'Fundraising',
      funding_history: 'Funding History',
      kpis: 'KPIs',
      team: 'Team',
    },
  }

  handleClick = () => {
    const { onClick } = this.props;
    const { sections } = this.state;

    this.setState({ open: false });
    onClick(sections); if (onClick);
  }

  toggleSection = (section) => {
    const { sections } = this.state;
    sections[section] = !sections[section] === false ? null : true;
    return sections;
  }

  renderCancel = () => {
    const { cancelText } = this.props;

    return (
      <div
        className="col-3 center py1 bg-light-gray rounded text-white cursor-pointer"
        onClick={(e) => { e.preventDefault(); this.setState({ open: false }); }}
      >
        {cancelText}
      </div>
    );
  }

  renderLink = () => {
    const { link, data_method, confirmationText } = this.props;

    return (
      <a
        href={link}
        data-method={data_method}
        className="col-3 center py1 bg-green rounded text-white cursor-pointer"
        onClick={() => { this.handleClick(); }}
      >
        {confirmationText}
      </a>
    );
  }

  renderSectionsCheckBoxes = () => {
    const { sections, sections_names } = this.state;
    const sections_names_array = Object.keys(sections_names);
    const renderSections = sections_names_array.map((section_name) => (
      <div className="flex items-center col-4 mt1" key={section_name}>
        <CheckBox
          fixedWidth
          defaultChecked={sections[section_name]}
          onChange={() => this.setState({ sections: this.toggleSection(section_name) })}
        />
        <span className="ml1 h5">{sections_names[section_name]}</span>
      </div>
    ));
    return (
      <div className="flex flex-wrap flex-justify-center col-11 mt2">
        {renderSections}
      </div>
    );
  }

  render() {
    const { className, width, html, message } = this.props;
    const { open } = this.state;

    return (
      <div>
        <div
          href="#"
          className={`${className} cursor-pointer`}
          style={{ width }}
          onClick={(e) => { e.preventDefault(); this.setState({ open: true }); }}
        >
          <HtmlComponent data={{ html }} />
        </div>
        <Modal
          className="react_modal--300"
          show={open}
          onClickOutside={() => { this.setState({ open: false }); }}
        >
          <div className="bg-white p3">
            <div className="flex flex-column flex-center flex-justify-center text-gray">
              <HtmlComponent data={{ html: message }} />

              {this.renderSectionsCheckBoxes()}
            </div>
            <div className="pt2 flex flex-center flex-justify-around">
              {this.renderCancel()}
              {this.renderLink()}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ConfirmationUpdateModal;
