import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Highcharts from 'highcharts';
import HCExporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';

import { chartOptions } from '../../utils/highcharts/genericChartOptions';
import { filterAccumulatorPeriodOptions, accumulatorPeriodOptions } from '../../utils/kpis/accumulatorUtils';

import LoadingSpinner from '../../LoadingSpinner';
import ChartOptionsBar from './generic_kpi_chart_components/ChartOptionsBar';

const periodStepOptions = [{ value: 'days' }, { value: 'weeks' }, { value: 'months' }, { value: 'quarters' }, { value: 'years' }];

class GenericKpiChart extends Component {
  static propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    data: PropTypes.object,
    kpiOptions: PropTypes.array,
    kpis: PropTypes.array,
    timeRangeOptions: PropTypes.array,
    chosenKpi: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.array,
    ]),
    chosenTimeline: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    onKpiChange: PropTypes.func.isRequired,
    onTimeFrameChange: PropTypes.func,
    investmentName: PropTypes.string,
    emptyMessage: PropTypes.string,
    withViewTypeSelect: PropTypes.bool,
    withTimeFrameSelect: PropTypes.bool,
    loading: PropTypes.bool,
    children: PropTypes.node,
    source: PropTypes.bool,
    chartMarginBottom: PropTypes.string,
    kpiSorting: PropTypes.string,
    color: PropTypes.string,
    exportDataURL: PropTypes.string,
    canExport: PropTypes.bool,
    multiKpis: PropTypes.bool,
    maxMultiOptionsLength: PropTypes.number,
    cumulativeKpis: PropTypes.bool,
    blurred: PropTypes.bool,
    currency: PropTypes.string,
  }

  static defaultProps = {
    show: true,
    title: null,
    data: {},
    kpiOptions: [],
    chosenKpi: null,
    kpis: [],
    chosenTimeline: 'current',
    investmentName: '',
    emptyMessage: 'No KPI Data to show yet...',
    timeRangeOptions: [
      { value: 'current', label: 'Current Year' },
      { value: 'previous_year', label: 'Previous Year' },
      { value: 'two_years', label: 'Last 2 Years' },
      { value: 'three_years', label: 'Last 3 Years' },
    ],
    onTimeFrameChange: null,
    children: null,
    withViewTypeSelect: true,
    withTimeFrameSelect: true,
    loading: false,
    source: false,
    chartMarginBottom: '100',
    kpiSorting: 'highest',
    color: '',
    exportDataURL: null,
    canExport: true,
    multiKpis: false,
    maxMultiOptionsLength: 2,
    cumulativeKpis: false,
    blurred: false,
    currency: null,
  }

  constructor(props) {
    super(props);

    HCExporting(Highcharts);

    const { color, multiKpis } = this.props;
    const firstActualColor = color || '#2974b7';
    const firstPlanColor = '#ff9d05';
    let accumulateByOptions = [];
    let accumulateOption = null;

    if (props.cumulativeKpis) {
      let kpi;
      if (props.multiKpis) {
        kpi = props.kpiOptions.find(k => k.value === props.chosenKpi[0]);
      } else {
        kpi = props.kpiOptions.find(k => k.value === props.chosenKpi);
      }
      accumulateByOptions = filterAccumulatorPeriodOptions(accumulatorPeriodOptions(), periodStepOptions, kpi || {}, {});
      accumulateOption = accumulateByOptions[accumulateByOptions.length - 1].value;
    }

    this.initialState = {
      chosenKpi: props.chosenKpi,
      chosenTimeline: props.chosenTimeline,
      chosenChartType: 'column',
      btnColorThemeOpened: false,
      chartValueTypes: [
        { value: 'actual-plan', label: 'Actual & Plan' },
        { value: 'actual', label: 'Actual' },
        { value: 'plan', label: 'Plan' },
      ],
      chosenChartValueType: 'actual-plan',
      showDataLabels: false,
      chartAnimation: true,
      startDate: moment().startOf('year').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      accumulateByOptions,
      operationOptions: [
        { label: 'Sum', value: 'sum' },
        { label: 'Max', value: 'max' },
        { label: 'Min', value: 'min' },
        { label: 'Average', value: 'avg' },
      ],
      accumulateOption,
      operationOption: 'sum',
      // Max 2 multi KPIs for now
      actualColors: multiKpis ? [firstActualColor, '#08477F'] : [firstActualColor],
      planColors: multiKpis ? [firstPlanColor, '#9D5F00'] : [firstPlanColor],
    };
    if (!props.loading) this.initializeChartOptions();
    this.state = this.initialState;
  }

  componentDidUpdate() {
    const { data, loading } = this.props;

    // Chart Options can only be initialized after data was loaded (for lazy loading)
    if (data !== {} && !loading && !this.initialChartOptions) {
      this.initializeChartOptions();
    }
  }

  initializeChartOptions = () => {
    const { data, chartMarginBottom, loading } = this.props;
    const { chosenChartType } = this.initialState;

    if (!loading) {
      this.initialChartOptions = {
        ...chartOptions(data,
          {
            type: chosenChartType,
            width: null,
            height: '300',
            marginBottom: chartMarginBottom,
            ...this.initialState,
          }),
      };
    }
  }

  handleChartOptionUpdate = (name, value) => {
    this.setState({ [name]: value });
  }

  handleChartColorsUpdate = (type, color, index) => {
    const { actualColors, planColors } = this.state;

    if (type === 'actual') {
      const colors = actualColors.map((c, i) => (i === index ? color : c));
      this.setState({ actualColors: colors });
    } else {
      const colors = planColors.map((c, i) => (i === index ? color : c));
      this.setState({ planColors: colors });
    }
  }

  handleCustomDateChange = (customDate, type) => {
    const { onKpiChange } = this.props;
    const { chosenKpi, chosenTimeline, startDate, endDate } = this.state;

    let params = {};

    this.setState({ [type]: customDate }, async () => {
      if (type === 'startDate') {
        params = { startDate: customDate, endDate };
      } else {
        params = { startDate, endDate: customDate };
      }
      await onKpiChange(chosenKpi, chosenTimeline, params);
    });
  }

  handleTimeFrameSelection = (selectedTimeFrame) => {
    const { onKpiChange, onTimeFrameChange } = this.props;
    const { chosenKpi, startDate, endDate, accumulateOption, operationOption } = this.state;

    this.setState({ chosenTimeline: selectedTimeFrame }, async () => {
      const { chosenTimeline } = this.state;

      try {
        if (onTimeFrameChange) {
          await onTimeFrameChange(chosenTimeline, chosenKpi);
        } else if (chosenTimeline === 'custom') {
          await onKpiChange(chosenKpi, chosenTimeline, { startDate, endDate, accumulateOption, operationOption });
        } else {
          await onKpiChange(chosenKpi, chosenTimeline, { accumulateOption, operationOption });
        }
      } catch {
        App.State.setFlash({ name: 'alert', msg: 'Sorry, an error happened while updating the chart.' });
      }
    });
  }

  handleKpiSelection = (selectedKpi) => {
    const { onKpiChange, withTimeFrameSelect, multiKpis, cumulativeKpis, maxMultiOptionsLength } = this.props;

    let newState;

    // When in multi KPIs mode
    if (multiKpis) {
      // If no KPI is selected set to null
      if (selectedKpi.length === 0) {
        newState = { chosenKpi: null };
      } else if (selectedKpi.length <= maxMultiOptionsLength) {
        // If any KPI selected, check if cumulative options is enabled
        if (cumulativeKpis) {
          // This filter works for max 2 KPIs. If more multi KPIs will be added in the future, this filter will need to be enhanced.
          const filteredOptions = filterAccumulatorPeriodOptions(accumulatorPeriodOptions(), periodStepOptions, selectedKpi[0] || {}, selectedKpi[1] || {});

          newState = {
            chosenKpi: selectedKpi.map(selected => selected.value),
            accumulateOption: filteredOptions[filteredOptions.length - 1].value,
            accumulateByOptions: filteredOptions,
          };
        // Normal selection for multi KPIs with no cumulative ooptions
        } else {
          newState = { chosenKpi: selectedKpi.map(selected => selected.value) };
        }
      } else return;
    // When in single KPI mode
    } else {
      newState = { chosenKpi: selectedKpi };
    }

    this.setState(newState, async () => {
      const { chosenKpi, startDate, endDate, chosenTimeline, accumulateOption, operationOption } = this.state;

      try {
        if (chosenKpi) {
          if (withTimeFrameSelect) {
            if (chosenTimeline === 'custom') {
              await onKpiChange(chosenKpi, chosenTimeline, { startDate, endDate, accumulateOption, operationOption });
            } else {
              await onKpiChange(chosenKpi, chosenTimeline, { accumulateOption, operationOption });
            }
          } else {
            await onKpiChange(chosenKpi);
          }
        }
      } catch (err) {
        App.State.setFlash({ name: 'alert', msg: 'Sorry, an error happened while updating the chart.' });
      }
    });
  }

  handleAccumulatorSelection = (value, type) => {
    const { onKpiChange, withTimeFrameSelect } = this.props;

    let newState;
    if (type === 'accumulator') {
      newState = { accumulateOption: value };
    } else {
      newState = { operationOption: value };
    }

    this.setState(newState, async () => {
      const { chosenTimeline, chosenKpi, startDate, endDate, accumulateOption, operationOption } = this.state;

      try {
        if (chosenKpi) {
          if (withTimeFrameSelect) {
            if (chosenTimeline === 'custom') {
              await onKpiChange(chosenKpi, chosenTimeline, { startDate, endDate, accumulateOption, operationOption });
            } else {
              await onKpiChange(chosenKpi, chosenTimeline, { accumulateOption, operationOption });
            }
          }
        }
      } catch (err) {
        App.State.setFlash({ name: 'alert', msg: 'Sorry, an error happened while updating the chart.' });
      }
    });
  }

  sortWithIndices = (toSort) => {
    const sorted = new Array(toSort.length);
    for (let i = 0; i < toSort.length; i += 1) {
      sorted[i] = [toSort[i], i];
    }
    sorted.sort((left, right) => (left[0] > right[0] ? -1 : 1));

    const sortIndices = [];
    for (let j = 0; j < sorted.length; j += 1) {
      sortIndices.push(sorted[j][1]);
      sorted[j] = sorted[j][0];
    }
    return sortIndices;
  }

  handleColorPickerClick = () => {
    this.setState({ btnColorThemeOpened: false });
  }

  render() {
    const {
      show,
      title,
      kpiOptions,
      timeRangeOptions,
      emptyMessage,
      withViewTypeSelect,
      withTimeFrameSelect,
      loading,
      children,
      kpis,
      source,
      kpiSorting,
      multiKpis,
      exportDataURL,
      canExport,
      investmentName,
      maxMultiOptionsLength,
      cumulativeKpis,
      blurred,
      currency,
    } = this.props;

    let { data } = this.props;

    const {
      chosenKpi,
      chosenTimeline,
      chosenChartType,
      btnColorThemeOpened,
      chartValueTypes,
      chosenChartValueType,
      planColors,
      actualColors,
      showDataLabels,
      chartMarginBottom,
      chartAnimation,
      startDate,
      endDate,
      accumulateByOptions,
      operationOptions,
      accumulateOption,
      operationOption,
    } = this.state;

    // Sorting only works for single KPI, not multi
    if (!loading && !multiKpis) {
      const sortedData = {};
      sortedData.actuals = new Array(data.actuals.length);
      sortedData.forecasts = new Array(data.forecasts.length);
      sortedData.kpiUnit = data.kpiUnit;

      if (kpiSorting === 'highest') {
        if (data.categories) {
          sortedData.categories = new Array(data.actuals.length);
          this.sortWithIndices(data.actuals).forEach((sortedIndex, index) => {
            sortedData.actuals[index] = data.actuals[sortedIndex];
            sortedData.forecasts[index] = data.forecasts[sortedIndex];
            sortedData.categories[index] = data.categories[sortedIndex];
          });
          data = { ...sortedData };
        }
      }
    }

    // Check empty data
    let emptyData = false;
    if (!loading) {
      emptyData = multiKpis ? data.values.every(value => value.actuals.every(element => element === null)) : data.actuals.every(element => element === null);
    }

    // Check if custom data for label
    let custom;
    if (kpis.length > 0 && !multiKpis) {
      custom = !kpis.find(kpi => kpi.id === parseInt(chosenKpi, 10)).company_data;
    }

    let exportTitle;
    if (!multiKpis) {
      exportTitle = (kpiOptions.length > 0 && chosenKpi) && `${kpiOptions.find(option => option.value === chosenKpi).label} ${investmentName ? `- ${investmentName}` : ''}`;
    }

    return (
      show ?
        <React.Fragment>
          {title &&
            <div className="mb2 pt2 pl2">
              <span className="text-gray fw400">{title}</span>
            </div>
          }

          {loading ?
            <LoadingSpinner
              show={loading}
              type="fit"
              background="white"
              height="200px"
            />
            :
            //
            // Begin of Chart Options Bar
            //
            <ChartOptionsBar
              withViewTypeSelect={withViewTypeSelect}
              chosenChartType={chosenChartType}
              multiKpis={multiKpis}
              kpiOptions={kpiOptions}
              chosenKpi={chosenKpi}
              chosenTimeline={chosenTimeline}
              maxMultiOptionsLength={maxMultiOptionsLength}
              source={source}
              custom={custom}
              btnColorThemeOpened={btnColorThemeOpened}
              chartValueTypes={chartValueTypes}
              chosenChartValueType={chosenChartValueType}
              showDataLabels={showDataLabels}
              actualColors={actualColors}
              planColors={planColors}
              exportDataURL={exportDataURL}
              canExport={canExport}
              withTimeFrameSelect={withTimeFrameSelect}
              timeRangeOptions={timeRangeOptions}
              startDate={startDate}
              endDate={endDate}
              cumulativeKpis={cumulativeKpis}
              accumulateByOptions={accumulateByOptions}
              operationOptions={operationOptions}
              accumulateOption={accumulateOption}
              operationOption={operationOption}
              handleTimeFrameSelection={this.handleTimeFrameSelection}
              handleCustomDateChange={this.handleCustomDateChange}
              handleColorPickerClick={this.handleColorPickerClick}
              handleChartColorsUpdate={this.handleChartColorsUpdate}
              handleChartOptionUpdate={this.handleChartOptionUpdate}
              handleKpiSelection={this.handleKpiSelection}
              handleAccumulatorSelection={this.handleAccumulatorSelection}
              blurred={blurred}
            >
              {children}
            </ChartOptionsBar>
          }
          {/* End of Chart Options Bar */}

          {loading ?
            null
            :
            (emptyData || !chosenKpi ?
              <div className={`px2 pt2 bg-white center py3 ${blurred ? 'hide-data' : ''}`}>
                <span className="text-medium-gray mx-auto h5">{emptyMessage}</span>
              </div>
              :
              // KPI Chart Show
              <div className={`px2 pt2 bg-white ${blurred ? 'hide-data' : ''}`}>
                {/* Chart only can be shown after options have been initialized */}
                {this.initialChartOptions &&
                  <HighchartsReact
                    highcharts={Highcharts}
                    immutable={multiKpis}
                    options={chartOptions(data,
                      {
                        exportTitle,
                        type: chosenChartType,
                        width: null,
                        height: '300',
                        planColors,
                        actualColors,
                        showDataLabels,
                        valueType: chosenChartValueType,
                        marginBottom: chartMarginBottom,
                        chartAnimation,
                        currency,
                      })}
                  />
                }
                {currency &&
                  <span className="h6 text-light-gray">
                    <i className="fa fa-info-circle mr1" />
                    Some of the companies' KPI data values may not be in {currency}, so {currency} values had to be calculated using the companies' currency at today's spot rate.
                  </span>
                }
              </div>)
          }
        </React.Fragment>
        :
        null
    );
  }
}

export default GenericKpiChart;
