import React, { Component } from 'react';
import PropTypes from 'prop-types';


class InvestmentCard extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      applied: props.record.applied,
      loading: false,
    };
  }

  postRequest = () => {
    const { record } = this.props;
    this.setState({ loading: true });
    App.Api.post(record.apply_url, { company_id: record.id }).then(
      () => {
        this.setState({ applied: true, loading: false });
        App.State.setFlash({ name: 'notice', msg: 'Investment request has been sent!' });
      },
    );
  }

  request = () => {
    const { loading, applied } = this.state;

    if (loading) {
      return (
        <div className="btn bg-white border border-light-green h5 p1 rounded text-light-green cursor-pointer">
          <i className="fa fa-refresh fa-spin fa-fw" />
          Loading..
        </div>
      );
    }
    if (applied) {
      return (
        <div className="btn bg-white border border-light-green h5 p1 rounded text-light-green cursor-pointer">
          Request sent
        </div>
      );
    }
    return (
      <div className="btn bg-blue border border-transparent cursor-pointer inline-block h5 p1 rounded text-white" onClick={this.postRequest}>
        Add as investment
      </div>
    );
  }

  render() {
    const { record } = this.props;

    return (
      <div className="col-4 lg-col-6 sm-col-12 flex px2 mb3">
        <div className="card--has-hover bg-white bs-around-small center flex flex-auto flex-center flex-column p3 relative rounded">
          <div className="flex-auto">
            <i className="fa fa-briefcase fa--search-card absolute position-right-top text-extra-light-gray" />
            <div
              className="search-card__img bg-center bg-cover bg-no-repeat border border-light-gray circle mb1 mx-auto"
              style={{ backgroundImage: `url(${record.avatar})` }}
            />
            <p className="bold h3 m0 mb1 text-outer-space">{record.name}</p>
            <p className="h5 m0 mb3 text-light-gray">{record.industry}</p>
          </div>
          {this.request()}
        </div>
      </div>
    );
  }
}

export default InvestmentCard;
