import {
  updateCompanyReport,
  updateReportColumn,
  removeReportColumn,
} from '../../actions/investor/companyReportActions';


export const saveCompanyReport = (investorId, id, params) =>
  dispatch =>
    App.Api.submitPatch(`/investor/${investorId}/reports/company_reports/${id}`, { company_report: params }).then(
      () => dispatch(updateCompanyReport(id, params)),
      err => console.log(err),
    );

export const saveReportColumn = (investorId, id, params) =>
  dispatch =>
    App.Api.submitPatch(`/investor/${investorId}/reports/report_columns/${id}`, { report_column: params }).then(
      res => dispatch(updateReportColumn(id, res)),
      err => console.log(err),
    );

export const destroyReportColumn = (investorId, id) =>
  dispatch =>
    App.Api.delete(`/investor/${investorId}/reports/report_columns/${id}`).then(
      () => dispatch(removeReportColumn(id)),
      err => console.log(err),
    );
