import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SorterHeader from '../SorterHeader';

class InvestorFundHeader extends Component {
  static propTypes = {
    item_count: PropTypes.number.isRequired,
  }

  displayHeader = () => {
    const { item_count } = this.props;
    if (item_count > 1) {
      return (
        <div className="col-12 text-gray h5 flex px2 bg-extra-light-gray">
          <div className="col-1 py1 fw400 pl2 sm-pl0">
            Logo
          </div>
          <div className="col-2 py1 bold pl2 sm-pl0">
            <SorterHeader text="Name" field="name" />
          </div>
          <div className="col-9 py1 bold pl2 sm-pl0 fw400 sm-hide">
            Assigned companies
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div>
        {this.displayHeader()}
      </div>
    );
  }
}

// export default InvestorFundHeader;

const mapStateToProps = (store) => (
  {
    item_count: store.get('items').toJS().main.length,
  }
);

export default connect(mapStateToProps)(InvestorFundHeader);
