/* eslint-disable import/first */
/* eslint-disable import/newline-after-import */

// Bugsnag
import Bugsnag from 'bugsnag-js';
window.Bugsnag = Bugsnag;
// import bugsnagConfig from './bugsnag_config.js.erb';
// window.setBugsnagConfig = bugsnagConfig;

// Promise polyfill
import Promise from 'promise-polyfill';
if (!window.Promise) window.Promise = Promise;

import ReactUJS from 'react_ujs';

// jQuery
import jQuery from 'jquery';
window.jQuery = jQuery;
window.$ = jQuery;

import React from 'react';
window.React = React;

import ReactDOM from 'react-dom';
window.ReactDOM = ReactDOM;

import WebpackerReact from 'webpacker-react';
window.WebpackerReact = WebpackerReact;

import Immutable from 'immutable';
window.Immutable = Immutable;

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
window.ReactCSSTransitionGroup = ReactCSSTransitionGroup;

import ReactSelect from 'react-select';
window.ReactSelect = ReactSelect;

import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';
import cloneDeep from 'lodash.clonedeep';
import maxBy from 'lodash.maxby';
window._ = { throttle, debounce, cloneDeep, maxBy };

import Screenfull from 'screenfull';
window.Screenfull = Screenfull;

import Color from 'color';
window.Color = Color;

import ColorVariables from '../../assets/stylesheets/utils/variables.scss';
window.ColorVariables = ColorVariables;

import App from './app_init.js.es6';
window.App = App;

import Api from './api/api.js.es6';
window.App.Api = Api;

import State from './api/state.js.es6';
window.App.State = State;

// Branding

import applyCompanyBrandingColorTheme from './branding/companyColorTheme';
window.applyCompanyBrandingColorTheme = applyCompanyBrandingColorTheme;

// Charts
import chart from './charts/chart';
window.createChart = chart;

import funnelChart from './charts/funnel_chart';
window.createFunnelChart = funnelChart;

import pieChart from './charts/pie_chart';
window.createPie = pieChart;

import runwayChart from './charts/runway_chart';
window.createRunwayChart = runwayChart;

import boxplotChart from './charts/boxplot_chart';
window.boxplotChart = boxplotChart;

// Reducers
import DashboardReducer from './reducers/dashboard.js.es6';
window.dashboardReducer = DashboardReducer;

import kpiStepReducer from './reducers/kpi_step.js.es6';
window.kpiStepReducer = kpiStepReducer;

import TabViewReducer from './reducers/tabView.js.es6';
window.tabViewReducer = TabViewReducer;

// mixins
import SetIntervalMixin from './components/utils/SetIntervalMixin';
window.SetIntervalMixin = SetIntervalMixin;

// components
import AddAsInvestment from './components/AddAsInvestment';
window.AddAsInvestment = AddAsInvestment;

import Avatars from './components/list/Avatars';
window.Avatars = Avatars;

import ChatDesktop from './components/chat/ChatDesktop';
window.ChatDesktop = ChatDesktop;

import ChartSelect from './components/kpis/ChartSelect';
window.ChartSelect = ChartSelect;

import Comment from './components/shareholder_update/CommentSh';
window.Comment = Comment;

import CommentBox from './components/shareholder_update/CommentBoxSh';
window.CommentBox = CommentBox;

import CommentForm from './components/shareholder_update/CommentFormSh';
window.CommentForm = CommentForm;

import ConversationAttachmentBox from './components/ConversationAttachmentBox';
window.ConversationAttachmentBox = ConversationAttachmentBox;

import ConversationLinkIndex from './components/ConversationLinkIndex';
window.ConversationLinkIndex = ConversationLinkIndex;

import CopyToClipboardIcon from './components/CopyToClipboardIcon';
window.CopyToClipboardIcon = CopyToClipboardIcon;

import CustomDropzone from './components/CustomDropzone';
window.CustomDropzone = CustomDropzone;

import FileUpload from './components/file_uploads/FileUpload';
window.FileUpload = FileUpload;

import ExportSettings from './components/ExportSettings';
window.ExportSettings = ExportSettings;

import CrunchSearch from './components/crunch_search/CrunchSearch';
window.CrunchSearch = CrunchSearch;

import CountrySearch from './components/company/country_search/CountrySearch';
window.CountrySearch = CountrySearch;

import CountryValidator from './components/company/country_search/CountryValidator';
window.CountryValidator = CountryValidator;

import LinkTo from './components/LinkTo';
window.LinkTo = LinkTo;

import EditStatusOptions from './components/investor/info/EditStatusOptions';
window.EditStatusOptions = EditStatusOptions;

import FileField from './components/FileField';
window.FileField = FileField;

import ImageEditor from './components/ImageEditor';
window.ImageEditor = ImageEditor;

import Switch from './components/Switch';
window.Switch = Switch;

import Flash from './components/Flash';
window.Flash = Flash;

import InvestorInviter from './components/onboarding/InvestorInviter';
window.InvestorInviter = InvestorInviter;

import Invite from './components/invites/Invite';
window.Invite = Invite;

import Kpi from './components/Kpi';
window.Kpi = Kpi;

import AddOrImportKpis from './components/kpis/AddOrImportKpis';
window.AddOrImportKpis = AddOrImportKpis;

import CompanyKpiList from './startup/CompanyKpiList';
window.CompanyKpiList = CompanyKpiList;

import Link from './components/Link';
window.Link = Link;

import Liker from './components/Liker';
window.Liker = Liker;

import List from './components/list/List';
window.List = List;

import ListMenu from './components/ListMenu';
window.ListMenu = ListMenu;

import LinkShareButton from './components/shareholder_update/LinkShareButton';
window.LinkShareButton = LinkShareButton;

import ShareholderUpdate from './components/shareholder_update/ShareholderUpdate';
window.ShareholderUpdate = ShareholderUpdate;

import MenuButton from './components/MenuButton';
window.MenuButton = MenuButton;

import PostList from './components/PostList';
window.PostList = PostList;

import PostWithComments from './components/PostWithComments';
window.PostWithComments = PostWithComments;

import SheetsSource from './components/kpis/SheetsSource';
window.SheetsSource = SheetsSource;

import OnboardSearchList from './components/list/OnboardSearchList';
window.OnboardSearchList = OnboardSearchList;

import Select from './components/Select';
window.Select = Select;

import RoleSelect from './components/onboarding/RoleSelect';
window.RoleSelect = RoleSelect;

import RowManager from './components/kpis/RowManager';
window.RowManager = RowManager;

import InvestorRoleSelect from './components/onboarding/InvestorRoleSelect';
window.InvestorRoleSelect = InvestorRoleSelect;

import MultiSelect from './components/selects/MultiSelect';
window.MultiSelect = MultiSelect;

import TabWithMenu from './components/TabWithMenu';
window.TabWithMenu = TabWithMenu;

import Circle from './components/Circle';
window.Circle = Circle;

import TabView from './components/tab_view/TabView';
window.TabView = TabView;

import FundAddButton from './components/tab_view/fund/FundAddButton';
window.FundAddButton = FundAddButton;

import Textarea from './components/Textarea';
window.Textarea = Textarea;

import ConfirmationModal from './components/ConfirmationModal';
window.ConfirmationModal = ConfirmationModal;

import ColorPicker from './components/selects/ColorPicker';
window.ColorPicker = ColorPicker;

import CheckBox from './components/CheckBox';
window.CheckBox = CheckBox;

// Onboarding

import AboutYourselfInvestor from './components/onboarding/Investors/AboutYourselfInvestor';
window.AboutYourselfInvestor = AboutYourselfInvestor;

import CompanyConnectWithOthers from './components/onboarding/Companies/CompanyConnectWithOthers';
window.CompanyConnectWithOthers = CompanyConnectWithOthers;

import InvestorConnectWithOthers from './components/onboarding/Investors/InvestorConnectWithOthers';
window.InvestorConnectWithOthers = InvestorConnectWithOthers;

// Cap table
import CapTable from './components/cap_tables/CapTable';
window.CapTable = CapTable;
import ManualCapTable from './components/cap_tables/ManualCapTable';
window.ManualCapTable = ManualCapTable;

import CapTableLater from './components/cap_tables/CapTableLater';
window.CapTableLater = CapTableLater;

// Dashboard
import Dashboard from './components/dashboard/Dashboard';
window.Dashboard = Dashboard;

import CompanyReport from './startup/CompanyReport';
window.CompanyReport = CompanyReport;

import SigningRoomRequest from './startup/SigningRoomRequest';
window.SigningRoomRequest = SigningRoomRequest;

import SigningRoomSetupRequest from './startup/SigningRoomSetupRequest';
window.SigningRoomSetupRequest = SigningRoomSetupRequest;

import SigningRoomRequestSummary from './components/signing_room/SigningRoomRequestSummary';
window.SigningRoomRequestSummary = SigningRoomRequestSummary;

import FactSheetSettings from './components/company_report/FactSheetSettings';
window.FactSheetSettings = FactSheetSettings;

import AddInvestment from './components/investor/investments/AddInvestment';
window.AddInvestment = AddInvestment;

import InvestoryLogoSvg from './components/InvestoryLogoSvg';
window.InvestoryLogoSvg = InvestoryLogoSvg;

// Common
import KpisLibrary from './startup/KpisLibrary';
window.KpisLibrary = KpisLibrary;

import DataRoom from './startup/DataRoom';
window.DataRoom = DataRoom;

import ReadOnlyCaptable from './startup/ReadOnlyCaptable';
window.ReadOnlyCaptable = ReadOnlyCaptable;

import MultipleSelects from './components/selects/MultipleSelects';
window.MultipleSelects = MultipleSelects;

// Company
import CompanyBilling from './startup/company/Billing';
window.CompanyBilling = CompanyBilling;

import InvestorBilling from './startup/investor/Billing';
window.InvestorBilling = InvestorBilling;

import CompanyBrandingSettings from './components/company/branding/CompanyBrandingSettings';
window.CompanyBrandingSettings = CompanyBrandingSettings;

import RecipientStep from './startup/company/shareholder_update/RecipientStep';
window.RecipientStep = RecipientStep;

import CompanyDataRooms from './startup/company/data_rooms/CompanyDataRooms';
window.CompanyDataRooms = CompanyDataRooms;

import CompanyDataRoom from './startup/company/data_rooms/CompanyDataRoom';
window.CompanyDataRoom = CompanyDataRoom;

import CompanyDealRoom from './startup/company/deal_room/CompanyDealRoom';
window.CompanyDealRoom = CompanyDealRoom;

import DataRoomEditForm from './startup/company/data_rooms/DataRoomEditForm';
window.DataRoomEditForm = DataRoomEditForm;

import SetupUpdateSchedule from './components/company/pro_features/SetupUpdateSchedule';
window.SetupUpdateSchedule = SetupUpdateSchedule;

import ScheduleUpdateReminder from './components/company/scheduled_updates/ScheduleUpdateReminder';
window.ScheduleUpdateReminder = ScheduleUpdateReminder;

import CompanyCaptable from './startup/company/cap_tables/CompanyCapTable';
window.CompanyCaptable = CompanyCaptable;

import DataRoomManagerSharedAccess from './components/company/data_rooms/DataRoomManageSharedAccess';
window.DataRoomManagerSharedAccess = DataRoomManagerSharedAccess;

import CreateNewKPi from './components/kpis/CreateNewKpi';
window.CreateNewKPi = CreateNewKPi;

// Investor
import InvestorHome from './startup/investor/InvestorHome';
window.InvestorHome = InvestorHome;

import FundDashboard from './startup/investor/FundDashboard';
window.FundDashboard = FundDashboard;

import InvestorPortfolio from './startup/investor/InvestorPortfolio';
window.InvestorPortfolio = InvestorPortfolio;

import InvestorFunding from './startup/investor/InvestorFunding';
window.InvestorFunding = InvestorFunding;

import InvestorKpis from './startup/investor/InvestorKpis';
window.InvestorKpis = InvestorKpis;

import InvestmentDataRooms from './startup/investor/InvestmentDataRooms';
window.InvestmentDataRooms = InvestmentDataRooms;

import InvestmentDataRoom from './startup/investor/InvestmentDataRoom';
window.InvestmentDataRoom = InvestmentDataRoom;

import FundDataRoom from './startup/investor/FundDataRoom';
window.FundDataRoom = FundDataRoom;

import InvestorUpdates from './startup/investor/InvestorUpdates';
window.InvestorUpdates = InvestorUpdates;

import ElasticSearchResults from './startup/investor/ElasticSearchResults';
window.ElasticSearchResults = ElasticSearchResults;

// Modals
import CustomInvestmentGuidenceModal from './components/investor/investments/modals/CustomInvestmentGuidenceModal';
window.CustomInvestmentGuidenceModal = CustomInvestmentGuidenceModal;

import ModalWithButton from './components/modals/ModalWithButton';
window.ModalWithButton = ModalWithButton;

import HandleMembersInvite from './components/investor/pricing/HandleMembersInvite';
window.HandleMembersInvite = HandleMembersInvite;

import InvestorInvite from './components/invites/InvestorInvite';
window.InvestorInvite = InvestorInvite;

import CompanyMainObjectives from './components/company/CompanyMainObjectives';
window.CompanyMainObjectives = CompanyMainObjectives;

import Benchmarking from './components/company/benchmarking/Benchmarking';
window.Benchmarking = Benchmarking;
