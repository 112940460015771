import { connect } from 'react-redux';
import Slideshow from '../../components/investor/slideshow/Slideshow';

import { changeCompanyFilters } from '../../actions/investor/slideshowActions';
import { trackEvent } from '../../api/mixpanelApi';

const mapStateToProps = state => ({
  slideshow: state.slideshow,
});

const mapDispatchToProps = {
  changeCompanyFilters,
  trackEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Slideshow);
