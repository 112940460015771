import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import SelectWithImage from '../selects/SelectWithImage';
import { defaultSelectStyles, defaultSelectTheme } from '../selects/SelectStyles';
import ColorPicker from '../selects/ColorPicker';

import BillingModal from '../company/billing/BillingModal';


class KpiChartsList extends Component {
  static propTypes = {
    kpi_charts: PropTypes.array.isRequired,
    chart_types: PropTypes.array.isRequired,
    isNumberKpi: PropTypes.func.isRequired,
    isFunnelKpi: PropTypes.func.isRequired,
    isTableKpi: PropTypes.func.isRequired,
    setChartTypeForKpiChart: PropTypes.func.isRequired,
    setStateInKpiSection: PropTypes.func.isRequired,
    brandingColors: PropTypes.array.isRequired,
    billingUrl: PropTypes.string.isRequired,
    subscribed: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);

    const { chart_types } = this.props;

    this.state = {
      chart_types_for_selection: this.isMultiKpi() ? this.typesForMultiKpi() : chart_types,
      showBillingModal: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { kpi_charts, chart_types, setChartTypeForKpiChart } = this.props;

    // When in multi kpi mode, less chart types are used. These make sure the incorrect ones are not used
    if (kpi_charts.length === 1 && nextProps.kpi_charts.length === 2) {
      const chartId = kpi_charts[0].kpi_id;
      let chartType = 'line';
      if (kpi_charts[0].type === 'funnel') chartType = 'funnel';
      if (kpi_charts[0].type === 'table') chartType = 'table';
      setChartTypeForKpiChart(chartId, chartType);
    }
    const chart_types_for_selection = this.isMultiKpi(nextProps.kpi_charts) ? this.typesForMultiKpi() : chart_types;
    this.setState({ chart_types_for_selection });
  }

  isMultiKpi = (kpiCharts = this.props.kpi_charts) => (kpiCharts.length > 1)

  optionsForActualPlanSelector = () => (
    [{ value: 'actual/plan', label: 'Actual/Plan' }, { value: 'actual', label: 'Actual Only' }, { value: 'plan', label: 'Plan Only' }]
  )

  getActualPlanValue = (actual, plan) => {
    let type;
    if (actual && plan) {
      type = 'actual/plan';
    } else if (actual) {
      type = 'actual';
    } else {
      type = 'plan';
    }
    return this.optionsForActualPlanSelector().filter(option => option.value === type);
  }

  updateKpiChart = (kpiChart, kpiId, value, name) => {
    if (kpiId === kpiChart.kpi_id) {
      return {
        ...kpiChart,
        [name]: value,
      };
    }
    return kpiChart;
  }

  setActualPlanForKpiChart = (kpiId, actualValue, planValue) => {
    const { kpi_charts, setStateInKpiSection } = this.props;

    const clonedKpiCharts = _.cloneDeep(kpi_charts);
    clonedKpiCharts.find(kpiChart => kpiChart.kpi_id === kpiId).show_actual_data = actualValue;
    clonedKpiCharts.find(kpiChart => kpiChart.kpi_id === kpiId).show_plan_data = planValue;
    setStateInKpiSection({ kpi_charts: clonedKpiCharts });

    Intercom('trackEvent', 'changed_chart_color');
    App.trackCompanyEvent('update', 'changed_chart_color');
  }

  setActualPlanColorsForKpiChart = (kpiId, color, name) => {
    const { kpi_charts, setStateInKpiSection } = this.props;

    const updatedKpiCharts = kpi_charts.map(kpiChart => this.updateKpiChart(kpiChart, kpiId, color, name));
    setStateInKpiSection({ kpi_charts: updatedKpiCharts });

    Intercom('trackEvent', 'changed_chart_color');
    App.trackCompanyEvent('update', 'changed_chart_color');
  }

  setActualPlanStates = (kpitId, val) => {
    if (val === 'actual/plan') {
      this.setActualPlanForKpiChart(kpitId, true, true);
    } else if (val === 'actual') {
      this.setActualPlanForKpiChart(kpitId, true, false);
    } else {
      this.setActualPlanForKpiChart(kpitId, false, true);
    }
  }

  typesForMultiKpi = () => {
    const { chart_types, isFunnelKpi, isTableKpi } = this.props;

    const clonedChartTypes = _.cloneDeep(chart_types);
    if (isFunnelKpi()) {
      clonedChartTypes.map((chart_type) => { if (chart_type.value !== 'funnel') chart_type.isDisabled = true; });
    } else if (isTableKpi()) {
      clonedChartTypes.map((chart_type) => { if (chart_type.value !== 'table') chart_type.isDisabled = true; });
    } else {
      clonedChartTypes.map((chart_type) => { if (chart_type.value !== 'line' && chart_type.value !== 'spline' && chart_type.value !== 'column') chart_type.isDisabled = true; });
    }
    return clonedChartTypes;
  }

  render() {
    const {
      kpi_charts,
      setChartTypeForKpiChart,
      isNumberKpi,
      isFunnelKpi,
      isTableKpi,
      brandingColors,
      billingUrl,
      subscribed,
    } = this.props;
    const { chart_types_for_selection, showBillingModal } = this.state;

    const showBrandingColors = !isNumberKpi() && !isFunnelKpi() && !isTableKpi();

    const kpiCharts = kpi_charts.map(kpiChart =>
      (
        <div className="flex flex-center mt2 mb2 sm-block sm-center" key={`kpi_chart_${kpiChart.kpi_id}`}>
          <span className="col-3 pl3 sm-block sm-pb3 sm-col-12 sm-pl0 truncate" style={{ fontWeight: 500 }}> {kpiChart.name} </span>
          <div className="col-9 pr3">
            <div className="flex flex-auto items-center flex-justify-end sm-block sm-pr0">
              <div className="col-4 sm-col-12 sm-block sm-mx-auto">
                <SelectWithImage
                  value={chart_types_for_selection.filter(option => option.value === kpiChart.type)}
                  options={chart_types_for_selection}
                  onChange={selectedOption => setChartTypeForKpiChart(kpiChart.kpi_id, selectedOption.value)}
                  isClearable={false}
                  placeholder="Select a chart type"
                />
              </div>
              <div className="col-4 sm-col-12 center pl1 sm-pl0 sm-block sm-pt2 sm-mx-auto">
                <ReactSelect
                  className={`${isNumberKpi() ? 'Select--disabled' : ''}`}
                  value={this.getActualPlanValue(kpiChart.show_actual_data, kpiChart.show_plan_data)}
                  options={this.optionsForActualPlanSelector()}
                  onChange={(selectedOption) => { this.setActualPlanStates(kpiChart.kpi_id, selectedOption.value); }}
                  isClearable={false}
                  isDisabled={isNumberKpi()}
                  placeholder="Select a Kpi"
                  styles={defaultSelectStyles}
                  theme={defaultSelectTheme}
                />
              </div>
              {
                showBrandingColors &&
                <div className="col-2 pl1 flex flex-justify-end">
                  {kpiChart.show_actual_data &&
                    <div
                      className="mr1"
                      data-balloon="Select the actual kpi charting color (for this kpi/chart only)."
                      data-balloon-pos="up"
                      data-balloon-length="large"
                    >
                      <ColorPicker
                        color={kpiChart.actual_color || brandingColors[1]}
                        onChange={(color) => this.setActualPlanColorsForKpiChart(kpiChart.kpi_id, color.hex, 'actual_color')}
                      />
                    </div>
                  }
                  {kpiChart.show_plan_data &&
                    <div
                      className=""
                      data-balloon="Select the plan kpi charting color (for this kpi/chart only)."
                      data-balloon-pos="up"
                      data-balloon-length="large"
                    >
                      <ColorPicker
                        color={kpiChart.plan_color || brandingColors[2]}
                        onChange={(color) => this.setActualPlanColorsForKpiChart(kpiChart.kpi_id, color.hex, 'plan_color')}
                      />
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      ));
    return (
      <React.Fragment>
        <div className="border-top border-bottom" style={{ borderColor: '#bcc7cf', backgroundColor: '#ededed' }}>
          {kpiCharts}
          {
            !subscribed && showBrandingColors &&
            <div className="mt1 flex flex-justify-end mr3 mb1">
              <div className="h5 text-orange">
                <span
                  className="h5"
                  data-balloon="You can change the chart colors to preview how they would look in your own colors. However, when sending the update, the colors will revert to investory defaults, unless you upgrade to Pro!"
                  data-balloon-pos="down"
                  data-balloon-length="large"
                >
                  <i className="fa fa-warning mr1" />
                  Chart color customization is only available on Pro plans -&nbsp;
                  <span
                    className="cursor-pointer text-blue"
                    onClick={() => { this.setState({ showBillingModal: true }); App.trackCompanyEvent('kpi_charts', 'upgrade_modal'); }}
                  >
                    Upgrade Here.
                  </span>
                </span>
              </div>
            </div>
          }
        </div>
        <BillingModal
          show={showBillingModal}
          url={billingUrl}
          toogleModal={() => this.setState({ showBillingModal: !showBillingModal })}
          topic="kpi_colors"
        />
      </React.Fragment>
    );
  }
}

export default KpiChartsList;
