/* eslint-disable object-shorthand */
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsTheme from './highcharts_theme';
import applyDateFormat from './highcharts_date_format';

HighchartsMore(Highcharts);

HighchartsTheme();

applyDateFormat();

export default function boxplotChart(selector, data) {
  Highcharts.getOptions().plotOptions.pie.colors = (function colors() {
    return ['#1C6B7C', 'green', '#ff7f0e', '#f28f3b', '#c8553d', '#e0a890', '#70b77e', '#5AAA95', '#473335', '#fcaa67', '#BF1363', '#01A7C2'];
  }());

  const getMonthFromQuarter = (quarter) => {
    let month;
    if (quarter === '1') {
      month = 1;
    } else if (quarter === '2') {
      month = 4;
    } else if (quarter === '3') {
      month = 7;
    } else {
      month = 10;
    }
    return month;
  };

  const dateProcessor = (accumulator, date) => {
    let processedDate;
    if (accumulator === 'months') {
      // eslint-disable-next-line radix
      processedDate = Date.UTC(parseInt(date.split(',')[1]), parseInt(date.split(',')[0] - 1));
    } else if (accumulator === 'years') {
      processedDate = Date.UTC(date);
    } else if (accumulator === 'quarters') {
      // eslint-disable-next-line radix
      processedDate = Date.UTC(parseInt(date.split(',')[1]), getMonthFromQuarter(date.split(',')[0]));
    }

    return processedDate;
  };

  const lineDataProcessor = (updata, accumulator) => {
    const dateProcessed = updata.map(([ext, ...rest]) => {
      const processedDate = dateProcessor(accumulator, ext);
      // eslint-disable-next-line radix
      return [processedDate, ...rest.map(el => parseFloat(el.toPrecision(2)))];
    });
    return dateProcessed;
  };

  const getMaxOfLineData = (updata) => {
    let max = 0;
    updata.forEach((val) => {
      if (val[1] > max) {
        // eslint-disable-next-line prefer-destructuring
        max = val[1];
      }
    });
    return max;
  };

  const getMinOfLineData = (updata) => {
    let min = 0;
    updata.forEach((val) => {
      if (val[1] < min) {
        // eslint-disable-next-line prefer-destructuring
        min = val[1];
      }
    });
    return min;
  };

  const maxData = (updata) => {
    const lineData = lineDataProcessor(updata.lineData, updata.accumulator);
    let max = getMaxOfLineData(lineData);
    updata.data.forEach((val) => {
      if (val[4] > max) {
        // eslint-disable-next-line prefer-destructuring
        max = val[4];
      }
    });
    return max;
  };

  const minData = (updata) => {
    const lineData = lineDataProcessor(updata.lineData, updata.accumulator);
    let min = getMinOfLineData(lineData);
    updata.data.forEach((val) => {
      if (val[2] < min) {
        // eslint-disable-next-line prefer-destructuring
        min = val[2];
      }
    });
    return min;
  };

  const tichIntervalForPeriodStep = (accumulator) => {
    let interval;
    if (accumulator === 'quarters') interval = 3 * 24 * 3600 * 1000 * 30;
    if (accumulator === 'years') interval = 12 * 24 * 3600 * 1000 * 30;
    return interval;
  };

  const getTickPositions = (lineData, accumulator) => {
    const dates = lineData.map(values => values[0]);
    let parts;
    let result;
    const parsedDates = dates.map(date => {
      parts = date.split(',');
      if (accumulator === 'months') {
        result = Date.UTC(parts[1], parts[0] - 1, 1);
      } else if (accumulator === 'quarters') {
        result = Date.UTC(parseInt(parts[1], 10), getMonthFromQuarter(parts[0]));
      } else {
        result = Date.UTC(parts[0], 0, 1);
      }
      return result;
    });
    return parsedDates.filter((v, i, a) => a.indexOf(v) === i);
  };

  const getDateFormat = (accumulator) => {
    if (accumulator === 'months') {
      return '%b %Y';
    }
    if (accumulator === 'quarters') {
      return 'Q%q, %Y';
    }

    return '%Y';
  };

  const boxPlotDataProcessor = (updata, accumulator, isGrowthValue, currency) => {
    const dateProcessed = updata.map(([ext, ...rest]) => {
      const processedDate = dateProcessor(accumulator, ext);
      return {
        type: 'boxplot',
        name: `Observations: ${rest.pop()}`,
        pointWidth: 20,
        pointPadding: 0.25,
        // eslint-disable-next-line radix
        data: [[processedDate, ...rest.map(el => parseFloat(el.toPrecision(2)))]],
        color: Highcharts.getOptions().colors[0],
        tooltip: {
          valueSuffix: isGrowthValue ? '%' : ` ${currency}`,
        },
      };
    });

    return dateProcessed;
  };

  const SeriesData = (updata) => {
    const processData = boxPlotDataProcessor(updata.data, updata.accumulator, updata.isGrowthValue, updata.currency);
    processData.push(
      {
        name: 'benchmarked company',
        type: 'spline',
        data: lineDataProcessor(updata.lineData, updata.accumulator),
        marker: {
          lineWidth: 2,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: 'white',
        },
        tooltip: {
          valueSuffix: updata.isGrowthValue ? '%' : ` ${updata.currency}`,
        },
      },
    );
    processData.push(
      {
        name: 'Outliers',
        color: Highcharts.getOptions().colors[0],
        type: 'scatter',
        data: [],
        marker: {
          fillColor: 'white',
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors[0],
        },
        tooltip: {
          pointFormat: 'Revenue: {point.y}',
        },
      },
    );
    return processData;
  };

  return Highcharts.chart({
    chart: {
      renderTo: selector,
      scrollablePlotArea: {
        minWidth: 300,
        scrollPositionX: 1,
      },
      zoomType: 'xy',
    },
    plotOptions: {
      series: {
        lineWidth: 3,
      },
      boxplot: {
        groupPadding: 0.4,
      },
    },
    title: {
      text: ' ',
    },

    legend: {
      enabled: false,
    },

    tooltip: {
      xDateFormat: getDateFormat(data.accumulator),
    },

    xAxis: {
      type: 'datetime',
      labels: {
        format: `{value:${getDateFormat(data.accumulator)}}`,
      },
      tickInterval: tichIntervalForPeriodStep(data.accumulator),
      tickPositions: getTickPositions(data.lineData, data.accumulator),
    },

    yAxis: {
      title: {
        text: data.chartTitle,
      },
      max: maxData(data),
      min: minData(data),
      labels: {
        formatter: function formatter() {
          const formattedLabel = this.axis.defaultLabelFormatter.call(this);
          return `${data.isGrowthValue ? `${formattedLabel}%` : `${formattedLabel}`}`;
        },
      },
    },

    series: SeriesData(data),
  });
}
