import React, { Component } from 'react';
import PropTypes from 'prop-types';


class TextInputProps extends Component {
  static defaultProps = {
    skipPropsUpdate: false,
  }

  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    skipPropsUpdate: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  componentWillReceiveProps(props) {
    const { skipPropsUpdate } = this.props;

    if (skipPropsUpdate) {
      return;
    }
    this.setState(props);
  }

  onChange = (e) => {
    const { onChange } = this.props;

    if (onChange) onChange(e);

    this.setState({ value: e.target.value });
  }

  render() {
    const { onChange, value, skipPropsUpdate, ...other } = this.props;

    return (
      <input
        type="text"
        onChange={this.onChange}
        value={this.state.value}
        {...other}
      />
    );
  }
}

export default TextInputProps;
