import { connect } from 'react-redux';
import DataRooms from '../../../components/company/data_rooms';

import {
  createQuickAccessFile,
  updateQuickAccessFile,
  deleteQuickAccessFile,
  deleteDataRoom,
} from '../../../api/company/dataRoomApi';

const mapStateToProps = state => ({
  company: state.company,
  dataRooms: state.companyDataRooms,
});

const mapDispatchToProps = {
  createFile: createQuickAccessFile,
  updateFile: updateQuickAccessFile,
  deleteFile: deleteQuickAccessFile,
  deleteDataRoom,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataRooms);
