import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';


class AddAsInvestment extends Component {
  static propTypes = {
    apply_url: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }

  state = {
    used: false,
  }

  postRequest = async () => {
    const { apply_url, id } = this.props;
    this.setState({ used: true });

    try {
      await axios.post(apply_url, { company_id: id });
      App.State.setFlash({ name: 'notice', msg: 'Access request has been sent!' });
      setTimeout(() => { window.location.reload(); }, 2000);
    } catch (err) {
      setTimeout(() => {
        this.setState({ used: false });
        App.State.setFlash({ name: 'alert', msg: err.response.data.error || err.response.data.errors || 'Sorry, an error prevented the investment from being created.' });
      }, 1000);
    }
  }

  render() {
    const { used } = this.state;

    return (
      <div className="col-12 flex-justify-center flex">
        <div
          className="col-4 rounded bg-orange h3 center text-white py1 px2 cursor-pointer"
          data-behavior="modal"
          data-target="#modal-message"
        >
          Send message
        </div>
        {used ?
          <div
            className="col-4 ml1 rounded bg-blue h3 center text-white py1 px2"
            href="#"
          >
            Loading...
          </div>
          :
          <div
            className="col-4 ml1 rounded bg-green h3 center text-white py1 px2 cursor-pointer"
            href="#"
            onClick={this.postRequest}
          >
            Request Access
          </div>
        }
      </div>
    );
  }
}

export default AddAsInvestment;
