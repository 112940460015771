import React, { Component } from 'react';
import PropTypes from 'prop-types';

import QuickAccessFiles from './QuickAccessFiles';
import CopyToClipboardIcon from '../../CopyToClipboardIcon';
import DropdownMenu from '../../DropdownMenu';
import ConfirmationModal from '../../ConfirmationModal';
import BillingModal from '../billing/BillingModal';
import Button from '../../Button';

class DataRooms extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    dataRooms: PropTypes.object.isRequired,
    createFile: PropTypes.func.isRequired,
    updateFile: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    deleteDataRoom: PropTypes.func.isRequired,
  }

  state = {
    roomLoading: null,
    showBillingModal: false,
  }

  deleteDataRoom = (roomId) => {
    const { deleteDataRoom } = this.props;

    this.setState({ roomLoading: roomId });

    deleteDataRoom(roomId).then(
      () => {
        App.State.setFlash({ name: 'notice', msg: 'Data Room deleted successfully!' });
        this.setState({ roomLoading: null });
      },
      () => {
        App.State.setFlash({ name: 'alert', msg: 'Sorry, an error happened while deleting the Data Room' });
        this.setState({ roomLoading: null });
      },
    );
  }

  render() {
    const { company, dataRooms, createFile, updateFile, deleteFile } = this.props;
    const { roomLoading, showBillingModal } = this.state;

    return (
      <div className="mb2">
        <div className="flex flex-column col-12">
          {[...dataRooms.default_room, ...dataRooms.shared_rooms].filter(room => room.reached_limit).length > 0 &&
            <div className="flex flex-justify-center bg-light-orange border border-orange rounded py05 mb2">
              <span className="text-gray fw400 h5"><i className="fa fa-exclamation-triangle mr1" />A data room has reached its 5 GB upload limit! You will no longer be able to upload documents in this data room. Contact <a href="mailto:support@investory.io">Support</a> if you need more space.</span>
            </div>
          }
          <div className="h3 bold mb2">
            <span className="mr1">Company Data Room</span>
            <span
              data-balloon="The company data room is accessible by all invited users with Data Room Access. This data room can not be removed and is used to share static company documents."
              data-balloon-pos="down"
              data-balloon-length="large"
            >
              <i className="fa fa-globe text-light-gray" />
            </span>
          </div>

          <QuickAccessFiles
            defaultRoom={dataRooms.default_room}
            files={dataRooms.quick_access_files}
            createFile={createFile}
            updateFile={updateFile}
            deleteFile={deleteFile}
          />

          <div className="flex flex-justify-between bs-around fw400 bg-white my3 pr2  card--has-hover-light-gray__small">
            <a className="flex flex-auto flex-column text-gray lh-12 py2 pl2" href={`/c/${company.id}/data_rooms/${dataRooms.default_room.id}`}>
              <div className="fw400">{company.name} Documents</div>
              <div className="h6 text-light-gray light">Last upload {dataRooms.default_room.last_upload} - {dataRooms.default_room.files_count} ({dataRooms.default_room.total_size}) Documents</div>
            </a>
            <div className="flex items-center text-light-gray sm-hide">
              <a
                className="bs-around card--has-hover-light-gray__small py05 px1 rounded mr1 text-light-gray"
                href={`data_rooms/${dataRooms.default_room.id}/data_room_stats`}
              >
                View Stats
              </a>
              <a
                className="bs-around card--has-hover-light-gray__small py05 px1 rounded text-light-gray mr1"
                href={`${dataRooms.default_room.shareable_link}/files`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download All
              </a>
              <a
                className="bs-around bg-green card--has-hover-light-gray__small py05 px1 rounded text-white"
                href={`/c/${company.id}/data_rooms/${dataRooms.default_room.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Access Data Room
              </a>
            </div>
          </div>

          <div className="flex flex-justify-between items-center mb2 sm-flex-column">
            <div className="h3 bold sm-mb2"><span className="mr1">Shareable Data Room(s)</span>
              <span
                data-balloon="The shareable data rooms are only accessible by shareable link. Only team members can see and manage the data rooms here. All other access is only possible via shareable link and read/download only."
                data-balloon-pos="down"
                data-balloon-length="large"
              >
                <i className="fa fa-lock text-light-gray" />
              </span>
            </div>
            {company.subscribed ?
              <a
                className="bg-green bs-around text-white fw400 p1 rounded"
                href="data_rooms"
                data-method="post"
                data-disable-with="Creating..."
              >
                + New Data Room
              </a>
              :
              <Button
                classNames="border border-alto bg-alto text-light-gray fw-400 p1 rounded"
                onClick={() => this.setState({ showBillingModal: true })}
                data-balloon="You need to upgrade to Pro to create shareable data rooms!"
                data-balloon-pos="left"
              >
                + New Data Room
              </Button>
            }
          </div>

          <div className="bg-white bs-around fw400 px2 py2">
            {dataRooms.shared_rooms.length ?
              <div className="flex flex-column">
                <div className="flex">
                  <span className="col-6">Name</span>
                  <span className="col-1">Share</span>
                  <span className="col-5 sm-hide" />
                  <span className="pr1 mx2 sm-hide" />
                </div>
                {dataRooms.shared_rooms.map(room => (
                  <div key={`data_room_${room.id}`} className="flex items-center bs-around my1 bs-around card--has-hover-light-gray__small cursor-pointer">
                    <a href={`/c/${company.id}/data_rooms/${room.id}`} className="col-6 flex flex-column flex-auto text-gray lh-12 py2 pl2">
                      <span className="text-gray">{room.name}</span>
                      { room.last_upload === '-' ?
                        <div className="h6 text-light-gray light">No documents uploaded yet...</div>
                        :
                        <div className="h6 text-light-gray light">Last upload {room.last_upload} - {room.files_count} ({room.total_size}) Documents</div>
                      }
                    </a>
                    <div className="col-1 sm-col-4">
                      { company.subscribed ?
                        <React.Fragment>
                          <CopyToClipboardIcon
                            icon="link"
                            text={room.shareable_link}
                            showCopy={false}
                          />
                          <a
                            className="ml1 text-light-gray"
                            href={`mailto:?subject=${room.name} Data Room Link&body=${room.shareable_link}`}
                            data-balloon="Mail Data Room Link"
                            data-balloon-pos="down"
                            data-balloon-length="small"
                          >
                            <i className="fa fa-envelope-o" />
                          </a>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <div
                            className="lh-12 text-light-gray inline-block cursor-pointer"
                            data-balloon="Copying of the Shareable link of this data room has been temporarily disabled, upgrade to Pro to unlock again!"
                            data-balloon-pos="down"
                            data-balloon-length="large"
                          >
                            <i className="fa fa-clipboard fa-fw" />
                          </div>
                          <span
                            className="ml1 text-light-gray"
                            data-balloon="Sending of the Shareable link via email of this data room has been temporarily disabled, upgrade to Pro to unlock again!"
                            data-balloon-pos="down"
                            data-balloon-length="large"
                          >
                            <i className="fa fa-envelope-o" />
                          </span>
                        </React.Fragment>
                      }
                    </div>
                    <div className="col-5 flex flex-justify-end pr2 sm-hide">
                      { company.subscribed ?
                        <React.Fragment>
                          <a
                            className="bs-around card--has-hover-light-gray__small py05 px1 rounded text-light-gray mr1"
                            href={`data_rooms/${room.id}/data_room_stats`}
                          >
                            View Stats
                          </a>
                          <a
                            className="bs-around card--has-hover-light-gray__small py05 px1 rounded text-light-gray"
                            href={`${room.shareable_link}/files`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download All
                          </a>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <div
                            className="bs-around card--has-hover-light-gray__small py05 px1 rounded text-light-gray mr1"
                            data-balloon="Viewing Stats link of this data room has been temporarily disabled, upgrade to Pro to unlock again!"
                            data-balloon-pos="down"
                            data-balloon-length="large"
                          >
                            View Stats
                          </div>
                          <div
                            className="bs-around card--has-hover-light-gray__small py05 px1 rounded text-light-gray"
                            data-balloon="Download all documents of this data room has been temporarily disabled, upgrade to Pro to unlock again!"
                            data-balloon-pos="down"
                            data-balloon-length="large"
                          >
                            Download All
                          </div>
                        </React.Fragment>
                      }
                    </div>
                    <div className="flex flex-justify-end cursor-pointer mr1 sm-hide">
                      <DropdownMenu faIcon={roomLoading === room.id ? 'refresh fa-spin' : 'cog'}>
                        <li>
                          { company.subscribed ?
                            <a
                              className="flex flex-center hover hover-bg-wild-sand hover-text-blue text-gray cursor-pointer bold h5 lh-36"
                              href={`/company/${company.id}/data_rooms/${room.id}/edit`}
                            >
                              <i className="fa fa-edit fa-fw px2 mr1" />
                              Edit
                            </a>
                            :
                            <span
                              className="flex flex-center bg-wild-sand hover-text-blue text-medium-gray cursor-pointer bold h5 lh-36"
                              data-balloon="Editing of data room has been temporarily disabled, upgrade to Pro to unlock again!"
                              data-balloon-pos="left"
                              data-balloon-length="large"
                            >
                              <i className="fa fa-edit fa-fw px2 mr1" />
                              Edit
                            </span>
                          }
                        </li>
                        <li>
                          <div className="flex flex-center hover hover-bg-wild-sand">
                            <ConfirmationModal
                              width="180px"
                              className="block bold h5 lh-36 text-gray nowrap"
                              onClick={() => this.deleteDataRoom(room.id)}
                              html='<div class="flex flex-center hover hover-text-blue"><i class="fa fa-trash fa-fw px2"></i><span class="px1">Delete</span></div>'
                              message="<b>You are about to permanently delete this Data Room. This cannot be undone.</b><br/><br/>Are you sure you want to delete?"
                              confirmationText="Delete"
                              cancelText="Cancel"
                            />
                          </div>
                        </li>
                      </DropdownMenu>
                    </div>
                  </div>
                ))}
              </div>
              :
              <div className="flex flex-justify-center text-light-gray py3">
                <img src={window.images.dataRoomSafe} alt="Empty!" style={{ width: '170px' }} />
                <div className="flex flex-column ml2">
                  <p className="fw400">A data room is a secure document repository for <br /> sharing with external users and tracking their access</p>
                  <div className="h5"><i className="fa fa-check mr1" />Setup Data Rooms for specific use cases like Due Diligence</div>
                  <div className="h5"><i className="fa fa-check mr1" />Share access with anyone via shareable link and track access</div>
                  <div className="h5"><i className="fa fa-check mr1" />Get notified when data rooms have been accessed</div>
                  { !company.subscribed &&
                    <span className="h5 mt1 text-blue cursor-pointer" onClick={() => { this.setState({ showBillingModal: true }); App.trackCompanyEvent('data_rooms', 'upgrade_modal'); }}>Click here to upgrade to Pro now!</span>
                  }
                </div>
                <BillingModal
                  show={showBillingModal}
                  url={`/company/${company.id}/billing`}
                  toogleModal={() => { this.setState({ showBillingModal: !showBillingModal }); }}
                  topic="data_rooms"
                />
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default DataRooms;
