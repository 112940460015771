import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../../../LoadingSpinner';
import TabView from '../../../../tab_view/TabView';

class FundrasingFactSheets extends Component {
  static propTypes = {
    show: PropTypes.bool,
    report: PropTypes.object,
    trackEvent: PropTypes.func,
    blurred: PropTypes.bool,
  }

  static defaultProps = {
    show: false,
    report: null,
    trackEvent: null,
    blurred: null,
  }

  showModal = () => {
    $('#modal_fact_sheet_settings').next('.modal__layer').show();
    $('#modal_fact_sheet_settings').fadeIn();
  }

  render() {
    const { show, report, trackEvent, blurred } = this.props;

    return (show ?
      <React.Fragment>
        {!report.loading ?
          <React.Fragment>
            <div className="flex h3 pt1 pb2">
              <div className="flex flex-auto">
                <p className="fw400 text-gray mr1">Fundraising Fact Sheets</p>
              </div>
              <div className={`flex lg-hide ${blurred ? 'hide-data' : ''}`}>
                <a className="pr2" href={report.data.export_url} data-method="get">
                  <p className="flex flex-center text-light-gray h4">
                    <i className="fa fa-table" />
                    <span className="ml1">Export</span>
                  </p>
                </a>
                <div onClick={this.showModal} className="cursor-pointer">
                  <p className="flex flex-center text-light-gray h4">
                    <i className="fa fa-cog" />
                    <span className="ml1">Fact Sheet Settings (All)</span>
                  </p>
                </div>
              </div>
            </div>
            <TabView
              searchEnabled={!blurred}
              items={report.data.items}
              trackEvent={trackEvent}
            />
          </React.Fragment> :
          <LoadingSpinner
            show={report.loading}
            type="fit"
            height="300px"
            background="transparent"
          />
        }
      </React.Fragment> : null
    );
  }
}

export default FundrasingFactSheets;
