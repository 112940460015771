import React from 'react';
import PropTypes from 'prop-types';

import Switch from '../../../Switch';
import Textarea from '../../../Textarea';
import LargeButton from '../common/LargeButton';
import CopyToClipboardIcon from '../../../CopyToClipboardIcon';

const buttonLabels = [
  'Create Public Link',
  'Create Protected Link',
];

const autoplayDurationOptions = [
  {
    label: ' 3 seconds',
    value: 3000,
  },
  {
    label: ' 5 seconds',
    value: 5000,
  },
  {
    label: ' 7 seconds',
    value: 7000,
  },
  {
    label: ' 10 seconds',
    value: 10000,
  },
  {
    label: ' 15 seconds',
    value: 15000,
  },
  {
    label: ' 20 seconds',
    value: 20000,
  },
  {
    label: ' 60 seconds',
    value: 20000,
  },
];

const expiresAtDurationOptions = [
  {
    label: ' 7 days',
    value: 7,
  },
  {
    label: ' 30 days',
    value: 30,
  },
  {
    label: ' 90 days',
    value: 90,
  },
  {
    label: ' 180 days',
    value: 180,
  },
  {
    label: ' 365 days',
    value: 365,
  },
];

class GeneralConfigForm extends React.Component {
  static propTypes = {
    autoplay: PropTypes.bool,
    autoplayDuration: PropTypes.number,
    expiresIn: PropTypes.number,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    shareableLink: PropTypes.string,
    protectedLink: PropTypes.string,
    onTitleChange: PropTypes.func.isRequired,
    onSubtitleChange: PropTypes.func.isRequired,
    onAutoplayChange: PropTypes.func.isRequired,
    onAutoplayDurationChange: PropTypes.func.isRequired,
    onRequireLink: PropTypes.func.isRequired,
    onExpiresInChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    autoplay: true,
    autoplayDuration: 10000,
    title: '',
    subtitle: '',
    shareableLink: '',
    protectedLink: '',
    expiresIn: 180,
  };

  state = {
    activeButton: false,
    dropdownOpen: false,
    expiresDropdownOpen: false,
    password: '',
  };

  handleNavClick = label => {
    const { activeButton } = this.state;

    if (label !== activeButton) {
      const { onRequireLink } = this.props;
      this.setState({
        activeButton: label,
      });
    }
  };

  handleSubmitPassword = () => {
    const { password } = this.state;
    const { onRequireLink } = this.props;

    if (password === '') {
      App.State.setFlash({
        name: 'alert',
        msg: 'Please set a password to be able to generate a link',
      });
      return;
    }
    onRequireLink(password);
  };

  onPasswordChange = e => {
    this.setState({
      password: e.target.value,
    });
  }

  handleLinkCreation = () => {
    const { onRequireLink } = this.props;
    onRequireLink();
  };


  renderViewOptions = () => {
    const {
      activeButton,
      password,
    } = this.state;
    const {
      shareableLink,
      protectedLink,
    } = this.props;

    switch (activeButton) {
      case buttonLabels[0]:
        return (
          <div className="row-input link-field">
            <label htmlFor="share-publicly">
              <span className="text-medium-gray sm-h6 lh-12">Shareable Link (anyone with the link can view the slideshow)</span>
            </label>
            <div className="flex sm-flex-column">
              <input
                className="flex-auto border border-lighter-gray hover hover-border-blue sm-h6"
                id="share-publicly"
                value={shareableLink}
              />
              <input
                id="submit-link-button"
                className="cursor-pointer bg-gallery border-none text-gray rounded inline-block height-38 px3 sm-px2 py1 ml2 sm-ml0 sm-mt2 fw400 sm-h6"
                type="button"
                value="Generate Link"
                onClick={this.handleLinkCreation}
                placeholder="Enter Password here..."
              />
            </div>
            <div className="flex mt1 sm-center">
              <div className="col-1 sm-col-6 sm-mt2">
                <CopyToClipboardIcon text={shareableLink} />
              </div>
              <div className="col-1 sm-col-6 sm-mt2">
                <a
                  href={`mailto:?subject=Portfolio Overview&body=${shareableLink}`}
                >
                  <span
                    className="text-light-gray ml1"
                    data-balloon="Send by email."
                    data-balloon-pos="down"
                    data-balloon-length="small"
                  >
                    <i className="fa fa-envelope fa-fw" /> Mail
                  </span>
                </a>
              </div>
            </div>
          </div>
        );
      case buttonLabels[1]:
        return (
          <div className="row-input link-field">
            <label htmlFor="link-password">
              <span className="text-medium-gray">Password</span>
            </label>
            <div id="link-password" className="flex sm-flex-column">
              <input
                id="submit-password-input"
                className="border border-lighter-gray hover hover-border-blue sm-h6"
                type="password"
                value={password}
                onChange={this.onPasswordChange}
              />
              <input
                id="submit-password-button"
                className="cursor-pointer bg-gallery border-none text-gray rounded inline-block height-38 px3 sm-px2 py1 ml2 sm-ml0 sm-mt2 fw400 sm-h6"
                type="button"
                value="Generate Link"
                onClick={this.handleSubmitPassword}
                placeholder="Enter Password here..."
              />
            </div>
            <label htmlFor="share-protected">
              <span className="text-medium-gray">Protected Link</span>
            </label>
            <input
              className="border border-lighter-gray hover hover-border-blue sm-h6"
              id="share-protected"
              value={protectedLink}
              placeholder="Add a password and click on Generate Link..."
            />
            { protectedLink &&
              <div className="flex mt1">
                <div className="col-1 sm-col-6">
                  <CopyToClipboardIcon text={protectedLink} />
                </div>
                <div className="col-1 sm-col-6">
                  <a
                    href={`mailto:?subject=Portfolio Overview&body=${protectedLink}`}
                  >
                    <span
                      className="text-light-gray ml1"
                      data-balloon="Send by email."
                      data-balloon-pos="down"
                      data-balloon-length="small"
                    >
                      <i className="fa fa-envelope fa-fw" /> Mail
                    </span>
                  </a>
                </div>
              </div>
            }
          </div>
        );
      default:
        return null;
    }
  }

  renderExpiresDropdown = () => {
    const { expiresDropdownOpen } = this.state;
    const {
      expiresIn,
      onExpiresInChange,
    } = this.props;
    const currentExpiresAtDuration = expiresAtDurationOptions.findIndex(option => option.value === expiresIn);

    return (
      <div className="relative">
        <div className="cursor-pointer flex flex-center" onClick={() => { this.setState({ expiresDropdownOpen: !expiresDropdownOpen }); }}>
          <span className="text-blue ml1 right-align">
            { expiresAtDurationOptions[currentExpiresAtDuration].label }
          </span>
          <i className="ml1 fa fa-caret-down" />
          <div className={`dropdown dropdown--center absolute bg-white border border-alto bs-dropdown left-70 transform-horizontal-center z1 ${expiresDropdownOpen ? '' : 'hidden'} py1`}>
            <ul className="left-align m0 mw200">
              { expiresAtDurationOptions.map(option => (
                <li
                  className="p1 bold h5 hover-bg-wild-sand"
                  key={option.label}
                  onClick={() => onExpiresInChange(option.value)}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  renderDelayDropdown = () => {
    const { dropdownOpen } = this.state;
    const {
      autoplayDuration,
      onAutoplayDurationChange,
    } = this.props;
    const currentAutoplayDuration = autoplayDurationOptions.findIndex(option => option.value === autoplayDuration);

    return (
      <div className="relative">
        <div className="cursor-pointer flex flex-center" onClick={() => { this.setState({ dropdownOpen: !dropdownOpen }); }}>
          <span className="text-blue ml1 right-align">
            { autoplayDurationOptions[currentAutoplayDuration].label }
          </span>
          <i className="ml1 fa fa-caret-down" />
          <div className={`dropdown dropdown--center absolute bg-white border border-alto bs-dropdown left-70 transform-horizontal-center z1 ${dropdownOpen ? '' : 'hidden'} py1`}>
            <ul className="left-align m0 mw200">
              { autoplayDurationOptions.map(option => (
                <li
                  className="p1 bold h5 hover-bg-wild-sand"
                  key={option.label}
                  onClick={() => onAutoplayDurationChange(option.value)}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { activeButton } = this.state;
    const {
      title,
      subtitle,
      onTitleChange,
      onSubtitleChange,
      autoplay,
      onAutoplayChange,
    } = this.props;

    return (
      <div className="general-config-form-wrapper">
        <form className="general-config-form">
          <section className="general-config-title-input-section mb1">
            <div className="row-input">
              <label htmlFor="slideshow-title">
                <span className="text-medium-gray">Title</span>
              </label>
              <Textarea
                className="border border-lighter-gray hover hover-border-blue"
                id="slideshow-title"
                onChange={onTitleChange}
                value={title}
              />
            </div>
            <div className="row-input">
              <label htmlFor="slideshow-subtitle">
                <span className="text-medium-gray">Subtitle</span>
              </label>
              <Textarea
                className="border border-lighter-gray hover hover-border-blue"
                id="slideshow-subtitle"
                onChange={onSubtitleChange}
                value={subtitle}
              />
            </div>
            <div className="flex flex-justify-between items-center">
              <div className="autoplay-settings flex items-center ml2 mb1 sm-h6 lh-12">
                {
                  <Switch
                    defaultChecked={autoplay}
                    onChange={onAutoplayChange}
                  />
                }
                <span className="pl1">Automatically run presentation with delay of</span>
                {this.renderDelayDropdown()}
              </div>
              <div className="flex sm-center sm-h6 mb1 mr2">
                Slideshow expires in {this.renderExpiresDropdown()}
              </div>
            </div>
          </section>
          <section className="general-config-nav mt1">
            { buttonLabels.map(label => {
              let faIcon = '';
              if (label === buttonLabels[0]) {
                faIcon = 'fa-share-alt';
              } else if (label === buttonLabels[1]) {
                faIcon = 'fa-lock';
              }
              return (
                <LargeButton
                  key={`but_${label}`}
                  labelText={label}
                  onClick={this.handleNavClick}
                  active={activeButton === label}
                  faIcon={faIcon}
                />
              );
            })}
          </section>
          <section className="general-config-view-options">
            {this.renderViewOptions()}
          </section>
        </form>
      </div>
    );
  }
}

export default GeneralConfigForm;
