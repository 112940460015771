import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../../TextInput';
import Modal from '../../Modal';

class FundEditButton extends Component {
  static defaultProps = {
    name: '',
  }

  static propTypes = {
    manage_url: PropTypes.string.isRequired,
    name: PropTypes.string,
  }

  state = {
    open: false,
    name: this.props.name,
  }

  updateFund =() => {
    const { manage_url } = this.props;
    const { name } = this.state;

    App.Api.submitPatch(manage_url, { name }).then((data) => {
      if (data.success === false) {
        return App.State.setFlash({ name: data.flash.name, msg: data.flash.msg });
      }
      this.setState({ open: false });
      store.dispatch({ type: 'SET_ITEM', list: 'main', id: data.id, value: data });
    });
  }

  render() {
    const { open, name } = this.state;
    return (
      <div className="">
        <div
          className="cursor-pointer text-gray mx1"
          onClick={() => { this.setState({ open: true }); }}
          data-balloon="Edit name"
          data-balloon-pos="down"
        >
          <i className="fa fa-pencil fa-fw" />
        </div>
        <Modal
          className="bg-white flex flex-column mb2 react_modal"
          show={open}
          onClickOutside={() => { this.setState({ open: false }); }}
        >
          <div
            className="flex flex-auto flex-justify-end cursor-pointer p1"
            onClick={() => { this.setState({ open: false }); }}
          >
            <i className="fa fa-close text-gray" />
          </div>
          <p className="col-12 center semi-bold py2 h2">Edit fund name</p>
          <div className="col-12 flex flex-column flex-center sm-block bg-alabaster p3 sm-center">
            <TextInput
              className="col-12"
              onChange={(e) => { this.setState({ name: e.target.value }); }}
              placeholder="Name of the fund"
              value={name}
              name="fund[name]"
              id="name"
            />
            <div className="bg-blue mt2 text-white semi-bold p1 px2 cursor-pointer" onClick={this.updateFund}>
              Save changes
            </div>
          </div>
        </Modal>
      </div>

    );
  }
};

export default FundEditButton;
