import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FundrasingFactSheets from './FundrasingFactSheets';
import InvestmentReports from './InvestmentReports';
import Slideshow from './Slideshow';
import InvestorDynamicReports from '../../../dynamic_reports/InvestorDynamicReports';

class Reports extends Component {
  static propTypes = {
    reports: PropTypes.object,
    getFactSheetTabData: PropTypes.func,
    getExcelExportTabData: PropTypes.func,
    getSlideshowTabData: PropTypes.func,
    investorId: PropTypes.number.isRequired,
    fundId: PropTypes.number,
    loadNewExportFile: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
    blurred: PropTypes.bool,
  }

  static defaultProps = {
    reports: null,
    getFactSheetTabData: null,
    getExcelExportTabData: null,
    getSlideshowTabData: null,
    blurred: null,
    fundId: null,
  }

  state = {
    selectedReportType: 'fact_sheet',
  }

  componentDidMount() {
    const { getFactSheetTabData } = this.props;

    getFactSheetTabData();
  }

  changeReportType = (type) => {
    const { getExcelExportTabData, getSlideshowTabData, trackEvent } = this.props;

    this.setState({ selectedReportType: type }, () => {
      if (type === 'excel_export') getExcelExportTabData();
      if (type === 'slideshow') getSlideshowTabData();
      if (type === 'dynamic_reports') trackEvent('dynamic_reports');
    });
  }

  render() {
    const { reports, investorId, fundId, loadNewExportFile, trackEvent, blurred } = this.props;
    const { selectedReportType } = this.state;

    const active = 'flex items-center flex-justify-center text-blue border-blue';
    const inactive = 'flex items-center flex-justify-center hover hover-text-blue text-light-gray border-transparent bs-around hover-border-blue';
    return (
      <React.Fragment>
        <div className="flex flex-column flex-auto pt1">
          <div className="flex flex-justify-start sm-block">
            <div className={`col-2 sm-col-12 bg-white cursor-pointer mb3 mr3 border ${selectedReportType === 'fact_sheet' ? active : inactive}`} onClick={() => this.changeReportType('fact_sheet')}>
              <div className="flex flex-column items-center py2">
                <i className="fa fa-file-text-o fa-3x" />
                <div className="h4 pt1 fw400 flex flex-column flex-center">
                  <span>Fundraising</span>
                  <span>Fact Sheets</span>
                </div>
              </div>
            </div>
            <div className={`col-2 sm-col-12 bg-white cursor-pointer mb3 mr3 border ${selectedReportType === 'excel_export' ? active : inactive}`} onClick={() => this.changeReportType('excel_export')}>
              <div className="flex flex-column items-center py2">
                <i className="fa fa-file-excel-o fa-3x" />
                <div className="h4 pt2 fw400 flex flex-column flex-center">
                  <span>Portfolio Report</span>
                  <span>(Excel)</span>
                </div>
              </div>
            </div>
            <div className={`col-2 sm-col-12 bg-white cursor-pointer mb3 mr3 border ${selectedReportType === 'slideshow' ? active : inactive}`} onClick={() => this.changeReportType('slideshow')}>
              <div className="flex flex-column items-center py2">
                <i className="fa fa-file-powerpoint-o fa-3x" />
                <div className="h4 pt2 fw400 flex flex-column flex-center">
                  <span>Slideshow</span>
                </div>
              </div>
            </div>
            {reports.dynamic_reports.can_access &&
              <div className={`col-2 sm-col-12 bg-white cursor-pointer mb3 mr3 border ${selectedReportType === 'dynamic_reports' ? active : inactive}`} onClick={() => this.changeReportType('dynamic_reports')}>
                <div className="flex flex-column items-center py2">
                  <i className="fa fa-bar-chart fa-3x" />
                  <div className="h4 pt2 fw400 flex flex-column flex-center">
                    <span>Dynamic Reports</span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <FundrasingFactSheets
          show={selectedReportType === 'fact_sheet'}
          report={reports.fact_sheet}
          trackEvent={trackEvent}
          blurred={blurred}
        />
        <InvestmentReports
          show={selectedReportType === 'excel_export'}
          investorId={investorId}
          fundId={fundId}
          report={reports.excel_export}
          dateRangeOptions={reports.date_range_options}
          loadNewExportFile={loadNewExportFile}
          trackEvent={trackEvent}
          blurred={blurred}
        />
        <Slideshow
          show={selectedReportType === 'slideshow'}
          report={reports.slideshow}
          blurred={blurred}
        />
        {reports.dynamic_reports.can_access &&
          <InvestorDynamicReports
            show={selectedReportType === 'dynamic_reports'}
            kpiDataUrl={`/investor/${investorId}/reports/dynamic_reports/kpis`}
            investmentDataUrl={`/investor/${investorId}/reports/dynamic_reports/investments`}
            dateRangeOptions={reports.date_range_options}
            blurred={blurred}
          />
        }
      </React.Fragment>
    );
  }
}

export default Reports;
