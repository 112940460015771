import { connect } from 'react-redux';
import CapTable from '../../../components/cap_table/CapTable';

import {
  updateRound,
  createShare,
  updateShare,
  deleteShare,
  createShareClass,
  updateShareClass,
  deleteShareClass,
  createConvertible,
  updateConvertible,
  deleteConvertible,
} from '../../../api/company/capTableApi';

const mapStateToProps = state => ({
  editor: true,
  company: state.company,
  capTable: state.capTable,
});

const mapDispatchToProps = {
  updateRound,
  createShare,
  updateShare,
  deleteShare,
  createShareClass,
  updateShareClass,
  deleteShareClass,
  createConvertible,
  updateConvertible,
  deleteConvertible,
};

export default connect(mapStateToProps, mapDispatchToProps)(CapTable);
