import React, { Component } from 'react';
import PropTypes from 'prop-types';


class MagicStick extends Component {
  static propTypes = {
    color: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    setMagic: PropTypes.func.isRequired,
    active: PropTypes.any.isRequired,
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
    document.removeEventListener('touchstart', this.handleClick, false);
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
    document.addEventListener('touchstart', this.handleClick, false);
  }

  activate = () => {
    const { field, setMagic } = this.props;
    setMagic(field);
  }

  deactivate = () => {
    const { active, field, setMagic } = this.props;
    if (active !== field) return;
    setMagic(false);
  }

  handleClick = (e) => {
    if (ReactDOM.findDOMNode(this).contains(e.target)) return;
    this.deactivate();
  }

  render() {
    const { color, active, field } = this.props;

    return (
      <div
        className={`bg-${color} flex flex-center flex-justify-center cursor-pointer ${active === field ? 'border border-gray bw-2' : ''}`}
        style={{ width: '40px' }}
        onClick={this.activate}
      >
        <i className="fa fa-magic text-white" />
      </div>
    );
  }
}

export default MagicStick;
