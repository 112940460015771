import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';

import ProFeatures from './ProFeatures';
import PaymentModal from '../../payment/PaymentModal';
import TextInput from '../../TextInput';
import Switch from '../../Switch';


class Pricing extends Component {
  static propTypes = {
    plans: PropTypes.object.isRequired,
    subscribe: PropTypes.func.isRequired,
    trialUsed: PropTypes.bool.isRequired,
  }

  state = {
    saving: false,
    savingCoupon: false,
    showCoupon: false,
    coupon: '',
    discount: null,
    duration: null,
    repeating: null,
    showPaymentModal: false,
    selectedPlan: {},
    monthlyPayments: true,
  }

  closeModal = () => this.setState({ showPaymentModal: false });

  applyCoupon = async () => {
    const { coupon } = this.state;

    const validateCoupon = /^[a-z0-9]+$/i;

    if (coupon === null || !validateCoupon.test(coupon)) {
      App.State.setFlash({ name: 'alert', msg: 'Invalid Code!' });
      return;
    }

    this.setState({ savingCoupon: true });

    try {
      const res = await Axios.get(`/coupons?id=${coupon}`);
      this.setState({ discount: res.data.percent_off / 100, duration: res.data.duration_in_months, repeating: res.data.duration });
    } catch (err) {
      this.setState({ coupon: null, discount: null });
      App.State.setFlash({ name: 'alert', msg: 'Coupon Code not found!' });
    }
    this.setState({ savingCoupon: false });
  }

  onSubmit = async (params) => {
    const { subscribe } = this.props;
    const { selectedPlan } = this.state;

    this.setState({ saving: true });
    try {
      await subscribe({
        ...params,
        subscription: {
          ...params.subscription,
          plan_id: selectedPlan.id,
        },
      });
      this.setState({ saving: false });
    } catch (err) {
      this.setState({ saving: false });
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
    }
  }

  renderBasicFeatures = () => (
    <React.Fragment>
      <ul className="flex flex-wrap mx2 mt2 h5">
        <li className="col-12 mb1"><i className="fa fa-check text-green mr1" />Unlimited Updates and Users</li>
        <li className="col-12 mb1"><i className="fa fa-check text-green mr1" />Unlimited Investor Connections</li>
        <li className="col-12 mb1"><i className="fa fa-check text-green mr1" />Document Signing</li>
        <li className="col-12 mb1"><i className="fa fa-check text-green mr1" />CapTable Management</li>
        <li className="col-12 mb1"><i className="fa fa-check text-green mr1" />API (google sheets)</li>
        <li className="col-12 pb2 mb2 border-bottom border-gallery"><i className="fa fa-check text-green mr1" />Data Room (with 100 Mb storage)</li>
        <li className="col-12 mb1"><i className="fa fa-check text-green mr1" />Email and Chat Support</li>
      </ul>
    </React.Fragment>
  )

  YearlyToMonthly = () => {
    const { plans: { yearly } } = this.props;
    return +(yearly.price / 12).toFixed(2);
  }

  calcDiscount = (amount) => {
    const { discount } = this.state;
    if (discount === null) {
      return;
    }
    return +(amount * (1 - discount)).toFixed(2);
  }

  render() {
    const { plans: { monthly, yearly }, trialUsed } = this.props;
    const { saving, savingCoupon, showCoupon, coupon, discount, duration, repeating, showPaymentModal, selectedPlan, monthlyPayments } = this.state;

    return (
      <div className="flex flex-column">
        <div className="flex flex-justify-between mb2">
          <span className="h2 text-blue">Plans</span>
          <div
            className="flex text-blue cursor-pointer"
            onClick={() => this.setState(state => ({ showCoupon: !state.showCoupon }))}
          >
            Apply Coupon Code
            <span className="ml1">
              <i className={`fa ${showCoupon ? 'fa-caret-down' : 'fa-caret-right'}`} />
            </span>
          </div>
          <div className="h4">
            <Switch
              labelLeft="Monthly"
              labelRight="Yearly"
              onChange={() => this.setState({ monthlyPayments: !monthlyPayments })}
            />
          </div>
        </div>
        {showCoupon &&
          <React.Fragment>
            <div className="flex items-center fw400 bg-white bs-around p1">
              <TextInput
                value={coupon}
                className="flex-auto border-none mr2"
                onChange={e => this.setState({ coupon: e.target.value })}
                placeholder="Insert Coupon Code Here"
              />
              {savingCoupon ?
                <div className="text-white px2 pt1 mr1 rounded cursor-disabled">
                  <img src={window.images.loading} alt="Applying..." height="24" width="24" />
                </div>
                :
                <input className="border-none bg-blue text-white px2 py1 rounded cursor-pointer" type="submit" value="Apply" onClick={this.applyCoupon} />
              }
            </div>
            <span className="h6 text-orange lh-12 mb2 mt1">Coupon Codes only apply to paid subscriptions (not free trials). Make sure that the code is also correctly added to the payment form!</span>
          </React.Fragment>
        }
        <div className="bg-white bs-around p2">
          <div className="flex flex-justify-center">
            <div className="col-6">
              <ProFeatures />
            </div>
            {monthlyPayments ?
              <div className="col-6 flex flex-column flex-justify-center ml1">
                <div className="col-12">
                  <div className="col-12 center pt2">
                    {(discount === null) ?
                      <React.Fragment>
                        <div className="h1">€{monthly.price} <span className="h4">/ {monthly.interval}</span></div>
                        <div className="text-light-gray h5 mb1">billed monthly</div>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <div className="text-orange h5 mb1 strikethrough">€{monthly.price} <span className="h4">/ {monthly.interval}</span></div>
                        <div className="h1">€{this.calcDiscount(monthly.price)} <span className="h4">/ Month</span></div>
                        {repeating === 'forever' ?
                          <div className="text-light-gray h5 mb1 lh-12">Coupon for {discount * 100}% off {repeating}</div>
                          :
                          <div className="text-light-gray h5 mb1 lh-12">Coupon for {discount * 100}% off for first {duration} months <br /> then €{monthly.price} billed monthly</div>
                        }
                      </React.Fragment>
                    }
                    <div className="flex flex-justify-center mt2 mb1">
                      {saving ?
                        <img src={window.images.loading} alt="Subscribing..." height="32" width="32" />
                        :
                        <React.Fragment>
                          {!trialUsed &&
                            <div
                              className="border border-green text-white bg-green bold hover hover-text-green hover-bg-white px2 py1 rounded bs-around cursor-pointer"
                              onClick={() => { this.setState({ selectedPlan: monthly }, () => this.onSubmit({})); Intercom('trackEvent', 'start_trial_subscription_successful'); }}
                            >
                              Start 14 Days Free Trial Now
                            </div>
                          }
                        </React.Fragment>
                      }
                    </div>
                    <div>
                      <span className="mr1">Or</span>
                      <span
                        className="text-blue fw400 cursor-pointer"
                        onClick={() => { this.setState({ showPaymentModal: true, selectedPlan: monthly }); App.trackCompanyEvent('stripe', 'pricing_upgrade_now_monthly'); }}
                      >
                        subscribe now
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className="col-6 flex flex-column flex-justify-center ml1">
                <div className="col-12">
                  <div className="col-12 center pt2">
                    {(discount === null) ?
                      <React.Fragment>
                        <div className="h1">€{this.YearlyToMonthly()} <span className="h4">/ Month</span></div>
                        <div className="text-light-gray h5 mb1">€{yearly.price} billed annually</div>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <div className="text-orange h5 mb1 strikethrough">€{this.YearlyToMonthly()} <span className="h4">/ Month</span></div>
                        <div className="h1">€{this.calcDiscount(this.YearlyToMonthly())} <span className="h4">/ Month</span></div>
                        {repeating === 'forever' ?
                          <div className="text-light-gray h5 mb1 lh-12">Coupon for {discount * 100}% off {repeating}</div>
                          :
                          <div className="text-light-gray h5 mb1 lh-12">Coupon for {discount * 100}% off for first {Math.ceil(duration / 12)} year(s)<br /> then €{yearly.price} billed annually</div>
                        }
                      </React.Fragment>
                    }
                    <div className="flex flex-justify-center mt2 mb1">
                      {saving ?
                        <img src={window.images.loading} alt="Subscribing..." height="32" width="32" />
                        :
                        <React.Fragment>
                          {!trialUsed &&
                            <div
                              className="border border-green text-white bg-green bold hover hover-text-green hover-bg-white px2 py1 rounded bs-around cursor-pointer"
                              onClick={() => { this.setState({ selectedPlan: yearly }, () => this.onSubmit({})); Intercom('trackEvent', 'start_trial_subscription_successful'); }}
                            >
                              Start 14 Days Free Trial Now
                            </div>
                          }
                        </React.Fragment>
                      }
                    </div>
                    <div>
                      <span className="mr1">Or</span>
                      <span
                        className="text-blue fw400 cursor-pointer"
                        onClick={() => { this.setState({ showPaymentModal: true, selectedPlan: yearly }); App.trackCompanyEvent('stripe', 'pricing_upgrade_now_yearly'); }}
                      >
                        subscribe now
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          {showPaymentModal &&
            <PaymentModal
              closeModal={this.closeModal}
              plan={selectedPlan}
              coupon={coupon}
              onSubmit={this.onSubmit}
            />
          }
        </div>
        <div className="mt1 h6"><span className="fw400">Note: </span>Prices exclude VAT.</div>
      </div>
    );
  }
}

export default Pricing;
