import React from 'react';
import PropTypes from 'prop-types';

const NavButton = props => {
  const {
    labelText,
    onClick,
    enabled,
    bgColor,
  } = props;

  return enabled ? (
    <div className={`but-view-slideshow ${bgColor}`} onClick={onClick}>
      <div className="but-view-slideshow-label">
        <span>{labelText}</span>
      </div>
    </div>
  ) : (
    <div className="but-view-slideshow disabled">
      <div className="but-view-slideshow-label">
        <span>{labelText}</span>
      </div>
    </div>
  );
};

NavButton.propTypes = {
  labelText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  bgColor: PropTypes.string,
};

NavButton.defaultProps = {
  enabled: false,
  bgColor: 'bg-green',
};

export default NavButton;
