import React from 'react';
import PropTypes from 'prop-types';


const SvgChart = ({ table, forecastTable }) => (
  <div className="absolute bottom-0 left-0 right-0 top-50 flex flex-justify-center flex-auto">
    <svg viewBox="0 0 330 40" className="col-12">
      <polyline
        fill="none"
        stroke="#E9F1F8"
        strokeWidth="3"
        points={table}
      />
      <polyline
        fill="none"
        stroke="#fff2e0"
        strokeWidth="3"
        points={forecastTable}
      />
    </svg>
  </div>
);

SvgChart.propTypes = {
  table: PropTypes.any.isRequired,
  forecastTable: PropTypes.any.isRequired,
};

export default SvgChart;
