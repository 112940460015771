import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectStripe } from 'react-stripe-elements';

class IntentForm extends Component {
    static propTypes = {
      stripe: PropTypes.object.isRequired,
      company: PropTypes.object.isRequired,
    };

    async componentDidMount() {
      const { company: { subscription }, stripe } = this.props;
      if (subscription.setup_intent_status === 'requires_action' || subscription.setup_intent_status === 'requires_confirmation') {
        const setup = await stripe.handleCardSetup(subscription.setup_client_secrets);
        const setupIntentStatus = localStorage.getItem('setup_intent_status');
        if (setup.error && setup.error.message) {
          if (!setupIntentStatus) {
            App.State.setFlash({ name: 'alert', msg: 'Payment failed! Please try again later or contact support.' });
            localStorage.setItem('setup_intent_status', 'requires_payment_method');
          }
          window.location.reload();
        }
        if (setup && setup.setupIntent.status === 'succeeded') {
          if (!setupIntentStatus) {
            App.State.setFlash({ name: 'notice', msg: 'The subscription was successful.' });
            localStorage.setItem('setup_intent_status', 'succeeded');
          }
          window.location.reload();
        }
      } else if (subscription.payment_intent_status === 'requires_action' || subscription.payment_intent_status === 'requires_confirmation') {
        const payment = await stripe.handleCardPayment(subscription.client_secrets);
        const paymentIntentStatus = localStorage.getItem('payment_intent_status');
        if (payment.error && payment.error.message) {
          if (!paymentIntentStatus) {
            App.State.setFlash({ name: 'alert', msg: 'Payment failed! Please try again later or contact support.' });
            localStorage.setItem('payment_intent_status', 'requires_payment_method');
          }
          window.location.reload();
        }
        if (payment && payment.paymentIntent.status === 'succeeded') {
          if (!paymentIntentStatus) {
            App.State.setFlash({ name: 'notice', msg: 'The subscription was successful.' });
            localStorage.setItem('payment_intent_status', 'succeeded');
          }
          window.location.reload();
        }
      }
    }

    render() {
      return null;
    }
}

export default injectStripe(IntentForm);
