import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Textarea from '../../Textarea';


class Kpi extends Component {
  static propTypes = {
    col: PropTypes.object.isRequired,
    edit: PropTypes.bool.isRequired,
    investorId: PropTypes.number.isRequired,
    updateReportColumn: PropTypes.func.isRequired,
    titleColor: PropTypes.string.isRequired,
    valueColor: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      value: props.col.value || '',
    };
  }

  colSpacing = col => {
    if (col.width === '4' && col.border) {
      return col.position === '3' ? 'sm-mr0' : 'mr3 sm-mr0';
    }
    return '';
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  inputWidth = col => {
    switch (col.width) {
      case 12: return '100%';
      case 4: return '65%';
      default: return '80%';
    }
  }

  saveChange = id => {
    const { investorId, updateReportColumn } = this.props;
    updateReportColumn(investorId, id, this.state);
  }

  render() {
    const { col, edit, titleColor, valueColor } = this.props;
    const { value } = this.state;
    const container = classNames(`py1 sm-col-12 col-${col.width} ${this.colSpacing(col)}`, {
      'flex flex-center': col.display === 'inline',
      'px2 sm-px1 mt2': col.border,
    });
    const titleClasses = classNames('m0 text-light-gray', {
      'flex-auto': col.display === 'inline',
    });
    const valueClasses = classNames('h2 m0 lh-15 fw400 border bg-none', {
      'border-transparent p0': !edit,
      'border-lighter-gray hover hover-border-blue': edit,
    });

    return (
      <div
        key={`col_${col.id}`}
        className={container}
        style={{ border: `1px solid ${col.border}` }}
      >
        {col.title && (<p className={titleClasses} style={{ color: titleColor }}>{col.title}</p>)}
        {col.subtitle && (<p className="m0 h6 text-gray">{col.subtitle}</p>)}
        <Textarea
          className={valueClasses}
          style={{ width: this.inputWidth(col), resize: 'none', color: valueColor }}
          name="value"
          value={value}
          onChange={e => this.handleChange(e)}
          onBlur={() => this.saveChange(col.id)}
          disabled={!edit}
        />
      </div>
    );
  }
}

export default Kpi;
