import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Chart extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
  }

  componentDidMount() {
    const { data } = this.props;
    window.createPie('pie_chart', data);
  }

  render() {
    return (
      <div className="flex">
        <div className="col-12 px2">
          <div className="flex flex-auto flex-column mb3 relative" id="chart">
            <div className="flex-auto">
              <div className="p2" id="pie_chart" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Chart;
