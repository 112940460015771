import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

class Pivot extends Component {
  webdatarocks;

  static propTypes = {
    report: [PropTypes.string, PropTypes.object],
    highChartLoading: PropTypes.func,
    columns: PropTypes.array,
  }

  static defaultProps = {
    report: null,
    highChartLoading: null,
    columns: [],
  }

  componentDidMount() {
    this.renderComponent(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { report, columns } = this.props;
    if (report !== nextProps.report) this.renderComponent(nextProps);
    if (columns !== nextProps.columns) this.updateColumns(nextProps);
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    this.webdatarocks.dispose();
  }

  updateColumns(props) {
    const { columns } = props;
    const report = this.webdatarocks.getReport();
    this.webdatarocks.setReport({ ...report,
      slice: {
        ...report.slice,
        columns,
      } });
  }

  renderComponent(props) {
    const config = {};
    config.container = ReactDOM.findDOMNode(this);
    if (props.toolbar !== undefined) {
      config.toolbar = props.toolbar;
    }
    if (props.width !== undefined) {
      config.width = props.width;
    }
    if (props.height !== undefined) {
      config.height = props.height;
    }
    if (props.report !== undefined) {
      config.report = props.report;
    }
    if (props.global !== undefined) {
      config.global = props.global;
    }
    if (props.customizeCell !== undefined) {
      config.customizeCell = props.customizeCell;
    }
    // events
    if (props.cellclick !== undefined) {
      config.cellclick = props.cellclick;
    }
    if (props.celldoubleclick !== undefined) {
      config.celldoubleclick = props.celldoubleclick;
    }
    if (props.dataerror !== undefined) {
      config.dataerror = props.dataerror;
    }
    if (props.datafilecancelled !== undefined) {
      config.datafilecancelled = props.datafilecancelled;
    }
    if (props.dataloaded !== undefined) {
      config.dataloaded = props.dataloaded;
    }
    if (props.datachanged !== undefined) {
      config.datachanged = props.datachanged;
    }
    if (props.fieldslistclose !== undefined) {
      config.fieldslistclose = props.fieldslistclose;
    }
    if (props.fieldslistopen !== undefined) {
      config.fieldslistopen = props.fieldslistopen;
    }
    if (props.filteropen !== undefined) {
      config.filteropen = props.filteropen;
    }
    if (props.fullscreen !== undefined) {
      config.fullscreen = props.fullscreen;
    }
    if (props.loadingdata !== undefined) {
      config.loadingdata = props.loadingdata;
    }
    if (props.loadinglocalization !== undefined) {
      config.loadinglocalization = props.loadinglocalization;
    }
    if (props.loadingreportfile !== undefined) {
      config.loadingreportfile = props.loadingreportfile;
    }
    if (props.localizationerror !== undefined) {
      config.localizationerror = props.localizationerror;
    }
    if (props.localizationloaded !== undefined) {
      config.localizationloaded = props.localizationloaded;
    }
    if (props.openingreportfile !== undefined) {
      config.openingreportfile = props.openingreportfile;
    }
    if (props.querycomplete !== undefined) {
      config.querycomplete = props.querycomplete;
    }
    if (props.queryerror !== undefined) {
      config.queryerror = props.queryerror;
    }
    if (props.ready !== undefined) {
      config.ready = props.ready;
    }
    if (props.reportchange !== undefined) {
      config.reportchange = props.reportchange;
    }
    if (props.reportcomplete !== undefined) {
      config.reportcomplete = props.reportcomplete;
    }
    if (props.reportfilecancelled !== undefined) {
      config.reportfilecancelled = props.reportfilecancelled;
    }
    if (props.reportfileerror !== undefined) {
      config.reportfileerror = props.reportfileerror;
    }
    if (props.reportfileloaded !== undefined) {
      config.reportfileloaded = props.reportfileloaded;
    }
    if (props.runningquery !== undefined) {
      config.runningquery = props.runningquery;
    }
    if (props.update !== undefined) {
      config.update = props.update;
    }
    if (props.beforetoolbarcreated !== undefined) {
      config.beforetoolbarcreated = props.beforetoolbarcreated;
    }
    this.webdatarocks = new window.WebDataRocks(config);

    const { highChartLoading } = this.props;

    this.webdatarocks.on('reportcomplete', () => {
      if (highChartLoading) {
        this.webdatarocks.highcharts.getData({
          type: 'areaspline',
        },
        (data) => highChartLoading(data),
        (data) => highChartLoading(data));
      }
    });
  }

  render() {
    return (
      <div> Pivot </div>
    );
  }
}

export default Pivot;
