import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Avatar from './Avatar';
import FaButtonFunction from './FaButtonFunction';
import CompanyInvestmentAdmin from './CompanyInvestmentAdmin';
import CompanyPermissions from './CompanyPermissions';


class CompanyInvestment extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  accept = () => {
    const { data } = this.props;

    App.Api.patch(data.accept_url).then((returnedData) => {
      store.dispatch({ type: 'SET_ITEM', list: 'main', id: returnedData.id, value: returnedData });
      App.State.setFlash({ name: 'notice', msg: 'Investor request is accepted!' });
    });
  }

  decline = () => {
    const { data } = this.props;

    App.Api.delete(data.manage_url).then(() => {
      store.dispatch({ type: 'REMOVE_ITEM', list: 'main', id: data.id });
      App.State.setFlash({ name: 'notice', msg: 'Investor request is rejected' });
    });
  }

  displayActions = () => {
    const { data } = this.props;

    if (data.admin && data.pending) {
      return (
        <div className="flex items-center">
          <FaButtonFunction faIcon="times" margin="m0" tooltip="Reject Investor" handleClick={this.decline} color="red" />
          <FaButtonFunction faIcon="check" margin="m0" tooltip="Approve Investor" handleClick={this.accept} color="green" />
        </div>
      );
    }

    if (data.admin) {
      return (
        <CompanyInvestmentAdmin
          id={data.id}
          manage_url={data.manage_url}
          name={data.name}
        />
      );
    }

    return null;
  }

  render() {
    const { data } = this.props;
    let type = data.pending ? '(Awaiting approval)' : null;
    type = data.requested ? '(Request sent)' : type;
    const classNames = data.pending ? 'col-12 flex flex-wrap px2 h5 sm-px0 bg-lighter-gray' : 'col-12 flex flex-wrap px2 h5 sm-px0';

    return (
      <div className={classNames}>
        <div className="col-3 py1 pl2 sm-col-6 sm-pr2">
          <div href={data.is_investor ? null : data.url} className="flex items-center cursor-pointer mr2">
            <Avatar avatarUrl={data.avatar} />
            <span
              className="col-12"
              data-balloon={data.type === 'Fund' ? `Fund contact: ${data.fund_email}` : `${data.fund_email}`}
              data-balloon-pos="down"
            >
              <div className="col-12 truncate">
                <span className="ml1">{data.name}</span>
              </div>
            </span>
          </div>
        </div>

        <div className="col-2 flex items-center py1 pl1 sm-hide">
          <span className="ml1">{data.number_of_members} {type ? `${type}` : ''}</span>
        </div>

        { !data.is_investor &&
          <div className="col-7 sm-col-6 flex items-center">
            {/* Permissions Listing */}
            <CompanyPermissions data={data} />

            {/* Cog of actions */}
            {this.displayActions()}
          </div>
        }
      </div>
    );
  }
}

export default CompanyInvestment;
