import { connect } from 'react-redux';
import InvestorHome from '../../components/investor/home/InvestorHome';
import { removeItem } from '../../actions/investor/investorHomeActions';

import {
  setTimeAgo,
  createFund,
  deleteFund,
  getChartData,
  getDashboardData,
  getCardsData,
  getUpdatesData,
  getDealRoomsData,
  getDocumentsData,
  updateChartData,
} from '../../api/investor/investorHomeApi';

import {
  trackEvent,
} from '../../api/mixpanelApi';

const mapStateToProps = state => ({
  investorHome: state.investorHome,
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  setTimeAgo,
  removeItem,
  createFund,
  deleteFund,
  getChartData,
  getDashboardData,
  getCardsData,
  getUpdatesData,
  getDealRoomsData,
  getDocumentsData,
  updateChartData,
  trackEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestorHome);
