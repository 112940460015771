import React, { Component } from 'react';


class Kpi extends Component {
  selectKpi(index) {
    this.props.selectKpi(this.props.index);
  }

  render() {
    let active = 'btn-chart';
    const { kpi } = this.props;
    if (kpi.selected) {
      active = 'btn-chart active';
    }

    return (
      <div className={active} onClick={this.selectKpi}>
        <span className="chart__img-container">
          <img src={kpi.thumb} alt="thumb" />
        </span>
        <p>{kpi.name}</p>
      </div>
    );
  }
}

export default Kpi;
