import React from 'react';

import SorterHeader from '../SorterHeader';


const CompanyDataRoomHeader = () => (
  <div className="col-12 text-gray h5 flex px2 bg-extra-light-gray">
    <div className="col-4 py1 bold pl2 sm-col-6 sm-pl0">
      <SorterHeader text="Company" field="company_name" />
    </div>
    <div className="col-2 py1 bold pl2 sm-hide">
      <SorterHeader text="Pitch Deck" field="first_file" />
    </div>
    <div className="col-2 py1 bold pl2 sm-hide">
      <SorterHeader text="Business Plan" field="second_file" />
    </div>
    <div className="col-2 py1 bold pl2 sm-hide">
      <SorterHeader text="Annual Report" field="third_file" />
    </div>
    <div className="col-2 py1 bold pl2 sm-hide">
      <SorterHeader text="Captable" field="fourth_file" />
    </div>
  </div>
);

export default CompanyDataRoomHeader;
