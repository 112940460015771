import React from 'react';
import PropTypes from 'prop-types';

import ConnectWithOthers from '../ConnectWithOthers';
import InvestorsAndCompaniesSearch from '../utils/InvestorsAndCompaniesSearch';
import Avatar from '../../tab_view/Avatar';

class CompanyConnectWithOthers extends ConnectWithOthers {
  static propTypes = {
    addedUsers: PropTypes.array,
    newInvestors: PropTypes.array,
    existingInvestors: PropTypes.array,
    confirmedUser: PropTypes.string.isRequired,
  }

  static defaultProps = {
    addedUsers: [],
    newInvestors: [],
    existingInvestors: [],
  }

  constructor(props) {
    super(props);
    const { newInvestors, existingInvestors } = props;

    this.state = {
      invitedUsers: [...newInvestors, ...existingInvestors],
    };
  }

  render() {
    const { addedUsers, confirmedUser } = this.props;
    const { invitedUsers } = this.state;

    return (
      <div className="flex flex-column">

        <div className="flex flex-justify-start text-light-gray fw400 pb1 mt1">
          Name or Email
        </div>

        <InvestorsAndCompaniesSearch
          searchUrl="/onboarding/investors/step/2"
          resultSelect={result => this.handleResult(result)}
        />
        {!(addedUsers.length || invitedUsers.length) &&
          <div className="flex flex-column flex-justify-start text-light-gray pb1 py1 mt1">
            <div className="flex flex-justify-start text-light-gray fw400 pb1">
              Your Investor(s)
            </div>
            {confirmedUser ?
              <div className="flex flex-justify-start">
                No investors added yet...
              </div>
              :
              <div
                className="flex flex-justify-start items-center text-orange"
                data-balloon="Check your email account! You should have received a email with a link to verify that you have access to this email address."
                data-balloon-pos="down"
                data-balloon-length="large"
              >
                <i className="fa fa-warning mr1" />
                <span className="left-align fw400">Confirm your email adress first...</span>
              </div>
            }
          </div>
        }
        {invitedUsers.length > 0 &&
          <div className="flex flex-justify-start flex-column text-light-gray fw400 mt2">
            <div className="flex flex-justify-start pb1">
              Your Investor(s)
            </div>
            <div className="flex flex-column">
              {invitedUsers.map(invitedUser => (
                <div
                  key={invitedUser.id || invitedUser.email}
                  className="flex items-center flex-justify-between mb1 col-12"
                  data-type="invited_users"
                >
                  <div className="flex items-center col-8">
                    <Avatar avatarUrl={invitedUser.avatar || window.images.userGray} />
                    <span className="ml1 light truncate">{invitedUser.name || invitedUser.email}</span>
                  </div>
                  <div
                    className="flex flex-justify-center items-center col-3 fs-10 lh-2 bg-alto text-white rounded px05 mt05"
                    data-balloon={invitedUser.avatar ? 'A connection request will be sent after you complete sign-up. Access rights can be given later.' : 'An invite to connect will be sent after you complete sign-up. Additional access rights can be given later.'}
                    data-balloon-pos="down"
                    data-balloon-length="medium"
                  >
                    <span className="text-upper ">{invitedUser.avatar ? 'Request Pending' : 'Invite Pending'}</span>
                  </div>
                  <div
                    className="flex flex-justify-end cursor-pointer col-1"
                    onClick={() => this.removeInvitedUser(invitedUser)}
                  >
                    <i className="fa fa-close text-red" />
                  </div>
                </div>
              ))}
            </div>
            {invitedUsers.filter(invitedUser => invitedUser.id).map(invitedUser => (
              <input key={invitedUser.id || invitedUser.email} type="hidden" name="existing_invited_users[]" multiple="multiple" value={invitedUser.id || invitedUser.email} />
            ))}
            {invitedUsers.filter(invitedUser => invitedUser.email).map(invitedUser => (
              <input key={invitedUser.id || invitedUser.email} type="hidden" name="new_invited_users[]" multiple="multiple" value={invitedUser.id || invitedUser.email} />
            ))}
          </div>
        }

        {addedUsers.length > 0 &&
          <div className="flex flex-justify-start flex-column text-light-gray fw400 mt2">
            <div className="flex flex-justify-start pb1">
              Added Investors
            </div>
            <div className="flex flex-column">
              {addedUsers.map(addedUser => (
                <div
                  key={addedUser.name}
                  className="flex items-center mb1"
                  data-type="added_users"
                >
                  <Avatar
                    avatarUrl={addedUser.avatar}
                  />
                  <span className="ml1 bold text-gray">{addedUser.name}</span>
                </div>
              ))}
            </div>
          </div>
        }
      </div>
    );
  }
}

export default CompanyConnectWithOthers;
