import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Avatar from '../../tab_view/Avatar';
// import FundEditButton from '../../tab_view/fund/FundEditButton';
import ConfirmationModal from '../../ConfirmationModal';

export default class FundCard extends Component {
  static propTypes = {
    fund: PropTypes.object.isRequired,
    investorId: PropTypes.number.isRequired,
    themeColor: PropTypes.string.isRequired,
    admin: PropTypes.bool.isRequired,
    deleteFund: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      cardHover: false,
      buttonHover: false,
    };
  }

  deleteFund = async () => {
    const { deleteFund, fund } = this.props;

    try {
      await deleteFund(fund.id);
      App.State.setFlash({ name: 'alert', msg: 'Deleted with Success!' });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
    }
  }

  toggleCardHover = () => {
    const { cardHover } = this.state;

    this.setState({ cardHover: !cardHover });
  }

  toggleButtonHover = () => {
    const { buttonHover } = this.state;

    this.setState({ buttonHover: !buttonHover });
  }

  render() {
    const { fund, investorId, admin, themeColor } = this.props;
    const { open, cardHover, buttonHover } = this.state;

    let hoverCardBorderColor = {};
    let hoverTextColor = { color: '#fff' };
    if (cardHover) {
      hoverCardBorderColor = { borderColor: themeColor };
      hoverTextColor = { color: '#adadad' };
    }

    let hoverButtonTextColor = {};
    if (buttonHover) {
      hoverButtonTextColor = { color: themeColor };
    }

    const dashboardUrl = fund.all_invesments_card ? `/investor/${investorId}/funds/all_investments` : `/investor/${investorId}/funds/${fund.id}`;

    return (
      <div
        className="mr2 fex flex-column bg-white bs-around border-top border-light-gray hover bw-2 pb0 fund-card relative px1 card--has-hover-shadow-light-gray__small"
        onMouseEnter={() => { this.toggleCardHover(); }}
        onMouseLeave={() => { this.toggleCardHover(); }}
        style={{ minWidth: '180px', ...hoverCardBorderColor }}
      >
        {!fund.all_invesments_card &&
          <div className="flex flex-justify-end mt2 pr1 text-light-gray relative cursor-pointer" onClick={() => { this.setState({ open: !open }); }}>
            <div className="flex flex-justify-center cursor-pointer z1 relative">
              <i className="fa fa-ellipsis-h" />
              <div className={`dropdown dropdown--center absolute bg-white border border-alto bs-dropdown top-20 left-50 transform-horizontal-center z1 ${open ? '' : 'hidden'} py1`}>
                <ul className="left-align m0 mw150">
                  <li>
                    <div
                      className={`flex flex-row fbold h5 block nowrap px1 ${admin ? 'text-gray cursor-pointer link-hover--blue ' : 'text-light-gray'}`}
                    >
                      <i className="fa fa-fw fa-pencil fa--dropdown" />
                      <span className="ml1 bold">Edit</span>
                      {/* <FundEditButton manage_url={manage_url} name={name} /> */}
                    </div>
                  </li>
                  <li>
                    <ConfirmationModal
                      html={`<div class="flex flex-row bold h5 block nowrap px1 ${admin ? 'text-gray cursor-pointer link-hover--blue ' : 'text-light-gray'}"><i class="fa fa-fw fa-trash fa--dropdown" /><span class="ml1 bold">Delete</span></div>`}
                      className=""
                      onClick={this.deleteFund}
                      message="<b>You are about to permanently delete this fund. All fund associated portfolio companies will be visible in all other funds until a new fund is chosen.</b><br/><b>Also the Data Room associated to this fund will get deleted.</b><br/><br/>Are you sure you want to delete?"
                      width=""
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        }
        <a href={dashboardUrl}>
          <div className={`flex flex-justify-center relative ${fund.all_invesments_card ? 'pt3' : ''}`} style={{ top: '-15px' }}>
            <Avatar avatarUrl={fund.logo} size={40} shadow />
          </div>

          <div className="flex flex-justify-center h3 fw400 pb1 text-gray center">
            {fund.name}
          </div>

          <div
            className="flex flex-justify-center h1 bold text-light-gray"
            data-balloon={`${fund.current_post_money ? 'The total Post Money value of all investments in this portfolio.' : 'No Post-Money Valuation for portfolio companies available.'}`}
            data-balloon-pos="top"
            data-balloon-length="medium"
          >
            {fund.current_post_money || '-'}
          </div>

          <div
            className="flex flex-justify-center h6 fw400 mt05 pb1 center mb1"
            style={hoverTextColor}
          >
            {fund.total_investments ?
              (
                fund.total_investments !== 1 ?
                  `(${fund.total_investments} companies)`
                  :
                  `(${fund.total_investments} company)`
              )
              :
              '(0 companies)'
            }
          </div>
        </a>
      </div>
    );
  }
}
