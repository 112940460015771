import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import NumberInputWithNegative from '../NumberInputWithNegative';
import { defaultSelectStyles, defaultSelectTheme } from '../selects/SelectStyles';


class ChartOptions extends Component {
  static propTypes = {
    chart_title: PropTypes.string,
    show_markers: PropTypes.bool,
    show_data_labels: PropTypes.bool,
    values_as_percentage: PropTypes.bool,
    y_scaling: PropTypes.string,
    tick_start: PropTypes.number,
    tick_end: PropTypes.number,
    show_comma_values: PropTypes.bool,
    show_human_readable: PropTypes.bool,
    last_data_info: PropTypes.bool,
    last_data_percentage: PropTypes.bool,
    isNumberKpi: PropTypes.func,
    isFunnelKpi: PropTypes.func,
    isTableKpi: PropTypes.func,
    setStateInKpiSection: PropTypes.func,
    kpi_charts: PropTypes.array,
  }

  constructor(props) {
    super(props);

    const { y_scaling } = this.props;

    this.state = {
      y_scaling_options: [{ value: 'automatic', label: 'Automatic' }, { value: 'min/max', label: 'Min/Max range' }],
      disabledInterval: y_scaling === 'automatic',
    };
  }

  setTick = (whichTick, val) => {
    const { setStateInKpiSection } = this.props;
    const tick = {};

    tick[whichTick] = parseInt(val, 10);
    setStateInKpiSection(tick);
  }

  setTitle = (val) => {
    const { setStateInKpiSection } = this.props;

    const kpi_charts = this.updatedKpiCharts('chart_title', val);
    setStateInKpiSection({ chart_title: val, useDefaultTitle: false, kpi_charts });
  }

  setValue = (field) => {
    const { setStateInKpiSection } = this.props;
    const kpi_charts = this.updatedKpiCharts(field, !this.props[field]);

    setStateInKpiSection({ [field]: !this.props[field], kpi_charts });
  }

  setYScaling = (val) => {
    const { setStateInKpiSection } = this.props;
    const isAutomatic = val === 'automatic';

    this.setState({ disabledInterval: isAutomatic });
    let kpiCharts = this.updatedKpiCharts('y_scaling', val);
    setStateInKpiSection({ y_scaling: val, kpi_charts: kpiCharts });

    if (isAutomatic) {
      kpiCharts = this.updatedKpiCharts('tick_start', null);
      kpiCharts = this.updatedKpiCharts('tick_end', null);
      setStateInKpiSection({ tick_start: null, tick_end: null, kpi_charts: kpiCharts });
    }
  }

  isMinMax = () => (this.props.y_scaling === 'min/max')

  isNumberOrFunnelKpi = () => (
    this.props.isNumberKpi() || this.props.isFunnelKpi()
  )

  isNumberOrFunnelOrTableKpi = () => (
    this.props.isNumberKpi() || this.props.isFunnelKpi() || this.props.isTableKpi()
  )

  updatedKpiCharts = (key, value) => {
    const { kpi_charts } = this.props;
    const updatedKpiCharts = [];

    Object.assign(updatedKpiCharts, kpi_charts);
    updatedKpiCharts.forEach(kpi_chart => kpi_chart[key] = value);

    return updatedKpiCharts;
  }

  renderNumberKpiShowDateOption = () => (
    <div className="center col-4 pl2" style={{ fontSize: '16px' }}>
      <input
        type="checkbox"
        onChange={() => this.setValue('last_data_info')}
        checked={this.props.last_data_info}
      />
      <div
        href="#"
        onClick={(e) => { e.preventDefault(); this.setValue('last_data_info'); }}
        className="ml1 text-black cursor-pointer"
      >
        Show Date
      </div>
    </div>
  )

  renderNumberKpiShowPercentageOption = () => (
    <div className="center col-4 pl2" style={{ fontSize: '16px' }}>
      <input
        type="checkbox"
        onChange={() => this.setValue('last_data_percentage')}
        checked={this.props.last_data_percentage}
      />
      <div
        href="#"
        onClick={(e) => { e.preventDefault(); this.setValue('last_data_percentage'); }}
        className="ml1 text-black cursor-pointer"
      >
        Percentage Change
      </div>
    </div>
  )

  render() {
    const {
      isTableKpi, chart_title, isNumberKpi, isFunnelKpi, values_as_percentage,
      show_human_readable, show_comma_values, show_markers, show_data_labels,
      y_scaling, tick_start, tick_end,
    } = this.props;

    const { y_scaling_options, disabledInterval } = this.state;

    return (
      <div className={`px3 pt1 border-top ${this.isNumberOrFunnelKpi() ? 'border-bottom' : ''}`} style={{ borderColor: '#bcc7cf', backgroundColor: '#ededed' }}>
        <p className="h5 mb1 text-light-gray">Chart title</p>
        <div className={`h5 ${isTableKpi() ? '' : 'mb2'} relative flex flex-center`}>
          <div className={`col-4 ${isTableKpi() ? 'pb2' : ''}`}>
            <input
              type="text"
              value={chart_title}
              onChange={val => this.setTitle(val.target.value)}
              placeholder="Title"
              style={{ width: '100%', height: '40px', borderRadius: '4px', borderColor: '#bcc7cf' }}
              className="border rounded"
            />
          </div>
          {
            isNumberKpi() && this.renderNumberKpiShowDateOption()
          }
          {
            isNumberKpi() && this.renderNumberKpiShowPercentageOption()
          }
          {
            isFunnelKpi() &&
            (
              <div className="center col-4 pl2" style={{ fontSize: '16px' }}>
                <input
                  type="checkbox"
                  onChange={() => this.setValue('values_as_percentage')}
                  checked={values_as_percentage}
                />
                <div
                  href="#"
                  onClick={(e) => { e.preventDefault(); this.setValue('values_as_percentage'); }}
                  className="ml1 text-black cursor-pointer"
                >
                  Values as percentage
                </div>
              </div>
            )
          }
          {
            isTableKpi() &&
            (
              <div className={`center col-4 pl2 pb2 ${show_human_readable ? 'opacity-35' : ''}`} style={{ fontSize: '16px' }}>
                <input
                  type="checkbox"
                  onChange={() => this.setValue('show_comma_values')}
                  checked={show_comma_values}
                  disabled={show_human_readable}
                />
                <div
                  onClick={(e) => { e.preventDefault(); if (!show_human_readable) this.setValue('show_comma_values'); }}
                  className="ml1 text-black cursor-pointer"
                >
                  Show decimal places
                </div>
              </div>
            )
          }
          {
            isTableKpi() &&
            (
              <div className={`center col-4 pl2 pb2 ${show_comma_values ? 'opacity-35' : ''}`} style={{ fontSize: '16px' }}>
                <input
                  type="checkbox"
                  onChange={() => this.setValue('show_human_readable')}
                  checked={show_human_readable}
                  disabled={show_comma_values}
                />
                <div
                  href="#"
                  onClick={(e) => { e.preventDefault(); if (!show_comma_values) this.setValue('show_human_readable'); }}
                  className="ml1 text-black cursor-pointer"
                >
                  Number shortening
                </div>
              </div>
            )
          }
          {
            !this.isNumberOrFunnelOrTableKpi() &&
            (
              <div className="center col-4 pl2" style={{ fontSize: '16px' }}>
                <input
                  type="checkbox"
                  onChange={() => this.setValue('show_markers')}
                  checked={show_markers}
                />
                <div
                  href="#"
                  onClick={(e) => { e.preventDefault(); this.setValue('show_markers'); }}
                  className="ml1 text-black cursor-pointer"
                >
                  Show markers
                </div>
              </div>
            )
          }
          {
            !isNumberKpi() && !isTableKpi() &&
            (
              <div className="center col-4 pl3" style={{ fontSize: '16px' }}>
                <input
                  type="checkbox"
                  onChange={() => this.setValue('show_data_labels')}
                  checked={show_data_labels}
                />
                <div
                  href="#"
                  onClick={(e) => { e.preventDefault(); this.setValue('show_data_labels'); }}
                  className="ml1 text-black cursor-pointer"
                >
                  Show data labels
                </div>
              </div>
            )
          }
        </div>
        {
          !this.isNumberOrFunnelOrTableKpi() &&
          (
            <div>
              <div className="flex">
                <p className="h5 mb1 text-light-gray light">Y-Axis scaling</p>
                {
                  this.isMinMax() &&
                  <span
                    className="btn btn-primary col-1 ml1"
                    data-balloon="Set the range of data to display in the chart. The entire axis will not be allowed to span under or over this interval - even if there is no data available."
                    data-balloon-pos="down"
                    data-balloon-length="xlarge"
                  >
                    <i className="fa text-blue fa-question-circle-o fs-18" />
                  </span>
                }
              </div>
              <div className="h5 relative flex flex-justify-between sm-flex sm-flex-column">
                <div className="h5 left-align regular mb2 relative col-4 sm-col-12 light">
                  <ReactSelect
                    name="kpi_chart[y_scaling]"
                    value={y_scaling_options.filter(option => option.value === y_scaling)}
                    options={y_scaling_options}
                    onChange={selectedOption => this.setYScaling(selectedOption.value)}
                    isClearable={false}
                    isMulti={false}
                    placeholder="Automatic"
                    styles={defaultSelectStyles}
                    theme={defaultSelectTheme}
                  />
                </div>
                <div className={`col-3 sm-col-12 sm-ml0 italic ${disabledInterval ? 'opacity-40' : ''}`}>
                  <NumberInputWithNegative
                    placeholder="From"
                    value={tick_start}
                    onChange={val => this.setTick('tick_start', val.target.value)}
                    disabled={disabledInterval}
                    style={{ height: '40px', borderRadius: '4px', width: '-webkit-fill-available', borderColor: '#bcc7cf' }}
                    className="col-12 border rounded"
                  />
                </div>
                <div className={`col-3 sm-col-12 sm-ml0 italic ${disabledInterval ? 'opacity-40' : ''}`}>
                  <NumberInputWithNegative
                    placeholder="To"
                    value={tick_end}
                    onChange={val => this.setTick('tick_end', val.target.value)}
                    disabled={disabledInterval}
                    style={{ height: '40px', borderRadius: '4px', width: '-webkit-fill-available', borderColor: '#bcc7cf' }}
                    className="col-12 border rounded"
                  />
                </div>
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

export default ChartOptions;
