const permittedKpis = genericKpis => genericKpis.filter(genericKpi => !genericKpi.disabled);
const kpiIds = genericKpis => genericKpis.map(kpi => kpi.id);

const kpisLibrary = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_GENERIC_KPI':
      return {
        ...state,
        selectedKpis: [
          ...state.selectedKpis,
          {
            id: action.genericKpi.id,
            metric: action.genericKpi.metric,
            used: action.genericKpi.used,
            unit: action.genericKpi.unit,
            aggregation_type: action.genericKpi.aggregation_type,
            use_for_benchmarking: !action.genericKpi.used,
          },
        ],
      };

    case 'REMOVE_GENERIC_KPI':
      return {
        ...state,
        // selectedKpis: state.selectedKpis.filter(selectedGenericKpiId => selectedGenericKpiId !== action.genericKpi.id),
        selectedKpis: state.selectedKpis.filter(selectedGenericKpi => selectedGenericKpi.id !== action.genericKpi.id),
      };

    case 'RESET_GENERIC_KPIS_SELECTION':
      return {
        ...state,
        selectedKpis: [],
      };

    case 'UPDATE_FREQUENCY':
      return {
        ...state,
        frequency: action.frequency,
        // categories: action.data.categories,
        // selectedKpis: state.selectedKpis,
        // selectedKpis: state.selectedKpis.filter(selectedGenericKpiId => action.data.categories.map(category => kpiIds(permittedKpis(category.kpis))).flat().includes(selectedGenericKpiId)),
      };

    case 'CREATE_GENERIC_KPIS':
      return {
        ...state,
        selectedKpis: state.selectedKpis.map(selectedGenericKpi => (
          action.data ?
            (
              { ...action.data.generic_kpis.filter(kpi => kpi.id === selectedGenericKpi.id) }
            )
            :
            { ...selectedGenericKpi }
        )),
      };

    case 'CHANGE_GENERIC_KPI_VALUES':
      return {
        ...state,
        selectedKpis: state.selectedKpis.map(genericKpi => (
          action.genericKpi.id === genericKpi.id ?
            {
              ...genericKpi,
              [action.field]: action.value,
            }
            :
            { ...genericKpi }
        )),
      };

    case 'UPDATE_USED_GENERIC_KPIS':
      return {
        ...state,
        categories: state.categories.map(category => (
          {
            ...category,
            kpis: category.kpis.map(genericKpi => (
              {
                ...genericKpi,
                used: action.usedGenericKpiIds.includes(genericKpi.id),
              }
            )),
          }
        )),
      };

    default: return state;
  }
};

export default kpisLibrary;
