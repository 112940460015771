import Highcharts from 'highcharts';
import loadFunnel from 'highcharts/modules/funnel';
import numberFormat from '../user_number_format';

loadFunnel(Highcharts);

export default function createFunnelChart(options) {
  const generateSeries = () => {
    const data = [];
    options.individualOptions.forEach((option) => {
      const actual = { name: `${option.name} (Actual)`, y: Math.abs(parseFloat(option.actual_value)), label: parseFloat(option.actual_value) };
      const plan = { name: `${option.name} (Plan)`, y: Math.abs(parseFloat(option.plan_value)), label: parseFloat(option.plan_value) };
      option.show_actual_data ? data.push(actual) : null;
      option.show_plan_data ? data.push(plan) : null;
    });
    return [{ name: 'Value', data }];
  };

  const calculateTotal = () => {
    let total = 0;
    options.individualOptions.forEach((option) => {
      if (option.show_actual_data) {
        if (option.actual_value) total += Math.abs(parseFloat(option.actual_value));
      }
      if (option.show_plan_data) {
        if (option.plan_value) total += Math.abs(parseFloat(option.plan_value));
      }
    });
    return total;
  };

  const percentageCalc = (value, total) => {
    return (100 * value) / total;
  };

  const valuesAsPercentage = () => {
    const data = [];
    const total = calculateTotal();
    options.individualOptions.forEach((option) => {
      if (option.show_actual_data) {
        if (option.actual_value) {
          const actualValue = percentageCalc(Math.abs(parseFloat(option.actual_value)), total);
          const actual = { name: `${option.name} (Actual)`, y: parseFloat(actualValue.toFixed(2)), label: parseFloat(actualValue.toFixed(2)) };
          data.push(actual);
        }
      }
      if (option.show_plan_data) {
        if (option.plan_value) {
          const planValue = percentageCalc(Math.abs(parseFloat(option.plan_value)), total);
          const plan = { name: `${option.name} (Plan)`, y: parseFloat(planValue.toFixed(2)), label: parseFloat(planValue.toFixed(2)) };
          data.push(plan);
        }
      }
    });
    return [{ name: 'Value', data }];
  };

  const series = options.values_as_percentage ? valuesAsPercentage() : generateSeries();
  const percentage = options.values_as_percentage ? ' %' : '';

  Highcharts.setOptions({
    lang: numberFormat().getNumberFormatKpiChart(),
  });

  return Highcharts.chart({
    chart: {
      renderTo: options.selector,
      animation: false,
      type: 'funnel',
    },
    title: {
      text: options.chart_title ? options.chart_title : null,
    },
    tooltip: {
      valueDecimals: 2,
      valueSuffix: percentage,
    },
    plotOptions: {
      series: {
        center: ['30%', '50%'],
        neckWidth: '30%',
        neckHeight: '25%',
        width: '60%',
        dataLabels: {
          format: `<b>{point.name}</b>: {point.label:,.2f}${percentage}`,
          enabled: options.show_data_labels,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series,
  });
}
