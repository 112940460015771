import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProFeatures from './ProFeatures';
import Switch from '../../Switch';
import ConfirmationModal from '../../modals/ConfirmationModal';


class SubscriptionDetails extends Component {
  static propTypes = {
    subscription: PropTypes.object,
    plans: PropTypes.object.isRequired,
    subscribe: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,
    resubscribe: PropTypes.func.isRequired,
  }

  static defaultProps = {
    subscription: null,
  }

  state = {
    hover: false,
    saving: false,
    monthlyPayments: true,
    confirmationModal: null,
  }

  hideConfirmationModal = () => this.setState({ confirmationModal: null });

  subscribe = async planId => {
    const { subscribe } = this.props;
    this.setState({ saving: true });

    try {
      await subscribe({ subscription: { plan_id: planId } });
      App.State.setFlash({ name: 'notice', msg: 'Congratulations! Subscribed successfully.' });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
    }
    this.setState({ saving: false });
  }

  unsubscribe = async () => {
    const { unsubscribe } = this.props;
    this.setState({ saving: true });

    try {
      await unsubscribe();
      App.State.setFlash({ name: 'notice', msg: 'Unsubscribed successfully.' });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
    }
    this.setState({ saving: false });
  }

  reactivateSubscription = async () => {
    const { resubscribe } = this.props;
    this.setState({ saving: true });

    try {
      await resubscribe();
      App.State.setFlash({ name: 'notice', msg: 'Congratulations! Resubscribed successfully.' });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.errors });
    }
    this.setState({ saving: false });
  }

  renderPricingCards = () => {
    const { plans: { monthly, yearly } } = this.props;
    const { saving, monthlyPayments, confirmationModal } = this.state;

    return (
      <div className="bg-white py2 mx2 fw400">
        <div className="flex flex-justify-between mb2">
          <span className="h3 text-gray">Choose a Plan</span>
          <div className="h4">
            <Switch
              labelLeft="Monthly"
              labelRight="Yearly"
              onChange={() => this.setState({ monthlyPayments: !monthlyPayments })}
            />
          </div>
        </div>
        <div className="bg-white bs-around p2 fw400">
          <div className="flex flex-justify-center">
            <div className="col-6">
              <ProFeatures />
            </div>
            {monthlyPayments ?
              <div className="col-6 flex flex-column flex-justify-center ml1">
                <div className="col-12">
                  <div className="col-12 center py2 pt3">
                    <div className="h1 light">€{monthly.price} <span className="h4">/ {monthly.interval}</span></div>
                    <div className="flex flex-justify-center">
                      {saving ?
                        <img src={window.images.loading} alt="Subscribing..." width="32" height="32" className="mt3 mb1" />
                        :
                        <div
                          className="border border-green text-white bg-green hover hover-text-green hover-bg-white px2 py1 mt3 mb1 rounded bs-around cursor-pointer"
                          onClick={() => this.setState({ confirmationModal: monthly.id })}
                        >
                          Upgrade Now
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className="col-6 flex flex-column flex-justify-center ml1">
                <div className="col-12">
                  <div className="col-12 center py2 pt3">
                    <div className="h1 light">€{yearly.price} <span className="h4 light">/ {yearly.interval}</span></div>
                    <div className="flex flex-justify-center">
                      {saving ?
                        <img src={window.images.loading} alt="Subscribing..." width="32" height="32" className="mt3 mb1" />
                        :
                        <div
                          className="border border-green text-white bg-green hover hover-text-green hover-bg-white px2 py1 mt3 mb1 rounded bs-around cursor-pointer"
                          onClick={() => this.setState({ confirmationModal: yearly.id })}
                        >
                          Upgrade Now
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          <ConfirmationModal
            show={!!confirmationModal}
            closeModal={this.hideConfirmationModal}
            title="You are about to upgrade to a Pro Plan"
            text="Are you sure you want to upgrade (Your card will be charged immediately)?"
            onSubmit={() => this.subscribe(confirmationModal)}
            confirmationText="Subscribe Now"
          />
        </div>
      </div>
    );
  }

  render() {
    const { subscription } = this.props;
    const { hover, saving } = this.state;

    if (!subscription) return this.renderPricingCards();

    return (
      subscription.canceled_at ?
        <div className="flex py2 mx2 text-gray border-bottom border-gallery h5">
          <div className="col-3 text-light-gray">Your Plan</div>
          <div className="col-8 fw400">{subscription.title} subscription ends on {subscription.current_period_end}</div>
          <div className="col-1 flex flex-justify-end">
            {saving ?
              <img src={window.images.loading} alt="Resubscribing..." width="24" height="24" />
              :
              <div
                onMouseEnter={() => this.setState({ hover: true })}
                onMouseLeave={() => this.setState({ hover: false })}
              >
                {hover ?
                  <span className="text-blue cursor-pointer" onClick={this.reactivateSubscription}>Reactivate</span>
                  :
                  <span className="text-red">Cancelled</span>
                }
              </div>
            }
          </div>
        </div>
        :
        <div className="mx2">
          <div className="flex text-gray pt2 pb1 h5">
            <div className="col-3 text-light-gray">Current Plan</div>
            <div className="col-8 fw400">{subscription.title}, billed {subscription.interval}ly</div>
            <div className="col-1 flex flex-justify-end">
              {saving ?
                <img src={window.images.loading} alt="Unsubscribing..." width="24" height="24" />
                :
                <div className="text-blue cursor-pointer" onClick={this.unsubscribe}>
                  Unsubscribe<sup className="h6 text-light-gray">*</sup>
                </div>
              }
            </div>
          </div>
          <div className="flex flex-justify-end text-light-gray h6 mb1 light border-bottom border-gallery">*If you unsubscribed, the subscription will not end until the end of the billed period.</div>
        </div>
    );
  }
}

export default SubscriptionDetails;
