import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ToTemplateButton from './ToTemplateButton';
import LinkShareButton from '../shareholder_update/LinkShareButton';
import DropdownMenu from '../DropdownMenu';


class InvestorUpdate extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  commentsCount = () => {
    const { data } = this.props;
    if (data.comments_count > 0) {
      return (
        <div className="h6">
          <i className="fa fa-comment-o mr1" />
          {data.comments_count} Comments
        </div>
      );
    }
    return null;
  }

  displayAttachments = () => {
    const { data } = this.props;

    const attachments = data.attachments.map((item) => (
      <a key={`${item.key}`} className="cursor-pointer mr1 text-medium-gray" href={item.url} data-balloon={item.file_name}><i className="fa fa-file-text-o" /></a>
    ));

    if (data.attachements_count > 0) {
      return (
        <div className={`${data.is_investor ? 'col-4' : 'col-2'} sm-hide pl3`}>
          <div className="">{data.attachements_count} files attached</div>
          <div className="flex">{attachments}</div>
        </div>
      );
    }
    return (<div className="col-2 sm-hide" />);
  }

  displayButton = () => {
    const { data } = this.props;

    if (!data.update_can_get_deleted) {
      return null;
    }
    if (!data.published) {
      return (
        <li className="flex">
          <a className="col-12 text-gray" data-method="delete" href={data.destroy_url}>
            <i className="mx1 fa fa-fw fa-trash" /> Delete
          </a>
        </li>
      );
    }
    return (
      <LinkShareButton
        url={data.shareable_link}
        generateUrl={data.generate_url}
        revokeUrl={data.revoke_url}
        expiry={data.expiry}
      />
    );
  }

  displayEdit = () => {
    const { data } = this.props;
    if (!data.published) {
      return (
        <li className="flex">
          <a onClick={e => this.handleClick(e)} className="col-12 text-gray" href={data.edit_url}>
            <i className="mx1 fa fa-fw fa-pencil" /> Edit
          </a>
        </li>
      );
    }
    return (
      <li
        className="text-light-gray"
        data-balloon="Published updates are not editable."
        data-balloon-pos="left"
      >
        <i className="mx1 fa fa-fw fa-pencil" /> Edit
      </li>
    );
  }

  displayMails = () => {
    const { data } = this.props;
    const deliveredPercentage = ((data.recipients_count - data.bounced_count) / data.recipients_count) * 100;
    const readPercentage = (data.read_count / data.recipients_count) * 100;
    if (!data.can_show_update_statistics && data.published) {
      return (
        <div className="col-2 h6 fw400 lg-hide">
          <div className="col-12 flex text-medium-gray">
            <div className="center">Visible to all Investors and Team Members connected with before Dec 18, 2017</div>
          </div>
        </div>
      );
    }
    if (!(data.recipients_count > 0) || !data.published) {
      return (<div className="col-2 flex flex-column lg-hide" />);
    }
    return (
      <div className="col-2 flex flex-column h6 fw400 lg-hide">
        <a href={data.update_statistics_link}>
          <div className="col-12 flex text-medium-gray">
            <div className="col-8">Delivered to</div>
            <div className="flex flex-auto flex-justify-end">{data.recipients_count - data.bounced_count} of {data.recipients_count}</div>
          </div>
          <div className="col-12 border border-alto rounded text-medium-gray" style={{ height: '8px' }}>
            <div className="bg-green rounded" style={{ width: `${deliveredPercentage}%`, height: '6px' }} />
          </div>
          <div className="col-12 flex text-medium-gray">
            <div className="col-8">Read by</div>
            <div className="flex flex-auto flex-justify-end">{data.read_count} of {data.recipients_count}</div>
          </div>
          <div className="col-12 border border-alto rounded text-medium-gray" style={{ height: '8px' }}>
            <div className="bg-blue rounded" style={{ width: `${readPercentage}%`, height: '6px' }} />
          </div>
        </a>
      </div>
    );
  }

  extraInfo = () => {
    const { data } = this.props;
    if (data.published) {
      return (
        <div className="h6 sm-hide">
          {data.sent_text}
        </div>
      );
    }
    return (<div className="h6 sm-hide">Last edited {data.last_modified}.</div>);
  }

  handleClick = (event) => {
    const { data } = this.props;
    const { published } = data;

    if (!published && !App.is_desktop) {
      event.preventDefault();
      alert("Sorry, we currently don't support update creation on small screen sizes...");
      return null;
    }
    return null;
  }

  likesCount = () => {
    const { data } = this.props;
    if (data.likes_count > 0) {
      return (
        <div className="h6 mr1">
          <i className="fa fa-hand-paper-o mr1" />
          {data.likes_count} High Five
        </div>
      );
    }
    return null;
  }

  render() {
    const { data } = this.props;

    return (
      <div className="col-12 flex py1 border-top border-alto text-medium-gray flex-center" style={{ height: '83px' }}>
        <div className={`col-1 sm-col-2 flex flex-column flex-justify-center border-left bw-2 border-${data.is_investor ? 'green' : data.status.color}`} style={{ height: '83px' }}>
          <div className="h2 center text-gray">{data.date_day}</div>
          <div className="text-upper center text-gray">{data.date_month}</div>
        </div>
        <a onClick={e => this.handleClick(e)} className="col-6 sm-col-6 truncate text-light-gray" href={data.url}>
          <div className="fw400 text-gray">{data.name}</div>
          <div className="flex flex-center lh-12">
            <div
              className={`text-alto h6 fw400 border border-alto rounded inline-block px1 text-upper mr1 ${data.target_date ? 'border-blue text-blue' : ''}`}
              style={data.target_date ? { opacity: 0.5 } : {}}
            >
              {data.target_date ? `${data.update_type} ` : 'custom '} update
            </div>
            {this.extraInfo()}
          </div>
          <div className="flex">{this.likesCount()}{this.commentsCount()}</div>
        </a>
        { !data.is_investor && this.displayMails()}
        {this.displayAttachments()}
        { !data.is_investor &&
          <div className="col-2 sm-col-3 flex flex-center">
            <div className={`text-${data.status.color} capitalize`}>
              <i className={`fa fa-${data.status.icon} mr1`} />
              {data.status.text}
            </div>
            <div className="flex flex-justify-end flex-auto mr1 lg-hide">
              <DropdownMenu>
                {this.displayEdit()}
                <ToTemplateButton url={data.template_url} updateId={data.id} />
                <li className="flex">
                  <a className="col-12 text-gray" data-method="post" href={data.clone_url}>
                    <i className="mx1 fa fa-fw fa-copy" /> Clone
                  </a>
                </li>
                {this.displayButton()}
              </DropdownMenu>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default InvestorUpdate;
