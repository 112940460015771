import chartDataStructure from '../../components/investor/modules/chartDataStructure';
import {
  totalValues,
  xirr,
  overviewWidgetData,
} from '../../components/utils/investmentInfoDataCalculations';

import {
  kpiTotalValues,
  kpisWidgetData,
} from '../../components/utils/kpisDataCalculations';

import { structureKpiStateData } from '../utils/homeAndDashboard';

const applyFilteredData = (data, filteredData) => (
  data.filter(d => (
    filteredData.find(fd => fd.id === d.id).selected
  ))
);

const fundDashboard = (state = {}, action) => {
  switch (action.type) {
    case 'SET_TAB':
      return {
        ...state,
        configs: {
          ...state.configs,
          activeTab: action.tabId,
        },
      };
    case 'SET_TIME_AGO':
      return {
        ...state,
        tabProps: {
          ...state.tabProps,
          time_manage: {
            ...state.tabProps.time_manage,
            time_ago: action.tabProps.time_ago,
            frequency: action.tabProps.frequency,
          },
        },
        kpis: {
          ...state.kpis,
          chart_data: {
            ...state.kpis.chart_data,
            chosen_time_frame: action.tabProps.time_ago, // check if it's being used
          },
        },
      };
    case 'SET_LOADING_ON_TAB':
      return {
        ...state,
        loadingTab: action.tabId,
      };

    //
    // BEGIN OF COMPANIES TAB DATA HADLING
    //
    case 'ADD_COMPANY_INFO_DATA': {
      // ADD BASIC DATA TO STATE
      if (action.lazyLoadingLayer === 'basic') {
        return {
          ...state,
          company_info: {
            ...state.company_info,
            data: action.companyInfo.map(companyData => ({ ...companyData })),
            loading: false,
          },
        };
      }

      // merge basic data with remaining data
      const remainingData = state.company_info.data.map(companyData => (
        {
          ...companyData,
          ...action.companyInfo.find(company => company.id === companyData.id),
        }
      ));

      // ADD REMAINING DATA TO STATE
      return {
        ...state,
        company_info: {
          ...state.company_info,
          data: remainingData,
          chart_data: {
            ...state.company_info.chart_data,
            data: chartDataStructure(remainingData, 'company_info', { filter: state.company_info.chart_data.filter, colorTheme: state.configs.color_theme }),
            loading: false,
          },
        },
        widgets: state.widgets.map((widget) => {
          switch (widget.type) {
            case 'map':
              return (
                {
                  ...widget,
                  data: {
                    ...widget.data,
                    map_data: state.configs.show_investments ? remainingData : [],
                    loading: false,
                  },
                }
              );
            case 'portfolio_by_stage':
            case 'portfolio_by_industry': {
              let investmentFields = remainingData.flatMap(data => data.investment_fields);
              investmentFields = investmentFields.filter(field => field.is_category);
              const investmentFieldNames = Array.from(new Set(investmentFields.map(field => field.name)));
              return {
                ...widget,
                data: {
                  ...widget.data,
                  chart_data: chartDataStructure(remainingData, 'company_info', { filter: widget.data.filter, filterType: widget.data.filter_type, colorTheme: state.configs.color_theme, hideData: !state.configs.show_investments }),
                  filter_options: [...widget.data.filter_options, ...investmentFieldNames.map(fieldName => ({ value: fieldName, label: `by ${fieldName}`, custom: true }))],
                  loading: false,
                },
              };
            }
            default:
              return widget;
          }
        }),
      };
    }
    //
    // END OF COMPANIES TAB DATA HANDLING
    //

    //
    // BEGIN OF KPIS TAB DATA HANDLING
    //
    case 'ADD_KPIS_DATA': {
      const filteredData = applyFilteredData(action.kpis.data, state.filterOptions.investment_options);
      const chartData = chartDataStructure(filteredData, 'kpis_info', { kpiMetric: state.kpis.chart_data.chosen_kpi, hideData: !state.configs.show_investments }, true);
      const totals = kpiTotalValues(filteredData, state.kpis.dashboard_kpis);

      return {
        ...state,
        kpis: {
          ...state.kpis,
          data: action.kpis.data,
          total_sum_values: totals.sumValues,
          total_median_values: totals.medianValues,
          chart_data: {
            ...state.kpis.chart_data,
            data: chartData,
            loading: false,
          },
          loading: false,
        },
        widgets: state.widgets.map((widget) => {
          if (widget.type === 'kpis_summary') {
            return (
              {
                ...widget,
                data: {
                  ...widget.data,
                  total_values: kpisWidgetData(totals, state.kpis.dashboard_kpis, state.configs.currency),
                  bottomValue: { title: 'Companies', value: filteredData.length },
                  loading: false,
                },
              }
            );
          }
          return widget;
        }),
      };
    }
    //
    // END OF KPIS TAB DATA HANDLING
    //

    //
    // BEGIN OF DATA ROOMS, FACT SHEET, EXPORT AND SLIDESHOW DATA HANDLING
    //
    case 'ADD_DATA_ROOM_DATA':
      return {
        ...state,
        data_rooms: action.dataRooms,
      };
    case 'ADD_FACT_SHEET_TAB_DATA':
      return {
        ...state,
        reports: {
          ...state.reports,
          fact_sheet: action.factSheetData,
        },
      };
    case 'ADD_EXCEL_EXPORT_TAB_DATA':
      return {
        ...state,
        reports: {
          ...state.reports,
          excel_export: action.excelExportData,
        },
      };
    case 'ADD_Slideshow_TAB_DATA':
      return {
        ...state,
        reports: {
          ...state.reports,
          slideshow: action.slideshowData,
        },
      };
      //
      // END OF DATA ROOMS, FACT SHEET, EXPORT AND SLIDESHOW DATA HANDLING
      //

    //
    // BEGIN OF INVESTMENTS TAB DATA HANDLING
    //
    case 'ADD_INVESTMENT_INFO_DATA': {
      // ADD BASIC DATA TO STATE
      if (action.lazyLoadingLayer === 'basic') {
        return {
          ...state,
          investment_info: {
            ...state.investment_info,
            data: action.investmentInfo.data,
            chart_data: {
              ...state.investment_info.chart_data,
              data: chartDataStructure(action.investmentInfo.data, 'investment_info', { colorTheme: state.configs.color_theme }),
              loading: false,
            },
            loading: false,
          },
        };
      }

      // MERGING UP BASIC DATA WITH REMAINING DATA
      const investmentInfoDashboardData = state.investment_info.data.map(companyData => {
        const investmentInfoData = action.investmentInfo.data.find(company => company.id === companyData.id);
        const completeData = { ...companyData, ...investmentInfoData };
        return {
          ...completeData,
          xirr: xirr(completeData, state.investment_info.show_irr),
        };
      });

      const totals = totalValues(investmentInfoDashboardData, state.investment_info.show_irr, 'dashboard', !state.configs.show_investments);

      // ADD REMAINING DATA TO STATE
      return {
        ...state,
        investment_info: {
          ...state.investment_info,
          data: investmentInfoDashboardData,
          total_values: totals,
        },
        widgets: state.widgets.map((widget) => {
          if (widget.type === 'overview') {
            return (
              {
                ...widget,
                data: {
                  ...widget.data,
                  total_values: overviewWidgetData(totals, action.investmentInfo.data.length, state.investment_info.show_irr, state.configs.currency),
                  currency: state.configs.currency,
                  showWarning: investmentInfoDashboardData.length > 0 && !!investmentInfoDashboardData.find(dataEl => dataEl.has_converted_cashflow),
                  loading: false,
                },
              }
            );
          }
          return widget;
        }),
      };
    }
    //
    // END OF INVESTMENTS TAB DATA HANDLING
    //

    //
    // BEGIN OF UPDATES TAB DATA HANDLING
    //
    case 'ADD_RECENT_UPDATES_DATA':
      return {
        ...state,
        recent_updates: {
          data: action.recentUpdates.data,
          groups: action.recentUpdates.groups,
          loading: false,
        },
      };
    case 'ADD_PORTFOLIO_UPDATES_DATA':
      return {
        ...state,
        portfolio_updates: {
          ...state.portfolio_updates,
          data: action.monthlyUpdates.data,
          groups: action.monthlyUpdates.groups,
          loading: false,
        },
      };
      //
      // END OF UPDATES TAB DATA HANDLING
      //

    //
    // BEGIN OF CO INVESTORS TAB DATA HANDLING
    //
    case 'ADD_CO_INVESTORS_DATA':
      return {
        ...state,
        co_investors: {
          ...action.coInvestors.data,
          loading: false,
        },
      };
      //
      // END OF CO INVESTORS TAB DATA HANDLING
      //

    //
    // BEGIN OF MAIN CHARTS DATA HANDLING
    //
    case 'UPDATE_CHART_FUND_DASHBOARD': {
      switch (action.dashboardType) {
        case 'company_info': return {
          ...state,
          company_info: {
            ...state.company_info,
            chart_data: {
              ...state.company_info.chart_data,
              data: chartDataStructure(applyFilteredData(state.company_info.data, state.filterOptions.investment_options), 'company_info', { filter: action.filterType, colorTheme: state.configs.color_theme }),
              filter: action.filterType,
            },
          },
        };
        case 'investment_info': return {
          ...state,
          investment_info: {
            ...state.investment_info,
            chart_data: {
              data: action.chartData.data,
              right_section_data: action.chartData.right_section_data,
            },
          },
        };
        case 'kpis_info': return {
          ...state,
          kpis: {
            ...state.kpis,
            chart_data: {
              ...state.kpis.chart_data,
              data: action.chartData ? action.chartData.data : chartDataStructure(applyFilteredData(state.kpis.data, state.filterOptions.investment_options), 'kpis_info', { kpiMetric: action.kpiMetric, hideData: !state.configs.show_investments }, true),
              chosen_kpi: action.kpiMetric,
              chosen_kpi_type: action.kpiType,
            },
          },
        };
        default: return state;
      }
    }
    //
    // END OF MAIN CHARTS DATA HANDLING
    //

    //
    // BEGIN OF KPI INFO TABLE AND CHART SIMULTANEOUS UPDATE (FOR NEW CUSTOM KPI SHOWN)
    //
    case 'UPDATE_KPI_TABLE_AND_CHART_FUND_DASHBOARD': {
      // get new kpi custom data and update KPIs info table
      const newKpisState = state.kpis.data.map(tableData => (
        structureKpiStateData(action.chartData.data, tableData)
      ));
      const filteredData = applyFilteredData(newKpisState, state.filterOptions.investment_options);
      // State Update
      return {
        ...state,
        kpis: {
          ...state.kpis,
          data: newKpisState,
          chart_data: {
            ...state.kpis.chart_data,
            data: chartDataStructure(filteredData, 'kpis_info', { kpiMetric: action.kpiMetric, hideData: !state.configs.show_investments }, true),
            chosen_kpi: action.kpiMetric,
            chosen_kpi_type: action.kpiType,
          },
        },
      };
    }
    //
    // END OF KPI INFO TABLE AND CHART SIMULTANEOUS UPDATE
    //

    //
    // BEGIN OF START TAB CHART FILTERS SELECTION
    //
    case 'UPDATE_WIDGET_CHART': return {
      ...state,
      widgets: state.widgets.map(widget => (
        widget.position === action.widgetPosition ?
          {
            ...widget,
            data: {
              ...widget.data,
              filter: action.filter,
              chart_data: chartDataStructure(state.company_info.data, 'company_info', { filter: action.filter, filterType: action.filterType, colorTheme: state.configs.color_theme, hideData: !state.configs.show_investments }),
            },
          }
          :
          { ...widget }
      )),
    };
    //
    // END OF START TAB CHART FILTERS SELECTION
    //

    //
    // BEGIN OF FILTERS DATA HANDLING
    //
    case 'CHANGE_FILTERS': {
      const investmentOptions = state.filterOptions.investment_options.map(company => {
        const matchingCompany = action.selection.find(selectedCompany => selectedCompany.id === company.id);
        if (matchingCompany) {
          return {
            ...company,
            selected: matchingCompany.selected,
          };
        }
        return company;
      });

      let companyInfoDataFiltered,
        investmentInfoDataFiltered,
        kpisDataFiltered,
        investmentTotals,
        kpiTotals;

      // ------------------ check if main states are loaded ------------------
      // When tabs and widgets are not added, they are not in the state
      if (state.company_info) {
        companyInfoDataFiltered = applyFilteredData(state.company_info.data, investmentOptions);
      }

      if (state.investment_info) {
        investmentInfoDataFiltered = applyFilteredData(state.investment_info.data, investmentOptions);
        investmentTotals = totalValues(investmentInfoDataFiltered, state.investment_info.show_irr, 'dashboard', !state.configs.show_investments);
      }

      if (state.kpis) {
        kpisDataFiltered = applyFilteredData(state.kpis.data, investmentOptions);
        kpiTotals = kpiTotalValues(kpisDataFiltered, state.kpis.dashboard_kpis);
      }
      // ------------------ end of check if main states are loaded ------------------

      // ADD FILTER DATA TO STATE & UPDATE ALL INFOS BASED ON CHOSEN FILTERS
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          investment_options: investmentOptions,
        },
        company_info:
          state.company_info ?
            {
              ...state.company_info,
              chart_data: {
                ...state.company_info.chart_data,
                data: chartDataStructure(companyInfoDataFiltered, 'company_info', { filter: state.company_info.chart_data.filter, colorTheme: state.configs.color_theme }),
              },
            }
            :
            null,
        investment_info:
          state.investment_info ?
            {
              ...state.investment_info,
              chart_data: {
                ...state.investment_info.chart_data,
                data: chartDataStructure(investmentInfoDataFiltered, 'investment_info', { colorTheme: state.configs.color_theme }),
              },
              total_values: investmentTotals,
            }
            :
            null,
        kpis:
          state.kpis ?
            {
              ...state.kpis,
              total_sum_values: kpiTotals.sumValues,
              total_median_values: kpiTotals.medianValues,
              chart_data: {
                ...state.kpis.chart_data,
                data: chartDataStructure(kpisDataFiltered, 'kpis_info', { kpiMetric: state.kpis.chart_data.chosen_kpi }),
              },
            }
            :
            null,
        widgets: state.widgets.map((widget) => {
          switch (widget.type) {
            case 'map':
              return (
                {
                  ...widget,
                  data: {
                    ...widget.data,
                    map_data: state.configs.show_investments ? applyFilteredData(state.company_info.data, investmentOptions) : [],
                    loading: false,
                  },
                }
              );
            case 'overview': {
              return {
                ...widget,
                data: {
                  ...widget.data,
                  total_values: overviewWidgetData(investmentTotals, investmentInfoDataFiltered.length, state.investment_info.show_irr, state.configs.currency),
                  currency: state.configs.currency,
                  showWarning: investmentInfoDataFiltered.length > 0 && !!investmentInfoDataFiltered.find(dataEl => dataEl.has_converted_cashflow),
                  loading: false,
                },
              };
            }
            case 'kpis_summary': {
              return {
                ...widget,
                data: {
                  ...widget.data,
                  total_values: kpisWidgetData(kpiTotals, state.kpis.dashboard_kpis, state.configs.currency),
                  bottomValue: { title: 'Companies', value: kpisDataFiltered.length },
                  loading: false,
                },
              };
            }
            default:
              return widget;
          }
        }),
      };
      //
      // END OF FILTERS DATA HANDLING
      //
    }

    case 'UPDATE_REPORT_FILE_DATA': return {
      ...state,
      reports: {
        ...state.reports,
        excel_export: {
          ...state.reports.excel_export,
          data: {
            ...state.reports.excel_export.data,
            file_id: action.fileData.file_id,
            file_generated_on: action.fileData.file_generated_on,
          },
        },
      },
    };

    default: return state;
  }
};

export default fundDashboard;
