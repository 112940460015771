import { connect } from 'react-redux';
import CapTable from '../components/cap_table/CapTable';


const mapStateToProps = state => ({
  editor: false,
  company: state.company,
  capTable: state.capTable,
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(CapTable);
