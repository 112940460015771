import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Textarea from '../Textarea';

import ReportSection from './ReportSection';


class CompanyReport extends Component {
  static propTypes = {
    companyReport: PropTypes.object.isRequired,
    saveReportColumn: PropTypes.func.isRequired,
    saveCompanyReport: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      params: {
        company_name: props.companyReport.company_name || '',
        company_description: props.companyReport.company_description || '',
      },
    };
  }

  handleChange = e => {
    const { name, value } = e.target;

    this.setState(state => ({
      ...state,
      params: {
        ...state.params,
        [name]: value,
      },
    }));
  }

  saveChange = () => {
    const { companyReport, saveCompanyReport } = this.props;
    const { params } = this.state;

    saveCompanyReport(companyReport.investor.id, companyReport.id, params);
  }

  render() {
    const { companyReport: report, saveReportColumn } = this.props;
    const { params } = this.state;
    const inputClasses = classNames('p0 m0 ml1 lh-15 fw400 text-white', {
      'hover border-transparent-white hover-border-alto bg-none': report.edit,
      'border border-transparent': !report.edit,
    });

    return (
      <div className="bg-white bs-around sm-mx1">
        <div className="flex col-12 text-white p2 sm-px1 px3 mb1 rh-print" style={{ backgroundColor: report.header_background_color }}>
          <div className="flex-shrink-none circle avatar--report-company overflow-hidden">
            <img
              className="flex-shrink-none circle avatar--report-company bg-white"
              src={report.company.logo}
              alt={report.company.name}
            />
          </div>

          <div className="flex flex-column col-10 pl1">
            <Textarea
              className={`h2 ${inputClasses} ios-disabled-textarea-text-white`}
              style={{ resize: 'none', backgroundColor: report.header_background_color, color: report.header_text_color }}
              name="company_name"
              value={params.company_name}
              placeholder="Add or edit the Company Name here."
              onChange={e => this.handleChange(e)}
              onBlur={this.saveChange}
              disabled={!report.edit}
            />
            <Textarea
              className={`h4 ${inputClasses} ios-disabled-textarea-text-white`}
              style={{ resize: 'none', backgroundColor: report.header_background_color, color: report.header_text_color }}
              name="company_description"
              value={params.company_description}
              placeholder="Here you add a one sentence company description (optional)."
              onChange={e => this.handleChange(e)}
              onBlur={this.saveChange}
              disabled={!report.edit}
            />
          </div>

          <div className="flex flex-justify-end col-2 sm-hide fw400">
            {report.formatted_shared_at}
          </div>
        </div>
        {report.report_pages.map(page => (
          <div key={`page_${page.id}`} className="px2 sm-px0 rb-print">
            <div className="flex sm-flex-column">
              {page.report_sections.map((section, idx) => {
                if (idx >= 2) return null;
                return (
                  <ReportSection
                    secIdx={idx}
                    key={`section_${section.id}`}
                    section={section}
                    edit={report.edit}
                    investorId={report.investor.id}
                    updateReportColumn={saveReportColumn}
                    headingColor={report.heading_color}
                    subHeadingColor={report.sub_heading_color}
                    stringColor={report.string_color}
                  />
                );
              })}
            </div>
            {page.report_sections.map((section, idx) => {
              if (idx < 2) return null;
              return (
                <ReportSection
                  secIdx={idx}
                  key={`section_${section.id}`}
                  section={section}
                  edit={report.edit}
                  investorId={report.investor.id}
                  updateReportColumn={saveReportColumn}
                  headingColor={report.heading_color}
                  subHeadingColor={report.sub_heading_color}
                  stringColor={report.string_color}
                />
              );
            })}
          </div>
        ))}
      </div>
    );
  }
}

export default CompanyReport;
