import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { validateEmail } from '../utils/validate_email';


class InvestorInviter extends Component {
  static propTypes = {
    inviteUrl: PropTypes.string.isRequired,
    finishUrl: PropTypes.string.isRequired,
    mailPreview: PropTypes.string.isRequired,
    companyId: PropTypes.number.isRequired,
  }

  state = {
    address: '',
    invites: [],
  }

  onInvite = () => {
    const { inviteUrl, companyId } = this.props;
    const { address, invites } = this.state;

    if (!validateEmail(address)) {
      App.State.setFlash({ name: 'alert', msg: 'Enter a valid email address' });
    }
    App.Api.post(inviteUrl, {
      other: companyId,
      type: 'investor',
      email: address,
    }).then(() => {
      const invs = invites;
      invs.push(address);
      App.State.setFlash({ name: 'notice', msg: 'Request email has been sent!' });
      this.setState({
        invites: invs,
        address: '',
      });
    });
    return null;
  }

  createMarkup = text => ({ __html: text });

  handleChange = e => this.setState({ address: e.target.value });

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onInvite();
    }
  }

  /* validateEmail = address => {
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(address);
  }
  */

  render() {
    const { finishUrl, mailPreview } = this.props;
    const { address, invites } = this.state;

    return (
      <div>
        <div className="flex flex-wrap mb3 pb1">
          <div className="flex-grow mr2 relative sm-m0 sm-mb2 sm-col-12">
            <i className="fa fa-envelope fa--onboard-search absolute h3 sm-mt1 sm-top-0 sm-transform-none text-green transform-vertical-center vertical-center" />
            <input
              className="onboard__search-input bg-gray border-none block col-12 fs-18 placeholder-white rounded sm-mb2 text-white"
              type="email"
              value={address}
              placeholder="Invite or send fund request by e-mail"
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              name="email"
            />

            <button
              type="button"
              className="btn-search-invite absolute bg-blue border-none cursor-pointer h4 px2 rounded sm-col-12 sm-static text-white"
              onClick={this.onInvite}
            >
              <i className="fa fa-user-plus mr1" />
              Invite / request
            </button>
          </div>

          <a
            className="btn-finish-onboard bg-green border-none flex flex-center left-align px2 rounded sm-flex-justify-center sm-p2 text-white"
            href={finishUrl}
            onClick={() => { App.mixpanelOnboarding('finished'); }}
            data-method="patch"
          >
            <i className="fa fa-check fa--finish-onboard" />
            <span>Finish <br /> Onboarding</span>
          </a>
        </div>

        <div className="bg-white bs-around mb3 p2">
          <div className="flex mxn2 sm-block">
            <div className="col-5 left-align px2 sm-col-12 sm-p0">
              {invites.map(a => (
                <p key={Math.random()} className="border-bottom border-alto fs-18 mb2 pb2 pl2 pr2 regular text-gray truncate">
                  {a}
                  <br />
                  <span className="text-light-gray h5"><i className="fa fa-check mr1" />Invite sent</span>
                </p>
              ))}
            </div>

            <div className="col-7 px2 relative sm-col-12">
              <div
                className="bs-around"
                dangerouslySetInnerHTML={this.createMarkup(mailPreview)}
              />
              <span className="icon-preview absolute bg-contain bg-no-repeat block bottom-0 left-0 opacity-10 right-0 top-0" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvestorInviter;
