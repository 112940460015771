import { connect } from 'react-redux';
import SetupRequest from '../../components/signing_room/SetupRequest';

import {
  saveRequest,
  sendRequest,
  completeRequest,
  uploadRequestDocument,
} from '../../api/signingRoomRequestApi';

const mapStateToProps = state => ({
  request: state.request,
});

const mapDispatchToProps = dispatch => ({
  saveRequest: (companyId, id, params) => dispatch(saveRequest(companyId, id, params)),
  sendRequest: (companyId, id, params) => dispatch(sendRequest(companyId, id, params)),
  completeRequest: (companyId, id) => dispatch(completeRequest(companyId, id)),
  uploadRequestDocument: (companyId, id, params) => dispatch(uploadRequestDocument(companyId, id, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetupRequest);
