import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Settings from './Settings';
import RecipientStepContacts from './RecipientStepContacts';
import BillingModal from '../../company/billing/BillingModal';


class RecipientStep extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    recipientStep: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    addRecipients: PropTypes.func.isRequired,
    removeRecipients: PropTypes.func.isRequired,
    updateShareholderUpdate: PropTypes.func.isRequired,
    highlightMember: PropTypes.func.isRequired,
  }

  state = {
    showModal: false,
  }

  toogleModal = () => {
    const { showModal } = this.state;

    this.setState({ showModal: !showModal });
    App.trackCompanyEvent('recipient_step', 'upgrade_modal');
  }

  render() {
    const {
      company,
      recipientStep,
      currentUser,
      addRecipients,
      removeRecipients,
      updateShareholderUpdate,
      highlightMember,
    } = this.props;
    const { showModal } = this.state;

    return (
      <div className="container container--main lg-px2 mt3 flex flex-column text-gray flex-wrap mb4">
        <div className="bg-white bs-around rounded">
          <div className="px3 h2 semi-bold border-bottom border-alto" style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}>
            <span>Update Setup</span>
          </div>
          <div className="my3 px3">
            <RecipientStepContacts
              company={company}
              currentUser={currentUser}
              groups={recipientStep.groups}
              contacts={recipientStep.contacts}
              externalUsers={recipientStep.externalUsers}
              addRecipients={(params) => addRecipients(recipientStep.companyId, recipientStep.updateId, params)}
              removeRecipients={(params) => removeRecipients(recipientStep.companyId, recipientStep.updateId, params)}
              highlightMember={(recipientId, params) => highlightMember(recipientStep.companyId, recipientStep.updateId, recipientId, params)}
              toogleModal={this.toogleModal}
            />
          </div>
          <div className="my2 px3">
            <Settings
              updateInvestorUpdate={params => updateShareholderUpdate(recipientStep.companyId, recipientStep.updateId, params)}
              externalUsers={recipientStep.externalUsers}
              investorManaged={company.investor_managed}
              {...recipientStep.settings}
            />
          </div>
        </div>
        <BillingModal
          url={`/company/${company.id}/billing`}
          show={showModal}
          toogleModal={this.toogleModal}
          topic="external_recipient"
        />
      </div>
    );
  }
}

export default RecipientStep;
