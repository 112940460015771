import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import CheckBox from '../../../CheckBox';

class CompanyListFilters extends React.Component {
  static defaultProps = {
    companies: [],
  };

  static propTypes = {
    companies: PropTypes.array,
    onChangeCompanyFilters: PropTypes.func.isRequired,
  };

  handleCheck = id => {
    const {
      companies,
      onChangeCompanyFilters,
    } = this.props;
    const currentFilter = companies.find(company => company.id === id).selected;
    onChangeCompanyFilters([{ id, selected: !currentFilter }]);
  }

  renderListItem = company => (
    <div className="company-list-item normal-item" key={company.id}>
      <div className="check-item">
        <CheckBox
          manualCheck
          checked={company.selected}
          onChange={this.handleCheck.bind(this, company.id)}
        />
        <label>
          <span>{company.name}</span>
        </label>
      </div>
    </div>
  );

  render() {
    const { companies } = this.props;
    return (
      <div className="company-list">
        {companies.map(company => this.renderListItem(company))}
      </div>
    );
  }
}

export default CompanyListFilters;
