import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import HtmlComponent from '../tab_view/HtmlComponent';


class TableKpiPortal extends Component {
  static propTypes = {
    kpi_charts: PropTypes.array,
    id: PropTypes.number,
  }

  fontSize = () => {
    let fontSize = 13;
    const minFontSize = 9;
    const columnsQnt = this.dates().length;
    if (columnsQnt >= 13) {
      fontSize = minFontSize;
    } else if (columnsQnt > 4 && columnsQnt < 13) {
      const percentage = columnsQnt / 13;
      const proportion = minFontSize - (minFontSize * percentage);
      fontSize = (minFontSize + proportion) > 13 ? 13 : (minFontSize + proportion);
    }
    return fontSize;
  }

  padding = () => {
    let padding = '.25rem 1rem';
    const columnsQnt = this.dates().length;
    if (columnsQnt > 4) {
      padding = '.25rem .25rem .25rem 0';
    }
    return padding;
  }

  paddingHeader = () => {
    let padding = '.25rem 1rem';
    const columnsQnt = this.dates().length;
    if (columnsQnt > 4) {
      padding = '.25rem 0 .25rem .35rem';
    }
    return padding;
  }

  parser = (data) => {
    if (typeof data === 'string' || data instanceof String) {
      return JSON.parse(data);
    }
    return data;
  }

  width = () => {
    let width = 64;
    const columnsQnt = this.dates().length;
    if (columnsQnt > 4) {
      width = '1%';
    }
    return width;
  }

  // When composing charts, the longest date timeframe need to be chosen
  dates = () => {
    const { kpi_charts } = this.props;

    let dates = [];
    if (kpi_charts.length > 1) {
      dates = this.parser(_.maxBy(kpi_charts, kpiChart => this.parser(kpiChart.dates).length).dates);
    } else {
      dates = this.parser(kpi_charts[0].dates);
    }
    dates.shift(1);
    return dates;
  }

  datesHeader = () => {
    const { kpi_charts } = this.props;

    const formattedDate = (date) => {
      switch (kpi_charts[0].period_step) {
        case 'years':
          return moment(date).format('YYYY');
        case 'quarters':
          return moment(date).format('[Q]Q, YYYY');
        case 'months':
          return moment(date).format('MMM, YYYY');
        case 'weeks':
          return moment(date).format('w, YYYY');
        case 'days':
          return moment(date).format('MMM D, YYYY');
        default:
          return moment(date).format('MMM, YYYY');
      }
    };

    const datesHeader = this.dates().reverse().map(date =>
      (
        <th className="nowrap fw400 center bg-lighter-gray" style={{ color: '#555555', fontSize: this.fontSize(), width: this.width(), padding: '.25rem .25rem' }}>
          {
            formattedDate(date)
          }
        </th>
      ));
    return (
      <tr>
        <th />
        {datesHeader}
      </tr>
    );
  }

  mapTableRow = (KpiName, values) => {
    let parsedValues = this.parser(values);
    parsedValues.shift(1);
    const cellQntLeft = this.dates().length - parsedValues.length;
    parsedValues = parsedValues.reverse();
    const fillCells = () => {
      const cells = [];
      for (let i = 0; i < cellQntLeft; i += 1) {
        cells.push((<td className="light right-align" style={{ color: '#555555', fontSize: this.fontSize(), padding: this.padding() }}><HtmlComponent data={{ html: '&ndash;' }} /></td>));
      }
      return cells;
    };
    return (
      <tr>
        <td className="fw400 nowrap bold" style={{ color: '#555555', fontSize: this.fontSize(), width: this.width(), padding: this.paddingHeader() }}>
          {KpiName}
        </td>
        {
          parsedValues.map(value =>
            (
              <td className="light right-align nowrap" style={{ color: '#555555', fontSize: this.fontSize(), padding: this.padding() }}>
                {value || <HtmlComponent data={{ html: '&ndash;' }} />}
              </td>
            ))
        }
        {fillCells()}
      </tr>
    );
  }

  render() {
    const { kpi_charts, id } = this.props;

    const tableRows = [];
    kpi_charts.forEach((kpiChart) => {
      if (kpiChart.show_actual_data) {
        tableRows.push(this.mapTableRow(`${kpiChart.name} (Actual)`, kpiChart.actuals));
      }
      if (kpiChart.show_plan_data) {
        tableRows.push(this.mapTableRow(`${kpiChart.name} (Plan)`, kpiChart.forecasts));
      }
    });
    return (
      <div className="">
        <h4 className="pl2 pt2 mt0 bold fs-18" style={{ color: '#555' }}>{kpi_charts[0].chart_title}</h4>
        <div className="p2 update__table" id={id} style={{ overflowX: 'auto', maxWidth: '100%', fontSize: '80%' }}>
          <div className="pr2">
            <table className="">
              <tbody>
                {this.datesHeader()}
                {tableRows}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default TableKpiPortal;
