import axios from 'axios';

import {
  updateCapTable,
} from '../../actions/company/capTableActions';

const updateRound = params =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const capTableId = getState().capTable.data.id;
    const res = await axios.patch(`/company/${companyId}/manual_cap_tables/${capTableId}`, params);
    dispatch(updateCapTable(res.data));
  };

const createShare = (params) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const capTableId = getState().capTable.data.id;
    const res = await axios.post(`/company/${companyId}/manual_cap_tables/${capTableId}/manual_cap_shares`, params);
    dispatch(updateCapTable(res.data));
  };

const updateShare = (id, params) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const capTableId = getState().capTable.data.id;
    const res = await axios.patch(`/company/${companyId}/manual_cap_tables/${capTableId}/manual_cap_shares/${id}`, params);
    dispatch(updateCapTable(res.data));
  };

const deleteShare = (id) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const capTableId = getState().capTable.data.id;
    const res = await axios.delete(`/company/${companyId}/manual_cap_tables/${capTableId}/manual_cap_shares/${id}`);
    dispatch(updateCapTable(res.data));
  };

const createShareClass = (params) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const capTableId = getState().capTable.data.id;
    const res = await axios.post(`/company/${companyId}/share_classes?manual_cap_table_id=${capTableId}`, params);
    dispatch(updateCapTable(res.data));
  };

const updateShareClass = (id, params) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const capTableId = getState().capTable.data.id;
    const res = await axios.patch(`/company/${companyId}/share_classes/${id}?manual_cap_table_id=${capTableId}`, params);
    dispatch(updateCapTable(res.data));
  };

const deleteShareClass = (id) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const capTableId = getState().capTable.data.id;
    const res = await axios.delete(`/company/${companyId}/share_classes/${id}?manual_cap_table_id=${capTableId}`);
    dispatch(updateCapTable(res.data));
  };

const createConvertible = (params) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const capTableId = getState().capTable.data.id;
    const res = await axios.post(`/company/${companyId}/manual_cap_tables/${capTableId}/manual_cap_convertibles`, params);
    dispatch(updateCapTable(res.data));
  };

const updateConvertible = (id, params) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const capTableId = getState().capTable.data.id;
    const res = await axios.patch(`/company/${companyId}/manual_cap_tables/${capTableId}/manual_cap_convertibles/${id}`, params);
    dispatch(updateCapTable(res.data));
  };

const deleteConvertible = (id) =>
  async (dispatch, getState) => {
    const companyId = getState().company.id;
    const capTableId = getState().capTable.data.id;
    const res = await axios.delete(`/company/${companyId}/manual_cap_tables/${capTableId}/manual_cap_convertibles/${id}`);
    dispatch(updateCapTable(res.data));
  };

export {
  updateRound,
  createShare,
  updateShare,
  deleteShare,
  createShareClass,
  updateShareClass,
  deleteShareClass,
  createConvertible,
  updateConvertible,
  deleteConvertible,
};
