import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Textarea from '../../../Textarea';
import { contactIcon, contactLink } from '../../../modules/investmentContacts';

class Contact extends Component {
  static propTypes = {
    contact: PropTypes.object.isRequired,
    edit: PropTypes.bool.isRequired,
    saveInvestmentField: PropTypes.func.isRequired,
  }

  renderLink = () => {
    const { contact, edit } = this.props;
    let link = contact.text;

    if (!link) return null;

    link = contactLink(contact);

    return (
      <div className="flex items-center lh-15">
        {contactIcon(contact.name)}
        {edit && <div className="col-6 text-light-gray">{contact.name}</div>}
        <a href={link} target="_blank" rel="noopener noreferrer" className={`hover underline__hover truncate ${edit ? 'col-6' : 'col-12'}`}>
          {contact.name === 'Website' || contact.name === 'Other' || contact.name === 'Url' ?
            link ? link.replace(/^http(s?):\/\//i, '') : ''
            :
            `View on ${contact.name}`
          }
        </a>
      </div>
    );
  }

  render() {
    const { contact, edit, saveInvestmentField } = this.props;
    const indicator = typeof contact.id === 'string' ? 'desktop__notification--changes-green' : 'desktop__notification--changes-blue';

    if (!edit) return this.renderLink(contact);

    return (
      <div className="flex items-center lh-125">
        {contactIcon(contact.name)}
        <div className="col-4 py1 text-light-gray border border-transparent capitalize">{contact.name}</div>
        <div className={`col-8 relative ${indicator}`}>
          <Textarea
            className="col-12 lh-125 text-gray border border-lighter-gray bg-none hover hover-border-blue truncate"
            name={contact.name}
            value={contact.text}
            onBlur={e => saveInvestmentField(contact.id, e)}
            disabled={!edit}
            rows="1"
          />
        </div>
      </div>
    );
  }
}

export default Contact;
