import thunk from 'redux-thunk';
import logger from 'redux-logger';

import { createStore, applyMiddleware, compose } from 'redux';

import rootReducer from '../reducers/rootReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = railsProps =>
  createStore(
    rootReducer,
    railsProps,
    composeEnhancers(applyMiddleware(thunk, logger)),
  );

export default configureStore;
