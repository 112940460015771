import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ShUpdate from './ShUpdate';


class ShUpdateWidget extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  handleClick = e => {
    if (!App.is_desktop) {
      e.preventDefault();
      alert("Sorry, we currently don't support update creation on small screen sizes...");
    }
  }

  render() {
    const { data } = this.props;

    return (
      <div className="col-12 flex mb2">
        <div className="bg-white bs-around col-12 flex flex-column p1">
          <div className="flex mb2">
            <span className="bold h5">Published updates</span>
            <div className="flex flex-auto flex-justify-end ml2" />
          </div>
          <div className="overflow-y-auto" style={{ maxHeight: '190px' }}>
            {data.table.map(item => (
              <ShUpdate
                key={item.id}
                data={item}
              />
            ))}
            {!data.table.length &&
              <div className="pt4 mt1 text-light-gray center">No published investor updates available.</div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ShUpdateWidget;
