import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Textarea from '../Textarea';


class CommentFormSh extends Component {
  static propTypes = {
    setCommentBody: PropTypes.func,
    authorAvatar: PropTypes.any,
    setCommentBody: PropTypes.any,
    commentBody: PropTypes.any,
    submitComment: PropTypes.any,
  }

  setCommentBody = (event) => {
    this.props.setCommentBody(event.target.value);
  }

  render() {
    let avatarStyle = {
      backgroundImage: 'url(' + this.props.authorAvatar + ')',
    };

    return (
      <div className="clearfix bg-white mb2">
        <div className="relative">
          <Textarea
            className="border border-alto col-12 h5 mb1"
            onChange={this.setCommentBody}
            placeholder="Write your response..."
            rows="1"
            value={this.props.commentBody}
          />

          <i
            className="fa fa-pencil absolute right-0 text-alto top-0"
            style={{
              right: '10px',
              top: '10px',
            }}
          />

          <div
            className="bg-blue center cursor-pointer h5 inline-block px3 py1 regular right rounded text-white"
            onClick={this.props.submitComment}
          >
            Send to all recipients
          </div>
        </div>
      </div>
    );
  }
}

export default CommentFormSh;
