import React, { Component } from 'react';
import PropTypes from 'prop-types';


class KpiPortal extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    kpi: PropTypes.object,
    kpiCharts: PropTypes.array,
    brandingColors: PropTypes.array,
  }

  static defaultProps = {
    kpi: null,
    kpiCharts: null,
    brandingColors: null,
  }

  chartTypeCorrector = {
    bar: 'column',
    line: 'line',
    spline: 'spline',
    'area-spline': 'areaspline',
    bar_horizontal: 'bar',
  }

  componentDidMount() {
    this.generateChart();
  }

  advancedOptions = () => {
    const { kpi, kpiCharts, brandingColors } = this.props;
    const firstKpiChart = kpiCharts ? kpiCharts[0] : kpi;
    return {
      chart_title: firstKpiChart.chart_title,
      show_markers: firstKpiChart.show_markers,
      show_data_labels: firstKpiChart.show_data_labels,
      values_as_percentage: firstKpiChart.values_as_percentage,
      tick_start: firstKpiChart.tick_start,
      tick_end: firstKpiChart.tick_end,
      date_format: firstKpiChart.date_format,
      period_step: firstKpiChart.period_step,
      branding_colors: brandingColors,
    };
  }

  generateChart = () => {
    const { id, kpiCharts } = this.props;
    const advancedOptions = this.advancedOptions();

    if (kpiCharts && kpiCharts[0].funnel_chart) {
      window.createFunnelChart({
        selector: `graph${id}`,
        /* advanced options (for every chart) */
        chart_title: advancedOptions.chart_title,
        show_data_labels: advancedOptions.show_data_labels,
        values_as_percentage: advancedOptions.values_as_percentage,
        /* individual options array */
        individualOptions: this.individualOptionsForFunnel(),
      });
    } else {
      window.createChart({
        selector: `graph${id}`,
        /* advanced options (for every chart) */
        ...advancedOptions,
        /* individual options array */
        individualOptions: this.individualOptions(),
      });
    }
  }

  individualOptions = () => {
    const { kpi, kpiCharts } = this.props;
    const values = kpiCharts || [kpi];

    return values.map((kpiChart) => {
      const type = kpi ? this.chartTypeCorrector[kpiChart.type] : kpiChart.type;
      const actuals = this.parser(kpiChart.actuals);
      const forecasts = this.parser(kpiChart.forecasts);
      const actualLabels = this.parser(kpiChart.actual_labels);
      const forecastLabels = this.parser(kpiChart.forecast_labels);
      actuals.shift(1);
      forecasts.shift(1);
      if (actualLabels) {
        actualLabels.shift(1);
        actualLabels.reverse();
      }
      if (forecastLabels) {
        forecastLabels.shift(1);
        forecastLabels.reverse();
      }
      return {
        name: kpiChart.name,
        type,
        show_actual_data: kpiChart.show_actual_data,
        show_plan_data: kpiChart.show_plan_data,
        actuals: actuals.map(value => parseFloat(value)).reverse(),
        forecasts: forecasts.map(value => parseFloat(value)).reverse(),
        dates: this.parser(kpiChart.dates).reverse(),
        actual_labels: actualLabels,
        forecast_labels: forecastLabels,
        unit: kpiChart.unit,
        actual_color: kpiChart.actual_color,
        plan_color: kpiChart.plan_color,
      };
    });
  }

  individualOptionsForFunnel = () => {
    const { kpiCharts } = this.props;
    return kpiCharts.map((kpiChart) => ({
      name: kpiChart.name,
      type: 'funnel',
      show_actual_data: kpiChart.show_actual_data,
      show_plan_data: kpiChart.show_plan_data,
      actual_value: kpiChart.actual_value,
      plan_value: kpiChart.plan_value,
    }));
  }

  parser = (data) => {
    if (typeof data === 'string' || data instanceof String) {
      return JSON.parse(data);
    }

    if (data) {
      return [...data];
    }

    return data;
  }

  render() {
    const { id } = this.props;

    return (
      <div className="col-12 sm-p0">
        <div id={`graph${id}`} />
      </div>
    );
  }
}

export default KpiPortal;
