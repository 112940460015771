import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';

import Modal from '../Modal';


class LinkShareButton extends Component {
  static defaultProps = {
    url: '',
    expiry: '',
    buttonClass: 'list',
  }

  static propTypes = {
    url: PropTypes.string,
    expiry: PropTypes.string,
    generateUrl: PropTypes.string.isRequired,
    revokeUrl: PropTypes.string.isRequired,
    buttonClass: PropTypes.string,
  }

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      url: props.url,
      expiry: props.expiry,
    };
  }

  buttonClass = () => {
    const { buttonClass } = this.props;
    const { url } = this.state;

    if (buttonClass === 'header') {
      return 'bg-green cursor-pointer flex flex-center hover-text-white mr1 px1 rounded text-white nowrap';
    }
    if (buttonClass === 'list' && url === '') {
      return 'cursor-pointer text-gray mx1';
    }
    return 'cursor-pointer text-green mx1';
  }

  createLink = () => {
    const { generateUrl } = this.props;

    App.Api.patch(generateUrl).then((data) => {
      this.setState({ url: data.url, expiry: data.expiry });
    });
  }

  displayLink = () => {
    const { url, expiry } = this.state;

    if (url === '') {
      return (
        <div>
          <div
            className="flex flex-auto flex-justify-end cursor-pointer p1"
            onClick={() => this.setState({ open: false })}
          >
            <i className="fa fa-close text-gray" />
          </div>
          <p className="col-12 center semi-bold py2 h2">Create shareable link for update</p>
          <p className="col-12 text-light-gray center px1">
            <span className="text-blue mr1">Please note:</span>
            Anyone in possession of this shareable link can access this Update!<br />
          Features like commenting or responding to this Update will be disabled.
          </p>
          <div className="col-12 flex bg-alabaster p3">
            <div className="bg-white border border-light-gray p1 flex flex-auto">
              No link available currently.
            </div>
            <div className="bg-blue text-white semi-bold p1 px2 cursor-pointer hover-text-white" onClick={this.createLink}>
              <i className="fa fa-link mr1" />
              Create Link
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div
          className="flex flex-auto flex-justify-end cursor-pointer p1"
          onClick={() => this.setState({ open: false })}
        >
          <i className="fa fa-close text-gray" />
        </div>
        <p className="col-12 center semi-bold py2 h2">Create shareable link for update</p>
        <p className="col-12 text-light-gray center">
          <span className="text-blue mr1">Please note:</span>
          Anyone in possession of this shareable link can access this Update!<br />
          Features like commenting or viewing other recipients of this Update will be disabled.
        </p>
        <div className="col-12 flex sm-block bg-alabaster p3 sm-center mb1 mt1">
          <input
            className="bg-white border sm-block sm-col-12 border-light-gray h6 p1 flex flex-auto"
            onClick={e => e.target.setSelectionRange(0, e.target.value.length)}
            value={url}
            style={{ marginTop: '0px', marginBottom: '0px' }}
          />
          <div className="sm-mt2 flex sm-block">
            <CopyToClipboard
              text={url}
              onCopy={() => App.State.setFlash({ name: 'notice', msg: 'The link was copied to your clipboard!' })}
            >
              <div className="bg-green text-white semi-bold p1 px2 cursor-pointer center width-100 hover-text-white">
                Copy link
              </div>
            </CopyToClipboard>
            <div className="bg-red text-white sm-center semi-bold p1 px2 cursor-pointer center width-100 hover-text-white" onClick={this.revokeLink}>
              Revoke
            </div>
          </div>
        </div>
        <p className="col-12 text-light-gray center mt2 mb2">
          This link will automatically expire in
          <span className="text-blue"> {expiry}</span>
        </p>
      </div>
    );
  }

  revokeLink = () => {
    const { revokeUrl } = this.props;

    const confirmed = confirm('You are about to revoke access through the link you generated earlier. Anyone with the link will not be able to access this Update any more. You will be able to immediately get another shareable link after you revoke the currently active one. Are you sure you want to revoke this link?');
    if (confirmed) {
      App.Api.delete(revokeUrl).then(() => {
        this.setState({ url: '', expiry: '' });
      });
    }
  }

  render() {
    const { open } = this.state;

    return (
      <div>
        <div
          className={this.buttonClass()}
          onClick={() => this.setState({ open: true })}
          data-balloon="Shareable link to this update"
          data-balloon-pos="left"
          style={{ height: '28px' }}
        >
          <i className="fa fa-link fa-fw mr1" /> Shareable link
        </div>
        <Modal
          className="bg-white flex flex-column mb2 react_modal"
          show={open}
          onClickOutside={() => this.setState({ open: false })}
        >
          {this.displayLink()}
        </Modal>
      </div>
    );
  }
}

export default LinkShareButton;
