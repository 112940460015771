import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../../../store/configureStore';
import RecipientStepContainer from '../../../containers/company/shareholder_update/RecipientStepContainer';


const RecipientStep = props => (
  <Provider store={configureStore(props)}>
    <RecipientStepContainer />
  </Provider>
);

export default RecipientStep;
