import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ColorPicker from '../../selects/ColorPicker';

export default class EditStatusOptions extends Component {
  static propTypes = {
    statusOptions: PropTypes.array.isRequired,
    hideData: PropTypes.bool,
    upgradeAccountText: PropTypes.string,
    legend: PropTypes.string,
  }

  static defaultProps = {
    hideData: false,
    upgradeAccountText: null,
    legend: null,
  }

  constructor(props) {
    super(props);

    const { statusOptions } = props;
    this.initialAddState = {
      normal: {
        id: '',
        order: 1,
        color_hex: 'ffffff',
        option_type: 'normal',
        name: '',
      },
      fundraising: {
        id: '',
        order: 1,
        color_hex: 'ffffff',
        option_type: 'fundraising',
        name: '',
      },
    };

    this.state = {
      statusOptions,
      addOption: this.initialAddState,
    };
  }

  state = {

  }

  compareOptions = (o1, o2) => o1.order === o2.order && o1.option_type === o2.option_type

  onColorChange = (option, color) => {
    const { statusOptions } = this.state;
    const options = statusOptions.map(o => {
      if (this.compareOptions(o, option)) return { ...o, color_hex: color.hex.slice(1) };
      return o;
    });
    this.setState({ statusOptions: options });
  }

  onChangeName = (option, value) => {
    const { statusOptions } = this.state;
    const options = statusOptions.map(o => {
      if (this.compareOptions(o, option)) return { ...o, name: value };
      return o;
    });
    this.setState({ statusOptions: options });
  }

  onChangeOrder = (option1, option2) => {
    const { statusOptions } = this.state;
    const options = statusOptions.map(o => {
      if (this.compareOptions(option1, o)) return { ...o, order: option2.order };
      if (this.compareOptions(option2, o)) return { ...o, order: option1.order };
      return o;
    });
    this.setState({ statusOptions: options });
  }

  onRemoveOption = (option) => {
    const { statusOptions } = this.state;
    const options = statusOptions.filter(o => !this.compareOptions(o, option));
    this.setState({ statusOptions: options });
  }

  onAddOption = (type) => {
    const { statusOptions, addOption } = this.state;
    if (addOption[type].name) {
      const options = statusOptions.filter(o => o.option_type === type);
      this.setState({
        statusOptions: [...statusOptions, { ...addOption[type], order: options.length + 1 }],
        addOption: this.initialAddState,
      });
    } else {
      App.State.setFlash({ name: 'alert', msg: 'Status name is empty.' });
    }
  }

  render() {
    const { hideData, upgradeAccountText, legend } = this.props;
    const { statusOptions, addOption } = this.state;

    const options = statusOptions.sort((a, b) => ((a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0)));
    const optionTypes = ['normal', 'fundraising'];
    const filteredOptions = {
      normal: options.filter(o => o.option_type === 'normal'),
      fundraising: options.filter(o => o.option_type === 'fundraising'),
    };
    return (
      <div
        className="flex flex-column bg-white bs-around mb3 p4 sm-px2 sm-py3"
        data-balloon={hideData ? upgradeAccountText : null}
        data-balloon-pos="top"
        data-balloon-length="xlarge"
      >
        {legend &&
          <span className="mb2 h5 text-light-gray fw400">
            {legend}
          </span>
        }
        <div className="flex">
          {
            optionTypes.map(type => (
              <div className="col-6" key={type}>
                <div className="h4 fw400 mb3">{`${type.charAt(0).toUpperCase() + type.substring(1)} Status Options`}</div>
                <div className={`mx-auto ${hideData ? 'hide-data' : ''}`}>
                  {
                    filteredOptions[type].map((option, idx) => (
                      <div className="mb2 flex flex-row" key={`option_${type}_${option.order}`}>
                        <input type="hidden" name="status_options[values][][id]" value={option.id} multiple="multiple" />
                        <input type="hidden" name="status_options[values][][option_type]" value={option.option_type} multiple="multiple" />
                        <input type="hidden" name="status_options[values][][order]" value={option.order} multiple="multiple" />
                        <input type="hidden" name="status_options[values][][color_hex]" value={option.color_hex} multiple="multiple" />
                        <div className="mr1">
                          <ColorPicker
                            iconClass="circle cursor-pointer"
                            color={`#${option.color_hex}`}
                            onChange={(color) => this.onColorChange(option, color)}
                          />
                        </div>
                        <div className="w-200 mr1">
                          <input className="input h-30 px1" name="status_options[values][][name]" value={option.name} multiple="multiple" onChange={(e) => this.onChangeName(option, e.target.value)} />
                        </div>
                        {idx !== 0 ?
                          <div className="circle border border-blue bg-white mr1 center cursor-pointer mr1" style={{ width: '30px', height: '30px' }} onClick={() => this.onChangeOrder(option, filteredOptions[type][idx - 1])}><i className="fa fa-arrow-up lh-15 text-blue" /></div>
                          : <div className="mr1" style={{ width: '30px', height: '30px' }} />
                        }
                        {idx !== filteredOptions[type].length - 1 ?
                          <div className="circle border border-blue bg-white mr1 center cursor-pointer mr1" style={{ width: '30px', height: '30px' }} onClick={() => this.onChangeOrder(option, filteredOptions[type][idx + 1])}><i className="fa fa-arrow-down lh-15 text-blue" /></div>
                          : <div className="mr1" style={{ width: '30px', height: '30px' }} />
                        }
                        <div className="border border-red bg-white w-50 rounded center cursor-pointer" onClick={() => this.onRemoveOption(option)}><i className="fa fa-times text-red lh-16" /></div>
                      </div>
                    ))
                  }
                  <div className="mb2 flex flex-row">
                    <div className="mr1">
                      <ColorPicker
                        iconClass="circle cursor-pointer"
                        color={`#${addOption[type].color_hex}`}
                        onChange={color => this.setState({ addOption: { ...addOption, [type]: { ...addOption[type], color_hex: color.hex.slice(1) } } })}
                      />
                    </div>
                    <div className="w-200 mr1">
                      <input className="input h-30 px1" value={addOption[type].name} onChange={(e) => this.setState({ addOption: { ...addOption, [type]: { ...addOption[type], name: e.target.value } } })} />
                    </div>
                    <div className="mr1" style={{ width: '30px', height: '30px' }} />
                    <div className="mr1" style={{ width: '30px', height: '30px' }} />
                    <div className="border-none bg-blue w-50 rounded center cursor-pointer text-white" onClick={() => this.onAddOption(type)}>Add</div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}
