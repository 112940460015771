const company = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_CUSTOMER':
      return { ...state, customer: action.customer };

    case 'REMOVE_CUSTOMER':
      return { ...state, customer: null };

    case 'SUBSCRIBE_COMPANY':
      return { ...state, customer: action.customer, subscription: action.subscription };

    case 'UNSUBSCRIBE_COMPANY':
      return { ...state, subscription: action.subscription };

    case 'RESUBSCRIBE_COMPANY':
      return { ...state, subscription: action.subscription };

    default: return state;
  }
};

export default company;
