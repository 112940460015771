import React, { Component } from 'react';
import PropTypes from 'prop-types';


class HtmlComponent extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  createMarkup = (text) => ({ __html: text })

  render() {
    const { data } = this.props;
    return (
      <div
        className={data.css_class}
        dangerouslySetInnerHTML={this.createMarkup(data.html)}
      />
    );
  }
}

export default HtmlComponent;
