import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';

import Modal from '../Modal';


class KpiValuesModal extends Component {
  static propTypes = {
    companyId: PropTypes.number,
    investorId: PropTypes.number,
    investmentId: PropTypes.number,
    show: PropTypes.bool.isRequired,
    initialStep: PropTypes.func,
    closeModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    companyId: null,
    investorId: null,
    investmentId: null,
    initialStep: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      kpis: [],
      errors: [],
      loading: false,
      file: null,
    };

    this.initialState = this.state;
  }

  blockSubmit = () => {
    const { kpis, errors } = this.state;
    return ($.isEmptyObject(kpis) || errors.length > 0);
  }

  handleCloseModal = () => {
    const { closeModal } = this.props;

    this.setState(this.initialState);
    closeModal();
  }

  handleGoBack = () => {
    const { initialStep } = this.props;

    this.setState(this.initialState);
    initialStep();
  }

  checkImportedFile = (file) => {
    if ((file.size / 1024 / 1024) > 50) {
      this.setState({ loading: false });
      App.State.setFlash({ name: 'alert', msg: 'The file you are trying to upload is too large. Please upload files not larger than 50 MB!' });
      return false;
    }
    if (file.name.split('.').pop() !== 'xlsx') {
      this.setState({ loading: false });
      App.State.setFlash({ name: 'alert', msg: 'Only .xlsx fiels can be used.' });
      return false;
    }
    return true;
  }

  uploadFile = (file) => {
    const { companyId, investorId, investmentId } = this.props;
    const { loading } = this.state;
    const formData = new FormData();

    const url = companyId ? `/company/${companyId}/kpis/import_info` : `/investor/${investorId}/investments/${investmentId}/kpi_fields/import_info`;

    if (loading) return;

    this.setState({ loading: true });

    if (this.checkImportedFile(file[0])) {
      this.setState({ file: file[0] });
      formData.append('attachment', file[0]);
    } else {
      return;
    }

    axios.post(url, formData).then(
      (res) => {
        this.setState({ kpis: res.data.kpis, errors: res.data.errors });
        this.setState({ loading: false });
      },
      (error) => {
        const errorMessage = error.response ? error.response.data.error : 'An error prevented this file from being uploaded.';
        App.State.setFlash({ name: 'alert', msg: errorMessage });
        this.setState({ loading: false });
      },
    );
  }

  submit = () => {
    const { companyId, investorId, investmentId } = this.props;
    const { file } = this.state;
    const formData = new FormData();

    const url = companyId ? `/company/${companyId}/kpis/import` : `/investor/${investorId}/investments/${investmentId}/kpi_fields/import`;

    this.setState({ loading: true });

    formData.append('attachment', file);

    if (typeof Intercom !== 'undefined') Intercom('trackEvent', 'excel_new_import');

    axios.post(url, formData).then(
      () => {
        App.State.setFlash({ name: 'notice', msg: 'KPIs imported successfully!' });
        this.setState({ loading: false });
        this.handleCloseModal();
        window.location = companyId ? `/c/${companyId}/kpis` : `/investor/${investorId}/investments/${investmentId}/kpi_fields/edit`;
      },
      (error) => {
        const errorMessage = error.response ? error.response.data.error : 'An error prevented this file from being uploaded.';
        App.State.setFlash({ name: 'alert', msg: errorMessage });
        this.setState({ loading: false });
      },
    );
  }

  render() {
    const { show, initialStep } = this.props;
    const { loading, errors, file, kpis } = this.state;

    return (
      <Modal
        show={show}
        loading={loading}
      >
        <div className="flex flex-justify-between items-center bold p2 border-bottom border-lighter-gray">
          <div className="h3 text-gray center">Import KPIs from Excel</div>
          <i className="fa fa-close h5 cursor-pointer" onClick={this.handleCloseModal} />
        </div>

        <div className="flex flex-column items-center center mx2 mb1" style={{ maxWidth: '540px' }}>
          <div className="col-11">
            {
            !file &&
            <div>
              <div className="my2">
                <p className="text-light-gray mb1">
                To upload KPIs from a spreadsheet, it needs to be formatted similar to the example below.
                </p>
                <a className="" href="https://investory.io/wp-content/uploads/2024/01/Investory_KPI_Import_Template_2024_Version.xlsx">Download KPI template</a>
              </div>
            </div>
            }

            <div className="my2">
              {
                !$.isEmptyObject(kpis) &&
                <div>
                  <div className="border border-green rounded mt3" style={{ borderStyle: 'dashed' }}>
                    <div className="flex items-center m1">
                      <i className="fa fa-check fa-lg mx1 text-green" />
                      <div className="ml1 flex flex-wrap truncate">
                      Upload of <span className="fw400">{`"${file.name}" `}</span> successful!
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-column flex-start my2 left-align lh-15 overflow-y-auto" style={{ maxHeight: '120px' }}>
                    <span>{`Following ${Object.keys(kpis).length} KPIs were detected and will be imported:`}</span>
                    {
                      kpis.map(kpi => (
                        <div key={kpi.metric}>
                          - <span className="fw400">{kpi.metric}</span> ({kpi.actual_values_present_count} actual &amp; {kpi.plan_values_present_count} plan values) {kpi.warning && <span className="text-red">{`- ${kpi.warning}`}</span>}
                        </div>
                      ))
                    }
                  </div>
                </div>
              }
              {
                errors.length > 0 &&
                <div>
                  <div className="border border-red rounded mt3" style={{ borderStyle: 'dashed' }}>
                    <div className="flex items-center m1">
                      <i className="fa fa-close fa-lg mx1 text-red" />
                      <div className="ml1 flex flex-wrap truncate">
                        Upload of <span className="fw400">{` "${file.name}" `}</span> unsuccessful!
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-column flex-start my2 left-align lh-15">
                    <span>The import failed due to following reason(s):</span>
                    {
                      errors.map(errorMessage => (
                        <span key={Date.now} className="fw400">- {errorMessage}</span>
                      ))
                    }
                  </div>
                </div>
              }
              {
                errors.length === 0 && $.isEmptyObject(kpis) &&
                <Dropzone
                  className="border-light-gray bg-lighter-gray flex flex-justify-center cursor-pointer"
                  style={{ borderWidth: '2px', borderStyle: 'dashed', borderRadius: '5px' }}
                  onDrop={(fileToUpload) => this.uploadFile(fileToUpload)}
                  multiple={false}
                  activeStyle={{ borderColor: window.ColorVariables.colorBlue }}
                >
                  <div className="px1 my3 text-light-gray center flex flex-column">
                    <i className="fa fa-file-o fa-lg mb2" />
                    <span className="h4">Click or Drag and drop a .xlsx file here</span>
                  </div>
                </Dropzone>
              }
              {
                ((errors.length === 0) && file) &&
                  <div className="text-orange mt3 mb2">
                    WARNING: Importing overwrites all existing KPI data!
                    {kpis.find(kpi => kpi.warning) &&
                      <div className="flex">
                        <span className="h5 text-red">*KPIs with different frequencies will have them changed to the new ones specified in the template.</span>
                      </div>
                    }
                  </div>
              }
              {
                file &&
                  <div className="cursor-pointer" onClick={() => this.setState(this.initialState)}>
                    <span className="text-blue">To upload again, click here</span>
                  </div>
              }
            </div>

          </div>
        </div>

        <div className={`flex ${initialStep ? 'flex-justify-between' : 'flex-justify-end'} items-center p2 border-top border-lighter-gray`}>
          {
            initialStep &&
            <div
              className="h5 p1 px2 cursor-pointer bg-white text-blue border border-blue rounded"
              onClick={this.handleGoBack}
            >
              &#60; Back
            </div>
          }

          <div className="flex items-center">
            <div
              className="h5 text-blue mr2 cursor-pointer"
              onClick={this.handleCloseModal}
            >
              Cancel
            </div>
            <div
              className={`h5 p1 px2 text-white rounded ${this.blockSubmit() ? 'bg-lighter-gray' : 'bg-green cursor-pointer'}`}
              onClick={this.blockSubmit() ? null : this.submit}
            >
              {loading ? 'Importing...' : 'Import KPIs'}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default KpiValuesModal;
