const addGenericKpi = genericKpi => ({
  type: 'ADD_GENERIC_KPI',
  genericKpi,
});

const removeGenericKpi = genericKpi => ({
  type: 'REMOVE_GENERIC_KPI',
  genericKpi,
});

const resetGenericKpisSelection = genericKpi => ({
  type: 'RESET_GENERIC_KPIS_SELECTION',
  genericKpi,
});

const updateFrequency = (frequency, data) => ({
  type: 'UPDATE_FREQUENCY',
  frequency,
  data,
});

const createGenericKpis = (data) => ({
  type: 'CREATE_GENERIC_KPIS',
  data,
});

const changeGenericKpiValues = (genericKpi, field, value) => ({
  type: 'CHANGE_GENERIC_KPI_VALUES',
  genericKpi,
  field,
  value,
});

const updateGenericKpisUsedState = (usedGenericKpiIds) => ({
  type: 'UPDATE_USED_GENERIC_KPIS',
  usedGenericKpiIds,
});

export {
  addGenericKpi,
  removeGenericKpi,
  resetGenericKpisSelection,
  updateFrequency,
  createGenericKpis,
  changeGenericKpiValues,
  updateGenericKpisUsedState,
};
