import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../Modal';
import Switch from '../../../Switch';
import FileField from '../../../FileField';
import TextInput from '../../../TextInput';
import SelectWithImage from '../../../selects/SelectWithImage';


class UserFieldModal extends Component {
  static defaultProps = {
    options: [],
  }

  static propTypes = {
    show: PropTypes.bool.isRequired,
    userType: PropTypes.string.isRequired,
    options: PropTypes.array,
    closeModal: PropTypes.func.isRequired,
    saveFields: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      custom: false,
      selected: null,
      image: '',
      name: '',
    };

    this.baseState = this.state;
  }

  setImage = image => this.setState({ image });

  closeModal = () => {
    const { closeModal } = this.props;

    this.setState(this.baseState);
    closeModal();
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  submit = async () => {
    const { custom, selected, name, image } = this.state;
    const { saveFields, userType } = this.props;

    const formData = new FormData();
    formData.append('investment_field[values][][overwrite]', userType);

    if (!custom && selected) {
      let id;
      let selectedValue;
      // co-investors are added from shares. Which have shareholders, which is a polymorphic field
      // (can be either 'investor_id' either 'user_id' either 'custom' - i.e, just the name)
      if (userType === 'investor') {
        switch (selected.type) {
          case 'Investor':
            id = 'investor_id';
            break;
          case 'User':
            id = 'user_id';
            break;
          default:
            id = 'name';
            break;
        }
        selectedValue = selected.value;
      } else {
        id = 'user_id';
        selectedValue = selected.value;
      }
      formData.append(`investment_field[values][][${id}]`, selectedValue);
    } else {
      formData.append('investment_field[values][][image]', image);
      formData.append('investment_field[values][][name]', name);
    }

    try {
      await saveFields(userType, formData);
      this.closeModal();
    } catch (err) {
      console.log(err.response.data);
    }
  }

  render() {
    const { custom, selected, image, name } = this.state;
    const { show, userType, options } = this.props;

    return (
      <Modal
        show={show}
        onClickOutside={this.closeModal}
      >
        <div className="flex flex-justify-between items-center p2 border-bottom border-lighter-gray">
          <div className="h3 text-gray fw400">Add New User</div>
          <i className="fa fa-close h5 cursor-pointer" onClick={this.closeModal} />
        </div>

        <div className="flex flex-column items-center p2 mt2">
          {custom ?
            <form className="col-8 flex items-center text-gray">
              <div className="mr1 flex items-center">
                <div className="image-preview inline-block relative">
                  <div
                    className="image-preview--small user-avatar bg-center bg-cover bg-no-repeat circle"
                    style={{ backgroundImage: `url(${image || window.images.userGray})` }}
                    data-behavior={`avatar-preview-file-${userType}`}
                  />
                  <FileField
                    name="image"
                    preview_target={`[data-behavior="avatar-preview-file-${userType}"]`}
                    onChange={this.setImage}
                  />
                  <i className="fa fa-camera fa--funds absolute left-25 text-white vertical-center transform-center" />
                </div>
              </div>
              <TextInput
                name="name"
                value={name}
                className="flex-auto border border-lighter-gray hover hover-border-blue"
                placeholder={`Add ${userType === 'investor' ? 'investor' : 'team member'}'s name...`}
                onChange={this.handleChange}
              />
            </form>
            :
            <SelectWithImage
              className="col-8"
              value={selected ? options.filter(option => option.value === selected.value) : null}
              options={options}
              placeholder="Select..."
              onChange={selectedOption => this.setState({ selected: selectedOption })}
              isClearable={false}
            />
          }
          <div className="flex items-center mt2">
            <div className="flex">
              <div className="mr2">
                <Switch
                  defaultChecked={custom}
                  onChange={() => this.setState({ custom: !custom, selected: null })}
                />
              </div>
            </div>
            <div className="fw400">Custom {userType === 'investor' ? 'investor' : 'team member'}</div>
          </div>
        </div>

        <div className="flex flex-justify-end items-center p2 border-top border-lighter-gray">
          <div
            className="h5 text-blue mr2 cursor-pointer"
            onClick={this.closeModal}
          >
            Cancel
          </div>
          <div
            className="h5 p1 px2 cursor-pointer bg-blue text-white rounded"
            onClick={this.submit}
          >
            Save
          </div>
        </div>
      </Modal>
    );
  }
}

export default UserFieldModal;
