import React, { Component } from 'react';
import PropTypes from 'prop-types';


class HoverButton extends Component {
  static propTypes = {
    img: PropTypes.string,
    hoverImg: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
  }

  state = {
    hover: false,
  }

  displayButton = () => {
    const { img, hoverImg, width, height } = this.props;
    if (this.state.hover) {
      return (
        <img
          src={hoverImg}
          style={{ width, height }}
          onMouseLeave={() => { this.setState({ hover: false }); }}
          alt="Hover"
        />
      );
    }
    return (
      <img
        src={img}
        style={{ width, height }}
        onMouseEnter={() => { this.setState({ hover: true }); }}
        alt="img"
      />
    );
  }

  render() {
    return (
      <div className="flex">
        { this.displayButton() }
      </div>
    );
  }
}

export default HoverButton;
