import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SorterHeader from '../SorterHeader';


class FactSheetHeader extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  getColClass = () => {
    const { data } = this.props;

    let name, modified, manager, status, settings, fundraiseStatus = null;
    manager = 'col-3';
    settings = 'col-1';
    fundraiseStatus = 'col-1';
    if (data.angel) {
      name = 'col-4';
      modified = 'col-3';
      status = 'col-3';
    } else {
      name = 'col-3';
      modified = 'col-2';
      status = 'col-2';
    }
    return { name, modified, manager, status, settings, fundraiseStatus };
  }

  render() {
    const { data } = this.props;

    const { angel } = data;
    return (
      <div className="col-12 text-gray h5 flex px2 bg-extra-light-gray">
        <div className={`${this.getColClass().name} flex py1 bold pl2 sm-col-6 lg-col-6 sm-pl0`}>
          <SorterHeader text="Name" field="name" />
        </div>
        <div className={`${this.getColClass().modified} flex py1 bold pl2 sm-col-6 sm-pl0 lg-hide`}>
          <SorterHeader text="Modified" field="modified" />
        </div>
        {
          !angel &&
          <div className={`${this.getColClass().manager} flex py1 bold pl2 sm-col-6 sm-pl0 lg-hide`}>
            <SorterHeader text="Assigned Manager" field="investment_managers" />
          </div>
        }
        <div className={`${this.getColClass().status} flex py1 bold pl2 sm-col-6`}>
          <SorterHeader text="Published" field="status" />
        </div>
        <div className={`${this.getColClass().fundraiseStatus} border-alto py1 pl2 flex items-center sm-col-6 lg-hide`}>
          <SorterHeader text="Status" field="fundraise_status" />
        </div>
        <div className={`${this.getColClass().settings} flex py1 bold pl2 sm-col-6 sm-pl0 lg-hide`} />
      </div>
    );
  }
}

export default FactSheetHeader;
