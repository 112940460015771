// eslint-disable-next-line import/no-mutable-exports
let proPlans = [];
console.log('process.env.NODE_ENV: ', process.env.NODE_ENV);
if (process.env.NODE_ENV === 'production') {
  proPlans = [
    {
      id: 'angel_pro_plan',
      name: 'Angel Pro',
      desc: 'Everything in Basic, plus:',
      monthlyPrice: 49,
      limitedMonthlyId: 'price_1MFkaXAW33eP81Eye4V7nkuB',
      yearlyPrice: 490,
      limitedYearlyId: 'price_1MFkaXAW33eP81EyP6LFTfNw',
      monthlyPriceForUnlimited: 99,
      unlimitedMonthlyId: 'price_1MFkRFAW33eP81Ey0OAPBSuU',
      yearlyPriceForUnlimited: 990,
      unlimitedYearlyId: 'price_1MFkRFAW33eP81EybG8F8TZW',
      features: [
        {
          name: 'Portfolio Performance',
        },
        {
          nameFor10Portfolio: '10 Portfolio Companies',
          nameForUnlimitedPortfolio: 'Unlimited Portfolio Companies',
        },
        {
          name: '1 Fund',
        },
        {
          name: 'Data Export',
        },
        {
          name: 'Investor Notes',
        },
        {
          name: 'Co-Investment Analysis',
        },
        {
          name: 'Investor-Entered Data Companies',
        },
        {
          name: 'Personal Support',
        },
        {
          name: 'Guided Onboarding',
        },
      ],
    },
    {
      id: 'vc_pro_plan',
      name: 'VC Pro',
      monthlyPrice: 199,
      limitedMonthlyId: 'price_1MFkftAW33eP81EyCeOuR80R',
      yearlyPrice: 1990,
      limitedYearlyId: 'price_1MFkftAW33eP81Ey1PpaJ9KX',
      monthlyPriceFor30Portfolio: 299,
      monthly30PortfolioId: 'price_1MFkjrAW33eP81Eyky88QcN8',
      yearlyPriceFor30Portfolio: 2990,
      yearly30PortfolioId: 'price_1MFkjrAW33eP81EytLwFsHJe',
      monthlyPriceForUnlimited: 399,
      unlimitedMonthlyId: 'price_1MFkmUAW33eP81EyCOhb2rjn',
      yearlyPriceForUnlimited: 3990,
      unlimitedYearlyId: 'price_1MFkmUAW33eP81EybgxlwdYM',
      desc: 'Everything in Angel Pro, plus:',
      features: [
        {
          nameFor20Portfolio: '20 Portfolio Companies',
          nameFor30Portfolio: '30 Portfolio Companies',
          nameForUnlimitedPortfolio: 'Unlimited Portfolio Companies',
        },
        {
          name: 'Unlimited Funds',
        },
        {
          name: '5 Team Members',
        },
        {
          name: 'Unlimited Guests',
        },
        {
          name: 'Fund Data Room',
        },
        {
          name: 'Data Completeness Console',
        },
        {
          name: 'API Access',
        },
      ],
    },
    {
      name: 'Enterprise (Individual pricing based on specific client needs)',
    },
  ];
} else {
  proPlans = [
    {
      id: 'angel_pro_plan',
      name: 'Angel Pro',
      desc: 'Everything in Basic, plus:',
      monthlyPrice: 49,
      limitedMonthlyId: 'price_1M6HiNAW33eP81EydTyYhWoB',
      yearlyPrice: 490,
      limitedYearlyId: 'price_1M6HioAW33eP81Ey6pw6TQWS',
      monthlyPriceForUnlimited: 99,
      unlimitedMonthlyId: 'price_1M6HjLAW33eP81EyUGcwW2nV',
      yearlyPriceForUnlimited: 990,
      unlimitedYearlyId: 'price_1M6HjnAW33eP81EyrOUlGZGl',
      features: [
        {
          name: 'Portfolio Performance',
        },
        {
          nameFor10Portfolio: '10 Portfolio Companies',
          nameForUnlimitedPortfolio: 'Unlimited Portfolio Companies',
        },
        {
          name: '1 Fund',
        },
        {
          name: 'Data Export',
        },
        {
          name: 'Investor Notes',
        },
        {
          name: 'Co-Investment Analysis',
        },
        {
          name: 'Investor-Entered Data Companies',
        },
        {
          name: 'Personal Support',
        },
        {
          name: 'Guided Onboarding',
        },
      ],
    },
    {
      id: 'vc_pro_plan',
      name: 'VC Pro',
      monthlyPrice: 199,
      limitedMonthlyId: 'price_1M6HlFAW33eP81EyPJzw96ci',
      yearlyPrice: 1990,
      limitedYearlyId: 'price_1M6HlqAW33eP81EyUY9NuqwI',
      monthlyPriceFor30Portfolio: 299,
      monthly30PortfolioId: 'price_1M6HmLAW33eP81EydD6njoUk',
      yearlyPriceFor30Portfolio: 2990,
      yearly30PortfolioId: 'price_1M6HmkAW33eP81EyFlpw1CQi',
      monthlyPriceForUnlimited: 399,
      unlimitedMonthlyId: 'price_1M6HnBAW33eP81Eyz6oRt5Sz',
      yearlyPriceForUnlimited: 3990,
      unlimitedYearlyId: 'price_1M6HnWAW33eP81EyjiVocCKn',
      desc: 'Everything in Angel Pro, plus:',
      features: [
        {
          nameFor20Portfolio: '20 Portfolio Companies',
          nameFor30Portfolio: '30 Portfolio Companies',
          nameForUnlimitedPortfolio: 'Unlimited Portfolio Companies',
        },
        {
          name: 'Unlimited Funds',
        },
        {
          name: '5 Team Members',
        },
        {
          name: 'Unlimited Guests',
        },
        {
          name: 'Fund Data Room',
        },
        {
          name: 'Data Completeness Console',
        },
        {
          name: 'API Access',
        },
      ],
    },
    {
      name: 'Enterprise (Individual pricing based on specific client needs)',
    },
  ];
}
// eslint-disable-next-line import/prefer-default-export
export { proPlans };
