import numberFormat from '../../user_number_format';
import NumeralSetting from './numeral';

export default function NumeralWrapper(value) {
  if (!value) return null;

  const Numeral = NumeralSetting('custom');

  if (value && numberFormat().decimalPoint === ',') {
    return Numeral(value.toString().replace('.', ',')).format('0.0a');
  }

  return Numeral(value).format('0.0a');
}
