export const trackEvent = (category, event, customProperties = {}) =>
  (dispatch, getState) => {
    mixpanel.track(event, ...{
      category,
      label: `${category}: ${event}`,
      user_type: getState().currentUser.type,
      user_id: getState().currentUser.id,
      user_name: getState().currentUser.name,
    }, ...customProperties);

    dispatch({ type: null });
  };

export default trackEvent;
