import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import classNames from 'classnames';

import Avatar from '../../tab_view/Avatar';
import Modal from '../../Modal';
import NumeralWrapper from '../../utils/NumeralWrapper';
import CleaveNumberFormatting from '../../CleaveNumberFormatting';
import InvestorsAndCompaniesSearch from '../../onboarding/utils/InvestorsAndCompaniesSearch';

import { defaultSelectStyles, defaultSelectTheme } from '../../selects/SelectStyles';

export default class DealCommitmentsWidget extends Component {
  static propTypes = {
    dealCommitments: PropTypes.array.isRequired,
    currentUser: PropTypes.object.isRequired,
    canEdit: PropTypes.bool.isRequired,
    companyId: PropTypes.string.isRequired,
    dealRoomId: PropTypes.number.isRequired,
    fundingGoal: PropTypes.number,
    daysUntilClosing: PropTypes.number,
    currency: PropTypes.string.isRequired,
    investorConnectionOptions: PropTypes.array,
    commitmentStateOptions: PropTypes.array,
    createDealCommitment: PropTypes.func,
    saveDealCommitment: PropTypes.func,
    deleteDealCommitment: PropTypes.func,
    showModal: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    dealContactEmail: PropTypes.string,
  }

  static defaultProps = {
    fundingGoal: null,
    daysUntilClosing: null,
    investorConnectionOptions: [],
    commitmentStateOptions: [],
    createDealCommitment: null,
    saveDealCommitment: null,
    deleteDealCommitment: null,
    showModal: false,
    dealContactEmail: '',
  }

  constructor(props) {
    super(props);

    this.initial = {
      dealCommitment: {
        committable_id: null,
        committable_type: null,
        connection_name: '',
        total_amount_committed: null,
        state: 0,
      },
      connectionType: 'existing_connection',
      saving: false,
    };
    this.state = this.initial;
  }

  handleChange = (name, value) => {
    this.setState(state => ({
      ...state,
      dealCommitment: {
        ...state.dealCommitment,
        [name]: value,
      },
    }));
  }

  handleConnectionTypeChange = (type) => {
    this.setState({ connectionType: type }, () => {
      if (type === 'new_connection' || type === 'invited') {
        this.handleChange('committable_id', null);
        this.handleChange('committable_type', null);
      } else {
        this.handleChange('connection_name', '');
      }
    });
  }

  handleResult = (result) => {
    if (result.id) {
      this.handleChange('committable_id', result.id);
      this.handleChange('committable_type', 'User');
    } else {
      this.handleChange('committable_id', null);
      this.handleChange('committable_type', null);
    }

    this.handleChange('connection_name', result.email);
  }

  handleCommitmentDelete = async (id) => {
    const { deleteDealCommitment } = this.props;

    try {
      await deleteDealCommitment(id);
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
    }
  }

  handleAddCommitment = async () => {
    const { createDealCommitment } = this.props;
    const { dealCommitment, connectionType, saving } = this.state;

    if (saving) return;

    this.setState({ saving: true });

    let params = { deal_room_commitment: dealCommitment };

    try {
      params = { ...params, invited: connectionType === 'invited' };
      await createDealCommitment(params);
      this.setState({ ...this.initial, showModal: true, saving: false });
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
      this.setState({ saving: false });
    }
  }

  handleCommitmentUpdate = async (id, name, value) => {
    const { saveDealCommitment } = this.props;

    const params = { deal_room_commitment: { [name]: value } };

    try {
      await saveDealCommitment(id, params);
    } catch (err) {
      App.State.setFlash({ name: 'alert', msg: err.response.data.error });
    }
  }

  render() {
    const {
      dealCommitments,
      currentUser,
      canEdit,
      companyId,
      dealRoomId,
      fundingGoal,
      daysUntilClosing,
      currency,
      investorConnectionOptions,
      commitmentStateOptions,
      showModal,
      closeModal,
      dealContactEmail,
    } = this.props;

    const {
      dealCommitment,
      connectionType,
      saving,
    } = this.state;

    const commitmentClasses = state => (
      classNames('h5 col-5 center fw400', {
        'text-green': state === 'Confirmed',
        'text-orange': state === 'Unconfirmed',
      })
    );
    const sortedDealCommitments = dealCommitments.sort((a, b) => ((a.total_amount_committed.raw > b.total_amount_committed.raw) ? -1 : 1));
    const connectionTypeOptions = [
      { value: 'existing_connection', label: 'Existing Connection' },
      { value: 'new_connection', label: 'New Connection' },
      // { value: 'invited', label: 'Invite Connection' }, add & invite is blocked for now
    ];
    const connectionText = () => {
      switch (connectionType) {
        case 'new_connection':
          return 'Connection Name';
        case 'existing_connection':
          return 'Select from Connection';
        default:
          return 'Search/Add below';
      }
    };
    const totalAmount = dealCommitments.reduce((acc, commitment) => acc + commitment.total_amount_committed.raw, 0);
    const goalPercentage = (100 * totalAmount) / (fundingGoal || 0.0);

    return (
      <React.Fragment>
        <span className="h5 text-light-gray">Deal Commitments</span>
        <div className="flex flex-justify-between flex-baseline">
          <div className="h3 fw400 text-gray">{NumeralWrapper(totalAmount.toFixed(2))}<span className="h6 text-light-gray pl1">in {currency}</span></div>
          <div className="h4 fw400 text-gray">{sortedDealCommitments.length}<span className="h6 text-light-gray pl1">Investors</span></div>
        </div>
        <div className="col-12 border border-alto rounded text-medium-gray" style={{ height: '8px' }}>
          <div className="bg-gradient-blue rounded" style={{ width: `${goalPercentage > 100 ? '100' : goalPercentage}%`, height: '6px' }} />
        </div>
        <div className="flex flex-justify-between pb2">
          <div className="h6 text-gray">{fundingGoal ? `${goalPercentage.toFixed(2)}%` : ''}<span className={`text-light-gray ${fundingGoal ? 'pl1' : ''}`}>{fundingGoal ? 'of Funding Goal' : ''}</span></div>
          <div className="h6 text-gray">{daysUntilClosing}<span className="text-light-gray pl1">{daysUntilClosing == null ? '' : 'days until Closing'}</span></div>
        </div>
        <div className="overflow-y-auto mb2" style={{ height: canEdit ? 164 : 192 }}>
          {sortedDealCommitments.length > 0 ?
            <React.Fragment>
              {sortedDealCommitments.map(commitment => (
                <div
                  key={commitment.id}
                  className={`flex items-center ${sortedDealCommitments.length > 4 ? 'pb1' : 'pb2'}`}
                >
                  <div className="col-5 flex items-center">
                    <Avatar avatarUrl={commitment.connection_avatar || window.images.userGray} size={18} shadow />
                    <div className="h5 text-gray pl2 truncate">{commitment.connection_name}</div>
                  </div>
                  <div className={commitmentClasses(commitment.state.readable)}>{commitment.state.readable}</div>
                  <div className="h5 fw400 pr1 col-2 right-align text-gray">{commitment.total_amount_committed.readable}</div>
                </div>
              ))}
            </React.Fragment>
            :
            <div className="flex flex-justify-start h5 text-light-gray pb2 pt2 lh-12 fw400" style={{ whiteSpace: 'pre-wrap' }}>
              No commitments so far...
            </div>
          }
        </div>
        {/* Only show if user is a collaborator */}
        {!currentUser.investor &&
          <div className="flex flex-justify-between">
            <a className="btn btn-outline rounded h5 cursor-pointer border border-green text-green px1 py05 fw400 " href={`mailto:${dealContactEmail}`}>Ask to Invest</a>
            <a
              className="btn btn-outline rounded h5 cursor-pointer border border-light-gray text-light-gray px1 py05 fw400"
              href={`/company/${companyId}/deal_rooms/${dealRoomId}/state?state=closed`}
              data-method="patch"
              data-balloon="Closing a deal prevents any user from accessing the deal room data. No data is changed when closing/opening the deal room and no notification is sent automatically."
              data-balloon-length="medium"
            >
              Close Deal
            </a>
          </div>
        }

        {/* Modal starts here */}
        {canEdit &&
          <Modal
            show={showModal}
            onClickOutside={closeModal}
            widthClass="react_modal--600"
          >
            <div className="flex flex-justify-between items-center semi-bold px3 py2 border-bottom border-lighter-gray">
              <div className="h3">Deal Room - Manage Deal Commitments</div>
              <i className="fa fa-close h5 cursor-pointer" onClick={closeModal} />
            </div>
            <div className="flex flex-column px3 py2">
              <div className="flex flex-justify-between items-center pb2">
                <div className="col-4 border-bottom border-lighter-gray" />
                <span className="h5 text-gray">New Deal Commitment</span>
                <div className="col-4 border-bottom border-lighter-gray" />
              </div>
              <div className="flex flex-row pb2">
                <div className="flex flex-column col-6 pr1">
                  <span className="h5 text-gray pb1">New Committment by</span>
                  <ReactSelect
                    className="lh-16"
                    value={connectionTypeOptions.filter(opt => opt.value === connectionType)}
                    options={connectionTypeOptions}
                    onChange={selectedOption => this.handleConnectionTypeChange(selectedOption.value)}
                    styles={defaultSelectStyles}
                    theme={defaultSelectTheme}
                  />
                </div>
                <div className="flex flex-column col-6 pl1">
                  <span className="h5 text-gray pb1">{connectionText()}</span>
                  {connectionType === 'new_connection' &&
                    <input
                      value={dealCommitment.connection_name}
                      onChange={e => this.handleChange(e.target.name, e.target.value)}
                      name="connection_name"
                      type="text"
                      className="border border-medium-lighter-gray hover-border-medium-light-gray"
                    />
                  }
                  {connectionType === 'existing_connection' &&
                    <ReactSelect
                      className="lh-16"
                      value={investorConnectionOptions.filter(opt => opt.value === dealCommitment.committable_id)}
                      options={investorConnectionOptions}
                      onChange={selectedOption => { this.handleChange('committable_id', selectedOption.value); this.handleChange('committable_type', selectedOption.type); }}
                      styles={defaultSelectStyles}
                      theme={defaultSelectTheme}
                    />
                  }
                  {/* add & invite is blocked for now */}
                  {connectionType === 'invited' && false &&
                    <InvestorsAndCompaniesSearch
                      searchUrl={`/c/${companyId}/invited_investors`}
                      resultSelect={result => this.handleResult(result)}
                      placeholder="Add an email address..."
                      emptyFieldAfterSelect={false}
                      height="26px"
                      inputClass="input-alto__focus"
                    />
                  }
                </div>
              </div>
              <div className="flex flex-row pb2">
                <div className="flex flex-column col-6 pr1">
                  <span className="h5 text-gray pb1">Total Amount Committed
                    <i
                      className="fa fa-info-circle pl1 text-light-gray"
                      data-balloon="Is the amount of money the investor is likely to invest."
                      data-balloon-pos="up"
                      data-balloon-length="medium"
                    />
                  </span>
                  <CleaveNumberFormatting
                    value={`${dealCommitment.total_amount_committed}`}
                    className="col-12 border border-medium-lighter-gray hover-border-medium-light-gray mr1"
                    name="total_amount_committed"
                    onChange={e => this.handleChange(e.target.name, e.target.rawValue)}
                  />
                </div>
              </div>
              <div className="pb2 flex">
                <div className="cursor-pointer" onClick={this.handleAddCommitment}>
                  <div className="px2 py05 border border-blue text-blue h5 rounded">{saving ? 'Saving...' : 'Add Committment'}</div>
                </div>
              </div>
              <div className="flex flex-justify-between items-center pb2">
                <div className="col-4 border-bottom border-lighter-gray" />
                <span className="h5 text-gray nowrap mx1">Manage Deal Commitments</span>
                <div className="col-4 border-bottom border-lighter-gray" />
              </div>

              {sortedDealCommitments.length > 0 ?
                <React.Fragment>
                  {sortedDealCommitments.map(commitment => (
                    <div
                      key={commitment.id}
                      className="flex flex-row pb2 items-center"
                    >
                      <div className="flex flex-grow">
                        <input
                          type="text"
                          value={commitment.connection_name}
                          className="col-4 border border-light-gray mr1 bg-gallery"
                          disabled
                        />
                        <ReactSelect
                          className="col-4 mr1 lh-16"
                          value={commitmentStateOptions.filter(opt => opt.value === commitment.state.raw)}
                          options={commitmentStateOptions}
                          onChange={selectedOption => this.handleCommitmentUpdate(commitment.id, 'state', selectedOption.value)}
                          styles={defaultSelectStyles}
                          theme={defaultSelectTheme}
                          name="state"
                        />
                        <div className="col-4">
                          <CleaveNumberFormatting
                            value={`${commitment.total_amount_committed.raw}`}
                            className="col-12 border border-medium-lighter-gray hover-border-medium-light-gray mr1"
                            name="total_amount_committed"
                            onChange={e => this.handleCommitmentUpdate(commitment.id, e.target.name, e.target.rawValue)}
                          />
                        </div>
                      </div>
                      <i
                        className="fa fa-trash text-red cursor-pointer pl1"
                        onClick={() => this.handleCommitmentDelete(commitment.id)}
                      />
                    </div>
                  ))}
                </React.Fragment>
                :
                <div className="flex flex-row pb2 items-center">
                  <div className="flex flex-grow text-light-gray h6 flex-justify-center">
                  No commitments added yet...
                  </div>
                </div>
            }

            </div>
            <div className="flex flex-justify-end px3 pt1 mt1 mb2 border-top border-lighter-gray">
              <button
                type="submit"
                className="bg-blue text-white bs-around border-none h4 fw400 px3 py1 cursor-pointer rounded"
                onClick={closeModal}
              >
                Close & Save
              </button>
              {/* TODO: check if save is necessary because deal commitments are created/updated/deleted dinamically */}
              {/* <button
                type="submit"
                className="bg-blue text-white bs-around border-none h4 fw400 px3 py1 cursor-pointer rounded"
                onClick={closeModal}
              >
                Save
              </button> */}
            </div>
          </Modal>
        }
      </React.Fragment>
    );
  }
}
