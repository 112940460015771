import { connect } from 'react-redux';
import FundDashboard from '../../../components/investor/funds/dashboard/Dashboard';

import {
  setTabAndResetSorting,
  setTimeAgo,
  setLoadingTab,
  getCompanyInfoData,
  getInvestmentInfoData,
  getRecentUpdatesData,
  getPortfolioUpdatesData,
  getCoInvestorsData,
  getKpisData,
  getDataRoomInfoData,
  getFactSheetTabData,
  getExcelExportTabData,
  getSlideshowTabData,
  updateChartData,
  changeCompanyFilters,
  setWidgetChartFilter,
  loadNewExportFile,
} from '../../../api/investor/funds/fundDashboardApi';

import { trackEvent } from '../../../api/mixpanelApi';

const mapStateToProps = state => ({
  dashboard: state.fundDashboard,
});

const mapDispatchToProps = {
  setTabAndResetSorting,
  setTimeAgo,
  setLoadingTab,
  getCompanyInfoData,
  getInvestmentInfoData,
  getRecentUpdatesData,
  getPortfolioUpdatesData,
  getCoInvestorsData,
  getKpisData,
  getDataRoomInfoData,
  getFactSheetTabData,
  getExcelExportTabData,
  getSlideshowTabData,
  updateChartData,
  changeCompanyFilters,
  setWidgetChartFilter,
  loadNewExportFile,
  trackEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(FundDashboard);
