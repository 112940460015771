// TODO: remove if not being used
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Avatar from './Avatar';
import FaButton from './FaButton';
import FaButtonFunction from './FaButtonFunction';


class Recipient extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    is_last_item: PropTypes.bool,
    activeTab: PropTypes.string,
  }

  addRecipient = () => {
    const { data, activeTab } = this.props;

    App.Api.post(data.update_recipients_manage_url, { type: activeTab, users_ids: data.users_ids, investor_id: data.investor_id }).then((returnedData) => {
      if (returnedData) {
        if (returnedData.error) {
          store.dispatch({ type: 'SET_ITEM', list: activeTab, id: returnedData.data.id, value: returnedData.data });
          store.dispatch({ type: 'SET_CHECK_BOX', list: activeTab, value: returnedData.data.all_recipients_selected });
          App.State.setFlash({ name: 'alert', msg: returnedData.error });
        } else {
          store.dispatch({ type: 'SET_ITEM', list: activeTab, id: returnedData.id, value: returnedData });
          store.dispatch({ type: 'SET_CHECK_BOX', list: activeTab, value: returnedData.all_recipients_selected });
        }
      }
    });
  }

  checkBox = () => {
    const { data, activeTab } = this.props;
    if (data.disabled) {
      return (
        <FaButton
          faIcon={activeTab === 'investors' ? 'exclamation-triangle' : 'check-square'}
          color={activeTab === 'investors' ? 'orange' : 'alto'}
          size="h2"
          tooltip={activeTab === 'investors' ? "Can't select recipient due to an unaccepted invitation!" : null}
          tooltipPos="right"
          margin="mx0"
          disabled
        />
      );
    }
    if (data.checked_recipient) {
      return (
        <FaButtonFunction
          key={`check_${Date.now()}`}
          faIcon="check-square"
          tooltip="Remove Recipient"
          tooltipPos="right"
          handleClick={this.destroyRecipient}
          color="green"
          size="h2"
          margin="mx0"
        />
      );
    }
    return (
      <FaButtonFunction
        key={`uncheck_${Date.now()}`}
        faIcon="square-o"
        tooltip="Add Recipient"
        tooltipPos="right"
        handleClick={this.addRecipient}
        color="alto"
        size="h2"
        margin="mx0"
      />
    );
  }

  destroyRecipient = () => {
    const { data, activeTab } = this.props;

    App.Api.delete(data.update_recipients_destroy_url, { type: activeTab, recipients_ids: data.recipients_ids, users_ids: data.users_ids, investor_id: data.investor_id }).then((returnedData) => {
      if (returnedData.error) {
        store.dispatch({ type: 'SET_ITEM', list: activeTab, id: returnedData.data.id, value: returnedData.data });
        store.dispatch({ type: 'SET_CHECK_BOX', list: activeTab, value: returnedData.data.all_recipients_selected });
        App.State.setFlash({ name: 'alert', msg: returnedData.error });
      } else {
        store.dispatch({ type: 'SET_ITEM', list: activeTab, id: returnedData.id, value: returnedData });
        store.dispatch({ type: 'SET_CHECK_BOX', list: activeTab, value: returnedData.all_recipients_selected });
      }
    });
  }

  displayLink = () => {
    const { data, activeTab } = this.props;

    const link = (activeTab === 'investors') ? data.manage_investors : data.manage_team;
    const text = (activeTab === 'investors') ? 'Manage Investors' : 'Manage or Invite Team';
    return (
      <div className="flex flex-row col-12">
        <div className="col-6 pl2 ml1" style={{ width: '25%' }}>
          <a href={link} className="text-light-gray">
            <div className="center bg-wild-sand border rounded" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <span className="center fw400 text-gray">{text}</span>
            </div>
          </a>
        </div>
        {
          activeTab === 'investors' &&
          <div className="pl2" style={{ width: '25%' }}>
            <div
              className="cursor-pointer"
              onClick={(e) => { e.preventDefault(); this.openModal(); }}
            >
              <div className="center bg-blue border-blue rounded" style={{ paddingTop: '11px', paddingBottom: '11px' }}>
                <span className="center fw400 text-white">Invite Investors</span>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }

  openModal = () => {
    $('#modal-invite-investor').next('.modal__layer').show();
    $('#modal-invite-investor').fadeIn();
  }

  renderInvestorMembersRecipients = () => {
    const { data, is_last_item } = this.props;
    if (!data.members_recipients || data.members_recipients.length === 0) return null;
    const investorMembers = data.members_recipients.map((member) => (
      <div
        key={`member_${member.email}`}
        className="col-12 flex flex-wrap flex-justify-center px2 sm-px0"
      >
        <div className="col-1" />
        <div className="col-4 pb1">
          <div className="flex flex-justify-center">
            <div className="col-1 mr2">
              <Avatar avatarUrl={member.avatar} />
            </div>
            <span className="col-10 truncate text-gray" >{member.name}</span>
          </div>
        </div>
        <div className="col-5 pb1 text-medium-gray truncate pl1">
          {member.email}
        </div>
        <div className="col-2" />
      </div>
    ));

    return (
      <div className={is_last_item ? 'pb1' : ''}>
        {investorMembers}
      </div>
    );
  }

  render() {
    const { data, is_last_item } = this.props;
    return (
      <div>
        <div className={`col-12 flex flex-wrap flex-justify-center px2 sm-px0 ${is_last_item ? 'pb1' : ''}`}>
          <div className="col-6 p1">
            <a href={data.url} className="flex flex-justify-center">
              <div className="col-1 mr2">
                {this.checkBox()}
              </div>
              <div className="col-1 mr2">
                <Avatar avatarUrl={data.avatar} />
              </div>
              <span className="col-10 truncate text-gray fw400">
                {data.name}
              </span>
            </a>
          </div>
          <div className="col-6 p1 text-medium-gray truncate">
            {data.email}
          </div>
        </div>
        {this.renderInvestorMembersRecipients()}
        {is_last_item && this.displayLink()}
      </div>
    );
  }
}

export default Recipient;
